import { AdditionalProduct } from "src/gql/graphql";
import { TELIA_SKY_PRODUCT_TYPE } from "util/constants/valueAddedServices";

export const isB2BCompanyPaidTeliaSkyProduct = (product: AdditionalProduct) => {
  const b2bTeliaSkyCodes: string[] = [
    TELIA_SKY_PRODUCT_TYPE.B2B_COMPANY_PAID_FOR_ALL_EMPLOYEES_ACTIVE,
    TELIA_SKY_PRODUCT_TYPE.B2B_COMPANY_PAID_FOR_ALL_EMPLOYEES,
    TELIA_SKY_PRODUCT_TYPE.B2B_COMPANY_PAID_FOR_SELECTED_EMPLOYEES,
  ];
  return product.code ? b2bTeliaSkyCodes.includes(product.code) : false;
};
