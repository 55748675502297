import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Telia5thElementLightBeigePng from "./assets/Telia_5th-Element_Light-Beige_16.png";
import Telia5thElementPurplePng from "./assets/Telia_5th-Element_Purple.png";
import "./polyfills";
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
dayjs.extend(utc);
export * from "./Accordion";
export * from "./ActionMenuButton";
export type { ActionPoint, ActionPoint as ProductBoxActionPoint } from "./ActionMenuButton";
export { ActionMenuButtonStyled } from "./ActionMenuButton/style";
export { Alert } from "./Alert";
export { Backdrop } from "./Backdrop";
export { BlueconicCard } from "./BlueconicCard";
export * from "./Banner";
export { Button } from "./Button";
export type { ButtonProps, ButtonTagProps, ButtonVariant } from "./Button";
export { CancelButton } from "./Button/CancelButton";
export * from "./Card";
export * from "./Carousel";
export { Collapse } from "./Collapse";
export { CollapseButton } from "./Collapse/CollapseButton";
export * from "./Container";
export { EntertainmentBox } from "./EntertainmentBox";
export * from "./ExpandableCard";
export { FullPageLoading } from "./FullPageLoading";
export * from "./GaugeMeter";
export * from "./Heading";
export { HorizontalScroll } from "./HorizontalScroll";
export { scrollElementToCenter as scrollIntoViewOnClick } from "./HorizontalScroll/utils";
export { HorseshoeBoostChart, HorseshoeSizeChart, HorseshoeSpeedChart, SpeedDetails } from "./HorseshoeChart";
export { HorseshoeChartPicker } from "./HorseshoeChartPicker";
export { Icon } from "./Icon";
export type { IconProps } from "./Icon";
export { InformationSection } from "./InformationSection";
export { InformationItem } from "./InformationSection/InformationItem";
export * from "./InputFields";
export * from "./DatePicker";
export * from "./TrackClickEvent";
export { Link } from "./Link";
export type { LinkProps as LinkProps } from "./Link";
export { LinkGoBack } from "./Link/LinkGoBak";
export * from "./List";
export { MainLayout } from "./MainLayout";
export { MinSideButton } from "./MainLayout/MinSideButton";
export * from "./Modal";
export { MoreInfoIcon } from "./MoreInfoIcon";
export { MultiSelectButton, MultiSelectButtonGroup } from "./MultiSelectButton";
export * from "./NoProducts";
export { NotificationBox } from "./NotificationBox";
export type { Props as NotificationBoxProps } from "./NotificationBox";
export { OfferingPlanAccordion } from "./OfferingPlanAccordion/OfferingPlanAccordion";
export * from "./Paragraph";
export { ProductBox } from "./ProductBox";
export type { Props as ProductBoxProps } from "./ProductBox";
export { InfoText } from "./ProductBox/InfoText";
export { ProductBoxPrice } from "./ProductBox/Price";
export { ProductBoxList } from "./ProductBoxList";
export type { ProductBoxListProps as ProductBoxListProps } from "./ProductBoxList";
export { QuantitySelector } from "./QuantitySelector";
export * from "./RadioCardGroup";
export * from "./RadioGroup";
export * from "./SelfRecovery";
export { ServiceBox } from "./ServiceBox";
export * from "./ShoppingCard";
export * from "./ShoppingSummary";
export { LineSeparator } from "./ShoppingSummary/style";
export { Skeleton } from "./Skeleton";
export type { Props as SkeletonProps } from "./Skeleton";
export { SkeletonList } from "./SkeletonList";
export * from "./Switch";
export * from "./SwitchProgressBar";
export { Tabs } from "./Tabs";
export * from "./ProfilePicker";
export * as ProfilePickerTypes from "./ProfilePicker/types";
export { RouterTabs } from "./Tabs/RouterTabs";
export { Tab } from "./Tabs/Tab";
export * from "./Toast";
export { ToggleButton } from "./ToggleButton";
export { ToggleButtonGroup } from "./ToggleButtonGroup";
export { TopSection } from "./TopSection";
export { UpsellMobileCard } from "./UpsellMobileCard";
export { VippsIcon } from "./VippsIcon";
export * from "./context/auth";
export * from "./hooks";
export { focusRing } from "./styles/focus";
export * from "./utils/ScrollToTop";
export { axiosGet, axiosPost } from "./utils/axios";
export { breakpoints, devices } from "./utils/breakpoints";
export type { Breakpoints } from "./utils/breakpoints";
export { remCalc } from "./utils/fonts";
export { isStageDomain } from "./utils/locationHelper";
export { zIndex } from "./utils/variables";
export { handleUnauthorizedResponse } from "./utils/apiError";
export { images };

const images = {
  Telia5thElementLightBeigePng,
  Telia5thElementPurplePng,
};
