import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MenuLinkId, regExpsForCheckingActiveRoutes } from "../MainLayout/utils/links";
import type { Dispatch, SetStateAction } from "react";

export type ActiveMenu = "" | MenuLinkId;

export type UseMainLayoutNavigation = {
  push: (navigateTo: string, isInternalLink: boolean) => void;
  activeMenuId: ActiveMenu;
  setClickedMenuId: Dispatch<SetStateAction<ActiveMenu>>;
};

export function useMainLayoutNavigation(): UseMainLayoutNavigation {
  const [activeMenuId, setActiveMenuId] = useState<ActiveMenu>("");
  const [clickedMenuId, setClickedMenuId] = useState<ActiveMenu>("");
  const navigate = useNavigate();
  const location = useLocation();

  function getActiveMenuId(menuLinkIds: MenuLinkId[]): MenuLinkId | undefined {
    return menuLinkIds.find((menuLinkId) => {
      const isGroupId =
        menuLinkId === "menu_link_invoice" ||
        menuLinkId === "menu_link_alerts" ||
        menuLinkId === "menu_link_more" ||
        menuLinkId === "menu_link_shopping" ||
        menuLinkId === "menu_link_shopping_mobile" ||
        menuLinkId === "menu_link_shopping_smart-watch" ||
        menuLinkId === "menu_link_shopping_pakke_tilbud" ||
        menuLinkId === "menu_link_shopping_tablet" ||
        menuLinkId === "menu_link_shopping_addon" ||
        menuLinkId === "menu_link_customer_vas" ||
        menuLinkId === "menu_link_profile";
      const currentMenuPointIsActiveOnThisLocation =
        !isGroupId && regExpsForCheckingActiveRoutes[menuLinkId].test(location.pathname);
      return currentMenuPointIsActiveOnThisLocation;
    });
  }

  useEffect(() => {
    const menuLinkIds = Object.keys(regExpsForCheckingActiveRoutes) as MenuLinkId[];
    const currentActiveMenuId = getActiveMenuId(menuLinkIds);
    const isNotClicked = clickedMenuId === "";

    if (isNotClicked && currentActiveMenuId == null) return;
    if (currentActiveMenuId == null) {
      setActiveMenuId(clickedMenuId);
      return;
    }
    setClickedMenuId("");
    setActiveMenuId(currentActiveMenuId);
  }, [location, clickedMenuId]);

  function push(navigateTo: string, isInternalLink: boolean) {
    if (isInternalLink) {
      navigate(navigateTo);
    } else {
      window.location.href = navigateTo;
    }
  }

  return { push, activeMenuId, setClickedMenuId };
}
