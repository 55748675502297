import { Card, Container, Icon, Paragraph, Link } from "@telia-no-min-side/components";
import { Feature, FeatureList } from "pages/internet/broadband/styles/TeliaSkyDescription.styled";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function TeliaSkyDescription() {
  return (
    <>
      <FeatureList>
        <Feature>
          <Icon size="sm" icon="infinite" />

          <Paragraph variant="paragraph-100" removeMargin>
            Ubegrenset lagring
          </Paragraph>
        </Feature>

        <Feature>
          <Icon size="sm" icon="security" />

          <Paragraph variant="paragraph-100" removeMargin>
            Trygg lagring
          </Paragraph>
        </Feature>

        <Feature>
          <Icon size="sm" icon="conversation" />

          <Paragraph variant="paragraph-100" removeMargin>
            Alltid tilgjengelig
          </Paragraph>
        </Feature>

        <Feature>
          <Icon size="sm" icon="folder-new" />

          <Paragraph variant="paragraph-100" removeMargin>
            Lagre alt på ett sted
          </Paragraph>
        </Feature>
      </FeatureList>

      <Container padding="top">
        <Card backgroundColor="blue" showShadow={false} hideBorder removeMinHeight>
          <Paragraph variant="additional-100">
            Last ned i{" "}
            <Link href={EXTERNAL_LINK.APP_STORE_TELIA_SKY} target="_blank">
              App Store
            </Link>{" "}
            og{" "}
            <Link href={EXTERNAL_LINK.GOOGLE_PLAY_TELIA_SKY} target="_blank">
              Google Play
            </Link>
            , eller bruk Telia Sky på web. Logg inn med det samme brukernavnet og passordet du bruker på Min Side for å
            komme i gang.
          </Paragraph>
        </Card>
      </Container>
    </>
  );
}
