import { Link } from "react-router-dom";
import { Card, Text } from "@telia/teddy";

export function FirstTimeCard() {
  return (
    <Card variant="white" shadow layout="default" bordered width="100%" maxWidth="400px">
      <Card.Heading variant="title-100">Er du her for første gang?</Card.Heading>
      <Card.Content>
        <Text variant="additional-100">
          For å få tilgang til dine produkter og tjenester, må du utføre en verifisering via knappen under.
        </Text>
      </Card.Content>
      <Card.Footer height="100%" align="end">
        <Card.Button asChild>
          <Link to="/minside/hjelp/mobil-kontogjenoppretting">Verifiser deg</Link>
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
