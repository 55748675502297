import { AdditionalProduct, Maybe, AdditionalProductCategory } from "gql/graphql";

// GET users are fixed customers
// The extra NSHAPE (dobbel data) that we receive is the benefit that "Telia Samlet" customers,
// meaning Telia customer with both fixed and mobile products get
export function isGetUserDoubleDataActivated(additionalProducts: Maybe<AdditionalProduct[]> | undefined) {
  return (additionalProducts || []).some(
    (additionalProduct) =>
      additionalProduct.code &&
      additionalProduct.presentation?.active &&
      additionalProduct.productCategory === AdditionalProductCategory.Get &&
      additionalProduct.shortName?.toLowerCase().includes("dobbel data")
  );
}
