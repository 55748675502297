import { Button, Container, Paragraph } from "@telia-no-min-side/components";
import { useState } from "react";
import { StyledBox, UserInfoKey, UserInfoRow } from "../style";
import { useApolloClient, useQuery } from "@apollo/client";
import { GET_SUBSCRIPTION_INFO } from "../graphql/getSubscriptionInfo";
import { getConvergentBenefitAmount } from "src/util/format-subscription/getConvergentBenefitAmount";
import { getName } from "src/util/format-subscription/getName";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { format, track } from "@telia-no-min-side/utils";
import { StatusReasonCode, SubscriptionStatus, SubscriptionStatusInput } from "src/gql/graphql";
import { useChangeSubscriptionStatus } from "../hook/useChangeSubscriptionStatus";
import { SuspendError } from "./SuspendError";
import { SuspendSuccess } from "./SuspendSuccess";
import { SuspendBoxLoader } from "./SuspendBoxLoader";
import { updateSubscriptionStatus } from "../utils/update-subscription-status";

type Props = {
  onSuspended: () => void;
};

export function Suspend({ onSuspended }: Props) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { phoneNumber } = useMobileAccount();
  const { loading, data } = useQuery(GET_SUBSCRIPTION_INFO, { variables: { phoneNumber } });
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const client = useApolloClient();

  const changedSubscriptionStatus = useChangeSubscriptionStatus({
    onError(error) {
      if (error.message === "SUBSCRIPTION_ALREADY_SUSPENDED") {
        setErrorMessage("Dette abonnementet er allerede sperret");
      } else {
        setErrorMessage("Vi klarte ikke å utføre handlingen. Vennligst prøv igjen eller kontakt kundeservice.");
      }
    },
    onCompleted() {
      onSuspended();

      updateSubscriptionStatus(client, phoneNumber, {
        subscriptionStatus: SubscriptionStatus.Suspended,
        statusReasonCode: StatusReasonCode.Lost,
      });
    },
  });

  const subscription = data?.subscription;

  const offeringPrices = subscription?.userOffering?.offeringPrices?.find(
    (offeringPrice) => offeringPrice?.offeringPrices
  )?.offeringPrices;
  const convergentBenefitAmount = getConvergentBenefitAmount(subscription?.additionalProducts || []);
  const subscriptionName = getName(
    offeringPrices,
    subscription?.userOffering?.shortName,
    convergentBenefitAmount.hasDoubleData
  );

  const runChangeSubscriptionStatus = () => {
    changedSubscriptionStatus.changeSubscriptionStatus({
      variables: {
        phoneNumber,
        status: {
          reason: "LP",
          status: SubscriptionStatusInput.Suspend,
        },
      },
    });

    pushGenericTrackingEvent({
      ui_item_action: "button_click",
      ui_item_context: "minside/mobil/sperr-abonnement",
      ui_item_text: "Sperr mine SIM",
      ui_item_type: "button",
      ui_item_url: "no_url",
    });
  };

  if (loading || !subscription) return <SuspendBoxLoader />;

  const { productUser } = subscription;

  return (
    <StyledBox data-tracking-id="container--suspend-subscription">
      {errorMessage && (
        <SuspendError
          errorMessage={errorMessage}
          onRetry={runChangeSubscriptionStatus}
          loading={changedSubscriptionStatus.loading}
        />
      )}

      {changedSubscriptionStatus.data || subscription.subscriptionStatus === SubscriptionStatus.Suspended ? (
        <SuspendSuccess />
      ) : (
        <>
          <Paragraph>
            <strong>Er du sikker på at du ønsker å sperre SIM-kortet og abonnementet ditt?</strong>
          </Paragraph>
          <Container padding="top">
            <UserInfoRow>
              <UserInfoKey>
                <b>Navn:</b>
              </UserInfoKey>
              <Paragraph removeMargin data-di-mask>
                {format.startCase(`${productUser?.firstName} ${productUser?.surname}`)}
              </Paragraph>
            </UserInfoRow>
            <UserInfoRow>
              <UserInfoKey>
                <b>Abonnement:</b>
              </UserInfoKey>
              <Paragraph removeMargin>{subscriptionName}</Paragraph>
            </UserInfoRow>
            <UserInfoRow>
              <UserInfoKey>
                <b>Telefonnummer:</b>
              </UserInfoKey>
              <Paragraph removeMargin>{phoneNumber}</Paragraph>
            </UserInfoRow>
          </Container>
          <Paragraph>
            Hvis du har mistet eller blitt frastjålet telefonen din, så kan du sperre SIM-kortet og abonnementet ditt.
            Da kan ikke abonnementet ditt misbrukes, men abonnementet er ikke sagt opp, og du vil fortsatt få faktura.
          </Paragraph>
          <Paragraph>
            Eventuelle tilleggskort vil også bli sperret inntil abonnementet gjenåpnes. Du kan gjenåpne når som helst
            her på Min Side.
          </Paragraph>
          <Container horizontalPosition="left" padding="top">
            <Button
              variant="primary"
              onClick={runChangeSubscriptionStatus}
              isLoading={changedSubscriptionStatus.loading}
              loadingText="Behandler"
              data-tracking-id="button--suspend-subscription"
            >
              Sperr SIM/abonnement
            </Button>
          </Container>
        </>
      )}
    </StyledBox>
  );
}
