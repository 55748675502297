import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";

export const FamilyDiscountLine = styled.div<{ height?: number | undefined }>(
  (props) => css`
    width: 50px;
    height: ${props.height ? props.height + 64 : 135}px;
    border-left: 2px solid ${colors.corePurple700};
    border-bottom: 2px solid ${colors.corePurple700};
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 10px;
    top: 0;
    margin-top: 3px;
  `
);
