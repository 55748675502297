import { useNavigate } from "react-router-dom";
import { useQuery, NetworkStatus } from "@apollo/client";
import { Flex, Icon, Text, Card, Button, Badge, color } from "@telia/teddy";
import { Skeleton } from "@telia-no-min-side/components";
import { getPostPurchaseInsurances } from "./utils/getPostPurchaseInsurances";
import { GET_POST_PURCHASE } from "./graphql/getPostPurchase";
import { INSURANCE_POST_DUMMY_EXISTING } from "util/constants/agreements";
import { getInsuranceExpirationPeriod } from "./utils/getInsuranceExpirationPeriod";
import { format, isOlderThan } from "@telia-no-min-side/utils";
import { UserRole } from "gql/graphql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { INTERNAL_LINK } from "util/constants/internalLinks";

type Props = {
  phoneNumber: string;
};

export function PostPurchaseOfferCard({ phoneNumber }: Props) {
  const navigate = useNavigate();
  const { orders } = useOrderSubscription();

  const { loading, data, error, refetch, networkStatus } = useQuery(GET_POST_PURCHASE, {
    skip: !phoneNumber,
    notifyOnNetworkStatusChange: true,
    variables: { phoneNumber },
  });

  const subscription = data?.subscription;
  const firstName = subscription?.productUser?.firstName || "";
  const birthDate = subscription?.productUser?.birthDate || "";
  const isLegalOwner = subscription?.roles.includes(UserRole.LegalOwner);

  const isOver18 = isOlderThan(18, birthDate);
  if (!isOver18 && !isLegalOwner) return null;

  const additionalProducts = subscription?.additionalProducts || [];
  const postPurchaseDummyProduct = additionalProducts.find((p) => p.code === INSURANCE_POST_DUMMY_EXISTING);
  if (!postPurchaseDummyProduct) return null;

  const daysLeft = getInsuranceExpirationPeriod(postPurchaseDummyProduct);
  const postPurchaseInsurances = getPostPurchaseInsurances(additionalProducts, postPurchaseDummyProduct);

  const hasOnGoingOrder = orders.some(
    (order) => order.phoneNumber === phoneNumber && order.dataTrackingId === INSURANCE_POST_DUMMY_EXISTING
  );

  const handleNavigate = () => {
    if (hasOnGoingOrder) return;
    navigate(INTERNAL_LINK.POST_PURCHASE_INSURANCES_PAGE, {
      state: { firstName, phoneNumber, postPurchaseDummyProduct, postPurchaseInsurances },
    });
  };

  if (error || networkStatus === NetworkStatus.refetch) {
    return (
      <Card shadow bordered variant="white" width="100%" maxWidth="400px" layout="default">
        <Card.Heading as="h3">Feil oppstod!</Card.Heading>
        <Card.Content>Vi klarte ikke hente Aktuelt. Vennligst prøv igjen litt senere.</Card.Content>
        <Card.Footer>
          <Button loading={networkStatus === NetworkStatus.refetch} onClick={() => refetch({ phoneNumber })}>
            Last inn på nytt
          </Button>
        </Card.Footer>
      </Card>
    );
  }

  if (loading) {
    return (
      <Card shadow bordered variant="white" width="100%" maxWidth="400px" layout="default">
        <Flex direction="column" gap="100">
          <Skeleton variant="heading-s" width="10ch" />
          <Flex direction="column">
            <Skeleton width="80%" />
            <Skeleton width="60%" />
          </Flex>
          <Flex justify="end">
            <Skeleton variant="circular" height="30px" width="30px" />
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <Card
      shadow
      bordered
      width="100%"
      maxWidth="400px"
      layout="default"
      data-tracking-id="post-purchase-offer-card"
      onClick={handleNavigate}
    >
      <Card.Slot align="top-right">
        <Badge variant={hasOnGoingOrder ? "information" : "special"} hideIcon>
          {hasOnGoingOrder ? <Icon name="sync">Oppdateres</Icon> : "Tilbud til deg!"}
        </Badge>
      </Card.Slot>

      <Card.Heading as="h3" variant="title-100">
        Bestill forsikring
      </Card.Heading>

      <Card.Content>
        <Text data-di-mask>
          Bestill forsikring til {format.startCase(firstName)} sin nye telefon. Tilbudet gjelder i {daysLeft} dager
        </Text>
      </Card.Content>

      <Flex height="100%" align="end" justify="end">
        <Card.Button aria-label="chevron-right" variant="text" iconOnly>
          <Icon name="chevron-right" color={color.teddyColorPurple700} />
        </Card.Button>
      </Flex>
    </Card>
  );
}
