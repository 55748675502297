import { Operation } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import { GraphQLError, print } from "graphql";

const MIN_SOAP_ERROR_CODE_LENGTH = 3;
const SOAP_ERROR_CODE = /([A-Z_]+)(?=<\/errorCode)/;
const JUST_UPPERCASE_ERROR_CODE = /^[A-Z_]+$/;

const GRAPHQL_ERROR_IGNORE_LIST = [
  {
    operationName: "AccountsAgreements",
    message: "ACCOUNT_OWNER_ROLE_REQUIRED",
    ignoreReason: "we fetch both agreements without checking the user role and just handle the error in a component",
  },
];

export const isErrorInIgnoreList = (graphQLError: GraphQLError, operation: Operation) => {
  return GRAPHQL_ERROR_IGNORE_LIST.some(
    (error) => error.operationName === operation.operationName && error.message === graphQLError.message
  );
};

const getValidationErrorType = (error: GraphQLError) => {
  // Channel API returns more data than GraphQLError interface has
  // Some errors have a type ('VALIDATION'), which can be used as a facet
  // to filter errors in datadog
  const errorType = "errorType" in error && typeof error.errorType === "string" ? error.errorType : undefined;
  return errorType;
};

export const hasAuthenticationError = (graphQLErrors: GraphQLErrors) =>
  graphQLErrors.some((error) => "errorType" in error && error.errorType === "AUTHENTICATION");

export const getGraphQLErrorContext = (error: GraphQLError, operation: Operation) => {
  const operationContext = operation.getContext();
  const response: Response | undefined = operationContext.response;

  const requestTraceId = response?.headers?.get("x-amzn-requestid");
  const errorType = getValidationErrorType(error);

  const context = {
    errorGroup: "GraphqlError",
    errorType,
    path: error.path,
    operationName: operation.operationName,
    query: print(operation.query),
    "x-amzn-requestid": requestTraceId || undefined,
  };

  return context;
};

export const getGraphQLErrorGroup = (error: GraphQLError, operationName?: string) => {
  let errorGroup = "";

  let errorMessage = "unknownError";

  if (typeof error?.message === "string") {
    errorMessage = error.message;
  }

  if (typeof error?.message === "object") {
    errorMessage = JSON.stringify(error.message);
  }

  if (errorMessage.includes("SOAP-ENV")) {
    const match = errorMessage.match(SOAP_ERROR_CODE);

    if (match && match[0].length > MIN_SOAP_ERROR_CODE_LENGTH) {
      errorGroup = match[1];
    }
  } else if (JUST_UPPERCASE_ERROR_CODE.test(errorMessage)) {
    errorGroup = errorMessage;
  }

  if (errorGroup) {
    return [operationName, errorGroup].filter(Boolean).join(" | ");
  }

  return undefined;
};

export const getGraphQLErrorMessage = (graphQLError: GraphQLError, operation: Operation) => {
  const errorType = getValidationErrorType(graphQLError);
  return [operation.operationName, graphQLError.message, errorType].filter(Boolean).join(" | ");
};
