import styled from "styled-components";

export const InformationText = styled.p`
  margin: 0 0 1rem;
  a {
    color: black;
    text-decoration: none;
  }
`;

export const HeaderWrapper = styled.div`
  min-height: 49px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > :not(:first-child) {
    margin-top: 0.5rem;
  }
`;

export const Heading = styled.h2`
  font-family: TeliaSans, Helvetica, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1.3125rem;
`;
