import { NavigationButtons, type Props as NavigationButtonsProps } from "components/NavigationButtons";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { useMobileDashboardContext } from "../../context/MobileDashboardContext";

export function NavigationSection() {
  const { isPrepaid } = useMobileDashboardContext();
  const buttonsData: NavigationButtonsProps["buttonsData"] = [
    { URL: INTERNAL_LINK.INVOICE_PAGE, text: "Faktura", icon: "invoice" },
    { URL: INTERNAL_LINK.VALUE_ADDED_SERVICES_PAGE, text: "Dine tjenester", icon: "services" },
    { URL: INTERNAL_LINK.MOBILE_NEW.SIM_PIN_PUK_PAGE, text: "SIM, PIN & PUK", icon: "simcard" },
  ];
  const buttonsDataPrepaid: NavigationButtonsProps["buttonsData"] = [
    { URL: INTERNAL_LINK.MOBILE_NEW.SIM_PIN_PUK_PAGE, text: "SIM, PIN & PUK", icon: "simcard" },
    { URL: INTERNAL_LINK.MOBILE_NEW.SETTINGS_PAGE, text: "Innstillinger", icon: "settings" },
  ];
  return <NavigationButtons buttonsData={isPrepaid ? buttonsDataPrepaid : buttonsData} />;
}
