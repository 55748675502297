import { Container } from "@telia-no-min-side/components";
import { Card, Text, Button, Icon, Badge, Flex } from "@telia/teddy";
import { Link } from "react-router-dom";
import { phoneNumbers } from "util/phoneNumbers";

export function TempAccessNoMatch() {
  return (
    <Container maxWidth="sm" padding>
      <Card shadow gap="200">
        <Card.Heading>Beklager</Card.Heading>
        <Card.Content>
          <Flex direction="column" align="start" gap="400">
            <Text>Vi klarte ikke å matche dataene dine. Vennligst prøv igjen, eller kontakt kundeservice:</Text>
            <Badge variant="neutral">
              <Icon name="phone" />
              {phoneNumbers.customerServiceNorway}
            </Badge>
            <Flex gap="200">
              <Button variant="primary" asChild>
                <Link to="/minside/midlertidig-tilgang">Prøv igjen</Link>
              </Button>
              <Button variant="secondary" asChild>
                <Link to="/minside/hjem">
                  <Icon name="home" />
                  Tilbake til Hjem
                </Link>
              </Button>
            </Flex>
          </Flex>
        </Card.Content>
      </Card>
    </Container>
  );
}
