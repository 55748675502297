import { Card, Icon, Text } from "@telia/teddy";
import { useQuery } from "@apollo/client";
import { GDPR_REPORTS_MOBILE } from "pages/privacy/pages/RightOfAccessStepOne";
import { downloadGdprReportMobile } from "src/api/fixed/useFetchGDPR";
import dayjs from "dayjs";

export function InsightCard() {
  const gdprStatus = useQuery(GDPR_REPORTS_MOBILE);

  const newestReport = gdprStatus.data?.gdprStatus?.downloadableReports
    ?.sort((a, b) => new Date(b?.ticketCreationDate ?? 0).getTime() - new Date(a?.ticketCreationDate ?? 0).getTime())
    ?.find((report) => report?.requestId);

  const requestId = newestReport?.requestId;
  if (!requestId) {
    return null;
  }
  return (
    <Card shadow bordered variant="white" layout="navigation-horizontal-large" width="100%" maxWidth="400px">
      <Card.Heading as="h3" variant="title-100">
        Din innsynsrapport er klar
      </Card.Heading>
      <Card.Content>
        <Text>Du kan nå laste ned dine personopplysninger ved å klikke på knappen nedenfor.</Text>
      </Card.Content>
      <Card.Button
        mt="auto"
        ml="auto"
        size="sm"
        variant="text"
        onClick={(e) => {
          e.stopPropagation();
          downloadGdprReportMobile({
            requestId,
            fileName: `innsynsrapport-${dayjs(newestReport.ticketCreationDate).format("DD-MM-YYYY")}.zip`,
          });
        }}
      >
        <Icon name="download">Last ned</Icon>
      </Card.Button>
    </Card>
  );
}
