import { Accordion, Container, Heading } from "@telia-no-min-side/components";
import { GetAccountAgreementsQuery } from "gql/graphql";
import { CardHeader } from "../insurance/components/CardHeader";
import { AgreementInfo } from "./components/AgreementInfo";
import { Terms } from "../Terms";
import { LEASE_AGREEMENT_FRAGMENT } from "../../graphql/leaseAgreementFragment";
import { getFragmentData } from "gql";
import { URL_LEASE_AGREEMENT_TERMS } from "util/constants/agreements";

type Leases = NonNullable<GetAccountAgreementsQuery["account"]["leaseAgreements"]>["leases"][0];

type Props = {
  leaseAgreements: Leases[] | undefined;
};

export function LeaseAgreements({ leaseAgreements }: Props) {
  if (!leaseAgreements) return null;

  return leaseAgreements?.map((leaseAgreement) => {
    const agreement = getFragmentData(LEASE_AGREEMENT_FRAGMENT, leaseAgreement);

    return (
      <Accordion key={agreement?.id} data-tracking-id="agreement-lease-card">
        <Accordion.Item>
          <Container gap="lg">
            <Accordion.Trigger>
              <Heading tag="h2" variant="title-100" data-di-mask>
                Delbetaling
              </Heading>

              <CardHeader
                phoneImage=""
                phoneModel={agreement?.model || ""}
                phoneNumber={agreement?.phoneNumber.localNumber || ""}
              />
            </Accordion.Trigger>
          </Container>

          <Accordion.Content>
            <Container flexDirection="column" gap="lg">
              <AgreementInfo
                monthlyPrice={agreement?.regularInstallmentAmount?.value || 0}
                remainingPrice={agreement?.outstandingAmount?.value || 0}
                effectiveDate={agreement?.effectiveDate || ""}
                expirationDate={agreement?.expirationDate || ""}
              />

              <Terms
                termsInsuranceUrl={URL_LEASE_AGREEMENT_TERMS}
                termsInsuranceProofUrl=""
                dataTrackingId="lease-agreements"
              />
            </Container>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    );
  });
}
