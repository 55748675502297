import { Card, Text, FlipCard } from "@telia/teddy";

type Props = {
  formattedLimits: { dataLimitWhenSpeedReduced: string; reducedSpeed: string };
};

export const Terms = ({ formattedLimits }: Props) => {
  const { dataLimitWhenSpeedReduced, reducedSpeed } = formattedLimits;
  return (
    <div>
      <Card.Heading variant="title-400">Vilkår</Card.Heading>
      <Card.Line mt="250" mb="250" />
      <Card.Content>
        <Text style={{ lineHeight: "1.8" }}>
          Etter {dataLimitWhenSpeedReduced} reduseres hastigheten til {reducedSpeed}. Med Mobilt Bredbånd-abonnement kan
          du surfe som hjemme når du er i Norden og Baltikum, men det kan ikke brukes utenfor disse landene. Det kan
          heller ikke brukes ombord på fly, skip eller offshore.
        </Text>
      </Card.Content>
      <FlipCard.Footer>
        <FlipCard.Trigger variant="back">Lukk</FlipCard.Trigger>
      </FlipCard.Footer>
    </div>
  );
};
