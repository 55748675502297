import { Dispatch, SetStateAction } from "react";
import { Text, Box } from "@telia/teddy";
import { types } from "@telia-no-min-side/utils";
import { ShoppingCard } from "components/shopping-card";
import { getSimplePriceText } from "util/priceFormatter";
import { QuantitySelector } from "components/QuantitySelector";
import { useSmartWifiExtenderRestrictions } from "pages/internet/broadband/hooks/useSmartWifiExtenderRestrictions";

type Props = {
  product: types.fixed.ProductGroupSingleProductWithShipping;
  quantity: number;
  maxQuantity?: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  shouldHideQuantitySelector?: boolean;
};

export function SmartWifiExtender(props: Props) {
  const { extenderRestrictions, hasMaxNumberOfExtenderRestriction, maxAllowedExtendersToOrder, isLoading } =
    useSmartWifiExtenderRestrictions();

  const { quantity, setQuantity, product, maxQuantity, shouldHideQuantitySelector } = props;
  const { currentPrice, postCampaignPrice, campaignDuration } = product.price;
  const isCampaign = postCampaignPrice.recurring > currentPrice.recurring;

  return (
    <ShoppingCard
      title={product?.name || "Smart Wifi Extender"}
      additionalTagText={
        <>
          <Text>
            <strong>Sikrer optimal dekning i alle rom!</strong> <br /> Dette er anbefalt for deg som har en større bolig
            eller krevende dekningsforhold, f.eks. flere etasjer eller murvegger.
          </Text>
          {typeof maxQuantity !== "number" && hasMaxNumberOfExtenderRestriction && (
            <Text>Krever at du har vår Smart Wifi Router. Maks {extenderRestrictions?.max} stk per husholdning.</Text>
          )}
        </>
      }
      image={product?.image}
      price={getSimplePriceText(currentPrice.recurring, currentPrice.chargePeriod) || "Finner ikke pris."}
      postCampaignPrice={isCampaign ? getSimplePriceText(postCampaignPrice.recurring) : undefined}
      campaignText={campaignDuration}
      additionalPriceText="per stk"
      isSelected={quantity > 0}
      selectedBadgeText="Valgt"
    >
      {!shouldHideQuantitySelector && (
        <ShoppingCard.Footer>
          <Box mt="200">
            <QuantitySelector
              label="Velg antall Telia Smart Wifi Extenders"
              maxValue={maxQuantity ?? maxAllowedExtendersToOrder}
              minValue={0}
              quantity={quantity}
              setQuantity={setQuantity}
              isLoading={isLoading}
            />
          </Box>
        </ShoppingCard.Footer>
      )}
    </ShoppingCard>
  );
}
