import React, { ReactNode, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { ToastPortal } from "./Portal";
import { track } from "@telia-no-min-side/utils";

type ToastProviderProps = {
  children: ReactNode;
};
// Error type should always start with MS with MOBILE/TVBB/COMMON seperated by _ with specific error type
// Example: MS_TVBB_POSTPONE_INVOICE_ERROR
type AppSection = "TVBB" | "MOBILE" | "COMMON";
type ErrorType = `MS_${AppSection}_${string}`;

export type Toast = {
  id: string;
  text: string;
  animateOutAndRemove?: boolean;
  variant?: "success" | "error" | "warning" | "loading";
  dataTrackingId?: string;
  eventErrorType?: ErrorType;
};

export type Toasts = Toast[];

type Context = {
  addToast: (
    args: Pick<Toast, "text" | "variant" | "dataTrackingId" | "eventErrorType">
  ) => Pick<Toast, "id"> & { onRemove: Promise<string> };
  updateToast: (id: Toast["id"], args: Partial<Pick<Toast, "text" | "variant" | "animateOutAndRemove">>) => void;
  removeToast: (id: Toast["id"]) => void;
};

const ToasterContext = React.createContext<Context | undefined>(undefined);

export function ToastProvider(props: ToastProviderProps): JSX.Element {
  const [toasts, setToasts] = useState<Toasts>([]);
  const toastResolversRef = useRef(new Map<string, () => void>());
  const { pushTrackingEvent } = track.useEventTracking();

  function addToast(args: Pick<Toast, "text" | "variant" | "eventErrorType">) {
    const id = uuid();
    setToasts((toasts) => [{ id, ...args }, ...toasts]);
    // Google analytics for error event
    if (args.variant === "error") {
      pushTrackingEvent({
        event: "error_web",
        affiliation: "Telia Min Side",
        error_type: args.eventErrorType || "MS_UNKNOWN_ERROR",
        error_message: args.text,
      });
    }
    return {
      id,
      onRemove: new Promise<string>((resolve) => {
        toastResolversRef.current.set(id, () => {
          resolve(id);
        });
      }),
    };
  }

  function updateToast(id: Toast["id"], args: Partial<Pick<Toast, "text" | "variant" | "animateOutAndRemove">>) {
    setToasts((toasts) =>
      toasts.map((toast) => {
        if (toast.id === id) {
          return { ...toast, ...args };
        }
        return toast;
      })
    );
  }

  function removeToast(id: Toast["id"]) {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
    const resolver = toastResolversRef.current.get(id);
    if (resolver) {
      resolver();
      toastResolversRef.current.delete(id);
    }
  }
  return (
    <ToasterContext.Provider value={{ addToast, removeToast, updateToast }}>
      <ToastPortal toasts={toasts} />
      {props.children}
    </ToasterContext.Provider>
  );
}

export function useToast(): Context {
  const context = React.useContext(ToasterContext);

  if (!context) {
    throw Error("No toast context found! This usually happens when you try to access a context outside of a provider");
  }
  return context;
}
