import React, { ComponentProps } from "react";
import { colors } from "@telia/styleguide";
import { HorizontalScroll } from "../HorizontalScroll";
import { ToggleButtonGroup } from "../ToggleButtonGroup";
import { Container } from "../Container";
import { ActionCard } from "./style";
import { Icon } from "../Icon";
import type { Profile } from "./types";

type ProfilePickerProps<T> = {
  loading?: boolean;
  profiles: Profile<T>[];
  selectedProfile?: T;
  onChange?: (value: T) => void;
  children?: React.ReactNode;
  showRetry?: boolean;
  onRetry?: () => void;
  noProfilesPlaceholder?: React.ReactNode;
  variant?: ComponentProps<typeof ToggleButtonGroup.Item<T>>["variant"];
  dataTrackingId?: string;
  containerProps?: ComponentProps<typeof Container>;
};

export function ProfilePicker<T>({
  loading,
  profiles,
  selectedProfile,
  onChange,
  children,
  showRetry,
  onRetry,
  noProfilesPlaceholder,
  variant = "profile-picker",
  dataTrackingId,
  containerProps = {},
}: ProfilePickerProps<T>) {
  if (loading) {
    return (
      <HorizontalScroll addContainerScrollMargin>
        <Container
          gap="lg"
          maxWidth="full"
          data-tracking-id={dataTrackingId ? `${dataTrackingId}-loading` : "profile-picker-loading"}
          {...containerProps}
        >
          {[...Array(2)].map((_, i) => (
            <ToggleButtonGroup.Item.Skeleton variant={variant} key={`toggle-button-skeleton-${i}`} />
          ))}
        </Container>
      </HorizontalScroll>
    );
  }

  if (!profiles?.length && noProfilesPlaceholder && !showRetry) {
    return noProfilesPlaceholder;
  }

  return (
    <HorizontalScroll addContainerScrollMargin>
      {({ scrollBy }) => (
        <Container gap="lg" maxWidth="full" data-tracking-id={dataTrackingId || "profile-picker"} {...containerProps}>
          <ToggleButtonGroup
            variant={variant}
            value={selectedProfile}
            onChange={(_, value) => value && onChange && onChange(value)}
          >
            {(profiles || []).map((profile) => {
              const title = typeof profile.title === "string" ? profile.title.toLowerCase() : "";
              const dataTrackingID = `profile-picker-profileId${title ? "-" + title : ""}-${profile.id}`;

              return (
                <ToggleButtonGroup.Item
                  scrollBy={scrollBy}
                  fadeFromSkeleton={false}
                  id={profile.id}
                  dataTrackingID={dataTrackingID}
                  key={`profile-${profile.key || profile.id}`}
                  value={profile.value}
                  text={profile.title}
                  secondaryText={profile.subtitle}
                  onClick={profile.onClick}
                />
              );
            })}
          </ToggleButtonGroup>

          {showRetry ? (
            <ActionCard
              tag="button"
              aria-label="En feil oppsto. Prøv igjen."
              onClick={onRetry}
              color={colors.red500}
              dataTrackingID="profile-picker-try-again-button"
            >
              <Icon size="sm" icon="refresh" color={colors.red500} />
              <span>Prøv igjen</span>
            </ActionCard>
          ) : null}

          {children}
        </Container>
      )}
    </HorizontalScroll>
  );
}

ProfilePicker.ActionCard = ActionCard;
