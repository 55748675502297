import { types, uri } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { useFetchSelectedAccount } from "./useFetchSelectedAccount";
import { GeneralSWRResponse, GetCustomerSpecificUrlArgs, ReturnData, getCustomerSpecificUrl } from "./utils";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

const url = uri.purchase(ICX_REQUEST.URL_TELIA_PLAY_BOX_INFO);

export function useFetchTeliaBoxPilot<T extends GetCustomerSpecificUrlArgs | undefined = undefined>(
  args?: T
): GeneralSWRResponse<T, types.fixed.TeliaBoxPilot> {
  const account = useFetchSelectedAccount();
  const customerId = args?.customerId || account.data?.customerId.toString();
  const { error, ...rest } = useAxiosSWR<ReturnData<T, types.fixed.TeliaBoxPilot>>(
    getCustomerSpecificUrl(url, args?.fetchAllAccounts ? args : { ...args, customerId, requireCustomerId: true })
  );

  return {
    isError: !!error,
    error,
    ...rest,
  };
}
