import { getMinSideUserToken } from "@telia-no-min-side/components";

function getAppHeader(getnoSession, ciamToken) {
  const getnoSessionExists = getnoSession && getnoSession !== "";
  const ciamTokemExists = ciamToken && ciamToken !== "";
  if (getnoSessionExists && ciamTokemExists) {
    return { "getno-session": getnoSession, "identity-token": ciamToken };
  }
  if (getnoSessionExists && !ciamTokemExists) {
    return { "getno-session": getnoSession };
  }
  if (!getnoSessionExists && ciamTokemExists) {
    return { "identity-token": ciamToken };
  }
  return;
}

function handleUnauthorized(response, reject, url) {
  const loginUri = response.headers.get("x-login-uri");

  if (loginUri) {
    window.location =
      loginUri +
      "&returnPage=" +
      encodeURIComponent(window.location.toString());
  } else {
    reject("Unauthorized request " + url);
  }
}

function handleDefaultResponse(url, method, resolve, reject) {
  return (response) => {
    const contentType = response.headers.get("content-type");

    if (response.status === 401 || response.status === 403) {
      handleUnauthorized(response, reject, url);
    } else if ([404, 409, 500, 504].includes(response.status)) {
      reject(response.status);
    } else if ([204, 205, 304].includes(response.status)) {
      resolve();
    } else if (contentType && contentType.includes("json")) {
      response.json().then(resolve, reject);
    } else if (response.ok) {
      response.text().then(resolve, reject);
    } else {
      reject("Could not " + method + " " + url);
    }
  };
}

export default {
  get: (url, options = {}) =>
    new Promise((resolve, reject) => {
      const appToken = document.querySelector("#app")
        ? document.querySelector("#app").getAttribute("app-token")
        : "";

      const appHeader = getAppHeader(appToken, getMinSideUserToken());

      const opts = appHeader
        ? {
            ...options,
            headers: appHeader,
            method: "GET",
            credentials: "same-origin",
          }
        : {
            ...options,
            method: "GET",
            credentials: "same-origin",
          };

      window
        .fetch(url, { ...opts })
        .then(handleDefaultResponse(url, "GET", resolve, reject), (error) => {
          /* eslint-enable */
          reject(error);
        });
    }),
  post: (url, data = {}, options = {}) =>
    new Promise((resolve, reject) => {
      const appToken = document.querySelector("#app").getAttribute("app-token");
      const appHeader = getAppHeader(appToken, getMinSideUserToken());

      let defaultHeaders;

      if (appHeader) {
        defaultHeaders = {
          "content-type": "application/json",
          ...appHeader,
        };
      } else {
        defaultHeaders = {
          "content-type": "application/json",
        };
      }

      const defaultOptions = {
        body: JSON.stringify(data),
        headers: defaultHeaders,
      };

      const headers = { ...defaultOptions.headers, ...options.headers };

      const opts = {
        ...defaultOptions,
        ...options,
        method: "POST",
        credentials: "same-origin",
        headers,
      };

      window
        .fetch(url, { ...opts })
        .then(handleDefaultResponse(url, "POST", resolve, reject), (error) => {
          /* eslint-enable */
          reject(error);
        });
    }),
  del: (url, options = {}) =>
    new Promise((resolve, reject) => {
      const appToken = document.querySelector("#app").getAttribute("app-token");
      const appHeader = getAppHeader(appToken, getMinSideUserToken());

      const opts = {
        ...options,
        method: "DELETE",
        credentials: "same-origin",
      };

      if (!appHeader) {
        opts.headers = appHeader;
      }

      window
        .fetch(url, { ...opts })
        .then(
          handleDefaultResponse(url, "DELETE", resolve, reject),
          (error) => {
            /* eslint-enable */
            reject(error);
          }
        );
    }),
};
