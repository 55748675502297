import React from "react";
import { Button, Icon, Modal, TextSpacing, Text } from "@telia/teddy";

type Props = {
  /**
   * User open property when an action is triggered not with the triggerComp,
   * but with a shortuct or form submit.
   * E.g. a form can be submitted with an enter key in any input field.
   */
  open?: boolean;
  onOpenChange?: ((open: boolean) => void) | undefined;
  /**
   * The component that triggers the modal to open.
   * You dont need any onClick handler here, the modal will handle that.
   * ex
   * ```
   * <Button>Trigger</Button>
   * ```
   */
  triggerComp?: React.ReactElement;
  /**
   * The component that will trigger the mutation.
   * This component will be shown in the modal footer.
   * ex
   * ```
   * <Button onClick={() => mutate({...})}>Til BankID</Button>
   * ```
   */
  mutateComp: React.ReactElement;
};

export function BankIdRequiredModal({ open, onOpenChange, triggerComp, mutateComp }: Props) {
  return (
    <Modal open={open} onOpenChange={onOpenChange}>
      {triggerComp ? <Modal.Trigger>{triggerComp}</Modal.Trigger> : null}
      <Modal.Content>
        <Modal.Title data-tracking-id="bankid-modal-header">BankID-verifisering kreves</Modal.Title>
        <Modal.Close slot="floating" aria-label="lukk" />
        <TextSpacing>
          <Modal.Description data-tracking-id="bankid-modal-description-1">
            Av sikkerhetshensyn må du verifisere deg med BankID for å gjennomføre denne handlingen.
          </Modal.Description>
          <Text>Hvis du ikke har BankID kan du få hjelp i våre fysiske butikker eller hos Elkjøp og Power.</Text>
        </TextSpacing>
        <Modal.Group variant="action">
          <Button
            mr={{
              md: "auto",
            }}
            variant="text"
            asChild
          >
            <a
              href="https://www.telia.no/hjelp/butikker-og-forhandlere/"
              data-tracking-id="bankid-modal-find-store-link"
            >
              <Icon name="store">Finn nærmeste butikk</Icon>
            </a>
          </Button>
          <Modal.Close slot="content">
            <Button variant="secondary" data-tracking-id="bankid-modal-cancel-button">
              Avbryt
            </Button>
          </Modal.Close>
          {mutateComp}
        </Modal.Group>
      </Modal.Content>
    </Modal>
  );
}
