import { Grid, Skeleton } from "@telia/teddy";
import { Roaming } from "./components/Roaming/Roaming";
import { useMobileDashboardContext } from "../../context/MobileDashboardContext";
import { DataUsageCardError, ActiveDataBoost, LimitedDataUsageCard, UnlimitedDataUsageCard } from "./components";

export function DataUsageCard() {
  const { isError, refetch, isLoading, subscription, foreignDataUsage, isTeliaXSubscription, activeDataBoostProduct } =
    useMobileDashboardContext();

  if (isLoading) return <Skeleton height="440px" width="100%" maxWidth="350px" />;
  if (isError) return <DataUsageCardError refetch={refetch} />;
  if (!subscription) return null;

  return (
    <Grid columns={{ xs: "1", md: "2" }} gap="gutter-xl" align="start" justify={{ sm: "stretch", md: "start" }}>
      {activeDataBoostProduct ? (
        <ActiveDataBoost />
      ) : isTeliaXSubscription ? (
        <UnlimitedDataUsageCard />
      ) : (
        <LimitedDataUsageCard />
      )}

      {!!foreignDataUsage?.length && foreignDataUsage?.map((usage, index) => <Roaming key={index} usage={usage} />)}
    </Grid>
  );
}
