import { BaseSyntheticEvent, createContext, useContext, useEffect, useState } from "react";
import { SelfRecoveryPageWrapper } from "@telia-no-min-side/components";
import { track, uri } from "@telia-no-min-side/utils";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountNotFound } from "./AccountNotFound";
import { ChooseRecoveryMethod } from "./ChooseRecoveryMethod";
import { ErrorPage } from "./Error";
import { LandingPage } from "./LandingPage";
import { SelfRecoveryInputForm } from "./SelfRecoveryInputForm";
import { Success } from "./Success";
import { InfoModal } from "./info-modal";
import { LabelWrapper } from "./style";

export type RecoveryPageState =
  | "init"
  | "chooseRecoveryMethod"
  | "invoiceNumber"
  | "customerNumber"
  | "serialNumber"
  | "bankId"
  | "accountNotFound"
  | "error"
  | "success";

type SelfRecoveryStateContext = {
  recoveryPage: RecoveryPageState;
  setRecoveryPage: (page: RecoveryPageState) => void;
  recoveryPageHistory: RecoveryPageState[];
};

const initialSelfRecoveryStateValue: SelfRecoveryStateContext = {
  recoveryPage: "init",
  recoveryPageHistory: ["init"],
  setRecoveryPage: () => {
    return;
  },
};

const SelfRecoveryState = createContext<SelfRecoveryStateContext>(initialSelfRecoveryStateValue);

export function useSelfRecoveryState(): SelfRecoveryStateContext {
  const context = useContext<SelfRecoveryStateContext>(SelfRecoveryState);

  if (!context) {
    throw Error(
      "No SelfRecoveryState context found! This usually happens when you try to access a context outside of a provider"
    );
  }
  return context;
}

export function SelfRecovery(): JSX.Element {
  const [recoveryPageHistory, setRecoveryPageHistory] = useState<RecoveryPageState[]>(
    initialSelfRecoveryStateValue.recoveryPageHistory
  );
  const { search } = useLocation();
  const navigate = useNavigate();
  const { pushGenericTrackingEvent } = track.useEventTracking();

  function onGoBackClick(e: BaseSyntheticEvent) {
    const lastElement = recoveryPageHistory[recoveryPageHistory.length - 1];
    if (lastElement === "success") {
      navigate(uri.minSideFixed("/tv"));
      return e.preventDefault();
    }

    if (recoveryPageHistory.length <= 1) return;
    e.preventDefault();
    setRecoveryPageHistory((prevState) => prevState.slice(0, -1));
  }

  function setRecoveryPage(page: RecoveryPageState) {
    setRecoveryPageHistory((prevState) => [...prevState, page]);
  }

  const recoveryPage = recoveryPageHistory[recoveryPageHistory.length - 1];

  useEffect(() => {
    const searchValues = queryString.parse(search);
    if (searchValues.success === "false") {
      pushGenericTrackingEvent({
        ui_item_action: "view",
        ui_item_context: "self-recovery",
        ui_item_text: "Vi fant dessverre ikke informasjon som samsvarte med dine opplysninger.",
        ui_item_type: "message",
        ui_item_url: "minside/kontogjenoppretting?success=false",
      });
      setRecoveryPage("accountNotFound");
    }
    if (searchValues.success === "true") {
      pushGenericTrackingEvent({
        ui_item_action: "view",
        ui_item_context: "self-recovery",
        ui_item_text:
          "Suksess, Produktene dine vil snart bli synlig i appen og på Min Side. Det vil ta ca. 5 minutter.",
        ui_item_type: "message",
        ui_item_url: "minside/kontogjenoppretting?success=true",
      });
      setRecoveryPage("success");
    }
  }, [search]);

  return (
    <SelfRecoveryState.Provider
      value={{
        recoveryPageHistory,
        recoveryPage,
        setRecoveryPage,
      }}
    >
      <SelfRecoveryPageWrapper onGoBackClick={onGoBackClick} title="Hent dine TV/internett-produkter">
        {recoveryPage === "init" && <LandingPage />}
        {recoveryPage === "error" && <ErrorPage />}
        {recoveryPage === "chooseRecoveryMethod" && <ChooseRecoveryMethod />}
        {recoveryPage === "invoiceNumber" && (
          <SelfRecoveryInputForm
            label={
              <LabelWrapper>
                Fakturanummer <InfoModal content={"invoiceNumber"} />
              </LabelWrapper>
            }
            methodId="invoiceNumber"
            placeholder="1234567890"
            type="number"
          />
        )}
        {recoveryPage === "customerNumber" && (
          <SelfRecoveryInputForm label="Kundenummer" methodId="customerNumber" type="number" />
        )}
        {recoveryPage === "serialNumber" && (
          <SelfRecoveryInputForm
            label={
              <LabelWrapper>
                Serienummer på utstyr <InfoModal content={"serialNumber"} />
              </LabelWrapper>
            }
            methodId="serialNumber"
            placeholder="S/N 1234567890"
          />
        )}
        {recoveryPage === "accountNotFound" && <AccountNotFound />}
        {recoveryPage === "success" && <Success />}
      </SelfRecoveryPageWrapper>
    </SelfRecoveryState.Provider>
  );
}
