import { useEffect } from "react";
import { types } from "@telia-no-min-side/utils";
import { colors } from "@telia/styleguide";
import dayjs from "dayjs";
import { getPriceAmountText } from "../../../../util/priceFormatter";
import {
  DateCell,
  DetailsAccordionItem,
  Dot,
  InvoiceProduct,
  InvoiceProductList,
  PriceCell,
  StyledAccordionBody,
  StyledAccordionHeader,
  SumCell,
  SumTextCell,
  TitleCell,
  TotalSumGrid,
} from "./style";

export type Props = {
  invoiceDetail: types.fixed.InvoiceDetailCategory | undefined;
  category: string;
  isOpen: boolean | undefined;
  toggleIsOpen: (forceValue?: boolean) => void;
};

export function getProductTypColor(amount: number): string {
  if (amount < 0) {
    return colors.green200;
  }

  return colors.corePurple300;
}

export function getDatePeriod(product: types.fixed.InvoiceDetail): string {
  const { periodFrom, periodTo } = product;
  const formatString = "DD.MM.YY";
  const dateFormatter = (date: number) => dayjs.unix(date).format(formatString);
  const datePeriod = (from: number, to: number) => `${dateFormatter(from)}-${dateFormatter(to)}`;

  if (periodFrom <= 0 && periodTo <= 0) return "";
  if (periodFrom <= 0 && periodTo > 0) {
    return datePeriod(periodTo, periodTo);
  }
  if (periodFrom > 0 && periodTo <= 0) {
    return datePeriod(periodFrom, periodFrom);
  }
  return datePeriod(periodFrom, periodTo);
}

export function DetailsElement(props: Props): JSX.Element | null {
  const { invoiceDetail, category, isOpen, toggleIsOpen } = props;

  useEffect(() => {
    const hasInvoiceDetails = invoiceDetail?.details?.length;
    if (isOpen === undefined && hasInvoiceDetails) {
      toggleIsOpen(false);
    }
  }, [invoiceDetail?.details]);

  if (!invoiceDetail?.details?.length) {
    return null;
  }

  return (
    <DetailsAccordionItem hideBorder={true} showShadow={false} onClick={() => toggleIsOpen()} isOpen={isOpen}>
      <StyledAccordionHeader>
        <TitleCell as="h3" data-di-mask>
          {category}
        </TitleCell>
        {!isOpen && <PriceCell data-di-mask>{getPriceAmountText(invoiceDetail.sum, "kr")}</PriceCell>}
      </StyledAccordionHeader>
      <StyledAccordionBody>
        <InvoiceProductList>
          {invoiceDetail.details.map((detail) => (
            <InvoiceProduct key={detail.id}>
              <TitleCell>
                <Dot color={getProductTypColor(detail.amount)} data-di-mask />
                {detail.name}
              </TitleCell>
              <DateCell data-di-mask>{getDatePeriod(detail)}</DateCell>
              <PriceCell data-di-mask>{getPriceAmountText(detail.amount, "kr")}</PriceCell>
            </InvoiceProduct>
          ))}
          <TotalSumGrid>
            <SumTextCell>Delsum</SumTextCell>
            <SumCell data-di-mask>{getPriceAmountText(invoiceDetail.sum, "kr")}</SumCell>
          </TotalSumGrid>
        </InvoiceProductList>
      </StyledAccordionBody>
    </DetailsAccordionItem>
  );
}
