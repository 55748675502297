import styled from "styled-components";
import { remCalc } from "@telia-no-min-side/components";
import { Lozenge } from "@telia/styleguide";

export function MobileSubscriptionInfo() {
  return (
    <StyledContainer>
      <p>
        Dette abonnementet viderekobler fastnummeret ditt til mobilnummeret ditt, slik at du mottar alle anrop på
        mobilen din.
      </p>
      <p>Dersom du har spørsmål til dette abonnementet, vennligst kontakt kundeservice:</p>
      <StylePhoneContainer label="924 05 050" type="square" icon="phone" />
    </StyledContainer>
  );
}

export const StyledContainer = styled.div`
  width: 100%;
  max-width: ${remCalc(569)};
  margin-top: ${remCalc(-10)};
`;

export const StylePhoneContainer = styled(Lozenge)`
  font-size: ${remCalc(14)};
  column-gap: ${remCalc(5)};
`;
