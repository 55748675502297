enum EVENT {
  CUSTOMER_NO_PROD = "ms_nrtc_customer_no_prod_displayed",
}

enum AFFILIATION {
  TELIA_MIN_SIDE = "Telia Min Side",
}

enum ITEM_TEXT {
  HOME = "Hjem",
  SUCCESS = "Suksess",
  PAY_NOW = "Betal nå",
  NOT_MBB_CUSTOMER = "Ikke mobilt bredbånd-kunde ennå?",
  NOT_TV_CUSTOMER = "Ikke TV-kunde ennå?",
  NOT_MOBILE_CUSTOMER = "Ikke mobilkunde ennå?",
  NOT_INERNET_CUSTOMER = "Ikke Internett-kunde ennå?",
  CONFIRM_ORDER = "Bekreft bestilling",
  CONFIRM_PURCHASE = "Bekreft kjøp",
  TRY_AGAIN = `Prøv igjen`,
  TRY_AGAIN_ERROR = `Prøv igjen (Error)`,
  GO_TO_HOME_ERROR = `Gå til Hjem (Error)`,
  GO_TO_HOME_INVOICE = "Gå til Hjem (utestående faktura)",
  GO_TO_MINSIDE_TV = "Gå til Minside TV",
  FURTHER = "Videre",
  ORDER = "Bestill",
  ORDER_TELIA_PLAY = "Bestill Telia Play",
  CHANGE_PACKAGE = "Endre pakke",
  SELECT_THIS_COMBINATION = "Velg denne kombinasjonen",
  BANKID_LOGIN = "Logg inn med BankID",
  BANK_ID_VERIFY = "Verifiser deg med BankID",
  BECOME_MOBILE_CUSTOMER = "Bli mobil-kunde",
  PAY_INVOICE = "Betal faktura",
  BACK_TO_OVERVIEW = "Tilbake til oversikten",
  CANCEL = "Avbryt",
  ADD_ANNUAL_DISCOUNT = "Legg til årsrabatt",
  EXTEND_ANNUAL_DISCOUNT = "Forleng din årsrabatt",
  CHANGE_FLEX_PRODUCT = "Hvordan endrer jeg på flex produktet",
  RETURN_TV_BOX_NOW = "Returner TV-boks nå",
  KEEP_TV_BOX_30_DAYS = "Behold TV-boks i 30 dager",
  RECORDING = "Opptak",
  NO_BOX = "Uten boks",
  TELIA_PLAY_BOX = "Telia Play Box",
  TELIA_WIFI_ROUTER = "TeliaWifiRouter",
  SMART_WIFI_ROUTER = "SmartWifiRouter",
  DONT_NEED_NEW_EQUIPMENT = "Jeg trenger ikke nytt utstyr",
  RELOADING = "Laster inn på nytt",
  ACTIVATE_DATA_BOOST = "Aktiver DataBoost",
  ACTIVATE_OFFER = "Aktiver tilbud",
  UPGRADE_ROUTER = "Oppgrader din router",
  BUY_NEW_SUBSCRIPTION = "Kjøp familieabonnement/Nytt abonnement",
  SELECT = "Velg",
  SELECT_DISABLED = "Velg (disabled)",
  BLOCK_MY_SIM = "Sperr mine SIM",
  UNBLOCK = "Opphev sperring",
  CONTACT_INFO_CHANGE_ERROR = "error på endre kontaktinformasjon",
  CONTACT_INFO_CHANGE_SUCCESS = "Vellykket endring av kontaktinformasjon",
  CONTACT_INFO_CHANGE = "Endre kontaktinformasjon",
  FILL_IN_INFO = "Fyll inn din informasjon",
  REPORT_MOVE = "Meld flytting",
  RESEND_CODE = "Send kode på nytt",
  ENTER_MOBILE_NUMBER = "Fyll inn ditt mobilnummer",
  UNKNOWN_NUMBER = "Ukjent nummer. Vennligst kontakt kundeservice.",
  ENTER_OTP = "Skriv inn engangskoden du fikk tilsendt",
  SOMETHING_WRONG_TRY_AGAIN = "Noe gikk galt. Vennligst prøv igjen.",
  VERIFY_YOURSELF = "Verifiser deg",
  CALL_CUSTOMER_SERVICE = "Ring +47 924 05 050",
  POSTPONE_INVOICE = "Utsett betalingen",
}

enum ITEM_ACTION {
  NEW = "new",
  VIEW = "view",
  SHOW = "show",
  INFO = "info",
  ORDER = "order",
  CANCEL = "cancel",
  REFRESH = "refresh",
  SELECT = "select",
  SUBMIT_ERROR = "sumbit-error",
  SUBMIT_SUCCESS = "sumbit-success",
  SELECT_ITEM = "select_item",
  BUTTON_CLICK = "button_click",
  BUTTON_CLICK_ON_ERROR = "button_click on error",
  BUTTON_CLICK_ON_CANCEL = "button_click on cancel",
  BUTTON_CLICK_ON_SUCCESS = "button_click on success",
  NEXT_CLICK = "next_click",
  LINK_CLICK = "link_click",
  ORDER_WITH_REQUIREMENTS = "order with requirements",
  GO_TO_NEXT_REQUIREMENT = "go to next requirementSelect",
  UPGRADE_DOWNGRADE_SUMMARY_DISABLED = "upgrade-downgrade-summary disabled-click",
  BANKID_LOGIN = "bank_id_login",
  PHONE_NUMBER_SUBMIT = "phone_number_submit",
  OTP_SUBMIT = "otp_submit",
  EXTENSION = "extension",
  LINK_CLICK_BACK_TO_HOME = "link_click back to home",
}

enum ITEM_CONTEXT {
  PROFILE = "Profil",
  NO_PRODUCT_ACTIVE = "no_product_active",
  ROUTER_EXTENDER = "Router/extender",
  ORDER_TV = "order-tv",
  FLEX = "flex",
  HOME = "hjem",
  NO_MOBILE_SUB = "NoSubMobile",
  BUY_FWA_SUB = "buy-tv-subscription-fwa",
  FWA_COMMITMENT = "commitment-fwa",
  DASHBOARD_SUMMER_CAMPAIGN = "dashboard-summer-campaign",
  PAY_INVOICE = "pay invoice",
  MOBILE_SUB = "mobile-sub",
  BUY_NEW_SUBSCRIPTION = "buy-new-subscription",
  B2B2C_FAMILY_DISCOUNT = "b2b2c-family-discount",
  ANNUAL_DISCOUNT_FOR_COMMITMENT = "annual-discount-for-commitment",
  CHANGE_MBB = "change-mbb-subscription-page",
  CHANGE_SUBSCRIPTION = "change-subscription-page",
  UPGRADE_DOWNGRADE_SUMMARY = "upgrade-downgrade-summary",
  SIM_PIN_PUK = "min-side/sim-pin-puk",
  UNBLOCK_ROAMING_TOPUPS = "Unblock roaming topups",
  INSPECTION_REPORT = "Innsynsrapport",
  SELF_RECOVERY = "self-recovery",
  TELIA_PLAY_BOX_PILOT = "telia-play-box-pilot",
  TV_BOX_BUY_ERROR = "Error ved kjøp TV-Boks",
  TV_BOX_CONFIRM_PURCHASE = "Bekreft kjøp TV-Boks",
  ORDER_RECORDING = "order-recording",
  POSTPONE_INVOICE_MODAL = "utsett_betaling_i_30_dager_modal",
}
enum ITEM_CONTEXT_SWTICH {
  SWITCH = "switch",
  PROGRESS_BAR = "progress-bar",
  NO_SUB = "switch-no-sub",
  SELF_ASSESSMENT = "switch-self-assessment",
  FILE_INSURANCE_CLAIM = "switch-file-insurance-claim",
  CANCEL_PLUS_INSURANCE = "switch-cancel-plus-insurance",
  CANCEL_SCREEN_INSURANCE = "switch-cancel-screen-insurance",
}

enum ITEM_TYPE {
  SUBMIT = "submit",
  INFO_MSG = "info_msg",
  BUTTON = "button",
  LINK = "link",
  TELIA_SAMLET = "banner_telia_samlet",
  SELECT = "select",
  MESSAGE = "message",
  UI_ITEM = "ui_item_type",
  BANNER_SMART_WIFI = "banner_smart_wifi",
  BANNER_FWA_ANTICHURN = "banner_fwa_antichurn",
  BANNER_TV_OVER_FWA = "banner_tv_over_fwa",
  PHONE_NUMBER_LINK = "phoneNumber-link",
  FIXED_INVOICE_POSTPONE_ERROR = "MS_TVBB_POSTPONE_INVOICE_ERROR",
  MOBILE_INVOICE_POSTPONE_ERROR = "MS_MOBILE_POSTPONE_INVOICE_ERROR",
  BANNER_COMPENSATION_EXTRA_POINTS = "banner_compensation_extra_points",
}

enum ITEM_URL {
  NO_URL = "no url",
  FORBRUK = "https://www.telia.no/min-side/forbruk",
  MBB = "https://www.telia.no/min-side/mobilt-bredband",
  INTERNET = "https://www.telia.no/minside/internett/utstyr",
  IDENTITY = "https://identity.telia.no",
  TV_CONTENT = "https://www.telia.no/minside/tv/tv-innhold",
}

export const TRACK_EVENT = {
  EVENT,
  AFFILIATION,
  ITEM_TEXT,
  ITEM_ACTION,
  ITEM_CONTEXT,
  ITEM_TYPE,
  ITEM_URL,
  ITEM_CONTEXT_SWTICH,
};
