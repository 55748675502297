import { MobileSubscriptionAccordionItem } from "components/mobile-subscription-accordion-item";
import { Maybe, AdditionalProperty, Disclaimer, OfferingPriceDetails } from "gql/graphql";
import { ComponentProps } from "react";
import { isMbbBucketOffer } from "./isMbbBucketOffer";
import { isMbbUnlimitedDataOffer } from "./isMbbUnlimitedDataOffer";
import { findAmount } from "util/format-subscription/findAmount";
import { findReducedSpeedDownload } from "util/format-subscription/findReducedSpeedDownload";
import { isMbbSleepModeOffer } from "./isMbbSleepModeOffer";

// Unlike mobile included properties, MBB included properties do not come from additionalProperties
// Here MBB included properties are conjured from multiple places.
// Or if certain properties do not exit - hardcoded.

type IncludedProperties = ComponentProps<typeof MobileSubscriptionAccordionItem>["includedProperties"];
type IncludedProperty = IncludedProperties[0];

const dataRolloverProperty: IncludedProperty = {
  code: "DATA_ROLLOVER",
  icon: "sync",
  text: "Data Rollover",
};

// You can pause your MBB subscription by
// switching to a special MBB subscription type
// Hvilemodus (sleep mode)
const sleepModeProperty: IncludedProperty = {
  code: "SLEEP_MODE",
  icon: "coffee",
  text: (
    <>
      <strong>Hvilemodus</strong>, reduser abonnementet i perioder du bruker lite data.
    </>
  ),
};

// This data does not exist in backend response
// The property is applicable only for bucket subscriptions
const dataBoostProperty: IncludedProperty = {
  code: "DATA_BOOST",
  icon: "getting-started",
  text: "Data Boost! Få ubegrenset data i 12 timer til kun 99,-",
};

const getDataAndSpeedProperty = (
  downloadSpeed: number | undefined,
  offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined
): IncludedProperty | undefined => {
  if (!downloadSpeed || !offeringPrices) return undefined;

  const displayDownloadSpeed = `${downloadSpeed} Mbps`;
  const dataProp = offeringPrices?.find(findAmount);
  const data = `${dataProp?.amount} ${dataProp?.monetaryUnit}`;
  const reducedDownloadSpeedProp = offeringPrices?.find(findReducedSpeedDownload);
  const reducedDownloadSpeed = `${reducedDownloadSpeedProp?.amount} ${
    reducedDownloadSpeedProp?.monetaryUnit === "Mbps" ? "Mbps" : "Kbps"
  }`;

  return {
    code: "SPEED_DATA_BENEFIT",
    icon: "speedometer",
    text: (
      <>
        {displayDownloadSpeed} - helt opptil {data}, deretter <strong>{reducedDownloadSpeed}</strong>
      </>
    ),
  };
};

function getUnlimitedDataProperties(
  hasDataRollover: boolean,
  dataAndSpeedProperty: IncludedProperty | undefined
): ComponentProps<typeof MobileSubscriptionAccordionItem>["includedProperties"] {
  const properties: IncludedProperties = [];

  if (dataAndSpeedProperty) {
    properties.push(dataAndSpeedProperty);
  }

  if (hasDataRollover) {
    properties.push(dataRolloverProperty);
  }

  properties.push(sleepModeProperty);

  return properties;
}

function getBucketProperties(
  hasDataRollover: boolean,
  dataAndSpeedProperty: IncludedProperty | undefined
): ComponentProps<typeof MobileSubscriptionAccordionItem>["includedProperties"] {
  const properties: IncludedProperties = [];

  if (dataAndSpeedProperty) {
    properties.push(dataAndSpeedProperty);
  }

  if (hasDataRollover) {
    properties.push(dataRolloverProperty);
  }

  properties.push(dataBoostProperty);

  properties.push(sleepModeProperty);

  return properties;
}

export function getHvilemodusProperties(
  disclaimers: Maybe<Maybe<Disclaimer>[]> | undefined
): ComponentProps<typeof MobileSubscriptionAccordionItem>["includedProperties"] {
  const properties: IncludedProperties = [];

  properties.push(sleepModeProperty);

  const sortedDisclaimers = (disclaimers || []).slice().sort((disclaimerA, disclaimerB) => {
    if (disclaimerA?.weight && disclaimerB?.weight) {
      return disclaimerA.weight - disclaimerB.weight;
    } else return 0;
  });

  const combinedDisclaimers = (sortedDisclaimers || [])
    .map((entry) => entry?.text)
    .filter((entry) => !!entry)
    .join(" ");

  if (combinedDisclaimers) {
    properties.push({
      code: "DISCLAIMERS",
      icon: "speedometer",
      text: combinedDisclaimers,
    });
  }

  return properties;
}

export function getIncludedPropertiesMbb(
  shortName: Maybe<string> | undefined,
  downloadSpeed: number | undefined,
  additionalProperties: Maybe<Maybe<AdditionalProperty>[]> | undefined,
  disclaimers: Maybe<Maybe<Disclaimer>[]> | undefined,
  offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined
): ComponentProps<typeof MobileSubscriptionAccordionItem>["includedProperties"] {
  const hasDataRollover = !!additionalProperties?.some(
    (additionalProperty) => additionalProperty?.name === "DATA_ROLLOVER" && additionalProperty?.value === "true"
  );

  if (isMbbSleepModeOffer(shortName)) {
    return getHvilemodusProperties(disclaimers);
  }

  const dataAndSpeedProperty = getDataAndSpeedProperty(downloadSpeed, offeringPrices);

  if (isMbbBucketOffer(offeringPrices, shortName)) {
    return getBucketProperties(hasDataRollover, dataAndSpeedProperty);
  }

  if (isMbbUnlimitedDataOffer(offeringPrices, shortName)) {
    return getUnlimitedDataProperties(hasDataRollover, dataAndSpeedProperty);
  }

  return [];
}
