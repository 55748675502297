import { devices, remCalc, focusRing } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";
import styled from "styled-components";

export const ContentSpacing = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const RecoveryMethodButton = styled.button`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${remCalc(28)} 0.5rem;
  box-shadow: 0px 2px 8px #dcdce1;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  color: black;
  cursor: pointer;
  font-weight: 400;

  ${focusRing}
  :hover {
    background-color: ${colors.corePurple100};
  }

  transition: background-color 250ms ease;

  @media ${devices.sm_down} {
    flex: 1;
  }
`;
export const RecoveryMethodButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;

  @media ${devices.sm_down} {
    flex-direction: column;
  }
`;
export const ButtonTextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media ${devices.sm_down} {
    flex-direction: row;
    align-items: center;
  }
`;

export const InfoText = styled.div`
  margin: 0;
  @media ${devices.sm_down} {
    display: none;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
`;

export const LabelWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
`;

export const HideOnDesktop = styled.span`
  @media ${devices.sm_up} {
    display: none;
    visibility: hidden;
  }
`;

export const ProductLine = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  padding-bottom: 0.5rem;
  & > *:last-child {
    text-align: right;
    flex: 1 0 auto;
  }
`;
