import dayjs from "dayjs";
import { Invoice } from "src/gql/graphql";
import { PreviousInvoiceCard } from "../components/PreviousInvoiceCard";
import format from "@telia-no-min-side/utils/src/format";

type PreviousInvoiceListProps = {
  invoices: Invoice[];
  isSubLevel?: boolean;
};

export const PreviousInvoiceList = ({ invoices, isSubLevel }: PreviousInvoiceListProps) => {
  return (
    <>
      {invoices.map((item) => (
        <PreviousInvoiceCard
          invoiceId={item.invoiceNumber ?? ""}
          invoiceDate={dayjs(item.billingDate).format("MMMM YY")}
          paidText={item.paid == null ? "Du betaler ikke denne fakturaen" : item.paid ? "Betalt" : "Ubetalt"}
          amount={item.amount?.value ?? 0}
          paid={item.paid}
          invoicePeriod={
            item.period
              ? `${format.formatDate(item.period.startDate, "DD. MMM")} - ${format.formatDate(
                  item.period.endDate,
                  "DD. MMM"
                )}`
              : undefined
          }
          isMobile
          key={`${item.invoiceNumber}-${item.billingDate}-prev-inv-list`}
          isSubLevel={isSubLevel}
        />
      ))}
    </>
  );
};
