import { Offering, UserOffering } from "src/gql/graphql";

export function createConfirmChangeSubscriptionEvent(
  subToBeRemoved: UserOffering | undefined | null,
  subToBeAdded: Offering | undefined | null,
  orderId: string
) {
  return {
    event: "transaction",
    ecommerce: {
      purchase: {
        actionField: { id: orderId, affiliation: "Telia Min Side", action: "purchase", shipping: 0 },
        products: [
          {
            name: subToBeAdded?.shortName,
            id: subToBeAdded?.pricePlan,
            price: subToBeAdded?.monthlyCost?.amount,
            brand: "Telia",
            category: "Subscription/Telephone/Mobile",
            quantity: 1,
            affiliation: "Telia Min Side",
            dimension105: subToBeRemoved?.pricePlan,
            dimension106: subToBeRemoved?.shortName,
            dimension107: subToBeRemoved?.monthlyCost?.amount,
          },
        ],
      },
    },
  };
}
