import { AdditionalProduct, Maybe } from "gql/graphql";
import { SUMMER_CAMPAIGN_PRODUCT_CODES } from "./variables";

export function isSummerCampaignDoubleDataActivated(additionalProducts: Maybe<AdditionalProduct[]> | undefined) {
  return (additionalProducts || []).some(
    (additionalProduct) =>
      additionalProduct.code &&
      additionalProduct.presentation?.active &&
      SUMMER_CAMPAIGN_PRODUCT_CODES.includes(additionalProduct.code.split("_")[0])
  );
}
