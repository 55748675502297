import { Container, Icon, Paragraph } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import { colors } from "@telia/styleguide";
import { SwitchAgreementFieldsFragment, SwitchInsuranceType } from "src/gql/graphql";
import { capitalizeFirstLetter } from "util/capitalizeFirstLetter";
import { INSURANCE_TYPE } from "util/constants/agreements";

type Props = Pick<SwitchAgreementFieldsFragment, "phoneNumber" | "imei" | "product"> & {
  insuranceType?: SwitchInsuranceType;
};

export function PhoneInfo({ product, phoneNumber, imei, insuranceType }: Props) {
  const isScreenInsurance = insuranceType === SwitchInsuranceType.Screen;
  const insuranceName = capitalizeFirstLetter(isScreenInsurance ? INSURANCE_TYPE.SCREEN : INSURANCE_TYPE.PLUS);

  return (
    <Container flexDirection="row" justifyContent="center" gap="lg">
      {product?.imageUrl && (
        <Container width="unset">
          <img src={`https:${product?.imageUrl}`} alt="product-image" width="70px" />
        </Container>
      )}
      <Container gap="sm" flexDirection="column">
        {product?.name && (
          <Paragraph removeMargin>
            <em>{product?.name}</em>
          </Paragraph>
        )}
        {phoneNumber?.localNumber && (
          <Paragraph removeMargin className="phone-number" data-di-mask isFaded>
            <i>{format.formatPhoneNumber(phoneNumber.localNumber)}</i>
          </Paragraph>
        )}
        {imei && (
          <Paragraph removeMargin data-di-mask>
            {`IMEI ${imei}`}
          </Paragraph>
        )}
        {insuranceType && (
          <Container flexDirection="row">
            <Icon size="sm" icon="check-mark" color={colors.corePurple} />
            <Paragraph>{insuranceName}</Paragraph>
          </Container>
        )}
      </Container>
    </Container>
  );
}
