import { graphql } from "src/gql";

export const GET_ACTIVATION_SIM_DATA = graphql(`
  query getActivationSimData($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      mainSimcard {
        simCardNumber
      }
      additionalSimcards {
        simCardNumber
        product {
          code
          shortName
        }
      }
    }
  }
`);
