import React from "react";
import { LogoLink } from "./style";
import teliaSymbol from "./telia-symbol.png";
import teliaLogo from "./telia-logo.png";

type Props = {
  isCollapsed?: boolean;
};

export function OpenPagesIconLink({ isCollapsed }: Props) {
  const currentURL = window.location.href;

  const logoLinkHref =
    currentURL.includes("localhost") || currentURL.includes("stage") ? "https://stage.telia.no" : "https://telia.no";

  return (
    <LogoLink href={logoLinkHref}>
      <img src={isCollapsed ? teliaSymbol : teliaLogo} alt="Telia logo" height={28} />
    </LogoLink>
  );
}
