import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

export type MutationResponseMessage = {
  isSuccessfulMutation?: boolean;
  title?: string;
  text?: string;
};

export type Context = {
  mutationResponseMessage: MutationResponseMessage;
  setMutationResponseMessage: Dispatch<SetStateAction<MutationResponseMessage>>;
};

export type Props = {
  children: ReactNode;
};

export const MutationResponseContext = createContext<Context | null>(null);

export function useMutationResponse(): Context {
  const context = useContext(MutationResponseContext);
  if (!context) {
    throw Error("SuccessfulMutationContext not found");
  }
  return context;
}

export function MutationResponseProvider(props: Props) {
  const [mutationResponseMessage, setMutationResponseMessage] = useState<MutationResponseMessage>({
    isSuccessfulMutation: false,
    title: "",
    text: "",
  });

  return (
    <MutationResponseContext.Provider value={{ mutationResponseMessage, setMutationResponseMessage }}>
      {props.children}
    </MutationResponseContext.Provider>
  );
}
