import dayjs from "dayjs";

export const getRemainingTime = (expireDate: string) => {
  const now = dayjs();
  const expiration = dayjs(expireDate);
  const diff = expiration.diff(now);
  const remaining = dayjs.duration(diff);
  const hours = remaining.hours();
  const minutes = remaining.minutes();
  return `${hours > 0 ? `${hours} timer og ` : ""}${minutes} minutter igjen`;
};
