/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useMutation, useQuery } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { track } from "@telia-no-min-side/utils";
import { BlueConicData, BlueConicItem } from "gql/graphql";
import { graphql } from "src/gql";
import { config } from "util/config";

export const blueconicItemFragment = graphql(`
  fragment BlueConicItemFields on BlueConicItem {
    variant
    tracking {
      promoCreative
      promoId
      promoName
      variantId
    }
    title
    imageUrl
    dismissible
    description
    button {
      icon
      text
      uri
    }
    badge {
      icon
      text
    }
  }
`);

export const blueconicQuery = graphql(`
  query GetBlueConicData {
    blueconic {
      mobile {
        ...BlueConicItemFields
      }
      broadband {
        ...BlueConicItemFields
      }
      tv {
        ...BlueConicItemFields
      }
      highlights {
        ...BlueConicItemFields
      }
      explore {
        ...BlueConicItemFields
      }
    }
  }
`);

export const blueconicMutation = graphql(`
  mutation PostBlueConicEvent($input: BlueConicEventInput!) {
    postBlueConicEvent(input: $input) {
      status
      statusText
    }
  }
`);

export const useBlueConicPromos = () => {
  const {
    data: promos,
    loading: promosLoading,
    error: promosError,
  } = useQuery(blueconicQuery, {
    skip: !config.isNewBlueconicEnabled,
  });

  const [postBlueConicEvent, { loading: eventLoading, error: eventError }] = useMutation(blueconicMutation, {
    onError: (error) => {
      if (window.location.hostname.includes("localhost")) return;
      datadogRum.addError(error, { feature: "blueconic" });
    },
  });

  const { pushEcommercePromoTrackingEvent } = track.useEventTracking();

  const trackBlueConicClick = (position: keyof Omit<BlueConicData, "__typename">, promo: BlueConicItem) => {
    if (!promo?.tracking?.variantId) return;

    const blueConicClickEvent: Parameters<typeof pushEcommercePromoTrackingEvent>[0] = {
      id: promo.tracking.variantId,
      name: promo.tracking.promoName || "",
      creative: promo.tracking.promoCreative || "",
      destinationUrl: promo.button?.uri || "",
      position,
    };
    pushEcommercePromoTrackingEvent(blueConicClickEvent, "promoClick");
  };

  const trackBlueConicView = (position: keyof Omit<BlueConicData, "__typename">, promo: BlueConicItem) => {
    if (!promo?.tracking?.variantId) return;

    const blueConicViewEvent: Parameters<typeof pushEcommercePromoTrackingEvent>[0] = {
      id: promo?.tracking?.variantId,
      name: promo?.tracking?.promoName || "",
      creative: promo?.tracking?.promoCreative || "",
      destinationUrl: promo?.button?.uri || "",
      position,
    };
    pushEcommercePromoTrackingEvent(blueConicViewEvent, "promoView");
  };

  return {
    promos,
    promosLoading,
    promosError,
    postBlueConicEvent,
    eventLoading,
    eventError,
    trackBlueConicView,
    trackBlueConicClick,
  };
};
