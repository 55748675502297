import { ReactElement, ComponentProps } from "react";
import { Container, Card } from "@telia-no-min-side/components";
import { Fields } from "./utils/getInitialState";
import { EFaktura } from "./components/EInvoice";
import { EmailInvoice } from "./components/EMailInvoice";
import { PaperInvoice } from "./components/PaperInvoice";
import { AccountDetailsQuery, DistributionCode, PaymentMethodName } from "src/gql/graphql";
import { DELIVERY_TYPE } from "util/constants/address";

type InvoiceTypeListProps = {
  account: AccountDetailsQuery["account"];
  onSubmit: (
    formData: Fields,
    emailAddress: string,
    distributionType: DELIVERY_TYPE,
    invoiceType: DistributionCode,
    eInvoiceId?: string
  ) => void;
};

export const InvoiceTypeList = ({ account, onSubmit }: InvoiceTypeListProps) => {
  const invoiceTypes = account.invoiceTypes;
  const selectedDistributionType = account.selectedInvoiceDistributionType;
  const hasAvtalegiro = account?.paymentMethod?.name === PaymentMethodName.Avtalegiro;

  const invoiceTypeEFaktura = invoiceTypes.find((t) => t.code === "EF");
  const invoiceTypeEmail = invoiceTypes.find((t) => t.code === "EE");
  const invoiceTypePaper = invoiceTypes.find((t) => t.code === "RG");

  const invoiceTypesComponentList: ReactElement<
    ComponentProps<typeof PaperInvoice | typeof EFaktura | typeof EmailInvoice>
  >[] = [
    <PaperInvoice key={invoiceTypePaper?.code} invoiceType={invoiceTypePaper} account={account} onSubmit={onSubmit} />,
    <EFaktura
      key={invoiceTypeEFaktura?.code}
      invoiceType={invoiceTypeEFaktura}
      account={account}
      onSubmit={onSubmit}
      hasAvtalegiro={hasAvtalegiro}
    />,
    <EmailInvoice
      key={invoiceTypeEmail?.code}
      account={account}
      invoiceType={invoiceTypeEmail}
      onSubmit={onSubmit}
      hasAvtalegiro={hasAvtalegiro}
    />,
  ];

  const selectedInvoiceDistribution = invoiceTypesComponentList.find(
    (element) => element.props.invoiceType?.invoiceDistribution === selectedDistributionType
  );

  const otherDistributionOptions = invoiceTypesComponentList.filter(
    (element) => element.props.invoiceType?.invoiceDistribution !== selectedDistributionType
  );

  return (
    <Container maxWidth="xs" flexDirection="column" gap>
      <Card lozengeLeft={{ label: "Du har denne fakturatypen" }} padding="unset">
        {selectedInvoiceDistribution}
      </Card>
      {otherDistributionOptions}
    </Container>
  );
};
