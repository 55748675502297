import { Alert, Container, Skeleton } from "@telia-no-min-side/components";
import { LeaseAgreements } from "../lease/LeaseAgreements";
import { InsuranceAgreements } from "../insurance/InsuranceAgreements";
import { useAccountAgreements } from "pages/mobile/mobile-subscription-agreements/context/AccountAgreementsContext";
import { useMobileAccount } from "hooks/useMobileAccount";
import { SwitchAgreements } from "../switch/SwitchAgreements";
import { AgreementError } from "../AgreementError";

export function SeeAllForAccountAgreements() {
  const { isLoading: accountLoading } = useMobileAccount();

  const {
    loading: agreementsLoading,
    data,
    error,
    refetch,
    isRefetching,
    hasNoAgreements,
    hasSwitchAgreements,
    switchAgreements,
    hasLeaseAgreements,
    leaseAgreements,
    hasInsuranceAgreements,
    insuranceAgreements,
  } = useAccountAgreements();

  const isLoading = accountLoading || agreementsLoading;

  if (isLoading || isRefetching) {
    return (
      <Container flexDirection="column" gap="lg">
        <Skeleton variant="rectangular" height="11rem" width="37.5rem" />
      </Container>
    );
  }

  if (error && !data) {
    return <AgreementError refetch={refetch} />;
  }

  if (hasNoAgreements && !isLoading) {
    return (
      <Container maxWidth="sm">
        <Alert
          kind="info"
          isOpen
          icon="info-filled"
          title="Ingen avtaler funnet"
          text="Dette abonnementet har ingen tilknyttede avtaler."
          dataTrackingId="alert-user-without-agreements"
        />
      </Container>
    );
  }

  return (
    <Container flexDirection="column" gap="lg">
      {hasSwitchAgreements && <SwitchAgreements switchAgreements={switchAgreements} />}
      {hasLeaseAgreements && <LeaseAgreements leaseAgreements={leaseAgreements} />}
      {hasInsuranceAgreements && <InsuranceAgreements insuranceAgreements={insuranceAgreements} />}
    </Container>
  );
}
