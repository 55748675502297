import type { Dispatch, SetStateAction } from "react";
import { Card, Text, Button } from "@telia/teddy";

type Props = {
  formattedLimits: { dataLimitRLH: string; dataLimitWhenSpeedReduced: string; reducedSpeed: string };
  setIsTermsVisible: Dispatch<SetStateAction<boolean>>;
};

export const Terms = ({ formattedLimits, setIsTermsVisible }: Props) => {
  const { dataLimitRLH, dataLimitWhenSpeedReduced, reducedSpeed } = formattedLimits;
  return (
    <div>
      <Card.Heading variant="title-400">Vilkår</Card.Heading>
      <Card.Line mt="250" mb="250" />
      <Card.Content>
        <Text style={{ lineHeight: "1.8" }}>
          Etter {dataLimitWhenSpeedReduced} reduseres hastigheten til {reducedSpeed}. Det påløper ingen ekstra
          kostnader, og du kan fortsatt surfe og streame videre på mobilen din i Norge, Sverige, Danmark, Finland og
          Baltikum. Inntil {dataLimitRLH} kan benyttes i resten av EU/EØS, samt Storbritannia og Sveits, deretter koster
          data 2 øre pr. MB.
        </Text>
      </Card.Content>
      <Button width="100%" variant="text" onClick={() => setIsTermsVisible(false)}>
        Lukk
      </Button>
    </div>
  );
};
