import { DashboardSection } from "../card/DashboardSection";
import { BroadbandCabin } from "./BroadbandCabin";
import { MoveCard } from "./MoveCard";
import { HelpCard } from "./HelpCard";
import { useBlueConic } from "hooks/useBlueConic";
import { BlueConicActionCard } from "../highlights/personal/BlueConicActionCard";
import { BankIdCard } from "./BankIdCard";
import { ACCESS_LEVEL, useAuth } from "@telia-no-min-side/components";
import { config } from "util/config";
import { BlueConicPromoList } from "../highlights/personal/BlueConicPromoList";

export function UsefulShortcuts() {
  const { bcTips } = useBlueConic();
  const auth = useAuth();

  return (
    <DashboardSection title="Nyttige snarveier">
      {auth?.accessLevel !== ACCESS_LEVEL.SILVER && <BankIdCard />}
      <HelpCard />
      <BroadbandCabin />
      <MoveCard />
      {config.isNewBlueconicEnabled ? <BlueConicPromoList type="explore" /> : <BlueConicActionCard message={bcTips} />}
    </DashboardSection>
  );
}
