import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { devices } from "./../utils/breakpoints";

export const TabsList = styled.ul`
  margin: 0 0;
  padding: 0.5rem 0 0;
  position: relative;
  a {
    text-decoration: none;
  }
  ::before {
    position: absolute;
    inset: 0;
    content: "";
    box-shadow: 3px 8px 8px 0px rgba(0, 0, 0, 0.16);
    opacity: 1;
    pointer-events: none;
  }
`;

export const TabsListItem = styled.li<{ removePadding?: boolean }>`
  list-style: none;
  padding: 0 1rem;
  :first-of-type {
    padding-left: 0;
    margin-left: ${(props) => (props.removePadding ? "0" : "2rem")};
  }
  :last-of-type {
    padding-right: 0;
    margin-right: ${(props) => (props.removePadding ? "0" : "2rem")};
  }
  @media ${devices.sm_down} {
    :first-of-type {
      margin-left: ${(props) => (props.removePadding ? "0" : "1rem")};
    }
    :last-of-type {
      margin-right: ${(props) => (props.removePadding ? "0" : "1rem")};
    }
  }
`;

export const TabListButton = styled.button<{ $isSelected: boolean }>`
  font-weight: 400;
  color: ${colors.grey800};
  font-size: 1.125rem;
  height: 1.8rem;
  list-style: none;
  text-decoration: none;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  display: block;
  padding: 0;
  /* If desktop  */
  @media (hover: hover) and (pointer: fine) {
    :hover {
      color: ${colors.corePurple800};
      background-color: ${colors.corePurple100};
    }
  }
  ${(props) =>
    props.$isSelected &&
    css`
      color: ${colors.corePurple700};
      border-bottom: 2px solid ${colors.corePurple700};
    `}
`;

export const StyledTabPanel = styled.div`
  padding: 1rem 0;
`;
