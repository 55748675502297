import styled from "styled-components";
import { zIndex } from "../../utils/variables";
import { Link } from "../../Link";

export const LinkText = styled.span`
  margin-left: 0.5rem;
`;

export const BottomSection = styled.div`
  padding: 0rem 0.5rem;
  display: flex;
  flex-direction: column;
  & span {
    font-weight: 400;
    margin-left: 0.5rem;
  }
`;

export const MinSideButtonWrapper = styled.div`
  z-index: ${zIndex.sideMenuMobile};
  position: relative;
`;

export const StyledLink = styled(Link)`
  margin-left: 0.8rem;
  margin-top: 1rem;
`;
