import { ReactNode } from "react";
import { Card, Badge, Flex, Text, Box, Skeleton, Heading } from "@telia/teddy";
import { AdditionalPriceText, PostCampaignPrice, ProductImage } from "./style";

export type Props = {
  title: string;
  additionalTagText?: ReactNode | string;
  price?: string;
  postCampaignPrice?: string;
  additionalPriceText?: string;
  image?: string;
  campaignText?: string;
  children?: ReactNode;
  dataTrackingID?: string;
} & (
  | {
      isSelected?: never;
      selectedBadgeText?: never;
    }
  | {
      isSelected: boolean;
      selectedBadgeText?: string;
    }
);

function Root({
  title,
  additionalTagText,
  price,
  image,
  isSelected,
  children,
  additionalPriceText,
  selectedBadgeText,
  postCampaignPrice,
  campaignText,
  dataTrackingID,
}: Props) {
  return (
    <Card variant="white" shadow justify="stretch" bordered data-tracking-id={dataTrackingID}>
      {isSelected && (
        <Card.Slot align="top-right">
          <Badge variant="warning" hideIcon>
            {selectedBadgeText || "Valgt"}
          </Badge>
        </Card.Slot>
      )}
      {campaignText && (
        <Card.Slot align="top-left">
          <Badge variant="special" hideIcon>
            {campaignText}
          </Badge>
        </Card.Slot>
      )}
      <Flex direction="row" width="100%">
        {image && (
          <Box mr="200">
            <ProductImage alt={title} src={image} />
          </Box>
        )}
        <Box p="10">
          <Heading as="h2" variant="title-100">
            {title}
          </Heading>
          {additionalTagText && <Text>{additionalTagText}</Text>}
        </Box>
        <Box p="100" ml="auto">
          <Flex direction="column" align="end">
            <Box right="0">
              {postCampaignPrice && <PostCampaignPrice>{postCampaignPrice}</PostCampaignPrice>}
              <b>{price}</b>
            </Box>
            {additionalPriceText && <AdditionalPriceText>{additionalPriceText}</AdditionalPriceText>}
          </Flex>
        </Box>
      </Flex>
      {children}
    </Card>
  );
}

function Footer({ children }: { children: ReactNode }) {
  return (
    <Box mt="100">
      <Card.Line />
      <Card.Footer>{children}</Card.Footer>
    </Box>
  );
}

function ShoppingCardSkeleton({
  hasImage,
  hasTitle,
  hasBody,
  hasFooter,
}: {
  hasImage?: boolean;
  hasBody?: boolean;
  hasTitle?: boolean;
  hasFooter?: boolean;
}) {
  return (
    <Card variant="white" shadow justify="stretch" bordered>
      <Flex direction="row" width="100%" wrap="wrap">
        {hasImage && (
          <Box mr="200">
            <Skeleton width="6rem" height="6rem" />
          </Box>
        )}
        {hasTitle && (
          <Box mt="100">
            <Skeleton width="20rem" height="20px" />
            {hasBody && (
              <Box mt="100">
                {[...Array(3)].map((x, i) => (
                  <Skeleton key={i} width={{ xs: "25rem", lg: "35rem" }} height="15px" mt="100" />
                ))}
              </Box>
            )}
          </Box>
        )}
      </Flex>

      {hasFooter && (
        <>
          <Card.Line mt="200" />
          <Box mt="200" ml="auto">
            <Skeleton width="150px" height="30px" />
          </Box>
        </>
      )}
    </Card>
  );
}

export const ShoppingCard = Object.assign(Root, {
  Footer,
  Skeleton: ShoppingCardSkeleton,
});
