import { createContext, ReactNode, useState } from "react";

export type InvoicePaymentResult = "" | "OK" | "CANCEL" | "FAILED" | "ANOTHER_PAYMENT_IN_PROGRESS";

type IResultMessageContext = {
  invoicePaymentResult: InvoicePaymentResult;
  setInvoicePaymentResult: (result: InvoicePaymentResult) => void;
};

export const ResultMessageContext = createContext<IResultMessageContext | null>(null);

export const ResultMessageProvider = ({ children }: { children: ReactNode }) => {
  const [invoicePaymentResult, setInvoicePaymentResult] = useState<InvoicePaymentResult>("");

  return (
    <ResultMessageContext.Provider
      value={{
        invoicePaymentResult,
        setInvoicePaymentResult,
      }}
    >
      {children}
    </ResultMessageContext.Provider>
  );
};
