import { useState, useMemo } from "react";
import { Flex, Badge, Icon, Text } from "@telia/teddy";
import { Card, Container, Heading, DynamicGaugeMeter } from "@telia-no-min-side/components";
import { types } from "@telia-no-min-side/utils";
import { getSimplePriceText } from "src/util/priceFormatter";
import { DynamicGaugeMeterWrapper, StreamingImage, StreamingContainer } from "../../styles/style";
import { getUpDowngradeBroadbandProducts, getUpDowngradeTvProducts } from "../../utils/getProductOptions";
import { useFlexOrder } from "../../context/FlexOrderProvider";
import { BadgeCenter, PackageContentGrid } from "../../styles/Package.styled";
import { ShoppingCart } from "pages/flex/components/ShoppingCart/ShoppingCart";
import { PackageOptionButtons } from "./PackageOptionButtons";

type Props = {
  isCustomersCurrentProduct: boolean;
};

export function Package({ isCustomersCurrentProduct }: Props) {
  const [showShoppingCart, setShowShoppingCart] = useState(false);
  const { flexPricesData, flexProductOffer, setFlexProductOffer } = useFlexOrder();

  const { downgradeBroadbandProduct, upgradeBroadbandProduct, downgradeTvProduct, upgradeTvProduct } = useMemo(() => {
    const broadBandOptions = getUpDowngradeBroadbandProducts(flexProductOffer!, flexPricesData?.flatten);
    const tvOptions = getUpDowngradeTvProducts(flexProductOffer!, flexPricesData?.flatten);

    return {
      ...broadBandOptions,
      ...tvOptions,
    };
  }, [flexPricesData, flexProductOffer]);

  if (!flexProductOffer) return null;

  const showShoppingCartHandler = () => setShowShoppingCart(true);
  const hideShoppingCartHandler = () => setShowShoppingCart(false);

  const totalPrice = flexProductOffer.sum.currentAmount;

  return (
    <Flex direction="column" gap="400">
      <Card style={{ padding: "0" }}>
        <BadgeCenter variant="information" hideIcon>
          Valgfritt
        </BadgeCenter>

        <Flex direction="column" gap="400" p="32px">
          <Heading tag="h3" variant="title-100" textAlign="center" removeMargin>
            Vil du gjøre noen oppgraderinger på valgt pakke?
          </Heading>

          <DynamicGaugeMeterWrapper>
            <Container>
              <Heading variant="title-100" tag="h3">
                Internett
              </Heading>
              <DynamicGaugeMeter
                percentage={flexProductOffer.broadbandOption?.normalizedSpeed || 0}
                isDecrementDisable={!downgradeBroadbandProduct}
                isIncrementDisable={!upgradeBroadbandProduct}
                fadeHideButton={showShoppingCart}
                strokeWidth={8}
                disabled
                onDecrement={() => setFlexProductOffer((prev) => downgradeBroadbandProduct || prev)}
                onIncrement={() => setFlexProductOffer((prev) => upgradeBroadbandProduct || prev)}
              >
                <Heading removeMargin variant="title-100" tag="h4">
                  {flexProductOffer.broadbandOption?.downloadSpeed || 0}
                </Heading>
                <Text variant="additional-100">Mbps</Text>
              </DynamicGaugeMeter>
            </Container>

            <Container>
              <Heading variant="title-100" tag="h3">
                Strømming & TV
              </Heading>
              <DynamicGaugeMeter
                percentage={flexProductOffer?.tvOption?.normalizedPoints || 0}
                isDecrementDisable={!downgradeTvProduct}
                isIncrementDisable={!upgradeTvProduct}
                fadeHideButton={showShoppingCart}
                strokeWidth={8}
                onDecrement={() => setFlexProductOffer((prev) => downgradeTvProduct || prev)}
                onIncrement={() => setFlexProductOffer((prev) => upgradeTvProduct || prev)}
              >
                <Heading removeMargin variant="title-100" tag="h4">
                  {flexProductOffer?.tvOption?.points || 0}
                </Heading>
                <Text variant="additional-100">poeng</Text>
              </DynamicGaugeMeter>
            </Container>
          </DynamicGaugeMeterWrapper>

          <PackageContentGrid>
            <Text variant="paragraph-100-medium">Pris:</Text>
            <Badge variant={!totalPrice ? "success" : "information"} hideIcon>
              {!totalPrice ? "Inkludert" : getSimplePriceText(totalPrice, flexProductOffer.sum.chargePeriod)}
            </Badge>
            {flexProductOffer.broadbandOption !== null && (
              <>
                <Icon
                  name="speedometer"
                  size="md"
                  style={{ opacity: flexProductOffer.broadbandOption?.downloadSpeed === 0 ? 0.5 : 1 }}
                />
                <Text style={{ opacity: flexProductOffer.broadbandOption?.downloadSpeed === 0 ? 0.5 : 1 }}>
                  {flexProductOffer.broadbandOption?.salesText}
                </Text>
              </>
            )}

            {flexProductOffer?.tvOption !== null && (
              <>
                <Icon
                  name="entertainment"
                  size="md"
                  style={{ alignSelf: "start", opacity: flexProductOffer?.tvOption?.points === 0 ? 0.5 : 1 }}
                />
                <Text style={{ opacity: flexProductOffer?.tvOption?.points === 0 ? 0.5 : 1 }}>
                  {flexProductOffer?.tvOption?.salesText}
                </Text>
              </>
            )}

            <div style={{ gridRow: "4 / 5", gridColumn: "1 / 2" }}></div>
            {flexProductOffer?.tvOption?.logos && (
              <StreamingContainer style={{ gridRow: "4 / 5", gridColumn: "2 / 3" }}>
                {flexProductOffer?.tvOption?.logos.map((logo: types.fixed.Logo) => (
                  <StreamingImage key={logo.name} transparentBG>
                    <img alt={logo.name} src={logo.src} />
                  </StreamingImage>
                ))}
              </StreamingContainer>
            )}
          </PackageContentGrid>
        </Flex>
      </Card>

      {showShoppingCart ? (
        <ShoppingCart hideShoppingCartHandler={hideShoppingCartHandler} />
      ) : (
        <PackageOptionButtons
          showShoppingCartHandler={showShoppingCartHandler}
          isCustomersCurrentProduct={isCustomersCurrentProduct}
        />
      )}
    </Flex>
  );
}
