import { useEffect, useRef, useState } from "react";
import { isUnauthorizedResponse, logFixedApiError, track } from "@telia-no-min-side/utils";
import { AxiosRequestConfig } from "axios";
import { axiosPost } from "./axios";
import { handleUnauthorizedResponse } from "@telia-no-min-side/components";

type Config<ResponseData> = {
  onSuccess?(responseData: ResponseData): void;
  onFinally?(): void;
  onError?(e: Error): void;
  customSuccessValidation?(responseData: ResponseData): boolean;
} & AxiosRequestConfig<ResponseData>;

export function useAxiosPost<ResponseData = unknown, RequestData = unknown>(
  url: string,
  config?: Config<ResponseData>
) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<unknown>();
  const task = useRef<AbortController>();
  const { pushTrackingEvent } = track.useEventTracking();

  useEffect(() => {
    return () => {
      task.current?.abort();
    };
  }, [task]);

  function post(data?: RequestData, transactionData?: track.Transaction) {
    const abortController = new AbortController();
    if (task.current) {
      task.current.abort();
    }
    task.current = abortController;
    setIsLoading(true);
    setIsSuccess(false);
    return axiosPost<ResponseData, RequestData>(url, data, {
      ...config,
      signal: abortController.signal,
    })
      .then((response) => {
        const isSuccess = config?.customSuccessValidation ? config?.customSuccessValidation(response) : true;
        if (!isSuccess) {
          throw new Error("error");
        }
        if (transactionData) {
          pushTrackingEvent(track.createTransactionEvent(transactionData));
        }
        if (config?.onSuccess) {
          config.onSuccess(response);
        }
        setError(null);
        setIsSuccess(true);
        return response;
      })
      .catch((e) => {
        logFixedApiError(error, { url });
        if (isUnauthorizedResponse(error)) {
          handleUnauthorizedResponse(error);
        }
        config?.onError && config.onError(e);
        setIsSuccess(false);
        setError(e);
        throw e;
      })
      .finally(() => {
        config?.onFinally && config.onFinally();
        setIsLoading(false);
      });
  }
  return {
    isError: !!error,
    error,
    isSuccess,
    isLoading,
    post,
  };
}
