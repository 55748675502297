import { useQuery } from "@apollo/client";
import { Box, Skeleton, breakpoint } from "@telia/teddy";
import { useMobileAccount } from "hooks/useMobileAccount";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { useSubscriptionData } from "./hooks/useSubscriptionData";
import { GET_SUBSCRIPTION_DATA } from "./graphql/getSubscriptionData";
import { SubscriptionCardB2B, SubscriptionCardB2C, SubscriptionCardError, HasNoSubscriptionError } from "./components";
import { useMobileDashboardContext } from "../../context/MobileDashboardContext";
import { PrepaidSubscriptionInfo } from "pages/prepaid/subscription-info";

export function SubscriptionCard() {
  const { orders } = useOrderSubscription();
  const { phoneNumber } = useMobileAccount();
  const { isPrepaid } = useMobileDashboardContext();

  const { loading, data, error, refetch } = useQuery(GET_SUBSCRIPTION_DATA, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
    skip: !phoneNumber,
  });

  const subscription = data?.subscription;
  const subscriptionData = useSubscriptionData(subscription, orders);

  if (loading) return <Skeleton height="180px" width="100%" maxWidth={breakpoint.teddyBreakpointMd} />;
  if (error) return <SubscriptionCardError refetch={refetch} />;
  if (!subscriptionData.hasSubscription) return <HasNoSubscriptionError />;

  return (
    <Box maxWidth={breakpoint.teddyBreakpointMd}>
      {isPrepaid && subscriptionData.subscriptionName ? (
        <PrepaidSubscriptionInfo subscriptionName={subscriptionData.subscriptionName} />
      ) : subscriptionData.isB2B ? (
        <SubscriptionCardB2B
          subHeading={subscriptionData.subHeading}
          subscriptionName={subscriptionData.subscriptionName}
        />
      ) : (
        <SubscriptionCardB2C subscriptionData={subscriptionData} />
      )}
    </Box>
  );
}
