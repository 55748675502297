import { Container } from "@telia-no-min-side/components";
import { ReactNode, useState } from "react";
import { List } from "@telia/teddy";
import { Heading, Card, Button, Skeleton, Text, Icon, RadioCardGroup, TextSpacing, breakpoint } from "@telia/teddy";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GdprBusinessObject } from "gql/graphql";
import { graphql } from "gql";
import dayjs from "dayjs";
import { downloadGdprReportMobile } from "src/api/fixed/useFetchGDPR";

export const GDPR_REPORTS_MOBILE = graphql(`
  query GdprStatus {
    gdprStatus {
      canRequestNewReport
      requestedReports {
        ticketCreationDate
      }
      downloadableReports {
        requestId
        ticketCreationDate
      }
    }
  }
`);

export function RightOfAccessStepOne() {
  const gdprStatus = useQuery(GDPR_REPORTS_MOBILE, {
    fetchPolicy: "cache-and-network",
  });
  const [showOnlyRequestNewReport, setShowOnlyRequestNewReport] = useState(false);
  const { downloadableReports, canRequestNewReport } = gdprStatus.data?.gdprStatus || {};
  const hasDownloadableReports = downloadableReports?.some((report) => report?.requestId);

  if (gdprStatus.error && !gdprStatus.data) {
    return (
      <Container showGoBackButton padding>
        <Heading as="h1" variant="title-200">
          Innsynsrapport for mobil
        </Heading>
        <Card bordered variant="white" maxWidth={breakpoint.teddyBreakpointMd}>
          <TextSpacing>
            <Heading as="h2" variant="title-100">
              <Icon name="alert">En uventet feil oppstod</Icon>
            </Heading>
            <Text>
              Vi klarte dessverre ikke hente dine innsynsrapport. Vennligst prøv igjen eller ta kontakt med
              kundeservice.
            </Text>
          </TextSpacing>
        </Card>
      </Container>
    );
  }
  if (showOnlyRequestNewReport) {
    return (
      <Container
        showGoBackButton
        onGoBackClick={(e) => {
          e.preventDefault();
          setShowOnlyRequestNewReport(false);
        }}
        padding
        flexDirection="column"
        gap="lg"
      >
        <Heading as="h1" variant="title-200">
          Innsynsrapport for mobil
        </Heading>
        <RequestNewReport>
          <Button variant="secondary" onClick={() => setShowOnlyRequestNewReport(false)}>
            Avbryt
          </Button>
        </RequestNewReport>
      </Container>
    );
  }

  return (
    <Container showGoBackButton padding flexDirection="column" alignItems="start" gap="lg">
      <Heading as="h1" variant="title-200">
        Innsynsrapport for mobil
      </Heading>
      <RequestedReports />
      <DownloadableReports />
      {hasDownloadableReports && canRequestNewReport ? (
        <Button variant="secondary" onClick={() => setShowOnlyRequestNewReport(true)}>
          <Icon name="plus">Bestill ny rapport</Icon>
        </Button>
      ) : (
        <RequestNewReport>
          <Button variant="secondary" asChild>
            <Link to="/minside/personvern">Avbryt</Link>
          </Button>
        </RequestNewReport>
      )}
    </Container>
  );
}

function DownloadableReports() {
  const gdprReports = useQuery(GDPR_REPORTS_MOBILE);
  const downloadableReports = gdprReports.data?.gdprStatus?.downloadableReports;
  if (!downloadableReports) {
    return null;
  }
  return (
    <>
      {[...downloadableReports]
        /* sort by date, newest first */
        .sort((a, b) => new Date(b?.ticketCreationDate).getTime() - new Date(a?.ticketCreationDate).getTime())
        .map((report) => {
          const requestId = report?.requestId;
          if (!requestId) return null;
          return (
            <Card
              bordered
              variant="white"
              maxWidth={breakpoint.teddyBreakpointMd}
              key={report?.ticketCreationDate}
              layout="default"
            >
              <Card.Heading as="h2" variant="title-100">
                <Icon name="checkmark">Innsynsrapport</Icon>
              </Card.Heading>
              <Card.Description asChild>
                <Text>Du kan nå laste ned din innsynsrapport ved å klikke på knappen under.</Text>
              </Card.Description>
              <Card.Content>
                <Text>Dato for forespørsel: {dayjs(report?.ticketCreationDate).format("DD.MM.YYYY")}</Text>
              </Card.Content>
              <Card.Footer>
                <Button
                  variant="primary"
                  onClick={() =>
                    requestId &&
                    downloadGdprReportMobile({
                      requestId,
                      fileName: `innsynsrapport-${dayjs(report?.ticketCreationDate).format("DD-MM-YYYY")}.zip`,
                    })
                  }
                >
                  Last ned
                </Button>
              </Card.Footer>
            </Card>
          );
        })}
    </>
  );
}

function RequestedReports() {
  const gdprReports = useQuery(GDPR_REPORTS_MOBILE);
  if (!gdprReports.data?.gdprStatus?.requestedReports?.length) {
    return null;
  }
  const numberOfRequestedReports = gdprReports.data.gdprStatus.requestedReports.length;
  return (
    <Card bordered variant="white" maxWidth={breakpoint.teddyBreakpointMd} layout="default">
      <Card.Heading as="h2" variant="title-100">
        <Icon name="care">Dine personopplysninger er under behandling</Icon>
      </Card.Heading>
      <Card.Description asChild>
        <Text>
          Vi behandler dine personopplysninger og vil sende deg en e-post med en lenke når de er klare. Hvor lang tid
          det tar før du mottar e-posten er avhengig av hvilke opplysninger du har bedt om.
        </Text>
      </Card.Description>
      <Card.Content>
        <Text variant="paragraph-100-medium" mb="100">
          Du har {numberOfRequestedReports} {numberOfRequestedReports === 1 ? "forespørsel" : "forespørsler"} under
          behandling:
        </Text>
        <List ml={"100"}>
          {gdprReports.data.gdprStatus.requestedReports.map((report) => (
            <List.Item key={report?.ticketCreationDate}>
              <Icon name="report" /> Dato for forespørsel: {dayjs(report?.ticketCreationDate).format("DD.MM.YYYY")}
            </List.Item>
          ))}
        </List>
      </Card.Content>
    </Card>
  );
}

function RequestNewReport({ children }: { children?: ReactNode }) {
  const [selectedBusinessObject, setSelectedBusinessObject] = useState<GdprBusinessObject>(GdprBusinessObject.Traffic);
  const gdprReports = useQuery(GDPR_REPORTS_MOBILE);
  if (gdprReports.data?.gdprStatus?.canRequestNewReport === false) {
    return null;
  }

  return (
    <Card p="0" layout="default" maxWidth={breakpoint.teddyBreakpointMd}>
      <Card.Description>
        <Skeleton as="span" loading={gdprReports.loading}>
          Under kan du velge hvilke personopplysninger du ønsker å få innsyn i. Du vil motta en e-post med en lenke fra
          oss. Hvor lang tid det tar før du mottar e-posten er avhengig av hvilke opplysninger du ønsker.
        </Skeleton>
      </Card.Description>
      <Skeleton loading={gdprReports.loading}>
        <Card.Heading variant="title-100" as="h2">
          Hvilke personopplysninger ønsker du innsyn i?
        </Card.Heading>
      </Skeleton>
      <Card.Content>
        <RadioCardGroup
          defaultValue={GdprBusinessObject.Traffic}
          loop
          orientation="vertical"
          onValueChange={(v) =>
            setSelectedBusinessObject(
              v === GdprBusinessObject.All.toString() ? GdprBusinessObject.All : GdprBusinessObject.Traffic
            )
          }
        >
          <Skeleton loading={gdprReports.loading}>
            <RadioCardGroup.Item value={GdprBusinessObject.Traffic}>
              <RadioCardGroup.Title>Jeg ønsker oversikt over mine trafikkdata</RadioCardGroup.Title>
              <RadioCardGroup.Body asChild>
                <div>
                  <Text>Noen dagers ventetid</Text>
                  <Text asChild>
                    <List>
                      <List.Item>Innkommende og utgående samtaler</List.Item>
                      <List.Item>SMS og MMS</List.Item>
                      <List.Item>Mobildatabruk på abonnement</List.Item>
                    </List>
                  </Text>{" "}
                </div>
              </RadioCardGroup.Body>
            </RadioCardGroup.Item>
          </Skeleton>
          <Skeleton loading={gdprReports.loading}>
            <RadioCardGroup.Item value={GdprBusinessObject.All}>
              <RadioCardGroup.Title>Jeg ønsker å motta alle personopplysninger Telia har om meg</RadioCardGroup.Title>
              <RadioCardGroup.Body asChild>
                <div>
                  <Text>Opptil 30 dagers ventetid</Text>
                  <Text asChild>
                    <List>
                      <List.Item>
                        Trafikkdata (Innkommende og utgående samtaler, SMS og MMS, og mobildatabruk på abonnementet)
                      </List.Item>
                      <List.Item>
                        Kundeopplysninger (navn, fødsels- og personnummer, adresse, mobilnummer og e-post)
                      </List.Item>
                      <List.Item>
                        Informasjon om abonnementet (f.eks. abonnementstype, faktura, bestillinger og betalinger)
                      </List.Item>
                    </List>
                  </Text>
                </div>
              </RadioCardGroup.Body>
            </RadioCardGroup.Item>
          </Skeleton>
        </RadioCardGroup>
      </Card.Content>
      <Card.Footer gap={"100"}>
        <Skeleton loading={gdprReports.loading}>{children}</Skeleton>
        <Skeleton loading={gdprReports.loading}>
          <Button variant="primary" asChild>
            <Link to={`/minside/personvern/innsyn-mobil/send-foresporsel?accessRequested=${selectedBusinessObject}`}>
              Fortsett
            </Link>
          </Button>
        </Skeleton>
      </Card.Footer>
    </Card>
  );
}
