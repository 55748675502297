import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchFwaActiveOffers() {
  const { data, error, ...rest } = useAxiosSWR<types.fixed.OffersActive>(ICX_REQUEST.URL_FWA_OFFERS);

  return {
    offersByCustomer: data,
    isError: !!error,
    ...rest,
  };
}
