import { graphql } from "src/gql";

export const leaseAgreementFragment = graphql(`
  fragment LeaseAgreement on LeaseAgreement {
    id
    model
    phoneNumber {
      localNumber
    }
  }
`);
