import { remCalc } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";
import styled from "styled-components";

export const StyledHeading = styled.h3`
  font-size: ${remCalc(18)};
  color: ${colors.grey900};
  font-weight: normal;
  padding: 0;
  margin: 10px 0;
`;
