import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { breakpoint, Flex, Link, Notification, Text } from "@telia/teddy";
import { UseMobileAccountSubscriptionData } from "hooks/useMobileAccount";
import { phoneNumbers } from "util/phoneNumbers";
import PhoneBox from "components/PhoneBox";
import { useWindowSize } from "@telia-no-min-side/components";

type Props = Pick<UseMobileAccountSubscriptionData, "userOffering">;

export function AgencyAssignMessage({ userOffering }: Props) {
  const userOfferingShortName = userOffering?.shortName || "";
  const { isMobile } = useWindowSize();

  return (
    <Flex maxWidth={breakpoint.teddyBreakpointMd} p={isMobile ? "200" : "400"} gap="300" direction="column">
      <Notification variant="information" data-tracking-id="alert--suspended-subscription_reason-agency-assign">
        <Notification.Heading as="h3">
          <Notification.Icon>Ditt abonnement {userOfferingShortName} er sperret</Notification.Icon>
        </Notification.Heading>
        <Flex direction="column" gap="gutter-md">
          <Notification.Text>
            <Text>
              Du har et forfalt beløp som har gått til inkasso. Vennligst ta kontakt med inkassobyrået Sergel for videre
              oppfølging på telefon eller logg deg inn med BankID hos{" "}
              <Link
                href={EXTERNAL_LINK.DEBT_COLLECTION_AGENCY}
                variant="navigation"
                style={{ display: "inline" }}
                target="_blank"
              >
                {EXTERNAL_LINK.DEBT_COLLECTION_AGENCY}
              </Link>
            </Text>
          </Notification.Text>
          <Notification.Footer>
            <PhoneBox number={phoneNumbers.customerServiceNorway} />
          </Notification.Footer>
        </Flex>
      </Notification>
    </Flex>
  );
}
