import { Button, Icon } from "@telia/teddy";
import { ComponentProps } from "react";

export function DownloadButton(props: ComponentProps<typeof Button>) {
  return (
    <Button {...props} data-html2canvas-ignore variant="text" style={{ textDecoration: "underline" }}>
      <Icon name="download" />
      Last ned ordresammendrag
    </Button>
  );
}
