import { Flex, Skeleton } from "@telia/teddy";

export function Loading() {
  return (
    <Flex direction="column" gap="gutter-sm">
      <Skeleton height="40px" width="100%" />
      <Skeleton height="1px" width="100%" />
      <Skeleton height="72px" width="100%" />

      <Flex direction="column" gap="gutter-sm">
        <Skeleton height="90px" width="100%" />
        <Skeleton height="90px" width="100%" />
        <Skeleton height="90px" width="100%" />
      </Flex>
    </Flex>
  );
}
