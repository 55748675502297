import { Breadcrumb } from "components/Breadcrumb";

export function MobileSubscriptionProfile() {
  return (
    <div>
      <Breadcrumb />
      <h1>MobileSubscriptionProfile</h1>
    </div>
  );
}
