import { BaseSyntheticEvent, ReactNode } from "react";
import { DirectoryListing, SubscriptionData } from "src/gql/graphql";

export type Option = {
  id: string;
  isActive: boolean | undefined | null;
  isModifiable: boolean;
  title: ReactNode;
  subTitle?: string;
  content: ReactNode;
  priceText?: string;
  onChange?: (e: BaseSyntheticEvent) => void;
  withIndent?: boolean;
  excludedAdditionalProducts?: string[];
  originalState: boolean | undefined | null;
};

export type Characteristic = {
  characteristicType: string | undefined | null;
  name: string | undefined | null;
  value: string | undefined | null;
};

export type FormStateField = {
  code: string;
  active: boolean;
  characteristics: Characteristic[] | [] | undefined;
  email?: string;
};

export enum ProductFilters {
  Hint = "HINT_2",
  SecurityDNS = "NETVAKT01",
  CancelSecurityDNS = "NETVAKT02",
  CallSpecificationFree = "CALL_SPECIFICATION_FREE",
  MbnUser = "MBN_USER",
  GiftDataBalance = "GIFT_DATA_BALANCE",

  TRYGG = "TRYGG",
  SKY = "SKY",
  HBO = "HBO",
  STORYTEL = "STORYTEL",
  Voicemail = "VOICEMAIL",
  SPOTIFY = "SPOTIFY",
  EXTRA_SIM = "EXTRA_SIM",
  BarringCpa = "BARRING_CPA",
  BarringGprs = "BARRING_GPRS",
  BarringCalls = "BARRING_CALLS",
}

export type DirectoryListingsData = {
  subscription: Partial<SubscriptionData>;
  directoryListings: DirectoryListing[];
};

export enum PublishLevelCode {
  "NO_INFO" = "Z",
  "NAME_PHONE" = "H",
  "NAME_PHONE_ADDRESS" = "X",
  "NAME_PHONE_ADDRESS_LO" = "W",
}
