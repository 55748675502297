import { TopupDuration, TopupDurationCalendricalTypes } from "gql/graphql";

export function getTopupDuration(duration: TopupDuration) {
  if (!duration.durationCalendrical) {
    return null;
  }

  const multipleDays = duration.durationCalendrical.type !== TopupDurationCalendricalTypes.Day;

  return `${duration.durationCalendrical.days} ${multipleDays ? "dager" : "dag"}`;
}
