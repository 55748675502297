import { formatDateForSuspendedMessage } from "../utils";
import { UseMobileAccountSubscriptionData } from "src/hooks/useMobileAccount";
import { breakpoint, Button, Flex, Icon, Notification } from "@telia/teddy";
import { Link } from "react-router-dom";
import { useWindowSize } from "@telia-no-min-side/components";

type Props = Pick<UseMobileAccountSubscriptionData, "subscriptionStatusDate" | "userOffering">;

export function StolenMessage({ userOffering, subscriptionStatusDate }: Props) {
  const userOfferingShortName = userOffering?.shortName || "";
  const { isMobile } = useWindowSize();

  return (
    <Flex maxWidth={breakpoint.teddyBreakpointMd} p={isMobile ? "200" : "400"} gap="300" direction="column">
      <Notification variant="information" data-tracking-id="alert--suspended-subscription_reason-stolen">
        <Notification.Heading as="h3">
          <Notification.Icon>Ditt abonnement {userOfferingShortName} er sperret</Notification.Icon>
        </Notification.Heading>
        <Flex direction="column" gap="gutter-md">
          <Notification.Text>
            Abonnementet ditt ble sperret den {formatDateForSuspendedMessage(subscriptionStatusDate || "")} fordi
            telefonen er meldt stjålet. For å gjenåpne abonnementet kreves gyldig legitimasjon. Dette kan du gjørs hos
            din nærmeste Telia-butikk.
          </Notification.Text>
          <Notification.Footer>
            <Button asChild>
              <Link to="/kundeservice/butikker-og-forhandlere">
                <Icon name="arrow-right" />
                Finn din nærmeste Telia-butikk
              </Link>
            </Button>
          </Notification.Footer>
        </Flex>
      </Notification>
    </Flex>
  );
}
