import { useNavigate } from "react-router-dom";
import { uri } from "@telia-no-min-side/utils";
import { Flex, Card, Text, Icon } from "@telia/teddy";
import { FragmentType, getFragmentData } from "../../../../gql";
import { AccountData } from "../../../../gql/graphql";
import { leaseAgreementFragment } from "../graphql/leaseAgreementFragment";
import { useMobileAccount } from "hooks/useMobileAccount";

type Props = {
  leaseAgreement: FragmentType<typeof leaseAgreementFragment>;
  accountId: AccountData["id"];
};

export function LeaseAgreementCard({ leaseAgreement, accountId }: Props) {
  const navigate = useNavigate();
  const { setPhoneNumber, setAccountId } = useMobileAccount();

  const {
    model,
    phoneNumber: { localNumber },
  } = getFragmentData(leaseAgreementFragment, leaseAgreement);

  const handleClick = () => {
    if (localNumber) {
      setPhoneNumber(localNumber);
    }
    setAccountId(accountId);
    navigate(uri.minSideMobile("/avtaler"));
  };

  return (
    <Card
      shadow
      bordered
      width="100%"
      maxWidth="400px"
      layout="default"
      variant="white"
      data-tracking-id={`lease-agreement-${localNumber}`}
    >
      <Card.Heading as="h3" variant="title-100">
        <Flex direction="row" justify="between" align="center">
          Delbetaling
          {!!localNumber && (
            <Text variant="paragraph-100-bold" data-di-mask>
              {localNumber}
            </Text>
          )}
        </Flex>
      </Card.Heading>

      <Card.Content>
        {model && (
          <Icon name="smartphone" size="md">
            {model}
          </Icon>
        )}
      </Card.Content>

      <Card.Footer height="100%" align="end">
        <Card.Button variant="text" onClick={handleClick}>
          Se avtale
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
