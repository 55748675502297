import type { SyntheticEvent } from "react";
import { useEffect } from "react";
import { track } from "@telia-no-min-side/utils";
import { useFormHandling, useLocalStorage } from "@telia-no-min-side/components";
import { AdditionalProduct, OrderOp } from "src/gql/graphql";
import { useOrderAdditionalProducts } from "src/hooks/useAdditionalProducts";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { formatDatalayerTransactionData } from "src/services/googleAnalytics/eventDataHelpers";
import { getTax } from "util/tracking";
import { Badge, ExpandableCard, Flex, Heading, Notification, Button } from "@telia/teddy";
import { ServiceBox } from "components/service-box";

type Props = {
  options: AdditionalProduct[];
};

export type FormStateField = {
  code: AdditionalProduct["code"];
  active: boolean;
};

export function CallSpecificationPrivate({ options }: Props) {
  const { phoneNumber } = useMobileAccount();
  const { pushTrackingEvent } = track.useEventTracking();

  const product = options[0];
  const initialState: FormStateField = {
    code: product.code,
    active: !!product.presentation?.active,
  };

  const { state, dispatch, Actions } = useFormHandling(initialState);
  const { form, previousForm, showError, showSuccess } = state;

  const { orderResponse, runOrderAdditionalProducts } = useOrderAdditionalProducts();
  const { data, loading, error } = orderResponse;

  const { setValue: setOrderId } = useLocalStorage<string>("telia-no-minside-order-id__settings");

  useEffect(() => {
    if (data?.order?.orderId && !loading) {
      dispatch({ type: Actions.ON_SUCCESS });
      setOrderId(data.order.orderId);
    }

    if (error?.message && !loading) {
      dispatch({ type: Actions.ON_ERROR });
    }
  }, [dispatch, data, error, loading, Actions]);

  function order(form: FormStateField, phoneNumber: string) {
    const isFormActive = form.active;
    const orderInput = [
      {
        productName: product.code,
        operation: isFormActive ? OrderOp.AddAdditionalProduct : OrderOp.RemoveAdditionalProduct,
      },
    ];

    runOrderAdditionalProducts(
      {
        phoneNumber,
        input: orderInput,
      },
      {
        onCompleted(data) {
          const orderId = data.order.orderId || "";
          const isActiveProduct = product?.presentation?.active || false;

          const transactionData = formatDatalayerTransactionData({
            actionId: orderId,
            revenue: isActiveProduct ? 0 : product?.price?.price || 0,
            shipping: 0,
            name: product?.name,
            id: product?.code,
            price: isActiveProduct ? 0 : product?.price?.price || 0,
            tax: isActiveProduct ? 0 : getTax(product.price?.price || 0),
            category: "Subscription/Settings/Call_Specification_Private",
            isActiveProduct,
          });

          return pushTrackingEvent(transactionData);
        },
      }
    );
  }

  function onChangeHandler(key: string, checked: boolean) {
    dispatch({
      type: Actions.SET_FORM,
      payload: { [key]: checked },
    });
  }

  return product ? (
    <ExpandableCard type="multiple">
      <ExpandableCard.Item value="content1">
        <ExpandableCard.Trigger
          onClick={() => {
            !loading && dispatch({ type: Actions.RESET });
          }}
        >
          <ExpandableCard.Header>
            <ExpandableCard.Description>
              <Flex direction="column" gap="100">
                <Heading as="h5" variant="title-100">
                  Samtalespesifikasjon Privat
                </Heading>
                <div>
                  {previousForm.active ? (
                    <Badge variant="success" hideIcon>
                      På
                    </Badge>
                  ) : (
                    <Badge variant="neutral">Av</Badge>
                  )}
                </div>
              </Flex>
            </ExpandableCard.Description>
            <ExpandableCard.Indicator />
          </ExpandableCard.Header>
        </ExpandableCard.Trigger>
        <ExpandableCard.Content>
          {showSuccess ? (
            <Notification variant="success">
              <Notification.Dismiss />
              <Notification.Heading as="h3">
                <Notification.Icon>Dine endringer er lagret.</Notification.Icon>
              </Notification.Heading>
              <Notification.Text>Det kan ta opp til 5 minutter før du ser endringen her.</Notification.Text>
            </Notification>
          ) : null}
          {showError ? (
            <Notification variant="error">
              <Notification.Dismiss />
              <Notification.Heading as="h3">
                <Notification.Icon>Endringene dine ble ikke lagret. Vennligst prøv på nytt.</Notification.Icon>
              </Notification.Heading>
            </Notification>
          ) : null}
          <ServiceBox
            isActive={form.active}
            title={<strong>{product.name}</strong>}
            priceText={product.price?.price + ",-"}
            id={product.code}
            key={product.code}
            disabled={!product.presentation?.modifiable || loading}
            onChange={(checked) => {
              onChangeHandler("active", checked);
            }}
            content={[]}
          />
          {form.active !== previousForm.active && (
            <Flex gap="200">
              <Button
                onClick={() => dispatch({ type: Actions.RESET })}
                type="button"
                variant="tertiary-purple"
                disabled={loading}
              >
                Avbryt
              </Button>

              <Button
                onClick={(event: SyntheticEvent) => {
                  event.preventDefault();
                  order(form, phoneNumber);
                }}
                loading={loading}
                disabled={loading}
                type="button"
                data-tracking-id="service-save-button"
              >
                {loading ? "Sender bestilling" : "Lagre"}
              </Button>
            </Flex>
          )}
        </ExpandableCard.Content>
      </ExpandableCard.Item>
    </ExpandableCard>
  ) : null;
}
