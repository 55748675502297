import { graphql } from "src/gql";

export const ACCOUNT_OWNER_INVOICE_QUERY = graphql(`
  query AccountOwnerInvoiceQuery($accountId: String!, $pageSize: Int) {
    account(accountId: $accountId) {
      id
      invoiceDistribution
      canDeferInvoice
      paymentArrangement(status: Open, type: FaxPayment) {
        expectedPaymentDate
      }
      currentInvoice {
        paid
        invoiceNumber
        billingDate
        billingInfo {
          bankAccount
        }
      }
      paymentBalance {
        amount
        dueDate
      }
      selectedInvoiceDistributionType: invoiceDistribution
      paymentMethod {
        name
      }
      billingCycles {
        currentBillCycle {
          dueDay
          startDay
        }
        orderedBillCycle {
          dueDay
          startDay
          startDate
        }
        possibleBillCycles {
          dueDay
          startDay
        }
      }
      subscriptions {
        subscriptionStatus
        productUser {
          firstName
          surname
        }
        phoneNumber {
          localNumber
        }
      }
      invoiceCardPaymentStatus
      invoices(take: $pageSize) {
        billingDate
        dueDate
        invoiceNumber
        paid
        amount {
          value
          formatted
          valueWithVat
          valueWithoutVat
        }
        period {
          startDate
          endDate
        }
        pdfUrl
      }
    }
  }
`);

export const SUBSCRIPTION_INVOICE_QUERY = graphql(`
  query SubInvoiceQuery($phoneNumber: String!, $pageSize: Int) {
    subscription(phoneNumber: $phoneNumber) {
      account {
        id
      }
      phoneNumber {
        localNumber
      }
      invoices(take: $pageSize) {
        billingDate
        invoiceNumber
        paid
        amount {
          value
          formatted
          valueWithVat
          valueWithoutVat
        }
        period {
          startDate
          endDate
        }
        pdfUrl
      }
    }
  }
`);

export const SUBSCRIPTION_CURRENT_INVOICE_QUERY = graphql(`
  query SubCurrentInvoiceQuery($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      currentInvoice {
        invoiceNumber
        invoiceGroup {
          name
          invoiceRow {
            chargeType
            service
          }
          totalAmount {
            value
          }
        }
      }
    }
  }
`);

export const GET_INVOICE_BY_SUBSCRIPTION_QUERY = graphql(`
  query InvoiceDetails($localNumber: String!, $invoiceId: String!, $pageSize: Int) {
    subscription(phoneNumber: $localNumber) {
      agreementType
      roles
      phoneNumber {
        localNumber
      }
      productUser {
        firstName
        surname
      }
      invoices(take: $pageSize) {
        billingDate
        invoiceNumber
        billingDate
        period {
          startDate
          endDate
        }
      }
      invoice(id: $invoiceId) {
        billingDate
        invoiceNumber
        dueDate
        paid
        period {
          startDate
          endDate
        }
        amount {
          value
          currency
        }
        billingInfo {
          bankAccount
        }
        invoiceGroup {
          name
          totalAmount {
            value
            currency
            formatted
            valueWithVat
            valueWithoutVat
          }
          invoiceRow {
            usageType
            chargeType
            service
            name
            totalAmount {
              value
              currency
              formatted
              valueWithVat
              valueWithoutVat
            }
            quantity {
              unit
              quantity
            }
            displayOnInvoice
          }
        }
      }
    }
  }
`);

export const GET_INVOICE_BY_ACCOUNT_QUERY = graphql(`
  query AccountInvoiceDetails($accountId: String!, $invoiceId: String!) {
    account(accountId: $accountId) {
      id
      agreementType
      roles
      canDeferInvoice
      paymentArrangement(status: Open, type: FaxPayment) {
        expectedPaymentDate
      }
      invoiceCardPaymentStatus
      paymentBalance {
        amount
        dueDate
      }
      invoice(id: $invoiceId) {
        billingDate
        paid
        paidAmount
        showPaidAmount
        restAmount
        showOverpaidAmount
        billingDate
        invoiceNumber
        amount {
          value
          currency
          valueWithVat
          valueWithoutVat
          formatted
        }
        billingInfo {
          bankAccount
        }
        period {
          startDate
          endDate
        }
        dueDate
        invoiceGroup {
          name
          totalAmount {
            value
          }
          invoiceRow {
            name
            displayOnInvoice
            totalAmount {
              value
            }
            accountLevel
          }
        }
      }
      subscriptions {
        subscriptionStatus
        productUser {
          firstName
          surname
        }
        phoneNumber {
          localNumber
        }
        invoice(id: $invoiceId) {
          invoiceNumber
          amount {
            value
            currency
          }
          billingInfo {
            bankAccount
          }
          paid
        }
      }
    }
  }
`);

export const GET_INVOICE_SETTINGS = graphql(`
  query accountDetails($accountId: String!) {
    account(accountId: $accountId) {
      id
      selectedInvoiceDistributionType: invoiceDistribution
      roles
      invoiceTypes {
        code
        name
        invoiceDistribution
        denyReasons
        isAllowed
        isDefault
        requirements
        additionalProperties {
          key
          value
        }
        monthlyPrice
      }
      paymentMethod {
        name
        parameter {
          name
          value
        }
      }
      invoiceReceiver {
        individual {
          firstName
          surname
          emailAddress
          address {
            urbanAddress {
              careOfName
              streetName
              streetNr
              streetNrSuffix
              city
              postcode
              floorNumber
              doorNumber
              direction
              locality
            }
            poboxAddress {
              boxNr
              boxName
              city
              postcode
            }
          }
          telephoneNumber {
            countryCode
            localNumber
          }
        }
      }
    }
  }
`);

export const GET_BILLING_CYCLES = graphql(`
  query getBillingCycles($accountId: String!) {
    account(accountId: $accountId) {
      id
      billingCycles {
        activePaymentArrangement
        currentBillCycle {
          billCycle
          closeDate
          closeDay
          dueDate
          dueDay
          frequency
          startDate
          startDay
        }
        orderedBillCycle {
          billCycle
          closeDate
          closeDay
          dueDate
          dueDay
          frequency
          startDate
          startDay
        }
        possibleBillCycles {
          billCycle
          closeDate
          closeDay
          dueDate
          dueDay
          frequency
          startDate
          startDay
        }
      }
    }
  }
`);
