import React, { useState } from "react";
import { Button, useWindowSize } from "@telia-no-min-side/components";
import styled from "styled-components";
import { StyledModal, Wrapper } from "./style";
import { SerialNumberContent } from "./SerialNumberContent";
import { InvoiceNumberContent } from "./InvoiceNumberContent";
import { CustomerNumberContent } from "./CustomerNumberContent";

type Props = {
  content: "serialNumber" | "invoiceNumber" | "customerNumber";
  modalActionText?: string;
};

export function InfoModal({ content, modalActionText }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useWindowSize();

  return (
    <Wrapper>
      <StyledModal setIsOpen={setIsOpen} isOpen={isOpen}>
        {content === "invoiceNumber" && <InvoiceNumberContent />}
        {content === "serialNumber" && <SerialNumberContent />}
        {content === "customerNumber" && <CustomerNumberContent />}
      </StyledModal>
      {modalActionText && !isMobile && (
        <StyledButton variant="text-black" onClick={() => setIsOpen(true)}>
          {modalActionText}
        </StyledButton>
      )}
      <Button
        variant="tertiary-purple"
        size="unset"
        isIconButton
        title="Åpne"
        icon="question"
        onClick={() => setIsOpen(true)}
      />
    </Wrapper>
  );
}

const StyledButton = styled(Button)`
  height: unset;
  margin: 0;
  padding: 0;
`;
