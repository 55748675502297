import { Fragment } from "react";

export const formatTextWithLineBreaks = (text: string) => {
  return text.split(" ").map((word, index) => (
    <Fragment key={index}>
      {word}
      <br />
    </Fragment>
  ));
};
