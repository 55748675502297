import { Button, Flex, Notification, breakpoint, Text } from "@telia/teddy";

type Props = {
  handleClick: () => void;
};

export function ShoppingCartError({ handleClick }: Props) {
  return (
    <Notification variant="warning" maxWidth={breakpoint.teddyBreakpointMd}>
      <Notification.Heading as="h3">
        <Notification.Icon>Noe gikk galt!</Notification.Icon>
      </Notification.Heading>
      <Notification.Text>
        <Flex direction="column" align="start" gap="250">
          <Text>
            Vi kunne dessverre ikke behandle forespørselen din og fikk heller ikke opprettet en manuell sak for
            oppfølging. Vennligst prøv igjen.
          </Text>
          <Button onClick={handleClick}>Prøv igjen</Button>
        </Flex>
      </Notification.Text>
    </Notification>
  );
}
