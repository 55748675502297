// import { OfferingPriceDetails, Maybe } from "src/gql/graphql";
// import { isOfferingPriceDetails } from "src/util/format-subscription/isOfferingPriceDetails";

// export type Quantity = {
//   amount: number;
//   unit: string;
//   customQuantity: string | null;
//   formatted: string | null;
// };

// export function getOfferingPrice(
//   offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined,
//   code: string
// ): Quantity | null {
//   if (isOfferingPriceDetails(offeringPrices)) {
//     return (
//       offeringPrices
//         .filter((p) => p.code === code)
//         .map((p) => ({
//           amount: p?.amount ? p.amount : 0,
//           unit: p?.monetaryUnit || "",
//           customQuantity: null,
//           formatted: null,
//         }))[0] || null
//     );
//   }
//   return null;
// }

import { OfferingPriceDetails, Maybe } from "src/gql/graphql";
import { isOfferingPriceDetails } from "src/util/format-subscription/isOfferingPriceDetails";

export type Quantity = {
  amount: number;
  unit: string;
};

export function getOfferingPrice(
  offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined,
  code: string
): Quantity | null {
  if (!isOfferingPriceDetails(offeringPrices)) {
    return null;
  }

  const match = offeringPrices.find((p) => p?.code === code);

  if (!match) {
    return null;
  }

  return {
    amount: match.amount ?? 0,
    unit: match.monetaryUnit || "",
  };
}
