import { useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import { MoreInfoIcon, useToast } from "@telia-no-min-side/components";
import { Flex, Button, HelperText, InputGroup, Modal, Box } from "@telia/teddy";
import { AdditionalSimCard } from "gql/graphql";
import { useChangeSimCardNickname } from "../../hooks/useChangeSimCardNickname";
import { ModalType } from "../AdditionalSimCard";

type Props = {
  phoneNumber: string;
  simCard: AdditionalSimCard;
  setModalType: Dispatch<SetStateAction<ModalType | undefined>>;
  simCardNickname: string | undefined;
  isSimCardNicknameExist: boolean;
};

export function ChangeSimCardNicknameModal({
  phoneNumber,
  simCard,
  setModalType,
  simCardNickname,
  isSimCardNicknameExist,
}: Props) {
  const [nickname, setNickname] = useState(simCardNickname || "");
  const { addToast } = useToast();

  const { response, runChangeSimCardNickname } = useChangeSimCardNickname({
    onCompleted() {
      addToast({
        text: "Egendefinert kallenavn er registrert. Det vil ta et par minutter før endringen blir registrert.",
        variant: "success",
      });
      setModalType(undefined);
    },
    onError() {
      addToast({
        text: "Beklager, noe gikk galt. Prøv igjen senere",
        variant: "error",
      });
      setModalType(undefined);
    },
  });

  const handleClose = () => {
    setModalType(undefined);
    setNickname("");
  };

  const handleClick = () => {
    if (simCard) {
      runChangeSimCardNickname({
        phoneNumber,
        additionalSimCard: simCard,
        alias: nickname,
      });
    }
  };

  return (
    <Modal open>
      <Modal.Overlay>
        <Modal.Content>
          <Modal.Group variant="title">
            <Modal.Title as="h3">
              {isSimCardNicknameExist ? `Rediger egendefinert kallenavn` : `Gi kallenavn`}
            </Modal.Title>
          </Modal.Group>

          <Flex direction="column" align="start" gap="50" width="100%">
            <Box width="100%" data-di-mask>
              <InputGroup.Input value={nickname} onValueChange={(value) => setNickname(value)} maxLength={12} />
            </Box>
            <HelperText>Maks lengde er 12 karakterer</HelperText>
            <MoreInfoIcon
              label="Hva er et kallenavn?"
              description="Du kan sette et egendefinert kallenavn for å enklere skille mellom simkortene dine."
              position="right"
            />
          </Flex>

          <Modal.Group variant="action">
            <Modal.Close slot="content">
              <Button variant="text" onClick={handleClose}>
                Avbryt
              </Button>
            </Modal.Close>
            <Button loading={response.loading} onClick={handleClick}>
              OK
            </Button>
          </Modal.Group>
        </Modal.Content>
      </Modal.Overlay>
    </Modal>
  );
}
