import { types } from "@telia-no-min-side/utils";
import { LinkProps } from "@telia-no-min-side/components";
import { isInternalLink, getAppSpecificLink } from "./link";

export function getActionLinks(actions?: types.fixed.Product["actions"]): LinkProps[] | undefined {
  if (!actions) return;

  return actions.map((action) => ({
    isInternalNavigation: isInternalLink(action.application),
    href: getAppSpecificLink(action),
    children: action.displayName,
  }));
}
