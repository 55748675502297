import { Link } from "react-router-dom";
import { Flex, Text, Icon, List, IconName, Button } from "@telia/teddy";
import { AdditionalPropertiesCode, VIAPLAY_IMAGES } from "util/format-subscription/variables";
import { useWindowSize } from "@telia-no-min-side/components";

export type IncludedProperty = {
  code: AdditionalPropertiesCode;
  icon: IconName;
  text: string;
};

type Props = {
  isViaplaySubscription: boolean;
  includedProperties: IncludedProperty[];
  changeSubscriptionLink: string;
};

const ViaplayContent = ({ changeSubscriptionLink }: Pick<Props, "changeSubscriptionLink">) => {
  const { isMobile } = useWindowSize();
  return (
    <List isSubcomponent layout="horizontal">
      <Flex width="100%" direction={isMobile ? "column" : "row"} justify="between">
        <Flex gap="gutter-lg" align="center">
          {VIAPLAY_IMAGES.map((image) => (
            <List.Item key={`subscription-card-content-${image.url}`}>
              <List.Illustration>
                <img src={image.url} alt="logo" height={image.height} />
              </List.Illustration>
            </List.Item>
          ))}
        </Flex>
        <Button variant="text" asChild style={{ alignSelf: "end" }}>
          <Link to={changeSubscriptionLink}>Endre</Link>
        </Button>
      </Flex>
    </List>
  );
};

const Content = ({
  includedProperties,
  changeSubscriptionLink,
}: Pick<Props, "includedProperties" | "changeSubscriptionLink">) => {
  return (
    <List isSubcomponent layout="horizontal">
      <Flex width="100%" justify="between" align="center">
        <Flex gap="100">
          {includedProperties
            .filter((properties) => properties.icon)
            .map(({ icon, code }) => (
              <List.Item key={`subscription-card-content-property-${code}`}>
                <List.Illustration>
                  <Flex align="center" gap="25">
                    <Icon name={icon} size="md" />
                    <Text>{code === "EXTRA_SIM" && "x2"}</Text>
                  </Flex>
                </List.Illustration>
              </List.Item>
            ))}
        </Flex>
        <Button variant="text" asChild>
          <Link to={changeSubscriptionLink}>Endre</Link>
        </Button>
      </Flex>
    </List>
  );
};

export function CardContent({ isViaplaySubscription, includedProperties, changeSubscriptionLink }: Props) {
  if (isViaplaySubscription) {
    return <ViaplayContent changeSubscriptionLink={changeSubscriptionLink} />;
  }

  return <Content includedProperties={includedProperties} changeSubscriptionLink={changeSubscriptionLink} />;
}
