import styled from "styled-components";
import { colors } from "@telia/styleguide";
import { remCalc } from "../utils/fonts";
import { devices } from "../utils/breakpoints";

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`;
export const Section = styled.section`
  padding: 0.5rem ${remCalc(10)};
  @media ${devices.sm_down} {
    padding: 0.5rem 0;
  }
`;

export const LineSeparator = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 12px;
  background-color: ${colors.grey300};
`;

export const Li = styled.li`
  margin: 6px 0;
  font-size: ${remCalc(16)};
  display: flex;
  flex-direction: column;
  & > * {
    flex-grow: 1;
  }
  b {
    font-weight: normal;
  }
  strong {
    b {
      font-weight: bold;
    }
  }
`;

export const SecondaryText = styled.p`
  color: ${colors.grey600};
  margin-block: ${remCalc(6)};
  font-size: ${remCalc(12)};
`;
export const SpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
