import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { graphql } from "src/gql";

export type CallForward = {
  active: boolean;
  number: string;
};

export type CallForwarding = {
  callForwardUnconditional: CallForward;
  callForwardWhenBusy: CallForward;
  callForwardWhenNoReply: CallForward;
  callForwardWhenNotReachable: CallForward;
};

export type CallForwardingResponse = {
  subscription: {
    callForwarding: CallForwarding;
  };
};

const GET_CALL_FORWARDING = graphql(`
  query callForwarding($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      callForwarding {
        callForwardUnconditional {
          active
          number
        }
        callForwardWhenBusy {
          active
          number
        }
        callForwardWhenNoReply {
          active
          number
        }
        callForwardWhenNotReachable {
          active
          number
        }
      }
    }
  }
`);

export function useCallForwarding(phoneNumber: string) {
  const [fetchCallForwarding, { loading, error, data }] = useLazyQuery<CallForwardingResponse, { phoneNumber: string }>(
    GET_CALL_FORWARDING,
    {
      variables: { phoneNumber },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (phoneNumber) {
      fetchCallForwarding();
    }
  }, [phoneNumber, fetchCallForwarding]);

  return { data: data?.subscription?.callForwarding, loading, error };
}
