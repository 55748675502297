import React, { InputHTMLAttributes, useContext, useState } from "react";
import { Card } from "../Card";
import { Container } from "../Container";
import { RadioGroupContext, RadioGroupProps } from "../RadioGroup";
import { RadioFieldset, RadioIndicator, RadioInput, RadioLabel, RadioLegend } from "../RadioGroup/style";
import { RadioCardItem } from "./style";

export type RadioCardGroupProps = RadioGroupProps;

/**
 * @deprecated Use Teddy components instead
 */
function RadioCardGroup({
  children,
  visualHideLegend,
  defaultValue,
  name,
  onValueChange,
  legend,
  ...props
}: RadioCardGroupProps) {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(defaultValue || String(props.value));
  function onChange(value: string) {
    onValueChange && onValueChange(value);
    setSelectedValue(value);
  }
  return (
    <RadioGroupContext.Provider value={{ value: selectedValue, name, onChange }}>
      <RadioFieldset {...props}>
        <Container flexDirection="column" gap>
          <RadioLegend isVisuallyHidden={visualHideLegend}>{legend}</RadioLegend>
          <Container maxWidth="xs" flexDirection="column" gap="lg">
            {children}
          </Container>
        </Container>
      </RadioFieldset>
    </RadioGroupContext.Provider>
  );
}

type RadioGroupItemProps = {
  title: string;
  value: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "name" | "value" | "id" | "checked">;

/**
 * @deprecated Use Teddy components instead
 */
function Item({ title, children, ...props }: RadioGroupItemProps) {
  const id = title;
  const { value, onChange, name } = useContext(RadioGroupContext);
  const isChecked = value === props.value;
  return (
    <RadioCardItem onClick={() => onChange(props.value)} isSelected={isChecked}>
      <Card removeMinHeight>
        <RadioInput
          type="radio"
          id={id}
          name={name}
          {...props}
          onChange={(e) => {
            props.onChange && props.onChange(e);
            onChange(props.value);
          }}
          checked={isChecked}
        />
        <Container justifyContent="space-between" gap flexDirection="row">
          <RadioLabel isBold htmlFor={id}>
            <span>{title}</span>
          </RadioLabel>
          <RadioIndicator isSelected={isChecked} />
        </Container>
        {children}
      </Card>
    </RadioCardItem>
  );
}

RadioCardGroup.Item = Item;
export { RadioCardGroup };
