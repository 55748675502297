import { format } from "@telia-no-min-side/utils";
import { L } from "src/util/links";
import { formatDateForSuspendedMessage } from "../utils";
import { UseMobileAccountSubscriptionData } from "src/hooks/useMobileAccount";
import { Flex, Notification, Button, Icon, breakpoint } from "@telia/teddy";
import { Link } from "react-router-dom";
import { useWindowSize } from "@telia-no-min-side/components";

type Props = Pick<
  UseMobileAccountSubscriptionData,
  "productUser" | "phoneNumber" | "subscriptionStatusDate" | "userOffering" | "subscriptionStatus" | "statusReasonCode"
>;

export function NotPayingMessage({ userOffering, subscriptionStatusDate, productUser, phoneNumber }: Props) {
  const userOfferingShortName = userOffering?.shortName || "";
  const invoiceLink = L.Invoice("Gå til faktura");
  const { isMobile } = useWindowSize();

  return (
    <Flex maxWidth={breakpoint.teddyBreakpointMd} p={isMobile ? "200" : "400"} gap="300" direction="column">
      <Notification variant="information" data-tracking-id="alert--suspended-subscription_reason-not-paid">
        <Notification.Heading as="h3">
          <Notification.Icon>Ditt abonnement {userOfferingShortName} er sperret</Notification.Icon>
        </Notification.Heading>
        <Flex direction="column" gap="gutter-md">
          <Notification.Text>
            Abonnementet ditt ble sperret den {formatDateForSuspendedMessage(subscriptionStatusDate || "")} fordi vi
            mangler betaling for utestående, forfalt faktura. For å gjenåpne abonnementet ber vi deg betale det
            utestående beløpet.
          </Notification.Text>
          <Notification.Footer>
            <Flex direction="column" gap="200" align="start">
              <Button asChild>
                <Link to={invoiceLink.href}>
                  <Icon name="invoice" />
                  {invoiceLink.text}
                </Link>
              </Button>
              <Button asChild>
                <Link
                  to={`/minside/mobil/gjenaapne-abonnement?phoneNumber=${
                    phoneNumber.localNumber
                  }&name=${format.getFullName(productUser)}&userOfferingShortName=${userOfferingShortName}`}
                >
                  <Icon name="key" />
                  Jeg har betalt og vil gjenåpne abbonnementet
                </Link>
              </Button>
            </Flex>
          </Notification.Footer>
        </Flex>
      </Notification>
    </Flex>
  );
}
