import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { GeneralSWRResponse, getCustomerSpecificUrl, GetCustomerSpecificUrlArgs, ReturnData } from "./utils";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchProductsAdditionalInfo<T extends GetCustomerSpecificUrlArgs | undefined = undefined>(
  args?: T
): GeneralSWRResponse<T, types.fixed.ProductsAdditionalInfo> {
  const { error, ...rest } = useAxiosSWR<ReturnData<T, types.fixed.ProductsAdditionalInfo>>(
    getCustomerSpecificUrl(ICX_REQUEST.URL_PRODUCTS_ADDITIONAL_INFO, args)
  );

  return {
    isError: !!error,
    error: error,
    ...rest,
  };
}
