import { DashboardSection } from "./DashboardSection";
import { ErrorCard, ErrorCardProps } from "./ErrorCard";

type Props = {
  title: string;
} & ErrorCardProps;

export function ErrorSection({ title, isLoading, onRefreshClick, sectionText }: Props) {
  return (
    <DashboardSection title={title}>
      <ErrorCard isLoading={isLoading} onRefreshClick={onRefreshClick} sectionText={sectionText} />
    </DashboardSection>
  );
}
