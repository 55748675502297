import dayjs from "dayjs";
import { Card, Text, Heading, Icon, Button, Box, ProgressBar, Flex, Badge, Skeleton } from "@telia/teddy";
import { GetPrepaidUsagesQuery, UserTopupQuotaTypeEnum } from "gql/graphql";
import { MainCardContainer, HeaderFlexContainer } from "./style";
import { getDataDetailedUsage } from "../utils/usage";

type Props = {
  loading: boolean;
  usageTopupData: GetPrepaidUsagesQuery["subscription"]["usageTopup"];
  topupLink: string;
};

export function DataUsage({ loading, usageTopupData, topupLink }: Props) {
  const { balance, usages } = getDataDetailedUsage(usageTopupData);
  return (
    <MainCardContainer variant="white" bordered shadow>
      <HeaderFlexContainer direction="row" width="100%" justify="between" align="end">
        <div>
          <Text>Data</Text>
          <Skeleton loading={loading}>
            <Heading as="h1" variant="title-400">
              {balance}
            </Heading>
          </Skeleton>
        </div>
      </HeaderFlexContainer>
      {loading && (
        <Card.Content>
          <Card.Line />
          <Box mt="300" mb="300" key={"loading"}>
            <Skeleton width="200px" height="20px" mb="100" />
            <Skeleton width="100%" height="20px" mb="100" />
            <Skeleton width="100px" height="20px" />
          </Box>
        </Card.Content>
      )}
      {usages?.length > 0 && (
        <Card.Content>
          <Box>
            {usages.map((usage, i) => (
              <Box key={`${usage?.service}-${i}`}>
                <Card.Line />
                <Box mt="100" mb="200">
                  {usage.total && usage.remaining && (
                    <Box mt="300" mb="150">
                      <Text mb="100">
                        {usage.remaining} igjen av {usage.total}
                      </Text>
                      <ProgressBar progress={usage.percentage} />
                    </Box>
                  )}
                  <Flex>
                    {usage.type === UserTopupQuotaTypeEnum.Base && (
                      <Box mr="100">
                        <Badge variant="special">Inkludert data</Badge>
                      </Box>
                    )}
                    {!!usage?.expireTime && (
                      <Badge variant="information">Utgår om {dayjs(usage?.expireTime).toNow(true)}</Badge>
                    )}
                  </Flex>
                </Box>
              </Box>
            ))}
          </Box>
          <Flex justify="end">
            <Skeleton loading={loading}>
              <Button variant="primary" asChild>
                <a href={topupLink} target="_blank" rel="noreferrer">
                  Kjøp datapakker <Icon name="arrow-right" />
                </a>
              </Button>
            </Skeleton>
          </Flex>
        </Card.Content>
      )}
    </MainCardContainer>
  );
}
