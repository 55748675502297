export type Breakpoints = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export const breakpoints = {
  xxs: 400,
  xs: 500,
  sm: 600,
  md: 840,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
} as const;

export const devices = {
  xxs_down: `(max-width: ${breakpoints.xxs - 1}px)`,
  xxs_up: `(min-width: ${breakpoints.xxs}px)`,
  xs_down: `(max-width: ${breakpoints.xs - 1}px)`,
  xs_up: `(min-width: ${breakpoints.xs}px)`,
  sm_down: `(max-width: ${breakpoints.sm - 1}px)`,
  sm_up: `(min-width: ${breakpoints.sm}px)`,
  md_down: `(max-width: ${breakpoints.md - 1}px)`,
  md_up: `(min-width: ${breakpoints.md}px)`,
  lg_down: `(max-width: ${breakpoints.lg - 1}px)`,
  lg_up: `(min-width: ${breakpoints.lg}px)`,
  xl_down: `(max-width: ${breakpoints.xl - 1}px)`,
  xl_up: `(min-width: ${breakpoints.xl}px)`,
  xxl_down: `(max-width: ${breakpoints.xxl - 1}px)`,
  xxl_up: `(min-width: ${breakpoints.xxl}px)`,
};
