import { Card, Container, Icon, Paragraph } from "@telia-no-min-side/components";

type Props = {
  title: string;
  currentDate: number | undefined;
  newDate: number | undefined;
};

export function ChangeInfoCard({ title, currentDate, newDate }: Props) {
  return (
    <Container>
      <Paragraph>
        <strong>{title}</strong>
      </Paragraph>

      <Card removeMinHeight backgroundColor="grey" showShadow={false}>
        <Container flexDirection="row" justifyContent="space-between">
          <Paragraph removeMargin>{currentDate}. hver måned</Paragraph>

          <Icon icon="arrow-right" />

          <Paragraph removeMargin>
            <strong>{newDate}. hver måned</strong>
          </Paragraph>
        </Container>
      </Card>
    </Container>
  );
}
