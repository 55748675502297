import { graphql } from "src/gql";

export const GET_COUNTRIES = graphql(`
  query getCountriesNEW {
    countries {
      name
      countryCode
      prefix
    }
  }
`);
