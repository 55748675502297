import { useState } from "react";

const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const getCurrentPageData = (dataList: object[]) => {
    return dataList.slice(startIndex, endIndex);
  };

  function onPageChange(forward: boolean) {
    if (forward) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }

  return {
    currentPage,
    setCurrentPage,
    startIndex,
    endIndex,
    getCurrentPageData,
    onPageChange,
    itemsPerPage,
  };
};

export default usePagination;
