import dayjs from "dayjs";

export function isCommitted(commitmentEndDate: string | null): boolean {
  // Date format '2022-03-23'
  return dayjs().diff(commitmentEndDate, "hours") < 0;
}

export function committedEndDate(commitmentEndDate: string | null) {
  if (!commitmentEndDate) return null;

  const today = dayjs();
  const endDate = dayjs(commitmentEndDate);

  if (endDate.isAfter(today)) {
    return endDate.format("DD.MM.YY");
  } else {
    return null; // Return null if the commitment end date is in the past
  }
}
