export const purposesData = [
  {
    title: "Kundeforståelse, innsikt og tilpasset innhold",
    description:
      "Telia kan behandle personopplysninger for å forstå kundene våre bedre, som for eksempel å forstå hvem som bruker appene våre slik at vi kan tilpasse innholdet til ulike kundegrupper.",
    groupLabel: undefined,
    purposes: [
      {
        id: "TN_PU_100000040",
        label:
          "Jeg ønsker ikke at Telia bruker mine opplysninger for å få økt kundeforståelse og gi tilpasset innhold.",
      },
    ],
    isConsent: false,
  },
  {
    title: "Tjeneste- og produktforbedring",
    description:
      "Telia kan bruke personopplysninger for å forbedre tjenester og produkter som du har hos oss, som eksempel å tilby nye meldingstjenester eller tilby abonnementer med fri databruk for strømming av musikk eller video.",
    groupLabel: undefined,
    purposes: [
      {
        id: "TN_PU_100000046",
        label: "Jeg ønsker ikke at Telia bruker mine opplysninger for tjeneste og produktforbedring.",
      },
    ],
    isConsent: false,
  },
  {
    title: "Markedsføring",
    description:
      "Telia bruker ulike kanaler for å fortelle om tilbud og andre fordeler, for eksempel når vi har kampanjer på mobiltelefoner eller vi lanserer nye tjenester.",
    groupLabel: "Jeg ønsker ikke at Telia bruker følgende kanaler til markedsføring:",
    purposes: [
      {
        id: "TN_PU_100000078",
        label: "E-post",
      },
      {
        id: "TN_PU_100000076",
        label: "SMS",
      },
      {
        id: "TN_PU_100000077",
        label: "Telefon",
      },
    ],
    isConsent: false,
  },
  {
    title: "Deling av opplysninger",
    description:
      "Ved å dele noen opplysninger om deg med utvalgte mediepartnere, får du nyttig informasjon og skreddersydde tilbud på f.eks på sosial medier. Du kan være trygg på at dataene dine slettes av mediepartnerne våre etter bruk.",
    groupLabel: undefined,
    purposes: [
      {
        id: "TN_PU_100000150",
        label: "Ikke la Telia dele informasjon med utvalgte mediepartnere",
      },
    ],
    isConsent: true,
  },
];
