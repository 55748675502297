import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { getCustomerSpecificUrl, GetCustomerSpecificUrlArgs, GeneralSWRResponse, ReturnData } from "./utils";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

const urlProductGroupParam = "?groupTags=";

type ProductGroup =
  | "grp_smart_wifi"
  | "grp_remote_control_decoder"
  | "grp_new_tv_box"
  | "grp_tv_fwa"
  | "grp_tv_over_fwa"
  | "grp_opptak";

export function useFetchProductGroup<T extends GetCustomerSpecificUrlArgs | undefined = undefined>(
  productGroup: ProductGroup,
  args?: T
): GeneralSWRResponse<T, types.fixed.ProductGroupWithShipping> {
  const { error, ...rest } = useAxiosSWR<ReturnData<T, types.fixed.ProductGroupWithShipping>>(
    `${getCustomerSpecificUrl(ICX_REQUEST.URL_PRODUCT_GROUP, args)}${urlProductGroupParam}${productGroup}`
  );
  return {
    isError: !!error,
    error: error,
    ...rest,
  };
}
