import styled from "styled-components";
import { breakpoints, LinkGoBack, remCalc, devices } from "@telia-no-min-side/components";

export const Column = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: ${breakpoints.lg}px;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 2 1 ${breakpoints.xs}px;
`;

export const SummaryWrapper = styled.div`
  flex: 1 1 330px;
`;

export const SpaceBottomWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const CancelLink = styled(LinkGoBack)`
  margin-right: 1rem;
`;

export const InfoTitle = styled.div`
  margin: 0;
  font-size: ${remCalc(14)};
  font-weight: 700;
  line-height: ${remCalc(20)};
`;

export const InfoText = styled.p`
  margin: 0;
  margin-bottom: ${remCalc(14)};
  font-size: ${remCalc(12)};
  line-height: ${remCalc(20)};
`;

export const QuantitySelectorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
`;

export const SpecGrid = styled.div`
  display: grid;
  grid-template-columns: 9fr 25fr;
  margin-right: 4px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.85fr auto;
  align-items: top;
  gap: 1rem;
  @media ${devices.xxs_down} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const SpecWrapper = styled.div`
  grid-area: 2 / 1 / 2 / 3;
`;
