import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { Button } from "../Button";
import { devices } from "../utils/breakpoints";
import { zIndex } from "./../utils/variables";
import { HorizontalScrollProps } from ".";

const shadowWidth = 8;

export const Container = styled.div<{ margin?: string }>`
  position: relative;
  margin-inline: calc(${(props) => props.margin});
`;

const hideScrollbar = css`
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
`;

export const ScrollView = styled.div`
  overflow-x: scroll;
  position: relative;
  scroll-snap-type: x mandatory;
  display: grid;
  grid-template-columns: max-content;
  ${hideScrollbar}
`;

type ChildrenProps = Required<Pick<HorizontalScrollProps, "initialMargin" | "reversScrollOrder">>;

export const Children = styled.div<ChildrenProps>`
  display: flex;
  flex-direction: ${(props) => (props.reversScrollOrder ? "row-reverse" : "row")};
  flex-shrink: 1;

  ::before {
    content: "";
    min-width: calc(${(props) => props.initialMargin});
    min-height: 100%;
  }
  ::after {
    content: "";
    min-width: calc(${(props) => props.initialMargin});
    min-height: 100%;
  }
`;

const NavigationButton = styled(Button)`
  background-color: #ffffff;
  color: ${colors.grey900};
  position: absolute;
  top: 50%;
  z-index: ${zIndex.toolTip};
  -webkit-tap-highlight-color: transparent;
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
  ::after {
    border-radius: inherit;
    box-shadow: 0px 2px ${shadowWidth}px rgba(0, 0, 0, 0.2);
    inset: 0;
    content: "";
    display: block;
    position: absolute;
  }

  svg {
    height: 25px;
    width: 25px;
  }

  @media ${devices.md_down} {
    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const LeftNavigation = styled(NavigationButton)<{ hide: boolean }>`
  opacity: ${(props) => (props.hide ? 0 : 1)};
  left: 10px;
  transform: translate(${(props) => (props.hide ? -50 : 0)}px, -50%);
`;

export const RightNavigation = styled(NavigationButton)<{ hide: boolean }>`
  opacity: ${(props) => (props.hide ? 0 : 1)};
  right: 10px;
  transform: translate(${(props) => (props.hide ? 50 : 0)}px, -50%);
`;

const commonShadowStyling = css`
  z-index: 1;
  content: "";
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  width: 20%;
  max-width: 100px;
  min-width: 60px;
  transition-property: left, right, opacity;
  transition-duration: 500ms;
  transition-timing-function: ease;
`;

export const Shadow = styled.div<{
  showLeftShadow: boolean;
  showRightShadow: boolean;
  hideBackdrop: boolean;
}>`
  position: relative;
  &::before {
    left: 0px;
    transform: translateX(${(props) => (props.showLeftShadow ? 0 : -150)}px);
    opacity: ${(props) => (props.showLeftShadow ? 1 : 0)};
    display: ${(props) => (props.hideBackdrop ? "none" : "block")};
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
    ${commonShadowStyling}
  }
  &::after {
    opacity: ${(props) => (props.showRightShadow ? 1 : 0)};
    display: ${(props) => (props.hideBackdrop ? "none" : "block")};
    right: 0px;
    transform: translateX(${(props) => (props.showRightShadow ? 0 : 150)}px);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 100%);
    ${commonShadowStyling}
  }
`;
