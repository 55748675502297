import { useMutation } from "@apollo/client";
import { graphql } from "gql";
import {
  AnswerChallengeMutation,
  AnswerChallengeMutationVariables,
  SendChallengeMutation,
  SendChallengeMutationVariables,
} from "gql/graphql";

export const SEND_CHALLENGE = graphql(`
  mutation sendChallenge($destination: String, $channel: String) {
    sendChallenge(destination: $destination, channel: $channel) {
      id
    }
  }
`);

export const ANSWER_CHALLENGE = graphql(`
  mutation answerChallenge($challengeId: String, $challengeAnswer: String) {
    answerChallenge(challengeId: $challengeId, challengeAnswer: $challengeAnswer) {
      accepted
    }
  }
`);

export function useSendChallenge() {
  const [sendChallenge, sendChallengeResponse] = useMutation<SendChallengeMutation, SendChallengeMutationVariables>(
    SEND_CHALLENGE
  );

  const runSendChallenge = async ({ destination, channel }: SendChallengeMutationVariables) => {
    try {
      const challenge: SendChallengeMutationVariables = {
        destination,
        channel,
      };
      await sendChallenge({ variables: challenge });
    } catch (error) {
      console.error(error);
    }
  };

  return { sendChallengeResponse, runSendChallenge };
}

export function useAnswerChallenge() {
  const [answerChallenge, answerChallengeResponse] = useMutation<
    AnswerChallengeMutation,
    AnswerChallengeMutationVariables
  >(ANSWER_CHALLENGE);

  const runAnswerChallenge = async ({ challengeId, challengeAnswer }: AnswerChallengeMutationVariables) => {
    try {
      const challenge: AnswerChallengeMutationVariables = {
        challengeId,
        challengeAnswer,
      };
      await answerChallenge({ variables: challenge });
    } catch (error) {
      console.error(error);
    }
  };

  return { answerChallengeResponse, runAnswerChallenge };
}
