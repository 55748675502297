import { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import { getCookieValue } from "@telia-no-min-side/utils";
import { useLocalStorage, useAuth, Container } from "@telia-no-min-side/components";
import { FakeloginForm, HideButton } from "./style";
import { useOnFixedAccountChange } from "src/hooks/useFixedAccount";
import { AUTH } from "util/constants/auth";
import { COOKIE } from "util/constants/cookies";
import { Toggletip, Box, Button, Icon, Text, ProgressBar, Heading, Input, Flex } from "@telia/teddy";

const formatTimestamp = (timestamp: number): string => {
  return new Date(timestamp * 1000).toLocaleTimeString("no-NO", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export function FakeLogin() {
  const {
    setAuthAdapter,
    setFakeLoginAsCustomerId,
    selectedAuthProvider,
    requestBankIdWithMaxAge,
    accessToken,
    tokenParsed,
  } = useAuth();
  const [hideFakeLogin, setHideFakeLogin] = useState(true);
  const { onFixedAccountChange } = useOnFixedAccountChange();
  const currentSelectedCustomerId = getCookieValue(COOKIE.FIXED_SELECTED_ACCOUNT);
  const fakeLoginAsHistory = useLocalStorage<string[]>("fakeLoginAsHistory");
  const [fakeLoginAs, setFakeLoginAs] = useState(currentSelectedCustomerId || "f498776b-d863-4a0a-89e2-ee471024534f");
  const [isValid, setIsValid] = useState(true);
  const [maxAge, setMaxAge] = useState(120);
  const [timeLeft, setTimeLeft] = useState(0);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const authTime = tokenParsed?.auth_time;

  useEffect(() => {
    const checkValidity = () => {
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime = currentTime - (authTime || 0);
      const remainingTime = maxAge - elapsedTime;

      setTimeLeft(remainingTime);
      setIsValid(remainingTime > 0);
      if (remainingTime <= 0) {
        sessionStorage.removeItem("temp");
      }

      // Stop the interval when session becomes invalid
      if (remainingTime <= 0 && intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };

    // Run the validity check immediately
    checkValidity();

    // Only set the interval if the session is valid
    if (isValid && !intervalRef.current) {
      intervalRef.current = setInterval(checkValidity, 1000);
    }

    // Cleanup function to clear the interval when the component unmounts or dependencies change
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
  }, [authTime, maxAge, isValid]);

  const progress = Math.max(0, (timeLeft / maxAge) * 100);

  useEffect(() => {
    if (
      (accessToken && selectedAuthProvider === AUTH.PROVIDER.CIAM) ||
      selectedAuthProvider === AUTH.PROVIDER.FAKELOGIN
    ) {
      onFixedAccountChange();
    }
  }, [accessToken, selectedAuthProvider]);

  function onSubmit(value?: string, e?: BaseSyntheticEvent) {
    e?.preventDefault();
    onFixedAccountChange();
    if (value) {
      setFakeLoginAsCustomerId(value);

      const currentFakeLoginAsHistory = fakeLoginAsHistory.value ?? [];
      const fakeLoginValueExist = currentFakeLoginAsHistory.includes(value);
      if (fakeLoginValueExist) return;
      fakeLoginAsHistory.setValue([value, ...currentFakeLoginAsHistory]);
    }
  }

  return (
    <FakeloginForm fadeOut={hideFakeLogin} autoComplete="on" onSubmit={(e) => onSubmit(fakeLoginAs, e)}>
      <HideButton rotateLeft={hideFakeLogin}>
        <Button
          iconOnly
          type="button"
          variant="primary"
          size="sm"
          onClick={() => {
            setHideFakeLogin((prev) => !prev);
          }}
          aria-label="pil"
        >
          <Icon name="arrow-right" />
        </Button>
      </HideButton>
      <label htmlFor="fakeloginas">Log in with customer id / party id</label>
      <Container flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box p="50" m="0" asChild>
          <Input
            id="fakeloginas"
            type="text"
            name="customerid"
            autoComplete="customerid"
            value={fakeLoginAs}
            onChange={(e) => setFakeLoginAs(e.currentTarget.value)}
          />
        </Box>
        <Button disabled={selectedAuthProvider !== AUTH.PROVIDER.FAKELOGIN} variant="primary" size="sm" type="submit">
          Set customer
        </Button>
      </Container>
      <label htmlFor="fakeloginas-history">
        <span>History </span>
        <select
          id="fakeloginas-history"
          onChange={(e) => {
            setFakeLoginAs(e.currentTarget.value);
            onSubmit(e.currentTarget.value);
          }}
          value={fakeLoginAs}
        >
          {fakeLoginAsHistory.value?.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </label>
      <Button
        variant="destructive"
        size="sm"
        onClick={() => {
          const deletedIndex = fakeLoginAsHistory.value?.findIndex((v) => v === fakeLoginAs);
          fakeLoginAsHistory.setValue(fakeLoginAsHistory.value?.filter((v) => v !== fakeLoginAs));
          setFakeLoginAs(
            (typeof deletedIndex === "number" && fakeLoginAsHistory.value?.at(deletedIndex + 1)) ||
              "f498776b-d863-4a0a-89e2-ee471024534f"
          );
        }}
      >
        Delete customer(!)
      </Button>
      <label htmlFor="fakeloginas">
        Use common login
        <input
          type="checkbox"
          checked={selectedAuthProvider === AUTH.PROVIDER.CIAM}
          onChange={() => {
            setAuthAdapter(selectedAuthProvider === AUTH.PROVIDER.CIAM ? AUTH.PROVIDER.FAKELOGIN : AUTH.PROVIDER.CIAM);
          }}
        />
      </label>
      <Heading as="h3" variant="title-100">
        Token
      </Heading>
      <Toggletip modal>
        <Toggletip.Trigger asChild>
          <Button disabled={!tokenParsed} variant="tertiary-purple" size="sm">
            Show info
          </Button>
        </Toggletip.Trigger>
        <Toggletip.Content m={"100"} style={{ zIndex: 100000 }}>
          <Toggletip.Heading>Token</Toggletip.Heading>
          <Text>Token ACR: {tokenParsed?.acr}</Text>
          <Text>Token ATUH TIME: {tokenParsed?.auth_time && formatTimestamp(tokenParsed?.auth_time)}</Text>
          <Text>Token expiry: {tokenParsed?.exp && formatTimestamp(tokenParsed?.exp)}</Text>
          <Text>Issued at: {tokenParsed?.iat && formatTimestamp(tokenParsed?.iat)}</Text>
          <Text>AMR: {tokenParsed?.amr}</Text>
          <Text>IAL: {tokenParsed?.["urn:teliacompany:dis:ciam:1.0:ial"]}</Text>
        </Toggletip.Content>
      </Toggletip>
      <Flex align="center" gap={"100"}>
        <Text variant="paragraph-100-medium">Token with max age </Text>
        <Box p="50" m="0" asChild>
          <Input value={maxAge} type="number" onValueChange={(v) => setMaxAge(Number(v))} />
        </Box>
      </Flex>
      {isValid && `Session Valid: ${formatTime(timeLeft)}`}
      {isValid && <ProgressBar progress={progress} />}
      {!isValid && (
        <Button
          onClick={() => {
            sessionStorage.setItem("temp", maxAge.toString());
            requestBankIdWithMaxAge && requestBankIdWithMaxAge(maxAge);
          }}
          size="sm"
          variant="tertiary-purple"
        >
          Trigger bankid with max age {maxAge}s
        </Button>
      )}
    </FakeloginForm>
  );
}
