import { Breadcrumbs, Box } from "@telia/teddy";
import { Link, useMatches, type UIMatch } from "react-router-dom";
import { Fragment, ReactNode } from "react";

type Handle = {
  crumb?: ReactNode;
};

const useTypedMatches = <TData,>() => useMatches() as UIMatch<TData, Handle>[];

export function Breadcrumb() {
  const matches = useTypedMatches();
  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => ({
      text: match.handle.crumb,
      path: match.pathname,
    }));

  if (crumbs.length === 0) {
    return null;
  }

  return (
    <Box pt="200" pb="200">
      <Breadcrumbs>
        <Breadcrumbs.List>
          {crumbs.map(({ text, path }, index) => {
            const isFirst = index === 0;
            const isLast = crumbs.length === index + 1;

            if (isLast) {
              return (
                <Breadcrumbs.Item key={path}>
                  <Breadcrumbs.Page>{text}</Breadcrumbs.Page>
                </Breadcrumbs.Item>
              );
            }

            return (
              <Fragment key={path}>
                <Breadcrumbs.Item>
                  <Breadcrumbs.Link asChild home={isFirst}>
                    <Link to={path}>{text}</Link>
                  </Breadcrumbs.Link>
                </Breadcrumbs.Item>
                <Breadcrumbs.Separator />
              </Fragment>
            );
          })}
        </Breadcrumbs.List>
      </Breadcrumbs>
    </Box>
  );
}
