import { ApolloClient, useApolloClient, useQuery } from "@apollo/client";
import { track } from "@telia-no-min-side/utils";
import { useContext, useState } from "react";
import { graphql } from "src/gql";
import { PaymentProvider } from "src/gql/graphql";
import { ResultMessageContext } from "src/util/ResultMessageContext";
import { PAY_INVOICE_BALANCE } from "../mutations";

const payByCardMutation = async (client: ApolloClient<object>, accountId: string, amount?: number) => {
  const rawResult = await client.mutate({
    mutation: PAY_INVOICE_BALANCE,
    variables: {
      input: {
        accountId,
        amount,
        provider: PaymentProvider.Nets,
        returnUrl: `${window.location.origin}/minside/faktura/verifiser-betaling`,
      },
    },
  });
  return rawResult.data;
};

export const INVOICE_BALANCE = graphql(`
  query InvoiceBalanceQuery($accountId: String!) {
    account(accountId: $accountId) {
      id
      paymentBalance {
        amount
      }
      invoices(take: 5) {
        paid
        amount {
          value
          currency
        }
      }
    }
  }
`);

const usePayByCard = (accountId: string) => {
  const [paymentLoading, setPaymentLoading] = useState(false);
  const client = useApolloClient();
  const context = useContext(ResultMessageContext);
  const accountBalance = useQuery(INVOICE_BALANCE, { variables: { accountId } });
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const unpaidInvoice = accountBalance?.data?.account?.invoices?.filter((invoice) => !invoice.paid);
  const oldestUnpaidInvoice = unpaidInvoice?.findLast((invoice) => !invoice.paid);
  const hasMultipleUnpaidInvoices = (unpaidInvoice?.length || 0) > 1;

  const payByCard = async (amount?: number) => {
    try {
      setPaymentLoading(true);
      const result = await payByCardMutation(client, accountId, amount);
      const redirectToPayment = result?.payInvoiceBalance?.redirectUrl || "";
      pushGenericTrackingEvent({
        ui_item_action: "submit payment",
        ui_item_context: "pay invoice",
        ui_item_text: "SUCCESS",
        ui_item_type: "api response",
        ui_item_url: "no_url",
      });
      if (redirectToPayment) {
        window.location.assign(redirectToPayment);
      }
    } catch (error) {
      // Quick fix, to be removed
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((error as any)?.graphQLErrors?.[0]?.message === "ANOTHER_PAYMENT_IN_PROGRESS") {
        context?.setInvoicePaymentResult("ANOTHER_PAYMENT_IN_PROGRESS");
        pushGenericTrackingEvent({
          ui_item_action: "submit payment",
          ui_item_context: "pay invoice",
          ui_item_text: "ANOTHER_PAYMENT_IN_PROGRESS",
          ui_item_type: "api response",
          ui_item_url: "no_url",
        });
      } else {
        context?.setInvoicePaymentResult("FAILED");
        pushGenericTrackingEvent({
          ui_item_action: "submit payment",
          ui_item_context: "pay invoice",
          ui_item_text: "FAILED",
          ui_item_type: "api response",
          ui_item_url: "no_url",
        });
      }
    } finally {
      setPaymentLoading(false);
    }
  };

  return {
    payByCard,
    paymentLoading,
    oldestUnpaidInvoice,
    hasMultipleUnpaidInvoices,
    accountBalance: accountBalance.data?.account.paymentBalance?.amount,
  };
};

export default usePayByCard;
