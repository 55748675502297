import { Button, Container, RadioGroup, TextInput, Heading, useToast } from "@telia-no-min-side/components";
import { validationPatterns } from "@telia-no-min-side/utils";
import { OrderAddressInput, OrderAddressType } from "gql/graphql";
import { SimCheckoutView, useSimCheckout } from "pages/mobile/mobile-subscription-sim-pin-puk/hooks/useSimCheckout";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  ADDRESS_MESSAGE,
  URBAN_ADDRESS_FIELD_EN,
  PO_BOX_FIELD_EN,
  URBAN_ADDRESS_FIELD_NO,
  PO_BOX_FIELD_NO,
} from "util/constants/address";

function cleanupDeliveryAddress(deliveryAddress: OrderAddressInput): OrderAddressInput {
  // delete fields that are not relevant for the selected address type
  if (deliveryAddress.addressType === OrderAddressType.Pobox) {
    return {
      addressType: OrderAddressType.Pobox,
      boxNr: deliveryAddress.boxNr,
      boxName: deliveryAddress.boxName,
      postcode: deliveryAddress.postcode,
      city: deliveryAddress.city,
      iso3countryCode: deliveryAddress.iso3countryCode,
    };
  }

  return {
    addressType: OrderAddressType.Urban,
    streetName: deliveryAddress.streetName,
    streetNr: deliveryAddress.streetNr,
    streetNrSuffix: deliveryAddress.streetNrSuffix,
    floorNumber: deliveryAddress.floorNumber,
    doorNumber: deliveryAddress.doorNumber,
    careOfName: deliveryAddress.careOfName,
    postcode: deliveryAddress.postcode,
    city: deliveryAddress.city,
    iso3countryCode: deliveryAddress.iso3countryCode,
  };
}

export function ChangeAddressForm() {
  const { addToast } = useToast();
  const { deliveryAddress, setDeliveryAddress, setCheckoutView } = useSimCheckout();

  const defaultValues: Partial<OrderAddressInput> = { ...deliveryAddress };

  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<OrderAddressInput>({ defaultValues });

  const addressType = watch("addressType");
  const isUrbanAddress = addressType === OrderAddressType.Urban;
  const isPoboxAddress = addressType === OrderAddressType.Pobox;

  const onReset = () => {
    reset({ ...deliveryAddress });
    setCheckoutView(SimCheckoutView.Cart);
  };

  const onSubmit: SubmitHandler<OrderAddressInput> = (address) => {
    const cleanAddress = cleanupDeliveryAddress(address);
    setDeliveryAddress(cleanAddress);
    addToast({ text: ADDRESS_MESSAGE.ADDRESS_IS_UPDATED, variant: ADDRESS_MESSAGE.SUCCESS });
    setCheckoutView(SimCheckoutView.Cart);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container flexDirection="column" gap="lg">
        <Heading tag="h2" variant="title-100" removeMargin>
          {ADDRESS_MESSAGE.CHANGE_YOUR_DELIVERY_ADDRESS}
        </Heading>

        <Controller
          name="addressType"
          control={control}
          render={({ field }) => (
            <RadioGroup
              legend={ADDRESS_MESSAGE.CHANGE_YOUR_DELIVERY_ADDRESS}
              defaultValue={field.value as string}
              visualHideLegend
              name={field.name}
              onValueChange={field.onChange}
              itemAlignment="row"
            >
              <RadioGroup.Item title={URBAN_ADDRESS_FIELD_NO.STREET_ADDRESS} value={OrderAddressType.Urban}>
                {URBAN_ADDRESS_FIELD_NO.STREET_ADDRESS}
              </RadioGroup.Item>
              <RadioGroup.Item title={PO_BOX_FIELD_NO.ADDRESS} value={OrderAddressType.Pobox}>
                {PO_BOX_FIELD_NO.ADDRESS}
              </RadioGroup.Item>
            </RadioGroup>
          )}
        />

        {isUrbanAddress && (
          <>
            <TextInput
              {...register(URBAN_ADDRESS_FIELD_EN.STREET_NAME, {
                required: ADDRESS_MESSAGE.REQUIRED_FIELD,
              })}
              required={isUrbanAddress}
              errorMessage={errors.streetName?.message}
              data-di-mask
            >
              {URBAN_ADDRESS_FIELD_NO.STREET_NAME}
            </TextInput>

            <TextInput
              {...register(URBAN_ADDRESS_FIELD_EN.STREET_NUMBER, {
                required: ADDRESS_MESSAGE.REQUIRED_FIELD,
              })}
              required={isUrbanAddress}
              errorMessage={errors.streetNr?.message}
              data-di-mask
            >
              {URBAN_ADDRESS_FIELD_NO.STREET_NUMBER}
            </TextInput>

            <TextInput
              {...register(URBAN_ADDRESS_FIELD_EN.STREET_NUMBER_SUFFIX)}
              errorMessage={errors.streetNrSuffix?.message}
              data-di-mask
            >
              {URBAN_ADDRESS_FIELD_NO.STREET_NUMBER_SUFFIX}
            </TextInput>

            <TextInput
              {...register(URBAN_ADDRESS_FIELD_EN.FLOOR_NUMBER)}
              errorMessage={errors.floorNumber?.message}
              data-di-mask
            >
              {URBAN_ADDRESS_FIELD_NO.FLOOR_NUMBER}
            </TextInput>

            <TextInput
              {...register(URBAN_ADDRESS_FIELD_EN.DOOR_NUMBER)}
              errorMessage={errors.doorNumber?.message}
              data-di-mask
            >
              {URBAN_ADDRESS_FIELD_NO.DOOR_NUMBER}
            </TextInput>
          </>
        )}

        {isPoboxAddress && (
          <>
            <TextInput
              {...register(PO_BOX_FIELD_EN.BOX_NAME, {
                required: ADDRESS_MESSAGE.REQUIRED_FIELD,
              })}
              required={isPoboxAddress}
              errorMessage={errors.boxName?.message}
              data-di-mask
            >
              {PO_BOX_FIELD_NO.BOX_NAME}
            </TextInput>

            <TextInput
              {...register(PO_BOX_FIELD_EN.BOX_NUMBER, {
                required: ADDRESS_MESSAGE.REQUIRED_FIELD,
                pattern: { ...validationPatterns.numbersOnly },
              })}
              required={isPoboxAddress}
              errorMessage={errors.boxNr?.message}
              data-di-mask
            >
              {PO_BOX_FIELD_NO.BOX_NUMBER}
            </TextInput>
          </>
        )}

        <TextInput
          {...register(URBAN_ADDRESS_FIELD_EN.POST_CODE, {
            required: ADDRESS_MESSAGE.REQUIRED_FIELD,
            pattern: { ...validationPatterns.postalCode },
          })}
          required
          errorMessage={errors.postcode?.message}
          data-di-mask
        >
          {URBAN_ADDRESS_FIELD_NO.ZIP_CODE}
        </TextInput>

        <TextInput
          {...register(PO_BOX_FIELD_EN.CITY, {
            required: ADDRESS_MESSAGE.REQUIRED_FIELD,
          })}
          required
          errorMessage={errors.city?.message}
          data-di-mask
        >
          {PO_BOX_FIELD_NO.CITY}
        </TextInput>

        {isUrbanAddress && (
          <TextInput
            {...register(URBAN_ADDRESS_FIELD_EN.CARE_OF_NAME)}
            errorMessage={errors.careOfName?.message}
            data-di-mask
          >
            {URBAN_ADDRESS_FIELD_NO.CARE_OF_NAME}
          </TextInput>
        )}

        <Container gap="lg">
          <Button onClick={onReset} variant="text-purple">
            Avbryt
          </Button>

          <Button type="submit" variant="primary">
            {ADDRESS_MESSAGE.SAVE_ADDRESS}
          </Button>
        </Container>
      </Container>
    </form>
  );
}
