import { useNavigate } from "react-router-dom";
import { Card, Badge, Text, Icon } from "@telia/teddy";
import { uri } from "@telia-no-min-side/utils";
import { UserRole } from "src/gql/graphql";
import { useMobileAccount } from "hooks/useMobileAccount";
import { useSubscriptionOverview } from "../hooks/useSubscriptionOverview";

export function MobileSubAdminCard() {
  const navigate = useNavigate();
  const subscriptionOverview = useSubscriptionOverview();
  const { setAccountId } = useMobileAccount();
  const legalOwnerSubscriptions = subscriptionOverview.mobileSubscriptionsOverview.filter((subscription) =>
    subscription.account.roles.includes(UserRole.LegalOwner)
  );

  if (!legalOwnerSubscriptions.length) return null;

  const hasMultipleLegalOwnerSubscriptions = legalOwnerSubscriptions.length > 1;
  const firstLegalOwnerSubscription = legalOwnerSubscriptions[0];
  const { id: accountId } = firstLegalOwnerSubscription.account;
  const phoneNumber = firstLegalOwnerSubscription.phoneNumber.localNumber;

  const handleClick = () => {
    setAccountId(accountId);
    const targetPath = hasMultipleLegalOwnerSubscriptions
      ? "/mobil/mine-abonnement"
      : `/mobil/endre/${accountId}/${phoneNumber}`;
    navigate(uri.minSideFixed(targetPath));
  };

  return (
    <Card
      shadow
      bordered
      width="100%"
      maxWidth="400px"
      layout="default"
      variant="white"
      onClick={handleClick}
      data-tracking-id="mobile-subscription-admin-card"
    >
      {hasMultipleLegalOwnerSubscriptions && (
        <Card.Slot align="top-right">
          <Badge variant="special">
            <Icon name="savings" />
            Sjekk din familierabatt!
          </Badge>
        </Card.Slot>
      )}

      <Card.Heading as="h3" variant="title-100">
        {hasMultipleLegalOwnerSubscriptions ? "Dine abonnement" : "Endre abonnement?"}
      </Card.Heading>
      <Card.Content>
        <Text>
          {hasMultipleLegalOwnerSubscriptions
            ? "Mix og match flere abonnement - få familierabatt!"
            : "Velg abonnement med ubegrenset data - nå til lavere pris enn noensinne - eller faste datapakker per måned."}
        </Text>
      </Card.Content>
      <Card.Footer>
        <Card.Button variant="text">{hasMultipleLegalOwnerSubscriptions ? "Se din oversikt" : "Endre nå"}</Card.Button>
      </Card.Footer>
    </Card>
  );
}
