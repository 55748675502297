import { Skeleton, Flex } from "@telia/teddy";
import { useWindowSize } from "@telia-no-min-side/components";

export function SimPinPukLoading() {
  const { isMobile } = useWindowSize();

  return (
    <Flex direction="column" width="100%" gap="50px">
      <Skeleton height="175px" width="100%" maxWidth="600px" />
      <Flex direction={isMobile ? "column" : "row"} gap="gutter-xl">
        <Skeleton radius="full" height="50px" width={isMobile ? "100%" : "180px"} />
        <Skeleton radius="full" height="50px" width={isMobile ? "100%" : "180px"} />
        <Skeleton radius="full" height="50px" width={isMobile ? "100%" : "180px"} />
      </Flex>
    </Flex>
  );
}
