import { Container, Heading, Button, Icon, Text, Box } from "@telia/teddy";
import { Link } from "react-router-dom";
import { CustomerCareLink } from "src/components/customer-care-link";

export const NoCommitmentFound = () => {
  return (
    <Container>
      <Button variant="text" data-tracking-id="back-button-no-commitment-found-page" asChild mb="200">
        <Link to="/minside/hjem">
          <Icon name="arrow-left">Tilbake</Icon>
        </Link>
      </Button>
      <Heading as="h2" variant="title-100" mb="600">
        Vi klarte ikke å finne din årsrabatt
      </Heading>
      <Text>Er du mobilkunde og mener dette er feil, vennligst kontakt vår kundeservice:</Text>
      <Box mb="600">
        <CustomerCareLink trackingContext="annual-discount-mobile" />
      </Box>
      <Button variant="primary" asChild>
        <Link to="/minside/hjem">
          <Icon name="home">Hjem</Icon>
        </Link>
      </Button>
    </Container>
  );
};
