import React from "react";
import styled from "styled-components";
import { Button } from "../Button";
import { Card } from "../Card";
import { Container } from "../Container";
import { Heading } from "../Heading";

type Props = {
  title: string;
  href?: string;
  linkText?: string;
  description?: string;
  openInSameTab?: boolean;
  isMobileLinkVisible?: boolean;
};

export function NoProducts({ href, linkText, title, description, openInSameTab }: Props): JSX.Element {
  return (
    <Container padding>
      <Heading tag="h1" variant="title-200">
        Her kunne vi dessverre ikke finne noen produkter...
      </Heading>

      <SpacedContent padding="vertical" maxWidth="xxs">
        <Card backgroundColor="light-purple">
          <Heading tag="h2" variant="title-100">
            {title}
          </Heading>
          <p>{description || "Ved å samle både TV, internett og mobil får du mange fordeler med Telia!"}</p>
          {href && linkText && (
            <Button
              variant="secondary"
              tag="a"
              hideLinkIcon
              href={href}
              trackEvent={{
                ui_item_action: "link_click",
                ui_item_context: "no products",
                ui_item_text: title,
                ui_item_type: "link",
                ui_item_url: href,
              }}
              target={openInSameTab ? "_self" : "_blank"}
            >
              {linkText}
            </Button>
          )}
        </Card>
      </SpacedContent>
    </Container>
  );
}

const SpacedContent = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
