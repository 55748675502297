import { useState } from "react";
import { Alert, Button, Container, Heading, Paragraph, Skeleton, Card, useToast } from "@telia-no-min-side/components";
import { types, uri } from "@telia-no-min-side/utils";
import { useFetchFixedInsight } from "src/api/fixed";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { ButtonContainer } from "src/pages/home/card/style";
import { datadogRum } from "@datadog/browser-rum";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { Icon } from "@telia/teddy";
import { axiosGet } from "src/services/axios/axios";

export function Insight() {
  const [isDownloading, setIsDownloading] = useState(false);
  const insight = useFetchFixedInsight();
  const { addToast } = useToast();

  const requestInsight = useAxiosPost<types.fixed.Insight>(uri.minSideFixed("/api/roa/registerrequest"));

  if (insight.isLoading) {
    return (
      <div data-tracking-id="insight-skeleton">
        <Skeleton width="30ch" />
        <Skeleton width="40ch" />
        <Skeleton width="40ch" />
      </div>
    );
  }
  if (insight.isError && !insight.data) {
    return (
      <Alert isOpen title="En uventet feil oppstod" kind="negative" icon="alert">
        Vi klarte dessverre ikke hente dine innsynsrapport. Vennligst prøv igjen eller ta kontakt med kundeservice.
      </Alert>
    );
  }

  if (!insight.data)
    return (
      <Card>
        <Container padding>
          <Heading tag="h2" variant="title-100">
            Innsynsrapport for TV/Internett
          </Heading>
          <Paragraph>Det ser ikke ut som vi finner noe data knyttet til din konto</Paragraph>
        </Container>
      </Card>
    );
  const hasRequested = insight.data.requested;
  const isLinkReady = insight.data.processStatus === "SUCCESS";

  return (
    <Card>
      <Container padding data-tracking-id="insight-page">
        {isLinkReady ? (
          <>
            <Heading tag="h2" variant="title-100">
              Bestill innsynsrapport for TV/Internett
            </Heading>
            <Paragraph>
              Du har rett til innsyn i egne personopplysninger. Dette betyr at du skal få vite om vi behandler
              opplysninger om deg, og til å få utlevert en kopi av opplysningene vi sitter på om deg.
            </Paragraph>

            <Card backgroundColor="green">
              <Container flexDirection="row" alignItems="center" justifyContent="center" gap>
                <div>
                  <Icon name="checkmark" />
                </div>
                <Paragraph>Oversikt over dataene Telia har lagret om deg er nå tilgjengelig.</Paragraph>
              </Container>
            </Card>

            <ButtonContainer padding="vertical">
              <Button
                icon="download"
                isLoading={isDownloading}
                onClick={async () => {
                  try {
                    setIsDownloading(true);
                    const downloadLink = await axiosGet<types.fixed.Insight>(
                      uri.minSideFixed(ICX_REQUEST.URL_DOWNLOAD_INSIGHT)
                    );
                    if (!downloadLink.data?.downloadLink) {
                      throw new Error("No download link found");
                    }
                    const link = document.createElement("a");
                    link.href = downloadLink.data?.downloadLink;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  } catch (e) {
                    datadogRum.addError(e);
                    addToast({
                      text: "En feil oppstod, prøv igjen.",
                      variant: "error",
                    });
                  } finally {
                    setIsDownloading(false);
                  }
                }}
              >
                Last ned oversikt
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <Heading tag="h2" variant="title-100">
              Bestill innsynsrapport for TV/Internett
            </Heading>
            <Paragraph>
              Du har rett til innsyn i egne personopplysninger. Dette betyr at du skal få vite om vi behandler
              opplysninger om deg, og til å få utlevert en kopi av opplysningene vi sitter på om deg.
            </Paragraph>

            {hasRequested && (
              <Card backgroundColor="grey">
                <Container flexDirection="row" alignItems="center" justifyContent="center" gap>
                  <div>
                    <Icon name="checkmark" />
                  </div>
                  <Paragraph>
                    Bestillingen din er registrert
                    {insight.data.registeredAtTime
                      ? `(${insight.data.registeredAtTime?.format("DD.MM.YYYY HH:mm")})`
                      : null}
                    , og vi jobber med å hente ut dataene dine. Du vil få beskjed straks de er klare.
                  </Paragraph>
                </Container>
              </Card>
            )}
            <ButtonContainer padding="vertical">
              <Button
                isLoading={requestInsight.isLoading}
                onClick={async () => {
                  try {
                    const response = await requestInsight.post();
                    insight.mutate(response, { revalidate: false });
                  } catch (e) {
                    datadogRum.addError(e);
                    addToast({
                      text: "En feil oppstod, prøv igjen.",
                      variant: "error",
                    });
                  }
                }}
                disabled={!!hasRequested}
              >
                Bestill innsiktsrapport
              </Button>
            </ButtonContainer>
          </>
        )}
      </Container>
    </Card>
  );
}
