import { Accordion, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";

export function InvoiceCopyMobileAccordion(): JSX.Element {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Jeg trenger fakturakopi - hvor finner jeg det?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Vil du se fakturakopien din finner du alle fakturaene dine på denne siden og i Telia-appen. Her kan du også
          laste de ned som pdf-dokumenter, og betale direkte med VISA / Mastercard.
        </Paragraph>
        <Paragraph>Har du eFaktura finner du også fakturakopier i nettbanken din.</Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
