import { getCookieValue, types, uri } from "@telia-no-min-side/utils";
import { toast } from "@telia/teddy";
import dayjs from "dayjs";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { config } from "util/config";
import { COOKIE } from "util/constants/cookies";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { downloadFile } from "util/downloadfile";

export const urlInsight = uri.minSideFixed(ICX_REQUEST.URL_INSIGHT_STATUS);

export function useFetchFixedInsight() {
  const { error, ...rest } = useAxiosSWR<types.fixed.Insight>(urlInsight);
  const status = {
    requested: rest.data?.processStatus === "REQUESTED",
  };

  return {
    isError: !!error,
    error: error,
    ...rest,
    data: rest.data
      ? {
          ...rest.data,
          ...status,
          // registeredAtTime: rest.data?.registeredAtTime ? dayjs.utc(rest.data.registeredAtTime) : undefined,
          registeredAtTime: rest.data?.registeredAtTime ? dayjs(rest.data.registeredAtTime) : undefined,
        }
      : undefined,
  };
}

export async function downloadGdprReportMobile({ requestId, fileName }: { requestId: string; fileName?: string }) {
  const ctsession = getCookieValue(COOKIE.CT_SESSION);
  const url = `${config.channelApiRestUrl}/documents?type=MOBILE_ROA_DOWNLOAD&requestId=${requestId}`;
  const loaderToastId = toast.loading("Laster ned fil...");
  try {
    await downloadFile({
      url,
      filename: fileName ?? `innsynsrapport.zip`,
      config: {
        headers: {
          ctsession: ctsession || "",
        },
      },
    });
    toast.success("Filen er lastet ned", { id: loaderToastId });
  } catch {
    toast.error("Kunne ikke laste ned filen", { id: loaderToastId });
  }
}
