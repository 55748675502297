import { Card, Icon, Badge as TeddyBadge } from "@telia/teddy";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";

export function Badge() {
  const { subscriptionBenefits } = useMobileDashboardContext();
  const { convergentBenefit, showConvergentBenefit, showSummerCampaignBenefit } = subscriptionBenefits;

  if (showSummerCampaignBenefit) {
    return (
      <Card.Slot align="top-right">
        <TeddyBadge variant="warning" hideIcon>
          <Icon name="weather" />
          Du har dobbel data!
        </TeddyBadge>
      </Card.Slot>
    );
  }

  if (showConvergentBenefit) {
    return (
      <Card.Slot align="top-right">
        <TeddyBadge variant="information" hideIcon>
          Du har dobbel {convergentBenefit.hasDoubleSpeed ? "hastighet" : "data"}!
        </TeddyBadge>
      </Card.Slot>
    );
  }

  return null;
}
