import { Icon } from "@telia/styleguide";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { updateResponse } from "../../util/PropTypes";
import { responseCodes } from "../../util/ResponseCodes";
import { FadeInContent } from "../Animations/FadeIn/FadeInContent";

import "./style.less";

const { string, func } = PropTypes;

export const ResponseMessage = (props) => {
  const [show, setShow] = useState(true);

  const { updateResponse, tag, onSuccessFullResponseCallback } = props;

  const renderResponseMessage = (err, conflict) => {
    if (err) {
      if (conflict) {
        return (
          <div className="response-message__text-box response-message__text-box--error">
            <Icon icon={"info"} />
            <p className="response-message__text">En oppdatering skjer allerede. Prøv igjen senere.</p>
          </div>
        );
      } else {
        return (
          <div className="response-message__text-box response-message__text-box--error">
            <Icon icon={"info"} />
            <p className="response-message__text">Kunne ikke lagre innstillingene. Prøv igjen.</p>
          </div>
        );
      }
    } else {
      return (
        <div className="response-message__text-box response-message__text-box--success">
          <Icon icon={"check-mark"} />
          <p className="response-message__text">Innstillingene er lagret.</p>
        </div>
      );
    }
  };

  const hideMessageAfterTimeout = () => {
    window.setTimeout(() => {
      setShow(false);
    }, 3000);
  };

  const shouldShowMessage = () => {
    if (updateResponse.error) {
      return updateResponse.tag === tag;
    }
    return show && !updateResponse.shown && updateResponse.tag === tag;
  };

  const resetShowFlag = () => {
    if (!updateResponse.error && onSuccessFullResponseCallback) {
      onSuccessFullResponseCallback();
    }
    setShow(true);
  };

  return (
    <div className="response-message">
      <CSSTransition
        timeout={500}
        classNames="response-message"
        in={shouldShowMessage()}
        onExited={() => (updateResponse.shown = true) && resetShowFlag()}
        onEntered={hideMessageAfterTimeout}
      >
        {(state) => (
          <div>
            <FadeInContent show={state === "entered"}>
              {renderResponseMessage(updateResponse.error, updateResponse.response === responseCodes.CONFLICT)}
            </FadeInContent>
          </div>
        )}
      </CSSTransition>
    </div>
  );
};

ResponseMessage.propType = {
  updateResponse: updateResponse,
  tag: string,
  onSuccessFullResponseCallback: func,
};
