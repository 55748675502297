import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { axiosGet, FullPageLoading, useToast } from "@telia-no-min-side/components";
import { downloadBlob, setSearchParams, uri } from "@telia-no-min-side/utils";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "src/util/config";

export const InvoicePreview = () => {
  const { accountId, invoiceId } = useParams<{
    accountId: string;
    invoiceId: string;
  }>();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryParams = {
    type: "INVOICE",
    accountId,
    invoiceId,
  };
  const uriParams = setSearchParams(queryParams);

  useEffect(() => {
    axiosGet<Blob>(`${config.channelApiRestUrl}/documents${uriParams}`, {
      responseType: "blob",
    })
      .then((response) => {
        if (response.data instanceof Blob) {
          downloadBlob({
            blob: new Blob([response.data], { type: "application/pdf" }),
            openInNewTab: true,
          });
          navigate(uri.minSideFixed(`/faktura/${invoiceId}?isMobile=true`));
        } else {
          throw Error("Invalid pdf invoice response type");
        }
      })
      .catch((error: Error) => {
        datadogRum.addError(error, { feature: "InvoicePreview" });
        navigate(uri.minSideFixed(`/faktura/${invoiceId}?isMobile=true`));
        addToast({
          text: "Vi klarte ikke å laste ned din faktura. Vennligst prøv igjen, eller ta kontakt med kundesenteret.",
          variant: "error",
        });
      });
  }, []);
  return <FullPageLoading />;
};
