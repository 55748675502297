import { ApolloError, gql, useMutation } from "@apollo/client";
import { SetNewPasswordMutation } from "gql/graphql";

const SET_NEW_PASSWORD_REQUEST = gql`
  mutation setNewPassword($userId: String!, $otp: String!, $password: String!) {
    clearTrustSetPassword(userId: $userId, otp: $otp, newPassword: $password) {
      ctsession
    }
  }
`;

const getErrorMessage = (error: ApolloError) => {
  if (error.message.includes("IPL_PASSWORD_MISMATCH")) {
    return "Du har tastet feil engangskode. Vennligst prøv igjen.";
  }
  if (error.message.includes("IPL_PASSWORD_PATTERN_VIOLATED")) {
    return `Ditt nye passord følger ikke våre minimumskrav til et passord: Minimum 6 tegn, 
    maksimalt 32 tegn, en liten bokstav, en stor bokstav og et tall eller spesialtegn.`;
  }
  if (error.message.includes("IPL_TOO_MANY_BAD_OTP_LOGONS")) {
    return "Du har tastet feil engangskode 3 ganger. Vennligst få tilsendt en ny engangskode.";
  }
  if (error.message.includes("IPL_PASSWORD_REUSED")) {
    return "Ditt nye passord kan ikke være det samme som ditt siste passord.";
  }
  return "En uventet feil oppstod";
};

type Props = {
  onError: (error: string) => void;
  onCompleted: (data: SetNewPasswordMutation) => void;
};

export const useSetNewPassword = ({ onCompleted, onError }: Props) => {
  const [setNewPassword, { loading, data }] = useMutation(SET_NEW_PASSWORD_REQUEST, {
    onError: (error) => {
      if (onError) {
        onError(getErrorMessage(error));
      }
    },
    onCompleted,
  });

  return {
    setNewPassword,
    loading,
    data,
  };
};
