import { types } from "@telia-no-min-side/utils";
import { BareFetcher, SWRConfiguration } from "swr";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { useFetchProfile } from "./useFetchProfile";
import { getCustomerSpecificUrl } from "./utils";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

const url = ICX_REQUEST.URL_FLEX_PRICES;

type TvPointsToBroadbandPricesFilterPredicate = (
  value: types.fixed.TvPointsAndBroadbandPrice,
  index: number,
  array: types.fixed.TvPointsAndBroadbandPrice[]
) => unknown;

export function getFlexProductsByFilter(
  tvPointsToBroadbandPrices: types.fixed.FlexPrices | undefined,
  filterPredicate: TvPointsToBroadbandPricesFilterPredicate
): types.fixed.TvPointsAndBroadbandPrice[] | null {
  if (!tvPointsToBroadbandPrices) return null;
  return getFlattenFlexPrices(tvPointsToBroadbandPrices)?.filter(filterPredicate) || [];
}

export function getFlattenFlexPrices(
  tvPointsToBroadbandPrices: types.fixed.FlexPrices | undefined
): types.fixed.TvPointsAndBroadbandPrice[] | null {
  if (!tvPointsToBroadbandPrices) return null;
  const noTv = tvPointsToBroadbandPrices[0] || [];
  const tv5 = tvPointsToBroadbandPrices[5] || [];
  const tv30 = tvPointsToBroadbandPrices[30] || [];
  const tv50 = tvPointsToBroadbandPrices[50] || [];
  const tv60 = tvPointsToBroadbandPrices[60] || [];
  const tv70 = tvPointsToBroadbandPrices[70] || [];
  const tv90 = tvPointsToBroadbandPrices[90] || [];
  const tv110 = tvPointsToBroadbandPrices[110] || [];
  const tv130 = tvPointsToBroadbandPrices[130] || [];
  const tv200 = tvPointsToBroadbandPrices[200] || [];
  return [...noTv, ...tv5, ...tv30, ...tv50, ...tv60, ...tv70, ...tv90, ...tv110, ...tv130, ...tv200];
}

export function useFetchFlexPrices(args?: {
  config?: SWRConfiguration<types.fixed.FlexPrices, Error, BareFetcher<types.fixed.FlexPrices>>;
  customerId?: string;
}) {
  const { config } = args || {};
  const customerId = useFetchProfile().data?.customerId.toString();
  const { error, data, ...rest } = useAxiosSWR<Omit<types.fixed.FlexPrices, "free" | "flatten">>(
    getCustomerSpecificUrl(url, { customerId: args?.customerId || customerId, requireCustomerId: true }),
    { config }
  );

  const free = getFlexProductsByFilter(data, (product) => product.free);

  const flatten = getFlattenFlexPrices(data);

  const extendedData: types.fixed.FlexPrices | undefined = data ? { ...data, free, flatten } : undefined;

  return {
    isError: !!error,
    error,
    ...rest,
    data: extendedData,
  };
}
