import { GetAccountAgreementsQuery } from "gql/graphql";

export function getAgreementStatus(account: GetAccountAgreementsQuery["account"] | undefined) {
  if (!account)
    return {
      hasNoAgreements: true,
      hasSwitchAgreements: false,
      hasLeaseAgreements: false,
      hasInsuranceAgreements: false,
    };

  const hasAccountSwitchAgreements = !!account.switchAgreements.length;
  const hasAccountLeaseAgreements = !!account.leaseAgreements?.leases.length;
  const hasAccountInsuranceAgreements = !!account.insuranceAgreements?.length;

  const hasSubscriptionSwitchAgreements =
    account.subscriptions?.some(
      (subscription) => subscription.switchAgreements && subscription.switchAgreements.length > 0
    ) || false;

  const hasSubscriptionLeaseAgreements =
    account.subscriptions?.some(
      (subscription) => subscription.leaseAgreements && subscription.leaseAgreements?.leases?.length > 0
    ) || false;

  const hasSubscriptionInsuranceAgreements =
    account.subscriptions?.some(
      (subscription) => subscription.insuranceAgreements && subscription.insuranceAgreements.length > 0
    ) || false;

  const hasSwitchAgreements = hasAccountSwitchAgreements || hasSubscriptionSwitchAgreements;
  const hasLeaseAgreements = hasAccountLeaseAgreements || hasSubscriptionLeaseAgreements;
  const hasInsuranceAgreements = hasAccountInsuranceAgreements || hasSubscriptionInsuranceAgreements;

  const hasNoAgreements =
    !hasAccountSwitchAgreements &&
    !hasAccountLeaseAgreements &&
    !hasAccountInsuranceAgreements &&
    !hasSubscriptionSwitchAgreements &&
    !hasSubscriptionLeaseAgreements &&
    !hasSubscriptionInsuranceAgreements;

  return { hasNoAgreements, hasSwitchAgreements, hasLeaseAgreements, hasInsuranceAgreements };
}
