import { useEffect } from "react";
import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { useLocalStorage } from "@telia-no-min-side/components";

type Options =
  | {
      enableSkeletonNumberStore: true;
      storeKey: string;
    }
  | {
      enableSkeletonNumberStore?: false;
      storeKey?: string;
    };

export function useFetchAndLocalStore(url: string, options?: Options) {
  const { enableSkeletonNumberStore = false, storeKey } = options ?? {};
  const { data, error, ...rest } = useAxiosSWR<types.fixed.ProductCategory>(url);

  const { value: numberOfSkeleton, setValue: setNumberOfSkeleton } = useLocalStorage<number>(storeKey ?? "", {
    skip: !enableSkeletonNumberStore,
  });

  useEffect(() => {
    if (enableSkeletonNumberStore && data?.products && numberOfSkeleton !== data.products.length) {
      setNumberOfSkeleton(data.products.length);
    }
  }, [data?.products, numberOfSkeleton, enableSkeletonNumberStore]);

  return {
    data,
    isError: !!error,
    error: error,
    numberOfSkeleton,
    ...rest,
  };
}
