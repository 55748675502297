import { Container, Paragraph, Skeleton } from "@telia-no-min-side/components";
import { Lozenge } from "@telia/styleguide";
import { ReactNode } from "react";
import { GridItem, Hero, HeroHeading, InfoGrid, SubHeading } from "./style";

type Props = {
  invoiceDate: string;
  dueDate: string;
  kidNumber: string;
  bankAccount: string;
  paid?: boolean;
  invoiceUser?: string;
  invoicePeriod?: string;
  children?: ReactNode;
  isLegalOwner?: boolean;
  isLoading: boolean;
};

export function DetailsInfoHero(props: Props): JSX.Element {
  const {
    invoiceDate,
    dueDate,
    kidNumber,
    bankAccount,
    invoicePeriod,
    paid,
    children,
    invoiceUser,
    isLegalOwner,
    isLoading,
  } = props;
  return (
    <Hero>
      <Container padding>
        {children}
        <InfoGrid maxWidth="xxs" padding="vertical">
          <HeroHeading>
            Faktura
            <Container tag="span" gap="sm" flexDirection="row" alignItems="flex-end">
              <SubHeading data-di-mask>{!isLoading && invoiceDate}</SubHeading>
              {invoicePeriod && (
                <Paragraph tag="span" variant="additional-100">
                  ({invoicePeriod})
                </Paragraph>
              )}
            </Container>
          </HeroHeading>
          {invoiceUser && (
            <>
              <GridItem>Gjelder:</GridItem>
              <GridItem data-di-mask>
                {!isLoading && invoiceUser}
                {isLoading && <Skeleton width="10ch" />}
              </GridItem>
            </>
          )}
          <GridItem>Betalingsfrist:</GridItem>
          <GridItem>
            <strong data-di-mask>{!isLoading && dueDate}</strong>
            {isLoading && <Skeleton width="10ch" />}
          </GridItem>
          <GridItem>Kid:</GridItem>
          <GridItem data-di-mask>
            {!isLoading && kidNumber}
            {isLoading && <Skeleton width="10ch" />}
          </GridItem>
          <GridItem>Til konto:</GridItem>
          <GridItem data-di-mask>
            {!isLoading && bankAccount}
            {isLoading && <Skeleton width="10ch" />}
          </GridItem>
          {isLegalOwner && typeof paid === "boolean" && (
            <>
              <GridItem>Status:</GridItem>
              <GridItem data-di-mask>
                {!isLoading && (
                  <Lozenge status={paid ? "positive" : "info"} label={paid ? "Betalt" : "Ubetalt"} type="square" />
                )}
              </GridItem>
            </>
          )}
        </InfoGrid>
      </Container>
    </Hero>
  );
}
