import { TopupCategory } from "gql/graphql";
import { OrderSectionTopupType } from "pages/mobile/mobile-subscription-dashboard/types";

export const getVoucherCount = (topups: OrderSectionTopupType[]): number =>
  topups.reduce((total, topup) => {
    if (
      (topup.category === TopupCategory.DataFree || topup.category === TopupCategory.DataBoostFree) &&
      "numberOfVouchers" in topup &&
      typeof topup.numberOfVouchers === "number"
    ) {
      return total + topup.numberOfVouchers;
    }
    return total;
  }, 0);
