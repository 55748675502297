import { Accordion, Button, devices, remCalc, TextInput } from "@telia-no-min-side/components";
import { colors, Form } from "@telia/styleguide";
import styled, { css } from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;

  @media ${devices.xxs_down} {
    justify-content: start;
  }
`;

export const InputHeadline = styled.p`
  margin: 0rem;
  font-size: 0.75rem;
  color: ${colors.grey600};
`;

export const StyledTextInput = styled(TextInput)`
  margin-bottom: 1rem;
  width: 18.5rem;

  @media ${devices.xxs_down} {
    width: 16rem;
  }
`;

export const PaperTextInput = styled(StyledTextInput)`
  text-transform: capitalize;
`;

export const FormButton = styled(Button)`
  justify-content: end;
  margin-right: 0.9rem;
  margin-top: 0.3rem;
  font-weight: 500;
`;

export const StyledForm = styled(Form)`
  margin-top: 1.25rem;
`;

export const StyledAccordionItem = styled(Accordion.Item)<{
  selected?: boolean;
}>`
  .Icon--chevron-down {
    display: none;
  }

  ${(props) =>
    props.selected &&
    css`
      border: 1px solid #9909e3;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.15);
      border-radius: ${remCalc(5)};
    `}
`;
