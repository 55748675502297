import { Lozenge } from "@telia/styleguide";
import React, { ComponentProps, ComponentType, forwardRef } from "react";

import { Breakpoints } from "../utils/breakpoints";
import { CardWrapper, StyledLozengeLeft, StyledLozengeRight } from "./style";

// ----- type helper -------
// The type that styled components accepts as an argument to as prop
type As = keyof JSX.IntrinsicElements | ComponentType | undefined;

// Retrieves all attributes for a specific tag.
type TagProps<Tag extends As> = ComponentProps<Tag extends keyof JSX.IntrinsicElements ? Tag : "div">;

// Collect your own props with attributes and tag the prop.
type PropsWithTag<Tag extends As, Props> = {
  tag?: Tag;
} & TagProps<Tag> &
  Props;

export type CardBaseProps = {
  removeMinHeight?: boolean;
  maxWidth?: Breakpoints;
  width?: "unset";
  showShadow?: boolean;
  showSelectedRing?: boolean;
  showHoverShadow?: boolean;
  hideBorder?: boolean;
  isSelected?: boolean;
  hideIsSelectedLozenge?: boolean;
  hideIsSelectedRing?: boolean;
  lozengeLeft?: Omit<ComponentProps<typeof Lozenge>, "type" | "className">;
  lozengeRight?: Omit<ComponentProps<typeof Lozenge>, "type" | "className">;
  backgroundColor?: "white" | "beige" | "purple" | "light-purple" | "blue" | "grey" | "warning" | "green";
  borderColor?: "purple";
  padding?: "md" | "lg" | "lg-vertical" | "unset" | "sm" | "top";
  dataTrackingID?: string;
};

export type CardProps<Tag extends As> = PropsWithTag<Tag, CardBaseProps>;

/**
 * @deprecated Use Teddy components instead
 */
export const Card = forwardRef(function <Tag extends As = "div">(
  props: CardProps<Tag>,
  ref: React.ForwardedRef<HTMLElement>
) {
  const {
    tag,
    lozengeLeft,
    showHoverShadow,
    lozengeRight,
    dataTrackingID,
    children,
    isSelected,
    showSelectedRing,
    hideIsSelectedLozenge = false,
    ...rest
  } = props;
  const isClickable = tag === "a" || tag === "button";
  return (
    <CardWrapper
      as={tag}
      ref={ref}
      isClickable={isClickable}
      showHoverShadow={typeof showHoverShadow === "boolean" ? showHoverShadow : isClickable || !!rest.onClick}
      data-tracking-id={dataTrackingID}
      isSelected={isSelected || showSelectedRing}
      {...rest}
    >
      {(lozengeLeft || isSelected) && !hideIsSelectedLozenge && (
        <StyledLozengeLeft
          {...lozengeLeft}
          label={lozengeLeft?.label || "Valgt"}
          type="square"
          status={lozengeLeft?.status || "warning"}
          padding={props.padding}
        />
      )}
      {lozengeRight && (
        <StyledLozengeRight
          {...lozengeRight}
          padding={props.padding}
          label={lozengeRight?.label}
          type="square"
          status={lozengeRight?.status}
        />
      )}
      {children}
    </CardWrapper>
  );
});

Card.displayName = "Card";
