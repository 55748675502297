import { useMutation, BaseMutationOptions, MutationResult } from "@apollo/client";
import { UPDATE_INVOICE_SETTINGS } from "../../mutations";
import { AccountUpdateInput, AccountUpdateMutation } from "src/gql/graphql";

export type AccountUpdateResponse = [
  ({ accountId, distributionCode, eInvoiceId, invoiceReceiver }: AccountUpdateInput) => Promise<void>,
  MutationResult<AccountUpdateMutation>,
];

export function useUpdateInvoiceSettings({ onCompleted, onError }: BaseMutationOptions): AccountUpdateResponse {
  const [accountUpdate, response] = useMutation(UPDATE_INVOICE_SETTINGS, {
    onCompleted,
    onError,
  });

  const runUpdateInvoiceSettings = async ({
    accountId,
    distributionCode,
    eInvoiceId,
    invoiceReceiver,
  }: AccountUpdateInput) => {
    if (!accountId) return;

    const updateSettingsData = {
      variables: {
        input: {
          accountId,
          distributionCode,
          invoiceReceiver,
          eInvoiceId,
        },
      },
    };

    try {
      await accountUpdate(updateSettingsData);
    } catch (error) {
      console.error(error);
    }
  };

  return [runUpdateInvoiceSettings, response];
}
