import { NewOfferingDetails, OfferingPriceCodes } from "gql/graphql";

export const getUserOfferingWithAnnualDiscount = (
  userOfferingShortName: string,
  offerings: NewOfferingDetails["offerings"]
) => {
  const userOfferingWithDiscount = offerings?.find(
    (offering) =>
      offering?.shortName === userOfferingShortName &&
      offering?.price?.offeringPrices?.some((priceDetails) => priceDetails?.code === OfferingPriceCodes.MonthlyDiscount)
  );

  return userOfferingWithDiscount;
};
