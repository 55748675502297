import styled from "styled-components";
import { ListItem } from "../../List";
import { remCalc } from "./../../utils/fonts";

export const Wrapper = styled.div`
  font-size: ${remCalc(12)};
`;

export const TextWrapper = styled.div`
  font-size: ${remCalc(12)};
  p {
    margin: 0;
  }
`;

export const HeadingWrapper = styled.div`
  margin-right: 2rem;
  text-align: left;
  line-height: 1.5rem;
  h4 {
    font-size: ${remCalc(14)};
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
  h3 {
    font-size: ${remCalc(20)};
    margin: 0;
    padding: 0;
    font-weight: bold;
  }
`;

export const StyledListItem = styled(ListItem)`
  font-size: ${remCalc(12)};
  line-height: ${remCalc(15)};
  margin-top: ${remCalc(20)};
  h4 {
    font-weight: 500;
    font-size: ${remCalc(12)};
    margin: 0;
  }
`;

export const LinkWrapper = styled.p`
  margin: ${remCalc(15)} 0 0;
`;
