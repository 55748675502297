import styled, { css, keyframes } from "styled-components";
import { colors } from "@telia/styleguide";
import { Card, CardBaseProps } from "../Card";
import { Button } from "../Button";
import { Container } from "../Container";
import { breakpoints, devices } from "./../utils/breakpoints";
import { zIndex } from "./../utils/variables";
import { ModalProps } from ".";

type ModalWrapperProps = Pick<ModalProps, "isOpen" | "size" | "removePadding"> & CardBaseProps;

const transformIn = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ModalWrapper = styled(Card)<ModalWrapperProps>`
  position: fixed;
  z-index: ${zIndex.modal};
  overflow-y: hidden;
  padding: 0;
  ${(props) => {
    if (props.isOpen) {
      return css`
        display: flex;
      `;
    }
    return css`
      display: none;
    `;
  }}
  @media ${devices.sm_up} {
    inset: 50% 50% auto auto;
    transform: translate(50%, -50%);
    max-height: 90vh;
    max-width: ${(props) => breakpoints[props.size ?? "sm"]}px;
    width: 95vw;
    min-height: 160px;
  }
  @media ${devices.sm_down} {
    inset: 15% 0 0 0;
    animation: ${transformIn} 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
  }
`;

export const Header = styled(Container)`
  background-color: ${colors.corePurple100};
  width: auto;
  min-height: 8rem;
  margin-inline: -2rem;
  margin-top: -1.5rem;
  @media ${devices.sm_down} {
    min-height: 6rem;
  }
  @media ${devices.xs_down} {
    min-height: 4.5rem;
    margin-inline: -1rem;
    margin-top: -1rem;
  }
`;

export const ScrollElement = styled.div<{ removePadding: boolean }>`
  overflow-y: auto;
  width: 100%;
  padding: ${(props) => (props.removePadding ? "0" : "1.5rem 2rem 0")};
  @media ${devices.xs_down} {
    display: flex;
    flex-direction: column;
    padding: ${(props) => (props.removePadding ? "0" : "1rem 1rem 0")};
  }
`;
export const BodyWrapper = styled.div`
  margin: 7px 0 15px;
  @media ${devices.xs_down} {
    flex: 1 0 auto;
  }
`;
export const FooterWrapper = styled.div<{ flex?: boolean }>`
  margin: 7px 0 15px;
  text-align: right;
  ${(props) =>
    props.flex &&
    css`
      display: flex;
      gap: 0.5rem;
      justify-content: flex-end;
    `}
`;

export const StyledIconButton = styled(Button)`
  position: absolute;
  right: 5px;
  top: 5px;
`;
