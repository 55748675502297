import React, { CSSProperties, HTMLAttributes } from "react";
import { StyledParagraph } from "./style";

export type ParagraphProps = {
  /**
   * When when a paragraph appears after another paragraph,
   * a margin-top will automatically be added to the siblings.
   *  "additional-100"
    | "overline-100"
    | "overline-200"
    | "preamble-100"
    | "preamble-200" will have a margin-bottom.
   * @default false
   */
  removeMargin?: boolean;
  verticalAlign?: boolean;
  whiteSpace?: CSSProperties["whiteSpace"];
  ellipsisOverflow?: boolean;
  /**
   * Align text "center" | "end" | "left" | "right" | "start"
   * @default undefined
   */
  textAlign?: AlignSetting;
  textDecorationLine?: "underline" | "overline" | "line-through" | "blink";
  isFaded?: boolean;
  tag?: "p" | "span";

  variant?:
    | "additional-50"
    | "additional-100"
    | "overline-100"
    | "overline-200"
    | "paragraph-100"
    | "paragraph-200"
    | "preamble-100"
    | "preamble-200";
} & HTMLAttributes<HTMLParagraphElement>;

/**
 * @deprecated Use Teddy components instead
 */
export function Paragraph({
  variant = "paragraph-100",
  removeMargin = false,
  children,
  tag = "p",
  ...rest
}: ParagraphProps): JSX.Element {
  return (
    <StyledParagraph as={tag} {...rest} variant={variant} removeMargin={removeMargin}>
      {children}
    </StyledParagraph>
  );
}
