import { useState } from "react";
import { Button, Container } from "@telia-no-min-side/components";
import { types } from "@telia-no-min-side/utils";
import { getPriceAmountText } from "src/util/priceFormatter";
import { DetailsElementSkeleton } from "../../components/DetailsElementSkeleton";
import { DetailsElement } from "../../components/details-element";
import { ColorCode, Cost, Refund, SumCell, SumTextCell, TotalSumGrid } from "../../styles";
import { PayByCardFixed } from "../PayByCardFixed";
import { DetailsIsOpen } from "./InvoiceDetailsFixed";
import { InvoiceActions } from "../../components/InvoiceActions";

type Props = {
  detailsIsOpen: DetailsIsOpen;
  onChange: (id: keyof DetailsIsOpen, forceValue?: boolean) => void;
  invoiceDetails: types.fixed.InvoiceDetails | undefined;
  invoiceDetailsIsLoading: boolean;
  mutateInvoices: () => void;
  isPostponingDisabled: boolean;
  setIsPostoneInvoiceModalOpen: (value: boolean) => void;
};

export const DetailsElementListFixed = (props: Props): JSX.Element => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const {
    detailsIsOpen,
    invoiceDetails,
    invoiceDetailsIsLoading,
    onChange,
    isPostponingDisabled,
    setIsPostoneInvoiceModalOpen,
  } = props;

  const showColorCode = Object.values(detailsIsOpen).some((accordionIsOpen) => accordionIsOpen);

  const onPaymentButtonClick = () => {
    setIsPaymentModalOpen(true);
  };

  return (
    <Container>
      {invoiceDetailsIsLoading && (
        <>
          <DetailsElementSkeleton />
          <DetailsElementSkeleton />
          <DetailsElementSkeleton />
        </>
      )}
      {invoiceDetails && (
        <>
          <ColorCode fadeIn={showColorCode} maxWidth="sm" padding="horizontal">
            <span>Fargekoder:</span>
            <Cost>Kostnad</Cost>
            <Refund>Kreditert/rabattert</Refund>
          </ColorCode>
          <DetailsElement
            isOpen={detailsIsOpen.tv}
            toggleIsOpen={(forceValue?: boolean) => onChange("tv", forceValue)}
            invoiceDetail={invoiceDetails.tv}
            category="TV"
          />
          <DetailsElement
            isOpen={detailsIsOpen.broadband}
            toggleIsOpen={(forceValue?: boolean) => onChange("broadband", forceValue)}
            invoiceDetail={invoiceDetails.broadband}
            category="Internett"
          />
          <DetailsElement
            isOpen={detailsIsOpen.ipTelephony}
            toggleIsOpen={(forceValue?: boolean) => onChange("ipTelephony", forceValue)}
            invoiceDetail={invoiceDetails.ipTelephony}
            category="IP-Telefoni"
          />
          <DetailsElement
            isOpen={detailsIsOpen.other}
            toggleIsOpen={(forceValue?: boolean) => onChange("other", forceValue)}
            invoiceDetail={invoiceDetails.other}
            category="Andre"
          />
          <TotalSumGrid maxWidth="sm">
            <SumTextCell>Totalsum</SumTextCell>
            <SumCell data-di-mask>{getPriceAmountText(invoiceDetails.sumOfDetails, "kr")}</SumCell>
          </TotalSumGrid>
        </>
      )}
      <Container flexWrap padding="horizontal" gap flexDirection="column" style={{ marginTop: "10px" }}>
        <InvoiceActions
          isPostponingDisabled={isPostponingDisabled}
          setPostponeInvoiceModalOpen={setIsPostoneInvoiceModalOpen}
          isDetailPage
        >
          {invoiceDetails?.canBePaidOnline && !isPaymentDone && (
            <Button icon="credit-card" onClick={onPaymentButtonClick} variant="primary" fullWidth>
              Betal nå
            </Button>
          )}
        </InvoiceActions>
      </Container>
      <PayByCardFixed
        isPaymentModalOpen={isPaymentModalOpen}
        setIsPaymentModalOpen={setIsPaymentModalOpen}
        setIsPaymentDone={setIsPaymentDone}
      />
    </Container>
  );
};
