export const generalUtilities = {
  getActiveUnit(devices, activeUnitSerialNumber) {
    return (
      devices &&
      devices?.find((device) => activeUnitSerialNumber === device.SerialNumber)
    );
  },
  isMobile() {
    return window.innerWidth < 500;
  },
};
