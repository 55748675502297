import { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { GetProductUserQuery } from "gql/graphql";
import { useProductUser } from "../hooks/useProductUser";
import { ProfileView } from "../types";
import { ADDRESS_MESSAGE, URBAN_ADDRESS_FIELD_EN, URBAN_ADDRESS_FIELD_NO } from "util/constants/address";
import { BankIdRequiredModal } from "components/bank-id-required-modal";
import { config } from "util/config";
import { Flex, Button, TextField, breakpoint } from "@telia/teddy";

export type FormSubmitStatus = "success" | "error";

type ProfileFormProps = {
  profileData?: GetProductUserQuery["subscription"];
  onCancelUpdateClick: (profileView: ProfileView) => void;
  onFormSumbitComplete: (status: FormSubmitStatus) => void;
};

type ProfileFormFields = {
  emailAddress: string;
};

type TextInputFieldProps = {
  label: string;
  name: keyof ProfileFormFields;
  defaultValue?: string | null;
  formatting?: (value: string) => string;
  required?: boolean;
  onlyNumbers?: boolean;
  maxLength?: number;
  customValidation?: Record<string, Record<string, unknown>>;
  dataTrackingId?: string;
};

export function ProfileForm({ profileData, onCancelUpdateClick, onFormSumbitComplete }: ProfileFormProps) {
  const { runUpdateProductUser, runUpdateProductUserResponse } = useProductUser();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
    setValue,
  } = useForm<ProfileFormFields>();
  const { productUser, phoneNumber } = profileData || {};
  const { emailAddress } = productUser || {};

  useEffect(() => {
    const { data, loading, error } = runUpdateProductUserResponse;
    if (isSubmitSuccessful && !loading) {
      reset();
      if (data?.productUserUpdate?.orderId) {
        onFormSumbitComplete("success");
      }
      if (error) {
        onFormSumbitComplete("error");
      }
    }
  }, [runUpdateProductUserResponse]);

  const onSubmit: SubmitHandler<ProfileFormFields> = (formData) => {
    const { emailAddress } = formData;
    if (phoneNumber?.localNumber) {
      runUpdateProductUser(phoneNumber?.localNumber, {
        emailAddress: emailAddress,
      });
    }
  };

  const TextInputField = ({
    label,
    name,
    defaultValue,
    formatting,
    required,
    onlyNumbers,
    maxLength,
    customValidation,
    dataTrackingId,
  }: TextInputFieldProps) => (
    <div data-tracking-id={dataTrackingId ? dataTrackingId : `profile-page-form-${name}`}>
      <TextField errors={errors?.[name]?.message ? [errors?.[name]?.message] : undefined} isRequired={required}>
        <TextField.Label>{label}</TextField.Label>
        <TextField.Input
          {...register(name, {
            ...(required && { required: ADDRESS_MESSAGE.REQUIRED_FIELD }),
            ...customValidation,
            ...(onlyNumbers && {
              pattern: {
                value: /^[0-9]+$/,
                message: ADDRESS_MESSAGE.NUMERIC_FIELD,
              },
            }),
          })}
          {...(formatting && { onChange: (e) => setValue(name, formatting(e.target.value)) })}
          defaultValue={defaultValue || ""}
          maxLength={maxLength}
        />
        <TextField.ErrorList />
      </TextField>
    </div>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex maxWidth={breakpoint.teddyBreakpointMd} direction="column" gap="200">
        <TextInputField
          label={URBAN_ADDRESS_FIELD_NO.EMAIL_ADDRESS}
          name={URBAN_ADDRESS_FIELD_EN.EMAIL_ADDRESS}
          defaultValue={emailAddress}
          required
          dataTrackingId="profile-page-form-email"
        />

        <Flex data-tracking-id="profile-page-form-action-buttons" gap="200">
          <Button variant="secondary" onClick={() => onCancelUpdateClick("viewInfo")}>
            Avbryt
          </Button>
          {/* NOTE: USE THIS UNTIL WE CAN DEPLOY TO PROD - AWAITING FOR APP TO BE READY  */}
          {config.requireBankId ? (
            <BankIdRequiredModal
              triggerComp={
                <Button type="button" data-tracking-id="email-change-button">
                  Lagre
                </Button>
              }
              mutateComp={
                <Button
                  variant="primary"
                  data-tracking-id="bankid-modal-bankid-button"
                  disabled={runUpdateProductUserResponse.loading}
                  onClick={handleSubmit(onSubmit)}
                >
                  {runUpdateProductUserResponse.loading ? "Sender forespørsel" : "Til BankID"}
                </Button>
              }
            />
          ) : (
            <Button type="submit" variant="primary" disabled={runUpdateProductUserResponse.loading}>
              {runUpdateProductUserResponse.loading
                ? "Sender forespørsel"
                : runUpdateProductUserResponse?.data?.productUserUpdate?.orderId
                ? "Lagret!"
                : "Lagre endringer"}
            </Button>
          )}
        </Flex>
      </Flex>
    </form>
  );
}
