import { AdditionalProduct } from "src/gql/graphql";

export const isDiscountAvailable = (product: AdditionalProduct) => {
  return !!(product?.price?.price !== product?.price?.priceWithoutDiscount && product?.price?.discountDuration);
};

export const isPurchased = (product: AdditionalProduct) => {
  return !!product?.presentation?.active;
};
export const isFreeOrIncluded = (product: AdditionalProduct) => {
  return product.presentation?.changed || product.price?.price === 0;
};
export type DeviceInfo = ReturnType<typeof getDeviceInfo>;

export const getDeviceInfo = (product: AdditionalProduct) => {
  const deviceConfig = product.characteristic?.find((config) => config?.featureCode === "F-IMEI");
  const deviceModel = deviceConfig?.config?.find((configItem) => configItem?.name === "MODEL");
  const deviceImei = deviceConfig?.config?.find((configItem) => configItem?.name === "IMEI");

  return {
    deviceModel: deviceModel
      ? {
          name: deviceModel?.name,
          value: deviceModel?.value,
        }
      : undefined,
    deviceImei: deviceImei
      ? {
          name: deviceImei?.name,
          value: deviceImei?.value,
        }
      : undefined,
    featureCode: deviceConfig?.featureCode || undefined,
  };
};

export enum AdditionalServicesView {
  Main = "MAIN",
  Checkout = "CHECKOUT",
  CheckoutModal = "CHECKOUT_MODAL",
}
