import { useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Container,
  Heading,
  Icon,
  LineSeparator,
  Paragraph,
  Skeleton,
  useToast,
  useWindowSize,
} from "@telia-no-min-side/components";
import { format, uri } from "@telia-no-min-side/utils";
import dayjs from "dayjs";
import { graphql } from "src/gql";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { PageLoadError } from "src/pages/error/ErrorContent";
import { getPriceAmountText } from "src/util/priceFormatter";

const BANK_ACCOUNT_NUMBER = "6005 06 72525";
const ORG_NUMBER = "NO 951 589 888";
const SWIFT_NUMBER = "NDEANOKK";
const IBAN_NUMBER = "NO82 6005 06 72525";

export const INVOICE_ABROAD_QUERY = graphql(`
  query InvoiceAbroadQuery($accountId: String!) {
    account(accountId: $accountId) {
      id
      paymentArrangement(status: Open, type: FaxPayment) {
        expectedPaymentDate
      }
      currentInvoice {
        billingDate
      }
      paymentBalance {
        dueDate
        amount
      }
    }
  }
`);

export const InvoiceAbroadPage = () => {
  const { accountId } = useMobileAccount();
  const { addToast } = useToast();
  const { isMobile } = useWindowSize();

  const { loading, error, data } = useQuery(INVOICE_ABROAD_QUERY, {
    variables: { accountId },
  });

  if (loading || !accountId) {
    return (
      <Container showGoBackButton padding gap="lg" flexDirection="column">
        <Heading tag="h2" variant="title-400">
          Betal med utenlandsk konto
        </Heading>
        <Card>
          <Container flexDirection="column" gap>
            <Skeleton width="10ch" variant="heading-s" />
            <Container gap padding="vertical">
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Container>
            <Container gap padding="bottom">
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Container>
            <Skeleton width="80%" />
            <Skeleton width="80%" />
          </Container>
        </Card>
      </Container>
    );
  }

  if (error && !data) {
    return (
      <Container showGoBackButton padding gap="lg" flexDirection="column">
        <Heading tag="h2" variant="title-400">
          Betal med utenlandsk konto
        </Heading>
        <PageLoadError />
        <div>
          <Button tag="a" href={uri.minSideFixed("/hjem")} icon="home" isInternalNavigation>
            Hjem
          </Button>
        </div>
      </Container>
    );
  }

  function handleCopyClick(copyInput: string, tostText: string) {
    navigator.clipboard
      .writeText(copyInput)
      .then(() => {
        addToast({ text: `${tostText} kopiert til utklippstavlen`, variant: "success" });
      })
      .catch(() => {
        addToast({ text: `Kunne ikke kopiere til utklippstavlen`, variant: "error" });
      });
  }

  const paymentDate =
    data?.account.paymentArrangement?.[0]?.expectedPaymentDate || data?.account.paymentBalance?.dueDate;
  const amountToPay = data?.account.paymentBalance?.amount;

  const nextInvoiceDate = format.formatDate(
    data?.account.currentInvoice?.billingDate,
    format.DATE_DISPLAY_FORMAT_WITH_SHORT_YEAR
  );

  return (
    <Container showGoBackButton padding gap flexDirection="column">
      <Heading tag="h2" variant="title-400">
        Betal med utenlandsk konto
      </Heading>
      <Card padding="lg" lozengeRight={{ label: "Ubetalt", status: "info" }}>
        <Container flexDirection="column" gap>
          <Heading variant="title-200-light" tag="h3">
            Faktura for mobil
          </Heading>
          <Container flexDirection="row" justifyContent="space-between">
            <Paragraph removeMargin variant="preamble-100">
              Utestående beløp:
            </Paragraph>
            <Paragraph variant="preamble-100">{getPriceAmountText(amountToPay, "kr")}</Paragraph>
          </Container>
          <LineSeparator />
          <Container flexDirection="row" justifyContent="space-between">
            <Container flexDirection="column">
              <Paragraph removeMargin variant="additional-100">
                Til konto:
              </Paragraph>
              <Container flexDirection="row" alignItems="center">
                <Paragraph whiteSpace="nowrap" variant="additional-100" isFaded removeMargin>
                  {BANK_ACCOUNT_NUMBER}
                </Paragraph>
                <Button
                  title="Kopier Kontonummer"
                  size="sm"
                  isIconButton
                  iconSize="sm"
                  variant="tertiary-black"
                  icon="copy"
                  onClick={() => handleCopyClick(BANK_ACCOUNT_NUMBER.replace(/\s+/g, ""), "Kontonummer")}
                />
              </Container>
            </Container>
            <Container />
            <Container flexDirection="column">
              <Paragraph removeMargin variant="additional-100">
                Forfallsdato:
              </Paragraph>
              <Container flexDirection="row" alignItems="center" flexGrow={1}>
                <Paragraph whiteSpace="nowrap" variant="additional-100" isFaded removeMargin>
                  {dayjs(paymentDate).format(format.DATE_DISPLAY_FORMAT_MONTH_NAME)}
                </Paragraph>
              </Container>
            </Container>
          </Container>
          <LineSeparator />
          <Container flexWrap={isMobile} flexDirection="row" justifyContent="space-between">
            <Container flexDirection="column">
              <Paragraph removeMargin variant="additional-100">
                Organisasjonsnr.:
              </Paragraph>
              <Container flexDirection="row" alignItems="center">
                <Paragraph variant="additional-100" isFaded removeMargin>
                  {ORG_NUMBER}
                </Paragraph>
                <Button
                  title="Kopier Kontonummer"
                  size="sm"
                  isIconButton
                  iconSize="sm"
                  variant="tertiary-black"
                  icon="copy"
                  onClick={() => handleCopyClick(ORG_NUMBER.replace(/\s+/g, ""), "Organisasjonsnr.")}
                />
              </Container>
            </Container>
            <Container flexDirection="column">
              <Paragraph removeMargin variant="additional-100">
                Swift:
              </Paragraph>
              <Container flexDirection="row" alignItems="center">
                <Paragraph variant="additional-100" isFaded removeMargin>
                  {SWIFT_NUMBER}
                </Paragraph>
                <Button
                  title="Kopier Kontonummer"
                  size="sm"
                  isIconButton
                  iconSize="sm"
                  variant="tertiary-black"
                  icon="copy"
                  onClick={() => handleCopyClick(SWIFT_NUMBER, "Swift")}
                />
              </Container>
            </Container>
            <Container flexDirection="column">
              <Paragraph removeMargin variant="additional-100">
                IBAN:
              </Paragraph>
              <Container flexDirection="row" alignItems="center">
                <Paragraph variant="additional-100" isFaded removeMargin>
                  {IBAN_NUMBER}
                </Paragraph>
                <Button
                  title="Kopier Kontonummer"
                  size="sm"
                  isIconButton
                  iconSize="sm"
                  variant="tertiary-black"
                  icon="copy"
                  onClick={() => handleCopyClick(IBAN_NUMBER.replace(/\s+/g, ""), "IBAN")}
                />
              </Container>
            </Container>
          </Container>
          <LineSeparator />
          <Card padding="sm" backgroundColor="grey" showShadow={false} removeMinHeight>
            <Container gap alignItems="center">
              <Icon size="sm" icon="calendar" />
              <Paragraph removeMargin isFaded>
                Neste faktura kommer
              </Paragraph>
              <Paragraph isFaded>{<em>{nextInvoiceDate}</em>}</Paragraph>
            </Container>
          </Card>
        </Container>
      </Card>
    </Container>
  );
};
