import { types } from "@telia-no-min-side/utils";
import { productId } from "../../../../../util/productIds";

export function getOptionsByAxiomId(
  id: number,
  options: types.fixed.ProductGroupWithShipping
): types.fixed.ProductGroupSingleProductWithShipping | undefined {
  return options.find((option) => option.axiomId === id);
}

export function someOptionsHasRecordingAddon(options: types.fixed.ProductGroupWithShipping): boolean {
  return options.some((option) => option.addons.some((addon) => addon.addon.productIds.includes(productId.recording)));
}

export function allOptionsHasRecordingAddon(options: types.fixed.ProductGroupWithShipping): boolean {
  return options.every((option) => option.addons.some((addon) => addon.addon.productIds.includes(productId.recording)));
}

export function noOptionHasRecordingAddon(options: types.fixed.ProductGroupWithShipping): boolean {
  return !options.some((option) => option.addons.some((addon) => addon.addon.productIds.includes(productId.recording)));
}

function getAddonIds(recording?: {
  addon?: types.fixed.ProductGroupSingleProductAddon;
  price?: types.fixed.ProductGroupPriceExtended;
}): number[] {
  if (recording?.addon) {
    return [recording.addon.addonId.id];
  }
  return [];
}

function getAxiomIds(
  tvBox: types.fixed.ProductGroupSingleProductWithShipping,
  modem?: types.fixed.ProductGroupSingleProductWithShipping,
  recording?: {
    addon?: types.fixed.ProductGroupSingleProductAddon;
    price?: types.fixed.ProductGroupPriceExtended;
  }
) {
  if (modem) {
    return [
      {
        axiomId: tvBox.axiomId,
        quantity: 1,
        addonIds: getAddonIds(recording),
      },
      {
        axiomId: modem.axiomId,
        quantity: 1,
        addonIds: [],
      },
    ];
  } else {
    return [
      {
        axiomId: tvBox.axiomId,
        quantity: 1,
        addonIds: getAddonIds(recording),
      },
    ];
  }
}

export function createOrder(
  tvBox: types.fixed.ProductGroupSingleProductWithShipping,
  shippingMethod?: types.fixed.ProductGroupShipping,
  recording?: {
    addon?: types.fixed.ProductGroupSingleProductAddon;
    price?: types.fixed.ProductGroupPriceExtended;
  },
  modem?: types.fixed.ProductGroupSingleProductWithShipping
): types.fixed.SubmitMultipleProducts {
  const order: types.fixed.SubmitMultipleProducts = {
    axiomIds: getAxiomIds(tvBox, modem, recording),
    shippingMethodId: shippingMethod ? shippingMethod.shippingMethodId : undefined,
  };
  return order;
}

export function findAddonFromId(
  id: number,
  productOptions: types.fixed.ProductGroupWithShipping
): types.fixed.ProductAddonWithPrice | undefined {
  const addons: types.fixed.ProductAddonWithPrice[] = productOptions.flatMap((option) => option.addons);

  return addons.find((addon) => addon.addon.addonId.id === id);
}
