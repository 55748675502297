import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_INSURANCE_PROOF_DATA } from "./graphql/getInsuranceProofData";
import { GetInsuranceAgreementsProofQuery } from "gql/graphql";
import { useMobileAccount } from "hooks/useMobileAccount";
import { Spinner } from "components/spinner-telia/Spinner";
import { Flex, Notification } from "@telia/teddy";

export function InsuranceProofDocumentPage() {
  const { contractId } = useParams();
  const { isLoading: isLoadingUser, phoneNumber, accountId } = useMobileAccount();

  const { loading, error, data } = useQuery<GetInsuranceAgreementsProofQuery>(GET_INSURANCE_PROOF_DATA, {
    variables: {
      phoneNumber,
      contractId,
      accountId,
    },
    skip: !phoneNumber,
  });

  if (!loading && !isLoadingUser && error)
    return (
      <Flex justify="center" p="300">
        <Notification variant="error">
          <Notification.Heading as="h3">
            <Notification.Icon>
              Vi klarte ikke laste ned forsikringsbeviset. Vennligst ta kontakt med kundeservice.
            </Notification.Icon>
          </Notification.Heading>
        </Notification>
      </Flex>
    );

  if (!loading && !isLoadingUser && data?.subscription?.insuranceProofDocument?.document) {
    const byteCharacters = atob(data?.subscription?.insuranceProofDocument?.document);
    const byteNumbers = byteCharacters.split("").map((char: string) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    const blobFile = new Blob([byteArray], { type: "application/pdf" });
    window.location.href = URL.createObjectURL(blobFile);
  }

  return <Spinner />;
}
