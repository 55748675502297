import { Heading, TextSpacing, Text, Flex, Skeleton, Box, Notabene, Button } from "@telia/teddy";
import { Link, useParams } from "react-router-dom";
import { Container } from "@telia-no-min-side/components";
import { TopCard } from "./components/TopCard";
import { Line } from "./components/Line";
import { FamilySubscriptionCard } from "./components/FamilySubscriptionCard";
import { format, uri } from "@telia-no-min-side/utils";
import { useB2BFamilyDiscountFlow } from "./useB2BFamilyDiscount";
import { Success } from "./components/Success";

export function Overview() {
  const { b2cAccountId, b2bPhoneNumber } = useParams();
  const { orderFamilyDiscount, b2cAccount, isLoading } = useB2BFamilyDiscountFlow({
    selectedB2cAccountId: b2cAccountId,
  });

  const sortedSubsByRank = b2cAccount?.subscriptions
    ?.filter((sub) => sub.userOffering)
    ?.slice()
    ?.sort((sub1, sub2) => (sub1.subscriptionRank || 0) - (sub2.subscriptionRank || 0));

  const subWithoutLinesDiscount = sortedSubsByRank?.find(
    (sub) => !sub.userOffering?.monthlyCost?.reasons?.some((r) => r?.code === "MONTHLY_CHARGE_LINE")
  );

  const discountPrice =
    (subWithoutLinesDiscount?.userOffering?.originalPrice?.amount || 0) -
    (subWithoutLinesDiscount?.userOffering?.offeringPrices
      ?.flatMap((o) => o?.offeringPrices)
      .find((o) => o?.code === "MONTHLY_CHARGE_LINE")?.amount || 0);

  const totalPrice =
    sortedSubsByRank?.reduce((acc, sub) => acc + (sub?.userOffering?.monthlyCost?.amount || 0), 0) || 0;

  const isActivating = orderFamilyDiscount.isOrderInProgress || orderFamilyDiscount.isOrderDiscountMutationCalled;

  if (!isLoading && !orderFamilyDiscount.needToOrder) {
    return <Success />;
  }

  if (!isLoading && (!b2cAccountId || !b2bPhoneNumber)) {
    return (
      <Container goBackTo="/minside/hjem" padding maxWidth="sm" flexDirection="column" gap="lg">
        <TextSpacing>
          <Heading variant="title-300" as="h1">
            En feil har oppstått!
          </Heading>
          <Text>Vi kunne ikke finne kontoen din. Vennligst prøv igjen.</Text>
          <Button asChild>
            <Link to={uri.minSideFixed("/mobil/bedrift-familierabatt")}>Prøv igjen</Link>
          </Button>
        </TextSpacing>
      </Container>
    );
  }

  return (
    <Container goBackTo="/minside/hjem" padding maxWidth="sm" flexDirection="column" gap="lg">
      <TextSpacing>
        <Heading variant="title-300" as="h1">
          Aktiver din familierabatt
        </Heading>

        <Text>Her er oversikt over din familerabatt. Husk å aktivere familierabatten med knappen nedenfor.</Text>
      </TextSpacing>

      {isLoading ? (
        <>
          <Skeleton loading maxWidth="400px" minHeight="100px" />
          <Skeleton loading maxWidth="400px" minHeight="100px" />
          <Skeleton loading maxWidth="400px" minHeight="100px" />
        </>
      ) : (
        <>
          <Flex direction="column" gap="200" style={{}}>
            <TopCard />
            {sortedSubsByRank?.map((sub, i) => (
              <Box position="relative" key={sub.phoneNumber.localNumber}>
                <Line />
                <FamilySubscriptionCard style={{ marginLeft: "2rem", width: "unset" }} subscription={sub} index={i} />
              </Box>
            ))}

            <Flex justify="between">
              <Heading as="h3" variant="title-100">
                Din familierabatt
              </Heading>
              <Text>{format.simplePriceText(discountPrice, "md.")}</Text>
            </Flex>
            <Flex justify="between">
              <Heading as="h3" variant="title-100">
                Totalpris
              </Heading>
              <Flex gap="300">
                <Text style={{ textDecorationLine: "line-through" }}>{format.simplePriceText(totalPrice, "md.")}</Text>
                <Text>{format.simplePriceText(totalPrice - discountPrice, "md.")}</Text>
              </Flex>
            </Flex>

            {b2bPhoneNumber && b2cAccountId ? (
              <Button
                loading={isActivating}
                ml={"auto"}
                onClick={() => orderFamilyDiscount.order({ b2bPhoneNumber, b2cAccountId })}
              >
                Aktiver familierabatt
              </Button>
            ) : null}
          </Flex>
          {isActivating && <Text as="p">Vi jobber med å aktivere din familierabatt. Dette kan ta litt tid.</Text>}

          <Notabene>
            <Notabene.Icon name="bulb" />
            <Notabene.Content>
              <Notabene.Heading>Slik fungerer familierabatt</Notabene.Heading>
              <Text mb="50">
                Ditt bedriftsabonnement gir rabatt til de andre abonnementene du har. Disse abonnementene vil ha en{" "}
                <strong>fast rabatt (30,-/md. for fast datamengde og 100,-/md. for ubegrenset).</strong>
              </Text>
            </Notabene.Content>
          </Notabene>
        </>
      )}
    </Container>
  );
}
