import { graphql } from "src/gql";

export const CURRENT_SUBSCRIPTION_FRAGMENT = graphql(`
  fragment CurrentSubscription on SubscriptionData {
    phoneNumber {
      localNumber
    }
    additionalProducts {
      id
      code
      productGroup
      productCategory
      name
      productTerms {
        code
        amount
        monetaryUnit
      }
    }
    subscriptionRank
    userOffering {
      pricePlan
      name
      longDescription
      shortName
      monthlyCost {
        amount
      }
      originalPrice {
        amount
      }
      additionalProperties {
        name
        value
      }
      offeringPrices {
        offeringPrices {
          code
          amount
          monetaryUnit
        }
      }
      disclaimers {
        code
        name
        text
        weight
      }
    }
  }
`);
