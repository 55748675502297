import { ApolloError } from "@apollo/client";
import { VAS_ERROR_CODE, VAS_MESSAGE } from "util/constants/valueAddedServices";

type DisplayError = {
  title: string;
  text: string;
};

export const getErrorMessage = (error: ApolloError | undefined): DisplayError | undefined => {
  if (!error) return undefined;

  if (error.message.includes(VAS_ERROR_CODE.USED_BY_SIS)) {
    return {
      title: VAS_MESSAGE.CANNOT_PROCCESS_ORDER,
      text: VAS_MESSAGE.EMAIL_ALREADY_IN_USE,
    };
  }

  return {
    title: VAS_MESSAGE.CANNOT_COMPLETE_ORDER,
    text: VAS_MESSAGE.UNKNOWN_ERROR,
  };
};
