import { Badge, Box, Card, Flex, ProgressBar, Text, Skeleton } from "@telia/teddy";
import { canOrderNordicOrBalticMinutesPrepaidPlan } from "util/mobile/prepaid";
import { GetPrepaidUsagesQuery } from "gql/graphql";
import dayjs from "dayjs";

type Props = {
  loading: boolean;
  usageTopup: GetPrepaidUsagesQuery["subscription"]["usageTopup"];
};

export function CallBalance({ loading, usageTopup }: Props) {
  const nordicBalticsMinutes = usageTopup?.topupQuotas.find((quota) => quota.product?.isNordicAndBalticMinutes);
  if (!loading && canOrderNordicOrBalticMinutesPrepaidPlan(usageTopup?.topupQuotas)) {
    return null;
  }

  return (
    <Card variant="white" shadow asChild display="flex" bordered style={{ height: "content-fit" }}>
      <Flex direction="column" justify="stretch">
        <Flex direction="column" id="secret">
          <Text>Ringing i Norden og Baltikum</Text>
          <Card.Heading as="h3" variant="title-400" mb="400">
            <Skeleton width="100px" loading={loading}>
              {nordicBalticsMinutes?.usage.remaining.amount} min
            </Skeleton>
          </Card.Heading>
        </Flex>
        <Card.Line />
        <Box mb="400">
          <Skeleton width="100px" loading={loading}>
            <Text mb="100">
              {nordicBalticsMinutes?.usage.remaining.amount} igjen av {nordicBalticsMinutes?.usage.total.amount} min
            </Text>
          </Skeleton>
          <Skeleton width="100%" height="20px" loading={loading}>
            {nordicBalticsMinutes?.usage.remaining.amount && nordicBalticsMinutes?.usage.total.amount && (
              <ProgressBar
                progress={(nordicBalticsMinutes.usage.remaining.amount / nordicBalticsMinutes.usage.total.amount) * 100}
              />
            )}
          </Skeleton>
        </Box>
        <Skeleton width="100px" height="20px" loading={loading}>
          <Box>
            <Badge variant="information">Utgår om {dayjs(nordicBalticsMinutes?.expireTime).toNow(true)} </Badge>
          </Box>
        </Skeleton>
      </Flex>
    </Card>
  );
}
