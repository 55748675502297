import { getTopupPeriod } from "../../TopupDrawer/utils/getTopupPeriod";
import { getTopupVouchers } from "../../TopupDrawer/utils/getTopupVouchers";
import { TopupLocalType } from "pages/mobile/mobile-subscription-dashboard/types";

export function getTopupDisplayInfo(topup: TopupLocalType) {
  const quotaValue = topup.quota?.value || 0;
  const isMinutes = topup.quota?.unit === "MIN";
  const size = isMinutes ? quotaValue / 60 : quotaValue;
  const sizeUnit = isMinutes ? `time${size > 1 ? "r" : ""}` : topup.quota?.unit;
  const price = "price" in topup && topup.price ? topup.price.valueWithVat : 0;

  const { numberOfVouchers, lastReceivedDate, nextExpirationDateNullable } = getTopupVouchers(topup);

  return {
    id: topup.id,
    text: getTopupPeriod(topup.name),
    size,
    sizeUnit,
    price,
    numberOfVouchers,
    lastReceivedDate,
    nextExpirationDateNullable,
  };
}
