import { createContext, useContext } from "react";
import { ExpandableCardState } from "../ExpandableCard/Context";
import { AccordionProps } from ".";

export type AccordionState = {
  openIds?: string[];
  setAccordionItemOpen?: (id: ExpandableCardState["id"]) => void;
} & Required<Pick<AccordionProps, "variant">>;

export const AccordionContext = createContext<AccordionState>({
  openIds: undefined,
  setAccordionItemOpen: undefined,
  variant: "primary",
});

export function useAccordion() {
  return useContext(AccordionContext);
}
