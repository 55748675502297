import { types } from "@telia-no-min-side/utils";

type Args = {
  selectedRouter: types.fixed.ProductGroupSingleProductWithShipping | undefined;
  smartExtender: types.fixed.ProductGroupSingleProductWithShipping | undefined;
  quantityExtender: number;
  shippingMethodId?: number;
};

export function getOrderToPlace({
  selectedRouter,
  smartExtender,
  quantityExtender,
  shippingMethodId,
}: Args): types.fixed.SubmitMultipleProducts {
  const routerOrder = selectedRouter
    ? [
        {
          axiomId: selectedRouter.axiomId,
          quantity: 1,
          addonIds: [],
        },
      ]
    : [];
  const extenderOrder =
    smartExtender && quantityExtender
      ? [
          {
            axiomId: smartExtender.axiomId,
            quantity: quantityExtender,
            addonIds: [],
          },
        ]
      : [];

  return {
    axiomIds: [...routerOrder, ...extenderOrder],
    shippingMethodId,
  };
}
