import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchSafeOnNet() {
  const { data, mutate, error, ...rest } = useAxiosSWR<types.fixed.ProductCategory>(ICX_REQUEST.URL_SAFE_ON_NET);

  return {
    safeOnNet: data,
    isError: !!error,
    error: error,
    mutateSafeOnNet: mutate,
    ...rest,
  };
}
