import { Drawer, VisuallyHidden } from "@telia/teddy";
import { TopupCategory } from "gql/graphql";
import { Roaming } from "./modules/Roaming/Roaming";
import { NORDIC_BALTIC_MINUTES_CODE } from "util/constants/topups";
import { NordicBalticMinutes } from "./modules/NordicBalticMinutes/NordicBalticMinutes";
import { DataBoostsAndPackages } from "./modules/DataBoostsAndPackages/DataBoostsAndPackages";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";

export function TopupDrawer() {
  const { isDrawerOpen, selectedTopupCategory, closeDrawerResetTopup } = useMobileDashboardContext();

  const getDrawerTitle = () => {
    switch (selectedTopupCategory) {
      case TopupCategory.Roaming:
        return "Datapakker utenfor EU, EØS, Sveits, Storbritannia";
      case TopupCategory.Domestic:
        return "Datapakker";
      case TopupCategory.DataBoost:
        return "Data Boost";
      case NORDIC_BALTIC_MINUTES_CODE:
        return "Ring Norden & Baltikum";
      default:
        return "Topup";
    }
  };

  const renderContent = () => {
    switch (selectedTopupCategory) {
      case TopupCategory.Roaming:
        return <Roaming />;
      case TopupCategory.Domestic:
      case TopupCategory.DataBoost:
        return <DataBoostsAndPackages />;
      case NORDIC_BALTIC_MINUTES_CODE: {
        return <NordicBalticMinutes />;
      }
      default:
        return null;
    }
  };

  return (
    <Drawer open={isDrawerOpen} onOpenChange={(open) => !open && closeDrawerResetTopup()}>
      <VisuallyHidden>
        <Drawer.Title>{getDrawerTitle()}</Drawer.Title>
      </VisuallyHidden>
      <Drawer.Content width="100%" maxWidth="500px">
        <Drawer.Close aria-label="Close" slot="floating" onClick={closeDrawerResetTopup} />
        {renderContent()}
      </Drawer.Content>
    </Drawer>
  );
}
