import { ProductFilters } from "../types";
import { AdditionalProduct, GroupedAdditionalProducts } from "src/gql/graphql";

export function availableProductsList(
  isLegalOwner: boolean,
  isOver18: boolean,
  additionalProducts?: GroupedAdditionalProducts | null
) {
  const filteredAdditionalProducts: Record<string, AdditionalProduct[]> = {};
  if (!additionalProducts) return filteredAdditionalProducts;

  for (const [key, value] of Object.entries(additionalProducts)) {
    // filter out __typename property
    if (typeof value !== "string") {
      const productGroup: AdditionalProduct[] = [];
      if (!isLegalOwner) {
        if (!isOver18) {
          value?.forEach((singleProduct) => {
            if (
              singleProduct &&
              !singleProduct.name?.toLowerCase().includes("sperre") &&
              !singleProduct.name?.toLowerCase().includes("topup_b2c")
            ) {
              productGroup.push(singleProduct);
            }
          });
        } else {
          value?.forEach((singleProduct) => {
            if (
              (singleProduct && !singleProduct.name?.toLowerCase().includes("sperre")) ||
              singleProduct?.code === ProductFilters.Hint
            ) {
              productGroup.push(singleProduct);
            }
          });
        }
      } else {
        value?.forEach((singleProduct) => {
          // MinSide are not ready to sell this product
          if (singleProduct && singleProduct.code !== "TOPUP_B2C_DA_100") {
            productGroup.push(singleProduct);
          }
        });
      }
      filteredAdditionalProducts[key] = productGroup;
    }
  }

  return filteredAdditionalProducts;
}
