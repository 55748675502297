import React from "react";
import { ModalBody, ModalHeading, Paragraph } from "@telia-no-min-side/components";
import { StyledFooter } from "./style";
import invoiceImage from "./assets/invoice.png";

export function InvoiceNumberContent(): JSX.Element {
  return (
    <>
      <ModalHeading>Fakturanummer</ModalHeading>
      <ModalBody>
        <img src={invoiceImage} alt="Hvor du finner fakturanummer" />
      </ModalBody>
      <StyledFooter>
        <Paragraph>Dette nummeret kan du se øverst på din faktura i din nettbank for TV/Internett fra Telia.</Paragraph>
      </StyledFooter>
    </>
  );
}
