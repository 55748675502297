import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { CustomerCareLink } from "src/components/customer-care-link";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = {
  isLoading: boolean;
  refetch?: () => void;
};

export function ErrorMessage({ isLoading, refetch }: Props) {
  const url = uri.minSideFixed("/internett");
  return (
    <Container padding showGoBackButton flexDirection="column">
      <Heading tag="h2" variant="title-300">
        Noe gikk galt!
      </Heading>
      <Paragraph>
        Vi kunne dessverre ikke behandle forespørselen din og fikk heller ikke opprettet en manuell sak for oppfølging.
        Vennligst prøv igjen, eller kontakt vår kundeservice:
      </Paragraph>
      <CustomerCareLink trackingContext="upgrade-downgrade-summary" />
      <Container flexDirection="row" gap padding="top">
        {!!refetch && (
          <Button
            isLoading={isLoading}
            onClick={refetch}
            trackEvent={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_ERROR,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.UPGRADE_DOWNGRADE_SUMMARY,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.TRY_AGAIN,
            }}
          >
            Prøv igjen
          </Button>
        )}

        <Button
          tag="a"
          iconPosition="before"
          icon="arrow-left"
          isInternalNavigation
          href={url}
          hideLinkIcon
          variant="secondary"
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_ERROR,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.UPGRADE_DOWNGRADE_SUMMARY,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
            ui_item_url: url,
            ui_item_text: TRACK_EVENT.ITEM_TEXT.BACK_TO_OVERVIEW,
          }}
        >
          Tilbake til oversikten
        </Button>
      </Container>
    </Container>
  );
}
