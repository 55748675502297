import { Text, Flex, Heading, Icon, Card, Badge, Button, ChannelButton, Skeleton } from "@telia/teddy";
import { useCompensation } from "./useCompensation";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { TrackClickEvent } from "@telia-no-min-side/components";

export function PointsCompensationCard() {
  const { currentUserCompensation, isLoading } = useCompensation();
  return (
    <Card variant="white" bordered layout="default">
      {currentUserCompensation?.hasExtraPoints ? (
        <Card.Slot>
          <Badge variant="success">Du har dette</Badge>
        </Card.Slot>
      ) : null}
      <Card.Heading>
        <Skeleton loading={isLoading}>
          <Flex as="span" gap="100" justify="between" align="center">
            {currentUserCompensation?.canSwitchCompensation ? <span>Ditt tilbud</span> : <span>Dine TV-poeng</span>}
            <Flex gap="50" as="span" align="center">
              <Text as="span" variant="paragraph-100-medium">
                <s>
                  {(currentUserCompensation?.points.initial ?? 0) + (currentUserCompensation?.points.extra15 ?? 0)}{" "}
                  poeng
                </s>
              </Text>
              <Text as="span">{currentUserCompensation?.points.total ?? 0} poeng</Text>
            </Flex>
          </Flex>
        </Skeleton>
      </Card.Heading>
      <Card.Description>
        Med 50 ekstra TV-poeng kan du velge inn favorittinnholdet ditt og skreddersy TV-opplevelsen din akkurat slik du
        vil ha den.
      </Card.Description>
      <Card.Content>
        <Heading as="h3" variant="title-100">
          Bytt så ofte du vil
        </Heading>
        <Flex gap="200" wrap="wrap" align="center" mt="100" mb="200">
          <ChannelButton as="div">
            <ChannelButton.Image src="https://www.telia.no/globalassets/tv-channel-logos/netflix.svg" alt="Netflix" />
            <ChannelButton.Text>50 poeng</ChannelButton.Text>
          </ChannelButton>
          <ChannelButton as="div">
            <ChannelButton.Image src="https://www.telia.no/globalassets/tv-channel-logos/max.svg" alt="Max" />
            <ChannelButton.Text>30 poeng</ChannelButton.Text>
          </ChannelButton>
          <ChannelButton as="div">
            <ChannelButton.Image src="https://www.telia.no/globalassets/tv-channel-logos/viaplay.svg" alt="viaplay" />
            <ChannelButton.Text>45 poeng</ChannelButton.Text>
          </ChannelButton>
          <ChannelButton as="div">
            <ChannelButton.Image
              src="https://www.telia.no/globalassets/tv-channel-logos/skyshowtime.svg"
              alt="Sky Showtime"
            />
            <ChannelButton.Text>30 poeng</ChannelButton.Text>
          </ChannelButton>
          <ChannelButton as="div">
            <ChannelButton.Image src="https://www.telia.no/globalassets/tv-channel-logos/prime.svg" alt="Prime" />
            <ChannelButton.Text>30 poeng</ChannelButton.Text>
          </ChannelButton>
        </Flex>
        <Text>Dette tilbudet gjelder frem til TV 2 Play er tilgjengelig igjen på Telia Play.</Text>
      </Card.Content>
      <Card.Footer>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: "click",
            ui_item_type: "button",
            ui_item_text: "Bruk poengene dine nå",
            ui_item_url: EXTERNAL_LINK.TELIA_PLAY_CHANNELPICKER,
            ui_item_context: "tv-compensation",
          }}
        >
          <Button variant="secondary" asChild>
            <a href={EXTERNAL_LINK.TELIA_PLAY_CHANNELPICKER} target="_blank" rel="noopener noreferrer">
              Bruk poengene dine nå
              <Icon name="arrow-right" />
            </a>
          </Button>
        </TrackClickEvent>
      </Card.Footer>
    </Card>
  );
}
