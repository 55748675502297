import { remCalc } from "@telia-no-min-side/components";
import styled from "styled-components";

export const Header = styled.div`
  font-weight: 700;
  font-size: ${remCalc(30)};
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const HeaderText = styled.div`
  font-weight: 500;
  font-size: ${remCalc(20)};
  margin-top: 1.5rem;
  margin-bottom: 1.75rem;
`;
