import * as React from "react";
import { ApolloError, useQuery } from "@apollo/client";
import queryString from "query-string";
import { useAuth } from "@telia-no-min-side/components";
import { ErrorMessage } from "./components";
import InvoiceDetailedUsage from "./components/InvoiceDetailedUsage";
import { config } from "util/config";
import { USER_ACCESS_LEVEL, INVOICED_SERVICE, UN_BILLED_SERVICE } from "./queries";
import { AccessLevel, LoginProvider, UserQuery } from "gql/graphql";
import { SkeletonForm } from "pages/profile/common/SkeletonForm";

type IInvoiceDetailedUsagePageWrapperProps = {
  phoneNumber: string;
  serviceCodes: string[];
  invoiceId: string;
};

function InvoiceDetailedUsagePageWrapper({
  phoneNumber,
  serviceCodes,
  invoiceId,
}: IInvoiceDetailedUsagePageWrapperProps) {
  const hasBeenInvoiced = !!invoiceId;
  const query = hasBeenInvoiced ? INVOICED_SERVICE : UN_BILLED_SERVICE;
  const { data, loading, error } = useQuery(query, {
    variables: {
      phoneNumber,
      invoiceId,
      serviceCodes,
    },
  });

  return <InvoiceDetailedUsage loading={loading} error={error} data={data} serviceCodes={serviceCodes} />;
}

type IInvoiceDetailedUsagePageWithExtraSecurityCheck = {
  loading: boolean;
  error?: ApolloError;
  data?: UserQuery;
  performExtraSecurityLogin: () => void;
  children: React.ReactNode;
};
export function InvoiceDetailedUsagePageWithExtraSecurityCheck({
  loading,
  error,
  data,
  performExtraSecurityLogin,
  children,
}: IInvoiceDetailedUsagePageWithExtraSecurityCheck) {
  if (loading) {
    return <SkeletonForm />;
  }
  if (error || !data) {
    return <ErrorMessage errorText="En uventet feil oppstod." />;
  }

  if (data.user?.accessLevel !== AccessLevel.Otp) {
    if (data.user?.loginProvider === LoginProvider.ClearTrust) {
      const additionalSecurityUrl = `${
        config.legacyMinSideRootUrl
      }/re/ekstra-sikkerhet?CT_ORIG_URL=${encodeURIComponent(window.location.href)}`;
      window.location.assign(additionalSecurityUrl);
      return <React.Fragment />;
    }

    performExtraSecurityLogin();
    return <React.Fragment />;
  }

  return <>{children}</>;
}

export function InvoiceDetailedUsagePage() {
  const { requestBankId } = useAuth();
  const { data, loading, error } = useQuery(USER_ACCESS_LEVEL);

  const {
    phoneNumber = "",
    serviceCode = [],
    invoiceId = "",
  } = queryString.parse(location.search, { arrayFormat: "comma" });

  const serviceCodesArray = typeof serviceCode === "string" ? serviceCode.split(",") : serviceCode;

  return (
    <InvoiceDetailedUsagePageWithExtraSecurityCheck
      loading={loading}
      error={error}
      data={data}
      performExtraSecurityLogin={() => {
        requestBankId && requestBankId();
        return <React.Fragment />;
      }}
    >
      <InvoiceDetailedUsagePageWrapper
        phoneNumber={phoneNumber as string}
        serviceCodes={serviceCodesArray as string[]}
        invoiceId={invoiceId as string}
      />
    </InvoiceDetailedUsagePageWithExtraSecurityCheck>
  );
}
