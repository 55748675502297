import { useFetchUser } from "src/api/fixed";
import { useSubscriptionOverview } from "../hooks/useSubscriptionOverview";
import { FirstTimeCard } from "../useful-shortcuts/FirstTimeCard";

export function NoAccounts() {
  const user = useFetchUser();
  const subscriptionOverview = useSubscriptionOverview();
  const usersWithFixedProducts = user.data?.usersWithCustomerId || [];

  return (
    !subscriptionOverview.hasAnyAccounts &&
    usersWithFixedProducts.length === 0 &&
    !subscriptionOverview.loading &&
    !user.isLoading && <FirstTimeCard />
  );
}
