import React from "react";
import { CSSTransition } from "react-transition-group";
import { FadeInContent } from "../../../modules/Animations/FadeIn/FadeInContent";

import "./ExpandablePortForwardingRule.less";

export const ExpandablePortForwardingRule = ({ show, children }) => (
  <CSSTransition in={show} timeout={600} unmountOnExit classNames="expandable-port-forwarding-rule">
    {(state) => (
      <div>
        <FadeInContent show={state === "entered"}>{children}</FadeInContent>
      </div>
    )}
  </CSSTransition>
);
