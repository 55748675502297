import { Card as TCard, Icon as TIcon } from "@telia-no-min-side/components";
import styled from "styled-components";

export const Card = styled(TCard)`
  margin-bottom: 1rem;
  width: fit-content;
`;

export const Icon = styled(TIcon)`
  margin-right: 0.5rem;
`;

export const NoShrinkParagraph = styled.p`
  flex-shrink: 0;
  margin: 0;
`;
