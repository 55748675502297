import { BaseSyntheticEvent } from "react";
import { Box, Button, Text } from "@telia/teddy";
import { types } from "@telia-no-min-side/utils";
import { ShoppingCard } from "components/shopping-card";
import { getSimplePriceText } from "src/util/priceFormatter";
import { DeviceSpecification } from "./DeviceSpecification";

type Props = {
  product: types.fixed.ProductGroupSingleProductWithShipping;
  isSelected: boolean;
  onSelectClick?: (e: BaseSyntheticEvent) => void;
  shouldDisableSelect?: boolean;
};

export function SmartWifiRouter({ product, isSelected, onSelectClick, shouldDisableSelect }: Props) {
  const { currentPrice, postCampaignPrice, campaignDuration } = product.price;
  const isCampaign = postCampaignPrice.recurring > currentPrice.recurring;

  return (
    <ShoppingCard
      title={product.name || "Smart Wifi Router"}
      additionalTagText={
        <Text>
          Vår kraftigste router. Smart WiFi sørger for et superraskt og stabilt nett hjemme, som sømløst dekker hele
          huset.
        </Text>
      }
      image={product.image}
      isSelected={isSelected}
      price={getSimplePriceText(currentPrice.recurring, currentPrice.chargePeriod) || "Finner ikke pris."}
      postCampaignPrice={isCampaign ? getSimplePriceText(postCampaignPrice.recurring) : undefined}
      campaignText={campaignDuration}
    >
      <Box mt="200" mb="100">
        <DeviceSpecification
          specifications={[
            "Superenkel oppkobling og tilkobling",
            "Integrert mesh - supert for større boliger",
            "Smarte funksjoner",
            "Wi-Fi 6",
            "Seneste sikkerhetskryptering",
          ]}
          gaTrackingData={{
            onOpenTracking: {
              ui_item_action: "dropdown-click",
              ui_item_context: "buy-smart-wifi",
              ui_item_text: "Se spesifikasjoner",
              ui_item_type: "dropdown",
              ui_item_url: "no_url",
            },
          }}
        />
      </Box>
      {onSelectClick && (
        <ShoppingCard.Footer>
          <Box mt="200">
            <Button
              disabled={shouldDisableSelect}
              variant={isSelected ? "primary" : "secondary"}
              size="sm"
              onClick={onSelectClick}
            >
              {isSelected ? "Valgt" : "Velg"}
            </Button>
          </Box>
        </ShoppingCard.Footer>
      )}
    </ShoppingCard>
  );
}
