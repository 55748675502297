import cloneDeep from "lodash.clonedeep";
import { ipv4Equals } from "./validation";

export const portForwardingTags = {
  deleteRule: "portforwarding-rule-delete",
  updateExistingRule: "portforwarding-update-existing-rule",
  createNewRule: "portforwarding-write-new-rule",
  externalPortFrom: "external-port-from",
  externalPortTo: "external-port-to",
  internalPortFrom: "internal-port-from",
  externalPort: "external-port",
  internalIp: "internal-ip",
  internalPort: "internal-port",
  name: "name",
  type: "type",
  enabled: "enabled",
};

export const portForwardingUtilities = {
  setPortForwardingRules(homeCentral, newRules) {
    const updatedHomeCentral = cloneDeep(homeCentral);

    if (
      newRules.length <=
      updatedHomeCentral.SupportedSettings.PortForwarding.MaxNumOfRules
    ) {
      updatedHomeCentral.Settings.PortForwarding.PortForwardingRules = newRules;
    }
    return updatedHomeCentral;
  },
  updatePortForwardingRules(homeCentral, rule, index) {
    const newRules = cloneDeep(
      homeCentral.Settings.PortForwarding.PortForwardingRules
    );

    if (index === -1) {
      newRules.push(rule);
    } else {
      newRules[index] = rule;
    }
    return newRules;
  },
  createNewRule(gateway, mask) {
    return {
      Name: "",
      Protocol: "TCP",
      Enabled: true,
      ExternalFromPort: "",
      ExternalToPort: "",
      InternalFromPort: "",
      InternalToPort: "",
      InternalIP: this.extractCorrectOctetsFromGateway(gateway, mask),
    };
  },
  deleteRule(homeCentral, index) {
    const updatedHomeCentral = cloneDeep(homeCentral);

    if (
      updatedHomeCentral.Settings.PortForwarding.PortForwardingRules.length ===
      1
    ) {
      return [];
    } else {
      updatedHomeCentral.Settings.PortForwarding.PortForwardingRules.splice(
        index,
        1
      );
      return updatedHomeCentral.Settings.PortForwarding.PortForwardingRules;
    }
  },
  extractCorrectOctetsFromGateway(gateway, mask) {
    const gatewayValues = Object.values(gateway);
    const maskValues = Object.values(mask);
    const internalIpForRule = {
      Octet1: "",
      Octet2: "",
      Octet3: "",
      Octet4: "",
    };

    for (let i = 0; i < maskValues.length; i++) {
      if (maskValues[i] === 255) {
        switch (i) {
          case 0:
            internalIpForRule.Octet1 = gatewayValues[i];
            break;
          case 1:
            internalIpForRule.Octet2 = gatewayValues[i];
            break;
          case 2:
            internalIpForRule.Octet3 = gatewayValues[i];
            break;
          case 3:
            internalIpForRule.Octet4 = gatewayValues[i];
            break;
        }
      }
    }
    return internalIpForRule;
  },
  setNewValuesForRule(rule, param, value, supportsPortRange) {
    const updatedRule = cloneDeep(rule);
    const {
      externalPortFrom,
      externalPortTo,
      internalIp,
      internalPortFrom,
      name,
      type,
    } = portForwardingTags;

    if (param.split("-").includes("port")) {
      const valueFromInput = value === "" ? "" : parseInt(value, 10);

      switch (param) {
        case externalPortFrom:
          updatedRule.ExternalFromPort = valueFromInput;
          if (supportsPortRange) {
            const newInternalToPort =
              updatedRule.InternalFromPort +
              updatedRule.ExternalToPort -
              updatedRule.ExternalFromPort;

            if (newInternalToPort >= 0) {
              updatedRule.InternalToPort = newInternalToPort;
            }
          }
          if (!supportsPortRange) {
            updatedRule.ExternalToPort = valueFromInput;
          }
          break;
        case externalPortTo:
          updatedRule.ExternalToPort = valueFromInput;
          updatedRule.InternalToPort =
            updatedRule.InternalFromPort +
            updatedRule.ExternalToPort -
            updatedRule.ExternalFromPort;
          break;
        case internalPortFrom:
          updatedRule.InternalFromPort = valueFromInput;
          if (supportsPortRange) {
            updatedRule.InternalToPort =
              updatedRule.InternalFromPort +
              updatedRule.ExternalToPort -
              updatedRule.ExternalFromPort;
          }
          if (!supportsPortRange) {
            updatedRule.InternalToPort = valueFromInput;
          }
          break;
      }
    } else if (param === name) {
      updatedRule.Name = value;
    } else if (param === type) {
      updatedRule.Protocol = value;
    } else if (param === internalIp) {
      updatedRule.InternalIP = value;
    }
    return updatedRule;
  },
};

const nameRegex = /^[ 0-9a-zA-Z-_:;,.!]{0,30}$/;

const isValidName = (rule) => {
  return rule === "" || rule === null || nameRegex.test(rule.Name);
};

export const portForwardingValidation = {
  validatePortForwardingRules(rules, mask, gateway, supportsRange) {
    const errorMessages = [];
    const {
      internalIp,
      externalPortFrom,
      externalPortTo,
      internalPortFrom,
      externalPort,
      internalPort,
      name,
    } = portForwardingTags;

    rules.forEach((rule, index) => {
      const tag = `portforward-${index}-`;

      if (ipv4Equals(rule.InternalIP, gateway)) {
        errorMessages.push({
          valid: false,
          message: "Kan ikke være lik intern IP (gateway)",
          tag: tag + internalIp,
        });
      }
      if (rule.InternalIP.Octet4 === 0) {
        errorMessages.push({
          valid: false,
          message: "Kan ikke slutte på 0",
          tag: tag + internalIp,
        });
      }
      if (rule.InternalIP.Octet4 === "") {
        errorMessages.push({
          valid: false,
          message: "Alle deler av IP må ha en verdi.",
          tag: tag + internalIp,
        });
      }
      if (!isValidName(rule)) {
        errorMessages.push({
          valid: false,
          message:
            "Kan maksimalt være 30 tegn samt kun inneholde bokstaver(a-z), tall og disse tegnene -_:;,.!.",
          tag: tag + name,
        });
      }
      if (supportsRange) {
        if (rule.ExternalToPort < rule.ExternalFromPort) {
          errorMessages.push({
            valid: false,
            message: '"Fra" må være et lavere tall enn "til"',
            tag: tag + externalPortFrom,
          });
        }
        errorMessages.push(
          this.validatePortWithEmptyCheck(
            rule.ExternalFromPort,
            externalPortFrom
          )
        );
        errorMessages.push(
          this.validatePortWithEmptyCheck(rule.ExternalToPort, externalPortTo)
        );
        errorMessages.push(
          this.validatePortWithEmptyCheck(
            rule.InternalFromPort,
            internalPortFrom
          )
        );
      } else {
        errorMessages.push(
          this.validatePortWithEmptyCheck(rule.ExternalFromPort, externalPort)
        );
        errorMessages.push(
          this.validatePortWithEmptyCheck(rule.InternalFromPort, internalPort)
        );
      }
    });
    return errorMessages.filter((msg) => msg.message !== "");
  },
  validatePortForwardingRuleWRTOtherRules(
    rule,
    portRangeSupported,
    externalPorts
  ) {
    const errorMessages = [];
    const portRangeForRule = [];

    for (
      let port = rule.ExternalFromPort;
      port <= rule.ExternalToPort;
      port++
    ) {
      portRangeForRule.push(port);
    }

    externalPorts.map((portObject) => {
      if (portRangeSupported) {
        if (
          rule.ExternalFromPort >= portObject.ExternalFromPort &&
          rule.ExternalFromPort <= portObject.ExternalToPort
        ) {
          errorMessages.push({
            valid: false,
            message: `Ekstern port fra kan ikke ligge mellom de eksterne portene til en annen regel. Eksisterende porter: ${portObject.ExternalFromPort} - ${portObject.ExternalToPort}`,
            tag: portForwardingTags.externalPortFrom,
          });
        }
        if (
          rule.ExternalToPort >= portObject.ExternalFromPort &&
          rule.ExternalToPort <= portObject.ExternalToPort
        ) {
          errorMessages.push({
            valid: false,
            message: `Ekstern port til kan ikke ligge mellom de eksterne portene til en annen regel. Eksisterende porter: ${portObject.ExternalFromPort} - ${portObject.ExternalToPort}`,
            tag: portForwardingTags.externalPortTo,
          });
        }
        if (
          errorMessages.length === 0 &&
          (portRangeForRule.includes(portObject.ExternalFromPort) ||
            portRangeForRule.includes(portObject.ExternalToPort))
        ) {
          errorMessages.push({
            valid: false,
            message: `Eksterne porter for ny regel kan ikke ha et intervall som inkluderer de eksterne portene for en eksisterende regel. Eksisterende porter: ${portObject.ExternalFromPort} - ${portObject.ExternalToPort}`,
            tag: `${portForwardingTags.externalPortFrom} : ${portForwardingTags.externalPortTo}`,
          });
        }
      } else {
        if (rule.ExternalFromPort === portObject.ExternalFromPort) {
          errorMessages.push({
            valid: false,
            message: `Ekstern port fra kan være lik den eksterne porten til en annen regel. Eksisterende port: ${portObject.ExternalFromPort}`,
            tag: portForwardingTags.externalPort,
          });
        }
      }
    });
    return errorMessages;
  },
  validatePortWithEmptyCheck(port, tag) {
    if (port <= 0) {
      return {
        valid: false,
        message: "- må være større enn 0",
        tag: tag,
      };
    }
    if (port >= 65536) {
      return {
        valid: false,
        message: "- må være mindre enn 65536",
        tag: tag,
      };
    }
    if (port === "") {
      return {
        valid: false,
        message: "- må ha en verdi",
        tag: tag,
      };
    }
    return {
      valid: true,
      message: "",
      tag: tag,
    };
  },
};
