import { NewOfferingDetails, OfferingPriceCodes } from "src/gql/graphql";

export const getOldAndNewPrices = (userOfferingWithDiscount: NewOfferingDetails["offering"]) => {
  const newPriceWithDiscount = userOfferingWithDiscount?.originalPrice?.amount || "";

  const discountAmount = userOfferingWithDiscount?.price?.offeringPrices?.find(
    (priceDetails) => priceDetails?.code === OfferingPriceCodes.MonthlyDiscount
  )?.amount;

  const userOfferingWithDiscountPrice =
    discountAmount && newPriceWithDiscount ? Math.ceil(newPriceWithDiscount - discountAmount) : "";

  return {
    oldPrice: newPriceWithDiscount.toString(),
    newPrice: userOfferingWithDiscountPrice.toString(),
  };
};
