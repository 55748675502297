import { Maybe, OfferingPrice, OfferingPriceDetails, UserOffering, AdditionalProductPrice } from "src/gql/graphql";

export function mergeArraysByKey<T extends Record<string, unknown>>(
  existing: Maybe<Maybe<T>[]> | undefined,
  incoming: Maybe<Maybe<T>[]> | undefined,
  key: keyof T
): Maybe<T>[] {
  if (!existing || !incoming) return existing ?? incoming ?? [];

  const existingMap = new Map(existing.filter(Boolean).map((item) => [item![key], item]));

  const merged = incoming.filter(Boolean).reduce((acc, cur) => {
    if (cur && cur[key]) {
      const existingItem = existingMap.get(cur[key]);
      if (existingItem) {
        acc.set(cur[key], { ...existingItem, ...cur });
      } else {
        acc.set(cur[key], cur);
      }
    }
    return acc;
  }, existingMap);

  return Array.from(merged.values()).filter(Boolean);
}

export function mergeOfferingPrices(
  existing: UserOffering["offeringPrices"],
  incoming: UserOffering["offeringPrices"]
): UserOffering["offeringPrices"] {
  if (!existing || !incoming) return incoming;

  const filteredExisting = existing.filter((e): e is OfferingPrice => e !== null);
  const filteredIncoming = incoming.filter((i): i is OfferingPrice => i !== null);

  const merged = filteredExisting.map((existingItem) => {
    const correspondingIncomingItem = filteredIncoming.find(
      (incomingItem) => existingItem.__typename === incomingItem.__typename
    );
    if (!correspondingIncomingItem) return existingItem;

    // Merge OfferingPriceDetails
    const mergedOfferingPrices = mergeArraysByKey(
      existingItem.offeringPrices
        ? existingItem.offeringPrices.filter((d): d is OfferingPriceDetails => d !== null)
        : [],
      correspondingIncomingItem.offeringPrices
        ? correspondingIncomingItem.offeringPrices.filter((d): d is OfferingPriceDetails => d !== null)
        : [],
      "code"
    );
    // Merge AdditionalProductPrices
    const mergedAdditionalProductPrices = mergeArraysByKey(
      existingItem.additionalProductPrices
        ? existingItem.additionalProductPrices.filter((d): d is AdditionalProductPrice => d !== null)
        : [],
      correspondingIncomingItem.additionalProductPrices
        ? correspondingIncomingItem.additionalProductPrices.filter((d): d is AdditionalProductPrice => d !== null)
        : [],
      "productCode"
    );

    return {
      ...existingItem,
      offeringPrices: mergedOfferingPrices,
      additionalProductPrices: mergedAdditionalProductPrices,
    };
  });

  return merged;
}
