import { graphql } from "gql";

export const GET_DETAILED_SUBSCRIPTION_QUERY = graphql(`
  query DetailedSubscription($phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      productUser {
        firstName
        surname
      }
      subscriptionStatus
      account {
        id
      }
      phoneNumber {
        localNumber
      }
      additionalProducts {
        id
        code
        shortName
        productCategory
        presentation {
          active
        }
        productTerms {
          code
          amount
          monetaryUnit
        }
      }
      commitmentEndDate
      subscriptionRank
      userOffering {
        pricePlan
        originalPrice {
          amount
          description
          monetaryUnit
        }
        monthlyCost {
          amount
        }
        name
        shortName
        offeringPrices {
          offeringPrices {
            amount
            code
            description
            monetaryUnit
          }
        }
      }
      ...SubscriptionUsage
    }
  }
`);
