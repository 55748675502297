import type { FC, PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { track } from "@telia-no-min-side/utils";

export enum PromoPosition {
  Highlights = "div[id='bc-min-side-highlights']",
  Mobile = "div[id='bc-min-side-mobile']",
  BB = "div[id='bc-min-side-bb']",
  TV = "div[id='bc-min-side-tv']",
  Tips = "div[id='bc-min-side-tips']",
}
export type BlueConicItem = {
  position: PromoPosition;
  data: {
    ui_item_headline: string;
    ui_item_description: string;
    ui_item_action_text: string;
    ui_item_url: string;
    image_url: string;
    label: string;
    bc_promo_id: string;
    bc_promo_name: string;
    bc_promo_creative: string;
  };
  variantId: string;
};

type BlueConicData = {
  bcTv: BlueConicItem | undefined;
  bcBroadband: BlueConicItem | undefined;
  bcMobile: BlueConicItem | undefined;
  bcHighlights: BlueConicItem | undefined;
  bcTips: BlueConicItem | undefined;
  trackBlueConicClick: (variantId: string) => void;
  trackBlueConicView: (variantId: string) => void;
  setBlueConicProfile: (variantId: string) => void;
};

const BlueConicContext = createContext<BlueConicData>({
  bcTv: undefined,
  bcBroadband: undefined,
  bcMobile: undefined,
  bcHighlights: undefined,
  bcTips: undefined,
  trackBlueConicClick: () => {},
  trackBlueConicView: () => {},
  setBlueConicProfile: () => {},
});

function getPositionNameFromPosition(position: PromoPosition) {
  // Example: getPositionNameFromPosition(PromoPosition.Highlights)); // Output: 'bc-min-side-highlights'
  const regex = /id='([^']+)'/;

  const match = position.match(regex);

  return match?.[1] || "unknown";
}

export const BlueConicProvider: FC<PropsWithChildren> = ({ children }) => {
  const [blueConicData, setBlueConicData] = useState<Map<string, BlueConicItem>>(new Map());

  const { pushEcommercePromoTrackingEvent } = track.useEventTracking();
  const addPersonalContentItem = (newItem: BlueConicItem) => {
    setBlueConicData((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(newItem.variantId, newItem);
      return newMap;
    });
  };
  useEffect(() => {
    const subscribeToBcEvents = () => {
      window.blueConicClient.event.subscribe(
        "propertiesDialogue",
        globalThis,
        function (event: string, message: BlueConicItem) {
          addPersonalContentItem(message);
        }
      );
    };

    if (typeof window.blueConicClient?.event?.subscribe != "undefined") {
      subscribeToBcEvents();
    } else {
      window.addEventListener(
        "onBlueConicLoaded",
        function () {
          subscribeToBcEvents();
        },
        false
      );
    }
  }, []);

  function trackBlueConicClick(variantId: string) {
    if (window.blueConicClient && typeof window.blueConicClient.createEvent === "function") {
      window.blueConicClient.createEvent("CLICK", variantId);
      const promotion = blueConicData.get(variantId);
      if (!promotion?.data) return;
      const blueConicViewEvent = {
        id: promotion.data.bc_promo_id,
        name: promotion.data.bc_promo_name,
        creative: promotion.data.bc_promo_creative,
        destinationUrl: promotion.data.ui_item_url,
        position: getPositionNameFromPosition(promotion.position),
      };
      pushEcommercePromoTrackingEvent(blueConicViewEvent, "promoClick");
    }
  }

  function trackBlueConicView(variantId: string) {
    const promotion = blueConicData.get(variantId);
    if (!promotion?.data) return;
    const blueConicViewEvent = {
      id: promotion.data.bc_promo_id,
      name: promotion.data.bc_promo_name,
      creative: promotion.data.bc_promo_creative,
      destinationUrl: promotion.data.ui_item_url,
      position: getPositionNameFromPosition(promotion.position),
    };
    pushEcommercePromoTrackingEvent(blueConicViewEvent, "promoView");
  }

  function setBlueConicProfile(trackingId: string) {
    if (window.blueConicClient?.profile && typeof window.blueConicClient.profile.getProfile === "function") {
      const profile = window.blueConicClient.profile.getProfile();
      profile.setValue("hashed_party_id", trackingId);
      window.blueConicClient.profile.updateProfile();
    }
  }
  const contextValue = useMemo(() => {
    const findItemByPosition = (position: PromoPosition): BlueConicItem | undefined =>
      [...blueConicData.values()].find((item) => item.position === position);

    return {
      bcTv: findItemByPosition(PromoPosition.TV),
      bcBroadband: findItemByPosition(PromoPosition.BB),
      bcMobile: findItemByPosition(PromoPosition.Mobile),
      bcHighlights: findItemByPosition(PromoPosition.Highlights),
      bcTips: findItemByPosition(PromoPosition.Tips),
      trackBlueConicClick,
      trackBlueConicView,
      setBlueConicProfile: setBlueConicProfile,
    };
  }, [blueConicData, trackBlueConicClick, setBlueConicProfile]);

  return <BlueConicContext.Provider value={contextValue}>{children}</BlueConicContext.Provider>;
};

export const useBlueConic = () => {
  const context = useContext(BlueConicContext);
  if (context === undefined) {
    throw new Error("useBlueConic must be used within a BlueConicContext");
  }
  return context;
};
