import html2pdf from "html2pdf.js";

export async function openPdf(querySelector: string) {
  const opt = {
    filename: "summary.pdf",
    image: { type: "jpeg", quality: 1 },
    html2canvas: { scale: 2, useCORS: true, scrollY: 0 },
    jsPDF: { format: "a3", unit: "px", hotfixes: ["px_scaling"], autoPaging: "text" },
    margin: [35, 60],
    pagebreak: {
      mode: ["css", "legacy"],
      before: ".before",
      after: ".after",
      avoid: ".avoid",
    },
  };
  const blob = await html2pdf().set(opt).from(document.querySelector(querySelector)).outputPdf("bloburl");
  window.open(blob, "_blank");
}
