import { useMutation } from "@apollo/client";
import { graphql } from "src/gql";
import { GetSubscriptionCommitmentOfferingsQuery } from "src/gql/graphql";
import { OfferingContent } from "./OfferingContent";
import { SuccessContent } from "./SuccessContent";
import { ErrorContent } from "./ErrorContent";
import { getUserOfferingWithAnnualDiscount } from "util/mobile/offer";
import { NoCommitmentFound } from "./NoCommitmentFound";

type AddCommitmentProps = {
  phoneNumber: string;
  accountId: string;
  subscription: GetSubscriptionCommitmentOfferingsQuery["subscription"];
};

export const ADD_COMMITMENT = graphql(`
  mutation addCommitment(
    $phoneNumber: String
    $accountId: String
    $offering: String
    $products: [String]
    $campaignId: String
    $offerings: [OfferingInput]
  ) {
    changeOffering(
      phoneNumber: $phoneNumber
      accountId: $accountId
      offering: $offering
      products: $products
      campaignId: $campaignId
      offerings: $offerings
    ) {
      orderId
    }
  }
`);

export const AddCommitment = ({ subscription, phoneNumber, accountId }: AddCommitmentProps) => {
  const { productUser, userOffering, newOfferingDetails } = subscription;

  const userOfferingWithDiscount = getUserOfferingWithAnnualDiscount(
    userOffering?.shortName || "",
    newOfferingDetails?.offerings
  );

  const [addSubscription, { data: orderData, loading: orderLoading, error: orderError, reset: resetOrder }] =
    useMutation(ADD_COMMITMENT);

  const orderOffering = () => {
    const selectedOfferingName = userOfferingWithDiscount?.pricePlan;

    addSubscription({
      variables: {
        accountId,
        phoneNumber,
        offering: selectedOfferingName,
      },
    });
  };

  if (!userOfferingWithDiscount) {
    return <NoCommitmentFound />;
  }

  return (
    <>
      {!orderError && !orderData && (
        <OfferingContent
          phoneNumber={phoneNumber}
          productUser={productUser}
          userOfferingWithDiscount={userOfferingWithDiscount}
          orderLoading={orderLoading}
          orderOffering={orderOffering}
        />
      )}

      {orderData && !orderError && <SuccessContent />}
      {orderError && <ErrorContent resetOrder={resetOrder} />}
    </>
  );
};
