import { Box, Button, Icon, IconName } from "@telia/teddy";
import { Backdrop } from "@telia-no-min-side/components";
import { ActionMenuItem } from "./ActionMenuItem";
import { ActionMenuCard } from "./style";
import { types } from "@telia-no-min-side/utils";

export type ActionItem = {
  isInternalNavigation?: boolean;
  kind: "link" | "button";
  icon: IconName;
  text: string;
  styling?: "negative";
  type?: types.fixed.Action["type"];
  href?: string;
  onClick?: () => void;
};

export type ActionMenuProps = {
  actionList?: ActionItem[];
  showActions: boolean;
  toggleShowActions: () => void;
};

export function ActionMenu(props: ActionMenuProps) {
  const { actionList, showActions, toggleShowActions } = props;

  return (
    <Box style={{ position: "relative" }}>
      {actionList && (
        <>
          <Button
            aria-label="Valg"
            title="Valg"
            variant={showActions ? "primary-negative" : "secondary"}
            iconOnly
            onClick={toggleShowActions}
          >
            <Icon name="more-horizontal" />
          </Button>
          {showActions && (
            <>
              <ActionMenuCard variant="white" bordered>
                {actionList.map((actionItem, index) => (
                  <ActionMenuItem key={`action-point-${actionItem.text}-${index}`} {...actionItem} />
                ))}
              </ActionMenuCard>
              <Backdrop isVisible color="transparent" onClick={toggleShowActions} />
            </>
          )}
        </>
      )}
    </Box>
  );
}
