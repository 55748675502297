import { Button, Modal, ModalBody, ModalFooter, ModalHeading } from "@telia-no-min-side/components";

type Props = {
  loading: boolean;
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  onCancelProduct: () => void;
};

export const SkyCancelServiceModal = ({ isOpen, loading, setIsModalOpen, onCancelProduct }: Props) => {
  return (
    <Modal
      setIsOpen={setIsModalOpen}
      isOpen={isOpen}
      size="xs"
      dataTrackingID="additional-services-telia-sky-cancel-modal"
    >
      <ModalHeading>Vil du kansellere Telia Sky?</ModalHeading>

      <ModalBody>
        <p>Telia Sky blir avsluttet umiddelbart. Vær oppmerksom på at du mister tilgang til dine filer.</p>
      </ModalBody>

      <ModalFooter flex>
        <Button size="sm" variant="tertiary-black" disabled={loading} onClick={() => setIsModalOpen(false)}>
          Avbryt
        </Button>

        <Button isLoading={loading} loadingText="Vent litt ..." size="sm" onClick={onCancelProduct}>
          Kanseller Telia Sky
        </Button>
      </ModalFooter>
    </Modal>
  );
};
