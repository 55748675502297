import { ApolloClient, ApolloLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { HttpLink } from "@apollo/client/link/http";
import { getMainDefinition } from "@apollo/client/utilities";
import { getMinSideUserToken } from "@telia-no-min-side/components";
import { createApolloErrorHandler, getCookieValue } from "@telia-no-min-side/utils";
import { Kind, OperationTypeNode } from "graphql";
import { onError } from "@apollo/client/link/error";
import { datadogRum } from "@datadog/browser-rum";
import { config } from "../config";
import { cache } from "./cache";
import { wsLink } from "./wsLink";
import { COOKIE } from "util/constants/cookies";

const channelApiHttpHeaders = {
  clientid: config.channelApiClientId,
  "x-api-key": config.channelApiKey,
};

const httpLink = new HttpLink({ uri: config.channelApiUrl });

const authLink = setContext(() => {
  const accessToken = getMinSideUserToken();
  const ctSessionFromCookie = getCookieValue(COOKIE.CT_SESSION);

  return {
    headers: {
      ...channelApiHttpHeaders,
      ...(accessToken
        ? {
            "identity-token": accessToken || "",
          }
        : ctSessionFromCookie
        ? {
            ctsession: ctSessionFromCookie,
          }
        : {}),
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
  },
  wsLink,
  httpLink
);

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${config.channelApiUrl}?${operation.operationName}`,
  }));
  return forward ? forward(operation) : null;
});

const errorLink = onError(createApolloErrorHandler(datadogRum));

// const deepDiff = (obj1: any, obj2: any): any => {
//   if (obj1 === obj2) return null;

//   if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
//     return obj2;
//   }

//   const diff: any = {};

//   for (const key in obj1) {
//     if (!(key in obj2)) {
//       diff[key] = null;
//     } else {
//       const valueDiff = deepDiff(obj1[key], obj2[key]);
//       if (valueDiff !== null) {
//         diff[key] = valueDiff;
//       }
//     }
//   }

//   for (const key in obj2) {
//     if (!(key in obj1)) {
//       diff[key] = obj2[key];
//     }
//   }

//   return Object.keys(diff).length === 0 ? null : diff;
// };

// let prevCacheSnapshot: any = null;
// /**
//  * debugging
//  * will print out the cache diff after every query
//  * can be used to debug why a query is executed and not using the cache
//  */
// const logCacheDiff = new ApolloLink((operation, forward) => {
//   return forward(operation).map((response) => {
//     const cacheSnapshot = apolloClient.cache.extract();
//     const cacheDiff = deepDiff(prevCacheSnapshot, cacheSnapshot);

//     if (cacheDiff !== null) {
//       console.log("Cache difference after query:", cacheDiff);
//     }

//     prevCacheSnapshot = cacheSnapshot;

//     return response;
//   });
// });

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, namedLink, splitLink]), //, logCacheDiff]),
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    //   refetchQueries: "active",
    // },
  },
});
