import { useNavigate } from "react-router-dom";
import { Flex, Card, Text, Icon } from "@telia/teddy";
import { FragmentType, getFragmentData } from "../../../../gql";
import { AccountData } from "../../../../gql/graphql";
import { insuranceAgreementFragment } from "../graphql/insuranceAgreementFragment";
import { useMobileAccount } from "hooks/useMobileAccount";
import { uri } from "@telia-no-min-side/utils";
import { truncateText } from "../utils/truncateText";

type Props = {
  accountId: AccountData["id"];
  insuranceAgreement: FragmentType<typeof insuranceAgreementFragment>;
};

export function InsuranceAgreementCard({ insuranceAgreement, accountId }: Props) {
  const navigate = useNavigate();
  const { setAccountId } = useMobileAccount();

  const { shortName, productTerms, imageUrl } = getFragmentData(insuranceAgreementFragment, insuranceAgreement);

  const phoneModel = productTerms?.find((productTerm) => productTerm?.name === "MODEL")?.value || "";
  const truncatedPhoneModel = truncateText(phoneModel, 40);

  const handleClick = () => {
    setAccountId(accountId);
    navigate(uri.minSideMobile("/avtaler"));
  };

  return (
    <Card
      shadow
      bordered
      width="100%"
      maxWidth="400px"
      layout="default"
      variant="white"
      data-tracking-id={`insurance-agreement-${shortName}`}
    >
      <Card.Heading as="h3" variant="title-100">
        {shortName || "Forsikring"}
      </Card.Heading>

      <Card.Content>
        <Flex direction="row" gap="100" width="100%">
          {imageUrl ? (
            <>
              <img src={`https:${imageUrl}`} alt="product-image" width="70px" />
              <Text>{truncatedPhoneModel}</Text>
            </>
          ) : (
            <Icon name="smartphone" size="md">
              {truncatedPhoneModel}
            </Icon>
          )}
        </Flex>
      </Card.Content>

      <Card.Footer height="100%" align="end">
        <Card.Button variant="text" onClick={handleClick}>
          Se avtale
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
