import { Button, Heading } from "@telia/styleguide";
import { Spinner } from "@telia/styleguide";
import { useState } from "react";
import { RouterSettingsActions } from "../../actions/RouterSettingActions";
import { ResponseMessage } from "../../modules/ResponseMessage";
import { useRouterSettings } from "../../stores/RouterSettingsProvider";
import {
  portForwardingTags,
  portForwardingUtilities,
  portForwardingValidation,
} from "../../util/PortForwardingUtilities";
import { routerAnalytics } from "../../util/RouterSettingsAnalytics";
import { ExpandablePortForwardingRule } from "./PortForwardingRule/ExpandablePortForwardingRule";
import { PortForwardingRule } from "./PortForwardingRule";
import "./style.less";

const NUMBER_OF_RULES_HIGH_ENOUGH_TO_SHOW_TWO_SPINNERS = 3;

const Container = ({ children }) => <div className="portforwarding-page-container">{children}</div>;

const Row = ({ children }) => <div className="portforwarding-page-row">{children}</div>;

const ResponseMessageContainer = ({ children }) => <div className="response-message-container">{children}</div>;

const RowVertical = ({ children }) => <div className="portforwarding-page-row-vertical">{children}</div>;

export const PortForwarding = () => {
  const { routerSettings, dispatch } = useRouterSettings();
  const { updateStatus, updateResponse, activeUnit } = routerSettings;
  const [showTopNewRule, setShowTopNewRule] = useState(false);
  const [showBottomNewRule, setShowBottomNewRule] = useState(false);

  const deleteRule = (index) => {
    const updatedHomeCentral = portForwardingUtilities.setPortForwardingRules(
      activeUnit,
      portForwardingUtilities.deleteRule(activeUnit, index)
    );

    RouterSettingsActions.updateDevice(dispatch, {
      device: updatedHomeCentral,
      tag: portForwardingTags.deleteRule,
    });
    routerAnalytics.trackStoreClick(portForwardingTags.deleteRule);
  };

  const showNewRulePrompt = (bottom) => {
    const { InternalGWIP, NetworkMask } = activeUnit.Settings.Lan;
    const newRule = portForwardingUtilities.createNewRule(InternalGWIP, NetworkMask);

    routerAnalytics.trackButtonClick("port-forwarding-add-rule-show-prompt");
    return (
      <PortForwardingRule
        forwardingRule={newRule}
        onSaveClick={(rule) => validateAndSetNewRules(rule, -1)}
        onCancelClick={() => (bottom ? setShowBottomNewRule(false) : setShowTopNewRule(false))}
        externalPorts={activeUnit.Settings.PortForwarding.PortForwardingRules.map((rule) => {
          return {
            ExternalFromPort: rule.ExternalFromPort,
            ExternalToPort: rule.ExternalToPort,
          };
        })}
      />
    );
  };

  const validateAndSetNewRules = (rule, index) => {
    const newRules = portForwardingUtilities.updatePortForwardingRules(activeUnit, rule, index);
    const errorMessages = portForwardingValidation.validatePortForwardingRules(
      newRules,
      activeUnit.Settings.Lan.NetworkMask,
      activeUnit.Settings.Lan.InternalGWIP
    );
    const tag = index === -1 ? portForwardingTags.createNewRule : portForwardingTags.updateExistingRule;

    if (errorMessages.length === 0 && !updateStatus.updating) {
      RouterSettingsActions.updateDevice(dispatch, {
        device: portForwardingUtilities.setPortForwardingRules(activeUnit, newRules),
        tag: tag,
      });
      routerAnalytics.trackStoreClick(tag);
    }
  };

  const portForwardingRules = activeUnit?.Settings?.PortForwarding?.PortForwardingRules;

  if (!activeUnit.SupportedSettings.PortForwarding.Supported) {
    return (
      <Container>
        <Heading level={2} text={"Denne enheten støtter ikke portviderekobling."} />
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Heading
          level={2}
          text={
            portForwardingRules.filter((rule) => rule.Enabled).length === 0
              ? "Ingen aktive viderekoblinger"
              : "Aktive viderekoblinger"
          }
        />
      </Row>
      {portForwardingRules.length >= 1 && (
        <RowVertical>
          <Button onClick={() => setShowTopNewRule(!showTopNewRule)} text={"+ Legg til regel"} />
          <ExpandablePortForwardingRule show={showTopNewRule}>{showNewRulePrompt(false)}</ExpandablePortForwardingRule>
        </RowVertical>
      )}
      {updateStatus?.tag === portForwardingTags.deleteRule &&
        updateStatus?.updating &&
        portForwardingRules.length > NUMBER_OF_RULES_HIGH_ENOUGH_TO_SHOW_TWO_SPINNERS && (
          <Spinner size={"medium"} color={"black"} />
        )}
      {portForwardingRules
        .filter((rule) => rule.Enabled)
        .reverse()
        .map((rule) => {
          const ruleIndex = portForwardingRules.indexOf(rule);

          return (
            <Row key={ruleIndex}>
              <PortForwardingRule
                forwardingRule={rule}
                onSaveClick={(rule) => validateAndSetNewRules(rule, ruleIndex)}
                onDelete={() => deleteRule(ruleIndex)}
                externalPorts={activeUnit.Settings.PortForwarding.PortForwardingRules.map((rule, index) => {
                  if (index !== ruleIndex) {
                    return {
                      ExternalFromPort: rule.ExternalFromPort,
                      ExternalToPort: rule.ExternalToPort,
                    };
                  }
                }).filter((ports) => ports)}
                preExisting
              />
            </Row>
          );
        })}
      {portForwardingRules.filter((rule) => !rule.Enabled).length > 0 && (
        <Heading level={2} text={"Inaktive portviderekoblinger"} />
      )}
      {portForwardingRules
        .filter((rule) => !rule.Enabled)
        .map((rule) => {
          const ruleIndex = portForwardingRules.indexOf(rule);

          return (
            <Row key={ruleIndex}>
              <PortForwardingRule
                forwardingRule={rule}
                onSaveClick={(rule) => validateAndSetNewRules(rule, ruleIndex)}
                preExisting
                onDelete={() => deleteRule(ruleIndex)}
                externalPorts={activeUnit.Settings.PortForwarding.PortForwardingRules.map((rule, index) => {
                  if (index !== ruleIndex) {
                    return {
                      ExternalFromPort: rule.ExternalFromPort,
                      ExternalToPort: rule.ExternalToPort,
                    };
                  }
                }).filter((ports) => ports)}
              />
            </Row>
          );
        })}
      {updateStatus?.tag === portForwardingTags.deleteRule && updateStatus.updating && (
        <Row>
          <Spinner size={"medium"} color={"black"} />
        </Row>
      )}
      <ResponseMessageContainer>
        <ResponseMessage updateResponse={updateResponse} tag={portForwardingTags.deleteRule} />
      </ResponseMessageContainer>
      <RowVertical>
        <Button onClick={() => setShowBottomNewRule(!showBottomNewRule)} text={"+ Legg til regel"} />
        <ExpandablePortForwardingRule show={showBottomNewRule}>{showNewRulePrompt(true)}</ExpandablePortForwardingRule>
      </RowVertical>
    </Container>
  );
};
