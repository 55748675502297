import { graphql } from "src/gql";

export const GET_SUBSCRIPTION_DATA = graphql(`
  query getSubscriptionData($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      account {
        id
      }
      phoneNumber {
        localNumber
      }
      roles
      agreementType
      subscriptionRank

      userOffering {
        pricePlan
        shortName
        monthlyCost {
          amount
        }
        originalPrice {
          amount
        }
        additionalProperties {
          name
          value
        }
        offeringPrices {
          offeringPrices {
            code
            amount
            monetaryUnit
          }
        }
      }

      additionalProducts {
        id
        code
        name
        productCategory
        productTerms {
          code
          amount
          monetaryUnit
        }
      }
    }
  }
`);
