import { Maybe, AdditionalProduct, AdditionalProductTerm, OfferingPriceCodes } from "../../gql/graphql";
import { DataAmount } from "./common";
import { getDataAndSpeedConvergentBenefits } from "./getDataAndSpeedConvergentBenefits";

export type ConvergentBenefitType = {
  amount: number | undefined;
  unit: string | undefined;
  hasDoubleSpeed: boolean;
  hasDoubleData: boolean;
};

function getBenefitAmount(productTerms: Maybe<Maybe<AdditionalProductTerm>[]> | undefined, code: string): DataAmount {
  const convergentBenefit = productTerms?.find((term) => term?.code === code);
  return { amount: convergentBenefit?.amount || 0, unit: convergentBenefit?.monetaryUnit || "" };
}

export function getConvergentBenefitAmount(
  additionalProducts: AdditionalProduct[] | null | undefined
): ConvergentBenefitType {
  if (!additionalProducts) return { hasDoubleSpeed: false, hasDoubleData: false, amount: 0, unit: "" };
  const { downloadSpeed, dataIncluded } = getDataAndSpeedConvergentBenefits(additionalProducts);

  if (downloadSpeed) {
    return {
      hasDoubleSpeed: true,
      hasDoubleData: false,
      ...getBenefitAmount(downloadSpeed.productTerms, OfferingPriceCodes.SpeedDownload),
    };
  }

  if (dataIncluded) {
    return {
      hasDoubleData: true,
      hasDoubleSpeed: false,
      ...getBenefitAmount(dataIncluded.productTerms, OfferingPriceCodes.DataIncluded),
    };
  }

  return { hasDoubleSpeed: false, hasDoubleData: false, amount: 0, unit: "" };
}
