import { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";
import UIElement from "@adyen/adyen-web/dist/types/components/UIElement";
import { uri } from "@telia-no-min-side/utils";
import { axiosPost } from "@telia-no-min-side/components";
import { FixedPaymentResponse } from "../types/PaymentResponse";
import { ConfigurationResponse, DropInstate, DropInStateData } from "../types/AdyenTypes";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

let identifier: string;

export function makePayment(
  data: DropInStateData,
  amount: number,
  financialAccountId: number,
  invoiceId: number
): Promise<FixedPaymentResponse> {
  const { encryptedCardNumber, encryptedExpiryMonth, encryptedExpiryYear, encryptedSecurityCode } = data.paymentMethod;

  const dataToPost = {
    cardDetails: {
      encryptedExpiryYear,
      encryptedSecurityCode,
      encryptedCardNumber,
      encryptedExpiryMonth,
    },
    amount: amount,
    browserInfo: data.browserInfo,
    financialAccountId,
    invoiceId,
  };

  const paymentEndpoint = uri.minSideFixed(ICX_REQUEST.URL_PAYMENT_SUBMIT);

  return axiosPost(paymentEndpoint, dataToPost);
}

type Options = {
  makePayment: (
    data: DropInStateData,
    amount: number,
    financialAccountId: number,
    invoiceId: number
  ) => Promise<FixedPaymentResponse>;
  showFinalResult: (response: FixedPaymentResponse, onPaymentDone: (response: FixedPaymentResponse) => void) => void;
  makeDetailsCall: (data: DropInStateData, identifier: string) => Promise<FixedPaymentResponse>;
  amount: number;
  onPaymentDone: (response: FixedPaymentResponse) => void;
  financialAccountId: number;
  invoiceId: number;
  onPaymentPending: (flag: boolean) => void;
  onPaymentError: (flag: boolean) => void;
};

export function getDropinConfiguration(configurationResponse: ConfigurationResponse, options: Options): CoreOptions {
  const {
    makeDetailsCall,
    makePayment,
    showFinalResult,
    onPaymentDone,
    amount,
    financialAccountId,
    invoiceId,
    onPaymentPending,
    onPaymentError,
  } = options;
  const { environment, locale, paymentMethods, clientKey } = configurationResponse;
  return {
    paymentMethodsResponse: {
      paymentMethods,
    },
    environment,
    clientKey,
    locale,
    amount: { value: amount * 100, currency: "NOK" },
    onSubmit: (state: DropInstate, dropin: UIElement) => {
      // Makes the dropin show a loading state, making the customer unable to resubmit their payment by accident
      dropin.setStatus("loading");
      // Global configuration for onSubmit
      // Your function calling your server to make the `/payments` request
      makePayment(state.data, amount, financialAccountId, invoiceId)
        .then((response: FixedPaymentResponse) => {
          identifier = response.identifier;
          if (response.action) {
            // Drop-in handles the action object from the /payments response
            dropin.handleAction(response.action);
          } else {
            // Unmounting the dropin removes it from the DOM.
            dropin.unmount();
            // Your function to show the final result to the shopper
            showFinalResult(response, onPaymentDone);
          }
        })
        .catch((error) => {
          dropin.unmount();
          onPaymentError(true);
          throw Error(error);
        });
    },
    onAdditionalDetails: (state: DropInstate, dropin: UIElement) => {
      // Your function calling your server to make a `/payments/details` request
      onPaymentPending(true);
      makeDetailsCall(state.data, identifier)
        .then((response: FixedPaymentResponse) => {
          onPaymentPending(false);
          if (response.action) {
            // Drop-in handles the action object from the /payments response
            dropin.handleAction(response.action);
          } else {
            // Unmounting the dropin removes it from the DOM.
            dropin.unmount();
            // Your function to show the final result to the shopper
            showFinalResult(response, onPaymentDone);
          }
        })
        .catch((error) => {
          dropin.unmount();
          onPaymentPending(false);
          onPaymentError(true);
          throw Error(error);
        });
    },
    paymentMethodsConfiguration: {
      card: {
        // Example optional configuration for Cards
        hasHolderName: false,
        holderNameRequired: true,
        enableStoreDetails: true,
        hideCVC: false, // Change this to true to hide the CVC field for stored cards
        name: "Kreditt eller debetkort",
      },
    },
  };
}

export function showFinalResult(
  response: FixedPaymentResponse,
  onPaymentDone: (response: FixedPaymentResponse) => void
): void {
  onPaymentDone(response);
}

export function authoriseThreeDSVersion2(data: DropInStateData, identifier: string): Promise<FixedPaymentResponse> {
  if (!identifier) throw Error("Identifier not set in class");

  const urlToUse = uri.minSideFixed(ICX_REQUEST.URL_TRANSACTIONS_DETAILS + "?identifier=" + identifier);

  return axiosPost<FixedPaymentResponse>(urlToUse, data);
}

export function authoriseThreeDSVersion1(redirectResult: string, identifier: string): Promise<FixedPaymentResponse> {
  const urlToUse = uri.minSideFixed(ICX_REQUEST.URL_TRANSACTIONS_DETAILS + "?identifier=" + identifier);

  const data = {
    details: {
      redirectResult,
    },
  };

  return axiosPost<FixedPaymentResponse>(urlToUse, data);
}

export const SUCCESSFUL_TRANSACTION_STATUS = "succeeded";

export function formatAmountToNorwegianStandard(amount?: number): string {
  const numberAsString: string = amount + "";

  if (numberAsString.indexOf(".") > 0) {
    return replacePeriodWithComma(numberAsString);
  }
  return numberAsString;
}

function replacePeriodWithComma(inputString: string): string {
  return inputString.replace(".", ",");
}
