import { Container } from "@telia-no-min-side/components";
import styled from "styled-components";

export const TabContent = styled(Container)`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 1rem;
  }
`;

export const Centering = styled.div`
  text-align: center;
  margin: 20vh 20%;
`;
