import { Box, Icon, Text, Button } from "@telia/teddy";
import { ActionItemLink, ActionItemExternalLink, ActionItemButton } from "./style";
import { ActionItem } from ".";

type Props = ActionItem;

export function ActionMenuItem(props: Props) {
  const { isInternalNavigation, kind, icon, text, href, styling, onClick } = props;

  return (
    <Box style={{ textWrap: "nowrap", display: "flex", alignItems: "left" }}>
      {kind === "link" && href ? (
        <Button asChild variant="text" p="0">
          {isInternalNavigation ? (
            <ActionItemLink to={href} styling={styling}>
              <Icon name={icon} size="sm" />
              <Text variant="paragraph-100-medium">{text}</Text>
            </ActionItemLink>
          ) : (
            <ActionItemExternalLink href={href} styling={styling}>
              <Icon name={icon} size="sm" />
              <Text variant="paragraph-100-medium">{text}</Text>
            </ActionItemExternalLink>
          )}
        </Button>
      ) : (
        <ActionItemButton variant="text" onClick={onClick} styling={styling} p="0">
          <Icon name={icon} size="sm" />
          <Text variant="paragraph-100-medium">{text}</Text>
        </ActionItemButton>
      )}
    </Box>
  );
}
