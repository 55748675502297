import { colors } from "@telia/styleguide";
import styled from "styled-components";

export const StreamingImage = styled.div<{ theme?: "dark" | "light" }>`
  background-color: ${({ theme }) => (theme === "light" ? "white" : "black")};
  border-radius: 0.5rem;
  border: ${({ theme }) => (theme === "light" ? `1px solid ${colors.grey100}` : null)};
  width: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 2rem;
    max-width: 3.5rem;
  }
`;
