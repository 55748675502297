import { useWindowSize } from "@telia-no-min-side/components";
import { breakpoint, Flex, Notification } from "@telia/teddy";
import PhoneBox from "components/PhoneBox";
import { phoneNumbers } from "util/phoneNumbers";

export function MobileSubscriptionReserved() {
  const { isMobile } = useWindowSize();

  return (
    <Flex maxWidth={breakpoint.teddyBreakpointMd} p={isMobile ? "200" : "400"} gap="300" direction="column">
      <Notification variant="information" data-tracking-id="alert--suspended-subscription_reason-fraud">
        <Notification.Heading as="h3">
          <Notification.Icon />
          Abonnementet har ikke blitt aktivert enda
        </Notification.Heading>
        <Flex direction="column" gap="gutter-md">
          <Notification.Text>
            Om du mener abonnementet burde vært aktivert allerede, ta kontakt med kundeservice.
          </Notification.Text>
          <Notification.Footer>
            <PhoneBox number={phoneNumbers.customerServiceNorway} />
          </Notification.Footer>
        </Flex>
      </Notification>
    </Flex>
  );
}
