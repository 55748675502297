import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Card, Container, Link, Paragraph, Icon, useToast } from "@telia-no-min-side/components";
import { OrderOp } from "src/gql/graphql";
import { graphql } from "src/gql";
import format from "@telia-no-min-side/utils/src/format";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

const ORDER_ADDITIONAL_PRODUCT = graphql(`
  mutation OrderAdditionalProduct($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);

type Props = {
  phoneNumber: string;
  isConvergentUser: boolean;
  hasAnyOutage: boolean;
};

export function ExpandedOutageFooter({ phoneNumber, isConvergentUser, hasAnyOutage }: Props) {
  const [isDataBoostActive, setIsDataBoostActive] = useState<"init" | "success" | "error">("init");
  const [orderAdditionalProduct, orderAdditionalProductResponse] = useMutation(ORDER_ADDITIONAL_PRODUCT);
  const { addToast } = useToast();

  function activateFreeDataBoost() {
    orderAdditionalProduct({
      variables: {
        phoneNumber,
        input: {
          productName: "Dont know yet",
          operation: OrderOp.AddAdditionalProduct,
        },
      },
      onCompleted: (response) => setIsDataBoostActive(response.order.orderId ? "success" : "init"),
      onError: () => {
        addToast({
          text: "Kunne ikke aktivere Data Boost. Prøv igjen om noen minutter, eller ta kontakt med kundesenteret.",
          variant: "error",
        });
      },
    });
  }

  if (!hasAnyOutage) {
    return (
      <>
        <Container flexDirection="column" gap padding="vertical">
          <Paragraph>Opplever du likevel problemer, kan du melde inn feil på din adresse:</Paragraph>
          <Link href={EXTERNAL_LINK.CUSTOMER_SERVICE_INTERNET} icon="arrow-right" iconPosition="after">
            Løs problemer med internett
          </Link>
          <Link href={EXTERNAL_LINK.CUSTOMER_SERVICE_TV} icon="arrow-right" iconPosition="after">
            Løs problemer med TV
          </Link>
        </Container>
      </>
    );
  }

  if (hasAnyOutage && isConvergentUser) {
    return (
      <>
        <Paragraph removeMargin>
          For å få midlertidig tilgang til internett, anbefaler vi deg å benytte dine kostnadsfrie Data Boosts. Aktiver
          Data Boost, og surf så mye du vil i 12 timer!
        </Paragraph>

        {isDataBoostActive === "init" && (
          <Button
            tag="button"
            variant="text-purple"
            size="sm"
            style={{ marginRight: "auto" }}
            trackEvent={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.ACTIVATE_DATA_BOOST,
            }}
            onClick={() => activateFreeDataBoost()}
            isLoading={orderAdditionalProductResponse.loading}
          >
            Aktiver DataBoost
          </Button>
        )}

        {isDataBoostActive === "success" && (
          <Card backgroundColor="light-purple" showShadow={false} hideBorder removeMinHeight>
            <Container flexDirection="column" gap="sm">
              <Paragraph removeMargin>
                <em>12 timers Data Boost er aktivert for:</em>
              </Paragraph>
              <Container flexDirection="row" gap alignItems="flex-end">
                <Icon icon="check-mark" />
                <Paragraph>{format.formatPhoneNumber(phoneNumber)}</Paragraph>
              </Container>
            </Container>
          </Card>
        )}
      </>
    );
  }

  return null;
}
