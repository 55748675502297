import { AdditionalProduct } from "src/gql/graphql";
import { TELIA_TRYGG_PRODUCT_TYPE } from "util/constants/valueAddedServices";

export const isB2BCompanyPaidTeliaTryggProduct = (product: AdditionalProduct) => {
  const b2bTeliaTryggCodes: string[] = [
    TELIA_TRYGG_PRODUCT_TYPE.B2B_COMPANY_PAID_FOR_ALL_EMPLOYEES,
    TELIA_TRYGG_PRODUCT_TYPE.B2B_COMPANY_PAID_FOR_ALL_EMPLOYEES_ACTIVE,
    TELIA_TRYGG_PRODUCT_TYPE.B2B_COMPANY_PAID_FOR_SELECTED_EMPLOYEES,
  ];

  return product.code ? b2bTeliaTryggCodes.includes(product.code) : false;
};
