import { Button, Card, Container, Heading, Icon, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { AdditionalInfoContainer } from "../styles";

type InvoiceErrorProps = {
  onRefresh?: () => void;
};

export const InvoiceError = ({ onRefresh }: InvoiceErrorProps) => {
  return (
    <Card padding="lg">
      <Heading tag={"h3"} variant={"title-400"}>
        Noe gikk galt!
      </Heading>
      <Paragraph>
        Vi kunne dessverre ikke hente dine opplysninger. Vennligst prøv igjen, eller kontakt vår kundeservice:
      </Paragraph>
      <AdditionalInfoContainer floatContent="center" flexDirection="row" gap>
        <Icon size="sm" icon="phone" />
        <Paragraph>924 05 050</Paragraph>
      </AdditionalInfoContainer>
      <Container flexDirection="row" padding="top" gap>
        <Button onClick={onRefresh} icon="refresh">
          Last inn siden på nytt
        </Button>
        <Button variant="secondary" tag="a" href={uri.minSideFixed("/hjem")} icon="home">
          Hjem
        </Button>
      </Container>
    </Card>
  );
};
