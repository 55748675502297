import { Container } from "@telia-no-min-side/components";
import { Success } from "../components/Success";

export function GDPRSuccessPage() {
  return (
    <Container padding flexDirection="column" gap="lg">
      <Success />
    </Container>
  );
}
