import React, { HTMLAttributes, useEffect, useState } from "react";
import { IconDefinition } from "@telia/styleguide";
import { track } from "@telia-no-min-side/utils";
import { Button } from "../Button";
import { Container } from "../Container";
import { Heading, HeadingProps } from "../Heading";
import { Icon } from "../Icon";
import { Paragraph } from "../Paragraph";
import { AlertContainer, AlertTitleContainer } from "./style";

export type Props = {
  title?: string;
  kind: "positive" | "negative" | "warning" | "info";
  icon?: IconDefinition;
  withSpacing?: boolean;
  text?: string;
  isOpen: boolean;
  canClose?: boolean;
  dataTrackingId?: string;
  children?: React.ReactNode;
  titleTag?: HeadingProps["tag"];
} & Pick<HTMLAttributes<HTMLDivElement>, "id">;

/**
 * @deprecated Use Teddy components instead
 */
export function Alert(props: Props): JSX.Element | null {
  const {
    title,
    icon,
    kind,
    titleTag = "h3",
    text,
    isOpen,
    withSpacing,
    children,
    canClose = true,
    dataTrackingId,
    ...rest
  } = props;
  const [show, setShow] = useState(isOpen || false);
  const { pushTrackingEvent } = track.useEventTracking();

  useEffect(() => {
    if (kind === "negative" && process.env.NODE_ENV === "production") {
      pushTrackingEvent({
        event: "error_web",
        error_type: "MS_ALERT_MOBILE",
        error_message: text,
        affiliation: "Telia Min Side",
      });
    }
  }, []);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  return show ? (
    <AlertContainer kind={kind} withSpacing={withSpacing} data-tracking-id={dataTrackingId}>
      <Container
        maxWidth="full"
        flexDirection="row"
        padding="bottom"
        justifyContent="space-between"
        alignItems="center"
        {...rest}
      >
        <AlertTitleContainer kind={kind} flexDirection="row" gap alignItems="center">
          {icon && (
            <div>
              <Container alignItems="center" justifyContent="center" width="unset">
                <Icon icon={icon} color="inherit" />
              </Container>
            </div>
          )}
          {title && (
            <Heading tag={titleTag} variant="title-100">
              {title}
            </Heading>
          )}
        </AlertTitleContainer>

        {canClose && (
          <div>
            <Button
              size="unset"
              variant="text-black"
              icon="close"
              isIconButton
              onClick={() => setShow(false)}
              title="Lukk"
            />
          </div>
        )}
      </Container>

      {!!text && <Paragraph removeMargin>{text}</Paragraph>}

      {children}
    </AlertContainer>
  ) : null;
}
