import { remCalc, devices, Button, Link as TLink } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";
import styled from "styled-components";

export const AdditionalInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${remCalc(15)};
`;

export const AdditionalInfoBlock = styled.div<{
  pink?: boolean;
}>`
  padding: ${remCalc(15)} ${remCalc(20)};
  display: flex;
  flex-direction: column;
  gap: ${remCalc(8)};
  background-color: ${colors.grey100};
  background-color: ${(props) => (props.pink ? colors.corePurple100 : colors.grey100)};
`;

export const LinksContainer = styled.div<{
  withSpacing?: boolean;
}>`
  margin-top: ${(props) => (props.withSpacing ? remCalc(10) : 0)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: ${(props) => (props.withSpacing ? remCalc(450) : "none")};
  gap: ${remCalc(10)};
  @media ${devices.sm_up} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Link = styled(TLink)`
  color: ${colors.grey800};
  :visited {
    color: ${colors.corePurple700};
  }
`;

export const PhoneLink = styled(TLink)`
  padding: ${remCalc(5)} ${remCalc(10)};
  width: fit-content;
  background-color: white;
  color: ${colors.grey800};
  svg {
    color: ${colors.grey800};
  }
`;

export const CancelButton = styled(Button)`
  align-self: flex-end;
`;
