import { Maybe, OfferingPriceDetails } from "../../gql/graphql";
import { getAmount } from "./getAmount";
import { getUnit } from "./getUnit";

export function getName(
  offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined,
  shortName: string | undefined | null,
  hasDoubleData?: boolean
) {
  if (/(telia mobil)\b/.test(shortName?.toLowerCase() || "")) {
    const amount = hasDoubleData ? getAmount(offeringPrices) * 2 : getAmount(offeringPrices);
    const unit = getUnit(offeringPrices);

    return `${amount} ${unit}`;
  }

  return shortName;
}
