import { quantityToBytes } from "util/data-usage";
import { UsageDataType } from "./mergeDataUsageByType";

export function getTotalDataUsage(groupedData: UsageDataType[]) {
  return groupedData.reduce(
    (total, current) => ({
      remaining: total.remaining + quantityToBytes(current?.remaining)!,
      limit: total.limit + quantityToBytes(current?.limit)!,
    }),
    { remaining: 0, limit: 0 }
  );
}
