import React, { BaseSyntheticEvent, ReactNode } from "react";
import { Container } from "../Container";
import { Card } from "@telia/teddy";

type Props = {
  title: string;
  children?: ReactNode;
  onGoBackClick?: (e: BaseSyntheticEvent) => void;
};

export function SelfRecoveryPageWrapper({ title, children, onGoBackClick }: Props): JSX.Element {
  return (
    <Container showGoBackButton onGoBackClick={onGoBackClick} padding maxWidth="md">
      <Card bordered shadow variant="white" layout="default">
        <Card.Heading>{title}</Card.Heading>
        <Card.Content>{children}</Card.Content>
      </Card>
    </Container>
  );
}
