import { graphql } from "src/gql";

export const GET_ADDITIONAL_SIM_DATA = graphql(`
  query getAdditionalSimData($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      productUser {
        emailAddress
      }
      additionalProducts {
        id
        code
        weight
        productGroup
        productCategory
        presentation {
          active
          modifiable
        }
        price {
          price
          priceWithoutDiscount
          priceAggregated
          discountDuration
        }
        additionalProperties {
          name
          value
        }
      }
    }
  }
`);
