import { Card, Container, Heading, Paragraph } from "@telia-no-min-side/components";

export function NoFlex() {
  return (
    <Container padding showGoBackButton>
      <Heading tag="h2" variant="title-300">
        Din Flex-avtale
      </Heading>
      <Paragraph variant="preamble-100">Du har ingen Flex-avtale enda!</Paragraph>
      <Card maxWidth="xxs">
        <Heading tag="h4" variant="title-100">
          Hva er Flex?
        </Heading>
        <Paragraph>
          Med Flex kan du velge den kombinasjonen av internett og TV som passer ditt hjem best. Denne avtalen har du
          gjennom ditt borettslag/sameie.
        </Paragraph>
      </Card>
    </Container>
  );
}
