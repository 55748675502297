import { Container, HorizontalScroll, ToggleButtonGroup } from "@telia-no-min-side/components";

type Option<T> = {
  text: string;
  value: T;
};

type Props<T> = {
  value: T;
  onChange: (value: T) => void;
  options: Option<T>[];
};

export function OfferGroupPicker<T extends { toString(): string }>({ value, onChange, options }: Props<T>) {
  if (!options.length) {
    return null;
  }

  return (
    <Container padding="bottom">
      <HorizontalScroll addContainerScrollMargin>
        {({ scrollBy }) => (
          <Container gap flexDirection="row">
            <ToggleButtonGroup value={value} onChange={(e, newVal) => newVal && onChange(newVal)}>
              {options.map((option) => (
                <ToggleButtonGroup.Item
                  key={option.value.toString()}
                  text={option.text.toString()}
                  value={option.value}
                  scrollBy={scrollBy}
                  data-tracking-id={`mobile-subscription-change-${option.value.toString()}`}
                />
              ))}
            </ToggleButtonGroup>
          </Container>
        )}
      </HorizontalScroll>
    </Container>
  );
}
