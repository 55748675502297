import { FullPageLoading } from "@telia-no-min-side/components";
import { Container, Flex, Heading, Box, Link, Text, breakpoint } from "@telia/teddy";
import { Icon } from "@telia/teddy";
import { useCompensation } from "./useCompensation";
import { Navigate } from "react-router-dom";
import { uri } from "@telia-no-min-side/utils";
import { Breadcrumb } from "components/Breadcrumb";
import { TvCompensationCards } from "./TvCompensationCards";
import { config } from "util/config";
export function SelectUserForCompensation() {
  const { isLoading, eligibleCustomerIds } = useCompensation();
  const firstEligibleCustomer = eligibleCustomerIds?.find((customer) => customer.customerId);
  const customerId = firstEligibleCustomer?.customerId;
  const hasOnlyOneEligibleCustomer = eligibleCustomerIds?.length === 1;

  if (!config.showTvCompensation || (!isLoading && eligibleCustomerIds?.length === 0)) {
    return <Navigate to={uri.minSideFixed("/hjem")} replace />;
  }

  if (isLoading) {
    return <FullPageLoading />;
  }

  if (hasOnlyOneEligibleCustomer && customerId) {
    return <Navigate to={`${customerId}`} replace />;
  }

  return (
    <Container align="flex-start" maxWidth={breakpoint.teddyBreakpointMd}>
      <Breadcrumb />
      <Flex mb="400" direction="column" gap="300">
        <Heading as="h1" variant="title-300">
          Du har fått 50 TV-poeng ekstra
        </Heading>
        <Text as="p">
          Du har fått 50 ekstra TV-poeng i Telia Play som kompensasjon for at TV 2 Play midlertidig er utilgjengelig.
          Hvis du har brukt poeng på TV 2 Play, er de nå frigjort til andre strømmetjenester og kanaler. Dette tilbudet
          gjelder frem til TV 2 Play er tilgjengelig igjen.
        </Text>
        <Box>
          <Link variant="standalone" href={uri.openPages("/tv/tv-2-info/")}>
            <Icon name="arrow-right">Les mer om forhandlingene her</Icon>
          </Link>
        </Box>
      </Flex>

      <Text mb="300">Din kompensasjon:</Text>

      <Flex direction="column" gap="300">
        <TvCompensationCards />
      </Flex>
    </Container>
  );
}
