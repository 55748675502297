import { getSimplePriceText } from "@telia-no-min-side/utils";
import { Badge, Card, Heading, Text, Flex, Icon } from "@telia/teddy";
import { FwaOffer } from "src/store/hooks/useFwaCommitment";

type Props = {
  offer: FwaOffer;
};

export function CommitmentSummary({ offer }: Props) {
  const hasBbSubscriptionDiscount =
    offer.subscriptionOffer?.priceWithDiscount !== offer.subscriptionOffer?.priceWithoutDiscount;

  return (
    <Card bordered shadow layout="default" variant="white" mt="gutter-md" mb="gutter-lg" width="100%">
      <Card.Slot align="top-right">
        <Badge variant="special">
          <Icon name="savings" />
          Spar {getSimplePriceText(offer.totalOffer.discount, "md.")}
        </Badge>
      </Card.Slot>
      <Card.Heading>
        <Flex justify="space-between">
          Ditt tilbud
          <Flex gap="100" align="center">
            <Text variant="paragraph-200">
              <s>{getSimplePriceText(offer.totalOffer?.priceWithoutDiscount)}</s>
            </Text>
            <Text variant="preamble-100">
              <strong>{getSimplePriceText(offer.totalOffer?.priceWithDiscount, "md.")}</strong>
            </Text>
          </Flex>
        </Flex>
      </Card.Heading>
      <Card.Content>
        <Flex gap="gutter-sm" direction="column">
          <Text>Legg til årsrabatt på ditt trådløse bredbånd i {offer.address} ved 12 md. avtaletid.</Text>
          <Heading as="h4" variant="title-100">
            Din månedspris:
          </Heading>
          <div>
            <Flex gap="100" mb="50" align="center">
              <Icon name="wireless" />
              <Text>{offer.subscriptionOffer?.description}</Text>
              {hasBbSubscriptionDiscount ? (
                <Text faded>
                  <s>{getSimplePriceText(offer.subscriptionOffer?.priceWithoutDiscount)}</s>
                </Text>
              ) : null}
              <Text variant="paragraph-100-medium">
                {getSimplePriceText(offer.subscriptionOffer?.priceWithDiscount)}
              </Text>
            </Flex>
            <Flex gap="100" align="center">
              <Icon name="router" />
              <Text>{offer.routerOffer?.description}</Text>
              <Text faded>
                <s>{getSimplePriceText(offer.routerOffer?.priceWithoutDiscount)}</s>
              </Text>
              <Text variant="paragraph-100-medium">{getSimplePriceText(offer.routerOffer?.priceWithDiscount)}</Text>
            </Flex>
          </div>
          <Text variant="additional-100">Avtaletid og rabatterte priser gjelder til {offer.bindingDate}</Text>
        </Flex>
      </Card.Content>
    </Card>
  );
}
