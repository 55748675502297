import { useQuery } from "@apollo/client";
import { uri } from "@telia-no-min-side/utils";
import { Navigate } from "react-router-dom";
import { graphql } from "src/gql";
import { AddSubRedirQuery, UserRole } from "src/gql/graphql";
import { SkeletonPage } from "../mobile-subscription-add/Skeleton";

export const ADD_SUB_REDIR_QUERY = graphql(`
  query AddSubRedir {
    accounts {
      id
      roles
    }
  }
`);
function allAccountsSameAccountId(accounts: AddSubRedirQuery["accounts"]) {
  if (!accounts) return false;
  if (accounts.length === 0) {
    return false;
  }

  const firstAccountId = accounts[0].id;
  return accounts.every((account) => account.id === firstAccountId);
}
function getOwnedAccounts(accounts: AddSubRedirQuery["accounts"]) {
  if (!accounts) return [];

  return accounts.filter((account) => account.roles.includes(UserRole.LegalOwner));
}

export function MobileSubscriptionAddRedir() {
  const addSub = useQuery(ADD_SUB_REDIR_QUERY);

  if (addSub.loading && !addSub.data) {
    return <SkeletonPage />;
  }
  const ownedAccounts = getOwnedAccounts(addSub.data?.accounts);
  const hasOneLegalAccount = allAccountsSameAccountId(ownedAccounts);

  if (hasOneLegalAccount) {
    const accountId = ownedAccounts[0].id;
    const pathname = uri.minSideFixed(`/mobil/legg-til/${accountId}`);

    return <Navigate replace to={{ pathname }} />;
  }

  return <Navigate replace to={uri.minSideFixed("/hjem/")} />;
}
