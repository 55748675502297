import React from "react";
import "@babel/polyfill";
import dayjs from "dayjs";
import { createRoot } from "react-dom/client";
import { datadogRum } from "@datadog/browser-rum";
import { ApolloProvider } from "@apollo/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router";
import "dayjs/locale/nb";
import { apolloClient } from "./util/apollo";

dayjs.locale("nb");

if (import.meta.env.VITE_TELIA_ENV == "production") {
  datadogRum.init({
    applicationId: "689e52d2-9f57-4c4d-9d16-868d272b85da",
    clientToken: "pub64b6f3a7cab7568123d20b370098b781",
    site: "datadoghq.eu",
    service: "min-side-fixed",
    version: import.meta.env.VITE_GIT_HASH || "1.0.0",
    env: "prod",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask",
  });
  datadogRum.setGlobalContextProperty("h2id", "hid100002930");
  datadogRum.setGlobalContextProperty("billing-code", "p1-00177-01574");
  datadogRum.setGlobalContextProperty("business-unit", "cps");
}

const container = document.getElementById("app");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <RouterProvider
        router={router}
        future={{
          v7_startTransition: true,
        }}
      />
    </ApolloProvider>
  </React.StrictMode>
);
