import { AgreementType, GetAdditionalServicesSummaryQuery } from "src/gql/graphql";
import { AdditionalServiceSummary } from "../types";

export function getB2BAdditionalServices(
  accounts: GetAdditionalServicesSummaryQuery["accounts"]
): AdditionalServiceSummary[] {
  const b2bAccounts = accounts?.filter((account) => account?.agreementType === AgreementType.Business);

  const b2bSubscriptions = b2bAccounts?.flatMap((accounts) =>
    accounts.subscriptions ? [...accounts.subscriptions] : []
  );
  const b2bAdditionalServices =
    b2bSubscriptions?.flatMap((subscription) => {
      const { additionalProducts, productUser, phoneNumber } = subscription;
      if (!additionalProducts?.length) return [];

      return additionalProducts.map((additionalProduct) => ({
        phoneNumber: phoneNumber.localNumber,
        userName: productUser?.firstName || null,
        productGroup: additionalProduct.productGroup || "",
        isPurchased: !!additionalProduct?.presentation?.active,
        productName: additionalProduct.shortName || "",
      }));
    }) || [];

  return b2bAdditionalServices;
}
