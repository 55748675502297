import { types, uri } from "@telia-no-min-side/utils";
import { ReceiptErrorContent } from "./ReceiptErrorContent";
import { ReceiptSuccessContent } from "./ReceiptSuccessContent";
import { Modal } from "@telia/teddy";
import { useNavigate } from "react-router-dom";

type ReceiptProps = {
  status: types.fixed.ResponseStatusSubmitMultipleProducts;
  isOpen: boolean;
};

export function Receipt(props: ReceiptProps): JSX.Element {
  const { status, isOpen } = props;
  const navigate = useNavigate();

  return (
    <Modal
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) navigate(uri.minSideFixed("/tv"));
      }}
    >
      {status === "vellykket" ? <ReceiptSuccessContent /> : <ReceiptErrorContent />}
    </Modal>
  );
}
