import { Flex, Text, Heading, Button, Icon } from "@telia/teddy";
import { uri } from "@telia-no-min-side/utils";
import { Link as RuterLink } from "react-router-dom";
import { CustomerCareLink } from "components/customer-care-link";

export function CompensationError() {
  return (
    <>
      <Flex mb="400" direction="column" gap="300">
        <Heading as="h1" variant="title-300">
          En uventet feil oppstod
        </Heading>
        <Text as="p">
          Vi beklager! Vi klarte ikke gjøre om kompensasjonen din. Vennligst prøv igjen litt senere, eller kontakt
          kundeservice:
        </Text>
        <CustomerCareLink trackingContext="tv-compensation-error" />
      </Flex>
      <Button asChild mt="200">
        <RuterLink to={uri.minSideFixed("/hjem")}>
          <Icon name="home">Tilbake til hjem </Icon>
        </RuterLink>
      </Button>
    </>
  );
}
