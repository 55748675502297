import { types, uri } from "@telia-no-min-side/utils";
import { getAppSpecificLink, isInternalLink } from "src/util/productHelper/link";

export function getEvoBoxToChangeActionLink(evoBoxToChange: types.fixed.Product[] | undefined) {
  const haveMultipleProducts = (evoBoxToChange?.length || 0) > 1;

  if (haveMultipleProducts)
    return {
      href: uri.minSideFixed("/tv/tv-bokser"),
      isInternalNavigation: true,
      linkText: "Bestill",
    };

  const change = evoBoxToChange?.find((product) => product)?.actions?.find((action) => action.type === "CHANGE");
  if (!change) return null;
  return {
    href: getAppSpecificLink(change),
    isInternalNavigation: isInternalLink(change.application),
    linkText: "Bestill",
  };
}
