import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useFetchInvoiceHistory } from "src/api/fixed";
import { types } from "@telia-no-min-side/utils";
import dayjs from "dayjs";

type Options = {
  prefetchFetchFrom?: number;
  numberInvoicesToFetch: number;
  financialAccountId: number | undefined;
};

type Return = {
  setDateToFetchFrom: Dispatch<SetStateAction<number>>;
  invoiceHistory: types.fixed.InvoiceHistory | undefined;
  invoiceHistoryError: Error | undefined;
  invoiceHistoryIsLoading: boolean;
  lastInvoiceHistoryIsLoading: boolean;
  lastInvoiceHistory: types.fixed.InvoiceHistory | undefined;
  dateAndHourNow: number;
  mutateInvoiceHistory: () => void;
};

export function useFetchInvoiceHistoryWithPrefetch({
  prefetchFetchFrom,
  numberInvoicesToFetch,
  financialAccountId,
}: Options): Return {
  const dateAndHourNowRef = useRef(dayjs(dayjs().format("YYYY-MM-DDTHH")).unix());
  const [dateToFetchFrom, setDateToFetchFrom] = useState(dateAndHourNowRef.current);

  const { invoiceHistory, invoiceHistoryError, invoiceHistoryIsLoading, invoiceHistoryMutate } = useFetchInvoiceHistory(
    {
      financialAccountId,
      offset: numberInvoicesToFetch,
      dateFrom: dateToFetchFrom,
    }
  );

  useFetchInvoiceHistory({
    financialAccountId,
    offset: numberInvoicesToFetch,
    dateFrom: prefetchFetchFrom || dateAndHourNowRef.current,
  });

  const {
    invoiceHistory: lastInvoiceHistory,
    invoiceHistoryIsLoading: lastInvoiceHistoryIsLoading,
    invoiceHistoryMutate: lastInvoiceHistoryMutate,
  } = useFetchInvoiceHistory({
    financialAccountId,
    offset: 1,
    dateFrom: dateAndHourNowRef.current,
  });

  function mutateInvoiceHistory() {
    lastInvoiceHistoryMutate();
    invoiceHistoryMutate();
    setDateToFetchFrom(dateAndHourNowRef.current);
  }
  return {
    setDateToFetchFrom,
    invoiceHistory,
    invoiceHistoryError,
    invoiceHistoryIsLoading,
    lastInvoiceHistory,
    dateAndHourNow: dateAndHourNowRef.current,
    lastInvoiceHistoryIsLoading,
    mutateInvoiceHistory,
  };
}
