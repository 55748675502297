import { ProductUser } from "src/gql/graphql";
import {
  DELIVERY_TYPE,
  DELIVERY_OPTION,
  ADDRESS_MESSAGE,
  USER_ADDRESS_FIELD_NO,
  USER_ADDRESS_FIELD_EN,
} from "util/constants/address";

export const getInitialState = (shouldUsePoboxAddress: boolean | null | undefined, productUser: ProductUser) => {
  const { firstName, surname, address } = productUser;
  const distributionType = shouldUsePoboxAddress ? DELIVERY_OPTION.PO_BOX_ADDRESS : DELIVERY_OPTION.URBAN_ADDRESS;
  const initialState: Fields = {
    firstName: {
      labelText: USER_ADDRESS_FIELD_NO.FIRST_NAME,
      name: USER_ADDRESS_FIELD_EN.FIRST_NAME,
      fieldType: [DELIVERY_OPTION.URBAN_ADDRESS, DELIVERY_OPTION.PO_BOX_ADDRESS],
      value: firstName || "",
      required: true,
      errorMessage: ADDRESS_MESSAGE.REQUIRED_FIELD,
      isDirty: false,
      isValid: true,
    },
    surname: {
      labelText: USER_ADDRESS_FIELD_NO.SURNAME,
      name: USER_ADDRESS_FIELD_EN.SURNAME,
      fieldType: [DELIVERY_OPTION.URBAN_ADDRESS, DELIVERY_OPTION.PO_BOX_ADDRESS],
      value: surname || "",
      required: true,
      errorMessage: ADDRESS_MESSAGE.REQUIRED_FIELD,
      isDirty: false,
      isValid: true,
    },
    streetName: {
      labelText: USER_ADDRESS_FIELD_NO.STREET_NAME,
      name: USER_ADDRESS_FIELD_EN.STREET_NAME,
      fieldType: [DELIVERY_OPTION.URBAN_ADDRESS],
      value: address?.urbanAddress?.streetName || "",
      required: true,
      isDirty: false,
      isValid: true,
    },
    streetNr: {
      labelText: USER_ADDRESS_FIELD_NO.STREET_NUMBER,
      name: USER_ADDRESS_FIELD_EN.STREET_NUMBER,
      fieldType: [DELIVERY_OPTION.URBAN_ADDRESS],
      value: address?.urbanAddress?.streetNr || "",
      required: true,
      isDirty: false,
      isValid: true,
    },
    streetNrSuffix: {
      labelText: USER_ADDRESS_FIELD_NO.STREET_NUMBER_SUFFIX,
      name: USER_ADDRESS_FIELD_EN.STREET_NUMBER_SUFFIX,
      fieldType: [DELIVERY_OPTION.URBAN_ADDRESS],
      value: address?.urbanAddress?.streetNrSuffix || "",
      isDirty: false,
      isValid: true,
    },
    floorNumber: {
      labelText: USER_ADDRESS_FIELD_NO.FLOOR_NUMBER,
      name: USER_ADDRESS_FIELD_EN.FLOOR_NUMBER,
      fieldType: [DELIVERY_OPTION.URBAN_ADDRESS],
      value: address?.urbanAddress?.floorNumber || "",
      isDirty: false,
      isValid: true,
    },
    doorNumber: {
      labelText: USER_ADDRESS_FIELD_NO.DOOR_NUMBER,
      name: USER_ADDRESS_FIELD_EN.DOOR_NUMBER,
      fieldType: [DELIVERY_OPTION.URBAN_ADDRESS],
      value: address?.urbanAddress?.doorNumber || "",
      isDirty: false,
      isValid: true,
    },
    boxName: {
      labelText: USER_ADDRESS_FIELD_NO.BOX_NAME,
      name: USER_ADDRESS_FIELD_EN.BOX_NAME,
      fieldType: [DELIVERY_OPTION.PO_BOX_ADDRESS],
      value: address?.poboxAddress?.boxName || "",
      isDirty: false,
      isValid: true,
    },
    boxNr: {
      labelText: USER_ADDRESS_FIELD_NO.BOX_NUMBER,
      name: USER_ADDRESS_FIELD_EN.BOX_NUMBER,
      fieldType: [DELIVERY_OPTION.PO_BOX_ADDRESS],
      value: address?.poboxAddress?.boxNr || "",
      required: true,
      errorMessage: "Ugyldig format",
      isDirty: false,
      isValid: true,
    },
    postcode: {
      labelText: USER_ADDRESS_FIELD_NO.POST_CODE,
      name: USER_ADDRESS_FIELD_EN.POST_CODE,
      fieldType: [DELIVERY_OPTION.URBAN_ADDRESS, DELIVERY_OPTION.PO_BOX_ADDRESS],
      value: address?.[distributionType]?.postcode || "",
      required: true,
      errorMessage: ADDRESS_MESSAGE.ZIP_CODE_ERROR,
      isDirty: false,
      isValid: true,
    },
    city: {
      labelText: USER_ADDRESS_FIELD_NO.CITY,
      name: USER_ADDRESS_FIELD_EN.CITY,
      fieldType: [DELIVERY_OPTION.URBAN_ADDRESS, DELIVERY_OPTION.PO_BOX_ADDRESS],
      value: address?.[distributionType]?.city || "",
      required: true,
      isDirty: false,
      isValid: true,
    },
    careOfName: {
      labelText: USER_ADDRESS_FIELD_NO.CARE_OF_NAME,
      type: "email",
      name: USER_ADDRESS_FIELD_EN.CARE_OF_NAME,
      fieldType: [DELIVERY_OPTION.URBAN_ADDRESS],
      value: address?.urbanAddress?.careOfName || "",
      isDirty: false,
      isValid: true,
    },
  };

  return initialState;
};

type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

export type Field = {
  name: string;
  fieldType: RequireAtLeastOne<DELIVERY_TYPE>[];
  labelText: string;
  type?: "text" | "email";
  value: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  isDirty: boolean;
  isValid: boolean;
};

export type Fields = Record<string, Field>;
