import React from "react";
import { CheckBoxWithLabel, Heading } from "@telia/styleguide";
import { ContentWrapper } from "../../../modules/LayoutComponents/ContentWrapper";
import { ALGParams } from "../../../util/HomeCentralUtilities";

export const ALG = ({ alg, flipFlag }) => (
  <ContentWrapper>
    <Heading level={3} text={"ALG"} />
    <CheckBoxWithLabel label={"SIP"} onChange={() => flipFlag(ALGParams.SIP)} checked={alg.SipAlgEnabled} />
    <CheckBoxWithLabel label={"PPTP"} onChange={() => flipFlag(ALGParams.PPTP)} checked={alg.PptpAlgEnabled} />
    <CheckBoxWithLabel label={"IPSEC"} onChange={() => flipFlag(ALGParams.IPSEC)} checked={alg.IpsecAlgEnabled} />
  </ContentWrapper>
);
