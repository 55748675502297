import { useMutation } from "@apollo/client";
import { SelfVerifyProductUserMutationVariables } from "gql/graphql";
import { graphql } from "src/gql";

export const SELF_VERIFY_PRODUCT_USER = graphql(`
  mutation selfVerifyProductUser(
    $challengeId: String
    $phoneNumber: String!
    $firstName: String!
    $surname: String!
    $personId: String!
    $partyId: String!
    $birthDate: String!
  ) {
    selfVerifyProductUser(
      challengeId: $challengeId
      phoneNumber: $phoneNumber
      firstName: $firstName
      surname: $surname
      personId: $personId
      partyId: $partyId
      birthDate: $birthDate
    ) {
      result
    }
  }
`);

export function useSelfVerifyProductUser() {
  const [selfVerifyProductUser, selfVerifyProductUserResponse] = useMutation(SELF_VERIFY_PRODUCT_USER);

  const runSelfVerifyProductUser = async ({
    challengeId,
    phoneNumber,
    firstName,
    surname,
    personId,
    partyId,
    birthDate,
  }: SelfVerifyProductUserMutationVariables) => {
    try {
      const productUser = {
        challengeId,
        phoneNumber,
        firstName,
        surname,
        personId,
        partyId,
        birthDate,
      };
      await selfVerifyProductUser({ variables: productUser });
    } catch (error) {
      console.error(error);
    }
  };

  return { selfVerifyProductUserResponse, runSelfVerifyProductUser };
}
