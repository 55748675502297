import { formatDateForSuspendedMessage } from "../utils";
import { format } from "@telia-no-min-side/utils";
import { UseMobileAccountSubscriptionData } from "src/hooks/useMobileAccount";
import { breakpoint, Button, Flex, Icon, Notification } from "@telia/teddy";
import { Link } from "react-router-dom";
import { useWindowSize } from "@telia-no-min-side/components";

type Props = Pick<
  UseMobileAccountSubscriptionData,
  "productUser" | "phoneNumber" | "subscriptionStatusDate" | "userOffering"
>;

export function CustomerRequestMessage({ userOffering, subscriptionStatusDate, productUser, phoneNumber }: Props) {
  const userOfferingShortName = userOffering?.shortName || "";
  const { isMobile } = useWindowSize();
  return (
    <Flex maxWidth={breakpoint.teddyBreakpointMd} p={isMobile ? "200" : "400"} gap="300" direction="column">
      <Notification variant="information" data-tracking-id="alert--suspended-subscription_reason-customer-request">
        <Notification.Heading as="h3">
          <Notification.Icon>Ditt abonnement {userOfferingShortName} er sperret</Notification.Icon>
        </Notification.Heading>
        <Flex direction="column" gap="gutter-md">
          <Notification.Text>
            Abonnementet ditt ble sperret den {formatDateForSuspendedMessage(subscriptionStatusDate || "")} etter ønske
            fra eier eller bruker av dette abonnementet. Du kan selv gjenåpne abonnementet når du ønsker.
          </Notification.Text>
          <Notification.Footer>
            <Button asChild>
              <Link
                to={`/minside/mobil/gjenaapne-abonnement?phoneNumber=${
                  phoneNumber.localNumber
                }&name=${format.getFullName(productUser)}&userOfferingShortName=${userOfferingShortName}`}
              >
                <Icon name="key" />
                Gjennåpne abonnement
              </Link>
            </Button>
          </Notification.Footer>
        </Flex>
      </Notification>
    </Flex>
  );
}
