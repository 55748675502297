import { Container, Skeleton } from "@telia-no-min-side/components";
import { Dispatch, SetStateAction } from "react";
import { AccountInvoiceDetailsQuery, InvoicePaymentStatus } from "src/gql/graphql";
import { DetailsElementSkeleton } from "src/pages/invoice/components/DetailsElementSkeleton";
import { getPriceAmountText } from "src/util/priceFormatter";
import { PaymentResultMessage } from "../../components/PaymentResultMessage";
import { ColorCode, Cost, Refund } from "../../styles";
import { PayBalance } from "../PayBalance";
import { DetailsElementAccountLevel } from "./DetailsElementAccountLevel";
import { DetailsElementMobile } from "./DetailsElementMobile";
import { colors } from "@telia/styleguide";
import { InvoiceDetailsHeading, InvoiceDetailsParagraph, Line } from "../../components/details-element/style";
import { InvoiceActions } from "../../components/InvoiceActions";

type DetailsElementListMobileProps = {
  setOpenIds: Dispatch<SetStateAction<string[]>>;
  openIds: string[];
  isAllOpen: boolean;
  setIsAllOpen: (isOpen: boolean) => void;
  invoice?: AccountInvoiceDetailsQuery["account"]["invoice"];
  subscriptions: AccountInvoiceDetailsQuery["account"]["subscriptions"];
  setModalOpen: (isOpen: boolean) => void;
  invoiceDetailsIsLoading: boolean;
  invoiceCardPaymentStatus: InvoicePaymentStatus | undefined;
  accountId: string;
  showPaidAmount: boolean;
  paidAmount: number;
  showOverpaidAmount: boolean;
  restAmount: number;
  isPostponingDisabled: boolean;
};

export function DetailsElementListMobile(props: DetailsElementListMobileProps) {
  const {
    isAllOpen,
    openIds,
    setOpenIds,
    setModalOpen,
    invoice,
    subscriptions,
    invoiceDetailsIsLoading,
    setIsAllOpen,
    invoiceCardPaymentStatus,
    accountId,
    showPaidAmount,
    paidAmount,
    showOverpaidAmount,
    restAmount,
    isPostponingDisabled,
  } = props;
  const isPaymentInProgress = invoiceCardPaymentStatus && invoiceCardPaymentStatus !== InvoicePaymentStatus.None;

  const accountLevelInvoiceGroup =
    invoice?.invoiceGroup
      ?.map((invoiceGroup) => ({
        ...invoiceGroup,
        invoiceRow: invoiceGroup?.invoiceRow?.filter((invoice) => invoice?.displayOnInvoice && invoice.accountLevel),
      }))
      .filter((invoiceGroup) => invoiceGroup.invoiceRow?.length) || [];

  function toggleIsOpen(id: string) {
    setIsAllOpen(false);
    setOpenIds((prev) => {
      if (prev?.includes(id)) {
        return prev?.filter((id) => id !== id);
      } else {
        return [...(prev || []), id];
      }
    });
  }

  return (
    <Container>
      {invoiceDetailsIsLoading && (
        <>
          <DetailsElementSkeleton />
          <DetailsElementSkeleton />
        </>
      )}
      <>
        <ColorCode fadeIn={Boolean(openIds?.length) || isAllOpen} maxWidth="sm" padding="horizontal">
          <span>Fargekoder:</span>
          <Cost>Kostnad</Cost>
          <Refund>Kreditert/rabattert</Refund>
        </ColorCode>
        <DetailsElementMobile
          isOpen={openIds.includes("subscription") || isAllOpen}
          toggleIsOpen={() => toggleIsOpen("subscription")}
          subscriptions={subscriptions}
          accountLevelInvoiceGroups={accountLevelInvoiceGroup}
          amount={invoice?.amount?.value}
          category="Abonnement"
        />
        {accountLevelInvoiceGroup.map((invoiceGroup) => {
          const isOpen = openIds.includes(invoiceGroup.name || "") || isAllOpen;
          return (
            <DetailsElementAccountLevel
              key={`${invoiceGroup.name}-detail-accordion-item-invoice`}
              invoiceGroup={invoiceGroup}
              isOpen={isOpen}
              toggleIsOpen={() => toggleIsOpen(invoiceGroup.name || "")}
            />
          );
        })}
        <Container maxWidth="sm" flexDirection="column" gap>
          <Container flexDirection="row" justifyContent="space-between" alignItems="center" padding>
            <InvoiceDetailsHeading>Totalsum</InvoiceDetailsHeading>

            {invoiceDetailsIsLoading ? (
              <Skeleton variant="rectangular" width="10ch" height="3ch" />
            ) : (
              <InvoiceDetailsParagraph data-di-mask>
                {getPriceAmountText(invoice?.amount?.value, "kr")}
              </InvoiceDetailsParagraph>
            )}
          </Container>

          {(showPaidAmount || showOverpaidAmount) && (
            <>
              <Line />
              <Container flexDirection="row" justifyContent="space-between" alignItems="center" padding>
                <InvoiceDetailsHeading>For mye betalt tidligere</InvoiceDetailsHeading>
                {invoiceDetailsIsLoading ? (
                  <Skeleton variant="rectangular" width="10ch" height="3ch" />
                ) : (
                  <InvoiceDetailsParagraph data-di-mask>
                    -{getPriceAmountText(paidAmount, "kr")}
                  </InvoiceDetailsParagraph>
                )}
              </Container>
              <Line dashed />
              <Container flexDirection="row" justifyContent="space-between" alignItems="center" padding>
                <InvoiceDetailsHeading customColor={showOverpaidAmount ? colors.green700 : colors.corePurple700}>
                  {showOverpaidAmount ? "Penger tilgode" : "Resterende beløp å betale"}
                </InvoiceDetailsHeading>
                {invoiceDetailsIsLoading ? (
                  <Skeleton variant="rectangular" width="10ch" height="3ch" />
                ) : (
                  <InvoiceDetailsParagraph
                    data-di-mask
                    customColor={showOverpaidAmount ? colors.green700 : colors.corePurple700}
                  >
                    {getPriceAmountText(Math.abs(restAmount), "kr")}
                  </InvoiceDetailsParagraph>
                )}
              </Container>
            </>
          )}
        </Container>
      </>
      {invoice &&
        !invoice.paid &&
        (isPaymentInProgress ? (
          <Container padding>
            <PaymentResultMessage invoiceCardPaymentStatus={invoiceCardPaymentStatus} />
          </Container>
        ) : (
          <Container flexWrap padding="horizontal" gap flexDirection="column" style={{ marginTop: "10px" }}>
            <InvoiceActions
              isPostponingDisabled={isPostponingDisabled}
              setPostponeInvoiceModalOpen={setModalOpen}
              isDetailPage
            >
              <PayBalance accountId={accountId} />
            </InvoiceActions>
          </Container>
        ))}
    </Container>
  );
}
