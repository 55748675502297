import { useState } from "react";
import { Flex, Card, Badge, Text, Icon } from "@telia/teddy";
import { Container } from "@telia-no-min-side/components";
import { format, track } from "@telia-no-min-side/utils";
import { useFixedAccount } from "hooks/useFixedAccount";
import { LinkCard } from "pages/home/card/LinkCard";
import { BaseCard } from "pages/home/card/style";
import { CloseButton } from "./styles";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { useFetchEvoBoxes } from "../hooks/useFetchEvoBoxes";
import { getEvoBoxToChangeActionLink } from "../utils/getEvoBoxToChangeActionLink";

// 99447779
const lozenge = {
  label: "Viktig informasjon",
  icon: "info",
  status: "info",
} as const;

export function ChangeEvoBox() {
  const { changeFixedAccount } = useFixedAccount();
  const [showSelectAccount, setShowSelectAccount] = useState(false);
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { usersWithEVo, usersIdsWithEvo, evoBoxUsersThatChanSwitch, hasMultipleAccounts } = useFetchEvoBoxes();

  if (!evoBoxUsersThatChanSwitch?.length) return null;

  const addressCanChange =
    hasMultipleAccounts && evoBoxUsersThatChanSwitch.length === 1 ? usersWithEVo?.find((user) => user.street) : null;

  // return (
  //   <>
  //     {Boolean(evoBoxUsersThatChanSwitch.length) && (
  //       <CardWithProfilePick
  //         isLoading={evoBox.isLoading}
  //         usersIds={evoBoxUsersThatChanSwitch.map(({ customerId }) => customerId)}
  //         ui_item_type="banner_compensation_extra_points"
  //         heading="TV-boksen din avvikles"
  //         lozenge={{
  //           label: "Viktig informasjon",
  //           icon: "info",
  //           status: "info",
  //         }}
  //         actionLink={{
  //           href: compensationUrl,
  //           linkText: "Bestill",
  //           isInternalNavigation: true,
  //         }}
  //       >
  //
  //         {addressCanChange && (
  //           <Paragraph isFaded variant="additional-100">
  //             Gjelder: {format.startCase(addressCanChange.street)}
  //           </Paragraph>
  //         )}
  //       </CardWithProfilePick>
  //     )}
  //   </>
  // );

  if (showSelectAccount && usersWithEVo?.length) {
    return (
      <BaseCard lozengeRight={lozenge}>
        <CloseButton
          isIconButton
          icon="close"
          onClick={() => setShowSelectAccount(false)}
          title="Lukk"
          size="sm"
          variant="text-black"
        />
        <Text>For hvilken adresse ønsker du å endre:</Text>
        <Container flexDirection="column" gap>
          {usersWithEVo.map((customer) => {
            const evoBoxToChange = evoBoxUsersThatChanSwitch.find(
              ({ customerId }) => customerId === customer.customerId
            );
            const actionLink = getEvoBoxToChangeActionLink(evoBoxToChange?.products);
            if (!actionLink) return null;

            return (
              <div key={`${customer.customerId}-select-profile`}>
                <LinkCard
                  removeMinHeight
                  data-di-mask
                  backgroundColor="light-purple"
                  onLinkNavigation={() => {
                    pushGenericTrackingEvent({
                      ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
                      ui_item_type: TRACK_EVENT.ITEM_TYPE.UI_ITEM,
                      ui_item_url: actionLink.href,
                      ui_item_text: format.startCase(`${customer.street}`),
                    });
                    changeFixedAccount(customer.customerId);
                  }}
                  cardHref={actionLink.href}
                  isCardLinkInternalNavigation={actionLink.isInternalNavigation}
                  cardLinkText={format.startCase(`${customer.street}`)}
                >
                  <Container flexDirection="row" gap floatContent="center">
                    <Icon name="home" size="sm" />
                    <Text data-di-mask>{format.startCase(customer.street)}</Text>
                  </Container>
                </LinkCard>
              </div>
            );
          })}
        </Container>
      </BaseCard>
    );
  }

  const hasToSelectAccount = (usersIdsWithEvo?.length || 0) > 1;
  if (hasToSelectAccount) {
    return (
      <Card shadow bordered variant="white" layout="default" height="100%" width="100%" maxWidth="400px">
        <Card.Slot align="top-right">
          <Badge variant="information">
            <Icon name="info" />
            Viktig informasjon
          </Badge>
        </Card.Slot>

        <Card.Heading variant="title-100">TV-boksen din avvikles</Card.Heading>

        <Card.Content>
          <Text>TV-boksen din slutter snart å virke. Bestill ny Telia box nå, helt uten ekstra kostnad.</Text>
        </Card.Content>

        <Card.Footer>
          <Card.Button
            variant="text"
            onClick={() => {
              setShowSelectAccount(true);
              pushGenericTrackingEvent({
                ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
                ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_COMPENSATION_EXTRA_POINTS,
                ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
                ui_item_text: TRACK_EVENT.ITEM_TEXT.ORDER,
              });
            }}
          >
            Bestill
          </Card.Button>
        </Card.Footer>
      </Card>
    );
  }

  const userToSelect = usersWithEVo?.find((customerId) => customerId);
  const evoBoxToChange = evoBoxUsersThatChanSwitch.find(({ customerId }) => customerId === userToSelect?.customerId)
    ?.products;
  const actionLink = getEvoBoxToChangeActionLink(evoBoxToChange);
  if (!actionLink) return null;
  return (
    <Card
      shadow
      bordered
      variant="white"
      layout="default"
      height="100%"
      width="100%"
      maxWidth="400px"
      onClick={() => {
        userToSelect && changeFixedAccount(userToSelect.customerId);
        pushGenericTrackingEvent({
          ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
          ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
          ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_COMPENSATION_EXTRA_POINTS,
          ui_item_url: actionLink.href,
          ui_item_text: actionLink.linkText,
        });
      }}
    >
      <Card.Slot align="top-right">
        <Badge variant="information">
          <Icon name="info" />
          Viktig informasjon
        </Badge>
      </Card.Slot>

      <Card.Heading variant="title-100">TV-boksen din avvikles</Card.Heading>

      <Card.Content>
        <Flex direction="column" gap="100">
          <Text>TV-boksen din slutter snart å virke. Bestill ny Telia box nå, helt uten ekstra kostnad.</Text>
          {addressCanChange && (
            <Text variant="additional-100" faded data-di-mask>
              Gjelder: {format.startCase(addressCanChange.street)}
            </Text>
          )}
        </Flex>
      </Card.Content>

      <Card.Footer>
        <Card.Button variant="text" asChild>
          <a href={actionLink.href}>{actionLink.linkText}</a>
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
