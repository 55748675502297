import styled from "styled-components";
import { Card, Flex, color, spacing } from "@telia/teddy";

export const MainCardContainer = styled(Card)`
  justify-content: stretch;
`;

export const HeaderFlexContainer = styled(Flex)`
  margin-bottom: 26px;
`;

export const CardAdditionalInfo = styled(Card)`
  background-color: ${color.teddyColorPurple50};
  padding: ${spacing.teddySpacing200};
`;

export const DetailedInfoFlexContainer = styled(Flex)`
  margin-bottom: 26px;
`;

export const DetailedInfoItem = styled.div`
  min-width: 25%;
  margin-right: 15px;
`;

export const PriceCardContainer = styled(Card)`
  justify-content: stretch;
  padding: 0;
  & > div:nth-child(odd) {
    background: ${color.teddyColorGray50};
  }
`;
