import { createContext, useContext } from "react";
import { track } from "@telia-no-min-side/utils";
import { ExpandableCardProps } from ".";
import type { BaseSyntheticEvent } from "react";

export type ExpandableCardState = {
  id: string;
  isOpen: boolean;
  onClick?: (e: BaseSyntheticEvent) => void;
  tracking?: {
    open: track.GenericEvent;
    close: track.GenericEvent;
  };
} & Required<Pick<ExpandableCardProps, "icon" | "compact">>;

export const ExpandableCardContext = createContext<ExpandableCardState>({
  id: "",
  isOpen: false,
  onClick: undefined,
  tracking: undefined,
  icon: "chevron-down",
  compact: false,
});

export function useExpandableCard() {
  return useContext(ExpandableCardContext);
}
