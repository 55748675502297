import { colors } from "@telia/styleguide";
import styled, { css, keyframes } from "styled-components";
import { Container } from "../Container";
import { devices } from "../utils/breakpoints";
import { remCalc } from "../utils/fonts";
import { zIndex } from "../utils/variables";
import { Button } from "../Button";
import { Stack } from ".";

export const Dot = styled.input`
  border-radius: 999rem;
  height: ${remCalc(24)};
  width: ${remCalc(24)};
  transition: background-color 0.2s ease-out;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  background-color: white;
  display: grid;
  place-content: center;
  border: 1px solid ${colors.grey400};
  position: relative;
  cursor: pointer;

  &::before {
    position: absolute;
    content: "";
    inset: 0;
    border-radius: inherit;
    background-color: ${colors.corePurple900};
    transition:
      opacity 0.3s ease-in-out,
      scale 0.5s ease-in-out;
    opacity: ${({ checked }) => (checked ? 1 : 0)};
    scale: ${({ checked }) => (checked ? 0.6 : 1)};
  }

  &::after {
    position: absolute;
    content: "";
    inset: -1px;
    border-radius: inherit;
    border: 1px solid ${colors.corePurple900};
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ checked }) => (checked ? 1 : 0)};
  }
  &:hover::after {
    opacity: 1;
  }
`;

export const HiddenSpaceMaker = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
  & > * {
    flex: 1 0 100%;
    width: 100%;
  }
`;
export const DotSelector = styled.div`
  display: flex;
  gap: 1rem;
  margin-inline: auto;
`;

export const CarouselWrapper = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${devices.xs_down} {
    padding-inline: 0.5rem;
  }
  @media ${devices.xxs_down} {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-block: 0.5rem;
`;

export const Scroller = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  transition: 0.35s height ease;
`;

type LabelProps = {
  isSelected: boolean;
  stackLeft: boolean;
  stackRight: boolean;
  isNextElementInStack: boolean;
  prevState: Stack;
  isFocusViewMode?: boolean;
};

const toFront = keyframes`
  0% {
    z-index: 2;
  }
  19% {
    z-index: 2;
  }
  20% {
    z-index: 3;
  }
`;

const toStack = keyframes`
  0% {
    z-index: 3;
  }
  19% {
    z-index: 3;
  }
  20% {
    z-index: 2;
  }
`;

const scaleOut = keyframes`
  60% {
    scale: .8;
  }
  100% {
    scale: 0;
  }
`;

export const Label = styled.label<LabelProps>`
  --CARD_STACK_WIDTH: 100px;
  --START_POSITION_CENTER_LEFT: 0px;
  --START_POSITION_CENTER_RIGHT: 0px;
  transition:
    1.3s translate ease,
    0.75s scale ease;
  scale: ${({ isSelected }) => (isSelected ? 1 : 0.85)};
  transform-origin: center;
  position: absolute;
  display: grid;
  place-items: center;
  translate: 0;

  @media ${devices.sm_down} {
    --CARD_STACK_WIDTH: 100%;
  }
  ${({ stackLeft, stackRight, isSelected, isFocusViewMode }) => {
    if (!isSelected && isFocusViewMode) {
      return css`
        animation: ${scaleOut} 1s ease;
      `;
    }
    if (stackLeft) {
      return css`
        translate: calc(var(--CARD_STACK_WIDTH) * -1);
      `;
    }
    if (stackRight) {
      return css`
        translate: var(--CARD_STACK_WIDTH);
      `;
    }
  }}

  ${({ isSelected, isNextElementInStack, prevState }) => {
    if (isSelected) {
      return css`
        animation: ${toFront} 1s ease;
        z-index: 3;
      `;
    }
    if (isNextElementInStack && prevState === "stackSelected") {
      return css`
        animation: ${toStack} 1s ease;
        z-index: 1;
      `;
    }
    if (prevState === "stackSelected") {
      return css`
        animation: ${toStack} 1s ease;
      `;
    }
    if (isNextElementInStack) {
      return css`
        z-index: 1;
      `;
    }
  }}
`;

export const ShadowOverlay = styled.div<{ fadeIn: boolean }>`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
  pointer-events: none;
  @media ${devices.sm_down} {
    background-color: rgba(0, 0, 0, 0.8);
  }

  ${({ fadeIn }) => {
    if (fadeIn) {
      return css`
        pointer-events: all;
        opacity: 0.3;
        :hover {
          transition: opacity 0.3s ease-in-out;
          opacity: 0.1;
        }
      `;
    }
  }}
`;

type SelectCardButtonProps = {
  align: "left" | "right";
  isFocusViewMode?: boolean;
};

export const SelectCardButton = styled(Button)<SelectCardButtonProps>`
  z-index: ${zIndex.toolTip};
  position: absolute;
  top: 50%;
  ${({ align }) => {
    if (align === "left") {
      return css`
        left: 0;
        translate: -50% -50%;
        @media ${devices.sm_down} {
          translate: -40% -50%;
          scale: 0.8;
        }
      `;
    }
    if (align === "right") {
      return css`
        right: 0;
        translate: 50% -50%;
        @media ${devices.sm_down} {
          translate: 40% -50%;
          scale: 0.8;
        }
      `;
    }
  }}
  transition: 1s opacity ease;
  ${({ isFocusViewMode }) => {
    if (isFocusViewMode) {
      return css`
        opacity: 0;
      `;
    }
  }}
`;
