import { Card } from "@telia/teddy";

type Props = {
  subscriptionName: string;
};

export function PrepaidSubscriptionInfo({ subscriptionName }: Props) {
  return (
    <Card variant="white" layout="navigation-vertical" bordered height={"120px"}>
      <Card.Heading as="h4" variant="title-100" mt="200">
        {subscriptionName}
      </Card.Heading>
    </Card>
  );
}
