import { getSimplePriceText } from "@telia-no-min-side/utils";
import { Flex, Card, Text } from "@telia/teddy";

type Props = {
  name: string | null | undefined;
  price: number | undefined;
};

export function SubscriptionCard({ name, price }: Props) {
  return (
    <Card variant="white" layout="navigation-vertical" shadow>
      <Flex justify="between" align="center" width="100%">
        <Card.Heading as="h4" variant="title-100" width="100%">
          {name}
        </Card.Heading>

        <Flex>
          <Text variant="paragraph-100-bold">{getSimplePriceText(price)}</Text>
          <Text>/md.</Text>
        </Flex>
      </Flex>
    </Card>
  );
}
