import { useState } from "react";
import { Link } from "react-router-dom";
import { Flex, Card, Button, Icon } from "@telia/teddy";
import { TopupCategory } from "gql/graphql";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { convertBytesToGB, formatBytesToGB, getOfferingPrice } from "../../utils";
import { Badge, Terms, UnlimitedDataProgressSection, ActionsSection } from "..";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";

export function UnlimitedDataUsageCard() {
  const [isTermsVisible, setIsTermsVisible] = useState(false);

  const { showTopup, subscription, localDataUsage, openSelectedTopupDrawer, timeUntilDataUsagePeriodStarts } =
    useMobileDashboardContext();
  const offeringPrices = subscription?.userOffering?.offeringPrices?.[0]?.offeringPrices || [];

  const dataLimitRLH = getOfferingPrice(offeringPrices, "DATA_LIMIT_RLH"); // ROAMING_LIKE_HOME
  const dataLimitWhenSpeedReduced = getOfferingPrice(offeringPrices, "DATA_LIMIT_SPEED_REDUCED");
  const reducedSpeedAfterExceededDataLimit = getOfferingPrice(offeringPrices, "SPEED_DOWNLOAD_REDUCED");

  const formattedLimits = {
    dataLimitRLH: `${dataLimitRLH?.amount} ${dataLimitRLH?.unit}`,
    dataLimitWhenSpeedReduced: `${dataLimitWhenSpeedReduced?.amount} ${dataLimitWhenSpeedReduced?.unit}`,
    reducedSpeed: `${reducedSpeedAfterExceededDataLimit?.amount} ${reducedSpeedAfterExceededDataLimit?.unit}`,
  };

  const dataUsed = localDataUsage?.reduce((sum, quota) => sum + (quota?.usage || 0), 0) || 0;
  const hasExceededDataLimit = convertBytesToGB(dataUsed) > (dataLimitWhenSpeedReduced?.amount || 0);

  return (
    <Card bordered shadow layout="default" variant="white" gap="gutter-md">
      <Badge />

      {isTermsVisible ? (
        <Terms formattedLimits={formattedLimits} setIsTermsVisible={setIsTermsVisible} />
      ) : (
        <>
          <Card.Heading variant="title-400">{formatBytesToGB(dataUsed)} brukt</Card.Heading>
          <Card.Line />
          <Card.Content>
            <Flex direction="column" gap="400" mb="gutter-sm">
              <UnlimitedDataProgressSection
                formattedLimits={formattedLimits}
                hasExceededDataLimit={hasExceededDataLimit}
                timeUntilDataUsagePeriodStarts={timeUntilDataUsagePeriodStarts}
              />
              <ActionsSection setIsTermsVisible={setIsTermsVisible} hasExceededDataLimit={hasExceededDataLimit} />
            </Flex>
          </Card.Content>

          <Card.Footer>
            {hasExceededDataLimit && showTopup ? (
              <Button width="100%" variant="primary" onClick={() => openSelectedTopupDrawer(TopupCategory.DataBoost)}>
                <Icon name="getting-started">Aktiver Data Boost</Icon>
              </Button>
            ) : (
              <Button width="100%" variant="primary" asChild>
                <Link to={INTERNAL_LINK.MOBILE_NEW.DATA_USAGE_HISTORY_PAGE}>
                  <Icon name="bar-chart">Tidligere dataforbruk</Icon>
                </Link>
              </Button>
            )}
          </Card.Footer>
        </>
      )}
    </Card>
  );
}
