import { Link } from "react-router-dom";
import { ExpandableCard, Skeleton, Box } from "@telia/teddy";
import { getActionLinks } from "src/util/productHelper/getActionLinks";
import { types } from "@telia-no-min-side/utils";

type Props = {
  actions: types.fixed.Product["actions"];
  isLoading: boolean;
};

export function ActionButtons({ actions, isLoading }: Props) {
  return (
    <Skeleton width="100px" height="25px" style={{ float: "right" }} loading={isLoading} radius="lg">
      <Box>
        {getActionLinks(actions)?.map((actionLink) => (
          <ExpandableCard.Button
            key={actionLink.href}
            position="right"
            variant="tertiary-purple"
            asChild
            onClick={(e) => e.stopPropagation()}
          >
            {actionLink.isInternalNavigation ? (
              <Link to={actionLink.href}>{actionLink.children}</Link>
            ) : (
              <a href={actionLink.href}>{actionLink.children}</a>
            )}
          </ExpandableCard.Button>
        ))}
      </Box>
    </Skeleton>
  );
}
