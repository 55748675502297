import { Link } from "react-router-dom";
import { Flex, Card, Badge, Icon, Text, Image } from "@telia/teddy";
import { LocalAgreementType } from "pages/mobile/mobile-subscription-dashboard/types";

type Props = {
  agreementData: LocalAgreementType;
};

export function Agreement({ agreementData }: Props) {
  const {
    isImageAvailable = false,
    imageUrl = "",
    agreementTitle = "",
    phoneBrand = "",
    phoneModel = "",
    isInsuranceExist = false,
    insuranceType = null,
    navigateTo = "",
    canSwitchInstantly = false,
  } = agreementData;

  return (
    <Card layout="default" variant="white" gap="gutter-md" bordered shadow minHeight="320px" height="100%">
      {canSwitchInstantly && (
        <Card.Slot align="right">
          <Badge variant="special" hideIcon>
            Du kan svitsje!
          </Badge>
        </Card.Slot>
      )}

      <Flex justify="start" gap="gutter-xl">
        {isImageAvailable && (
          <Card.Illustration>
            <Image width="150px" objectFit="fill" src={imageUrl} />
          </Card.Illustration>
        )}

        <Flex direction="column" justify="center">
          <Text>{phoneBrand?.toUpperCase()}</Text>
          <Card.Heading variant="title-200">{phoneModel}</Card.Heading>
        </Flex>
      </Flex>

      <Card.Content>
        <Flex direction="column" gap="gutter-sm">
          <Flex gap="100">
            <Icon name="checkmark" size="md" />
            <Text>{agreementTitle}</Text>
          </Flex>
          {isInsuranceExist && (
            <Flex gap="100">
              <Icon name="checkmark" size="md" />
              {insuranceType && <Text>{insuranceType}</Text>}
            </Flex>
          )}
        </Flex>
      </Card.Content>

      <Card.Footer height="100%" align="end">
        <Card.Button aria-label={`Se detaljer for ${agreementTitle}`} asChild iconOnly variant="secondary">
          <Link to={navigateTo}>
            <Icon name="arrow-right" />
          </Link>
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
