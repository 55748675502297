import { ExpandableCard, Heading, Text } from "@telia/teddy";
import { getSimplePriceText } from "@telia-no-min-side/utils";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { Badge, Ribbon, CardContent, CardExpandedContent, JuniorSummerCampaignTitle } from ".";
import { useSubscriptionData } from "pages/mobile/mobile-subscription-dashboard/modules/SubscriptionCard/hooks/useSubscriptionData";

type Props = {
  subscriptionData: ReturnType<typeof useSubscriptionData>;
};

export function SubscriptionCardB2C({ subscriptionData }: Props) {
  const changeSubscriptionLink = `${INTERNAL_LINK.MOBILE.CHANGE_SUBSCRIPTION_PAGE}/${subscriptionData.accountId}/${subscriptionData.phoneNumber}`;

  const {
    hasFamilyDiscount = false,
    includedProperties = [],
    hasJuniorSummerCampaign = false,
    monthlyPrice = undefined,
    originalMonthlyPrice = undefined,
    subscriptionName = "",
    isViaplaySubscription = false,
    canGetDoubleSpeed = false,
    showDoubleDataOrSpeedInfo = false,
    hasOnGoingOrder = false,
    formattedBaseDataAmount = "",
    formattedTotalDataAmount = "",
    hasDoubleSpeed = false,
    subHeading = "",
  } = subscriptionData;

  return (
    <ExpandableCard type="multiple">
      <ExpandableCard.Item value="temp1">
        <ExpandableCard.Trigger>
          <Badge hasOnGoingOrder={hasOnGoingOrder} hasFamilyDiscount={hasFamilyDiscount} />

          {subHeading && (
            <Text as="p" mb="25" variant="paragraph-100">
              {subHeading}
            </Text>
          )}

          <ExpandableCard.Header>
            <ExpandableCard.Description>
              <Heading as="h4" variant="title-100">
                {hasJuniorSummerCampaign ? (
                  <JuniorSummerCampaignTitle
                    baseDataAmount={formattedBaseDataAmount}
                    totalDataAmount={formattedTotalDataAmount}
                  />
                ) : (
                  subscriptionName
                )}
              </Heading>
              <ExpandableCard.Price>
                {typeof originalMonthlyPrice === "number" && originalMonthlyPrice > (monthlyPrice || 0) && (
                  <Text as="p" mr="100" variant="paragraph-100">
                    <s>{getSimplePriceText(originalMonthlyPrice)}</s>
                  </Text>
                )}
                <Heading as="h4" variant="title-100">
                  {getSimplePriceText(monthlyPrice)}
                </Heading>
                <Text as="p" variant="paragraph-100">
                  /md.
                </Text>
              </ExpandableCard.Price>
            </ExpandableCard.Description>
            <ExpandableCard.Indicator />
          </ExpandableCard.Header>

          <Ribbon
            hasJuniorSummerCampaign={hasJuniorSummerCampaign}
            showDoubleDataOrSpeedInfo={showDoubleDataOrSpeedInfo}
            hasDoubleSpeed={hasDoubleSpeed || canGetDoubleSpeed}
          />

          <ExpandableCard.Line />
          <ExpandableCard.HiddenContainer>
            <CardContent
              isViaplaySubscription={isViaplaySubscription}
              includedProperties={includedProperties}
              changeSubscriptionLink={changeSubscriptionLink}
            />
          </ExpandableCard.HiddenContainer>
        </ExpandableCard.Trigger>

        <CardExpandedContent
          isViaplaySubscription={isViaplaySubscription}
          includedProperties={includedProperties}
          changeSubscriptionLink={changeSubscriptionLink}
        />
      </ExpandableCard.Item>
    </ExpandableCard>
  );
}
