import React, { ReactNode } from "react";
import { Icon } from "@telia/teddy";
import { StyledButton } from "./style";

type Props = {
  id: string;
  ariaControls: string;
  toggleIsOpen: () => void;
  isOpen: boolean;
  children: ReactNode;
  className?: string;
};

export function CollapseButton(props: Props) {
  const { id, ariaControls, isOpen, toggleIsOpen, children, className } = props;
  return (
    <StyledButton
      size="sm"
      id={id}
      aria-controls={ariaControls}
      $isOpen={isOpen}
      className={className}
      variant="text"
      onClick={toggleIsOpen}
    >
      {children}
      <Icon name={"chevron-up"} />
    </StyledButton>
  );
}
