import { colors } from "@telia/styleguide";
import { css } from "styled-components";
import { remCalc } from "../utils/fonts";

export const focusRing = css`
  &:focus-visible {
    outline: ${remCalc(2)} solid ${colors.blue500};
    outline-offset: ${remCalc(2)};
  }
`;
