/*
 * Norwegian numbers like 58 xx xx xx xx xx and  +47 58 xx xx xx xx xx
 */

import dayjs, { Dayjs } from "dayjs";

//
// Date
//
export const SWITCH_API_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_DISPLAY_FORMAT_FULL = "DD.MM.YYYY";
export const DATE_DISPLAY_FORMAT_WITH_SHORT_YEAR = "DD.MM.YY";
export const DATE_DISPLAY_FORMAT_FULL_WITH_TIME = "DD.MM.YYYY HH:mm:ss";
export const DATE_DISPLAY_FORMAT_SHORT = "MMM YYYY";
export const DATE_DISPLAY_FORMAT_MONTH_NAME = "DD. MMMM";

export function formatDate(date: Dayjs | string | number | undefined, formatType: string) {
  const day: Dayjs = dayjs(date);
  return day.format(formatType);
}

//
// Number
//
type formatFunction = (value: string) => string;

function formatNumber(
  num: number,
  decimalPlaces: number,
  prefix: string | formatFunction = "",
  suffix: string | formatFunction = "",
  cutTrailingZeros = false
): string {
  const value = num.toLocaleString("nb-NO", {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  });
  const result = `${typeof prefix === "function" ? prefix(value) : prefix}${
    cutTrailingZeros ? value.replace(/,0+$/g, "") : value
  }${typeof suffix === "function" ? suffix(value) : suffix}`;
  return result;
}

//
// Name
// /^\d+$/.test(characters[index - 1]) ti support address like Buskerudveien 217G
export function startCase(fullName: string): string {
  const characters = fullName.split("");

  return characters.reduce((prev, next, index) => {
    if (
      index === 0 ||
      characters[index - 1] === " " ||
      characters[index - 1] === "-" ||
      /^\d+$/.test(characters[index - 1])
    ) {
      prev += next.toUpperCase();
    } else {
      prev += next.toLowerCase();
    }

    return prev;
  }, "");
}

// eslint-disable-next-line
type ChargePeriod = "md." | "år" | "kvartal" | "halvår" | (string & {});

export function simplePriceText(price: number | undefined | null, chargePeriod?: ChargePeriod): string {
  const priceWithTwoDecimals = (price || 0).toFixed(2);
  const [integer, decimal] = priceWithTwoDecimals.split(/[.,]/);

  if (decimal === "00") {
    return chargePeriod ? `${integer},-/${chargePeriod}` : `${integer},-`;
  }

  const formattedDecimal = decimal[1] === "0" ? decimal[0] : decimal;
  return chargePeriod ? `${integer},${formattedDecimal}/${chargePeriod}` : `${integer},${formattedDecimal},-`;
}
//
// Price
//
export function formatPrice(price: number, decimals = 2, suffix = true): string {
  return formatNumber(
    price,
    price % 1 !== 0 ? decimals : 0,
    "",
    suffix ? (price % 1 === 0 || price === 0 ? ",-" : " kr") : ""
  );
}

//
// Phone number
//
export function validatePhoneNumber(phoneNumber: string): boolean {
  if (phoneNumber.startsWith("+47") && /^\+\d{10}$/.test(phoneNumber)) {
    return true;
  }

  return /^\d{8}$/.test(phoneNumber);
}

export function toLocalPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) return null;

  if (phoneNumber.startsWith("+47") && /^\+\d{10}$/.test(phoneNumber)) {
    return phoneNumber.slice(3);
  }

  return phoneNumber;
}

export function formatPhoneNumber(phoneNumber: string | null | undefined): string {
  if (!phoneNumber || typeof phoneNumber !== "string") {
    return "";
  }

  if (phoneNumber.length === 15 && phoneNumber.startsWith("+47")) {
    const removedPrefix = phoneNumber.slice(3);
    return removedPrefix.replace(/(\d\d\d)(\d\d\d)(\d\d\d)(\d\d\d)/, "$1 $2 $3 $4");
  }

  if (phoneNumber.length === 12 && phoneNumber.startsWith("58")) {
    return phoneNumber.replace(/(\d\d\d)(\d\d\d)(\d\d\d)(\d\d\d)/, "$1 $2 $3 $4");
  }

  if (phoneNumber.length === 11 && phoneNumber.startsWith("+47")) {
    const removedPrefix = phoneNumber.slice(3);
    return removedPrefix.replace(/(\d\d\d)(\d\d)(\d\d\d)/, "$1 $2 $3");
  }

  if (phoneNumber.length === 10 && phoneNumber.startsWith("47")) {
    const removedPrefix = phoneNumber.slice(2);
    return removedPrefix.replace(/(\d\d\d)(\d\d)(\d\d\d)/, "$1 $2 $3");
  }

  if (phoneNumber.length === 8) {
    return phoneNumber.replace(/(\d\d\d)(\d\d)(\d\d\d)/, "$1 $2 $3");
  }

  return phoneNumber;
}

export const formatFullPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber || typeof phoneNumber !== "string") {
    return "";
  }

  if (/^\d{8}$/.test(phoneNumber)) {
    return `+47${phoneNumber}`;
  }

  return phoneNumber;
};

//
// Misc
//
export function removeSpaces(stringWithPossibleSpaces: string): string {
  return stringWithPossibleSpaces.replace(/\s/g, "");
}

//
// Format string to kebab-case
//

export function toKebabCase(text?: string): string {
  if (!text) {
    return "";
  }
  return text
    .replace(/[^A-Za-z0-9 ]/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase();
}

function getDataAsMegaByte(data: DataAmount): number {
  const unit = data.unit as UnitSize;
  if (unit === UnitSize.gigabyte) {
    return (data.amount || 0) * UNIT_SIZE_MULTIPLIER;
  }
  return data.amount || 0;
}

function sumDataAsMegaByte(amountA: DataAmount, amountB: DataAmount): DataAmount {
  return {
    amount: getDataAsMegaByte(amountA) + getDataAsMegaByte(amountB),
    unit: UnitSize.megabyte,
  };
}

function getDataAsGigaByte(data: DataAmount): number {
  const unit = data.unit as UnitSize;
  if (unit === UnitSize.megabyte) {
    return (data.amount || 0) / UNIT_SIZE_MULTIPLIER;
  }
  return data.amount || 0;
}

function getDisplayData(data: DataAmount): string {
  if (!data.amount) {
    return `0 ${data.unit}`;
  }
  if (data.amount >= UNIT_SIZE_MULTIPLIER) {
    return `${getDataAsGigaByte(data)} ${UnitSize.gigabyte}`;
  }
  return `${data.amount} ${data.unit}`;
}

type DataAmount = {
  amount: number | undefined;
  unit: string | undefined;
};

enum UnitSize {
  byte = "B",
  kilobyte = "KB",
  megabyte = "MB",
  megabyteLong = "MEGABYTE",
  gigabyte = "GB",
  terabyte = "TB",
}

const UNIT_SIZE_MULTIPLIER = 1024;

export function getTotalData(subAmount: DataAmount, promoAmount: DataAmount): string {
  const totalDataMB = sumDataAsMegaByte(subAmount, promoAmount);
  return getDisplayData(totalDataMB);
}

export function getFullName(user?: { firstName?: string; surname?: string } | null): string {
  if (!user) return "";
  return startCase([user?.firstName, user?.surname].filter(Boolean).join(" "));
}

export function roundPrice(floatNumber: number) {
  return parseFloat((Math.round(floatNumber * 100) / 100).toFixed(2));
}

export const firstLetterUpperCase = (text: string) => {
  return text.length === 0
    ? ""
    : text.length === 1
    ? text.toLocaleUpperCase()
    : text[0].toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();
};

export const isMobilePhoneNumber = (phoneNumber: string) => phoneNumber.length === 8;

export default {
  formatDate,
  startCase,
  formatPrice,
  validatePhoneNumber,
  formatPhoneNumber,
  removeSpaces,
  toKebabCase,
  getTotalData,
  getFullName,
  roundPrice,
  firstLetterUpperCase,
  isMobilePhoneNumber,
};
