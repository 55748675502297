import { useMemo } from "react";
import { Accordion, Button } from "@telia-no-min-side/components";
import { Fields, getInitialState } from "../utils/getInitialState";
import { HeaderInfo } from "./InvoiceCardHeader";
import { EFakturaText, EInvoiceUndertext } from "./styles/EInvoice.styled";
import { ButtonContainer, StyledAccordionItem } from "./styles/common-styles";
import { AccountDetailsQuery, DistributionCode, InvoiceDistribution, InvoiceType } from "src/gql/graphql";
import { DELIVERY_OPTION, DELIVERY_TYPE } from "util/constants/address";

type EInvoiceProps = {
  invoiceType?: InvoiceType;
  account: AccountDetailsQuery["account"];
  onSubmit: (
    formData: Fields,
    emailAddress: string,
    distributionType: DELIVERY_TYPE,
    invoiceType: DistributionCode,
    eInvoiceId?: string
  ) => void;
  hasAvtalegiro: boolean;
};

export const EFaktura = ({ invoiceType, account, hasAvtalegiro, onSubmit }: EInvoiceProps) => {
  const { selectedInvoiceDistributionType, invoiceReceiver } = account;
  const productUser = invoiceReceiver!.individual!;
  const eInvoiceId = invoiceType?.additionalProperties?.find((p) => p.key === "eInvoiceIdentifier")?.value ?? "";

  const selected = selectedInvoiceDistributionType === InvoiceDistribution.EFaktura;

  const hasPoBoxAdress = !!productUser.address?.poboxAddress;

  const initialState = useMemo(() => getInitialState(hasPoBoxAdress, productUser), [hasPoBoxAdress, account]);

  const distributionType = hasPoBoxAdress ? DELIVERY_OPTION.PO_BOX_ADDRESS : DELIVERY_OPTION.URBAN_ADDRESS;

  const handleOnSubmit = () => {
    onSubmit(initialState, productUser.emailAddress!, distributionType, DistributionCode.Ef, eInvoiceId);
  };

  return (
    <Accordion>
      <StyledAccordionItem selected={selected}>
        <Accordion.Trigger>
          <HeaderInfo invoiceType={invoiceType} selected={selected} hasAvtalegiro={hasAvtalegiro} />
        </Accordion.Trigger>
        {!selected && (
          <Accordion.Content>
            <div>
              {eInvoiceId ? (
                <EFakturaText>eFaktura betyr at du får din faktura direkte i nettbanken din.</EFakturaText>
              ) : (
                <EFakturaText>
                  For å få eFaktura må du gi en generell aksept for dette i nettbanken eller betalingsappen din. Dette
                  blir kalt «Ja takk til alle», «Automatisk eFaktura», «Alltid eFaktura» eller «eFaktura fra alle»,
                  avhengig av hvilken bank eller betalingsapp du bruker. Når dette er aktivert, vil du automatisk få
                  eFaktura på fremtidige faktura.
                </EFakturaText>
              )}
              <ButtonContainer>
                <Button disabled={!eInvoiceId} onClick={handleOnSubmit}>
                  Opprett eFaktura
                </Button>
              </ButtonContainer>
            </div>
          </Accordion.Content>
        )}
      </StyledAccordionItem>
      {selected && (
        <EInvoiceUndertext>
          Du har eFaktura, som betyr at du får din faktura direkte i nettbanken din. Ønsker du å avslutte din eFaktura,
          må du gjøre dette selv i nettbanken.
        </EInvoiceUndertext>
      )}
    </Accordion>
  );
};
