import { graphql } from "src/gql";

export const GET_INSURANCE_PROOF_DATA = graphql(`
  query getInsuranceAgreementsProof($phoneNumber: String!, $contractId: String!, $accountId: String) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      insuranceAgreements {
        name
        productTerms {
          value
          name
        }
      }
      insuranceProofDocument(contractId: $contractId, accountId: $accountId) {
        document
      }
    }
  }
`);
