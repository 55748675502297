import { uri } from "@telia-no-min-side/utils";
import { Alert, Button, Container } from "@telia-no-min-side/components";

type Props = {
  errorMessage: string;
  loading: boolean;
  onRetry: () => void;
};

export function SuspendError({ errorMessage, loading, onRetry }: Props) {
  return (
    <Container flexDirection="column" padding="bottom" gap="lg">
      <Alert kind="negative" isOpen icon="info" title="En feil oppsto" text={errorMessage} canClose={false} />
      <Container gap="lg">
        <Button variant="secondary" isLoading={loading} onClick={onRetry}>
          Prøv igjen
        </Button>
        <Button variant="primary" tag="a" href={uri.minSideFixed("/mobil")} isInternalNavigation>
          Tilbake til Mobil
        </Button>
      </Container>
    </Container>
  );
}
