import { isStageDomain } from "../utils/locationHelper";
import type { AuthenticationAdapter, AuthenticationAdapterOptions } from "./auth";

export function createCTAdapter({ forceProd }: Pick<AuthenticationAdapterOptions, "forceProd">): AuthenticationAdapter {
  function requestSignOut() {
    const isStage = isStageDomain(forceProd);
    window.location.replace(`//${isStage ? "stage." : "www."}telia.no/logout`);
  }

  return {
    requestSignOut,
  };
}
