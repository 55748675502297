import { Button, Card, Container, Heading, Icon, Paragraph } from "@telia-no-min-side/components";

type Props = {
  handleClick: () => void;
};

export const DataSimCard = ({ handleClick }: Props) => {
  return (
    <Card padding="lg" data-tracking-id="extra-data-sim-card-block">
      <Container flexDirection="column" gap>
        <Heading removeMargin tag="h3" variant="title-100">
          DataSIM
        </Heading>

        <Paragraph removeMargin>
          Det du trenger hvis du ønsker å bruke mobildata fra ditt nåværende abonnement på flere enheter. Gjelder for:
        </Paragraph>

        <Container flexDirection="column" gap="sm">
          <Container gap alignItems="center">
            <Icon size="sm" icon="check-mark" />

            <Paragraph>Nettbrett</Paragraph>
          </Container>

          <Container gap alignItems="center">
            <Icon size="sm" icon="check-mark" />

            <Paragraph>PC</Paragraph>
          </Container>

          <Container gap alignItems="center">
            <Icon size="sm" icon="check-mark" />

            <Paragraph>Mobilt bredbånd (egen router)</Paragraph>
          </Container>
        </Container>

        <Button size="sm" alignSelf="flex-end" variant="secondary" onClick={handleClick}>
          Velg DataSIM
        </Button>
      </Container>
    </Card>
  );
};
