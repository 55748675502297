import { Button } from "@telia-no-min-side/components";
import { SwitchAgreementFieldsFragment } from "gql/graphql";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = {
  agreement: SwitchAgreementFieldsFragment;
  accountId: string;
  canSwitchWithPayment: boolean;
};

export function SwitchButton({ agreement, canSwitchWithPayment, accountId }: Props) {
  const { id, costToReplace } = agreement;

  function getSwitchButtonText() {
    if (canSwitchWithPayment) {
      return `Svitsj nå for ${costToReplace.value},-`;
    }
    return "SVITSJ telefon";
  }

  return (
    <Button
      trackEvent={{
        ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
        ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SWITCH,
        ui_item_text: getSwitchButtonText(),
        ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
        ui_item_url: "/minside/mobil/avtaler/svitsj/evaluering/accountId/id",
      }}
      whiteSpace="nowrap"
      tag="a"
      href={`/minside/mobil/avtaler/svitsj/evaluering/${accountId}/${id}`}
      variant="primary"
      isInternalNavigation
    >
      {getSwitchButtonText()}
    </Button>
  );
}
