import { useMutation } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { Container } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { Button, Card, Text, TextField, TextSpacing } from "@telia/teddy";
import { ANSWER_CHALLENGE, SEND_CHALLENGE } from "hooks/useChallenge";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SELF_VERIFY_TEMP_ACCESS } from "pages/temp-access/graphql/verifyUser";
import { subscriptionOverviewQuery } from "hooks/useMobileAccount";

type Props = {
  error: string[];
  isLoading?: boolean;
  phoneNumber: string;
  onResetPhone: () => void;
};

export function GetTempAccess({ error, phoneNumber, onResetPhone }: Props) {
  const [isSubmittingOTP, setIsSubmittingOTP] = useState(false);
  const [challengeId, setChallengeId] = useState<string | null>(null);
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [sendChallenge] = useMutation(SEND_CHALLENGE, {
    onCompleted(data) {
      setChallengeId(data?.sendChallenge?.id || null);
      setIsSubmittingOTP(false);
    },
    onError(err) {
      datadogRum.addError(err);
      setIsSubmittingOTP(false);
    },
  });

  const [sendAnswer] = useMutation(ANSWER_CHALLENGE, {
    onCompleted() {
      selfVerifyTemporaryAccess({
        variables: {
          challengeId: challengeId || "",
          verifyPhoneNumber: phoneNumber || "",
        },
      });
    },
    onError(err) {
      datadogRum.addError(err);
      setLoading(false);
    },
  });

  const [selfVerifyTemporaryAccess] = useMutation(SELF_VERIFY_TEMP_ACCESS, {
    refetchQueries: [
      {
        query: subscriptionOverviewQuery,
      },
    ],
    onCompleted() {
      navigate(uri.minSideFixed("/midlertidig-tilgang/suksess"));
      setLoading(false);
    },
    onError(err) {
      if (err.message.includes("NO_MATCHING_USERINFO")) {
        navigate(uri.minSideFixed("/midlertidig-tilgang/igen-treff"));
      } else {
        datadogRum.addError(err);
        navigate(uri.minSideFixed("/midlertidig-tilgang/feil"));
      }
      setLoading(false);
    },
  });

  const handleResendOTP = async () => {
    setIsSubmittingOTP(true);

    await sendChallenge({
      variables: {
        destination: `+47${phoneNumber}`,
        channel: "SMS",
      },
    });
  };

  const handleAnswerChallenge = async () => {
    setLoading(true);
    await sendAnswer({
      variables: {
        challengeId,
        challengeAnswer: answer,
      },
    });
  };

  useEffect(() => {
    sendChallenge({
      variables: {
        destination: `+47${phoneNumber}`,
        channel: "SMS",
      },
    });
  }, [phoneNumber]);

  return (
    <Card shadow>
      <TextSpacing>
        <Card.Heading>Steg 2</Card.Heading>
        <Card.Content>
          <Container flexDirection="column" gap alignItems="start">
            <Text>Skriv inn koden du fikk tilsendt her.</Text>
            <TextField isRequired errors={error} isValid={false} disabled={loading}>
              <TextField.Label>Engangskode</TextField.Label>
              <TextField.Input placeholder="Skriv inn her" value={answer} onValueChange={(e) => setAnswer(e)} />
              <TextField.ErrorList />
            </TextField>
            <Button variant="primary" onClick={handleAnswerChallenge} loading={loading}>
              Fortsett
            </Button>

            <TextSpacing>
              <Text>Engangskode ble sendt til {phoneNumber}</Text>
              <Container flexDirection="column" gap>
                <Button variant="secondary" onClick={handleResendOTP} disabled={isSubmittingOTP || loading}>
                  Send kode på nytt
                </Button>
                <Button variant="secondary" onClick={onResetPhone} disabled={loading}>
                  Velg et annet nummer
                </Button>
              </Container>
            </TextSpacing>
          </Container>
        </Card.Content>
      </TextSpacing>
    </Card>
  );
}
