import { Flex, Box } from "@telia/teddy";
import { Devices } from "./Devices";
import { useFetchNetworkStatistics } from "src/api/fixed";

export function ConnectedDevices() {
  const { networkStatistics } = useFetchNetworkStatistics();
  const networkStatisticsResult = networkStatistics?.result;
  const LifeMoteApiData = typeof networkStatisticsResult !== "string" && networkStatisticsResult?.LifeMoteApiData;
  const connectedDevices = LifeMoteApiData && LifeMoteApiData?.Clients;
  const modem = LifeMoteApiData && LifeMoteApiData?.Devices[0];
  const WifiDevices5GHz =
    connectedDevices &&
    connectedDevices.filter((device) => device.IsConnected && device?.Last_Connected_Interface === "WIFI_5");
  const WifiDevices24GHz =
    connectedDevices &&
    connectedDevices.filter((device) => device.IsConnected && device?.Last_Connected_Interface === "WIFI_24");
  const EthernetPortsWithDevices = LifeMoteApiData && LifeMoteApiData?.Ethernet_Ports;

  return (
    <Flex wrap="wrap" gap="2%" justify="space-between">
      {WifiDevices24GHz && modem && modem?.Ssid_24 && (
        <Box width={{ sm: "100%", lg: "49%" }}>
          <Devices
            title="2.4 GHz"
            subText={modem.Ssid_24}
            devices={WifiDevices24GHz}
            connectedDurationKey="Connected_24_Minutes"
          />
        </Box>
      )}
      {WifiDevices5GHz && modem && modem?.Ssid_5 && (
        <Box width={{ sm: "100%", lg: "49%" }}>
          <Devices
            title="5 GHz"
            subText={modem?.Ssid_5}
            devices={WifiDevices5GHz}
            connectedDurationKey="Connected_5_Minutes"
          />
        </Box>
      )}
      {EthernetPortsWithDevices &&
        EthernetPortsWithDevices?.length > 0 &&
        EthernetPortsWithDevices?.map((ethernetPort, i) => {
          const ethernetLanPort = ethernetPort?.LanPort ?? "";
          const connectedEthernetDevices = ethernetPort?.Clients.filter((device) => device?.IsConnected) ?? [""];
          return (
            <Box key={`wired-list-${ethernetLanPort}-${i}`} width={{ sm: "100%", lg: "49%" }}>
              <Devices
                title={`Ethernet / Kabel (${ethernetLanPort})`}
                devices={connectedEthernetDevices}
                connectedDurationKey="Connected_Minutes"
              />
            </Box>
          );
        })}
    </Flex>
  );
}
