import { useEffect } from "react";
import { useMobileAccount } from "hooks/useMobileAccount";
import { ProfilePicker, Container } from "@telia-no-min-side/components";
import { getSubscriptionByAccountId, mapAccountsToProfile, mapSubscriptionToProfile } from "util/mobile/accounts";
import { NoSubscriptionsFound } from "./components/NoSubscriptionsFound";

export function MobileProfilePicker() {
  const { allAccounts, error, refetch, isLoading, isRefetching, phoneNumber, accountId, setPhoneNumber, setAccountId } =
    useMobileAccount();
  const selectedAccountSubscriptions = getSubscriptionByAccountId(allAccounts, accountId);
  const accountProfiles = allAccounts?.map((account) => mapAccountsToProfile(account)) || [];
  const subscriptionProfiles =
    selectedAccountSubscriptions?.map((subscription) => mapSubscriptionToProfile(subscription)) || [];
  const showRetry = !(isLoading || isRefetching) && !!error;

  const onRetry = () => {
    if (error) {
      refetch();
    }
  };

  // On accountId change check if existing phoneNumber is part of subscriptions under selected account
  // if not reset to first phoneNumber under the selected account
  useEffect(() => {
    const selectedSubscription = selectedAccountSubscriptions.find(
      (subscription) => subscription?.phoneNumber.localNumber === phoneNumber
    );

    if (!selectedSubscription) {
      setAccountId(selectedAccountSubscriptions[0]?.account.id || "");
      setPhoneNumber(selectedAccountSubscriptions[0]?.phoneNumber.localNumber || "");
    }
  }, [accountId]);

  return (
    <>
      {
        // List all accounts
        <>
          {accountProfiles.length > 0 && (
            <Container padding="vertical">
              {accountProfiles.length === 1 ? "Ditt kundenummer" : "Velg kundenummer"}:
            </Container>
          )}
          <ProfilePicker
            profiles={accountProfiles}
            selectedProfile={accountId}
            loading={isLoading || isRefetching}
            variant="primary"
            onChange={setAccountId}
            showRetry={showRetry}
            onRetry={onRetry}
            containerProps={{ gap: true }}
            noProfilesPlaceholder={<NoSubscriptionsFound isMobile padding="vertical" />}
          />
        </>
      }
      {
        // List all subcriptions under selected account, if there are more than 1 subcription under account
        subscriptionProfiles.length !== 1 && accountProfiles.length > 0 && (
          <>
            {subscriptionProfiles.length > 0 && <Container padding="vertical">Velg abonnement:</Container>}
            <ProfilePicker
              profiles={subscriptionProfiles}
              selectedProfile={phoneNumber}
              loading={isLoading || isRefetching}
              variant="primary"
              onChange={setPhoneNumber}
              containerProps={{ gap: true }}
              noProfilesPlaceholder={<NoSubscriptionsFound isMobile padding="vertical" />}
            />
          </>
        )
      }
    </>
  );
}
