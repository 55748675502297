import React from "react";
import { Skeleton } from "../Skeleton";
import { SkeletonText, SkeletonItem, SkeletonButton } from "./style";

export function SkeletonListItem() {
  return (
    <SkeletonItem>
      <SkeletonText>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="75%" />
      </SkeletonText>
      <SkeletonButton variant="button" width="10ch" />
    </SkeletonItem>
  );
}
