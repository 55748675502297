import { SubCurrentInvoiceQueryQuery } from "gql/graphql";

export const getExtraSpendingsValue = (currentInvoice?: SubCurrentInvoiceQueryQuery | null) => {
  if (!currentInvoice) return 0;

  const extrasInvoiceGroup = currentInvoice.subscription.currentInvoice?.invoiceGroup?.find(
    (group) => group?.name === "Bruk"
  );

  if (!extrasInvoiceGroup?.totalAmount?.value) return 0;

  return extrasInvoiceGroup.totalAmount.value;
};
