import { useState } from "react";
import { Card, Box, Skeleton, Flex, Badge, Image } from "@telia/teddy";
import { ActionMenu } from "components/action-menu";
import { getDeviceProducts } from "util/productHelper";
import { FixedDevicePrice } from "./FixedDevicePrice";

type Props = {
  product?: NonNullable<ReturnType<typeof getDeviceProducts>>[0];
  isLoading: boolean;
};

export function FixedDeviceCard({ product, isLoading }: Props) {
  const [showActions, setShowActions] = useState(false);

  return (
    <Card layout="product" shadow bordered variant="white">
      {product?.badge ? (
        <Card.Slot>
          <Badge hideIcon variant={product.badge.status}>
            {product.badge.label}
          </Badge>
        </Card.Slot>
      ) : null}
      <Flex gap="400">
        <Skeleton loading={isLoading} width="150px" height="100px" radius="none">
          {product?.src ? (
            <Card.Illustration style={{ height: "auto" }}>
              <Image maxWidth="100px" maxHeight="100px" src={product.src} alt={product.alt} />
            </Card.Illustration>
          ) : null}
        </Skeleton>
        <Skeleton loading={isLoading} width="100%" height="20px" radius="lg">
          <Box>
            <Card.Heading variant="title-200">{product?.text}</Card.Heading>
          </Box>
        </Skeleton>
      </Flex>
      <Card.Content width="100%">
        <Flex justify="between" align="end">
          <Skeleton loading={isLoading} radius="lg" width="70%" height="20px" mt="200">
            <FixedDevicePrice
              originalPrice={product?.listPrice}
              currentPrice={product?.price}
              secondaryText={product?.secondaryText}
            />
          </Skeleton>
          <Skeleton loading={isLoading} width="50px" height="50px" radius="full" mt="200">
            {(product?.actionList || []).length > 0 ? (
              <ActionMenu
                showActions={showActions}
                toggleShowActions={() => setShowActions(!showActions)}
                actionList={product?.actionList}
              />
            ) : null}
          </Skeleton>
        </Flex>
      </Card.Content>
    </Card>
  );
}
