import { Heading, Text, Button, Icon } from "@telia/teddy";
import { Container, TrackClickEvent } from "@telia-no-min-side/components";
import { Link } from "react-router-dom";
import { L } from "util/links";
import { URL_INSURANCE_CLAIM_DAMAGE } from "util/constants/agreements";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function LostPhonePlusInsurance() {
  return (
    <>
      <Heading as="h4" variant="title-100">
        Meld en forsikringssak
      </Heading>
      <Text as="p">
        Forsikringen din dekker stjålet eller mistet telefon. Meld en forsikringssak på linken under før du velger en ny
        telefon. Det kan ta opptil 24 timer før saken er behandlet.
      </Text>
      <Container alignItems="start" flexDirection="column" gap>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_lost-phone-plus-insurance",
            ui_item_text: "Meld forsikringssak",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: L.TeliaNoUrl.href + URL_INSURANCE_CLAIM_DAMAGE,
          }}
        >
          <Button asChild variant="primary">
            <a href={L.TeliaNoUrl.href + URL_INSURANCE_CLAIM_DAMAGE}>
              Meld forsikringssak
              <Icon name="chevron-right" />
            </a>
          </Button>
        </TrackClickEvent>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_CANCEL,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_lost-phone-plus-insurance",
            ui_item_text: "Tilbake til forrige steg",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: "..",
          }}
        >
          <Button asChild variant="text">
            <Link to="..">
              <Icon name="arrow-left" />
              Tilbake til forrige steg
            </Link>
          </Button>
        </TrackClickEvent>
      </Container>
    </>
  );
}
