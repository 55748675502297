import { ReactNode } from "react";
import { Paragraph } from "@telia-no-min-side/components";
import { track } from "@telia-no-min-side/utils";
import { InfoModal } from "./info-modal";
import {
  ButtonTextWrapper,
  ContentSpacing,
  HideOnDesktop,
  InfoText,
  RecoveryMethodButton,
  RecoveryMethodButtonWrapper,
} from "./style";
import { RecoveryPageState, useSelfRecoveryState } from ".";

type RecoveryMethodProps = {
  infoText: string;
  text: string;
  methodId: RecoveryPageState;
  infoModal?: ReactNode;
};

function RecoveryMethod({ infoText, text, methodId, infoModal }: RecoveryMethodProps) {
  const { setRecoveryPage } = useSelfRecoveryState();
  const { pushGenericTrackingEvent } = track.useEventTracking();

  return (
    <ButtonTextWrapper>
      <RecoveryMethodButton
        aria-describedby={methodId}
        onClick={() => {
          pushGenericTrackingEvent({
            ui_item_action: "next_click",
            ui_item_context: "self-recovery",
            ui_item_text: text,
            ui_item_type: "button",
            ui_item_url: "no_url",
          });
          setRecoveryPage(methodId);
        }}
      >
        {text}
      </RecoveryMethodButton>
      <div>
        <HideOnDesktop>{infoModal}</HideOnDesktop>
        <InfoText id={methodId}>
          {infoText} {infoModal}
        </InfoText>
      </div>
    </ButtonTextWrapper>
  );
}

export function ChooseRecoveryMethod(): JSX.Element {
  return (
    <ContentSpacing>
      <Paragraph>Vennligst oppgi ett av følgende numre:</Paragraph>
      <RecoveryMethodButtonWrapper>
        <RecoveryMethod
          methodId="invoiceNumber"
          text="Fakturanummer"
          infoText="Fakturanummeret kan du se øverst på din faktura for TV/Internett i din nettbank."
          infoModal={<InfoModal content="invoiceNumber" />}
        />
        <RecoveryMethod
          methodId="customerNumber"
          text="Kundenummer"
          infoText="Kundenummeret kan du se på din faktura for TV/Internett i din nettbank eller på e-post."
          infoModal={<InfoModal content="customerNumber" />}
        />
        <RecoveryMethod
          methodId="serialNumber"
          text="Serienummer på utstyr"
          infoText="Serienummeret finner du på en klistrelapp på boksen din. Hvilket nummer du skal legge inn, varierer utfra hvilken du har."
          infoModal={<InfoModal modalActionText="Se informasjon om din boks:" content="serialNumber" />}
        />
      </RecoveryMethodButtonWrapper>
    </ContentSpacing>
  );
}
