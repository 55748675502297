import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { getCustomerSpecificUrl, GetCustomerSpecificUrlArgs, ReturnData } from "./utils";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchTvSubscription<T extends GetCustomerSpecificUrlArgs | undefined = undefined>(args?: T) {
  const { error, ...rest } = useAxiosSWR<ReturnData<T, types.fixed.ProductCategory>>(
    getCustomerSpecificUrl(ICX_REQUEST.URL_TV_SUBSCRIPTION, args)
  );

  return {
    isError: !!error,
    error,
    ...rest,
  };
}
