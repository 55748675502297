import { Alert, Button, Container } from "@telia-no-min-side/components";

type Props = {
  refetch: () => void;
};

export function AgreementError({ refetch }: Props) {
  return (
    <Container maxWidth="xs">
      <Alert
        kind="warning"
        isOpen
        icon="info-filled"
        title="En feil oppsto"
        text="Vi klarte ikke å hente dine avtaler. Vennligst prøv igjen litt senere."
        dataTrackingId="alert-user-agreements-error"
        canClose={false}
      >
        <Container padding="top">
          <Button variant="secondary" icon="sync" onClick={() => refetch()}>
            Prøv igjen
          </Button>
        </Container>
      </Alert>
    </Container>
  );
}
