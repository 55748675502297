import { Badge, Card, Icon, Text } from "@telia/teddy";
import { Link as RouterLink } from "react-router-dom";
import { format, uri } from "@telia-no-min-side/utils";
import { useCompensation } from "./useCompensation";
import { useSelectAccount } from "hooks/useSelectAccount";
import { TrackClickEvent } from "@telia-no-min-side/components";

export function TvCompensationCards() {
  const { shouldOnlySeePointsCard, shouldSeeSwitchCard } = useCompensation();

  return (
    <>
      {shouldOnlySeePointsCard?.map(({ customerId, address }) => (
        <CompensationCard key={customerId} address={address} customerId={customerId}>
          Som kompensasjon for at innholdet til TV 2 Play for øyeblikket ikke er tilgjengelig, har du fått 50 TV-poeng
          ekstra.
        </CompensationCard>
      ))}
      {shouldSeeSwitchCard?.map(({ customerId, address }) => (
        <CompensationCard key={customerId} address={address} customerId={customerId}>
          Dersom du ikke trenger ekstra TV-poeng, tilbyr vi et avslag på 109,-/md.
        </CompensationCard>
      ))}
    </>
  );
}

function CompensationCard({
  address,
  customerId,
  children,
}: {
  address: string;
  customerId: string;
  children: string;
}) {
  const { setAccount } = useSelectAccount();
  return (
    <Card shadow bordered variant="white" layout="navigation-vertical" width="100%" maxWidth="400px">
      <Card.Heading as="h3" variant="title-100">
        Du har fått 50 TV-poeng ekstra!
      </Card.Heading>
      <Card.Slot>
        <Badge variant="special">
          <Icon name="heart">Til deg</Icon>
        </Badge>
      </Card.Slot>
      <Card.Content>
        <Text>{children}</Text>
        <Text mt="100">Gjelder for {format.startCase(address)}</Text>
      </Card.Content>
      <TrackClickEvent
        event={{
          ui_item_action: "click",
          ui_item_type: "button",
          ui_item_text: children,
          ui_item_url: uri.minSideFixed(`/tv/kompensasjon/customerId`),
          ui_item_context: "tv-compensation",
        }}
        asChild
      >
        <Card.Button iconOnly aria-label="Se tilbud" asChild size="sm" variant="tertiary-purple">
          <RouterLink
            onClick={() => setAccount({ accountType: "FIXED", id: customerId })}
            to={uri.minSideFixed(`/tv/kompensasjon/${customerId}`)}
          >
            <Icon name="chevron-right" />
          </RouterLink>
        </Card.Button>
      </TrackClickEvent>
    </Card>
  );
}
