import { graphql } from "src/gql";

export const INSURANCE_AGREEMENT_FRAGMENT = graphql(`
  fragment InsuranceAgreementFields on InsuranceAgreement {
    effectiveDate
    product
    presentation {
      modifiable
      active
    }
    productLevel
    serviceAgreementId
    productTerms {
      name
      value
    }
    price {
      value
    }
    longDescription
    terms
    shortName
  }
`);
