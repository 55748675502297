import { BaseSyntheticEvent, ChangeEvent, useMemo, useState } from "react";
import { Accordion } from "@telia-no-min-side/components";
import { isValidEmailAddress } from "@telia-no-min-side/utils";
import { Fields, getInitialState } from "../utils/getInitialState";
import { AccordionButtons } from "./AccordionButtons";
import { HeaderInfo } from "./InvoiceCardHeader";
import { InputHeadline, StyledAccordionItem, StyledForm, StyledTextInput } from "./styles/common-styles";
import { AccountDetailsQuery, DistributionCode, InvoiceDistribution, InvoiceType } from "src/gql/graphql";
import { ADDRESS_MESSAGE, DELIVERY_OPTION, DELIVERY_TYPE } from "util/constants/address";

type EmailInvoiceProps = {
  account: AccountDetailsQuery["account"];
  invoiceType?: InvoiceType;
  onSubmit: (
    formData: Fields,
    emailAddress: string,
    distributionType: DELIVERY_TYPE,
    invoiceType: DistributionCode
  ) => void;
  hasAvtalegiro: boolean;
};

export const EmailInvoice = ({ account, invoiceType, hasAvtalegiro, onSubmit }: EmailInvoiceProps) => {
  const selected = account.selectedInvoiceDistributionType === InvoiceDistribution.Email;
  const user = account.invoiceReceiver!.individual!;
  const { emailAddress = "" } = user;
  const [formData, setFormData] = useState(emailAddress || "");

  const hasPoBoxAdress = !!user.address?.poboxAddress;

  const initialState = useMemo(() => getInitialState(hasPoBoxAdress, user), [hasPoBoxAdress, account]);

  const distributionType = hasPoBoxAdress ? DELIVERY_OPTION.PO_BOX_ADDRESS : DELIVERY_OPTION.URBAN_ADDRESS;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormData(e.target.value);
  };

  const handleOnSubmit = (form: Fields, emailAddress: string | null | undefined) => {
    onSubmit(form, emailAddress || "", distributionType, DistributionCode.Ee);
  };

  const handleOnCancel = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setFormData(emailAddress ?? "");
  };

  const emailError = (formData: string): string => {
    if (!formData) {
      return ADDRESS_MESSAGE.VALID_EMAIL_REQUIRED;
    }

    if (!isValidEmailAddress(formData)) {
      return ADDRESS_MESSAGE.INVALID_EMAIL;
    }

    return "";
  };

  const disabled = !formData || !isValidEmailAddress(formData);

  return (
    <Accordion>
      <StyledAccordionItem selected={selected}>
        <Accordion.Trigger>
          <HeaderInfo
            hasAvtalegiro={hasAvtalegiro}
            invoiceType={invoiceType}
            emailAddress={emailAddress}
            selected={selected}
          />
        </Accordion.Trigger>
        <Accordion.Content>
          <StyledForm
            onSubmit={(e) => {
              e.preventDefault();
              handleOnSubmit(initialState, formData);
            }}
          >
            <StyledTextInput
              key="email"
              type="email"
              value={formData || ""}
              errorMessage={emailError(formData)}
              onChange={handleOnChange}
            >
              <InputHeadline>Oppgi hvilken e-post du ønsker å motta din faktura på:</InputHeadline>
            </StyledTextInput>
            <AccordionButtons
              emailAddress={formData}
              form={initialState}
              disabled={disabled}
              handleOnSubmit={handleOnSubmit}
              handleOnCancel={handleOnCancel}
            />
          </StyledForm>
        </Accordion.Content>
      </StyledAccordionItem>
    </Accordion>
  );
};
