import React, { Fragment, PropsWithChildren, ReactElement, Children, isValidElement, cloneElement } from "react";
import { ToggleButton, ToggleButtonOnChange, ToggleButtonProps } from "../ToggleButton";

ToggleButtonGroup.Item = ToggleButton;

export type ToggleButtonGroupProps<T> = {
  /**
   * The value you of the selected ToggleButton
   */
  value?: T;
  /**
   * Callback function fired when the value changes.
   *
   * @param {React.BaseSyntheticEvent} event
   * @param {T} newValue
   */
  onChange?: ToggleButtonOnChange<T>;

  /**
   * Remove left and right margin
   */
  removeMargin?: boolean;

  /**
   * Children of type ToggleButton
   */
  children: React.ReactNode;
} & Pick<ToggleButtonProps<T>, "variant">;

function isChildSelected(childValue: unknown, value: unknown): boolean {
  return childValue === value;
}

/**
 * @deprecated Use Teddy components instead
 */
export function ToggleButtonGroup<T>(props: PropsWithChildren<ToggleButtonGroupProps<T>>) {
  const { value, onChange, variant, removeMargin = false, children } = props;

  const numberOfChild = Children.count(children);

  return (
    <Fragment>
      {Children.map(children, (child, i) => {
        if (!isValidElement(child)) {
          return null;
        }
        const toggleButton = child as ReactElement<PropsWithChildren<ToggleButtonProps<T>>>;

        if (toggleButton.type !== ToggleButton) {
          return child;
        }
        return cloneElement(toggleButton, {
          onChange: onChange,
          variant,
          removeLeftMargin: i === 0 ? removeMargin : false,
          removeRightMargin: i === numberOfChild - 1 ? removeMargin : false,
          isSelected:
            toggleButton.props.isSelected === undefined
              ? isChildSelected(toggleButton.props.value, value)
              : toggleButton.props.isSelected,
        });
      })}
    </Fragment>
  );
}
