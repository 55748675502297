import { graphql } from "src/gql";

export const GET_ORDER_SECTION_DATA = graphql(`
  query getOrderSectionData($phoneNumber: String, $filter: TopupFilter) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }

      billingType

      additionalProducts {
        id
        code
        presentation {
          active
        }
      }

      usageTopup {
        topupQuotas {
          unit
          type
          expireTime
          category
          service
          allowanceType
          usage {
            total {
              amount
              unit
              normalised {
                amount
                unit
              }
            }
            remaining {
              amount
              unit
              normalised {
                amount
                unit
              }
            }
          }
          product {
            isNordicAndBalticMinutes
            topupPackageType
            size {
              unit
              amount
            }
          }
        }
      }

      topups(filter: $filter) {
        id
        name
        hiddenInGeneralTopupList
        type
        category

        ... on FreeDataBoostTopup {
          numberOfVouchers
        }
        ... on FreeDataTopup {
          numberOfVouchers
        }
      }
    }
  }
`);
