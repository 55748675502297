import { useQuery } from "@apollo/client";
import { config } from "util/config";
import { AgreementType, UserRole } from "gql/graphql";
import { GET_EXPLORE_SECTION_DATA } from "../graphql";
import { useMobileAccount } from "hooks/useMobileAccount";
import { getUserOfferingWithAnnualDiscount } from "util/mobile/offer";
import { formatBytesToGB } from "../../DataUsageCard/utils";

export function useExploreSection() {
  const { allAccounts, accountId, phoneNumber } = useMobileAccount();

  const { loading, data, error, refetch } = useQuery(GET_EXPLORE_SECTION_DATA, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
    skip: !phoneNumber,
  });

  const subscription = data?.subscription;

  const showAccountOverviewCard =
    (allAccounts?.find((account) => account.id === accountId)?.subscriptions?.length ?? 0) > 1;

  const showAnnualDiscountCard =
    config.showAnnualDiscount &&
    !!subscription &&
    !subscription.commitmentEndDate &&
    !!getUserOfferingWithAnnualDiscount(
      subscription.userOffering?.shortName || "",
      subscription.newOfferingDetails?.offerings
    ) &&
    subscription.agreementType === AgreementType.Private &&
    subscription.roles.includes(UserRole.LegalOwner);

  const isEligibleToShareData = subscription?.dataSharingQualification?.eligibleForDataSharing;
  const dataUsage = subscription?.usage?.filter((quota) => quota?.category?.startsWith("DATA"));
  const rolloverData = dataUsage?.find((quota) => quota?.type === "ROLLOVER");
  const dataShareText =
    typeof rolloverData?.remaining?.amount === "number"
      ? `${formatBytesToGB(rolloverData.remaining.amount)} tilgjengelig for deling`
      : "Klarte ikke hente data";

  return {
    loading,
    error,
    refetch,
    showAccountOverviewCard,
    showAnnualDiscountCard,
    dataShareText: isEligibleToShareData ? dataShareText : null,
  };
}
