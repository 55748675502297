import { useEffect, useState } from "react";

function getStoredValue<T>(key: string): T | undefined {
  try {
    const savedValue = localStorage.getItem(key);
    if (!savedValue) return;
    const parsedValue = JSON.parse(savedValue) as T;
    return parsedValue;
  } catch {
    return;
  }
}

type Options = {
  skip?: boolean;
};

export function useLocalStorage<T>(key: string, options?: Options) {
  const skip = options?.skip ?? false;
  const [value, setValue] = useState<T | undefined>(skip ? undefined : getStoredValue(key));

  useEffect(() => {
    if (value === null) {
      localStorage.removeItem(key);
    }
    if (!value || skip) return;
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch {
      return;
    }
  }, [key, value, skip]);

  return { value, setValue };
}
