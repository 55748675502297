import React, { createContext, useContext, useReducer } from "react";
import REQ from "../../../util/requestStatus";
import { RouterSettingsReducer } from "./reducer";

const initialState = {
  devices: null,
  activeUnitSerialNumber: null,
  activeUnit: null,
  fetchStatus: REQ.INIT,
  updateStatus: { updating: false, tag: "" },
  updateResponse: { response: null, tag: "", error: false, shown: false },
  otherSettingsChanged: false,
};
const RouterSettings = createContext(initialState);

export const useRouterSettings = () => useContext(RouterSettings);

export const RouterSettingsProvider = (props) => {
  const [routerSettings, dispatch] = useReducer(RouterSettingsReducer, initialState);

  return (
    <RouterSettings.Provider
      value={{
        routerSettings,
        dispatch,
      }}
    >
      {props.children}
    </RouterSettings.Provider>
  );
};
