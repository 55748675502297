import { LINE_DISCOUNT_GROUP } from "util/format-subscription/variables";
import { notEmpty } from "@telia-no-min-side/utils";
import { Maybe } from "gql/graphql";
import { MobileOfferForSale } from "../../types";

export function getMobileOffersForSale<T extends Maybe<MobileOfferForSale>>(
  offerings: Maybe<Maybe<T>[]> | undefined
): T[] | undefined {
  return (offerings || []).filter(notEmpty).filter((offering) => {
    return (
      !offering?.commitmentTimeInMonths &&
      !!offering?.additionalProperties?.find((additionalProperty) => additionalProperty?.value === LINE_DISCOUNT_GROUP)
    );
  });
}
