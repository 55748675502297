import { Container, TrackClickEvent } from "@telia-no-min-side/components";
import { switchReturnOptions } from "../../utils/switchOptions";
import { Heading, RadioCardGroup, Button, Flex } from "@telia/teddy";
import { Link, useLocation } from "react-router-dom";
import { useSwitchAgreement } from "../../hooks/useSwitchAgreements";
import { InsuranceCaseStatus } from "gql/graphql";
import { InsuranceProcessed } from "../InsuranceClaim/InsuranceProcessed";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { useState } from "react";

export function SwitchOptions() {
  const { pathname } = useLocation();
  const { switchAgreement } = useSwitchAgreement();
  const [selectedPath, setSelectedPath] = useState("");

  const handleChange = (path: string) => {
    setSelectedPath(path);
  };

  const getOptionByPath = (path: string) => {
    return switchReturnOptions.find((option) => option.path === path);
  };

  switchAgreement?.insurance?.insuranceCase !== null &&
    typeof switchAgreement?.insurance?.insuranceCase?.caseNumber == "string";

  if (switchAgreement?.insurance?.insuranceCase?.caseStatus === InsuranceCaseStatus.PreApproved) {
    return <InsuranceProcessed />;
  }

  return (
    <Container maxWidth="sm" flexDirection="column" gap="lg">
      <Heading as="h4" variant="title-100" data-tracking-id="switch-phone-status-text">
        Velg telefonens tilstand
      </Heading>

      <RadioCardGroup onValueChange={handleChange}>
        {switchReturnOptions.map((option, index) => (
          <RadioCardGroup.Item
            key={option.path}
            value={option.path}
            data-tracking-id={`switch-phone-return-option-${index}`}
          >
            <RadioCardGroup.Title>{option.title}</RadioCardGroup.Title>
            <RadioCardGroup.Body>{option.text}</RadioCardGroup.Body>
          </RadioCardGroup.Item>
        ))}
      </RadioCardGroup>
      <Flex width="full" justify="end">
        <TrackClickEvent
          asChild
          event={{
            ui_item_url: `${pathname}${selectedPath}`,
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_text: getOptionByPath(selectedPath)?.title || "",
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT,
          }}
        >
          <Button asChild data-tracking-id={`switch-phone-confirm-button`}>
            <Link to={`${pathname}${selectedPath}`}>Videre</Link>
          </Button>
        </TrackClickEvent>
      </Flex>
    </Container>
  );
}
