import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { track, getSimplePriceText } from "@telia-no-min-side/utils";
import { Drawer, Card, Flex, Toggle, Text, Button } from "@telia/teddy";
import { OrderOp } from "gql/graphql";
import { Loading, Error } from "./components";
import { GET_NORDIC_BALTIC_MINUTES } from "../../graphql";
import { useMobileAccount } from "hooks/useMobileAccount";
import { getTransactionData } from "./utils/getTransactionData";
import { NORDIC_BALTIC_MINUTES_CODE } from "util/constants/topups";
import { useAdditionalProducts } from "hooks/useAdditionalProducts";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";
import { useMutationResponse } from "hooks/useMutationResponse";

export function NordicBalticMinutes() {
  const { phoneNumber } = useMobileAccount();
  const { pushTrackingEvent } = track.useEventTracking();
  const { closeDrawerResetTopup } = useMobileDashboardContext();
  const { orderResponse, runOrderAdditionalProducts } = useAdditionalProducts();
  const { setMutationResponseMessage } = useMutationResponse();

  const { loading, data, error, refetch } = useQuery(GET_NORDIC_BALTIC_MINUTES, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
  });

  const additionalProducts = data?.subscription.additionalProducts || [];
  const nordicBalticsMinutes = additionalProducts.find(
    (product) => product?.code?.includes(NORDIC_BALTIC_MINUTES_CODE)
  );

  const isActive = nordicBalticsMinutes?.presentation?.active ?? false;
  const isModifiable = nordicBalticsMinutes?.presentation?.modifiable ?? false;

  const [isEnabled, setIsEnabled] = useState(isActive);
  const hasChanged = isEnabled !== isActive;

  const title = nordicBalticsMinutes?.name;
  const description = nordicBalticsMinutes?.shortDescription;
  const price = nordicBalticsMinutes?.price?.price;

  useEffect(() => {
    setIsEnabled(isActive);
  }, [isActive]);

  const handleToggleChange = () => {
    if (isModifiable && !orderResponse?.loading) {
      setIsEnabled((prev) => !prev);
    }
  };

  const handleCancel = () => setIsEnabled(isActive);

  const handleSubmit = () => {
    const orderInput = {
      phoneNumber,
      input: [
        {
          productName: nordicBalticsMinutes?.code,
          operation: isActive ? OrderOp.RemoveAdditionalProduct : OrderOp.AddAdditionalProduct,
        },
      ],
    };

    if (runOrderAdditionalProducts && nordicBalticsMinutes) {
      runOrderAdditionalProducts(orderInput, {
        onCompleted(data) {
          setMutationResponseMessage((prev) => ({
            ...prev,
            title: "NORDIC_BALTIC_MINUTES",
            text: `Ring Norden & Baltikum er ${isActive ? "kansellert" : "aktivert"}`,
          }));
          const transactionData = getTransactionData(nordicBalticsMinutes, data?.order?.orderId || "");
          closeDrawerResetTopup();

          return pushTrackingEvent(transactionData);
        },
      });
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error refetch={refetch} />;

  return (
    <>
      <Drawer.Close aria-label="Close" slot="floating" />
      <Drawer.Title variant="title-200">{title}</Drawer.Title>
      <Card.Line />
      <Drawer.Description>{description}</Drawer.Description>

      {orderResponse?.error && <Error isMutationError onClose={() => closeDrawerResetTopup()} />}

      {!orderResponse?.error && price && (
        <Flex align="center" justify="between">
          <Flex align="center" gap="gutter-sm">
            <Toggle>
              <Toggle.Input
                checked={isEnabled}
                onCheckedChange={handleToggleChange}
                disabled={!isModifiable || orderResponse?.loading}
              />
            </Toggle>
            <Text>{title}</Text>
          </Flex>

          <Text>{getSimplePriceText(price)}</Text>
        </Flex>
      )}

      {!orderResponse?.error && hasChanged && (
        <Flex justify="end" gap="gutter-md" mt="gutter-md">
          <Button variant="secondary" type="button" onClick={handleCancel}>
            Avbryt
          </Button>
          <Button variant="primary" type="button" onClick={handleSubmit} loading={orderResponse?.loading}>
            Lagre
          </Button>
        </Flex>
      )}
    </>
  );
}
