import { useState } from "react";
import { track, types, uri } from "@telia-no-min-side/utils";
import { Link } from "react-router-dom";
import { getOrderToPlace } from "../order";
import { Receipt } from "../receipt";
import { OneTimeCosts } from "./one-time-costs";
import { getSingleProductTracking } from "../getSingleProductTracking";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { openPdf } from "util/pdf-order-summary/openPdf";
import { DownloadButton } from "components/pdf-order-summary/DownloadButton";
import { PdfHeader } from "components/pdf-order-summary/PdfHeader";
import { PdfFooter } from "components/pdf-order-summary/PdfFooter";
import { getProductShipping } from "pages/tv/utils/shipping";
import { getPrice } from "pages/tv/utils/price";
import { ShoppingSummary } from "components/shopping-summary";
import { Button, Flex } from "@telia/teddy";

type Props = {
  remoteControlProduct: types.fixed.ProductGroupSingleProductWithShipping;
};

export function OrderSummary(props: Props) {
  const [showReceipt, setShowReceipt] = useState(true);
  const [placeOrderResponseStatus, setPlaceOrderResponseStatus] = useState<
    types.fixed.ResponseStatusSubmitMultipleProducts | undefined
  >(undefined);
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { remoteControlProduct } = props;

  const productShipping = remoteControlProduct?.shippingOptionDTOS;
  const shipping = getProductShipping(productShipping);
  const remoteControlPrice = getPrice(remoteControlProduct);
  const currentOrder = getOrderToPlace(remoteControlProduct, shipping?.shippingMethodId);

  const submitOrder = useAxiosPost<types.fixed.ResponseSubmitMultipleProducts, types.fixed.SubmitMultipleProducts>(
    uri.purchase(ICX_REQUEST.URL_MULTIPLE_PRODUCTS_SUBMIT),
    {
      onSuccess(response) {
        setPlaceOrderResponseStatus(response.status);
      },
      onError() {
        setPlaceOrderResponseStatus("order_failed");
      },
      onFinally() {
        setShowReceipt(true);
      },
    }
  );

  if (!currentOrder.axiomIds.length) return null;

  return (
    <Flex maxWidth="400px" direction="column" gap="lg" id="remote-control-order-summary">
      <PdfHeader />
      <ShoppingSummary>
        <OneTimeCosts
          remoteControlProduct={remoteControlProduct}
          shipping={shipping}
          remoteControlPrice={remoteControlPrice}
        />
        <DownloadButton onClick={async () => await openPdf("#remote-control-order-summary")} />
        <Flex data-html2canvas-ignore justify="end" gap="400">
          <Button asChild variant="text">
            <Link to={uri.minSideFixed("/tv")}>Tilbake</Link>
          </Button>
          <Button
            onClick={() => {
              const trackInfo: track.GenericEvent = {
                ui_item_text: "Bekreft kjøp",
                ui_item_url: "no_url",
                ui_item_context: "Telia Box Fjernkontroll",
                ui_item_action: "button_click",
                ui_item_type: "button",
              };
              pushGenericTrackingEvent(trackInfo);
              submitOrder.post(currentOrder, getSingleProductTracking(remoteControlProduct, "Hardware/TV/Remote"));
            }}
            variant="primary"
            disabled={submitOrder.isLoading}
            loading={submitOrder.isLoading}
          >
            {submitOrder.isLoading ? "Gjennomfører kjøp" : "Bekreft kjøp"}
          </Button>
        </Flex>
        {placeOrderResponseStatus && <Receipt isOpen={showReceipt} status={placeOrderResponseStatus} />}
      </ShoppingSummary>
      <PdfFooter />
    </Flex>
  );
}
