import { Container, Card, Paragraph } from "@telia-no-min-side/components";
import { getSimplePriceText } from "@telia-no-min-side/utils";

type Props = {
  newPrice?: number;
  oldPrice?: number;
};

export function SimChanges({ newPrice, oldPrice }: Props) {
  return (
    <Container gap="sm" flexDirection="column">
      <Paragraph removeMargin>
        <em>SIM-kort</em>
      </Paragraph>
      <Card backgroundColor="grey" removeMinHeight showShadow={false} padding="md" maxWidth="lg">
        <Paragraph removeMargin>{`Ny pris for ekstra SIM blir ${getSimplePriceText(newPrice || 0, "md.")}`}</Paragraph>
        <Paragraph removeMargin>{`Din nåværende pris for ekstra SIM er ${getSimplePriceText(
          oldPrice || 0,
          "md."
        )}`}</Paragraph>
      </Card>
    </Container>
  );
}
