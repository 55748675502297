import {
  Accordion,
  CardProps,
  Container,
  ContainerBaseProps,
  Heading,
  Icon,
  Paragraph,
} from "@telia-no-min-side/components";
import { getSimplePriceText } from "@telia-no-min-side/utils";
import { IconDefinition, colors } from "@telia/styleguide";
import { IncludedInViaplay } from "pages/mobile/mobile-subscription-change/components/IncludedInViaplay";
import { ViaplayTotalImages } from "pages/mobile/mobile-subscription-change/components/ViaplayTotalImages";
import { ReactNode, useEffect, useRef } from "react";
import { isViaplay } from "util/format-subscription/isViaplay";
import { BUCKET_SUBCRIPTIONS, MAX_SPEED_SUBSCRIPTIONS } from "util/format-subscription/variables";

const transitionDuration = 300;
const transitionDurationWithUnit = `${transitionDuration}ms`;

type MobileSubscriptionAccordionItemProps = {
  overLine?: ReactNode;
  hasDoubleData: boolean;
  hasDoubleSpeed: boolean;
  subscriptionName: string;
  customSubscriptionName?: ReactNode;
  priceMonthly: number | undefined | null;
  originalPriceMonthly?: number | null;
  includedProperties: { code: string; text: string | ReactNode; icon?: IconDefinition; showInPeak?: boolean }[];
  children?: ReactNode;
  triggerContainerProps?: ContainerBaseProps;
  additionalText?: string;
  isRecommended?: boolean;
  highlightText?: string;
  dataTrackingID?: string;
  customPeakContent?: ReactNode;
} & Pick<CardProps<"button">, "lozengeRight">;

export function MobileSubscriptionAccordionItem({
  includedProperties,
  priceMonthly,
  subscriptionName,
  customSubscriptionName,
  originalPriceMonthly,
  overLine,
  hasDoubleSpeed,
  hasDoubleData,
  triggerContainerProps,
  additionalText,
  isRecommended,
  highlightText,
  children,
  lozengeRight,
  dataTrackingID,
  customPeakContent,
}: MobileSubscriptionAccordionItemProps) {
  const peakRef = useRef<HTMLDivElement>(null);
  const animationId = useRef<ReturnType<typeof setTimeout>>();
  const scrollAnimationId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (peakRef.current && isRecommended) {
      scrollAnimationId.current = setTimeout(() => {
        if (peakRef.current && isRecommended) {
          peakRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 50);
    }
    return () => {
      clearTimeout(scrollAnimationId.current);
    };
  }, [isRecommended, peakRef]);

  useEffect(() => {
    return () => {
      clearTimeout(animationId.current);
    };
  }, []);

  const isViaplayTotal = isViaplay(subscriptionName);
  const isBucket = BUCKET_SUBCRIPTIONS.includes(subscriptionName);
  const canGetDoubleSpeed = !isBucket && !MAX_SPEED_SUBSCRIPTIONS.includes(subscriptionName);
  const showBenefit = (canGetDoubleSpeed || isBucket) && (hasDoubleSpeed || hasDoubleData);

  const renderPeakContent = () => {
    if (customPeakContent) {
      return customPeakContent;
    }

    return (
      <>
        {additionalText?.length ? <Paragraph removeMargin>{additionalText}</Paragraph> : null}
        <Container gap flexDirection="row">
          {includedProperties
            .filter((properties) => properties.showInPeak !== false && properties.icon)
            .map(({ icon, code }) => (
              <div key={`${code}-peak-content-${subscriptionName}`}>
                <Container flexDirection="row">
                  {icon && <Icon icon={icon} />}
                  {code === "EXTRA_SIM" && "x2"}
                </Container>
              </div>
            ))}
        </Container>
      </>
    );
  };

  return (
    <Accordion.Item
      initialIsOpen={isRecommended}
      showSelectedRing={isRecommended || !!highlightText?.length}
      lozengeRight={lozengeRight}
      lozengeLeft={
        isRecommended
          ? { label: "Anbefalt", status: "communication" }
          : highlightText
          ? { label: highlightText, status: "attention" }
          : undefined
      }
      dataTrackingID={dataTrackingID}
    >
      <Accordion.Trigger overLine={overLine}>
        <Container {...triggerContainerProps} gap="sm" flexDirection="column">
          <Container gap alignItems="center" justifyContent="space-between" flexDirection="row">
            <Heading tag="h3" variant="title-150" removeMargin>
              {customSubscriptionName ? customSubscriptionName : <b>{subscriptionName}</b>}
            </Heading>
            <div>
              <Container gap="sm">
                {typeof originalPriceMonthly === "number" && originalPriceMonthly > (priceMonthly || 0) && (
                  <Paragraph removeMargin textDecorationLine="line-through" isFaded>
                    {getSimplePriceText(originalPriceMonthly)}
                  </Paragraph>
                )}
                <Paragraph removeMargin>
                  {typeof priceMonthly === "number" ? (
                    <em>{getSimplePriceText(priceMonthly, "md.")}</em>
                  ) : (
                    "Klarte ikke hente pris"
                  )}
                </Paragraph>
              </Container>
            </div>
          </Container>
        </Container>
      </Accordion.Trigger>
      {showBenefit && (
        <Container style={{ backgroundColor: colors.green100 }} gap alignItems="center" padding="left">
          <Paragraph>
            <em>{hasDoubleSpeed || canGetDoubleSpeed ? "Dobbel hastighet" : "Dobbel data"} </em>er allerede inkludert,
            siden du har TV/Internett fra Telia.
          </Paragraph>
        </Container>
      )}
      <Accordion.Content
        peakContent={({ isOpen }) => {
          if (animationId.current) {
            clearTimeout(animationId.current);
          }
          if (isOpen) {
            if (peakRef.current?.parentElement) {
              peakRef.current.parentElement.style.position = "absolute";
            }
          }
          if (!isOpen && peakRef.current?.parentElement) {
            animationId.current = setTimeout(() => {
              if (peakRef.current?.parentElement) {
                peakRef.current.parentElement.style.position = "relative";
              }
            }, transitionDuration);
          }
          if (isViaplayTotal) {
            return <ViaplayTotalImages subscriptionName={subscriptionName} />;
          }
          return (
            <Container
              ref={peakRef}
              style={{
                opacity: isOpen ? 0 : 1,
                transitionProperty: "opacity",
                transitionDuration: transitionDurationWithUnit,
              }}
              gap
              flexDirection="column"
              alignItems="flex-start"
            >
              {renderPeakContent()}
            </Container>
          );
        }}
      >
        {({ isOpen }) => (
          <>
            {isViaplayTotal && (
              <IncludedInViaplay
                isOpen={isOpen}
                subscriptionName={subscriptionName}
                transitionDurationWithUnit={transitionDurationWithUnit}
              />
            )}
            <Container
              padding="top"
              style={{
                opacity: isOpen ? 1 : 0,
                transitionProperty: "opacity",
                transitionDuration: transitionDurationWithUnit,
              }}
              gap
              flexDirection="column"
            >
              {additionalText?.length ? <Paragraph removeMargin>{additionalText}</Paragraph> : null}
              <Paragraph removeMargin>
                <em>{isViaplayTotal ? "Inkludert i abonnement" : "Inkludert"}</em>
              </Paragraph>
              {includedProperties.map(({ icon, code, text }) => (
                <div key={`${code}-content-${subscriptionName}`}>
                  <Container flexDirection="row" gap alignItems="flex-start">
                    {icon && (
                      <div>
                        <Icon icon={icon} />
                      </div>
                    )}
                    <Paragraph removeMargin>{text}</Paragraph>
                  </Container>
                </div>
              ))}
            </Container>
            {children}
          </>
        )}
      </Accordion.Content>
    </Accordion.Item>
  );
}
