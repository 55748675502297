import { uri } from "@telia-no-min-side/utils";
import { Button, Container, Heading, Link, Paragraph, ScrollToTop } from "@telia-no-min-side/components";
import { useSearchParams } from "react-router-dom";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

// used by TV-over-FWA when main order is success, but box order fail

export function ReceiptSuccessNoBox() {
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get("returnTo");
  const returnToName = searchParams.get("name");
  const isExternalNavigation = searchParams.get("external") === "true";

  return (
    <Container padding maxWidth="md">
      <ScrollToTop />
      <Heading tag="h2" variant="title-400">
        Takk for bestillingen!
      </Heading>
      <Paragraph variant="paragraph-100">
        Vi har nå mottatt din bestilling, men vi klarte ikke bestille TV-boks til deg. Dersom du ønsker en Telia Box, så
        kan det bestilles under fanen for TV-bokser.
      </Paragraph>
      <Paragraph variant="paragraph-100">
        Angreretten forutsetter at du senest innen 14 dager etter at du mottar leveransen gir oss melding om dette
        (angrefrist). Skal du benytte deg av angreretten og sende inn en klage må du fylle ut{" "}
        <Link
          target="_blank"
          rel="noreferrer"
          variant="text"
          href={EXTERNAL_LINK.CUSTOMER_SERVICE_TERMS_AND_CONDITIONS}
        >
          angrerettskjema TV og internett
        </Link>{" "}
        og sende det til oss.
      </Paragraph>
      <Button
        tag={"a"}
        isInternalNavigation={!isExternalNavigation}
        href={returnToName && returnTo ? returnTo : uri.minSideFixed("/hjem")}
        iconPosition="before"
        icon="arrow-left"
      >
        Tilbake til {returnToName || "Hjem"}
      </Button>
    </Container>
  );
}
