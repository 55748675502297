import { Link } from "react-router-dom";
import { Button, Icon, IconName, Grid, Text, ChannelButton } from "@telia/teddy";
import styled from "styled-components";

export type Props = {
  buttonsData: { URL: string; text: string; icon: IconName; hide?: boolean; isLoading?: boolean }[];
};

export function NavigationButtons({ buttonsData }: Props) {
  const visibleButtons = buttonsData.filter(({ hide, isLoading }) => (isLoading ? true : !hide));

  return (
    <Grid gap="100" columns="repeat(auto-fit, 95px)">
      {visibleButtons.map((button) => (
        <LinkWrapper asChild key={button.text}>
          <Link to={button.URL}>
            <Button variant="primary" asChild iconOnly aria-label={button.text}>
              <div>
                <Icon name={button.icon} />
              </div>
            </Button>
            <Text variant="paragraph-100">{button.text}</Text>
          </Link>
        </LinkWrapper>
      ))}
    </Grid>
  );
}

const LinkWrapper = styled(ChannelButton)`
  && {
    border-color: transparent;
    justify-content: start;
    padding-inline: 0;
    &:hover::after {
      opacity: 0;
    }
  }
`;
