import { Maybe, OfferingPriceDetails, ProductService, OfferingPriceCodes, AdditionalProduct } from "src/gql/graphql";
import { config } from "util/config";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";

export function getJuniorSummerCampaignDataForOffer(
  pricePlan: string,
  offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined,
  includedProducts: Maybe<Maybe<ProductService>[]> | undefined
) {
  if (!isJuniorSummerCampaign(pricePlan)) {
    return { formattedBaseDataAmount: "", formattedExtraDataAmount: "", formattedTotalDataAmount: "" };
  }

  const baseDataAmount = offeringPrices?.find((price) => price?.code === OfferingPriceCodes.DataIncluded);

  const formattedBaseDataAmount =
    typeof baseDataAmount?.amount === "number" && !!baseDataAmount.monetaryUnit
      ? `${baseDataAmount.amount} ${baseDataAmount.monetaryUnit}`
      : "";

  // When you change subscription, includedProducts are available
  // When you buy a new subscription, includedProducts are not available
  // In that case faking double data amount by assigning base data amount to extra data amount
  const extraDataAmount = includedProducts
    ?.flatMap((product) => product?.productTerms)
    ?.find((terms) => terms?.code === OfferingPriceCodes.DataIncluded) || {
    value: config.disableSummerCampaignDoubleData ? 0 : baseDataAmount?.amount,
    unit: baseDataAmount?.monetaryUnit,
  };

  const formattedExtraDataAmount =
    typeof extraDataAmount?.value === "number" && !!extraDataAmount.unit
      ? `${extraDataAmount?.value} ${extraDataAmount.unit}`
      : "";

  const formattedTotalDataAmount =
    typeof baseDataAmount?.amount === "number" && typeof extraDataAmount?.value === "number" && !!extraDataAmount.unit
      ? `${baseDataAmount.amount + extraDataAmount?.value} ${extraDataAmount.unit}`
      : "";

  return { formattedBaseDataAmount, formattedExtraDataAmount, formattedTotalDataAmount };
}

export function getJuniorSummerCampaignDataForCurrentSubscription(
  offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined,
  additionalProducts: Maybe<Maybe<AdditionalProduct>[]> | undefined
) {
  const baseDataAmount = offeringPrices?.find((price) => price?.code === OfferingPriceCodes.DataIncluded);

  const formattedBaseDataAmount =
    typeof baseDataAmount?.amount === "number" && !!baseDataAmount.monetaryUnit
      ? `${baseDataAmount.amount} ${baseDataAmount.monetaryUnit}`
      : "";

  const doubleDataProduct = additionalProducts?.find(
    (additionalProduct) => additionalProduct?.name?.toLowerCase().includes("dobbel data")
  );

  const extraDataAmount = doubleDataProduct?.productTerms?.find(
    (term) => term?.code === OfferingPriceCodes.DataIncluded
  );

  const formattedExtraDataAmount =
    typeof extraDataAmount?.amount === "number" && !!extraDataAmount.monetaryUnit
      ? `${extraDataAmount?.amount} ${extraDataAmount.monetaryUnit}`
      : "";

  const formattedTotalDataAmount =
    typeof baseDataAmount?.amount === "number" &&
    typeof extraDataAmount?.amount === "number" &&
    !!extraDataAmount.monetaryUnit
      ? `${baseDataAmount.amount + extraDataAmount?.amount} ${extraDataAmount.monetaryUnit}`
      : "";

  return { formattedBaseDataAmount, formattedExtraDataAmount, formattedTotalDataAmount };
}
