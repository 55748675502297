import React from "react";
import { CSSTransition } from "react-transition-group";

import "./FadeInContent.less";

export const FadeInContent = ({ show, children }) => (
  <CSSTransition timeout={300} classNames="fade-in-content" unmountOnExit in={show}>
    {children}
  </CSSTransition>
);
