import React, { useState } from "react";
import { colors } from "@telia/styleguide";
import { Backdrop } from "../Backdrop";
import { getPositionEl } from "./utils/getPositionEl";
import {
  Container,
  StyledIcon,
  InfoTextContainer,
  LabelContainer,
  CloseIconWrapper,
  StyledCloseIcon,
  LineSeparator,
  DescriptionText,
  IconWrapper,
} from "./style";

export type Props = {
  label: string;
  description: string;
  position: "top" | "right" | "bottom" | "left";
};

export type InfoTextContainerProps = {
  positionEl:
    | {
        top: string;
        left: string;
      }
    | undefined;
};

/**
 * @deprecated Use Teddy components instead
 */
export function MoreInfoIcon({ label, description, position }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") setIsOpen((prevState) => !prevState);
  };

  const handleClose = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") setIsOpen(false);
  };

  return (
    <Container>
      <IconWrapper
        tabIndex={0}
        aria-label="Mer informasjon"
        onKeyUp={(e) => handleToggle(e)}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <StyledIcon icon="info" color={colors.corePurple500} />
      </IconWrapper>

      {isOpen ? (
        <>
          <InfoTextContainer positionEl={getPositionEl(position)}>
            <LabelContainer>
              <span>{label}</span>
            </LabelContainer>
            <CloseIconWrapper tabIndex={0} onKeyPress={(e) => handleClose(e)} onClick={() => setIsOpen(false)}>
              <StyledCloseIcon />
            </CloseIconWrapper>
            <LineSeparator />
            <DescriptionText>{description}</DescriptionText>
          </InfoTextContainer>
          <Backdrop isVisible color="transparent" onClick={() => setIsOpen(false)} />
        </>
      ) : null}
    </Container>
  );
}
