import { track } from "@telia-no-min-side/utils";
import { Flex, Card, Text, Icon, Button, breakpoint } from "@telia/teddy";
import { useAuth } from "@telia-no-min-side/components";
import { AUTH } from "util/constants/auth";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function GetAccessFixedCard() {
  const { setAuthAdapter } = useAuth();
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const handleClick = () => {
    setAuthAdapter(AUTH.PROVIDER.CIAM);
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.BANKID_LOGIN,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
      ui_item_url: TRACK_EVENT.ITEM_URL.IDENTITY,
    });
  };

  return (
    <Card bordered variant="purple-light" width="100%" maxWidth={breakpoint.teddyBreakpointMd}>
      <Card.Heading as="h3" variant="title-100">
        <Flex direction="row" gap="150" align="center">
          <Icon name="lock-open" size="md" />
          Få tilgang til den nye Min Side
        </Flex>
      </Card.Heading>

      <Card.Content>
        <Flex direction="column" gap="150">
          <Text variant="paragraph-200">
            For å sikre deg en bedre brukeropplevelse av Min Side må du logge inn med BankID. Dette trenger vi for å
            kunne hente riktige opplysninger om deg og vise dine produkter på din Hjem-side.
          </Text>
          <Text>
            <Button onClick={handleClick}>Logg inn med BankID</Button>
          </Text>
          <Text faded variant="paragraph-200">
            Jeg vil ikke logge inn med BankID nå. Bruk sidemenyen til å navigere deg rundt.
          </Text>
        </Flex>
      </Card.Content>
    </Card>
  );
}
