import React, { ComponentProps } from "react";
import { Notabene, Text, Link, Button } from "@telia/teddy";

type Props = {
  title: string;
  description?: string | React.ReactNode;
  href?: string;
  actionContent?: string | React.ReactNode;
  onClick?: () => void;
  openInSameTab?: boolean;
  width?: ComponentProps<typeof Notabene>["width"];
  maxWidth?: ComponentProps<typeof Notabene>["maxWidth"];
};

export function ErrorBox({
  title,
  description,
  href,
  actionContent,
  openInSameTab,
  maxWidth,
  width = "100%",
  onClick,
}: Props) {
  return (
    <Notabene maxWidth={maxWidth} width={width}>
      <Notabene.Icon name="smiley-sad" />
      <Notabene.Content>
        <Notabene.Heading>{title}</Notabene.Heading>
        {description && <Text mb="50">{description}</Text>}
        {actionContent &&
          (href ? (
            <Link href={href} variant="standalone" target={openInSameTab ? "_self" : "_blank"}>
              {actionContent}
            </Link>
          ) : (
            <Button variant="text" onClick={onClick}>
              {actionContent}
            </Button>
          ))}
      </Notabene.Content>
    </Notabene>
  );
}
