import { Container, Paragraph } from "@telia-no-min-side/components";
import { RadioButton } from "@telia/styleguide";
import { track } from "@telia-no-min-side/utils";
import { useFlexOrder } from "../../context/FlexOrderProvider";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function AskAboutKeepingDecoder() {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { pageRequirement, cancelTv, setCancelTv } = useFlexOrder();

  return (
    <>
      <Paragraph>{pageRequirement?.description}</Paragraph>
      <Container maxWidth="xs">
        <RadioButton
          name="askAboutKeepingDecoder"
          checked={!cancelTv}
          onChange={() => {
            pushGenericTrackingEvent({
              ui_item_action: TRACK_EVENT.ITEM_ACTION.SELECT_ITEM,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FLEX,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.KEEP_TV_BOX_30_DAYS,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.SELECT,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
            });
            setCancelTv(false);
          }}
          label="Behold TV-boks i 30 dager"
        />
        <RadioButton
          name="askAboutKeepingDecoder"
          checked={cancelTv}
          onChange={() => {
            pushGenericTrackingEvent({
              ui_item_action: TRACK_EVENT.ITEM_ACTION.SELECT_ITEM,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FLEX,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.RETURN_TV_BOX_NOW,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.SELECT,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
            });
            setCancelTv(true);
          }}
          label="Returner TV-boks nå"
        />
      </Container>
    </>
  );
}
