import { L } from "src/util/links";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { ExpandableCard, Heading, Badge, Flex, Icon, Link } from "@telia/teddy";
import { Link as RouterLink } from "react-router-dom";

type Props = {
  isLegalOwner: boolean;
};

export function SubscriptionControls({ isLegalOwner }: Props) {
  const { phoneNumber } = useMobileAccount();

  return (
    <ExpandableCard type="multiple">
      <ExpandableCard.Item value="content1">
        <ExpandableCard.Trigger>
          <ExpandableCard.Header>
            <ExpandableCard.Description>
              <Flex direction="column" gap="100">
                <Heading as="h5" variant="title-100">
                  Ditt abonnement
                </Heading>
                <div>
                  <Badge variant="neutral">Administrer ditt abonnement</Badge>
                </div>
              </Flex>
            </ExpandableCard.Description>
            <ExpandableCard.Indicator />
          </ExpandableCard.Header>
        </ExpandableCard.Trigger>
        <ExpandableCard.Content>
          <Flex direction="column" gap="100">
            {isLegalOwner && (
              <>
                <Link asChild variant="text">
                  <a href={L.ChangeLegalOwner.href}>
                    {L.ChangeLegalOwner.text}
                    <Icon name="arrow-right" />
                  </a>
                </Link>
                <Link asChild variant="text">
                  <a href={L.CancelSubscription.href}>
                    {L.CancelSubscription.text}
                    <Icon name="arrow-right" />
                  </a>
                </Link>
              </>
            )}
            <Link asChild variant="text">
              <RouterLink
                to={`${L.BlockSubscription.href}/?${new URLSearchParams({
                  phoneNumber,
                }).toString()}`}
              >
                Sperr abonnement/SIM-kort
                <Icon name="arrow-right" />
              </RouterLink>
            </Link>
          </Flex>
        </ExpandableCard.Content>
      </ExpandableCard.Item>
    </ExpandableCard>
  );
}
