import styled from "styled-components";
import { colors, DropDownListWithLabel, Paragraph } from "@telia/styleguide";

export const AlertContainer = styled.div`
  margin: 2rem;
`;

export const DropdownList = styled(DropDownListWithLabel)`
  margin-bottom: 1rem;
`;

export const StyledParagraph = styled(Paragraph)<{
  color?: string;
}>`
  color: ${(props) => (props.color ? props.color : colors.grey300)};
`;

export const InformationSection = styled(StyledParagraph)`
  margin-top: 1rem;
`;
