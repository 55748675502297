import React, { ComponentProps } from "react";
import { useReactRouterHistory } from "../hooks/useReactRouterHistory";
import { Link } from ".";

type Props = {
  fallbackUrl: string;
  className?: string;
} & Omit<Partial<ComponentProps<typeof Link>>, "href">;
export function LinkGoBack(props: Props) {
  const { fallbackUrl, className, ...rest } = props;
  const { location } = useReactRouterHistory();
  const href = location.state?.returnUrl || fallbackUrl;

  return (
    <Link isInternalNavigation href={href} icon="arrow-left" iconPosition="before" className={className} {...rest}>
      Tilbake
    </Link>
  );
}
