import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { logFixedApiError, isUnauthorizedResponse, getCookieValue } from "@telia-no-min-side/utils";
import { getMinSideUserToken } from "@telia-no-min-side/components";
import { handleUnauthorizedResponse } from "@telia-no-min-side/components";
import { COOKIE } from "util/constants/cookies";

function getRequestConfig<T>(
  config?: AxiosRequestConfig<T>,
  userToken?: string,
  ctsession?: string
): AxiosRequestConfig<T> {
  if (userToken) {
    return {
      ...config,
      headers: { "identity-token": userToken, ...config?.headers },
    };
  }
  if (ctsession) {
    return {
      ...config,
      headers: {
        ctsession: ctsession,
      },
    };
  }
  return {
    ...config,
  };
}

export async function axiosGet<ResponseData = unknown>(
  url: string,
  config?: AxiosRequestConfig<ResponseData>
): Promise<AxiosResponse<ResponseData | undefined, Error | undefined>> {
  try {
    const userToken = getMinSideUserToken();
    const ctsession = getCookieValue(COOKIE.CT_SESSION);
    const response = await axios.get<ResponseData>(url, getRequestConfig<ResponseData>(config, userToken, ctsession));

    if (response.status === 204) {
      return { ...response, data: undefined };
    }
    return response;
  } catch (error) {
    logFixedApiError(error, { url });

    if (isUnauthorizedResponse(error)) {
      handleUnauthorizedResponse(error);
    }
    throw error;
  }
}

export async function axiosPost<ResponseData = unknown, RequestData = unknown>(
  url: string,
  data?: RequestData,
  config?: AxiosRequestConfig<ResponseData>
) {
  try {
    const userToken = getMinSideUserToken();
    const response = await axios.post<ResponseData>(url, data, getRequestConfig<ResponseData>(config, userToken));
    return response.data;
  } catch (error) {
    logFixedApiError(error, { url });

    if (isUnauthorizedResponse(error)) {
      handleUnauthorizedResponse(error);
    }

    throw error;
  }
}
