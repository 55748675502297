import { NetworkStatus, useQuery } from "@apollo/client";
import { Skeleton } from "@telia-no-min-side/components";
import { Flex, Card } from "@telia/teddy";
import { useFetchFinanceAccounts, useFetchFinanceInvoices } from "src/api/fixed";
import { graphql } from "../../../gql";
import { DashboardSection } from "../card/DashboardSection";
import { ErrorCard } from "../card/ErrorCard";
import { ErrorSection } from "../card/ErrorSection";
import { FinanceFixed } from "../finance-fixed";
import { FinanceMobile } from "../finance-mobile";

const financeOverviewQuery = graphql(`
  query FinanceOverviewQuery($filter: AccountFilter) {
    accounts(filter: $filter) {
      id
      paymentBalance {
        amount
      }
      ...AccountFinanceOverview
    }
  }
`);

export function Finance() {
  const mobileFinance = useQuery(financeOverviewQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: { invoicesVisible: true },
    },
  });
  const financeInvoices = useFetchFinanceInvoices();
  const financeAccounts = useFetchFinanceAccounts();

  function getLastInvoiceByFinancialAccountId(financialAccountId: string) {
    const financeInvoicesFlatted = financeInvoices.data?.flatMap((financeInvoice) => financeInvoice.financialAccounts);
    const invoices = financeInvoicesFlatted?.find(
      (financeInvoice) => financeInvoice.financialAccountId === financialAccountId
    )?.invoices;
    if (!invoices?.length) return null;
    return invoices[0];
  }

  const financeAccountsAndLastInvoice = financeAccounts.data?.map((financeAccount) => ({
    ...financeAccount,
    financialAccounts: financeAccount.financialAccounts.map((financialAccount) => ({
      ...financialAccount,
      lastInvoice: getLastInvoiceByFinancialAccountId(financialAccount.financialAccountId),
    })),
  }));

  const isFixeFinanceLoading =
    (!financeInvoices.data && financeInvoices.isLoading) || (!financeAccounts.data && financeAccounts.isLoading);

  const hasFixeFinanceData = Boolean(
    financeAccountsAndLastInvoice?.some((financeAccountAndLastInvoice) =>
      financeAccountAndLastInvoice.financialAccounts.some((financialAccount) => !!financialAccount.lastInvoice)
    )
  );

  const mobileAccountsWithPaymentBalance = mobileFinance.data?.accounts?.filter(
    (account) => typeof account.paymentBalance?.amount === "number"
  );
  const hasMobileFinanceData = Boolean(mobileAccountsWithPaymentBalance?.length);
  const isMobileFinanceLoading = mobileFinance.loading && !mobileFinance.data;
  const hasInvoices = hasMobileFinanceData || hasFixeFinanceData;

  const isLoading = (isMobileFinanceLoading && !hasFixeFinanceData) || (isFixeFinanceLoading && !hasMobileFinanceData);

  if (isLoading) {
    return (
      <DashboardSection isLoading>
        <Card variant="white" shadow bordered width="100%" maxWidth="400px">
          <Skeleton variant="heading-s" width="10ch" />
          <Skeleton width="80%" />
          <Flex justify="between" mt="150">
            <Skeleton width="10ch" />
            <Skeleton width="10ch" />
          </Flex>
        </Card>
      </DashboardSection>
    );
  }

  const isMobileFinancialError = !!mobileFinance.error;
  const isFixedFinancialError =
    (!financeInvoices.data && financeInvoices.error) || (!financeAccounts.data && financeAccounts.error);
  const isNoDataAndError = isMobileFinancialError && isFixedFinancialError;

  if (isNoDataAndError) {
    return (
      <ErrorSection
        title="Faktura"
        sectionText="din kontobalanse"
        onRefreshClick={() => {
          if (!mobileFinance.data && mobileFinance.error) {
            mobileFinance.refetch();
          }
          if (!financeInvoices.data && financeInvoices.error) {
            financeInvoices.mutate();
          }
          if (!financeAccounts.data && financeAccounts.error) {
            financeAccounts.mutate();
          }
        }}
        isLoading={
          mobileFinance.networkStatus === NetworkStatus.refetch ||
          financeInvoices.isValidating ||
          financeAccounts.isValidating
        }
      />
    );
  }

  if (!hasInvoices) return null;

  return (
    <DashboardSection title="Faktura">
      {Boolean(mobileAccountsWithPaymentBalance?.length) &&
        mobileAccountsWithPaymentBalance?.map((accountFinanceOverview) => (
          <FinanceMobile
            key={accountFinanceOverview.id}
            hasMultipleAccounts={mobileAccountsWithPaymentBalance.length > 1}
            accountFinanceOverview={accountFinanceOverview}
          />
        ))}
      {isMobileFinancialError && (
        <ErrorCard
          title="Mobil"
          sectionText="din kontobalanse"
          isLoading={mobileFinance.networkStatus === NetworkStatus.refetch}
          onRefreshClick={mobileFinance.refetch}
        />
      )}
      {financeAccountsAndLastInvoice
        ?.sort((customerA, customerB) => Number(customerA.customerId) - Number(customerB.customerId))
        .map((userWithCustomerId) => (
          <FinanceFixed key={userWithCustomerId.customerId} userWithCustomerId={userWithCustomerId} />
        ))}
      {isFixedFinancialError && (
        <ErrorCard
          title="TV/Internett"
          onRefreshClick={() => {
            financeAccounts.mutate();
            financeInvoices.mutate();
          }}
          isLoading={financeAccounts.isValidating || financeInvoices.isValidating}
          sectionText="din kontobalanse"
        />
      )}
    </DashboardSection>
  );
}
