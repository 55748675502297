// Reference to the list of roaming countries: https://itwiki.atlassian.teliacompany.net/display/MIN/Data+Usage+in+Digital+Channels

export const ROAMING_COUNTRIES_BY_ZONE = {
  ZONE1: [
    "Albania",
    "Argentina",
    "Armenia",
    "Australia",
    "Aserbajdsjan",
    "Bahrain",
    "Hviterussland",
    "Bosnia-Hercegovina",
    "Brasil",
    "Kambodsja",
    "Canada",
    "Chile",
    "Kina",
    "Colombia",
    "Costa Rica",
    "Ecuador",
    "Egypt",
    "Færøyene",
    "Georgia",
    "Guernsey",
    "Hong Kong",
    "Indonesia",
    "Isle of Man",
    "Israel",
    "Japan",
    "Jersey",
    "Jordan",
    "Kasakhstan",
    "Sør-Korea",
    "Kosovo",
    "Kuwait",
    "Macau",
    "Makedonia",
    "Malaysia",
    "Mexico",
    "Moldova",
    "Montenegro",
    "Marokko",
    "Nepal",
    "New Zealand",
    "Oman",
    "Palestina",
    "Panama",
    "Filippinene",
    "Russland",
    "Serbia",
    "Singapore",
    "Taiwan",
    "Uruguay",
    "Venezuela",
    "Vietnam",
  ],
  ZONE2: [
    "Anguilla",
    "Antigua og Barbuda",
    "Bangladesh",
    "Aruba",
    "Belize",
    "Bermuda",
    "Kapp Verde",
    "Bahamas",
    "Kongo-dr",
    "Barbados",
    "Caymanøyene",
    "Dominica",
    "El Salvador",
    "Grønland",
    "Den dominikanske republikk",
    "Guatemala",
    "Guyana",
    "Grenada",
    "Honduras",
    "India",
    "Irak",
    "Haiti",
    "Kenya",
    "Kirgisistan",
    "Madagaskar",
    "Malawi",
    "Mauritius",
    "Jamaica",
    "Mosambik",
    "Montserrat",
    "Nicaragua",
    "Nigeria",
    "Pakistan",
    "Paraguay",
    "Peru",
    "Bonaire, Curacao, Saint Martin",
    "Qatar",
    "Rwanda",
    "Saint Kitts og Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Saudi-Arabia",
    "Sør-Afrika",
    "Sri Lanka",
    "Surinam",
    "Tadsjikistan",
    "Tanzania",
    "Trinidad og Tobago",
    "Tunisia",
    "Turks and Caicos",
    "De forente arabiske emirater",
    "Usbekistan",
    "Jomfruøyene UK",
  ],
  ZONE3: [
    "Afghanistan",
    "Andorra",
    "Benin",
    "Bolivia",
    "Botswana",
    "Brunei",
    "Burkina Faso",
    "Kamerun",
    "Densentralafrikanskerepublikk",
    "Elfenbeinskysten",
    "Gambia",
    "Ghana",
    "Liberia",
    "Mali",
    "Monaco",
    "Maritime-OffshoreSatellitte",
    "Senegal",
    "Seychellene",
    "Sierra Leone",
    "Uganda",
  ],
  ZONE4: ["AeroMobile", "Algerie", "Bhutan", "Etiopia", "Laos", "Libanon", "TN Maritime - Skip"],
  ZONE5: ["EU", "EØS", "Sveits", "Storbritannia"],
  ZONE6: [
    "Kongo-Brazzaville",
    "Øst-Timor",
    "Ekvatorial-Guinea",
    "Falklandsøyene",
    "Fiji",
    "Fransk Polynesia",
    "Gabon",
    "Guam",
    "Iran",
    "Libya",
    "Maldivene",
    "Mauritania",
    "Myanmar",
    "Niger",
    "Thuraya Satellite",
    "Syria",
    "Turkmenistan",
    "Cuba",
    "Zambia",
    "Zimbabwe",
  ],
  ZONE7: ["Siminn On-Waves"],
  ZONE8: [
    "Angola",
    "Cook Islands",
    "Djibouti",
    "Guinea",
    "Mongolia",
    "Namibia",
    "Ny-Caledonia",
    "Papua Ny-Guinea",
    "Jemen",
    "Sudan",
    "Togo",
    "Tsjad",
    "Wireless Maritime Services (AT&T)",
  ],
  ZONE9: ["Tampnet - Offshore"],
  ZONE10: ["Puerto Rico", "JomfruøyeneUS", "Thailand", "Tyrkia", "USA"],
};
