import { Button, CheckBoxWithLabel, DropDownListWithLabel, Heading, TextBoxWithLabel } from "@telia/styleguide";
import React from "react";
import { IpAddressInput } from "../../../modules/IPAddressInput";
import { ErrorMessageContainer } from "../../../modules/LayoutComponents/ErrorMessageContainer";
import { RowFlexAlignEndJustifyStart } from "../../../modules/LayoutComponents/RowFlexAlignEndJustifyStart";
import { ResponseMessage } from "../../../modules/ResponseMessage";
import { portForwardingTags } from "../../../util/PortForwardingUtilities";

import "./PortForwardingRule.less";

const { externalPort, externalPortFrom, externalPortTo, internalPort, internalPortFrom, internalIp, type, name } =
  portForwardingTags;

const Container = ({ children }) => <div className="portforwarding-container">{children}</div>;

const InputContainer = ({ children }) => <div className="portforwarding-input-container">{children}</div>;

const RowAlignCenter = ({ children }) => <div className="portforwarding-row-align-center">{children}</div>;

const findFirstErrorMessage = (messages, tag) => {
  const message = messages.filter((msg) => msg.tag.includes(tag)).find((msg) => msg.message !== "");

  return !message ? "" : message.message;
};

export const PortForwardingRuleEditable = ({
  onClose,
  preExisting,
  forwardingRule,
  supportsPortRange,
  handleInput,
  errorMessages,
  errorMessagesWRTOtherRules,
  validate,
  mask,
  setEnabledFlag,
  updateResponse,
  onSave,
  tag,
  updateStatus,
}) => (
  <Container>
    <Heading
      tag="h3"
      size="xs"
      text={`${preExisting ? "Endre portviderekobling" : "Ny portviderekobling"}`}
      style={{ marginTop: 0 }}
    />
    <RowFlexAlignEndJustifyStart>
      <TextBoxWithLabel
        errorMessage={findFirstErrorMessage(errorMessages, name)}
        labelText={"Navn"}
        onChange={(value) => handleInput(value.target.value, name)}
        onBlur={validate}
        value={forwardingRule.Name}
        placeholder={"Navn"}
        type={"text"}
      />
    </RowFlexAlignEndJustifyStart>
    <RowFlexAlignEndJustifyStart>
      <DropDownListWithLabel
        label={"Type"}
        changeSelectedOption={(event) => handleInput(event.target.value, type)}
        options={[
          { value: "TCP", name: "TCP" },
          { value: "UDP", name: "UDP" },
          { value: "Both", name: "TCP + UDP" },
        ]}
        selectedOption={forwardingRule.Protocol}
      />
    </RowFlexAlignEndJustifyStart>
    <RowFlexAlignEndJustifyStart>
      <InputContainer style={{ marginRight: supportsPortRange ? ".5rem" : "0" }}>
        <TextBoxWithLabel
          onChange={(value) => handleInput(value.target.value, externalPortFrom)}
          labelText={supportsPortRange ? "Ekstern port fra" : "Ekstern port"}
          type={"number"}
          value={forwardingRule.ExternalFromPort}
          onBlur={validate}
          errorMessage={
            supportsPortRange
              ? findFirstErrorMessage(errorMessages, externalPortFrom)
              : findFirstErrorMessage(errorMessages, externalPort)
          }
          min={"1"}
          max={"65535"}
        />
      </InputContainer>
      {supportsPortRange && (
        <InputContainer>
          <TextBoxWithLabel
            onChange={(value) => handleInput(value.target.value, externalPortTo)}
            labelText={"Ekstern port til"}
            errorMessage={findFirstErrorMessage(errorMessages, externalPortTo)}
            type={"number"}
            min={"1"}
            max={"65535"}
            value={forwardingRule.ExternalToPort}
            onBlur={validate}
          />
        </InputContainer>
      )}
    </RowFlexAlignEndJustifyStart>
    <RowFlexAlignEndJustifyStart>
      <InputContainer style={{ width: "50%", marginRight: supportsPortRange ? ".5rem" : "0" }}>
        <TextBoxWithLabel
          onChange={(value) => handleInput(value.target.value, internalPortFrom)}
          errorMessage={
            supportsPortRange
              ? findFirstErrorMessage(errorMessages, internalPortFrom)
              : findFirstErrorMessage(errorMessages, internalPort)
          }
          labelText={supportsPortRange ? "Intern port fra" : "Intern port"}
          type={"number"}
          min={"1"}
          max={"65535"}
          value={forwardingRule.InternalFromPort}
          onBlur={validate}
        />
      </InputContainer>
      {supportsPortRange && (
        <InputContainer style={{ width: "50%" }}>
          <TextBoxWithLabel
            labelText={"Intern port til"}
            type={"number"}
            min={"1"}
            max={"65535"}
            value={forwardingRule.InternalToPort}
            disabled
            onBlur={validate}
          />
        </InputContainer>
      )}
    </RowFlexAlignEndJustifyStart>
    <RowFlexAlignEndJustifyStart>
      <IpAddressInput
        onChange={(address) => handleInput(address, internalIp)}
        addressContract={{ noContract: true }}
        typeOfAddress={"portforwarding-rule"}
        title={"Intern IP"}
        mask={mask}
        address={forwardingRule.InternalIP}
        onBlur={validate}
        errorMessage={findFirstErrorMessage(errorMessages, internalIp)}
      />
    </RowFlexAlignEndJustifyStart>
    <RowFlexAlignEndJustifyStart>
      <CheckBoxWithLabel label={"Aktiv"} onChange={setEnabledFlag} checked={forwardingRule.Enabled} />
    </RowFlexAlignEndJustifyStart>
    {errorMessagesWRTOtherRules.length > 0 && (
      <RowFlexAlignEndJustifyStart>
        <div style={{ maxWidth: "30rem" }}>
          <ErrorMessageContainer>
            {errorMessagesWRTOtherRules.map((msg, index) => (
              <div key={index} style={{ margin: "1rem 0" }}>
                {msg.message}
              </div>
            ))}
          </ErrorMessageContainer>
        </div>
      </RowFlexAlignEndJustifyStart>
    )}
    <RowAlignCenter>
      <Button text={"Avbryt"} kind={"cancel"} onClick={onClose} />
      <Button
        onClick={onSave}
        isProcessing={tag === updateStatus.tag && updateStatus.updating}
        processingText={"Lagre"}
        text={"Lagre"}
        kind={"inverted"}
      />
    </RowAlignCenter>
    <ResponseMessage updateResponse={updateResponse} tag={tag} onSuccessFullResponseCallback={onClose} />
  </Container>
);
