import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { SUBSCRIPTION_INVOICE_QUERY } from "../queries";

export function useTabData({ accountId = "", phoneNumber = "" }: { accountId?: string; phoneNumber?: string }) {
  const [selectedTab, setSelectedTab] = useState({
    accountId,
    phoneNumber,
  });

  const [fetchSubInvoices, { loading: loadingSubInvoices, data: subscriptionInvoices }] = useLazyQuery(
    SUBSCRIPTION_INVOICE_QUERY,
    {
      variables: { phoneNumber: selectedTab.phoneNumber },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (selectedTab.phoneNumber) {
      fetchSubInvoices({
        variables: { phoneNumber: selectedTab.phoneNumber },
        notifyOnNetworkStatusChange: true,
      });
    }
  }, [selectedTab.phoneNumber, fetchSubInvoices]);

  return {
    selectedTab,
    setSelectedTab,
    subscriptionInvoices,
    loadingSubInvoices,
  };
}
