import { Card } from "@telia/teddy";
import { Item, ShoppingSummaryItemProps } from "./Item";
import { List } from "./List";
import { Root } from "./Root";
export type { ShoppingSummaryItemProps };

export const ShoppingSummary = Object.assign(Root, {
  List,
  Item,
  Line: Card.Line,
});
