import { BaseMutationOptions, gql, MutationResult, useMutation } from "@apollo/client";
import { OrderOp, AdditionalSimCard } from "src/gql/graphql";

const CHANGE_SIMCARD_NICKNAME = gql`
  mutation changeSimCardDescription($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`;

export type Params = {
  phoneNumber: string;
  additionalSimCard: AdditionalSimCard;
  alias: string;
};

export type changeSimCardNickname = {
  response: MutationResult;
  runChangeSimCardNickname: ({ phoneNumber, additionalSimCard, alias }: Params) => Promise<void>;
};

export function useChangeSimCardNickname({ onCompleted, onError }: BaseMutationOptions): changeSimCardNickname {
  const [changeSimCardNickname, response] = useMutation(CHANGE_SIMCARD_NICKNAME, {
    onCompleted,
    onError,
  });
  const runChangeSimCardNickname = async ({ phoneNumber, additionalSimCard, alias }: Params) => {
    const changeSimCardNicknameInput = {
      operation: OrderOp.ModifyAdditionalProduct,
      productName: additionalSimCard?.product?.code,
      productCharacteristics: {
        featureCode: "SIM_EXISTING",
        config: [
          {
            name: "SIM",
            value: additionalSimCard.simCardNumber,
          },
          { name: "DESCRIPTION", value: alias },
        ],
      },
    };
    const simData = {
      variables: {
        phoneNumber,
        input: [changeSimCardNicknameInput],
      },
    };
    try {
      await changeSimCardNickname(simData);
    } catch (error) {
      console.error(error);
    }
  };
  return { response, runChangeSimCardNickname };
}
