import { useCallback, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { AgreementType, OrderOp } from "gql/graphql";
import { useServerCache } from "hooks/useServerCache";
import { B2B_FAMILY_DISCOUNT } from "./queries";
import { CREATE_B2C_ACCOUNT, ORDER_FAMILY_DISCOUNT } from "./mutations";
import { useNavigate } from "react-router-dom";
import { uri } from "@telia-no-min-side/utils";

const FAMILY_DISCOUNT_PRODUCT_CODE = "B2B_FAMILY_OFFER";
const FIVE_MINUTES_IN_SECONDS = 60 * 5;
const FIVE_SECONDS_IN_MILLISECONDS = 5000;

export function useB2BFamilyDiscountFlow(props?: { selectedB2cAccountId?: string }) {
  const navigate = useNavigate();
  const { data, loading, startPolling, stopPolling } = useQuery(B2B_FAMILY_DISCOUNT);
  const [createB2CAccountMutation, { called: isCreateB2CAccountMutationCalled }] = useMutation(CREATE_B2C_ACCOUNT);
  const [orderDiscountMutation, { called: isOrderDiscountMutationCalled }] = useMutation(ORDER_FAMILY_DISCOUNT);

  const accountsAfterCreationCache = useServerCache("NUMBER_OF_ACCOUNTS_AFTER_CREATION", {
    lifetimeInSeconds: FIVE_MINUTES_IN_SECONDS,
  });
  const orderDiscountCacheAccountId = useServerCache("ORDER_B2C_DISCOUNT_ACCOUNT_ID", {
    lifetimeInSeconds: FIVE_MINUTES_IN_SECONDS,
  });

  const selectSubscriptionPricePlanCache = useServerCache("SELECTED_SUBSCRIPTION_PRICE_PLAN", {
    lifetimeInSeconds: FIVE_MINUTES_IN_SECONDS,
  });

  const accounts = data?.accounts || [];
  const b2cAccounts = accounts.filter((a) => a.agreementType === AgreementType.Private);
  const b2cAccountsWithSubscription = b2cAccounts.filter((a) => a.subscriptions?.length || 0 > 0);
  const b2cAccountsWithoutSubscription = b2cAccounts.filter((a) => !(a.subscriptions?.length || 0 > 0));
  const b2bAccountsWithSubscription = accounts
    .filter((a) => a.agreementType === AgreementType.Business)
    .flatMap((a) => a.subscriptions || []);

  const b2bFamilySubscription = b2bAccountsWithSubscription.find(
    (sub) => sub.additionalProducts?.some((p) => p.code === FAMILY_DISCOUNT_PRODUCT_CODE)
  );

  const b2bPhoneNumber = b2bFamilySubscription?.phoneNumber.localNumber;

  const isB2cDiscountActive = b2bFamilySubscription?.additionalProducts?.some(
    (p) => p.code === FAMILY_DISCOUNT_PRODUCT_CODE && p.presentation?.active
  );

  const needB2cAccount = !loading && b2cAccounts.length === 0;
  const needDiscountOnB2C = !loading && !needB2cAccount && !isB2cDiscountActive && !!b2bFamilySubscription;

  // If the user has ordered a discount, the b2c account id will be stored as a characteristic on the b2b subscription
  const b2cAccountConnectedToDiscount = b2bFamilySubscription?.additionalProducts
    ?.find((p) => p.code === FAMILY_DISCOUNT_PRODUCT_CODE)
    ?.characteristic?.find((c) => c?.config?.some((conf) => conf?.name === "ACCOUNT_ID"))
    ?.config?.find((conf) => conf?.name === "ACCOUNT_ID")?.value;

  // Automatically select the B2C account if there is only one or a default is provided
  const b2cAccountId =
    b2cAccountConnectedToDiscount ||
    props?.selectedB2cAccountId ||
    (b2cAccountsWithSubscription.length === 1
      ? b2cAccountsWithSubscription.find((account) => account.id)?.id
      : b2cAccountsWithoutSubscription.length === 1
      ? b2cAccountsWithoutSubscription.find((account) => account.id)?.id
      : undefined);

  const b2cAccount = b2cAccounts.find((account) => account.id === b2cAccountId);

  // This is only needed for the order discount step.
  const needToSelectB2CAccountWithoutSubscrioton =
    !loading && b2cAccountsWithoutSubscription.length > 1 && !b2cAccountId && needDiscountOnB2C;
  const needToSelectB2cAccountWithSubscription =
    !loading && b2cAccountsWithSubscription.length > 1 && !b2cAccountId && needDiscountOnB2C;

  const hasSubscription = (b2cAccount?.subscriptions?.length || 0) > 0;
  const needToSelectSubscription = !loading && !hasSubscription && !selectSubscriptionPricePlanCache.value;

  const isOrderB2cAccountInProgress =
    needB2cAccount &&
    Boolean(accountsAfterCreationCache.value) &&
    Number(accountsAfterCreationCache.value) > accounts.length;

  const isOrderB2cDiscountInProgress = needDiscountOnB2C && Boolean(orderDiscountCacheAccountId.value);

  const setError = useCallback(
    (errorMsg: string) => {
      navigate(uri.minSideFixed("/mobil/bedrift-familierabatt/feil"), { state: { errorMsg } });
      stopPolling();
    },
    [stopPolling]
  );

  const createB2CAccount = useCallback(
    async ({ b2bPhoneNumber, pricePlan }: { b2bPhoneNumber: string; pricePlan: string }) => {
      try {
        const accountsBeforeOrdering = accounts.length;
        if (isCreateB2CAccountMutationCalled) return;
        const { data } = await createB2CAccountMutation({ variables: { phoneNumber: b2bPhoneNumber } });
        const orderId = data?.accountCreate?.orderId;
        if (!orderId) {
          throw new Error("Feil ved opprettelse av B2C-konto");
        }
        // Mark that we expect a new B2C account to appear
        accountsAfterCreationCache.setValue(String(accountsBeforeOrdering + 1));
        // Save the price plan if the user leaves the page when the account is crated. So we can redirect them back to the correct page
        selectSubscriptionPricePlanCache.setValue(pricePlan);
      } catch {
        setError("En uventet feil oppstod under konto-opprettelse");
      }
    },
    [accounts, accountsAfterCreationCache, createB2CAccountMutation, setError]
  );

  const orderFamilyDiscount = useCallback(
    async ({ b2bPhoneNumber, b2cAccountId }: { b2bPhoneNumber: string; b2cAccountId: string }) => {
      try {
        if (isOrderDiscountMutationCalled) return;
        const { data } = await orderDiscountMutation({
          variables: {
            phoneNumber: b2bPhoneNumber,
            input: [
              {
                productName: FAMILY_DISCOUNT_PRODUCT_CODE,
                operation: OrderOp.AddAdditionalProduct,
                productCharacteristics: {
                  config: [{ name: "ACCOUNT_ID", value: b2cAccountId }],
                  featureCode: "B2C_ACCOUNT_LINK",
                },
              },
            ],
          },
        });
        const orderId = data?.order?.orderId;
        if (!orderId) {
          throw new Error("Feil ved bestilling av rabatt");
        }
        orderDiscountCacheAccountId.setValue(b2cAccountId);
      } catch {
        setError("En uventet feil oppstod under rabatt-bestilling");
      }
    },
    [orderDiscountCacheAccountId, orderDiscountMutation, setError]
  );

  useEffect(() => {
    if (isOrderB2cAccountInProgress || isOrderB2cDiscountInProgress) {
      startPolling(FIVE_SECONDS_IN_MILLISECONDS);
    } else {
      stopPolling();
    }
  }, [isOrderB2cAccountInProgress, isOrderB2cDiscountInProgress]);

  return {
    isLoading: loading,
    b2cAccountId,
    b2cAccount,
    b2bPhoneNumber,
    b2cAccountsWithSubscription,
    b2cAccountsWithoutSubscription,
    needToSelectB2CAccountWithoutSubscrioton,
    needToSelectB2cAccountWithSubscription,
    needToSelectSubscription,
    cachedPricePlan: selectSubscriptionPricePlanCache.value,
    orderFamilyDiscount: {
      order: orderFamilyDiscount,
      isOrderInProgress: isOrderB2cDiscountInProgress,
      cachedAccountId: orderDiscountCacheAccountId.value,
      needToOrder: needDiscountOnB2C,
      isOrderDiscountMutationCalled,
    },
    orderB2cAccount: {
      order: createB2CAccount,
      isOrderInProgress: isOrderB2cAccountInProgress,
      needToOrder: needB2cAccount,
    },
  };
}
