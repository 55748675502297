import { Dispatch, SetStateAction, ChangeEvent } from "react";
import { Flex, Button, Icon, Skeleton } from "@telia/teddy";

export type Props = {
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  /**
   * Label you want to associate with the quantity selector.
   * This label should give information about this input.
   */
  label: string;
  minValue?: number;
  maxValue?: number;
  className?: string;
  isLoading?: boolean;
};

export function QuantitySelector({ quantity, setQuantity, label, maxValue, minValue, isLoading = false }: Props) {
  const changeQuantity = (operator: "increase" | "decrease") => {
    setQuantity((prevState) => {
      const canIncrease = operator === "increase" && (maxValue == null || prevState < maxValue);
      const canDecrease = operator === "decrease" && (minValue == null || prevState > minValue);
      if (canIncrease) return prevState + 1;
      if (canDecrease) return prevState - 1;
      return prevState;
    });
  };

  const getValueInsideMinMax = (currentValue: number, minValue?: number, maxValue?: number) => {
    if (maxValue != null && currentValue > maxValue) {
      const oneDigitValue = currentValue % 10;
      return Math.min(oneDigitValue, maxValue);
    }
    if (minValue != null && currentValue < minValue) {
      const oneDigitValue = currentValue % 10;
      return Math.max(oneDigitValue, minValue);
    }
    return currentValue;
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.valueAsNumber;
    if (isNaN(currentValue)) return setQuantity(0);

    setQuantity(getValueInsideMinMax(currentValue, minValue, maxValue));
  };

  return (
    <Flex align="center" gap="100">
      <Skeleton loading={isLoading} radius="full">
        <Button
          variant="secondary"
          aria-label="decrease quantity"
          title="Fjern"
          iconOnly
          onClick={() => changeQuantity("decrease")}
          disabled={minValue != null && quantity <= minValue}
          aria-hidden="true"
          tabIndex={-1}
        >
          <Icon name="minus" />
        </Button>
      </Skeleton>
      <Skeleton loading={isLoading}>
        <input
          style={{
            border: 0,
            fontSize: "16px",
            height: "40px",
            width: "40px",
            textAlign: "center",
          }}
          aria-label={label}
          type="number"
          min={minValue}
          max={maxValue}
          value={quantity}
          onChange={onChange}
        />
      </Skeleton>
      <Skeleton loading={isLoading} radius="full">
        <Button
          variant="secondary"
          aria-label="increase quantity"
          title="Legg til"
          iconOnly
          onClick={() => changeQuantity("increase")}
          disabled={maxValue != null && quantity >= maxValue}
          aria-hidden="true"
          tabIndex={-1}
        >
          <Icon name="plus" />
        </Button>
      </Skeleton>
    </Flex>
  );
}
