import { Container, Heading } from "@telia-no-min-side/components";
import { PageLoadError } from "./ErrorContent";

type Props = {
  showGoBackButton?: boolean;
};

export function Error({ showGoBackButton = true }: Props) {
  return (
    <Container maxWidth="sm" padding showGoBackButton={showGoBackButton}>
      <Heading tag="h2" variant="title-500">
        Vi beklager
      </Heading>
      <PageLoadError />
    </Container>
  );
}
