export type DELIVERY_TYPE = "poboxAddress" | "urbanAddress";

export enum DELIVERY_OPTION {
  PO_BOX_ADDRESS = "poboxAddress",
  URBAN_ADDRESS = "urbanAddress",
}

export enum ADDRESS_MESSAGE {
  REQUIRED_FIELD = "Dette feltet må fylles ut",
  NUMERIC_FIELD = "Dette feltet kan kun inneholde tall",
  ZIP_CODE_ERROR = "Postnummer må bestå av fire siffer",
  ADDRESS_IS_UPDATED = "Adressen din er oppdatert!",
  SAVE_ADDRESS = "Lagre adresse",
  CHANGE_YOUR_DELIVERY_ADDRESS = "Endre din leveringsadresse",
  CONTACT_INFO_CHANGE_SUCCESS = "Din kontaktinformasjon er nå endret!",
  CONTACT_INFO_CHANGE_ERROR = "Vi klarte dessverre ikke oppdatere din kontaktinformasjon.",
  INVALID_EMAIL = "E-postadressen er ugyldig",
  VALID_EMAIL_REQUIRED = "Feltet må inneholde en gyldig e-postadresse",
  SUCCESS = "success",
  ERROR = "error",
}

export enum URBAN_ADDRESS_FIELD_EN {
  EMAIL_ADDRESS = "emailAddress",
  STREET_NAME = "streetName",
  STREET_NUMBER = "streetNr",
  STREET_NUMBER_SUFFIX = "streetNrSuffix",
  FLOOR_NUMBER = "floorNumber",
  DOOR_NUMBER = "doorNumber",
  ZIP_CODE = "zipCode",
  POST_CODE = "postcode",
  CITY = "city",
  CARE_OF_NAME = "careOfName",
  LOCALITY = "locality",
}
export enum URBAN_ADDRESS_FIELD_NO {
  EMAIL_ADDRESS = "E-post",
  STREET_ADDRESS = "Gateadresse",
  STREET_NAME = "Gatenavn",
  STREET_NUMBER = "Gatenummer",
  STREET_NUMBER_SUFFIX = "Bokstav",
  FLOOR_NUMBER = "Etasje",
  DOOR_NUMBER = "Leilighetsnummer",
  ZIP_CODE = "Postnummer",
  CITY = "Sted",
  CARE_OF_NAME = "C/O-adresse",
  LOCALITY = "Lokalitet",
}

export enum PO_BOX_FIELD_EN {
  BOX_NAME = "boxName",
  BOX_NUMBER = "boxNr",
  ZIP_CODE = "zipCode",
  CITY = "city",
}
export enum PO_BOX_FIELD_NO {
  ADDRESS = "Postboksadresse",
  BOX_NAME = "Postboksnavn",
  BOX_NUMBER = "Postboksnummer",
  ZIP_CODE = "Postnummer",
  CITY = "Sted",
}

export enum USER_ADDRESS_FIELD_NO {
  FIRST_NAME = "Fornavn",
  SURNAME = "Etternavn",
  STREET_NAME = "Adresse",
  STREET_NUMBER = "Gatenummer",
  STREET_NUMBER_SUFFIX = "Bokstav",
  FLOOR_NUMBER = "Etasje",
  DOOR_NUMBER = "Leilighetsnummer",
  BOX_NAME = "Postboksnavn",
  BOX_NUMBER = "Postboksnummer",
  POST_CODE = "Postnummer",
  CITY = "Sted",
  CARE_OF_NAME = "C/O-adresse",
}

export enum USER_ADDRESS_FIELD_EN {
  FIRST_NAME = "firstName",
  SURNAME = "surname",
  STREET_NAME = "streetName",
  STREET_NUMBER = "streetNr",
  STREET_NUMBER_SUFFIX = "streetNrSuffix",
  FLOOR_NUMBER = "floorNumber",
  DOOR_NUMBER = "doorNumber",
  BOX_NAME = "boxName",
  BOX_NUMBER = "boxNr",
  POST_CODE = "postcode",
  CITY = "city",
  CARE_OF_NAME = "careOfName",
}
