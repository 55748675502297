import React, { ReactNode } from "react";
import { Card } from "..";
import { Heading } from "../Heading";
import { Breakpoints } from "../utils/breakpoints";

export type WrapperProps = {
  title?: string;
  maxWidth?: Breakpoints;
  children: ReactNode;
  dataTrackingID?: string;
};

export function Wrapper(props: WrapperProps) {
  const { title = "Ordresammendrag", maxWidth = "xxs", dataTrackingID, children } = props;
  return (
    <Card dataTrackingID={dataTrackingID} maxWidth={maxWidth} tag="article">
      <Heading variant="title-200" tag="h2" textAlign="center">
        {title}
      </Heading>
      {children}
    </Card>
  );
}
