import { Accordion, Container } from "@telia-no-min-side/components";
import { AvtalegiroAccordion } from "./accordion/Avtalegiro";
import { Efaktura } from "./accordion/Efaktura";
import { ForgotToPayAccordion } from "./accordion/ForgotToPay";
import { HighPriceAccordion } from "./accordion/HighPrice";
import { InvoiceCopyAccordion } from "./accordion/InvoiceCopy";
import { InvoiceFeeAccordion } from "./accordion/InvoiceFee";
import { PaidEmployerAccordion } from "./accordion/PaidEmployer";
import { Papirfaktura } from "./accordion/Papirfaktura";
import { PaymentDeferralAccordion } from "./accordion/PaymentDeferral";
import { PostSmsAccordion } from "./accordion/PostSms";
import { ShippingCostAccordion } from "./accordion/ShippingCost";
import { SubBlockedAccordion } from "./accordion/SubBlocked";
import { VippsPaymentAccordion } from "./accordion/VippsPayment";

export const FAQAccordion = () => {
  return (
    <Accordion>
      <Container flexDirection="column" gap>
        <HighPriceAccordion />
        <Efaktura />
        <Papirfaktura />
        <InvoiceFeeAccordion />
        <ShippingCostAccordion />
        <AvtalegiroAccordion />
        <InvoiceCopyAccordion />
        <ForgotToPayAccordion />
        <PaymentDeferralAccordion />
        <SubBlockedAccordion />
        <PaidEmployerAccordion />
        <VippsPaymentAccordion />
        <PostSmsAccordion />
      </Container>
    </Accordion>
  );
};
