import { Container } from "@telia-no-min-side/components";
import styled from "styled-components";

export const Grid = styled(Container)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  & > * {
    :last-of-type {
      justify-self: end;
    }
  }
`;
