import { graphql } from "src/gql";

export const subscriptionAgreementsQuery = graphql(`
  query SubscriptionAgreements($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      roles
      switchAgreements(loadInsuranceInfo: true) {
        status
        id
        phoneNumber {
          localNumber
        }
        insurance {
          name
          type
        }
        ...SwitchAgreement
      }
      insuranceAgreements {
        serviceAgreementId
        productLevel
        product
        ...InsuranceAgreement
      }
      leaseAgreements {
        leases {
          id
          phoneNumber {
            localNumber
          }
          ...LeaseAgreement
        }
      }
    }
  }
`);
