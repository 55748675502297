import { Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { SwitchAgreementFieldsFragment } from "gql/graphql";
import { Lozenge } from "@telia/styleguide";
import { HeaderTop } from "../styles/SwitchInsurance.styled";

type Props = {
  insurance: SwitchAgreementFieldsFragment["insurance"];
  isScreenInsurance: boolean;
};

export function SwitchInsuranceHeader({ insurance, isScreenInsurance }: Props) {
  const hasDiscount = insurance?.price?.priceWithDiscount !== insurance?.price?.value;
  return (
    <HeaderTop>
      <Container gap flexDirection="row">
        <Container gap="sm" flexDirection="column">
          <Heading tag="h2" variant="title-150">
            {insurance?.name}
          </Heading>
          {isScreenInsurance && <Lozenge label="Inkludert" status="positive" type="square" icon="check-circle" />}
        </Container>
        <Container gap flexDirection="row" justifyContent="flex-end">
          <Paragraph textDecorationLine={hasDiscount ? "line-through" : undefined} data-di-mask>
            <em>{insurance?.price?.value ?? 0},-/md.</em>
          </Paragraph>
          {hasDiscount && (
            <Paragraph>
              <em>{insurance?.price?.priceWithDiscount ?? 0},-/md.</em>
            </Paragraph>
          )}
        </Container>
      </Container>
    </HeaderTop>
  );
}
