import { useQuery } from "@apollo/client";
import { Badge, Box, Button, Flex, Icon, ProgressBar, Skeleton, Text } from "@telia/teddy";
import { SubCurrentInvoiceQueryQuery, SubCurrentInvoiceQueryQueryVariables } from "gql/graphql";
import { useMobileAccount } from "hooks/useMobileAccount";
import { SUBSCRIPTION_CURRENT_INVOICE_QUERY } from "pages/invoice/queries";
import { getExtraSpendingsValue } from "../../utils/getExtraSpendings";

type Props = {
  limit: number;
  isLegalOwner: boolean;
  onEditInput: () => void;
  onDeactivate: () => void;
};

export function HintProgress({ limit, isLegalOwner, onEditInput, onDeactivate }: Props) {
  const { phoneNumber } = useMobileAccount();

  const { loading, data } = useQuery<SubCurrentInvoiceQueryQuery, SubCurrentInvoiceQueryQueryVariables>(
    SUBSCRIPTION_CURRENT_INVOICE_QUERY,
    {
      variables: {
        phoneNumber,
      },
    }
  );

  const spendings = getExtraSpendingsValue(data);
  const remaining = limit - spendings;

  const getProgress = () => {
    if (!limit || remaining < 0) return 0;
    return (remaining / limit) * 100;
  };

  return (
    <Flex key="hint-progress" direction="column" gap="200">
      <Box>
        <Flex justify="between" pb="50">
          <Skeleton loading={loading}>
            <Text>Din beløpsgrense</Text>
          </Skeleton>
          <Skeleton loading={loading}>
            <Text>{`${remaining > 0 ? remaining : 0} kr igjen av ${limit} kr`}</Text>
          </Skeleton>
        </Flex>
        <Skeleton loading={loading}>
          <ProgressBar progress={getProgress()} />
        </Skeleton>
      </Box>
      {remaining <= 0 && (
        <Box>
          <Badge variant="warning">Din beløpsgrense er nådd</Badge>
        </Box>
      )}

      {isLegalOwner && (
        <Flex justify="end" align="start" gap="200">
          <Skeleton loading={loading} radius="full">
            <Button variant="secondary" size="sm" onClick={onEditInput}>
              <Icon name="edit" />
              Endre beløp
            </Button>
          </Skeleton>
          <Skeleton loading={loading} radius="full">
            <Button variant="secondary" size="sm" onClick={onDeactivate}>
              Fjern sperre
            </Button>
          </Skeleton>
        </Flex>
      )}
    </Flex>
  );
}
