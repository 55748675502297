import { HorizontalScrollScrollBy } from ".";

export function scrollElementToCenter(element: HTMLElement, scrollBy: HorizontalScrollScrollBy) {
  const parentElement = element.offsetParent;

  if (!parentElement) {
    return;
  }

  // Calculate the element's and viewport's center positions
  const elementRect = element.getBoundingClientRect();
  const parentRect = parentElement.getBoundingClientRect();
  const elementCenterX = elementRect.left + elementRect.width / 2;
  const viewportCenterX = parentRect.left + parentRect.width / 2;

  const scrollOffset = elementCenterX - viewportCenterX;
  if (scrollOffset === 0) {
    return;
  }

  // Ensure the scroll is within the bounds of the scrollable area
  const maxScrollRight = parentElement.scrollWidth - parentElement.scrollLeft - parentElement.clientWidth;
  const maxScrollLeft = -parentElement.scrollLeft;
  const scrollDistance =
    scrollOffset > 0 ? Math.min(scrollOffset, maxScrollRight) : Math.max(scrollOffset, maxScrollLeft);

  // Execute the scrolling action
  scrollBy({ scrollDistance });
}
