import { Paragraph, TrackClickEvent } from "@telia-no-min-side/components";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { MobileSubscriptionAccordionItem } from "src/components/mobile-subscription-accordion-item";
import { get5GSpeedText } from "src/util/format-subscription/get5GSpeedText";
import { getName } from "src/util/format-subscription/getName";
import { hasUnlimitedData } from "src/util/format-subscription/hasUnlimitedData";
import { getDownloadSpeed } from "util/format-subscription/getDownloadSpeed";
import { getIncludedProperties } from "util/format-subscription/getIncludedProperties";
import { getOfferingPrices } from "util/format-subscription/getOfferingPrices";
import { hasLinesPrice } from "util/format-subscription/hasLinesPrice";
import { hasStartDiscountOnAddSubscription } from "util/format-subscription/hasStartDiscount";
import { isUngSubscription } from "util/format-subscription/isUngSubscription";
import { SpeedOrDataBenefit } from "../../mobile/mobile-subscription-change/components/SpeedOrDataBenefit";
import { ComponentProps } from "react";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";
import { getJuniorSummerCampaignDataForOffer } from "../../mobile/mobile-subscription-change/change-mobile/utils/getJuniorSummerCampaignData";
import { JuniorSummerCampaignTitle } from "../../mobile/mobile-subscription-change/components/JuniorSummerCampaignTitle";
import { JuniorSummerCampaignBanner } from "../../mobile/mobile-subscription-change/components/JuniorSummerCampaignBanner";
import { data } from "./data";
import { Button } from "@telia/teddy";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { uri } from "@telia-no-min-side/utils";

export function OfferingAccordionItem({
  userOffering,
  hasOneOrMoreSubs,
  hideAction = false,
  additionalText,
}: {
  userOffering: (typeof data.account.offerings)[number];
  hasOneOrMoreSubs: boolean;
  hideAction?: boolean;
  additionalText?: string;
}) {
  const [urlSearchParams] = useSearchParams();
  const params = useParams<{ b2bPhoneNumber: string }>();
  const hasFamilyDiscount = true; //urlSearchParams.get("bedrift-familierabatt") === "true";
  const b2cAccountId = urlSearchParams.get("b2cAccountId");

  if (!userOffering) return <Paragraph removeMargin>Klarte ikke hente data</Paragraph>;

  const offeringPrices = getOfferingPrices(userOffering.offeringPrices);
  const name = getName(offeringPrices, userOffering.shortName);
  const downloadSpeed = getDownloadSpeed(offeringPrices);
  const hasUnlimitedDataBenefit = hasUnlimitedData(userOffering.additionalProperties);
  const hasStartDiscount = hasStartDiscountOnAddSubscription(userOffering.pricePlan);

  // this data is hardcoded. aka [0] is ok here
  const priceMonthly =
    hasFamilyDiscount && hasStartDiscount
      ? (userOffering.offeringPrices?.[0]?.offeringPrices?.find((op) => op?.code === "MONTHLY_CHARGE_LINE")?.amount ||
          0) - 50
      : hasFamilyDiscount
      ? userOffering.offeringPrices?.[0]?.offeringPrices?.find((op) => op?.code === "MONTHLY_CHARGE_LINE")?.amount
      : userOffering.monthlyCost?.amount;

  const includedProperties = getIncludedProperties(userOffering?.additionalProperties);
  const linesPriceEnabled = hasLinesPrice(userOffering.monthlyCost) || hasFamilyDiscount;
  const isUngSub = isUngSubscription(userOffering?.additionalProperties);
  const hasJuniorSummerCampaign = isJuniorSummerCampaign(userOffering?.pricePlan || "");
  const { formattedBaseDataAmount, formattedTotalDataAmount } = getJuniorSummerCampaignDataForOffer(
    userOffering?.pricePlan || "",
    offeringPrices,
    includedProperties
  );

  const tracking = {
    new_subscription: userOffering.shortName || "",
    new_subscription_price: userOffering.originalPrice?.amount || "",
  };

  const getLozenge = (): ComponentProps<typeof MobileSubscriptionAccordionItem>["lozengeRight"] => {
    if (hasJuniorSummerCampaign) {
      // Family benefit is not applicable for junior summer campaign
      return undefined;
    }

    if (!isUngSub && linesPriceEnabled) {
      return { label: "Familierabatt", status: "communication" };
    }

    if (hasOneOrMoreSubs && !linesPriceEnabled) {
      return { label: "Gir familierabatt", status: "communication" };
    }

    return undefined;
  };

  const overLine = (
    <SpeedOrDataBenefit
      downloadSpeed={downloadSpeed}
      hasUnlimitedData={hasUnlimitedDataBenefit}
      showDownloadSpeed={!!downloadSpeed}
      removeMargin={true}
    />
  );

  const juniorSummerCampaignTitle = hasJuniorSummerCampaign ? (
    <JuniorSummerCampaignTitle baseDataAmount={formattedBaseDataAmount} totalDataAmount={formattedTotalDataAmount} />
  ) : undefined;

  return (
    <MobileSubscriptionAccordionItem
      subscriptionName={name?.length ? name : "Klarte ikke hente navn"}
      customSubscriptionName={juniorSummerCampaignTitle}
      hasDoubleData={false}
      hasDoubleSpeed={false}
      overLine={overLine}
      customPeakContent={hasJuniorSummerCampaign ? <JuniorSummerCampaignBanner /> : undefined}
      additionalText={
        additionalText?.length
          ? additionalText
          : isUngSub
          ? "For deg under 30 år"
          : hasStartDiscount
          ? "50,- i rabatt de første 12 md. uten binding"
          : undefined
      }
      includedProperties={[
        ...includedProperties,
        ...(hasUnlimitedDataBenefit && downloadSpeed
          ? [{ code: "5g", text: get5GSpeedText(downloadSpeed), icon: "five-g", showInPeak: false } as const]
          : []),
      ]}
      priceMonthly={priceMonthly}
      originalPriceMonthly={userOffering.originalPrice?.amount}
      lozengeRight={getLozenge()}
      dataTrackingID={`change-subscription-offering-${(name || "").replaceAll(" ", "_")}`}
    >
      {hideAction ? null : (
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: `link_click ${JSON.stringify(tracking)}`,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.B2B2C_FAMILY_DISCOUNT,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: window.location.pathname,
            ui_item_text: TRACK_EVENT.ITEM_TEXT.BUY_NEW_SUBSCRIPTION,
          }}
        >
          <Button asChild ml="auto">
            <Link
              to={
                b2cAccountId
                  ? uri.minSideFixed(
                      `/mobil/bedrift-familierabatt/${params.b2bPhoneNumber}/${userOffering.pricePlan}/bestill?b2cAccountId=${b2cAccountId}`
                    )
                  : uri.minSideFixed(
                      `/mobil/bedrift-familierabatt/${params.b2bPhoneNumber}/${userOffering.pricePlan}/bestill`
                    )
              }
            >
              Velg
            </Link>
          </Button>
        </TrackClickEvent>
      )}
    </MobileSubscriptionAccordionItem>
  );
}
