export function getSimplePriceText(price: number | undefined, chargePeriod?: string, currency = "kr"): string {
  if (typeof price !== "number") return "Klarte ikke hente pris";
  const priceWithTwoDecimals = (price || 0).toFixed(2);
  const [integerDot, decimalDot] = priceWithTwoDecimals.split(".");
  const [integerComma, decimalComma] = priceWithTwoDecimals.split(",");
  const integer = decimalComma ? integerComma : integerDot;
  const decimal = decimalComma ? decimalComma : decimalDot;

  if (decimal === "00") {
    if (!chargePeriod) {
      return `${integer},-`;
    }
    return `${integer},-/${chargePeriod}`;
  }

  return chargePeriod
    ? `${integer},${decimal}${currency ? ` ${currency}` : ""}/${chargePeriod}`
    : `${integer},${decimal}`;
}
