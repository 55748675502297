import { useQuery } from "@apollo/client";
import { uri } from "@telia-no-min-side/utils";
import { Navigate, useSearchParams } from "react-router-dom";
import { graphql } from "src/gql";
import { isPricePlanMobileBroadband } from "src/util/format-subscription/isPricePlanMobileBroadband";
import {
  DATA_UNLIMITED_ADDITIONAL_PROPERTY,
  MAX_AGE_VALUE_JUNIOR,
  PRODUCT_USER_MAX_AGE_ADDITIONAL_PROPERTY,
} from "src/util/format-subscription/variables";
import { SkeletonPage } from "../mobile-subscription-add/Skeleton";

export const recommendedKeySearchParam = "rec";
export const recommendedValueSearchParam = "TELIA_X_START.REGULAR";

export const CHANGE_SUB_REDIR_QUERY = graphql(`
  query ChangeSubRedir {
    subscriptions {
      phoneNumber {
        localNumber
      }
      account {
        id
      }
      userOffering {
        pricePlan
        additionalProperties {
          name
          value
        }
      }
    }
  }
`);

export function MobileSubscriptionChangeRedir() {
  const changeSub = useQuery(CHANGE_SUB_REDIR_QUERY);
  const [search] = useSearchParams();
  const preSelectPricePlan = search.get(recommendedKeySearchParam);

  const validMobilSubs = changeSub.data?.subscriptions?.filter((subscription) => {
    if (isPricePlanMobileBroadband(subscription?.userOffering?.pricePlan)) return false;
    const additionalProps = subscription?.userOffering?.additionalProperties || [];
    const isJunior = additionalProps.some(
      (prop) => prop?.name === PRODUCT_USER_MAX_AGE_ADDITIONAL_PROPERTY && prop.value === MAX_AGE_VALUE_JUNIOR
    );
    return !isJunior;
  });

  const bucketSubscriptions = validMobilSubs?.filter((subscription) => {
    const additionalProps = subscription?.userOffering?.additionalProperties || [];
    const hasDataUnlimited = additionalProps.some(
      (prop) => prop?.name === DATA_UNLIMITED_ADDITIONAL_PROPERTY && prop.value === "true"
    );
    return !hasDataUnlimited;
  });
  const unlimitedSubscriptions = validMobilSubs?.filter((subscription) => {
    const additionalProps = subscription?.userOffering?.additionalProperties || [];
    const hasDataUnlimited = additionalProps.some(
      (prop) => prop?.name === DATA_UNLIMITED_ADDITIONAL_PROPERTY && prop.value === "true"
    );
    return hasDataUnlimited;
  });

  if (changeSub.loading && !changeSub.data) {
    return <SkeletonPage />;
  }

  if (!bucketSubscriptions?.length && !unlimitedSubscriptions?.length) {
    return <Navigate replace to={uri.minSideFixed("/hjem")} />;
  }
  if (bucketSubscriptions?.length !== 1 && unlimitedSubscriptions?.length !== 1) {
    return <Navigate replace to={uri.minSideFixed("/mobil/mine-abonnement")} />;
  }

  const bucketSubscription = bucketSubscriptions?.find((sub) => sub?.phoneNumber.localNumber);
  if (bucketSubscriptions?.length === 1 && bucketSubscription) {
    const { localNumber } = bucketSubscription.phoneNumber;
    const pathname = uri.minSideFixed(`/mobil/endre/${bucketSubscription.account.id}/${localNumber}`);
    const searchParams = preSelectPricePlan?.length ? preSelectPricePlan.toUpperCase() : recommendedValueSearchParam;
    return <Navigate replace to={{ pathname, search: `?${recommendedKeySearchParam}=${searchParams}` }} />;
  }

  const unlimitedSubscription = unlimitedSubscriptions?.find((sub) => sub?.phoneNumber.localNumber);
  if (unlimitedSubscriptions?.length === 1 && unlimitedSubscription) {
    const { localNumber } = unlimitedSubscription.phoneNumber;
    const pathname = uri.minSideFixed(`/mobil/endre/${unlimitedSubscription.account.id}/${localNumber}`);
    const searchParams = preSelectPricePlan?.length ? preSelectPricePlan.toUpperCase() : recommendedValueSearchParam;
    return <Navigate replace to={{ pathname, search: `?${recommendedKeySearchParam}=${searchParams}` }} />;
  }

  return <Navigate replace to={uri.minSideFixed("/hjem")} />;
}
