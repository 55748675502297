import { Container } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { Button, Flex, Heading, Icon, Text } from "@telia/teddy";
import { CustomerCareLink } from "components/customer-care-link";
import { Link, useLocation } from "react-router-dom";

export function Error({ showRetryButton = true }: { showRetryButton?: boolean }) {
  const { state } = useLocation();

  return (
    <Container flexDirection="column" floatContent="left" gap="lg" padding maxWidth="md">
      <Heading as="h1" variant="title-300">
        Noe gikk galt!
      </Heading>

      <Text>
        Vi kunne dessverre ikke behandle forespørselen din og fikk heller ikke opprettet en manuell sak for oppfølging.
        Vennligst prøv igjen, eller kontakt vår kundeservice:
      </Text>
      <CustomerCareLink trackingContext="b2b2c-family-discount" />

      {state?.errorMsg ? <Text>{state.errorMsg}</Text> : null}

      <Flex gap="300">
        {showRetryButton ? (
          <Button asChild>
            <Link to={uri.minSideFixed("/mobil/bedrift-familierabatt")}>
              <Icon name="sync" />
              Prøv igjen
            </Link>
          </Button>
        ) : null}

        <Button variant={showRetryButton ? "secondary" : "primary"} asChild>
          <Link to="/minside/hjem">
            <Icon name="home" />
            Tilbake til hjem
          </Link>
        </Button>
      </Flex>
    </Container>
  );
}
