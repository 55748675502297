import { FakeLogin } from "./FakeLogin";

export function FakeLoginAs() {
  // TODO: use VITE flag instead
  const isFixedLocalhost = window.location.host === "localhost.telia.no:9002";

  if (!isFixedLocalhost) {
    return null;
  }

  return <FakeLogin />;
}
