import { Container } from "@telia-no-min-side/components";
import { Button, Card, Text, TextSpacing } from "@telia/teddy";
import { Link } from "react-router-dom";

export function TempAccessPage() {
  return (
    <Container showGoBackButton padding flexDirection="column" gap maxWidth="sm">
      <Card shadow>
        <TextSpacing>
          <Card.Heading>Slik fungerer det</Card.Heading>
          <Card.Content>
            <TextSpacing>
              <Text>For at du skal få tilgang til dine produkter må du verifisere deg med engangskode.</Text>
              <Text>
                Du får tilgang til det abonnementet som tilhører nummeret du verifiserer. Har du flere abonnementer må
                du verifisere numrene det gjelder.
              </Text>
              <Text>Tilgangen varer i 12 måneder. Etter dette må man utføre verifiseringen på nytt.</Text>
              <Button asChild variant="primary">
                <Link to="/minside/midlertidig-tilgang/bekrefte">Fortsett</Link>
              </Button>
            </TextSpacing>
          </Card.Content>
        </TextSpacing>
      </Card>
    </Container>
  );
}
