import React from "react";
import { track, uri } from "@telia-no-min-side/utils";
import { Button } from "../Button";
import { Container } from "../Container";
import { Heading } from "../Heading";
import { List, ListItem } from "../List";
import { Paragraph } from "../Paragraph";
import { BaseCard, CardMainText } from "./style";

type Props = {
  trackEvent?: track.GenericEvent;
  dashboardCard?: boolean;
};

export function UpsellMobileCard({ trackEvent, dashboardCard = false }: Props) {
  return (
    <BaseCard dashboardCard={dashboardCard} backgroundColor="light-purple">
      <CardMainText tag="h3" variant="title-100">
        Ikke mobilkunde ennå?
      </CardMainText>

      <Paragraph variant="additional-100">
        Ved å samle både TV, internett og mobil får du mange fordeler med Telia!
      </Paragraph>

      <Heading tag="h4" variant="subsection-100">
        Fordeler
      </Heading>

      <Container padding="vertical">
        <List>
          <ListItem icon="devices">
            <em>Ekstra data</em> <br />
            <span>Dobbel data/hastighet på mobilen, hver måned.</span>
          </ListItem>

          <ListItem icon="wireless">
            <em>Surfegaranti</em> <br />
            <span>Alltid på nett med Data Boost.</span>
          </ListItem>

          <ListItem icon="tv-simple">
            <em>Flere TV-poeng</em> <br />
            <span>10 ekstra TV-poeng til fritt valg av kanaler og strømmetjenester.</span>
          </ListItem>
        </List>
      </Container>

      <Button
        tag="a"
        target="_blank"
        trackEvent={trackEvent}
        href={uri.openPages("/mobilabonnement/ubegrenset-data/?ts=true")}
        variant="text-purple"
        floatRight
      >
        Bli mobilkunde
      </Button>
    </BaseCard>
  );
}
