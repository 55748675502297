import { getFragmentData } from "src/gql";
import { UserRole, GetAccountAgreementsQuery, SwitchAgreementFieldsFragment } from "src/gql/graphql";
import { SWITCH_AGREEMENT_FRAGMENT } from "../graphql/switchAgreementFragment";
import { DUMMY_SCREEN_INSURANCE_PRODUCT } from "util/constants/agreements";

type InsuranceAgreement = {
  __typename?: "InsuranceAgreement" | undefined;
  product?: string | null | undefined;
  serviceAgreementId?: number | null | undefined;
};

type InsuranceAgreements =
  | {
      insuranceAgreements: InsuranceAgreement[] | null | undefined;
      phoneNumber: string;
    }[]
  | undefined;

// NOTE: isBanLevelOnly will be used to correctly show if customer has switch as a stand-alone agreement (no phone number attached to it)
export type SwitchAgreements = SwitchAgreementFieldsFragment[] &
  {
    productUserName?: string;
    accountId: string;
    wantToContinueScreenInsurance: boolean;
    isBanLevelOnly: boolean;
    isLegalOwner: boolean;
    hasSubscriptions: boolean;
  }[];

export type SwitchAgreement = SwitchAgreements[number];

export type MobileAgreement = { type: "SwitchAgreement"; accountId: string; data: NonNullable<SwitchAgreement> };

function isInsuranceProductPresent(
  phoneNumber: string,
  product: string,
  insuranceAgreements: InsuranceAgreements
): boolean {
  if (!insuranceAgreements) return false;
  return insuranceAgreements.some(
    (agreement) =>
      agreement.phoneNumber === phoneNumber &&
      agreement.insuranceAgreements?.some((detail) => detail.product === product)
  );
}

function productByPhoneNumber(insuranceAgreements: InsuranceAgreements, product: string) {
  return function (phoneNumber: string): boolean {
    return isInsuranceProductPresent(phoneNumber, product, insuranceAgreements);
  };
}

export function getAllUniqueSwitchAgreements(account: GetAccountAgreementsQuery["account"]) {
  const switchAgreements = new Map<string, SwitchAgreement>();

  if (!account) return switchAgreements;
  const hasSubscriptions = (account.subscriptions && account?.subscriptions?.length > 0) || false;

  const insuranceAgreements =
    account.subscriptions?.map((sub) => ({
      insuranceAgreements: sub.insuranceAgreements,
      phoneNumber: sub.phoneNumber.localNumber,
    })) || [];

  // if INSURANCE_SWITCH_SCREEN_DUMMY_EXISTING is present, they want to continue with screen insurance after switch
  const hasDummyProduct = productByPhoneNumber(insuranceAgreements, DUMMY_SCREEN_INSURANCE_PRODUCT);

  const switchAgreementsD = getFragmentData(SWITCH_AGREEMENT_FRAGMENT, account.switchAgreements);

  switchAgreementsD?.forEach((agreement) => {
    if (!switchAgreements.has(agreement.id)) {
      switchAgreements.set(agreement.id, {
        hasSubscriptions,
        isLegalOwner: account.roles.includes(UserRole.LegalOwner),
        isBanLevelOnly: !agreement.phoneNumber?.localNumber.length,
        ...agreement,
        productUserName: account.legalOwner?.individual?.firstName,
        accountId: account.id,
        wantToContinueScreenInsurance: hasDummyProduct(agreement.phoneNumber?.localNumber || ""),
      });
    }
  });

  account.subscriptions?.forEach((subscription) => {
    getFragmentData(SWITCH_AGREEMENT_FRAGMENT, subscription.switchAgreements)?.forEach((agreement) => {
      switchAgreements.set(agreement.id, {
        hasSubscriptions,
        isLegalOwner: subscription.roles.includes(UserRole.LegalOwner),
        isBanLevelOnly: false,
        ...agreement,
        productUserName: subscription.productUser?.firstName,
        accountId: account.id,
        wantToContinueScreenInsurance: hasDummyProduct(agreement.phoneNumber?.localNumber || ""),
      });
    });
  });

  return switchAgreements;
}
