import { Heading, Flex, Box } from "@telia/teddy";
import { NavigationCard, Props as NavigationCardProps } from "components/NavigationCard";

import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function AdditionalInfo() {
  const additionalInfoItems: NavigationCardProps[] = [
    {
      title: "Trygg på nett",
      description: "Trygg på nett er din digitale sikkerhetspakke.",
      icon: "secured-1",
      link: "tilleggstjenester",
    },
    {
      title: "Ta med deg internett overalt!",
      description: "Med mobilt bredbånd får du raskt internett, der du er.",
      icon: "sense-car",
      link: EXTERNAL_LINK.INTERNET_MOBILE_BROADBAND,
    },
    {
      title: "Hjelpesider internett",
      description: "Sjekk ut våre guider hvis du har problemer med internett",
      icon: "question",
      link: EXTERNAL_LINK.INTERNET_HELP,
      cardVariant: "purple-light",
    },
  ];

  return (
    <Box mt="600" mb="200">
      <Heading as="h2" variant="title-100">
        Utforsk
      </Heading>
      <Flex mt="200" gap="400" wrap="wrap">
        {additionalInfoItems.map(({ title, description, icon, link, cardVariant }) => (
          <NavigationCard
            key={title}
            cardVariant={cardVariant}
            title={title}
            description={description}
            icon={icon}
            link={link}
            width={{ sm: "100%", md: "45%", lg: "33.33%" }}
          />
        ))}
      </Flex>
    </Box>
  );
}
