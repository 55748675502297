import { Accordion, Container, Icon, Paragraph } from "@telia-no-min-side/components";
import { IconDefinition } from "@telia/styleguide";
import { getSimplePriceText, notEmpty } from "@telia-no-min-side/utils";
import { ProductChangesData } from "../type";

type Props = {
  icon: IconDefinition;
  iconColor: string;
  title: string;
  subTitle: string;
  products?: ProductChangesData[];
};

export function ProductChangesList({ icon, iconColor, title, subTitle, products }: Props) {
  if (!products?.length) return null;

  return (
    <Container>
      <Container flexDirection="row" alignItems="center" gap padding="bottom">
        <Icon icon={icon} color={iconColor} size="sm" />
        <Paragraph>
          <em>{title}</em>
        </Paragraph>
      </Container>
      <Container padding="bottom">
        <Paragraph removeMargin>{subTitle}</Paragraph>
      </Container>
      <Accordion variant="info">
        {products.filter(notEmpty).map((product) => (
          <Accordion.Item maxWidth="md" key={product.code}>
            <Accordion.Trigger>
              <Container padding={false} justifyContent="space-between" flexDirection="row">
                <Paragraph removeMargin>{product.name}</Paragraph>
                {product.priceComparison ? (
                  <Paragraph removeMargin>{getSimplePriceText(product.priceComparison.newPrice, "md.")}</Paragraph>
                ) : null}
              </Container>
            </Accordion.Trigger>
            <Accordion.Content>
              {!!product.longDescription && <Paragraph variant="additional-100">{product.longDescription}</Paragraph>}

              {!product.hidePrice &&
                (product.priceComparison ? (
                  <>
                    <Paragraph variant="additional-100">{`Ny pris for denne tjenesten vil være ${getSimplePriceText(
                      product.priceComparison.newPrice,
                      "md."
                    )}`}</Paragraph>
                    <Paragraph variant="additional-100">{`Gjeldende pris ${getSimplePriceText(
                      product.priceComparison.oldPrice,
                      "md."
                    )}`}</Paragraph>
                  </>
                ) : (
                  <Paragraph variant="additional-100">{getSimplePriceText(product.price, "md.")}</Paragraph>
                ))}
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
}
