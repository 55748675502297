import { useSearchParams } from "react-router-dom";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { InvoiceDetailsFixed } from "./fixed/details/InvoiceDetailsFixed";
import { InvoiceDetailsMobile } from "./mobile/details/InvoiceDetailsMobile";
import { SubInvoiceDetails } from "./mobile/details/SubInvoiceDetails";

export const InvoiceDetailsPage = () => {
  const [searchParams] = useSearchParams();
  const isMobile = searchParams.get("isMobile") === "true";
  const isSubLevel = searchParams.get("isSubLevel") === "true";
  const { accountId, phoneNumber } = useMobileAccount();

  return (
    <>
      {isSubLevel ? (
        <SubInvoiceDetails isMobile accountId={accountId} localNumber={phoneNumber} />
      ) : isMobile ? (
        <InvoiceDetailsMobile accountId={accountId} />
      ) : (
        <InvoiceDetailsFixed />
      )}
    </>
  );
};
