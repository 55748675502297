import { Container, Heading, Paragraph, useAuth } from "@telia-no-min-side/components";
import { useFetchUser } from "src/api/fixed";
import { ProfileInfo } from "./ProfileInfo";
import { SkeletonForm } from "../common/SkeletonForm";
import { GetAccessFixedCard } from "src/components/get-access-fixed-card";
import { FixedProfilePicker } from "src/components/profile-pickers/FixedProfilePicker";
import { AUTH } from "util/constants/auth";

export type EditMode = false | "contactInformation" | "password";
export const FORM_EDIT_KEY = "endre";
export const BANKID_ACR_VALUE = "3";

export function FixedProfilePage() {
  const user = useFetchUser();
  const { selectedAuthProvider } = useAuth();

  const selectedUser = user.data?.usersWithCustomerId.find((user) => user.selected);

  if (selectedAuthProvider === AUTH.PROVIDER.CT) {
    return (
      <Container padding gap flexDirection="column">
        <Heading tag="h2" variant="title-300">
          Din kontaktinformasjon
        </Heading>
        <GetAccessFixedCard />
      </Container>
    );
  }

  if (user.noContent) {
    return (
      <div>
        <Container maxWidth="sm" padding="top">
          <Heading tag="h2" variant="title-200">
            Din kontaktinformasjon
          </Heading>
          <Paragraph>Du har ingen kontaktinformasjon for dine TV og Internett produkter</Paragraph>
        </Container>
      </div>
    );
  }

  return (
    <>
      {(user.data?.usersWithCustomerId || []).length > 1 && (
        <>
          <Container padding="vertical">Velg adresse:</Container>
          <FixedProfilePicker dataTrackingID="profile-fixed-profile-picker" pickerVariant="primary" />
        </>
      )}
      <Container maxWidth="sm">
        <Container maxWidth="sm" padding="vertical">
          <Heading tag="h2" variant="title-200">
            Din kontaktinformasjon
          </Heading>
        </Container>
        {selectedUser ? (
          <Container padding="bottom">
            <ProfileInfo user={selectedUser} />
          </Container>
        ) : (
          <SkeletonForm />
        )}
      </Container>
    </>
  );
}
