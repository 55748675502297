import { uri, types } from "@telia-no-min-side/utils";

export function isInternalLink(application: types.fixed.Action["application"]): boolean {
  return application === "MIN_SIDE_FIXED";
}
export function getAppSpecificLink(action: types.fixed.Action): string {
  if (action.application === "MIN_SIDE_FIXED") {
    return uri.minSideFixed(action.url);
  }
  if (action.application === "channelpicker") {
    return uri.channelpicker();
  }
  if (action.application === "PURCHASE") {
    return uri.purchase(action.url);
  }
  if (action.application === "min-side-mobile") {
    return uri.minSideMobile(action.url);
  }
  return action.url;
}
