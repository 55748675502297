import { ReactNode } from "react";
import { Container, Heading, Paragraph } from "@telia-no-min-side/components";

type ErrorProps = {
  children?: ReactNode;
};

export function Error({ children }: ErrorProps) {
  return (
    <Container maxWidth="md" padding alignItems="flex-start" flexDirection="column" gap>
      <Heading tag="h2" variant="title-300">
        Noe gikk galt!
      </Heading>
      <Paragraph>
        En feil har oppstått ved lasting av innholdet! Vennligst last inn siden på nytt og prøv igjen.
      </Paragraph>
      {children}
    </Container>
  );
}
