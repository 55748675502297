import { Maybe, OfferingPriceDetails } from "gql/graphql";
import { findSpeedDownload } from "./findSpeedDownload";

// Speed for "bucket" mbb plans
// They don't have any speed property
const DEFAULT_SPEED = 100;

// Normally offeringPrices list should have a property with name 'SPEED_DOWNLOAD' and value - a number.
// But for some reason this prop is configured just for Hvilemodus, but not for other MBB plans.
// So the only way to get download speed is to extract it from longDescription.
export function getDownloadSpeedMbb(
  offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined,
  longDescription: Maybe<string> | undefined
): number | undefined {
  // try to find speed property in offeringPrices, Hvilemdous have it there
  const downloadSpeedProperty = offeringPrices?.find(findSpeedDownload);

  if (downloadSpeedProperty && typeof downloadSpeedProperty.amount === "number") {
    return downloadSpeedProperty.amount;
  }

  // most other plans have it as the last line of longDescription
  if (!longDescription) return DEFAULT_SPEED;

  const descriptionBulletPoints = longDescription?.split("\n•") || [];
  const lastBulletPoint = descriptionBulletPoints.pop() || "";

  if (!lastBulletPoint.includes("Mbit/s")) return DEFAULT_SPEED;

  const downloadSpeed = lastBulletPoint.replace(/\D/g, "");
  const numericDownloadSpeed = downloadSpeed ? Number(downloadSpeed) : undefined;

  if (typeof numericDownloadSpeed === "number") {
    return numericDownloadSpeed;
  }

  // "bucket" plans don't have speed neither in offeringPrices, nor longDescription,
  // so hardcoding
  return DEFAULT_SPEED;
}
