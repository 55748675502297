import styled from "styled-components";
import { colors, Heading } from "@telia/styleguide";

export const StyledServiceBoxWithIndent = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
`;
export const StyledServiceBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 0.875rem;
  width: 100%;
`;

export const ServiceBoxWithoutIndentHeading = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  & > span {
    font-size: 0.875rem;
    color: ${colors.corePurple500};
  }

  & > h1 {
    margin: 0;
  }
`;

export const StyledHeading = styled(Heading)`
  margin: 0 0 5px;
`;

export const ServiceBoxHeading = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  justify-content: space-between;

  & > span {
    font-size: 0.875rem;
    color: ${colors.corePurple500};
  }
`;

export const ServiceBoxHeadingTitle = styled.div`
  font-weight: 500;
`;

export const ServiceContentText = styled.div`
  font-size: 0.875rem;
  margin: 14px 0;
`;

export const ServiceBoxPrice = styled.div`
  font-size: 0.875rem;
  align-self: flex-start;
  text-align: right;
`;
