import styled from "styled-components";
import { Card, Container, Button, devices } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";

export const OutageContainer = styled(Container)`
  position: absolute;
  top: 75px;
  right: 20px;
  z-index: 5;

  @media ${devices.xxs_down} {
    right: 0;
    padding: 0 10px;
  }
`;

export const CollapsedOutageContainer = styled(Card)`
  height: 45px;
  width: 45px;
  border-radius: 50%;

  @media ${devices.md_up} {
    height: 60px;
    width: 83px;
    border-radius: 5px;
  }
`;

export const ExpandedOutageContainer = styled(Container)`
  margin: 0;
  max-width: 340px;
  animation: animateOpacity 0.3s ease;

  @keyframes animateOpacity {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const BackdropInnerContainer = styled(Container)`
  @media ${devices.md_down} {
    position: absolute;
    top: 77px;
    right: 20px;
  }
  @media ${devices.sm_down} {
    right: 17px;
  }
  @media ${devices.xxs_down} {
    right: 0;
    padding: 0 10px;
  }
`;

export const CloseButton = styled(Button)`
  height: 45px;
  width: 45px;
  background-color: ${colors.white};
`;
