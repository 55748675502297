export enum UnitSize {
  byte = "B",
  kilobyte = "KB",
  megabyte = "MB",
  megabyteLong = "MEGABYTE",
  gigabyte = "GB",
  terabyte = "TB",
}

export enum UnitSizeExponent {
  kilobyte = 1,
  megabyte = 2,
  gigabyte = 3,
}

export const UNIT_SIZE_MULTIPLIER = 1024;

type QuantityToBytesArgs = {
  unit: string | null | undefined;
  amount: number | null | undefined;
} | null;

export function quantityToBytes(quantity?: QuantityToBytesArgs) {
  if (!quantity || typeof quantity.amount !== "number" || typeof quantity.unit !== "string") return null;

  const unit = quantity.unit as UnitSize;

  if (unit === UnitSize.kilobyte) return quantity.amount * Math.pow(UNIT_SIZE_MULTIPLIER, UnitSizeExponent.kilobyte);

  if (unit === UnitSize.megabyte || unit === UnitSize.megabyteLong)
    return quantity.amount * Math.pow(UNIT_SIZE_MULTIPLIER, UnitSizeExponent.megabyte);

  if (unit === UnitSize.gigabyte) return quantity.amount * Math.pow(UNIT_SIZE_MULTIPLIER, UnitSizeExponent.gigabyte);

  return quantity.amount;
}
