import { AxiosRequestConfig } from "axios";
import useSWR, { BareFetcher, SWRConfiguration, SWRResponse } from "swr";
import { useAuth } from "../context/auth";
import { axiosGet } from "../utils/axios";

// Deprecated. Used only in a few places at apps/min-side
// Can be removed after me move code from apps/min-side

const defaultConfig: SWRConfiguration = {
  errorRetryCount: 5,
  revalidateOnFocus: false,
};

async function swrFetcher(url: string, config?: AxiosRequestConfig) {
  const response = await axiosGet(url, config);
  if (response.status === 204 || !response.data) {
    return "no-content"; // Handle 204 no content response
  }
  return response.data;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAxiosSWR<Data = any, Error = any>(
  url: string | null,
  args?: { axiosConfig?: AxiosRequestConfig<Data>; config?: SWRConfiguration<Data, Error, BareFetcher<Data>> }
): SWRResponse<Data, Error> & {
  noContent?: boolean;
  isLoading: boolean;
  isWaitingKey?: boolean;
} {
  const { axiosConfig, config } = args || {};
  const { selectedAuthProvider } = useAuth();

  const swrResponse = useSWR<Data, Error>(
    url && selectedAuthProvider !== "CT"
      ? axiosConfig?.params
        ? `${url}${JSON.stringify(axiosConfig?.params)}`
        : url
      : null,
    () => swrFetcher(url || "", axiosConfig),
    {
      ...defaultConfig,
      ...config,
    }
  );

  if (!url) {
    return {
      ...swrResponse,
      isLoading: false,
      isWaitingKey: true,
    };
  }

  const data = swrResponse.data;
  if (data === "no-content") {
    return {
      ...swrResponse,
      data: undefined,
      isLoading: false,
      noContent: true,
    };
  }

  return swrResponse;
}
