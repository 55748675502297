import { useNavigate } from "react-router-dom";
import { Card, Text, Icon } from "@telia/teddy";

export function AddSubscriptionCard() {
  const navigate = useNavigate();

  return (
    <Card
      bordered
      shadow
      width="100%"
      maxWidth="400px"
      layout="default"
      variant="white"
      onClick={() => navigate("/minside/midlertidig-tilgang")}
    >
      <Card.Heading as="h3" variant="title-100">
        Har du flere abonnement?
      </Card.Heading>
      <Card.Content>
        <Text variant="additional-100">
          Verifiser nummeret det gjelder for å få tilgang til abonnementet på Min Side.
        </Text>
      </Card.Content>
      <Card.Footer height="100%" align="end">
        <Card.Button variant="text">
          <Icon name="plus" size="sm" />
          Legg til nummer
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
