import { Link } from "react-router-dom";
import { Flex, Button, Text, Icon, FlipCard } from "@telia/teddy";
import { formatTextWithLineBreaks } from "../utils";
import { useMobileBroadbandContext } from "pages/internet/mobile-broadband/context/MobileBroadbandContext";

type Props = {
  hasExceededDataLimit?: boolean;
  showTerms?: boolean;
};
// TODO: use hasExceededDataLimit
export function ActionsSection({
  // hasExceededDataLimit = false,
  showTerms,
}: Props) {
  const { openDrawer, setDrawerContent, isSleepMode } = useMobileBroadbandContext();

  return (
    <Flex justify={showTerms ? "between" : "start"} gap={showTerms ? "100" : "300"} wrap="wrap">
      {!isSleepMode && (
        <Flex direction="column" gap="100" align="center">
          <Button variant="secondary" aria-label="bar-chart" iconOnly asChild>
            {/* TODO: add link to constants */}
            <Link to={"tidligere-dataforbruk"}>
              <Icon name="bar-chart" />
            </Link>
          </Button>
          <Text style={{ textAlign: "center" }}>{formatTextWithLineBreaks("Tidligere dataforbruk")}</Text>
        </Flex>
      )}
      {!isSleepMode && (
        <Flex direction="column" gap="100" align="center">
          <Button
            aria-label="hvilemodus"
            iconOnly
            variant="secondary"
            onClick={() => {
              openDrawer();
              setDrawerContent("Hvilemodus");
            }}
          >
            <Icon name="coffee" />
          </Button>
          <Text style={{ textAlign: "center" }}>Hvilemodus</Text>
        </Flex>
      )}
      {showTerms && (
        <Flex direction="column" gap="100" align="center">
          <FlipCard.Trigger asChild>
            <Button aria-label="info" iconOnly variant="secondary">
              <Icon name="info" />
            </Button>
          </FlipCard.Trigger>
          <Text style={{ textAlign: "center" }}>Vilkår</Text>
        </Flex>
      )}
    </Flex>
  );
}
