import { ShoppingSummary } from "@telia-no-min-side/components";
import { getSimplePriceText } from "src/util/priceFormatter";
import { SelectedFlexRequirementsPrice } from "./ShoppingCart";
import { SumPrice } from "util/prices";
import { useFlexOrder } from "pages/flex/context/FlexOrderProvider";

type Props = {
  selectedFlexRequirementsPrice: SelectedFlexRequirementsPrice;
  totalRecursiveAmount: SumPrice;
  totalOnceOffAmount: number;
};

export function ShoppingCartSummary({
  selectedFlexRequirementsPrice,
  totalRecursiveAmount,
  totalOnceOffAmount,
}: Props) {
  const { flexProductOffer } = useFlexOrder();

  return (
    <>
      <ShoppingSummary.List title="Månedspris">
        {flexProductOffer?.broadbandOption && (
          <ShoppingSummary.Item
            text={`Internett ${flexProductOffer.broadbandOption.downloadSpeed} Mbps`}
            price={getSimplePriceText(
              flexProductOffer.broadbandOption.price.currentAmount,
              flexProductOffer.broadbandOption.price.chargePeriod
            )}
          />
        )}
        {flexProductOffer?.tvOption && (
          <ShoppingSummary.Item
            text={`Strømming & TV ${flexProductOffer.tvOption.points} poeng`}
            price={getSimplePriceText(
              flexProductOffer.tvOption.price.currentAmount,
              flexProductOffer.tvOption.price.chargePeriod
            )}
          />
        )}
        {selectedFlexRequirementsPrice.recurring.map(({ text, price }) => (
          <ShoppingSummary.Item key={`${text}-${price}-recurring`} text={text} price={price} />
        ))}
        <ShoppingSummary.Item
          highlight
          text="Totalt per måned"
          additionalText="Belastes på din neste faktura"
          price={getSimplePriceText(totalRecursiveAmount?.amount, totalRecursiveAmount?.chargePeriod)}
        />
      </ShoppingSummary.List>
      <ShoppingSummary.LineSeparator />
      {(selectedFlexRequirementsPrice.onceOff.length > 0 || selectedFlexRequirementsPrice.shippingOptionDTOS) && (
        <>
          <ShoppingSummary.List title="Engangspris">
            {selectedFlexRequirementsPrice.onceOff.map(({ text, price }) => (
              <ShoppingSummary.Item key={`${text}-${price}-onceOff`} text={text} price={price} />
            ))}
            <ShoppingSummary.Item
              text="Frakt"
              additionalText={selectedFlexRequirementsPrice.shippingOptionDTOS?.description}
              price={getSimplePriceText(selectedFlexRequirementsPrice.shippingOptionDTOS?.onceOffPrice)}
            />
            <ShoppingSummary.Item
              highlight
              text="Totalt engangsbeløp"
              additionalText="Belastes på din neste faktura"
              price={getSimplePriceText(totalOnceOffAmount)}
            />
          </ShoppingSummary.List>
          <ShoppingSummary.LineSeparator />
        </>
      )}
    </>
  );
}
