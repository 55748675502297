import { GDPRErrorType } from "../components/Error";

export const GDPR_ERROR_CONTENT = {
  general: {
    heading: "Noe gikk galt!",
    paragraph:
      "Vi kunne dessverre ikke behandle forespørselen din og fikk heller ikke opprettet en manuell sak for oppfølging. Vennligst prøv igjen, eller kontakt vår kundeservice: 924 05 050",
  },
  "request-already-submitted": {
    heading: "Vi har allerede mottatt din forespørsel",
    paragraph:
      "For å behandle forespørselen din, trenger vi litt tid på oss. Det kan derfor ta opp til 30 dager før du hører fra oss igjen. Takk for din tålmodighet.",
  },
};

export function getGDPRErrorContent(errorType: string | null) {
  switch (errorType) {
    case GDPRErrorType.InProgress:
      return GDPR_ERROR_CONTENT[GDPRErrorType.InProgress];

    default:
      return GDPR_ERROR_CONTENT.general;
  }
}
