import { Flex } from "@telia/teddy";
import { Breadcrumb } from "components/Breadcrumb";
import { PageContainer } from "components/PageContainer";
import { TvTopMenu } from "components/top-menu/TvTopMenu";
import { PropsWithChildren } from "react";

export function DashboardLayout({ children }: PropsWithChildren) {
  return (
    <>
      <TvTopMenu dataTrackingID="tv-top=menu" />
      <PageContainer>
        <Breadcrumb />
        <Flex mb="400" direction="column" gap="400">
          {children}
        </Flex>
      </PageContainer>
    </>
  );
}
