import { Link } from "react-router-dom";
import { Flex, Card, Badge, Icon } from "@telia/teddy";
import { format, track, types, uri } from "@telia-no-min-side/utils";
import { getPriceText } from "src/util/priceFormatter";
import { getBadgeVariant } from "src/util/productHelper/getBadge";
import { getAppSpecificLink } from "src/util/productHelper/link";
import { useSelectAccount } from "hooks/useSelectAccount";
import { AUTH } from "util/constants/auth";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { useRef } from "react";
import { INTERNAL_LINK } from "util/constants/internalLinks";

type Props = {
  customer: types.fixed.UserWithCustomerId;
  product: types.fixed.Product;
};

export function BroadbandSubscriptionCard({ customer, product }: Props) {
  const { setAccount } = useSelectAccount();
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const additinalLinkRef = useRef<HTMLAnchorElement>(null);

  const badgeVariant = product.status ? getBadgeVariant(product.status) : "neutral";
  const changeBroadband = product.actions?.find((action) => action.type === "CHANGE");

  const handleCardClick = () => {
    setAccount({ accountType: AUTH.ACCOUNT_TYPE.FIXED, id: customer.customerId });
    pushGenericTrackingEvent({
      ui_item_action: "link_click",
      ui_item_context: "Hjem",
      ui_item_type: "card",
      ui_item_url: uri.minSideFixed(ICX_REQUEST.URL_INTERNET_EQUIPMENT),
      ui_item_text: "Ditt internettutstyr",
    });
  };

  const handleButtonClick = () => {
    setAccount({ accountType: AUTH.ACCOUNT_TYPE.FIXED, id: customer.customerId });
    pushGenericTrackingEvent({
      ui_item_action: "link_click",
      ui_item_context: "Hjem",
      ui_item_type: "card",
      ui_item_url: changeBroadband ? getAppSpecificLink(changeBroadband) : "",
      ui_item_text: "Endre hastighet",
    });
  };

  return (
    <Card
      shadow
      bordered
      width="100%"
      maxWidth="400px"
      layout="default"
      variant="white"
      onPointerUp={(e) => {
        if (additinalLinkRef.current === e.target) {
          e.preventDefault();
        }
      }}
    >
      {product.status?.localizedtitle && badgeVariant !== "success" && (
        <Card.Slot align="top-right">
          <Badge variant={badgeVariant || "neutral"} hideIcon>
            {product.status.localizedtitle}
          </Badge>
        </Card.Slot>
      )}

      <Card.Heading as="h3" variant="title-100" data-di-mask>
        {format.startCase(`${customer.street}`)}
      </Card.Heading>

      <Card.Content>
        <Flex direction="row" justify="between">
          <Icon name="speedometer" size="md">
            {product.speed && `${product.speed.down}/${product.speed.up} Mbps`}
          </Icon>
          {getPriceText(product.price)}
        </Flex>
      </Card.Content>

      <Card.Footer height="100%" align="end">
        <Flex direction="row" justify={changeBroadband ? "between" : "end"} width="100%">
          {changeBroadband && (
            <Card.Button variant="text" asChild>
              <Link ref={additinalLinkRef} to={getAppSpecificLink(changeBroadband)} onClick={handleButtonClick}>
                Endre hastighet
              </Link>
            </Card.Button>
          )}
          <Card.Button variant="text" asChild>
            <Link onClick={handleCardClick} to={INTERNAL_LINK.FIXED.BROADBAND_HOME}>
              Ditt internettutstyr
            </Link>
          </Card.Button>
        </Flex>
      </Card.Footer>
    </Card>
  );
}
