import { track } from "@telia-no-min-side/utils";
import { IconDefinition } from "@telia/styleguide";
import React, {
  BaseSyntheticEvent,
  CSSProperties,
  ComponentPropsWithoutRef,
  ElementType,
  MouseEvent,
  ReactNode,
} from "react";
import { Icon, IconProps } from "../Icon";
import { LocationState, useReactRouterHistory } from "../hooks/useReactRouterHistory";
import { LoadingDots } from "./LoadingDots";
import { StyledButton } from "./style";

export type ButtonVariant =
  | "expressive"
  | "primary"
  | "secondary"
  | "purchase"
  | "purchase-secondary"
  | "tertiary-black"
  | "tertiary-purple"
  | "tertiary-destructive"
  | "destructive"
  | "text-purple"
  | "text-black"
  | "none";

type OmittedComponentPropsWithoutRef<T extends ElementType> = Omit<
  ComponentPropsWithoutRef<T>,
  "children" | "disabled"
>;

export type ButtonTagProps = OmittedComponentPropsWithoutRef<"button"> & {
  tag?: "button" | undefined;
  makeReturnUrl?: never;
  href?: never;
  isInternalNavigation?: never;
};

type LinkTagProps = OmittedComponentPropsWithoutRef<"a"> &
  LocationState & {
    tag: "a";
    makeReturnUrl?: boolean;
    href: string;
    isInternalNavigation?: boolean;
    hideLinkIcon?: boolean;
    reactRouterState?: Record<string, unknown>;
    historyReplace?: boolean;
  };

export type BaseProps = {
  variant?: ButtonVariant;
  trackEvent?: track.GenericEvent;
  size?: "md" | "sm" | "lg" | "unset";
  alignSelf?: CSSProperties["alignSelf"];
  floatRight?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  fullWidth?: boolean;
  fullWidthSmallScreen?: boolean;
  whiteSpace?: CSSProperties["whiteSpace"];
  onDisableClick?: (e: BaseSyntheticEvent) => void;
  id?: string;
} & (
  | {
      icon?: IconDefinition;
      enableIconSpinLoading?: boolean;
      iconSize?: IconProps["size"];
      iconPosition?: "before" | "after";
    }
  | {
      icon?: never;
      enableIconSpinLoading?: never;
      iconSize?: never;
      iconPosition?: never;
    }
) &
  (
    | {
        isIconButton: true;
        children?: never;
        title: string;
      }
    | { isIconButton?: never; children: ReactNode; title?: string }
  );

export type ButtonProps = BaseProps & (ButtonTagProps | LinkTagProps);

function isLinkOnclick(
  onClick: ButtonProps["onClick"],
  tag: ButtonProps["tag"]
): onClick is (event: MouseEvent<HTMLAnchorElement>) => void {
  return !!onClick && tag === "a";
}

function isButtonOnclick(
  onClick: ButtonProps["onClick"],
  tag: ButtonProps["tag"]
): onClick is (event: MouseEvent<HTMLButtonElement>) => void {
  return !!onClick && tag !== "a";
}

/**
 * @deprecated Use Teddy components instead
 */
export function Button(props: ButtonProps): JSX.Element {
  const {
    children,
    isLoading,
    loadingText,
    icon,
    tag,
    iconPosition,
    trackEvent,
    onClick,
    disabled,
    enableIconSpinLoading,
    isInternalNavigation,
    makeReturnUrl,
    variant = "primary",
    iconSize,
    onDisableClick,
    ...rest
  } = props;
  const isLinkTag = tag === "a";
  const isButtonTag = !isLinkTag;
  const history = useReactRouterHistory();
  const { pushGenericTrackingEvent } = track.useEventTracking();

  function onLinkClick(e: MouseEvent<HTMLAnchorElement>, isDisabled?: boolean) {
    if (isDisabled) {
      onDisableClick && onDisableClick(e);
      e.preventDefault();
      return;
    }
    if (isButtonTag) return;
    trackEvent && pushGenericTrackingEvent(trackEvent);
    if (isLinkOnclick(onClick, tag)) onClick(e);

    const url = new URL(rest.href || "", window.location.origin);
    const internalUrl = url.href.replace(url.origin, "");
    const href = isInternalNavigation ? internalUrl : rest.href;

    if (!isInternalNavigation || !href) return;
    e.preventDefault();
    const reactRouterState = props.reactRouterState || {};

    const locationState = { ...history.location.state, ...reactRouterState };
    const newRouterState = makeReturnUrl ? { ...locationState, returnUrl: location.pathname } : locationState;
    props.historyReplace ? history.replace(href, newRouterState) : history.push(href, newRouterState);
  }

  function onButtonClick(e: MouseEvent<HTMLButtonElement>, isDisabled?: boolean) {
    if (isDisabled) {
      onDisableClick && onDisableClick(e);
      e.preventDefault();
      return;
    }
    trackEvent && pushGenericTrackingEvent(trackEvent);
    if (isButtonOnclick(onClick, tag)) onClick(e);
  }

  const showIconBefore = icon && (!iconPosition || iconPosition === "before");
  const showLinkIcon = isLinkTag && !props.hideLinkIcon;
  const showIconAfter = (icon || showLinkIcon) && !showIconBefore;
  const isDisabled = disabled || isLoading;

  return (
    <StyledButton
      {...rest}
      variant={variant}
      as={tag}
      aria-disabled={isDisabled}
      aria-busy={isLoading || undefined}
      onClick={(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        return isLinkTag
          ? onLinkClick(event as MouseEvent<HTMLAnchorElement>, isDisabled)
          : onButtonClick(event as MouseEvent<HTMLButtonElement>, isDisabled);
      }}
    >
      {showIconBefore && <Icon spin={enableIconSpinLoading && isLoading} size={iconSize || "sm"} icon={icon} />}
      {!props.isIconButton && (
        <span>
          {isLoading && loadingText ? loadingText : children}
          {isLoading && !enableIconSpinLoading && <LoadingDots />}
        </span>
      )}
      {showIconAfter && (
        <Icon size={iconSize || "sm"} spin={enableIconSpinLoading && isLoading} icon={icon ?? "arrow-right"} />
      )}
    </StyledButton>
  );
}
