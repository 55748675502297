import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";

type ErrorContentProps = {
  resetOrder: () => void;
};

export const ErrorContent = ({ resetOrder }: ErrorContentProps) => {
  return (
    <Container flexDirection="column" floatContent="left" gap padding="bottom" maxWidth="sm">
      <Heading tag="h1" variant="title-300">
        Noe gikk galt!
      </Heading>

      <Paragraph>
        Vi kunne dessverre ikke fullføre din bestilling. Vennligst prøv igjen. Hvis feilen gjentar seg, kontakt
        kundeservice.
      </Paragraph>

      <Container padding="top" gap>
        <Button onClick={resetOrder}>Prøv igjen</Button>

        <Button tag="a" makeReturnUrl isInternalNavigation href="/min-side" variant="secondary" icon="arrow-left">
          Tilbake til oversikten
        </Button>
      </Container>
    </Container>
  );
};
