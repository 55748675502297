import { Card } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";

type Props = {
  isPaid: boolean;
};
export const StyledCard = styled(Card)<Props>`
  min-height: 40px;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 10px 1rem;
  min-height: 75px;
  ${(props) => {
    if (props.isPaid) {
      return css`
        svg {
          color: ${colors.green600};
        }
      `;
    }
    return css`
      svg {
        color: ${colors.red400};
      }
    `;
  }}
`;
