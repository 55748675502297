import { Container, useAuth, useWindowSize, MinSideButton } from "@telia-no-min-side/components";
import { Flex, Skeleton, border, color } from "@telia/teddy";
import { PickerScroller } from "./PickerScroller";

export const TopMenuSkeleton = ({ dataTrackingID }: { dataTrackingID?: string }) => {
  const { isDesktop, isMobile } = useWindowSize();
  const { hasLimitedAccess } = useAuth();

  return (
    <Container
      padding
      style={{ borderBottom: `${border.teddyBorderWidthXs} ${color.teddyColorGray200} solid` }}
      maxWidth="full"
      data-tracking-id={dataTrackingID}
    >
      <Flex direction={isDesktop ? "row" : "column"} justify="between" align={isDesktop ? "end" : "stretch"} gap="300">
        <Flex direction="column" gap="100" flexShrink="0" style={{ order: isDesktop ? "0" : "2" }}>
          <Skeleton style={{ width: "200px", height: "30px" }} radius="full" />
          <Skeleton style={{ width: "150px", height: "16px" }} />
        </Flex>
        <Flex
          gap="300"
          justify={isDesktop ? "flex-end" : "space-between"}
          style={{ minWidth: "0px" }}
          role="tablist"
          aria-label="Profile picker"
          m="4px"
        >
          <PickerScroller>
            {[...Array(3)].map((_, i) => (
              <Flex key={i} direction="column" align="center" style={{ scrollSnapAlign: "center" }}>
                <Skeleton style={{ width: "50px", height: "50px" }} radius="full" mb="50" />
                <Skeleton style={{ width: "60px", height: "16px" }} radius="full" />
              </Flex>
            ))}
          </PickerScroller>
          {!isMobile && (
            <div style={{ flexShrink: 0, margin: "4px" }}>
              <MinSideButton minSide="fixed" limitedAccess={hasLimitedAccess} />
            </div>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};
