import { useEffect, useState } from "react";
import { breakpoints } from "../utils/breakpoints";

type WindowSize = {
  width?: number;
  height?: number;
};

export type UseWindowSize = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isBigDesktop: boolean;
} & WindowSize;

export function useWindowSize(): UseWindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isMobile = (windowSize.width || 0) < breakpoints.sm;
  const isTablet = !isMobile && (windowSize.width || 0) < breakpoints.md;
  const isDesktop = !isTablet && !isMobile;
  const isBigDesktop = (windowSize.width || 0) > breakpoints.xl;

  useEffect(() => {
    function handleResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    ...windowSize,
    isMobile,
    isTablet,
    isDesktop,
    isBigDesktop,
  };
}
