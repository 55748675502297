import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { zIndex } from "./../utils/variables";
import { ActionPoint } from ".";

export const ActionMenuButtonStyled = styled.button<{ active: boolean }>`
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 2.25rem;
  border: 0.06rem solid ${colors.corePurple700};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: white;
  :active,
  :hover {
    background-color: ${colors.corePurple100};
  }
  svg {
    height: 1.125rem;
    width: 1.125rem;
    color: black;
  }
`;

export const ActionMenuContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 2rem;
  display: grid;
  place-items: center;
`;

export const ActionPoints = styled.ul`
  position: absolute;
  z-index: ${zIndex.modal};
  top: 0;
  right: 0;
  border: 1px solid;
  transform: translate(1rem, 2.5rem);
  background-color: white;
  border: 1px solid ${colors.grey200};
  box-shadow:
    0px 2px 8px 1px rgba(34, 34, 34, 0.1),
    0px 1px 10px rgba(34, 34, 34, 0.04),
    0px 2px 4px rgba(34, 34, 34, 0.08);
  border-radius: 8px;
  list-style: none;
  padding: 0;
  margin-block: 0;
  width: max-content;
`;

export const ActionPointItem = styled.li<{ borderTop?: boolean }>`
  padding: 0;
  ${(props) => {
    if (props.borderTop) {
      return css`
        border-top: 1px solid ${colors.grey100};
      `;
    }
  }}
  min-width: 200px;
`;

const commonActionPointStyling = css<{ color?: string }>`
  background-color: transparent;
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 1rem;
  padding: 0;
  text-decoration: none;
  color: ${colors.grey800};
  font-weight: 500;
  :hover,
  :focus,
  :active {
    color: ${colors.corePurple};
    background-color: transparent;
    svg path {
      fill: ${colors.corePurple};
    }
  }
  :focus {
    outline: none;
    border-radius: 5px;
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }
  svg {
    padding-top: 3px;
    fill: ${colors.grey800};
  }
`;

const negativeActionPointStyle = css`
  ${commonActionPointStyling}
  color: ${colors.red400};
  :hover,
  :focus,
  :active {
    color: ${colors.red600};
    svg path {
      fill: ${colors.red600};
    }
  }
  svg {
    fill: ${colors.red400};
  }
`;

export const ActionPointLink = styled.a<Pick<ActionPoint, "styling">>`
  ${(props) => (props.styling === "negative" ? negativeActionPointStyle : commonActionPointStyling)}
`;

export const ActionPointButton = styled.button<Pick<ActionPoint, "styling">>`
  border: none;
  ${(props) => (props.styling === "negative" ? negativeActionPointStyle : commonActionPointStyling)}
`;
