import { useEffect, useState } from "react";
import { useAuth } from "@telia-no-min-side/components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Card, Text, Heading, TextField, breakpoint, Grid, FieldErrorText, Box } from "@telia/teddy";
import { GDPRErrorType } from "./Error";
import { graphql } from "src/gql";
import { GdprRequest, GdprBusinessObject, GdprResponse, MutationGdprArgs } from "src/gql/graphql";
import { AUTH } from "util/constants/auth";
import { getGdprInput } from "../utils/getGdprInput";
import { GDPR_REPORTS_MOBILE } from "../pages/RightOfAccessStepOne";

export const sendRequestPageSettings = {
  [GdprRequest.Access]: { title: "Send en forespørsel om uttrekk av data", operationType: "innsyn" },
  [GdprRequest.Portability]: { title: "Send en forespørsel om innsyn", operationType: "dataportabilitet" },
  [GdprRequest.Erasure]: { title: "Send en forespørsel om sletting", operationType: "sletting" },
} as const;

const inputRequired = {
  [GdprRequest.Portability]: ["name", "phone_number", "email"],
  [GdprRequest.Access]: ["name", "phone_number", "email"],
  [GdprRequest.Erasure]: ["name", "phone_number", "email"],
} as const;

export type AllowedGdprOperationType = GdprRequest.Access | GdprRequest.Portability | GdprRequest.Erasure;

type Props = {
  operation: AllowedGdprOperationType;
};

const SEND_GDPR_REQUEST = graphql(`
  mutation sendGDPRRequest($input: GDPRInput!) {
    gdpr(input: $input) {
      id
    }
  }
`);

export const SendRequest = ({ operation }: Props) => {
  const { userInfo, selectedAuthProvider } = useAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const pageTitle = sendRequestPageSettings[operation].title;
  const operationType = sendRequestPageSettings[operation].operationType;
  const [hasClickedDisabledButton, setHasClickedDisabledButton] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessObject = searchParams.get("accessRequested") as GdprBusinessObject;

  const [sendGDPRRequest, { loading }] = useMutation<GdprResponse, MutationGdprArgs>(SEND_GDPR_REQUEST, {
    onError(error) {
      if (error.message.includes("status code 422")) {
        navigate(`/minside/personvern/error?type=${GDPRErrorType.InProgress}`);
        return;
      }
      navigate("/minside/personvern/error");
    },
    refetchQueries: [GDPR_REPORTS_MOBILE],
    onCompleted() {
      navigate(`/minside/personvern/suksess?type=${operationType}&email=${email}`);
    },
  });

  useEffect(() => {
    if (!userInfo?.name) return;
    const requiredFields = inputRequired[operation];
    requiredFields.forEach((field) => {
      if (field === "name") setName(userInfo.name);
      if (field === "email") setEmail(userInfo.email || "");
      if (field === "phone_number") setPhoneNumber(userInfo.phone_number || "");
    });
  }, [userInfo]);

  const areRequiredFieldsFilled = () => {
    const requiredFields = inputRequired[operation];
    return requiredFields.every((field) => {
      if (field === "name") return name.trim() !== "";
      if (field === "email") return email.trim() !== "";
      if (field === "phone_number") return phoneNumber.trim() !== "";
      return true;
    });
  };

  const onSubmit = () => {
    if (!areRequiredFieldsFilled()) {
      alert("Please fill all required fields.");
      return;
    }

    sendGDPRRequest({
      variables: {
        input: {
          ...getGdprInput({
            operation,
            name,
            email,
            phoneNumber,
            pid: userInfo?.no_person_id,
            businessObject,
          }),
        },
      },
    });
  };

  const requiredFields = inputRequired[operation];

  return (
    <>
      <Heading as="h1" variant="title-200" pb={"100"}>
        {pageTitle}
      </Heading>
      <Card
        layout="default"
        maxWidth={breakpoint.teddyBreakpointMd}
        bordered
        variant="white"
        data-tracking-id="send-request-page"
      >
        <Card.Heading as="h2" variant="title-100">
          Dine kontaktopplysninger
        </Card.Heading>
        <Card.Description>
          Oppgi den {requiredFields.some((v) => v === "email") && "e-postadressen"}
          {requiredFields.filter((v) => v === "email" || v === "phone_number").length === 2 && " og "}
          {requiredFields.some((v) => v === "phone_number") && "telefonnummeret"} du ønsket å bli kontaktet på i
          forbindelse med din forespørsel. Vi vil kun bruke informasjonen til dette formålet.
        </Card.Description>
        <Card.Content>
          <Grid gap={"200"} style={{ justifyItems: "start" }}>
            {requiredFields.some((r) => r === "name") && (
              <TextField isRequired>
                <TextField.Label>Navn</TextField.Label>
                <TextField.Input
                  name="name"
                  autoComplete="name"
                  size={500}
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </TextField>
            )}
            {requiredFields.some((r) => r === "email") && (
              <TextField isRequired>
                <TextField.Label>E-post</TextField.Label>
                <TextField.Input
                  type="email"
                  name="email"
                  autoComplete="email"
                  size={30}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </TextField>
            )}
            {requiredFields.some((r) => r === "phone_number") && (
              <TextField isRequired>
                <TextField.Label>Telefonnummer</TextField.Label>
                <TextField.Input
                  type="tel"
                  name="phoneNumber"
                  size={16}
                  value={phoneNumber}
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                  }}
                />
              </TextField>
            )}
            {selectedAuthProvider !== AUTH.PROVIDER.CIAM && (
              <Text>
                Husk at du via mail i etterkant vil bli spurt om kopi av gyldig legitimasjon siden du ikke autentiserer
                deg med bankid. Dette må sendes per post.
              </Text>
            )}
            {hasClickedDisabledButton && !areRequiredFieldsFilled() ? (
              <FieldErrorText>Vennligst fyll ut alle påkrevde felt.</FieldErrorText>
            ) : (
              <Box height={"1.5em"} as="span" />
            )}
          </Grid>
        </Card.Content>
        <Card.Footer gap={"200"}>
          <Button variant="secondary" asChild>
            <Link to="/minside/personvern">Avbryt</Link>
          </Button>
          <Button
            loading={loading}
            disabled={!areRequiredFieldsFilled()}
            onPointerDown={() => setHasClickedDisabledButton(true)}
            onClick={onSubmit}
          >
            Send forespørsel
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};
