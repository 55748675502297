import { ProfilePickerTypes } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import { Account, BaseSubscription } from "components/profile-pickers/hooks/useProfilePicker";
import { UserOverviewDataQuery } from "gql/graphql";

type MobileAccounts = UserOverviewDataQuery["accounts"];

export function getSubscriptionByAccountId(accounts: MobileAccounts, accountId: string) {
  return accounts?.find((account) => account.id === accountId)?.subscriptions || [];
}

export function isSingleAccountWithSingleSubscription(accounts: MobileAccounts) {
  return accounts?.length === 1 && accounts[0]?.subscriptions?.length === 1;
}

export const mapAccountsToProfile = (account: Account): ProfilePickerTypes.Profile<string> => {
  const { id } = account;
  return {
    id: id,
    key: id,
    title: id,
    value: id,
  };
};

export const mapSubscriptionToProfile = (subscription: BaseSubscription): ProfilePickerTypes.Profile<string> => {
  const phoneNumber = subscription.phoneNumber.localNumber;
  return {
    id: phoneNumber,
    key: phoneNumber,
    title: `${format.startCase(subscription?.productUser?.firstName || "")} (${format.formatPhoneNumber(phoneNumber)})`,
    value: phoneNumber,
  };
};
