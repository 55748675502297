import { Heading, Flex, Box } from "@telia/teddy";
import { useFetchTvBoxes, useFetchTvRecord } from "src/api/fixed";
import { getDeviceProducts } from "util/productHelper";
import { FixedDeviceCard } from "components/fixed-device-card/FixedDeviceCard";
import { tvCardWidth } from "../utils/constants";

export function PossessedEquipment() {
  const tv = useFetchTvBoxes({ enableSkeletonNumberStore: true });
  const record = useFetchTvRecord();
  const tvBoxes = (tv.tvBoxes?.products || []).filter((box) => box.displayName.toLocaleLowerCase() !== "uten boks");
  const hasTvBoxes = !tv.isLoading && tvBoxes.length > 0;
  const hasRecords = !record.isLoading && (record.record?.products || []).length > 0;

  if (!tvBoxes && tv.isError && !record.record && record.isError) return null;

  if (!hasTvBoxes && !hasRecords) return null;

  return (
    <Box>
      <Heading as="h2" variant="title-100">
        Ditt TV-utstyr
      </Heading>
      <Flex mt="200" gap="400" wrap="wrap">
        {tv.isLoading
          ? [...Array(tv.numberOfSkeleton)].map((v, i) => (
              <Box key={`loading-box-${i}`} width={tvCardWidth}>
                <FixedDeviceCard isLoading />
              </Box>
            ))
          : null}
        {getDeviceProducts(tvBoxes)?.map((product) => (
          <Box key={`product-box-${product.id}`} width={tvCardWidth}>
            <FixedDeviceCard isLoading={false} product={product} />
          </Box>
        ))}
        {record.isLoading ? (
          <Box key={`loading-box-record`} width={tvCardWidth}>
            <FixedDeviceCard isLoading />
          </Box>
        ) : null}
        {getDeviceProducts(record.record?.products)?.map((product) => (
          <Box key={`product-box-${product.id}`} width={tvCardWidth}>
            <FixedDeviceCard isLoading={false} product={product} />
          </Box>
        ))}
      </Flex>
    </Box>
  );
}
