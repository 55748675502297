import { Button, Card, Container, Heading, Icon, Paragraph, remCalc } from "@telia-no-min-side/components";
import { IconDefinition } from "@telia/styleguide";
import { format } from "@telia-no-min-side/utils";

type Props = {
  title: string;
  icon: IconDefinition;
  paragraphs: string[];
  isSelected: boolean;
  handleSelect: () => void;
  isFirstMonthFree?: boolean;
  buttonText?: string;
};

export const SimCardTypeOption = ({
  title,
  icon,
  paragraphs,
  isSelected,
  isFirstMonthFree,
  handleSelect,
  buttonText,
}: Props) => {
  return (
    <Card
      isSelected={isSelected}
      padding="lg"
      lozengeRight={
        isFirstMonthFree
          ? {
              label: "Første måned kostnadsfritt!",
              status: "communication",
            }
          : undefined
      }
      dataTrackingID={`order-${format.toKebabCase(title)}`}
    >
      <Container flexDirection="column" gap>
        <Heading tag="h3" variant="title-100">
          {title}
        </Heading>

        <Container alignItems="flex-start" gap>
          <Container width="unset" flexShrink="0" style={{ paddingTop: remCalc(2) }}>
            <Icon size="sm" icon={icon} />
          </Container>

          <Container flexDirection="column">
            {paragraphs.map((paragraph) => (
              <Paragraph key={paragraph}>{paragraph}</Paragraph>
            ))}
          </Container>
        </Container>

        <Button size="sm" variant={isSelected ? "primary" : "secondary"} alignSelf="flex-end" onClick={handleSelect}>
          {isSelected ? "Valgt" : `Velg ${buttonText || title}`}
        </Button>
      </Container>
    </Card>
  );
};
