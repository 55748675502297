export enum ProductActionIcons {
  ACTIVATE = "plus",
  CANCEL = "close",
  CHANGE = "switch-arrows",
  MANUAL = "summary",
  ORDER = "shopping",
  ORDER_REMOTE = "remote-control",
  SETUP = "wallplug",
  FACTORY_RESET = "ai-robot",
  RETURN = "recycle",
  FREEZE = "minus",
  SETTINGS = "settings",
  LINK = "link",
  REGISTER = "register",
  INFO = "info",
  DEBUG = "repair",
}
