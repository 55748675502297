import { Card, Container, Skeleton } from "@telia-no-min-side/components";

export function SkeletonPage() {
  return (
    <Container padding showGoBackButton maxWidth="md" gap="lg" flexDirection="column">
      <Skeleton width="15ch" />
      <Card removeMinHeight>
        <Skeleton width="15ch" />
        <Skeleton variant="heading-xs" />
        <Skeleton width="15ch" />
      </Card>
      <Skeleton width="15ch" />
      <Container gap flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
        <Skeleton width="10ch" variant="button-small" />
        <Skeleton width="10ch" variant="button-small" />
      </Container>
      <Card removeMinHeight>
        <Container gap flexDirection="column">
          <Skeleton width="15ch" />
          <Skeleton variant="heading-xs" width="80%" />
        </Container>
      </Card>
      <Card removeMinHeight>
        <Container gap flexDirection="column">
          <Skeleton width="15ch" />
          <Skeleton variant="heading-xs" width="80%" />
        </Container>
      </Card>
      <Card removeMinHeight>
        <Container gap flexDirection="column">
          <Skeleton width="15ch" />
          <Skeleton variant="heading-xs" width="80%" />
        </Container>
      </Card>
    </Container>
  );
}
