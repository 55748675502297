import { GetMobileDashboardDataQuery } from "gql/graphql";
import { getConvergentBenefitAmount } from "util/format-subscription/getConvergentBenefitAmount";
import { isSummerCampaignDoubleDataActivated } from "util/format-subscription/isSummerCampaignDoubleDataActivated";

export function getSubscriptionBenefits(
  additionalProducts: GetMobileDashboardDataQuery["subscription"]["additionalProducts"]
) {
  const convergentBenefit = getConvergentBenefitAmount(additionalProducts || []);
  const showConvergentBenefit = convergentBenefit.hasDoubleData || convergentBenefit.hasDoubleSpeed;
  const showSummerCampaignBenefit = isSummerCampaignDoubleDataActivated(additionalProducts);

  return { convergentBenefit, showConvergentBenefit, showSummerCampaignBenefit };
}
