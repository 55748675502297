import { formatDatalayerTransactionData } from "src/services/googleAnalytics/eventDataHelpers";
import { getTax } from "util/tracking";
import { AdditionalProduct } from "gql/graphql";

export function getTransactionData(product: AdditionalProduct, insuranceName: string, orderId?: string | null) {
  return formatDatalayerTransactionData({
    actionId: orderId || "",
    revenue: product.price?.price || product.price?.priceWithoutDiscount || 0,
    shipping: 0,
    name: insuranceName,
    id: product?.code,
    price: product.price?.price || product.price?.priceWithoutDiscount || 0,
    tax: getTax(product.price?.price || product.price?.priceWithoutDiscount || 0),
    category: "VAS/Security/Insurance",
  });
}
