import { Link, useLocation } from "react-router-dom";
import { Icon, Button, Flex } from "@telia/teddy";
import { track } from "@telia-no-min-side/utils";
import { useWindowSize } from "@telia-no-min-side/components";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { useMobileAccount } from "hooks/useMobileAccount";
import { INTERNAL_LINK } from "util/constants/internalLinks";

type Props = {
  canOrderSim: boolean;
};

export function ActionButtons({ canOrderSim }: Props) {
  const { isMobile } = useWindowSize();
  const { phoneNumber } = useMobileAccount();
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const location = useLocation();
  const isMBB = location.pathname?.includes("mobilt-bredband");

  const suspendSubscriptionHandler = () => {
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.SIM_PIN_PUK,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.BLOCK_MY_SIM,
    });
  };

  return (
    <Flex direction={isMobile ? "column" : "row"} gap="gutter-xl">
      <Button asChild variant="secondary">
        <Link
          to={`${INTERNAL_LINK.MOBILE.SUSPEND_SUBSCRIPTION_PAGE}/?${new URLSearchParams({ phoneNumber }).toString()}`}
          onClick={suspendSubscriptionHandler}
        >
          <Icon name="close-circle">Sperr mine SIM</Icon>
        </Link>
      </Button>

      {canOrderSim && (
        <Button asChild variant="secondary">
          <Link to={INTERNAL_LINK[isMBB ? "FIXED" : "MOBILE"].ORDER_SIM_PAGE}>
            <Icon name="plus">Bestill SIM</Icon>
          </Link>
        </Button>
      )}

      <Button asChild variant="secondary">
        <Link to={INTERNAL_LINK[isMBB ? "FIXED" : "MOBILE"].ACTIVATE_SIM_PAGE}>Aktiver SIM manuelt</Link>
      </Button>
    </Flex>
  );
}
