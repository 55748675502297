import { AdditionalProduct } from "gql/graphql";

export function isViaplay(subscriptionName: string | null | undefined = "") {
  return subscriptionName?.toLowerCase().includes("viaplay");
}

export function isViaplayTotalPriceplan(pricePlan: string | null | undefined) {
  const VIAPLAY_TOTAL_PRICE_PLAN = "TELIA_MOBIL_X_RASK_VT";
  return !!pricePlan && pricePlan.split(".").includes(VIAPLAY_TOTAL_PRICE_PLAN);
}

export function getAdditionalOfferingMonthlyCharge(
  productCode: string,
  additionalProducts: AdditionalProduct[] | null | undefined
) {
  if (!additionalProducts?.length) {
    return null;
  }
  const product = additionalProducts.find((product) => product.code === productCode);
  if (!product) {
    return null;
  }
  return product.productTerms?.find((term) => term && term.code === "MONTHLY_CHARGE") ?? null;
}
export function getViaplayOfferingMonthlyCharge(additionalProducts: AdditionalProduct[] | null | undefined) {
  const VIAPLAY_TOTAL_PRODUCT_CODE = "NCVPLCH03";
  return getAdditionalOfferingMonthlyCharge(VIAPLAY_TOTAL_PRODUCT_CODE, additionalProducts);
}
