import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Container } from "../Container";
import { useWindowSize } from "../hooks";
import { useExpandableCard } from "./Context";
import {
  ExpandableCardContent,
  ExpandableCardContentContainer,
  ExpandableCardContentRegion,
  animationDuration,
} from "./style";

export type Props = {
  children?: (({ isOpen }: { isOpen: boolean }) => ReactNode) | ReactNode;
  peakContent?: (({ isOpen }: { isOpen: boolean }) => ReactNode) | ReactNode;
  className?: string;
};

/**
 * @deprecated Use Teddy components instead
 */
export function Content(props: Props): JSX.Element {
  const { className, children } = props;
  const peakContentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const childWrapperRef = useRef<HTMLDivElement>(null);
  const timingId = useRef<ReturnType<typeof setTimeout>>();
  const [heightToAnimate, setHeightToAnimate] = useState(peakContentRef.current?.getBoundingClientRect().height);
  const { isOpen, id, onClick, compact } = useExpandableCard();
  const { width } = useWindowSize();

  useLayoutEffect(() => {
    if (!childWrapperRef.current || !containerRef.current) return;
    timingId.current && clearTimeout(timingId.current);
    const minHeight = peakContentRef.current ? peakContentRef.current.getBoundingClientRect().height : 0;
    if (isOpen) {
      const contentHeight = childWrapperRef.current.getBoundingClientRect().height;
      setHeightToAnimate(contentHeight + minHeight);
      timingId.current = setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.style.overflow = "visible";
        }
      }, animationDuration);
      return;
    }
    containerRef.current.style.overflow = "hidden";
    setHeightToAnimate(minHeight);
  }, [isOpen, childWrapperRef.current, containerRef.current, peakContentRef.current, children, width]);

  useEffect(() => {
    () => {
      timingId.current && clearTimeout(timingId.current);
    };
  }, []);

  const hasPeakContent = Boolean(props.peakContent);

  return (
    <ExpandableCardContentContainer ref={containerRef} isOpen={isOpen} animateHeight={heightToAnimate}>
      {props.peakContent && (
        <Container padding onClick={onClick} ref={peakContentRef}>
          {typeof props.peakContent === "function" ? props.peakContent({ isOpen }) : props.peakContent}
        </Container>
      )}
      <ExpandableCardContent isOpen={isOpen} animateHeight={heightToAnimate} aria-hidden={!isOpen}>
        <ExpandableCardContentRegion
          aria-labelledby={`expandable-card-header-${id}`}
          id={`expandable-card-body-${id}`}
          role="region"
          aria-expanded={isOpen}
          ref={childWrapperRef}
          className={className}
          hasPeakContent={hasPeakContent}
          compact={compact}
        >
          {typeof props.children === "function" ? props.children({ isOpen }) : props.children}
        </ExpandableCardContentRegion>
      </ExpandableCardContent>
    </ExpandableCardContentContainer>
  );
}
