import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Card, Container, Icon, Paragraph, ScrollToTop } from "@telia-no-min-side/components";
import { track, uri } from "@telia-no-min-side/utils";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Offering, UserOffering } from "src/gql/graphql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { getConvergentBenefitAmount } from "src/util/format-subscription/getConvergentBenefitAmount";
import { ErrorMessage } from "../components/MobileError";
import { SkeletonPage } from "../components/Skeleton";
import { ReceiptSuccess } from "../components/MobileSuccess";
import { Grid } from "../components/styles/style";
import { ProductChangesSection } from "../components/ProductChangesSection";
import { createConfirmChangeSubscriptionEvent } from "../utils/createConfirmChangeSubscriptionEvent";
import { GET_UPGRADE_DOWNGRADE_SUMMARY } from "../graphql/getUpgradeDowngradeSummary";
import { CHANGE_OFFERING_MUTATION } from "../graphql/changeOfferingMutation";
import { getLineSubscriptions } from "../../mobile-subscription-change/change-mobile/utils/getLineSubscriptions";
import { findAmount } from "util/format-subscription/findAmount";
import { getOfferingPrices } from "util/format-subscription/getOfferingPrices";
import { getDownloadSpeed } from "util/format-subscription/getDownloadSpeed";
import { PriceChangeSummary } from "../components/PriceChangeSummary";
import { Heading } from "@telia/teddy";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { isViaplayTotalPriceplan } from "util/format-subscription/isViaplay";
import { PdfHeader } from "components/pdf-order-summary/PdfHeader";
import { DownloadButton } from "components/pdf-order-summary/DownloadButton";
import { openPdf } from "util/pdf-order-summary/openPdf";
import { PdfFooter } from "components/pdf-order-summary/PdfFooter";

export function MobileSubscriptionConfirm() {
  const navigate = useNavigate();
  const params = useParams<{ accountId?: string; phoneNumber?: string }>();
  const { subscribeToOrder } = useOrderSubscription();
  const { pushTrackingEvent } = track.useEventTracking();
  const [search] = useSearchParams();
  const pricePlan = search.get("pricePlan");
  const [pageView, setPageView] = useState<"summary" | "receipt-error" | "receipt-success">("summary");

  const upgradeDowngradeSummary = useQuery(GET_UPGRADE_DOWNGRADE_SUMMARY, {
    variables: {
      phoneNumber: params.phoneNumber || "",
      offering: pricePlan || "",
      accountId: params.accountId || "",
      withProductChanges: true,
    },
    skip: !params.phoneNumber || !pricePlan || !params.accountId,
  });

  const [mutateSubscription, mutateSubscriptionResult] = useMutation(CHANGE_OFFERING_MUTATION);

  const handleChangeSubscription = (
    subToBeRemoved: UserOffering | undefined | null,
    subToBeAdded: Offering | undefined | null
  ) => {
    const phoneNumber = params.phoneNumber || "";
    const offering = pricePlan;
    mutateSubscription({
      variables: { phoneNumber, offering },
      onCompleted(data) {
        const orderId = data.changeOffering?.orderId;
        if (orderId) {
          subscribeToOrder({
            id: orderId,
            toastText: "Nytt abonnement er aktivert",
            phoneNumber: params.phoneNumber || "",
          });
          pushTrackingEvent(createConfirmChangeSubscriptionEvent(subToBeRemoved, subToBeAdded, orderId));
          return setPageView("receipt-success");
        }
        setPageView("receipt-error");
      },
      onError: () => {
        setPageView("receipt-error");
      },
    });
  };

  const linesSubscriptions = getLineSubscriptions(upgradeDowngradeSummary.data?.account.subscriptions);

  const convergentBenefit =
    upgradeDowngradeSummary.data?.account.subscription?.additionalProducts &&
    getConvergentBenefitAmount(upgradeDowngradeSummary.data?.account.subscription?.additionalProducts);

  const isLines = !!linesSubscriptions?.length && linesSubscriptions.length > 1;

  const subToBeAdded = upgradeDowngradeSummary.data?.account.subscription.newOfferingDetails?.offerings?.find(
    (offer) => offer?.pricePlan === pricePlan
  );

  if (upgradeDowngradeSummary.loading && !upgradeDowngradeSummary.data) {
    return <SkeletonPage />;
  }
  if (
    (!upgradeDowngradeSummary.data && upgradeDowngradeSummary.error) ||
    !upgradeDowngradeSummary.data ||
    !params.accountId ||
    !params.phoneNumber ||
    (!subToBeAdded && pageView === "summary") ||
    !pricePlan
  ) {
    return (
      <ErrorMessage
        isLoading={upgradeDowngradeSummary.loading}
        refetch={
          pricePlan && params.phoneNumber
            ? () => {
                upgradeDowngradeSummary.refetch({ offering: pricePlan, phoneNumber: params.phoneNumber });
              }
            : undefined
        }
      />
    );
  }

  const { userOffering: subToBeRemoved } = upgradeDowngradeSummary.data.account.subscription;

  const subToBeRemovedOfferingPrices = getOfferingPrices(subToBeRemoved?.offeringPrices);
  const subToBeAddedOfferingPrices = subToBeAdded?.price?.offeringPrices;

  const subToBeRemovedDownloadSpeed = getDownloadSpeed(subToBeRemovedOfferingPrices);
  const subToBeAddedDownloadSpeed = getDownloadSpeed(subToBeAddedOfferingPrices);

  const subToBeRemovedDataIncluded = subToBeRemovedOfferingPrices?.find(findAmount);
  const subToBeAddedDataIncluded = subToBeAddedOfferingPrices?.find(findAmount);

  const subToBeAddedHasUnlimitedData = subToBeAdded?.additionalProperties?.some(
    (prop) => prop?.name === "DATA_UNLIMITED" && prop.value === "true"
  );
  const subToBeRemovedHasUnlimitedData = subToBeRemoved?.additionalProperties?.some(
    (prop) => prop?.name === "DATA_UNLIMITED" && prop.value === "true"
  );

  const qualifyForTeliaSamlet = convergentBenefit?.hasDoubleData || convergentBenefit?.hasDoubleSpeed;
  const maxSpeedSubs = ["Telia X Max Pluss", "Telia X Max"];

  const isNewBucket = ["Telia Mobil 10 GB", "Telia Mobil 5 GB"].includes(subToBeAdded?.shortName || "");

  const isOldBucket = ["Telia Mobil 10 GB", "Telia Mobil 5 GB"].includes(subToBeRemoved?.shortName || "");
  const canNewGetDoubleSpeed =
    qualifyForTeliaSamlet && !maxSpeedSubs.includes(subToBeAdded?.shortName || "") && !isNewBucket;
  const canOldGetDoubleSpeed =
    qualifyForTeliaSamlet && !maxSpeedSubs.includes(subToBeRemoved?.shortName || "") && !isOldBucket;
  const isNewViaplayTotal = isViaplayTotalPriceplan(subToBeAdded?.pricePlan);

  if (pageView === "receipt-success") {
    return <ReceiptSuccess isLines={isLines} isNewViaplayTotal={isNewViaplayTotal} />;
  }
  if (pageView === "receipt-error") {
    return (
      <ErrorMessage
        isLines={isLines}
        isLoading={mutateSubscriptionResult.loading}
        refetch={() => handleChangeSubscription(subToBeRemoved, subToBeAdded)}
      />
    );
  }

  return (
    <Container
      padding
      showGoBackButton
      gap
      flexDirection="column"
      maxWidth="sm"
      data-tracking-id="mobile-subscription-confirm-container"
      id="mobile-subscription-confirm-container"
    >
      <ScrollToTop />
      <PdfHeader />
      <Heading as="h2" variant="title-300" data-tracking-id="mobile-subscription-confirm-heading">
        Ordresammendrag
      </Heading>
      <PriceChangeSummary subToBeRemoved={subToBeRemoved} subToBeAdded={subToBeAdded} />
      <Container flexDirection="column" gap="lg" padding="bottom" data-tracking-id="mobile-subscription-confirm-info">
        <Container gap="sm" flexDirection="column" data-tracking-id="mobile-subscription-confirm-speed">
          <Paragraph removeMargin>
            <em>Hastighet</em>
          </Paragraph>
          <Card backgroundColor="grey" removeMinHeight showShadow={false} padding="md" maxWidth="lg">
            <Grid gap>
              <Paragraph removeMargin>
                {canOldGetDoubleSpeed && subToBeRemovedDownloadSpeed
                  ? subToBeRemovedDownloadSpeed * 2
                  : subToBeRemovedDownloadSpeed}{" "}
                Mbps
              </Paragraph>
              <Icon icon="arrow-right" />
              <Paragraph removeMargin>
                {canNewGetDoubleSpeed && subToBeAddedDownloadSpeed
                  ? subToBeAddedDownloadSpeed * 2
                  : subToBeAddedDownloadSpeed}{" "}
                Mbps
              </Paragraph>
            </Grid>
          </Card>
        </Container>
        <Container gap="sm" flexDirection="column" data-tracking-id="mobile-subscription-confirm-data">
          <Paragraph removeMargin>
            <em>Datamengde</em>
          </Paragraph>
          <Card backgroundColor="grey" removeMinHeight showShadow={false} padding="md" maxWidth="lg">
            <Grid gap>
              <Paragraph removeMargin>
                {subToBeRemovedHasUnlimitedData
                  ? "Ubegrenset data"
                  : typeof subToBeRemovedDataIncluded?.amount === "number"
                  ? `${
                      qualifyForTeliaSamlet ? subToBeRemovedDataIncluded.amount * 2 : subToBeRemovedDataIncluded.amount
                    } GB`
                  : "Klarte ikke hente"}
              </Paragraph>
              <Icon icon="arrow-right" />
              <Paragraph>
                {" "}
                {subToBeAddedHasUnlimitedData
                  ? "Ubegrenset data"
                  : typeof subToBeAddedDataIncluded?.amount === "number"
                  ? `${
                      qualifyForTeliaSamlet ? subToBeAddedDataIncluded.amount * 2 : subToBeAddedDataIncluded.amount
                    } GB`
                  : "Klarte ikke hente"}
              </Paragraph>
            </Grid>
          </Card>
        </Container>
        <ProductChangesSection productChanges={upgradeDowngradeSummary.data.account.subscription.productChanges} />
      </Container>

      <DownloadButton
        mr="auto"
        className="hide-on-print"
        onClick={async () => openPdf("#mobile-subscription-confirm-container")}
      />

      <Container
        className="hide-on-print"
        flexDirection="row"
        gap="lg"
        justifyContent="flex-end"
        padding="vertical"
        data-tracking-id="mobile-subscription-confirm-action-buttons"
        data-html2canvas-ignore
      >
        <Button
          variant="secondary"
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_CANCEL,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.UPGRADE_DOWNGRADE_SUMMARY,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
            ui_item_url: uri.minSideFixed("/minside/mobil/endre"),
            ui_item_text: TRACK_EVENT.ITEM_TEXT.CANCEL,
          }}
          data-tracking-id="mobile-subscription-confirm-action-buttons-cancel"
          onClick={() => navigate(-1)}
        >
          Avbryt
        </Button>
        <Button
          type="button"
          variant="primary"
          hideLinkIcon
          isLoading={mutateSubscriptionResult.loading}
          loadingText="Sender bestilling"
          onClick={() => handleChangeSubscription(subToBeRemoved, subToBeAdded)}
          data-tracking-id="mobile-subscription-confirm-action-buttons-order"
        >
          Fullfør bestilling
        </Button>
      </Container>
      <PdfFooter isCommitment={subToBeAdded?.pricePlan?.endsWith("REGULAR")} />
    </Container>
  );
}
