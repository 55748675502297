import cloneDeep from "lodash.clonedeep";

export const wiFiSettingsUtilities = {
  copySettingsFromHomeCentral(homeCentral, originalExtender) {
    const extender = cloneDeep(originalExtender);

    if (extender.SupportedSettings.Wifi2GHz.Supported) {
      extender.Settings.Wifi2GHz.Ssid = homeCentral.Settings.Wifi2GHz.Ssid;
      extender.Settings.Wifi2GHz.Password =
        homeCentral.Settings.Wifi2GHz.Password;
      if (
        extender.SupportedSettings.Wifi2GHz.SecurityProtocols.includes(
          homeCentral.Settings.Wifi2GHz.SecurityProtocol
        )
      ) {
        extender.Settings.Wifi2GHz.SecurityProtocol =
          homeCentral.Settings.Wifi2GHz.SecurityProtocol;
      }
      extender.Settings.Wifi2GHz.Enabled =
        homeCentral.Settings.Wifi2GHz.Enabled;
      extender.Settings.Wifi2GHz.Channel =
        homeCentral.Settings.Wifi2GHz.Channel;
    }
    if (extender.SupportedSettings.Wifi5GHz.Supported) {
      extender.Settings.Wifi5GHz.Ssid = homeCentral.Settings.Wifi5GHz.Ssid;
      extender.Settings.Wifi5GHz.Password =
        homeCentral.Settings.Wifi5GHz.Password;
      if (
        extender.SupportedSettings.Wifi5GHz.SecurityProtocols.includes(
          homeCentral.Settings.Wifi5GHz.SecurityProtocol
        )
      ) {
        extender.Settings.Wifi5GHz.SecurityProtocol =
          homeCentral.Settings.Wifi5GHz.SecurityProtocol;
      }
      extender.Settings.Wifi5GHz.Enabled =
        homeCentral.Settings.Wifi5GHz.Enabled;
      extender.Settings.Wifi5GHz.Channel =
        homeCentral.Settings.Wifi5GHz.Channel;
    }
    return extender;
  },
  setSamePasswordForWifiNetworks(originalDevice, frequency) {
    const device = cloneDeep(originalDevice);

    if (frequency === "2.4") {
      const { Password } = device.Settings.Wifi5GHz;

      device.Settings.Wifi2GHz.Password = Password;
    } else {
      const { Password } = device.Settings.Wifi2GHz;

      device.Settings.Wifi5GHz.Password = Password;
    }
    return device;
  },
  setSameSettingsForWiFiNetworks(originalDevice) {
    const device = cloneDeep(originalDevice);
    const TwoGhzSettings = cloneDeep(originalDevice.Settings.Wifi2GHz);

    device.Settings.Wifi5GHz.Bandwidth = TwoGhzSettings.Bandwidth;
    device.Settings.Wifi5GHz.Channel = TwoGhzSettings.Channel;
    device.Settings.Wifi5GHz.SecurityProtocol = TwoGhzSettings.SecurityProtocol;
    device.Settings.Wifi5GHz.SignalStrength = TwoGhzSettings.SignalStrength;
    return device;
  },
  setSSIDAndPasswordForWifi(originalDevice, wifiFrequency, SSID, Password) {
    const device = cloneDeep(originalDevice);

    switch (wifiFrequency) {
      case "2.4":
        device.Settings.Wifi2GHz.Password = Password;
        device.Settings.Wifi2GHz.Ssid = SSID;
        break;
      case "5":
        device.Settings.Wifi5GHz.Password = Password;
        device.Settings.Wifi5GHz.Ssid = SSID;
        break;
    }
    return device;
  },
  setAdditionalSettingsForWiFi(originalDevice, frequency, newSettings) {
    const device = cloneDeep(originalDevice);

    switch (frequency) {
      case "2.4":
        device.Settings.Wifi2GHz = newSettings;
        break;
      case "5":
        device.Settings.Wifi5GHz = newSettings;
        break;
    }
    return device;
  },
  setActiveStatusForWiFi(originalDevice, wifiFrequency) {
    const device = cloneDeep(originalDevice);

    switch (wifiFrequency) {
      case "2.4":
        device.Settings.Wifi2GHz.Enabled = !device.Settings.Wifi2GHz.Enabled;
        break;
      case "5":
        device.Settings.Wifi5GHz.Enabled = !device.Settings.Wifi5GHz.Enabled;
        break;
    }
    return device;
  },
  checkEquality2And5Ghz(wifi2ghz, wifi5ghz) {
    return (
      wifi2ghz.SecurityProtocol === wifi5ghz.SecurityProtocol &&
      wifi2ghz.SignalStrength === wifi5ghz.SignalStrength &&
      wifi2ghz.Channel === wifi5ghz.Channel &&
      wifi2ghz.Bandwidth === wifi5ghz.Bandwidth
    );
  },
};

export const wifiSettingsTags = {
  Wifi2GhzActiveStatus: "wifi-active-status-2.4",
  Wifi5GhzActiveStatus: "wifi-active-status-5",
  Wifi2GhzSettings: "wifi-settings-2.4",
  Wifi5GhzSettings: "wifi-settings-5",
  SetSsidAndPassword2Ghz: "set-ssid-password-2.4",
  SetSsidAndPassword5Ghz: "set-ssid-password-5",
  CopySettingsFromHomeCentral: "copy-settings-from-home-central",
  SetSameSSIDAndPassword2GHz: "set-same-SSID-Password-2GHz",
  SetSameSSIDANdPassword5GHz: "set-same-SSID-Password-5GHz",
};
