import React from "react";
import { ReactNode } from "react";
import { FooterWrapper } from "./style";

type Props = {
  className?: string;
  children: ReactNode;
  flex?: boolean;
};

/**
 * @deprecated Use Teddy components instead
 */
export function ModalFooter(props: Props): JSX.Element {
  const { children, className, flex } = props;
  return (
    <FooterWrapper className={className} flex={flex}>
      {children}
    </FooterWrapper>
  );
}
