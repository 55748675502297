import { types } from "@telia-no-min-side/utils";

export function formatPriceForRemoteController(price: types.fixed.ProductGroupPriceExtended): string {
  const { currentPrice } = price;
  if (price.currentPrice.recurring > 0) {
    return `${currentPrice.recurring},-/${currentPrice.chargePeriod}`;
  }
  return `${currentPrice.onceOff},-`;
}

export function calculateSumOfProducts(
  product: types.fixed.ProductGroupSingleProductWithShipping,
  selectedShippingMethod: number
): string {
  const shippingMethod = product.shippingOptionDTOS.find(
    (shipping) => shipping.shippingMethodId === selectedShippingMethod
  );

  return `${product.price.currentPrice.onceOff + (shippingMethod ? shippingMethod.onceOffPrice : 0)},-`;
}
