import { useQuery } from "@apollo/client";
import { Flex, Grid, Heading, Skeleton } from "@telia/teddy";
import { Agreement, AgreementCardError } from "./components";
import { useAgreements } from "./hooks/useAgreements";
import { useMobileAccount } from "hooks/useMobileAccount";
import { GET_ACCOUNT_AGREEMENTS, GET_SUBSCRIPTION_AGREEMENTS } from "./graphql";
import { useMobileDashboardContext } from "../../context/MobileDashboardContext";

export function AgreementCard() {
  const { accountId, phoneNumber } = useMobileAccount();
  const { isB2B, isLegalOwner } = useMobileDashboardContext();

  const accountAgreements = useQuery(GET_ACCOUNT_AGREEMENTS, {
    variables: { accountId },
    notifyOnNetworkStatusChange: true,
    skip: isB2B || !isLegalOwner,
  });

  const subscriptionAgreements = useQuery(GET_SUBSCRIPTION_AGREEMENTS, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
    skip: isB2B || isLegalOwner,
  });

  const { agreements } = useAgreements(
    accountAgreements.data?.account,
    subscriptionAgreements.data?.subscription,
    phoneNumber
  );

  const error = accountAgreements.error || subscriptionAgreements.error;
  const loading = accountAgreements.loading || subscriptionAgreements.loading;
  const refetch = isLegalOwner ? accountAgreements.refetch : subscriptionAgreements.refetch;

  if (loading) return <Skeleton height="440px" width="100%" maxWidth="350px" />;
  if (error) return <AgreementCardError refetch={refetch} />;

  // Business rule: B2B [PRODUCT_USER] customers don't have agreements.
  if (isB2B || agreements.length === 0) return null;

  return (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100" style={{ fontWeight: "500" }}>
        Dine enheter
      </Heading>

      <Grid columns={{ xs: "1", md: "2" }} gap="gutter-xl" align="start">
        {agreements.map((agreement) => (
          <Agreement key={`${agreement.agreementTitle}-${agreement.phoneModel}`} agreementData={agreement} />
        ))}
      </Grid>
    </Flex>
  );
}
