import { Container, TrackClickEvent } from "@telia-no-min-side/components";
import { Heading, Text, Button, Icon } from "@telia/teddy";
import { Link } from "react-router-dom";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = { safeWebshopUrl?: string | null; remainingCost?: number; loading: boolean };

export function LostPhone({ safeWebshopUrl, remainingCost, loading }: Props) {
  return (
    <>
      <Heading as="h4" variant="title-100">
        Har du mistet telefonen?
      </Heading>
      <Text as="p">
        Du kan fortsatt svitsje, men du må betale restbeløpet for nåværende mobiltelefon for {remainingCost || 0},-.
        Dette blir lagt på din neste faktura.
      </Text>
      <Container flexDirection="column" alignItems="start" gap>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_lost-phone",
            ui_item_text: "Velg ny telefon",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: safeWebshopUrl || "",
          }}
        >
          <Button asChild variant="primary" disabled={loading}>
            <a href={safeWebshopUrl || ""}>
              <Icon name="trade-phone" />
              Velg ny telefon
            </a>
          </Button>
        </TrackClickEvent>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_CANCEL,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_lost-phone",
            ui_item_text: "Tilbake til forrige steg",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: "..",
          }}
        >
          <Button asChild variant="text">
            <Link to="..">
              <Icon name="arrow-left" />
              Tilbake til forrige steg
            </Link>
          </Button>
        </TrackClickEvent>
      </Container>
    </>
  );
}
