import { AdditionalProductCategory, AdditionalProduct } from "../../gql/graphql";

export function getDataAndSpeedConvergentBenefits(additionalProducts: AdditionalProduct[]) {
  function getAdditionalProductTerm(termCode: string) {
    const products = additionalProducts?.filter(
      (p) =>
        p.productCategory === AdditionalProductCategory.Get && p.productTerms?.find((term) => term?.code === termCode)
    );

    return products && products.length > 0 ? products[0] : null;
  }

  return {
    downloadSpeed: getAdditionalProductTerm("SPEED_DOWNLOAD"),
    dataIncluded: getAdditionalProductTerm("DATA_INCLUDED"),
  };
}
