import { Container } from "@telia-no-min-side/components";
import { Button, Card, Text, Icon, Flex } from "@telia/teddy";
import { Link } from "react-router-dom";

export function TempAccessSuccess() {
  return (
    <Container maxWidth="sm" padding>
      <Card shadow gap="200">
        <Card.Heading>Suksess!</Card.Heading>
        <Card.Content>
          <Flex direction="column" align="start" gap="400">
            <Text>
              Midlertidig tilgang er nå opprettet. Vi klarte å innhente dine opplysninger, som vil oppdateres på Min
              Side i løpet av 10 minutter.
            </Text>
            <Text>
              Adgangen vil vare i 12 måneder fra nå. Om du har mulighet til å logge inn med BankID slipper du å
              verifisere deg på nytt etter 12 måneder.
            </Text>
            <Button variant="primary" asChild>
              {/* Reload is required to get new roles from token */}
              <Link to="/minside/hjem" reloadDocument>
                <Icon name="home" />
                Tilbake til Hjem
              </Link>
            </Button>
          </Flex>
        </Card.Content>
      </Card>
    </Container>
  );
}
