import { useWindowSize } from "@telia-no-min-side/components";
import { Box, Flex, Icon } from "@telia/teddy";
import { useEffect, useRef, useState, type ReactNode } from "react";
import { PickerButton } from "./PickerButton";

type Props = {
  children: ReactNode;
};

const MAGIC_NUMBER = 25;

export function PickerScroller({ children }: Props) {
  const { isMobile } = useWindowSize();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isAtScrollStart, setIsAtScrollStart] = useState(false);
  const [isAtScrollEnd, setIsAtScrollEnd] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);

  const handleScroll = () => {
    if (scrollRef.current != null) {
      const scrollLeft = scrollRef.current.scrollLeft;
      const scrollWidth = scrollRef.current.scrollWidth;
      const offsetWidth = scrollRef.current.offsetWidth;
      setIsAtScrollStart(scrollLeft <= MAGIC_NUMBER);
      setIsAtScrollEnd(scrollWidth <= MAGIC_NUMBER || scrollWidth - scrollLeft <= offsetWidth + MAGIC_NUMBER);
      setHasScroll(scrollWidth > offsetWidth);
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  const handleArrowClick = (direction: "left" | "right") => {
    const offsetWidth = scrollRef.current?.offsetWidth || 0;
    const scrollAmount = Math.floor((offsetWidth - 100) / 60) * 60;
    scrollRef.current?.scrollBy({ behavior: "smooth", left: direction === "left" ? -scrollAmount : scrollAmount });
  };

  return (
    <Box position="relative" style={{ minWidth: hasScroll ? "170px" : "0px" }}>
      {!isMobile && hasScroll && !isAtScrollStart && (
        <PickerButton
          variant="primary-negative"
          style={{
            position: "absolute",
            backgroundColor: "white",
            zIndex: 5,
            height: "100%",
          }}
          handleClick={() => handleArrowClick("left")}
        >
          <Icon name="arrow-left" />
        </PickerButton>
      )}
      <Flex
        gap="100"
        style={{ overflowX: "auto", scrollbarWidth: "none", scrollSnapType: "x mandatory" }}
        ref={scrollRef}
        onScroll={() => {
          hasScroll && handleScroll();
        }}
      >
        {children}
      </Flex>
      {!isMobile && hasScroll && !isAtScrollEnd && (
        <PickerButton
          variant="primary-negative"
          style={{ position: "absolute", top: 0, right: 0, backgroundColor: "white", zIndex: 5, height: "100%" }}
          handleClick={() => handleArrowClick("right")}
        >
          <Icon name="arrow-right" />
        </PickerButton>
      )}
    </Box>
  );
}
