import { Modal, Button } from "@telia/teddy";
import { ReactNode } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  heading: ReactNode;
  body: ReactNode;
};

export const PromptModal = (props: Props) => {
  const { open, onClose, isLoading, onConfirm, confirmText, cancelText, body, heading } = props;

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onClose();
    }
  };

  return (
    <Modal open={open} onOpenChange={onOpenChange}>
      <Modal.Content>
        <Modal.Group>
          <Modal.Description>
            <Modal.Title>{heading}</Modal.Title>
            <Modal.Description>{body}</Modal.Description>
          </Modal.Description>
        </Modal.Group>
        <Modal.Group variant="action">
          <Modal.Close slot="content">
            <Button size="sm" variant="tertiary-purple">
              {cancelText || "Avbryt"}
            </Button>
          </Modal.Close>
          <Button loading={isLoading} size="sm" onClick={onConfirm}>
            {isLoading ? "Vent litt ..." : confirmText || "OK"}
          </Button>
        </Modal.Group>
      </Modal.Content>
    </Modal>
  );
};
