import type { ReactNode } from "react";
import { Flex, Heading, Skeleton } from "@telia/teddy";
import { format } from "@telia-no-min-side/utils";

type Props = {
  children: ReactNode;
  title?: string;
  isLoading?: boolean;
};

export function DashboardSection({ isLoading, title, children }: Props) {
  if (isLoading) {
    return (
      <Flex direction="column" gap="200" data-tracking-id="agreements-skeleton">
        <Skeleton loading radius="full" height="30px" width="100px">
          Heading-Loading
        </Skeleton>
        <Flex direction="row" gap="250" wrap="wrap">
          {children}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="200" data-tracking-id={`homepage-subscription-${format.toKebabCase(title)}`}>
      <Heading as="h2" variant="title-200">
        {title}
      </Heading>
      <Flex direction="row" gap="250" wrap="wrap">
        {children}
      </Flex>
    </Flex>
  );
}
