import type { SyntheticEvent } from "react";
import { Fragment, useEffect } from "react";
import { useFormHandling, useLocalStorage } from "@telia-no-min-side/components";
import { PublishLevelCode } from "../../types";
import { StyledRadioButtonListWrapper } from "../../styles/style";
import { AgreementType, GetSubscriptionSettingsQuery } from "src/gql/graphql";
import { useUpdateDirectoryListing } from "../../hooks/useUpdateDirectoryListing";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { formatPoboxAddress, formatUrbanAddress } from "util/mobile/address";
import { notEmpty } from "@telia-no-min-side/utils";
import { Badge, ExpandableCard, Flex, Heading, Button, Notification, Label, Toggle } from "@telia/teddy";

type Props = {
  subscription: GetSubscriptionSettingsQuery["subscription"];
  directoryListings: GetSubscriptionSettingsQuery["subscription"]["directoryListings"];
};

export function InformationServicesForm({ subscription, directoryListings }: Props) {
  const { phoneNumber } = useMobileAccount();

  const initialState = {
    publishLevelCode: subscription?.publishLevelCode,
  };

  const [updateResponse, runUpdate] = useUpdateDirectoryListing();
  const { data, error, loading } = updateResponse;

  const { state, dispatch, Actions } = useFormHandling(initialState);
  const { form, previousForm, showSuccess, showError } = state;

  const user =
    form.publishLevelCode === PublishLevelCode.NAME_PHONE_ADDRESS_LO &&
    subscription.agreementType === AgreementType.Private
      ? subscription?.legalOwner?.individual
      : subscription.productUser;

  const address = user?.address;
  const displayLocation = address?.poboxAddress || address?.urbanAddress;
  const displayAddress = address?.poboxAddress
    ? formatPoboxAddress(address.poboxAddress)
    : formatUrbanAddress(address?.urbanAddress);
  const isInformationDisplayed = previousForm.publishLevelCode !== (PublishLevelCode.NO_INFO || undefined);

  const { setValue: setOrderId } = useLocalStorage<string>("telia-no-minside-order-id__settings");

  // if initially informationServices is undefined and then changes to defined, form states change accordingly
  useEffect(() => {
    dispatch({
      type: Actions.SET_STATE,
      payload: {
        form: {
          publishLevelCode: subscription.publishLevelCode,
        },
        previousForm: {
          publishLevelCode: subscription.publishLevelCode,
        },
      },
    });
  }, [dispatch, directoryListings, Actions]);

  useEffect(() => {
    dispatch({ type: Actions.SET_SHOW_ERROR, payload: { showError: false } });
    dispatch({
      type: Actions.SET_SHOW_SUCCESS,
      payload: { showSuccess: false },
    });

    if (data?.updateDirectoryListing?.orderId && !loading) {
      dispatch({ type: Actions.ON_SUCCESS });
      setOrderId(data.updateDirectoryListing.orderId);
    }

    if (error) {
      dispatch({ type: Actions.ON_ERROR });
    }
  }, [dispatch, data, error, loading, Actions]);

  function updateDirectoryListing() {
    if (form) {
      runUpdate({
        phoneNumber,
        directoryListingLevel: form.publishLevelCode as PublishLevelCode,
      });
    }
  }

  function handleToggle(key: string) {
    dispatch({
      type: Actions.SET_FORM,
      payload: { publishLevelCode: key as PublishLevelCode },
    });
  }

  return (
    <ExpandableCard type="multiple">
      <ExpandableCard.Item value="content1">
        <ExpandableCard.Trigger
          onClick={() => {
            !loading && dispatch({ type: Actions.RESET });
          }}
        >
          <ExpandableCard.Header>
            <ExpandableCard.Description>
              <Flex direction="column" gap="100">
                <Heading as="h5" variant="title-100">
                  Opplysningstjenester
                </Heading>
                <div>
                  {isInformationDisplayed ? (
                    <Badge variant="success" hideIcon>
                      Informasjon vises
                    </Badge>
                  ) : (
                    <Badge variant="neutral">Ingen informasjon vises</Badge>
                  )}
                </div>
              </Flex>
            </ExpandableCard.Description>
            <ExpandableCard.Indicator />
          </ExpandableCard.Header>
        </ExpandableCard.Trigger>
        <ExpandableCard.Content>
          <Notification variant="success" open={showSuccess}>
            <Notification.Dismiss />
            <Notification.Heading as="h3">
              <Notification.Icon>Dine endringer er lagret.</Notification.Icon>
            </Notification.Heading>
            <Notification.Text>Det kan ta opp til 5 minutter før du ser endringen her.</Notification.Text>
          </Notification>
          <Notification variant="error" open={showError}>
            <Notification.Dismiss />
            <Notification.Heading as="h3">
              <Notification.Icon>Endringene dine ble ikke lagret. Vennligst prøv på nytt.</Notification.Icon>
            </Notification.Heading>
          </Notification>
          {directoryListings?.filter(notEmpty).map((item) => {
            return item.key ? (
              <Fragment key={item.key}>
                <StyledRadioButtonListWrapper>
                  <Label>{item?.shortDescription || ""}</Label>
                  <Toggle>
                    <Toggle.Input
                      checked={form.publishLevelCode === item.key}
                      onCheckedChange={() => handleToggle(item.key || "")}
                      disabled={loading}
                    >
                      <Toggle.Indicator />
                      <Toggle.Thumb />
                    </Toggle.Input>
                  </Toggle>
                </StyledRadioButtonListWrapper>
              </Fragment>
            ) : null;
          })}

          <Heading as="h4" variant="subsection-100">
            Informasjon som vil vises:
          </Heading>

          {form.publishLevelCode !== PublishLevelCode.NO_INFO && (
            <p data-di-mask>
              {user?.firstName} {user?.surname}
              <br />
              {user?.telephoneNumber?.localNumber}
            </p>
          )}
          {form.publishLevelCode === PublishLevelCode.NAME_PHONE_ADDRESS && (
            <p data-di-mask>
              {displayAddress}
              <br />
              {displayLocation?.postcode} {displayLocation?.city}
            </p>
          )}
          {form.publishLevelCode !== previousForm.publishLevelCode && (
            <Flex gap="200">
              <Button
                onClick={() => dispatch({ type: Actions.RESET })}
                type="button"
                variant="tertiary-purple"
                disabled={loading}
              >
                Avbryt
              </Button>

              <Button
                onClick={(e: SyntheticEvent) => {
                  e.preventDefault();
                  updateDirectoryListing();
                }}
                disabled={loading}
                loading={loading}
                data-tracking-id="service-save-button"
              >
                {loading ? "Lagrer" : "Lagre"}
              </Button>
            </Flex>
          )}
        </ExpandableCard.Content>
      </ExpandableCard.Item>
    </ExpandableCard>
  );
}
