import { Container, Paragraph, ShoppingSummary, TextInput, useToast } from "@telia-no-min-side/components";
import { Text, Button, Icon } from "@telia/teddy";
import { useNavigate } from "react-router-dom";
import { isValidEmailAddress, track, uri } from "@telia-no-min-side/utils";
import { AdditionalSimType, AdditionalProduct, OrderOp } from "src/gql/graphql";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import {
  isValidDeliveryAddress,
  SimCheckoutView,
  useSimCheckout,
} from "pages/mobile/mobile-subscription-sim-pin-puk/hooks/useSimCheckout";
import { config } from "util/config";
import { getTransactionDataSIM } from "../utils/getTransactionDataSIM";
import { useBankIdMutation } from "hooks/useBankIdMutation";
import { graphql } from "gql";
import { GET_ADDITIONAL_SIM_DATA } from "../graphql/getAdditionalSimData";
import { BankIdRequiredModal } from "components/bank-id-required-modal";
import { getErrorMessage } from "pages/mobile/mobile-subscription-sim-pin-puk/utils";
import { GET_SIM_DATA } from "pages/mobile/mobile-subscription-sim-pin-puk/graphql/getSimData";
import { PostAddress } from "pages/mobile/mobile-subscription-order-blank-sim-card/components/PostAddress";

const { Item, LineSeparator, List, ActionWrapper, Wrapper } = ShoppingSummary;

type Props = {
  isFirstMonthFree: boolean;
  productToOrder: AdditionalProduct;
  isTwinSim: boolean | undefined;
  simCardType: AdditionalSimType;
};

export const ORDER_ADDITIONAL_SIM = graphql(`
  mutation orderAdditionalSIM($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);

export function OrderAdditionalSimCardSummary({ isFirstMonthFree, productToOrder, isTwinSim, simCardType }: Props) {
  const navigate = useNavigate();
  const { phoneNumber } = useMobileAccount();
  const { pushTrackingEvent } = track.useEventTracking();
  const { setCheckoutView, deliveryAddress, userEmail, setUserEmail, simDescription, addressLoading } =
    useSimCheckout();
  const { addToast } = useToast();
  const isEsimSim = simCardType !== AdditionalSimType.HwSimRemote;
  const forceSkipBankIdCheck = !config.requireBankId;

  const [mutation, orderResponse] = useBankIdMutation(
    ORDER_ADDITIONAL_SIM,
    {
      refetchQueries: [
        {
          query: GET_ADDITIONAL_SIM_DATA,
          variables: { phoneNumber },
        },
        {
          query: GET_SIM_DATA,
          variables: { phoneNumber },
        },
      ],
      onCompleted(data) {
        const orderId = data.order?.orderId;
        const transactionData = getTransactionDataSIM(productToOrder, data?.order.orderId);
        pushTrackingEvent(transactionData);
        if (isEsimSim && config.enableEsimInstallationRedirect) {
          navigate(uri.minSideFixed(`/mobil/sim-pin-puk/ordre-status/${phoneNumber}/${orderId}`));
          return;
        }

        addToast({
          text: "SIM er bestilt. Det vil ta et par minutter før endringen blir registrert.",
          variant: "success",
          dataTrackingId: "order-additional-physical-sim-success",
        });

        navigate("..");
      },
      onError(error) {
        addToast({
          text: `Bestillingen kunne ikke gjennomføres. ${getErrorMessage(error.message)}`,
          variant: "error",
          dataTrackingId: "order-additional-physical-sim-error",
        });
        navigate("..");
      },
    },
    "orderAdditionalSimCard"
  );

  const onSubmitOrder = () => {
    mutation(
      {
        variables: {
          phoneNumber,
          input: [
            {
              operation: OrderOp.OrderAdditionalSimCard,
              additionalSimType: simCardType,
              productName: productToOrder?.code,
              email: userEmail,
              deliverToLegalOwner: false,
              deliveryAddress,
              productCharacteristics: {
                config: [{ name: "DESCRIPTION", value: simDescription }],
              },
            },
          ],
        },
      },
      forceSkipBankIdCheck
    );
  };

  const price = productToOrder.price;

  const freeMonthText = isFirstMonthFree ? "1 md. rabatt" : "";
  const simCardDetailsText = isTwinSim ? `TvillingSIM ${freeMonthText}` : `Ekstra dataSIM ${freeMonthText}`;

  const isValidAddress = simCardType === AdditionalSimType.HwSimRemote ? isValidDeliveryAddress(deliveryAddress) : true;
  const isValidOrder = phoneNumber && isValidEmailAddress(userEmail) && isValidAddress;

  return (
    <Wrapper dataTrackingID="order-sim-card-summary-block">
      <List title="Månedspris">
        <Item
          text={simCardDetailsText}
          price={`${price?.price},-/md.`}
          secondaryText={simCardType === AdditionalSimType.EsimQr ? "eSIM" : "Vanlig SIM"}
          additionalText={isFirstMonthFree ? `Deretter ${price?.priceWithoutDiscount},-/md.` : ""}
        />
        <Item
          text="Totalt per måned"
          price={`${price?.priceWithoutDiscount},-/md.`}
          highlight
          secondaryText={"Belastes på din neste faktura"}
        />
      </List>
      <LineSeparator />
      {simCardType === AdditionalSimType.HwSimRemote ? (
        <>
          <List>
            <Paragraph>
              <strong>* Din adresse</strong>
            </Paragraph>

            <PostAddress />

            <Button variant="text" onClick={() => setCheckoutView(SimCheckoutView.ChangeAddress)}>
              <Icon name="edit">Endre</Icon>
            </Button>
          </List>
          {!isValidAddress && (
            <Text color="red">
              <Container as="span" flexDirection="row" alignItems="center" gap="sm" padding="bottom">
                <Icon name="alert" size="sm">
                  Ufullstendig adresse
                </Icon>
              </Container>
            </Text>
          )}
          <LineSeparator />
        </>
      ) : null}
      <Container gap flexDirection="column">
        <TextInput
          value={userEmail}
          required
          onChange={(e) => setUserEmail(e.target.value)}
          inputMode="email"
          showEditIcon
          style={{ fontWeight: "500" }}
          placeholder="Fyll inn din e-post"
          disabled={addressLoading}
          errorMessage={!addressLoading && !isValidEmailAddress(userEmail) ? "E-postadressen er ugyldig" : ""}
        >
          Din e-post
        </TextInput>
        <Paragraph variant="additional-100" isFaded>
          {simCardType === AdditionalSimType.HwSimRemote
            ? "Vi sender ordrebekreftelse til denne e-posten"
            : "Vi sender eSIM til denne e-postadressen"}
        </Paragraph>
      </Container>
      <LineSeparator />
      <ActionWrapper>
        <Button variant="tertiary-purple" onClick={() => navigate("..")} data-tracking-id="cancel-sim-purchase-button">
          Avbryt
        </Button>
        {forceSkipBankIdCheck ? (
          <Button
            variant="primary"
            loading={orderResponse.loading}
            disabled={!isValidOrder}
            data-tracking-id="order-sim-button"
            onClick={onSubmitOrder}
          >
            Bestill SIM
          </Button>
        ) : (
          <BankIdRequiredModal
            triggerComp={
              <Button
                variant="primary"
                loading={orderResponse.loading}
                disabled={!isValidOrder}
                data-tracking-id="order-sim-button"
              >
                Bestill SIM
              </Button>
            }
            mutateComp={
              <Button
                loading={orderResponse.loading}
                onClick={onSubmitOrder}
                data-tracking-id="bankid-modal-bankid-button"
                disabled={!isValidOrder}
              >
                Til BankID
              </Button>
            }
          />
        )}
      </ActionWrapper>
    </Wrapper>
  );
}
