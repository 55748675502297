import styled from "styled-components";
import { colors } from "@telia/styleguide";
import { Container as TContainer, devices, Paragraph } from "@telia-no-min-side/components";
import { BlackLink } from "../../mobile-subscription-suspended/components/styles";

export const PhoneLink = styled(BlackLink)`
  background-color: ${colors.grey100};
  border-radius: 8px;
  padding: 0.2rem 0.8rem;
  span {
    text-decoration: none;
  }
`;

export const Header = styled(TContainer)`
  @media screen and (min-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    a {
      position: absolute;
      left: 0;
    }
  }
`;

export const Component = styled(TContainer)`
  padding: 4rem 1rem;
`;

export const OpenSubscriptionContainer = styled(TContainer)`
  width: 100%;
  padding: 50px;
  margin: 0 auto 1rem;
  background-color: ${colors.white};
  border: solid 0.5rem ${colors.corePurple700};
`;

export const UserInfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin: 0;
  }

  @media ${devices.sm_down} {
    flex-direction: column;
  }
`;

export const UserInfoKey = styled(Paragraph)`
  width: 150px;
`;
