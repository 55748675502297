import { Flex } from "@telia/teddy";
import { AdminSection, OrderSection, ExploreSection } from "./components";

export function Sections() {
  return (
    <Flex direction="column" gap="50px">
      <AdminSection />
      <OrderSection />
      <ExploreSection />
    </Flex>
  );
}
