import { Accordion, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";

export function HighPriceAccordion(): JSX.Element {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Hvorfor er min første faktura så høy?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Når du blir kunde hos oss, blir du fakturert for en periode fra og med den dagen du ble kunde samt to hele
          perioder frem i tid. Etter den første fakturaen vil de videre fakturaene du mottar gjelde kun én periode av
          gangen, og dermed mer i tråd med hva du forventer.
        </Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
