import { useQuery } from "@apollo/client";
import { TopupCategory } from "gql/graphql";
import { getVoucherCount } from "../utils";
import { GET_ORDER_SECTION_DATA } from "../graphql";
import { useMobileAccount } from "hooks/useMobileAccount";
import { NORDIC_BALTIC_MINUTES_CODE, ROAMING_RESTRICTION_CODE } from "util/constants/topups";
import { canOrderNordicOrBalticMinutesPrepaidPlan } from "util/mobile/prepaid";

export function useOrderSection() {
  const { phoneNumber } = useMobileAccount();

  const { loading, data, error, refetch } = useQuery(GET_ORDER_SECTION_DATA, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
    skip: !phoneNumber,
  });

  const subscription = data?.subscription;
  const additionalProducts = subscription?.additionalProducts;
  const validTopups = (subscription?.topups || []).filter((topup) => !topup.hiddenInGeneralTopupList) || [];

  const dataBoosts = validTopups.filter(
    (topup) => topup.category === TopupCategory.DataBoost || topup.category === TopupCategory.DataBoostFree
  );

  const dataPackages = validTopups
    .filter((topup) => topup.category === TopupCategory.Domestic || topup.category === TopupCategory.DataFree)
    .filter((topup) => !topup.id.includes("GIFT"));

  const freeDataBoosts = dataBoosts.filter((topup) => topup.category === TopupCategory.DataBoostFree);
  const freeDataPackages = dataPackages.filter((topup) => topup.category === TopupCategory.DataFree);

  const freeDataBoostsVoucherCount = getVoucherCount(freeDataBoosts);
  const freeDataPackagesVoucherCount = getVoucherCount(freeDataPackages);

  const roamingProduct = additionalProducts?.find((group) => group?.code === ROAMING_RESTRICTION_CODE);
  const isRoamingBlocked = roamingProduct?.presentation?.active;

  const nordicBalticMinutesProduct = additionalProducts?.find(
    (product) => product?.code?.includes(NORDIC_BALTIC_MINUTES_CODE)
  );
  const isActiveNordicBalticMinutes = nordicBalticMinutesProduct?.presentation?.active || false;
  const canOrderNordicBalticMinutesPrepaidProduct = canOrderNordicOrBalticMinutesPrepaidPlan(
    subscription?.usageTopup?.topupQuotas
  );

  return {
    loading,
    error,
    refetch,
    hasDataBoosts: dataBoosts.length > 0,
    hasDataPackages: dataPackages.length > 0,
    hasFreeDataBoosts: freeDataBoosts.length > 0,
    hasFreeDataPackages: freeDataPackages.length > 0,
    hasNordicBalticMinutesProduct: !!nordicBalticMinutesProduct,
    canOrderNordicBalticMinutesPrepaidProduct,
    isRoamingBlocked,
    isActiveNordicBalticMinutes,
    freeDataBoostsVoucherCount,
    freeDataPackagesVoucherCount,
  };
}
