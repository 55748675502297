import { useFetchProfile } from "src/api/fixed";

export function useFixedInvoice() {
  // This can be used in future to accomadate other common flags/functionality realted to fixed invoice
  const profile = useFetchProfile();
  const isInvoiceOverdueOrInCollection =
    profile.data?.defaultFinancialAccountOverdue || profile.data?.defaultFinancialAccountInCollection;
  return {
    isInvoiceOverdueOrInCollection,
  };
}
