import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Card, color, Button } from "@telia/teddy";
import { zIndex } from "@telia-no-min-side/components";
import { ActionItem } from ".";

export const ActionMenuCard = styled(Card)`
  position: absolute;
  top: 10px;
  right: 0;
  transform: translate(1rem, 2.5rem);
  z-index: ${zIndex.modal};
`;

const commonActionPointStyling = css<{ color?: string }>`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 16px 8px;
  text-decoration: none;
  color: #000000;
  :hover,
  :focus,
  :active {
    color: ${color.teddyColorBrandCorePurple};
    svg path {
      fill: ${color.teddyColorBrandCorePurple};
    }
  }
`;

const negativeActionPointStyle = css`
  ${commonActionPointStyling}
  color: ${color.teddyColorRed400};
  :hover,
  :focus,
  :active {
    color: ${color.teddyColorRed600};
    svg path {
      fill: ${color.teddyColorRed600};
    }
  }
  svg {
    fill: ${color.teddyColorRed400};
  }
`;

export const ActionItemLink = styled(Link)<Pick<ActionItem, "styling">>`
  ${(props) => (props.styling === "negative" ? negativeActionPointStyle : commonActionPointStyling)}
`;

export const ActionItemExternalLink = styled("a")<Pick<ActionItem, "styling">>`
  ${(props) => (props.styling === "negative" ? negativeActionPointStyle : commonActionPointStyling)}
`;

export const ActionItemButton = styled(Button)<Pick<ActionItem, "styling">>`
  ${(props) => (props.styling === "negative" ? negativeActionPointStyle : commonActionPointStyling)}
`;
