import { Box, Card, Icon, spacing, Text, Flex } from "@telia/teddy";
import { VasAdditionalProduct } from "./types";
import { Link } from "react-router-dom";
import { useSelectAccount } from "hooks/useSelectAccount";
import { ProductBadge } from "./ProductBadge";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { MOBILE_VAS } from "util/constants/valueAddedServices";

type Props = {
  activeProducts: VasAdditionalProduct[];
};

export function ActiveVas(props: Props) {
  const { selectedMobileAccount } = useSelectAccount();
  const { orders } = useOrderSubscription();

  return (
    <>
      {props.activeProducts?.map((product) => {
        const dataTrackingId = `vas-${product.productGroup?.toLowerCase()}-active`;
        const inOrder = orders.some(
          (order) => order.phoneNumber === selectedMobileAccount?.phoneNumber && order.vasProduct === product?.code
        );
        return (
          <Card
            layout="navigation-vertical"
            key={`${product.id}-${product.code}`}
            variant="white"
            bordered
            shadow
            data-tracking-id={dataTrackingId}
          >
            <Card.Slot align="top-right">
              <ProductBadge product={product} />
            </Card.Slot>
            <Card.Illustration>
              <ServiceImage type={product.productGroup} />
            </Card.Illustration>
            <Card.Heading as="h3" variant="title-100">
              {product.shortName}
            </Card.Heading>
            <Card.Content>
              <Text data-tracking-id={`additional-services-description-${product.productGroup?.toLowerCase()}`}>
                {product.shortDescription}
              </Text>
              {product.productGroup ? <Box ml={"auto"} asChild mt="auto"></Box> : null}
            </Card.Content>
            <Flex justify="end" width="100%">
              <Card.Button
                aria-label={inOrder ? "Se mer" : product.presentation?.active ? "Administrer" : "Aktiver nå"}
                variant="secondary"
                iconOnly
                asChild
              >
                <Link to={`${product.productGroup}/${selectedMobileAccount?.phoneNumber}`}>
                  <Icon name="arrow-right" />
                </Link>
              </Card.Button>
            </Flex>
          </Card>
        );
      })}
    </>
  );
}

export function ServiceImage({ type }: { type: string | null | undefined }) {
  if (type === MOBILE_VAS.SKY) {
    return <Icon name="cloud" size="lg" />;
  }
  if (type === MOBILE_VAS.TRYGG) {
    return <Icon name="secured-2" size="lg" />;
  }
  if (type === MOBILE_VAS.HBO) {
    return (
      <Box width="auto" maxWidth={spacing.teddySpacing800} maxHeight={spacing.teddySpacing400} asChild>
        <img alt="max" src="https://www.telia.no/globalassets/system/logo-kanaler/max-basis-med-reklame-logo.svg" />
      </Box>
    );
  }
  if (type === MOBILE_VAS.STORYTEL) {
    return (
      <Box width="auto" maxWidth={spacing.teddySpacing800} maxHeight={spacing.teddySpacing400} asChild>
        <img alt="max" src="https://www.telia.no/globalassets/strommetjenester/storytel-logo-no-text.png" />
      </Box>
    );
  }
  return null;
}
