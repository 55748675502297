import { useState } from "react";
import { Box, Card, Heading, Flex, Text, Button } from "@telia/teddy";
import { types, uri, track } from "@telia-no-min-side/utils";
import { Link, useNavigate } from "react-router-dom";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { getSimplePriceText } from "util/priceFormatter";
import { getChargePeriodText } from "util/prices";
import { openPdf } from "util/pdf-order-summary/openPdf";
import { DownloadButton } from "components/pdf-order-summary/DownloadButton";
import { PdfHeader } from "components/pdf-order-summary/PdfHeader";
import { PdfFooter } from "components/pdf-order-summary/PdfFooter";
import { Receipt } from "./Receipt";
import { getProductShipping, getOrderToPlace, getTransactionTracking } from "pages/internet/broadband/utils";
import { getPriceProductGroupSingleProductWithShipping } from "util/getPriceProductGroupSingleProductWithShipping";

type Props = {
  selectedRouter: types.fixed.ProductGroupSingleProductWithShipping | undefined;
  smartExtender: types.fixed.ProductGroupSingleProductWithShipping | undefined;
  quantityExtender: number;
};

export function OrderSummary({ selectedRouter, smartExtender, quantityExtender }: Props) {
  const [showReceipt, setShowReceipt] = useState(false);
  const [placeOrderResponseStatus, setPlaceOrderResponseStatus] = useState<
    types.fixed.ResponseStatusSubmitMultipleProducts | undefined
  >(undefined);
  const navigate = useNavigate();
  const submitOrder = useAxiosPost<types.fixed.ResponseSubmitMultipleProducts, types.fixed.SubmitMultipleProducts>(
    uri.purchase(ICX_REQUEST.URL_MULTIPLE_PRODUCTS_SUBMIT),
    {
      onSuccess(response) {
        setPlaceOrderResponseStatus(response.status);
      },
      onError() {
        setPlaceOrderResponseStatus("order_failed");
      },
      onFinally() {
        setShowReceipt(true);
      },
    }
  );
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const extenderShippings = quantityExtender > 0 ? smartExtender?.shippingOptionDTOS : undefined;
  const routerShippings = selectedRouter?.shippingOptionDTOS;
  const shipping = getProductShipping(extenderShippings, routerShippings);
  const routerPrice = getPriceProductGroupSingleProductWithShipping(selectedRouter, 1);
  const extenderPrice = getPriceProductGroupSingleProductWithShipping(smartExtender, quantityExtender);

  const totalCost = (routerPrice?.totalRecurringPrice ?? 0) + (extenderPrice?.totalRecurringPrice ?? 0);
  const chargePeriod = routerPrice?.chargePeriod ?? extenderPrice?.chargePeriod ?? "";
  const priceText = getSimplePriceText(totalCost, chargePeriod);
  const chargePeriodTextLowercase = getChargePeriodText(chargePeriod, false);
  const chargePeriodTextCapitalized = getChargePeriodText(chargePeriod, true);

  const currentOrder = getOrderToPlace({
    selectedRouter,
    smartExtender,
    quantityExtender,
    shippingMethodId: shipping?.shippingMethodId,
  });
  if (!currentOrder.axiomIds.length) return null;

  const setReceiptModalVisible = (show: boolean) => {
    setShowReceipt(show);
    if (!show) {
      navigate(-1);
    }
  };

  const handleOrderSubmit = () => {
    const trackInfo: track.GenericEvent = {
      ui_item_text: TRACK_EVENT.ITEM_TEXT.CONFIRM_PURCHASE,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
      ui_item_context: selectedRouter?.name || smartExtender?.name || "Router/extender",
      ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
    };
    pushGenericTrackingEvent(trackInfo);
    submitOrder.post(currentOrder, getTransactionTracking({ selectedRouter, smartExtender, quantityExtender }));
  };

  return (
    <Box id="internet-equipment-order-summary">
      <PdfHeader />
      <Card variant="white" mb="200" shadow justify="stretch" bordered>
        <Box mr="auto" ml="auto">
          <Heading as="h2" variant="title-200">
            Ordresammendrag
          </Heading>
        </Box>
        <Heading as="h3" variant="title-100" mt="300">
          {chargePeriodTextCapitalized + "spris"}
        </Heading>
        {selectedRouter?.name && routerPrice && (
          <Flex justify="space-between" mt="100">
            <Text>1x {selectedRouter?.name}</Text>
            <Text>{routerPrice.displayPriceRecurring}</Text>
          </Flex>
        )}
        {quantityExtender > 0 && extenderPrice && (
          <Flex justify="space-between" mt="100">
            <Text>{quantityExtender}x Smart Wifi Extender</Text>
            <Text>{extenderPrice.displayPriceRecurring}</Text>
          </Flex>
        )}
        <Flex justify="space-between" mt="100">
          <Text variant="paragraph-100-bold">Totalt per {chargePeriodTextLowercase}</Text>
          <Text variant="paragraph-100-bold">{priceText}</Text>
        </Flex>
        <Text variant="additional-100" mt="100">
          Belastes på din neste faktura
        </Text>
        {shipping && (
          <>
            <Card.Line mt="200" />
            <Heading as="h3" variant="title-100" mt="200">
              Engangspris
            </Heading>
            <Flex justify="space-between" mt="100">
              <Text>Frakt</Text>
              <Text>{shipping?.onceOffPrice},-</Text>
            </Flex>
            <Text variant="additional-100" mt="100">
              {shipping.description}
            </Text>
            <Flex justify="space-between" mt="100">
              <Text variant="paragraph-100-bold">Totalt engangsbeløp</Text>
              <Text variant="paragraph-100-bold">{shipping?.onceOffPrice},-</Text>
            </Flex>
            <Text variant="additional-100" mt="100">
              Belastes på din neste faktura
            </Text>
          </>
        )}
        <Card.Line mt="200" data-html2canvas-ignore />
        <DownloadButton mr="auto" onClick={async () => openPdf("#internet-equipment-order-summary")} />
        <Card.Footer data-html2canvas-ignore>
          <Box mr="200">
            <Button variant="text" asChild>
              <Link to={INTERNAL_LINK.FIXED.BROADBAND_HOME}>Avbryt</Link>
            </Button>
          </Box>
          <Button
            onClick={handleOrderSubmit}
            variant="primary"
            disabled={submitOrder.isLoading}
            loading={submitOrder.isLoading}
          >
            {submitOrder.isLoading ? "Gjennomfører kjøp" : "Bekreft kjøp"}
          </Button>
        </Card.Footer>
        {placeOrderResponseStatus && (
          <Receipt
            setIsOpen={setReceiptModalVisible}
            isOpen={showReceipt}
            status={placeOrderResponseStatus}
            isBuyingRouter={!!selectedRouter}
          />
        )}
      </Card>
      <PdfFooter />
    </Box>
  );
}
