import "./App.less";
import { TV as TvDashboardNew } from "./pages/tv/Tv";

// NEW PAGES ROUTES (TEMPORARY):
import { BuyNewTvBoxPage } from "pages/tv/buy/new-tv-box";
import { BuyRecordingPage } from "pages/tv/buy/recording";
import { BuyRemoteControlPage } from "pages/tv/buy/remote-control";
import { TvPointCompensation } from "pages/tv/compensation/TvPointCompensation";
import { SelectUserForCompensation } from "pages/tv/compensation/SelectUserForCompensation";
import { CompensationReceipt } from "pages/tv/compensation/CompensationReceipt";
import { CompensationLayout } from "pages/tv/compensation/CompensationLayout";
import { CompensationError } from "pages/tv/compensation/CompensationError";
import { Navigate } from "react-router-dom";
import { uri } from "@telia-no-min-side/utils";

export const tvRoutes = [
  {
    path: "tv",
    handle: {
      crumb: "Strømming & TV",
    },
    children: [
      { index: true, element: <TvDashboardNew /> },
      {
        path: "tv-innhold",
        element: <Navigate to={uri.minSideFixed("/minside/tv")} replace />,
      },
      {
        path: "tv-bokser",
        element: <Navigate to={uri.minSideFixed("/minside/tv")} replace />,
      },
      {
        path: "tilleggstjenester",
        element: <Navigate to={uri.minSideFixed("/minside/tv")} replace />,
      },
      {
        path: "tilleggstjenester/kjop-opptak",
        element: <BuyRecordingPage />,
        handle: {
          crumb: "Bestill opptak",
        },
      },
      {
        path: "tv-bokser/kjop-fjernkontroll",
        element: <BuyRemoteControlPage />,
        handle: {
          crumb: "Kjop fjernkontroll",
        },
      },
      {
        path: "tv-bokser/kjop-ny-tv-boks",
        element: <BuyNewTvBoxPage />,
        handle: {
          crumb: "Bestill Telia Play Box",
        },
      },
      {
        path: "kompensasjon",
        handle: {
          crumb: "Kompensasjon",
        },
        children: [
          { index: true, element: <SelectUserForCompensation /> },
          {
            path: ":customerId",
            element: <CompensationLayout />,
            children: [
              { index: true, element: <TvPointCompensation /> },
              { path: "kvittering", element: <CompensationReceipt /> },
              { path: "feil", element: <CompensationError /> },
            ],
          },
        ],
      },
    ],
  },
];
