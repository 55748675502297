import { Container, Paragraph } from "@telia-no-min-side/components";
import { Icon } from "@telia/teddy";

type Props = {
  isOpen: boolean;
  subscriptionName: string;
  transitionDurationWithUnit: string;
};

const viaplayInfo = [
  { icon: "price", text: "Premier League" },
  {
    icon: "flag",
    text: "Sport - Formel 1, Viaplay Vinter, UEFA Europa League, UFC, Bundesliga, DP World Tour og mange andre!",
  },
  {
    icon: "film",
    text: "Film & Serier ",
  },
] as const;

export function IncludedInViaplay({ isOpen, subscriptionName, transitionDurationWithUnit }: Props) {
  return (
    <Container
      padding="top"
      style={{
        opacity: isOpen ? 1 : 0,
        transitionProperty: "opacity",
        transitionDuration: transitionDurationWithUnit,
      }}
      gap
      flexDirection="column"
    >
      <Paragraph removeMargin>
        <em>Inkludert i Viaplay Total</em>
      </Paragraph>
      {viaplayInfo.map(({ icon, text }) => (
        <div key={`${icon}-content-${subscriptionName}`}>
          <Container flexDirection="row" gap alignItems="flex-start">
            {icon && (
              <div>
                <Icon name={icon} size="md" />
              </div>
            )}
            <Paragraph removeMargin>{text}</Paragraph>
          </Container>
        </div>
      ))}
    </Container>
  );
}
