import type { SyntheticEvent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Badge, BadgeProps, Button, Card, Flex, Text } from "@telia/teddy";
import { InvoiceDistribution, PaymentMethodName } from "gql/graphql";
import { format, track } from "@telia-no-min-side/utils";
import { canCreateDirectDebit } from "util/mobile/invoice";
import { useRef } from "react";

type LozengeType = { status: BadgeProps["variant"]; label: string };
type Props = {
  cardHeading: string;
  cardContent: ReactNode;
  buttonHref: string;
  onLinkNavigation?: (e: SyntheticEvent) => void;
  dataTrackingID?: string;
  balance: number;
  lastInvoiceAmount: number | undefined;
  isLastInvoicePaid: boolean | null | undefined;
  paymentMethodName?: PaymentMethodName | null;
  invoiceDistributionType?: InvoiceDistribution;
};

export function FinanceCard({
  cardHeading,
  cardContent,
  buttonHref,
  onLinkNavigation,
  dataTrackingID,
  balance,
  lastInvoiceAmount,
  isLastInvoicePaid,
  paymentMethodName,
  invoiceDistributionType,
}: Props) {
  const additionalLinkRef = useRef<HTMLAnchorElement>(null);
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const showMobileDirectDebitLink = canCreateDirectDebit(paymentMethodName, invoiceDistributionType, isLastInvoicePaid);
  const showViewInvoiceLabel =
    isLastInvoicePaid ||
    paymentMethodName === PaymentMethodName.Avtalegiro ||
    invoiceDistributionType === InvoiceDistribution.EFaktura;

  const getBadgeDetails = (): LozengeType => {
    if (isLastInvoicePaid) {
      return { status: "success", label: "Betalt" };
    }
    if (paymentMethodName === PaymentMethodName.Avtalegiro) {
      return { status: "information", label: "Betales med AvtaleGiro" };
    }
    if (invoiceDistributionType === InvoiceDistribution.EFaktura) {
      return { status: "information", label: "Betales med eFaktura" };
    }
    return { status: "information", label: "Ubetalt" };
  };

  const badgeDetails = getBadgeDetails();

  function onLinkClick(e: SyntheticEvent) {
    e.stopPropagation();
    if (onLinkNavigation) onLinkNavigation(e);
  }

  return (
    <Card
      layout="default"
      bordered
      shadow
      variant="white"
      width="100%"
      maxWidth="400px"
      onClick={onLinkClick}
      onPointerUp={(e) => {
        if (additionalLinkRef.current === e.target) {
          e.preventDefault();
        }
      }}
      data-tracking-id={dataTrackingID}
    >
      {typeof lastInvoiceAmount === "number" ? (
        <Card.Slot align="top-right">
          <Badge variant={badgeDetails.status}>{badgeDetails.label}</Badge>
        </Card.Slot>
      ) : null}

      <Card.Heading as="h3" variant="title-100">
        {cardHeading}
      </Card.Heading>

      <Card.Content>
        {balance < 0 && isLastInvoicePaid !== false && (
          <Flex justify="between">
            <span>Penger til gode:</span>
            <span>{format.formatPrice(-balance)}</span>
          </Flex>
        )}
        {isLastInvoicePaid === false &&
          (typeof lastInvoiceAmount === "number" ? (
            <Flex justify="between">
              <Text>Siste faktura:</Text>
              <Text>{format.formatPrice(Math.abs(lastInvoiceAmount))}</Text>
            </Flex>
          ) : (
            <Text>Klarte ikke å hente din siste faktura.</Text>
          ))}
        {balance > 0 && balance !== lastInvoiceAmount && isLastInvoicePaid === false && (
          <Flex justify="between">
            <em>Utestående beløp:</em>
            <em>{format.formatPrice(Math.abs(balance))}</em>
          </Flex>
        )}
        {isLastInvoicePaid && (
          <Text>
            <em>Du har betalt det du skal.</em>
          </Text>
        )}
        {cardContent}
      </Card.Content>

      <Card.Footer height="100%" align="end">
        <Flex direction="row" justify={showMobileDirectDebitLink ? "between" : "end"} width="100%">
          {showMobileDirectDebitLink && (
            <Button asChild variant="text" onClick={onLinkClick}>
              <Link ref={additionalLinkRef} to="/minside/faktura/avtalegiro">
                Opprett AvtaleGiro
              </Link>
            </Button>
          )}

          <Card.Button
            asChild
            variant="text"
            onClick={(e) => {
              onLinkClick(e);
              pushGenericTrackingEvent({
                ui_item_action: "link_click",
                ui_item_context: "finance card",
                ui_item_type: "link",
                ui_item_url: buttonHref,
                ui_item_text: showViewInvoiceLabel ? "Se fakturaoversikt" : "Betal faktura",
              });
            }}
          >
            <Link to={buttonHref}>{showViewInvoiceLabel ? "Se fakturaoversikt" : "Betal faktura"}</Link>
          </Card.Button>
        </Flex>
      </Card.Footer>
    </Card>
  );
}
