import React, { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { CollapseWrapper } from "./style";

export type Props = {
  isOpen: boolean;
  children: ReactNode;
  /**
   * Points to the ID of the panel which the header controls.
   */
  id: string;
  /**
   * Defines the accessible name for the region element.
   * References the collapse header button that expands and collapses the region.
   * Region elements are required to have an accessible name to be identified as a landmark.
   */
  ariaLabelledby: string;
  className?: string;
};

/**
 * @deprecated Use Teddy components instead
 */
export function Collapse(props: Props) {
  const { isOpen, className, ariaLabelledby, id, children } = props;
  const [heightToAnimate, setHeightToAnimate] = useState(0);
  const childWrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!childWrapperRef.current) return;
    if (isOpen) {
      const height = childWrapperRef.current.getBoundingClientRect().height;
      setHeightToAnimate(height);
      return;
    }
    setHeightToAnimate(0);
  }, [isOpen, childWrapperRef.current, children]);

  return (
    <CollapseWrapper animateHeight={heightToAnimate}>
      <div
        role="region"
        className={className}
        aria-hidden={!isOpen}
        aria-labelledby={ariaLabelledby}
        id={id}
        aria-expanded={isOpen}
        ref={childWrapperRef}
      >
        {children}
      </div>
    </CollapseWrapper>
  );
}
