import { graphql } from "gql";

export const GET_ADDITIONAL_SERVICES_GROUP_QUERY = graphql(`
  query getAdditionalServicesGroupQuery($productGroups: [String!]) {
    accounts {
      id
      agreementType
      subscriptions {
        phoneNumber {
          localNumber
        }
        productUser {
          emailAddress
          firstName
        }
        additionalProducts(productGroups: $productGroups) {
          id
          productGroup
          code
          shortName
          shortDescription
          weight
          descriptionLink
          presentation {
            active
            included
          }
          price {
            price
            priceWithoutDiscount
            discountDuration
          }
        }
      }
    }
  }
`);

export const GET_ADDITIONAL_PRODUCT_STATUSES = graphql(`
  query getAdditionalProductStatus($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      additionalProducts {
        id
        productGroup
        code
        presentation {
          active
          selected
          modifiable
          removed
          preselected
          changed
          included
        }
        price {
          price
          priceWithoutDiscount
          discountDuration
        }
        shortName
        productTerms {
          description
          code
          amount
          amountWithoutVat
          monetaryUnit
        }
      }
    }
  }
`);
