import styled, { css } from "styled-components";

export const Container = styled.div<{
  height?: string;
}>(
  (props) => css`
    position: relative;
    height: ${props.height};
  `
);

export const Item = styled.div<{
  width?: string;
  rowPostion?: string;
  columnPosition?: string;
}>(
  (props) => css`
    position: absolute;
    width: ${props.width};
    top: ${props.rowPostion};
    left: ${props.columnPosition};
  `
);
