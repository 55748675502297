import { ReactNode, useEffect } from "react";
import { isPricePlanMobileBroadband } from "src/util/format-subscription/isPricePlanMobileBroadband";
import {
  ProfileValue,
  Subscription,
  isMobileCustomer,
  useProfilePicker,
} from "../profile-pickers/hooks/useProfilePicker";
import { TopMenu } from "./TopMenu";
import { format } from "@telia-no-min-side/utils";
import { PickerButton } from "./PickerButton";
import { Icon } from "@telia/teddy";
import { useMobileAccount } from "hooks/useMobileAccount";
import { getMobileSubscriptions } from "pages/mobile/mobile-subscription-dashboard-layout/utils/getMobileSubscriptions";
import { TopMenuSkeleton } from "./TopMenuSkeleton";
import { datadogRum } from "@datadog/browser-rum";

type Props = {
  subscriptions: ReturnType<typeof getMobileSubscriptions>;
  subcriptionType?: "all" | "mbb" | "mobile";
  onProfileSelect?: (id: string, profile: Subscription) => void;
  /* pass additional components */
  children?: ReactNode;
};

export function MobileTopMenu({ subcriptionType = "mobile", children, subscriptions }: Props) {
  const { newMobile: mobile } = useProfilePicker({ filter: "mobile" });
  const { phoneNumber, setPhoneNumber, setAccountId } = useMobileAccount();

  useEffect(() => {
    const selectedSubscription = subscriptions.find(
      (subscription) => subscription?.phoneNumber.localNumber === phoneNumber
    );

    if (!selectedSubscription) {
      setAccountId(subscriptions[0]?.account.id || "");
      setPhoneNumber(subscriptions[0]?.phoneNumber.localNumber || "");
    }
  }, []);

  const mapSubscriptions = () => {
    if (subcriptionType === "mbb") {
      return mobile.profiles.filter((profile) => isPricePlanMobileBroadband(profile.value?.userOffering?.pricePlan));
    }
    if (subcriptionType === "mobile") {
      return mobile.profiles.filter((profile) => !isPricePlanMobileBroadband(profile.value?.userOffering?.pricePlan));
    }
    return mobile.profiles;
  };

  const showRetry = !mobile.loading && mobile.hasError;
  const onRetry = () => {
    if (mobile.hasError) {
      mobile.refetch();
    }
  };

  const onProfileChange = (value: ProfileValue) => {
    if (isMobileCustomer(value)) {
      setAccountId(value.account.id);
      setPhoneNumber(value.phoneNumber.localNumber);
    }
  };

  const profiles = [...mapSubscriptions()];

  const selectedProfile = profiles.find((profile) => profile.id === phoneNumber);
  const info = {
    title: format.startCase(
      `${selectedProfile?.value.productUser?.firstName || ""} ${
        selectedProfile?.value.productUser?.surname || ""
      }`.toUpperCase()
    ),
    subtitle: format.formatPhoneNumber(selectedProfile?.value.phoneNumber.localNumber),
  };

  if (!selectedProfile) {
    datadogRum.addError("Profile picker does not have selected profile");
    return null;
  }

  const showLoading = !profiles?.length && mobile.loading;
  return showLoading ? (
    <TopMenuSkeleton />
  ) : (
    <TopMenu info={info} selectedOption={selectedProfile} options={profiles} handleOptionSelect={onProfileChange}>
      {children}
      {showRetry && (
        <PickerButton style={{ textWrap: "nowrap" }} subtext="Kan ikke laste" handleClick={onRetry}>
          <Icon size="sm" name="sync" />
        </PickerButton>
      )}
    </TopMenu>
  );
}
