import { Card, Flex, Text, color, Icon, VisuallyHidden } from "@telia/teddy";
import vippsIcon from "./vipps-icon.svg";
import { format } from "@telia-no-min-side/utils";

type Props = {
  value: string;
  valueUnit: string;
  duration?: string | null;
  period: string;
  price: number | undefined;
  buttonText: "Kjøp" | "Aktiver";
  vouchersNumber?: number;
  isVippsPayment: boolean;
  onClick: () => void;
};

export function Topup({
  value,
  valueUnit,
  duration,
  period,
  price,
  buttonText,
  onClick,
  vouchersNumber,
  isVippsPayment,
}: Props) {
  const renderPaymentType = () => {
    if (vouchersNumber)
      return (
        <>
          <Icon name="present" /> <Text>{vouchersNumber} stk. kostnadsfri</Text>
        </>
      );
    if (isVippsPayment)
      return (
        <>
          {/* TODO: Replace with a URL from contentful or add this to Teddy */}
          <img src={vippsIcon} style={{ width: "18px" }} />
          <Text>Betales med VIPPS</Text>
        </>
      );

    return (
      <>
        <Icon name="invoice" /> <Text>Faktureres via mobilfaktura</Text>
      </>
    );
  };

  return (
    <Card variant={vouchersNumber ? "purple-light" : "gray"} shadow layout="default">
      <Card.Content>
        <Flex gap="50" direction="column">
          <Flex justify="between">
            <Text variant="paragraph-200">
              {value} {valueUnit}
            </Text>
            <Flex gap="100" align="center">
              <Text variant="paragraph-100-medium">
                {price ? format.formatPrice(price, 2) : vouchersNumber && "0,-"}
              </Text>
              <Icon color={color.teddyColorPurple700} size="font" name="chevron-right" />
            </Flex>
          </Flex>
          <Text variant="additional-100">{duration || period}</Text>

          <Flex gap="100" justify="start" align="center">
            {renderPaymentType()}
          </Flex>
        </Flex>
      </Card.Content>
      <VisuallyHidden>
        <Card.Button onClick={onClick}>{buttonText}</Card.Button>
      </VisuallyHidden>
    </Card>
  );
}
