import { graphql } from "gql";

export const USER_ACCESS_LEVEL = graphql(`
  query user {
    user {
      accessLevel
      loginProvider
    }
  }
`);

export const INVOICED_SERVICE = graphql(`
  query invoicedService($phoneNumber: String!, $invoiceId: String!, $serviceCodes: [String!]!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      invoicedService(invoiceId: $invoiceId, serviceCodes: $serviceCodes) {
        serviceName
        items {
          country {
            code
            name
          }
          date
          duration {
            amount
            unit
            customQuantity
            formatted
          }
          roamingOperator
          price {
            value
            currency
            formatted
          }
          destination {
            phoneNumber {
              countryCode
              localNumber
            }
          }
          quantity {
            amount
            customQuantity
            formatted
            unit
          }
        }
        summary {
          duration {
            formatted
          }
          price {
            value
          }
          quantity {
            formatted
          }
        }
      }
    }
  }
`);

export const UN_BILLED_SERVICE = graphql(`
  query unbilledService($phoneNumber: String!, $serviceCodes: [String!]!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      unbilledService(serviceCodes: $serviceCodes) {
        serviceName
        items {
          country {
            code
            name
          }
          date
          duration {
            amount
            unit
            customQuantity
            formatted
          }
          roamingOperator
          price {
            value
            currency
            formatted
          }
          destination {
            phoneNumber {
              countryCode
              localNumber
            }
          }
          quantity {
            amount
            customQuantity
            formatted
            unit
          }
        }
        summary {
          duration {
            formatted
          }
          price {
            value
          }
          quantity {
            formatted
          }
        }
      }
    }
  }
`);
