import { useQuery } from "@apollo/client";
import { Card, Drawer, Flex } from "@telia/teddy";
import { Topup } from "../../components/Topup";
import { GET_TOPUPS } from "../../graphql";
import { Loading, Error } from "./components";
import { useMobileAccount } from "hooks/useMobileAccount";
import { getTopupDisplayInfo } from "../../../TopupModal/utils";
import { AdditionalProductPaymentType, GetTopupsNewQuery, TopupCategory } from "gql/graphql";
import { EmptyTopupNotification } from "../../components/EmptyTopupNotification";
import { isDataBoostTopup, isDataPackageTopup, getDataPackagesDescription } from "../../utils";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";

export function DataBoostsAndPackages() {
  const { phoneNumber } = useMobileAccount();
  const { isB2B, selectedTopupCategory, handleTopupSelection } = useMobileDashboardContext();
  const isDataBoost = selectedTopupCategory === TopupCategory.DataBoost;

  const { data, loading, error, refetch } = useQuery<GetTopupsNewQuery>(GET_TOPUPS, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
    skip: !phoneNumber,
  });

  if (loading) return <Loading />;
  if (error) return <Error refetch={refetch} />;

  const subscription = data?.subscription;
  const isB2BDobbelSubscription = isB2B && !!subscription?.userOffering?.shortName?.toLowerCase().includes("dobbel");

  const selectedTopups = subscription?.topups
    ?.filter((topup) => !topup.hiddenInGeneralTopupList)
    ?.filter(isDataBoost ? isDataBoostTopup : isDataPackageTopup)
    ?.filter((topup) =>
      isB2B && "paymentType" in topup ? topup.paymentType === AdditionalProductPaymentType.Vipps : true
    )
    ?.filter((topup) => !topup.id.includes("GIFT"));

  const title = isDataBoost ? "Data Boost" : "Datapakker";
  const description = isDataBoost
    ? "Data Boost gir deg ubegrenset med data, fra kjøp til pakken utløper. Velg den varigheten på pakken som passer best for deg."
    : getDataPackagesDescription(selectedTopups || [], isB2BDobbelSubscription);

  return (
    <>
      <Drawer.Close aria-label="Close" slot="floating" />
      <Drawer.Title variant="title-200">{title}</Drawer.Title>
      <Card.Line />
      <Drawer.Description>{description}</Drawer.Description>
      <Flex direction="column" gap="gutter-sm">
        {selectedTopups && selectedTopups.length > 0 ? (
          selectedTopups.map((topup) => {
            const value = topup.name.split(" ")[0];
            const valueUnit = topup.name.split(" ")[1];
            const price = "price" in topup ? topup.price?.valueWithVat : undefined;
            const topupInfo = getTopupDisplayInfo(topup);
            const hasVouchers = topupInfo.numberOfVouchers && topupInfo.numberOfVouchers > 0;
            const vouchersNumber = hasVouchers ? topupInfo.numberOfVouchers : 0;
            const isVippsPayment =
              "paymentType" in topup ? topup.paymentType === AdditionalProductPaymentType.Vipps : false;

            return (
              <Topup
                key={topup.id + topup.name}
                value={value}
                valueUnit={valueUnit}
                period={isDataBoost ? "Data Boost" : "Datapakke"}
                price={price}
                buttonText={hasVouchers ? "Aktiver" : "Kjøp"}
                isVippsPayment={isVippsPayment}
                onClick={() => handleTopupSelection(topup)}
                vouchersNumber={vouchersNumber}
              />
            );
          })
        ) : (
          <EmptyTopupNotification />
        )}
      </Flex>
    </>
  );
}
