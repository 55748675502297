import { Drawer, Card, Text, Button, Flex, VisuallyHidden } from "@telia/teddy";

export function RLHDrawerContent() {
  return (
    <>
      <Drawer.Title>Roam Like Home</Drawer.Title>
      <Card.Line />
      <Flex direction="column" gap="400">
        <VisuallyHidden>
          <Drawer.Description>
            For Mobilt Bredbånd kjøpt f.o.m 18. mars 2020 gjelder norske priser også i Norden og Baltikum. Bruk utenfor
            disse områdene er ikke mulig. Gjelder ikke Mobilt Bredbånd Bedrift og Mobilt Bredbånd Kontakt - disse vil
            det ikke være mulig å bruke i utlandet.
          </Drawer.Description>
        </VisuallyHidden>

        <Text aria-hidden>
          For Mobilt Bredbånd kjøpt f.o.m 18. mars 2020 gjelder norske priser også i Norden og Baltikum. Bruk utenfor
          disse områdene er ikke mulig.
        </Text>
        <Text aria-hidden>
          Gjelder ikke Mobilt Bredbånd Bedrift og Mobilt Bredbånd Kontakt - disse vil det ikke være mulig å bruke i
          utlandet.
        </Text>
        <Drawer.Close slot="content">
          <Button>Lukk</Button>
        </Drawer.Close>
      </Flex>
    </>
  );
}
