import { types } from "@telia-no-min-side/utils";

type ShippingMethod = "POSTAL" | "POSTAL_FREE" | "HOME_DELIVERY";

const shippingMethodId = {
  POSTAL: 2,
  POSTAL_FREE: 7,
  HOME_DELIVERY: 4,
};

function getShippingByShippingMethod(
  shippingMethod: ShippingMethod,
  productShipping?: types.fixed.ProductGroupShipping[]
) {
  return productShipping?.find((product) => product.shippingMethodId === shippingMethodId[shippingMethod]);
}

function getMostExpensiveShipping(
  product1Shipping?: types.fixed.ProductGroupShipping,
  product2Shipping?: types.fixed.ProductGroupShipping
) {
  if (!product1Shipping) return product2Shipping;
  if (!product2Shipping) return product1Shipping;
  if (product1Shipping?.onceOffPrice > product2Shipping?.onceOffPrice) return product1Shipping;
  if (product1Shipping?.onceOffPrice < product2Shipping?.onceOffPrice) return product1Shipping;
  return product1Shipping;
}

export function getProductShipping(
  product1?: types.fixed.ProductGroupShipping[],
  product2?: types.fixed.ProductGroupShipping[]
): types.fixed.ProductGroupShipping | undefined {
  const product1Postal = getShippingByShippingMethod("POSTAL", product1);
  const product2Postal = getShippingByShippingMethod("POSTAL", product2);

  if (product1Postal || product2Postal) {
    return getMostExpensiveShipping(product1Postal, product2Postal);
  }
  const product1Home = getShippingByShippingMethod("HOME_DELIVERY", product1);
  const product2Home = getShippingByShippingMethod("HOME_DELIVERY", product2);
  if (product1Home || product2Home) {
    return getMostExpensiveShipping(product1Home, product2Home);
  }

  const product1Free = getShippingByShippingMethod("POSTAL_FREE", product1);
  const product2Free = getShippingByShippingMethod("POSTAL_FREE", product2);
  return getMostExpensiveShipping(product1Free, product2Free);
}
