import { keyframes } from "styled-components";

export const fadeIn = keyframes`
0%{
  opacity: 0;
}
100%{
  opacity: 1;
}
`;

export const fadeInAndScaleY = keyframes`
0%{
  opacity: 0;
  transform: scaleY(0);
}
100%{
  opacity: 1;
  transform: scaleY(1);
}
`;
