import styled from "styled-components";
export const FakeloginForm = styled.form<{ fadeOut: boolean }>`
  position: fixed;
  bottom: 5px;
  right: 5px;
  background-color: white;
  box-shadow: 0 0 10px #000000aa;
  z-index: 10000;
  padding: 0.5rem;
  border-radius: 10px;
  display: grid;
  gap: 0.5rem;
  backface-visibility: hidden;
  translate: ${({ fadeOut }) => (fadeOut ? "100%" : 0)} 0;
  transition: translate 250ms ease;
  overflow: visible;

  p {
    margin: 0;
  }
`;

export const HideButton = styled.div<{ rotateLeft: boolean }>`
  position: absolute;
  right: calc(100% - 5px);
  rotate: ${({ rotateLeft }) => (rotateLeft ? "180deg" : 0)};
  transition: rotate 1s ease;
  translate: 0 0 -1px;
  backface-visibility: hidden;
`;
