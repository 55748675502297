class DatadogError extends Error {
  dd_fingerprint?: string;
}

type DataDogErrorInput = {
  customMessage?: string;
  // errorName is shown in dd issue title (error tracking view)
  customErrorName?: string;
  // dd will use this string to group errors into issues
  // https://docs.datadoghq.com/real_user_monitoring/error_tracking/custom_grouping/
  errorGroup?: string;
};

export const enhanceErrorData = (
  originalError: Error,
  { customMessage, customErrorName, errorGroup }: DataDogErrorInput
) => {
  originalError.name = customErrorName || originalError.name;
  originalError.message = customMessage || originalError.message;
  if (errorGroup) {
    (originalError as DatadogError).dd_fingerprint = errorGroup;
  }

  return originalError;
};
