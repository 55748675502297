import { useEffect } from "react";
import { Icon, Skeleton } from "@telia/teddy";
import { uri, track } from "@telia-no-min-side/utils";
import { useFetchProfile } from "src/api/fixed";
import { fetchPartnerInformation, isTelia } from "util/PartnerHacks";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { useFixedInvoice } from "hooks/useFixedInvoice";
import { UpsellTeliaPlay } from "./UpsellTeliaPlay";
import { ErrorBox } from "components/ErrorBox";

export function NotATvCustomer() {
  const profile = useFetchProfile();
  const { isInvoiceOverdueOrInCollection } = useFixedInvoice();
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const partnerInfo = fetchPartnerInformation(profile.data?.businessUnitId);

  const actionContent = (actionText: string) => (
    <>
      {actionText} <Icon name="arrow-right" />
    </>
  );

  useEffect(() => {
    const trackEvent = {
      event: TRACK_EVENT.EVENT.CUSTOMER_NO_PROD,
      affiliation: TRACK_EVENT.AFFILIATION.TELIA_MIN_SIDE,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.NOT_TV_CUSTOMER,
      ui_item_action: TRACK_EVENT.ITEM_ACTION.VIEW,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.NO_PRODUCT_ACTIVE,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.INFO_MSG,
      ui_item_url: TRACK_EVENT.ITEM_URL.TV_CONTENT,
    };
    pushGenericTrackingEvent(trackEvent);
  }, []);

  if (profile.isLoading) {
    return <Skeleton loading radius="md" as="span" maxWidth="60ch" height="24ch" />;
  }

  // Logged in without fixed customerid, gives 204 on /api/profile/get
  if (!profile) {
    return (
      <ErrorBox
        title="Ikke TV-kunde ennå?"
        href={uri.openPages("/tv/#sok")}
        maxWidth="600px"
        actionContent={actionContent("Sjekk om du kan få Telia Play")}
        description={
          "Med Telia Play kan du samle strømmetjenester og TV på ett sted. " +
          "Det er en enkel og fleksibel måte å strømme på, som gjør at du " +
          "ikke lenger trenger å ha mange ulike abonnement. Du kan isteden " +
          "velge innhold inn og ut i TV-pakken som det passer deg."
        }
      />
    );
  }

  // Only Telia customers can order TV on MinSide
  if (!isTelia(profile.data?.businessUnitId)) {
    if (partnerInfo) {
      return (
        <ErrorBox
          title="Ikke TV-kunde ennå?"
          href={partnerInfo.homepage}
          maxWidth="600px"
          actionContent={actionContent(`Gå til ${partnerInfo.name}`)}
          description={`Ta kontakt med ${partnerInfo.name} på tlf ${partnerInfo.phone} eller gå til hjemmesiden deres om du ønsker TV.`}
        />
      );
    }
    return (
      <ErrorBox
        title="Ikke TV-kunde ennå?"
        href={uri.openPages("/tv/#sok")}
        maxWidth="600px"
        actionContent={actionContent("Sjekk hva vi kan levere hos deg")}
        description="Med strømming og TV fra Telia har du tilgang til over 100 strømmetjenester og kanaler i samme abonnement."
      />
    );
  }

  // Customer cannot buy if invoice is overdue or in collection
  if (isInvoiceOverdueOrInCollection) {
    const overdueText =
      "Vi kan dessverre ikke selge deg Telia Play grunnet ubetalt faktura. " +
      "Vennligst betal utestående beløp og prøv igjen.";

    const collectionText =
      "Vi kan dessverre ikke selge deg Telia Play grunnet ubetalt faktura. " +
      "Etter du har betalt det du skylder, kan du kontakte Telia sin " +
      "kundeservice for å få tilgang på en ny konto.";

    return (
      <ErrorBox
        href={INTERNAL_LINK.INVOICE_PAGE}
        actionContent={actionContent("Gå til faktura")}
        title="Ikke TV-kunde ennå?"
        maxWidth="600px"
        description={profile.data?.defaultFinancialAccountOverdue ? overdueText : collectionText}
        openInSameTab={true}
      />
    );
  }

  // Customers can buy TV on MinSide if they already have Internet from Telia.
  // If not they have to use the schema on open pages.
  return <UpsellTeliaPlay />;
}
