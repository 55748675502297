import { AccountData, AgreementType } from "src/gql/graphql";

export type SubscriptionType = "mobile" | "mobile-broadband";

export function isB2BAccount(account: Required<Pick<AccountData, "agreementType">>) {
  return account.agreementType === AgreementType.Business;
}

export function removeCountryCodeFromPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/^\+47/, "");
}
