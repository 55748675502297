import { ChangeEvent, useState, ReactNode } from "react";
import { Alert, Container, Paragraph, useToast } from "@telia-no-min-side/components";
import { isValidEmailAddress, track, uri } from "@telia-no-min-side/utils";
import { getErrorMessage } from "./utils/getErrorMessage";
import { CartSummary } from "../CartSummary";
import { CheckoutContainer } from "../styles/Checkout.styled";
import { VasAdditionalProduct } from "../types";
import { SkyProduct } from "./SkyProduct";
import { useMutation, ApolloError } from "@apollo/client";
import { graphql } from "gql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { useParams, useNavigate } from "react-router-dom";
import { getTeliaSkyOrderInput } from "./utils/getTeliaSkyOrderInput";
import { TextField } from "@telia/teddy";
import { VAS_MESSAGE } from "util/constants/valueAddedServices";
import { getTransactionDataVAS } from "pages/value-added-services/utils/getTransactionDataVAS";

type Props = {
  product: VasAdditionalProduct;
  hasFreeTeliaSkyOnFixedSide: boolean;
};

const orderTeliaSkyMutation = graphql(`
  mutation orderTeliaSky($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);

export const TeliaSkyCheckout = ({ product, hasFreeTeliaSkyOnFixedSide }: Props) => {
  const [email, setEmail] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");
  const params = useParams<{ productGroup: string; selectedUserId: string }>();
  const phoneNumber = params.selectedUserId;
  const [errorMessage, setErrorMessage] = useState<{ title: string; text: ReactNode } | undefined>();
  const { addToast } = useToast();
  const { pushTrackingEvent } = track.useEventTracking();
  const navigate = useNavigate();
  const navigateBack = () => navigate(uri.minSideFixed("/tjenester"));
  const { subscribeToOrder, orders } = useOrderSubscription();
  const [orderTeliaSky, { loading }] = useMutation(orderTeliaSkyMutation, {
    onCompleted(data) {
      setErrorMessage(undefined);
      addToast({ text: VAS_MESSAGE.ORDER_COMPLETED, variant: "success" });
      product.code &&
        data?.order.orderId &&
        subscribeToOrder({
          id: data?.order.orderId,
          phoneNumber: phoneNumber || "",
          vasProduct: product.code,
          toastText: VAS_MESSAGE.SERVICE_ACTIVATED,
        });

      const transactionData = getTransactionDataVAS(product, data?.order.orderId);
      pushTrackingEvent(transactionData);
      navigateBack();
    },

    onError(error: ApolloError) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
    },
  });

  const orderSky = () => {
    if (!product) return;

    const isValid = isValidEmailAddress(email);

    if (isValid) {
      setEmailValidationError("");
      const orderInput = getTeliaSkyOrderInput(product, email);
      phoneNumber && orderTeliaSky({ variables: { input: orderInput, phoneNumber: phoneNumber } });
    } else {
      setEmailValidationError(VAS_MESSAGE.INVALID_EMAIL);
    }
  };

  if (!product) return null;

  const inOrder = orders.some((order) => order.vasProduct === product.code && order.phoneNumber === phoneNumber);

  return (
    <CheckoutContainer data-tracking-id="additional-services-telia-sky-checkout">
      <SkyProduct product={product} hasFreeTeliaSkyOnFixedSide={hasFreeTeliaSkyOnFixedSide} />
      {!hasFreeTeliaSkyOnFixedSide && (
        <Container maxWidth="xs" flexDirection="column" gap="lg">
          <Alert icon="alert" kind="negative" title={errorMessage?.title} isOpen={!!errorMessage?.title}>
            {errorMessage?.text}
          </Alert>
          {!inOrder && (
            <CartSummary selectedProduct={product} loading={loading} onOrder={orderSky} onCancel={navigateBack}>
              <TextField
                isRequired
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                  setEmailValidationError("");
                }}
                errors={emailValidationError ? [emailValidationError] : []}
                disabled={loading}
              >
                <TextField.Label>Din e-post</TextField.Label>
                <TextField.Input placeholder="Fyll inn din e-post" type="text" value={email} autoComplete="email" />
                <TextField.ErrorList />
              </TextField>

              <Paragraph variant="paragraph-100">
                Det er viktig at din e-post er riktig, siden det er her du får tilgang til {product.shortName}.
              </Paragraph>
            </CartSummary>
          )}
        </Container>
      )}
    </CheckoutContainer>
  );
};
