import { datadogRum } from "@datadog/browser-rum";
import { Button, Container, Skeleton } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchInvoiceDetails } from "src/api/fixed";
import { useInvoice } from "src/store/hooks/invoice-context";
import { downloadFile } from "src/util/downloadfile";
import { DetailsInfoHero } from "../../payment-info/DetailsInfoHero";
import { ButtonWrapper, DetailsWrapper, ReceiptPaper } from "../../styles";
import { DetailsElementListFixed } from "./DetailsElementListFixed";
import { InvoiceSelectorFixed } from "./InvoiceSelectorFixed";
import { PostponeInvoice } from "../PostponeInvoice";
import { shouldAllowPostponing } from "util/fixed/invoice";

export type InvoiceDetailsParams = {
  id?: string;
};

export type DetailsIsOpen = {
  tv: boolean;
  broadband: boolean;
  ipTelephony: boolean;
  other: boolean;
};

export const InvoiceDetailsFixed = () => {
  const [isPostoneInvoiceModalOpen, setIsPostoneInvoiceModalOpen] = useState(false);
  const { mutateInvoices, lastInvoice, financial } = useInvoice();
  const [isOpen, setIsOpen] = useState<DetailsIsOpen>({
    tv: false,
    broadband: false,
    ipTelephony: false,
    other: false,
  });
  const [isDownloadingInvoice, setIsDownloadingInvoice] = useState(false);
  const params = useParams<InvoiceDetailsParams>();
  const invoiceId = params.id;
  const invoiceDetailsRequest = useFetchInvoiceDetails(invoiceId);

  const invoiceDetails = invoiceDetailsRequest.data;

  const isAllOpen = Object.values(isOpen).every((state) => state);

  function onChange(id: keyof DetailsIsOpen, forceValue?: boolean) {
    setIsOpen((prevState) => ({
      ...prevState,
      [id]: forceValue ?? !prevState[id],
    }));
  }

  function toggleAll() {
    setIsOpen((prevState) => ({
      tv: prevState.tv ? !isAllOpen : true,
      broadband: prevState.broadband ? !isAllOpen : true,
      ipTelephony: prevState.ipTelephony ? !isAllOpen : true,
      other: prevState.other ? !isAllOpen : true,
    }));
  }
  function mutateInvoicesAndDetails() {
    invoiceDetailsRequest.mutate();
    mutateInvoices();
  }

  async function downloadInvoice() {
    setIsDownloadingInvoice(true);
    try {
      await downloadFile({
        url: uri.minSideFixed(`/invoice/pdf/${invoiceDetails?.invoiceNumber}`),
        filename: `faktura-${invoiceDetails?.invoiceNumber}.pdf`,
      });
    } catch (error) {
      datadogRum.addError(error, { feature: "Download invoice" });
    } finally {
      setIsDownloadingInvoice(false);
    }
  }

  return (
    <Container padding="bottom" goBackTo={uri.minSideFixed("/faktura")}>
      <DetailsInfoHero
        invoiceDate={dayjs.unix(Number(invoiceDetails?.invoiceDate)).format("MMMM YYYY")}
        dueDate={dayjs.unix(Number(invoiceDetails?.dueDate)).format("DD. MMMM YYYY")}
        kidNumber={invoiceDetails?.kidNumber ?? ""}
        bankAccount={invoiceDetails?.bankAccount ?? ""}
        paid={lastInvoice?.invoice.invoiceId === invoiceDetails?.invoiceId ? Boolean(invoiceDetails?.paid) : undefined}
        isLegalOwner
        isLoading={invoiceDetailsRequest.isLoading}
      >
        <InvoiceSelectorFixed />
      </DetailsInfoHero>
      <DetailsWrapper>
        <DetailsElementListFixed
          detailsIsOpen={isOpen}
          onChange={onChange}
          invoiceDetails={invoiceDetails}
          mutateInvoices={mutateInvoicesAndDetails}
          invoiceDetailsIsLoading={invoiceDetailsRequest.isLoading}
          isPostponingDisabled={!shouldAllowPostponing(lastInvoice, invoiceDetails, financial)}
          setIsPostoneInvoiceModalOpen={setIsPostoneInvoiceModalOpen}
        />
        <ReceiptPaper />
        <ButtonWrapper>
          {invoiceDetailsRequest.isLoading && (
            <>
              <Skeleton variant="button" />
              <Skeleton variant="button" />
            </>
          )}
          {invoiceDetails && (
            <>
              <Button
                icon="download"
                onClick={downloadInvoice}
                variant="secondary"
                fullWidthSmallScreen
                disabled={invoiceDetailsRequest.isLoading}
                isLoading={isDownloadingInvoice}
              >
                Last ned pdf
              </Button>

              <Button icon="zoom" fullWidthSmallScreen variant="secondary" onClick={toggleAll}>
                {isAllOpen ? "Lukk detaljert faktura" : "Se fakturadetaljer"}
              </Button>
            </>
          )}
        </ButtonWrapper>
      </DetailsWrapper>
      {invoiceDetails?.dueDate && (
        <PostponeInvoice
          setIsModalOpen={setIsPostoneInvoiceModalOpen}
          modalOpen={isPostoneInvoiceModalOpen}
          dueDate={invoiceDetails.dueDate}
        />
      )}
    </Container>
  );
};
