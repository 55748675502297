import { Container, TrackClickEvent, useAuth } from "@telia-no-min-side/components";
import { L } from "src/util/links";
import { Heading, Text, Icon, Button } from "@telia/teddy";
import { LostPhone } from "./LostPhone";
import { LostPhonePlusInsurance } from "./LostPhonePlusInsurance";
import { SwitchHeading } from "../Common/SwitchHeading";
import { SwitchInsuranceType } from "gql/graphql";
import { useSafeWebshopUrl, webshopSwitchPath, initialDataToEncrypt } from "../../utils/createWebshopUrl";
import { useOutletContext } from "react-router-dom";
import { SwitchOutletContext } from "../../types";
import { URL_REPORT_THEFT_OR_DAMAGE } from "util/constants/agreements";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { toLocalPhoneNumber } from "@telia-no-min-side/utils/src/format";

export function StolenPhone() {
  const { switchAgreement, accountId } = useOutletContext<SwitchOutletContext>();
  const { userInfo } = useAuth();
  const userPhoneNumber = toLocalPhoneNumber(userInfo?.phone_number);

  const { data: safeWebshopUrlData, loading } = useSafeWebshopUrl({
    host: L.TeliaNoUrl.href,
    path: webshopSwitchPath,
    dataToEncrypt: {
      ...initialDataToEncrypt,
      imei: switchAgreement?.imei || "",
      msisdn: switchAgreement?.phoneNumber?.localNumber || "",
      orderer_msisdn: switchAgreement?.phoneNumber?.localNumber || userPhoneNumber,
      ban: accountId,
      missing: true,
      keep: true,
    },
  });

  return (
    <Container flexDirection="column" alignItems="start" gap="lg">
      <SwitchHeading preamble="Før du kan svitsje" heading="Telefonen er stjålet eller mistet" />
      <Container flexDirection="column" alignItems="start" gap="lg">
        <Heading as="h4" variant="title-100">
          Har du blitt frastjålet telefonen?
        </Heading>
        <Text as="p">
          Da bør du melde dette til politiet og sperre abonnementet for å hindre misbruk. Tyveri må meldes til politiet
          før du kan sende skademelding.
        </Text>
        <Container flexDirection="column" alignItems="start">
          <TrackClickEvent
            asChild
            event={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_stolen-phone",
              ui_item_text: "Sperr SIM-kortet ditt her",
              ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
              ui_item_url: L.BlockSubscription.href,
            }}
          >
            <Button asChild variant="text">
              <a href={L.BlockSubscription.href}>
                Sperr SIM-kortet ditt her
                <Icon name="chevron-right" />
              </a>
            </Button>
          </TrackClickEvent>
          <TrackClickEvent
            asChild
            event={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_stolen-phone",
              ui_item_text: "Anmeld tyveri på Politi.no",
              ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
              ui_item_url: URL_REPORT_THEFT_OR_DAMAGE,
            }}
          >
            <Button asChild variant="text">
              <a href={URL_REPORT_THEFT_OR_DAMAGE}>
                Anmeld tyveri på Politi.no
                <Icon name="chevron-right" />
              </a>
            </Button>
          </TrackClickEvent>
        </Container>
        {switchAgreement?.insurance?.type === SwitchInsuranceType.Plus ? (
          <LostPhonePlusInsurance />
        ) : (
          <LostPhone
            safeWebshopUrl={safeWebshopUrlData?.safeWebshopUrl}
            remainingCost={switchAgreement?.remainingCost?.value}
            loading={loading}
          />
        )}
      </Container>
    </Container>
  );
}
