import { ReactNode } from "react";
import { Flex, Card, Text, Button, Icon } from "@telia/teddy";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { track } from "@telia-no-min-side/utils";

export type ErrorCardProps = {
  isLoading: boolean;
  onRefreshClick: () => void;
  title?: string;
} & { sectionText: ReactNode };

export function ErrorCard({ isLoading, onRefreshClick, sectionText, title }: ErrorCardProps) {
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const handleClick = () => {
    onRefreshClick();
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.REFRESH,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.RELOADING,
    });
  };

  return (
    <Card variant="white" shadow bordered width="100%" maxWidth="400px" layout="default">
      <Flex direction="column" gap="150">
        <Card.Heading as="h3" variant="title-100">
          {title || "Feil oppstod!"}
        </Card.Heading>
        <Card.Content>
          {sectionText && <Text>Vi klarte ikke hente {sectionText}. Vennligst prøv igjen litt senere.</Text>}
        </Card.Content>

        <Card.Footer>
          <Button onClick={handleClick} loading={isLoading}>
            <Icon name="sync" />
            Last inn på nytt
          </Button>
        </Card.Footer>
      </Flex>
    </Card>
  );
}
