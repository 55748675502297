import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { GeneralSWRResponse, GetCustomerSpecificUrlArgs, ReturnData, getCustomerSpecificUrl } from "./utils";
import { useFetchProfile } from "./useFetchProfile";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchFlexCurrent<T extends GetCustomerSpecificUrlArgs | undefined = undefined>(
  args?: T
): GeneralSWRResponse<T, types.fixed.FlexCurrent> {
  const profile = useFetchProfile();
  const customerId = args?.customerId || profile.data?.customerId.toString();
  const { error, ...rest } = useAxiosSWR<ReturnData<T, types.fixed.FlexCurrent>>(
    getCustomerSpecificUrl(
      ICX_REQUEST.URL_FLEX_CURRENT,
      args?.fetchAllAccounts ? args : { ...args, customerId, requireCustomerId: true }
    )
  );

  return {
    isError: !!error,
    error,
    ...rest,
  };
}
