import React, { FormEvent, useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { Link as ReactRouterLink } from "react-router-dom";
import { Button, Text, Link, Icon, TextField } from "@telia/teddy";
import { Container, SelfRecoveryPageWrapper, UserInfo, useAuth } from "@telia-no-min-side/components";
import { track, uri } from "@telia-no-min-side/utils";
import { useAnswerChallenge, useSendChallenge } from "hooks/useChallenge";
import { useSelfVerifyProductUser } from "hooks/useSelfVerifyProductUser";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type verificationSteps = "PHONE_INPUT" | "OTP_INPUT" | "INITIAL" | "SUCCESS";

function SubmitButton({ isSubmitting }: { isSubmitting: boolean }) {
  return (
    <Button type="submit" variant="primary" loading={isSubmitting}>
      Fortsett
    </Button>
  );
}

export function MobileUserVerification() {
  // ** For stage only, in stage otp is bypassed on backend for testing purposes.,
  // This is a hack to get the frontend validation to pass.
  const [searchParams] = useSearchParams();
  const bypassotpEmail = searchParams.get("bypassotp");

  const justVerified = window.location.href.includes("justverified");
  const navigate = useNavigate();
  const { requestBankId, userInfo, tokenParsed } = useAuth();
  const { sendChallengeResponse, runSendChallenge } = useSendChallenge();
  const { selfVerifyProductUserResponse, runSelfVerifyProductUser } = useSelfVerifyProductUser();
  const { answerChallengeResponse, runAnswerChallenge } = useAnswerChallenge();
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const [isSubmittingPhoneNumber, setisSubmittingPhoneNumber] = useState(false);
  const [isSubmittingOTP, setIsSubmittingOTP] = useState(false);
  const [phoneInput, setPhoneInput] = useState("");
  const [phoneInputError, setPhoneInputError] = useState("");
  const [OTPInput, setOTPInput] = useState("");
  const [OTPInputError, setOTPInputError] = useState("");
  const [currentStep, setCurrentStep] = useState<verificationSteps>(justVerified ? "PHONE_INPUT" : "INITIAL");
  const [errorMessage, setErrorMessage] = useState("");
  const [hasDoneBankId, setHasDoneBankid] = useState(false);
  const [currentChallengeId, setCurrentChallengeId] = useState("");
  const challengeId = sendChallengeResponse.data?.sendChallenge?.id;
  const challengeAccepted = answerChallengeResponse.data?.answerChallenge?.accepted;

  function hasCiamUserInfo(userInfo: UserInfo): userInfo is Required<UserInfo> {
    return !!(
      userInfo?.given_name &&
      userInfo?.family_name &&
      userInfo?.no_person_id &&
      userInfo?.["urn:teliacompany:dis:ciam:1.0:party_id"] &&
      userInfo?.birthdate
    );
  }

  useEffect(() => {
    function fetchUserUser() {
      try {
        setHasDoneBankid(!!(tokenParsed?.acr === "3" && tokenParsed?.amr?.includes("bankid") && justVerified));
      } catch (e) {
        setOTPInputError("Noe gikk galt. Vennligst prøv igjen.");
      }
    }
    if (justVerified) {
      fetchUserUser();
    }
  }, [justVerified, tokenParsed]);

  useEffect(() => {
    if (challengeId) {
      setCurrentChallengeId(challengeId);
      setCurrentStep("OTP_INPUT");
    }
  }, [challengeId]);

  useEffect(() => {
    if (challengeAccepted === false) {
      setOTPInputError("Du har tastet feil engangskode. Vennligst send kode på nytt og prøv igjen.");
      setIsSubmittingOTP(false);
    }
  }, [challengeAccepted]);

  useEffect(() => {
    if (selfVerifyProductUserResponse.called && selfVerifyProductUserResponse.data?.selfVerifyProductUser?.result) {
      const result = selfVerifyProductUserResponse.data.selfVerifyProductUser.result;
      setIsSubmittingOTP(false);

      if (result === "SUBSCRIPTION_NOT_FOUND") {
        setErrorMessage("Ukjent nummer. Prøv igjen, eller kontakt kundeservice.");
        return;
      }
      if (result === "NAME_MISMATCH") {
        setErrorMessage(
          "Din informasjon hos oss samsvarer ikke med informasjonen hos Folkeregisteret. For å løse problemet, kontakt kundeservice. Husk at du må benytte din egen BankID for at vi skal kunne hente produktene dine. Dersom produktene står på en annen enn deg, må den personen gjøre BankID-sjekken selv"
        );
        return;
      }
      if (result === "UPDATE_SUCCESS") {
        pushGenericTrackingEvent({
          ui_item_action: TRACK_EVENT.ITEM_ACTION.SHOW,
          ui_item_context: TRACK_EVENT.ITEM_CONTEXT.SELF_RECOVERY,
          ui_item_type: TRACK_EVENT.ITEM_TYPE.MESSAGE,
          ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
          ui_item_text: TRACK_EVENT.ITEM_TEXT.SUCCESS,
        });
        setCurrentStep("SUCCESS");
        return;
      } else {
        setErrorMessage("Noe gikk galt. Prøv igjen, eller kontakt kundeservice.");
        return;
      }
    }
  }, [selfVerifyProductUserResponse]);

  useEffect(() => {
    async function updateProductUser(userInfo: Required<UserInfo>) {
      try {
        const productUserUpdate = {
          challengeId: currentChallengeId,
          phoneNumber: phoneInput,
          firstName: userInfo.given_name.toLowerCase(),
          surname: userInfo?.family_name.toLowerCase(),
          personId: userInfo?.no_person_id,
          partyId: userInfo["urn:teliacompany:dis:ciam:1.0:party_id"],
          birthDate: userInfo?.birthdate,
        };
        await runSelfVerifyProductUser(productUserUpdate);
      } catch (e) {
        setIsSubmittingOTP(false);
        setOTPInputError("Noe gikk galt. Vennligst prøv igjen.");
      }
    }

    if (userInfo && hasCiamUserInfo(userInfo) && challengeAccepted) {
      updateProductUser(userInfo);
    }
  }, [userInfo, phoneInput, currentChallengeId, challengeAccepted]);

  const onVerify = () => {
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.SELF_RECOVERY,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
      ui_item_url: TRACK_EVENT.ITEM_URL.IDENTITY,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.VERIFY_YOURSELF,
    });
    if (requestBankId) requestBankId("justverified");
  };

  const handleResendOTP = async () => {
    setErrorMessage("");
    setOTPInputError("");
    setIsSubmittingOTP(true);
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.SELF_RECOVERY,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.RESEND_CODE,
    });

    await runSendChallenge({
      destination: `+47${phoneInput}`,
      channel: "SMS",
    });
    setIsSubmittingOTP(false);
  };

  function validatePhoneNumber(phoneNumber: string) {
    return phoneNumber?.toString().match("[0-9]{8}");
  }

  function handleBackClick(e: React.BaseSyntheticEvent) {
    e.preventDefault();
    setErrorMessage("");
    setOTPInputError("");
    setPhoneInputError("");
    if (currentStep === "OTP_INPUT") {
      setCurrentStep("PHONE_INPUT");
    } else {
      navigate(uri.minSideFixed("/minside/hjelp/"));
    }
  }

  async function onPhoneNumberSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    const form = new FormData(e.currentTarget);
    const phoneNumber = form.get("phoneNumber")?.toString() || "";
    const phoneNumberIsValid = validatePhoneNumber(phoneNumber);

    if (phoneNumberIsValid && hasDoneBankId) {
      setPhoneInput(phoneNumber);
      setPhoneInputError("");
      setOTPInput("");
      try {
        setisSubmittingPhoneNumber(true);
        // For testing only, see comment at top **
        if (bypassotpEmail) {
          await runSendChallenge({
            destination: `${bypassotpEmail}`,
            channel: "EMAIL",
          });
        } else {
          await runSendChallenge({
            destination: `+47${phoneNumber}`,
            channel: "SMS",
          });
        }

        pushGenericTrackingEvent({
          ui_item_action: TRACK_EVENT.ITEM_ACTION.PHONE_NUMBER_SUBMIT,
          ui_item_context: TRACK_EVENT.ITEM_CONTEXT.SELF_RECOVERY,
          ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
          ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
          ui_item_text: TRACK_EVENT.ITEM_TEXT.ENTER_MOBILE_NUMBER,
        });
        setisSubmittingPhoneNumber(false);
      } catch (error) {
        setPhoneInputError("Ukjent nummer. Vennligst kontakt kundeservice.");
        setisSubmittingPhoneNumber(false);
        pushGenericTrackingEvent({
          ui_item_action: TRACK_EVENT.ITEM_ACTION.PHONE_NUMBER_SUBMIT,
          ui_item_context: TRACK_EVENT.ITEM_CONTEXT.SELF_RECOVERY,
          ui_item_type: TRACK_EVENT.ITEM_TYPE.MESSAGE,
          ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
          ui_item_text: TRACK_EVENT.ITEM_TEXT.UNKNOWN_NUMBER,
        });

        datadogRum.addError(error, { feature: "SelfVerifyPhoneNumber" });
      }
    }
  }

  async function onOTPSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const isValidOTP = OTPInput.length === 6 && /^\d+$/.test(OTPInput);

    if (!isValidOTP) {
      return;
    }

    try {
      setIsSubmittingOTP(true);
      await runAnswerChallenge({
        challengeId: currentChallengeId,
        challengeAnswer: OTPInput,
      });
      pushGenericTrackingEvent({
        ui_item_action: TRACK_EVENT.ITEM_ACTION.OTP_SUBMIT,
        ui_item_context: TRACK_EVENT.ITEM_CONTEXT.SELF_RECOVERY,
        ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
        ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
        ui_item_text: TRACK_EVENT.ITEM_TEXT.ENTER_OTP,
      });
    } catch (error) {
      setIsSubmittingOTP(false);
      setOTPInputError("Noe gikk galt. Vennligst prøv igjen.");
      pushGenericTrackingEvent({
        ui_item_action: TRACK_EVENT.ITEM_ACTION.OTP_SUBMIT,
        ui_item_context: TRACK_EVENT.ITEM_CONTEXT.SELF_RECOVERY,
        ui_item_type: TRACK_EVENT.ITEM_TYPE.MESSAGE,
        ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
        ui_item_text: TRACK_EVENT.ITEM_TEXT.SOMETHING_WRONG_TRY_AGAIN,
      });

      datadogRum.addError(error, { feature: "SelfVerifyOTP" });
    }
  }

  return (
    <SelfRecoveryPageWrapper title="Hent dine mobilprodukter" onGoBackClick={handleBackClick}>
      {currentStep === "INITIAL" && (
        <StepWrapper>
          <Text>
            For at vi skal kunne hente dine opplysninger trenger vi mer informasjon fra deg. Etter du har verifisert deg
            med BankID, sender vi deg en engangskode til ditt mobilnummer for å fullføre prosessen. Husk at du må
            benytte din egen BankID for at vi skal kunne hente produktene dine.
          </Text>
          <Button variant="primary" onClick={onVerify}>
            Verifiser deg
          </Button>
          <Text>
            Har du ikke mulighet til å bruke BankID, er du velkommen til å kontakte kundeservice for å løse denne
            situasjonen.
          </Text>
          <Link
            onClick={() => {
              pushGenericTrackingEvent({
                ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                ui_item_context: TRACK_EVENT.ITEM_CONTEXT.SELF_RECOVERY,
                ui_item_type: TRACK_EVENT.ITEM_TYPE.PHONE_NUMBER_LINK,
                ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
                ui_item_text: TRACK_EVENT.ITEM_TEXT.CALL_CUSTOMER_SERVICE,
              });
            }}
            variant="standalone"
            href="tel:+4792405050"
          >
            <Icon name="phone" /> +47 924 05 050
          </Link>
        </StepWrapper>
      )}
      {currentStep === "PHONE_INPUT" && (
        <Form onSubmit={onPhoneNumberSubmit}>
          <TextField errors={phoneInputError ? [phoneInputError] : undefined}>
            <TextField.Label>Fyll inn ditt mobilnummer</TextField.Label>
            <TextField.Input
              value={phoneInput}
              type="tel"
              name="phoneNumber"
              autoComplete="tel"
              placeholder="Skriv inn her"
              maxLength={8}
              onBlur={() => {
                if (phoneInput.length < 8) setPhoneInputError("Ugyldig nummer");
              }}
              onChange={(event) => {
                if (phoneInputError) setPhoneInputError("");
                setPhoneInput(event.target.value);
              }}
            />
            <TextField.ErrorList />
          </TextField>
          <SubmitButton isSubmitting={isSubmittingPhoneNumber} />
        </Form>
      )}
      {currentStep === "OTP_INPUT" && (
        <Form onSubmit={onOTPSubmit}>
          <TextField errors={OTPInputError || errorMessage ? [OTPInputError || errorMessage] : undefined}>
            <TextField.Label>Skriv inn engangskoden du fikk tilsendt</TextField.Label>
            <TextField.Input
              value={OTPInput}
              type="text"
              placeholder="Skriv inn her"
              name="otp"
              onChange={(event) => {
                if (OTPInputError) {
                  setErrorMessage("");
                  setOTPInputError("");
                }
                setOTPInput(event.target.value);
              }}
            />
            <TextField.ErrorList />
          </TextField>
          <SubmitButton isSubmitting={isSubmittingOTP} />
          Engangskode ble sendt til {phoneInput}
          <Button variant="secondary" type="button" onClick={handleResendOTP}>
            Send kode på nytt
          </Button>
        </Form>
      )}
      {currentStep === "SUCCESS" && (
        <>
          <Text>Suksess!</Text>
          <Text>Abonnementene dine vil snart bli synlig i appen og på Min Side. Det vil ta ca 10 minutter.</Text>
          <Container padding="top">
            <Button variant="primary" asChild>
              <ReactRouterLink to={uri.minSideFixed("/minside/hjem")} rel="noreferrer">
                Tilbake til Min side
              </ReactRouterLink>
            </Button>
          </Container>
        </>
      )}
    </SelfRecoveryPageWrapper>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;
const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
