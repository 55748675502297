import React from "react";
import { CheckBoxWithLabel, Heading } from "@telia/styleguide";
import { IpAddressInput } from "../../../modules/IPAddressInput";
import { ContentWrapper } from "../../../modules/LayoutComponents/ContentWrapper";

export const Dmz = ({ errorMessages, onChange, handleDmzCheck, dmzEnabled, dmzIp, dmzContract, mask, onBlur }) => (
  <ContentWrapper>
    <Heading level={3} text={"DMZ-host"} />
    <CheckBoxWithLabel label={"Aktiver DMZ"} onChange={handleDmzCheck} checked={dmzEnabled} />
    <IpAddressInput
      address={dmzIp}
      numberOfeditableParts={1}
      onChange={onChange}
      typeOfAddress={"dmz"}
      title={"Adresse"}
      mask={mask}
      addressContract={dmzContract}
      onBlur={onBlur}
      errorMessage={errorMessages.map((msg) => msg.message).join(" og ")}
    />
  </ContentWrapper>
);
