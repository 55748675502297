import { Card, Badge, Icon, Text } from "@telia/teddy";

type Props = {
  subHeading: string | null;
  subscriptionName: string;
};

export function SubscriptionCardB2B({ subHeading, subscriptionName }: Props) {
  return (
    <Card variant="white" layout="navigation-vertical" bordered height={subHeading ? "120px" : "100px"}>
      <Card.Slot align="top-right">
        <Badge variant="information">
          <Icon name="suitcase" />
          Bedriftsabonnement
        </Badge>
      </Card.Slot>

      {subHeading && (
        <Text as="p" mb="50" variant="paragraph-100">
          {subHeading}
        </Text>
      )}

      <Card.Heading as="h4" variant="title-100">
        {subscriptionName}
      </Card.Heading>
    </Card>
  );
}
