import { colors } from "@telia/styleguide";
import styled from "styled-components";
import { Container } from "../Container";
import { devices } from "../utils/breakpoints";
import { remCalc } from "../utils/fonts";

type ContainerArgs = {
  animateHeight?: number;
  isOpen: boolean;
};

export const animationDuration = 300;
const animationDurationWithUnit = `${animationDuration}ms`;

export const ExpandableCardContentContainer = styled(Container)<ContainerArgs>`
  position: relative;
  overflow: hidden;
  max-height: ${(props) => props.animateHeight}px;
  transition: max-height ${animationDurationWithUnit} ease;
  will-change: opacity;

  ::before {
    content: "";
    display: block;
    position: absolute;
    inset: 0 2rem auto;
    height: 1px;
    background-color: ${colors.grey100};
    @media ${devices.sm_down} {
      inset: 0 1rem auto;
    }
  }
`;

type ContentArgs = {
  animateHeight?: number;
  isOpen: boolean;
};
export const ExpandableCardContent = styled.div<ContentArgs>`
  opacity: ${(props) => (props.animateHeight && props.animateHeight > 0 ? 1 : 0)};
  will-change: opacity;
  transition: ${({ isOpen }) =>
    `opacity ${animationDurationWithUnit} ease, visibility 0ms ease ${isOpen ? "0ms" : animationDurationWithUnit}`};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
`;

export const ExpandableCardContentRegion = styled.div<{ hasPeakContent: boolean; compact: boolean }>`
  display: grid;
  padding: ${({ hasPeakContent, compact }) =>
    hasPeakContent || compact ? `0 ${compact ? 1 : 2}rem 1rem` : `1rem 2rem`};
  @media ${devices.sm_down} {
    padding: ${({ hasPeakContent, compact }) => (hasPeakContent || compact ? "0 1rem 1rem" : "1rem 1rem")};
  }
`;

export const MainTriggerContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  justify-items: stretch;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media ${devices.sm_down} {
    gap: 0.5rem;
  }
`;

export const ExpandableCardTrigger = styled.button<{ compact: boolean }>`
  color: black;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: ${({ compact }) => (compact ? "1rem" : "1rem 2rem")};
  border: none;
  display: grid;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  @media ${devices.sm_down} {
    padding: 1rem;
  }

  ::before {
    position: absolute;
    inset: 0;
    content: "";
  }

  :focus-visible {
    outline: none;
    background: none;
    ::before {
      border-radius: 5px;
      outline: ${remCalc(2)} solid ${colors.blue500};
      outline-offset: ${remCalc(2)};
    }
  }
`;

type ArrowProps = {
  isOpen: boolean;
};

export const Arrow = styled.div<ArrowProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
  transition: transform ${animationDurationWithUnit} ease;
`;
