import { Card } from "@telia/teddy";
import React, { ComponentProps, ReactNode } from "react";

export type RootProps = {
  title?: string;
  maxWidth?: ComponentProps<typeof Card>["maxWidth"];
  children: ReactNode;
  dataTrackingID?: string;
};

export function Root(props: RootProps) {
  const { title = "Ordresammendrag", maxWidth = "400px", dataTrackingID, children } = props;
  return (
    <Card data-tracking-id={dataTrackingID} maxWidth={maxWidth} variant="white" layout="default" bordered>
      <Card.Heading m="0 auto">{title}</Card.Heading>
      <Card.Content>{children}</Card.Content>
    </Card>
  );
}
