import { Card, CardBaseProps, Container, Icon, Paragraph } from "@telia-no-min-side/components";
import { setSearchParams, uri } from "@telia-no-min-side/utils";
import { colors } from "@telia/styleguide";
import { Invoice } from "src/gql/graphql";
import { getPriceAmountText } from "src/util/priceFormatter";
import { CapitalLetterParagraph, StyledLink } from "../styles";

type PreviousInvoiceCardProps = {
  invoiceId: string;
  invoiceDate: string;
  paidText?: string;
  amount: number;
  paid?: Invoice["paid"];
  isMobile?: boolean;
  isSubLevel?: boolean;
  invoicePeriod?: string;
  lozenge?: CardBaseProps["lozengeLeft"];
};

export const PreviousInvoiceCard = ({
  invoiceId,
  invoiceDate,
  amount,
  paid,
  invoicePeriod,
  paidText,
  isMobile,
  isSubLevel,
  lozenge,
}: PreviousInvoiceCardProps) => {
  const queryParams = {
    isMobile,
    isSubLevel,
  };

  const uriParams = setSearchParams(queryParams);
  const iconType = paid == null ? "invoice" : paid ? "check-mark-circle" : "money-krone";
  const iconColor = paid == null ? colors.deepPurple : paid ? "green" : "red";

  return (
    <StyledLink to={uri.minSideFixed(`/faktura/${invoiceId}${uriParams}`)}>
      <Card removeMinHeight lozengeRight={lozenge}>
        <Container floatContent="center" flexDirection="row" gap>
          <Icon icon={iconType} color={iconColor} />
          <Container flexDirection="row" justifyContent="space-between" floatContent="center">
            <div>
              <Container gap="sm" flexDirection="row" alignItems="flex-end">
                <CapitalLetterParagraph removeMargin>{invoiceDate}</CapitalLetterParagraph>
                {invoicePeriod && (
                  <Paragraph removeMargin isFaded variant="additional-100">
                    ({invoicePeriod})
                  </Paragraph>
                )}
              </Container>
              {paidText && (
                <Paragraph variant="additional-50" isFaded>
                  {paidText}
                </Paragraph>
              )}
            </div>
            <Paragraph>{getPriceAmountText(amount, "kr")}</Paragraph>
          </Container>
        </Container>
      </Card>
    </StyledLink>
  );
};
