import { BaseMutationOptions } from "@apollo/client";
import { UpdateCallForwardingMutation, UpdateCallForwardingMutationVariables } from "gql/graphql";
import { ExtraBankIdMutationOptions, useBankIdMutation } from "hooks/useBankIdMutation";
import { graphql } from "src/gql";

export const UPDATE_CALL_FORWARDING = graphql(`
  mutation updateCallForwarding($phoneNumber: String!, $settings: CallForwardPutInput) {
    updateCallForwarding(phoneNumber: $phoneNumber, settings: $settings) {
      orderId
    }
  }
`);

export type CallForward = {
  active: boolean;
  number: string;
};

export type CallForwardingPutInput = {
  callForwardUnconditional: CallForward;
  callForwardWhenBusy: CallForward;
  callForwardWhenNoReply: CallForward;
  callForwardWhenNotReachable: CallForward;
};

type UpdateCallForwardingOptions = BaseMutationOptions<
  UpdateCallForwardingMutation,
  UpdateCallForwardingMutationVariables
> &
  ExtraBankIdMutationOptions;

export function useUpdateCallForwarding(options?: UpdateCallForwardingOptions) {
  const [updateCallForwarding, response] = useBankIdMutation(
    UPDATE_CALL_FORWARDING,
    {
      onCompleted: options?.onCompleted,
      onError: options?.onError,
    },
    options?.uniqueKey || "useUpdateCallForwarding"
  );

  async function runUpdateCallForwarding(
    phoneNumber: string,
    callForwardingInput: CallForwardingPutInput,
    forceSkipBankIdCheck?: boolean
  ) {
    try {
      if (!phoneNumber || !callForwardingInput) return;

      // not using options or mutationOptions options here, because they are not persisted to sessionStorage
      // during navigation to bankid and back
      await updateCallForwarding(
        {
          variables: {
            phoneNumber,
            settings: callForwardingInput,
          },
        },
        forceSkipBankIdCheck
      );
    } catch (error) {
      console.error(error);
    }
  }

  return {
    callForwardingResponse: response,
    runUpdateCallForwarding,
  };
}
