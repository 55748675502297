import { Maybe, OfferingPriceDetails } from "gql/graphql";
import { isMbbBucketOffer } from "./isMbbBucketOffer";
import { isMbbUnlimitedDataOffer } from "./isMbbUnlimitedDataOffer";
import { findAmount } from "util/format-subscription/findAmount";
import { isMbbSleepModeOffer } from "./isMbbSleepModeOffer";

export function getMbbName(
  offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined,
  shortName: string | undefined | null
) {
  if (isMbbSleepModeOffer(shortName)) {
    return shortName;
  }

  if (isMbbBucketOffer(offeringPrices, shortName)) {
    const dataProp = offeringPrices?.find(findAmount);
    return `${dataProp?.amount} ${dataProp?.monetaryUnit}`;
  }

  if (isMbbUnlimitedDataOffer(offeringPrices, shortName)) {
    return "Ubegrenset data";
  }

  return shortName;
}
