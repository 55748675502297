import React from "react";
import { Heading, ModalBody, ModalHeading, Paragraph } from "@telia-no-min-side/components";
import { ProductLine } from "../style";
import { StyledFooter } from "./style";
import serialNumberImage from "./assets/sn.png";

export function SerialNumberBoxes(): JSX.Element {
  return (
    <div>
      <ProductLine>
        <div>Telia Box:</div>
        <div>Device ID</div>
      </ProductLine>
      <ProductLine>
        <div>TV Boks II:</div>
        <div>NDS Nummer</div>
      </ProductLine>
      <ProductLine>
        <div>TV Boks HD PVR:</div>
        <div>NDS Nummer</div>
      </ProductLine>
      <ProductLine>
        <div>TV Boks Mikro:</div>
        <div>NDS Nummer</div>
      </ProductLine>
      <ProductLine>
        <div>Sagemcom 3686: </div>
        <div>MAC</div>
      </ProductLine>
      <ProductLine>
        <div>Sagemcom 3890:</div>
        <div>Modem</div>
      </ProductLine>
      <ProductLine>
        <div>C1 og F1: </div>
        <div>Serial Number</div>
      </ProductLine>
      <ProductLine>
        <div>Huawei Modem: </div>
        <div>Serial Number</div>
      </ProductLine>
    </div>
  );
}

export function SerialNumberContent(): JSX.Element {
  return (
    <>
      <ModalHeading>Serienummeret</ModalHeading>
      <ModalBody>
        <img
          src={serialNumberImage}
          alt="Hvor du finner serienummeret på Telia-router eller
            TV-boksen"
        />
      </ModalBody>
      <StyledFooter>
        <Paragraph>
          Dette nummeret kan du finne på undersiden av din <strong>Telia-router</strong> eller
          <strong>TV-boks</strong>.
        </Paragraph>
        <Heading variant="title-100" tag="h2">
          Ulike bokser:
        </Heading>
        <SerialNumberBoxes />
      </StyledFooter>
    </>
  );
}
