import { useEffect, useMemo, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Alert, Container } from "@telia-no-min-side/components";
import { OrderAdditionalSimCardStep1 } from "./components/OrderAdditionalSimCardStep1";
import { OrderAdditionalSimCardStep2 } from "./components/OrderAdditionalSimCardStep2";
import { AdditionalSimType, AdditionalProduct, AdditionalProductCategory } from "src/gql/graphql";
import { useQuery } from "@apollo/client";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { GET_ADDITIONAL_SIM_DATA } from "./graphql/getAdditionalSimData";
import { AdditionalSimCardsSkeleton } from "./components/AdditionalSimCardsSkeleton";
import { SimCheckoutContextProvider, useSimCheckout } from "../mobile-subscription-sim-pin-puk/hooks/useSimCheckout";
import { isBuyable, isDataSimProduct, isTwinSimProduct, sortByWeight } from "../mobile-subscription-sim-pin-puk/utils";
import { Button, Icon } from "@telia/teddy";

export const PARAMS = {
  SIM_TYPE: {
    key: "simType",
    twin: "twin",
    data: "data",
  } as const,
  CARD_TYPE: {
    key: "cardType",
    esim: "esim",
    hwSimRemote: "hwSimRemote",
  } as const,
} as const;

export function MobileSubscriptionOrderAdditionalSimCard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { phoneNumber } = useMobileAccount();
  const { data, loading, error } = useQuery(GET_ADDITIONAL_SIM_DATA, {
    variables: { phoneNumber },
  });
  const prevPhoneNumber = useRef(phoneNumber);
  const { addressLoading } = useSimCheckout();

  // Remove simType and cardType from URL when profile is changed
  useEffect(() => {
    const hasProfileChanged = phoneNumber !== prevPhoneNumber.current;
    if (hasProfileChanged) {
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        params.delete(PARAMS.SIM_TYPE.key);
        params.delete(PARAMS.CARD_TYPE.key);
        return params;
      });
    }
    prevPhoneNumber.current = phoneNumber;
  }, [phoneNumber]);

  const simType = searchParams.get(PARAMS.SIM_TYPE.key);
  const cardType = searchParams.get(PARAMS.CARD_TYPE.key);

  const isStep1 = !simType;
  const isTwinSim = simType ? simType === PARAMS.SIM_TYPE.twin : undefined;
  const simCardType = cardType
    ? cardType === PARAMS.CARD_TYPE.esim
      ? AdditionalSimType.EsimQr
      : AdditionalSimType.HwSimRemote
    : undefined;

  const { productToOrder, isProductAvailable } = useMemo(() => {
    if (!data || !simType) return { productToOrder: undefined, isProductAvailable: true };

    const availableProducts = data.subscription.additionalProducts?.filter(
      (product: AdditionalProduct) => product.productCategory === AdditionalProductCategory.MultiSim
    );

    const buyableProducts = availableProducts?.filter(isBuyable);
    const relevantProducts = isTwinSim
      ? buyableProducts?.filter(isTwinSimProduct)
      : buyableProducts?.filter(isDataSimProduct);

    const sortedProducts = [...(relevantProducts || [])].sort(sortByWeight);

    return {
      productToOrder: sortedProducts?.[0],
      isProductAvailable: (sortedProducts?.length || 0) > 0,
    };
  }, [data, simType, isTwinSim]);

  if (error) {
    return (
      <Alert isOpen={true} kind="negative" icon="alert" title="Beklager, noe gikk galt" text="Prøv igjen senere" />
    );
  }

  if (loading || addressLoading) {
    return <AdditionalSimCardsSkeleton />;
  }

  const subscription = data?.subscription;

  return (
    <SimCheckoutContextProvider>
      <Container maxWidth="lg" flexDirection="column" gap>
        <div>
          <Button
            variant="text"
            data-tracking-id="back-button-order-sim-page"
            asChild
            onClick={(e) => {
              if (simType) {
                e.preventDefault();
                setSearchParams((prev) => {
                  const params = new URLSearchParams(prev);
                  params.delete(PARAMS.SIM_TYPE.key);
                  params.delete(PARAMS.CARD_TYPE.key);
                  return params;
                });
              }
            }}
          >
            <Link to="..">
              <Icon name="arrow-left">Tilbake</Icon>
            </Link>
          </Button>
        </div>
        {!isProductAvailable && simType && isTwinSim !== undefined && (
          <Container padding="bottom" maxWidth="xs">
            <Alert
              title="Kan ikke gjennomføre bestilling"
              icon="alert"
              text={`Det ser ut til at du har maksimert antall ${
                isTwinSim ? "TvillingSIM-kort" : "Ekstra dataSIM-kort"
              } som kan bestilles.`}
              kind="negative"
              isOpen={true}
            />
          </Container>
        )}

        {isStep1 ? (
          <OrderAdditionalSimCardStep1 subscription={subscription} setSearchParams={setSearchParams} />
        ) : (
          <OrderAdditionalSimCardStep2
            isTwinSim={isTwinSim}
            productToOrder={productToOrder}
            simCardType={simCardType}
            setSearchParams={setSearchParams}
          />
        )}
      </Container>
    </SimCheckoutContextProvider>
  );
}
