import { BadgeProps, Flex, Grid, Heading, IconName } from "@telia/teddy";
import { NavigationCard } from "components/NavigationCard";
import { useOrderSection } from "pages/mobile/mobile-subscription-dashboard/modules/Sections/hooks";
import { useMobileBroadbandContext } from "pages/internet/mobile-broadband/context/MobileBroadbandContext";

type CardData = {
  condition: boolean;
  title: string;
  description: string;
  icon: IconName;
  onClick: () => void;
  link?: string;
  badge?: { variant: BadgeProps["variant"]; icon: IconName; text: string };
};

export function OrderSection() {
  const { openDrawer, setDrawerContent, isSleepMode, isPrepaid, setTopupCategory } = useMobileBroadbandContext();
  const { hasFreeDataBoosts, freeDataBoostsVoucherCount } = useOrderSection();

  const cardData: CardData[] = [
    {
      condition: true,
      title: "Data Boost",
      description: "Gir deg ubegrenset med data, fra kjøp og til pakken utgår.",
      icon: "getting-started",
      badge: hasFreeDataBoosts
        ? {
            icon: "present",
            variant: "special",
            text: `${freeDataBoostsVoucherCount} stk. kostnadsfri`,
          }
        : undefined,

      onClick: () => {
        openDrawer();
        setDrawerContent("Topup");
        setTopupCategory("Boost");
      },
    },
    {
      condition: true,
      title: "Datapakker",
      description: "Tom for data på mobilt bredbånd? Her finner du ekstra datapakker du kan kjøpe.",
      icon: "present",
      onClick: () => {
        openDrawer();
        setDrawerContent("Topup");
        setTopupCategory("Package");
      },
    },
    {
      condition: true,
      title: "Hvilemodus",
      description: "Sett abonnementet ditt i hvilemodus og spar penger i perioder du bruker lite data.",
      icon: "coffee",
      onClick: () => {
        setDrawerContent("Hvilemodus");
        openDrawer();
      },
      badge: isSleepMode ? { variant: "success", icon: "check-circle", text: "Active" } : undefined,
    },
    {
      condition: true,
      title: "Roam Like Home",
      description: "Få informasjon om databruk for mobilt bredbånd i utlandet.",
      icon: "world",
      onClick: () => {
        setDrawerContent("RLH");
        openDrawer();
      },
    },
  ];

  // if (loading) return <Loading />;
  // if (error) return <Error title="Bestill" refetch={refetch} />;
  // For prepaid users, we don't show the order section
  if (isPrepaid) return null;

  return (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100">
        Bestill
      </Heading>

      <Grid columns={{ xxs: "1", md: "2" }} gap="gutter-xl">
        {cardData.map(
          (card) =>
            card.condition && (
              <NavigationCard
                key={card.title}
                link={card?.link}
                icon={card.icon}
                title={card.title}
                description={card.description}
                badge={card?.badge}
                onClick={card?.onClick}
              />
            )
        )}
      </Grid>
    </Flex>
  );
}
