import { SubscriptionCardContainer } from "./styled";
import { Notabene, Text } from "@telia/teddy";

export function NewLinesCard() {
  return (
    <SubscriptionCardContainer data-tracking-id="mobile-subscription-new-lines-card">
      <Notabene>
        <Notabene.Icon name="bulb" style={{ flexShrink: 0 }} />
        <Notabene.Content>
          <Notabene.Heading>Slik fungerer familierabatt</Notabene.Heading>
          <Text>
            Du trenger kun å betale fullpris for det dyreste abonnementet. Alle andre abonnement vil ha en{" "}
            <strong>fast rabatt (30,-/md. for fast datamengde og 100,-/md. for ubegrenset). </strong>
            Gjelder ikke Telia X Ung.
          </Text>
        </Notabene.Content>
      </Notabene>
    </SubscriptionCardContainer>
  );
}
