import React, { useEffect } from "react";
import { useAuth, FullPageLoading } from "@telia-no-min-side/components";

export function LogOut(): JSX.Element {
  const { requestSignOut } = useAuth();

  useEffect(() => {
    requestSignOut();
  }, [requestSignOut]);
  return <FullPageLoading />;
}
