import { useState } from "react";
import { axiosPost } from "src/services/axios/axios";
import { track, types, uri } from "@telia-no-min-side/utils";
import { Link, useNavigate } from "react-router-dom";
import { getReceiptSearchParams } from "src/pages/receipt/utils";
import { useFetchProductGroup } from "src/api/fixed";
import { MonthList } from "./MonthList";
import { Shipping } from "./ShippingList";
import { createOrder, findAddonFromId, getOptionsByAxiomId } from "./utils";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { PdfHeader } from "components/pdf-order-summary/PdfHeader";
import { PdfFooter } from "components/pdf-order-summary/PdfFooter";
import { DownloadButton } from "components/pdf-order-summary/DownloadButton";
import { openPdf } from "util/pdf-order-summary/openPdf";
import { ShoppingSummary } from "components/shopping-summary";
import { Flex, Button, Text, TextSpacing, Box, Heading } from "@telia/teddy";

type OrderSummaryProps = {
  selectedBoxAxiomId: number | null;
  selectRecordingId: number | null;
};

export function OrderSummary(props: OrderSummaryProps): JSX.Element | null {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const newTvBoxesOptions = useFetchProductGroup("grp_new_tv_box");
  const navigate = useNavigate();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  if (!props.selectedBoxAxiomId || !newTvBoxesOptions.data?.length) return null;

  async function placeOrder(order: types.fixed.SubmitMultipleProducts) {
    pushGenericTrackingEvent({
      ui_item_text: TRACK_EVENT.ITEM_TEXT.CONFIRM_PURCHASE,
      ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.TV_BOX_CONFIRM_PURCHASE,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
    });

    setLoadingSubmit(true);

    try {
      const response = await axiosPost<types.fixed.ResponseSubmitMultipleProducts, types.fixed.SubmitMultipleProducts>(
        uri.purchase(ICX_REQUEST.URL_MULTIPLE_PRODUCTS_SUBMIT),
        order
      );
      if (response.status === "vellykket") {
        return navigate(
          {
            pathname: uri.minSideFixed("/kvittering/post/vellykket"),
            search: getReceiptSearchParams("success", {
              returnTo: uri.minSideFixed("/tv"),
              returnToButtonName: "oversikten",
            }),
          },
          { replace: true }
        );
      }
      navigate(
        {
          pathname: uri.minSideFixed("/kvittering/feil"),
          search: getReceiptSearchParams("error", {
            returnTo: uri.minSideFixed("/tv"),
            returnToButtonName: "oversikten",
            retryUrl: uri.minSideFixed("/tv/tv-bokser/kjop-ny-tv-boks"),
          }),
        },
        {
          replace: true,
        }
      );
    } catch (error) {
      navigate(
        {
          pathname: uri.minSideFixed("/kvittering/feil"),
          search: getReceiptSearchParams("error", {
            returnTo: uri.minSideFixed("/tv"),
            returnToButtonName: "TV-bokser",
            retryUrl: uri.minSideFixed("/tv/tv-bokser/kjop-ny-tv-boks"),
          }),
        },
        { replace: true }
      );
    } finally {
      setLoadingSubmit(false);
    }
  }

  const selectedTvBox = getOptionsByAxiomId(props.selectedBoxAxiomId, newTvBoxesOptions.data);

  if (!selectedTvBox) {
    return (
      <Box id="buy-new-tv-box-order-summary">
        <ShoppingSummary>
          <TextSpacing>
            <Heading as="h3">Feil oppstod!</Heading>
            <Text>Vi klarte ikke hente nødvendig informasjon. Vennligst prøv igjen litt senere.</Text>
          </TextSpacing>
          <ShoppingSummary.Line />
          <Flex data-html2canvas-ignore justify="end" gap="400">
            <Button
              asChild
              variant="text"
              onClick={() =>
                pushGenericTrackingEvent({
                  ui_item_text: TRACK_EVENT.ITEM_TEXT.CANCEL,
                  ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
                  ui_item_context: TRACK_EVENT.ITEM_CONTEXT.TV_BOX_BUY_ERROR,
                  ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
                  ui_item_url: uri.minSideFixed("/tv"),
                })
              }
            >
              <Link to={uri.minSideFixed("/tv")}>Avbryt</Link>
            </Button>
          </Flex>
        </ShoppingSummary>
      </Box>
    );
  }

  const recording = props.selectRecordingId
    ? findAddonFromId(props.selectRecordingId, newTvBoxesOptions.data)
    : undefined;
  const shipping = selectedTvBox?.shippingOptionDTOS.find((option) => option.shippingMethodId === 2);
  const order = createOrder(selectedTvBox, shipping, recording);

  return (
    <Box id="tv-box-order-summary">
      <PdfHeader />
      <ShoppingSummary>
        <MonthList newTvBox={selectedTvBox} recording={recording} />
        <Shipping newTvBox={selectedTvBox} selectedShipping={shipping} />
        <ShoppingSummary.Line />
        <DownloadButton onClick={async () => await openPdf("#tv-box-order-summary")} />

        <Flex data-html2canvas-ignore justify="end" gap="400">
          <Button
            asChild
            variant="text"
            onClick={() =>
              pushGenericTrackingEvent({
                ui_item_text: TRACK_EVENT.ITEM_TEXT.CANCEL,
                ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
                ui_item_context: TRACK_EVENT.ITEM_CONTEXT.TV_BOX_CONFIRM_PURCHASE,
                ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
                ui_item_url: uri.minSideFixed("/tv"),
              })
            }
          >
            <Link to={uri.minSideFixed("/tv")}>Avbryt</Link>
          </Button>
          <Button onClick={() => placeOrder(order)} variant="primary" loading={loadingSubmit}>
            {loadingSubmit ? "Gjennomfører kjøp" : "Bekreft kjøp"}
          </Button>
        </Flex>
      </ShoppingSummary>
      <PdfFooter />
    </Box>
  );
}
