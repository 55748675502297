import { Heading, Flex, Box } from "@telia/teddy";
import { NavigationCard } from "components/NavigationCard";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { getAvailableSmartWifiProducts } from "pages/internet/broadband/utils";
import { useFetchProductGroup, useFetchProductsAdditionalInfo } from "src/api/fixed";
import { useSmartWifiExtenderRestrictions } from "pages/internet/broadband/hooks/useSmartWifiExtenderRestrictions";

export function BuyHardware() {
  const { hasAvailableExtenders, isLoading: restrictionIsLoading } = useSmartWifiExtenderRestrictions();
  const smartWifi = useFetchProductGroup("grp_smart_wifi");
  const productsAdditionalInfo = useFetchProductsAdditionalInfo();
  const { smartWifiRouter, smartWifiExtender } = getAvailableSmartWifiProducts(smartWifi.data);
  const isLoading = smartWifi.isLoading || restrictionIsLoading;
  const hasEquipmentsToOrder = smartWifiRouter || (smartWifiExtender && hasAvailableExtenders);

  if (!isLoading && (!hasEquipmentsToOrder || !productsAdditionalInfo.data?.canOrderInternetEquipment)) return null;

  const cardWidth = { sm: "100%", md: "45%", lg: "33.33%" };

  return (
    <Box mt="600" mb="200">
      <Heading as="h2" variant="title-100">
        Bestill
      </Heading>
      <Flex mt="200" gap="400" wrap="wrap">
        {isLoading ? (
          <NavigationCard isLoading width={cardWidth} />
        ) : smartWifiRouter ? (
          <NavigationCard
            title="Telia Smart Router"
            description="Vår kraftigste router. Smart WiFi sørger for et superraskt og stabilt nett hjemme, som sømløst dekker hele huset."
            link={INTERNAL_LINK.FIXED.BROADBAND_BUY_HARDWARE_PAGE}
            icon="router"
            width={cardWidth}
          />
        ) : smartWifiExtender && hasAvailableExtenders ? (
          <NavigationCard
            title="Telia Ekstender"
            description="Sikrer optimal dekning i alle rom"
            link={INTERNAL_LINK.FIXED.BROADBAND_BUY_HARDWARE_PAGE}
            icon="router"
            width={cardWidth}
          />
        ) : null}
      </Flex>
    </Box>
  );
}
