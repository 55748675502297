import { uri } from "@telia-no-min-side/utils";
import { Alert, Button, Container } from "@telia-no-min-side/components";

export function SuspendSuccess() {
  return (
    <Container flexDirection="column" gap="lg" floatContent="left">
      <Alert
        kind="positive"
        isOpen
        icon="check-circle"
        title="Abonnementet er nå sperret"
        text="Du kan gjenåpne når som helst på Min Side."
        canClose={false}
      />
      <Button variant="primary" tag="a" href={uri.minSideFixed("/mobil")} isInternalNavigation>
        Tilbake til Mobil
      </Button>
    </Container>
  );
}
