import classnames from "classnames";
import React from "react";
import { Button } from "@telia/styleguide";

import "./style.less";

const Container = ({ children }) => <div className="portforwarding-rule-display-container">{children}</div>;

const ButtonContainer = ({ children }) => (
  <div className="portforwarding-rule-display-button-container">{children}</div>
);

const Row = ({ children, index }) => (
  <div key={index} className="portforwarding-rule-display-row">
    {children}
  </div>
);

const TopPartContainer = ({ children }) => (
  <div className="portforwarding-rule-display-top-part-container">{children}</div>
);

const BottomPartContainer = ({ children }) => (
  <div className="portforwarding-rule-display-bottom-part-container">{children}</div>
);

const TitleText = ({ children }) => (
  <label className={classnames("portforwarding-rule-display-title-text", "font-size-16")}>{children}</label>
);

const ValueText = ({ children }) => (
  <label className={classnames("portforwarding-rule-display-value-text", "font-size-16-bold")}>{children}</label>
);

export const PortforwardingRuleDisplay = ({
  name,
  type,
  enabled,
  ipAdress,
  externalPortFrom,
  externalPortTo,
  internalPortFrom,
  internalPortTo,
  onChangeClick,
  onDeleteClick,
}) => (
  <Container>
    <TopPartContainer>
      <Row index={0}>
        <div className={classnames("font-size-32", "margin-bottom")}>{name}</div>
      </Row>
      <Row index={1}>
        <TitleText>Status</TitleText>
        <ValueText>{enabled ? "Aktiv" : "Inaktiv"}</ValueText>
      </Row>
      <Row index={2}>
        <TitleText>IP-adresse</TitleText>
        <ValueText>{Object.values(ipAdress).join(".")}</ValueText>
      </Row>
      <Row index={3}>
        <TitleText>Type</TitleText>
        <ValueText>{type === "Both" ? "TCP + UDP" : type}</ValueText>
      </Row>
      <Row index={4}>
        <TitleText>Ekstern port</TitleText>
        <ValueText>
          {externalPortFrom} {externalPortTo ? ` - ${externalPortTo}` : ""}
        </ValueText>
      </Row>
      <Row index={5}>
        <TitleText>Intern port</TitleText>
        <ValueText>
          {internalPortFrom} {internalPortTo ? ` - ${internalPortTo}` : ""}
        </ValueText>
      </Row>
    </TopPartContainer>
    <BottomPartContainer>
      <ButtonContainer>
        <Button text={"Endre"} onClick={onChangeClick} size={"small"} />
        <Button onClick={onDeleteClick} text={"Slett"} kind={"inverted"} size={"small"} />
      </ButtonContainer>
    </BottomPartContainer>
  </Container>
);
