import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { Container } from "../Container";
import { AccordionProps } from ".";

type AccordionThemeProps = Pick<AccordionProps, "variant">;

export const AccordionTheme = styled(Container)<AccordionThemeProps>`
  ${(props) => {
    if (props.variant === "info") {
      return css`
        --accordion-info-border-radius: 0.5rem;

        > * {
          border-radius: 0;
        }

        > *::after {
          content: "";
          position: absolute;
          opacity: 1;
          background-color: ${colors.grey200};
          box-shadow: none;
          height: 1px;
          inset: auto 0.5rem 0;
        }

        > *:last-of-type::after {
          opacity: 0;
        }

        > *:first-of-type {
          border-top-left-radius: var(--accordion-info-border-radius);
          border-top-right-radius: var(--accordion-info-border-radius);
        }

        > *:last-of-type {
          border-bottom-left-radius: var(--accordion-info-border-radius);
          border-bottom-right-radius: var(--accordion-info-border-radius);
        }
      `;
    }
  }}
`;
