import { Ribbon as TeddyRibbon, Flex, Icon, Text } from "@telia/teddy";

type Props = {
  hasJuniorSummerCampaign: boolean;
  showDoubleDataOrSpeedInfo: boolean;
  hasDoubleSpeed: boolean;
};

export function Ribbon({ hasJuniorSummerCampaign, showDoubleDataOrSpeedInfo, hasDoubleSpeed }: Props) {
  if (hasJuniorSummerCampaign) {
    return (
      <TeddyRibbon mt="200" variant="orange-100">
        <Flex align="center">
          <Icon name="weather" size="sm" />
          <Text>
            <strong>Sommerkampanje ut august!</strong> Halv pris i et halvt år.
          </Text>
        </Flex>
      </TeddyRibbon>
    );
  }

  if (showDoubleDataOrSpeedInfo) {
    return (
      <TeddyRibbon mt="200" variant="green-50">
        <Text>
          <strong>{hasDoubleSpeed ? "Dobbel hastighet" : "Dobbel data"}</strong> er allerede inkludert, siden du har
          TV/Internett fra Telia.
        </Text>
      </TeddyRibbon>
    );
  }

  return null;
}
