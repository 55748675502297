import { isAxiosError } from "axios";
import { minside } from "../context/auth";

export function handleUnauthorizedResponse(error: unknown) {
  if (!isAxiosError(error)) return;

  const loginUri = error.response?.headers["x-login-uri"];
  const loginUriWithReturnPage = `${loginUri}&returnPage=${encodeURIComponent(window.location.toString())}`;

  if ("requestSignIn" in minside && typeof minside.requestSignIn === "function") {
    return minside.requestSignIn();
  }

  if (loginUri) {
    window.location.replace(loginUriWithReturnPage);
  }
}
