import { Accordion, Button, Container } from "@telia-no-min-side/components";
import { Product } from "@telia-no-min-side/utils/src/types/fixed";
import { Alert } from "@telia/styleguide";
import { HeaderWrapper, Heading, InformationText } from "pages/internet/broadband/styles/style";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { useAdditionalServices } from "pages/internet/broadband/hooks/useAdditionalServices";
import { getAccordionTrackingEvent, getBadgeInfo } from "pages/internet/broadband/utils";

type Props = {
  product: Product;
};

export function Nettsikkerhet({ product }: Props) {
  const { activationStatus, activateService } = useAdditionalServices(product);

  return (
    <Accordion.Item
      id="TPN-F-Secure"
      tracking={getAccordionTrackingEvent("Nettsikkerhet")}
      lozengeRight={getBadgeInfo(product)}
      dataTrackingID="additional-services-f-secure"
    >
      <Accordion.Trigger>
        <HeaderWrapper>
          <Heading>Nettsikkerhet</Heading>
        </HeaderWrapper>
      </Accordion.Trigger>
      <Accordion.Content>
        <InformationText>
          Virusbeskyttelse og foreldrekontroll gir deg nettsikkerhet for store og små. Tjenesten leveres av F-Secure.
        </InformationText>
        <InformationText>
          Ved aktivering får du tilsendt en e-post fra F-secure med all informasjon du trenger for å komme i gang med
          tjenesten.
        </InformationText>

        {product.status?.active ? (
          <Container floatContent="right">
            <Button
              variant="text-purple"
              size="sm"
              icon="arrow-right"
              iconPosition="after"
              onClick={(e) => {
                e.preventDefault();
                window.open(EXTERNAL_LINK.SAFE_AVENUE_SSO, "_blank");
              }}
            >
              Gå til Nettsikkerhet
            </Button>
          </Container>
        ) : (
          <Container floatContent="right">
            <Button variant="primary" size="sm" isLoading={activationStatus === "activating"} onClick={activateService}>
              Aktiver
            </Button>
          </Container>
        )}
        {activationStatus === "error" && (
          <Container padding="top">
            <Alert kind="negative">
              <p>En feil oppstod under aktivering, vennligst prøv igjen eller ta kontakt med kundeservice.</p>
            </Alert>
          </Container>
        )}
      </Accordion.Content>
    </Accordion.Item>
  );
}
