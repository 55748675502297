import { format, types, uri } from "@telia-no-min-side/utils";
import { Points } from "@telia-no-min-side/utils/src/types/fixed/productCategory";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchProfiles } from "src/api/fixed/useFetchProfiles";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { useSWRConfig } from "swr";
import { config } from "util/config";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

type Compensation = {
  hasExtraPoints: boolean;
  canSwitchCompensation: boolean;
  points: Points;
};

function getFormattedAddress(profiles: types.fixed.Profiles | undefined, customerId: string): string {
  const address = profiles?.find((p) => p.customerId.toString() === customerId.toString())?.profile?.address;
  const { streetName, streetLetter, streetNumber } = address || {};
  return address ? format.firstLetterUpperCase(`${streetName} ${streetNumber}${streetLetter}`) : "";
}

export function useCompensation() {
  const { customerId } = useParams();
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const compensationResponse = useAxiosSWR<types.fixed.AllUsersRequest<Compensation>>(
    uri.minSideFixed(ICX_REQUEST.URL_COMPENSATION_ALL)
  );

  const profile = useFetchProfiles();
  const switchCompensation = useAxiosPost<boolean>(
    uri.minSideFixed(`${ICX_REQUEST.URL_COMPENSATION_SWITCH}/${customerId}`),
    {
      onSuccess: (response) => {
        if (!response) throw new Error("Ordre mislyktes");
        compensationResponse.mutate();
        mutate(uri.minSideFixed(ICX_REQUEST.URL_TV_SUBSCRIPTION));
        navigate(uri.minSideFixed(`/tv/kompensasjon/${customerId}/kvittering`), { replace: true });
      },
      onError: () => {
        navigate(uri.minSideFixed(`/tv/kompensasjon/${customerId}/feil`));
      },
    }
  );

  const compensationData = compensationResponse.data ?? {};

  const compensationMap = useMemo(
    () => new Map<string, Compensation>(Object.entries(compensationData)),
    [compensationData]
  );
  // return if disabled
  if (!config.showTvCompensation) {
    return {
      isLoading: undefined,
      error: undefined,
      isValidating: undefined,
      currentUserCompensation: undefined,
      mutate: undefined,
      switchCompensation: undefined,
      compensationMap: undefined,
      shouldSeeSwitchCard: undefined,
      shouldOnlySeePointsCard: undefined,
      eligibleCustomerIds: undefined,
    };
  }

  const currentUserCompensation = customerId ? compensationMap.get(customerId) : undefined;

  const shouldSeeSwitchCard = [...compensationMap]
    .filter(([, comp]) => comp.canSwitchCompensation && comp.hasExtraPoints)
    .map(([customerId]) => ({
      customerId,
      address: getFormattedAddress(profile.data, customerId),
    }));

  const shouldOnlySeePointsCard = [...compensationMap]
    .filter(([, comp]) => !comp.canSwitchCompensation && comp.hasExtraPoints)
    .map(([customerId]) => ({
      customerId,
      address: getFormattedAddress(profile.data, customerId),
    }));

  return {
    ...compensationResponse,
    isLoading: profile.isLoading || compensationResponse.isLoading,
    error: profile.error || compensationResponse.error,
    isValidating: profile.isValidating || compensationResponse.isValidating,
    currentUserCompensation,
    switchCompensation,
    compensationMap,
    shouldSeeSwitchCard,
    shouldOnlySeePointsCard,
    eligibleCustomerIds: [...shouldSeeSwitchCard, ...shouldOnlySeePointsCard],
  };
}
