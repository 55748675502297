import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchSelectedAccount() {
  const { error, ...rest } = useAxiosSWR<{ customerId: number }>(ICX_REQUEST.URL_SELECTED_ACCOUNT);

  return {
    isError: !!error,
    error,
    ...rest,
  };
}
