import { Outlet } from "react-router-dom";
import { ACCESS_LEVEL, Container, useAuth } from "@telia-no-min-side/components";
import { useFetchUser } from "src/api/fixed";
import { UserRole } from "src/gql/graphql";
import { config } from "src/util/config";
import { Agreements } from "./agreements";
import { GetAccessDashboard } from "./card/GetAccessDashboard";
import { Finance } from "./finance";
import { Header } from "./header";
import { Highlights } from "./highlights";
import { TvSubscriptions } from "./subscriptions-tv/TvSubscriptions";
import { SubscriptionsMobile } from "./subscriptions-mobile";
import { UsefulShortcuts } from "./useful-shortcuts";
import { removeCountryCodeFromPhoneNumber } from "./utils";
import { AdditionalServices } from "./additional-services";
import { useSubscriptionOverview } from "./hooks/useSubscriptionOverview";
import { AllBroadbandSubscriptions } from "./subscriptions-broadband";
import { NoAccess } from "./no-access";
import { NoAccounts } from "./no-accounts";
import { AUTH } from "util/constants/auth";
import { ServiceNotification } from "components/alerts/ServiceNotification";
import { B2BCard } from "./b2b-card";
import { PageContainer } from "components/PageContainer";

export function Home() {
  const auth = useAuth();
  const user = useFetchUser();
  const subscriptionOverview = useSubscriptionOverview();

  if (
    !subscriptionOverview.hasAnyAccounts &&
    auth?.accessLevel === ACCESS_LEVEL.BRONZE &&
    !subscriptionOverview.loading
  ) {
    return (
      <PageContainer>
        <NoAccess />
      </PageContainer>
    );
  }

  //TODO: verify if it is still required
  if (auth.selectedAuthProvider !== AUTH.PROVIDER.CIAM && auth.selectedAuthProvider !== AUTH.PROVIDER.FAKELOGIN) {
    return (
      <PageContainer>
        <GetAccessDashboard />
      </PageContainer>
    );
  }

  const usersWithFixedProducts = user.data?.usersWithCustomerId || [];
  const selectedUser = usersWithFixedProducts.find((user) => user.selected);
  const phoneNumber = removeCountryCodeFromPhoneNumber(selectedUser?.mobile || "");
  const selectedMobileSubscription = [
    ...subscriptionOverview.mobileSubscriptionsOverview,
    ...subscriptionOverview.mbbSubscriptionsOverview,
  ].find((subscription) => subscription?.phoneNumber.localNumber === phoneNumber);
  const isLegalOwner = selectedMobileSubscription?.roles?.includes(UserRole.LegalOwner) || false;

  return (
    <Container padding flexDirection="column" gap>
      {auth.userInfo?.given_name && (
        <Header
          name={auth.userInfo?.given_name}
          phoneNumber={phoneNumber}
          isLegalOwner={isLegalOwner}
          usersWithFixedProducts={usersWithFixedProducts}
        />
      )}

      {config.isDownTimeExternalService && (
        <ServiceNotification
          type="EXTERNAL_SERVICE"
          title="Planlagt vedlikehold"
          from="torsdag 26. oktober kl. 22:00"
          to="fredag 27. oktober kl. 07:00"
          affectedService="BankID"
        />
      )}
      {config.isDownTimeInternalService && (
        <ServiceNotification
          type="INTERNAL_SERVICE"
          title="Planlagt vedlikehold"
          from="21:00 den 20. april"
          to="11:00 den 21. april"
          affectedService="enkelte tjenester på Min Side"
        />
      )}
      {config.isDownTimeInternalSystem && <ServiceNotification type="INTERNAL_SYSTEM" title="Tekniske problemer" />}
      {config.showInternalServiceWarning && (
        <ServiceNotification
          type="SERVICE_WARNING"
          title="Tekniske problemer"
          message="Vi opplever for tiden problemer relatert til betalingsløsningen vår grunnet problemer hos leverandør. Vi
            beklager ulempene. Hvis det haster så ta kontakt med vårt kundesenter på telefon."
        />
      )}

      <Container gap="lg" flexDirection="column" padding="bottom" data-tracking-id="homepage-profile-subscriptions">
        {config.showB2b2cCard ? <B2BCard /> : null}
        <Highlights />
        <Finance />
        <SubscriptionsMobile />
        <TvSubscriptions />
        <AllBroadbandSubscriptions />
        <Agreements />
        <AdditionalServices />
        <NoAccounts />
        <UsefulShortcuts />
      </Container>

      <Outlet />
    </Container>
  );
}
