import { IconDefinition } from "@telia/styleguide";

type DescriptionItem = {
  text: string;
  icon: IconDefinition;
};

export const descriptionItemList: DescriptionItem[] = [
  {
    text: "AvtaleGiro sørger for å betale fakturaen din til riktig tid. Så slipper du å tenke på det!",
    icon: "time",
  },
  {
    text: "Pass på at du har nok penger på kontoen og at maksbeløpet ikke overstiges.",
    icon: "money",
  },
  {
    text: "AvtaleGiro kan kombineres med alle fakturatyper. Vil du slippe papirgebyr, anbefaler vi eFaktura eller e-postfaktura.",
    icon: "document",
  },
  {
    text: "Husk at det kan ta opptil 45 dager fra du oppretter AvtaleGiro til den er aktiv.",
    icon: "calendar",
  },
];
