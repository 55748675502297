import { createContext, useContext } from "react";
import type { ReactNode } from "react";
import { ApolloError, NetworkStatus, useQuery } from "@apollo/client";
import { GetAccountAgreementsQuery } from "gql/graphql";
import { useMobileAccount } from "hooks/useMobileAccount";
import { GET_ACCOUNT_AGREEMENTS } from "../graphql/getAccountAgreements";
import { getAgreementStatus } from "../utils/getAgreementStatus";
import { SwitchAgreement, getAllUniqueSwitchAgreements } from "../utils/getAllUniqueSwitchAgreements";
import { getFragmentData } from "gql";
import { INSURANCE_AGREEMENT_FRAGMENT } from "../graphql/insuranceAgreementFragment";

type AccountAgreements = {
  loading: boolean;
  data: GetAccountAgreementsQuery | undefined;
  hasNoAgreements: boolean;
  hasSwitchAgreements: boolean;
  switchAgreements: SwitchAgreement[];
  hasLeaseAgreements: boolean;
  leaseAgreements: NonNullable<GetAccountAgreementsQuery["account"]["leaseAgreements"]>["leases"];
  hasInsuranceAgreements: boolean;
  insuranceAgreements: GetAccountAgreementsQuery["account"]["insuranceAgreements"];
  error: ApolloError | undefined;
  refetch: () => void;
  isRefetching: boolean;
  hasBanLevelAgreement: boolean;
  productUserName: string | undefined;
};

const AccountAgreementsContext = createContext<AccountAgreements>({
  loading: false,
  data: undefined,
  hasNoAgreements: false,
  hasSwitchAgreements: false,
  switchAgreements: [],
  hasLeaseAgreements: false,
  leaseAgreements: [],
  hasInsuranceAgreements: false,
  insuranceAgreements: [],
  error: undefined,
  refetch: () => undefined,
  isRefetching: false,
  hasBanLevelAgreement: false,
  productUserName: undefined,
});

type Props = {
  children: ReactNode;
  shouldSkipQuery: boolean;
};

export const AccountAgreementsProvider = ({ children, shouldSkipQuery }: Props) => {
  const { accountId } = useMobileAccount();
  const { loading, data, refetch, error, networkStatus } = useQuery(GET_ACCOUNT_AGREEMENTS, {
    variables: { accountId },
    skip: shouldSkipQuery,
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });

  const { hasNoAgreements, hasSwitchAgreements, hasLeaseAgreements, hasInsuranceAgreements } = getAgreementStatus(
    data?.account
  );

  const switchAgreementsMap = data?.account && getAllUniqueSwitchAgreements(data?.account);
  const switchAgreements =
    switchAgreementsMap && switchAgreementsMap?.size > 0 ? [...switchAgreementsMap.values()] : [];
  const leaseAgreements = data?.account.leaseAgreements?.leases || [];
  const insuranceAgreements = data?.account.insuranceAgreements;

  const banLevelSwitchAgreement = switchAgreements.find((agreement) => agreement.isBanLevelOnly);
  const banLevelInsuranceAgreement = insuranceAgreements?.find((insuranceAgreement) => {
    const agreement = getFragmentData(INSURANCE_AGREEMENT_FRAGMENT, insuranceAgreement);
    return agreement.productLevel === "ACCOUNT";
  });

  const hasBanLevelAgreement = !!banLevelSwitchAgreement || !!banLevelInsuranceAgreement;
  const productUserName = data?.account.legalOwner?.individual?.firstName;

  return (
    <AccountAgreementsContext.Provider
      value={{
        loading,
        data,
        hasNoAgreements,
        hasSwitchAgreements,
        switchAgreements,
        hasLeaseAgreements,
        leaseAgreements,
        hasInsuranceAgreements,
        insuranceAgreements,
        error,
        refetch,
        isRefetching: networkStatus === NetworkStatus.refetch,
        hasBanLevelAgreement,
        productUserName,
      }}
    >
      {children}
    </AccountAgreementsContext.Provider>
  );
};

export function useAccountAgreements(): AccountAgreements {
  const context = useContext(AccountAgreementsContext);

  if (!context) {
    throw Error("useAccountAgreements must be used within a AccountAgreementsContext.Provider");
  }
  return context;
}
