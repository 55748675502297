import { Card, Heading, Text } from "@telia/teddy";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";

export function RoamingLikeHomeBadge() {
  const { isTeliaXSubscription } = useMobileDashboardContext();

  return (
    <Card variant={isTeliaXSubscription ? "beige" : "purple-light"} gap="50">
      <Heading as="h3">Her kan du Roam Like Home!</Heading>
      <Text>
        {isTeliaXSubscription
          ? "Inntil 50 GB databruk er inkludert for Telia X Normal og Rask, mens Telia X Max inkluderer 65 GB. Bruker du mer enn det inkluderte, koster det 2,5 øre per MB."
          : "Det betyr at du kan bruke mobilen akkurat som hjemme i Norge. Surf, snap, post, ring og tekst i vei!"}
      </Text>
    </Card>
  );
}
