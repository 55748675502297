import { ScrollToTop } from "@telia-no-min-side/components";
import { FlexOrderProvider } from "./context/FlexOrderProvider";
import { PageHandler } from "./components/PageHandler";

export function FlexPage() {
  return (
    <FlexOrderProvider>
      <ScrollToTop />
      <PageHandler />
    </FlexOrderProvider>
  );
}
