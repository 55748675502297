import { Paragraph } from "@telia-no-min-side/components";
import { CustomerCareLink } from "src/components/customer-care-link";
import { useFetchCancelAgreement } from "./fetcher";

export function CanNotCancelReason({ agreementDetailId }: { agreementDetailId: string }) {
  const cancelAgreement = useFetchCancelAgreement({ agreementDetailId });
  if (cancelAgreement.data?.isInBinding) {
    return (
      <Paragraph>
        Produktet er i bindingstid, og du kan dessverre ikke avbestille over Internett. Ta kontakt med kundeservice for
        å avbestille
      </Paragraph>
    );
  }
  if (cancelAgreement.data?.alreadyCancelled) {
    return (
      <>
        <Paragraph>Det er allerede registrert en avbestilling for dette produktet.</Paragraph>
        {cancelAgreement.data.scheduledCancellationDate.length > 0 && (
          <Paragraph>Avbestillingen vil tre i kraft {cancelAgreement.data.scheduledCancellationDate}.</Paragraph>
        )}
      </>
    );
  }
  return (
    <>
      <Paragraph>
        Du kan dessverre ikke avbestille denne tjenesten på Min Side. Ta kontakt med kundeservice for å avbestille.
      </Paragraph>
      <CustomerCareLink trackingContext="cancel-agreement" />
    </>
  );
}
