import React, { forwardRef } from "react";
import { LinkProps as ReactRouterLinkProps, Link as ReactRouterLink } from "react-router-dom";
import { IconDefinition } from "@telia/styleguide";
import { LocationState, useReactRouterHistory } from "../hooks/useReactRouterHistory";
import { LinkIcon, LinkText, StyledLink } from "./style";

export type LinkProps = {
  isInternalNavigation?: boolean;
  makeReturnUrl?: boolean;
  href: string;
  icon?: IconDefinition;
  iconPosition?: "before" | "after";
  variant?: "text" | "standalone";
  reactRouterState?: Record<string, unknown>;
  /**
   * Only true if Link are on a dark background
   */
  negative?: boolean;
  dataTrackingId?: string;
} & Omit<ReactRouterLinkProps, "to" | "href"> &
  LocationState;

/**
 * @deprecated Use Teddy components instead
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const {
    href,
    isInternalNavigation = false,
    children,
    makeReturnUrl,
    variant,
    negative,
    icon,
    reactRouterState = {},
    iconPosition = "before",
    dataTrackingId,
    ...rest
  } = props;
  const { location } = useReactRouterHistory();

  if (isInternalNavigation) {
    const locationState = {
      ...location.state,
      ...reactRouterState,
      ...{ from: location },
    };
    const newRouterState = makeReturnUrl ? { ...locationState, returnUrl: location.pathname } : locationState;
    const url = new URL(href, window.location.origin);

    return (
      <StyledLink
        ref={ref}
        variant={variant}
        as={ReactRouterLink}
        {...rest}
        to={{
          pathname: url.pathname,
          hash: url.hash,
          search: url.search,
        }}
        state={newRouterState}
        negative={negative}
      >
        <Children icon={icon} iconPosition={iconPosition} negative={negative} dataTrackingId={dataTrackingId}>
          {children}
        </Children>
      </StyledLink>
    );
  }

  return (
    <StyledLink ref={ref} variant={variant} negative={negative} href={href} {...rest}>
      <Children icon={icon} iconPosition={iconPosition} negative={negative} dataTrackingId={dataTrackingId}>
        {children}
      </Children>
    </StyledLink>
  );
});

Link.displayName = "Link";

function Children({
  icon,
  iconPosition = "before",
  children,
  negative,
  dataTrackingId,
}: Pick<LinkProps, "icon" | "iconPosition" | "children" | "negative" | "dataTrackingId">) {
  if (!icon) return <LinkText>{children}</LinkText>;
  return (
    <>
      {iconPosition === "before" && <LinkIcon size="sm" icon={icon} negative={negative} />}
      <LinkText data-tracking-id={dataTrackingId}>{children}</LinkText>
      {iconPosition === "after" && <LinkIcon size="sm" icon={icon} negative={negative} />}
    </>
  );
}
