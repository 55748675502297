import { quantityToBytes } from "util/data-usage";
import { getDataUsageTitle, getColorByType, formatBytesToGB } from ".";
import { UsageDataType } from "./mergeDataUsageByType";

export function getDataUsageDetails(groupedData: UsageDataType[], totalUsageLimit: number) {
  return groupedData.map((quota) => ({
    color: getColorByType(quota?.type),
    title: getDataUsageTitle(quota?.type),
    limit: quantityToBytes(quota?.limit),
    remaining: quantityToBytes(quota?.remaining),
    limitFormatted: formatBytesToGB(quantityToBytes(quota?.limit)!),
    remainingFormatted: formatBytesToGB(quantityToBytes(quota?.remaining)!),
    percentage: Math.round((quantityToBytes(quota?.remaining)! / totalUsageLimit) * 100),
  }));
}
