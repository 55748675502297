import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "@telia/styleguide";
import { Container } from "../Container";
import { Paragraph } from "../Paragraph";
import dayjs from "dayjs";

const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background-color: #e6e6e6;
`;
const BorderContainer = styled.div`
  padding: 50px 40px;
  border: 1px solid #000000;
  min-height: 300px;
  border: 2px solid rgb(0 0 0 / 3%);
  border-radius: 8px;
`;
const InfoContainer = styled.div`
  margin-top: 60px;
`;
const FilledBar = styled.div<{ progress: number }>`
  height: 100%;
  background-color: ${colors.corePurple850};
  transition: width 0.3s ease;
  width: 0; /* Start with a width of 0 */
  animation: fillBar 1s forwards; /* Animation to fill the bar */
  animation-delay: 0.3s;

  @keyframes fillBar {
    to {
      width: ${(props) => props.progress}%;
    }
  }
`;
// TODO: import colors
const StepButton = styled.button<{ completed: boolean }>`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: ${(props) => (props.completed ? colors.corePurple850 : "#E6E6E6")};
  color: ${(props) => (props.completed ? "#fff" : "#00000")};

  border-radius: 50%;
  border: none;
  cursor: pointer;
  outline: none;
  transition: transform 0.3s ease;

  &:focus {
    outline: 2px solid #0099ff;
  }

  &:hover {
    transform: translateX(-50%) scale(1.1); // Slight scale on hover
  }
`;

const IndicatorLine = styled.div<{ position: number; completed: boolean }>`
  position: absolute;
  top: 40px;
  left: -10px;
  width: calc(100% + 20px);
  border-top: ${(props) => (props.completed ? "2px solid " : "2px solid #E6E6E6")};

  ::before,
  ::after {
    content: "";
    position: absolute;
    transform: translateY(-100%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transition: left 0.3s ease;
  }

  ::before {
    left: ${(props) => {
      if (props.position === 0) return `calc(${props.position}% + 5px)`;
      if (props.position === 100) return `calc(${props.position}% - 15px)`;
      return `calc(${props.position}% - 5px)`;
    }};
    top: -2px;
    border-bottom: ${(props) => (props.completed ? `12px solid ${colors.corePurple850}` : "12px solid #E6E6E6")};
  }

  ::after {
    left: ${(props) => {
      if (props.position === 0) return `calc(${props.position}% + 5px)`;
      if (props.position === 100) return `calc(${props.position}% - 15px)`;
      return `calc(${props.position}% - 5px)`;
    }};
    top: 2px;
    border-bottom: 12px solid white;
  }
`;

type ProgressBarProps = {
  effectiveDate: string;
  expirationDate: string;
  costToReplace: string;
  canSwitch: boolean;
  tackStepClick?: (step: number, initialStep: number, heading: string) => void;
};

function getStepText(currentStep: number, costToReplace: string, endDate: string, canSwitch: boolean) {
  const stepTexts = [
    {
      timePeriod: "0 - 12 MÅNEDER",
      heading: "Du nedbetaler på en SVITSJ-avtale",
      ingress: `Dersom du betaler det du skal hver måned er din estimerte SVITSJ-dato ${dayjs(endDate).format(
        "DD.MM.YYYY"
      )}. ${
        canSwitch
          ? `Du kan svitsje nå, men da må du betale det gjenstående beløpet på SVITSJ-avtalen: ${costToReplace}.`
          : ""
      }`,
    },
    {
      timePeriod: "12 - 24 MÅNEDER",
      heading: "Du har mulighet til å svitsje",
      ingress: "Du kan svitsje uten å betale noe ekstra dersom du ønsker.",
    },
    {
      timePeriod: "24+ MÅNEDER",
      heading: "Mobilen er din",
      ingress:
        "Du eier mobiltelefonen. Dersom du ønsker å svitsje kan du starte en ny SVITSJ-avtale. Du trenger ikke levere den gamle mobilen tilbake.",
    },
  ];
  return stepTexts[currentStep];
}

function getElapsedPercentage(effectiveDateStr: string, expirationDateStr: string): number {
  const effectiveDate = new Date(effectiveDateStr);
  const expirationDate = new Date(expirationDateStr);

  const today = new Date();

  const totalDuration = expirationDate.getTime() - effectiveDate.getTime();
  const currentDuration = today.getTime() - effectiveDate.getTime();

  if (totalDuration <= 0) {
    return 0; //expiration date is same as or before effective date
  }

  let percentage = (currentDuration / totalDuration) * 100;

  percentage = Math.max(0, Math.min(percentage, 100));

  return percentage;
}

function getInitialStep(progress: number): number {
  if (progress < 50) {
    return 1;
  } else if (progress < 100) {
    return 2;
  } else {
    return 3;
  }
}

function StepText({
  currentStep,
  completed,
  costToReplace,
  expirationDate,
  canSwitch,
}: {
  currentStep: number;
  completed: boolean;
  canSwitch: boolean;
  costToReplace: string;
  expirationDate: string;
}) {
  const currentText = getStepText(currentStep - 1, costToReplace, expirationDate, canSwitch);
  return (
    <Container>
      <Paragraph isFaded removeMargin>
        {currentText.timePeriod}
      </Paragraph>
      <Paragraph removeMargin isFaded={!completed}>
        <em>{currentText.heading}</em>
      </Paragraph>
      <Paragraph isFaded={!completed}>{currentText.ingress}</Paragraph>
    </Container>
  );
}

export const SwitchProgressBar: React.FC<ProgressBarProps> = ({
  effectiveDate,
  expirationDate,
  costToReplace,
  canSwitch,
  tackStepClick,
}) => {
  const stepThresholds = [0, 50, 100];

  const progress = getElapsedPercentage(effectiveDate, expirationDate);

  const [viewStep, setViewStep] = useState(getInitialStep(progress));

  const handleStepClick = (stepNumber: number) => {
    if (tackStepClick) {
      const currentText = getStepText(stepNumber - 1, costToReplace, expirationDate, canSwitch);
      const initialStep = getInitialStep(progress);
      const viewStep = (stepNumber - 1) * 12;
      tackStepClick(viewStep, initialStep, currentText.heading);
    }
    setViewStep(stepNumber);
  };

  return (
    <BorderContainer>
      <ProgressBarContainer>
        <FilledBar progress={progress} />
        {stepThresholds.map((threshold, index) => (
          <StepButton
            key={`step-${index + 1}`}
            aria-label={`Steg ${index + 1}`}
            completed={progress >= threshold}
            onClick={() => handleStepClick(index + 1)}
            style={{ left: `${threshold}%` }}
          >
            {index * 12}
          </StepButton>
        ))}
        <IndicatorLine position={stepThresholds[viewStep - 1]} completed={progress >= stepThresholds[viewStep - 1]} />
      </ProgressBarContainer>
      <InfoContainer>
        <StepText
          currentStep={viewStep}
          completed={progress >= stepThresholds[viewStep - 1]}
          canSwitch={canSwitch}
          costToReplace={costToReplace}
          expirationDate={expirationDate}
        />
      </InfoContainer>
    </BorderContainer>
  );
};
