import { axiosGet } from "@telia-no-min-side/components";
import { AxiosRequestConfig } from "axios";

type DownloadFileArgs = {
  url: string;
  filename: string;
  config?: AxiosRequestConfig;
};

export async function downloadFile({ url, filename, config = {} }: DownloadFileArgs): Promise<void> {
  const response = await axiosGet(url, {
    responseType: "blob",
    ...config,
  });
  if (response.data instanceof Blob) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
}

export function downloadBase64File(base64String: string, fileName: string) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/zip" });
  const blobUrl = window.URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.href = blobUrl;
  downloadLink.setAttribute("download", fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(blobUrl);
}
