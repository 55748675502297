export type SumPrice = {
  amount: number;
  chargePeriod: string | undefined;
};

export function sumPrices(price1: SumPrice, price2: SumPrice): SumPrice {
  if (!price1.chargePeriod || !price2.chargePeriod) return price1;
  if (price1.chargePeriod !== price2.chargePeriod) {
    const chargePeriodMultiplier = getChargePeriodMultiplier(price1.chargePeriod, price2.chargePeriod);
    return {
      amount: price1.amount + price2.amount * chargePeriodMultiplier,
      chargePeriod: price1.chargePeriod,
    };
  }
  return {
    amount: price1.amount + price2.amount,
    chargePeriod: price1.chargePeriod,
  };
}

export function getChargePeriodMultiplier(chargePeriod1: string, chargePeriod2: string) {
  if (chargePeriod1 === "md." && chargePeriod2 === "kvartal") return 1 / 3;
  if (chargePeriod1 === "md." && chargePeriod2 === "halvår") return 1 / 6;
  if (chargePeriod1 === "md." && chargePeriod2 === "år") return 1 / 12;
  if (chargePeriod1 === "kvartal" && chargePeriod2 === "md.") return 3;
  if (chargePeriod1 === "kvartal" && chargePeriod2 === "halvår") return 1 / 2;
  if (chargePeriod1 === "kvartal" && chargePeriod2 === "år") return 1 / 4;
  if (chargePeriod1 === "halvår" && chargePeriod2 === "md.") return 6;
  if (chargePeriod1 === "halvår" && chargePeriod2 === "kvartal") return 2;
  if (chargePeriod1 === "halvår" && chargePeriod2 === "år") return 1 / 2;
  if (chargePeriod1 === "år" && chargePeriod2 === "md.") return 12;
  if (chargePeriod1 === "år" && chargePeriod2 === "kvartal") return 4;
  if (chargePeriod1 === "år" && chargePeriod2 === "halvår") return 2;
  return 1;
}

export function getChargePeriodText(chargePeriod: string, capitalized: boolean) {
  let text = "måned";

  if (chargePeriod === "kv." || chargePeriod === "kvartal") {
    text = "kvartal";
  }
  if (chargePeriod === "halvår" || chargePeriod === "år") {
    text = chargePeriod;
  }

  if (capitalized) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return text;
}
