import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { useFetchAndLocalStore } from "./useFetchAndLocalStore";

export const urlBroadbandSubscription = "/minside/api/products/category/broadband_subscription";

type Options = {
  enableSkeletonNumberStore?: boolean;
};

export function useFetchBroadbandSubscription(options?: Options): ReturnType<typeof useFetchAndLocalStore> {
  const enableSkeletonNumberStore = options?.enableSkeletonNumberStore ?? false;
  return useFetchAndLocalStore(ICX_REQUEST.URL_BROADBAND_SUBSCRIPTION, {
    storeKey: "number-of-broadband-subscription",
    enableSkeletonNumberStore,
  });
}
