import { Container, TopSection } from "@telia-no-min-side/components";
import { FwaProfilePicker } from "components/profile-pickers/FwaProfilePicker";
import { FwaCommitment } from "./FwaCommitment";
import { useFetchUser } from "src/api/fixed";
import { useFetchProfiles } from "src/api/fixed/useFetchProfiles";
import { types } from "@telia-no-min-side/utils";

function getFwaCustomerIds(profiles: types.fixed.Profiles) {
  return (profiles || [])
    .filter((profile) => (profile.profile.address?.usedPlatform || "").startsWith("FWA"))
    .map((profile) => profile.customerId.toString());
}

export function FwaCommitmentPage() {
  const userData = useFetchUser();
  const profilesWithPlatforms = useFetchProfiles();
  const fwaCustomerIds = getFwaCustomerIds(profilesWithPlatforms.data || []);

  const hasMultipleUsers = (userData.data?.usersWithCustomerId || []).length > 0;

  return (
    <Container flexDirection="column" gap>
      {hasMultipleUsers ? (
        <TopSection data-html2canvas-ignore>
          <FwaProfilePicker
            dataTrackingID="moveout-fixed-profile-picker"
            pickerVariant="primary"
            fwaCustomerIds={fwaCustomerIds}
          />
        </TopSection>
      ) : null}
      <FwaCommitment />
    </Container>
  );
}
