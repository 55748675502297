import { Heading, Text, Link, Box } from "@telia/teddy";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export const WifiTips = () => {
  return (
    <Box mt="200">
      <Heading as="h3" variant="title-300">
        WiFi-tips for et bedre nett
      </Heading>
      <Heading as="h4" variant="title-200" mt="100">
        1. Husk plassering
      </Heading>
      <Text mt="100">
        Plasser utstyret høyt og fritt, unngå skuffer og skap. Vegger og gulv, spesielt betong og mur, blokkerer typisk
        signalet.
      </Text>
      <Heading as="h4" variant="title-200" mt="300">
        2. Bytt frekvens
      </Heading>
      <Text mt="100">
        Trådløse nett idag benytter to ulike frekvenser: 2,4 GHz er det eldste og tregeste, 5 GHz er nyere standard og i
        praksis det raskeste nettet. Vi anbefaler derfor at du bytter til 5 GHz-nettet hvis utstyret ditt tillater det.
        Sliter du med rekkevidden kan du vurdere et mesh-nettverk.
      </Text>
      <Heading as="h4" variant="title-200" mt="300">
        3. Bruk mesh-nett
      </Heading>
      <Text mt="100">
        Opplever du at det trådløse nettet er svakt i deler av hjemmet kan et mesh-nettverk forbedre nettet ditt
        drastisk. Vår kraftigste og nyeste router Telia Smart Wifi har integrert mesh - supert for større boliger.
        Trenger du litt ekstra rekkevidde vil en ekstender utvide signalet ytterligere.
        <Link variant="standalone" target={"_blank"} href={EXTERNAL_LINK.INTERNET_WIFI}>
          Les mer om vår Wifix-løsning
        </Link>
      </Text>
      <Heading as="h4" variant="title-200" mt="300">
        4. Bestill en trådløs ekspert
      </Heading>
      <Text mt="100">
        Vil du ha hjelp med oppkobling eller konfigurering av det trådløse nettet kan våre trådløse eksperter hjelpe
        deg. Har du problemer med nettet hjemme kan de også fjerne støykilder, gi gode råd og eventuelt koble til
        forsterkere for å utvide nettet.
      </Text>
      <Text mt="100" mb="200">
        Et besøk koster 990,- og varer i ca halvannen time.
      </Text>

      <Link target={"_blank"} href={EXTERNAL_LINK.CUSTOMER_SERVICE_CONTACT_US} variant="standalone">
        Kontakt oss for å bestille en trådløs ekspert
      </Link>
    </Box>
  );
};
