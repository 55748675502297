import { type ReactNode } from "react";
import { Container } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import { Heading, Text } from "@telia/teddy";
import teliaLogo from "./telia-logo.png";

type Props = {
  children?: ReactNode;
};

export function PdfHeader({ children }: Props) {
  const date = format.formatDate(Date.now(), "(DD.MM.YYYY)");

  return (
    <>
      <Container flexDirection="column" gap="lg" className="show-on-print" padding="bottom" display="flex">
        <img src={teliaLogo} alt="logo" height="32" width="81" />

        <Heading as="h2" variant="title-200">
          Hei!
        </Heading>

        <Text>
          Takk for at du vurderer å handle hos Telia. Nedenfor har vi lagret ditt ordresammendrag. Merk at tilbudet kun
          gjelder ut dagen {date}.
        </Text>
      </Container>
      {children}
    </>
  );
}
