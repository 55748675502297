import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { deleteCookie, getCookieValue, setCookie, uri } from "@telia-no-min-side/utils";
import dayjs from "dayjs";
import { KeycloakTokenParsed } from "keycloak-js";
import { FullPageLoading } from "../FullPageLoading";
import { createAppSsoAdapter } from "./appSsoAdapter";
import { createCTAdapter } from "./ctAdapter";
import { createKeycloakAdapter } from "./keycloakAdapter";
import { isStageDomain } from "../utils/locationHelper";

export type LoginProvider = "APP_SSO" | "CIAM" | "FAKELOGIN" | "CT";
export enum ACCESS_LEVEL {
  BRONZE = "bronze",
  SILVER = "silver",
}

export type UserInfo = {
  family_name?: string;
  given_name?: string;
  name: string;
  no_person_id?: string;
  phone_number?: string;
  email?: string;
  email_verified?: boolean;
  birthdate?: string;
  sub?: string;
  "urn:teliacompany:dis:ciam:1.0:ial"?: ACCESS_LEVEL;
  "urn:teliacompany:dis:ciam:1.0:party_id"?: string;
  "urn:teliacompany:dis:ciam:mobile:no:1.0:temp_access"?: string[];
  "urn:teliacompany:dis:ciam:icx:no:1.0:customer_id"?: {
    userAssets?: [
      {
        id?: string;
        entityType?: string;
        role?: string;
        businessUnitID?: string;
      },
    ];
  };
};

type AuthContextType = {
  accessToken: string | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  requestSignIn?: () => void;
  requestBankId?: (params?: string, redirectUri?: string) => void;
  requestBankIdWithMaxAge?: (maxAge: number, options?: { params?: string; redirectUri?: string }) => void;
  requestSignOut: () => void;
  setAuthAdapter: (provider: LoginProvider) => void;
  setFakeLoginAsCustomerId: (customerId: string) => void;
  selectedAuthProvider: LoginProvider | undefined;
  userInfo: UserInfo | undefined;
  tokenParsed: KeycloakTokenParsed | undefined;
  accessLevel?: ACCESS_LEVEL;
  hasLimitedAccess: boolean;
};

export type AuthenticationAdapter = {
  requestSignIn?: () => void;
  requestSignOut(): void;
  requestBankId?: (params?: string, redirectUri?: string) => void;
  requestBankIdWithMaxAge?: (maxAge: number, options?: { params?: string; redirectUri?: string }) => void;
};

/**
 * The options passed to the AuthenticationAdapter itself.
 */
export type AuthenticationAdapterOptions = {
  /**
   * Callback used when the token changes.
   * @param token The new token, or undefined if there currently is no valid authentication context.
   */
  onTokenChange: (token: string | undefined, tokenParsed: KeycloakTokenParsed | undefined) => void;
  setUserInfo: Dispatch<SetStateAction<UserInfo | undefined>>;
} & Pick<Props, "customRedirectUri" | "forceProd">;

export const initAuthValue: AuthContextType = {
  isAuthenticated: false,
  isLoading: true,
  accessToken: undefined,
  requestSignIn: undefined,
  requestBankId: () => {},
  requestSignOut: () => {},
  setAuthAdapter: () => {},
  setFakeLoginAsCustomerId: () => {},
  selectedAuthProvider: undefined,
  userInfo: undefined,
  tokenParsed: undefined,
  accessLevel: undefined,
  hasLimitedAccess: true,
};

const AuthContext = createContext<AuthContextType>(initAuthValue);

export function useAuth(): AuthContextType {
  const context = useContext<AuthContextType>(AuthContext);

  if (!context) {
    throw Error("No auth context found! This usually happens when you try to access a context outside of a provider");
  }
  return context;
}
type Props = {
  children: ReactNode;
  fallbackAuthProvider?: Omit<LoginProvider, "FAKELOGIN" | "FAKELOGIN_MOBILE">;
  customRedirectUri?: string;
  minsideApp: "mobile" | "fixed";
  forceProd?: boolean;
  enableFakeToken?: boolean;
  isTempAccessEnabled?: boolean; // only used for temp-access testing period
};

export const minside: Record<string, unknown> = { userToken: "" };
type GetMinSideUserTokenArg = {
  enableFakeToken: boolean;
};
export function getMinSideUserToken(args?: GetMinSideUserTokenArg) {
  return typeof minside.userToken === "string" && minside.userToken.length > 0
    ? minside.userToken
    : args?.enableFakeToken
    ? "fake"
    : undefined;
}

export function AuthProvider(props: Props) {
  const { children, customRedirectUri, minsideApp, forceProd, enableFakeToken, isTempAccessEnabled = false } = props;
  const [isLoading, setIsLoading] = useState(initAuthValue.isLoading);
  const [tokenParsed, setTokenParsed] = useState<KeycloakTokenParsed>();
  const [isAuthenticated, setIsAuthenticated] = useState(initAuthValue.isAuthenticated);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [selectedAuthProvider, setSelectedAuthProvider] = useState<LoginProvider>();
  const isFixedLocalhost = window.location.host === "localhost.telia.no:9002";
  const [accessToken, setAccessToken] = useState(initAuthValue.accessToken);
  const loginProviderRef = useRef<AuthenticationAdapter | null>(null);

  const accessLevel = tokenParsed ? tokenParsed["urn:teliacompany:dis:ciam:1.0:ial"] : undefined;

  const hasTempAccess = tokenParsed
    ? tokenParsed["urn:teliacompany:dis:ciam:mobile:no:1.0:temp_access"]?.length > 0
    : undefined;

  const hasLimitedAccess =
    selectedAuthProvider === "CIAM" && accessLevel !== ACCESS_LEVEL.SILVER && !hasTempAccess && isTempAccessEnabled;

  function onTokenChange(token: string | undefined, tokenParsed: KeycloakTokenParsed | undefined) {
    setAccessToken(token);
    setTokenParsed(tokenParsed);

    minside.userToken = token ?? "";
    if (token) {
      setIsAuthenticated(true);
    }

    if (isLoading) {
      setIsLoading(false);
    }
  }

  function requestSignOut() {
    setIsLoading(true);
    deleteCookie("minside-fixed-legacy-user");
    deleteCookie("CTSESSION", {
      path: "/",
      domain: ".telia.no",
    });
    deleteCookie("minside-fixed-common-login-user");
    deleteCookie("minside-mobile-common-login-user");
    deleteCookie("fixed-selected-account");
    deleteCookie("userdata_phone_nr");
    deleteCookie("userdata_account_id");

    loginProviderRef.current?.requestSignOut();
  }

  function setFakeLoginAsCustomerId(customerId: string) {
    setCookie("fakeloginas", customerId, {
      path: "/",
      expires: dayjs().add(1, "months"),
    });
  }

  function setAuthAdapter(loginProvider: LoginProvider, enableFakeToken?: boolean) {
    if (enableFakeToken) {
      setIsAuthenticated(true);
      setIsLoading(false);
      return;
    }

    if (loginProvider === "FAKELOGIN") {
      const currentFakeloginasCustomerId = getCookieValue("fakeloginas");
      const currentSelectedCustomerId = getCookieValue("fixed-selected-account");
      const getbekkGetId = "f498776b-d863-4a0a-89e2-ee471024534f";

      if (!currentFakeloginasCustomerId) {
        setFakeLoginAsCustomerId(currentSelectedCustomerId || getbekkGetId);
      }
      deleteCookie("minside-fixed-legacy-user");
      deleteCookie("minside-fixed-common-login-user");
      loginProviderRef.current = createAppSsoAdapter();
      setSelectedAuthProvider("FAKELOGIN");
      setIsAuthenticated(true);
      setAccessToken(undefined);
      setIsLoading(false);
    }
    if (loginProvider === "APP_SSO") {
      deleteCookie("minside-fixed-common-login-user");
      loginProviderRef.current = createAppSsoAdapter();
      setSelectedAuthProvider("APP_SSO");
      setAccessToken(undefined);
      setIsAuthenticated(true);
      setIsLoading(false);
    }
    if (loginProvider === "CT") {
      loginProviderRef.current = createCTAdapter({
        forceProd,
      });
      setSelectedAuthProvider("CT");
      setAccessToken(undefined);
      setIsAuthenticated(true);
      setIsLoading(false);
    }

    if (loginProvider === "CIAM") {
      setIsLoading(true);
      deleteCookie("minside-fixed-legacy-user");
      deleteCookie("fakeloginas");
      deleteCookie("CTSESSION", {
        path: "/",
        domain: ".telia.no",
      });
      setCookie(`minside-fixed-common-login-user`, "true", {
        path: "/",
      });
      loginProviderRef.current = createKeycloakAdapter({
        onTokenChange,
        setUserInfo,
        customRedirectUri,
        forceProd,
      });
      minside.requestSignIn = loginProviderRef.current.requestSignIn;
      setSelectedAuthProvider("CIAM");
    }
  }

  useEffect(() => {
    if (loginProviderRef.current) return;
    const searchParams = new URLSearchParams(window.location.search);
    const errorMessage = searchParams.get("error");
    const access_denied = searchParams.get("error_description");
    const customerWantToUseLegacyLogin = errorMessage === "access_denied" && access_denied === "user_cancelled";

    if (customerWantToUseLegacyLogin) {
      return window.location.replace(
        uri.openPages(
          `/minside/login/login?service=https%3A%2F%2F${
            isStageDomain() ? "stage." : "www."
          }telia.no%2Fminside%2Fportalbackend%2Fj_spring_cas_security_check&theme=cas-theme-telia`
        )
      );
    }

    const isAppSsoLogin = getCookieValue("minside-fixed-legacy-user") === "true";
    const isCTLogin = !!getCookieValue("CTSESSION");
    const isFixedCommonLogin = getCookieValue(`minside-fixed-common-login-user`) === "true";

    if (enableFakeToken) {
      return setAuthAdapter("CT", enableFakeToken);
    }
    if (isFixedLocalhost && !isFixedCommonLogin) {
      return setAuthAdapter("FAKELOGIN");
    }

    if (isAppSsoLogin && minsideApp === "fixed") {
      return setAuthAdapter("APP_SSO");
    }

    if (isCTLogin) {
      return setAuthAdapter("CT");
    }

    setAuthAdapter("CIAM");
  }, [loginProviderRef, enableFakeToken]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        isAuthenticated,
        isLoading,
        requestSignIn: loginProviderRef.current?.requestSignIn,
        requestSignOut,
        selectedAuthProvider,
        userInfo,
        setAuthAdapter,
        requestBankId: loginProviderRef.current?.requestBankId,
        requestBankIdWithMaxAge: loginProviderRef.current?.requestBankIdWithMaxAge,
        tokenParsed,
        setFakeLoginAsCustomerId,
        accessLevel,
        hasLimitedAccess,
      }}
    >
      {!isAuthenticated || isLoading ? <FullPageLoading /> : children}
    </AuthContext.Provider>
  );
}
