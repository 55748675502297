import { useState } from "react";
import { useLocalStorage, useToast } from "@telia-no-min-side/components";
import { AdditionalProduct, OrderOp } from "src/gql/graphql";
import { useOrderAdditionalProducts } from "src/hooks/useAdditionalProducts";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { useForm } from "react-hook-form";
import { Badge, ExpandableCard, Flex, Heading, Button } from "@telia/teddy";
import { ServiceBox } from "components/service-box";
import { HintControls } from "../Hint/HintControls";

type Props = {
  product: AdditionalProduct;
  isLegalOwner: boolean;
};

export type HintFormValues = {
  value: string;
  active: boolean;
};

export function Hint({ product, isLegalOwner }: Props) {
  const { addToast } = useToast();
  const { phoneNumber } = useMobileAccount();
  const [isEditing, setIsEdinting] = useState(false);

  const defaultValues = {
    value: product?.characteristic?.[0]?.config?.[0]?.value || "",
    active: product?.presentation?.active ?? false,
  };

  const form = useForm<HintFormValues>({
    defaultValues,
  });

  const { orderResponse, runOrderAdditionalProducts } = useOrderAdditionalProducts();
  const { data, loading } = orderResponse;

  const { setValue: setOrderId } = useLocalStorage<string>("telia-no-minside-order-id__settings");

  const getOperationStatus = () => {
    if (defaultValues.active && form.getValues("active") && form.formState.isDirty) {
      return OrderOp.ModifyAdditionalProduct;
    }
    if (!defaultValues.active && form.getValues("active") && form.getValues("value")) {
      return OrderOp.AddAdditionalProduct;
    }
    if (defaultValues.active && !form.getValues("active")) {
      return OrderOp.RemoveAdditionalProduct;
    }
    return OrderOp.ModifyAdditionalProduct;
  };

  function order() {
    const orderInput = [
      {
        productName: product?.code,
        operation: getOperationStatus(),
        productCharacteristics: form.getValues("active")
          ? {
              featureCode: product?.characteristic?.[0]?.featureCode || "AMOUNT",
              config: [
                {
                  characteristicType: product?.characteristic?.[0]?.config?.[0]?.characteristicType || "AMOUNT",
                  name: product?.characteristic?.[0]?.config?.[0]?.name || "AMOUNT",
                  value: form.getValues("value"),
                },
              ],
            }
          : undefined,
      },
    ];
    runOrderAdditionalProducts(
      {
        phoneNumber,
        input: orderInput,
      },
      {
        onCompleted() {
          setOrderId(data?.order.orderId || "");
          addToast({
            text: "Din forbrukssperre er lagret! Det kan ta opptil 5 minutter før du ser endringene her.",
            variant: "success",
            dataTrackingId: "change-hint-value-success",
          });
          form.reset(form.getValues());
        },
        onError() {
          addToast({
            text: "En feil oppsto. Prøv igjen om noen minutter, eller ta kontakt med kundesenteret.",
            variant: "error",
            dataTrackingId: "change-hint-value-error",
          });
        },
      }
    );
  }

  function handleToggle(value: boolean) {
    if (defaultValues.active === value) {
      form.reset();
    } else {
      form.setValue("active", value, { shouldDirty: true });
    }
  }

  const handleReset = () => {
    if (isEditing) {
      form.reset({ ...defaultValues });
      setIsEdinting(false);
    } else {
      form.reset();
    }
  };

  // NOTE: For PU we only show HINT if it's activated by LO.
  if (!product || (!isLegalOwner && defaultValues.active === false)) return null;

  return (
    <>
      <ExpandableCard type="multiple">
        <ExpandableCard.Item value="content1">
          <ExpandableCard.Trigger>
            <ExpandableCard.Header>
              <ExpandableCard.Description>
                <Flex direction="column" gap="100">
                  <Heading as="h5" variant="title-100">
                    Forbrukssperre
                  </Heading>
                  <div>
                    {form.watch("active") ? (
                      <Badge variant="success" hideIcon>
                        På
                      </Badge>
                    ) : (
                      <Badge variant="neutral">Av</Badge>
                    )}
                  </div>
                </Flex>
              </ExpandableCard.Description>
              <ExpandableCard.Indicator />
            </ExpandableCard.Header>
          </ExpandableCard.Trigger>
          <ExpandableCard.Content>
            <ServiceBox
              isActive={form.watch("active")}
              title={<strong>{product.name}</strong>}
              priceText={product.price?.price + ",-"}
              id={product.code}
              key={product.code}
              onChange={handleToggle}
              disabled={!product.presentation?.modifiable || loading || !isLegalOwner}
              content={[
                <p key={product.id + "-description-1"}>
                  Sett en beløpsgrense på forbruk utenom faste/abonnements-avgifter.
                </p>,
                <p key={product.id + "-description-2"}>
                  Dersom grensen passeres vil du få SMS-varsling, og videre forbruk vil bli sperret. Det sperrede
                  abonnementet kan ikke ringe, sende SMS/MMS eller bruke data i Norge. I utland vil det bli sperret for
                  all bruk.
                </p>,
                <p key={product.id + "-description-3"}>
                  Kun eier av abonnement kan endre denne grensen og oppheve forbrukssperren. Faktura vil kunne bli
                  høyere enn forbruksgrense pluss faste avgifter fordi forbruket ikke kan telles i sanntid.
                </p>,
                form.watch("active") && (
                  <HintControls
                    register={form.register}
                    value={form.watch("value")}
                    loading={loading}
                    defaultValues={defaultValues}
                    errorMessage={form.formState.errors.value?.message}
                    isEditing={isEditing}
                    isLegalOwner={isLegalOwner}
                    onEditing={() => setIsEdinting(true)}
                    onDeactivate={() => form.setValue("active", false, { shouldDirty: true })}
                    key={product.id + "-amount-v2"}
                  />
                ),
              ]}
            />

            {form.formState.isDirty || (!isEditing && defaultValues.active !== form.watch("active")) ? (
              <Flex gap="200">
                <Button disabled={loading} onClick={handleReset} type="button" variant="tertiary-purple">
                  Avbryt
                </Button>

                <Button
                  onClick={form.handleSubmit(order)}
                  disabled={loading || !form.watch("value") || (isEditing && !form.formState.isDirty)}
                  loading={loading}
                  data-tracking-id="service-save-button"
                >
                  {loading ? "Sender bestilling" : "Lagre"}
                </Button>
              </Flex>
            ) : null}
          </ExpandableCard.Content>
        </ExpandableCard.Item>
      </ExpandableCard>
    </>
  );
}
