import { ChangeEvent, useState } from "react";
import { Alert, Container, Paragraph, useToast } from "@telia-no-min-side/components";
import { TextField } from "@telia/styleguide";
import { isValidEmailAddress, track, uri } from "@telia-no-min-side/utils";
import { getErrorMessage } from "./utils/getErrorMessage";
import { getTryggOrderInput } from "./utils/getTryggOrderInput";
import { CheckoutContainer } from "../styles/Checkout.styled";
import { CartSummary } from "../CartSummary";
import { TryggProduct } from "./TryggProduct";
import { VasAdditionalProduct } from "../types";
import { graphql } from "gql";
import { ApolloError, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { VAS_MESSAGE } from "util/constants/valueAddedServices";
import { getTransactionDataVAS } from "pages/value-added-services/utils/getTransactionDataVAS";

type Props = {
  product: VasAdditionalProduct;
  phoneNumber: string;
};
const OrderTeliaTryggMutation = graphql(`
  mutation OrderTeliaTrygg($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);

export const TeliaTryggCheckout = ({ product, phoneNumber }: Props) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [emailValidationError, setEmailValidationError] = useState("");
  const [errorMessage, setErrorMessage] = useState<{ title: string; text: string } | undefined>();
  const { addToast } = useToast();
  const { pushTrackingEvent } = track.useEventTracking();
  const navigateBack = () => navigate(uri.minSideFixed("/tjenester"));
  const { subscribeToOrder, orders } = useOrderSubscription();
  const [orderTeliaTrygg, { loading }] = useMutation(OrderTeliaTryggMutation, {
    onCompleted(data) {
      setErrorMessage(undefined);
      addToast({ text: VAS_MESSAGE.ORDER_COMPLETED, variant: "success" });
      product.code &&
        data?.order.orderId &&
        subscribeToOrder({
          id: data?.order.orderId,
          phoneNumber: phoneNumber,
          vasProduct: product.code,
          toastText: VAS_MESSAGE.SERVICE_ACTIVATED,
        });

      const transactionData = getTransactionDataVAS(product, data?.order.orderId);
      pushTrackingEvent(transactionData);
      navigateBack();
    },

    onError(error: ApolloError) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
    },
  });

  const orderTrygg = () => {
    if (!product) return;

    const isValid = isValidEmailAddress(email);

    if (isValid) {
      setEmailValidationError("");
      const orderInput = getTryggOrderInput(product, email);
      orderTeliaTrygg({ variables: { input: orderInput, phoneNumber: phoneNumber } });
    } else {
      setEmailValidationError(VAS_MESSAGE.INVALID_EMAIL);
    }
  };

  if (!product) return null;

  const inOrder = orders.some((order) => order.vasProduct === product.code && order.phoneNumber === phoneNumber);

  return (
    <CheckoutContainer data-tracking-id="additional-services-telia-trygg-checkout">
      <TryggProduct product={product} />

      <Container maxWidth="xs" flexDirection="column" gap="lg">
        <Alert
          icon="alert"
          kind="negative"
          title={errorMessage?.title}
          text={errorMessage?.text}
          isOpen={!!errorMessage?.title}
        />

        {!inOrder && (
          <CartSummary selectedProduct={product} loading={loading} onOrder={orderTrygg} onCancel={navigateBack}>
            <TextField
              size="compact"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
                setEmailValidationError("");
              }}
              type="text"
              value={email}
              autoComplete="email"
              label="Din e-post"
              helpText={emailValidationError}
              error={!!emailValidationError}
              placeholder="Fyll inn din e-post"
              disabled={loading}
            />

            <Paragraph variant="paragraph-100">
              Det er viktig at din e-post er riktig, siden det er her du får tilgang til {product.shortName}.
            </Paragraph>
          </CartSummary>
        )}
      </Container>
    </CheckoutContainer>
  );
};
