import styled from "styled-components";
import { formatDataAmount } from "@telia-no-min-side/utils";
import { Flex, Text, MeterBar, Accordion, color, Notification } from "@telia/teddy";
import { getDataUsageDetails } from "../../utils";
import { useMobileBroadbandContext } from "pages/internet/mobile-broadband/context/MobileBroadbandContext";

type Props = {
  totalData: { remaining: number; limit: number };
  details: ReturnType<typeof getDataUsageDetails>;
  timeUntilDataUsagePeriodStarts: string | null;
};

export function LimitedDataProgressSection({ totalData, details, timeUntilDataUsagePeriodStarts }: Props) {
  const { isSleepMode } = useMobileBroadbandContext();

  return (
    <Flex direction="column" gap="50">
      <Accordion type="multiple">
        <Accordion.Item value="temp1">
          <Accordion.Header>
            <AccordionTrigger>
              <Flex direction="column" width="100%">
                <Flex justify="between">
                  <Text variant="paragraph-100-medium">{formatDataAmount(totalData.remaining)}</Text>
                  <Text variant="paragraph-100">{formatDataAmount(totalData.limit)}</Text>
                </Flex>

                <MeterBar max={totalData.limit}>
                  {details
                    .filter((detail) => detail.percentage)
                    .map((detail) => {
                      return <MeterBar.Indicator key={detail.color} color={detail.color} value={detail.remaining} />;
                    })}
                </MeterBar>
              </Flex>
            </AccordionTrigger>
          </Accordion.Header>
          <Accordion.Content>
            <Flex direction="column" gap="100">
              {details
                .filter((detail) => detail.percentage)
                .map((detail) => (
                  <Flex key={detail.color} direction="column" gap="100">
                    <Flex direction="column">
                      <Flex justify="between">
                        <Text variant="paragraph-100-medium">{detail.remainingFormatted}</Text>
                        <Text variant="paragraph-100">{detail.limitFormatted}</Text>
                      </Flex>
                      <MeterBar max={detail.limit ?? 0}>
                        <MeterBar.Indicator color={detail.color} value={detail.remaining ?? 0} />
                      </MeterBar>
                    </Flex>
                    <Text variant="additional-100">{detail.title}</Text>
                  </Flex>
                ))}
            </Flex>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
      {timeUntilDataUsagePeriodStarts && (
        <Text variant="additional-100">Ny periode starter om {timeUntilDataUsagePeriodStarts}</Text>
      )}

      {isSleepMode && (
        <Notification type="tile" variant="information">
          <Notification.Icon name="coffee" size="md" />
          <Notification.Text>Hvilemodus er aktivert.</Notification.Text>
        </Notification>
      )}
    </Flex>
  );
}

const AccordionTrigger = styled(Accordion.Trigger)`
  color: ${color.teddyColorFunctionalBlack};

  svg {
    margin-left: 0;
    margin-right: 0;
  }
`;
