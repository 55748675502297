export function getDataUsageTitle(type: string) {
  switch (type) {
    case "BASE":
      return "Inkludert data";
    case "ROLLOVER":
      return "Rollover";
    case "TOPUPS":
      return "Datapakker";
    default:
      return "";
  }
}
