import { Card, Container, Skeleton } from "@telia-no-min-side/components";

export function SkeletonPage() {
  return (
    <Container padding showGoBackButton maxWidth="md" gap flexDirection="column">
      <Skeleton variant="heading-m" width="30ch" />
      <Skeleton width="15ch" />
      <Container gap="lg" flexDirection="column">
        <Card removeMinHeight>
          <Container gap flexDirection="row" justifyContent="space-between" alignItems="center">
            <Skeleton width="10ch" />
            <Skeleton width="10ch" />
            <Skeleton width="10ch" />
          </Container>
        </Card>
        <Card removeMinHeight>
          <Container gap flexDirection="row" justifyContent="space-between" alignItems="center">
            <Skeleton width="10ch" />
            <Skeleton width="10ch" />
            <Skeleton width="10ch" />
          </Container>
        </Card>
        <Card removeMinHeight>
          <Container gap flexDirection="row" justifyContent="space-between" alignItems="center">
            <Skeleton width="10ch" />
            <Skeleton width="10ch" />
            <Skeleton width="10ch" />
          </Container>
        </Card>

        <Card removeMinHeight>
          <Container gap flexDirection="column">
            <Container gap flexDirection="row" justifyContent="space-between" alignItems="center">
              <Skeleton width="10ch" />
              <Skeleton width="10ch" />
              <Skeleton width="10ch" />
            </Container>
            <Skeleton />
            <Skeleton />
          </Container>
        </Card>
        <Card removeMinHeight>
          <Container gap flexDirection="column">
            <Container gap flexDirection="row" justifyContent="space-between" alignItems="center">
              <Skeleton width="10ch" />
              <Skeleton width="10ch" />
              <Skeleton width="10ch" />
            </Container>
            <Skeleton />
            <Skeleton />
          </Container>
        </Card>
      </Container>
    </Container>
  );
}
