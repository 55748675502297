import { Button, Modal, ModalBody, ModalFooter, ModalHeading } from "@telia-no-min-side/components";

type Props = {
  loading: boolean;
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  onCancelProduct: () => void;
};

export const TryggCancelServiceModal = ({ isOpen, loading, setIsModalOpen, onCancelProduct }: Props) => {
  return (
    <Modal
      setIsOpen={setIsModalOpen}
      isOpen={isOpen}
      size="xs"
      dataTrackingID="additional-services-telia-trygg-cancel-modal"
    >
      <ModalHeading>Vil du kansellere din Trygg forsikring?</ModalHeading>

      <ModalBody>
        <p>
          Velger du å kansellere din Trygg forsikring vil tjenestene sies opp umiddelbart og du vil miste tilgang til
          både Trygghetsportalen og ID-forsikring og E-handelforsikring.
        </p>
      </ModalBody>

      <ModalFooter flex>
        <Button size="sm" variant="tertiary-black" disabled={loading} onClick={() => setIsModalOpen(false)}>
          Avbryt
        </Button>

        <Button isLoading={loading} loadingText="Vent litt ..." size="sm" onClick={onCancelProduct}>
          Kanseller
        </Button>
      </ModalFooter>
    </Modal>
  );
};
