import { colors } from "@telia/styleguide";
import styled from "styled-components";
import { RadioItem } from "../RadioGroup/style";
import { remCalc } from "../utils/fonts";

export const RadioCardItem = styled(RadioItem)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: ${remCalc(8)};
    outline: 2px solid ${colors.corePurple700};
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
    transition: ease-out 0.15s opacity;
    pointer-events: none;
  }
  &:hover {
    ::after {
      opacity: 1;
    }
  }
`;
