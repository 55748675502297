import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Container, Skeleton, useToast } from "@telia-no-min-side/components";
import { format, getCookieValue, setSearchParams, uri } from "@telia-no-min-side/utils";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { UserRole } from "src/gql/graphql";
import { DetailsInfoHero } from "../../payment-info/DetailsInfoHero";
import { GET_INVOICE_BY_SUBSCRIPTION_QUERY } from "../../queries";
import { ButtonWrapper, DetailsWrapper, ReceiptPaper } from "../../styles";
import { INVOICE_PAGE_SIZE } from "../MobileInvoiceComponent";
import { InvoiceDetailsParams } from "./InvoiceDetailsMobile";
import { InvoiceSelectorMobile } from "./InvoiceSelectorMobile";
import { SubDetailsElementList } from "./SubDetailsElementList";
import { downloadFile } from "util/downloadfile";
import { COOKIE } from "util/constants/cookies";
import { config } from "util/config";

type SubInvoiceDetailsProps = {
  localNumber: string;
  accountId: string;
  isMobile: boolean;
};

export const SubInvoiceDetails = ({ localNumber, accountId, isMobile }: SubInvoiceDetailsProps) => {
  const queryParams = {
    isMobile,
  };
  const [accordionStates, setAccordionStates] = useState<boolean[]>([]);
  const [isDownloadingSubInvoice, setIsDownloadingSubInvoice] = useState(false);
  const { addToast } = useToast();
  const uriParams = setSearchParams(queryParams);
  const params = useParams<InvoiceDetailsParams>();
  const invoiceId = params.id;
  const subInvoiceData = useQuery(GET_INVOICE_BY_SUBSCRIPTION_QUERY, {
    variables: {
      localNumber,
      invoiceId: invoiceId || "",
      pageSize: INVOICE_PAGE_SIZE,
    },
  });

  useEffect(() => {
    if (subInvoiceData.data) {
      const initialStates = subInvoiceData.data?.subscription.invoice?.invoiceGroup?.map(() => false) ?? [];
      setAccordionStates(initialStates);
    }
  }, [subInvoiceData.data]);

  async function downloadSubInvoice() {
    setIsDownloadingSubInvoice(true);
    const ctsession = getCookieValue(COOKIE.CT_SESSION);
    const url = `${config.channelApiRestUrl}/documents?type=SUBSCRIPTION_INVOICE&countryCode=47&localNumber=${localNumber}&invoiceId=${invoiceId}`;
    try {
      await downloadFile({
        url,
        filename: `faktura-${invoiceId}.pdf`,
        config: {
          headers: {
            ctsession: ctsession || "",
          },
        },
      });
    } catch {
      addToast({
        text: "Kunne ikke laste ned faktura",
        variant: "error",
      });
    } finally {
      setIsDownloadingSubInvoice(false);
    }
  }

  const allOpen = accordionStates.every((isOpen) => isOpen);
  const toggleAllAccordions = () => {
    const newAccordionStates = accordionStates.map(() => !allOpen);
    setAccordionStates(newAccordionStates);
  };

  const subData = subInvoiceData?.data?.subscription;
  const isLegalOwner = subData?.roles.includes(UserRole.LegalOwner || UserRole.InvoicePayer);
  const isProductUser = subData?.roles.includes(UserRole.ProductUser);

  return (
    <Container padding="bottom" goBackTo={uri.minSideFixed("/faktura")}>
      <DetailsInfoHero
        invoiceDate={dayjs(subData?.invoice?.billingDate).format("MMMM YYYY")}
        dueDate={dayjs(subData?.invoice?.dueDate).format("DD. MMMM YYYY")}
        kidNumber={accountId}
        bankAccount={subData?.invoice?.billingInfo?.bankAccount ?? ""}
        paid={!!subData?.invoice?.paid}
        invoiceUser={format.startCase(`${subData?.productUser?.firstName} ${subData?.productUser?.surname}`)}
        isLegalOwner={isLegalOwner}
        isLoading={subInvoiceData.loading || !accountId}
      >
        <InvoiceSelectorMobile invoices={subData?.invoices} isSubLevel />
      </DetailsInfoHero>
      <DetailsWrapper>
        <SubDetailsElementList
          detailsIsOpen={accordionStates}
          invoiceGroup={subData?.invoice?.invoiceGroup}
          totalAmount={subData?.invoice?.amount?.value}
          accordionStates={accordionStates}
          setAccordionStates={setAccordionStates}
          invoiceDetailsIsLoading={subInvoiceData.loading || !accountId}
          phoneNumber={subData?.phoneNumber.localNumber}
          invoiceId={subData?.invoice?.invoiceNumber}
          isProductUser={isProductUser || false}
        />
        <ReceiptPaper />
        <ButtonWrapper>
          {subInvoiceData.loading || !accountId ? (
            <>
              <Skeleton variant="button" />
              <Skeleton variant="button" />
            </>
          ) : (
            <>
              <Button icon="zoom" variant="secondary" fullWidthSmallScreen onClick={toggleAllAccordions}>
                {allOpen ? "Lukk detaljert faktura" : "Se fakturadetaljer"}
              </Button>
              {config.showSubInvoiceDownload && (isProductUser || isLegalOwner) ? (
                <Button
                  fullWidthSmallScreen
                  icon="download"
                  onClick={downloadSubInvoice}
                  variant="secondary"
                  isLoading={isDownloadingSubInvoice}
                >
                  Last ned pdf
                </Button>
              ) : null}
              {subData?.roles.includes(UserRole.LegalOwner) && (
                <>
                  <Button
                    icon="invoice"
                    variant="primary"
                    fullWidthSmallScreen
                    tag="a"
                    href={uri.minSideFixed(`/faktura/${invoiceId}${uriParams}`)}
                  >
                    Se fullstendig faktura
                  </Button>
                </>
              )}
            </>
          )}
        </ButtonWrapper>
      </DetailsWrapper>
    </Container>
  );
};
