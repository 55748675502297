import dayjs from "dayjs";

export const calculateTimeLeft = (endDate: string) => {
  const duration = dayjs.duration(dayjs(endDate).diff(dayjs()));

  const daysLeft = Math.trunc(duration.asDays());
  const hoursLeft = Math.trunc(duration.asHours());
  const minutesLeft = Math.trunc(duration.asMinutes());

  return { daysLeft, hoursLeft, minutesLeft };
};
