import { WebSocketLink } from "@apollo/client/link/ws";
import { print } from "graphql/language/printer";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { v4 as uuidv4 } from "uuid";
import { config } from "../config";

// code from: https://github.com/apollographql/apollo-feature-requests/issues/224
const API_URL = config.channelApiWsUrl;
const WSS_URL = API_URL.replace("https", "wss").replace("appsync-api", "appsync-realtime-api");
const HOST = API_URL.replace("https://", "").replace("/graphql", "");
const api_header = {
  host: HOST,
  "x-api-key": config.channelApiKey,
};
const header_encode = (obj: Record<string, string | undefined>) => btoa(JSON.stringify(obj));
const connection_url = WSS_URL + "?header=" + header_encode(api_header) + "&payload=e30=";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
class UUIDOperationIdSubscriptionClient extends SubscriptionClient {
  //
  generateOperationId() {
    // AppSync recommends using UUIDs for Subscription IDs but SubscriptionClient uses an incrementing number
    return uuidv4();
  }
  processReceivedData(receivedData: string) {
    try {
      const parsedMessage: { type: string } = JSON.parse(receivedData);
      if (parsedMessage?.type === "start_ack") return; // sent by AppSync but meaningless to us
    } catch (e) {
      throw new Error("Message must be JSON-parsable. Got: " + receivedData);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    super.processReceivedData(receivedData);
  }
}

const createAppSyncGraphQLOperationAdapter = () => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  applyMiddleware: (options: Record<any, any>, next: () => void) => {
    // AppSync expects GraphQL operation to be defined as a JSON-encoded object in a "data" property
    options.data = JSON.stringify({
      query: typeof options?.query === "string" ? options?.query : print(options?.query),
      variables: options?.variables,
    });

    // AppSync only permits authorized operations
    options.extensions = { authorization: api_header };

    // AppSync does not care about these properties
    delete options.operationName;
    delete options.variables;
    // Not deleting "query" property as SubscriptionClient validation requires it

    next();
  },
});

export const wsLink = new WebSocketLink(
  new UUIDOperationIdSubscriptionClient(
    connection_url,
    {
      timeout: 5 * 60 * 1000,
      reconnect: true,
      lazy: true,
      reconnectionAttempts: 3,
      connectionCallback: (err) => console.log("connectionCallback", err ? "ERR" : "OK", err || ""),
    },
    WebSocket
  ).use([createAppSyncGraphQLOperationAdapter()])
);
