import { getSimplePriceText, uri, track } from "@telia-no-min-side/utils";
import { AdditionalProduct } from "src/gql/graphql";
import { isB2BCompanyPaidTeliaSkyProduct } from "./utils/isB2BCompanyPaidTeliaSkyProduct";
import { isTeliaSkyIncluded } from "./utils/isTeliaSkyIncluded";
import { SkyProductDescription } from "./SkyProductDescription";
import { Card, Flex, Link, Box } from "@telia/teddy";
import { Link as RouterLink } from "react-router-dom";
import { ProductBadge } from "../ProductBadge";
import { config } from "util/config";

type Props = {
  product: AdditionalProduct;
  hasFreeTeliaSkyOnFixedSide: boolean;
};

export const SkyProduct = ({ product, hasFreeTeliaSkyOnFixedSide }: Props) => {
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const isIncludedForFree = isTeliaSkyIncluded(product);
  const isB2BTeliaSky = isB2BCompanyPaidTeliaSkyProduct(product);

  const getPriceText = (): { price: string; pricePrefix?: string } | null => {
    if (isIncludedForFree || hasFreeTeliaSkyOnFixedSide) return null;

    const price = getSimplePriceText(product.price?.priceWithoutDiscount ?? 0, "md.");

    if (isB2BTeliaSky) {
      return {
        pricePrefix: "Ordinær pris",
        price,
      };
    }

    return { price };
  };

  const priceText = getPriceText();

  return (
    <Flex width="100%" maxWidth="400px" direction="column" gap="gutter-lg">
      <Card
        variant="white"
        bordered
        justify="stretch"
        shadow
        layout="product"
        data-tracking-id="additional-services-telia-sky-product-card"
      >
        <Card.Slot>
          <ProductBadge hasFreeTeliaSkyOnFixedSide={hasFreeTeliaSkyOnFixedSide} product={product} />
        </Card.Slot>
        <Card.Heading as="h2">{product.shortName}</Card.Heading>
        <Card.Content>
          {hasFreeTeliaSkyOnFixedSide && (
            <p>
              Siden du har TV/internett fra Telia, har du Telia Sky inkludert. Gå til Min Side for internett ved å
              klikke på knappen under, og aktiver Telia Sky derifra.
            </p>
          )}

          <SkyProductDescription
            hideInfoBox={hasFreeTeliaSkyOnFixedSide}
            shortDescription={hasFreeTeliaSkyOnFixedSide}
          />
          <Box mt="gutter-md">
            <Link
              variant="standalone"
              href={product?.descriptionLink?.length ? product?.descriptionLink : `${config.teliaNoRootUrl}/trygg/sky/`}
              target="_blank"
            >
              Les mer om Telia Sky
            </Link>
          </Box>
        </Card.Content>
        {priceText ? (
          <Card.Price priceText={priceText.price} pricePrefix={priceText.pricePrefix} variant="purple" />
        ) : null}
        {hasFreeTeliaSkyOnFixedSide && (
          <Card.Button
            mt="200"
            ml="auto"
            onClick={() => {
              pushGenericTrackingEvent({
                ui_item_action: "button_click",
                ui_item_context: "minside/tjenester",
                ui_item_text: "Videre",
                ui_item_type: "button",
                ui_item_url: "/internett/tilleggstjenester",
              });
            }}
            asChild
          >
            <RouterLink to={uri.minSideFixed("/internett/tilleggstjenester")}>Videre</RouterLink>
          </Card.Button>
        )}
      </Card>
    </Flex>
  );
};
