import { useLazyQuery } from "@apollo/client";
import { Container } from "@telia-no-min-side/components";
import { Button, Icon, Text, TextSpacing } from "@telia/teddy";
import { useEffect, useState } from "react";
import { PhoneCheck } from "./components/PhoneCheck";
import { GetTempAccess } from "./components/GetTempAccess";
import { IS_TELIA_NUMBER } from "../graphql/isTeliaPhoneNumber";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

enum Steps {
  phoneCheck = "PHONE_CHECK",
  otp = "OTP",
}

const NORWEGIAN_PHONE_LENGTH = 8;

export function TempAccessVerification() {
  const [number, setNumber] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const [isTeliaNumber, setIsTeliaNumber] = useState(false);
  const [step, setStep] = useState<Steps>(Steps.phoneCheck);

  const [checkNumber, { loading }] = useLazyQuery(IS_TELIA_NUMBER, {
    onCompleted: (data) => {
      if (!data.isTeliaNumber?.isTeliaNumber) {
        setIsTeliaNumber(false);
        setErrors(["Ukjent nummer. Prøv igjen, eller kontakt kundeservice."]);
      } else {
        setErrors([]);
        setIsTeliaNumber(true);
        setStep(Steps.otp);
      }
    },
    onError: () => {
      setErrors(["Ukjent nummer. Prøv igjen, eller kontakt kundeservice."]);
    },
  });

  const handleCheckNumber = () => {
    if (number.length === NORWEGIAN_PHONE_LENGTH) {
      checkNumber({ variables: { checkPhoneNumber: number } });
    }
  };

  const handleValueChange = (phoneNumber: string) => {
    setNumber(phoneNumber);
  };

  const handleResetNumber = () => {
    setStep(Steps.phoneCheck);
    setNumber("");
    setIsTeliaNumber(false);
  };

  // NOTE: used to clear the error if number has been removed from the input.
  useEffect(() => {
    if (number.length < NORWEGIAN_PHONE_LENGTH && errors.length) {
      setErrors([]);
    }
  }, [number]);

  return (
    <Container showGoBackButton padding flexDirection="column" gap="lg" maxWidth="sm">
      {step === Steps.phoneCheck ? (
        <PhoneCheck
          phoneNumber={number}
          onNumberChange={handleValueChange}
          error={errors}
          isLoading={loading}
          isTeliaNumber={isTeliaNumber}
          onSubmit={handleCheckNumber}
        />
      ) : (
        <GetTempAccess error={[]} phoneNumber={number} onResetPhone={handleResetNumber} />
      )}
      <TextSpacing>
        <Text>Trenger du mer hjelp?</Text>
        <Button variant="secondary" asChild>
          <a href={EXTERNAL_LINK.CUSTOMER_SERVICE_MOBILE}>
            Gå til kundeservice
            <Icon name="external" />
          </a>
        </Button>
      </TextSpacing>
    </Container>
  );
}
