import styled from "styled-components";
import { remCalc, devices } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";

export const Component = styled.div`
  @media (min-width: ${remCalc(500)}) {
    display: flex;
    gap: ${remCalc(32)};
  }
`;

export const TermContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${remCalc(5)};
  margin-bottom: initial;

  .Icon {
    width: 1rem;
    height: 1rem;
    color: ${colors.corePurple};
  }

  @media (max-width: ${remCalc(500)}) {
    margin-bottom: ${remCalc(24)};
  }
`;

export const HeaderTop = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: ${remCalc(16)};

  @media ${devices.sm_up} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const DescriptionText = styled.p`
  margin: 0;
  display: inline;
  padding-right: ${remCalc(5)};
`;

export const ModalBodyContainer = styled.div`
  margin: ${remCalc(16)} 0 ${remCalc(48)};
`;

export const ModalBodyParagraph = styled.p`
  margin: ${remCalc(16)} 0 ${remCalc(48)};
`;
