import { uri } from "@telia-no-min-side/utils";
import { Button, Container, Heading, Link, Paragraph } from "@telia-no-min-side/components";
import { useSearchParams } from "react-router-dom";
import { phoneNumbers } from "src/util/phoneNumbers";

export function ReceiptError() {
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get("returnTo");
  const retryUrl = searchParams.get("retry");
  const returnToName = searchParams.get("name");
  const isExternalNavigation = searchParams.get("external") === "true";

  return (
    <Container padding maxWidth="md">
      <Heading tag="h2" variant="title-400">
        Noe gikk galt!
      </Heading>
      <Paragraph>
        Vi kunne dessverre ikke behandle ordren din og fikk heller ikke opprettet en manuell sak for oppfølging. Vi
        beklager at dette skjedde og ønsker gjerne at du tar kontakt med oss på telefon
      </Paragraph>
      <Paragraph>
        <Link icon="phone" variant="standalone" href={`tel:${phoneNumbers.customerServiceNorway}`}>
          {phoneNumbers.customerServiceNorway}
        </Link>
      </Paragraph>
      <Container gap flexDirection="row">
        {retryUrl && (
          <Button tag={"a"} isInternalNavigation={!isExternalNavigation} href={retryUrl} hideLinkIcon>
            Prøv igjen
          </Button>
        )}
        <Button
          tag={"a"}
          isInternalNavigation={!isExternalNavigation}
          href={returnToName && returnTo ? returnTo : uri.minSideFixed("/hjem")}
          iconPosition="before"
          variant={retryUrl ? "secondary" : "primary"}
          icon="arrow-left"
        >
          Tilbake til {returnToName || "Hjem"}
        </Button>
      </Container>
    </Container>
  );
}
