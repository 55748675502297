import { Button, Link, Modal, Text, TextSpacing } from "@telia/teddy";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function ReceiptSuccessContent() {
  return (
    <Modal.Content>
      <Modal.Group variant="title">
        <Modal.Title as="h3">Takk for bestillingen!</Modal.Title>
      </Modal.Group>
      <Modal.Description>
        <TextSpacing>
          <Text variant="paragraph-100">
            Vi har nå mottatt din bestilling. Utstyret sendes til deg per post og du mottar pakken i løpet av 3-7
            arbeidsdager.
          </Text>
          <Text variant="paragraph-100">
            Angreretten forutsetter at du senest innen 14 dager etter at du mottar leveransen gir oss melding om dette
            (angrefrist). Skal du benytte deg av angreretten og sende inn en klage må du fylle ut{" "}
            <Link target="_blank" variant="text" href={EXTERNAL_LINK.CUSTOMER_SERVICE_TERMS_AND_CONDITIONS}>
              angrerettskjema TV og internett
            </Link>{" "}
            og sende det til oss.
          </Text>
        </TextSpacing>
      </Modal.Description>
      <Modal.Group variant="action">
        <Modal.Close slot="content">
          <Button variant="primary" size="sm">
            Lukk
          </Button>
        </Modal.Close>
      </Modal.Group>
    </Modal.Content>
  );
}
