import { Spinner as TeliaSpinner } from "@telia/styleguide";
import styled from "styled-components";

export function Spinner() {
  return (
    <Wrapper>
      <TeliaSpinner />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
