import { Badge, Card, Text } from "@telia/teddy";
import { Button, Container, Heading, Icon, Skeleton } from "@telia-no-min-side/components";
import { format, getSimplePriceText, track, uri } from "@telia-no-min-side/utils";
import { useFetchUser } from "src/api/fixed";
import { useEffect, useState } from "react";
import { useFetchTvOverFwa } from "src/store/hooks/useFetchTvOverFwa";
import { LinkCard } from "../card/LinkCard";
import { InteractiveCard, LinkCardImage } from "../card/style";
import { useSelectAccount } from "hooks/useSelectAccount";
import { AUTH } from "util/constants/auth";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function UpSellTvOverFwaCard() {
  const tvOverFwa = useFetchTvOverFwa();
  const user = useFetchUser();
  const { setAccount } = useSelectAccount();
  const [showSelectAccount, setShowSelectAccount] = useState(false);
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const isLoading = tvOverFwa.isLoading;

  if (isLoading) {
    return (
      <Card variant="white" shadow bordered width="100%" maxWidth="400px">
        <Skeleton variant="heading-s" width="10ch" />
        <Skeleton width="35ch" />
        <Skeleton variant="button-small" float="right" width="10ch" />
      </Card>
    );
  }

  if (!tvOverFwa.allAccounts.data || !tvOverFwa.customerIdsWithAvailableTvOverFwa?.length) return null;

  const buttonText = tvOverFwa.allAccounts.isCampaign ? "Se ditt tilbud" : "Bestill";
  const lozenge = tvOverFwa.allAccounts.isCampaign
    ? ({
        status: "special",
        label: `${tvOverFwa.allAccounts.hasMultipleFwaProducts ? "Opp til " : ""}${getSimplePriceText(
          tvOverFwa.allAccounts.mostDiscountPrice,
          tvOverFwa.allAccounts.productWithMostDiscount?.prices.chargePeriod
        )} i rabatt!`,
      } as const)
    : undefined;

  if (showSelectAccount && user.data) {
    const userWithTvOverFwa = tvOverFwa.customerIdsWithAvailableTvOverFwa.map(
      (customerId) =>
        user.data?.usersWithCustomerId.find((userWithCustomerId) => userWithCustomerId.customerId === customerId)
    );
    return (
      <Card variant="white" shadow bordered>
        {!!lozenge && (
          <Card.Slot align="top-right">
            <Badge variant={lozenge?.status}>{lozenge?.label}</Badge>
          </Card.Slot>
        )}

        <Text>Velg hvilken adresse det gjelder:</Text>

        <Container flexDirection="column" gap>
          {userWithTvOverFwa?.map(
            (customer) =>
              !!customer && (
                <div key={`${customer.customerId}-select-wifi-address`}>
                  <LinkCard
                    removeMinHeight
                    data-di-mask
                    backgroundColor="light-purple"
                    cardHref={uri.minSideFixed(ICX_REQUEST.URL_ORDER_TV)}
                    isCardLinkInternalNavigation
                    onLinkNavigation={() => {
                      pushGenericTrackingEvent({
                        ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                        ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
                        ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_TV_OVER_FWA,
                        ui_item_url: uri.minSideFixed(ICX_REQUEST.URL_ORDER_TV),
                        ui_item_text: format.startCase(`${customer.street}`),
                      });
                      setAccount({ accountType: AUTH.ACCOUNT_TYPE.FIXED, id: customer.customerId });
                    }}
                    cardLinkText={format.startCase(`${customer.street}`)}
                  >
                    <Container flexDirection="row" gap floatContent="center">
                      <Icon size="sm" icon="tv-simple" />
                      <Text>{customer.street}</Text>
                    </Container>
                  </LinkCard>
                </div>
              )
          )}
        </Container>
      </Card>
    );
  }

  const hasMultipleAvailableSmartWifiProducts = tvOverFwa.customerIdsWithAvailableTvOverFwa.length > 1;

  if (hasMultipleAvailableSmartWifiProducts) {
    return (
      <InteractiveCard
        onClick={() => {
          setShowSelectAccount(true);
        }}
      >
        {!!lozenge && (
          <Card.Slot align="top-right">
            <Badge variant={lozenge?.status}>{lozenge?.label}</Badge>
          </Card.Slot>
        )}
        <LinkCardImage src="https://www.telia.no/globalassets/produkter/tv/eget-utstyr-v-2.png" alt="Telia play" />
        <Container flexDirection="column">
          <UpSellContent />
          <Container floatContent="right">
            <Button
              variant="text-purple"
              size="sm"
              trackEvent={{
                ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
                ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
                ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_TV_OVER_FWA,
                ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
                ui_item_text: buttonText,
              }}
              onClick={() => {
                setShowSelectAccount(true);
              }}
            >
              {buttonText}
            </Button>
          </Container>
        </Container>
      </InteractiveCard>
    );
  }

  const customerIdToSelect = tvOverFwa.customerIdsWithAvailableTvOverFwa.find((customerId) => customerId);

  return (
    <LinkCard
      href={uri.minSideFixed(ICX_REQUEST.URL_ORDER_TV)}
      isInternalNavigation
      floatButtonRight
      linkText={buttonText}
      hideLinkIcon={false}
      onLinkNavigation={() => {
        pushGenericTrackingEvent({
          ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
          ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
          ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_TV_OVER_FWA,
          ui_item_url: uri.minSideFixed(ICX_REQUEST.URL_ORDER_TV),
          ui_item_text: buttonText,
        });
        customerIdToSelect && setAccount({ accountType: AUTH.ACCOUNT_TYPE.FIXED, id: customerIdToSelect });
      }}
      img={{
        src: "https://www.telia.no/globalassets/produkter/tv/eget-utstyr-v-2.png",
        alt: "Telia play",
      }}
    >
      {lozenge && (
        <Card.Slot align="top-right">
          <Badge variant={lozenge?.status}>{lozenge?.label}</Badge>
        </Card.Slot>
      )}
      <UpSellContent />
    </LinkCard>
  );
}

function UpSellContent() {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  useEffect(() => {
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.VIEW,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_TV_OVER_FWA,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.ORDER_TELIA_PLAY,
    });
  }, []);

  return (
    <div>
      <Heading tag="h3" variant="title-75" ellipsisOverflow>
        Bestill Telia Play
      </Heading>
      <Text variant="additional-100">Strømming og TV på ett sted!</Text>
    </div>
  );
}
