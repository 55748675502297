import { v4 as uuid } from "uuid";
import { Flex } from "@telia/teddy";
import { types } from "@telia-no-min-side/utils";
import { Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { ProductCard } from "../components/ProductCard";
import { useFlexOrder } from "../../context/FlexOrderProvider";
import { ProductCardSkeleton } from "../components/ProductCardSkeleton";

export function Step1View() {
  const { isLoading, activeFlexProduct, flexProductsForPurchase, setFlexProductOffer, navigateToStep2 } =
    useFlexOrder();

  const handleFlexOfferSelection = (product: types.fixed.TvPointsAndBroadbandPrice | undefined) => {
    setFlexProductOffer(product);
    navigateToStep2();
  };

  return (
    <Container flexDirection="column" gap="lg" maxWidth="sm">
      <Heading tag="h3" variant="title-200" removeMargin>
        Velg din inkluderte Flex-pakke
      </Heading>
      <Paragraph>
        Velg først hvilken kostnadsfrie Flex-pakke du vil ha. I neste steg kan du skreddersy valgt pakke ved å legge til
        mer internetthastighet eller TV-poeng hvis du ønsker det.
      </Paragraph>

      <Flex direction="column" gap="200" data-tracking-id="broadband-change-current-subscription">
        <Paragraph removeMargin>Endres fra</Paragraph>
        <ProductCard
          isLoading={isLoading}
          onClick={() => handleFlexOfferSelection(activeFlexProduct)}
          tvOption={activeFlexProduct?.tvOption}
          broadbandOption={activeFlexProduct?.broadbandOption}
          price={activeFlexProduct?.sum.currentAmount || 0}
          chargePeriod={activeFlexProduct?.sum.chargePeriod || ""}
          showActiveProductBadge
        />
      </Flex>

      {/* OFFERINGS */}
      <Flex direction="column" gap="200" data-tracking-id="broadband-change-current-subscription">
        <Paragraph removeMargin>Velg ny Flex-pakke</Paragraph>
        {isLoading ? (
          <Flex direction="column" gap="200">
            <ProductCardSkeleton />
            <ProductCardSkeleton />
          </Flex>
        ) : (
          flexProductsForPurchase?.map((flexProduct) => (
            <ProductCard
              key={uuid()}
              isLoading={isLoading}
              onClick={() => handleFlexOfferSelection(flexProduct)}
              tvOption={flexProduct.tvOption}
              broadbandOption={flexProduct.broadbandOption}
              price={flexProduct.sum.currentAmount}
              chargePeriod={flexProduct.sum.chargePeriod || ""}
            />
          ))
        )}
      </Flex>
    </Container>
  );
}
