import React from "react";
import PropTypes from "prop-types";
import { Button, Heading } from "@telia/styleguide";
import { ipv4, staticInternalIpEntry } from "../../../../util/PropTypes";
import { StaticInternalIpEntry } from "./StaticInternalIpEntry";
import { ExpandableStaticInternalIPEntry } from "./ExpandableStaticInternalIPEntry";
import NewStaticInternalIPEntry from "./NewStaticInternalIPEntry";

import "./style.less";

const { arrayOf, func, object } = PropTypes;

const Container = ({ children }) => <div className="static-internal-container">{children}</div>;

export class StaticInternalIp extends React.Component {
  static propTypes = {
    entries: arrayOf(staticInternalIpEntry),
    homeCentral: object,
    updateRule: func,
    deleteRule: func,
    mask: ipv4,
    errorMessages: arrayOf(object),
    validate: func,
    saveNewRuleToHomeCentral: func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showNewRulePrompt: false,
    };
  }

  saveNewRuleToHomeCentral = (newRule) => {
    this.props.saveNewRuleToHomeCentral(newRule);
  };

  render() {
    const { entries, deleteRule, mask, updateRule, errorMessages, validate, homeCentral } = this.props;

    return (
      <Container>
        <Heading level={3} text={"Statisk IP"} />
        {entries.map((entry, index) => (
          <StaticInternalIpEntry
            validate={validate}
            errorMessages={errorMessages.filter((msg) => msg.tag.includes(index))}
            key={index}
            mask={mask}
            onChange={(updatedRule) => updateRule(updatedRule, index)}
            internalIpEntry={entry}
            onDelete={() => deleteRule(index)}
          />
        ))}
        <Button
          text={"+ Legg til"}
          onClick={() => this.setState({ showNewRulePrompt: !this.state.showNewRulePrompt })}
        />
        <ExpandableStaticInternalIPEntry show={this.state.showNewRulePrompt}>
          <NewStaticInternalIPEntry
            onChange={this.saveNewRuleToHomeCentral}
            mask={mask}
            closePrompt={() => this.setState({ showNewRulePrompt: false })}
            homeCentral={homeCentral}
            saveNewRuleToHomeCentral={this.saveNewRuleToHomeCentral}
            errorMessages={errorMessages}
          />
        </ExpandableStaticInternalIPEntry>
      </Container>
    );
  }
}
