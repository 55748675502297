import React from "react";
import PropTypes from "prop-types";
import { ipv4, staticInternalIpEntry } from "../../../../util/PropTypes";
import { homeCentralUtilities } from "../../../../util/HomeCentralUtilities";
import { StaticInternalIPDisplay } from "./StaticInternalIPDisplay";
import { ExpandableStaticInternalIPEntry } from "./ExpandableStaticInternalIPEntry";
import { StaticInternalIPEntryEditable } from "./StaticInternalIPEntryEditable";

import "./StaticInternalIPEntry.less";

const { func, arrayOf, object } = PropTypes;

export class StaticInternalIpEntry extends React.Component {
  static propTypes = {
    internalIpEntry: staticInternalIpEntry,
    mask: ipv4,
    onDelete: func,
    onChange: func,
    errorMessages: arrayOf(object),
    validate: func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showEditable: false,
    };
  }

  updateRuleAndPropagateToParent = (newValue, param) => {
    const { onChange, internalIpEntry } = this.props;

    const updatedRule = homeCentralUtilities.updateParamInStaticInternalIPEntry(internalIpEntry, newValue, param);

    onChange(updatedRule);
  };

  validateIfAllNecessaryFieldsAreSet = () => {
    const { internalIpEntry, validate } = this.props;

    if (Object.values(internalIpEntry.IPAddress).every((val) => val !== "") && internalIpEntry.MacAddress !== "") {
      validate();
    }
  };

  renderEditableView = () => {
    const { internalIpEntry, mask, onDelete, errorMessages } = this.props;

    return (
      <StaticInternalIPEntryEditable
        errorMessages={errorMessages}
        mask={mask}
        onDelete={onDelete}
        internalIpEntry={internalIpEntry}
        onChange={this.updateRuleAndPropagateToParent}
        validate={this.validateIfAllNecessaryFieldsAreSet}
        onClose={() => this.setState({ showEditable: false })}
        onSave={() => this.setState({ showEditable: false })}
      />
    );
  };

  render() {
    const { internalIpEntry, onDelete } = this.props;

    return (
      <div className="static-internal-ip-entry-wrapper" style={{ width: "100%" }}>
        <StaticInternalIPDisplay
          ipAddress={internalIpEntry.IPAddress}
          macAddress={internalIpEntry.MacAddress}
          name={internalIpEntry.Alias}
          onChangeClick={() => this.setState({ showEditable: !this.state.showEditable })}
          onDeleteClick={onDelete}
        />
        <ExpandableStaticInternalIPEntry show={this.state.showEditable}>
          {this.renderEditableView()}
        </ExpandableStaticInternalIPEntry>
      </div>
    );
  }
}
