import {
  Button,
  Card,
  Container,
  List,
  ListItem,
  Paragraph,
  RadioGroup,
  TextInput,
  DatePicker,
} from "@telia-no-min-side/components";
import { validationPatterns as patterns, types, getFixedUserAddress } from "@telia-no-min-side/utils";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import dayjs from "dayjs";

export type MoveOutFormData = {
  email: string;
  to: {
    postalCode: string;
    city: string;
    streetAddress: string;
  };
  moveDate: string;
  contactNewResident: string;
  newResident?: {
    name?: string;
    phoneNumber?: string;
  };
};

type MoveOutFormProps = {
  selectedUser: types.fixed.UserWithCustomerId;
  isLoading: boolean;
  onFormSubmit: (data: MoveOutFormData) => void;
};

export function MoveOutForm({ selectedUser, onFormSubmit, isLoading }: MoveOutFormProps) {
  const defaultValues: Partial<MoveOutFormData> = {
    email: selectedUser.email,
    moveDate: dayjs().format("YYYY-MM-DD"),
    contactNewResident: "NO",
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<MoveOutFormData>({ defaultValues });

  const onSubmit: SubmitHandler<MoveOutFormData> = (d) => onFormSubmit(d);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container flexDirection="column" gap="lg">
        <Card removeMinHeight showShadow={false} backgroundColor="grey">
          <Paragraph variant="paragraph-100" removeMargin>
            <strong>Flytter fra adresse:</strong>
          </Paragraph>
          <Paragraph variant="paragraph-100" removeMargin>
            {getFixedUserAddress(selectedUser)}
          </Paragraph>
        </Card>
        <TextInput
          {...register("email", {
            required: "Epost må fylles ut",
            pattern: { ...patterns.email },
          })}
          required
          errorMessage={errors.email?.message}
        >
          Epost
        </TextInput>
        <TextInput
          {...register("to.postalCode", {
            required: "Husk å skriv inn postnummeret",
            pattern: { ...patterns.postalCode },
          })}
          required
          disabled={isLoading}
          errorMessage={errors.to?.postalCode?.message}
        >
          Flytter til postnummer
        </TextInput>
        <TextInput
          {...register("to.city", {
            required: "Husk å skriv inn poststed",
          })}
          required
          disabled={isLoading}
          errorMessage={errors.to?.city?.message}
        >
          Flytter til poststed
        </TextInput>
        <TextInput
          {...register("to.streetAddress", {
            required: "Husk å skrive inn adressen",
          })}
          required
          disabled={isLoading}
          errorMessage={errors.to?.streetAddress?.message}
        >
          Flytter til gateadresse
        </TextInput>
        <Controller
          name="moveDate"
          control={control}
          rules={{ required: "Husk å skrive inn dato for innflytting" }}
          render={({ field }) => (
            <DatePicker
              label="* Dato for innflytting"
              fullWidth
              value={field.value}
              onSelectDate={field.onChange}
              minDate={dayjs().format("YYYY-MM-DD")}
              disabled={isLoading}
              error={!!errors.moveDate?.message}
              helpText={errors.moveDate?.message}
            />
          )}
        />
        <Controller
          name="contactNewResident"
          control={control}
          render={({ field }) => (
            <RadioGroup
              legend="Ønsker ny beboer å ta over eksisterende abonnement?"
              name={field.name}
              onValueChange={field.onChange}
            >
              <RadioGroup.Item title="Vet ikke" value="MAYBE">
                Vet ikke
              </RadioGroup.Item>
              <RadioGroup.Item title="Nei" value="NO">
                Nei
              </RadioGroup.Item>
              <RadioGroup.Item title="Ja" value="YES">
                Ja
              </RadioGroup.Item>
            </RadioGroup>
          )}
        />

        <Paragraph>Hvis du har kontaktinformasjon på ny beboer kan du oppgi den her, så vi kan ta kontakt:</Paragraph>
        <TextInput
          {...register("newResident.name")}
          disabled={isLoading}
          errorMessage={errors.newResident?.name?.message}
        >
          Navn på ny beboer
        </TextInput>
        <TextInput
          {...register("newResident.phoneNumber")}
          disabled={isLoading}
          errorMessage={errors.newResident?.phoneNumber?.message}
        >
          Mobilnummer på ny beboer
        </TextInput>
        <div>
          <Paragraph variant="additional-100">Dette skjer når du sender inn skjemaet:</Paragraph>
          <List>
            <ListItem icon="check-mark">
              <Paragraph variant="additional-100">
                Etter at du har sendt inn skjemaet får du en SMS fra oss innen 2 – 3 dager, med informasjon om videre
                prosess.
              </Paragraph>
            </ListItem>
            <ListItem icon="check-mark">
              <Paragraph variant="additional-100">
                Har du utstyr som skal returneres til oss får du informasjon om dette. Har du kjøpt noen ekstra
                tjenester fra oss er det oppsigelsestid på 1 måned + inneværende måned, og du vil bli fakturert ut
                perioden.
              </Paragraph>
            </ListItem>
          </List>
        </div>
        <div>
          <Button type="submit" variant="primary" floatRight disabled={isLoading} isLoading={isLoading}>
            Send
          </Button>
        </div>
      </Container>
    </form>
  );
}
