import { Accordion, Paragraph } from "@telia-no-min-side/components";

type Props = {
  startDate: number | undefined;
  dueDate: number | undefined;
};

export function InfoAccordion({ startDate, dueDate }: Props) {
  return (
    <Accordion variant="info">
      <Accordion.Item maxWidth="xs">
        <Accordion.Trigger>
          <Paragraph>
            Forfallsdato <strong>{dueDate}. hver måned</strong>
          </Paragraph>
        </Accordion.Trigger>
        <Accordion.Content>
          <Paragraph variant="additional-100">
            Forfallsdato er den datoen en faktura senest skal være betalt.{" "}
          </Paragraph>
        </Accordion.Content>
      </Accordion.Item>

      <Accordion.Item maxWidth="xs">
        <Accordion.Trigger>
          <Paragraph>
            Periodestart <strong>{startDate}. hver måned</strong>
          </Paragraph>
        </Accordion.Trigger>
        <Accordion.Content>
          <Paragraph variant="additional-100">
            Periodestart er den datoen abonnementsperioden begynner. På denne datoen blir tjenestene fornyet, og gjelder
            eksempelvis påfyll av datamengde/hastighet.
          </Paragraph>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  );
}
