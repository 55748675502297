import React, { useEffect, useState } from "react";
import { Spinner } from "@telia/styleguide";
import { types } from "@telia-no-min-side/utils";
import { getQueryStringVal } from "../../util/GetQueryParamValue";
import { IdentifierParam, InvoiceIdParam, RedirectResultParam } from "./types/OnlinePaymentParam";
import { authoriseThreeDSVersion1 } from "./utils/PaymentUtils";
import { PaymentDone } from "./PaymentDone";
import { FixedPaymentResponse } from "./types/PaymentResponse";

type Props = {
  onPaymentConfirmed: () => void;
  invoiceId: types.fixed.Invoice["id"];
};

/**
 * Component which handles the return of the customer from a redirect when using 3DS version 1 authorisation for payment.
 */
export function RedirectPaymentAuthorisation(props: Props): React.ReactElement | null {
  const [detailsResponse, setDetailsResponse] = useState<FixedPaymentResponse | null>(null);
  const [postingDetails, setPostingDetails] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<boolean>(false);
  const redirectResultParam: RedirectResultParam = {
    redirectResult: getQueryStringVal("redirectResult"),
  };
  const identifierParam: IdentifierParam = {
    identifier: getQueryStringVal("identifier"),
  };

  const invoiceId: InvoiceIdParam = {
    invoiceId: getQueryStringVal("invoiceId"),
  };

  useEffect(() => {
    if (
      redirectResultParam.redirectResult &&
      identifierParam.identifier &&
      !detailsResponse &&
      !postingDetails &&
      invoiceId.invoiceId === props.invoiceId + ""
    ) {
      setPostingDetails(true);
      authoriseThreeDSVersion1(redirectResultParam.redirectResult, identifierParam.identifier)
        .then((response: FixedPaymentResponse) => {
          setDetailsResponse(response);
          setPostingDetails(false);
          props.onPaymentConfirmed();
        })
        .catch(() => {
          setPaymentError(true);
        });
    }
  }, [redirectResultParam, identifierParam, detailsResponse, postingDetails]);

  if (
    (!identifierParam.identifier && !redirectResultParam.redirectResult) ||
    invoiceId.invoiceId !== props.invoiceId + ""
  ) {
    return null;
  }

  if (!detailsResponse) {
    return <Spinner />;
  }

  return <PaymentDone response={detailsResponse} paymentError={paymentError} />;
}
