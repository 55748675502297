import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { ContainerBaseProps } from ".";
import { breakpoints, devices } from "../utils/breakpoints";
import { remCalc } from "../utils/fonts";

type StyleContainerProps = Omit<ContainerBaseProps, "goBackTo" | "showGoBackButton" | "onGoBackClick">;

export const StyledContainer = styled.div<StyleContainerProps>`
  --padding_size_inline: ${({ paddingSize = "md" }) =>
    paddingSize === "lg" ? "3rem" : paddingSize === "sm" ? ".5rem" : "2rem"};
  --padding_size_inline_mobile: ${({ paddingSize = "md" }) =>
    paddingSize === "lg" ? "1.5rem" : paddingSize === "sm" ? ".5rem" : "1rem"};
  --padding_size_block: ${({ paddingSize = "md" }) =>
    paddingSize === "lg" ? "1.5rem" : paddingSize === "sm" ? ".5rem" : "1rem"};
  display: ${({ display }) => display ?? "block"};
  max-width: ${(props) => (props.maxWidth === "full" ? "initial" : breakpoints[props.maxWidth ?? "xxl"])}px;
  flex-grow: ${(props) => props.flexGrow};
  flex-basis: ${(props) => props.flexBasis};
  flex-shrink: ${(props) => props.flexShrink};
  margin: 0;
  ${({ width }) => {
    if (width === "unset") return;
    return css`
      width: 100%;
    `;
  }}
  ${(props) => {
    const horizontalPosition = props.horizontalPosition ?? "left";
    if (horizontalPosition === "left") {
      return css`
        margin-right: auto;
      `;
    }
    if (horizontalPosition === "right") {
      return css`
        margin-left: auto;
      `;
    }
    if (horizontalPosition === "center") {
      return css`
        margin-right: auto;
        margin-left: auto;
      `;
    }
  }}
  padding-left: ${({ padding }) =>
    padding === true || padding === "horizontal" || padding === "left" ? css`var(--padding_size_inline)` : 0};
  padding-right: ${({ padding }) =>
    padding === true || padding === "horizontal" || padding === "right" ? css`var(--padding_size_inline)` : 0};
  padding-top: ${({ padding }) =>
    padding === true || padding === "vertical" || padding === "top" ? css`var(--padding_size_block)` : 0};
  padding-bottom: ${({ padding }) =>
    padding === true || padding === "vertical" || padding === "bottom" ? css`var(--padding_size_block)` : 0};

  @media ${devices.sm_down} {
    padding-left: ${({ padding }) =>
      padding === true || padding === "horizontal" || padding === "left" ? css`var(--padding_size_inline_mobile)` : 0};
    padding-right: ${({ padding }) =>
      padding === true || padding === "horizontal" || padding === "right" ? css`var(--padding_size_inline_mobile)` : 0};
    padding-top: ${({ padding }) =>
      padding === true || padding === "vertical" || padding === "top" ? css`var(--padding_size_block)` : 0};
    padding-bottom: ${({ padding }) =>
      padding === true || padding === "vertical" || padding === "bottom" ? css`var(--padding_size_block)` : 0};
  }
  ${(props) => {
    if (props.backgroundColor) {
      return css`
        background-color: ${props.backgroundColor};
      `;
    }
  }}
  ${(props) => {
    if (props.floatContent) {
      return css`
        display: flex;
        flex-direction: column;
      `;
    }
  }}

  ${(props) => {
    if (props.justifyContent) {
      return css`
        display: flex;
        flex-direction: column;
        justify-content: ${props.justifyContent};
      `;
    }
  }}

  ${(props) => {
    if (props.alignItems) {
      return css`
        display: flex;
        align-items: ${props.alignItems};
      `;
    }
  }}

  ${(props) => {
    if (props.flexDirection === "column") {
      return css`
        display: flex;
        flex-direction: column;
      `;
    }
    if (props.flexDirection === "row") {
      return css`
        display: flex;
        flex-direction: row;
      `;
    }
    if (props.flexDirection === "columnReverse") {
      return css`
        display: flex;
        flex-direction: column-reverse;
      `;
    }
    if (props.flexDirection === "rowReverse") {
      return css`
        display: flex;
        flex-direction: row-reverse;
      `;
    }
  }}

${(props) => {
    if (props.gap === "lg") {
      return css`
        display: flex;
        gap: ${remCalc(26)};
        @media ${devices.sm_down} {
          gap: ${remCalc(16)};
        }
      `;
    }
    if (props.gap === "sm") {
      return css`
        display: flex;
        gap: ${remCalc(4)};
      `;
    }
    if (props.gap) {
      return css`
        display: flex;
        gap: ${remCalc(10)};
      `;
    }
  }}

${(props) => {
    if (props.flexWrap) {
      return css`
        flex-wrap: wrap;
      `;
    }
  }}

  ${(props) => {
    if (props.floatContent === "center") {
      return css`
        align-items: center;
      `;
    }
    if (props.floatContent === "left") {
      return css`
        align-items: flex-start;
      `;
    }
    if (props.floatContent === "right") {
      return css`
        align-items: flex-end;
      `;
    }
  }}

  ${(props) => {
    if (props.isFocusBox) {
      return css`
        padding: 4rem 8% 3.5rem;
        border: 8px solid ${colors.corePurple700};
        @media ${devices.sm_down} {
          padding: 2rem 20px;
        }
      `;
    }
    if (props.isFocusBoxSmall) {
      return css`
        padding: 2rem 1rem;
        border: 8px solid ${colors.corePurple700};
        @media ${devices.sm_down} {
          padding: 2rem 20px;
        }
      `;
    }
  }}
`;
