import { types } from "@telia-no-min-side/utils";

export function getFixedAgreements(accountsWithFlexAgreements?: types.fixed.AllUsersRequest<types.fixed.FlexCurrent>) {
  const flexCustomerIds = accountsWithFlexAgreements
    ? Object.entries(accountsWithFlexAgreements).flatMap(([customerId, flexCurrent]) =>
        flexCurrent.currentTvAndBroadband ? [customerId] : []
      )
    : [];

  return {
    flexCustomerIds,
  };
}
