import { Text, Box } from "@telia/teddy";
import {
  List,
  ListItem,
  Collapse,
  useToggleWithGaTracking,
  useAriaId,
  ShoppingCard,
  CollapseButton,
  Button,
  Container,
} from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";
import { TabContent } from "../../styles/page-styles";
import { getSimplePriceText } from "util/priceFormatter";
import { InfoText, InfoTitle, SpecGrid, SpecWrapper } from "./style";
import { BoxProps } from ".";
import { config } from "util/config";

export function TeliaBox(props: BoxProps): JSX.Element {
  const { product, isSelected, onSelectClick, shouldDisableSelect } = props;
  const { isOpen, toggleIsOpen } = useToggleWithGaTracking(false, {
    onOpenTracking: {
      ui_item_action: "dropdown-click",
      ui_item_context: "buy-telia-box",
      ui_item_text: "Se spesifikasjoner",
      ui_item_type: "dropdown",
      ui_item_url: "no_url",
    },
  });
  const labelId = useAriaId();
  const collapseId = useAriaId();
  const { currentPrice, postCampaignPrice, campaignDuration } = product.price;
  const isCampaign = postCampaignPrice.recurring > currentPrice.recurring;

  const onceOffText = currentPrice.onceOff ? `Etableringspris: ${currentPrice.onceOff},-` : "";

  return (
    <TabContent maxWidth="sm">
      <ShoppingCard
        title={product.name ? `${product.name}` : "Telia Box"}
        additionalTagText="TV- og strømmeboks stappfull av dine favoritter"
        image={product.image}
        price={getSimplePriceText(currentPrice.recurring, currentPrice.chargePeriod) || "Finner ikke pris."}
        postCampaignPrice={isCampaign ? getSimplePriceText(postCampaignPrice.recurring) : undefined}
        campaignText={campaignDuration}
        isSelected={isSelected}
      >
        <ShoppingCard.Body>
          <Box>
            <Box mt="300" mb="200">
              <List spacing="small" style={{ marginTop: "16px" }}>
                <ListItem center icon="check-mark" iconColor={colors.black}>
                  Forhåndsinstallerte apper Netflix, {config.showTvCompensation ? "" : "TV 2"} Play, Max, Viaplay m.m.
                </ListItem>
                <ListItem center icon="check-mark" iconColor={colors.black}>
                  Tilgang til Google Play
                </ListItem>
                <ListItem center icon="check-mark" iconColor={colors.black}>
                  Innebygd Chromecast for iOS og Android
                </ListItem>
              </List>
            </Box>
            <Text mb="300" variant="additional-100">
              {onceOffText}
            </Text>
            <SpecWrapper>
              <CollapseButton isOpen={isOpen} id={labelId} ariaControls={collapseId} toggleIsOpen={toggleIsOpen}>
                Se spesifikasjoner
              </CollapseButton>

              <Collapse ariaLabelledby={labelId} id={collapseId} isOpen={isOpen} className="collapse">
                <SpecGrid>
                  <InfoTitle>Lyd og bilde</InfoTitle>
                  <InfoText>
                    Mulighet for 4K oppløsning <br /> HDR (High Dynamic Range)
                  </InfoText>
                  <InfoTitle>Fjernkontroll</InfoTitle>
                  <InfoText>
                    Fjernkontroll med Bluetooth <br /> Stemmestyring og Google assistant
                  </InfoText>
                  <InfoTitle>Opptak*</InfoTitle>
                  <InfoText>
                    500 timer opptak <br /> Ubegrenset antall samtidige opptak <br /> Skylagring
                    <br /> *Forutsetter at du allerede har eller kjøper Opptak
                  </InfoText>
                  <InfoTitle>Tilkobling</InfoTitle>
                  <InfoText>
                    HDMI 2.0 <br /> Lyd ut (optisk/analog) <br /> 1 Gb LAN port med IPv6-støtte
                    <br /> RF in (coaxial) <br /> Innebygd Chromecast
                  </InfoText>
                  <InfoTitle>Annet</InfoTitle>
                  <InfoText>
                    Android TV <br /> 2 GB RAM <br /> 120 x 120 x 50 mm
                  </InfoText>
                </SpecGrid>
              </Collapse>
            </SpecWrapper>
          </Box>
        </ShoppingCard.Body>
        {onSelectClick && (
          <ShoppingCard.Footer>
            <Container floatContent="right" padding="top">
              <Button
                disabled={shouldDisableSelect}
                variant={isSelected ? "primary" : "secondary"}
                size="sm"
                onClick={onSelectClick}
              >
                {isSelected ? "Valgt" : "Velg"}
              </Button>
            </Container>
          </ShoppingCard.Footer>
        )}
      </ShoppingCard>
    </TabContent>
  );
}
