import React, { ComponentProps, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { colors, Lozenge } from "@telia/styleguide";
import { v4 as uuid } from "uuid";
import { useWindowSize } from "../hooks";
import { Wrapper, Clip, SVGWrapper, SVG, Circle, ChildWrapper, LozengeWrapper, StyledLozenge } from "./style";

export type GaugeMeterProps = {
  /**
   * number between 0 and 1
   * @default 0.3
   */
  cutoffPercentage?: number;
  /**
   * @default 10
   */
  strokeWidth?: number;
  className?: string;
  isLoading?: boolean;
  isPulsing?: boolean;

  // number between 0 and 1
  percentage?: number;
  children?: ReactNode;
  disabled?: boolean;
  lozenge?: Omit<ComponentProps<typeof Lozenge>, "type" | "className">;
} & (
  | {
      isLoading: true;
    }
  | {
      percentage: number;
    }
);

type GetValueInsideExtremesArgs = {
  value: number;
  min: number;
  max: number;
};

function getValueInsideExtremes({ value, min, max }: GetValueInsideExtremesArgs) {
  if (value > max) return max;
  if (value < min) return min;
  return value;
}

const MIN_SIZE = 50;
const PADDING = 2;

export function GaugeMeter({
  percentage = 0,
  strokeWidth = 10,
  cutoffPercentage = 0.3,
  isLoading = false,
  children,
  isPulsing,
  className,
  lozenge,
  disabled,
}: GaugeMeterProps): JSX.Element {
  const percentageInsideExtremes = getValueInsideExtremes({
    value: disabled ? 0 : percentage,
    max: 1,
    min: 0,
  });
  const cutoffPercentageInsideExtremes = getValueInsideExtremes({
    value: cutoffPercentage,
    max: 1,
    min: 0,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const id = useRef(uuid());
  const prevColoredArea = useRef(0);
  const [currentColoredArea, setCurrentColoredArea] = useState(0);
  const windowSize = useWindowSize();
  const [clientWidth, setClientWidth] = useState(0);

  const size = clientWidth <= 0 ? MIN_SIZE : clientWidth;
  const dynamicStrokeWidth = Math.floor(size / 30 + strokeWidth);
  const centerX = size / 2;
  const centerY = centerX;
  const radius = Math.floor(centerX - (size * dynamicStrokeWidth) / 100 / 2) - PADDING;
  const circumference = 2 * Math.PI * radius;
  const cutoffCircumference = circumference * (1 - cutoffPercentageInsideExtremes);
  const coloredArea = cutoffCircumference * percentageInsideExtremes;
  const heightCutoffCircle = radius * (1 - Math.cos((circumference * cutoffPercentageInsideExtremes) / radius / 2));
  const heightPercentageCutoffCircle = heightCutoffCircle / size;
  const clipHight = clientWidth * (1 - heightPercentageCutoffCircle);

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      setClientWidth(Math.floor(wrapperRef.current.clientWidth));
    }
  }, [wrapperRef, windowSize.width]);

  useEffect(() => {
    if (clientWidth > 0) {
      setCurrentColoredArea(coloredArea);
    }
  }, [coloredArea]);

  useEffect(() => {
    if (clientWidth > 0) {
      prevColoredArea.current = currentColoredArea;
    }
  }, [currentColoredArea]);

  return (
    <Wrapper className={className}>
      <Clip clipHight={clipHight}>
        <SVGWrapper ref={wrapperRef}>
          <SVG
            aria-describedby={id.current}
            rotate={`${90 + (360 * cutoffPercentageInsideExtremes) / 2}deg`}
            width="100%"
            height="100%"
            viewBox={`0 0 ${size} ${size}`}
          >
            <Circle
              cx={centerX}
              cy={centerY}
              r={radius}
              strokeWidth={dynamicStrokeWidth > 0 ? dynamicStrokeWidth : 0}
              color={colors.grey300}
              coloredArea={cutoffCircumference}
              prevColoredArea={cutoffCircumference}
              circumference={circumference}
              isLoading={isLoading}
              isPulsing={isPulsing}
            />
            {!isLoading && !disabled && (
              <Circle
                animate={circumference > 0}
                color={colors.corePurple800}
                cx={centerX}
                cy={centerY}
                r={radius}
                strokeWidth={dynamicStrokeWidth > 0 ? dynamicStrokeWidth : 0}
                coloredArea={coloredArea}
                prevColoredArea={prevColoredArea.current}
                circumference={circumference}
                isPulsing={isPulsing}
              />
            )}
          </SVG>
        </SVGWrapper>
      </Clip>
      <ChildWrapper
        strokeWidth={dynamicStrokeWidth > 0 ? dynamicStrokeWidth + PADDING : 0}
        cutoffPercentage={cutoffPercentageInsideExtremes}
        id={id.current}
        radius={radius - PADDING}
      >
        {children}
      </ChildWrapper>
      {lozenge ? (
        <LozengeWrapper
          strokeWidth={dynamicStrokeWidth > 0 ? dynamicStrokeWidth + PADDING : 0}
          cutoffPercentage={cutoffPercentageInsideExtremes}
        >
          <StyledLozenge {...lozenge} type="square" />
        </LozengeWrapper>
      ) : null}
    </Wrapper>
  );
}
