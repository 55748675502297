import dayjs from "dayjs";
import { format, types } from "@telia-no-min-side/utils";
import {
  useFetchBroadbandProducts,
  useFetchBroadbandSubscription,
  useFetchFwaActiveOffers,
  useFetchFwaExpiringOffers,
  useFetchProductsAdditionalInfo,
  useFetchTvSubscription,
  useFetchUser,
} from "src/api/fixed";
import { useFetchFwaPortfolio } from "src/api/fixed/useFetchFwaPortfolio";
import { useFetchFwaPricingInfo } from "src/api/fixed/useFetchFwaPricingInfo";

export type FwaOffer = {
  address?: string;
  subscriptionOffer?: {
    description: string;
    priceWithoutDiscount: number;
    priceWithDiscount: number;
  };
  routerOffer?: {
    description: string;
    priceWithoutDiscount: number;
    priceWithDiscount: number;
  };
  totalOffer: {
    priceWithoutDiscount: number;
    priceWithDiscount: number;
    discount: number;
  };
  bindingDate: string;
};

function getOffersByType(
  pricingInfo: types.fixed.OffersPricingInfo | undefined,
  broadbandProducts: types.fixed.Product[] | undefined,
  broadbandAdditionalProducts: types.fixed.Product[] | undefined,
  tvProducts: types.fixed.Product[] | undefined
) {
  const broadbandCpids = (broadbandProducts || []).map((product) => product.cpid);
  const broadbandProductsCpids = (broadbandAdditionalProducts || []).map((product) => product.cpid);
  const tvCpids = (tvProducts || []).map((product) => product.cpid);

  const broadbandOffer = (pricingInfo || []).find((offer) => broadbandCpids.includes(offer.commercialProductId));
  const bbRouterOffer = (pricingInfo || []).find((offer) => broadbandProductsCpids.includes(offer.commercialProductId));
  const tvOffer = (pricingInfo || []).find((offer) => tvCpids.includes(offer.commercialProductId));

  return { broadbandOffer, bbRouterOffer, tvOffer };
}

function isUserEligibleForFwaOffers(
  selectedUserId: string | undefined,
  productsAdditionalInfo: types.fixed.ProductsAdditionalInfo | undefined,
  portfolioType: string | undefined
) {
  const isFwaCustomer = productsAdditionalInfo?.isFwaCustomer;
  const isNewFwaLineup = (portfolioType || "").toLowerCase() === "new";

  const userEligibleForFwaOffers = selectedUserId && isFwaCustomer && isNewFwaLineup;

  return userEligibleForFwaOffers;
}

function getSubscriptionOffer(
  broadbandOffer: types.fixed.OffersPricingInfo[0] | undefined,
  tvOffer: types.fixed.OffersPricingInfo[0] | undefined,
  broadbandProducts: types.fixed.Product[] | undefined
) {
  const bbSubscriptionOffer =
    broadbandOffer && tvOffer
      ? {
          description: "Trådløst bredbånd med Telia Play",
          priceWithoutDiscount: broadbandOffer.priceWithoutOffer + tvOffer.priceWithoutOffer,
          priceWithDiscount: broadbandOffer.priceWithOffer + tvOffer.priceWithOffer,
        }
      : undefined;

  const currentBbSubscription = broadbandProducts?.[0];
  const bbSubscriptionWithoutOffer = currentBbSubscription
    ? {
        description: currentBbSubscription?.displayName || "",
        priceWithoutDiscount: currentBbSubscription.price?.amount || 0,
        priceWithDiscount: currentBbSubscription.price?.amount || 0,
      }
    : undefined;

  return bbSubscriptionOffer ? bbSubscriptionOffer : bbSubscriptionWithoutOffer;
}

function getRouterOffer(bbRouterOffer: types.fixed.OffersPricingInfo[0] | undefined) {
  const routerOffer = bbRouterOffer
    ? {
        description: bbRouterOffer?.title || "",
        priceWithoutDiscount: bbRouterOffer.priceWithoutOffer,
        priceWithDiscount: bbRouterOffer.priceWithOffer,
      }
    : undefined;

  return routerOffer;
}

function getTotalOffer(
  subscriptionOffer: ReturnType<typeof getSubscriptionOffer>,
  routerOffer: ReturnType<typeof getRouterOffer>
) {
  const totalWithDiscount = (subscriptionOffer?.priceWithDiscount || 0) + (routerOffer?.priceWithDiscount || 0);
  const totalWithoutDiscount =
    (subscriptionOffer?.priceWithoutDiscount || 0) + (routerOffer?.priceWithoutDiscount || 0);

  const totalOffer = {
    priceWithoutDiscount: totalWithoutDiscount,
    priceWithDiscount: totalWithDiscount,
    discount: totalWithoutDiscount - totalWithDiscount,
  };

  return totalOffer;
}

function getUserEligibleFor(
  selectedUserId: string | undefined,
  activeOffers: types.fixed.OffersActive | undefined,
  expiringOffers: types.fixed.OffersExpiring | undefined
) {
  const activeFwaOffers = (activeOffers || []).find((offers) => `${offers?.customerId}` === selectedUserId)?.offers;

  const expiringFwaOffers = (expiringOffers || []).find((offers) => `${offers?.customerId}` === selectedUserId)?.offers;

  const hasActiveFwaOffers = !!activeFwaOffers?.length;
  const hasExpiringFwaOffers = !!expiringFwaOffers?.length;

  const userEligibleForRecommitment = hasActiveFwaOffers && hasExpiringFwaOffers;
  const userEligibleForNewCommitment = !hasActiveFwaOffers && !hasExpiringFwaOffers;

  return {
    userEligibleForRecommitment,
    userEligibleForNewCommitment,
  };
}

function getExistingFwaOfferEndDate(
  selectedUserId: string | undefined,
  commercialProductId: number | undefined,
  expiringOffers: types.fixed.OffersExpiring | undefined
) {
  const expiringFwaOffers = (expiringOffers || []).find((offers) => `${offers?.customerId}` === selectedUserId)?.offers;
  const expiringFwaOffer = expiringFwaOffers?.find(
    (offer) => offer.applicableCommercialProductsIds?.includes(commercialProductId || -1)
  );
  const currentBindingDate = expiringFwaOffer?.endDate;

  return currentBindingDate;
}

function getActivatedOfferBindingDate(
  selectedUserId: string | undefined,
  commercialProductId: number | undefined,
  activeOffers: types.fixed.OffersActive | undefined
) {
  const activeFwaOffers = (activeOffers || []).find((offers) => `${offers?.customerId}` === selectedUserId)?.offers;
  const activeFwaOffer = activeFwaOffers?.find(
    (offer) => offer.applicableCommercialProductsIds?.includes(commercialProductId || -1)
  );
  const currentBindingDate = activeFwaOffer?.endDate;

  return currentBindingDate;
}

export function useFwaCommitment() {
  const userData = useFetchUser();
  const broadbandSubscription = useFetchBroadbandSubscription();
  const broadbandProducts = useFetchBroadbandProducts();
  const tvSubscription = useFetchTvSubscription();
  const productsAdditionalInfo = useFetchProductsAdditionalInfo();
  const fwaActive = useFetchFwaActiveOffers();
  const fwaExpiring = useFetchFwaExpiringOffers();
  const pricingInfo = useFetchFwaPricingInfo();
  const portfolioType = useFetchFwaPortfolio();

  const isValidating =
    userData.isValidating ||
    broadbandSubscription.isValidating ||
    broadbandProducts.isValidating ||
    tvSubscription.isValidating ||
    productsAdditionalInfo.isValidating ||
    fwaActive.isValidating ||
    fwaExpiring.isValidating ||
    pricingInfo.isValidating ||
    portfolioType.isValidating;

  const isLoading =
    userData.isLoading ||
    broadbandSubscription.isLoading ||
    broadbandProducts.isLoading ||
    tvSubscription.isLoading ||
    productsAdditionalInfo.isLoading ||
    fwaActive.isLoading ||
    fwaExpiring.isLoading ||
    pricingInfo.isLoading ||
    portfolioType.isLoading;

  const isError =
    userData.isError ||
    broadbandSubscription.isError ||
    broadbandProducts.isError ||
    tvSubscription.isError ||
    productsAdditionalInfo.isError ||
    fwaActive.isError ||
    fwaExpiring.isError ||
    pricingInfo.isError ||
    portfolioType.isError;

  function refetch() {
    userData.mutate();
    broadbandSubscription.mutate();
    broadbandProducts.mutateBroadbandProducts();
    tvSubscription.mutate();
    productsAdditionalInfo.mutate();
    fwaActive.mutate();
    fwaExpiring.mutate();
    pricingInfo.mutate();
    portfolioType.mutate();
  }

  const selectedUser = (userData?.data?.usersWithCustomerId || []).find((user) => user.selected);
  const selectedUserId = selectedUser?.customerId;
  const userAddress = format.startCase(selectedUser?.street || "");

  const userEligibleForFwaOffers = isUserEligibleForFwaOffers(
    selectedUserId,
    productsAdditionalInfo?.data,
    portfolioType?.data
  );

  if (!userEligibleForFwaOffers || !broadbandSubscription?.data?.products || !pricingInfo.data) {
    return {
      extensionFwaOffer: undefined,
      newFwaOffer: undefined,
      activatedOfferBindingDate: undefined,
      isValidating,
      isLoading,
      isError,
      refetch,
    };
  }

  const { userEligibleForRecommitment, userEligibleForNewCommitment } = getUserEligibleFor(
    selectedUserId,
    fwaActive.offersByCustomer,
    fwaExpiring.offersByCustomer
  );

  const { broadbandOffer, bbRouterOffer, tvOffer } = getOffersByType(
    pricingInfo.data,
    broadbandSubscription?.data?.products,
    broadbandProducts?.broadbandProducts?.products,
    tvSubscription?.data?.products
  );

  const subscriptionOffer = getSubscriptionOffer(broadbandOffer, tvOffer, broadbandSubscription?.data?.products);
  const routerOffer = getRouterOffer(bbRouterOffer);
  const totalOffer = getTotalOffer(subscriptionOffer, routerOffer);
  const existingFwaOfferEndDate = getExistingFwaOfferEndDate(
    selectedUserId,
    broadbandOffer?.commercialProductId,
    fwaExpiring.offersByCustomer
  );

  const extensionFwaOffer: FwaOffer | undefined =
    userEligibleForRecommitment && routerOffer
      ? {
          address: userAddress,
          subscriptionOffer,
          routerOffer,
          totalOffer,
          bindingDate: dayjs(existingFwaOfferEndDate).add(1, "year").format("DD.MM.YY"),
        }
      : undefined;

  const newFwaOffer: FwaOffer | undefined =
    userEligibleForNewCommitment && routerOffer
      ? {
          address: userAddress,
          subscriptionOffer,
          routerOffer,
          totalOffer,
          bindingDate: dayjs().add(1, "year").format("DD.MM.YY"),
        }
      : undefined;

  const activatedOfferBindingDate = getActivatedOfferBindingDate(
    selectedUserId,
    broadbandOffer?.commercialProductId,
    fwaActive.offersByCustomer
  );

  return {
    extensionFwaOffer,
    newFwaOffer,
    activatedOfferBindingDate: activatedOfferBindingDate
      ? dayjs(activatedOfferBindingDate).format("DD.MM.YY")
      : undefined,
    isValidating,
    isLoading,
    isError,
    refetch,
  };
}
