import { gql, useQuery } from "@apollo/client";
import { SafeWebshopUrlQueryVariables, SafeWebshopUrlQuery } from "src/gql/graphql";
export const webshopSwitchPath = "/shop/api/switch?d=";

/* 
INSTRUCIONS:
imei:               choosen product user imei number
msisdn:             choosen product user phonenumber
orderer_msisdn:     legal user on ban/account
ban:                account id
ts: Date.now(),     time
case_number:        insurance claim number
missing:            lost or stolen phone
initiatedBy:        channel "minside"
keep:               svitsj and keep phone
*/

export const initialDataToEncrypt: {
  imei: string;
  msisdn: string;
  orderer_msisdn: string;
  ban: string;
  ts: number;
  case_number: string | null;
  missing: boolean;
  initiatedBy: string;
  keep: boolean;
} = {
  imei: "",
  msisdn: "",
  orderer_msisdn: "",
  ban: "",
  ts: Date.now(),
  case_number: null,
  missing: false,
  initiatedBy: "minside",
  keep: false,
};

const SAFE_WEBSHOP_URL = gql`
  query safeWebshopUrl($input: SafeWebshopUrlInput) {
    safeWebshopUrl(input: $input)
  }
`;

export const useSafeWebshopUrl = (input: SafeWebshopUrlQueryVariables["input"]) => {
  const { loading, data, error } = useQuery<SafeWebshopUrlQuery, SafeWebshopUrlQueryVariables>(SAFE_WEBSHOP_URL, {
    variables: {
      input,
    },
  });

  return {
    loading,
    error,
    data,
  };
};
