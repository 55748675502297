import React from "react";
import { ToggleButton } from "@telia/styleguide";
import { ContentWrapper } from "../../../modules/LayoutComponents/ContentWrapper";
import { generalUtilities } from "../../../util/GeneralUtilities";
import { RowSpaceBetween } from "../../../modules/LayoutComponents/RowSpaced";

const GeneralInfoText = ({ children }) => (
  <div className={generalUtilities.isMobile() ? "font-size-20-bold" : "font-size-24-bold"}>{children}</div>
);

const GeneralInfoValue = ({ children }) => (
  <div className={generalUtilities.isMobile() ? "font-size-20" : "font-size-24"}>{children}</div>
);

export const GeneralInfo = ({ onBridgeModeChange, homeCentral }) => (
  <ContentWrapper>
    <RowSpaceBetween>
      <GeneralInfoText>Modell:</GeneralInfoText> <GeneralInfoValue>{homeCentral.ModelDescription}</GeneralInfoValue>
    </RowSpaceBetween>
    <RowSpaceBetween>
      <div style={{ display: "flex", alignItems: "center" }}>
        <GeneralInfoText>Global IP:</GeneralInfoText>
      </div>
      <GeneralInfoValue>{homeCentral.IPAddress.split(".").join(".")}</GeneralInfoValue>
    </RowSpaceBetween>
    <RowSpaceBetween>
      <div style={{ display: "flex", alignItems: "center" }}>
        <GeneralInfoText>Mac-adresse:</GeneralInfoText>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <GeneralInfoValue>{homeCentral.MacAddress1}</GeneralInfoValue>
      </div>
    </RowSpaceBetween>
    {homeCentral.SupportedSettings.BridgeMode.Supported && (
      <RowSpaceBetween>
        <GeneralInfoText>Bro-Modus</GeneralInfoText>
        <ToggleButton
          id="wifi-toggler"
          labelText={"AV/PÅ"}
          onChange={onBridgeModeChange}
          checked={homeCentral.Settings.BridgeMode.Enabled}
        />
      </RowSpaceBetween>
    )}
  </ContentWrapper>
);
