import { types } from "@telia-no-min-side/utils";

type ShippingMethod = "POSTAL" | "POSTAL_FREE" | "HOME_DELIVERY";

const shippingMethodId = {
  POSTAL: 2,
  POSTAL_FREE: 7,
  HOME_DELIVERY: 4,
};

function getShippingByShippingMethod(
  shippingMethod: ShippingMethod,
  productShipping?: types.fixed.ProductGroupShipping[]
) {
  return productShipping?.find((product) => product.shippingMethodId === shippingMethodId[shippingMethod]);
}

export function getProductShipping(
  productShipping?: types.fixed.ProductGroupShipping[]
): types.fixed.ProductGroupShipping | undefined {
  const productPostal = getShippingByShippingMethod("POSTAL", productShipping);

  if (productPostal) {
    return productPostal;
  }
  const productHome = getShippingByShippingMethod("HOME_DELIVERY", productShipping);

  if (productHome) {
    return productHome;
  }

  return getShippingByShippingMethod("POSTAL_FREE", productShipping);
}
