import { PropsWithChildren, FC, createContext, useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { format } from "@telia-no-min-side/utils";
import { OrderAddressInput, OrderAddressType, Address, UserRole } from "src/gql/graphql";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { graphql } from "src/gql";
import { hasPoboxAddress } from "util/mobile/address";

export const GET_USER_ADDRESS = graphql(`
  query getUserAddress($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      roles
      legalOwner {
        individual {
          firstName
          surname
          emailAddress
          address {
            urbanAddress {
              streetName
              streetNr
              streetNrSuffix
              city
              postcode
              careOfName
              floorNumber
              doorNumber
              locality
            }
            poboxAddress {
              city
              postcode
              boxName
              boxNr
            }
          }
        }
      }
      productUser {
        firstName
        surname
        emailAddress
        address {
          urbanAddress {
            streetName
            streetNr
            streetNrSuffix
            city
            postcode
            careOfName
            floorNumber
            doorNumber
            locality
          }
          poboxAddress {
            city
            postcode
            boxName
            boxNr
          }
        }
      }
    }
  }
`);

function toOderAddress(userAddress?: Address): OrderAddressInput {
  if (hasPoboxAddress(userAddress ?? {})) {
    const poboxAddress = userAddress?.poboxAddress;
    return {
      addressType: OrderAddressType.Pobox,
      boxNr: poboxAddress?.boxNr,
      boxName: poboxAddress?.boxName,
      postcode: poboxAddress?.postcode || "",
      city: poboxAddress?.city || "",
      iso3countryCode: "NOR",
    };
  }

  const urbanAddress = userAddress?.urbanAddress;

  return {
    addressType: OrderAddressType.Urban,
    streetName: urbanAddress?.streetName,
    streetNr: urbanAddress?.streetNr,
    streetNrSuffix: urbanAddress?.streetNrSuffix,
    floorNumber: urbanAddress?.floorNumber,
    doorNumber: urbanAddress?.doorNumber,
    careOfName: urbanAddress?.careOfName,
    postcode: urbanAddress?.postcode || "",
    city: urbanAddress?.city || "",
    iso3countryCode: "NOR",
  };
}

export function isValidDeliveryAddress(deliveryAddress?: OrderAddressInput) {
  if (deliveryAddress?.addressType === OrderAddressType.Pobox) {
    return (
      deliveryAddress.boxNr &&
      deliveryAddress.boxName &&
      deliveryAddress.postcode &&
      deliveryAddress.city &&
      deliveryAddress.iso3countryCode
    );
  }

  if (deliveryAddress?.addressType === OrderAddressType.Urban) {
    return (
      deliveryAddress.streetName &&
      deliveryAddress.streetNr &&
      deliveryAddress.postcode &&
      deliveryAddress.city &&
      deliveryAddress.iso3countryCode
    );
  }

  return false;
}

export enum SimCheckoutView {
  Cart = "CART",
  ChangeAddress = "CHANGE_ADDRESS",
}

type SimCheckoutContextType = {
  checkoutView: SimCheckoutView;
  setCheckoutView: (view: SimCheckoutView) => void;
  addressLoading: boolean;
  userFullName?: string;
  deliveryAddress?: OrderAddressInput;
  setDeliveryAddress: (address: OrderAddressInput) => void;
  userEmail: string;
  setUserEmail: (email: string) => void;
  simDescription: string;
  setSimDescription: (email: string) => void;
};

const SimCheckoutContext = createContext<SimCheckoutContextType>({
  checkoutView: SimCheckoutView.Cart,
  setCheckoutView: () => {},
  addressLoading: false,
  userFullName: undefined,
  deliveryAddress: undefined,
  setDeliveryAddress: () => {},
  userEmail: "",
  setUserEmail: () => {},
  simDescription: "",
  setSimDescription: () => {},
});

export const SimCheckoutContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [checkoutView, setCheckoutView] = useState(SimCheckoutView.Cart);
  const [userFullName, setUserFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [simDescription, setSimDescription] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState<OrderAddressInput>({
    addressType: OrderAddressType.Urban,
    postcode: "",
    city: "",
    iso3countryCode: "NO",
  });

  const { phoneNumber } = useMobileAccount();
  const { loading } = useQuery(GET_USER_ADDRESS, {
    variables: { phoneNumber },
    onCompleted: (data) => {
      const subscription = data?.subscription;
      const isLegalOwner = !!subscription?.roles?.includes(UserRole.LegalOwner);
      const user = isLegalOwner ? subscription?.legalOwner?.individual : subscription?.productUser;

      const userFullName = format.getFullName(user);
      const defaultUserAddress = user?.address || undefined;
      const userEmail = subscription?.productUser?.emailAddress || "";

      setDeliveryAddress(toOderAddress(defaultUserAddress));
      setUserFullName(userFullName);
      setUserEmail(userEmail);
    },
  });

  return (
    <SimCheckoutContext.Provider
      value={{
        checkoutView,
        setCheckoutView,
        userFullName,
        deliveryAddress,
        addressLoading: loading,
        setDeliveryAddress,
        userEmail,
        setUserEmail,
        simDescription,
        setSimDescription,
      }}
    >
      {children}
    </SimCheckoutContext.Provider>
  );
};

export const useSimCheckout = (): SimCheckoutContextType => useContext(SimCheckoutContext);
