import cloneDeep from "lodash.clonedeep";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useRouterSettings } from "../../../stores/RouterSettingsProvider";
import { portForwardingUtilities, portForwardingValidation } from "../../../util/PortForwardingUtilities";
import { ipv4StringableOctets } from "../../../util/PropTypes";
import { PortforwardingRuleDisplay } from "../PortForwardingRuleDisplay";
import { ExpandablePortForwardingRule } from "./ExpandablePortForwardingRule";
import { PortForwardingRuleEditable } from "./PortForwardingRuleEditable";

import "./PortForwardingRule.less";

const { shape, number, func, oneOfType, string, bool, arrayOf } = PropTypes;

export const PortForwardingRule = (props) => {
  const { routerSettings } = useRouterSettings();
  const { updateStatus, updateResponse, activeUnit } = routerSettings;
  const { forwardingRule, externalPorts, onCancelClick, onSaveClick, onDelete, preExisting } = props;
  const mask = activeUnit.Settings.Lan.NetworkMask;
  const [forwardingRuleCopy, setForwardingRuleCopy] = useState(cloneDeep(forwardingRule));

  const [errorMessages, setErrorMessages] = useState([]);
  const [errorMessagesWRTOtherRules, setErrorMessagesWRTOtherRules] = useState([]);
  const [showEditMode, setShowEditMode] = useState(!preExisting);

  const tag = preExisting ? "portforwarding-update-existing-rule" : "portforwarding-write-new-rule";
  const supportsPortRange = activeUnit.SupportedSettings.PortForwarding.PortRangeSupported;
  const gateway = activeUnit.Settings.Lan.InternalGWIP;
  const prevTagRef = useRef();

  useEffect(() => {
    let timer = 0;

    if (prevTagRef.current !== updateResponse.tag && prevTagRef.current === tag && !updateResponse.error) {
      if (preExisting) {
        if (showEditMode) {
          timer = setTimeout(() => {
            setShowEditMode(false);
          }, 2000);
        }
      } else {
        timer = setTimeout(() => {
          onCancelClick();
        }, 2000);
      }
    }
    return () => clearTimeout(timer);
  }, [updateResponse, tag]);

  const handleInput = (value, param) => {
    const updatedRule = portForwardingUtilities.setNewValuesForRule(
      forwardingRuleCopy,
      param,
      value,
      supportsPortRange
    );

    if (errorMessages.length > 0 || errorMessagesWRTOtherRules.length > 0) {
      const newErrorMessages = portForwardingValidation.validatePortForwardingRules(
        [updatedRule],
        mask,
        gateway,
        supportsPortRange
      );
      const newErrorMessagesWRTOtherRules = portForwardingValidation.validatePortForwardingRuleWRTOtherRules(
        updatedRule,
        supportsPortRange,
        externalPorts
      );

      setErrorMessages(newErrorMessages);
      setErrorMessagesWRTOtherRules(newErrorMessagesWRTOtherRules);
    }
    setForwardingRuleCopy(updatedRule);
  };

  const setEnabledFlag = () => {
    const newState = cloneDeep(forwardingRuleCopy);

    newState.Enabled = !newState.Enabled;
    setForwardingRuleCopy(newState);
  };

  const areAllFieldsOfPortforwardingRulePopulated = (forwardingRule) => {
    const values = [];

    Object.keys(forwardingRule).forEach((key) => {
      if (key === "InternalIP") {
        Object.values(forwardingRule[key]).forEach((val) => values.push(val));
      } else {
        values.push(forwardingRule[key]);
      }
    });
    return values.every((val) => val !== "");
  };

  const validatePortForwardingRule = () => {
    if (areAllFieldsOfPortforwardingRulePopulated(forwardingRuleCopy)) {
      const newState = forwardingRuleCopy;
      const newErrorMessages = portForwardingValidation.validatePortForwardingRules(
        [newState],
        mask,
        gateway,
        supportsPortRange
      );

      if (errorMessagesWRTOtherRules.length > 0) {
        setErrorMessagesWRTOtherRules(
          portForwardingValidation.validatePortForwardingRuleWRTOtherRules(newState, supportsPortRange, externalPorts)
        );
      }
      setForwardingRuleCopy(newState);
      setErrorMessages(newErrorMessages);
    }
  };

  const handleSaveClick = () => {
    const newErrorMessagesWRTOtherRules = portForwardingValidation.validatePortForwardingRuleWRTOtherRules(
      forwardingRuleCopy,
      supportsPortRange,
      externalPorts
    );
    const newErrorMessages = portForwardingValidation.validatePortForwardingRules(
      [forwardingRuleCopy],
      mask,
      gateway,
      supportsPortRange
    );

    setErrorMessagesWRTOtherRules(newErrorMessagesWRTOtherRules);
    setErrorMessages(newErrorMessages);

    if (!(newErrorMessagesWRTOtherRules.length > 0 || newErrorMessages.length > 0)) {
      onSaveClick(forwardingRuleCopy);
    }
  };
  const closePromptOrChangeDisplayMode = () => {
    if (preExisting) {
      setShowEditMode(false);
    } else {
      onCancelClick();
    }
  };

  if (preExisting) {
    return (
      <div className="port-forwarding-rule-outer-container">
        <PortforwardingRuleDisplay
          externalPortFrom={forwardingRule.ExternalFromPort}
          externalPortTo={forwardingRule.ExternalToPort}
          internalPortFrom={forwardingRule.InternalFromPort}
          internalPortTo={forwardingRule.InternalToPort}
          ipAdress={forwardingRule.InternalIP}
          type={forwardingRule.Protocol}
          enabled={forwardingRule.Enabled}
          name={forwardingRule.Name}
          onChangeClick={() => setShowEditMode(!showEditMode)}
          onDeleteClick={onDelete}
        />

        <ExpandablePortForwardingRule show={showEditMode}>
          <PortForwardingRuleEditable
            tag={tag}
            updateResponse={updateResponse}
            updateStatus={updateStatus}
            supportsPortRange={supportsPortRange}
            errorMessages={errorMessages}
            errorMessagesWRTOtherRules={errorMessagesWRTOtherRules}
            forwardingRule={forwardingRuleCopy}
            handleInput={handleInput}
            mask={mask}
            onClose={closePromptOrChangeDisplayMode}
            onSave={handleSaveClick}
            preExisting={preExisting}
            setEnabledFlag={setEnabledFlag}
            validate={validatePortForwardingRule}
          />
        </ExpandablePortForwardingRule>
      </div>
    );
  } else {
    return (
      <PortForwardingRuleEditable
        tag={tag}
        updateResponse={updateResponse}
        updateStatus={updateStatus}
        supportsPortRange={supportsPortRange}
        errorMessages={errorMessages}
        errorMessagesWRTOtherRules={errorMessagesWRTOtherRules}
        forwardingRule={forwardingRuleCopy}
        handleInput={handleInput}
        mask={mask}
        onClose={closePromptOrChangeDisplayMode}
        onSave={handleSaveClick}
        preExisting={preExisting}
        setEnabledFlag={setEnabledFlag}
        validate={validatePortForwardingRule}
      />
    );
  }
};

PortForwardingRule.propTypes = {
  forwardingRule: shape({
    Name: oneOfType([number, string]),
    Protocol: string,
    ExternalFromPort: oneOfType([number, string]),
    ExternalToPort: oneOfType([number, string]),
    InternalFromPort: oneOfType([number, string]),
    InternalToPort: oneOfType([number, string]),
    InternalIP: ipv4StringableOctets,
  }).isRequired,
  onSaveClick: func.isRequired,
  onCancelClick: func,
  preExisting: bool,
  onDelete: func,
  externalPorts: arrayOf(
    shape({
      ExternalFromPort: number,
      ExternalToPort: number,
    })
  ).isRequired,
};
