import React from "react";
import { Container } from "../Container";
import { SkeletonListItem } from "./SkeletonListItem";

type Props = {
  count?: number;
};

export function SkeletonList({ count }: Props) {
  return (
    <Container flexDirection="column" gap="lg">
      {[...Array(count || 1)].map((_, i) => (
        <SkeletonListItem key={`skeleton-list-item-${i}`} />
      ))}
    </Container>
  );
}
