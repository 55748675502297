import React, { useEffect, useRef, ReactNode } from "react";
import { CardProps } from "../Card";
import { HorizontalScrollScrollBy } from "../HorizontalScroll";
import { scrollElementToCenter } from "../HorizontalScroll/utils";
import { ToggleButtonSkeleton } from "./ToggleButtonSkeleton";
import { SecondaryText, SkeletonLine, StyledToggleButton } from "./style";

ToggleButton.Skeleton = ToggleButtonSkeleton;

export type ToggleButtonOnChange<T> = (event: React.BaseSyntheticEvent, newValue?: T) => void;

export type ToggleButtonProps<T> = {
  /**
   * The main text to display
   */
  text: string | ReactNode;
  /**
   * Additional text to display
   */
  secondaryText?: string;
  /**
   * If set to `true`, the component is rendered as active.
   */
  isSelected?: boolean;
  /**
   * The value you want to associate with the button
   * This value is used by ToggleButtonGroup to handle selected button.
   */
  value: T;
  /**
   * function fired when button is clicked.
   *
   */
  onClick?: ToggleButtonOnChange<T>;
  /**
   * Selectable variant
   *
   * @default "primary"
   */
  variant?: "primary" | "profile-picker";
  /**
   * Remove left and right margin
   */
  removeLeftMargin?: boolean;
  removeRightMargin?: boolean;
  /**
   * Callback function fired when the value changes.
   *
   * @param {React.BaseSyntheticEvent} event
   */
  onChange?: ToggleButtonOnChange<T>;

  fadeFromSkeleton?: boolean;
  id?: string;
  dataTrackingID?: string;
  scrollBy?: HorizontalScrollScrollBy;
} & Pick<CardProps<"button">, "showShadow" | "className" | "borderColor" | "lozengeRight">;

/**
 * @deprecated Use Teddy components instead
 */
export function ToggleButton<T>(props: ToggleButtonProps<T>) {
  const {
    text,
    secondaryText,
    isSelected = false,
    onClick,
    onChange,
    removeLeftMargin = false,
    removeRightMargin = false,
    value,
    fadeFromSkeleton = false,
    id,
    dataTrackingID,
    scrollBy,
    variant = "primary",
    ...cardProps
  } = props;

  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (isSelected && elementRef.current && scrollBy) {
      scrollElementToCenter(elementRef.current, scrollBy);
    }
  }, [isSelected, elementRef.current]);

  function handleChange(event: React.BaseSyntheticEvent) {
    if (onClick) {
      onClick(event, value);
      if (event.defaultPrevented) {
        return;
      }
    }

    if (onChange) {
      onChange(event, value);
    }
  }

  return (
    <StyledToggleButton
      ref={elementRef}
      variant={variant}
      isSelected={isSelected}
      aria-pressed={isSelected}
      onClick={handleChange}
      removeLeftMargin={removeLeftMargin || variant !== "profile-picker"}
      removeRightMargin={removeRightMargin || variant !== "profile-picker"}
      fadeFromSkeleton={fadeFromSkeleton}
      hideIsSelectedLozenge
      hideIsSelectedRing
      tag={"button"}
      removeMinHeight
      borderColor={variant !== "profile-picker" ? "purple" : undefined}
      id={id}
      dataTrackingID={dataTrackingID}
      data-dd-action-name="click on toggle button"
      data-di-mask
      {...cardProps}
    >
      {text}
      {secondaryText && <SecondaryText id={id}>{secondaryText}</SecondaryText>}
      {fadeFromSkeleton && <SkeletonLine fadeOut />}
    </StyledToggleButton>
  );
}
