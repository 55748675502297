import { useQuery } from "@apollo/client";
import { graphql } from "src/gql";
import { GdprRequest } from "src/gql/graphql";

export const GET_PARTY_STATE = graphql(`
  query gdprPartyState($input: GDPRInput) {
    gdprPartyState(input: $input) {
      purposeCode
      context
    }
  }
`);

export const useGetPartyState = () => {
  const { data, loading, refetch, error } = useQuery(GET_PARTY_STATE, {
    variables: {
      input: {
        email: "",
        name: "",
        phoneNumber: "",
        operation: GdprRequest.GetPartyState,
      },
    },
    fetchPolicy: "network-only",
  });

  return { data, loading, refetch, error };
};
