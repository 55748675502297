import React, { ReactNode } from "react";
import { Heading } from "../Heading";
import { List as ListComponent } from "../List";
import { Section } from "./style";

type ListProps = {
  title?: string;
  children: ReactNode;
};

export function List(props: ListProps) {
  const { title, children } = props;
  return (
    <Section>
      <Heading variant="title-100" tag="h3">
        {title}
      </Heading>
      <ListComponent>{children}</ListComponent>
    </Section>
  );
}
