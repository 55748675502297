import { Container } from "@telia-no-min-side/components";
import { Error } from "../components/Error";

export function GDPRErrorPage() {
  return (
    <Container padding flexDirection="column" gap="lg">
      <Error />
    </Container>
  );
}
