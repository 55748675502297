import { types, track } from "@telia-no-min-side/utils";
import { Container } from "@telia-no-min-side/components";
import { Flex, Button } from "@telia/teddy";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { useFlexOrder } from "pages/flex/context/FlexOrderProvider";

type Props = {
  isCustomersCurrentProduct: boolean;
  showShoppingCartHandler: () => void;
};

export function PackageOptionButtons({ isCustomersCurrentProduct, showShoppingCartHandler }: Props) {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { resetFlexProductAndStartStep1, flexProductOffer, setSelectedTvProductId, setSelectedBroadbandProductId } =
    useFlexOrder();

  const handleProductSelection = (product: types.fixed.TvPointsAndBroadbandPrice | null | undefined) => {
    setSelectedTvProductId(product?.tvOption?.price.commercialProductId);
    setSelectedBroadbandProductId(product?.broadbandOption?.price.commercialProductId);
  };

  return (
    <Container maxWidth="sm">
      <Flex justify="end" gap="200" mt="200">
        <Button
          variant="secondary"
          onClick={() => {
            pushGenericTrackingEvent({
              ui_item_action: TRACK_EVENT.ITEM_ACTION.SELECT,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FLEX,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.CHANGE_PACKAGE,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
            });
            resetFlexProductAndStartStep1();
          }}
        >
          Velg en annen pakke
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            showShoppingCartHandler();
            pushGenericTrackingEvent({
              ui_item_action: `select (bb:${flexProductOffer?.broadbandOption?.downloadSpeed}, tv:${flexProductOffer?.tvOption?.points}, pris: ${flexProductOffer?.sum.currentAmount}/${flexProductOffer?.sum.chargePeriod})`,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FLEX,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.SELECT_THIS_COMBINATION,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
            });
            handleProductSelection(flexProductOffer);
          }}
          disabled={isCustomersCurrentProduct}
        >
          {isCustomersCurrentProduct ? "Du har denne pakken" : "Velg denne kombinasjonen"}
        </Button>
      </Flex>
    </Container>
  );
}
