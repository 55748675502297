import { useState } from "react";
import { uri } from "@telia-no-min-side/utils";
import { Badge, Card, Icon, Text, Link, Flex } from "@telia/teddy";
import { isPricePlanMobileBroadband } from "src/util/format-subscription/isPricePlanMobileBroadband";
import { UserOverviewDataQuery, UserRole } from "src/gql/graphql";
import { isSubscriptionOfType, useSubscriptionOverview } from "../hooks/useSubscriptionOverview";
import { OfferGroup } from "pages/mobile/mobile-subscription-change/types";
import { useMobileAccount } from "hooks/useMobileAccount";
import { Link as RouterLink } from "react-router-dom";

const getOfferTypeForAccount = (account: SubscriptionOverviewDataAccount): OfferGroup => {
  return (account.subscriptions || []).filter((sub) => isSubscriptionOfType(sub, "MOBILE")).length > 0
    ? "junior"
    : "unlimitedData";
};

type SubscriptionOverviewDataAccount = NonNullable<UserOverviewDataQuery["accounts"]>[0];

export function UpSellMobileSub() {
  const { setAccountId } = useMobileAccount();
  const subscriptionOverview = useSubscriptionOverview();
  const [showSelectAccount, setShowSelectAccount] = useState(false);

  if (!subscriptionOverview.legalOwnerAccounts.length) return null;

  if (showSelectAccount) {
    return (
      <Card bordered shadow variant="white" layout="product" width="100%" maxWidth="400px" justify="center">
        <Card.Slot align="top-right" onClick={() => setShowSelectAccount(false)}>
          <Icon name="close" size="sm" cursor="pointer" />
        </Card.Slot>
        <Flex direction="column" gap="100">
          <Text variant="paragraph-100">Velg hvilken konto det gjelder:</Text>

          <Flex direction="column" gap="100">
            {subscriptionOverview.legalOwnerAccounts.map((account) => {
              const showBadge = (account.subscriptions || []).some(
                (sub) => !isPricePlanMobileBroadband(sub.userOffering?.pricePlan)
              );

              return (
                account && (
                  <Link
                    key={`${account.id}-select-wifi-address`}
                    variant="navigation"
                    href={uri.minSideFixed(`/mobil/legg-til/${account.id}/${getOfferTypeForAccount(account)}`)}
                    data-di-mask
                  >
                    <Card
                      bordered
                      shadow
                      variant="white"
                      layout="default"
                      onClick={() => setAccountId(legalOwnerAccount.id)}
                      width="100%"
                      maxWidth="400px"
                      justify="center"
                    >
                      {showBadge && (
                        <Card.Slot align="top-right">
                          <Badge variant="special">
                            <Icon name="savings" />
                            Med rabatt!
                          </Badge>
                        </Card.Slot>
                      )}
                      <Card.Content>
                        <Flex direction="row" align="center" gap="150">
                          <Icon name="end-user" size="sm" />
                          <Text>Konto: {account.id}</Text>
                        </Flex>
                      </Card.Content>
                    </Card>
                  </Link>
                )
              );
            })}
          </Flex>
        </Flex>
      </Card>
    );
  }

  const hasToPickAccountId = subscriptionOverview.legalOwnerAccounts.length > 1;

  const isLegalOwnerOfAnyMobileSubscription = subscriptionOverview.mobileSubscriptionsOverview.some((subscription) =>
    subscription.account.roles.includes(UserRole.LegalOwner)
  );

  const offerMessage = isLegalOwnerOfAnyMobileSubscription
    ? "Kjøp nytt abonnement fra 99,-/md."
    : "Kjøp abonnement med ubegrenset data fra 399,-/md.";

  if (hasToPickAccountId) {
    return (
      <Card
        bordered
        shadow
        variant="white"
        layout="navigation-horizontal-small-centered"
        width="100%"
        maxWidth="400px"
        onClick={() => setShowSelectAccount(true)}
      >
        {isLegalOwnerOfAnyMobileSubscription && (
          <Card.Slot align="top-right">
            <Badge variant="special">
              <Icon name="savings" />
              Med familierabatt!
            </Badge>
          </Card.Slot>
        )}

        <Card.Button variant="tertiary-purple" mt="0" mb="0">
          <Icon name="plus" size="sm" />
          {offerMessage}
        </Card.Button>
      </Card>
    );
  }

  const legalOwnerAccount = subscriptionOverview.legalOwnerAccounts[0];

  return (
    <Card
      bordered
      shadow
      variant="white"
      layout="navigation-vertical"
      width="100%"
      maxWidth="400px"
      justify="center"
      align="center"
      onClick={() => setAccountId(legalOwnerAccount.id)}
      asChild
    >
      <Flex>
        {isLegalOwnerOfAnyMobileSubscription && (
          <Card.Slot align="top-right">
            <Badge variant="special">
              <Icon name="savings" />
              Med familierabatt!
            </Badge>
          </Card.Slot>
        )}
        <Card.Content mb="0">
          <Card.Button variant="tertiary-purple" asChild>
            <RouterLink
              to={uri.minSideFixed(
                `/mobil/legg-til/${legalOwnerAccount.id}/${getOfferTypeForAccount(legalOwnerAccount)}`
              )}
            >
              <Icon name="plus" size="sm" />
              {offerMessage}
            </RouterLink>
          </Card.Button>
        </Card.Content>
      </Flex>
    </Card>
  );
}
