import { graphql } from "src/gql";

export const GET_ACCOUNT_AGREEMENTS = graphql(`
  query getAccountAgreementsNEW($accountId: String!) {
    account(accountId: $accountId) {
      id
      roles
      legalOwner {
        individual {
          firstName
        }
      }
      switchAgreements(loadInsuranceInfo: true) {
        id
        status
        imei
        phoneNumber {
          localNumber
        }
        effectiveDate
        expirationDate
        insurance {
          code
          type
        }
        pricePerInstallment {
          value
        }
        canPerformSwitch
        remainingMandatoryInstallments
        product {
          id
          name
          brand
          productNumber
          imageUrl
        }
      }

      insuranceAgreements {
        serviceAgreementId
        effectiveDate
        product
        presentation {
          modifiable
          active
        }
        productLevel
        serviceAgreementId
        productTerms {
          name
          value
        }
        price {
          value
        }
        longDescription
        terms
        shortName
      }

      leaseAgreements {
        leases {
          id
          phoneNumber {
            localNumber
          }
          product
          model
          effectiveDate
          expirationDate
        }
      }
    }
  }
`);
