import React, { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { colors } from "@telia/styleguide";
import styled, { css, keyframes } from "styled-components";
import { Card } from "../Card";
import { Icon } from "../Icon";
import { Paragraph } from "../Paragraph";
import { Toast, useToast } from ".";

type ToastProps = {
  index: number;
} & Toast;

const DEFAULT_AUTO_DELETE_TIME = 4500;
const LOADING_DELETE_TIME = 60000;
const ANIMATE_OUT_TIME = 750;
const TOAST_SPACER = "1rem";

export function ToastElement({ text, variant, id, index, animateOutAndRemove, dataTrackingId }: ToastProps) {
  const { removeToast } = useToast();
  const toasterRef = useRef<HTMLDivElement | null>(null);
  const startTimeRef = useRef(dayjs());
  const hoverStartTimeRef = useRef<dayjs.Dayjs>(dayjs());
  const removeTimer = useRef<ReturnType<typeof setTimeout>>();
  const animateOutTimer = useRef<ReturnType<typeof setTimeout>>();
  const [timeToRemove, setTimeToRemoval] = useState(
    variant === "loading" ? LOADING_DELETE_TIME : DEFAULT_AUTO_DELETE_TIME
  );
  const [height, setHeight] = useState("0");

  function animateOut(el: HTMLDivElement) {
    el.style.opacity = "0";
    el.style.zIndex = "-10";
    el.style.transform = "translateY(-50%) scale(0.5)";
  }

  useEffect(() => {
    removeTimer.current = setTimeout(() => {
      removeToast(id);
    }, timeToRemove);

    animateOutTimer.current = setTimeout(() => {
      if (!toasterRef.current) return;
      setHeight("0");
      animateOut(toasterRef.current);
    }, timeToRemove - ANIMATE_OUT_TIME);

    return () => {
      clearTimeout(removeTimer.current);
      clearTimeout(animateOutTimer.current);
    };
  }, [id, timeToRemove]);

  useEffect(() => {
    if (animateOutAndRemove) {
      setTimeToRemoval(ANIMATE_OUT_TIME);
    }
  }, [animateOutAndRemove]);

  useEffect(() => {
    if (!toasterRef.current) return;
    setHeight(`calc(${toasterRef.current.getBoundingClientRect().height}px + ${TOAST_SPACER})`);
  }, []);

  return (
    <Wrapper style={{ height, zIndex: -index }} kind={variant} data-tracking-id={dataTrackingId}>
      <InnerCard
        showShadow
        ref={toasterRef}
        removeMinHeight
        onMouseEnter={() => {
          const now = dayjs();
          hoverStartTimeRef.current = now;
          clearTimeout(removeTimer.current);
          clearTimeout(animateOutTimer.current);
        }}
        onMouseLeave={() => {
          const startTime = startTimeRef.current;
          const hoverStart: Dayjs = hoverStartTimeRef.current;
          setTimeToRemoval((prevTime) => {
            const timeFromHoverStarted = hoverStart.diff(startTime, "ms");
            const now = dayjs();
            startTimeRef.current = now;
            const newTime = prevTime - timeFromHoverStarted;
            return newTime < ANIMATE_OUT_TIME ? ANIMATE_OUT_TIME : prevTime - timeFromHoverStarted;
          });
        }}
      >
        {variant && (
          <AnimatedIcon
            variant={variant}
            spin={variant === "loading"}
            icon={
              variant === "success"
                ? "check-circle-filled"
                : variant === "error"
                ? "close-circle"
                : variant === "loading"
                ? "refresh"
                : "alert-filled"
            }
          />
        )}
        <Paragraph removeMargin>{text}</Paragraph>
      </InnerCard>
    </Wrapper>
  );
}

const SlideIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const BounceIn = keyframes`
from {
    transform: scale(0);
    opacity: 0;
}
35% {
    transform: scale(0);
}
85% {
    opacity: 1;
    transform: scale(1.1);
}
to {
    transform: scale(1.0);
  }
`;

const Wrapper = styled.div<{ kind: Toast["variant"] }>`
  max-width: 100vw;
  animation: ${SlideIn} ease 0.3s;
  transition: height ${ANIMATE_OUT_TIME}ms ease;
  overflow: visible;
  display: grid;
  place-items: center;
  place-content: center;
  margin-inline: auto;
`;

const InnerCard = styled(Card)`
  animation: ${SlideIn} ease 0.3s;
  margin-bottom: 1rem;
  transition:
    transform ${ANIMATE_OUT_TIME}ms ease,
    opacity ${ANIMATE_OUT_TIME - 300}ms ease 300ms;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 0.8;
  pointer-events: all;
`;

const AnimatedIcon = styled(Icon)<Pick<ToastProps, "variant">>`
  flex: 0 0 auto;
  color: ${({ variant }) =>
    variant === "success"
      ? colors.green600
      : variant === "error"
      ? colors.red600
      : variant === "warning"
      ? colors.orange500
      : undefined};
  ${(props) => {
    if (props.variant !== "loading") {
      return css`
        animation: ${BounceIn} ${ANIMATE_OUT_TIME}ms ease;
      `;
    }
  }}
`;
