import { graphql } from "src/gql";

export const SWITCH_AGREEMENT_FRAGMENT = graphql(`
  fragment SwitchAgreementFields on SwitchAgreement {
    id
    status
    imei
    phoneNumber {
      localNumber
    }
    effectiveDate
    expirationDate
    switchRejectionReason
    insurance {
      shortDescription
      code
      longDescription
      claimUrl
      effectiveDate
      expirationDate
      insuranceCase {
        caseStatus
      }
      longDescription
      name
      presentation {
        active
        modifiable
      }
      price {
        value
        priceWithDiscount
      }
      shortDescription
      type
    }
    remainingCost {
      value
    }
    totalCost {
      value
    }
    pricePerInstallment {
      value
    }
    canPerformSwitch
    remainingMandatoryInstallments
    product {
      id
      name
      productNumber
      imageUrl
    }
    costToReplace {
      value
    }
  }
`);
