import { QueryResult } from "@apollo/client";
import { useState, useEffect } from "react";
import { ConfirmPaymentStatus, GetMobileBroadbandSubscriptionDataQuery } from "src/gql/graphql";
import { useMutationResponse } from "hooks/useMutationResponse";
import { useAdditionalProducts } from "hooks/useAdditionalProducts";
import { useConfirmPayment } from "pages/mobile/mobile-subscription-dashboard/hooks/useConfirmPayment";

export function useMobileBroadbandDataUsage(
  dataUsageQuery: QueryResult<GetMobileBroadbandSubscriptionDataQuery, { phoneNumber: string }>
) {
  const { loading, data, startPolling, stopPolling } = dataUsageQuery;

  const subscription = data?.subscription;
  const dataUsageString = JSON.stringify(subscription?.usage);

  const [isPolling, setIsPolling] = useState(false);
  const [purchaseError, setPurchaseError] = useState("");
  const [isUsageFetchedBefore, setIsUsageFetchedBefore] = useState(false);

  const isLoading = loading || isPolling;

  const { response: confirmPaymentResponse } = useConfirmPayment();
  const { orderResponse } = useAdditionalProducts();
  const { setMutationResponseMessage } = useMutationResponse();

  const handleOrderSuccess = (poll = true) => {
    setPurchaseError("");
    setMutationResponseMessage((prev) => ({ ...prev, isSuccessfulMutation: true }));
    if (poll) {
      startPolling(5000);
      setIsPolling(true);
    }
  };

  useEffect(() => {
    // If redirectUrl is returned as a part of order
    // it means that order is not finished yet and requires additional step
    if (orderResponse?.data?.order?.redirectUrl) {
      window.location.replace(orderResponse?.data?.order?.redirectUrl);
    }

    if (orderResponse?.called && !orderResponse.error && !orderResponse?.loading) {
      handleOrderSuccess();
      orderResponse.reset();
    }

    if (orderResponse?.error?.message) {
      setMutationResponseMessage((prev) => ({ ...prev, isSuccessfulMutation: false }));
      setPurchaseError(orderResponse.error.message || "Unknown error");
      orderResponse.reset();
    }
  }, [orderResponse]);

  useEffect(() => {
    const paymentResult = confirmPaymentResponse.data?.confirmPayment?.result;
    if (paymentResult === ConfirmPaymentStatus.Ok) {
      handleOrderSuccess(false);
      return;
    }

    if (paymentResult === ConfirmPaymentStatus.Failed || confirmPaymentResponse.error) {
      setMutationResponseMessage((prev) => ({ ...prev, isSuccessfulMutation: false }));
      setPurchaseError(confirmPaymentResponse.error?.message || "Unknown error");
    }
  }, [confirmPaymentResponse.data, confirmPaymentResponse.error]);

  // Stops polling when usage is updated
  useEffect(() => {
    // In case when user gets redirected from
    // a 3rd party payment page, theres no data to compare
    // usage to, so this will stop polling only for internal b2c
    // payments and 3rd party must call handleOrderSuccess({ poll: false })
    if (isUsageFetchedBefore) {
      stopPolling();
      setIsPolling(false);
    }
    setIsUsageFetchedBefore(!!dataUsageString);
  }, [dataUsageString, stopPolling, isUsageFetchedBefore]);

  return { isLoading, subscription, purchaseError, setPurchaseError };
}
