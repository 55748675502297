import { Flex, Skeleton, Grid } from "@telia/teddy";

export function Loading() {
  return (
    <Flex direction="column" gap="gutter-sm">
      <Skeleton height="25px" width="70px" />

      <Grid columns={{ xxs: "1", md: "2" }} gap="gutter-xl">
        <Skeleton height="275px" width="100%" />
        <Skeleton height="275px" width="100%" />
        <Skeleton height="275px" width="100%" />
        <Skeleton height="275px" width="100%" />
      </Grid>
    </Flex>
  );
}
