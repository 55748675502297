import { colors } from "@telia/styleguide";
import styled, { css, keyframes } from "styled-components";
import { devices } from "../utils/breakpoints";
import { remCalc } from "../utils/fonts";
import { BaseProps } from ".";

const BORDER_WIDTH = "1px";

const transitionProperties = css`
  transition-property: color, background-color, border-color;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
`;

function getSize(size: BaseProps["size"] = "md") {
  if (size === "sm") {
    return remCalc(40);
  }
  if (size === "md") {
    return remCalc(48);
  }
  if (size === "lg") {
    return remCalc(56);
  }

  if (size === "unset") {
    return css`
      height: "unset";
    `;
  }
}

function getPadding(size: BaseProps["size"] = "md", withBorder = false) {
  if (withBorder && size === "sm") {
    return `calc(${remCalc(12)} - ${BORDER_WIDTH})
        calc(${remCalc(20)} - ${BORDER_WIDTH})`;
  }
  if (withBorder && size === "md") {
    return `calc(${remCalc(16)} - ${BORDER_WIDTH})
        calc(${remCalc(24)} - ${BORDER_WIDTH})`;
  }
  if (withBorder && size === "lg") {
    return `calc(${remCalc(20)} - ${BORDER_WIDTH})
        calc(${remCalc(28)} - ${BORDER_WIDTH})`;
  }
  if (size === "sm") {
    return `${remCalc(12)} ${remCalc(20)}`;
  }
  if (size === "md") {
    return `${remCalc(16)} ${remCalc(24)}`;
  }
  if (size === "lg") {
    return `${remCalc(20)} ${remCalc(28)}`;
  }
}

type StyledButtonProps = Pick<
  BaseProps,
  "variant" | "size" | "floatRight" | "fullWidth" | "isIconButton" | "whiteSpace" | "alignSelf" | "fullWidthSmallScreen"
>;

export const StyledButton = styled.button<StyledButtonProps>`
  text-transform: none;
  text-decoration: none;
  font-size: ${remCalc(16)};
  white-space: ${({ whiteSpace }) => whiteSpace};
  font-family: inherit;
  font-weight: 500;
  border-radius: 99rem;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  letter-spacing: ${remCalc(0.4)};
  line-height: 1.3;
  padding: ${remCalc(14)} ${remCalc(24)};
  position: relative;
  ${transitionProperties}
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  gap: ${remCalc(8)};
  height: ${(props) => getSize(props.size)};
  border: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  outline: 0;

  &:focus::before {
    border-radius: 99.9rem;
    box-shadow: 0 0 0 ${remCalc(2)} ${colors.blue500};
    inset: -${remCalc(2)};
    content: "";
    display: block;
    position: absolute;
  }
  &:focus:not(:focus-visible)::before {
    box-shadow: none;
  }
  &:disabled,
  &[aria-disabled="true"] {
    cursor: not-allowed;
  }

  ${(props) =>
    props.fullWidthSmallScreen &&
    css`
      @media ${devices.xs_down} {
        width: 100%;
      }
    `}
  ${(props) =>
    props.floatRight &&
    css`
      float: right;
    `}

  ${(props) =>
    props.alignSelf &&
    css`
      align-self: ${props.alignSelf};
    `}

  ${(props) => {
    const { variant = "secondary" } = props;
    if (variant === "primary") {
      return solidStyling(props.size, {
        defaultColor: colors.corePurple900,
        hover: colors.corePurple700,
        pressed: colors.corePurple600,
      });
    }
    if (variant === "expressive") {
      return solidStyling(props.size, {
        defaultColor: colors.corePurple500,
        hover: colors.corePurple600,
        pressed: colors.corePurple700,
      });
    }
    if (variant === "purchase") {
      return solidStyling(props.size, {
        defaultColor: colors.green700,
        hover: colors.green800,
        pressed: colors.green900,
      });
    }
    if (variant === "purchase-secondary") {
      return borderStyling(props.size, {
        defaultColor: colors.green700,
        pressed: colors.green800,
      });
    }
    if (variant === "secondary") {
      return borderStyling(props.size, {
        defaultColor: colors.corePurple900,
        pressed: colors.corePurple700,
      });
    }
    if (variant === "tertiary-black") {
      return solidStyling(props.size, {
        defaultColor: "transparent",
        hover: "#00000024",
        pressed: "#00000033",
        fontColor: colors.black,
      });
    }
    if (variant === "tertiary-purple") {
      return solidStyling(props.size, {
        defaultColor: "transparent",
        hover: colors.corePurple100,
        pressed: colors.corePurple200,
        fontColor: colors.corePurple600,
      });
    }
    if (variant === "tertiary-destructive") {
      return solidStyling(props.size, {
        defaultColor: "transparent",
        hover: colors.red100,
        pressed: colors.red200,
        fontColor: colors.red600,
      });
    }

    if (variant === "destructive") {
      return solidStyling(props.size, {
        defaultColor: colors.red500,
        hover: colors.red600,
        pressed: colors.red700,
      });
    }

    if (variant === "text-purple") {
      return textStyling(props.size, {
        defaultColor: colors.corePurple700,
        hover: colors.corePurple500,
        pressed: colors.corePurple800,
      });
    }

    if (variant === "text-black") {
      return textStyling(props.size, {
        defaultColor: colors.black,
        hover: colors.grey600,
        pressed: colors.grey800,
      });
    }
  }}
  ${(props) => {
    if (props.isIconButton) {
      return css`
        padding: 0;
        border-radius: 99rem;
        height: ${props.size === "unset" ? "unset" : getSize(props.size)};
        width: ${props.size === "unset" ? "unset" : getSize(props.size)};
      `;
    }
  }}
`;

type ButtonBorderStyling = {
  defaultColor: string;
  pressed: string;
};

const borderStyling = (
  size: BaseProps["size"],
  { defaultColor, pressed }: ButtonBorderStyling,
  addPadding?: boolean
) => {
  return css`
    background-color: transparent;
    border: ${BORDER_WIDTH} solid ${defaultColor};
    color: ${defaultColor};
    box-sizing: border-box;
    padding: ${addPadding === false ? "initial" : getPadding(size, true)};

    @media (hover: hover) {
      &:hover {
        background-color: ${defaultColor};
        color: ${colors.white};
      }
    }

    &:active {
      background-color: ${pressed};
      border-color: ${pressed};
      color: ${colors.white};
    }
    &:disabled,
    &[aria-disabled="true"] {
      background-color: ${colors.grey100};
      border-color: ${colors.grey100};
      color: ${colors.grey600};
    }
    &:focus::before {
      inset: calc(-${remCalc(2)} - ${BORDER_WIDTH});
    }
  `;
};

type ButtonSolidStyling = {
  defaultColor: string;
  hover: string;
  pressed: string;
  fontColor?: string;
};

const solidStyling = (
  size: BaseProps["size"],
  { defaultColor, hover, pressed, fontColor }: ButtonSolidStyling,
  addPadding?: boolean
) => {
  return css`
    background-color: ${defaultColor};
    color: ${fontColor ?? colors.white};
    padding: ${addPadding === false ? "initial" : getPadding(size)};
    @media (hover: hover) {
      &:hover {
        background-color: ${hover};
      }
    }
    &:active {
      background-color: ${pressed};
    }
    &:disabled,
    &[aria-disabled="true"] {
      background-color: ${colors.grey100};
      color: ${colors.grey600};
    }
  `;
};

const textStyling = (size: BaseProps["size"], { defaultColor, hover, pressed }: ButtonSolidStyling) => {
  return css`
    background-color: transparent;
    color: ${defaultColor};
    border-radius: 0;
    padding: ${getPadding(size)};
    padding-inline: 0;

    @media (hover: hover) {
      &:hover {
        color: ${hover};
      }
    }
    &:active {
      color: ${pressed};
    }
    &:disabled,
    &[aria-disabled="true"] {
      color: ${colors.grey600};
    }
    &:focus::before {
      border-radius: 0;
    }
  `;
};

const dotAnimation = keyframes`
  0% {
      opacity: .3;
  }
  100% {
    opacity: 1;
  }
`;

export const LoadingDot = styled.span`
  animation-name: ${dotAnimation};
  animation-iteration-count: infinite;
  animation-duration: 600ms;
  animation-direction: alternate;
  opacity: 0;

  :nth-of-type(2) {
    animation-delay: 300ms;
  }
  :nth-of-type(3) {
    animation-delay: 600ms;
  }
`;
export const LoadingDotsWrapper = styled.span`
  color: inherit;
`;
