import { Paragraph } from "@telia-no-min-side/components";
import { MobileSubscriptionAccordionItem } from "src/components/mobile-subscription-accordion-item";
import { FragmentType, getFragmentData } from "src/gql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { getConvergentBenefitAmount } from "src/util/format-subscription/getConvergentBenefitAmount";
import { getName } from "src/util/format-subscription/getName";
import { hasUnlimitedData } from "src/util/format-subscription/hasUnlimitedData";
import { ComponentProps } from "react";
import { getDownloadSpeed } from "util/format-subscription/getDownloadSpeed";
import { getIncludedProperties } from "util/format-subscription/getIncludedProperties";
import { getOfferingPrices } from "util/format-subscription/getOfferingPrices";
import { SpeedOrDataBenefit } from "../components/SpeedOrDataBenefit";
import { CURRENT_SUBSCRIPTION_FRAGMENT } from "../graphql/currentSubscriptionFragment";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";
import { JuniorSummerCampaignBanner } from "../components/JuniorSummerCampaignBanner";
import { JuniorSummerCampaignTitle } from "../components/JuniorSummerCampaignTitle";
import { getJuniorSummerCampaignDataForCurrentSubscription } from "./utils/getJuniorSummerCampaignData";

export function CurrentSubscriptionAccordionItem({
  offeringFragment,
  hasLineDiscount,
}: {
  offeringFragment: FragmentType<typeof CURRENT_SUBSCRIPTION_FRAGMENT>;
  hasLineDiscount: boolean;
}) {
  const subscription = getFragmentData(CURRENT_SUBSCRIPTION_FRAGMENT, offeringFragment);
  const { orders } = useOrderSubscription();
  const hasOnGoingOrder = orders.some((order) => order.phoneNumber === subscription.phoneNumber.localNumber);

  if (!subscription.userOffering) return <Paragraph removeMargin>Klarte ikke hente data</Paragraph>;

  const offeringPrices = getOfferingPrices(subscription.userOffering.offeringPrices);
  const convergentBenefit = getConvergentBenefitAmount(subscription.additionalProducts);

  const subscriptionName = getName(
    offeringPrices,
    subscription.userOffering.shortName,
    convergentBenefit?.hasDoubleData
  );
  const downloadSpeed = getDownloadSpeed(offeringPrices);
  const hasUnlimitedDataBenefit = hasUnlimitedData(subscription.userOffering.additionalProperties);
  const includedProperties = getIncludedProperties(subscription.userOffering.additionalProperties);
  const hasJuniorSummerCampaign = isJuniorSummerCampaign(subscription.userOffering.pricePlan || "");
  const { formattedBaseDataAmount, formattedTotalDataAmount } = getJuniorSummerCampaignDataForCurrentSubscription(
    offeringPrices,
    subscription.additionalProducts
  );

  const getLozenge = (): ComponentProps<typeof MobileSubscriptionAccordionItem>["lozengeRight"] => {
    if (hasOnGoingOrder) {
      return { icon: "sync", label: "Oppdateres", status: "info" };
    }

    if (hasJuniorSummerCampaign) {
      return undefined;
    }

    if (hasLineDiscount) {
      return {
        label: "Familierabatt",
        status: "communication",
      };
    }

    return undefined;
  };

  const displaySpeed = convergentBenefit?.hasDoubleSpeed ? convergentBenefit.amount : downloadSpeed;
  const overLine = (
    <SpeedOrDataBenefit
      downloadSpeed={displaySpeed}
      hasUnlimitedData={hasUnlimitedDataBenefit}
      showDownloadSpeed={!!downloadSpeed}
      removeMargin={true}
    />
  );

  const juniorSummerCampaignTitle = hasJuniorSummerCampaign ? (
    <JuniorSummerCampaignTitle baseDataAmount={formattedBaseDataAmount} totalDataAmount={formattedTotalDataAmount} />
  ) : undefined;

  return (
    <MobileSubscriptionAccordionItem
      subscriptionName={subscriptionName?.length ? subscriptionName : "Klarte ikke hente navn"}
      customSubscriptionName={juniorSummerCampaignTitle}
      priceMonthly={subscription.userOffering.monthlyCost?.amount}
      originalPriceMonthly={subscription.userOffering.originalPrice?.amount}
      overLine={overLine}
      hasDoubleData={!!convergentBenefit?.hasDoubleData}
      hasDoubleSpeed={!!convergentBenefit?.hasDoubleSpeed}
      includedProperties={includedProperties}
      lozengeRight={getLozenge()}
      dataTrackingID={`change-subscription-current-item-${(subscriptionName || "").replaceAll(" ", "_")}`}
      customPeakContent={hasJuniorSummerCampaign ? <JuniorSummerCampaignBanner /> : undefined}
    />
  );
}
