import styled from "styled-components";
import { devices } from "./../../utils/breakpoints";
import { Link } from "../../Link";

export const LogoLink = styled(Link)`
  display: grid;
  @media ${devices.sm_down} {
    place-content: center;
  }
`;

export const LinkImage = styled.img`
  height: 30px;
`;
