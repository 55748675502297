import { getSimplePriceText, uri } from "@telia-no-min-side/utils";
import { useFetchBroadbandSubscription, useFetchFlexCurrent, useFetchProductsAdditionalInfo } from "src/api/fixed";
import { useFetchTvOverFwa } from "src/store/hooks/useFetchTvOverFwa";
import { TeliaPlayCard } from "./TeliaPlayCard";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { Skeleton } from "@telia/teddy";

export function UpsellTeliaPlay() {
  const tvOverFwa = useFetchTvOverFwa();
  const flexCurrent = useFetchFlexCurrent();
  const paInfo = useFetchProductsAdditionalInfo();
  const broadbandSubscription = useFetchBroadbandSubscription();
  const hasBroadband = broadbandSubscription.data?.products && broadbandSubscription.data.products.length > 0;

  const isLoading = tvOverFwa.isLoading || flexCurrent.isLoading || paInfo.isLoading || broadbandSubscription.isLoading;

  if (isLoading) {
    return <Skeleton loading radius="md" as="span" maxWidth="60ch" height="24ch" />;
  }

  if (paInfo.data?.isSitCustomer) {
    return <TeliaPlayCard href={uri.purchase("/tv/sit")} linkText="Bestill Telia Play" isLinkExternal />;
  }

  if (tvOverFwa.selectedAccount.productWithMostDiscount) {
    return (
      <TeliaPlayCard
        href={uri.minSideFixed(ICX_REQUEST.URL_ORDER_TV)}
        linkText={tvOverFwa.selectedAccount.isCampaign ? "Se ditt tilbud" : "Bestill"}
        tagPrefix="fwa"
        badge={
          tvOverFwa.selectedAccount.isCampaign
            ? {
                variant: "information",
                text: `${tvOverFwa.selectedAccount.hasMultipleFwaProducts ? "Opp til " : ""}${getSimplePriceText(
                  tvOverFwa.selectedAccount.mostDiscountPrice,
                  tvOverFwa.selectedAccount.productWithMostDiscount?.prices.chargePeriod
                )} i rabatt!`,
              }
            : undefined
        }
      />
    );
  }

  if (flexCurrent.data?.currentTvAndBroadband) {
    return <TeliaPlayCard href={uri.minSideFixed("/flex")} linkText="Bestill Telia Play" />;
  }

  if (hasBroadband) {
    return <TeliaPlayCard href={uri.purchase("/tv/nytt")} linkText="Bestill Telia Play" isLinkExternal />;
  }

  return <TeliaPlayCard href={uri.openPages("/tv")} linkText="Sjekk om du kan få Telia Play" />;
}
