const isLocalhost = window.location.hostname.includes("localhost");

const isUrlWithProtocol = (url: string) => url.indexOf("//") > -1;

const extractHost = (url: string) => {
  if (isUrlWithProtocol(url)) {
    return url.split("/")[2].split("?")[0];
  } else {
    return url.split("/")[0].split("?")[0];
  }
};

const isUrlWithHostName = (url: string) => url.includes(".") || url.includes(":");

const isInternalLink = (url: string): boolean => {
  if (isUrlWithHostName(url) && window.location.host !== extractHost(url)) {
    return false;
  }
  return true;
};

const localhostIfExternal = (host: string) => (isInternalLink(host) ? "" : host);

const environmentSpecificUrl = (
  localhost: string,
  minSidePath: "/minside" | "/min-side" | "" | "/" | "/minside/mobil",
  path: string
) => {
  const applicationSpecificUrl = `${minSidePath}${path}`;

  if (isLocalhost && !path.includes("api")) {
    return `${localhostIfExternal(localhost)}${applicationSpecificUrl}`;
  }
  return applicationSpecificUrl;
};

const uri = {
  minSideFixed(path: string) {
    const isPathAlreadyAppSpecific = path.includes("minside");
    return environmentSpecificUrl("//localhost.telia.no:9002", isPathAlreadyAppSpecific ? "" : "/minside", path);
  },
  minSideMobile(path: string) {
    return environmentSpecificUrl("//localhost.telia.no:9002", "/minside/mobil", path);
  },
  minSideMobileLegacy(path: string) {
    return environmentSpecificUrl("//localhost.telia.no:8889", "/min-side", path);
  },
  openPages(path?: string) {
    return environmentSpecificUrl("//stage.telia.no", "", path ?? "");
  },
  purchase(path: string) {
    const isPathAlreadyAppSpecific = path.includes("minside/bestilling");
    if (isPathAlreadyAppSpecific) {
      return environmentSpecificUrl("//localhost:9003", "", path);
    }
    const appPath = path.includes("bestilling") ? path : `/bestilling${path}`;
    return environmentSpecificUrl("//localhost:9003", "/minside", appPath);
  },
  channelpicker() {
    return "https://account.teliaplay.no/subscription/selection-menu";
  },
  channelpickerOld() {
    return environmentSpecificUrl("//localhost:2001", "/minside", `/valgmenyen`);
  },
  channelpickerBackend(path: string) {
    return environmentSpecificUrl("//localhost:2001", "/minside", `/valgmenyen${path}`);
  },
};

export { isInternalLink };
export default uri;
