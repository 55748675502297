import { useMutation } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { ConfirmPaymentMutation, ConfirmPaymentMutationVariables } from "src/gql/graphql";
import { graphql } from "src/gql";

export const CONFIRM_PAYMENT = graphql(`
  mutation confirmPayment($purchaseId: String!, $provider: PaymentProvider) {
    confirmPayment(purchaseId: $purchaseId, provider: $provider) {
      orderId
      result
    }
  }
`);

export function useConfirmPayment() {
  const [confirmPayment, response] = useMutation<ConfirmPaymentMutation, ConfirmPaymentMutationVariables>(
    CONFIRM_PAYMENT
  );

  const runConfirmPayment = async ({ purchaseId, provider }: ConfirmPaymentMutationVariables) => {
    if (!purchaseId) return;

    try {
      await confirmPayment({
        variables: {
          purchaseId,
          provider,
        },
      });
    } catch (error) {
      datadogRum.addError(error, { feature: "confirmPayment" });
    }
  };

  return { response, runConfirmPayment };
}
