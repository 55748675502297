import { INTERNAL_LINK } from "util/constants/internalLinks";
import { capitalizeFirstLetter } from "util/capitalizeFirstLetter";
import { truncateText } from "pages/home/agreements/utils/truncateText";
import { AGREEMENT_TYPE, INSURANCE_TYPE } from "util/constants/agreements";
import { getInsuranceForLeaseAgreement, removeWordFromString } from "../utils";
import { LocalAgreementType } from "pages/mobile/mobile-subscription-dashboard/types";
import { GetSubscriptionAgreementsQuery, SwitchInsuranceType, GetAccountAgreementsNewQuery } from "gql/graphql";

export function useAgreements(
  account: GetAccountAgreementsNewQuery["account"] | undefined,
  subscription: GetSubscriptionAgreementsQuery["subscription"] | undefined,
  phoneNumber: string
): { agreements: LocalAgreementType[] } {
  const switchAgreements = account?.switchAgreements || subscription?.switchAgreements || [];
  const leaseAgreements = account?.leaseAgreements?.leases || subscription?.leaseAgreements?.leases || [];
  const insuranceAgreements = account?.insuranceAgreements || [];

  if (!switchAgreements.length && !leaseAgreements.length && !insuranceAgreements.length) {
    return { agreements: [] };
  }

  const agreements: LocalAgreementType[] = [];

  const getSwitchAgreementDetails = (agreements: typeof switchAgreements): LocalAgreementType[] => {
    // 🛠️ First, find account-level Switch Agreement (where `phoneNumber.localNumber` is missing)
    const accountLevelSwitchAgreements = agreements.filter((agreement) => !agreement.phoneNumber?.localNumber);

    // 🛠️ If no account-level agreements, find subscription-level agreement by `phoneNumber`
    const subscriptionLevelSwitchAgreements = agreements.filter((agr) => agr.phoneNumber?.localNumber === phoneNumber);

    // ✅ If account-level agreement exist, use it. Otherwise, fall back to subscription-level agreement.
    const relevantAgreements =
      accountLevelSwitchAgreements.length > 0 ? accountLevelSwitchAgreements : subscriptionLevelSwitchAgreements;

    return relevantAgreements.map((switchAgreement) => {
      const { canPerformSwitch, product, insurance, remainingMandatoryInstallments } = switchAgreement;
      const isImageAvailable = !!product?.imageUrl;
      const canSwitchInstantly = canPerformSwitch && remainingMandatoryInstallments <= 0;
      const insuranceType =
        insurance?.type === SwitchInsuranceType.Screen ? INSURANCE_TYPE.SCREEN : INSURANCE_TYPE.PLUS;

      const phoneBrand = product?.brand || "";
      const phoneModel = removeWordFromString(product?.name, phoneBrand);
      const truncatedPhoneModel = truncateText(phoneModel, 30);

      return {
        isImageAvailable,
        imageUrl: product?.imageUrl || "",
        agreementTitle: AGREEMENT_TYPE.SWITCH,
        phoneBrand,
        phoneModel: isImageAvailable ? truncatedPhoneModel : phoneModel,
        isInsuranceExist: true,
        insuranceType: capitalizeFirstLetter(insuranceType),
        navigateTo: INTERNAL_LINK.MOBILE.AGREEMENTS_PAGE,
        canSwitchInstantly,
      };
    });
  };

  const getLeaseAgreementDetails = (agreements: typeof leaseAgreements): LocalAgreementType[] =>
    agreements
      .filter((agreement) => agreement?.phoneNumber.localNumber === phoneNumber)
      .map((leaseAgreement) => {
        const phoneBrand = leaseAgreement?.model.split(" ")[0] || "";
        const phoneModel = removeWordFromString(leaseAgreement?.model, phoneBrand);

        const { isInsuranceExist, insuranceType } = getInsuranceForLeaseAgreement(
          insuranceAgreements,
          leaseAgreement?.model || ""
        );

        return {
          isImageAvailable: false,
          imageUrl: "",
          agreementTitle: AGREEMENT_TYPE.LEASE,
          phoneBrand,
          phoneModel,
          isInsuranceExist,
          insuranceType,
          navigateTo: INTERNAL_LINK.MOBILE.AGREEMENTS_PAGE,
          canSwitchInstantly: false,
        };
      });

  // TODO: Don't have a test user with Standalone Insurance
  // const getInsuranceAgreementDetails = (agreements: typeof leaseAgreements): AgreementType[] => {
  //   return {
  //     hasAgreement: true,
  //     isImageAvailable: false,
  //     imageUrl: "",
  //     agreementTitle: AGREEMENT_TYPE.LEASE,
  //     phoneBrand: "",
  //     phoneModel: "",
  //     isInsuranceExist: true,
  //     insuranceType: "Screen",
  //     navigateTo: INTERNAL_LINK.MOBILE.AGREEMENTS_PAGE,
  //     canSwitchInstantly: false,
  //   };
  // }

  agreements.push(...getSwitchAgreementDetails(switchAgreements));
  agreements.push(...getLeaseAgreementDetails(leaseAgreements));

  return { agreements };
}

// 🛠️ **Subscription & Agreement Types - Data from Backend**

// 📌 **Switch Agreement (Applies to both subscription-level & account-level)**
// - If a Switch Agreement exists, it **always includes** Screen Insurance by default.
// - The Plus Insurance can be purchased separately for an additional cost.
// - While the Switch Agreement is active, the insurance (Screen or Plus) **can be extended** to continue separately **after the agreement ends**.

// 📌 **Lease Agreement (Applies only to subscription-level)**
// - A Lease Agreement can be **ordered with or without insurance** (Screen or Plus).
// - If a Lease Agreement **includes insurance**, the insurance (Screen or Plus) **continues separately** after the Lease Agreement ends.
// ❗ **BUG:** When a Lease Agreement includes insurance (Screen or Plus), the insurance is **only found** under `account-level` insurance agreements.

// 📌 **Insurances (Screen or Plus) (Applies to both subscription-level & account-level)**
// - Insurances **cannot** be purchased as standalone products.
// - Insurance is **only available** when bundled with:
//   1. A **Switch Agreement**.
//   2. A **Lease Agreement**.
// - The **only case** where insurance becomes standalone is **after** a Switch or Lease Agreement has ended.
