import { DatePicker as BaseDatePicker } from "@telia/styleguide";
import React, { ComponentProps } from "react";
import { Wrapper } from "./style";

/**
 * @deprecated Use Teddy components instead
 */
export const DatePicker = (props: ComponentProps<typeof BaseDatePicker>) => {
  const label = props.label?.length && props.required ? `* ${props.label}` : props.label;

  return (
    <Wrapper>
      <BaseDatePicker {...props} label={label} />
    </Wrapper>
  );
};
