import { useFetchTvSubscription, useFetchUser } from "src/api/fixed";
import { useFetchTvOverFwa } from "src/store/hooks/useFetchTvOverFwa";
import { DashboardSection } from "../card/DashboardSection";
import { ErrorSection } from "../card/ErrorSection";
import { LoadingSubscriptionCard } from "../card/LoadingSubscriptionCard";
import { useFwaAntichurn } from "../highlights/fwa-antichurn/FwaAntichurnCard";
import { TeliaPlayCard } from "./TeliaPlayCard";
import { TvSubscriptionCard } from "./TvSubscriptionCard";
import { UpSellTvOverFwaCard } from "./UpSellTvOverFwaCard";
import { useBlueConic } from "hooks/useBlueConic";
import { BlueConicActionCard } from "../highlights/personal/BlueConicActionCard";
import { config } from "util/config";
import { BlueConicPromoList } from "../highlights/personal/BlueConicPromoList";

export function TvSubscriptions() {
  const products = useFetchTvSubscription({ fetchAllAccounts: true });
  const user = useFetchUser();
  const fwaAntichurn = useFwaAntichurn();
  const tvOverFwa = useFetchTvOverFwa();
  const { bcTv } = useBlueConic();
  const hasAvailableTvOverFwaOfferAndFwaAntichurn =
    fwaAntichurn.showFwaAntichurn && tvOverFwa.allAccounts.hasAvailableTvOverFwaOffer;

  if (products.isLoading && !products.data) {
    return (
      <DashboardSection isLoading>
        <LoadingSubscriptionCard />
      </DashboardSection>
    );
  }

  if (products.error && !products.data) {
    return (
      <ErrorSection
        onRefreshClick={products.mutate}
        isLoading={products.isValidating}
        title="TV"
        sectionText="dine TV-produkter"
      />
    );
  }
  const productsEntries = products.data && Object.entries(products.data);
  const hasTv =
    products.data &&
    Object.values(products.data).some(
      ({ products }) => products.filter((product) => product.status?.shouldDisplay).length
    );

  if (!hasTv && !hasAvailableTvOverFwaOfferAndFwaAntichurn) {
    return null;
  }

  return (
    <DashboardSection title="TV">
      {productsEntries?.map(([customerId, { products }]) => {
        const customer = user.data?.usersWithCustomerId.find(
          (userWithCustomerId) => userWithCustomerId.customerId === customerId
        );
        if (!customer) return null;
        return products
          .filter((product) => product.status?.shouldDisplay)
          .map((product) => <TvSubscriptionCard key={product.id} product={product} customer={customer} />);
      })}
      {hasTv && <TeliaPlayCard />}
      {!hasTv && hasAvailableTvOverFwaOfferAndFwaAntichurn && <UpSellTvOverFwaCard />}
      {config.isNewBlueconicEnabled ? <BlueConicPromoList type="tv" /> : <BlueConicActionCard message={bcTv} />}
    </DashboardSection>
  );
}
