import { SUMMER_CAMPAIGN_PRICE_PLANS_FOR_JUNIORS } from "./variables";

/**
 * 2024 summer campaign has two main offerings:
 * TELIA_JUNIOR_01GB+PROMO_JR06_50
 * TELIA_JUNIOR_03GB+PROMO_JR06_50
 * After the summer campaign, the price plan will be changed to the regular price plan in Fokus:
 * TELIA_JUNIOR_01GB
 * TELIA_JUNIOR_03GB
 */
export function isJuniorSummerCampaign(pricePlan?: string): boolean {
  if (!pricePlan) return false;
  const mainOffering = pricePlan.split(".")[0];
  return SUMMER_CAMPAIGN_PRICE_PLANS_FOR_JUNIORS.includes(mainOffering);
}
