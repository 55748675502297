import { track } from "@telia-no-min-side/utils";
import { useState } from "react";
import { config } from "src/util/config";
import { PayByCardButton } from "../components/PayByCardButton";
import { PaymentAmountModal } from "../components/PaymentAmountModal";
import usePayByCard from "./usePayByCard";

export function PayBalance({ accountId }: { accountId: string }) {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { payByCard, paymentLoading, accountBalance, hasMultipleUnpaidInvoices, oldestUnpaidInvoice } =
    usePayByCard(accountId);
  const { pushGenericTrackingEvent } = track.useEventTracking();

  if (!config.showPartialPay) {
    return <PayByCardButton isLoading={paymentLoading} onClick={() => payByCard()} />;
  }

  return (
    <>
      {accountBalance && (
        <PaymentAmountModal
          accountBalance={accountBalance}
          oldestUnpaidInvoice={oldestUnpaidInvoice?.amount?.value || 0}
          onConfirm={(amountToPay) => {
            if (amountToPay === oldestUnpaidInvoice?.amount?.value) {
              payByCard(amountToPay);
            } else {
              payByCard();
            }
            setIsPaymentModalOpen(false);
          }}
          setIsPaymentModalOpen={setIsPaymentModalOpen}
          isPaymentModalOpen={isPaymentModalOpen}
        />
      )}
      <PayByCardButton
        isLoading={paymentLoading}
        onClick={() => {
          if (hasMultipleUnpaidInvoices && accountBalance) {
            pushGenericTrackingEvent({
              ui_item_action: `Has to select amount to pay`,
              ui_item_context: "pay invoice",
              ui_item_url: "no_url",
              ui_item_text: "Betal nå",
              ui_item_type: "button",
            });
            return setIsPaymentModalOpen(true);
          }
          pushGenericTrackingEvent({
            ui_item_action: `Pay Outstanding Balance (Single Invoice Only)`,
            ui_item_context: "pay invoice",
            ui_item_url: "no_url",
            ui_item_text: "Betal nå",
            ui_item_type: "button",
          });
          payByCard();
        }}
      />
    </>
  );
}
