import { types } from "@telia-no-min-side/utils";

function getBroadbandOptions(
  selectedProduct: types.fixed.TvPointsAndBroadbandPrice,
  flattenTvPointsAndBroadbandPrice: types.fixed.TvPointsAndBroadbandPrice[] | null | undefined
): types.fixed.TvPointsAndBroadbandPrice[] | null {
  if (!flattenTvPointsAndBroadbandPrice) return [];
  return flattenTvPointsAndBroadbandPrice?.filter(
    (product) => product.tvOption?.points === selectedProduct.tvOption?.points
  );
}

function getTvOptions(
  selectedProduct: types.fixed.TvPointsAndBroadbandPrice,
  flattenTvPointsAndBroadbandPrice: types.fixed.TvPointsAndBroadbandPrice[] | null | undefined
): types.fixed.TvPointsAndBroadbandPrice[] | null {
  if (!flattenTvPointsAndBroadbandPrice) return [];
  return flattenTvPointsAndBroadbandPrice.filter(
    (product) => product.broadbandOption?.downloadSpeed === selectedProduct.broadbandOption?.downloadSpeed
  );
}

function getUpDowngradeProducts(
  selectedProduct: types.fixed.TvPointsAndBroadbandPrice,
  productOptions: types.fixed.TvPointsAndBroadbandPrice[]
) {
  const currentProductIndex = productOptions.findIndex(
    (productOption) => productOption.normalizedWeight === selectedProduct.normalizedWeight
  );
  const numberOfProductOptions = productOptions.length;
  const isLastOption = currentProductIndex === numberOfProductOptions;
  const isFirstOption = currentProductIndex === 0;
  const upgradeProduct = isLastOption ? null : productOptions[currentProductIndex + 1];
  const downgradeProduct = isFirstOption ? null : productOptions[currentProductIndex - 1];

  return { upgradeProduct, downgradeProduct };
}

export function getUpDowngradeBroadbandProducts(
  selectedProduct: types.fixed.TvPointsAndBroadbandPrice,
  flattenTvPointsAndBroadbandPrice: types.fixed.TvPointsAndBroadbandPrice[] | null | undefined
): {
  upgradeBroadbandProduct: types.fixed.TvPointsAndBroadbandPrice | null;
  downgradeBroadbandProduct: types.fixed.TvPointsAndBroadbandPrice | null;
} {
  const allBroadbandOptions = getBroadbandOptions(selectedProduct, flattenTvPointsAndBroadbandPrice);
  if (!allBroadbandOptions) return { upgradeBroadbandProduct: null, downgradeBroadbandProduct: null };
  const { downgradeProduct, upgradeProduct } = getUpDowngradeProducts(selectedProduct, allBroadbandOptions);
  return {
    upgradeBroadbandProduct: upgradeProduct,
    downgradeBroadbandProduct: downgradeProduct,
  };
}

export function getUpDowngradeTvProducts(
  selectedProduct: types.fixed.TvPointsAndBroadbandPrice,
  flattenTvPointsAndBroadbandPrice: types.fixed.TvPointsAndBroadbandPrice[] | null | undefined
): {
  upgradeTvProduct: types.fixed.TvPointsAndBroadbandPrice | null;
  downgradeTvProduct: types.fixed.TvPointsAndBroadbandPrice | null;
} {
  const allTvOptions = getTvOptions(selectedProduct, flattenTvPointsAndBroadbandPrice);
  if (!allTvOptions) return { upgradeTvProduct: null, downgradeTvProduct: null };
  const { downgradeProduct, upgradeProduct } = getUpDowngradeProducts(selectedProduct, allTvOptions);
  return {
    upgradeTvProduct: upgradeProduct,
    downgradeTvProduct: downgradeProduct,
  };
}
