import type { SyntheticEvent } from "react";
import { useEffect } from "react";
import { useFormHandling, useLocalStorage } from "@telia-no-min-side/components";
import { AdditionalProduct, OrderOp } from "src/gql/graphql";
import { useOrderAdditionalProducts } from "src/hooks/useAdditionalProducts";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { ExpandableCard, Notification, Flex, Heading, Badge, Button } from "@telia/teddy";
import { ServiceBox } from "components/service-box";

type Props = {
  securityDNSService: AdditionalProduct[];
  cancelSecurityDNSService: AdditionalProduct[];
};

export type FormStateField = {
  code: AdditionalProduct["code"];
  active: boolean;
};

export function SecurityDNS({ securityDNSService, cancelSecurityDNSService }: Props) {
  const { phoneNumber } = useMobileAccount();

  const activationProduct = securityDNSService?.[0];
  const deactivationProduct = cancelSecurityDNSService?.[0];

  const hasDeactivationProduct = !!deactivationProduct;
  const isActive = !!activationProduct.presentation?.active;

  const isModifiable =
    isActive && hasDeactivationProduct
      ? deactivationProduct.presentation?.modifiable
      : activationProduct.presentation?.modifiable;

  const initialState: FormStateField = {
    code: activationProduct.code,
    active: isActive,
  };

  const { state, dispatch, Actions } = useFormHandling(initialState);
  const { form, previousForm, showError, showSuccess } = state;

  const { orderResponse, runOrderAdditionalProducts } = useOrderAdditionalProducts();
  const { data, loading, error } = orderResponse;

  const { setValue: setOrderId } = useLocalStorage<string>("telia-no-minside-order-id__settings");

  useEffect(() => {
    if (data?.order?.orderId && !loading) {
      dispatch({ type: Actions.ON_SUCCESS });
      setOrderId(data.order.orderId);
    }

    if (error?.message && !loading) {
      dispatch({ type: Actions.ON_ERROR });
    }
  }, [dispatch, data, error, loading, Actions]);

  function onChangeHandler(key: string, checked: boolean) {
    dispatch({
      type: Actions.SET_FORM,
      payload: { [key]: checked },
    });
  }

  const orderSecurityDNS = (event: SyntheticEvent) => {
    event.preventDefault();
    runOrderAdditionalProducts({
      phoneNumber,
      input: {
        productName: isActive && hasDeactivationProduct ? deactivationProduct.code : activationProduct.code,
        operation: OrderOp.AddAdditionalProduct,
      },
    });
  };

  return (
    <ExpandableCard type="multiple">
      <ExpandableCard.Item value="content1">
        <ExpandableCard.Trigger
          onClick={() => {
            !loading && dispatch({ type: Actions.RESET });
          }}
        >
          <ExpandableCard.Header>
            <ExpandableCard.Description>
              <Flex direction="column" gap="100">
                <Heading as="h5" variant="title-100">
                  Nettvakt
                </Heading>
                <div>
                  {previousForm.active ? (
                    <Badge variant="success" hideIcon>
                      På
                    </Badge>
                  ) : (
                    <Badge variant="neutral">Av</Badge>
                  )}
                </div>
              </Flex>
            </ExpandableCard.Description>
            <ExpandableCard.Indicator />
          </ExpandableCard.Header>
        </ExpandableCard.Trigger>
        <ExpandableCard.Content>
          {showSuccess ? (
            <Notification variant="success">
              <Notification.Dismiss />
              <Notification.Heading as="h3">
                <Notification.Icon>Dine endringer er lagret.</Notification.Icon>
              </Notification.Heading>
              <Notification.Text>Det kan ta opp til 5 minutter før du ser endringen her.</Notification.Text>
            </Notification>
          ) : null}
          {showError ? (
            <Notification variant="error">
              <Notification.Dismiss />
              <Notification.Heading as="h3">
                <Notification.Icon>Endringene dine ble ikke lagret. Vennligst prøv på nytt.</Notification.Icon>
              </Notification.Heading>
            </Notification>
          ) : null}
          <ServiceBox
            key={activationProduct.code}
            id={activationProduct.code}
            isActive={form.active}
            title={<strong>{activationProduct.name}</strong>}
            priceText={activationProduct.price?.price + ",-"}
            disabled={!isModifiable || loading}
            onChange={(checked) => onChangeHandler("active", checked)}
            content={
              "Nettvakt er et DNS-sikkerhetsfilter som trygger deg på nett når du er tilkoblet vårt mobilnett. Nettvakt vil hindre deg i å gå inn på nettsider som inneholder virus, svindelforsøk eller skadelig programvare."
            }
          />
          {form.active !== previousForm.active && (
            <Flex gap="200">
              <Button
                onClick={() => dispatch({ type: Actions.RESET })}
                type="button"
                variant="tertiary-purple"
                disabled={loading}
              >
                Avbryt
              </Button>

              <Button
                onClick={orderSecurityDNS}
                loading={loading}
                disabled={loading}
                type="button"
                data-tracking-id="service-save-button"
              >
                {loading ? "Sender bestilling" : "Lagre"}
              </Button>
            </Flex>
          )}
        </ExpandableCard.Content>
      </ExpandableCard.Item>
    </ExpandableCard>
  );
}
