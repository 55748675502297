import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { uri } from "@telia-no-min-side/utils";
import { useNavigate } from "react-router-dom";

type CancelInfo = {
  canCancel: boolean;
  agreementDetailId: number;
  productTitle: string;
  isInBinding: boolean;
  cancellationViewUrl: string;
  cancellationDate: string;
  alreadyCancelled: boolean;
  scheduledCancellationDate: string;
};

export function useFetchCancelAgreement({ agreementDetailId }: { agreementDetailId: string | undefined }) {
  return useAxiosSWR<CancelInfo>(agreementDetailId ? uri.purchase(`/api/cancelinfo/${agreementDetailId}`) : null);
}

type CancelInfoPost = {
  agreementDetailId: number;
  cancellationDate: string;
  errorMessage: string | null;
  productId: string;
  productTitle: string;
  productType: string;
  successful: boolean;
};

export function usePostCancelAgreement({ agreementDetailId }: { agreementDetailId: string | undefined }) {
  const navigate = useNavigate();
  const { mutate, data } = useFetchCancelAgreement({ agreementDetailId });
  return useAxiosPost<CancelInfoPost, undefined>(uri.purchase(`/api/cancel/${agreementDetailId}`), {
    customSuccessValidation({ successful }) {
      return successful;
    },
    onSuccess({ agreementDetailId, successful }) {
      if (agreementDetailId && successful) {
        data && mutate({ ...data, canCancel: false, alreadyCancelled: true });
        return navigate(uri.minSideFixed(`/avbestill/kvittering/${agreementDetailId}`), { replace: true });
      }
    },
  });
}
