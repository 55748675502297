import type { ReactNode } from "react";
import { Picker, PickerProps } from "./Picker";
import { useAuth, useWindowSize, MinSideButton } from "@telia-no-min-side/components";
import { Flex, Heading, Text, color, border, Box } from "@telia/teddy";

type Info = {
  title: string;
  subtitle: string;
};

type Props<T> = {
  info?: ReactNode | Info;
  dataTrackingID?: string;
} & PickerProps<T>;

function isInfo(obj: unknown): obj is Info {
  if (typeof obj !== "object" || !obj) return false;
  const info = obj as Info;
  return typeof info.title === "string" && typeof info.subtitle === "string";
}

export function TopMenu<T>({ info, selectedOption, options, handleOptionSelect, dataTrackingID, children }: Props<T>) {
  const { isDesktop, isMobile } = useWindowSize();
  const { hasLimitedAccess } = useAuth();
  const hasNoOptions = !selectedOption || !options?.length;

  if (isMobile && hasNoOptions) {
    return null;
  }

  return (
    <>
      <Box
        pl={{ sm: "page-padding-sm", md: "page-padding-md", lg: "page-padding-lg", xl: "page-padding-xl" }}
        pr={{ sm: "page-padding-sm", md: "page-padding-md", lg: "page-padding-lg", xl: "page-padding-xl" }}
        width="100%"
        maxWidth="100%"
        pt="gutter-sm"
        pb="gutter-sm"
        data-tracking-id={dataTrackingID}
      >
        <Flex
          direction={isDesktop ? "row" : "column"}
          justify={hasNoOptions ? "flex-end" : "between"}
          align={isDesktop ? "end" : "stretch"}
          gap="300"
        >
          {isInfo(info) ? (
            <Flex direction="column" gap="100" flexShrink="0" style={{ order: isDesktop ? "0" : "2" }}>
              <Heading as="h3" variant="title-200" data-di-mask>
                {info.title}
              </Heading>
              <Text variant="preamble-100" data-di-mask>
                {info.subtitle}
              </Text>
            </Flex>
          ) : (
            info
          )}
          <Flex
            gap="300"
            justify={isDesktop ? "flex-end" : "space-between"}
            style={{ minWidth: "0px" }}
            role="tablist"
            aria-label="Profile picker"
            m="4px"
          >
            <Picker selectedOption={selectedOption} options={options} handleOptionSelect={handleOptionSelect}>
              {children}
            </Picker>
            {!isMobile && (
              <div style={{ flexShrink: 0, margin: "4px" }}>
                <MinSideButton minSide="fixed" limitedAccess={hasLimitedAccess} />
              </div>
            )}
          </Flex>
        </Flex>
      </Box>
      <div style={{ borderBottom: `${border.teddyBorderWidthXs} ${color.teddyColorGray200} solid` }} />
    </>
  );
}
