import { Container, HorizontalScroll, ToggleButtonGroup } from "@telia-no-min-side/components";
import { useFetchFinancial } from "src/api/fixed";
import { useInvoice } from "src/store/hooks/invoice-context";
import { InvoiceError } from "../components/InvoiceError";
import { InvoiceLoader } from "../components/InvoiceLoader";
import { InvoiceCardOption } from "./InvoiceCardOption";

type FixedInvoiceComponentProps = {
  isLoadingNewProfile: boolean;
};

export const FixedInvoiceComponent = ({ isLoadingNewProfile }: FixedInvoiceComponentProps) => {
  const { financial, selectedFinancialAccountId, onFinancialAccountsChange, financialIsLoading } = useInvoice();
  const { error: financialError } = useFetchFinancial();

  if (financialIsLoading || isLoadingNewProfile) {
    return <InvoiceLoader />;
  }

  if (financialError) {
    return (
      <Container maxWidth="sm" padding>
        <InvoiceError />
      </Container>
    );
  }

  const hasMultipleFinancialAccounts =
    (financial?.financialAccounts && financial?.financialAccounts.length > 1) ?? false;

  return (
    <>
      {hasMultipleFinancialAccounts && (
        <HorizontalScroll addContainerScrollMargin>
          {({ scrollBy }) => (
            <Container maxWidth="full" gap padding="vertical">
              <ToggleButtonGroup onChange={onFinancialAccountsChange} value={selectedFinancialAccountId}>
                {financial?.financialAccounts.map((financialAccount) => (
                  <ToggleButtonGroup.Item
                    scrollBy={scrollBy}
                    key={`financialAccount-select-${financialAccount.id.financialAccountId}`}
                    text={`Avtale ${financialAccount.id.financialAccountId}`}
                    value={financialAccount.id.financialAccountId}
                  />
                ))}
              </ToggleButtonGroup>
            </Container>
          )}
        </HorizontalScroll>
      )}
      <Container padding="top">
        <InvoiceCardOption />
      </Container>
    </>
  );
};
