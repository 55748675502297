import { useState } from "react";
import { Alert, Button, Container, Heading, Link, Paragraph } from "@telia-no-min-side/components";
import { format, track, uri } from "@telia-no-min-side/utils";
import { SubscriptionStatus, SubscriptionStatusInput } from "src/gql/graphql";
import { useSearchParams } from "react-router-dom";
import { L } from "src/util/links";
import { useChangeSubscriptionStatus } from "../mobile-subscription-suspend/hook/useChangeSubscriptionStatus";
import { PhoneLink, Component, Header, OpenSubscriptionContainer, UserInfoKey, UserInfoRow } from "./styles/styles";
import { useApolloClient } from "@apollo/client";
import { updateSubscriptionStatus } from "../mobile-subscription-suspend/utils/update-subscription-status";

export function UnsuspendSubscriptionPage() {
  const [errorMessage, setErrorMessage] = useState({ reason: "", message: "" });
  const [subscriptionCannotBeReactivated, setSubscriptionCannotBeReactivated] = useState(false);
  const [searchParams] = useSearchParams();
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const subscriptionName = searchParams.get("userOfferingShortName");
  const phoneNumber = searchParams.get("phoneNumber") || "";
  const name = format.startCase(searchParams.get("name") || "");
  const invoiceLink = L.Invoice("Gå til faktura");
  const client = useApolloClient();

  const { changeSubscriptionStatus, data, loading } = useChangeSubscriptionStatus({
    onError(error) {
      if (error.message === "SUBSCRIPTION_ALREADY_ACTIVE") {
        setErrorMessage({ reason: error.message, message: "Abonnementet er allerede aktivt" });
      }
      if (error.message === "CANT_REACTIVATE_SUBSCRIPTION") {
        // This error can occur when user did not pay invoice on time, but final deadline
        // has not yet passed. When the user's last payment was less than 3 days ago, they
        // will be able to unblock the subscription, else they will get this error.
        // It's also possible that they paid, but the payment is not yet registered in our systems.
        setErrorMessage({
          reason: error.message,
          message:
            "Vi kan dessverre ikke se å ha mottatt noen betaling for utestående beløp. Dersom du allerede har betalt, ber vi deg prøve igjen innen kort tid. Dersom du ikke har betalt, ber vi deg gå til betaling eller kontakte kundeservice på telefon dersom du trenger å gjenåpne abonnementet raskere.",
        });
        setSubscriptionCannotBeReactivated(true);
      } else {
        setErrorMessage({
          reason: "OTHER",
          message: "En uventet feil oppstod, vennligst prøv igjen eller ta kontakt med kundeservice.",
        });
      }
    },
    onCompleted() {
      updateSubscriptionStatus(client, phoneNumber, {
        subscriptionStatus: SubscriptionStatus.Active,
        statusReasonCode: null,
      });
    },
  });

  const runChangeSubscriptionStatus = () => {
    changeSubscriptionStatus({
      variables: {
        phoneNumber: phoneNumber,
        status: {
          reason: "PR",
          status: SubscriptionStatusInput.Unsuspend,
        },
      },
    });

    pushGenericTrackingEvent({
      ui_item_action: "button_click",
      ui_item_context: "minside/mobil/gjenaapne-abonnement",
      ui_item_text: "Gjenåpne abonnement",
      ui_item_type: "button",
      ui_item_url: "no_url",
    });
  };

  return (
    <Component maxWidth="md" flexDirection="column" horizontalPosition="center" gap="lg">
      <Header padding="bottom" data-tracking-id="header--open-subscription">
        <Link href={uri.minSideFixed("/mobil")} icon="arrow-left" iconPosition="before" isInternalNavigation>
          Tilbake
        </Link>

        <Heading tag="h2" variant="title-400" textAlign="center">
          Gjenåpne abonnement
        </Heading>
      </Header>

      <OpenSubscriptionContainer flexDirection="column" gap data-tracking-id="container--open-subscription">
        {data && (
          <Container padding="bottom">
            <Alert kind="positive" isOpen title="Abonnementet er gjenåpnet" canClose={false} />
          </Container>
        )}

        {errorMessage.reason && !data && (
          <Container padding="bottom">
            <Alert
              icon="info-filled"
              kind="negative"
              isOpen
              title="Gjenåpne abonnement"
              text={errorMessage.message}
              canClose={false}
            >
              {errorMessage.reason === "CANT_REACTIVATE_SUBSCRIPTION" && (
                <Container flexDirection="column" alignItems="start" gap="lg" padding="top">
                  <PhoneLink variant="standalone" href="tel:+4792405050" icon="phone">
                    +47 924 05 050
                  </PhoneLink>

                  <Button tag="a" variant="secondary" icon="invoice" href={invoiceLink.href}>
                    {invoiceLink.text}
                  </Button>
                </Container>
              )}
            </Alert>
          </Container>
        )}

        <Container>
          <UserInfoRow>
            <UserInfoKey>
              <b>Navn:</b>
            </UserInfoKey>
            <Paragraph data-di-mask removeMargin>
              {name}
            </Paragraph>
          </UserInfoRow>
          <UserInfoRow>
            <UserInfoKey>
              <b>Abonnement:</b>
            </UserInfoKey>
            <Paragraph data-di-mask removeMargin>
              {subscriptionName}
            </Paragraph>
          </UserInfoRow>
          <UserInfoRow>
            <UserInfoKey>
              <b>Telefonnummer:</b>
            </UserInfoKey>
            <Paragraph data-di-mask removeMargin>
              {phoneNumber}
            </Paragraph>
          </UserInfoRow>
        </Container>

        <Container horizontalPosition="left" padding="top">
          {data || errorMessage.reason === "SUBSCRIPTION_ALREADY_ACTIVE" ? (
            <Button
              variant="primary"
              tag="a"
              href={uri.minSideFixed("/mobil")}
              isLoading={loading}
              loadingText="Behandler"
              isInternalNavigation
            >
              Tilbake til Mobil
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={runChangeSubscriptionStatus}
              isLoading={loading}
              loadingText="Behandler"
              data-tracking-id="button--open-subscription"
              disabled={subscriptionCannotBeReactivated}
            >
              Gjenåpne abonnementet
            </Button>
          )}
        </Container>
      </OpenSubscriptionContainer>
    </Component>
  );
}
