import { Container, Paragraph } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";

type Props = {
  phoneImage: string;
  phoneModel: string;
  phoneNumber: string;
  imei?: string;
};

export function CardHeader({ phoneImage, phoneModel, phoneNumber, imei }: Props) {
  return (
    <Container flexDirection="row" justifyContent="center" gap="lg">
      {phoneImage && (
        <Container width="unset">
          <img src={`https:${phoneImage}`} alt="product-image" width="70px" />
        </Container>
      )}
      <Container gap="sm" flexDirection="column">
        {phoneModel && (
          <Paragraph removeMargin>
            <em>{phoneModel}</em>
          </Paragraph>
        )}
        {phoneNumber && (
          <Paragraph removeMargin className="phone-number" data-di-mask isFaded>
            <i>{format.formatPhoneNumber(phoneNumber)}</i>
          </Paragraph>
        )}
        {imei && (
          <Paragraph removeMargin className="phone-number" data-di-mask>
            IMEI {imei}
          </Paragraph>
        )}
      </Container>
    </Container>
  );
}
