import { Button, Card, Container, Heading, Icon, Paragraph, remCalc } from "@telia-no-min-side/components";

type Props = {
  handleClick: () => void;
};

export const TvillingSimCard = ({ handleClick }: Props) => {
  return (
    <Card padding="lg" data-tracking-id="twin-sim-card-block">
      <Container flexDirection="column" gap>
        <Heading removeMargin tag="h3" variant="title-100">
          TvillingSIM
        </Heading>

        <Paragraph removeMargin>
          Hvis du ønsker å ha flere enheter tilknyttet samme abonnement og telefonnummer. Gjelder for:
        </Paragraph>

        <Container flexDirection="column" gap="sm">
          <Container gap alignItems="center">
            <Icon size="sm" icon="check-mark" />

            <Paragraph>Ekstra mobiltelefon</Paragraph>
          </Container>

          <Container gap alignItems="center">
            <Icon size="sm" icon="check-mark" />

            <Paragraph>Smartklokke</Paragraph>
          </Container>

          <Container gap alignItems="flex-start">
            <Container width="unset" flexShrink="0" style={{ paddingTop: remCalc(2) }}>
              <Icon size="sm" icon="alert" />
            </Container>

            <Paragraph removeMargin>
              Dersom du vil kjøpe klokke SIM, anbefaler vi å kjøpe TvillingSIM gjennom din klokke-app.
            </Paragraph>
          </Container>
        </Container>

        <Button size="sm" alignSelf="flex-end" variant="secondary" onClick={handleClick}>
          Velg TvillingSIM
        </Button>
      </Container>
    </Card>
  );
};
