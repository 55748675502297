import { Icon } from "@telia/teddy";
import { ErrorBox } from "components/ErrorBox";

type Props = {
  refetch: () => void;
};

export function Error({ refetch }: Props) {
  return (
    <ErrorBox
      title="Vi beklager"
      description="Dessverre oppstod det en feil under innlastningen av denne siden. Vennligst prøv igjen litt senere."
      actionContent={<Icon name="sync">Last side på nytt</Icon>}
      onClick={() => refetch()}
    />
  );
}
