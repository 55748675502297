import React from "react";

import "./style.less";

const ErrorPageContent = ({ children }) => <div className="error-page-content">{children}</div>;

export const ErrorPageContainer = ({ children }) => (
  <div className="error-page-container font-size-30">
    <ErrorPageContent>{children}</ErrorPageContent>
  </div>
);
