export const getTopupPeriod = (name: string): string => {
  const lowerCaseName = name.toLowerCase();

  switch (true) {
    case lowerCaseName.includes("boost"):
      return "Data Boost";
    case lowerCaseName.includes("dagspakke"):
      return "Dagspakke";
    case lowerCaseName.includes("ukespakke"):
      return "Ukespakke";
    default:
      return "Datapakke";
  }
};
