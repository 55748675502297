import { Flex, Icon, Text } from "@telia/teddy";

type Props = {
  number: string;
};

export default function PhoneBox({ number }: Props) {
  return (
    <Flex gap="100" style={{ background: "white" }} width="fit-content" p="100" pr="400" radius="md">
      <Icon name="phone" size="md" />
      <Text>{number}</Text>
    </Flex>
  );
}
