import { Link } from "react-router-dom";
import { Accordion, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";
import { INTERNAL_LINK } from "util/constants/internalLinks";

export const PapirfakturaMobile = () => {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Kan jeg bytte til papirfaktura?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Ja, du kan bytte til papirfaktura ved å reservere deg mot eFaktura i din nettbank. Ønsker du å reservere deg
          mot eFaktura fra oss, er det følgende tilbyder du kan velge:
        </Paragraph>
        <Paragraph>
          <strong>Telia Norge AS</strong>
        </Paragraph>
        <Paragraph>
          Vi vil da sende papirfaktura, og gjør deg oppmerksom på at du vil bli belastet gebyr for papirfaktura. Trenger
          du mer hjelp eller veiledning på hvordan du legger inn reservasjon, ta kontakt med banken din.
        </Paragraph>
        <Paragraph>
          Bytt fakturatype <Link to={INTERNAL_LINK.INVOICE_SETTINGS_PAGE}>her.</Link>
        </Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
};
