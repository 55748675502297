import { Flex, Heading, Notification } from "@telia/teddy";
import { Container } from "@telia-no-min-side/components";
import { Breadcrumb } from "components/Breadcrumb";
import { Step1View } from "./views/Step1View";
import { Step2View } from "./views/Step2View";
import { useFlexOrder } from "../context/FlexOrderProvider";

export function ProductSelector() {
  const { viewMode } = useFlexOrder();

  return (
    <Flex direction="column" gap="400" pb="1600">
      <div>
        <Breadcrumb />
        <Heading as="h2" variant="title-300">
          Din Flex-avtale
        </Heading>
      </div>
      <Container maxWidth="sm">
        <Notification variant="information">
          <Notification.Heading as="h3">
            <Notification.Icon name="bulb">Hva er Flex?</Notification.Icon>
          </Notification.Heading>
          <Notification.Text>
            Med Flex har du valgfriheten til å velge akkurat den internetthastigheten og antall TV-poeng som passer deg.
            Du kan bytte din Flex-pakke når du vil. Denne fleksible løsningen har du gjennom fellesavtalen for ditt
            borettslag/sameie.
          </Notification.Text>
        </Notification>
      </Container>
      {viewMode === "STEP1" && <Step1View />}
      {viewMode === "STEP2" && <Step2View />}
    </Flex>
  );
}
