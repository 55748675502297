import { Flex, Text, Heading, Card, AlertBadge, Box, Icon } from "@telia/teddy";

type Props = {
  title: string;
  subText?: string;
  devices: {
    Name: string;
    Connected_5_Minutes?: number;
    Connected_24_Minutes?: number;
    Connected_Minutes?: number;
  }[];
  connectedDurationKey: "Connected_5_Minutes" | "Connected_24_Minutes" | "Connected_Minutes";
};

const getFormattedConnectedDuration = (connectedDuration?: number) => {
  if (connectedDuration === undefined) {
    return "";
  }
  const connectedMinutes = connectedDuration % 60;
  const connectedHours = Math.floor(connectedDuration / 60);
  const hoursText = connectedHours > 0 ? ` ${connectedHours}t` : "";
  const minutesText = connectedMinutes > 0 ? `${connectedMinutes}m` : "";
  return `${hoursText} ${minutesText}`;
};

export function Devices({ title, subText, devices, connectedDurationKey }: Props) {
  return (
    <Box mt="200">
      <Flex mb="200" align="baseline">
        <Heading as="h3" variant="title-200" mr="200">
          {title}
        </Heading>
        {subText && (
          <>
            <AlertBadge variant="success" />
            <Text ml="50">{subText}</Text>
          </>
        )}
      </Flex>
      <Card variant="white" justify="stretch" bordered shadow>
        <Text variant="paragraph-100-bold" mb="100">
          Tilkoblede enheter
        </Text>
        <Flex wrap="wrap">
          {devices.length !== 0 ? (
            devices.map((device, i) => (
              <Card mb="200" width="100%" variant="white" bordered shadow key={`${device.Name}-${i}`}>
                <Flex gap="200" width="100%">
                  <Icon name="devices" size="lg" />
                  <Box>
                    <Text variant="paragraph-100-bold">{device.Name}</Text>
                    {device[connectedDurationKey] && (
                      <Text variant="additional-100">
                        Tilkoblet:{getFormattedConnectedDuration(device[connectedDurationKey])}
                      </Text>
                    )}
                  </Box>
                </Flex>
              </Card>
            ))
          ) : (
            <Card width="100%" variant="white" bordered shadow>
              <Text variant="paragraph-100-bold">Det er ingen tilkoblede enheter</Text>
            </Card>
          )}
        </Flex>
      </Card>
    </Box>
  );
}
