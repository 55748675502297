import { track, types } from "@telia-no-min-side/utils";
import { productId } from "src/util/productIds";
import { v4 } from "uuid";
import { getChargePeriodMultiplier } from "../../util/prices";

export function getIncludedProducts(selectedProduct: types.fixed.TvOverFwaPrice) {
  const hasFreeRecording = selectedProduct.inclusions.includes(productId.recording);
  const hasTvAsInclusions = selectedProduct.inclusions.includes(productId.tvPoints.p0);
  const hasMovieRentalInclusions = selectedProduct.inclusions.includes(productId.movieRental);
  const includedTeliaBoxId = selectedProduct.inclusions.find((id) => productId.teliaBox.includes(Number(id)));
  const hasFreeTeliaBox = typeof includedTeliaBoxId === "number";

  return {
    hasFreeRecording,
    hasTvAsInclusions,
    hasFreeTeliaBox,
    includedTeliaBoxId,
    hasMovieRentalInclusions,
  };
}

export function getTransactionTrackingData(
  currentSub: types.fixed.Product | undefined,
  selectedProduct: types.fixed.TvOverFwaPrice,
  shipping: types.fixed.ProductGroupShipping | undefined
): track.Transaction {
  const recurringPrice = selectedProduct.prices.currentAmount || 0;
  const recurringMultiplier = getChargePeriodMultiplier("år", selectedProduct.prices.chargePeriod);
  const yearlyCost = recurringPrice * recurringMultiplier;
  const previousSubPrice =
    typeof currentSub?.price?.amount === "number"
      ? `${getChargePeriodMultiplier("år", currentSub.price.chargePeriod) * currentSub.price.amount}`
      : undefined;
  const shippingPrice = shipping?.onceOffPrice || 0;
  const offerIds: string[] = [];
  //   selectedProduct.inclusions
  //     .flatMap((inclusion) => inclusion.offerIds?.filter(Boolean))
  //     .map((offerId) => `${offerId}`);
  const { hasFreeRecording, hasTvAsInclusions, hasFreeTeliaBox, includedTeliaBoxId } =
    getIncludedProducts(selectedProduct);
  const combosaleProductname =
    hasFreeRecording || hasTvAsInclusions || hasFreeTeliaBox
      ? [
          hasFreeRecording ? "Opptak" : null,
          hasTvAsInclusions ? "Telia Play" : null,
          hasFreeTeliaBox ? "Telia Box" : null,
        ]
          .filter(Boolean)
          .join(", ")
      : undefined;
  return {
    shippingPrice,
    transactionId: v4(),
    revenue: yearlyCost + shippingPrice,
    products: [
      {
        brand: "Telia",
        category: "Subscription/Broadband/Fixed wireless",
        id: `${selectedProduct.productId}`,
        name: selectedProduct.name || "unknown",
        price: yearlyCost,
        quantity: 1,
        financing: "billing",
        acquisitionRetention: currentSub?.id ? "Retention" : "Acquisition",
        fixedOfferCode: offerIds,
        newPortinUpsell: selectedProduct.purchaseType,
        previousSubId: currentSub?.id,
        previousSubName: currentSub?.displayName,
        previousSubPrice,
        combosaleProductname,
      },
      ...(shipping
        ? [
            {
              brand: "Telia",
              category: "Service/Delivery",
              id: "DELIVERY",
              name: shipping.description || "Frakt",
              price: shippingPrice,
              quantity: 1,
              financing: "Billing",
              delivery: "Posten",
            } as const,
          ]
        : []),
      ...(hasTvAsInclusions
        ? [
            {
              brand: "Telia",
              category: "Subscription/TV/Fixed wireless",
              id: `${productId.tvPoints.p0}`,
              name: "Telia Play",
              price: 0,
              quantity: 1,
              financing: "Billing",
              acquisitionRetention: "Acquisition",
              newPortinUpsell: "New",
            } as const,
          ]
        : []),
      ...(hasFreeTeliaBox
        ? [
            {
              brand: "Telia",
              category: "Hardware/TV/Decoder",
              id: `${includedTeliaBoxId}`,
              name: "Telia Box",
              price: 0,
              quantity: 1,
              financing: "Billing",
              acquisitionRetention: "Acquisition",
              newPortinUpsell: "New",
            } as const,
          ]
        : []),
      ...(hasFreeRecording
        ? [
            {
              brand: "Telia",
              category: "VAS/TV/Recording",
              id: `${productId.recording}`,
              name: "Opptak",
              price: 0,
              quantity: 1,
              financing: "Billing",
              acquisitionRetention: "Acquisition",
              newPortinUpsell: "New",
            } as const,
          ]
        : []),
    ],
  };
}
