import { Accordion, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";

export function PostSmsAccordion(): JSX.Element {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>
          Jeg har fått en SMS med <q>Post i retur</q> - hva betyr det?
        </StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Hvis du har fått en SMS med beskjed om at vi har mottatt din post i retur, betyr det at PostNord ikke har
          kunnet levere til adressen vi har registrert på ditt kundeforhold. Det kan være flere årsaker til dette:
        </Paragraph>
        <ul>
          <li>Manglende navnelapp på postkasse</li>
          <li>Behov for å merke fakturaen med et c/o-navn (f.eks. samboer e.l.)</li>
          <li>Du har flyttet, men ikke endret adresse hos oss</li>
          <li>Vi har registrert feil navn eller adresse</li>
        </ul>
        <Paragraph>
          Du kan se hvilken adresse vi har registrert på ditt kundeforhold på Min Side. Ta kontakt med oss hvis du
          oppdager at denne adressen er feil, så hjelper vi deg å rette opp i det.
        </Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
