import { ProgressBar, Card, Box, Heading, Text, Skeleton, Badge, Flex } from "@telia/teddy";
import { useFetchTvSubscription } from "src/api/fixed";
import { tvCardWidth } from "../utils/constants";

export function TvPoints() {
  const tvSubscription = useFetchTvSubscription();
  const tvSubscriptionProduct = tvSubscription.data?.products?.[0];

  if (!tvSubscriptionProduct && tvSubscription.isError) return null;

  const tvPoints = tvSubscriptionProduct?.points;
  const usedPoints = tvPoints?.used || 0;
  const totalPoints = tvPoints?.total || 0;

  if (!tvSubscription.isLoading && totalPoints === 0) return null;

  return (
    <Box mb="200">
      <Heading as="h2" variant="title-100" mb="200">
        Dine TV-poeng
      </Heading>
      <Card bordered layout="default" variant="white" width={tvCardWidth} minWidth="fit-content">
        <Skeleton loading={tvSubscription.isLoading}>
          <Card.Heading variant="title-300" style={{ whiteSpace: "nowrap" }}>
            {usedPoints} poeng brukt
          </Card.Heading>
        </Skeleton>
        <Card.Line />
        <Card.Content mb="200">
          <Skeleton loading={tvSubscription.isLoading}>
            <Flex gap="150" direction="column">
              <div>
                <Text variant="paragraph-100-medium">
                  {usedPoints} / {totalPoints} poeng brukt
                </Text>
                {totalPoints > 0 ? <ProgressBar progress={(usedPoints / totalPoints) * 100} /> : null}
              </div>
              <Badge variant="information">{tvPoints?.initial || 0} poeng - ditt TV-abonnement</Badge>
              {tvPoints?.hasExtra50 ? <Badge variant="special">50 poeng - kompensasjon for TV 2</Badge> : null}
              {tvPoints?.hasExtra10 ? <Badge variant="special">10 poeng - mobilabonnement fra Telia</Badge> : null}
              {tvPoints?.hasExtra5 ? <Badge variant="special">5 poeng - bonus!</Badge> : null}
            </Flex>
          </Skeleton>
        </Card.Content>
      </Card>
    </Box>
  );
}
