import { Container, Heading, Paragraph, ScrollToTop, Button } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { useFwaCommitment } from "src/store/hooks/useFwaCommitment";
import { CommitmentSummary } from "./CommitmentSummary";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { PdfHeader } from "components/pdf-order-summary/PdfHeader";
import { DownloadButton } from "components/pdf-order-summary/DownloadButton";
import { openPdf } from "util/pdf-order-summary/openPdf";
import { PdfFooter } from "components/pdf-order-summary/PdfFooter";
import { Flex } from "@telia/teddy";

type Props = {
  availableFwaOffer: NonNullable<ReturnType<typeof useFwaCommitment>["newFwaOffer"]>;
  isLoading: boolean;
  onSubmit: () => void;
};

export function NewOffer({ availableFwaOffer, isLoading, onSubmit }: Props) {
  return (
    <Container maxWidth="sm" padding alignItems="flex-start" flexDirection="column" gap id="fwa-commitment-offer">
      <ScrollToTop />
      <PdfHeader>
        <Heading tag="h2" variant="title-300" className="show-on-print">
          Ordresammendrag
        </Heading>
      </PdfHeader>
      <Heading tag="h2" variant="title-300" data-html2canvas-ignore>
        Vil du legge til årsrabatt på ditt trådløse bredbåndsabonnement?
      </Heading>
      <CommitmentSummary offer={availableFwaOffer} />
      <Paragraph className="hide-on-print" data-html2canvas-ignore removeMargin>
        Avslutter du årsrabatten før det har gått 12 md. påløper det et bruddgebyr. Bruddgebyret er lik totalrabatten du
        får i avtaletiden. Bruddgebyret reduseres månedlig ut avtaletiden, regnet fra når avtalen ble inngått, ned til
        minimum 399 kr.
      </Paragraph>

      <DownloadButton className="hide-on-print" onClick={async () => await openPdf("#fwa-commitment-offer")} />

      <Flex gap="gutter-sm" justify="flex-end" direction="row" data-html2canvas-ignore width="100%">
        <Button
          variant="tertiary-black"
          hideLinkIcon
          tag="a"
          href={uri.minSideFixed("/hjem")}
          isInternalNavigation={true}
          trackEvent={{
            ui_item_text: TRACK_EVENT.ITEM_TEXT.CANCEL,
            ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FWA_COMMITMENT,
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
          }}
        >
          Avbryt
        </Button>
        <Button
          isLoading={isLoading}
          trackEvent={{
            ui_item_text: TRACK_EVENT.ITEM_TEXT.ADD_ANNUAL_DISCOUNT,
            ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FWA_COMMITMENT,
            ui_item_action: TRACK_EVENT.ITEM_ACTION.NEW,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
          }}
          onClick={onSubmit}
        >
          Legg til årsrabatt
        </Button>
      </Flex>
      <PdfFooter isCommitment />
    </Container>
  );
}
