import { graphql } from "src/gql";

export const GET_DATA_USAGE_HISTORY = graphql(`
  query getDataUsageHistory($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      roles
      usage {
        category
        type
        limit {
          amount
        }
        activeDataBoostSession
      }
      usageGraphBillperiod: usageGraph(type: BYTE, grouping: BILLPERIOD) {
        period {
          startDate
          endDate
        }
        used
      }
    }
  }
`);
