import { Flex, Card, Button, Icon, FlipCard } from "@telia/teddy";
import { formatDataAmount } from "@telia-no-min-side/utils";
import { formatBytesToGB, getOfferingPrice } from "../../utils";
import { Terms, UnlimitedDataProgressSection, ActionsSection } from "..";
import { useMobileBroadbandContext } from "pages/internet/mobile-broadband/context/MobileBroadbandContext";

export function UnlimitedDataUsageCard() {
  const { subscription, domesticalUsage, openDrawer, setDrawerContent, timeUntilDataUsagePeriodStarts } =
    useMobileBroadbandContext();
  const offeringPrices = subscription?.userOffering?.offeringPrices?.[0]?.offeringPrices || [];

  const dataLimitRLH = getOfferingPrice(offeringPrices, "DATA_LIMIT_RLH"); // ROAMING_LIKE_HOME
  const dataLimitWhenSpeedReduced = getOfferingPrice(offeringPrices, "DATA_INCLUDED");
  const reducedSpeedAfterExceededDataLimit = getOfferingPrice(offeringPrices, "SPEED_DOWNLOAD_REDUCED");

  const formattedLimits = {
    dataLimitRLH: `${dataLimitRLH?.amount} ${dataLimitRLH?.unit}`,
    dataLimitWhenSpeedReduced: `${dataLimitWhenSpeedReduced?.amount} ${dataLimitWhenSpeedReduced?.unit}`,
    reducedSpeed: `${reducedSpeedAfterExceededDataLimit?.amount} ${reducedSpeedAfterExceededDataLimit?.unit}`,
  };

  const dataUsed = domesticalUsage?.reduce((sum, quota) => sum + (quota?.usage || 0), 0) || 0;
  const hasExceededDataLimit = formatBytesToGB(dataUsed) > (dataLimitWhenSpeedReduced?.amount || 0);

  return (
    <FlipCard>
      <FlipCard.Front bordered shadow layout="default" variant="white" gap="gutter-md">
        <>
          <Card.Heading variant="title-400">{formatDataAmount(dataUsed)} brukt</Card.Heading>
          <Card.Line />
          <Card.Content>
            <Flex direction="column" gap="400" mb="gutter-sm">
              <UnlimitedDataProgressSection
                formattedLimits={formattedLimits}
                hasExceededDataLimit={hasExceededDataLimit}
                timeUntilDataUsagePeriodStarts={timeUntilDataUsagePeriodStarts}
              />
              <ActionsSection showTerms hasExceededDataLimit={hasExceededDataLimit} />
            </Flex>
          </Card.Content>

          <Card.Footer>
            {/* {hasExceededDataLimit ? ( */}
            {/*   <Button width="100%" variant="primary" onClick={() => openSelectedTopupDrawer(TopupCategory.DataBoost)}> */}
            {/*     <Icon name="getting-started">Aktiver Data Boost</Icon> */}
            {/*   </Button> */}
            {/* ) : ( */}
            {/*   <Button width="100%" variant="primary" asChild> */}
            {/*     <Link to={INTERNAL_LINK.MOBILE_NEW.DATA_USAGE_HISTORY_PAGE}> */}
            {/*       <Icon name="bar-chart">Tidligere dataforbruk</Icon> */}
            {/*     </Link> */}
            {/*   </Button> */}
            {/* )} */}
            <Button
              width="100%"
              variant="primary"
              onClick={() => {
                openDrawer();
                setDrawerContent("Topup");
              }}
            >
              <Icon name="getting-started">Aktiver Data Boost</Icon>
            </Button>
          </Card.Footer>
        </>
      </FlipCard.Front>
      <FlipCard.Back bordered shadow variant="white">
        <Terms formattedLimits={formattedLimits} />
      </FlipCard.Back>
    </FlipCard>
  );
}
