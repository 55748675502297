import { Text } from "@telia/teddy";
import { uri } from "@telia-no-min-side/utils";
import { ReactNode } from "react";
import { usePrefetchQuery } from "src/hooks/usePrefetchQuery";
import { useSelectAccount } from "src/hooks/useSelectAccount";
import { INVOICE_PAGE_SIZE } from "src/pages/invoice/mobile/MobileInvoiceComponent";
import { ACCOUNT_OWNER_INVOICE_QUERY } from "src/pages/invoice/queries";
import { FinanceMobileProps } from ".";
import { FragmentType, getFragmentData, graphql } from "gql";
import { PaymentMethodName, InvoiceDistribution } from "gql/graphql";
import { FinanceCard } from "../card/FinanceCard";
import { AUTH } from "util/constants/auth";

const paymentBalanceFragment = graphql(`
  fragment PaymentBalance on PaymentBalance {
    amount
  }
`);
const lastInvoiceFragment = graphql(`
  fragment Invoice on Invoice {
    invoiceNumber
    paid
    amount {
      value
    }
  }
`);

type Props = {
  children?: ReactNode;
  accountId: string;
  paymentBalance: FragmentType<typeof paymentBalanceFragment>;
  lastInvoice: FragmentType<typeof lastInvoiceFragment> | null;
  paymentMethodName?: PaymentMethodName | null;
  invoiceDistributionType: InvoiceDistribution;
} & Pick<FinanceMobileProps, "hasMultipleAccounts">;

export function MobileFinanceCard({
  children,
  accountId,
  hasMultipleAccounts,
  paymentMethodName,
  invoiceDistributionType,
  ...props
}: Props) {
  usePrefetchQuery(ACCOUNT_OWNER_INVOICE_QUERY, { variables: { accountId, pageSize: INVOICE_PAGE_SIZE } });
  const paymentBalance = getFragmentData(paymentBalanceFragment, props.paymentBalance);
  const lastInvoice = getFragmentData(lastInvoiceFragment, props.lastInvoice);
  const { setAccount } = useSelectAccount();
  return (
    <FinanceCard
      balance={paymentBalance.amount}
      buttonHref={uri.minSideFixed("/faktura")}
      onLinkNavigation={() => setAccount({ id: accountId, accountType: AUTH.ACCOUNT_TYPE.MOBILE })}
      lastInvoiceAmount={lastInvoice?.amount?.value}
      isLastInvoicePaid={lastInvoice?.paid}
      paymentMethodName={paymentMethodName}
      invoiceDistributionType={invoiceDistributionType}
      cardHeading={hasMultipleAccounts ? `Mobil (Konto ${accountId})` : "Mobil"}
      cardContent={
        !lastInvoice && paymentBalance.amount === 0 ? (
          <Text>Alt er på stell, du har betalt det du skal!</Text>
        ) : (
          children
        )
      }
    />
  );
}
