import { UseMobileAccountSubscriptionData } from "src/hooks/useMobileAccount";
import { breakpoint, Flex, Notification } from "@telia/teddy";
import { formatDateForSuspendedMessage } from "../utils";
import PhoneBox from "components/PhoneBox";
import { phoneNumbers } from "util/phoneNumbers";
import { useWindowSize } from "@telia-no-min-side/components";

type Props = Pick<UseMobileAccountSubscriptionData, "subscriptionStatusDate" | "userOffering">;

export function FraudMessage({ userOffering, subscriptionStatusDate }: Props) {
  const userOfferingShortName = userOffering?.shortName || "";
  const { isMobile } = useWindowSize();

  return (
    <Flex maxWidth={breakpoint.teddyBreakpointMd} p={isMobile ? "200" : "400"} gap="300" direction="column">
      <Notification variant="information" data-tracking-id="alert--suspended-subscription_reason-fraud">
        <Notification.Heading as="h3">
          <Notification.Icon>Ditt abonnement {userOfferingShortName} er sperret</Notification.Icon>
        </Notification.Heading>
        <Flex direction="column" gap="gutter-md">
          <Notification.Text>
            Abonnementet ditt ble sperret den {formatDateForSuspendedMessage(subscriptionStatusDate || "")}. Vennligst
            kontakt kundeservice på telefon for nærmere detaljer.
          </Notification.Text>
          <Notification.Footer>
            <PhoneBox number={phoneNumbers.customerServiceNorway} />
          </Notification.Footer>
        </Flex>
      </Notification>
    </Flex>
  );
}
