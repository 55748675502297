import { uri } from "@telia-no-min-side/utils";
import { useFetchProfile } from "src/api/fixed";
import { getPartnerInvoiceUrl, isPartnerWithoutInvoice } from "../../util/PartnerHacks";

type GetInvoiceUrl = {
  url: string | null;
  isInternalNavigation: boolean;
  loading: boolean;
};

export function useInvoiceUrl(): GetInvoiceUrl {
  const profile = useFetchProfile();

  if (profile.data && isPartnerWithoutInvoice(profile.data.businessUnitId)) {
    return {
      url: getPartnerInvoiceUrl(profile.data.businessUnitId),
      isInternalNavigation: false,
      loading: false,
    };
  }

  return { url: uri.minSideFixed("/faktura"), isInternalNavigation: true, loading: profile.isLoading };
}
