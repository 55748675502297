import { AdditionalSimCard } from "gql/graphql";

export const getSimCardNickname = (simCard: AdditionalSimCard) => {
  const characteristics = simCard.product?.characteristic;
  if (!characteristics?.length || !characteristics[0]?.config) return;

  const description = characteristics.find(
    (characteristic) => characteristic?.config?.find((config) => config?.name === "DESCRIPTION" && config.value !== "")
  );

  if (!description?.config?.length || !description.config[0]?.value) return;

  return description.config[0].value;
};
