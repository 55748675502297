import styled, { css } from "styled-components";
import { remCalc } from "../utils/fonts";
import { HeadingProps } from ".";

type StyledHeadingProps = Pick<
  HeadingProps,
  "variant" | "removeMargin" | "textAlign" | "ellipsisOverflow" | "color" | "lineThrough"
>;
export const StyledHeading = styled.h2<StyledHeadingProps>`
  text-align: ${({ textAlign }) => textAlign};

  ${({ lineThrough }) => {
    if (lineThrough) {
      return css`
        text-decoration: line-through;
      `;
    }
  }}

  ${({ color }) => {
    if (color) {
      return css`
        color: ${color};
      `;
    }
  }}

  ${({ ellipsisOverflow }) => {
    if (ellipsisOverflow) {
      return css`
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }
  }}

  ${({ variant }) => {
    if (variant === "display-100") {
      return css`
        font-family: "TeliaSansHeading", "TeliaSans", Helvetica, sans-serif;
        font-size: 3rem;
        line-height: 1;
        font-size: clamp(3rem, 1.5rem + 4vw, 5rem);
      `;
    }
    if (variant === "display-200") {
      return css`
        font-family: "TeliaSansHeading", "TeliaSans", Helvetica, sans-serif;
        font-size: 2.5rem;
        line-height: 1;
        font-size: clamp(2.5rem, 1.375rem + 3vw, 4rem);
      `;
    }
    if (variant === "title-700") {
      return css`
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 1;
        font-size: clamp(2.5rem, 1.375rem + 3vw, 4rem);
      `;
    }
    if (variant === "title-600") {
      return css`
        font-weight: 500;
        font-size: 2.25rem;
        line-height: 1;
        font-size: clamp(2.25rem, 1.3125rem + 2.5vw, 3.5rem);
      `;
    }
    if (variant === "title-500") {
      return css`
        font-weight: 500;
        font-size: 2rem;
        line-height: 1;
        font-size: clamp(2rem, 1.25rem + 2vw, 3rem);
      `;
    }
    if (variant === "title-400") {
      return css`
        font-weight: 500;
        font-size: 1.75rem;
        line-height: 1.25;
        font-size: clamp(1.75rem, 1.1875rem + 1.5vw, 2.5rem);
      `;
    }
    if (variant === "title-300") {
      return css`
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.25;
        font-size: clamp(1.5rem, 1.125rem + 1vw, 2rem);
      `;
    }
    if (variant === "title-200") {
      return css`
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.25;
        font-size: clamp(1.25rem, 1.0625rem + 0.5vw, 1.5rem);
      `;
    }
    if (variant === "title-200-light") {
      return css`
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 1.25;
        font-size: clamp(1.25rem, 1.0625rem + 0.5vw, 1.5rem);
      `;
    }
    if (variant === "title-150") {
      return css`
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25;
        font-size: clamp(1.2rem, 0.8125rem + 0.5vw, 1.3rem);
      `;
    }
    if (variant === "title-75") {
      return css`
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25;
        font-size: clamp(1rem, 0.8125rem + 0.5vw, 1.125rem);
      `;
    }
    if (variant === "title-100") {
      return css`
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25;
        font-size: clamp(1.1rem, 0.8125rem + 0.5vw, 1.25rem);
      `;
    }
    if (variant === "subsection-100") {
      return css`
        font-weight: 500;
        line-height: 1.25;
        font-size: 1rem;
      `;
    }
  }}

  margin: 0;
  :not(:last-child) {
    ${({ variant, removeMargin }) => {
      if (removeMargin) return;
      if (
        variant === "display-100" ||
        variant === "display-200" ||
        variant === "title-700" ||
        variant === "title-600"
      ) {
        return css`
          margin-bottom: ${remCalc(24)};
        `;
      }
      if (variant === "title-500" || variant === "title-400") {
        return css`
          margin-bottom: ${remCalc(20)};
        `;
      }

      if (
        variant === "title-300" ||
        variant === "title-200" ||
        variant === "title-200-light" ||
        variant === "title-75" ||
        variant === "title-100"
      ) {
        return css`
          margin-bottom: ${remCalc(8)};
        `;
      }

      if (variant === "subsection-100") {
        return css`
          margin-bottom: ${remCalc(4)};
        `;
      }
    }}
  }

  ul + &&,
  p + &&,
  ol + && {
    ${({ variant, removeMargin }) => {
      if (removeMargin) return;
      if (
        variant === "display-100" ||
        variant === "display-200" ||
        variant === "title-700" ||
        variant === "title-600" ||
        variant === "title-500" ||
        variant === "title-400" ||
        variant === "title-300"
      ) {
        return css`
          margin-top: ${remCalc(48)};
        `;
      }
      if (variant === "title-200" || variant === "title-200-light") {
        return css`
          margin-top: ${remCalc(32)};
        `;
      }
      if (variant === "title-100" || variant === "title-75" || variant === "subsection-100") {
        return css`
          margin-top: ${remCalc(24)};
        `;
      }
    }}
  }
`;
