import { colors } from "@telia/styleguide";
import styled from "styled-components";
import { Container } from "../Container";
import { devices } from "../utils/breakpoints";

type Kind = "positive" | "negative" | "warning" | "info";

type AlertContainerProps = {
  kind: Kind;
  withSpacing?: boolean;
};

const getColor = (kind: string | undefined) => {
  if (kind === "positive") {
    return colors.green700;
  }
  if (kind === "negative") {
    return colors.red600;
  }
  if (kind === "warning") {
    return colors.orange600;
  }
  if (kind === "info") {
    return colors.blue600;
  }
};

export const AlertTitleContainer = styled(Container)<AlertContainerProps>`
  color: ${(props) => getColor(props.kind)};
`;

export const AlertContainer = styled.div<AlertContainerProps>`
  border: 2px solid ${(props) => getColor(props.kind)};
  margin: ${(props) => (props.withSpacing ? "1rem 0" : "initial")};
  width: 100%;
  border-left-width: 6px;
  padding: 1.25rem 1rem;
  border-radius: 0.5rem;
  @media ${devices.xs_down} {
    padding: 1rem 0.5rem;
  }
`;
