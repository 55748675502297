import type { SyntheticEvent } from "react";
import { useState } from "react";
import { useFormHandling, useLocalStorage, useToast } from "@telia-no-min-side/components";
import { AdditionalProduct, OrderAdditionalProductsMutation, OrderOp } from "src/gql/graphql";
import { useOrderAdditionalProducts } from "src/hooks/useAdditionalProducts";
import { FormStateField } from "../../types";
import { getCharacteristicValue } from "../../utils/getCharacteristicValue";
import { getProductCharacteristics } from "../../utils/getProductCharacteristics";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { BankIdRequiredModal } from "components/bank-id-required-modal";
import { config } from "util/config";
import { PromptModal } from "components/prompt-modal/PromptModal";
import { ExpandableCard, Heading, Button, Flex, Badge, Input, Label, Toggle, RadioGroup } from "@telia/teddy";
import { ServiceBox } from "components/service-box";

type Props = {
  options: AdditionalProduct[];
};

export function VoiceMail({ options }: Props) {
  const { phoneNumber } = useMobileAccount();
  const { addToast } = useToast();
  const product = options[0];
  const initialFormState = getInitialFormState(product);
  const [bankIdOpen, setBankIdOpen] = useState(false);

  const { state, dispatch, Actions } = useFormHandling(initialFormState);
  const { form, previousForm } = state;
  const [isModalShown, setIsModalShown] = useState(false);
  const requireBankId = isBankIdVerificationRequired(initialFormState, form) && config.requireBankId;

  const { orderResponse, runOrderAdditionalProducts } = useOrderAdditionalProducts({
    uniqueKey: "changeVoiceSettings",
    onCompleted(data: OrderAdditionalProductsMutation) {
      if (data?.order?.orderId) {
        setOrderId(data.order.orderId);
        dispatch({ type: Actions.ON_SUCCESS });
        dispatch({ type: Actions.SET_SHOW_SUCCESS, payload: { showSuccess: false } });
        addToast({
          text: "Dine endringer er lagret. Det kan ta opp til 5 minutter før du ser endringen her.",
          variant: "success",
          dataTrackingId: "change-voice-settings-success",
        });
      } else {
        addToast({
          text: `Bestillingen kunne ikke gjennomføres.`,
          variant: "error",
          dataTrackingId: "change-voice-settings-error",
        });
      }
      setBankIdOpen(false);
    },
    onError() {
      dispatch({ type: Actions.ON_ERROR });
      dispatch({ type: Actions.SET_SHOW_ERROR, payload: { showError: false } });
      addToast({
        text: `Endringene dine ble ikke lagret. Vennligst prøv på nytt.`,
        variant: "error",
        dataTrackingId: "change-voice-settings-error",
      });
      setBankIdOpen(false);
    },
  });

  const { loading } = orderResponse;

  const isFormModified =
    form.characteristics?.every((item, index) => item.value === previousForm.characteristics![index].value) &&
    form.active === previousForm.active
      ? false
      : true;

  const { setValue: setOrderId } = useLocalStorage<string>("telia-no-minside-order-id__settings");

  const order = (form: FormStateField, phoneNumber: string, product: AdditionalProduct) => {
    const isFormActive = form.active;
    const isProductModified = previousForm.active && form.active;

    const orderInput = [
      {
        productName: isFormActive ? product.code : "STOP_VOICEMAIL",
        operation: isProductModified ? OrderOp.ModifyAdditionalProduct : OrderOp.AddAdditionalProduct,
        email: form.email,
        productCharacteristics: isFormActive
          ? {
              featureCode: "S-VMS",
              config: form.characteristics,
            }
          : undefined,
      },
    ];

    runOrderAdditionalProducts(
      {
        phoneNumber,
        input: orderInput,
      },
      undefined,
      requireBankId
    );
  };

  function onChangeOptionHandler(key: keyof FormStateField) {
    const newState = !form[key];

    dispatch({ type: Actions.SET_FORM, payload: { [key]: newState } });
  }

  function onChangeCharacteristicHandler(form: FormStateField, characteristicType: string, value: string) {
    const characteristics = form.characteristics?.map((item) =>
      item.name === characteristicType ? { ...item, value } : item
    );

    dispatch({ type: Actions.SET_FORM, payload: { characteristics } });
  }

  function getCharacteristicChecked(form: FormStateField, characteristicType: string) {
    const value = getCharacteristicValue(form, characteristicType);

    const checked = "Y";
    return value === checked;
  }

  const showSaveButton = !!getCharacteristicValue(form, "LANGUAGE") && !!getCharacteristicValue(form, "NOTIFY-TYPE");

  return (
    <>
      <ExpandableCard type="multiple">
        <ExpandableCard.Item value="content1">
          <ExpandableCard.Trigger
            onClick={() => {
              !loading && dispatch({ type: Actions.RESET });
            }}
          >
            <ExpandableCard.Header>
              <ExpandableCard.Description>
                <Flex direction="column" gap="100">
                  <Heading as="h5" variant="title-100">
                    Personsvar
                  </Heading>
                  <div>
                    {previousForm.active ? (
                      <Badge variant="success" hideIcon>
                        På
                      </Badge>
                    ) : (
                      <Badge variant="neutral">Av</Badge>
                    )}
                  </div>
                </Flex>
              </ExpandableCard.Description>
              <ExpandableCard.Indicator />
            </ExpandableCard.Header>
          </ExpandableCard.Trigger>
          <ExpandableCard.Content>
            <ServiceBox
              isActive={form.active}
              title={<strong>{product.name}</strong>}
              priceText={product.price?.price + ",-"}
              id={product.code}
              key={product.code}
              disabled={!product.presentation?.modifiable || loading}
              onChange={() => {
                if (form.active) {
                  setIsModalShown(true);
                } else {
                  onChangeOptionHandler("active");
                }
              }}
              content={[
                <p key={product.id + "-description"}>{product.shortDescription}</p>,
                form.active && (
                  <Flex
                    direction="column"
                    gap="200"
                    mb="200"
                    key={product.id + "-characteristics"}
                    data-tracking-id="personal-response-settings"
                  >
                    <RadioGroup
                      value={getCharacteristicValue(form, "LANGUAGE")}
                      onValueChange={(value) => onChangeCharacteristicHandler(form, "LANGUAGE", value)}
                      orientation="horizontal"
                    >
                      <RadioGroup.GroupLabel>Språk</RadioGroup.GroupLabel>
                      {characteristicsOptions.LANGUAGE.map((lang) => (
                        <RadioGroup.Item key={lang.value}>
                          <RadioGroup.Trigger value={lang.value} />
                          <RadioGroup.Label>{lang.label}</RadioGroup.Label>
                        </RadioGroup.Item>
                      ))}
                    </RadioGroup>
                    <RadioGroup
                      value={getCharacteristicValue(form, "NOTIFY-TYPE")}
                      onValueChange={(value) => onChangeCharacteristicHandler(form, "NOTIFY-TYPE", value)}
                      orientation="horizontal"
                    >
                      <RadioGroup.GroupLabel>Varsel</RadioGroup.GroupLabel>
                      {characteristicsOptions["NOTIFY-TYPE"].map((lang) => (
                        <RadioGroup.Item key={lang.value}>
                          <RadioGroup.Trigger value={lang.value} />
                          <RadioGroup.Label>{lang.label}</RadioGroup.Label>
                        </RadioGroup.Item>
                      ))}
                    </RadioGroup>
                    <Toggle>
                      <Flex direction="column" gap="100">
                        <Toggle.Label>Who Called</Toggle.Label>
                        <Toggle.Input
                          checked={getCharacteristicChecked(form, "WHO-CALLED")}
                          onCheckedChange={(checked) =>
                            onChangeCharacteristicHandler(form, "WHO-CALLED", checked ? "Y" : "N")
                          }
                          disabled={loading}
                        >
                          <Toggle.Indicator />
                          <Toggle.Thumb />
                        </Toggle.Input>
                      </Flex>
                    </Toggle>
                    <Toggle>
                      <Flex direction="column" gap="100">
                        <Toggle.Label>Visual Voicemail Tilgjengelig</Toggle.Label>
                        <Toggle.Input
                          checked={getCharacteristicChecked(form, "VVM")}
                          onCheckedChange={(checked) => onChangeCharacteristicHandler(form, "VVM", checked ? "Y" : "N")}
                          disabled={loading}
                        >
                          <Toggle.Indicator />
                          <Toggle.Thumb />
                        </Toggle.Input>
                      </Flex>
                    </Toggle>
                    <div>
                      <Label>Kopi til e-post</Label>
                      <Input
                        placeholder="Kopi til e-post"
                        value={getCharacteristicValue(form, "EMAIL")}
                        onChange={(event: SyntheticEvent<Element, Event>) =>
                          onChangeCharacteristicHandler(form, "EMAIL", (event.target as HTMLInputElement).value)
                        }
                        disabled={loading}
                      />
                    </div>
                  </Flex>
                ),
              ]}
            />
            {isFormModified && (
              <Flex gap="200">
                <Button
                  onClick={() => {
                    dispatch({ type: Actions.RESET });
                  }}
                  type="button"
                  variant="tertiary-purple"
                  disabled={loading}
                >
                  Avbryt
                </Button>
                {!requireBankId && showSaveButton ? (
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="button"
                    data-tracking-id="service-save-button"
                    onClick={() => order(form, phoneNumber, product)}
                  >
                    {loading ? "Sender bestilling" : "Lagre"}
                  </Button>
                ) : null}

                {requireBankId && showSaveButton ? (
                  <BankIdRequiredModal
                    open={bankIdOpen}
                    onOpenChange={setBankIdOpen}
                    triggerComp={
                      <Button loading={loading} disabled={loading} type="button" data-tracking-id="service-save-button">
                        {loading ? "Sender bestilling" : "Lagre"}
                      </Button>
                    }
                    mutateComp={
                      <Button
                        loading={loading}
                        onClick={() => order(form, phoneNumber, product)}
                        data-tracking-id="bankid-modal-bankid-button"
                        type="button"
                        disabled={loading}
                      >
                        Til BankID
                      </Button>
                    }
                  />
                ) : null}
              </Flex>
            )}
          </ExpandableCard.Content>
        </ExpandableCard.Item>
      </ExpandableCard>

      <PromptModal
        open={isModalShown}
        onClose={() => {
          setIsModalShown(false);
        }}
        onConfirm={() => {
          setIsModalShown(false);
          onChangeOptionHandler("active");
        }}
        heading={<p>OBS!</p>}
        body={
          <p>Fjerning av personsvar vil også deaktivere alle tvilling SIM-kort som er knyttet til dette nummeret.</p>
        }
      />
    </>
  );
}

function isBankIdVerificationRequired(initialFormState: FormStateField, currentFormState: FormStateField): boolean {
  const hasVoiceMailActivationStatusChanged = initialFormState.active !== currentFormState.active;
  const oldEmail = getCharacteristicValue(initialFormState, "EMAIL");
  const newEmail = getCharacteristicValue(currentFormState, "EMAIL");
  const hasCopyVoiceMailToEmailChanged = oldEmail !== newEmail;

  return hasVoiceMailActivationStatusChanged || hasCopyVoiceMailToEmailChanged;
}

const getInitialFormState = (product: AdditionalProduct): FormStateField => ({
  code: product.code || "",
  characteristics: getProductCharacteristics(product, "S-VMS"),
  active: !!product.presentation?.active,
  email: "",
});

const characteristicsOptions: Record<string, { label: string; value: string }[]> = {
  LANGUAGE: [
    { label: "Norsk", value: "no-NO" },
    { label: "Engelsk", value: "en-GB" },
  ],
  "NOTIFY-TYPE": [
    { label: "SMS", value: "S" },
    { label: "MMS", value: "M" },
  ],
};
