import { Container, TrackClickEvent } from "@telia-no-min-side/components";
import { Heading, Text, Icon, Button } from "@telia/teddy";
import { L } from "src/util/links";
import { Link } from "react-router-dom";
import { SwitchHeading } from "../Common/SwitchHeading";
import { URL_INSURANCE_CLAIM_DAMAGE } from "util/constants/agreements";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function ScreenInsurance() {
  return (
    <Container flexDirection="column" alignItems="start" gap="lg">
      <SwitchHeading preamble="Velg telefonens tilstand" heading="Skader på skjermen" />
      <Container flexDirection="column" alignItems="start" gap="lg">
        <Heading as="h4" variant="title-100">
          Forsikringen din dekker skader på skjermen
        </Heading>
        <Text>
          Meld en forsikringssak. Når den har blitt godkjent (tar normalt 10 minutter) kan du bestille en ny telefon.
        </Text>
        <Text>Du trenger ikke gjøre noe med telefonen du har før du får en retureske fra oss.</Text>
        <Text>Det vil komme en egenandel basert på telefonens verdi som vil legges på din neste mobilfaktura.</Text>
      </Container>
      <Container flexDirection="column" alignItems="start" gap>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_screen-damage",
            ui_item_text: "Meld forsikringssak",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: L.TeliaNoUrl.href + URL_INSURANCE_CLAIM_DAMAGE,
          }}
        >
          <Button asChild variant="primary">
            <a href={L.TeliaNoUrl.href + URL_INSURANCE_CLAIM_DAMAGE}>
              Meld forsikringssak
              <Icon name="arrow-right" />
            </a>
          </Button>
        </TrackClickEvent>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_CANCEL,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_screen-damage",
            ui_item_text: "Tilbake til forrige steg",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: "..",
          }}
        >
          <Button asChild variant="text">
            <Link to="..">
              <Icon name="arrow-left" />
              Tilbake til forrige steg
            </Link>
          </Button>
        </TrackClickEvent>
      </Container>
    </Container>
  );
}
