import { Maybe } from "gql/graphql";
import { BRAND_NAME, AFFILIATION } from "./constants";

export function formatDatalayerTransactionData({
  actionId,
  revenue,
  name,
  id,
  price,
  tax,
  category,
  variant,
  shipping = 0,
  isActiveProduct,
  dimensions = {},
}: {
  actionId: string;
  revenue: number | null;
  name?: Maybe<string>;
  id?: Maybe<string>;
  price: number | null;
  tax: number | null;
  category: string | null;
  variant?: string | number;
  shipping?: number;
  isActiveProduct?: boolean;
  dimensions?: Record<string, string | undefined>;
}) {
  return {
    event: "transaction",
    ecommerce: {
      purchase: {
        actionField: {
          id: actionId,
          affiliation: AFFILIATION,
          revenue,
          shipping,
          action: isActiveProduct ? "cancel" : "purchase",
        },
        products: [
          {
            name,
            id,
            price,
            tax,
            brand: BRAND_NAME,
            category,
            quantity: 1,
            affiliation: AFFILIATION,
            variant,
            ...dimensions,
          },
        ],
      },
    },
  };
}
