import { Box, Text, Icon, Flex, Collapsible, color } from "@telia/teddy";
import { useToggleWithGaTracking, Options } from "hooks/useToggleWithGaTracking";

type Props = {
  specifications: string[];
  gaTrackingData: Options;
};

export function DeviceSpecification({ specifications, gaTrackingData }: Props) {
  const { isOpen, toggleIsOpen } = useToggleWithGaTracking(false, gaTrackingData);
  return (
    <Collapsible open={isOpen} onOpenChange={toggleIsOpen}>
      <Collapsible.Trigger asChild>
        <Flex gap="100">
          <Text color={color.teddyColorPurple700} variant="paragraph-100-bold">
            Se spesifikasjoner
          </Text>
          <Icon color={color.teddyColorPurple700} name={isOpen ? "chevron-down" : "chevron-up"} size="font" />
        </Flex>
      </Collapsible.Trigger>
      <Collapsible.Content>
        <Box mt="200">
          {specifications.map((specification, index) => (
            <Flex key={index} gap="200" mt="50">
              <Icon name="checkmark" size="font" color={color.teddyColorGreen700} />
              <Text variant="additional-100">{specification}</Text>
            </Flex>
          ))}
        </Box>
      </Collapsible.Content>
    </Collapsible>
  );
}
