import { OperationVariables, useLazyQuery, useQuery } from "@apollo/client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PrefetchOptions<T extends (...args: any) => any> = Omit<
  NonNullable<Parameters<T>[1]>,
  "nextFetchPolicy" | "fetchPolicy"
>;

export function usePrefetchLazyQuery<T, V extends OperationVariables = OperationVariables>(
  query: Parameters<typeof useLazyQuery<T, V>>[0],
  options?: PrefetchOptions<typeof useLazyQuery<T, V>>
) {
  return useLazyQuery<T, V>(query, {
    nextFetchPolicy: "standby",
    fetchPolicy: "cache-first",
    ...options,
  });
}

export function usePrefetchQuery<T, V extends OperationVariables = OperationVariables>(
  query: Parameters<typeof useQuery<T, V>>[0],
  options?: PrefetchOptions<typeof useQuery<T, V>>
) {
  useQuery<T, V>(query, {
    nextFetchPolicy: "standby",
    fetchPolicy: "cache-first",
    ...options,
  });
}

export function PrefetchQuery<T, V extends OperationVariables = OperationVariables>(args: {
  query: Parameters<typeof useQuery<T, V>>[0];
  options: PrefetchOptions<typeof useQuery<T, V>>;
}) {
  usePrefetchQuery(args.query, args.options);
  return null;
}
