import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { logFixedApiError, isUnauthorizedResponse } from "@telia-no-min-side/utils";
import { getMinSideUserToken } from "../context/auth";
import { handleUnauthorizedResponse } from "./apiError";

// Deprecated. Used only in a few places at apps/min-side
// Can be removed after me move code from apps/min-side

function getRequestConfig<T>(config?: AxiosRequestConfig<T>, userToken?: string): AxiosRequestConfig<T> {
  if (userToken) {
    return {
      ...config,
      headers: { "identity-token": userToken, ...config?.headers },
    };
  }
  return {
    ...config,
  };
}

export async function axiosGet<ResponseData = unknown>(
  url: string,
  config?: AxiosRequestConfig<ResponseData>
): Promise<AxiosResponse<ResponseData | undefined, Error | undefined>> {
  try {
    const userToken = getMinSideUserToken();
    const response = await axios.get<ResponseData>(url, getRequestConfig<ResponseData>(config, userToken));

    if (response.status === 204) {
      return { ...response, data: undefined };
    }
    return response;
  } catch (error) {
    logFixedApiError(error, { url });

    if (isUnauthorizedResponse(error)) {
      handleUnauthorizedResponse(error);
    }
    throw error;
  }
}

export async function axiosPost<ResponseData = unknown, RequestData = unknown>(
  url: string,
  data?: RequestData,
  config?: AxiosRequestConfig<ResponseData>
) {
  try {
    const userToken = getMinSideUserToken();
    const response = await axios.post<ResponseData>(url, data, getRequestConfig<ResponseData>(config, userToken));
    return response.data;
  } catch (error) {
    logFixedApiError(error, { url });

    if (isUnauthorizedResponse(error)) {
      handleUnauthorizedResponse(error);
    }

    throw error;
  }
}
