import styled from "styled-components";

const tablet = "767px";

export const Field = styled.div`
  display: flex;
  margin: 1rem 0;
  align-items: center;

  @media all and (max-width: ${tablet}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  div:nth-of-type(1),
  label {
    min-width: 9.5rem;
  }

  label:nth-of-type(2) {
    width: 100%;
  }

  div:nth-of-type(2) {
    font-weight: 500;
  }

  p {
    margin: 0;
  }
`;

export const ButtonGap = styled.div`
  margin: 2rem 0;
  display: flex;
  gap: 0.5rem;
`;

export const FieldBlock = styled.div`
  margin: 1rem 0;

  div:nth-of-type(2) {
    margin: 0.2rem 0;
    font-weight: 500;
  }

  p {
    margin: 0;
  }
`;
