import { graphql } from "src/gql";

export const GET_ROAMING_RESTRICTION = graphql(`
  query getRoamingRestrictionNEW($phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        countryCode
        localNumber
      }
      roles
      agreementType
      additionalProducts(manualProduct: "STOP_TOPUP") {
        id
        code
        presentation {
          active
          modifiable
        }
      }
    }
  }
`);
