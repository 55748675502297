import styled from "styled-components";
import { colors } from "@telia/styleguide";
import { remCalc } from "@telia-no-min-side/components";

export const Component = styled.div`
  @media (min-width: ${remCalc(500)}) {
    display: flex;
    gap: ${remCalc(32)};
  }
`;

export const TermContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${remCalc(5)};
  margin-bottom: initial;

  .Icon {
    width: 1rem;
    height: 1rem;
    color: ${colors.corePurple};
  }

  @media (max-width: ${remCalc(500)}) {
    margin-bottom: ${remCalc(24)};
  }
`;
