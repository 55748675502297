export type SwitchReturnOption = {
  title: string;
  text: string;
  path: string;
};

export const switchReturnOptions: SwitchReturnOption[] = [
  {
    title: "Ingen skader",
    text: "Telefonen har ingen skader bortsett fra normal bruksslitasje.",
    path: "/ingen-skader",
  },
  {
    title: "Skader på skjerm",
    text: "Det er kun skader på skjermen. For eksempel sprekker, dype riper eller knuste områder i skjermen.",
    path: "/skader-på-skjerm",
  },
  {
    title: "Skader på telefonen",
    text: "Det er skader på telefonen. For eksempel skader på kamera eller feil på ladeinnganger.",
    path: "/skader-på-telefonen",
  },
  {
    title: "Stjålet eller mistet",
    text: "Telefonen er stjålet eller mistet.",
    path: "/stjålet-eller-mistet",
  },
  {
    title: "Min forsikringssak er ferdigbehandlet",
    text: "Du kan velge deg ny telefon i nettbutikken.",
    path: "/forsikringssak-er-ferdigbehandlet",
  },
];
