import { Button, Card, Icon, Text, TextSpacing } from "@telia/teddy";
import { Link } from "react-router-dom";
// NOTE: There is an issue with BankId verification for newly created users (amr - totp).
// While CIAM is looking at it, we decided to remove this option as a short term solution.
// For more information contact Justas Sobutas

export function GetAccessOptions() {
  // const { requestBankId } = useAuth();
  return (
    <Card bordered variant="white">
      <TextSpacing>
        <Card.Heading as="h2">
          <Icon name="lock-open">Få tilgang til dine Telia-produkter</Icon>
        </Card.Heading>
        <Card.Content>
          <Text>For å få tilgang til dine abonnement må du verifisere deg.</Text>
          <Button variant="primary" asChild>
            <Link to="/minside/midlertidig-tilgang">Verifiser deg med engangskode</Link>
          </Button>
          {/* <Text>For å få tilgang til dine abonnement må du verifisere deg. Velg en av måtene under:</Text>
          <Flex direction="column" gap="200">
            <Card bordered variant="white">
              <TextSpacing>
                <Card.Heading as="h3">Full tilgang</Card.Heading>
                <Card.Content>
                  <TextSpacing>
                    <Text>
                      Gir full tilgang på tvers av produktene dine. Du trenger kun å bekrefte hvem du er med bankID én
                      gang.
                    </Text>
                    <Button
                      variant="primary"
                      disabled={!requestBankId}
                      onClick={() => requestBankId && requestBankId()}
                    >
                      Verifiser deg med BankID
                    </Button>
                  </TextSpacing>
                </Card.Content>
              </TextSpacing>
            </Card>
            <Card bordered variant="white">
              <TextSpacing>
                <Card.Heading as="h3">Brukertilgang</Card.Heading>
                <Card.Content>
                  <TextSpacing>
                    <Text>
                      Gir kun midlertidig tilgang til ett av dine abonnement i 12 måneder. Etter det må man verifisere
                      seg på nytt.
                    </Text>
                    <Button variant="primary" asChild>
                      <Link to="/minside/midlertidig-tilgang">Verifiser deg med engangskode</Link>
                    </Button>
                  </TextSpacing>
                </Card.Content>
              </TextSpacing>
            </Card>
          </Flex> */}
        </Card.Content>
      </TextSpacing>
    </Card>
  );
}
