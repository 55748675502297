import { DATA_UNLIMITED_ADDITIONAL_PROPERTY } from "util/format-subscription/variables";
import { notEmpty } from "@telia-no-min-side/utils";
import { isJuniorSubscription } from "util/format-subscription/isJuniorSubscription";
import { Maybe } from "gql/graphql";
import { MobileOfferForSale } from "../../types";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";

export function groupMobileOffersForSaleByCategory<T extends Maybe<MobileOfferForSale>>(
  offersForSale: Maybe<Maybe<T>[]> | undefined
) {
  const sortedOffers = (offersForSale || []).sort((a, b) => {
    if (!a?.weight || !b?.weight) return 0;
    return a.weight - b.weight;
  });

  const [juniorOffers, adultOffers] = sortedOffers.filter(notEmpty).reduce<NonNullable<T>[][]>(
    (groupedOffers, offer) => {
      isJuniorSubscription(offer.additionalProperties) ? groupedOffers[0].push(offer) : groupedOffers[1].push(offer);
      return groupedOffers;
    },
    [[], []]
  );

  const bucketOffers = adultOffers.filter((offering) => {
    return !offering?.additionalProperties?.filter(
      (prop) => prop?.name === DATA_UNLIMITED_ADDITIONAL_PROPERTY && prop?.value === "true"
    ).length;
  });

  const unlimitedDataOffers = adultOffers.filter((offering) => {
    return offering?.additionalProperties?.some(
      (prop) => prop?.name === DATA_UNLIMITED_ADDITIONAL_PROPERTY && prop?.value === "true"
    );
  });

  const hasBucketOffers = !!bucketOffers?.length;
  const hasUnlimitedDataOffers = !!unlimitedDataOffers?.length;
  const hasJuniorOffers = !!juniorOffers?.length;
  const hasAnyOffers = hasBucketOffers || hasUnlimitedDataOffers || hasJuniorOffers;
  const hasMultipleCategories =
    [hasBucketOffers, hasUnlimitedDataOffers, hasJuniorOffers].filter((subscriptionCategory) => !!subscriptionCategory)
      .length > 1;

  const isJuniorSummerCampaignActive = (juniorOffers || []).some((offer) =>
    isJuniorSummerCampaign(offer.pricePlan || "")
  );

  return {
    juniorOffers,
    bucketOffers,
    unlimitedDataOffers,
    hasBucketOffers,
    hasUnlimitedDataOffers,
    hasJuniorOffers,
    hasMultipleCategories,
    hasAnyOffers,
    isJuniorSummerCampaignActive,
  };
}
