import { Container, Skeleton } from "@telia-no-min-side/components";
import { InvoiceGroup } from "src/gql/graphql";
import { DetailsElementSkeleton } from "src/pages/invoice/components/DetailsElementSkeleton";
import { getPriceAmountText } from "src/util/priceFormatter";
import { ColorCode, Cost, Refund, SumCell, SumTextCell, TotalSumGrid } from "../../styles";
import { SubDetailsElementMobile } from "./SubDetailsElementMobile";

type Props = {
  detailsIsOpen: boolean[];
  invoiceGroup?: (InvoiceGroup | null)[] | null;
  totalAmount?: number;
  accordionStates: boolean[];
  setAccordionStates: (value: boolean[]) => void;
  invoiceDetailsIsLoading: boolean;
  phoneNumber?: string;
  invoiceId?: string | null;
  isProductUser: boolean;
};

export const SubDetailsElementList = ({
  detailsIsOpen,
  invoiceGroup,
  accordionStates,
  setAccordionStates,
  totalAmount,
  invoiceDetailsIsLoading,
  phoneNumber,
  invoiceId,
  isProductUser,
}: Props) => {
  const toggleAccordion = (index: number): void => {
    const newAccordionStates = [...accordionStates];
    newAccordionStates[index] = !newAccordionStates[index];
    setAccordionStates(newAccordionStates);
  };

  return (
    <Container>
      {invoiceDetailsIsLoading && (
        <>
          <DetailsElementSkeleton />
          <DetailsElementSkeleton />
          <DetailsElementSkeleton />
        </>
      )}
      <ColorCode fadeIn={detailsIsOpen.some((detail) => detail)} maxWidth="sm" padding="horizontal">
        <span>Fargekoder:</span>
        <Cost>Kostnad</Cost>
        <Refund>Kreditert/rabattert</Refund>
      </ColorCode>
      {invoiceGroup?.map((item: InvoiceGroup | null, index: number) => (
        <SubDetailsElementMobile
          key={`${item?.name}${item?.totalAmount?.value}-${index}`}
          invoiceGroupItem={item && item}
          isOpen={detailsIsOpen[index]}
          toggleIsOpen={() => toggleAccordion(index)}
          phoneNumber={phoneNumber}
          invoiceId={invoiceId}
          isProductUser={isProductUser}
        />
      ))}
      <TotalSumGrid maxWidth="sm">
        <SumTextCell>Totalsum</SumTextCell>
        <SumCell data-di-mask>
          {!invoiceDetailsIsLoading && getPriceAmountText(totalAmount, "kr")}
          {invoiceDetailsIsLoading && <Skeleton width="10ch" />}
        </SumCell>
      </TotalSumGrid>
    </Container>
  );
};
