import { types } from "@telia-no-min-side/utils";
import { productId } from "src/util/productIds";

export type AvailableSmartWifiProducts = {
  smartWifiRouter?: types.fixed.ProductGroupSingleProductWithShipping;
  smartWifiExtender?: types.fixed.ProductGroupSingleProductWithShipping;
  teliaWifiRouter?: types.fixed.ProductGroupSingleProductWithShipping;
  teliaPlayBox?: types.fixed.ProductGroupSingleProductWithShipping;
  noBox?: types.fixed.ProductGroupSingleProductWithShipping;
  recording?: types.fixed.ProductGroupSingleProductWithShipping;
};
type ArrayType<T> = T extends (infer Item)[] ? Item : T;

export function getAvailableProducts(
  requirementOptions: types.fixed.FlexProductRequirement["options"]
): AvailableSmartWifiProducts {
  const allRequirementOptions = requirementOptions.map((option) => {
    if (productId.teliaPlayBox == option.productId) {
      return { teliaPlayBox: option };
    }
    if (productId.noBox == option.productId) {
      return { noBox: option };
    }
    if (productId.recording === option.productId) {
      return { recording: option };
    }
    if (productId.smartWifiRouters.includes(option.productId)) {
      return { smartWifiRouter: option };
    }
    if (productId.smartWifiExtender === option.productId) {
      return { smartWifiExtender: option };
    }
    if (productId.teliaWifiRouter === option.productId) {
      return { teliaWifiRouter: option };
    }
    return { unknown: option };
  });

  type OptionKeys = keyof ArrayType<typeof allRequirementOptions>;
  const flatAllRequirementOptions: Partial<
    Record<Exclude<OptionKeys, "unknown">, types.fixed.ProductGroupSingleProductWithShipping>
  > = allRequirementOptions.reduce((prev, curr) => {
    if (curr.unknown) return prev;
    return { ...prev, ...curr };
  }, {});
  return flatAllRequirementOptions;
}
