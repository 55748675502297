import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchProductRestrictions() {
  const { data, error } = useAxiosSWR<types.fixed.ProductRestrictions>(ICX_REQUEST.URL_PRODUCT_RESTRICTIONS);

  return {
    productRestrictions: data,
    isLoading: !error && !data,
    isError: !!error,
    error: error,
  };
}
