import { getSimplePriceText } from "@telia-no-min-side/utils";
import { AdditionalProduct } from "gql/graphql";

export const summerCampaignTeliaTryggCodes = [
  "TRYCH01+TRYBNG03",
  "TRYCH01+TRYBNG05",
  "TRYCH01+TRYBNG07",
  "TRYCH01+TRYBNG09",
];

export const getOfferSummary = (product: AdditionalProduct) => {
  if (!product) return "";
  return `Kampanje! ${product?.price?.discountDuration === 1 ? "1 md." : `${product.price?.discountDuration} md.`} ${
    product.price?.price ? getSimplePriceText(product.price.price, "md.") : `kostnadsfritt`
  }`;
};
export const isSummerCampaign = (productCode: string | undefined | null) =>
  productCode && summerCampaignTeliaTryggCodes.includes(productCode);
export const getCampaignSummerText = (productCode: string | undefined | null) =>
  productCode && summerCampaignTeliaTryggCodes.includes(productCode) ? "Sommerkampanje" : "Kampanje";
