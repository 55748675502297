export const URL_INSURANCE_AGREEMENT_TERMS = "https://www.telia.no/trygghet/tjenester/mobilforsikring/#vilkar";
export const URL_LEASE_AGREEMENT_TERMS = "https://www.telia.no/trygghet/tjenester/mobilforsikring/#vilkar";
export const URL_SWITCH_AGREEMENT_TERMS =
  "https://www.telia.no/mobil/mobilabonnement/mobil-med-abonnement/svitsj#vilkar";

export const URL_CHECK_PHONE_REPAIR = "https://dst.telia.com/online/no/deviceSearch/LandingPage";
export const URL_REPORT_THEFT_OR_DAMAGE =
  "https://www.politiet.no/tjenester/anmelde-tyveri-eller-skade/anmeldelse/?steg1=2";
export const URL_INSURANCE_CLAIM_DAMAGE = "/trygghet/tjenester/mobilforsikring/meld-skade/";
export const URL_INSURANCE_PROOF = "forsikringsbevis";
export const URL_INSURANCE_POLICY_IPID = "https://www.telia.no/mobiltelefoner/mobilforsikring/#ipid";

export const SCREEN_INSURANCE_DESCRIPTION =
  "Skjermforsikringen dekker skader på skjermen, frontkamera og fingeravtrykkssensoren. I tillegg dekkes andre skader på forsiden som påvirker mobilens funksjonalitet.";

export const PLUS_INSURANCE_DESCRIPTION = [
  "Dekker skader på skjerm, frontkamera og fingeravtrykkssensoren",
  "Dekker skader",
  "Dekker fuktskader",
  "Dekker tyveri",
  "Dekker tap eller mistet telefon",
];

export enum AGREEMENT_TYPE {
  SWITCH = "Svitsj-avtale",
  LEASE = "Delbetaling",
  PLUS_INSURANCE = "Plussforsikring",
  SCREEN_INSURANCE = "Skjermforsikring",
}

export enum INSURANCE_TYPE {
  SCREEN = "skjermforsikring",
  PLUS = "plussforsikring",
}

export enum CANCEL_INSURANCE_TEXT {
  SUCCESS = "Forsikringen din er kansellert. Det kan ta opptil 15 minutter før du ser endringen her.",
  ERROR = "En feil oppsto. Prøv igjen om noen minutter, eller ta kontakt med kundesenteret.",
}

// if INSURANCE_SWITCH_SCREEN_DUMMY_EXISTING is present, they want to continue with screen insurance after switch
export const DUMMY_SCREEN_INSURANCE_PRODUCT = "INSURANCE_SWITCH_SCREEN_DUMMY_EXISTING";

// The offer is valid for 30 days from the purchase date of the new phone
// Indicates insurance availability: "Plus insurance" or "Screen Insurance" for new phone
export const INSURANCE_POST_DUMMY_EXISTING = "INSURANCE_POST_DUMMY_EXISTING";
