import dayjs from "dayjs";
import { Container, Icon, Paragraph } from "@telia-no-min-side/components";
import { PLUS_INSURANCE_DESCRIPTION, SCREEN_INSURANCE_DESCRIPTION } from "util/constants/agreements";

type Props = {
  monthlyPrice: number | undefined;
  startDate: string;
  remainingPrice: number | undefined;
  isScreenInsurance: boolean;
};

export function AgreementInfo({ monthlyPrice, remainingPrice, startDate, isScreenInsurance }: Props) {
  return (
    <Container flexDirection="column" gap="lg">
      <Container flexDirection="row" horizontalPosition="left">
        {monthlyPrice ? (
          <Container>
            <Paragraph isFaded removeMargin>
              Månedspris
            </Paragraph>
            <Paragraph data-di-mask removeMargin>
              {monthlyPrice},-
            </Paragraph>
          </Container>
        ) : null}

        {startDate && (
          <Container>
            <Paragraph isFaded removeMargin>
              Avtale startet
            </Paragraph>
            <Paragraph data-di-mask removeMargin>
              {dayjs(startDate).format("DD.MM.YYYY")}
            </Paragraph>
          </Container>
        )}

        {remainingPrice && (
          <Container>
            <Paragraph isFaded removeMargin>
              Egenandel
            </Paragraph>
            <Paragraph data-di-mask removeMargin>
              {remainingPrice},-
            </Paragraph>
          </Container>
        )}
      </Container>

      {isScreenInsurance ? (
        <Paragraph>{SCREEN_INSURANCE_DESCRIPTION}</Paragraph>
      ) : (
        <Container>
          <Paragraph>
            <em>Du har en Plussforsikring som dekker:</em>
          </Paragraph>
          <Container flexDirection="column" gap>
            {PLUS_INSURANCE_DESCRIPTION.map((description, index) => (
              <Container key={index} flexDirection="row" alignItems="end" gap>
                <Icon icon="check-mark" size="md" />
                <Paragraph>{description}</Paragraph>
              </Container>
            ))}
          </Container>
        </Container>
      )}
    </Container>
  );
}
