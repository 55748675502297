import { SWRResponse } from "swr";
import { types } from "@telia-no-min-side/utils";

export type ReturnData<GetCustomerSpecificUrl, Data> = GetCustomerSpecificUrl extends GetCustomerAllUrlArgs
  ? types.fixed.AllUsersRequest<Data>
  : Data;

export type GeneralSWRResponse<GetCustomerSpecificUrl, Data> = {
  isError: boolean;
  noContent?: boolean;
  isLoading: boolean;
  isWaitingKey?: boolean;
} & SWRResponse<ReturnData<GetCustomerSpecificUrl, Data>>;

export type GetCustomerIdUrlArgs = {
  customerId: string | undefined;
  requireCustomerId?: boolean;
} & {
  fetchAllAccounts?: never;
};

export type GetCustomerAllUrlArgs = {
  fetchAllAccounts: true;
} & {
  customerId?: never;
  requireCustomerId?: never;
};

export type GetCustomerSpecificUrlArgs = GetCustomerAllUrlArgs | GetCustomerIdUrlArgs;

export function getCustomerSpecificUrl(url: string, args?: GetCustomerSpecificUrlArgs) {
  if (args?.fetchAllAccounts) {
    return `${url}/all`;
  }
  if (args?.customerId) {
    return `${url}/${args.customerId}`;
  }

  if (args?.requireCustomerId && !args?.customerId) {
    return null;
  }
  return url;
}
