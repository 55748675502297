// IMPORTANT. Do not add non API links to this file
// When it's a link user can click, we classify them
// as either internalLinks or externalLinks.
// So use those files in order to add links
export enum ICX_REQUEST {
  URL_LOYALTY = "/minside/api/loyalty",
  URL_LAST_INVOICE = "/minside/api/invoices/renewed/recent",
  URL_INVOICES_SETTINGS = "/minside/api/invoices/settings",
  URL_INVOICES_HISTORY_OVERVIEW = "/minside/api/invoices/history/overview",
  URL_INVOICE_HISTORY = "/minside/api/invoices/history/detailed",
  URL_INVOICE_DETAILS = "/minside/api/invoices/details",
  URL_INVOICE_POSTPONE = "/minside/api/invoices/postpone",
  URL_FLEX_REQUIREMENTS = "/api/flex/requirements",
  URL_FLEX_CURRENT = "/minside/bestilling/api/flex/current",
  URL_FINANCIAL = "/minside/api/financial",
  URL_BROADBAND_CHANGE = "/minside/bestilling/api/broadbands/change",
  URL_BROADBAND_PRODUCT = "/minside/api/products/category/broadband_product",
  URL_FWA_ANTICHURN = "/api/fwa-antichurn/all",
  URL_ALL_INVOICE_HISTORY = "/minside/api/invoices/history",
  URL_FLEX_PRICES = "/minside/bestilling/api/flex/prices",
  URL_FWA_OFFERS = "/minside/api/offers/fwa/active",
  URL_FWA_OFFERS_EXPIRING = "/minside/api/offers/fwa/expiring",
  URL_FWA_OFFERS_PRICE_INFO = "/minside/api/offers/fwa/prices-with-and-without-offers",
  URL_FWA_OFFERS_PORTFOLIO = "/minside/api/offers/fwa/portfolio",
  URL_DOWNLOAD_INSIGHT = "/api/roa/downloadlink",
  URL_INSIGHT_STATUS = "/api/roa/status",
  URL_MOVIE_RENTAL = "/minside/api/movie/rental",
  URL_NETWORK_STATISTICS = "/minside/api/network-statistics/statistics",
  URL_PREMIUM_CHANNELS = "/minside/api/products/category/premium_channels",
  URL_PRODUCT_GROUP = "/minside/bestilling/api/productgroup/with-shipping",
  URL_PRODUCT_RESTRICTIONS = "/minside/bestilling/api/productgroup/product-restrictions",
  URL_PRODUCTS_ADDITIONAL_INFO = "/minside/api/products/additional-info",
  URL_GET_PROFILE = "/minside/api/profile/get",
  URL_GET_ALL_PROFILE = "/minside/api/profile/get/all",
  URL_SAFE_ON_NET = "/minside/api/products/category/broadband_safe_on_net",
  URL_SELECTED_ACCOUNT = "/minside/api/user/selectedAccount",
  URL_TELIA_PLAY_BOX_INFO = "/api/telia-play-box/info",
  URL_TELIA_PLAY_BOX_SUBMIT = "/api/telia-play-box/submit",
  URL_PRODUCTS_TO_BE_RETURNED = "/minside/api/products/tobereturned",
  URL_TV_BOXES = "/minside/api/products/category/tv_boxes",
  URL_ALL_TV_BOXES = "/minside/api/products/category/tv_boxes/all",
  URL_TV_OVER_FWA = "/minside/bestilling/api/tv-over-fwa/prices",
  URL_TV_RECORD = "/minside/api/products/category/tv_record",
  URL_TV_SUBSCRIPTION = "/minside/api/products/category/tv_subscription",
  URL_USER = "/minside/api/user",
  URL_TECHNICIAN_VISIT = "/minside/visit/api/customer/technician-visit",
  URL_BROADBAND_SUBSCRIPTION = "/minside/api/products/category/broadband_subscription",
  URL_ALL_BROADBAND_SUBSCRIPTION = "/api/products/category/broadband_subscription/all",
  URL_FINANCE = "/api/finance",
  URL_PAYMENT_METHOD_DATA = "/minside/api/paase/payment/payment-method-data",
  URL_PAYMENT_SUBMIT = "/api/paase/payment/submit",
  URL_TRANSACTIONS_DETAILS = "/api/paase/payment/details",
  URL_MULTIPLE_PRODUCTS_SUBMIT = "/api/order/multiple-products/submit",
  URL_TELIA_SKY_STATUS = "/minside/api/user/teliaskystatus",
  URL_COMPENSATION_SWITCH = "/minside/api/compensation/switch",
  URL_COMPENSATION_ALL = "/minside/api/compensation/all",
  // TODO: move these links either to internalLinks or externalLinks
  // They are not part of the API
  URL_CHANGE_BROADBAND = "/bredband/endre",
  URL_INTERNET_EQUIPMENT = "/internett/utstyr",
  URL_BUY_SMART_WIFI = "/internett/utstyr/kjop-smart-wifi",
  URL_ORDER_TV = "/bestill/tv",
  URL_CHANNEL_POINTS_CHANGE = "/kanalpoeng/endre",
}
