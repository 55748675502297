import { Link } from "react-router-dom";
import { formatDataAmount, uri } from "@telia-no-min-side/utils";
import { Card, Button, Flex, Icon, Text, Badge } from "@telia/teddy";
import { useMobileAccount } from "hooks/useMobileAccount";
import { HUNDRED_MB } from "../utils";

type Props = {
  offerUpgrade: boolean | undefined;
  totalBaseData: number;
  averageDataUsage: number;
};

export function OfferingCards({ offerUpgrade, totalBaseData, averageDataUsage }: Props) {
  const { accountId, phoneNumber } = useMobileAccount();

  return (
    <Flex direction="column" gap="gutter-xl">
      {offerUpgrade && (
        <Card variant="purple-light" width="100%" maxWidth="470px">
          <Flex direction="column" gap="100">
            <Flex justify="start">
              <Badge variant="warning" hideIcon>
                <Icon name="bulb">Tips!</Icon>
              </Badge>
            </Flex>
            <Text>{` Du har ${formatDataAmount(
              totalBaseData
            )} i måneden inkludert i ditt abonnement. Vil du bytte til et abonnement som passer bedre til
    ditt forbruk?`}</Text>

            <Flex justify="end">
              <Button asChild variant="text">
                <Link to={uri.minSideMobile(`/endre/${accountId}/${phoneNumber}`)}>Bytt abonnement</Link>
              </Button>
            </Flex>
          </Flex>
        </Card>
      )}

      {averageDataUsage < HUNDRED_MB && (
        <Card variant="purple-light" width="100%" maxWidth="470px">
          <Flex direction="column" gap="100">
            <Flex justify="start">
              <Badge variant="warning" hideIcon>
                <Icon name="bulb">Tips!</Icon>
              </Badge>
            </Flex>
            <Text>
              Det ser ut til at du ikke har brukt data de siste 6 månedene. Sjekk ut hvilket abonnement som passer ditt
              bruk!
            </Text>

            <Flex justify="end">
              <Button asChild variant="text">
                <Link to={uri.minSideMobile(`/endre/${accountId}/${phoneNumber}`)}>Bytt abonnement</Link>
              </Button>
            </Flex>
          </Flex>
        </Card>
      )}
    </Flex>
  );
}
