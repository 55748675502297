import {
  Button,
  Card,
  Container,
  Heading,
  Icon,
  Paragraph,
  Skeleton,
  TextInput,
  useAuth,
  useFormHandling,
  useToast,
} from "@telia-no-min-side/components";
import { track, uri } from "@telia-no-min-side/utils";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomerCareLink } from "src/components/customer-care-link";
import { BANKID_ACR_VALUE } from ".";
import { FormStateField, ProfileFormFields } from "../types";
import { useFetchUser } from "src/api/fixed";
import { useOnFixedAccountChange } from "src/hooks/useFixedAccount";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { AUTH } from "util/constants/auth";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { ADDRESS_MESSAGE } from "util/constants/address";

export type UpdateRequest = { customerId: string; value: string };
export type UpdateResult = Record<string, unknown>;

export function ChangeProfileInfo() {
  const user = useFetchUser();
  const { addToast } = useToast();
  const { onFixedAccountChange } = useOnFixedAccountChange();
  const selectedUser = user.data?.usersWithCustomerId.find((user) => user.selected);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { tokenParsed, setAuthAdapter, requestBankId } = useAuth();
  const isLoggedInWithBankId = tokenParsed?.acr === BANKID_ACR_VALUE;
  const updateEmail = useAxiosPost<UpdateResult, UpdateRequest>(uri.minSideFixed("/api/profile/change/email"));

  const updateMobile = useAxiosPost<UpdateResult, UpdateRequest>(uri.minSideFixed("/api/profile/change/mobile"));
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const initialState = {
    email: {
      value: searchParams.get("email") || selectedUser?.email || "",
      isDirty: !!searchParams.get("email"),
      isValid: true,
    },
    mobile: {
      value: searchParams.get("mobile") || selectedUser?.mobile || "",
      isDirty: !!searchParams.get("mobile"),
      isValid: true,
    },
  };
  const { state, dispatch, Actions } = useFormHandling(initialState);
  const showBankIdModal = !isLoggedInWithBankId && (initialState.email.isDirty || initialState.mobile.isDirty);

  useEffect(() => {
    if (user.data && !state.form.email.isDirty && !state.form.mobile.isDirty && selectedUser) {
      dispatch({
        type: Actions.SET_FORM,
        payload: {
          email: {
            value: selectedUser.email,
            isDirty: false,
            isValid: true,
          },
          mobile: {
            value: selectedUser.mobile,
            isDirty: false,
            isValid: true,
          },
        },
      });
    }
  }, [user.data]);

  async function handleOnSubmit() {
    try {
      const email = updateEmail.post({
        customerId: selectedUser?.customerId || "",
        value: state.form.email.value || "",
      });

      const mobile = updateMobile.post({
        customerId: selectedUser?.customerId || "",
        value: state.form.mobile.value || "",
      });
      await Promise.all([email, mobile]);
      onFixedAccountChange();
      pushGenericTrackingEvent({
        ui_item_action: TRACK_EVENT.ITEM_ACTION.SUBMIT_SUCCESS,
        ui_item_context: TRACK_EVENT.ITEM_CONTEXT.PROFILE,
        ui_item_type: TRACK_EVENT.ITEM_TYPE.SUBMIT,
        ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
        ui_item_text: TRACK_EVENT.ITEM_TEXT.CONTACT_INFO_CHANGE_SUCCESS,
      });
      addToast({
        text: ADDRESS_MESSAGE.CONTACT_INFO_CHANGE_SUCCESS,
        variant: ADDRESS_MESSAGE.SUCCESS,
      });
      navigate(uri.minSideFixed("/profil/personvern"));
    } catch (e) {
      addToast({
        text: ADDRESS_MESSAGE.CONTACT_INFO_CHANGE_ERROR,
        variant: ADDRESS_MESSAGE.ERROR,
      });
      pushGenericTrackingEvent({
        ui_item_action: TRACK_EVENT.ITEM_ACTION.SUBMIT_ERROR,
        ui_item_context: TRACK_EVENT.ITEM_CONTEXT.PROFILE,
        ui_item_type: TRACK_EVENT.ITEM_TYPE.SUBMIT,
        ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
        ui_item_text: TRACK_EVENT.ITEM_TEXT.CONTACT_INFO_CHANGE_ERROR,
      });
    }
  }

  const handleFieldChange = (fieldState: FormStateField, name: keyof ProfileFormFields, value: string) => {
    dispatch({
      type: Actions.SET_FORM,
      payload: {
        [name]: {
          ...fieldState,
          value: fieldState.formatting?.(value) || value,
          isDirty: true,
          isValid: !!value,
        },
      },
    });
  };

  if (showBankIdModal) {
    return (
      <Container showGoBackButton padding>
        <Card padding="lg" maxWidth="sm">
          <Heading tag="h3" variant="title-200">
            <Container flexDirection="row" gap floatContent="center">
              <Icon icon="lock-locked" title="Åpne opp" />
              Du må logge inn med BankID
            </Container>
          </Heading>
          <Paragraph variant="paragraph-200">
            For at du skal redigere din kontaktinformasjon så må du logge inn med BankID.
          </Paragraph>
          <Paragraph>
            <Button
              trackEvent={{
                ui_item_action: TRACK_EVENT.ITEM_ACTION.BANKID_LOGIN,
                ui_item_context: TRACK_EVENT.ITEM_CONTEXT.PROFILE,
                ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
                ui_item_url: TRACK_EVENT.ITEM_URL.IDENTITY,
                ui_item_text: TRACK_EVENT.ITEM_TEXT.BANK_ID_VERIFY,
              }}
              onClick={() => (requestBankId ? requestBankId() : setAuthAdapter(AUTH.PROVIDER.CIAM))}
            >
              Verifiser deg med BankID
            </Button>
          </Paragraph>
          <Paragraph isFaded variant="paragraph-200">
            Har du ikke mulighet til å bruke BankID, er du velkommen til å kontakte kundeservice for å løse denne
            situasjonen.
          </Paragraph>
          <Paragraph>
            <CustomerCareLink trackingContext="Profil" />
          </Paragraph>
        </Card>
      </Container>
    );
  }

  return (
    <Container showGoBackButton padding maxWidth="sm">
      <Heading tag="h2" variant="title-200">
        Endre kontaktinformasjon
      </Heading>
      {user.isLoading && (
        <Container flexDirection="column" gap padding="vertical">
          <Container gap>
            <Skeleton variant="text" width="10ch" />
            <Skeleton variant="text" width="25ch" />
          </Container>
          <Container gap>
            <Skeleton variant="text" width="10ch" />
            <Skeleton variant="text" width="25ch" />
          </Container>
          <Skeleton variant="button" width="20ch" />
        </Container>
      )}
      {!user.isLoading &&
        (selectedUser ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!isLoggedInWithBankId) {
                return setSearchParams({ email: state.form.email.value, mobile: state.form.mobile.value });
              }
              handleOnSubmit();
            }}
          >
            <Container gap="lg" padding="vertical" flexDirection="column">
              <TextInput
                value={state.form.email.value}
                required
                name="email"
                type="email"
                onChange={(e) => {
                  handleFieldChange(state.form.email, "email", e.currentTarget.value);
                }}
                errorMessage={
                  !state.form.email.isValid && state.form.email.isDirty ? ADDRESS_MESSAGE.REQUIRED_FIELD : undefined
                }
              >
                E-post
              </TextInput>

              <TextInput
                value={state.form.mobile.value}
                required
                name="mobile"
                type="tel"
                onChange={(e) => {
                  handleFieldChange(state.form.mobile, "mobile", e.currentTarget.value);
                }}
                errorMessage={
                  !state.form.mobile.isValid && state.form.mobile.isDirty ? ADDRESS_MESSAGE.REQUIRED_FIELD : undefined
                }
              >
                Telefon
              </TextInput>
            </Container>

            <Container gap>
              <Button
                variant="secondary"
                onClick={() => {
                  dispatch({ type: Actions.RESET });
                  navigate(uri.minSideFixed("/profil/personvern"));
                }}
              >
                Avbryt
              </Button>
              <Button
                type="submit"
                isLoading={updateEmail.isLoading || updateMobile.isLoading}
                disabled={!state.form.mobile.isValid || !state.form.email.isValid}
              >
                Lagre
              </Button>
            </Container>
          </form>
        ) : (
          <Container padding="top">
            <Paragraph>Beklager! En uventet feil skjedde.</Paragraph>
            <Paragraph>Vennligst prøv igjen litt senere.</Paragraph>
          </Container>
        ))}
    </Container>
  );
}
