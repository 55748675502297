import { Container, Skeleton } from "@telia-no-min-side/components";

export function SkeletonInfo() {
  return (
    <Container flexDirection="column" gap padding="vertical">
      <Container flexDirection="column" gap>
        <Skeleton variant="text" width="10ch" />
        <Skeleton variant="text" width="25ch" />
      </Container>
      <Container flexDirection="column" gap>
        <Skeleton variant="text" width="10ch" />
        <Skeleton variant="text" width="25ch" />
      </Container>
      <Container flexDirection="column" gap>
        <Skeleton variant="text" width="10ch" />
        <Skeleton variant="text" width="25ch" />
      </Container>
      <Skeleton variant="button" width="20ch" />
    </Container>
  );
}
