import {
  GetPrepaidUsagesQuery,
  UserTopupQuota,
  UserTopupQuotaUnitEnum,
  UserTopupQuotaCategoryEnum,
  UserTopupDataQuotaTopupQuantityNormalised,
} from "gql/graphql";

type UsageDetails = GetPrepaidUsagesQuery["subscription"]["usage"];
type UsageTopupDetails = GetPrepaidUsagesQuery["subscription"]["usageTopup"];

const dataUnits: { [key in UserTopupQuotaUnitEnum]?: string } = {
  BYTE: "B",
  GIGABYTE: "GB",
  MEGABYTE: "MB",
};

const calculateBalancePercentage = (
  remaining?: UserTopupDataQuotaTopupQuantityNormalised | null,
  total?: UserTopupDataQuotaTopupQuantityNormalised | null
) => {
  if (
    total?.unit === UserTopupQuotaUnitEnum.Byte &&
    remaining?.unit === UserTopupQuotaUnitEnum.Byte &&
    total?.amount > 0
  ) {
    return (remaining.amount / total.amount) * 100;
  }
  return 0;
};

const getUsageByCategory = (usageDetails: UsageDetails, type: UserTopupQuotaCategoryEnum) =>
  usageDetails?.find((usage) => usage?.type === type);

const getVoiceUsage = (usageDetails: UsageDetails) => {
  let voiceUsage = "";
  const voiceNormalized = getUsageByCategory(usageDetails, UserTopupQuotaCategoryEnum.Voice)?.usedDurationNormalised;
  if (!voiceNormalized) return "0 sek";
  if (voiceNormalized.hours) {
    voiceUsage += `${voiceNormalized.hours} t `;
  }
  if (voiceNormalized.minutes) {
    voiceUsage += `${voiceNormalized.minutes} min `;
  }
  if (voiceNormalized.seconds) {
    voiceUsage += `${voiceNormalized.seconds} sek `;
  }
  return voiceUsage;
};

export const getDataDetailedUsage = (usageTopupDetails?: UsageTopupDetails) => {
  return {
    balance: `${usageTopupDetails?.totalQuotas?.data?.remaining?.amount || 0} ${
      dataUnits[usageTopupDetails?.totalQuotas?.data?.remaining?.unit || UserTopupQuotaUnitEnum.Byte]
    }`,
    usages: (usageTopupDetails?.topupQuotas || [])
      .filter(
        (usage) =>
          !!usage && usage.unit === UserTopupQuotaUnitEnum.Byte && usage.category === UserTopupQuotaCategoryEnum.Data
      )
      .map((usage) => {
        return {
          type: usage?.type,
          service: usage?.service,
          expireTime: usage?.expireTime,
          total: `${usage?.usage?.total?.amount || 0} ${dataUnits[usage?.usage?.total?.unit] || ""}`,
          remaining: `${usage?.usage?.remaining?.amount || 0} ${dataUnits[usage?.usage?.remaining?.unit] || ""}`,
          percentage: calculateBalancePercentage(usage?.usage?.remaining?.normalised, usage?.usage?.total?.normalised),
        };
      }),
  };
};

export const getAllUsages = (usageDetails?: UsageDetails) => {
  return {
    voice: getVoiceUsage(usageDetails),
    sms: `${getUsageByCategory(usageDetails, UserTopupQuotaCategoryEnum.Sms)?.usage || 0} stk`,
    mms: `${getUsageByCategory(usageDetails, UserTopupQuotaCategoryEnum.Mms)?.usage || 0} stk.`,
    data: getUsageByCategory(usageDetails, UserTopupQuotaCategoryEnum.Data)?.usage || 0,
  };
};

export const hasUnlimitedNonDataServices = (unlimitedQuotas?: UserTopupQuota["unlimitedQuotas"]) => {
  if (!unlimitedQuotas) return false;
  const { sms, mms, voice } = unlimitedQuotas;
  if (sms && mms && voice) {
    return true;
  }
  return false;
};
