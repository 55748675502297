import { SetURLSearchParams } from "react-router-dom";
import { Container, Heading, Alert } from "@telia-no-min-side/components";
import { isBuyable, isDataSimProduct, isTwinSimProduct } from "../../mobile-subscription-sim-pin-puk/utils";
import { DataSimCard } from "./DataSimCard";
import { TvillingSimCard } from "./TvillingSimCard";
import { AdditionalProductCategory, GetAdditionalSimDataQuery } from "src/gql/graphql";
import { PARAMS } from "..";

type Props = {
  subscription: GetAdditionalSimDataQuery["subscription"] | undefined;
  setSearchParams: SetURLSearchParams;
};

export function OrderAdditionalSimCardStep1({ subscription, setSearchParams }: Props) {
  const additionalProducts = subscription?.additionalProducts;

  const additionalSimCardProducts = additionalProducts?.filter(
    (product) => product.productCategory === AdditionalProductCategory.MultiSim
  );

  const availableSimCards = additionalSimCardProducts?.filter(isBuyable) || [];
  const availableDataSims = availableSimCards.filter(isDataSimProduct) || [];
  const availableTvillingSims = availableSimCards.filter(isTwinSimProduct) || [];

  const handleSimSelection = (isTvillingSim: boolean) => {
    const simType = isTvillingSim ? PARAMS.SIM_TYPE.twin : PARAMS.SIM_TYPE.data;
    setSearchParams({ [PARAMS.SIM_TYPE.key]: simType });
  };

  const simCardsAreAvailable = availableDataSims.length > 0 || availableTvillingSims.length > 0;

  if (!simCardsAreAvailable) {
    return (
      <Container maxWidth="xs">
        <Alert title="Du har allerede bestilt maks antall SIM-kort" icon="alert" kind="negative" isOpen={true} />
      </Container>
    );
  }

  return (
    <Container maxWidth="xs" gap="lg" flexDirection="column" padding="bottom">
      <Heading tag="h2" variant="title-100" data-tracking-id="order-sim-card-header">
        Velg hvilket SIM-kort du trenger
      </Heading>

      <Container gap="lg" flexDirection="column" padding="bottom">
        {availableDataSims.length > 0 && <DataSimCard handleClick={() => handleSimSelection(false)} />}
        {availableTvillingSims.length > 0 && <TvillingSimCard handleClick={() => handleSimSelection(true)} />}
      </Container>
    </Container>
  );
}
