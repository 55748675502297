import { color } from "@telia/teddy";
import { ComponentProps } from "react";
import { Bar } from "react-chartjs-2";

export const ONE_GB = 1024 * 1024 * 1024;
export const HUNDRED_MB = 1024 * 1024 * 100;

export const options: ComponentProps<typeof Bar>["options"] = {
  responsive: true,
  events: ["click"],
  scales: {
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      grid: {
        drawTicks: false,
        color: (context) => (context.index > 0 ? color.teddyColorTransparentWhite300 : color.teddyColorBrandWhite),
      },
      border: {
        dash: (context) => (context.index > 0 ? [2, 4] : [0]),
      },

      ticks: {
        padding: 10,
        maxTicksLimit: 8,
        color: color.teddyColorBrandWhite,
        font: {
          weight: "bolder",
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      backgroundColor: color.teddyColorPurple100,
      titleColor: color.teddyColorFunctionalBlack,
      titleFont: {
        size: 16,
        weight: "normal",
      },
      bodyColor: color.teddyColorFunctionalBlack,
      bodyFont: {
        size: 16,
        weight: "bold",
      },
      boxPadding: 3,
      callbacks: {
        title: () => "Periode",
        afterTitle: (context) => context[0].label,
        label: (context) => `${context.formattedValue} GB`,
      },
    },
  },
};
