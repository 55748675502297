import { PropsWithChildren } from "react";
import { PickerScroller } from "./PickerScroller";
import { PickerButton } from "./PickerButton";

export type PickerOption<T> = {
  id: string;
  buttonText: string;
  buttonSubtext: string;
  value: T;
};

export type PickerProps<T> = PropsWithChildren<{
  options: PickerOption<T>[];
  selectedOption?: PickerOption<T>;
  handleOptionSelect: (value: PickerOption<T>["value"]) => void;
}>;

export function Picker<T>({ options, selectedOption, handleOptionSelect, children }: PickerProps<T>) {
  if (!selectedOption) {
    return null;
  }

  return (
    <PickerScroller>
      {options.map((p) => (
        <PickerButton
          key={p.id}
          id={p.id}
          selected={selectedOption?.value === p.value}
          subtext={p.buttonSubtext}
          handleClick={() => {
            handleOptionSelect(p.value);
            document.getElementById("tabpanel-for-picker")?.focus({
              preventScroll: true,
            });
          }}
        >
          {p.buttonText}
        </PickerButton>
      ))}
      {children}
    </PickerScroller>
  );
}
