export enum PROVIDER {
  CT = "CT",
  CIAM = "CIAM",
  FAKELOGIN = "FAKELOGIN",
}

export enum ACCOUNT_TYPE {
  BOTH = "BOTH",
  MOBILE = "MOBILE",
  FIXED = "FIXED",
  NONE = "NONE",
}

export const AUTH = {
  PROVIDER,
  ACCOUNT_TYPE,
};
