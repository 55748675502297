import { graphql } from "src/gql";

export const insuranceAgreementFragment = graphql(`
  fragment InsuranceAgreement on InsuranceAgreement {
    serviceAgreementId
    product
    productLevel
    shortName
    productTerms {
      name
      value
    }
    imageUrl
  }
`);
