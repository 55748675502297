export function removeWordFromString(
  input: string | null | undefined,
  wordToRemove: string | null | undefined
): string {
  if (!input || typeof input !== "string") return ""; // Ensure input is a string
  if (!wordToRemove || typeof wordToRemove !== "string") return input; // If wordToRemove is invalid, return the original input

  const wordRegex = new RegExp(`\\b${wordToRemove}\\b`, "gi"); // Matches the word as a whole (case-insensitive)
  return input.replace(wordRegex, "").replace(/\s+/g, " ").trim(); // Removes extra spaces and trims
}
