import { format, uri } from "@telia-no-min-side/utils";
import { Text, Container, Flex, Heading, border, color, Skeleton, breakpoint } from "@telia/teddy";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { Breadcrumb } from "components/Breadcrumb";
import { Error } from "pages/error";
import { useFetchProfiles } from "src/api/fixed/useFetchProfiles";
import { config } from "util/config";

export function CompensationLayout() {
  const { customerId } = useParams();
  const profile = useFetchProfiles();
  const currentProfile = profile.data?.find((p) => p.customerId.toString() === customerId?.toString())?.profile;

  if (!config.showTvCompensation) {
    return <Navigate to={uri.minSideFixed("/hjem")} replace />;
  }

  if (profile.isError) {
    return <Error />;
  }

  return (
    <div>
      <Container width="100%" maxWidth="100%" pt="gutter-sm" pb="gutter-sm" data-tracking-id={"tv-point-compensation"}>
        <Flex direction="column" gap="100">
          <Skeleton loading={profile.isLoading}>
            <Heading as="h3" variant="title-200" data-di-mask>
              {format.startCase(
                `${currentProfile?.address.streetName} ${currentProfile?.address.streetNumber}${
                  currentProfile?.address.streetLetter || ""
                }`
              )}
            </Heading>
          </Skeleton>
          <Skeleton loading={profile.isLoading}>
            <Text variant="preamble-100" data-di-mask>
              Kundenummer: {currentProfile?.customerId}
            </Text>
          </Skeleton>
        </Flex>
      </Container>
      <div style={{ borderBottom: `${border.teddyBorderWidthXs} ${color.teddyColorGray200} solid` }} />
      <Container display="block" maxWidth={breakpoint.teddyBreakpointMd}>
        <Breadcrumb />
        <Outlet />
      </Container>
    </div>
  );
}
