import dayjs from "dayjs";
import { color } from "@telia/teddy";
import { MouseEvent, useRef, useState } from "react";
import { useWindowSize } from "@telia-no-min-side/components";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from "chart.js";
import { GetDataUsageHistoryQuery } from "src/gql/graphql";
import { HUNDRED_MB, ONE_GB, options } from "../utils/chartOptions";

type Props = {
  subscription: GetDataUsageHistoryQuery["subscription"] | undefined;
  averageDataUsage: number;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

export function HistoryChart({ subscription, averageDataUsage }: Props) {
  const chartRef = useRef<ChartJS<"bar">>(null);
  const [activeBarIndex, setActiveBarIndex] = useState<number | null>(null);
  const { isMobile } = useWindowSize();

  if (!subscription?.usageGraphBillperiod) return null;

  const handleClick = (event: MouseEvent<HTMLCanvasElement>) => {
    if (!chartRef?.current) return;
    const activeEl = getElementAtEvent(chartRef.current, event);
    setActiveBarIndex(activeEl.length ? activeEl[0].index : null);
  };

  const sortedData = subscription.usageGraphBillperiod.slice().sort((a, b) => {
    if (a?.period?.startDate && b?.period?.startDate) {
      const dateA = new Date(a.period.startDate);
      const dateB = new Date(b.period.startDate);
      return dateA.getTime() - dateB.getTime();
    }
    return 0;
  });

  const usageDataLabels = sortedData.map((d) => {
    return `${dayjs(d?.period?.startDate).format("DD.MM")} - ${dayjs(d?.period?.endDate).format("DD.MM")}`;
  });

  // Convert bytes to gigabytes
  const usageDataForChart = sortedData.map((d) => (d?.used ? Math.round(d.used / (ONE_GB / 10)) / 10 : 0));

  const data = {
    labels: usageDataLabels,
    datasets: [
      {
        data: usageDataForChart,
        borderWidth: 1,
        backgroundColor: (context: { dataIndex: number }) => {
          return context.dataIndex === activeBarIndex
            ? color.teddyColorBrandWhite
            : color.teddyColorTransparentWhite700;
        },
        hoverBackgroundColor: color.teddyColorBrandWhite,
        borderRadius: 30,
        barThickness: isMobile ? 35 : 45,
      },
    ],
  };

  const noDataPlugin = [
    {
      id: "noData",
      afterDatasetDraw: (chart: ChartJS) => {
        const {
          ctx,
          chartArea: { top, left, width, height },
        } = chart;
        ctx.save();
        if (averageDataUsage < HUNDRED_MB) {
          ctx.fillStyle = color.teddyColorTransparentWhite500;
          ctx.fillRect(left, top, width, height);
          ctx.font = "bold 40px";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.fillText("Ingen data tilgjengelig", left + width / 2, top + height / 2);
        }
      },
    },
  ];

  return <Bar ref={chartRef} data={data} options={options} onClick={handleClick} plugins={noDataPlugin} />;
}
