import { get, post } from "../../../api";
import { routerSettingsAction } from "../stores/reducer";
import { routerAnalytics } from "../util/RouterSettingsAnalytics";

export const RouterSettingsActions = {
  fetchRouterSettings(dispatch) {
    dispatch({ type: routerSettingsAction.type.FETCH_ROUTER_SETTINGS_REQUEST });
    get("/minside/api/wifi-administration/wifi-units")
      .then((data) => {
        dispatch({
          type: routerSettingsAction.type.FETCH_ROUTER_SETTINGS_SUCCESS,
          payload: {
            devices: data.Devices,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: routerSettingsAction.type.FETCH_ROUTER_SETTINGS_ERROR,
          payload: {
            error: err,
          },
        });
      });
  },
  setActiveUnitSerialNumber(dispatch, serialNumber, devices) {
    dispatch({
      type: routerSettingsAction.type.SET_ACTIVE_UNIT,
      payload: {
        unitSerialNumber: serialNumber,
        devices,
      },
    });
  },
  updateDevice(dispatch, updateObject) {
    dispatch({
      type: routerSettingsAction.type.UPDATE_ACTIVE_UNIT_REQUEST,
      payload: {
        tag: updateObject.tag,
      },
    });
    post("/minside/api/wifi-administration/update-device", updateObject.device)
      .then((response) => {
        dispatch({
          type: routerSettingsAction.type.UPDATE_ACTIVE_UNIT_SUCCESS,
          payload: { response: response, tag: updateObject.tag },
        });
        dispatch({
          type: routerSettingsAction.type.UPDATE_ACTIVE_UNIT_SETTINGS_IN_STORE,
          payload: { device: updateObject.device },
        });
        routerAnalytics.trackStoreResponse(updateObject.tag, true);
      })
      .catch((err) => {
        dispatch({
          type: routerSettingsAction.type.UPDATE_ACTIVE_UNIT_ERROR,
          payload: { error: err, tag: updateObject.tag },
        });
        routerAnalytics.trackStoreResponse(updateObject.tag, false);
      });
  },
  flipOtherSettingsChanged(dispatcher) {
    dispatcher.dispatch({
      type: routerSettingsAction.type.FLIP_OTHER_SETTINGS_CHANGED,
    });
  },
};
