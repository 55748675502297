import { Accordion, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";

export function InvoiceFeeAccordion(): JSX.Element {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Hvorfor får jeg fakturagebyr og hvordan kan jeg slippe det?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>Din første faktura for TV og bredbånd vil inkludere et fakturagebyr på 6,47 kr.</Paragraph>
        <Paragraph>
          Du kan slippe å betale gebyr for papirfaktura etter første faktura ved å opprette en AvtaleGiro med
          elektronisk varsling eller eFaktura-avtale i nettbanken din.
        </Paragraph>
        <Paragraph>
          Gebyrer tilkommer ellers når du mottar papirfaktura, fakturapåminnelse per post, eller ved purringer.
        </Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
