import { types } from "@telia-no-min-side/utils";

export function getSimplePriceText(price: number | undefined, chargePeriod?: string): string {
  const priceWithTwoDecimals = (price || 0).toFixed(2);
  const [integerDot, decimalDot] = priceWithTwoDecimals.split(".");
  const [integerComma, decimalComma] = priceWithTwoDecimals.split(",");
  const integer = decimalComma ? integerComma : integerDot;
  const decimal = decimalComma ? decimalComma : decimalDot;

  if (decimal === "00") {
    return chargePeriod ? `${integer},-/${chargePeriod}` : `${integer},-`;
  }
  return chargePeriod ? `${integer},${decimal}/${chargePeriod}` : `${integer},${decimal}`;
}

export function getPriceText(price?: types.fixed.Price): string {
  if (!price) return "";
  return getSimplePriceText(price.amount, price.chargePeriod);
}

function getPriceAmountAsIntegerDecimals(amount: number) {
  const priceWithTwoDecimals = amount.toFixed(2);
  const [integer, decimal] = priceWithTwoDecimals.split(".");
  return {
    integer,
    decimal,
  };
}

export function getPriceAmountText(amount?: number, pricePostfix?: "kr"): string {
  const { integer, decimal } = getPriceAmountAsIntegerDecimals(amount ?? 0);
  if (decimal === "00") {
    return `${integer}${pricePostfix ? ` ${pricePostfix}` : ",-"}`;
  }

  return `${integer},${decimal}${pricePostfix ? ` ${pricePostfix}` : ""}`;
}

export function getListPriceText(price?: types.fixed.Price): string | undefined {
  if (!price || price.listPriceAmount === price.amount) return undefined;
  return getPriceAmountText(price.listPriceAmount);
}
