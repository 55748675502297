import { useQuery } from "@apollo/client";
import { Card, Heading, Button, Paragraph, Skeleton, Container } from "@telia-no-min-side/components";
import { config } from "src/util/config";
import { format } from "@telia-no-min-side/utils";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { graphql } from "src/gql";

const GET_PREPAID_USAGE = graphql(`
  query getPrepaidUsage($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      paymentBalance {
        amount
      }
    }
  }
`);

export function PrepaidBalance() {
  const { phoneNumber } = useMobileAccount();

  const { loading, data } = useQuery(GET_PREPAID_USAGE, {
    variables: { phoneNumber },
  });

  if (loading) {
    return (
      <Card maxWidth="xs" removeMinHeight>
        <Container flexDirection="row" alignItems="center" justifyContent="space-between">
          <Skeleton width="10ch" />
          <Skeleton width="15ch" />
          <Skeleton variant="button-small" />
        </Container>
      </Card>
    );
  }

  const paymentBalanceAmount = data?.subscription.paymentBalance?.amount || 0;

  return (
    <Card maxWidth="xs" removeMinHeight>
      <Container flexDirection="row" alignItems="center" justifyContent="space-between">
        <Heading tag="h2" variant="title-150">
          Din saldo{" "}
        </Heading>
        <Paragraph removeMargin>
          <em>{format.formatPrice(paymentBalanceAmount)}</em>
        </Paragraph>
        <Button variant="primary" tag="a" size="sm" href={`${config.teliaNoRootUrl}/lade/`}>
          Fyll på
        </Button>
      </Container>
    </Card>
  );
}
