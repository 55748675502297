import { Button, Container } from "@telia-no-min-side/components";
import { format, types, uri } from "@telia-no-min-side/utils";
import { Field } from "../style";
import { config } from "src/util/config";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export type LogoutState = "success" | "loading" | "error" | undefined;

export function ProfileInfo({ user }: { user: types.fixed.UserWithCustomerId }) {
  const { street, postalCode, city } = user;
  const fullAddress = `${street}, ${postalCode} ${city}`;
  const { showMduForm } = config;

  return (
    <>
      <Field>
        <div>Navn</div>
        <div data-di-mask>{format.startCase(user.fullName)}</div>
      </Field>
      <Field>
        <div>Adresse</div>
        <div data-di-mask>{format.startCase(fullAddress)}</div>
      </Field>
      <Field>
        <div>E-post</div>
        <div data-di-mask>{user.email}</div>
      </Field>
      <Field>
        <div>Telefon</div>
        <div data-di-mask>{format.formatPhoneNumber(user.mobile)}</div>
      </Field>
      <Field>
        <div>Kundenummer</div>
        <div data-di-mask>{user.customerId}</div>
      </Field>

      <Container flexDirection="column" alignItems="start" gap>
        <Button
          tag="a"
          href={uri.minSideFixed("/profil/endre")}
          variant="secondary"
          isInternalNavigation
          icon="edit"
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.PROFILE,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
            ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
            ui_item_text: TRACK_EVENT.ITEM_TEXT.CONTACT_INFO_CHANGE,
          }}
        >
          Endre kontaktinformasjon
        </Button>
        <Button
          tag="a"
          href={showMduForm ? uri.minSideFixed("/flytting") : uri.openPages("/kundeservice/flytting")}
          variant="secondary"
          icon="arrow-large-right"
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.PROFILE,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
            ui_item_url: showMduForm ? uri.minSideFixed("/flytting") : uri.openPages("/kundeservice/flytting"),
            ui_item_text: TRACK_EVENT.ITEM_TEXT.REPORT_MOVE,
          }}
        >
          Meld flytting
        </Button>
      </Container>
    </>
  );
}
