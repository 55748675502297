import { Accordion, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";

export function PaymentDeferralAccordion(): JSX.Element {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Jeg trenger en betalingsutsettelse</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Dersom du trenger en utsettelse på mobilregningen og er ansvarlig eier av abonnementet, kan du utsette
          fakturaen på Min Side. Det vil da legges på et gebyr. Fakturaen blir da utsatt i 30 dager, men du kan
          selvfølgelig betale tidligere.
        </Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
