import React from "react";
import { GaugeMeter, GaugeMeterProps } from "./GaugeMeter";
import { DynamicGaugeMeterWrapper, DynamicIconButton, FadeOut } from "./style";

export type DynamicGaugeMeterProps = {
  onIncrement?(): void;
  onDecrement?(): void;
  isIncrementDisable?: boolean;
  fadeHideButton?: boolean;
  isDecrementDisable?: boolean;
  classNameGaugeMeter?: string;
} & GaugeMeterProps;

export function DynamicGaugeMeter({
  onDecrement,
  onIncrement,
  percentage = 0,
  isDecrementDisable,
  isIncrementDisable,
  isLoading = false,
  strokeWidth = 10,
  cutoffPercentage = 0.3,
  children,
  className,
  classNameGaugeMeter,
  fadeHideButton,
}: DynamicGaugeMeterProps) {
  const roundedPercentage = Number(percentage.toFixed(2));
  const flooredPercentage = roundedPercentage < 0 ? 0 : roundedPercentage > 1 ? 1 : roundedPercentage;

  return (
    <DynamicGaugeMeterWrapper className={className}>
      <FadeOut fadeHideButton={fadeHideButton}>
        <DynamicIconButton
          variant="secondary"
          title="Reduser"
          isIconButton
          icon="minus"
          disabled={isLoading || isDecrementDisable || flooredPercentage === 0}
          onClick={onDecrement}
        />
      </FadeOut>
      <GaugeMeter
        strokeWidth={strokeWidth}
        cutoffPercentage={cutoffPercentage}
        percentage={percentage}
        isLoading={isLoading}
        className={classNameGaugeMeter}
      >
        {children}
      </GaugeMeter>
      <FadeOut fadeHideButton={fadeHideButton}>
        <DynamicIconButton
          variant="secondary"
          title="Øk"
          isIconButton
          icon="plus"
          disabled={isLoading || isIncrementDisable || flooredPercentage === 1}
          onClick={onIncrement}
        />
      </FadeOut>
    </DynamicGaugeMeterWrapper>
  );
}
