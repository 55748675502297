import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import AdyenCheckout from "@adyen/adyen-web";
import { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";
import DropinElement from "@adyen/adyen-web/dist/types/components/Dropin";
import { useAxiosSWR } from "@telia-no-min-side/components";
import { Spinner } from "@telia/styleguide";
import { FixedPaymentResponse } from "./types/PaymentResponse";
import { ConfigurationResponse } from "./types/AdyenTypes";
import { getDropinConfiguration, authoriseThreeDSVersion2, makePayment, showFinalResult } from "./utils/PaymentUtils";
import { PaymentDone } from "./PaymentDone";
import "@adyen/adyen-web/dist/adyen.css";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

type Props = {
  amount: number;
  financialAccountId: number;
  invoiceId: number;
  onPaymentDone: () => void;
};

type PaymentResult = {
  success: boolean;
  error: boolean;
  response: FixedPaymentResponse | null;
};

export function OnlinePayment({ amount, financialAccountId, invoiceId, onPaymentDone }: Props) {
  const dropinElement = useRef<HTMLDivElement>(null);
  const [dropinConfig, setDropinConfig] = useState<CoreOptions | undefined>();
  const [dropinError, setDropinError] = useState(false);
  const [paymentDone, setPaymentDone] = useState<PaymentResult>({
    success: false,
    error: false,
    response: null,
  });
  const [paymentPending, setPaymentPending] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const paymentConfig = useAxiosSWR<ConfigurationResponse>(ICX_REQUEST.URL_PAYMENT_METHOD_DATA);

  const paymentDoneCallback = (response: FixedPaymentResponse) => {
    setPaymentPending(false);

    const succeeded = response.status ? response.status === "succeeded" : false;
    const failed = response.status ? response.status === "failed" : false;

    onPaymentDone();
    setPaymentDone({ success: succeeded, error: failed, response });
  };

  const paymentPendingCallback = (flag: boolean) => {
    setPaymentPending(flag);
  };

  const onPaymentError = (flag: boolean) => {
    setPaymentError(flag);
  };

  useEffect(() => {
    if (paymentConfig.data) {
      const config = getDropinConfiguration(paymentConfig.data, {
        makePayment,
        makeDetailsCall: authoriseThreeDSVersion2,
        showFinalResult,
        amount,
        onPaymentDone: paymentDoneCallback,
        financialAccountId,
        invoiceId,
        onPaymentPending: paymentPendingCallback,
        onPaymentError,
      });

      setDropinConfig(config);
    }
    setDropinError(!!paymentConfig.error);
  }, [paymentConfig.data, paymentConfig.error, amount, invoiceId, financialAccountId]);

  useLayoutEffect(() => {
    async function fetchPaymentData(dropinElement: HTMLDivElement, dropinConfig: CoreOptions) {
      try {
        const core = await AdyenCheckout(dropinConfig);
        const dropinComponent: DropinElement = core.create("dropin");
        dropinComponent.mount(dropinElement);
      } catch (e) {
        setDropinError(true);
      }
    }

    if (dropinElement.current && dropinConfig) {
      fetchPaymentData(dropinElement.current, dropinConfig);
    }
  }, [dropinElement.current, dropinConfig, dropinConfig?.amount]);

  if (dropinError) {
    return (
      <CenterLayout>
        <div>Ooops! Her skjedde det noe galt! Last inn siden på nytt for å starte betalingen på nytt igjen.</div>
      </CenterLayout>
    );
  }

  return (
    <CenterLayout>
      <div key={`dropin-container-$-${invoiceId}`} ref={dropinElement} id="dropin-container" />
      {(!dropinConfig || paymentPending) && (
        <CenterContent>
          <Spinner />
        </CenterContent>
      )}
      {(paymentDone.success || paymentDone.error || paymentError) && (
        <PaymentDone response={paymentDone.response} paymentError={paymentError} />
      )}
    </CenterLayout>
  );
}

const CenterLayout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenterContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
