import { Text } from "@telia/teddy";
import { ShoppingCard, Button, Container, Paragraph } from "@telia-no-min-side/components";
import { getSimplePriceText } from "../../util/priceFormatter";
import { BoxProps } from ".";

export function Recording(props: BoxProps): JSX.Element {
  const { isSelected, onSelectClick, product } = props;
  const { currentPrice, postCampaignPrice, campaignDuration } = product.price;
  const isCampaign = postCampaignPrice.recurring > currentPrice.recurring;
  return (
    <ShoppingCard
      title={product.name || "Opptak"}
      price={getSimplePriceText(currentPrice.recurring, currentPrice.chargePeriod) || "Finner ikke pris."}
      postCampaignPrice={isCampaign ? getSimplePriceText(postCampaignPrice.recurring) : undefined}
      campaignText={campaignDuration}
      isSelected={isSelected}
      selectedBadgeText="Valgt"
    >
      <ShoppingCard.Body>
        <Paragraph variant="additional-100">
          Ta vare på favorittene dine eller det du ikke fikk sett ferdig. Alt blir tilgjengelig Telia Play og er lett
          tilgjengelig på alle dingsene dine.
        </Paragraph>
        <Text mt="100" mb="100" variant="additional-100">
          Ingen binding
        </Text>
      </ShoppingCard.Body>
      {onSelectClick && (
        <ShoppingCard.Footer>
          <Container floatContent="right" padding="top">
            <Button variant={isSelected ? "primary" : "secondary"} size="sm" onClick={onSelectClick}>
              {isSelected ? "Valgt" : "Legg til"}
            </Button>
          </Container>
        </ShoppingCard.Footer>
      )}
    </ShoppingCard>
  );
}
