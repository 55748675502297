import { colors, Icon, Lozenge } from "@telia/styleguide";
import styled from "styled-components";
import { Card } from "../Card";
import { devices } from "../utils/breakpoints";

export const StyledProductBox = styled(Card)`
  margin: 0.5rem 0;
  position: relative;

  &:first-of-type {
    margin-top: 0;
  }
  &::last-of-type {
    margin-bottom: 0;
  }
`;

export const ImageContainer = styled.div`
  margin: 0 1rem 0 0;
  min-width: 50px;
  flex: 1;
  display: flex;
  img {
    max-width: 100%;
    max-height: 70px;
    margin: auto;
  }
  @media ${devices.xs_down} {
    margin: 0 0.5rem 0 0;
  }
`;

export const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 4;
  @media ${devices.xs_down} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ButtonWrapper = styled.div`
  margin-left: max(5%, 10px);
`;

export const SkeletonWrapper = styled.div`
  padding: 0 2rem;
  width: 90%;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledLozenge = styled(Lozenge)`
  margin: 3px 0 0;
  @media ${devices.xs_down} {
    margin: 0;
    position: absolute;
    top: 0;
    left: 20px;
    transform: translateY(-50%);
  }
`;
export const StyledInfoText = styled.div`
  margin: 0 0 0 0.5rem;
`;

export const StyledPrice = styled.div`
  font-size: 1.125rem;
  margin-left: 0.5rem;
  font-weight: 500;
  display: flex;
  gap: 5px;
`;

export const ListPrice = styled.span`
  color: ${colors.grey600};
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 0;
    width: calc(100% - 10px);
    height: 1rem;
    transform: translateY(-50%);
    overflow: visible;
    line {
      stroke: ${colors.grey600};
      stroke-width: 2;
    }
  }
`;

export const StyledIcon = styled(Icon)<{ color?: string }>`
  color: ${(props) => props.color};
  margin: auto;
`;
