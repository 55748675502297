import React from "react";
import { SideMenuItem, SideMenuItemGroup } from "@telia/styleguide";
import { ActiveMenu, useMainLayoutNavigation } from "../../hooks/useMainLayoutNavigation";
import { MenuLinkGroup, MenuLinkId } from "../utils/links";
import { Options } from "./SideMenuItems";
import { useSideMenu } from ".";

type Props = {
  menuLinkGroup: MenuLinkGroup;
  activeMenuId: ActiveMenu;
  onMenuItemClick: (activeId: MenuLinkId, options: Options) => void;
  onMenuGroupClick: (id: string) => void;
};

export function SideMenuLinkGroup(props: Props) {
  const { openMenuGroup, isCollapsed, limitedAccess } = useSideMenu();
  const { menuLinkGroup, onMenuItemClick, onMenuGroupClick } = props;
  const { activeMenuId } = useMainLayoutNavigation();
  const { id, ...rest } = menuLinkGroup;

  const childIds = menuLinkGroup.items.map((item) => item.id);
  const isChildActive = childIds.some((id) => id === activeMenuId);

  return (
    <SideMenuItemGroup
      {...rest}
      onClick={() => onMenuGroupClick(id)}
      open={openMenuGroup === id}
      active={isChildActive && openMenuGroup !== id}
      collapse={isCollapsed}
      dataTrackingId={`side-menu-item-${id}`}
    >
      {menuLinkGroup.items.map((item) => {
        return (
          <SideMenuItem
            key={item.id}
            label={item.label}
            href={item.href}
            onClick={() => {
              onMenuItemClick(item.id, {
                navigateTo: item.href,
                isInternalLink: item.isInternalLink,
                isMenuGroupItemClick: true,
              });
            }}
            active={activeMenuId === item.id}
            tabIndex={openMenuGroup === id ? 1 : -1}
            collapse={isCollapsed}
            dataTrackingId={`side-menu-inner-item-${item.id}`}
            disabled={limitedAccess && item.limitedAccessView}
          />
        );
      })}
    </SideMenuItemGroup>
  );
}
