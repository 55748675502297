import { useEffect, useRef, useState } from "react";
import { useTpnPost } from "src/hooks/useTpnPost";
import { types } from "@telia-no-min-side/utils";

export function useAdditionalServices(product: types.fixed.Product | undefined) {
  const { postTpn } = useTpnPost();
  const [activationStatus, setActivationStatus] = useState<"activating" | "error" | null>(null);
  const timerId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, []);

  async function activateService() {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    const activateUrl = product?.actions?.find((action) => action.type === "ACTIVATE")?.url;
    if (!activateUrl) return;
    setActivationStatus("activating");
    try {
      await postTpn(activateUrl);
      await new Promise((resolve) => {
        timerId.current = setTimeout(resolve, 2000);
      });
      if (!product?.status?.active) {
        setActivationStatus(null);
      }
    } catch {
      setActivationStatus("error");
    }
  }

  return { activationStatus, activateService };
}
