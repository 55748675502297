import styled from "styled-components";

export const PostCampaignPrice = styled.span`
  opacity: 0.65;
  text-decoration: line-through;
`;

export const AdditionalPriceText = styled.span`
  text-align: right;
  font-weight: 500;
  font-size: 12px;
  align-self: flex-end;
`;

export const ProductImage = styled.img`
  max-height: 80px;
  max-width: 120px;
`;
