import styled from "styled-components";
import { colors } from "@telia/styleguide";
import { Card } from "../Card";
import { Collapse } from "../Collapse";

type Expanded = { expanded: boolean };

export const Wrapper = styled(Card)<{ boxWidth: string }>`
  background-color: ${colors.corePurple700};
  color: white;
  box-shadow: 0px 2px 8px ${colors.grey200};
  min-height: 73px;
  padding: 0;
  position: relative;
  width: ${(props) => props.boxWidth};
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      box-shadow: 0px 3px 8px ${colors.grey300};
    }
  }

  a {
    font-weight: 300;
    color: white;
    :hover {
      color: white;
    }
  }
  svg {
    fill: white;
  }
`;

export const ExpandableHeader = styled.button`
  display: grid;
  grid-template-columns: 1fr 30px;
  width: 100%;
  place-items: start;
  background: none;
  border: 0;
  color: white;
  padding: 12.5px 1rem;
  font-family: inherit;
  font-size: 100%;
  cursor: pointer;
`;

export const ExpandableContent = styled(Collapse)`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem;
`;

export const ExpandableIcon = styled.div<Expanded>`
  svg {
    fill: white;
    transform: rotate(${(props) => (props.expanded ? "180deg" : "0deg")});
    transition: transform 0.3s ease;
    height: 1.25rem;
  }
`;
