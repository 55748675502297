import { Alert, Link, Container } from "@telia-no-min-side/components";
import { useNavigate } from "react-router-dom";

export function SwitchAlert({ errorMsg }: { errorMsg: string }) {
  const navigate = useNavigate();

  return (
    <Container flexDirection="column" gap="lg">
      <Link
        href={"/minside/"}
        icon="arrow-left"
        iconPosition="before"
        isInternalNavigation
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
      >
        Tilbake
      </Link>
      <Alert title={errorMsg} kind="negative" isOpen canClose={false} />
    </Container>
  );
}
