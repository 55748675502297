import { Flex, Card, Text, Icon, Button } from "@telia/teddy";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function TeliaPlayCard() {
  return (
    <Card shadow bordered width="100%" maxWidth="400px" layout="default" variant="white">
      <Card.Heading as="h3" variant="title-100">
        Telia Play
      </Card.Heading>

      <Card.Content>
        <Flex direction="row" gap="150" height="100%" width="100%">
          <img
            src="https://www.telia.no/globalassets/produkter/tv/eget-utstyr-v-2.png"
            alt="Telia play"
            width="100px"
          />
          <Text variant="additional-100">Ha TV-en med deg med Telia Play!</Text>
        </Flex>
      </Card.Content>
      <Card.Footer height="100%" align="end">
        <Button variant="text" asChild>
          <a href={EXTERNAL_LINK.TELIA_PLAY}>
            Til Telia Play
            <Icon name="arrow-right" />
          </a>
        </Button>
      </Card.Footer>
    </Card>
  );
}
