import { DashboardSection } from "./card/DashboardSection";
import { Card, Icon, Text } from "@telia/teddy";
import { Link } from "react-router-dom";

export function B2BCard() {
  return (
    <DashboardSection>
      <Card bordered shadow width="100%" maxWidth="400px" layout="default" variant="white">
        <Card.Heading as="h3" variant="title-100">
          B2B Family Discount
        </Card.Heading>
        <Card.Content>
          <Text variant="additional-100">
            Verifiser nummeret det gjelder for å få tilgang til abonnementet på Min Side.
          </Text>
        </Card.Content>
        <Card.Footer height="100%" align="end">
          <Card.Button variant="text" asChild>
            <Link to="/minside/mobil/bedrift-familierabatt">
              <Icon name="plus" size="sm">
                Get discount
              </Icon>
            </Link>
          </Card.Button>
        </Card.Footer>
      </Card>
    </DashboardSection>
  );
}
