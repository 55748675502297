import { ApolloError, gql, useMutation } from "@apollo/client";
import { MutationSendPinArgs, SendPinMutation } from "gql/graphql";

const SEND_PIN_REQUEST = gql`
  mutation sendPin($userId: String!, $sendOtpByEmail: Boolean) {
    sendPin(userId: $userId, sendOtpByEmail: $sendOtpByEmail)
  }
`;

type Props = {
  onError?: (error: string) => void;
  onCompleted?: () => void;
};

const getErrorMessage = (error?: ApolloError) => {
  if (!error) {
    return "";
  }
  if (error.message.includes("IPL_OTP_LOGON_BLOCKED" || "IPL_TOO_MANY_BAD_OTP_LOGONS")) {
    return `Du har mottatt for mange engangskoder den siste timen. 
    Brukerkontoen din har blitt midlertidig sperret for utsendelse av engangskode.`;
  }
  return "En uventet feil oppstod.";
};

export const useSendPin = ({ onCompleted, onError }: Props) => {
  const [sendPin, { loading, data, error }] = useMutation<SendPinMutation, MutationSendPinArgs>(SEND_PIN_REQUEST, {
    onError: (error) => {
      if (onError) {
        onError(getErrorMessage(error));
      }
    },
    onCompleted,
  });

  return {
    sendPin,
    loading,
    error: getErrorMessage(error),
    data,
  };
};
