import { Link } from "react-router-dom";
import { useWindowSize } from "@telia-no-min-side/components";
import { Flex, Notification, Text, Button, Link as TeddyLink, breakpoint } from "@telia/teddy";
import { formatDateForSuspendedMessage } from "../utils";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { UseMobileAccountSubscriptionData } from "hooks/useMobileAccount";

type Props = Pick<UseMobileAccountSubscriptionData, "userOffering" | "subscriptionStatusDate">;

export function SoftCollectionMessage({ userOffering, subscriptionStatusDate }: Props) {
  const { isMobile } = useWindowSize();
  const subscriptionName = userOffering?.shortName || "";
  const formattedSubscriptionStatusDate = formatDateForSuspendedMessage(subscriptionStatusDate || "");

  return (
    <Flex maxWidth={breakpoint.teddyBreakpointMd} p={isMobile ? "200" : "400"} gap="300" direction="column">
      <Notification variant="information" data-tracking-id="alert--suspended-subscription_reason-soft-collection">
        <Notification.Heading as="h3">
          <Notification.Icon>Ditt abonnement {subscriptionName} er sperret</Notification.Icon>
        </Notification.Heading>
        <Flex direction="column" gap="gutter-md">
          <Notification.Text>
            <Flex as="div" direction="column" gap="gutter-md">
              <Text>
                Abonnementet ditt ble sperret den {formattedSubscriptionStatusDate} fordi vi mangler betaling av en
                eller flere forfalte fakturaer. Bruk knappen under for å betale det utestående beløpet. Abonnementet
                ditt vil bli gjenåpnet innen kort tid etter betalingen er mottatt.
              </Text>
              <Text>Hvis du har betalt via din nettbank vil det ta 1-2 virkedager før abonnementet gjenåpnes.</Text>

              <Flex direction="column">
                <Text variant="paragraph-100-bold">Hva gjør jeg hvis beløpet er sendt til inkasso?</Text>
                <Text>
                  Hvis beløpet er sendt til inkasso, må hele kravet, inkludert renter og gebyrer, betales direkte til
                  vårt inkassoselskap, Sergel Norge. Du kan enkelt betale med kort eller Vipps ved å besøke følgende
                  lenke:
                </Text>
                <Flex justify="start">
                  <TeddyLink href={EXTERNAL_LINK.DEBT_COLLECTION_AGENCY} variant="text" target="_blank">
                    minsak.sergel.no
                  </TeddyLink>
                </Flex>
              </Flex>

              <Flex direction="column">
                <Text>Dersom du trenger hjelp med din inkassosak, vennligst kontakt Sergel:</Text>
                <ul>
                  <li>Telefon: 33456031</li>
                  <li>E-post: no.post1@sergel.com</li>
                  <li>Åpningstider: 08-17 hverdager</li>
                </ul>
              </Flex>
            </Flex>
          </Notification.Text>

          <Notification.Footer>
            <Button aria-label="Betal nå" variant="primary" asChild>
              <Link to={INTERNAL_LINK.INVOICE_PAGE}>Betal nå</Link>
            </Button>
          </Notification.Footer>
        </Flex>
      </Notification>
    </Flex>
  );
}
