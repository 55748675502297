import React, { ReactNode } from "react";
import { Heading, HeadingProps } from "../Heading";
import { useAria } from "../hooks/context/aria";

type Props = {
  children: ReactNode;
  variant?: HeadingProps["variant"];
};

/**
 * @deprecated Use Teddy components instead
 */
export function ModalHeading(props: Props): JSX.Element {
  const { children, variant } = props;
  const { labelId } = useAria();

  return (
    <Heading tag="h2" variant={variant || "title-200"} id={labelId}>
      {children}
    </Heading>
  );
}
