import { LocationState, Options } from "../../hooks/useReactRouterHistory";

export function getNewLocationState<CustomState>(prevState: LocationState<CustomState>, option?: Options<CustomState>) {
  return { ...prevState, ...option };
}

export function getSearchParams(url: string): string | undefined {
  const urlWithoutHash = url.split("#")[0];
  const indexOfParams = urlWithoutHash.indexOf("?");

  if (indexOfParams < 0) return;

  return urlWithoutHash.substring(indexOfParams);
}

export function getHash(url: string): string | undefined {
  const indexOfHash = url.indexOf("#");
  if (indexOfHash < 0) return;

  return url.substring(indexOfHash);
}

export function getPathnameFromRouterUrl(url: string): string {
  const urlWithoutQueryParamsAndHash = url.split("?")[0].split("#")[0];
  return urlWithoutQueryParamsAndHash;
}
