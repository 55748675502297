import React from "react";
import PropTypes from "prop-types";
import { TextBoxWithLabel } from "@telia/styleguide";
import { ipv4StringableOctets } from "../../util/PropTypes";
import { ipUtilities } from "../../util/validation";
import { ipAddressUtility } from "../../util/IpAddressInputUtilities";
import { DisabledField } from "../LayoutComponents/DisabledField";

import "./style.less";

const InputContainer = ({ children, style }) => (
  <div style={style} className="portforwarding-input-container">
    {children}
  </div>
);

const { string, shape, bool, number, func, oneOfType } = PropTypes;

export class IpAddressInput extends React.Component {
  static propTypes = {
    address: oneOfType([ipv4StringableOctets, string]),
    addressContract: shape({
      Octet1: shape({
        CanChange: bool,
      }),
      Octet2: shape({
        CanChange: bool,
      }),
      Octet3: shape({
        CanChange: bool,
      }),
      Octet4: shape({
        CanChange: bool,
      }),
    }),
    mask: shape({
      Octet1: number,
      Octet2: number,
      Octet3: number,
      Octet4: number,
    }),
    onChange: func,
    typeOfAddress: string,
    title: string,
    onBlur: func,
    errorMessage: string,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      lastOctetChanged: null,
    };
  }

  handleIPAddressChange = (event, octetNumberToChange) => {
    const { onChange, typeOfAddress, address } = this.props;
    const updatedAddress = ipAddressUtility.updateOctet(address, event, octetNumberToChange);
    const validationObject = ipUtilities.validateOctet(event.target.value);

    this.setState({
      lastOctetChanged: octetNumberToChange,
    });

    onChange(updatedAddress, typeOfAddress, validationObject.valid);
  };

  validateIpAddressAndPropagate = (value) => {
    this.props.onBlur();
    const validation = ipUtilities.validateOctet(value);

    if (!validation.valid) {
      this.setState({ errorMessage: validation.message });
    } else {
      this.setState({ errorMessage: "" });
    }
  };

  getErrorMessageForInput = (octet) => {
    if (octet === this.state.lastOctetChanged) {
      if (this.state.errorMessage) {
        return this.state.errorMessage;
      }
      if (this.props.errorMessage) {
        return this.props.errorMessage?.message;
      }
    }
  };

  render() {
    const { address, title, mask, addressContract, typeOfAddress } = this.props;
    const { uneditable, editable } = ipUtilities.extractEditableAndUneditableParts(
      address,
      mask,
      addressContract,
      typeOfAddress
    );
    const uneditableString = uneditable.map((part) => part.value).join(".");

    if (editable.length === 0) {
      return (
        <InputContainer>
          <DisabledField value={uneditableString} labelText={title} />
        </InputContainer>
      );
    }
    return (
      <>
        <div>
          <InputContainer>
            <DisabledField value={uneditableString} labelText={title} />
          </InputContainer>
          {editable.map((octet, index) => {
            return (
              <InputContainer key={index}>
                <TextBoxWithLabel
                  type={"number"}
                  onChange={(event) => this.handleIPAddressChange(event, octet.octet)}
                  hideLabel
                  min={0}
                  max={255}
                  onBlur={() => this.validateIpAddressAndPropagate(octet.value)}
                  value={octet.value}
                  key={index}
                  errorMessage={this.getErrorMessageForInput(octet.octet)}
                />
              </InputContainer>
            );
          })}
        </div>
      </>
    );
  }
}
