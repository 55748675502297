import { useQuery } from "@apollo/client";
import { Container, HorizontalScroll, Skeleton, ToggleButtonGroup } from "@telia-no-min-side/components";
import { Flex } from "@telia/teddy";
import { useSelectAccount } from "hooks/useSelectAccount";
import { useParams } from "react-router-dom";
import { MOBILE_VAS_PRODUCT_GROUPS } from "./ValueAddedServicesPage";
import { GET_ADDITIONAL_SERVICES_GROUP_QUERY } from "./query";
import { format } from "@telia-no-min-side/utils";
import { AUTH } from "util/constants/auth";

type Props = {
  phoneNumberToFilter?: string;
};

export function ProfilePicker({ phoneNumberToFilter }: Props) {
  const { selectedMobileAccount, setAccount } = useSelectAccount();
  const productGroup = useParams<{ productGroup: string }>().productGroup;
  const products = useQuery(GET_ADDITIONAL_SERVICES_GROUP_QUERY, {
    variables: {
      productGroups: [...MOBILE_VAS_PRODUCT_GROUPS],
    },
  });
  const productsOnSubscriptionLevel = products.data?.accounts?.flatMap((account) => account.subscriptions);

  const alleUsers = productsOnSubscriptionLevel
    ?.map((subscription) => ({
      ...subscription,
      additionalProduct: subscription?.additionalProducts?.find((product) => product?.productGroup === productGroup),
    }))
    .sort(({ additionalProduct }) => (additionalProduct?.presentation?.active ? -1 : 1));

  const usersToDisplay = phoneNumberToFilter
    ? alleUsers?.filter(({ phoneNumber }) => phoneNumber?.localNumber === phoneNumberToFilter)
    : alleUsers;
  return (
    <HorizontalScroll addContainerScrollMargin>
      {({ scrollBy }) => (
        <Container gap="lg" maxWidth="full">
          <ToggleButtonGroup
            variant={"primary"}
            value={selectedMobileAccount?.phoneNumber}
            onChange={(_, value) => {
              if (!value) return;
              setAccount({
                accountType: AUTH.ACCOUNT_TYPE.MOBILE,
                phoneNumber: value,
                id: products.data?.accounts?.find(
                  (account) =>
                    account.subscriptions?.find((subscription) => subscription.phoneNumber.localNumber === value)
                )?.id,
              });
            }}
          >
            {selectedMobileAccount?.isLoading && !usersToDisplay?.length ? (
              <Skeleton variant="rectangular" height="2.5rem" width="10ch" />
            ) : null}
            {usersToDisplay?.map(({ phoneNumber, productUser }) => (
              <ToggleButtonGroup.Item
                scrollBy={scrollBy}
                key={`profile-${phoneNumber?.localNumber}`}
                value={phoneNumber?.localNumber}
                dataTrackingID={`profile-picker-profileId-${productUser?.firstName.toLowerCase()}`}
                text={
                  <Flex align={"center"} gap="100">
                    {format.startCase(productUser?.firstName || "")} (
                    {format.formatPhoneNumber(phoneNumber?.localNumber || "")})
                  </Flex>
                }
              />
            ))}
          </ToggleButtonGroup>
        </Container>
      )}
    </HorizontalScroll>
  );
}
