import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { Icon } from "../Icon";
import { focusRing } from "../styles/focus";
import { remCalc } from "../utils/fonts";
import { LinkProps } from ".";

type ColorProps = Pick<LinkProps, "negative">;
const colorSelector = css<ColorProps>`
  color: ${({ negative }) => (negative ? colors.corePurple300 : colors.corePurple700)};

  :visited,
  :active {
    color: ${({ negative }) => (negative ? colors.corePurple200 : colors.corePurple800)};
  }
`;

type StyledLinkProps = Pick<LinkProps, "variant" | "negative">;
export const StyledLink = styled.a<StyledLinkProps>`
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  ${colorSelector};
  ${focusRing};

  :hover,
  :active,
  :focus {
    background-color: ${({ negative }) => (negative ? "rgba(255, 255, 255, 0.1)" : colors.corePurple100)};
  }

  ${({ variant }) => {
    if (variant === "standalone") {
      return css`
        padding: ${remCalc(10)} 0;
      `;
    }
  }}
`;

export const LinkText = styled.span`
  text-decoration: underline;
`;

export const LinkIcon = styled(Icon)`
  ${colorSelector};
`;
