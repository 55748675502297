import { Container, TrackClickEvent } from "@telia-no-min-side/components";
import { Button, Heading, Icon, Text } from "@telia/teddy";
import { Link } from "react-router-dom";
import { URL_CHECK_PHONE_REPAIR } from "util/constants/agreements";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = {
  safeWebshopUrl?: string | null;
  loading: boolean;
};

export function OtherDamageBase({ safeWebshopUrl, loading }: Props) {
  return (
    <>
      <Container flexDirection="column" alignItems="start" gap="lg">
        <Heading as="h4" variant="title-100">
          Forsikringen din dekker skader på skjermen
        </Heading>
        <Text>Du kan fremdeles svitsje, så tar vi oss av reparasjonen.</Text>
        <Text>
          Du må selv dekke skader som ikke er en del av skjermforsikringen, dette kommer på en faktura etter at
          telefonen er vurdert.
        </Text>
      </Container>
      <Container flexDirection="column" alignItems="start" gap>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_other-damage-base",
            ui_item_text: "Sjekk priser for reparasjon",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: URL_CHECK_PHONE_REPAIR,
          }}
        >
          <Button asChild variant="secondary">
            <a href={URL_CHECK_PHONE_REPAIR}>
              Sjekk priser for reparasjon
              <Icon name="arrow-right" />
            </a>
          </Button>
        </TrackClickEvent>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_other-damage-base",
            ui_item_text: "SVITSJ likevel",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
            ui_item_url: safeWebshopUrl || "",
          }}
        >
          <Button asChild variant="primary" disabled={loading}>
            <a href={safeWebshopUrl || ""}>
              <Icon name="trade-phone" />
              SVITSJ likevel
            </a>
          </Button>
        </TrackClickEvent>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_CANCEL,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_other-damage-base",
            ui_item_text: "Tilbake til forrige steg",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: "..",
          }}
        >
          <Button asChild variant="text">
            <Link to="..">
              <Icon name="arrow-left" />
              Tilbake til forrige steg
            </Link>
          </Button>
        </TrackClickEvent>
      </Container>
    </>
  );
}
