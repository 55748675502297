import { ProductBoxActionPoint } from "@telia-no-min-side/components";
import { types } from "@telia-no-min-side/utils";
import { getAppSpecificLink, isInternalLink } from "./link";
import { ProductActionIcons } from "util/constants/icons";
import { ActionItem } from "components/action-menu";

function getActionIcon(actionType: types.fixed.Action["type"]): ProductBoxActionPoint["icon"] {
  if (actionType === "ACTIVATE") return "plus";
  if (actionType === "CANCEL") return "close";
  if (actionType === "CHANGE") return "replace";
  if (actionType === "MANUAL") return "document";
  if (actionType === "ORDER") return "shopping";
  if (actionType === "ORDER_REMOTE") return "remote-control";
  if (actionType === "SETUP") return "wallplug";
  if (actionType === "FACTORY_RESET") return "ai-robot";
  if (actionType === "RETURN") return "recycle";
  if (actionType === "FREEZE") return "minus";
  if (actionType === "SETTINGS") return "settings";
  if (actionType === "LINK") return "link";
  if (actionType === "REGISTER") return "link";
  if (actionType === "INFO") return "info";
  if (actionType === "DEBUG") return "repair";
  return "settings";
}

export function getActionList(actions?: types.fixed.Product["actions"]): ProductBoxActionPoint[] | undefined {
  if (!actions) return;

  return actions.map((action) => ({
    kind: "link",
    isInternalNavigation: isInternalLink(action.application),
    href: getAppSpecificLink(action),
    icon: getActionIcon(action.type),
    text: action.displayName,
    type: action.type,
    styling: action.type === "CANCEL" ? "negative" : undefined,
  }));
}

export function getDeviceActionIcon(actionType: types.fixed.Action["type"]): ProductActionIcons {
  return ProductActionIcons[actionType] || ProductActionIcons.SETTINGS;
}

export function getDeviceActionList(actions?: types.fixed.Product["actions"]): ActionItem[] | undefined {
  if (!actions) return;

  return actions.map((action) => ({
    kind: "link",
    isInternalNavigation: isInternalLink(action.application),
    href: getAppSpecificLink(action),
    icon: getDeviceActionIcon(action.type),
    text: action.displayName,
    type: action.type,
    styling: action.type === "CANCEL" ? "negative" : undefined,
  }));
}
