import { graphql } from "src/gql";

export const GET_SUBSCRIPTION_INFO = graphql(`
  query getSubscriptionInfo($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      account {
        id
      }
      phoneNumber {
        localNumber
      }
      roles
      agreementType
      billingType
      subscriptionStatus
      statusReasonCode
      subscriptionStatusDate
      commitmentEndDate
      legalOwner {
        individual {
          firstName
          surname
        }
        organization {
          name
        }
      }
      productUser {
        firstName
        surname
      }
      userOffering {
        pricePlan
        name
        shortName
        offeringPrices {
          offeringPrices {
            code
            amount
            monetaryUnit
          }
        }
        monthlyCost {
          amount
        }
      }
      additionalProducts {
        id
        code
        productCategory
        productTerms {
          code
          amount
          monetaryUnit
        }
      }
      newOfferingDetails {
        offerings {
          shortName
          price {
            offeringPrices {
              code
            }
          }
        }
      }
    }
  }
`);
