import styled from "styled-components";
import { remCalc } from "../utils/fonts";
import { Skeleton } from "../Skeleton";
import { Card } from "../Card";

export const SkeletonItem = styled(Card)`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const SkeletonText = styled.div`
  margin-left: ${remCalc(24)};
`;

export const SkeletonButton = styled(Skeleton)`
  position: relative;
  display: flex;
  align-self: center;
  margin-left: auto;
`;
