import { TopupCategory } from "gql/graphql";
import { isPaidTopupHandler } from "./isPaidTopupHandler";
import { TopupLocalType } from "pages/mobile/mobile-subscription-dashboard/types";
import { formatDatalayerTransactionData } from "src/services/googleAnalytics/eventDataHelpers";

export function getTopupTransactionData(topup: TopupLocalType | undefined, phoneNumber: string, orderId?: string) {
  if (!topup) return;

  const getCategory = (type: TopupCategory) => {
    const categoryMap: { [key in TopupCategory]: string } = {
      [TopupCategory.DataFree]: "Service/Topup/Datapakke/Free",
      [TopupCategory.Domestic]: "Service/Topup/Datapakke/Paid",
      [TopupCategory.SpeedBoost]: "Service/Topup/Speedboost/Paid",
      [TopupCategory.DataBoost]: "Service/Topup/Databoost/Paid",
      [TopupCategory.DataBoostFree]: "Service/Topup/Databoost/Free",
      [TopupCategory.Roaming]: "Service/Topup/Roaming",
      [TopupCategory.PrepaidBalance]: "Service/Topup/PrepaidBalance",
      [TopupCategory.PrepaidDataPackage]: "Service/Topup/PrepaidDataPackage",
      [TopupCategory.PrepaidMinutes]: "Service/Topup/PrepaidMinutes",
      [TopupCategory.PrepaidMonthlyPackage]: "Service/Topup/PrepaidMonthlyPackage",
      [TopupCategory.PrepaidSmallPackage]: "Service/Topup/PrepaidSmallPackage",
      [TopupCategory.Unknown]: "Service/Topup/Unknown",
    };
    return categoryMap[type] || "Service/Topup";
  };

  const baseTransactionData = {
    actionId: orderId || "",
    shipping: 0,
    id: topup.id,
    name: topup.name,
    category: getCategory(topup.category),
    variant: phoneNumber.length === 8 ? "Mobile" : "Mobile-broadband",
  };

  if (isPaidTopupHandler(topup)) {
    const priceWithVat = topup.price?.valueWithVat ?? 0;
    const priceWithoutVat = topup.price?.valueWithoutVat ?? 0;
    const taxAmount = +(priceWithVat - priceWithoutVat).toFixed(2);

    return formatDatalayerTransactionData({
      ...baseTransactionData,
      revenue: priceWithVat,
      price: priceWithVat,
      tax: taxAmount,
    });
  }

  return formatDatalayerTransactionData({
    ...baseTransactionData,
    revenue: 0,
    price: 0,
    tax: 0,
  });
}
