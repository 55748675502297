import { Notification } from "@telia/teddy";

export function NoOrders() {
  return (
    <Notification variant="information">
      <Notification.Heading as="h3">
        <Notification.Icon>Ingen kjøpshistorikk tilgjengelig</Notification.Icon>
      </Notification.Heading>
      <Notification.Text>Det ser ut til at det foreløpig ikke er registrert noen kjøp på din konto.</Notification.Text>
    </Notification>
  );
}
