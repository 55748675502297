import { track } from "@telia-no-min-side/utils";

function getGaEvent(open: boolean, text: string): track.GenericEvent {
  const event: track.GenericEvent = {
    ui_item_action: `accordion-${open ? "open" : "close"}`,
    ui_item_context: "tilleggstjenester/trygg-på-nett",
    ui_item_text: text,
    ui_item_type: "accordion",
    ui_item_url: "no_url",
  };

  return event;
}

export function getAccordionTrackingEvent(text: string): {
  open: track.GenericEvent;
  close: track.GenericEvent;
} {
  return { open: getGaEvent(true, text), close: getGaEvent(false, text) };
}
