import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { ApolloError, NetworkStatus, useQuery } from "@apollo/client";
import { getCookieValue, setCookie } from "@telia-no-min-side/utils";
import { useSearchParams } from "react-router-dom";
import {
  UserOverviewDataQuery,
  AgreementType,
  BillingType,
  StatusReasonCode,
  SubscriptionStatus,
  UserRole,
} from "src/gql/graphql";
import { graphql } from "../gql";
import { COOKIE } from "util/constants/cookies";

export const subscriptionOverviewQuery = graphql(`
  query UserOverviewData {
    accounts {
      id
      roles
      agreementType
      subscriptions {
        billingType
        statusReasonCode
        subscriptionStatus
        subscriptionType
        subscriptionStatusDate
        account {
          id
        }
        roles
        productUser {
          firstName
          surname
        }
        phoneNumber {
          localNumber
        }

        userOffering {
          pricePlan
          shortName
          monthlyCost {
            amount
          }
          additionalProperties {
            value
            name
          }
        }
      }
    }
  }
`);
export enum MOBILE_ACCOUNT_TYPE {
  B2C = "B2C",
  B2B = "B2B",
}

type MobileAccountType = "B2C" | "B2B";

type MobileAccount = {
  isLoading: boolean;
  phoneNumber: string;
  accountId: string;
  setPhoneNumber: (phoneNumber: string) => void;
  setAccountId: (accountId: string) => void;
  allAccounts: UserOverviewDataQuery["accounts"] | undefined;
  accountType?: MobileAccountType;
  error: ApolloError | undefined;
  refetch: () => void;
  isRefetching: boolean;
};

const MobileAccountContext = createContext<MobileAccount>({
  isLoading: false,
  phoneNumber: "",
  accountId: "",
  setPhoneNumber: () => undefined,
  setAccountId: () => undefined,
  allAccounts: [],
  error: undefined,
  refetch: () => undefined,
  isRefetching: false,
});

export const BAN_KEY_PARAM = "ban";
export const PHONE_NUMBER_KEY_PARAM = "pn";

export const MobileAccountProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [search] = useSearchParams();
  const initialBan = search.get(BAN_KEY_PARAM);
  const initialPhoneNumber = search.get(PHONE_NUMBER_KEY_PARAM);
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber || "");
  const [accountId, setAccountId] = useState(initialBan || "");

  const { loading, data, refetch, error, networkStatus } = useQuery(subscriptionOverviewQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ accounts }) {
      const persistedAccountId = getCookieValue(COOKIE.USER_DATA_ACCOUNT_ID);
      const persistedPhoneNumber = getCookieValue(COOKIE.USER_DATA_PHONE_NUMBER);

      const allSubscriptions = accounts?.flatMap((account) => account.subscriptions);
      const validAccounts = accounts?.filter((account) => account?.subscriptions?.length);

      const isPersistedAccountAvailable = validAccounts?.find((account) => account.id === persistedAccountId);
      const accountId = isPersistedAccountAvailable ? persistedAccountId : validAccounts?.[0]?.id;

      const isPersistedSubscriptionAvailable = validAccounts?.find(
        (account) => account.subscriptions?.find((sub) => sub.phoneNumber.localNumber === persistedPhoneNumber)
      );
      const selectedPhoneNumber = isPersistedSubscriptionAvailable
        ? persistedPhoneNumber
        : allSubscriptions?.[0]?.phoneNumber.localNumber;

      setPhoneNumber(selectedPhoneNumber || "");
      setAccountId(accountId || "");
    },
  });

  useEffect(() => {
    if (accountId) {
      setCookie(COOKIE.USER_DATA_ACCOUNT_ID, accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (phoneNumber) {
      setCookie(COOKIE.USER_DATA_PHONE_NUMBER, phoneNumber);
    }
  }, [phoneNumber]);

  const agreementType = data?.accounts?.find((account) => account.id === accountId)?.agreementType;

  return (
    <MobileAccountContext.Provider
      value={{
        isLoading: loading,
        phoneNumber,
        setPhoneNumber,
        accountId,
        setAccountId,
        allAccounts: data?.accounts,
        accountType:
          agreementType === AgreementType.Private
            ? "B2C"
            : agreementType === AgreementType.Business
            ? "B2B"
            : undefined,
        error,
        refetch,
        isRefetching: networkStatus === NetworkStatus.refetch,
      }}
    >
      {children}
    </MobileAccountContext.Provider>
  );
};

export function useMobileAccount(): MobileAccount {
  const context = useContext<MobileAccount>(MobileAccountContext);

  if (!context) {
    throw Error(
      "No MobileAccountContext found! This usually happens when you try to access a context outside of a provider"
    );
  }
  return context;
}

export type UseMobileAccountSubscriptionData = {
  billingType: BillingType;
  statusReasonCode?: StatusReasonCode | null;
  subscriptionStatus?: SubscriptionStatus | null;
  subscriptionType?: string | null | undefined;
  subscriptionStatusDate?: string | null | undefined;
  roles: UserRole[];
  account: { id: string };
  productUser?: { firstName: string } | null;
  phoneNumber: { localNumber: string };
  userOffering?:
    | {
        pricePlan?: string | null | undefined;
        shortName?: string | null | undefined;
        monthlyCost?: { amount?: number | undefined } | null | undefined;
        additionalProperties?: ({ value?: string | null } | null)[] | null;
      }
    | undefined
    | null;
};
