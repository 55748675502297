import { graphql } from "src/gql";

export const GET_MOBILE_DASHBOARD_DATA = graphql(`
  query getMobileDashboardData($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      roles
      agreementType
      billingType
      userOffering {
        pricePlan
        shortName
        additionalProperties {
          name
          value
        }
        offeringPrices {
          offeringPrices {
            code
            amount
            monetaryUnit
          }
        }
      }

      additionalProducts {
        id
        code
        productCategory
        presentation {
          active
          modifiable
        }
        productTerms {
          code
        }
      }

      usage {
        expireDate
        type
        usage
        service
        category
        activeDataBoostSession
        remaining {
          amount
          unit
        }
        limit {
          amount
          unit
        }
        period {
          endDate
        }
      }
      productUser {
        birthDate
      }
    }
  }
`);
