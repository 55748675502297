type ReceiptType = "success" | "error";

type CommonOptions = {
  returnTo: string;
  returnToButtonName: string;
  isExternalLink?: "true" | "false";
};

type SuccessOptions = CommonOptions;

type ErrorOptions = CommonOptions & {
  retryUrl?: string;
};

type Options<T extends ReceiptType> = T extends "success" ? SuccessOptions : ErrorOptions;

export function getReceiptSearchParams<T extends ReceiptType>(receiptType: T, options: Options<T>) {
  const commonSearchParams = `?returnTo=${options.returnTo}&name=${options.returnToButtonName}${
    options.isExternalLink ? `&external=${options.isExternalLink}` : ""
  }`;

  if (receiptType === "error" && "retryUrl" in options) {
    return `${commonSearchParams}&retry=${options.retryUrl}`;
  }
  return commonSearchParams;
}
