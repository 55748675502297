import { Button } from "@telia-no-min-side/components";
import { Text } from "@telia/teddy";
import { CustomerCareLink } from "src/components/customer-care-link";
import { ContentSpacing } from "./style";
import { useSelfRecoveryState } from ".";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function LandingPage(): JSX.Element {
  const { setRecoveryPage } = useSelfRecoveryState();
  return (
    <ContentSpacing>
      <Text>
        For at vi skal kunne hente dine opplysninger trenger vi mer informasjon fra deg. Etter du har fylt inn denne
        informasjonen trenger vi din BankID for å fullføre prosessen.
      </Text>
      <Button
        variant="primary"
        onClick={() => setRecoveryPage("chooseRecoveryMethod")}
        trackEvent={{
          ui_item_action: TRACK_EVENT.ITEM_ACTION.NEXT_CLICK,
          ui_item_context: TRACK_EVENT.ITEM_CONTEXT.SELF_RECOVERY,
          ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
          ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
          ui_item_text: TRACK_EVENT.ITEM_TEXT.FILL_IN_INFO,
        }}
      >
        Fyll inn din informasjon
      </Button>
      <Text>
        Har du ikke mulighet til å bruke BankID, er du velkommen til å kontakte kundeservice for å løse denne
        situasjonen.
      </Text>
      <CustomerCareLink trackingContext="self-recovery" />
    </ContentSpacing>
  );
}
