import React, { ReactNode } from "react";
import { BackdropProps } from "../Backdrop";
import { CardProps } from "../Card";
import { AriaProvider } from "../hooks/context/aria";
import { Breakpoints } from "../utils/breakpoints";
import { ModalElement } from "./ModalElement";

export type ModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: ReactNode;
  size?: Breakpoints;
  removePadding?: boolean;
  showCloseButton?: boolean;
  className?: string;
  dataTrackingID?: string;
  backDropColor?: BackdropProps["color"];
  onClose?: () => void;
} & Omit<CardProps<"div">, "children">;

/**
 * @deprecated Use Teddy components instead
 */
export function Modal(props: ModalProps): JSX.Element {
  return (
    <AriaProvider>
      <ModalElement {...props} />
    </AriaProvider>
  );
}

export { ModalBody } from "./ModalBody";
export { ModalFooter } from "./ModalFooter";
export { ModalHeader } from "./ModalHeader";
export { ModalHeading } from "./ModalHeading";
export { PromptModal } from "./PromptModal";
