import { Text, Button, Heading, Flex, CheckboxGroup } from "@telia/teddy";
import { useState } from "react";
import { Container } from "@telia-no-min-side/components";
import { useNavigate } from "react-router-dom";
import { uri } from "@telia-no-min-side/utils";
import { MobileUserVerification } from "./MobileUserVerification";

export function SelfVerifyProductUser() {
  const navigate = useNavigate();
  const justVerified = window.location.href.includes("justverified");
  const [mobileVerificationVisible, setMobileVerificationVisible] = useState(justVerified || false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  if (mobileVerificationVisible) {
    return <MobileUserVerification />;
  }

  const onContinueClick = () => {
    // If user has both fixed & mobile products, he's redirected to mobile verification
    if (selectedProducts.some((product) => product === "mobile" || product === "mobile-broadband")) {
      setMobileVerificationVisible(true);
    } else {
      navigate(uri.minSideFixed("/minside/kontogjenoppretting/"));
    }
  };

  return (
    <Container showGoBackButton padding maxWidth="md">
      <Flex gap="gutter-xl" direction="column" p="page-padding-sm">
        <Heading as="h2" variant="title-200">
          Gjenopprett din profil
        </Heading>
        <Text mb="gutter-sm">
          For at vi skal klare å hente ditt kundeforhold, ber vi deg huke av dine Telia-produkter.
        </Text>
        <CheckboxGroup direction="column" value={selectedProducts} onValueChange={setSelectedProducts}>
          <CheckboxGroup.GroupLabel>Huk av dine Telia-produkter:</CheckboxGroup.GroupLabel>
          <CheckboxGroup.List>
            <CheckboxGroup.Item value="mobile">Mobilabonnement</CheckboxGroup.Item>
            <CheckboxGroup.Item value="internet">Internett</CheckboxGroup.Item>
            <CheckboxGroup.Item value="tv">TV</CheckboxGroup.Item>
            <CheckboxGroup.Item value="mobile-broadband">Mobilt bredbånd</CheckboxGroup.Item>
          </CheckboxGroup.List>
        </CheckboxGroup>
        <div>
          <Button
            variant="primary"
            onClick={onContinueClick}
            fullWidth={false}
            disabled={selectedProducts.length === 0}
          >
            Fortsett
          </Button>
        </div>
      </Flex>
    </Container>
  );
}
