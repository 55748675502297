import { Outlet } from "react-router-dom";
import { Internet } from "./pages/internet";
import { PrepaidOrderHistory } from "./pages/prepaid/order-history";
import { MutationResponseProvider } from "hooks/useMutationResponse";
import { AdditionalProductsProvider } from "hooks/useAdditionalProducts";
import { MobileBroadbandDashboard } from "pages/internet/mobile-broadband/modules/Dashboard";
import { MobileSubscriptionSettings } from "pages/mobile/mobile-subscription-settings";
import { MobileSubscriptionSimPinPuk } from "./pages/mobile/mobile-subscription-sim-pin-puk";
import { MobileSubscriptionDataUsageHistory } from "pages/mobile/mobile-subscription-data-usage-history";
import { MobileSubscriptionActivateSimCard } from "./pages/mobile/mobile-subscription-activate-sim-card";
import { MobileSubscriptionOrderBlankSimCard } from "./pages/mobile/mobile-subscription-order-blank-sim-card";
import { MobileSubscriptionOrderAdditionalSimCard } from "./pages/mobile/mobile-subscription-order-additional-sim-card";
import { BroadbandDashboard, WifiNetwork, AdditionalServices, BuyInternetEquipment } from "pages/internet/broadband";

export const internetRoutes = [
  {
    path: "internett",
    element: <Internet />,
    handle: {
      crumb: "Internett",
    },
    children: [
      { index: true, element: <BroadbandDashboard /> },
      {
        path: "mobilt-bredband",
        children: [
          {
            index: true,
            element: (
              <AdditionalProductsProvider>
                <MutationResponseProvider>
                  <MobileBroadbandDashboard />
                </MutationResponseProvider>
              </AdditionalProductsProvider>
            ),
            handle: { crumb: "Mobilt bredbånd" },
          },
          {
            path: "tidligere-dataforbruk",
            element: <MobileSubscriptionDataUsageHistory />,
            handle: {
              crumb: "Tidligere dataforbruk",
            },
          },

          {
            path: "sim-pin-puk",
            element: (
              <MutationResponseProvider>
                <Outlet />
              </MutationResponseProvider>
            ),
            children: [
              {
                index: true,
                element: <MobileSubscriptionSimPinPuk />,
                handle: {
                  crumb: "SIM, PIN & PUK",
                },
              },
              { path: "bestill-erstatning", element: <MobileSubscriptionOrderBlankSimCard /> },
              { path: "aktivere-sim", element: <MobileSubscriptionActivateSimCard /> },
              { path: "bestill-sim", element: <MobileSubscriptionOrderAdditionalSimCard /> },
            ],
          },
          {
            path: "innstillinger",
            element: <MobileSubscriptionSettings />,
            handle: {
              crumb: "Innstillinger",
            },
          },
          {
            path: "kjopshistorikk",
            element: <PrepaidOrderHistory />,
            handle: {
              crumb: "Kjøpshistorikk",
            },
          },
        ],
      },
      {
        path: "wifi-nettverk",
        element: <WifiNetwork />,
        handle: {
          crumb: "Wifi nettverk",
        },
      },
      {
        path: "tilleggstjenester",
        element: <AdditionalServices />,
        handle: {
          crumb: "Tilleggstjenester",
        },
      },
      {
        path: "utstyr/kjop-smart-wifi",
        element: <BuyInternetEquipment />,
        handle: {
          crumb: "Bestill Telia Smart Wifi",
        },
      },
    ],
  },
];
