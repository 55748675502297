import { useAxiosSWR } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { Heading, Flex, Box } from "@telia/teddy";
import { NavigationCard } from "components/NavigationCard";
import { useFetchProductsAdditionalInfo, useFetchProfile, useFetchTvBoxes } from "src/api/fixed";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { fetchPartnerInformation } from "util/PartnerHacks";
import { tvCardWidth } from "../utils/constants";
import { FreeMaxBasic } from "../buy/free-max-basic/FreeMaxBasic";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function BuyEquipment() {
  const profile = useFetchProfile();
  const partnerInfo = fetchPartnerInformation(profile.data?.businessUnitId);
  const productsAdditionalInfo = useFetchProductsAdditionalInfo();
  const { data: hasKollektivOffer } = useAxiosSWR(uri.minSideFixed("/api/offers/kollektiv-recording"));
  const { tvBoxes, isLoading: isTvBoxesLoading } = useFetchTvBoxes({ enableSkeletonNumberStore: true });
  const isLoading = isTvBoxesLoading || productsAdditionalInfo.isLoading || profile.isLoading;
  const canOrderDecoder = !!productsAdditionalInfo.data?.canOrderDecoder;
  const ownsTvBoxes = (tvBoxes?.products || []).length > 0;
  const showOrderFreeMaxBasic =
    !!profile.data?.customerId &&
    !!productsAdditionalInfo.data?.hasMaxBasicOffer &&
    !productsAdditionalInfo.data?.hasMaxBasicProduct;

  const visibleItems: Record<string, boolean> = {
    showPremiumChannelsLink: !productsAdditionalInfo.data?.hasBoxDelayedReturn,
    showBuyNewTvCard: ownsTvBoxes && canOrderDecoder,
    showBuyNewTvPartnerCard: ownsTvBoxes && !canOrderDecoder && !!partnerInfo,
    showOrderPoints: !!productsAdditionalInfo.data?.canOrderExtraPoints,
    showOrderRecording: !!productsAdditionalInfo.data?.canOrderRecording,
    showRecordingThroughDecoder: !!productsAdditionalInfo.data?.hasRecordingThroughDecoder,
    showOrderFreeMaxBasic: showOrderFreeMaxBasic,
  };

  const anyItemsVisible = Object.values(visibleItems).some((item) => item);

  if (!anyItemsVisible) return null;

  return (
    <Box mb="200">
      <Heading as="h2" variant="title-100">
        Bestill
      </Heading>
      <Flex mt="200" gap="400" wrap="wrap">
        {visibleItems.showBuyNewTvCard ? (
          <NavigationCard
            title="Bestill Telia Play box"
            description="Bestill vår aller beste strømme- og TV-boks!"
            icon="router"
            link={INTERNAL_LINK.FIXED.TV_BUY_NEW_BOX}
            width={tvCardWidth}
          />
        ) : null}
        {visibleItems.showBuyNewTvPartnerCard ? (
          <NavigationCard
            title="Bestill TV-boks"
            description={
              <>
                Ta kontakt med {partnerInfo?.name} på tlf. {partnerInfo?.phone} eller se{" "}
                <a href={partnerInfo?.homepage} target="_blank" rel="noreferrer">
                  hjemmeside
                </a>
                dersom du ønsker å gjøre endringer for TV-boks.
              </>
            }
            icon="router"
            link={partnerInfo?.homepage}
            width={tvCardWidth}
          />
        ) : null}
        {visibleItems.showPremiumChannelsLink ? (
          <NavigationCard
            title="Premium innhold"
            description="Vil du ha mer film, serier, sport og annen underholdning, kan du utvide med vårt premium-innhold."
            icon="entertainment"
            link={uri.purchase("/premiumkanal/alle")}
            width={tvCardWidth}
            isExternalLink
          />
        ) : null}
        {visibleItems.showOrderPoints ? (
          <NavigationCard
            title="Kjøp TV-poeng"
            description="Du kan kjøpe 10, 20 eller 60 ekstra poeng. Det er ingen binding."
            icon="star"
            link={uri.purchase(ICX_REQUEST.URL_CHANNEL_POINTS_CHANGE)}
            width={tvCardWidth}
            isExternalLink
          />
        ) : null}
        {visibleItems.showOrderRecording ? (
          <NavigationCard
            title="Opptak"
            description={
              <>
                Ta opp det du ikke fikk sett ferdig. Alt blir tilgjengelig i skyen og du kan se det når det passer for
                deg. Det er ingen binding.
                {hasKollektivOffer && <span> Opptak er inkludert i din fellesavtale.</span>}
              </>
            }
            icon="record"
            link={INTERNAL_LINK.FIXED.TV_ORDER_RECORDING}
            width={tvCardWidth}
          />
        ) : null}
        {visibleItems.showRecordingThroughDecoder ? (
          <NavigationCard
            title="Opptak på TV-boksen"
            description="TV-boksen din har mulighet for å lagre opptak lokalt. Du har også tilgang til opptak i Telia Play, men dette er
        frakoblet fra de lokale opptakene på TV-boksen."
            icon="record"
            link={EXTERNAL_LINK.FILM_RENTALS_AND_RECORDINGS}
            width={tvCardWidth}
          />
        ) : null}
        {visibleItems.showOrderFreeMaxBasic ? <FreeMaxBasic /> : null}

        {isLoading ? <NavigationCard isLoading width={tvCardWidth} /> : null}
      </Flex>
    </Box>
  );
}
