import { useMutation } from "@apollo/client";
import { Container } from "@telia-no-min-side/components";
import { Button, TextField, Grid, Heading } from "@telia/teddy";
import { CREATE_B2C_ACCOUNT } from "pages/b2b-family-discount/mutations";
import { useState } from "react";

export function CreateBan() {
  const [createB2CAccountMutation, { loading }] = useMutation(CREATE_B2C_ACCOUNT);
  const [phoneNumber, setPhoneNumber] = useState("");
  return (
    <Container padding>
      <Grid gap={"gutter-md"}>
        <Heading as="h1" variant="title-300">
          Create ban
        </Heading>
        <TextField>
          <TextField.Label>Phone number</TextField.Label>
          <TextField.Input onValueChange={setPhoneNumber} value={phoneNumber} />
        </TextField>
        <Button
          disabled={!phoneNumber}
          loading={loading}
          onClick={() =>
            createB2CAccountMutation({
              variables: {
                phoneNumber,
              },
            })
          }
        >
          Create Ban
        </Button>
      </Grid>
    </Container>
  );
}
