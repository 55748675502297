import { types } from "@telia-no-min-side/utils";
import { isUnauthorizedResponse } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { getCustomerSpecificUrl, GetCustomerSpecificUrlArgs } from "./utils";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchUser(args?: GetCustomerSpecificUrlArgs, skip?: boolean) {
  const { error, ...rest } = useAxiosSWR<types.fixed.User>(
    skip ? null : getCustomerSpecificUrl(ICX_REQUEST.URL_USER, args)
  );

  return {
    error,
    isError: !!error,
    isUnauthorized: isUnauthorizedResponse(error),
    ...rest,
  };
}
