export const UNIT_SIZE_MULTIPLIER = 1024;

export enum UnitSize {
  byte = "B",
  kilobyte = "KB",
  megabyte = "MB",
  megabyteLong = "MEGABYTE",
  gigabyte = "GB",
  terabyte = "TB",
}

export function formatDataAmount(bytes: number, precision = 1): string {
  const kilobyte = UNIT_SIZE_MULTIPLIER;
  const megabyte = kilobyte * UNIT_SIZE_MULTIPLIER;
  const gigabyte = megabyte * UNIT_SIZE_MULTIPLIER;
  const terabyte = gigabyte * UNIT_SIZE_MULTIPLIER;

  if (bytes <= 0) {
    return `${Number(0).toFixed(precision).replace(".", ",")} ${UnitSize.gigabyte}`;
  }

  if (bytes >= kilobyte && bytes < megabyte) {
    return `${(bytes / kilobyte).toFixed(precision).replace(".", ",")} ${UnitSize.kilobyte}`;
  }

  if (bytes >= megabyte && bytes < gigabyte) {
    return `${(bytes / megabyte).toFixed(precision).replace(".", ",")} ${UnitSize.megabyte}`;
  }

  if (bytes >= gigabyte && bytes < terabyte) {
    return `${(bytes / gigabyte).toFixed(precision).replace(".", ",")} ${UnitSize.gigabyte}`;
  }

  if (bytes >= terabyte) {
    return `${(bytes / terabyte).toFixed(precision).replace(".", ",")} ${UnitSize.terabyte}`;
  }

  return `${bytes.toFixed(precision).replace(".", ",")} ${UnitSize.byte}`;
}
