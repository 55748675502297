import { useState } from "react";
import { Card, Flex, Button, Icon, Text, ProgressBar } from "@telia/teddy";
import { Terms } from "./Terms";
import { quantityToBytes } from "util/data-usage";
import { calculateTimeLeft, formatBytesToGB, getRoamingCountriesByZone } from "../../utils";
import { ArrayElement } from "../../utils/sortDataUsage";
import { GetMobileDashboardDataQuery, TopupCategory } from "gql/graphql";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";

type Props = {
  usage: ArrayElement<GetMobileDashboardDataQuery["subscription"]["usage"]>;
};

export function Roaming({ usage }: Props) {
  const [isTermsVisible, setIsTermsVisible] = useState(false);
  const { openSelectedTopupDrawer } = useMobileDashboardContext();
  const { daysLeft, hoursLeft, minutesLeft } = calculateTimeLeft(usage?.period?.endDate);

  const limitAmount = usage?.remaining?.amount;
  const remainingAmount = usage?.remaining?.amount;

  const totalLimit = limitAmount ? formatBytesToGB(limitAmount) : null;
  const remainingData = remainingAmount ? formatBytesToGB(remainingAmount) : null;
  const progress =
    limitAmount && remainingAmount
      ? Math.round(
          (quantityToBytes({ amount: remainingAmount, unit: usage?.remaining?.unit })! /
            quantityToBytes({ amount: limitAmount, unit: usage?.remaining?.unit })!) *
            100
        )
      : 0;

  const roamingZoneForUI = usage?.service?.replace(/^USA/, "SONE10") || "";
  const roamingCountriesByZone = getRoamingCountriesByZone(roamingZoneForUI);

  return (
    <Card bordered shadow layout="default" variant="white" gap="gutter-md">
      {isTermsVisible ? (
        <Terms title={roamingZoneForUI} countries={roamingCountriesByZone} setIsTermsVisible={setIsTermsVisible} />
      ) : (
        <>
          <Card.Heading variant="title-400">{remainingData} igjen</Card.Heading>
          <Card.Line />
          <Card.Content>
            <Flex direction="column" gap="100">
              <Flex justify="between">
                {remainingData && <Text variant="paragraph-100-medium">{remainingData}</Text>}
                {totalLimit && <Text>{totalLimit}</Text>}
              </Flex>
              <ProgressBar progress={progress} />
              {daysLeft > 0 ? (
                <Text>Utgår om {daysLeft} dager</Text>
              ) : hoursLeft > 0 ? (
                <Text>Utgår om {hoursLeft} timer</Text>
              ) : minutesLeft > 0 ? (
                <Text>Utgår om {minutesLeft} minutter</Text>
              ) : null}
            </Flex>
          </Card.Content>

          <Card.Footer direction="column" gap="100">
            <Button width="100%" variant="primary" onClick={() => openSelectedTopupDrawer(TopupCategory.Roaming)}>
              <Icon name="plus" />
              Kjøp flere datapakker
            </Button>

            <Button width="100%" variant="text" onClick={() => setIsTermsVisible(true)}>
              <Icon name="info" />
              Gjelder i {roamingZoneForUI}
            </Button>
          </Card.Footer>
        </>
      )}
    </Card>
  );
}
