import { useLocation } from "react-router-dom";
import { useFetchProductGroup } from "src/api/fixed";
import { ProductCard } from "./product-card";
import { OrderSummary } from "./summary";
import { getBoxNameFromRoute, getRemoteControlPurchaseOption } from "pages/tv/utils/remoteControl";
import { DashboardLayout } from "pages/tv/common/DashboardLayout";
import { breakpoint, Flex, Box } from "@telia/teddy";

export function BuyRemoteControlPage(): JSX.Element | null {
  const location = useLocation();
  const remoteControl = useFetchProductGroup("grp_remote_control_decoder");
  const remoteControlProduct = getRemoteControlPurchaseOption(remoteControl.data);
  const boxName = getBoxNameFromRoute(location);

  return (
    <DashboardLayout>
      <Flex gap="gutter-md" maxWidth={breakpoint.teddyBreakpointLg} wrap="wrap">
        <Box flexGrow="2" flexShrink="1">
          <ProductCard
            boxName={boxName}
            remoteControlData={remoteControlProduct}
            isError={remoteControl.isError}
            isLoading={remoteControl.isLoading}
          />
        </Box>
        {remoteControlProduct && (
          <Box flexGrow="1" flexShrink="1">
            <OrderSummary remoteControlProduct={remoteControlProduct} />
          </Box>
        )}
      </Flex>
    </DashboardLayout>
  );
}
