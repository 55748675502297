import { graphql } from "src/gql";

export const AdditionalProduct = graphql(`
  fragment AdditionalProduct on AdditionalProduct {
    id
    musicFreedomTerms
    productCategory
    code
    presentation {
      active
      selected
      modifiable
      removed
      preselected
      changed
      included
    }
    expirationDate
    effectiveDate
    price {
      price
      priceAggregated
      priceWithoutDiscount
      discountDuration
    }
    descriptionLink
    name
    mainProductGroup
    weight
    shortDescription
    longDescription
    excludedAdditionalProducts
    shortName
    requireEmail
    productGroup
    additionalProperties {
      name
      value
    }
    groups
    insuranceTerms
    productTerms {
      description
      code
      amount
      amountWithoutVat
      monetaryUnit
    }
    characteristic {
      featureCode
      config {
        name
        value
        characteristicType
      }
    }
  }
`);

export const GroupedAdditionalProductsFragment = graphql(`
  fragment GroupedAdditionalProducts on GroupedAdditionalProducts {
    NO_GROUP {
      ...AdditionalProduct
    }
    TRYGG {
      ...AdditionalProduct
    }
    SKY {
      ...AdditionalProduct
    }
    HBO {
      ...AdditionalProduct
    }
    STORYTEL {
      ...AdditionalProduct
    }
    VOICEMAIL {
      ...AdditionalProduct
    }
    SPOTIFY {
      ...AdditionalProduct
    }
    EXTRA_SIM {
      ...AdditionalProduct
    }
    BARRING_CPA {
      ...AdditionalProduct
    }
    BARRING_GPRS {
      ...AdditionalProduct
    }
    BARRING_CALLS {
      ...AdditionalProduct
    }
  }
`);
