import { Icon } from "@telia/teddy";
import { GaugeMeter } from "@telia-no-min-side/components";
import { format, formatDataAmount } from "@telia-no-min-side/utils";
import { FragmentType, getFragmentData, graphql } from "../../gql";
import { Text } from "./style";
import { getCurrentDownloadSpeed, getDataBoostRemainingTime, getSumDataUsage, isValidSpeedDownload } from "./utils";
import { Lozenge } from "@telia/styleguide";
import { ComponentProps } from "react";

export const subscriptionUsageFragment = graphql(`
  fragment SubscriptionUsage on SubscriptionData {
    userOffering {
      pricePlan
      offeringPrices {
        offeringPrices {
          code
          amount
          monetaryUnit
        }
      }
    }
    usage {
      used {
        amount
        unit
        formatted
      }
      expireDate
      activeDataBoostSession
      type
      service
      category
      usage
      remaining {
        amount
        unit
      }
    }
  }
`);

type Props = {
  isError: boolean;
  isTeliaX: boolean;
  subscriptionUsage: FragmentType<typeof subscriptionUsageFragment>;
  unlimitedSpeedAmount: number | undefined;
  disabled?: boolean;
  summerCampaignDataAmount?: number;
  summerCampaignDataAmountUnit?: string;
};

export function DataUsageGaugeMeter({
  isError,
  isTeliaX,
  subscriptionUsage,
  unlimitedSpeedAmount,
  disabled,
  summerCampaignDataAmount,
  summerCampaignDataAmountUnit,
}: Props) {
  const { usage, userOffering } = getFragmentData(subscriptionUsageFragment, subscriptionUsage);
  if (isError && (!usage || !userOffering)) {
    return (
      <GaugeMeter strokeWidth={5} percentage={0}>
        <Icon name="error" />
      </GaugeMeter>
    );
  }

  if (!usage || !userOffering) return null;

  const sumDataUsage = getSumDataUsage(usage, userOffering, summerCampaignDataAmount, summerCampaignDataAmountUnit);
  const dataBoostRemainingTime = getDataBoostRemainingTime(usage);
  const currentDownloadSpeed = getCurrentDownloadSpeed(usage, userOffering);

  const gaugeMeterLozenge: ComponentProps<typeof Lozenge> | undefined = disabled
    ? {
        label: "Sperret",
        status: "info",
        icon: "info",
      }
    : undefined;

  if (!sumDataUsage && !currentDownloadSpeed && !dataBoostRemainingTime)
    return (
      <GaugeMeter strokeWidth={5} percentage={0} disabled={disabled} lozenge={gaugeMeterLozenge}>
        <Icon name="error" />
      </GaugeMeter>
    );

  if (dataBoostRemainingTime?.length) {
    return (
      <GaugeMeter strokeWidth={5} percentage={1} isPulsing={!disabled} disabled={disabled} lozenge={gaugeMeterLozenge}>
        <Text>Boost: {dataBoostRemainingTime}</Text>
      </GaugeMeter>
    );
  }
  if (sumDataUsage && !isTeliaX)
    return (
      <GaugeMeter
        strokeWidth={5}
        percentage={sumDataUsage.totalDataRemaining / sumDataUsage.totalDataToUse}
        disabled={disabled}
        lozenge={gaugeMeterLozenge}
      >
        <Text>{formatDataAmount(sumDataUsage.totalDataRemaining)}</Text>
      </GaugeMeter>
    );
  const speedDownload = currentDownloadSpeed?.speedDownload;
  const isValidSpeed = isValidSpeedDownload(speedDownload);

  return (
    <GaugeMeter
      strokeWidth={5}
      percentage={currentDownloadSpeed?.isReduced ? 0 : 1}
      disabled={disabled}
      lozenge={gaugeMeterLozenge}
    >
      {isValidSpeed && isTeliaX ? (
        <Text>
          {unlimitedSpeedAmount || speedDownload.amount}{" "}
          {typeof speedDownload.monetaryUnit === "string" && format.startCase(speedDownload.monetaryUnit)}
        </Text>
      ) : (
        <Icon name="error" />
      )}
    </GaugeMeter>
  );
}
