import { TopupDurationTypes } from "src/gql/graphql";
import { TopupLocalType } from "pages/mobile/mobile-subscription-dashboard/types";

export const getDataPackagesDescription = (topups: TopupLocalType[], isB2BDobbelSubscription?: boolean) => {
  const topupWithDuration = topups.find((topup) => topup.duration);

  if (isB2BDobbelSubscription) {
    return "Datapakkene varer frem til dataen er brukt opp. Gjenstår det data i slutten av perioden, vil dette bli overført til neste periode.";
  }

  if (topupWithDuration?.duration.type === TopupDurationTypes.Calendrical) {
    return `Datapakkene varer i ${topupWithDuration.duration.durationCalendrical?.days} dager fra kjøp, eller til dataen er brukt opp.`;
  }

  if (topupWithDuration?.duration.type === TopupDurationTypes.Billcycle) {
    return `Datapakkene varer til ${topupWithDuration.duration.durationBillcycle?.untilDateInclusive}, eller til dataen er brukt opp.`;
  }

  if (topupWithDuration?.duration.type === TopupDurationTypes.Hours) {
    return `Datapakkene varer ${topupWithDuration.duration.durationHours} timer fra kjøp, eller til dataen er brukt opp.`;
  }

  return "Datapakkene gir deg tilleggsdata, inntil pakken utløper.";
};
