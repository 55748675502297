import styled from "styled-components";
import { Modal, ModalFooter } from "@telia-no-min-side/components";

export const StyledModal = styled(Modal)`
  width: min-content;
  min-width: 300px;
`;

export const StyledFooter = styled(ModalFooter)`
  text-align: left;
  ul {
    margin-top: 0;
  }
`;

export const Wrapper = styled.span`
  display: inline-block;
`;
