import { ProductCategory } from "@telia-no-min-side/utils/src/types/fixed";

export const getSafeOnNetProducts = (productCategory: ProductCategory | undefined) => {
  const help = productCategory?.products?.find((product) => product.id === "TPN-help");
  const teliaSky = productCategory?.products?.find((product) => product.id === "TPN-Telia-Sky");
  const fSecure = productCategory?.products?.find((product) => product.id === "TPN-F-Secure");

  return {
    help,
    teliaSky,
    fSecure,
  };
};
