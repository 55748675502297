import dayjs from "dayjs";
import { useSWRConfig, MutatorCallback } from "swr";
import { getCookieValue, setCookie, types } from "@telia-no-min-side/utils";
import { useFetchUser } from "src/api/fixed/useFetchUser";
import { urlInsight } from "src/api/fixed/useFetchGDPR";
import { useFetchSelectedAccount } from "src/api/fixed/useFetchSelectedAccount";
import { axiosPost } from "src/services/axios/axios";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { COOKIE } from "util/constants/cookies";

type RequestData = {
  customerId: string;
};

export function useFixedAccount() {
  const { onFixedAccountChange } = useOnFixedAccountChange();
  const user = useFetchUser();
  const selectedAccount = useFetchSelectedAccount();
  const customerIdToSelect = getCookieValue(COOKIE.FIXED_SELECTED_ACCOUNT);
  const selectedCustomerId = user.data?.usersWithCustomerId.find((customer) => customer.selected)?.customerId;
  const isChangingProfile = customerIdToSelect !== selectedCustomerId;

  async function changeFixedAccount(customerIdToSet: string) {
    const currentSelectedCustomerId = getCookieValue(COOKIE.FIXED_SELECTED_ACCOUNT);
    if (currentSelectedCustomerId === customerIdToSet) return;
    user.data &&
      user.mutate(
        {
          ...user.data,
          usersWithCustomerId: user.data?.usersWithCustomerId.map((customer) => ({
            ...customer,
            selected: customer.customerId === customerIdToSet,
          })),
        },
        { revalidate: false }
      );
    selectedAccount.mutate({ customerId: Number(customerIdToSet) }, { revalidate: false });
    setCookie(COOKIE.FIXED_SELECTED_ACCOUNT, customerIdToSet, {
      expires: dayjs().add(1, "year"),
      path: "/",
    });
    const response = await axiosPost<types.fixed.Profile, RequestData>(ICX_REQUEST.URL_USER, {
      customerId: `${customerIdToSet}`,
    });
    onFixedAccountChange();
    return response;
  }

  return {
    changeFixedAccount,
    isChangingProfile,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useMatchMutate<Data = any>() {
  const { cache, mutate } = useSWRConfig();
  return (matcher: RegExp, data?: Data | Promise<Data> | MutatorCallback<Data>, shouldRevalidate?: boolean) => {
    if (!(cache instanceof Map)) {
      throw new Error("matchMutate requires the cache provider to be a Map instance");
    }

    const keys = [];

    for (const key of cache.keys()) {
      if (matcher.test(key)) {
        keys.push(key);
      }
    }

    const mutations = keys.map((key) => {
      if (String(key).includes("/all")) return;
      return mutate(key, data, shouldRevalidate);
    });
    return Promise.all(mutations);
  };
}

const stringToValidRegex = (s: string) => s.split("/").join("\\/");

export function useOnFixedAccountChange() {
  const { mutate } = useSWRConfig();
  const matchMutate = useMatchMutate();

  function onFixedAccountChange() {
    mutate(ICX_REQUEST.URL_USER);
    mutate(ICX_REQUEST.URL_SELECTED_ACCOUNT);
    mutate(ICX_REQUEST.URL_GET_PROFILE);
    mutate(ICX_REQUEST.URL_BROADBAND_SUBSCRIPTION);
    mutate(ICX_REQUEST.URL_BROADBAND_PRODUCT);
    mutate(ICX_REQUEST.URL_TV_BOXES);
    mutate(ICX_REQUEST.URL_PREMIUM_CHANNELS);
    mutate(ICX_REQUEST.URL_TV_SUBSCRIPTION);
    mutate(urlInsight);
    mutate(ICX_REQUEST.URL_PRODUCTS_ADDITIONAL_INFO);
    mutate(ICX_REQUEST.URL_LOYALTY);
    mutate(ICX_REQUEST.URL_TV_RECORD);
    mutate(ICX_REQUEST.URL_SAFE_ON_NET);
    mutate(ICX_REQUEST.URL_MOVIE_RENTAL);
    mutate(ICX_REQUEST.URL_LAST_INVOICE);
    mutate(ICX_REQUEST.URL_NETWORK_STATISTICS);
    mutate(ICX_REQUEST.URL_ALL_INVOICE_HISTORY);
    mutate(ICX_REQUEST.URL_TECHNICIAN_VISIT);
    mutate(ICX_REQUEST.URL_PRODUCT_RESTRICTIONS);
    matchMutate(new RegExp(stringToValidRegex(ICX_REQUEST.URL_PRODUCT_GROUP)));
    mutate(ICX_REQUEST.URL_FINANCIAL);
  }

  return {
    onFixedAccountChange,
  };
}
