import { Flex, Card, Text, Notification as TeddyNotification, Icon } from "@telia/teddy";
import { phoneNumbers } from "util/phoneNumbers";

export function Notification() {
  return (
    <TeddyNotification variant="information" shadow>
      <TeddyNotification.Dismiss />
      <TeddyNotification.Heading as="h3">
        <TeddyNotification.Icon name="info">Informasjon</TeddyNotification.Icon>
      </TeddyNotification.Heading>
      <TeddyNotification.Text>
        <Text mb="300">
          Dette abonnementet vidrekobler fastnummeret ditt til mobilnummeret ditt, slik at du mottar alle anrop på
          mobilen din.
        </Text>
        <Text>Dersom du har spørsmål til dette abonnementet, vennligst kontakt kunderservice:</Text>
      </TeddyNotification.Text>

      <TeddyNotification.Footer mt="150">
        <Card variant="white" width="max-content">
          <Flex align="center" gap="200">
            <Icon name="phone" size="md" />
            <Text>{phoneNumbers.customerServiceNorway}</Text>
          </Flex>
        </Card>
      </TeddyNotification.Footer>
    </TeddyNotification>
  );
}
