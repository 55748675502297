import { Accordion, Container, Heading } from "@telia-no-min-side/components";
import { useSearchParams } from "react-router-dom";
import { Box } from "@telia/teddy";
import { data } from "./data";
import { OfferingAccordionItem } from "./OfferingAccordionItem";
import { getMobileOffersForSale } from "pages/mobile/mobile-subscription-change/change-mobile/utils/getMobileOffersForSale";
import { groupMobileOffersForSaleByCategory } from "pages/mobile/mobile-subscription-change/change-mobile/utils/groupMobileOffersForSaleByCategory";
import { OfferGroup } from "pages/mobile/mobile-subscription-change/types";
import { OfferGroupPicker } from "pages/mobile/mobile-subscription-change/components/OfferGroupPicker";
import { NewLinesCard } from "pages/mobile/mobile-subscription-account-overview/components/LinesInfoCard";

export function MobileSubscriptionAvailable() {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const hasFamilyDiscount = urlSearchParams.get("bedrift-familierabatt") === "true";
  const addSubscription = { data };
  const offerType = urlSearchParams.get("selectedOfferType") || "unlimitedData";

  const offersForSale = getMobileOffersForSale(addSubscription.data.account.offerings);
  const {
    juniorOffers,
    bucketOffers,
    unlimitedDataOffers,
    hasJuniorOffers,
    hasBucketOffers,
    hasUnlimitedDataOffers,
    isJuniorSummerCampaignActive,
  } = groupMobileOffersForSaleByCategory(offersForSale);

  const hasLineDiscount = hasFamilyDiscount;

  const offerGroups: { text: string; value: OfferGroup; available: boolean }[] = [
    {
      text: "Ubegrenset data",
      value: "unlimitedData",
      available: hasUnlimitedDataOffers,
    },
    {
      text: "Fast data",
      value: "bucket",
      available: hasBucketOffers,
    },
    {
      text: "Barn",
      value: "junior",
      available: hasJuniorOffers,
    },
  ];
  const availableOfferGroups = offerGroups.filter((group) => group.available);

  return (
    <Container padding showGoBackButton flexDirection="column">
      <Box pb={{ sm: "200", md: "400" }}>
        <Heading tag="h2" variant="title-200-light">
          Kjøp nytt abonnement
        </Heading>
      </Box>
      <OfferGroupPicker
        value={offerType}
        onChange={(v) => {
          setUrlSearchParams((prev) => ({ ...prev, selectedOfferType: v }));
        }}
        options={availableOfferGroups}
      />
      <Container flexDirection="column" gap="lg">
        {offerType === "unlimitedData" && (
          <Accordion gap="lg">
            {unlimitedDataOffers?.map((offering) => (
              <OfferingAccordionItem
                userOffering={offering}
                key={`${offering.shortName}-unlimitedData-new-${offering.weight}`}
                hasOneOrMoreSubs={hasLineDiscount}
              />
            ))}
          </Accordion>
        )}
        {offerType === "bucket" && (
          <Accordion gap="lg">
            {bucketOffers?.map((offering) => (
              <OfferingAccordionItem
                key={`${offering.shortName}-bucket-new-${offering.weight}`}
                userOffering={offering}
                hasOneOrMoreSubs={hasLineDiscount}
              />
            ))}
          </Accordion>
        )}
        {offerType === "junior" && (
          <Accordion gap="lg">
            {juniorOffers?.map((offering) => (
              <OfferingAccordionItem
                key={`${offering.shortName}-junior-new-${offering.weight}`}
                userOffering={offering}
                hasOneOrMoreSubs={hasLineDiscount}
                additionalText="For deg under 16 år"
              />
            ))}
          </Accordion>
        )}
        {hasLineDiscount && !(offerType === "junior" && isJuniorSummerCampaignActive) && <NewLinesCard />}
      </Container>
    </Container>
  );
}
