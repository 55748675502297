// There is a TS complaint without empty import
// https://github.com/microsoft/TypeScript/issues/47663#issuecomment-2028537992
import type {} from "csstype";
import React, {
  BaseSyntheticEvent,
  CSSProperties,
  ComponentProps,
  ComponentPropsWithoutRef,
  ComponentType,
  ReactNode,
  forwardRef,
} from "react";
import { RouteProps } from "react-router-dom";
import { Button } from "../Button";
import { useReactRouterHistory } from "../hooks/useReactRouterHistory";
import { Breakpoints } from "../utils/breakpoints";
import { StyledContainer } from "./style";

// ----- type helper  -------
// The type that styled components accepts as an argument to as prop
type As = keyof JSX.IntrinsicElements | ComponentType | undefined;

// Retrieves all attributes for a specific tag.
type TagProps<Tag extends As> = ComponentProps<Tag extends keyof JSX.IntrinsicElements ? Tag : "div">;

// Collect your own props with attributes and tag the prop.
type PropsWithTag<Tag extends As, Props> = {
  tag?: Tag;
} & TagProps<Tag> &
  Props;

export type ContainerBaseProps = {
  display?: CSSProperties["display"];
  backgroundColor?: CSSProperties["color"];
  maxWidth?: Breakpoints | "full";
  horizontalPosition?: "left" | "center" | "right" | "unset";
  padding?: boolean | "horizontal" | "vertical" | "top" | "bottom" | "left" | "right";
  paddingSize?: "sm" | "md" | "lg";
  flexDirection?: "row" | "column" | "rowReverse" | "columnReverse";
  gap?: boolean | "lg" | "sm";
  flexWrap?: boolean;
  isFocusBox?: boolean;
  isFocusBoxSmall?: boolean;
  className?: string;
  children?: ReactNode;
  showGoBackButton?: boolean;
  goBackTo?: RouteProps["path"];
  floatContent?: "right" | "left" | "center";
  justifyContent?: CSSProperties["justifyContent"];
  flexShrink?: CSSProperties["flexShrink"];
  flexGrow?: CSSProperties["flexGrow"];
  flexBasis?: CSSProperties["flexBasis"];
  width?: "unset";
  alignItems?: CSSProperties["alignItems"];
  onGoBackClick?: (e: BaseSyntheticEvent) => void;
} & ComponentPropsWithoutRef<"div">;

export type ContainerProps<Tag extends As = "div"> = PropsWithTag<Tag, ContainerBaseProps>;

/**
 * @deprecated Use Teddy components instead
 */
export const Container = forwardRef(function <Tag extends As = "div">(
  props: ContainerProps<Tag>,
  ref: React.ForwardedRef<HTMLElement>
) {
  const { goBackTo, onGoBackClick, showGoBackButton, tag, ...rest } = props;
  const history = useReactRouterHistory();

  function onClick(e: BaseSyntheticEvent) {
    if (onGoBackClick) {
      onGoBackClick(e);
    }

    if (e.isDefaultPrevented()) return;
    if (typeof goBackTo === "string") {
      history.push(goBackTo);
      return;
    }
    history.goBack();
  }

  return (
    <>
      {(showGoBackButton || goBackTo) && (
        <StyledContainer padding="horizontal">
          <Button
            variant="text-black"
            onClick={onClick}
            icon="arrow-left"
            iconPosition="before"
            data-tracking-id="container-go-back-button"
          >
            Tilbake
          </Button>
        </StyledContainer>
      )}
      <StyledContainer as={tag} ref={ref} {...rest} />
    </>
  );
});

Container.displayName = "Container";
