import { Button } from "@telia/styleguide";
import classNames from "classnames";
import React from "react";

import "./StaticInternalIPDisplay.less";

const Container = ({ children }) => <div className="static-internal-ip-display-container"> {children}</div>;

const ButtonContainer = ({ children }) => <div className="static-internal-ip-display-button-container">{children}</div>;

const Row = ({ children, index }) => (
  <div key={index} className="static-internal-ip-display-row">
    {children}
  </div>
);

const TopPartContainer = ({ children }) => (
  <div className="static-internal-ip-display-top-part-container">{children}</div>
);

const BottomPartContainer = ({ children }) => <div className="static-internal-ip-display-container">{children}</div>;

const TitleText = ({ children }) => <p className="static-internal-ip-display-title-text">{children}</p>;

const ValueText = ({ children }) => <p className="static-internal-ip-display-value-text">{children}</p>;

export const StaticInternalIPDisplay = ({ name, ipAddress, macAddress, onChangeClick, onDeleteClick }) => {
  return (
    <Container>
      <TopPartContainer>
        <Row index={0}>
          <div className={classNames("font-size-24", "margin-bottom")}>{name}</div>
        </Row>
        <Row index={1}>
          <TitleText>IP-adresse</TitleText>
          <ValueText>{Object.values(ipAddress).join(".")}</ValueText>
        </Row>
        <Row index={2}>
          <TitleText>Mac-adresse</TitleText>
          <ValueText>{macAddress}</ValueText>
        </Row>
      </TopPartContainer>
      <BottomPartContainer>
        <ButtonContainer>
          <Button onClick={onChangeClick} text={"Endre"} size={"small"} />
          <Button onClick={onDeleteClick} kind={"cancel"} text={"Slett IP"} size={"small"} />
        </ButtonContainer>
      </BottomPartContainer>
    </Container>
  );
};
