import { BaseSyntheticEvent } from "react";
import { Box, Button, Text } from "@telia/teddy";
import { ShoppingCard } from "components/shopping-card";
import { types } from "@telia-no-min-side/utils";
import { getSimplePriceText } from "util/priceFormatter";
import { DeviceSpecification } from "./DeviceSpecification";

type Props = {
  product: types.fixed.ProductGroupSingleProductWithShipping;
  isSelected: boolean;
  onSelectClick?: (e: BaseSyntheticEvent) => void;
  shouldDisableSelect?: boolean;
};

export function TeliaWifiRouter({ product, isSelected, onSelectClick, shouldDisableSelect }: Props) {
  const { currentPrice, postCampaignPrice, campaignDuration } = product.price;
  const isCampaign = postCampaignPrice.recurring > currentPrice.recurring;

  return (
    <ShoppingCard
      title={product.name || "Wifi Router"}
      additionalTagText={
        <Text>
          En router som gir gode opplevelser. Routeren leverer to ulike nett, som vil dekke både nye og gamle enheter.
        </Text>
      }
      image={product.image}
      isSelected={isSelected}
      price={getSimplePriceText(currentPrice.recurring, currentPrice.chargePeriod) || "Finner ikke pris."}
      postCampaignPrice={isCampaign ? getSimplePriceText(postCampaignPrice.recurring) : undefined}
      campaignText={campaignDuration}
    >
      <Box mt="200" mb="100">
        <DeviceSpecification
          specifications={[
            "Wifi 5 standard",
            "WPA 2 sikkerhetsstandard",
            "Støtter lynraske hastigheter med kabel og høye hastigheter over wifi",
          ]}
          gaTrackingData={{
            onOpenTracking: {
              ui_item_action: "dropdown-click",
              ui_item_context: "buy-telia-wifi",
              ui_item_text: "Se spesifikasjoner",
              ui_item_type: "dropdown",
              ui_item_url: "no_url",
            },
          }}
        />
      </Box>
      {onSelectClick && (
        <ShoppingCard.Footer>
          <Box mt="200">
            <Button
              disabled={shouldDisableSelect}
              variant={isSelected ? "primary" : "secondary"}
              size="sm"
              onClick={onSelectClick}
            >
              {isSelected ? "Valgt" : "Velg"}
            </Button>
          </Box>
        </ShoppingCard.Footer>
      )}
    </ShoppingCard>
  );
}
