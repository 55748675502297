import { Icon } from "@telia/teddy";
import { useAuth } from "@telia-no-min-side/components";
import { useFetchTvSubscription } from "src/api/fixed";
import { AUTH } from "util/constants/auth";
import { SubscriptionInfo } from "./dashboard/SubscriptionInfo";
import { QuickLinks } from "./dashboard/QuickLinks";
import { PossessedEquipment } from "./dashboard/PossesedEquipment";
import { BuyEquipment } from "./dashboard/BuyEquipment";
import { AdditionalLinks } from "./dashboard/AdditionalLinks";
import { NotATvCustomer } from "./dashboard/NotATvCustomer/NotATvCustomer";
import { GetAccessFixedCard } from "components/get-access-fixed-card";
import { useProfilePicker } from "components/profile-pickers/hooks/useProfilePicker";
import { DashboardLayout } from "./common/DashboardLayout";
import { config } from "util/config";
import { ServiceNotification } from "components/alerts/ServiceNotification";
import { ErrorBox } from "components/ErrorBox";
import { ManageLinks } from "./dashboard/ManageLinks";
import { TvPoints } from "./dashboard/TvPoints";

export function TV() {
  const { selectedAuthProvider } = useAuth();
  const { newFixed: fixed } = useProfilePicker();
  const tvSubscription = useFetchTvSubscription();
  const notAFixedCustomer = !fixed.loading && fixed.profiles.length === 0;
  const hasTvSubscription = tvSubscription.data?.products && tvSubscription.data.products.length > 0;

  if (selectedAuthProvider === AUTH.PROVIDER.CT) {
    return (
      <DashboardLayout>
        <GetAccessFixedCard />
      </DashboardLayout>
    );
  }

  if (notAFixedCustomer) {
    return (
      <DashboardLayout>
        <NotATvCustomer />
      </DashboardLayout>
    );
  }

  if (tvSubscription.isError) {
    return (
      <DashboardLayout>
        <ErrorBox
          title="Vi beklager"
          maxWidth="600px"
          description="Dessverre oppstod det en feil under innlastningen av denne siden. Vennligst prøv igjen litt senere."
          actionContent={
            <>
              <Icon name="sync" /> Last side på nytt
            </>
          }
          onClick={() => tvSubscription.mutate()}
        />
      </DashboardLayout>
    );
  }

  if (!hasTvSubscription && !tvSubscription.isLoading) {
    return (
      <DashboardLayout>
        <NotATvCustomer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      {config.showInternalServiceWarning_TV && (
        <ServiceNotification
          type="SERVICE_WARNING"
          title="Tekniske problemer"
          message="Grunnet tekniske problemer er aktivering og deaktivering av Max/Netflix/Prime ikke mulig i en periode. Beklager ulempene dette medfører."
        />
      )}
      <SubscriptionInfo />
      <QuickLinks />
      <TvPoints />
      <PossessedEquipment />
      <ManageLinks />
      <BuyEquipment />
      <AdditionalLinks />
    </DashboardLayout>
  );
}
