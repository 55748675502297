import dayjs from "dayjs";

export const formatDateForSuspendedMessage = (date: string): string => dayjs(date).format("DD.MM.YY");

export const suspendedText = {
  subscriptionSuspended: "Dette abonnementet er sperret",
  reopenSubscription: "Gjenåpne abonnementet",
  abonnement: "Abonnementet ",
  blocked: "ble sperret ",
  nonPayment: "på grunn av manglende betaling. Abonnementet vil bli åpnet igjen når faktura er betalt.",
  lost: "på grunn av at telefonen er mistet. Du kan selv gjenåpne abonnementet når du ønsker.",
  paidAndReopen: "Jeg har betalt fakturaen og vil gjenåpne",
  supportPhoneNumberFormated: "+47 924 05 050",
  supportPhoneNumber: "+4792405050",
};
