import { Flex, Heading, Grid } from "@telia/teddy";
import { NavigationCard, Props as NavigationCardProps } from "components/NavigationCard";
import { useMobileAccount } from "hooks/useMobileAccount";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { INTERNAL_LINK } from "util/constants/internalLinks";

export function ExploreSection() {
  const { accountId } = useMobileAccount();

  const EXPLORE_SECTION_DATA = [
    {
      link: `${INTERNAL_LINK.MOBILE.ADD_SUBSCRIPTION_PAGE}/${accountId}`,
      icon: "cart",
      title: "Kjøp nytt abonnement",
      description: "Kjøp nytt abonnement fra kun 99,-",
    },
    {
      link: EXTERNAL_LINK.MOBILE_HELP,
      icon: "question",
      title: "Hjelpesider mobil",
      description: "Sjekk ut våre guider hvis du har problemer med internett",
      cardVariant: "purple-light",
    },
  ] satisfies NavigationCardProps[];

  return (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100">
        Utforsk
      </Heading>

      <Grid columns={{ sm: "1", md: "2" }} gap="gutter-xl">
        {EXPLORE_SECTION_DATA.map((card) => (
          <NavigationCard
            key={card.title}
            link={card.link}
            icon={card.icon}
            title={card.title}
            description={card.description}
            cardVariant={card.cardVariant}
          />
        ))}
      </Grid>
    </Flex>
  );
}
