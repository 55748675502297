import { SetURLSearchParams } from "react-router-dom";
import { OrderAdditionalSimCardSummary } from "./OrderAdditionalSimCardSummary";
import { AdditionalSimType, AdditionalProduct } from "src/gql/graphql";
import { Container, Heading } from "@telia-no-min-side/components";
import { SimDescriptionField } from "./SimDescriptionField";
import { useSimCheckout, SimCheckoutView } from "pages/mobile/mobile-subscription-sim-pin-puk/hooks/useSimCheckout";
import { PARAMS } from "..";
import { SimCardTypeOption } from "pages/mobile/mobile-subscription-order-blank-sim-card/components/SimCardTypeOption";
import { ChangeAddressForm } from "pages/mobile/mobile-subscription-order-blank-sim-card/components/ChangeAdressForm";

type Props = {
  isTwinSim: boolean | undefined;
  productToOrder: AdditionalProduct | undefined;
  simCardType: AdditionalSimType | undefined;
  setSearchParams: SetURLSearchParams;
};

export function OrderAdditionalSimCardStep2({ isTwinSim, productToOrder, simCardType, setSearchParams }: Props) {
  const { checkoutView } = useSimCheckout();

  const isFirstMonthFree =
    productToOrder?.price?.price === 0 && productToOrder.price.price !== productToOrder.price.priceWithoutDiscount;

  if (checkoutView === SimCheckoutView.ChangeAddress) {
    return <ChangeAddressForm />;
  }

  const handleSimCardTypeSelection = (type: AdditionalSimType) => {
    setSearchParams((prev) => {
      const searchParams = new URLSearchParams(prev);
      searchParams.set(
        PARAMS.CARD_TYPE.key,
        type === AdditionalSimType.EsimQr ? PARAMS.CARD_TYPE.esim : PARAMS.CARD_TYPE.hwSimRemote
      );
      return searchParams;
    });
  };

  return (
    <Container maxWidth="lg" gap="lg" flexDirection="column">
      <Heading tag="h2" variant="title-100" data-tracking-id="order-sim-card-type-header">
        Velg hvilken type SIM-kort du vil ha
      </Heading>
      <Container gap="lg" flexWrap flexDirection="row" alignItems="flex-start">
        <div>
          <Container maxWidth="xs" gap="lg" flexDirection="column">
            <SimCardTypeOption
              title="Fysisk SIM-kort"
              buttonText="fysisk SIM-kort"
              icon="sim-card"
              paragraphs={["Dette er standard, fysisk SIM-kort, som passer i de fleste enheter."]}
              isSelected={simCardType === AdditionalSimType.HwSimRemote}
              handleSelect={() => {
                handleSimCardTypeSelection(AdditionalSimType.HwSimRemote);
              }}
              isFirstMonthFree={isFirstMonthFree}
            />

            <SimCardTypeOption
              title="eSIM"
              icon="esim"
              paragraphs={[
                "eSIM er et digitalt SIM-kort. Dermed kan det aktiveres umiddelbart etter bestilling, for utstyr som støtter eSIM.",
                "Pass på at din enhet støtter eSIM før du velger dette.",
              ]}
              isSelected={simCardType === AdditionalSimType.EsimQr}
              handleSelect={() => {
                handleSimCardTypeSelection(AdditionalSimType.EsimQr);
              }}
              isFirstMonthFree={isFirstMonthFree}
            />

            {!!simCardType && productToOrder && <SimDescriptionField />}
          </Container>
        </div>
        {!!simCardType && productToOrder && (
          <OrderAdditionalSimCardSummary
            isFirstMonthFree={isFirstMonthFree}
            productToOrder={productToOrder}
            isTwinSim={isTwinSim}
            simCardType={simCardType}
          />
        )}
      </Container>
    </Container>
  );
}
