import { graphql } from "src/gql";

export const SUBSCRIPTION_CHANGE_QUERY = graphql(`
  query ChangeSubscription($accountId: String!, $phoneNumber: String!) {
    account(accountId: $accountId) {
      id
      subscriptions {
        phoneNumber {
          localNumber
        }
        userOffering {
          pricePlan
          originalPrice {
            amount
          }
          additionalProperties {
            name
            value
          }
        }
      }
      subscription(phoneNumber: $phoneNumber) {
        ...CurrentSubscription
        roles
        phoneNumber {
          localNumber
        }
        productUser {
          firstName
          birthDate
        }
        legalOwner {
          individual {
            firstName
            surname
          }
          organization {
            name
          }
        }
        additionalProducts {
          id
          code
          productGroup
          productCategory
          name
          productTerms {
            code
            amount
          }
        }
        commitmentEndDate
        userOffering {
          pricePlan
          originalPrice {
            amount
          }
        }
        newOfferingDetails {
          offerings {
            shortName
            weight
            originalPrice {
              amount
            }
            price {
              offeringPrices {
                code
                monetaryUnit
              }
            }
            commitmentTimeInMonths
            additionalProperties {
              name
              value
            }
            pricePlan
            ...OfferForSale
          }
        }
      }
    }
  }
`);
