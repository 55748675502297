import { Text, Card, Button, Modal } from "@telia/teddy";
import { useParams } from "react-router-dom";
import { useCompensation } from "./useCompensation";
import { TrackClickEvent } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { v4 as uuidv4 } from "uuid";

export function CacheCompensationCard() {
  const { customerId } = useParams();
  const { switchCompensation } = useCompensation();

  return (
    <Card variant="white" bordered layout="default">
      <Card.Heading>Jeg vil heller ha avslag på 109,-/md</Card.Heading>
      <Card.Description>
        Dersom du ikke trenger 50 TV-poeng ekstra, tilbyr vi et avslag på 109,-/md. som trekkes fra din faktura.
      </Card.Description>
      <Card.Content>
        <Text variant="additional-100">
          Dette avslaget gjelder frem til TV 2 Play er tilgjengelig igjen på Telia Play.
        </Text>
      </Card.Content>
      <Card.Footer>
        <Modal>
          <TrackClickEvent
            asChild
            event={{
              ui_item_action: "click",
              ui_item_type: "button",
              ui_item_text: "Velg",
              ui_item_url: uri.minSideFixed("/tv/kompensasjon"),
              ui_item_context: "tv-compensation",
            }}
          >
            <Modal.Trigger>
              <Button variant="secondary">Velg</Button>
            </Modal.Trigger>
          </TrackClickEvent>
          <Modal.Content>
            <Modal.Title>Er du sikker?</Modal.Title>
            <Modal.Close slot="floating" aria-label="Lukk" />
            <Modal.Description>
              Du velger nå avslag på 109,-/md. istedenfor 50 ekstra TV-poeng. Vær oppmerksom på at beslutningen du tar
              ikke kan endres i etterkant.
            </Modal.Description>
            <Modal.Group variant="action">
              <Modal.Close slot="content">
                <Button variant="secondary">Avbryt</Button>
              </Modal.Close>
              <TrackClickEvent
                asChild
                event={{
                  ui_item_action: "click",
                  ui_item_type: "button",
                  ui_item_text: "Jeg er sikker",
                  ui_item_url: uri.minSideFixed("/tv/kompensasjon"),
                  ui_item_context: "tv-compensation",
                }}
              >
                <Button
                  variant="primary"
                  onClick={() =>
                    switchCompensation?.post(
                      { customerId },
                      {
                        products: [
                          {
                            id: uuidv4(),
                            price: -109,
                            quantity: 1,
                            category: "Subscription/TV/Compensation",
                            brand: "Telia",
                            name: "Jeg vil heller ha avslag på 109,-/md",
                          },
                        ],
                        transactionId: uuidv4(),
                        revenue: -109,
                        shippingPrice: 0,
                      }
                    )
                  }
                  loading={switchCompensation?.isLoading}
                >
                  Jeg er sikker
                </Button>
              </TrackClickEvent>
            </Modal.Group>
          </Modal.Content>
        </Modal>
      </Card.Footer>
    </Card>
  );
}
