import { colors } from "@telia/styleguide";
import styled from "styled-components";

export const EFakturaText = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1.25rem;
  margin-top: 1rem;
`;

export const EInvoiceUndertext = styled.p`
  font-size: 0.75rem;
  margin: 0 0 1rem 0.2rem;
  color: ${colors.grey600};
`;
