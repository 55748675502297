type DeviceSizes = {
  desktop: number;
  tablet: number;
  mobile: number;
};

type Sizes = {
  topMenu: DeviceSizes;
  sideMenu: DeviceSizes;
  content: {
    width: number;
  };
};

export const sizes: Sizes = {
  topMenu: {
    desktop: 60,
    tablet: 60,
    mobile: 50,
  },
  sideMenu: {
    desktop: 240,
    tablet: 64,
    mobile: 0,
  },
  content: {
    width: 570,
  },
};

export const zIndex = {
  toolTip: 4,
  sideMenu: 6,
  topMenu: 6,
  footer: 6,
  sideMenuMobile: 6,
  backDrop: 8,
  sideMenuBackDrop: 6,
  modal: 9,
};
