import { Accordion, Heading, Link, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function AvtalegiroAccordion() {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Hvordan oppretter jeg Avtalegiro / E-faktura?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          AvtaleGiro med elektronisk varsling og eFaktura er den enkleste og billigste måten å betale fakturaen din på.
          Med dette slipper du å betale fakturagebyret (6,47 kr pr. md.). Ønsker du å opprette avtalegiro eller eFaktura
          kan du enkelt gjøre dette selv i din nettbank, eller få hjelp ved å kontakte banken.
        </Paragraph>
        <ul>
          <li>
            Med AvtaleGiro trenger du kun å passe på at du har dekning på belastningskontoen og at maksbeløpet på
            regningen ikke overstiges.
          </li>
          <li>
            Med eFaktura får du regningene rett i nettbanken i stedet for i postkassen, ferdig utfylt med KID-nummer, og
            alt du trenger å gjøre er å godkjenne.
          </li>
        </ul>
        <Paragraph>
          <b>Husk på dette:</b>
        </Paragraph>
        <ul>
          <li>
            Har du Avtalegiro med papirvarsel i dag, anbefaler vi å opprette eFaktura/AvtaleGiro uten papirvarsel for å
            slippe gebyr.
          </li>
          <li>KID-nummeret på fakturaen din er referansen for å opprette AvtaleGiro.</li>
          <li>
            Det kan ta opptil 45 dager før AvtaleGiroen er aktiv. Du må derfor betale denne regningen på vanlig måte,
            men fra neste regning vil du få AvtaleGiro eller E-Faktura.
          </li>
          <li>All opprettelse, avslutting og endringer i avtalen, som varsel og maksbeløp, må gjøres via banken.</li>
        </ul>
        <Heading variant="subsection-100" tag="h3">
          eFaktura 2.0 – endringer 1. desember 2021 og 15. mai 2022
        </Heading>
        <Paragraph>
          Fra 15. mai 2022 må du som eFakturakunde gi en generell aksept for eFaktura for å fortsette å være
          eFaktura-bruker. Dette omtales som
          <q>Ja takk til alle</q> eller <q>Alltid eFaktura</q>, avhengig av hvilken bank eller betalingsapp du har.
        </Paragraph>
        <Paragraph>
          For å fortsatt kunne motta regninger og faktura som eFaktura, er det viktig at du aksepterer{" "}
          <q>Ja takk til alle</q> i nettbanken din. Hvis du ikke takker ja til dette innen fristen, vil du ikke lenger
          motta eFaktura etter 15. mai 2022.
        </Paragraph>

        <Paragraph>
          Merk at allerede fra 1. desember 2021 må du si <q>Ja takk til alle</q>
          for å kunne motta regninger fra bedrifter som du tidligere ikke har mottatt eFaktura fra. Vi oppfordrer derfor
          alle eFakturakunder til å inngå «Ja takk til alle»-avtale snarest mulig.
        </Paragraph>
        <Heading variant="subsection-100" tag="h3">
          Hvorfor endrer man til eFaktura 2.0?
        </Heading>
        <Paragraph>
          Dette gjøres for at det skal bli enklere for deg å betale regningene dine. Med <q>Ja takk til alle</q> vil du
          motta eFaktura automatisk fra bedrifter som tilbyr eFaktura. Så du slipper dermed å gå inn i nettbanken for å
          opprette eFaktura-avtale selv.
        </Paragraph>
        <Paragraph>Mer informasjon om AvtaleGiro og eFaktura:</Paragraph>
        <ul>
          <li>
            <Link href={EXTERNAL_LINK.AVTALEGIRO_PRIVATE} target="_blank">
              AvtaleGiro.no
            </Link>
          </li>
          <li>
            <Link href={EXTERNAL_LINK.E_FAKTURA_PRIVATE} target="_blank">
              eFaktura.no
            </Link>
          </li>
        </ul>
      </Accordion.Content>
    </Accordion.Item>
  );
}
