type StrictSimCardOption = {
  name: string;
  value: string;
};

export function isSimCardOption(obj: unknown): obj is StrictSimCardOption {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "name" in obj &&
    !!obj.name &&
    "value" in obj &&
    !!obj.value &&
    typeof obj.name === "string" &&
    typeof obj.value === "string"
  );
}
