import { isAxiosError, AxiosError } from "axios";
import { datadogRum } from "@datadog/browser-rum";
import { enhanceErrorData } from "./datadog";

type Options = {
  url: string;
};

export function isUnauthorizedResponse(error: unknown) {
  if (!isAxiosError(error)) return false;
  return error?.response?.status === 401;
}

export function normalizeResponseData(htmlMarkup?: string) {
  if (!htmlMarkup) return htmlMarkup;

  const imgRegex = /<img(.*?)>/g;
  const styleRegex = /<style(.*?)>(.*?)<\/style>/g;

  const withoutImg = htmlMarkup.replace(imgRegex, "");
  const withoutStyle = withoutImg.replace(styleRegex, "");

  return withoutStyle;
}

export const getAxiosErrorContext = (error?: unknown, options?: Options) => {
  if (!error) return undefined;
  if (!isAxiosError(error)) return undefined;

  return {
    url: options?.url || error?.response?.config?.url,
    code: error?.code,
    originalMessage: error.message,
    data: typeof error?.response?.data === "string" ? normalizeResponseData(error?.response?.data) : undefined,
    status: error?.response?.status,
    statusText: error?.response?.statusText,
  };
};

export const getAxiosErrorMessage = (error: AxiosError) => {
  const method = error?.response?.config?.method?.toUpperCase();
  const url = error?.response?.config?.url;
  const status = error?.response?.status;
  const statusText = error?.response?.statusText;
  const data = typeof error?.response?.data === "string" ? normalizeResponseData(error?.response?.data) : undefined;
  const isNotEmptyData = [method, url, status, statusText, data].some(Boolean);
  const message = isNotEmptyData ? [method, url, "|", status, statusText, "|", data].join(" ") : error.message;
  return message;
};

export function logFixedApiError(error: unknown, options: Options) {
  if (location.hostname === "localhost.telia.no") return;
  if (isAxiosError(error)) {
    const customErrorMessage = getAxiosErrorMessage(error);
    const errorContext = getAxiosErrorContext(error, options);

    const ddError = enhanceErrorData(error, {
      customMessage: customErrorMessage,
    });

    datadogRum.addError(ddError, errorContext);
    return;
  }

  datadogRum.addError(error);
}
