import { Modal, Button } from "@telia/teddy";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  heading: string;
  description: string;
  leftButtonText?: string;
  leftButtonClick?: () => void;
  rightButtonText: string;
  rightButtonClick: () => void;
  isLoading?: boolean;
};

export function TopupModalView({
  isOpen,
  closeModal,
  heading,
  description,
  leftButtonText,
  leftButtonClick,
  rightButtonText,
  rightButtonClick,
  isLoading,
}: Props) {
  return (
    <Modal open={isOpen}>
      <Modal.Overlay style={{ zIndex: 100 }}>
        <Modal.Content>
          <Modal.Group variant="title">
            <Modal.Title as="h3">{heading}</Modal.Title>
          </Modal.Group>
          <Modal.Close slot="floating" aria-label="lukk" onClick={closeModal} />
          <Modal.Description minWidth="400px">{description}</Modal.Description>
          <Modal.Group variant="action">
            {leftButtonText && leftButtonClick && (
              <Modal.Close slot="content">
                <Button variant="secondary" onClick={leftButtonClick}>
                  {leftButtonText}
                </Button>
              </Modal.Close>
            )}
            <Button variant="primary" onClick={rightButtonClick} loading={isLoading}>
              {rightButtonText}
            </Button>
          </Modal.Group>
        </Modal.Content>
      </Modal.Overlay>
    </Modal>
  );
}
