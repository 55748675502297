import { Maybe } from "gql/graphql";
import { LINE_DISCOUNT_GROUP } from "util/format-subscription/variables";
import { LineSubscription } from "../../types";

// Subscriptions can belong to discount groups.
// This function filters out subscriptions that belong to a group MHS_B2C.
// You can get discounts on subscriptions for the same discount group.
export function getLineSubscriptions<T extends Maybe<LineSubscription>>(
  subscriptions: Maybe<Maybe<T>[]> | undefined
): Maybe<T>[] {
  return (subscriptions || []).filter(
    (subscription) =>
      !!subscription?.userOffering?.additionalProperties?.find(
        (additionalProperty) => additionalProperty?.value === LINE_DISCOUNT_GROUP
      )
  );
}
