import { remCalc } from "@telia-no-min-side/components";
import styled from "styled-components";

export const NameList = styled.ul`
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0 0 0 ${remCalc(5)};
  overflow-x: hidden;
  li {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
