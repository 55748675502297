import { Container, Paragraph } from "@telia/styleguide";
import { ApolloError } from "@apollo/client";
import { UnbilledServiceQuery, InvoicedServiceQuery } from "gql/graphql";
import { ErrorMessage, PageWrapper } from ".";
import UsageDetailedTable from "./DetailedUsageTable";
import { SkeletonForm } from "pages/profile/common/SkeletonForm";

type IInvoiceDetailedUsageProps = {
  loading: boolean;
  error?: ApolloError;
  data?: UnbilledServiceQuery | InvoicedServiceQuery;
  serviceCodes: string[];
};

function isUnbilledServiceQuery(data: UnbilledServiceQuery | InvoicedServiceQuery): data is UnbilledServiceQuery {
  return !!data && "unbilledService" in data.subscription;
}

export default function InvoiceDetailedUsage({ loading, error, data, serviceCodes = [] }: IInvoiceDetailedUsageProps) {
  if (loading) {
    return <SkeletonForm />;
  }

  if (error) {
    return (
      <ErrorMessage errorText="En uventet feil oppstod.">
        Feilen er registrert og vi prøver å løse den så fort vi kan. Vi beklager de ulemper dette måtte medføre.
      </ErrorMessage>
    );
  }

  function getServiceData(data: UnbilledServiceQuery | InvoicedServiceQuery) {
    if (data && isUnbilledServiceQuery(data)) {
      return data.subscription.unbilledService;
    } else return data.subscription.invoicedService;
  }
  const serviceData = data && getServiceData(data);

  if (!serviceData) {
    if (serviceCodes.length === 1 && serviceCodes[0] === "GPRVOL") {
      return (
        <ErrorMessage errorText="Vi kan ikke vise denne dataen.">
          <Container size="small" extraMarginBottom>
            <Paragraph>
              Vi kan dessverre ikke vise data for tjenesten "Data i Norge" på Min Side. Det er rett og slett for mye
              informasjon til at vi kan vise deg denne dataen.
            </Paragraph>
          </Container>
        </ErrorMessage>
      );
    }
    return (
      <ErrorMessage errorText="Vi fant ikke noe data.">
        Den vanligste årsaken til at vi ikke kan vise deg denne informasjonen er på grunn av GDPR lover som tilsier at
        vi ikke kan lagre data lengre enn en viss periode.
      </ErrorMessage>
    );
  }

  return (
    <PageWrapper>
      <UsageDetailedTable serviceData={serviceData} />
    </PageWrapper>
  );
}
