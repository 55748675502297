import { useQuery } from "@apollo/client";
import { GET_ORDER_STATE } from "./graphql/getOrderState";
import { GET_SUBSCRIPTION_SETTINGS } from "./graphql/getSubscriptionSettings";
import { SubscriptionSettings } from "./components/SubscriptionSettings";
import { useCallForwarding } from "./hooks/useCallForwarding";
import { useLocalStorage } from "@telia-no-min-side/components";
import { SkeletonForm } from "./components/Forms/SkeletonForm";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { Flex, Text, Heading, breakpoint, Button, Icon } from "@telia/teddy";
import { Breadcrumb } from "components/Breadcrumb";
import { CustomerCareLink } from "components/customer-care-link";
import { Link } from "react-router-dom";
import { uri } from "@telia-no-min-side/utils";

export function MobileSubscriptionSettings() {
  const { phoneNumber } = useMobileAccount();

  const { value: orderId, setValue: setOrderId } = useLocalStorage<string | null>(
    "telia-no-minside-order-id__settings"
  );
  const numericalOderId = parseInt(orderId!);

  const settingsQuery = useQuery(GET_SUBSCRIPTION_SETTINGS, {
    variables: { phoneNumber },
    fetchPolicy: "network-only",
  });
  const orderStateQuery = useQuery(GET_ORDER_STATE, {
    skip: !numericalOderId,
    variables: {
      phoneNumber,
      orderId: numericalOderId,
    },
    onCompleted: (data) => {
      data?.subscription?.order?.orderState === "COMPLETED" && setOrderId(null);
    },
  });

  const { data: callForwardingData } = useCallForwarding(phoneNumber);

  if (!settingsQuery.loading && !settingsQuery.data) {
    return (
      <Flex maxWidth={breakpoint.teddyBreakpointMd} direction="column" gap="200">
        <Breadcrumb />
        <Heading as="h2" variant="title-300" mb="200">
          Innstillinger
        </Heading>
        <Text variant="paragraph-100">
          En uventet feil har oppstått. Vennligst prøv igjen senere, eller ring kundeservice:
        </Text>
        <CustomerCareLink trackingContext="mobile-subscription-settings" />
        <Button asChild mr="auto">
          <Link to={uri.minSideFixed("/hjem")}>
            <Icon name="arrow-left">Tilbake til Hjem</Icon>
          </Link>
        </Button>
      </Flex>
    );
  }
  if (settingsQuery.loading || !settingsQuery.data) {
    return (
      <Flex maxWidth={breakpoint.teddyBreakpointMd} direction="column" gap="200">
        <Breadcrumb />
        <Heading as="h2" variant="title-300" mb="200">
          Innstillinger
        </Heading>
        <SkeletonForm />
      </Flex>
    );
  }

  return (
    <SubscriptionSettings
      settings={settingsQuery.data}
      callForwardingData={callForwardingData}
      orderId={orderId}
      orderStatus={orderStateQuery?.data?.subscription?.order?.orderState || undefined}
    />
  );
}
