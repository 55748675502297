import { useQuery } from "@apollo/client";
import { uri } from "@telia-no-min-side/utils";
import { Container, Skeleton } from "@telia-no-min-side/components";
import { Heading, Text, Box, Card, breakpoint } from "@telia/teddy";
import { Navigate, useParams } from "react-router-dom";
import { TeliaSky } from "./components/services-telia-sky/TeliaSky";
import { graphql } from "gql";
import { ProfilePicker } from "./ProfilePicker";
import { TeliaTrygg } from "./components/services-telia-trygg/TeliaTrygg";
import { VASSummary } from "./components/VASSummary";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { MOBILE_VAS } from "util/constants/valueAddedServices";

export const VasDetailsQuery = graphql(`
  query vasDetails($productGroups: [String!], $phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      additionalProducts(productGroups: $productGroups) {
        id
        productGroup
        code
        shortName
        shortDescription
        weight
        descriptionLink
        presentation {
          active
          included
        }
        price {
          discountDuration
          price
          priceWithoutDiscount
        }
      }
    }
  }
`);

export function VasDetails() {
  const params = useParams<{ productGroup: string; selectedUserId: string }>();
  const productGroup = params.productGroup;
  const id = params.selectedUserId;
  const { orders } = useOrderSubscription();

  const products = useQuery(VasDetailsQuery, {
    variables: {
      productGroups: productGroup ? [productGroup] : [],
      phoneNumber: id || "",
    },
  });

  const selectedProduct = products.data?.subscription.additionalProducts?.find(
    (product) => product?.productGroup === productGroup
  );

  if (!products.loading && products.data && !selectedProduct) {
    return <Navigate replace to={uri.minSideFixed("/tjenester/")} />;
  }

  const inOrder = orders.some((order) => order.phoneNumber === id && order.vasProduct === selectedProduct?.code);
  return (
    <Container padding showGoBackButton gap flexDirection="column">
      <Heading as="h1" variant="title-300" pb={"100"}>
        {products.loading && !products.data ? (
          <Box maxWidth="10ch">
            <Skeleton variant="heading-m" />
          </Box>
        ) : (
          `${
            inOrder ? "" : selectedProduct?.presentation?.active ? "Administrer " : "Bestill "
          } ${selectedProduct?.shortName}`
        )}
      </Heading>
      <Text>
        {products.loading && !products.data ? (
          <Box maxWidth="10ch" as="span">
            <Skeleton variant="text" />
          </Box>
        ) : selectedProduct?.presentation?.active ? (
          `Tjenesten ${inOrder ? "kanselleres" : "er aktiv"} for:`
        ) : (
          `Tjenesten ${inOrder ? "aktiveres for" : "knyttes til"}: `
        )}
      </Text>
      <ProfilePicker phoneNumberToFilter={id} />
      <Box pt="200">
        {products.loading && !products.data ? (
          <Card variant="white" shadow maxWidth={breakpoint.teddyBreakpointMd} justify="stretch" bordered>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Card>
        ) : (
          <>
            {productGroup === MOBILE_VAS.SKY && <TeliaSky isLegalOwner product={selectedProduct} />}
            {selectedProduct && productGroup === MOBILE_VAS.TRYGG && (
              <TeliaTrygg phoneNumber={id || ""} product={selectedProduct} />
            )}
            {selectedProduct && productGroup === MOBILE_VAS.HBO && (
              <VASSummary product={selectedProduct} type={MOBILE_VAS.HBO} phoneNumber={id || ""} />
            )}
            {selectedProduct && productGroup === MOBILE_VAS.STORYTEL && (
              <VASSummary product={selectedProduct} type={MOBILE_VAS.STORYTEL} phoneNumber={id || ""} />
            )}
          </>
        )}
      </Box>
    </Container>
  );
}
