import dayjs from "dayjs";
import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { NewOfferingDetails, SubscriptionData } from "src/gql/graphql";
import { format } from "@telia-no-min-side/utils";
import { Card, Icon, NoShrinkParagraph } from "../styles/OfferingContent.styled";
import { getOldAndNewPrices } from "../utils/getOldAndNewPrice";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type OfferingContentProps = {
  phoneNumber: string;
  productUser: SubscriptionData["productUser"];
  userOfferingWithDiscount: NewOfferingDetails["offering"];
  orderLoading: boolean;
  orderOffering: () => void;
};

export const OfferingContent = ({
  userOfferingWithDiscount,
  productUser,
  phoneNumber,
  orderLoading,
  orderOffering,
}: OfferingContentProps) => {
  const { oldPrice, newPrice } = getOldAndNewPrices(userOfferingWithDiscount);

  const commitmentEndDate = dayjs().add(12, "M").subtract(1, "d").format("DD.MM.YY");

  return (
    <Container showGoBackButton padding="horizontal" goBackTo="/minside/hjem">
      <Heading tag="h1" variant="title-300">
        Vil du legge til årsrabatt på abonnementet?
      </Heading>

      <Container flexDirection="column" gap padding="bottom" maxWidth="sm">
        <Paragraph variant="paragraph-200">Med årsrabatt får du 10% rabatt ved 12 md. avtaletid.</Paragraph>

        <Card backgroundColor="light-purple">
          <Container flexDirection="column" gap>
            <Container flexDirection="row" floatContent="center">
              <Icon size="sm" icon="mobile" />

              <Paragraph removeMargin data-di-mask>
                {format.startCase(productUser?.firstName || "")} {format.startCase(productUser?.surname || "")} (
                {format.formatPhoneNumber(phoneNumber)})
              </Paragraph>
            </Container>

            <Container flexDirection="row">
              <Container flexDirection="row" gap>
                <Container flexDirection="row">
                  <Container flexDirection="row" floatContent="center">
                    <Icon size="sm" icon="savings" />
                  </Container>

                  <NoShrinkParagraph>Rabattert månedspris:</NoShrinkParagraph>
                </Container>
                <Container flexDirection="row" justifyContent="end" gap data-di-mask>
                  <Paragraph isFaded removeMargin textDecorationLine="line-through">
                    <strong>{oldPrice},-</strong>
                  </Paragraph>

                  <Paragraph>
                    <strong>{newPrice || oldPrice},-</strong>
                  </Paragraph>
                </Container>
              </Container>
            </Container>

            <Container flexDirection="row" floatContent="center">
              <Icon size="sm" icon="lock" />

              <Paragraph removeMargin data-di-mask>
                Bindingstid til {commitmentEndDate}
              </Paragraph>
            </Container>
          </Container>
        </Card>

        <Paragraph>
          Avslutter du årsrabatten før det har gått 12 md. påløper det et bruddgebyr. Bruddgebyret er lik totalrabatten
          du får i avtaletiden. Bruddgebyret reduseres månedlig ut avtaletiden, regnet fra når avtalen ble inngått, ned
          til minimum 399 kr.
        </Paragraph>

        <Container flexDirection="row" justifyContent="end" gap padding="bottom">
          <Button
            tag="a"
            variant="secondary"
            makeReturnUrl
            isInternalNavigation
            hideLinkIcon
            href="/minside/hjem"
            isLoading={orderLoading}
          >
            Avbryt
          </Button>

          {newPrice && (
            <Button
              onClick={orderOffering}
              trackEvent={{
                ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
                ui_item_context: TRACK_EVENT.ITEM_CONTEXT.ANNUAL_DISCOUNT_FOR_COMMITMENT,
                ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
                ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
                ui_item_text: TRACK_EVENT.ITEM_TEXT.ADD_ANNUAL_DISCOUNT,
              }}
              isLoading={orderLoading}
            >
              Legg til årsrabatt
            </Button>
          )}
        </Container>
      </Container>
    </Container>
  );
};
