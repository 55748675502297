import { useEffect } from "react";
import type { Dispatch, SetStateAction } from "react";
import { useMobileAccount } from "hooks/useMobileAccount";
import { ProfilePicker, Container, Paragraph } from "@telia-no-min-side/components";
import { mapAccountsToProfile, mapSubscriptionToProfile } from "util/mobile/accounts";
import { UserOverviewDataQuery } from "gql/graphql";
import { AGREEMENT_WITHOUT_SUBSCRIPTION, SEE_ALL } from "pages/mobile/mobile-subscription-agreements";
import { useAccountAgreements } from "pages/mobile/mobile-subscription-agreements/context/AccountAgreementsContext";
import { format, notEmpty } from "@telia-no-min-side/utils";
import { getMobileSubscriptionsByAccountId } from "../utils/getMobileSubscriptionsByAccountId";

type Props = {
  nonB2BAccounts: UserOverviewDataQuery["accounts"];
  seeAll: boolean;
  setSeeAll: Dispatch<SetStateAction<boolean>>;
  seeAllForAccount: boolean;
  setSeeAllForAccount: Dispatch<SetStateAction<boolean>>;
  showBanAgreementWithoutSub: boolean;
  setShowBanAgreementWithoutSub: Dispatch<SetStateAction<boolean>>;
};

export function AgreementsProfilePicker({
  nonB2BAccounts,
  seeAll,
  setSeeAll,
  seeAllForAccount,
  setSeeAllForAccount,
  showBanAgreementWithoutSub,
  setShowBanAgreementWithoutSub,
}: Props) {
  const { error, refetch, isLoading, isRefetching, accountId, setAccountId, phoneNumber, setPhoneNumber } =
    useMobileAccount();

  const { loading, hasBanLevelAgreement, productUserName } = useAccountAgreements();

  const seeAllTab = {
    id: SEE_ALL,
    key: SEE_ALL,
    title: SEE_ALL,
    value: SEE_ALL,
  };

  // If there is Account-level agreement, we need to create a new tab in the picker,
  // with title: (productUserName + "uten abonnement") and show the account-level agreement.
  const accountUserName = hasBanLevelAgreement && productUserName && format.startCase(productUserName);
  const banLevelUserTab = accountUserName
    ? {
        id: AGREEMENT_WITHOUT_SUBSCRIPTION,
        key: AGREEMENT_WITHOUT_SUBSCRIPTION,
        title: `${accountUserName} (${AGREEMENT_WITHOUT_SUBSCRIPTION})`,
        value: AGREEMENT_WITHOUT_SUBSCRIPTION,
      }
    : null;

  const account = nonB2BAccounts?.find((account) => account.id === accountId);
  const accountSubscriptions = getMobileSubscriptionsByAccountId(nonB2BAccounts, account?.id || "");

  const accountProfiles = nonB2BAccounts?.map((account) => mapAccountsToProfile(account)) || [];
  const accountProfilesWithSeeAll = [seeAllTab, ...accountProfiles];
  const accountProfilesToShow = accountProfiles.length > 1 ? accountProfilesWithSeeAll : accountProfiles;

  const subscriptionProfiles =
    accountSubscriptions?.map((subscription) => mapSubscriptionToProfile(subscription)) || [];

  const subscriptionProfilesWithSeeAll = hasBanLevelAgreement
    ? [seeAllTab, ...subscriptionProfiles, banLevelUserTab].filter(notEmpty)
    : [seeAllTab, ...subscriptionProfiles].filter(notEmpty);

  const subscriptionProfilesToShow =
    subscriptionProfiles.length > 1
      ? subscriptionProfilesWithSeeAll
      : hasBanLevelAgreement
      ? [...subscriptionProfiles, banLevelUserTab].filter(notEmpty)
      : [...subscriptionProfiles];

  const showRetry = !(isLoading || isRefetching) && !!error;

  const onRetry = () => {
    if (error) {
      refetch();
    }
  };

  useEffect(() => {
    if (seeAll) return;
    const selectedAccount = nonB2BAccounts?.find((account) => account.id === accountId);
    if (selectedAccount) {
      return setAccountId(selectedAccount.id);
    }
    setAccountId(nonB2BAccounts?.[0]?.id || accountId);
  }, [accountId, setAccountId, seeAll, nonB2BAccounts]);

  useEffect(() => {
    if (accountSubscriptions.length === 0) {
      return setShowBanAgreementWithoutSub(true);
    }
    if (accountSubscriptions.length === 1) {
      setSeeAllForAccount(false);
      setShowBanAgreementWithoutSub(false);
      return setPhoneNumber(accountSubscriptions[0]?.phoneNumber.localNumber || "");
    }
    if (accountSubscriptions.length > 1) {
      return setSeeAllForAccount(true);
    }
  }, [accountId, seeAll]);

  const onAccountProfileChange = (value: string) => {
    if (value === SEE_ALL) {
      return setSeeAll(true);
    }
    setSeeAll(false);
    setSeeAllForAccount(false);
    setShowBanAgreementWithoutSub(false);
    setAccountId(value);
  };

  const onSubscriptionProfileChange = (value: string) => {
    if (value === SEE_ALL) {
      return setSeeAllForAccount(true);
    }
    if (value === AGREEMENT_WITHOUT_SUBSCRIPTION) {
      setSeeAllForAccount(false);
      return setShowBanAgreementWithoutSub(true);
    }
    setSeeAllForAccount(false);
    setShowBanAgreementWithoutSub(false);
    setPhoneNumber(value);
  };

  return (
    <Container flexDirection="column" gap="lg">
      <Container flexDirection="column" gap>
        <Paragraph removeMargin>Kundenummer:</Paragraph>
        {accountProfiles.length > 0 && (
          <ProfilePicker
            profiles={accountProfilesToShow}
            selectedProfile={seeAll ? SEE_ALL : accountId}
            loading={isLoading || isRefetching}
            variant="primary"
            onChange={onAccountProfileChange}
            showRetry={showRetry}
            onRetry={onRetry}
            containerProps={{ gap: true }}
          />
        )}
      </Container>

      {!seeAll && (
        <Container flexDirection="column" gap>
          {accountSubscriptions.length > 0 ? <Paragraph removeMargin>Avtaler tilknyttet kundenummer:</Paragraph> : null}

          <ProfilePicker
            profiles={subscriptionProfilesToShow}
            selectedProfile={
              seeAllForAccount ? SEE_ALL : showBanAgreementWithoutSub ? AGREEMENT_WITHOUT_SUBSCRIPTION : phoneNumber
            }
            loading={isLoading || isRefetching || loading}
            variant="primary"
            onChange={onSubscriptionProfileChange}
            containerProps={{ gap: true }}
          />
        </Container>
      )}
    </Container>
  );
}
