import { Card, Container, Icon, Paragraph } from "@telia-no-min-side/components";

export function JuniorSummerCampaignBanner() {
  return (
    <Card backgroundColor="warning" showShadow={false} removeMinHeight>
      <Container flexDirection="row" gap>
        <div>
          <Icon size="sm" icon="weather" />
        </div>
        <Paragraph removeMargin>
          <strong>Sommerkampanje ut august!</strong> Halv pris i et halvt år.
        </Paragraph>
      </Container>
    </Card>
  );
}
