import { Icon, Text, Flex } from "@telia/teddy";
import { types } from "@telia-no-min-side/utils";
import { ExpandableProductCard } from "components/expandable-product-card";
import { getListPriceText, getPriceText } from "util/priceFormatter";

type Props = {
  subscriptionProduct?: types.fixed.Product;
  isLoading: boolean;
};

export function TvInfoCard({ subscriptionProduct, isLoading }: Props) {
  const currentPrice = getPriceText(subscriptionProduct?.price);
  const originalPrice = getListPriceText(subscriptionProduct?.price);

  return (
    <ExpandableProductCard
      category={subscriptionProduct?.title || "Strømming & TV"}
      name={subscriptionProduct?.title2 || ""}
      price={currentPrice}
      ordinaryPrice={originalPrice}
      actions={subscriptionProduct?.actions}
      expandableContent={
        <>
          <Text as="p" variant="paragraph-100-bold">
            Inkludert
          </Text>
          {/* TODO: this text as well as TV logos should be returned from the backend
            https://jira.atlassian.teliacompany.net/browse/NOCT-38886
            https://jira.atlassian.teliacompany.net/browse/NOCT-38887
        */}
          <Flex mt="200" gap="200">
            <Icon name="entertainment" size="sm" />
            <Text variant="paragraph-100">{subscriptionProduct?.description}</Text>
          </Flex>
        </>
      }
      isLoading={isLoading}
    />
  );
}
