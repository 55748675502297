import React from "react";
import { ExpandableCard, Badge, BadgeProps, Icon, IconProps, Text, Skeleton, Heading, Flex, Box } from "@telia/teddy";
import { types } from "@telia-no-min-side/utils";
import { ActionButtons } from "./ActionButtons";
import styled from "styled-components";
import { color } from "@telia/teddy";

type Props = {
  category: string;
  name: string;
  price: string;
  ordinaryPrice?: string;
  expandableContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  badges?: {
    variant: BadgeProps["variant"];
    icon?: IconProps["name"];
    text: string;
  }[];
  actions?: types.fixed.Product["actions"];
  isLoading?: boolean;
};

export function ExpandableProductCard({
  category,
  name,
  price,
  ordinaryPrice,
  expandableContent,
  footerContent,
  badges,
  actions,
  isLoading = false,
}: Props) {
  const hasActions = actions && actions.length > 0;
  return (
    <ExpandableCard type="multiple">
      <ExpandableCard.Item value="content1">
        <ExpandableCardTrigger>
          {badges && badges.length > 0 && (
            <ExpandableCard.Slot align="top-right">
              {badges.map((badge) => (
                <Badge key={badge.text} variant={badge.variant}>
                  {badge.icon && <Icon name={badge.icon} />}
                  {badge.text}
                </Badge>
              ))}
            </ExpandableCard.Slot>
          )}
          <Text as="p" mb="25" variant="paragraph-100">
            {category}
          </Text>
          <ExpandableCard.Header>
            <ExpandableCard.Description>
              <Skeleton key="skeleton-name" width="200px" loading={isLoading} radius="lg">
                <Heading as="h5" variant="title-100">
                  {name}
                </Heading>
              </Skeleton>
              <Skeleton key="skeleton-price" width="100px" loading={isLoading} radius="lg">
                <ExpandableCard.Price>
                  {ordinaryPrice ? (
                    <Text as="p" mr="100" variant="paragraph-100" maxWidth="300px">
                      {<s>{ordinaryPrice}</s>}
                    </Text>
                  ) : null}
                  <Text as="p" variant="paragraph-100-bold">
                    {price}
                  </Text>
                </ExpandableCard.Price>
              </Skeleton>
            </ExpandableCard.Description>
            <ExpandableCard.Indicator />
          </ExpandableCard.Header>
          <ExpandableCard.Line />
          <ExpandableCard.Content
            style={{
              width: "100%",
              paddingLeft: "0",
              paddingRight: "0",
            }}
          >
            <Skeleton key="skeleton-container" width="100%" loading={isLoading} radius="lg">
              {expandableContent}
            </Skeleton>
            {hasActions && <ActionButtons key="action-content" actions={actions} isLoading={isLoading} />}
          </ExpandableCard.Content>
          <ExpandableCard.HiddenContainer>
            <Flex>
              {footerContent}
              {hasActions && (
                <Box ml="auto">
                  <ActionButtons key="action-container" actions={actions} isLoading={isLoading} />
                </Box>
              )}
            </Flex>
          </ExpandableCard.HiddenContainer>
        </ExpandableCardTrigger>
      </ExpandableCard.Item>
    </ExpandableCard>
  );
}

const ExpandableCardTrigger = styled(ExpandableCard.Trigger)`
  color: ${color.teddyColorTextDefault};
`;
