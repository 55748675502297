import { format } from "@telia-no-min-side/utils";
import { GetAccountAgreementsNewQuery } from "gql/graphql";
import { INSURANCE_TYPE } from "util/constants/agreements";

// TODO: Temporary solution, to bind insurance with lease agreement
export function getInsuranceForLeaseAgreement(
  insuranceAgreements: GetAccountAgreementsNewQuery["account"]["insuranceAgreements"],
  phoneModel: string
) {
  if (!insuranceAgreements?.length) return { isInsuranceExist: false, insuranceType: "" };

  // 🛠️ Insurance agreements can be part of Switch, Lease, or standalone.
  // - "Telia SVITSJ i 24 md." → Insurance under Switch agreement.
  // - "Plussforsikring" → Plus insurance under Lease or standalone.
  // - "Skjermforsikring" → Screen insurance under Lease or standalone.
  const insurance = insuranceAgreements.find((ins) => ins.productTerms?.some((term) => term?.value === phoneModel));

  const shortNameLowerCase = insurance?.shortName?.toLowerCase() || "";

  const isScreenInsurance = shortNameLowerCase.includes(INSURANCE_TYPE.SCREEN);
  const isPlusInsurance = shortNameLowerCase.includes(INSURANCE_TYPE.PLUS);

  const insuranceUnderLease = isScreenInsurance || isPlusInsurance;
  if (!insuranceUnderLease) return { isInsuranceExist: false, insuranceType: "" };

  return {
    isInsuranceExist: true,
    insuranceType: format.startCase(isScreenInsurance ? INSURANCE_TYPE.SCREEN : INSURANCE_TYPE.PLUS),
  };
}
