import { Alert } from "@telia-no-min-side/components";
import { AvtalegiroStatus } from "src/pages/invoice/InvoiceSettingsPage";

type AvtalegiroAlertProps = {
  avtalegiroStatus: string | null;
};

export const AvtalegiroAlert = ({ avtalegiroStatus }: AvtalegiroAlertProps) => {
  if (avtalegiroStatus === AvtalegiroStatus.ERROR) {
    return (
      <Alert
        withSpacing
        icon="alert"
        kind="negative"
        isOpen
        title="Vi klarte ikke å opprette AvtaleGiro"
        text="Noe gikk galt. Prøv igjen senere."
      />
    );
  }

  if (avtalegiroStatus === AvtalegiroStatus.CANCEL) {
    return (
      <Alert
        withSpacing
        icon="alert"
        kind="info"
        isOpen
        title="AvtaleGiro ble ikke opprettet"
        text="Prøv igjen hvis du ønsker å opprette AvtaleGiro."
      />
    );
  }

  if (avtalegiroStatus === AvtalegiroStatus.SUCCESS) {
    return (
      <Alert
        withSpacing
        icon="alert"
        kind="positive"
        isOpen
        title="AvtaleGiro er opprettet"
        text="Det kan ta opptil 45 dager før avtalen trer i kraft. 
              Endringene vises på Min Side så snart avtalen er aktiv.
              Har du en faktura som forfaller snart, 
              husk å betale den manuelt hvis den forfaller før AvtaleGiro er aktiv."
      />
    );
  }

  return null;
};
