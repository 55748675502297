import { DashboardLayout } from "pages/tv/common/DashboardLayout";
import { BuyNewTvBox } from "./BuyNewTvBox";

export function BuyNewTvBoxPage() {
  return (
    <DashboardLayout>
      <BuyNewTvBox />
    </DashboardLayout>
  );
}
