import { IconDefinition } from "@telia/styleguide";
import { IconName } from "@telia/teddy";

export const LINE_DISCOUNT_GROUP = "MHS_B2C";

export type AdditionalPropertiesCode =
  | "DATA_ROLLOVER"
  | "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE"
  | "TELIA_SKY"
  | "TELIA_TRYGG"
  | "SCREEN_PROTECTOR"
  | "COST_CONTROL"
  | "EXTRA_SIM";

export const IncludedPropertiesCode: {
  code: AdditionalPropertiesCode;
  icon: IconDefinition;
  text: string;
}[] = [
  { code: "DATA_ROLLOVER", icon: "product-data-rollover", text: "Data Rollover" },
  { code: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE", icon: "gift", text: "Data Sharing" },
  { code: "TELIA_SKY", icon: "cloud", text: "Telia Sky - Ubegrenset skylagring" },
  { code: "SCREEN_PROTECTOR", icon: "broken-phone", text: "Tre skjermbeskyttere i året" },
  { code: "TELIA_TRYGG", icon: "security", text: "Telia Trygg - Sikkerhet på nett" },
  { code: "EXTRA_SIM", icon: "sim-card", text: "2 ekstra SIM-kort" },
  { code: "COST_CONTROL", icon: "lock-locked", text: "Kostnadskontroll" },
];

export const IncludedPropertiesCodeNEW: {
  code: AdditionalPropertiesCode;
  icon: IconName;
  text: string;
}[] = [
  { code: "DATA_ROLLOVER", icon: "sync", text: "Data Rollover" },
  { code: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE", icon: "present", text: "Data Sharing" },
  { code: "TELIA_SKY", icon: "cloud", text: "Telia Sky - Ubegrenset skylagring" },
  { code: "SCREEN_PROTECTOR", icon: "broken-phone", text: "Tre skjermbeskyttere i året" },
  { code: "TELIA_TRYGG", icon: "secured-1", text: "Telia Trygg - Sikkerhet på nett" },
  { code: "EXTRA_SIM", icon: "simcard", text: "2 ekstra SIM-kort" },
  { code: "COST_CONTROL", icon: "lock", text: "Kostnadskontroll" },
];

export const MAX_SPEED_SUBSCRIPTIONS = ["Telia X Max Pluss", "Telia X Max", "Telia X + Viaplay Total"];
export const BUCKET_SUBCRIPTIONS = ["Telia Junior 1 GB", "Telia Junior 3 GB", "10 GB", "5 GB", "20 GB"];

export const SUMMER_CAMPAIGN_PRICE_PLANS_FOR_JUNIORS = [
  "TELIA_JUNIOR_01GB+PROMO_JR06_50",
  "TELIA_JUNIOR_03GB+PROMO_JR06_50",
];

export const DATA_UNLIMITED_ADDITIONAL_PROPERTY = "DATA_UNLIMITED";
export const PRODUCT_USER_MAX_AGE_ADDITIONAL_PROPERTY = "PRODUCT_USER_MAX_AGE";
export const MAX_AGE_VALUE_JUNIOR = "15";
export const MAX_AGE_VALUE_UNG = "29";
export const START_DISCOUNT_CODE = "NGRC127";
export const BLOCKED_CHANGES_BY_VIAPLAY_TOTAL = "NCVPLBAR1_EXISTING";

export const SLEEP_MODE_OFFERING_CODE = "MBB4G_002GB_PRIV_3_2.REGULAR";

export const SUMMER_CAMPAIGN_PRODUCT_CODES = [
  // 2023
  "NSHAPE252",
  "NSHAPE253",
  "NSHAPE254",
  "NSHAPE255",
  "NSHAPE256",
  "NSHAPE257",
  "NSHAPE284",
  "NSHAPE285",
  //2024
  "NSHAPE330",
  "NSHAPE331",
  "NSHAPE332",
  "NSHAPE333",
  "NSHAPE334",
  "NSHAPE335",
  "NSHAPE336",
  "NSHAPE337",
  "NSHAPE338",
  "NSHAPE339",
  "NSHAPE340",
];

export const VIAPLAY_IMAGES = [
  { url: "https://www.telia.no/globalassets/system/logo-kanaler/viaplay-fixed-on-light.svg", height: "30" },
  { url: "https://www.telia.no/globalassets/tv/viaplay/premier-league-logo.svg", height: "30" },
  { url: "https://www.telia.no/globalassets/tv/viaplay/formel-1-logo.png", height: "20" },
];

export const VIAPLAY_INCLUDED_PRODUCTS = [
  {
    icon: "flag",
    text: "Sport - Formel 1, Viaplay Vinter, UEFA Europa League, UFC, Bundesliga, DP World Tour og mange andre!",
  },
  { icon: "price", text: "Premier League" },
  { icon: "film", text: "Film & Serier " },
] as const;
