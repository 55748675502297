import { Flex } from "@telia/teddy";
import { Breadcrumb } from "components/Breadcrumb";
import { MobileDashboardProvider } from "./context/MobileDashboardContext";
import {
  TopupModal,
  TopupDrawer,
  NavigationSection,
  SubscriptionCard,
  UsageSection,
  AgreementCard,
  Sections,
} from "./modules";

export function MobileSubscriptionDashboard() {
  return (
    <MobileDashboardProvider>
      <TopupModal />
      <TopupDrawer />

      <Flex direction="column" width="100%" maxWidth="720px" gap="50px">
        <Flex direction="column" gap="gutter-xl">
          <Breadcrumb />

          <SubscriptionCard />
          <NavigationSection />
        </Flex>

        <UsageSection />
        <AgreementCard />

        <Sections />
      </Flex>
    </MobileDashboardProvider>
  );
}
