import React, { ReactNode, useEffect, useRef, useState } from "react";
import { track } from "@telia-no-min-side/utils";
import { v4 as uuid } from "uuid";
import { CardBaseProps } from "../Card";
import { ExpandableCard } from "../ExpandableCard";
import { useAccordion } from "./Context";

export type Props = {
  /**
   * The id you want to associate with the AccordionItem.
   * By default, the component will generate a uniq id on its own.
   * This id is used by Accordion to handle which AccordionItem to expand.
   */
  id?: string;
  /**
   * Force open or closed state for the component.
   * If not present, the component will control this state on its own.
   */
  isOpen?: boolean;

  /**
   * SetInitial open state
   */
  initialIsOpen?: boolean;

  /**
   * Callback function fired when accordion is clicked.
   *
   * @param {React.BaseSyntheticEvent}
   */
  onClick?: (e: React.BaseSyntheticEvent, openIde: string) => void;

  tracking?: {
    open: track.GenericEvent;
    close: track.GenericEvent;
  };

  /**
   * In order for the component to work correctly,
   * you need to only use AccordionHeader and AccordionBody as child components
   */
  children?: (({ isOpen }: { isOpen: boolean }) => ReactNode) | ReactNode;

  className?: string;

  /**
   * Making on hover effect more applicable to different products and designs
   */
  backgroundOnHover?: string;
} & Omit<CardBaseProps, "removeMinHeight">;

/**
 * @deprecated Use Teddy components instead
 */
export function AccordionItem(props: Props): JSX.Element {
  const { children, tracking, isOpen: propsIsOpen, id: propsId, initialIsOpen, onClick, ...cardProps } = props;
  const [isOpenInternal, setIsOpenInternal] = useState(initialIsOpen || false);
  const generatedIdRef = useRef(uuid());
  const { openIds, setAccordionItemOpen, variant } = useAccordion();
  const isOpen = typeof propsIsOpen === "boolean" ? propsIsOpen : isOpenInternal;

  const id = propsId ?? generatedIdRef.current;

  useEffect(() => {
    if (openIds) {
      setIsOpenInternal(openIds.includes(id));
    }
  }, [openIds]);

  function onItemClick(e: React.BaseSyntheticEvent) {
    if (onClick) {
      onClick(e, id);
      if (e.defaultPrevented) return;
    }
    setIsOpenInternal((prev) => !prev);
    if (setAccordionItemOpen) setAccordionItemOpen(id);
  }

  return (
    <>
      <ExpandableCard
        backgroundColor={variant === "info" ? "grey" : undefined}
        id={id}
        isOpen={isOpen}
        onClick={onItemClick}
        tracking={tracking}
        hideBorder={variant === "info"}
        showShadow={variant !== "info"}
        compact={variant === "info"}
        icon={variant === "info" ? (isOpen ? "minus" : "info") : undefined}
        {...cardProps}
      >
        {typeof children === "function" ? children({ isOpen }) : children}
      </ExpandableCard>
    </>
  );
}
