import { Heading, Flex, Box } from "@telia/teddy";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { useFetchProductsAdditionalInfo } from "src/api/fixed";
import { NavigationCard } from "components/NavigationCard";
import { tvCardWidth } from "../utils/constants";
import { ComponentProps } from "react";

export function AdditionalLinks() {
  const productsAdditionalInfo = useFetchProductsAdditionalInfo();

  const additionalInfoItems: (ComponentProps<typeof NavigationCard> & { condition?: boolean })[] = [
    {
      condition: productsAdditionalInfo.data?.hasAlaCarte,
      title: "Valgmenyen",
      description: "Velg TV-kanaler og strømmetjenester som passer din smak.",
      icon: "switch-arrows",
      link: EXTERNAL_LINK.TELIA_PLAY_CHANNELPICKER,
    },
    {
      condition: true,
      title: "Telia Play",
      description: "Sjekk ut vårt strømme- og TV-univers!",
      icon: "play",
      link: EXTERNAL_LINK.TELIA_PLAY,
    },
    {
      condition: productsAdditionalInfo.data?.hasAlaCarte,
      title: "Gammel valgmeny",
      description: "For nostalgikere som synes alt var bedre før, og ønsker å velge inn TV-innhold på gamlemåten",
      icon: "bookmark",
      link: EXTERNAL_LINK.MINSIDE_CHANNELPICKER,
    },

    // TODO: enable when Additional Services page is ready
    // {
    //   title: "Dine Tjenester",
    //   description: "Se, bestill og administrer dine tjenester.",
    //   icon: "services",
    //   link: INTERNAL_LINK.VALUE_ADDED_SERVICES_PAGE,
    //   cardVariant: "purple-light",
    // },
    {
      condition: true,
      title: "Hjelpesider TV",
      description: "Sjekk ut våre guider hvis du har problemer med TV",
      icon: "question",
      link: EXTERNAL_LINK.TV_HELP_PAGES,
      cardVariant: "purple-light",
    },
  ];

  return (
    <Box>
      <Heading as="h2" variant="title-100">
        Utforsk
      </Heading>
      <Flex mt="200" gap="400" wrap="wrap">
        {additionalInfoItems.map(
          ({ title, description, icon, link, cardVariant, condition }) =>
            condition && (
              <NavigationCard
                key={title}
                cardVariant={cardVariant}
                title={title}
                description={description}
                icon={icon}
                link={link}
                width={tvCardWidth}
              />
            )
        )}
      </Flex>
    </Box>
  );
}
