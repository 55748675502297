import { Maybe, OfferingPriceDetails, OfferingPriceCodes } from "gql/graphql";
import { isMbbSleepModeOffer } from "./isMbbSleepModeOffer";

export function isMbbBucketOffer(
  offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined,
  shortName?: Maybe<string>
) {
  if (isMbbSleepModeOffer(shortName)) {
    return false;
  }

  return offeringPrices?.filter(
    (prop) => prop?.code === OfferingPriceCodes.SpeedDownloadReduced && prop?.monetaryUnit === "KBIT"
  ).length;
}
