import { Link } from "react-router-dom";
import { Card, Text } from "@telia/teddy";

export function HelpCard() {
  return (
    <Card variant="white" shadow layout="default" bordered width="100%" maxWidth="400px">
      <Card.Heading as="h3" variant="title-100">
        Trenger du hjelp?
      </Card.Heading>
      <Card.Content>
        <Text variant="additional-100">Vi hjelper deg med det du lurer på.</Text>
      </Card.Content>
      <Card.Footer height="100%" align="end">
        <Card.Button asChild variant="secondary">
          <Link to="/minside/hjelp">Få hjelp</Link>
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
