import { Button } from "@telia-no-min-side/components";
import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;
  gap: 25px;
`;

export const Image = styled.img`
  width: 100%;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;
