import { graphql } from "src/gql";

export const LEASE_AGREEMENT_FRAGMENT = graphql(`
  fragment LeaseAgreementFields on LeaseAgreement {
    id
    phoneNumber {
      localNumber
    }
    product
    model
    outstandingAmount {
      value
    }
    regularInstallmentAmount {
      value
    }
    effectiveDate
    expirationDate
  }
`);
