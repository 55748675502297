import { Accordion, Heading, Link, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function VippsPaymentAccordion() {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Hvordan kan jeg opprette / fjerne Vipps-betaling?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Nets og Vipps digitaliserer regningsbetaling i Norge, og tilrettelegger for enklere løsninger for deg som
          kunde. Hvis du har Vipps, vil du automatisk få alle fakturaer som eFaktura i Vipps-appen og i nettbanken din.
          Det vil dermed ikke automatisk sendes ut papirfaktura lenger.
        </Paragraph>
        <Heading tag="h3" variant="subsection-100">
          Ønsker du å fortsatt motta papirfaktura?
        </Heading>
        Har du tidligere mottatt fakturaen din på papir i posten, og vil fortsette med det, så må du deaktivere eFaktura
        i Vipps-appen og slette den nyopprettede eFaktura-avtalen i nettbanken din. Hvordan du sletter eFaktura-avtalen
        i nettbanken varierer fra bank til bank, så der må du ta kontakt med banken din for hjelp.
        <Link href={EXTERNAL_LINK.VIPS_PAYMENT_TERMS} target="_blank" variant="standalone">
          Les mer om Vipps eFaktura hos Nets
        </Link>
      </Accordion.Content>
    </Accordion.Item>
  );
}
