import { CheckBoxWithLabel, Heading, TextBoxWithLabel } from "@telia/styleguide";
import { Button } from "@telia/styleguide";
import PropTypes from "prop-types";
import React from "react";
import { Spinner } from "../../../../components/spinner-telia/Spinner";
import { RowFlexAlignEndJustifyStart } from "../../modules/LayoutComponents/RowFlexAlignEndJustifyStart";
import { ResponseMessage } from "../../modules/ResponseMessage";
import { updateResponse, updateStatus } from "../../util/PropTypes";
import { ssidPwValidation } from "../../util/SSIDPWUtilities";
import { ipUtilities } from "../../util/validation";
import { wifiSettingsTags } from "../../util/WiFiSettingsUtilities";

import "./SSIDPWDialog.less";

const SSIDPWContainer = ({ children }) => <div className="ssid-pw-dialogue-container">{children}</div>;

const RowHorisontal = ({ children }) => <div className="row-horisontal">{children}</div>;

const RowVertical = ({ children }) => <div className="row-vertical">{children}</div>;

const Spacer = ({ children }) => <div className="spacer">{children}</div>;

const { string, number, func, bool } = PropTypes;

class SSIDPWDialog extends React.Component {
  static propTypes = {
    SSID: string.isRequired,
    SSIDForOtherFrequency: string.isRequired,
    pw: string.isRequired,
    SsidMinLength: number.isRequired,
    SsidMaxLength: number.isRequired,
    PasswordMinLength: number.isRequired,
    PasswordMaxLength: number.isRequired,
    frequency: string.isRequired,
    onSaveClick: func.isRequired,
    onCancelClick: func.isRequired,
    updateStatus: updateStatus,
    updateResponse: updateResponse,
    twoAndFiveGhzSupported: bool,
    setSamePasswordForBothNetworks: func,
  };

  constructor(props) {
    super(props);
    const sameSSIDWarningMessages = ipUtilities.validateSSIDAndPasswordSettings(
      props.SSID,
      props.SSIDForOtherFrequency
    );

    this.state = {
      SSID: this.props.SSID,
      pw: this.props.pw,
      validSSID: { valid: true, message: "" },
      validPW: { valid: true, message: "" },
      pwVisibility: false,
      sameSsidWarning: sameSSIDWarningMessages,
    };
    this.setSSIDAndPasswordTag =
      props.frequency === "2.4" ? wifiSettingsTags.SetSsidAndPassword2Ghz : wifiSettingsTags.SetSsidAndPassword5Ghz;
    this.copySSIDAndPasswordFromOtherFrequencyTag =
      props.frequency === "2.4"
        ? wifiSettingsTags.SetSameSSIDAndPassword2GHz
        : wifiSettingsTags.SetSameSSIDANdPassword5GHz;
    this.otherFrequency = props.frequency === "2.4" ? "5" : "2.4";
  }

  componentDidUpdate(prevProps) {
    if (prevProps.SSID !== this.props.SSID || prevProps.pw !== this.props.pw) {
      /*eslint-disable*/
      this.setState({ SSID: this.props.SSID, pw: this.props.pw });
      /*eslint-enable*/
    }
  }

  handleButtonPress = () => {
    this.props.onSaveClick(this.state.SSID, this.state.pw);
  };

  handleSSIDChange = (event) => {
    const { SsidMaxLength, SsidMinLength, SSIDForOtherFrequency } = this.props;
    const message = ssidPwValidation.validateSsid(event.target.value, SsidMaxLength, SsidMinLength);
    const errorMessages = SSIDForOtherFrequency
      ? ipUtilities.validateSSIDAndPasswordSettings(event.target.value, SSIDForOtherFrequency)
      : [];

    this.setState({
      validSSID: {
        valid: message === "",
        message: message,
      },
      SSID: event.target.value,
      sameSsidWarning: errorMessages,
    });
  };

  handlePWChange = (event) => {
    const message = ssidPwValidation.validatePw(
      event.target.value,
      this.props.PasswordMaxLength,
      this.props.PasswordMinLength
    );

    this.setState({
      validPW: {
        valid: message === "",
        message: message,
      },
      pw: event.target.value,
    });
  };

  togglePasswordFieldVisibility = () => {
    this.setState({ pwVisibility: !this.state.pwVisibility });
  };

  render() {
    const {
      frequency,
      updateResponse,
      updateStatus,
      onCancelClick,
      twoAndFiveGhzSupported,
      setSamePasswordForBothNetworks,
    } = this.props;
    const { SSID, pw, pwVisibility, validPW, validSSID, sameSsidWarning } = this.state;

    return (
      <SSIDPWContainer>
        <Heading tag="h3" size="xs" text={`Endre Nettverksnavn (SSID) og passord ${frequency} GHz`} />
        <RowVertical>
          <TextBoxWithLabel
            labelText={"Nettverksnavn (SSID)"}
            errorMessage={this.state.validSSID.message}
            placeholder={"Nettverksnavn..."}
            onChange={this.handleSSIDChange}
            type={"text"}
            value={SSID}
          />
          {sameSsidWarning.length > 0 && (
            <label className="warning-message">{sameSsidWarning.find((msg) => msg.tag === "ssid").message}</label>
          )}
        </RowVertical>
        <RowVertical>
          <TextBoxWithLabel
            labelText={"Passord"}
            errorMessage={this.state.validPW.message}
            placeholder={"Passord..."}
            onChange={this.handlePWChange}
            type={pwVisibility ? "text" : "password"}
            value={pw}
          />
          <CheckBoxWithLabel
            label={"Vis passord"}
            onChange={this.togglePasswordFieldVisibility}
            checked={pwVisibility}
          />
        </RowVertical>
        {twoAndFiveGhzSupported && (
          <Spacer>
            <RowFlexAlignEndJustifyStart>
              <Button
                onClick={() => setSamePasswordForBothNetworks(frequency)}
                text={`Kopier passord fra ${this.otherFrequency} GHz`}
                size={"small"}
              />
              {updateStatus.tag === this.copySSIDAndPasswordFromOtherFrequencyTag && (
                <Spinner size={"small"} color={"black"} inline />
              )}
            </RowFlexAlignEndJustifyStart>
          </Spacer>
        )}
        <RowHorisontal>
          <Button text={"Avbryt"} kind={"cancel"} onClick={onCancelClick} />
          <Button
            isProcessing={updateStatus.tag === this.setSSIDAndPasswordTag && updateStatus.updating}
            onClick={this.handleButtonPress}
            processingText={"Lagre"}
            text={"Lagre"}
            isDisabled={!validPW.valid || !validSSID.valid}
            kind={"inverted"}
          />
        </RowHorisontal>
        <RowFlexAlignEndJustifyStart>
          <ResponseMessage updateResponse={updateResponse} tag={this.setSSIDAndPasswordTag} />
          <ResponseMessage updateResponse={updateResponse} tag={this.copySSIDAndPasswordFromOtherFrequencyTag} />
        </RowFlexAlignEndJustifyStart>
      </SSIDPWContainer>
    );
  }
}

export default SSIDPWDialog;
