import { Button, Card, Container, Paragraph, useAuth } from "@telia-no-min-side/components";
import { Insight } from "./Insight";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function RightOfAccessPage() {
  const { requestBankId, selectedAuthProvider } = useAuth();
  return (
    <Container goBackTo="/minside/personvern" padding maxWidth="lg" flexDirection="column" gap="lg">
      {selectedAuthProvider === "APP_SSO" ? (
        <Card>
          <Container padding data-tracking-id="insight-page">
            <>
              <Paragraph>
                Dessverre kan du ikke bestille innsynsrapport med gammel login lenger. Vennligst login med BankId.
              </Paragraph>
              <Paragraph>
                <Button
                  trackEvent={{
                    ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                    ui_item_context: TRACK_EVENT.ITEM_CONTEXT.INSPECTION_REPORT,
                    ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
                    ui_item_url: TRACK_EVENT.ITEM_URL.IDENTITY,
                    ui_item_text: TRACK_EVENT.ITEM_TEXT.BANKID_LOGIN,
                  }}
                  onClick={() => requestBankId?.()}
                >
                  Logg inn med BankID
                </Button>
              </Paragraph>
            </>
          </Container>
        </Card>
      ) : (
        <Insight />
      )}
    </Container>
  );
}
