import { uri } from "@telia-no-min-side/utils";
import { Button, Drawer, Flex, Icon, toast } from "@telia/teddy";
import { NavigationCard } from "components/NavigationCard";
import { tvCardWidth } from "pages/tv/utils/constants";
import { useState } from "react";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { useFetchProductsAdditionalInfo } from "src/api/fixed";

export function FreeMaxBasic() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const productsAdditionalInfo = useFetchProductsAdditionalInfo();

  const activateMax = useAxiosPost<{ status: string }>(uri.purchase("/api/order/submit?axiomId=1307839798297"), {
    onSuccess(response) {
      if (response.status === "order_failed") {
        toast.error("Her gikk noe galt!", {
          icon: <Icon name="error-filled" size="sm" />,
          description: "Din tjeneste er ikke aktivert.",
        });
      } else {
        toast.success("Max Basis er nå aktivert", {
          icon: <Icon name="checkmark" size="sm" />,
          description: "Din tjeneste er aktivert.",
        });
      }
    },
    onError() {
      toast.error("Her gikk noe galt!", {
        icon: <Icon name="error-filled" size="sm" />,
        description: "Din tjeneste er ikke aktivert.",
      });
    },
    onFinally() {
      setIsActivating(false);
      setIsDrawerOpen(false);
    },
  });

  const handleActivateMax = () => {
    setIsActivating(true);
    activateMax.post({}).then(() => productsAdditionalInfo.mutate());
  };

  return (
    <>
      <NavigationCard
        title="Max Basis inkludert"
        description="Du har Max Basis kostnadsfritt i din avtale. Du må aktivere tjenesten før bruk."
        icon="present"
        width={tvCardWidth}
        onClick={() => setIsDrawerOpen(true)}
      />
      <Drawer open={isDrawerOpen} onOpenChange={(open) => setIsDrawerOpen(open)}>
        <Drawer.Content width="100%" maxWidth="500px">
          <Drawer.Close aria-label="Close" slot="floating" />
          <Drawer.Title variant="title-200">Max Basis inkludert</Drawer.Title>
          <Flex mt="400" mb="400">
            <Drawer.Description>
              For å få tilgang til tjenesten må du først aktivere produktet ved å trykke på knappen under, og deretter
              registrere deg for å kunne se innholdet. Registreringslenken blir sendt på SMS og mail.{" "}
            </Drawer.Description>
          </Flex>
          <Flex gap="200">
            <Drawer.Close slot="content">
              <Button variant="secondary" width="100%">
                Avbryt
              </Button>
            </Drawer.Close>
            <Button onClick={handleActivateMax} width="100%" disabled={isActivating}>
              Aktiver nå
            </Button>
          </Flex>
        </Drawer.Content>
      </Drawer>
    </>
  );
}
