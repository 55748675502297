import Ajax from "./util/Ajax";
import UriHelper from "./util/UriHelper";

export const get = function (href, query = {}, isAbsolute = false) {
  return new Promise((resolve, reject) => {
    if (isAbsolute) {
      return Ajax.get(href).then(resolve, reject);
    } else {
      const url = UriHelper.withFakelogin(href);

      return Ajax.get(UriHelper.urlWithQuery(url, query)).then(resolve, reject);
    }
  });
};

export const post = function (href, data) {
  return new Promise((resolve, reject) => {
    const url = UriHelper.withFakelogin(href);

    Ajax.post(url, data).then(resolve, reject);
  });
};
