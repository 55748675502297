import { Heading } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import { Grid, Text, Box, Flex } from "@telia/teddy";
import dayjs from "dayjs";
import { LinkCard } from "pages/home/card/LinkCard";
import { useFetchToBeReturned } from "src/api/fixed/useFetchToBeReturned";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function ToBeReturned() {
  const toBeReturned = useFetchToBeReturned();
  return (
    <>
      {toBeReturned.data?.map((product) => (
        <LinkCard
          key={product.agreementDetailId}
          lozenge={{ icon: "info", label: "Viktig informasjon", status: "warning" }}
          href={
            product.recycle
              ? EXTERNAL_LINK.CUSTOMER_SERVICE_NOT_RETURNABLE_EQUIPMENT
              : EXTERNAL_LINK.CUSTOMER_SERVICE_RETURN_EQUIPMENT
          }
          isInternalNavigation={false}
          linkText="Les mer"
          floatButtonRight
        >
          <Grid gapY="50">
            <div />
            <Box asChild gridColumn="">
              {product.recycle ? (
                <Heading tag="h3" variant="title-75">
                  Du har utgått utstyr
                </Heading>
              ) : (
                <Heading tag="h3" variant="title-75">
                  Retur av {product.description}
                </Heading>
              )}
            </Box>
            {product.recycle ? (
              <Text>Lever din {product.description} til en miljøstasjon for el-retur.</Text>
            ) : product.returnDate ? (
              <Flex asChild>
                <Text>Sendes i retur senest {dayjs(product.returnDate).format("DD.MM.YY")}</Text>
              </Flex>
            ) : null}
            <Grid mt="100" width={"100%"} columns="80px auto" gapX="100">
              <Flex gridRow={"span 4"} gridColumn={"1"} justify={"center"} align={"center"}>
                <Box width="80px" height="auto" asChild>
                  <img src={product.productImageUrl} alt={product.description} />
                </Box>
              </Flex>
              {product.serialNumber && (
                <Text variant="additional-100">
                  SN:
                  {product.serialNumber}
                </Text>
              )}
              {product.address && (
                <Text variant="additional-100">
                  Registrert i: {format.startCase(`${product.address.streetName} ${product.address.streetNumber}`)}
                </Text>
              )}
            </Grid>
          </Grid>
        </LinkCard>
      ))}
    </>
  );
}
