import { ChangeEvent } from "react";
import { BillCycleInfo } from "src/gql/graphql";

type Props = {
  options: BillCycleInfo[] | undefined;
  selectedValue: number | undefined;
  onSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
};

export function Select({ options, selectedValue, onSelect }: Props) {
  return (
    <label>
      <strong>Velg ønsket forfallsdato</strong>

      <select className="dropdown-list-with-label__select" name="due-dates" value={selectedValue} onChange={onSelect}>
        <option value={-1} disabled>
          Velg...
        </option>
        {options?.map((dates) => (
          <option key={dates.billCycle} value={dates.billCycle || ""}>
            {dates.dueDay}. hver måned
          </option>
        ))}
      </select>
    </label>
  );
}
