import { useState } from "react";
import { graphql } from "src/gql";
import { Button, Card, Container, Icon, Paragraph, useToast } from "@telia-no-min-side/components";
import { SwitchAgreementFieldsFragment, SwitchInsuranceType } from "gql/graphql";
import { useMutation } from "@apollo/client";
import { format } from "@telia-no-min-side/utils";
import { SwitchInsuranceHeader } from "./SwitchInsuranceHeader";
import { DescriptionText } from "../styles/SwitchInsurance.styled";
import { Terms } from "../../Terms";
import { ToggleSwitchScreenOffModal } from "./ToggleSwitchScreenOffModal";
import { CancelInsuranceModal } from "./CancelInsuranceModal";
import { CANCEL_INSURANCE_TEXT, URL_INSURANCE_AGREEMENT_TERMS } from "util/constants/agreements";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = {
  accountId: string | undefined;
  isLegalOwner: boolean;
  switchAgreement: SwitchAgreementFieldsFragment;
  wantToContinueScreenInsurance: boolean;
};

export function SwitchInsuranceAgreement({
  accountId,
  isLegalOwner,
  switchAgreement,
  wantToContinueScreenInsurance,
}: Props) {
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [showCancelScreenInsuranceModal, setShowCancelScreenInsuranceModal] = useState(false);

  const { addToast } = useToast();

  const CANCEL_SWITCH_INSURANCE = graphql(`
    mutation cancelSwitchInsurance($phoneNumber: String, $input: [OrderInput!]!) {
      order(phoneNumber: $phoneNumber, input: $input) {
        orderId
      }
    }
  `);

  const [cancelSwitchInsurance, { loading }] = useMutation(CANCEL_SWITCH_INSURANCE, {
    onCompleted() {
      setShowCancelScreenInsuranceModal(false);
      setOpenCancelModal(false);
      addToast({ text: CANCEL_INSURANCE_TEXT.SUCCESS, variant: "success" });
    },

    onError() {
      setShowCancelScreenInsuranceModal(false);
      setOpenCancelModal(false);
      addToast({ text: CANCEL_INSURANCE_TEXT.ERROR, variant: "error" });
    },
  });

  const { expirationDate, insurance, phoneNumber, id } = switchAgreement;

  const endDate = format.formatDate(expirationDate, format.DATE_DISPLAY_FORMAT_WITH_SHORT_YEAR);
  const isScreenInsurance = insurance?.type === SwitchInsuranceType.Screen;

  const hasCancelablePlussInsurance = isLegalOwner && insurance?.presentation?.modifiable && !isScreenInsurance;
  const hasCancelableScreenInsurance = isLegalOwner && wantToContinueScreenInsurance && isScreenInsurance;
  const hasCancelableInsurance = hasCancelablePlussInsurance || hasCancelableScreenInsurance;

  return (
    <Container flexDirection="column" gap="lg" data-tracking-id="agreement-switch-card-description">
      <SwitchInsuranceHeader insurance={insurance} isScreenInsurance={isScreenInsurance} />

      <DescriptionText>{insurance?.longDescription}</DescriptionText>

      <Terms
        termsInsuranceProofUrl={""}
        ui_item_context={TRACK_EVENT.ITEM_CONTEXT_SWTICH.FILE_INSURANCE_CLAIM}
        termsInsuranceUrl={URL_INSURANCE_AGREEMENT_TERMS}
        dataTrackingId="switch-insurance-agreements"
      />
      {wantToContinueScreenInsurance && isScreenInsurance && (
        <Card backgroundColor="blue" showShadow={false} style={{ minHeight: "auto" }}>
          <Container flexDirection="row" gap>
            <Icon icon="info" />
            <Container>
              <Paragraph>
                <em>Automatisk fornyelse av skjermforsikring er på</em>
              </Paragraph>
              <Paragraph>
                Det betyr at din skjermforsikring fortsatt er gyldig etter at din SVITSJ-avtale utløpes{" "}
                <em>{endDate}</em> til <em>69,-/md</em>. Ønsker du ikke dette kan forsikringen kanselleres under.
              </Paragraph>
            </Container>
          </Container>
        </Card>
      )}
      {!wantToContinueScreenInsurance && isScreenInsurance && (
        <Card backgroundColor="blue" showShadow={false} style={{ minHeight: "auto" }}>
          <Container flexDirection="row" gap>
            <Icon icon="info" />
            <Paragraph>
              Skjermforsikring er inkludert frem til avtalens utløp, den <em>{endDate}</em>
            </Paragraph>
          </Container>
        </Card>
      )}
      <Container flexDirection="row" gap="lg" justifyContent={hasCancelableInsurance ? "space-between" : "flex-end"}>
        {hasCancelableScreenInsurance && (
          <Button
            trackEvent={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.SHOW,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.CANCEL_SCREEN_INSURANCE,
              ui_item_text: "Kanseller forsikring",
              ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
              ui_item_url: "",
            }}
            onClick={() => setShowCancelScreenInsuranceModal((prevState) => !prevState)}
            variant="tertiary-purple"
          >
            Kanseller forsikring
          </Button>
        )}

        {hasCancelablePlussInsurance && (
          <Button
            trackEvent={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.SHOW,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.CANCEL_PLUS_INSURANCE,
              ui_item_text: "Kanseller forsikring",
              ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
              ui_item_url: "",
            }}
            onClick={() => setOpenCancelModal(true)}
            variant="tertiary-purple"
          >
            Kanseller forsikring
          </Button>
        )}

        <Button
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.FILE_INSURANCE_CLAIM,
            ui_item_text: "Meld forsikringssak",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: insurance?.claimUrl ?? "",
          }}
          whiteSpace="nowrap"
          variant="secondary"
          href={insurance?.claimUrl ?? ""}
          tag="a"
          hideLinkIcon
        >
          Meld forsikringssak
        </Button>
      </Container>

      <ToggleSwitchScreenOffModal
        phoneNumber={phoneNumber?.localNumber ?? ""}
        isModalOpen={showCancelScreenInsuranceModal}
        setIsModalOpen={setShowCancelScreenInsuranceModal}
        endDate={endDate}
        cancelSwitchInsurance={cancelSwitchInsurance}
        loading={loading}
      />

      <CancelInsuranceModal
        phoneNumber={phoneNumber?.localNumber || ""}
        title={insurance?.name || "Forsikring"}
        product={insurance?.code}
        isOpen={openCancelModal}
        setIsOpen={setOpenCancelModal}
        cancelSwitchInsurance={cancelSwitchInsurance}
        loading={loading}
        // if account id is present in payload, backend will cancel on ban-level
        accountId={accountId}
        serviceAgreementId={+id}
      />
    </Container>
  );
}
