import { useContext } from "react";
import { Alert } from "@telia-no-min-side/components";
import { InvoicePaymentStatus } from "src/gql/graphql";
import { ResultMessageContext } from "src/util/ResultMessageContext";

export function PaymentResultMessage({
  invoiceCardPaymentStatus,
}: {
  invoiceCardPaymentStatus: InvoicePaymentStatus | undefined;
}) {
  const context = useContext(ResultMessageContext);
  if (invoiceCardPaymentStatus === InvoicePaymentStatus.Delivered) {
    return (
      <Alert
        isOpen
        kind="positive"
        icon="check-circle-filled"
        title="Takk for din betaling!"
        text=" Saldoen din blir oppdatert så fort vi har registrert betalingen. Det tar
  normalt 1-2 virkedager."
        id="invoice-paid"
      />
    );
  }
  if (invoiceCardPaymentStatus === InvoicePaymentStatus.Reserved) {
    return (
      <Alert
        isOpen
        title="Beløpet er reservert"
        text=" Beløpet er reservert, men vi venter fortsatt på bekreftelse på at beløpet er trukket. Dette kan ta opp
  til 20 minutter."
        kind="warning"
        icon="time-waiting"
      />
    );
  }

  if (context?.invoicePaymentResult === "OK") {
    return (
      <Alert
        isOpen
        title="Din faktura er betalt"
        text="Det kan ta opptil 3 dager før betalingen er registrert på Min Side."
        kind="positive"
        icon="check-circle-filled"
      />
    );
  }

  if (context?.invoicePaymentResult === "FAILED") {
    return (
      <Alert
        isOpen
        title="Her gikk noe galt!"
        text="Vi kunne dessverre ikke registrere din betaling. Vennligst prøv igjen. Dersom du fortsatt opplever problemer kan du ringe oss på +47 924 05 050"
        kind="negative"
        icon="error-filled"
      />
    );
  }

  if (context?.invoicePaymentResult === "ANOTHER_PAYMENT_IN_PROGRESS") {
    return (
      <Alert
        isOpen
        title="En annen betaling under behandling!"
        text="Du har allerede en betaling under behandling. Vennligst vent og prøv igjen litt senere."
        kind="warning"
        icon="money-krone"
      />
    );
  }
  if (context?.invoicePaymentResult === "CANCEL") {
    return (
      <Alert
        isOpen
        title="Betalingen ble avbrutt"
        text="Vennligst prøv på nytt om 5 minutter."
        kind="info"
        icon="info-filled"
      />
    );
  }
  if (invoiceCardPaymentStatus === InvoicePaymentStatus.Initialized) {
    return (
      <Alert
        isOpen
        title="Under behandling"
        text="Det kan ta opp til 5 minutter før dine siste handlinger er registrert."
        kind="info"
        icon="info-filled"
      />
    );
  }

  return null;
}
