import { Container, Heading, Modal } from "@telia-no-min-side/components";
import dayjs from "dayjs";
import { useFetchInvoiceDetails } from "src/api/fixed";
import { OnlinePayment } from "src/components/pay-invoice/OnlinePayment";
import { RedirectPaymentAuthorisation } from "src/components/pay-invoice/RedirectPaymentAuthorisation";
import { useInvoice } from "src/store/hooks/invoice-context";

type Props = {
  isPaymentModalOpen: boolean;
  setIsPaymentModalOpen: (value: boolean) => void;
  setIsPaymentDone: (value: boolean) => void;
};

export const PayByCardFixed = (props: Props): JSX.Element | null => {
  const { lastInvoice, mutateInvoices } = useInvoice();
  const invoiceId = lastInvoice?.invoice.invoiceId;
  const invoiceDetails = useFetchInvoiceDetails(invoiceId?.toString() ?? "");
  const { isPaymentModalOpen, setIsPaymentModalOpen, setIsPaymentDone } = props;

  function mutateInvoicesAndDetails() {
    invoiceDetails.mutate();
    mutateInvoices();
  }

  function onPaymentDone() {
    setIsPaymentDone(true);
    setTimeout(mutateInvoicesAndDetails, 1000);
  }

  if (!lastInvoice) {
    return null;
  }

  return (
    <>
      <Modal size="xs" isOpen={isPaymentModalOpen} setIsOpen={setIsPaymentModalOpen}>
        <Container padding="vertical">
          <Container padding="bottom">
            <Heading variant="title-300" tag="h2">
              Betal faktura
            </Heading>
            <Heading tag="h3" variant="subsection-100" data-di-mask>
              Betal faktura for
              {` ${dayjs.unix(lastInvoice.invoice.invoiceDate).format("MMMM YYYY")}`}
            </Heading>
          </Container>
          <OnlinePayment
            amount={lastInvoice.invoice.amount}
            invoiceId={lastInvoice.invoice.invoiceId}
            financialAccountId={lastInvoice.financialAccountId}
            onPaymentDone={onPaymentDone}
          />
        </Container>
      </Modal>
      <RedirectPaymentAuthorisation onPaymentConfirmed={onPaymentDone} invoiceId={lastInvoice.invoice.invoiceId} />
    </>
  );
};
