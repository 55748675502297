import { Alert, Button, Container, useToast } from "@telia-no-min-side/components";
import { VasAdditionalProduct } from "./types";
import { CartSummary } from "./CartSummary";
import { useState } from "react";
import { getSimplePriceText, track, uri } from "@telia-no-min-side/utils";
import { CancelVASModal } from "./CancelVASModal";
import { useNavigate } from "react-router-dom";
import { Card, Flex } from "@telia/teddy";
import { ProductBadge } from "./ProductBadge";
import { ProductCardInfo } from "./ProductCardInfo";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { useMutation } from "@apollo/client";
import { graphql } from "gql";
import { isPurchased } from "./utils/product";
import { OrderOp } from "gql/graphql";
import { CheckoutContainer } from "./styles/Checkout.styled";
import { MOBILE_VAS, VAS_MESSAGE } from "util/constants/valueAddedServices";
import { getTransactionDataVAS } from "../utils/getTransactionDataVAS";
import { ServiceImage } from "./ActiveVas";

type Props = {
  product: VasAdditionalProduct;
  type: MOBILE_VAS;
  phoneNumber: string;
};

const OrderVASMutation = graphql(`
  mutation OrderVAS($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);

export function VASSummary({ product, type, phoneNumber }: Props) {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const navigate = useNavigate();
  const { pushTrackingEvent } = track.useEventTracking();
  const navigateBack = () => navigate(uri.minSideFixed("/tjenester"));
  const isActive = !!product?.presentation?.active;
  const [errorMessage, setErrorMessage] = useState<{ title: string; text: string } | undefined>();
  const { addToast } = useToast();
  const { subscribeToOrder, orders } = useOrderSubscription();
  const [orderVas, { loading }] = useMutation(OrderVASMutation, {
    onCompleted(data) {
      setErrorMessage(undefined);
      addToast({
        text: isPurchased(product) ? VAS_MESSAGE.CONFIRM_CANCELLATION_BY_EMAIL : VAS_MESSAGE.ORDER_COMPLETED,
        variant: "success",
      });
      product.code &&
        data?.order.orderId &&
        subscribeToOrder({
          id: data?.order.orderId,
          phoneNumber: phoneNumber,
          vasProduct: product.code,
          toastText: isPurchased(product) ? VAS_MESSAGE.SERVICE_CANCELLED : VAS_MESSAGE.SERVICE_ACTIVATED,
        });

      const transactionData = getTransactionDataVAS(product, data?.order.orderId);
      pushTrackingEvent(transactionData);
      navigateBack();
    },

    onError() {
      const generalError = {
        title: VAS_MESSAGE.CANNOT_COMPLETE_ORDER,
        text: VAS_MESSAGE.ERROR_OCCURED,
      };
      setErrorMessage(generalError);
    },
  });

  const orderVasProduct = () => {
    const orderInput = {
      productName: product.code,
      operation: isPurchased(product) ? OrderOp.RemoveAdditionalProduct : OrderOp.AddAdditionalProduct,
    };

    orderVas({ variables: { input: orderInput, phoneNumber: phoneNumber } });
  };

  const inOrder = orders.some((order) => order.vasProduct === product.code && order.phoneNumber === phoneNumber);

  return (
    <CheckoutContainer>
      <Flex width="100%" maxWidth="400px" direction="column" gap="gutter-lg">
        <Card
          layout="product"
          variant="white"
          justify="stretch"
          shadow
          bordered
          data-tracking-id={`additional-services-${type}-summary`}
        >
          <Card.Slot>
            <ProductBadge product={product} showFullText />
          </Card.Slot>
          <Flex justify="center" mt="gutter-sm" mb="gutter-sm" width="100%">
            <ServiceImage type={product.productGroup} />
          </Flex>
          <Card.Heading as="h2">{product?.shortName}</Card.Heading>
          <Card.Content mt="200">
            <ProductCardInfo productType={type} isActive={isActive} />
          </Card.Content>
          <Card.Price
            priceText={getSimplePriceText(product?.price?.priceWithoutDiscount ?? 0, "md.")}
            variant="purple"
          />
        </Card>
        {isActive && !inOrder && (
          <div>
            <Button
              variant="secondary"
              onClick={() => {
                setShowCancelModal(true);
              }}
              disabled={loading}
              data-tracking-id={`additional-services-${type}-cancel-button`}
            >
              Kanseller
            </Button>
          </div>
        )}
      </Flex>

      {!isActive && (
        <Container maxWidth="xs" flexDirection="column">
          <Alert
            icon="alert"
            kind="negative"
            title={errorMessage?.title}
            text={errorMessage?.text}
            isOpen={!!errorMessage?.title}
          />
          {!inOrder && (
            <CartSummary
              selectedProduct={product}
              loading={loading}
              onCancel={() => navigateBack()}
              onOrder={orderVasProduct}
            />
          )}
        </Container>
      )}
      <CancelVASModal
        loading={loading}
        isOpen={showCancelModal}
        setIsModalOpen={setShowCancelModal}
        onCancelProduct={orderVasProduct}
        type={type}
      />
    </CheckoutContainer>
  );
}
