import { useEffect, useState } from "react";
import { useAuth } from "@telia-no-min-side/components";
import { purposesData } from "./data/gdprPurposes";
import { useGetPartyState } from "./hooks/useGetPartyState";
import { useObjection } from "./hooks/useObjection";
import { useToast } from "@telia-no-min-side/components";
import { GdprRequest } from "src/gql/graphql";
import { Button, Flex, Heading, Notification, Text, TextSpacing, Toggle } from "@telia/teddy";

export const GDPRForm = () => {
  const { addToast } = useToast();
  const { userInfo } = useAuth();
  const { data, loading, error } = useGetPartyState();

  const [touched, setTouched] = useState(false);
  const [selectedObjections, setSelectedObjections] = useState<string[]>([]);

  const { sendGDPRRequest, loading: loadingObjections } = useObjection();

  const isLoading = loading || loadingObjections;

  const activeObjections = data?.gdprPartyState?.reduce((previous: string[], current) => {
    if (current?.context === "Objected" && current.purposeCode) {
      return [...previous, current.purposeCode];
    }
    return [...previous];
  }, []);

  useEffect(() => {
    setSelectedObjections(activeObjections || []);
  }, [data, loading]);

  const isSelected = (purposeCode: string) => {
    return selectedObjections?.some((selectedObjection) => selectedObjection === purposeCode);
  };

  const handleChange = (purposeCode: string) => {
    setTouched(true);

    if (isSelected(purposeCode)) {
      setSelectedObjections((prev) => prev.filter((objection) => objection !== purposeCode));
    } else {
      setSelectedObjections((prev) => [...prev, purposeCode]);
    }
  };

  const objected = selectedObjections.filter((purposeCode) => !activeObjections?.includes(purposeCode));
  const objectionRevoked = activeObjections?.filter((purposeCode) => !selectedObjections.includes(purposeCode));

  const isChanged = !!(objected.length || objectionRevoked?.length);

  const handleSave = () => {
    if (!touched && isChanged) {
      return;
    }

    sendGDPRRequest({
      variables: {
        input: {
          phoneNumber: "",
          name: "",
          email: "",
          pid: userInfo?.no_person_id || "",
          operation: GdprRequest.ObjectionChange,
          operationPayload: {
            objected,
            objectionRevoked,
          },
        },
      },
      onCompleted: () => {
        addToast({
          text: "Innstillingene er oppdatert!",
          variant: "success",
        });
        setTouched(false);
      },
      onError: () => {
        addToast({
          text: "En feil oppsto. Prøv igjen om noen minutter, eller ta kontakt med kundesenteret.",
          variant: "error",
        });
        setSelectedObjections(activeObjections || []);
        setTouched(false);
      },
    });
  };

  const handleCancel = () => {
    setTouched(false);
    setSelectedObjections(activeObjections || []);
  };

  const isDisabled = isLoading || !data || !!error;

  return (
    <Flex direction="column" gap="400" data-tracking-id={loading ? "gdpr-form-loading" : "gdpr-form"}>
      {error && (
        <Notification variant="error">
          <Notification.Heading as="h3">Vi klarte ikke å laste inn dine innstillinger</Notification.Heading>
          <Notification.Text>
            Vi kunne ikke laste inn dine innstillinger for øyeblikket. Prøv igjen om noen minutter. Hvis problemet
            vedvarer, anbefaler vi deg å kontakte kundeservicen vår på tlf: 924 05 050
          </Notification.Text>
        </Notification>
      )}
      {purposesData.map((purposeItem) => (
        <Flex direction="column" gap="150" key={purposeItem.title}>
          <TextSpacing>
            <Heading as="h2" variant="subsection-100">
              {purposeItem.title}
            </Heading>
            <Text>{purposeItem.description}</Text>
            {purposeItem.groupLabel && (
              <Text>
                <strong>Jeg ønsker ikke at Telia bruker følgende kanaler til markedsføring:</strong>
              </Text>
            )}
          </TextSpacing>
          {purposeItem.purposes.map((purpose) => (
            <Toggle key={purpose.id}>
              <Toggle.Input
                checked={isSelected(purpose.id)}
                onCheckedChange={() => handleChange(purpose.id)}
                disabled={isDisabled}
                style={{ flexShrink: "0" }} // Needs to be fixed in Teddy
              />
              <Toggle.Label>{purpose.label}</Toggle.Label>
            </Toggle>
          ))}
        </Flex>
      ))}
      {touched && isChanged && (
        <Flex gap="100">
          <Button size="sm" variant="secondary" onClick={handleCancel} loading={isLoading}>
            Avbryt
          </Button>
          <Button size="sm" onClick={handleSave} loading={isLoading}>
            Lagre
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
