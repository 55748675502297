import { Link } from "react-router-dom";
import { Card, Text, Badge, Icon, Flex, IconName } from "@telia/teddy";
import { AdditionalServiceSummary } from "../types";
import { useMobileAccount } from "hooks/useMobileAccount";

type Props = {
  service: AdditionalServiceSummary;
  checkoutUrl: string;
  description: string;
  icon: IconName;
};

export function AdditionalServiceCard({ service, checkoutUrl, description, icon }: Props) {
  const { setPhoneNumber } = useMobileAccount();

  return (
    <Card
      shadow
      bordered
      variant="white"
      layout="default"
      width="100%"
      maxWidth="400px"
      data-tracking-id={`additional-service-${service.productGroup}-${service.phoneNumber}`}
      onClick={() => setPhoneNumber(service.phoneNumber)}
    >
      <Card.Slot align="top-right">
        <Badge variant={service.isPurchased ? "success" : "special"} hideIcon>
          <Icon name={service.isPurchased ? "check-circle" : "suitcase"} />
          {service.isPurchased ? "Aktivert" : "Betales av arbeidsgiver"}
        </Badge>
      </Card.Slot>

      <Flex direction="row" gap="150">
        <Icon name={icon} size="md" style={{ alignSelf: "flex-start" }} />

        <Flex direction="column" width="100%">
          <Card.Heading as="h3" variant="title-100">
            {service.productName}
          </Card.Heading>

          <Card.Content>
            <Flex direction="column" gap="150">
              <Text>{description}</Text>

              <Card variant="gray" p="100">
                <Text variant="additional-100">
                  Gjelder for {service.userName} ({service.phoneNumber})
                </Text>
              </Card>
            </Flex>
          </Card.Content>
        </Flex>
      </Flex>

      <Card.Footer height="100%" align="end">
        <Card.Button variant="text" asChild>
          <Link to={checkoutUrl}>
            {service.isPurchased ? "Se mer" : "Bestill nå"}
            <Icon name="arrow-right" />
          </Link>
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
