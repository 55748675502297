import { Outlet } from "react-router-dom";
import { Flex } from "@telia/teddy";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { MobileSubscriptionSuspended } from "../mobile-subscription-suspended";
import { getMobileSubscriptions } from "./utils/getMobileSubscriptions";
import { BillingType, SubscriptionStatus } from "src/gql/graphql";
import { NoSubscriptionsFound } from "../../../components/profile-pickers/components/NoSubscriptionsFound";
import { MobileTopMenu } from "components/top-menu/MobileTopMenu";
import { TopMenuSkeleton } from "components/top-menu/TopMenuSkeleton";
import { MobileSubscriptionReserved } from "../mobile-subscription-reserved";
import { NotSupportedSubscription } from "pages/mobile/mobile-subscription-dashboard/modules";
import { PageContainer } from "components/PageContainer";

export function MobileSubscriptionDashboardLayout() {
  const { isLoading, allAccounts, phoneNumber } = useMobileAccount();
  if (isLoading) {
    return <TopMenuSkeleton />;
  }

  // TODO: when no accounts exists, show smth like "buy subscription"?
  if (!allAccounts) return null;

  const mobileSubscriptions = getMobileSubscriptions(allAccounts);
  const subscription = mobileSubscriptions.find((sub) => sub?.phoneNumber?.localNumber === phoneNumber);

  const subscriptionStatus = subscription?.subscriptionStatus;
  const subscriptionName = subscription?.userOffering?.shortName;
  const monthlyCost = subscription?.userOffering?.monthlyCost?.amount;
  const isPrepaid = subscription?.billingType === BillingType.Prepaid;

  const isSubscriptionReserved = subscriptionStatus === SubscriptionStatus.Reserved;
  const isSubscriptionSuspended = subscriptionStatus === SubscriptionStatus.Suspended && !!subscription?.productUser;

  const notSupportedSubscriptions = ["Telia Hjemme Mini"];
  const isNotSupportedSubscription = !!subscriptionName && notSupportedSubscriptions.includes(subscriptionName);

  if (mobileSubscriptions?.length === 0) return <NoSubscriptionsFound isMobile />;

  return (
    <>
      <MobileTopMenu subscriptions={mobileSubscriptions || []} />

      <div id="tabpanel-for-picker" role="tabpanel" aria-labelledby={`tab-${phoneNumber}`} tabIndex={0}>
        {isSubscriptionSuspended && <MobileSubscriptionSuspended subscription={subscription} />}
        {isSubscriptionReserved && <MobileSubscriptionReserved />}
        {isNotSupportedSubscription && (
          <Flex p="400" pt="0">
            <NotSupportedSubscription name={subscriptionName} price={monthlyCost} />
          </Flex>
        )}

        {!isLoading && !isSubscriptionSuspended && !isSubscriptionReserved && !isNotSupportedSubscription && (
          <>
            <PageContainer>
              <Outlet context={{ isPrepaid }} />
            </PageContainer>
          </>
        )}
      </div>
    </>
  );
}
