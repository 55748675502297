import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { GeneralSWRResponse, GetCustomerSpecificUrlArgs, ReturnData, getCustomerSpecificUrl } from "./utils";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchProfiles<T extends GetCustomerSpecificUrlArgs | undefined = undefined>(
  args?: T
): GeneralSWRResponse<T, types.fixed.Profiles> {
  const { error, ...rest } = useAxiosSWR<ReturnData<T, types.fixed.Profiles>>(
    getCustomerSpecificUrl(ICX_REQUEST.URL_GET_ALL_PROFILE, args)
  );

  return {
    isError: !!error,
    error: error,
    ...rest,
  };
}
