import React from "react";
import PropTypes from "prop-types";
import { ipv4 } from "../../../../util/PropTypes";
import { validateUncommitedStaticInternalIPEntry } from "../../../../util/validation";
import { homeCentralUtilities } from "../../../../util/HomeCentralUtilities";
import { StaticInternalIPEntryEditable } from "./StaticInternalIPEntryEditable";

const { object, func, array } = PropTypes;

const allFieldsOfEntryArePopulated = (entry) => {
  return entry.Alias !== "" && entry.MacAddress !== "" && Object.values(entry.IPAddress).every((val) => val !== "");
};

class NewStaticInternalIPEntry extends React.Component {
  static propTypes = {
    onChange: func,
    closePrompt: func,
    mask: ipv4,
    homeCentral: object,
    saveNewRuleToHomeCentral: func,
    errorMessages: array,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      entry: homeCentralUtilities.createEmtpyStaticInternalEntry(this.props.homeCentral),
      errorMessages: [],
    };
  }

  validateEntry = () => {
    if (allFieldsOfEntryArePopulated(this.state.entry)) {
      const errorMessages = validateUncommitedStaticInternalIPEntry(this.state.entry, this.props.homeCentral);

      this.setState({
        errorMessages,
      });
    }
  };

  updateEntry = (value, param) => {
    const newEntry = homeCentralUtilities.updateParamInStaticInternalIPEntry(this.state.entry, value, param);

    if (this.state.errorMessages.length > 0 && allFieldsOfEntryArePopulated(newEntry)) {
      this.setState({
        entry: newEntry,
        errorMessages: validateUncommitedStaticInternalIPEntry(newEntry, this.props.homeCentral),
      });
    } else {
      this.setState({
        entry: newEntry,
      });
    }
  };

  saveNewRuleToHomeCentral = () => {
    const errorMessages = validateUncommitedStaticInternalIPEntry(this.state.entry, this.props.homeCentral);

    if (errorMessages.length === 0) {
      this.props.saveNewRuleToHomeCentral(this.state.entry);
      window.setTimeout(() => {
        this.props.closePrompt();
      }, 1000);
    } else {
      this.setState({
        errorMessages,
      });
    }
  };

  render() {
    const { closePrompt, mask } = this.props;

    return (
      <StaticInternalIPEntryEditable
        internalIpEntry={this.state.entry}
        onDelete={closePrompt}
        mask={mask}
        errorMessages={this.state.errorMessages}
        onChange={this.updateEntry}
        validate={this.validateEntry}
        onSave={this.saveNewRuleToHomeCentral}
        onClose={closePrompt}
      />
    );
  }
}

export default NewStaticInternalIPEntry;
