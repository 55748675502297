import { Icon, IconProps } from "@telia-no-min-side/components";
import { CrossIcon, FlexIconWrapper } from "../styles/style";

type FlexIconProps = {
  showCross?: boolean;
} & IconProps;

export function FlexIcon({ showCross, ...props }: FlexIconProps) {
  return (
    <FlexIconWrapper>
      <Icon {...props} />
      {showCross && <CrossIcon icon="close" />}
    </FlexIconWrapper>
  );
}
