import { AdditionalProduct } from "gql/graphql";

export const getPostPurchaseInsurances = (
  products: AdditionalProduct[],
  postPurchaseDummyProduct: AdditionalProduct
) => {
  const { characteristic } = postPurchaseDummyProduct;
  const deviceConfig = characteristic?.find((config) => config?.featureCode === "F-IMEI");
  const insuranceClass = deviceConfig?.config?.find((item) => item?.name === "INSURANCE_CLASS")?.value ?? "";

  return products.filter(
    (item) =>
      item?.additionalProperties?.some((prop) => prop?.name === "INSURANCE_CLASS" && prop?.value === insuranceClass)
  );
};
