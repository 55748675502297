import React, { Fragment } from "react";
import { useMainLayoutNavigation } from "../../hooks/useMainLayoutNavigation";
import { MenuLinkId } from "../utils/links";
import { SideMenuLink } from "./SideMenuLink";
import { SideMenuLinkGroup } from "./SideMenuLinkGroup";
import { useSideMenu } from ".";

export type Options = {
  navigateTo: string;
  isInternalLink: boolean;
  isMenuGroupItemClick?: boolean;
};

export function SideMenuItems() {
  const {
    menuLinks,
    openMenuGroup,
    isDeviceWithCollapseSupport,
    isDeviceWithSlideInSupport,
    setOpenMenuGroup,
    setSlideInSideMenu,
    setIsCollapsed,
  } = useSideMenu();

  const mainLayoutNavigation = useMainLayoutNavigation();

  function onMenuGroupClick(menuGroupId: string) {
    const isCurrentOpenMenuGroupClick = menuGroupId === openMenuGroup;

    setOpenMenuGroup(isCurrentOpenMenuGroupClick ? "" : menuGroupId);
    if (!isDeviceWithCollapseSupport) return;
    setIsCollapsed(isCurrentOpenMenuGroupClick);
  }

  function onMenuItemClick(activeId: MenuLinkId, options: Options) {
    const { navigateTo, isInternalLink, isMenuGroupItemClick = false } = options;

    if (isMenuGroupItemClick) {
      setOpenMenuGroup("");
    }
    if (isDeviceWithCollapseSupport) {
      setIsCollapsed(true);
    }
    if (isDeviceWithSlideInSupport) {
      setSlideInSideMenu(false);
    }

    mainLayoutNavigation.setClickedMenuId(activeId);
    mainLayoutNavigation.push(navigateTo, isInternalLink);
  }
  return (
    <>
      {menuLinks.map((link) => (
        <Fragment key={link.id}>
          {link.type === "MenuLinkGroup" && (
            <SideMenuLinkGroup
              activeMenuId={mainLayoutNavigation.activeMenuId}
              menuLinkGroup={link}
              onMenuGroupClick={onMenuGroupClick}
              onMenuItemClick={onMenuItemClick}
            />
          )}
          {link.type === "MenuLink" && (
            <SideMenuLink
              menuLink={link}
              onMenuItemClick={onMenuItemClick}
              dataTrackingId={`side-menu-item-${link.id}`}
              disabled={link.limitedAccessView}
            />
          )}
        </Fragment>
      ))}
    </>
  );
}
