import { Button, Modal, ModalBody, ModalFooter, ModalHeading } from "@telia-no-min-side/components";
import { MOBILE_VAS } from "util/constants/valueAddedServices";

type Props = {
  loading: boolean;
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  onCancelProduct: () => void;
  type: MOBILE_VAS;
};

export const CancelVASModal = ({ isOpen, loading, setIsModalOpen, onCancelProduct, type }: Props) => {
  const getName = () => {
    if (type === MOBILE_VAS.STORYTEL) return "Storytel";
    if (type === MOBILE_VAS.HBO) return "Max";
  };

  return (
    <Modal
      setIsOpen={setIsModalOpen}
      isOpen={isOpen}
      size="xs"
      dataTrackingID="additional-services-telia-trygg-cancel-modal"
    >
      <ModalHeading>Vil du kansellere {getName()}</ModalHeading>

      <ModalBody>
        <p>Tjenesten kommer til å bli deaktivert umiddelbart.</p>
        <p>{getName()} fjernes fra dine månedlige kostnader fra neste faktura.</p>
      </ModalBody>
      <ModalFooter flex>
        <Button size="sm" variant="tertiary-black" disabled={loading} onClick={() => setIsModalOpen(false)}>
          Avbryt
        </Button>

        <Button isLoading={loading} loadingText="Vent litt" size="sm" onClick={onCancelProduct}>
          Kanseller
        </Button>
      </ModalFooter>
    </Modal>
  );
};
