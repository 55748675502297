export enum MOBILE_VAS {
  TRYGG = "TRYGG",
  SKY = "SKY",
  HBO = "HBO",
  STORYTEL = "STORYTEL",
}

export enum VAS_ERROR_CODE {
  USED_BY_SIS = "Email is already used in SIS",
  TELIA_SKY_ALREADY_ACTIVE = "Product user has active Telia SKY",
}

export enum VAS_MESSAGE {
  IS_ACTIVATED = "er aktivert.",
  IS_CANCELED = "er kansellert.",
  INVALID_EMAIL = "E-postadressen er ugyldig",
  CANNOT_COMPLETE_ORDER = "Vi kan dessverre ikke fullføre din ordre",
  ERROR_OCCURED = "En feil oppstod. Prøv igjen om noen minutter, eller ta kontakt med kundesenteret.",
  SERVICE_ACTIVATED = "Din tjeneste er nå aktivert",
  SERVICE_CANCELLED = "Din tjeneste er nå kansellert",
  CONFIRM_CANCELLATION_BY_EMAIL = "Du vil motta en bekreftelse på kanselleringen på mail.",
  ORDER_COMPLETED = "Takk for din bestilling! Du vil motta et velkomstbrev på mail med nødvendig informasjon.",
  CANNOT_PROCCESS_ORDER = "Vi kunne ikke utføre bestilling",
  EMAIL_ALREADY_IN_USE = "E-postadressen er allerede registert som bruker på denne tjenesten.",
  TERMINATE_SERVICE = "Tjenesten avsluttes umiddelbart.",
  CHANGE_DELAY = "Det kan ta opp til 5 minutter før du ser endringen her.",
  SOMETHING_WENT_WRONG = "Noe gikk galt!",
  UNKNOWN_ERROR = "En ukjent feil oppsto. Vennligst prøv igjen senere.",
  REQUEST_PROCESSING_FAILURE_MESSAGE = "Vi kunne dessverre ikke behandle forespørselen din og fikk heller ikke opprettet en manuell sak for oppfølging. Vennligst prøv igjen, eller kontakt vår kundeservice.",
  TRYGG_UPGRADE = "Du har oppgradert til Telia Trygg Familie",
  TRYGG_DOWNGRADE = "Du har byttet fra Telia Trygg Familie til Telia Trygg",
  TELIA_SKY_ALREADY_ACTIVE = "Telia Sky allerede aktivert",
}

export enum TELIA_SKY_PRODUCT_TYPE {
  FREE = "SKYXCH01",
  PAID_WITH_ONE_MONTH_DISCOUNT = "SKYCH01+SKYBNG01",
  PAID_WITH_THREE_MONTHS_DISCOUNT = "SKYCH01+SKYBNG03",
  PAID_WITH_SIX_MONTHS_DISCOUNT = "SKYCH01+SKYBNG06",
  B2B_COMPANY_PAID_FOR_SELECTED_EMPLOYEES = "SKYBCH01",
  B2B_COMPANY_PAID_FOR_ALL_EMPLOYEES_ACTIVE = "SKYBCH01_EXISTING",
  B2B_COMPANY_PAID_FOR_ALL_EMPLOYEES = "SKYBCH01_CONSENT",
}

export enum TELIA_TRYGG_PRODUCT_TYPE {
  B2B_COMPANY_PAID_FOR_SELECTED_EMPLOYEES = "TRYBCH01",
  B2B_COMPANY_PAID_FOR_ALL_EMPLOYEES_ACTIVE = "TRYBCH01_EXISTING",
  B2B_COMPANY_PAID_FOR_ALL_EMPLOYEES = "TRYBCH01_CONSENT",
}

// _CONSENT is for inactive B2B product and _EXISTING ir for activated.
// Backend will return either _CONSENT or _EXISTING for each product type,
// both codes at the same time cannot be returned for the same subscription.
export const B2B_VAS_PRODUCT_CODES = ["SKYBCH01_CONSENT", "TRYBCH01_CONSENT", "SKYBCH01_EXISTING", "TRYBCH01_EXISTING"];
