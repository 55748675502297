import { graphql } from "src/gql";

export const OFFER_FOR_SALE_FRAGMENT = graphql(`
  fragment OfferForSale on Offering {
    shortName
    pricePlan
    weight
    commitmentTimeInMonths
    name
    longDescription
    shortDescription
    shortName
    originalPrice {
      amount
      code
      monetaryUnit
    }
    price {
      offeringPrices {
        code
        amount
        monetaryUnit
      }
    }
    monthlyCost {
      code
      amount
      reasons {
        code
        amount
      }
    }
    includedProducts {
      code
      name
      productTerms {
        code
        description
        unit
        value
      }
    }
    additionalProperties {
      name
      value
    }
    disclaimers {
      code
      name
      text
      weight
    }
  }
`);
