import React from "react";
import { Button } from "@telia/styleguide";
import { ErrorPageContainer } from "../../modules/ErrorPageContainer";

export const ErrorCodeResolver = ({ code, onRetry }) => {
  switch (code) {
    case 409:
      return (
        <ErrorPageContainer>
          <div style={{ margin: "2rem 0" }} />
          Vi oppdaterer en av enhetene dine, mens dette skjer kan vi dessverre ikke hente innstillingene dine.
          <br /> Etter en liten stund kan du prøve å laste inn siden på nytt igjen, så vil du kunne se de oppdaterte
          innstillingene dine.
        </ErrorPageContainer>
      );
    case 404:
      return (
        <ErrorPageContainer>
          <div style={{ margin: "2rem 0" }} />
          Vi finner dessverre ingen trådløse innstillinger tilknyttet ditt kundeforhold.
        </ErrorPageContainer>
      );
    case 403:
      return (
        <ErrorPageContainer>
          <div style={{ margin: "2rem 0" }} />
          Det ser ikke ut som vi er helt klare til å vise deg dine innstillinger på dette tidspunktet, de kommer snart!
        </ErrorPageContainer>
      );
    case 500:
    case 504:
    default:
      return (
        <ErrorPageContainer>
          <div className="font-size-24">Noe gikk galt! Du kan prøve å laste denne siden på nytt.</div>
          <div style={{ margin: "2rem 0" }} />

          <Button size={"small"} text={" Last på nytt"} onClick={onRetry} />
        </ErrorPageContainer>
      );
  }
};
