import React from "react";
import styled from "styled-components";
import { CheckMarkCircleIcon, InfoIcon } from "@telia/styleguide";
import { FixedPaymentResponse } from "./types/PaymentResponse";
import { formatAmountToNorwegianStandard, SUCCESSFUL_TRANSACTION_STATUS } from "./utils/PaymentUtils";

type Props = {
  response: FixedPaymentResponse | null;
  paymentError: boolean;
};

export function PaymentDone(props: Props): React.ReactElement | null {
  const { paymentError, response } = props;
  if (!response && !paymentError) {
    return null;
  }

  const transactionSucceeded = SUCCESSFUL_TRANSACTION_STATUS === props.response?.status;

  if (transactionSucceeded && response && !paymentError) {
    return (
      <InformationContainer>
        <IconContainer>
          <CheckMarkCircleIcon />
        </IconContainer>
        <TextContainer>
          <InformationHeading>Din nettbetaling er registrert</InformationHeading>
          <p data-di-mask>
            Din nettbetaling på {formatAmountToNorwegianStandard(response.amount)},- er registrert. Vær oppmerksom på at
            det kan ta noe tid før saldoen din blir oppdatert på Min Side.
          </p>
        </TextContainer>
      </InformationContainer>
    );
  }

  return (
    <InformationContainer>
      <IconContainer>
        <InfoIcon />
      </IconContainer>
      <TextContainer>
        <InformationHeading>Betalingen din gikk dessverre ikke gjennom</InformationHeading>
        <p>Last inn siden på nytt og vennligst prøv igjen.</p>
      </TextContainer>
    </InformationContainer>
  );
}

const InformationContainer = styled.div`
  background-color: #f2f2f2;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  padding: 2rem;
`;

const InformationHeading = styled.h2`
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`;

const IconContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
  margin-right: 1rem;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
