import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { sizes, zIndex } from "../utils/variables";
import { devices } from "../utils/breakpoints";

export const MainLayoutContainer = styled.div`
  --minside-top-menu-height: 60px;
  --minside-content-top-margin: 60px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
  z-index: 0; // This is to make sure that the modal/portal is always on top of the main layout

  @media ${devices.sm_down} {
    --minside-top-menu-height: 50px;
  }
`;

export const MenuAndContent = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: ${sizes.sideMenu.desktop}px auto;
  @media ${devices.md_down} {
    grid-template-columns: ${sizes.sideMenu.tablet}px auto;
  }
  @media ${devices.sm_down} {
    grid-template-columns: 0px auto;
  }
`;

export const Content = styled.div<{ hideMinsideButton: boolean }>`
  min-height: 300px;
  overflow-x: hidden;
  ${(props) => {
    return props.hideMinsideButton
      ? css`
          @media ${devices.sm_down} {
            margin-top: var(--minside-content-top-margin);
          }
        `
      : css`
          margin-top: var(--minside-content-top-margin);
        `;
  }}
`;

export const Zindex = styled.div<{ zIndex: number }>`
  z-index: ${(props) => props.zIndex};
  position: relative;
  margin-top: auto;
`;

export const TopMenu = styled.div`
  position: fixed;
  right: 0;
  height: var(--minside-top-menu-height);
  z-index: ${zIndex.topMenu};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 12.5px 20px 10px;
  @media ${devices.sm_down} {
    width: 100vw;
    padding: 5px 5rem 5px 20px;
    background-color: ${colors.grey100};
    justify-content: space-between;
  }
  @media print {
    display: none;
  }
`;

export const SideMenuButton = styled.div`
  position: fixed;
  top: 5px;
  right: 20px;
  z-index: ${zIndex.sideMenuBackDrop};
`;
