import dayjs from "dayjs";
import { Container, Paragraph } from "@telia-no-min-side/components";
import { SwitchAgreementFieldsFragment } from "gql/graphql";

export type AgreementInfoProps = Pick<
  SwitchAgreementFieldsFragment,
  "pricePerInstallment" | "remainingCost" | "totalCost" | "effectiveDate" | "expirationDate"
>;

export function AgreementInfo({
  pricePerInstallment,
  remainingCost,
  totalCost,
  effectiveDate,
  expirationDate,
}: AgreementInfoProps) {
  return (
    <Container flexDirection="row" gap data-tracking-id="agreement-switch-card-info">
      <Container flexDirection="column" gap="lg">
        <Container>
          <Paragraph isFaded removeMargin>
            Månedspris
          </Paragraph>
          <Paragraph data-di-mask removeMargin>
            {pricePerInstallment.value},-
          </Paragraph>
        </Container>
        {remainingCost && (
          <Container>
            <Paragraph isFaded removeMargin>
              Gjenstående å betale
            </Paragraph>
            <Paragraph data-di-mask removeMargin>
              {remainingCost.value},-
            </Paragraph>
          </Container>
        )}

        {!!totalCost && (
          <Container>
            <Paragraph isFaded removeMargin>
              Totalpris
            </Paragraph>
            <Paragraph data-di-mask removeMargin>
              {totalCost.value},-
            </Paragraph>
          </Container>
        )}
      </Container>
      <Container flexDirection="column" gap="lg">
        <Container>
          <Paragraph isFaded removeMargin>
            Avtale startet
          </Paragraph>
          <Paragraph data-di-mask removeMargin>
            {dayjs(effectiveDate).format("DD.MM.YYYY")}
          </Paragraph>
        </Container>
        <Container>
          <Paragraph isFaded removeMargin>
            Telefonen er ferdig nedbetalt
          </Paragraph>
          <Paragraph data-di-mask removeMargin>
            {dayjs(expirationDate).format("DD.MM.YYYY")}
          </Paragraph>
        </Container>
      </Container>
    </Container>
  );
}
