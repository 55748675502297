import { useState } from "react";
import { axiosPost } from "@telia-no-min-side/components";
import { types, uri } from "@telia-no-min-side/utils";
import { datadogRum } from "@datadog/browser-rum";

type PlaceOrderArgs = {
  customerId: string | undefined;
  order: types.fixed.FlexSubmitArgs;
};

export type SubmitFlexDataResponse = {
  isSubmitting: boolean;
  placeOrder: ({ customerId, order }: PlaceOrderArgs) => Promise<void>;
  receipt: types.fixed.FlexSubmitResponse["receipt"] | undefined;
};

export function useSubmitFlex(): SubmitFlexDataResponse {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [receipt, setReceipt] = useState<types.fixed.FlexSubmitResponse["receipt"]>();
  async function placeOrder({ customerId, order }: PlaceOrderArgs) {
    if (!customerId)
      return setReceipt({
        title: "Noe gikk galt!",
        texts: [],
        showAngrerett: false,
        showForhandler: false,
        showCustomerService: true,
      });
    setIsSubmitting(true);
    try {
      datadogRum.addAction("orderFlexAgreement");
      const response = await axiosPost<types.fixed.FlexSubmitResponse, types.fixed.FlexSubmitArgs>(
        uri.purchase(`/api/flex/submit/${customerId}`),
        order
      );
      setReceipt(response.receipt);
    } catch (error) {
      datadogRum.addError(error, { feature: "flexAgreement" });
      setReceipt({
        title: "Noe gikk galt!",
        texts: [],
        showAngrerett: false,
        showForhandler: false,
        showCustomerService: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  }
  return { isSubmitting, placeOrder, receipt };
}
