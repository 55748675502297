import { types } from "@telia-no-min-side/utils";
import { formatPriceForRemoteController } from "./utils";
import { Box, breakpoint, Flex, Text } from "@telia/teddy";
import { ShoppingCard } from "components/shopping-card";

type Props = {
  remoteControlData?: types.fixed.ProductGroupSingleProductWithShipping;
  boxName?: string;
  isLoading: boolean;
  isError: boolean;
};

export function ProductCard(props: Props): JSX.Element {
  const { remoteControlData, isLoading, isError } = props;

  if (isError) {
    return (
      <Box pb="gutter-sm" data-test-id="remote-control-order-product-card-error">
        <Text>En feil har oppstått ved lasting av innholdet! Vennligst last inn siden på nytt og prøv igjen.</Text>
      </Box>
    );
  }

  if (!remoteControlData || isLoading) {
    return (
      <Flex maxWidth={breakpoint.teddyBreakpointMd} id="remote-control-order-skeleton">
        <ShoppingCard.Skeleton hasTitle />
      </Flex>
    );
  }

  return (
    <ShoppingCard
      title={`Fjernkontroll til din ${props.boxName ?? "TV-boks"}`}
      price={formatPriceForRemoteController(remoteControlData.price)}
      isSelected
      selectedBadgeText="Valgt"
      dataTrackingID="remote-control-order-product-card"
    />
  );
}
