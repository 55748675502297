import { Dispatch, SetStateAction } from "react";
import { graphql } from "src/gql";
import { getAddress } from "@telia-no-min-side/utils";
import { useQuery } from "@apollo/client";
import { ExtendedFixedProductType } from "../../Outages";

const GET_OPERATING_MESSAGES = graphql(`
  query GetOperatingMessages($postCode: String!, $streetName: String!, $streetNumberAndLetter: String!) {
    operatingMessages(
      input: { postCode: $postCode, streetName: $streetName, streetNumberAndLetter: $streetNumberAndLetter }
    ) {
      id
      title
      message
      isNationWide
      type
      relatedPlaces {
        streetName
        postCode
        city
        country
      }
    }
  }
`);

type Props = {
  postCode: string;
  street: string;
  setOutagesList: Dispatch<SetStateAction<ExtendedFixedProductType>>;
};

export function OutageFetcher({ postCode, street, setOutagesList }: Props) {
  const addressInfo = getAddress(street);

  useQuery(GET_OPERATING_MESSAGES, {
    variables: {
      postCode,
      streetName: addressInfo.streetName,
      streetNumberAndLetter: addressInfo.streetNumberAndLetter,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const hasOutage = data?.operatingMessages?.length > 0;
      setOutagesList((prevState) =>
        [...prevState].map((outage) => (outage.postalCode === postCode ? { ...outage, hasOutage } : outage))
      );
    },
  });

  return null;
}
