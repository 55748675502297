import { ProductBoxProps } from "@telia-no-min-side/components";
import { types } from "@telia-no-min-side/utils";
import { BadgeProps } from "@telia/teddy";

function getProductBadgeStatus(
  status: Exclude<types.fixed.Product["status"], undefined>
): Exclude<ProductBoxProps["badge"], undefined>["status"] {
  if (status.waitingActivation) return "warning";
  if (status.suspended) return "warning";
  if (status.title === "Abuse") return "warning";
  if (status.active) return "positive";
  return "neutral";
}

export function getProductBadge(product?: types.fixed.Product): ProductBoxProps["badge"] {
  if (!product?.status) return;

  const badgeStatus = getProductBadgeStatus(product.status);
  return { label: product.status.localizedtitle, status: badgeStatus };
}

export function getBadgeVariant(status: Exclude<types.fixed.Product["status"], undefined>): BadgeProps["variant"] {
  if (status.waitingActivation) return "warning";
  if (status.suspended) return "warning";
  if (status.title === "Abuse") return "warning";
  if (status.active) return "success";
  return "neutral";
}

export function getDeviceBadge(product?: types.fixed.Product) {
  if (!product?.status) return;
  const badgeStatus = getBadgeVariant(product.status);
  return { label: product.status.localizedtitle, status: badgeStatus };
}
