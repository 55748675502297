import { Card, Container, List, ListItem, Paragraph, Skeleton } from "@telia-no-min-side/components";
import styled from "styled-components";
import { format } from "@telia-no-min-side/utils";
import { useFetchUser } from "src/api/fixed";

export function UserInfo() {
  const user = useFetchUser();
  const userInfo = user.data?.identity;

  if (!user.isLoading && !userInfo) {
    return null;
  }

  return (
    <Container alignItems="flex-start" padding="vertical" data-tracking-id="profile-user-info">
      <div>
        <Card backgroundColor="blue" showShadow={false}>
          <Paragraph>
            <em>Disse innstillingene gjelder for:</em>
          </Paragraph>
          {user.isLoading && !userInfo && (
            <div data-tracking-id="profile-user-info-loading">
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          )}
          {userInfo && (
            <List>
              {userInfo.fullname && (
                <IconListItem center icon="user" data-di-mask>
                  {format.startCase(userInfo.fullname)}
                </IconListItem>
              )}
              {userInfo.email && (
                <IconListItem center icon="email" data-di-mask>
                  {userInfo.email}
                </IconListItem>
              )}
              {userInfo.phoneNumber && (
                <IconListItem center icon="smartphone" data-di-mask>
                  {userInfo.phoneNumber}
                </IconListItem>
              )}
            </List>
          )}
        </Card>
      </div>
    </Container>
  );
}

export const IconListItem = styled(ListItem)`
  font-size: 1rem;
`;
