import { useRef, useState, useContext, createContext } from "react";
import type { Dispatch, SetStateAction, ReactNode, RefObject } from "react";
import { types, track } from "@telia-no-min-side/utils";
import { useFetchFlexCurrent, useFetchFlexPrices, useFetchFlexRequirements, useFetchProfile } from "src/api/fixed";
import { SubmitFlexDataResponse, useSubmitFlex } from "../utils/useSubmitFlex";
import { FlexError } from "../components/FlexError";
import { NoFlex } from "../components/NoFlex";
import { productId } from "util/productIds";
import { createFlexChangeEvent } from "../utils/createFlexChangeEvent";

type PageView = "PRODUCT_SELECT" | "REQUIREMENT_SELECT" | "RECEIPT";
type ViewMode = "STEP1" | "STEP2";

export type SetSelectedProductArgs = {
  selectedProduct: types.fixed.TvPointsAndBroadbandPrice | null;
  productCardIndex: number | null;
};

export type SelectedRequirementProductIds = {
  productId: number;
  flexRequirementId: number | null | undefined;
  requirementIndex: number;
}[];

type FlexContextType = {
  isLoading: boolean;
  profileLoading: boolean;
  street: string;
  selectedTvProductId: number | undefined;
  setSelectedTvProductId: Dispatch<SetStateAction<number | undefined>>;
  selectedBroadbandProductId: number | undefined;
  setSelectedBroadbandProductId: Dispatch<SetStateAction<number | undefined>>;
  viewMode: ViewMode;
  setViewMode: Dispatch<SetStateAction<ViewMode>>;
  pageView: PageView;
  setPageView: Dispatch<SetStateAction<PageView>>;
  navigateToStep1: () => void;
  navigateToStep2: () => void;
  pageRequirement: types.fixed.FlexProductRequirement | undefined;
  requirementIndex: number;
  setRequirementIndex: Dispatch<SetStateAction<number>>;
  hasNextRequirementsPage: boolean;
  hasPreviousRequirementsPage: boolean;
  pageSelectedRequirements: SelectedRequirementProductIds | undefined;
  selectedRequirementProductIds: SelectedRequirementProductIds | undefined;
  setSelectedRequirementProductIds: Dispatch<SetStateAction<SelectedRequirementProductIds | undefined>>;
  hasSelectedRequirementsForCurrentPage: boolean;
  quantityExtender: number;
  setQuantityExtender: Dispatch<SetStateAction<number>>;
  resetFlexProductAndStartStep1: () => void;
  cancelTv: boolean;
  setCancelTv: Dispatch<SetStateAction<boolean>>;
  receiptRef: RefObject<HTMLDivElement>;
  scrollToReceipt(): void;
  submitFlexData: SubmitFlexDataResponse;
  flexPricesData: types.fixed.FlexPrices | undefined;
  flexRequirementsData: types.fixed.FlexProductRequirements | undefined;
  flexRequirementsLoading: boolean;
  flexRequirementsError: boolean;
  activeFlexProduct: types.fixed.FlexCurrent["currentTvAndBroadband"] | undefined;
  flexProductOffer: types.fixed.TvPointsAndBroadbandPrice | undefined;
  setFlexProductOffer: Dispatch<SetStateAction<types.fixed.TvPointsAndBroadbandPrice | undefined>>;
  flexProductsForPurchase: types.fixed.FlexPrices["free"] | undefined;
  submit: {
    placeOrder: () => void;
    isSubmitting: boolean;
  };
};

export const FlexOrderContext = createContext<FlexContextType | undefined>(undefined);

export function FlexOrderProvider({ children }: { children: ReactNode }) {
  const profile = useFetchProfile();
  const submitFlexData = useSubmitFlex();
  const flexPrices = useFetchFlexPrices();
  const flexCurrent = useFetchFlexCurrent();
  const { pushTrackingEvent } = track.useEventTracking();

  const [viewMode, setViewMode] = useState<ViewMode>("STEP1");
  const [pageView, setPageView] = useState<PageView>("PRODUCT_SELECT");
  const [selectedTvProductId, setSelectedTvProductId] = useState<number | undefined>(undefined);
  const [selectedBroadbandProductId, setSelectedBroadbandProductId] = useState<number | undefined>(undefined);
  const [flexProductOffer, setFlexProductOffer] = useState<types.fixed.TvPointsAndBroadbandPrice>();

  const flexRequirements = useFetchFlexRequirements({
    productIdTv: selectedTvProductId?.toString(),
    productIdBroadband: selectedBroadbandProductId?.toString(),
  });

  const [cancelTv, setCancelTv] = useState(false);
  const [requirementIndex, setRequirementIndex] = useState(0);
  const [quantityExtender, setQuantityExtender] = useState(0);
  const [selectedRequirementProductIds, setSelectedRequirementProductIds] = useState<SelectedRequirementProductIds>();

  const receiptRef = useRef<HTMLDivElement>(null);
  const customerId = profile.data?.customerId.toString();
  const street = `${profile.data?.address.streetName || ""} ${profile.data?.address.streetNumber || ""}`;

  const navigateToStep1 = () => setViewMode("STEP1");
  const navigateToStep2 = () => setViewMode("STEP2");

  const resetFlexProductAndStartStep1 = () => {
    setQuantityExtender(0);
    setRequirementIndex(0);
    setSelectedRequirementProductIds(undefined);
    setSelectedTvProductId(undefined);
    setSelectedBroadbandProductId(undefined);
    navigateToStep1();
  };

  const isLoading = flexCurrent.isLoading || !!flexCurrent.isWaitingKey || flexPrices.isLoading || profile.isLoading;
  const activeFlexProduct = flexCurrent.data?.currentTvAndBroadband;
  const flexProductsForPurchase = flexPrices.data?.free;

  const orderToPlace = {
    tvProduct: cancelTv ? -1 : selectedTvProductId,
    bbProduct: selectedBroadbandProductId,
    requirements: [
      ...(selectedRequirementProductIds
        ?.filter(({ productId }) => typeof productId === "number" && productId > 0)
        .map(({ productId }) => productId) || []),
      ...(quantityExtender > 0 ? [productId.smartWifiExtender] : []),
    ],
  };

  const pageRequirement = flexRequirements.data?.at(requirementIndex);

  const pageSelectedRequirements = selectedRequirementProductIds?.filter((requirement) => {
    return requirement.requirementIndex === requirementIndex;
  });

  const hasSelectedRequirementsForCurrentPage = Boolean(
    pageSelectedRequirements?.filter((p) => p.flexRequirementId || p.productId === -1).length ||
      pageRequirement?.askAboutKeepingDecoder
  );

  const hasNextRequirementsPage = requirementIndex < (flexRequirements.data?.length || 0) - 1;
  const hasPreviousRequirementsPage = requirementIndex > 0;

  function scrollToReceipt() {
    receiptRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  if (flexPrices.isError || flexRequirements.isError) {
    return <FlexError />;
  }

  if (!flexPrices.data?.free?.length && !flexPrices.isLoading && !flexPrices.isWaitingKey) {
    return <NoFlex />;
  }

  return (
    <FlexOrderContext.Provider
      value={{
        isLoading,
        profileLoading: profile.isLoading || profile.isValidating,
        street,
        selectedBroadbandProductId,
        setSelectedBroadbandProductId,
        selectedTvProductId,
        setSelectedTvProductId,
        viewMode,
        setViewMode,
        navigateToStep1,
        navigateToStep2,
        pageView,
        setPageView,
        pageRequirement,
        requirementIndex,
        setRequirementIndex,
        hasNextRequirementsPage,
        hasPreviousRequirementsPage,
        pageSelectedRequirements,
        selectedRequirementProductIds,
        setSelectedRequirementProductIds,
        hasSelectedRequirementsForCurrentPage,
        quantityExtender,
        setQuantityExtender,
        cancelTv,
        setCancelTv,
        resetFlexProductAndStartStep1,
        receiptRef,
        scrollToReceipt,
        submitFlexData,
        flexRequirementsData: flexRequirements.data,
        flexPricesData: flexPrices.data,
        flexRequirementsLoading: flexRequirements.isLoading,
        flexRequirementsError: flexRequirements.isError,
        activeFlexProduct,
        flexProductOffer,
        setFlexProductOffer,
        flexProductsForPurchase,
        submit: {
          placeOrder: () => {
            const transactionData = createFlexChangeEvent(activeFlexProduct, flexProductOffer);
            pushTrackingEvent(transactionData);
            return submitFlexData
              .placeOrder({
                customerId,
                order: orderToPlace,
              })
              .finally(() => setPageView("RECEIPT"));
          },
          isSubmitting: submitFlexData.isSubmitting,
        },
      }}
    >
      {children}
    </FlexOrderContext.Provider>
  );
}

export function useFlexOrder(): FlexContextType {
  const context = useContext(FlexOrderContext);

  if (!context) {
    throw Error("useFlexOrder must be used within a FlexOrderProvider");
  }
  return context;
}
