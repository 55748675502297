import { Accordion, Container, Paragraph, breakpoints, devices, images, remCalc } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const AdditionalInfoContainer = styled(Container)`
  background-color: ${colors.grey50};
  border-radius: 0.3rem;
  padding: 0.5rem;
`;

export const PositivContainer = styled(AdditionalInfoContainer)`
  background-color: ${colors.green100};
`;

export const CapitalLetterParagraph = styled(Paragraph)`
  text-transform: capitalize;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  :hover {
    text-decoration: underline;
  }
  outline-color: ${colors.blue500};
  outline-offset: 4px;
`;

export const DetailsWrapper = styled.div`
  position: relative;
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
  @media ${devices.lg_up} {
    grid-template-columns: auto auto;
  }
  max-width: ${breakpoints.lg}px;
`;

export const ReceiptPaper = styled.div`
  position: relative;
  margin-bottom: 32px;
  @media ${devices.lg_up} {
    position: absolute;
    width: 100vw;
    inset: 100% auto 0 auto;
  }

  :after {
    background: linear-gradient(-45deg, transparent 16px, white 0), linear-gradient(45deg, transparent 16px, white 0);
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 16px 32px;
    content: "";
    display: block;
    width: 100%;
    height: 32px;
    position: absolute;
    filter: drop-shadow(0px 2px 8px #dcdce1);
    clip-path: inset(0px 0px -3px 0);
    top: 100%;
    left: 0px;
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-content: start;
  gap: 1rem;
  margin: 1rem;
  @media ${devices.lg_up} {
    justify-items: start;
    margin: 2.75rem 0 1rem 2rem;
  }
`;

export const PayWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-content: start;
  max-width: 300px;
  @media ${devices.lg_up} {
    justify-items: end;
  }
`;

export const Hero = styled.div`
  font-size: ${remCalc(18)};
  color: ${colors.white};
  font-weight: normal;
  padding: 0;
  margin: 10px 0;
  position: relative;
  background-color: ${colors.corePurple850};
  background-image: url(${images.Telia5thElementPurplePng});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 100%;
  background-clip: padding-box;
  @media ${devices.sm_down} {
    background-position: 50vw;
  }
`;

export const HeroHeading = styled.h2`
  font-size: min(max(${remCalc(24)}, 3vw), ${remCalc(40)});
  grid-template-columns: 1fr 1fr;
  display: grid;
  grid-column: 1 / 3;
  align-items: end;
  margin: 0 0 0.5rem;
  gap: 0.25rem 0.5rem;
`;
export const SubHeading = styled.span`
  font-size: ${remCalc(16)};
  text-transform: capitalize;
`;
export const InfoGrid = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.25rem 0.5rem;
`;
export const GridItem = styled.div`
  font-size: ${remCalc(14)};
`;

export const DateLink = styled(Link)<{ $isCurrentDate: boolean }>`
  color: white;
  flex-shrink: 0;
  padding: 1rem 0;
  text-transform: capitalize;
  font-weight: ${(props) => (props.$isCurrentDate ? "bold" : "normal")};
  opacity: ${(props) => (props.$isCurrentDate ? 1 : 0.8)};
`;

export const DetailsAccordionItem = styled(Accordion.Item)`
  box-shadow: none;
  margin: 0;
  border-bottom: 1px solid ${colors.grey100};
  border-radius: 0;
  @media ${devices.lg_up} {
    max-width: ${breakpoints.sm}px;
  }
`;

const accordionRow = css`
  display: grid;
  grid-template-columns: 1fr 1fr 100px;
  align-items: center;
  gap: 0.5rem 1rem;
  width: 100%;
  @media ${devices.sm_down} {
    grid-template-columns: 1fr 100px;
    grid-template-rows: auto auto;
  }
`;

export const StyledAccordionHeader = styled(Accordion.Trigger)`
  ${accordionRow}
  margin-right: 1rem;
  margin-block: 5px;
  h3 {
    margin: 0;
  }
  font-size: 16px;
`;
export const StyledAccordionBody = styled(Accordion.Content)`
  padding: 0;
  font-size: 16px;
`;

export const InvoiceProductList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Dot = styled.div<{ color: string }>`
  position: absolute;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  width: 7px;
  height: 7px;
  left: -16px;
  top: 50%;
  transform: translateY(-50%);
`;

export const InvoiceProduct = styled.li`
  ${accordionRow}
  position: relative;
  padding: 14px 14px 14px 50px;
  &:first-of-type {
    border-top: 1px solid ${colors.grey100};
  }
  &:not(&:last-of-type) {
    border-bottom: 1px solid ${colors.grey100};
  }
`;

export const TitleCell = styled.div`
  position: relative;
  @media ${devices.sm_down} {
    grid-area: 2 / 1;
  }
`;

export const DateCell = styled.div`
  @media ${devices.sm_down} {
    grid-area: 1 / 1;
    font-size: 12px;
  }
`;
export const PriceCell = styled.div`
  text-align: end;
  grid-area: 1 / 3;
  @media ${devices.sm_down} {
    grid-area: 1 / 2 / 3 / 2;
  }
`;

export const SumTextCell = styled.div`
  font-weight: bold;
  grid-area: 1 / 1 / 2 / 2;
`;

export const SumCell = styled.div`
  font-weight: bold;
  text-align: end;
  grid-area: 1 / 3 / 3 / 3;
  @media ${devices.sm_down} {
    grid-area: 1 / 2 / 3 / 2;
  }
`;

export const TotalSumGrid = styled(Container)`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 14px 80px 14px 30px;
  font-size: ${remCalc(20)};
  @media ${devices.sm_down} {
    padding: 14px 30px;
  }
`;

export const ColorCode = styled(Container)<{ fadeIn: boolean }>`
  display: flex;
  font-size: ${remCalc(12)};
  gap: 10px;
  opacity: ${({ fadeIn }) => (fadeIn ? 1 : 0)};
  transition: opacity 300ms ease;
`;

export const Cost = styled.div`
  border-radius: 10px;
  padding: 0 10px;
  background-color: ${colors.corePurple200};
  color: ${colors.corePurple900};
`;

export const Refund = styled.div`
  border-radius: 10px;
  padding: 0 10px;
  background-color: ${colors.green200};
  color: ${colors.green900};
`;
