import React, { useState, useEffect, SyntheticEvent } from "react";
import { useReactRouterHistory } from "../hooks/useReactRouterHistory";
import { Icon } from "../Icon";
import { ActionPointItem, ActionPointLink, ActionPointButton, IconWrapper } from "./style";
import { ActionPoint } from ".";

type Props = ActionPoint & {
  toggleShowActions: () => void;
};

/**
 * @deprecated Use Teddy components instead
 */
export function ActionMenuPoint(props: Props) {
  const history = useReactRouterHistory();
  const [close, setClose] = useState(false);
  const { icon, kind, text, styling, borderTop, toggleShowActions } = props;

  useEffect(() => {
    if (close) toggleShowActions();
  }, [close]);

  if (kind === "link") {
    const handleOnClick = (e: SyntheticEvent) => {
      if (props.isInternalNavigation) {
        e.preventDefault();
        history.push(props.href, { returnUrl: props.returnUrl });
      }
    };

    return (
      <ActionPointItem borderTop={borderTop}>
        <ActionPointLink href={props.href} onClick={handleOnClick} styling={styling}>
          <IconWrapper>
            <Icon size="sm" icon={icon} />
          </IconWrapper>
          {text}
        </ActionPointLink>
      </ActionPointItem>
    );
  }

  const handleOnClick = () => {
    props.onClick();
    setClose(true);
  };

  return (
    <ActionPointItem borderTop={borderTop}>
      <ActionPointButton styling={styling} onClick={handleOnClick}>
        <IconWrapper>
          <Icon size="sm" icon={icon} />
        </IconWrapper>
        {text}
      </ActionPointButton>
    </ActionPointItem>
  );
}
