import { ComponentProps, RefObject, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Button } from "@telia-no-min-side/components";
import { useParams, useSearchParams } from "react-router-dom";
import { MobileSubscriptionAccordionItem } from "src/components/mobile-subscription-accordion-item";
import { FragmentType, getFragmentData } from "src/gql";
import { AdditionalProduct } from "src/gql/graphql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { get5GSpeedText } from "src/util/format-subscription/get5GSpeedText";
import { getConvergentBenefitAmount } from "src/util/format-subscription/getConvergentBenefitAmount";
import { getName } from "src/util/format-subscription/getName";
import { recommendedKeySearchParam } from "../../mobile-subscription-change-redir";
import { hasUnlimitedData } from "util/format-subscription/hasUnlimitedData";
import { getDownloadSpeed } from "util/format-subscription/getDownloadSpeed";
import { getIncludedProperties } from "util/format-subscription/getIncludedProperties";
import { hasLinesPrice } from "util/format-subscription/hasLinesPrice";
import { hasStartDiscountOnChangeSubscription } from "util/format-subscription/hasStartDiscount";
import { isUngSubscription } from "util/format-subscription/isUngSubscription";
import { SpeedOrDataBenefit } from "../components/SpeedOrDataBenefit";
import { OFFER_FOR_SALE_FRAGMENT } from "../graphql/offerForSaleFragment";
import { GET_UPGRADE_DOWNGRADE_SUMMARY } from "pages/mobile/mobile-subscription-confirm/graphql/getUpgradeDowngradeSummary";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";
import { JuniorSummerCampaignBanner } from "../components/JuniorSummerCampaignBanner";
import { getJuniorSummerCampaignDataForOffer } from "./utils/getJuniorSummerCampaignData";
import { JuniorSummerCampaignTitle } from "../components/JuniorSummerCampaignTitle";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { BUCKET_SUBCRIPTIONS, MAX_SPEED_SUBSCRIPTIONS } from "util/format-subscription/variables";
import { Card, Flex, Toggle } from "@telia/teddy";

export function OfferForSale({
  offeringFragment,
  isLinesApplicable,
  additionalProducts,
  additionalText,
  canNotChangeAlertRef,
  offeringWithCommitmentFragment,
}: {
  offeringFragment: FragmentType<typeof OFFER_FOR_SALE_FRAGMENT>;
  isLinesApplicable: boolean;
  additionalProducts?: AdditionalProduct[] | null;
  additionalText?: string;
  canNotChangeAlertRef: RefObject<HTMLDivElement>;
  offeringWithCommitmentFragment?: FragmentType<typeof OFFER_FOR_SALE_FRAGMENT> | null;
}) {
  const params = useParams<{ phoneNumber?: string; accountId?: string }>();
  const [search] = useSearchParams();
  const recommendedPricePlan = search.get(recommendedKeySearchParam);
  const [isAddingCommitment, setIsAddingCommitment] = useState(false);

  const offeringWithCommitment = getFragmentData(OFFER_FOR_SALE_FRAGMENT, offeringWithCommitmentFragment);
  const offering =
    isAddingCommitment && offeringWithCommitment
      ? offeringWithCommitment
      : getFragmentData(OFFER_FOR_SALE_FRAGMENT, offeringFragment);

  const [prefetchNextPage, prefetchedSummary] = useLazyQuery(GET_UPGRADE_DOWNGRADE_SUMMARY, {
    variables: {
      phoneNumber: params.phoneNumber || "",
      offering: offering.pricePlan || "",
      accountId: params.accountId || "",
      withProductChanges: true,
    },
    nextFetchPolicy: "standby",
    fetchPolicy: "cache-first",
  });
  const { orders } = useOrderSubscription();
  const hasOnGoingOrder = orders.some((order) => order.phoneNumber === params.phoneNumber);

  if (!offering.pricePlan) return null;

  const downloadSpeed = getDownloadSpeed(offering.price?.offeringPrices);
  const convergentBenefit = getConvergentBenefitAmount(additionalProducts);
  const hasTeliaSamlet = convergentBenefit?.hasDoubleData || convergentBenefit?.hasDoubleSpeed;
  const hasUnlimitedDataBenefit = hasUnlimitedData(offering?.additionalProperties);
  const hasStartDiscount = hasStartDiscountOnChangeSubscription(offering.includedProducts);
  const isUngSub = isUngSubscription(offering?.additionalProperties);
  const hasJuniorSummerCampaign = isJuniorSummerCampaign(offering.pricePlan);
  const name = getName(offering.price?.offeringPrices, offering.shortName, hasTeliaSamlet);
  const canGetDoubleSpeed =
    hasTeliaSamlet && name && !MAX_SPEED_SUBSCRIPTIONS.includes(name) && !BUCKET_SUBCRIPTIONS.includes(name);
  const priceMonthly = offering.monthlyCost?.amount;
  const includedProperties = getIncludedProperties(offering?.additionalProperties);
  const linesPriceEnabled = hasLinesPrice(offering.monthlyCost);
  const { formattedBaseDataAmount, formattedTotalDataAmount } = getJuniorSummerCampaignDataForOffer(
    offering.pricePlan || "",
    offering.price?.offeringPrices,
    offering.includedProducts
  );

  const tracking = {
    new_subscription: offering.shortName || "",
    new_subscription_price: priceMonthly || "",
  };

  const scrollToCannotChangeAlert = () => {
    if (canNotChangeAlertRef.current) {
      canNotChangeAlertRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      canNotChangeAlertRef.current.animate(
        [{ transform: "scale(1)" }, { transform: "scale(1.025)" }, { transform: "scale(1)" }],
        {
          delay: 1000,
          duration: 1000,
        }
      );
    }
  };

  const getLozenge = (): ComponentProps<typeof MobileSubscriptionAccordionItem>["lozengeRight"] => {
    if (hasJuniorSummerCampaign) {
      // Family benefit is not applicable for junior summer campaign
      return undefined;
    }

    if (!isUngSub && linesPriceEnabled) {
      return { label: "Familierabatt", status: "communication" };
    }

    if (isLinesApplicable && !linesPriceEnabled) {
      return { label: "Gir familierabatt", status: "communication" };
    }

    return undefined;
  };

  const displaySpeed = canGetDoubleSpeed && downloadSpeed ? downloadSpeed * 2 : downloadSpeed;
  const overLine = (
    <SpeedOrDataBenefit
      downloadSpeed={displaySpeed}
      hasUnlimitedData={hasUnlimitedDataBenefit}
      showDownloadSpeed={!!downloadSpeed}
      removeMargin={true}
    />
  );

  const juniorSummerCampaignTitle = hasJuniorSummerCampaign ? (
    <JuniorSummerCampaignTitle baseDataAmount={formattedBaseDataAmount} totalDataAmount={formattedTotalDataAmount} />
  ) : undefined;

  return (
    <MobileSubscriptionAccordionItem
      subscriptionName={name?.length ? name : "Klarte ikke hente navn"}
      customSubscriptionName={juniorSummerCampaignTitle}
      priceMonthly={priceMonthly}
      originalPriceMonthly={offering.originalPrice?.amount}
      hasDoubleData={!!convergentBenefit.hasDoubleData}
      hasDoubleSpeed={!!convergentBenefit.hasDoubleSpeed}
      overLine={overLine}
      customPeakContent={hasJuniorSummerCampaign ? <JuniorSummerCampaignBanner /> : undefined}
      isRecommended={recommendedPricePlan === offering.pricePlan}
      additionalText={
        additionalText?.length
          ? additionalText
          : hasStartDiscount
          ? "50,- i rabatt de første 12 md. uten binding"
          : undefined
      }
      includedProperties={[
        ...includedProperties,
        ...(hasUnlimitedDataBenefit && downloadSpeed
          ? [{ code: "5g", text: get5GSpeedText(downloadSpeed), icon: "five-g", showInPeak: false } as const]
          : []),
      ]}
      lozengeRight={getLozenge()}
      triggerContainerProps={{
        onMouseOver: () => {
          if (params.phoneNumber && offering.pricePlan && !prefetchedSummary.data && !prefetchedSummary.loading) {
            prefetchNextPage();
          }
        },
      }}
      dataTrackingID={`change-subscription-offering-item-${(name || "").replaceAll(" ", "_")}`}
    >
      <Flex direction="column" gap="200">
        {offeringWithCommitment && (
          <>
            <Card.Line />

            <Toggle>
              <Toggle.Input
                checked={isAddingCommitment}
                onCheckedChange={() => setIsAddingCommitment((prev) => !prev)}
              />
              <Toggle.Label>
                Jeg vil ha{" "}
                {offeringWithCommitment?.price?.offeringPrices?.find((p) => p?.code === "MONTHLY_DISCOUNT")?.amount}
                ,-/md i rabatt ved 12 md. bindingstid
              </Toggle.Label>
            </Toggle>
          </>
        )}

        {hasOnGoingOrder ? (
          <Button
            floatRight
            alignSelf="end"
            disabled
            onDisableClick={scrollToCannotChangeAlert}
            trackEvent={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.UPGRADE_DOWNGRADE_SUMMARY_DISABLED,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.CHANGE_SUBSCRIPTION,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.SELECT_DISABLED,
            }}
          >
            Velg
          </Button>
        ) : (
          <Button
            floatRight
            alignSelf="end"
            tag="a"
            hideLinkIcon
            isInternalNavigation
            href={`${location.pathname}/bekreft?pricePlan=${encodeURIComponent(offering.pricePlan)}`}
            trackEvent={{
              ui_item_action: `upgrade-downgrade-summary page ${JSON.stringify(tracking)}`,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.CHANGE_SUBSCRIPTION,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: "/mobil/mine-abonnement/endre",
              ui_item_text: TRACK_EVENT.ITEM_TEXT.SELECT,
            }}
          >
            Velg
          </Button>
        )}
      </Flex>
    </MobileSubscriptionAccordionItem>
  );
}
