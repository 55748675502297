import { types } from "@telia-no-min-side/utils";
import { getSimplePriceText } from "./priceFormatter";

export type GetPrice = {
  displayPriceRecurring: string;
  totalRecurringPrice: number;
  displayOnceOffPrice: string;
  totalOnceOffPrice: number;
  chargePeriod: string;
};

export function getPriceProductGroupSingleProductWithShipping(
  product?: types.fixed.ProductGroupSingleProductWithShipping,
  quantity?: number
): GetPrice | undefined {
  if (!product || !quantity) return;
  const {
    price: { currentPrice },
  } = product;
  const { chargePeriod, onceOff, recurring } = currentPrice;
  const totalRecurringPrice = recurring * quantity;
  return {
    displayPriceRecurring: getSimplePriceText(totalRecurringPrice, chargePeriod),
    totalRecurringPrice: totalRecurringPrice,
    displayOnceOffPrice: getSimplePriceText(onceOff, chargePeriod),
    totalOnceOffPrice: onceOff,
    chargePeriod,
  };
}
