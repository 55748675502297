import styled, { css } from "styled-components";
import { Props } from ".";

export const Box = styled.div<Pick<Props, "kind">>`
  margin: 0.5rem 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: stretch;
  gap: 10px;
  padding: 0.5rem 1rem;

  ${(props) => {
    if (props.kind === "error") {
      return css`
        background-color: #ffe0eb;
        border: 1px solid #ff658b;
      `;
    }
    return css`
      background-color: #ffedd0;
      border: 1px solid #ffda8b;
    `;
  }}
`;
