import { ReactNode } from "react";
import { Grid, Toggle, Flex, Text, color } from "@telia/teddy";

type ServiceBoxProps = {
  id: string | undefined | null;
  isActive: boolean | undefined | null;
  title: ReactNode;
  subTitle?: string;
  content: ReactNode | ReactNode[];
  priceText?: string;
  onChange?: (checked: boolean) => void;
  withIndent?: boolean;
  disabled?: boolean;
};

export function ServiceBoxWithoutIndent(props: ServiceBoxProps) {
  const { id, isActive, title, subTitle, content, priceText, onChange, disabled } = props;

  return (
    <Grid style={{ gridTemplateColumns: "1fr 1fr" }} gap="200" mb="300" width="100%" align="start">
      <div>
        <Flex align="start" gap="200">
          <Toggle id={id || ""}>
            <Toggle.Input
              checked={!!isActive}
              onCheckedChange={onChange}
              disabled={disabled}
              style={{ height: "var(--teddy-spacing-300)" }}
            >
              <Toggle.Indicator />
              <Toggle.Thumb />
            </Toggle.Input>
          </Toggle>
          <Flex align="center" justify="start" gap="200">
            <Text variant="paragraph-100-medium">{title}</Text>
            {subTitle && (
              <Text variant="additional-100" color={color.teddyColorBrandCorePurple}>
                {subTitle}
              </Text>
            )}
          </Flex>
        </Flex>
        {content && (
          <Text as="div" variant="additional-100" pt="200">
            {content}
          </Text>
        )}
      </div>
      {priceText && (
        <Flex justify="end">
          <Text variant="additional-100">{priceText}</Text>
        </Flex>
      )}
    </Grid>
  );
}

export function ServiceBoxWithIndent(props: ServiceBoxProps) {
  const { id, isActive, title, subTitle, content, priceText, onChange, disabled } = props;

  return (
    <Grid style={{ gridTemplateColumns: "auto auto 1fr" }} gap="200" mb="300" width="100%" align="start">
      <Toggle id={id || ""}>
        <Toggle.Input
          checked={!!isActive}
          onCheckedChange={onChange}
          disabled={disabled}
          style={{ height: "var(--teddy-spacing-300)" }}
        >
          <Toggle.Indicator />
          <Toggle.Thumb />
        </Toggle.Input>
      </Toggle>
      <div>
        <Flex align="start" justify="space-between" gap="1rem">
          <Text variant="paragraph-100-medium">{title}</Text>
          {subTitle && (
            <Text variant="additional-100" color={color.teddyColorBrandCorePurple}>
              {subTitle}
            </Text>
          )}
        </Flex>
        {content && (
          <Text as="div" variant="additional-100" pt="200">
            {content}
          </Text>
        )}
      </div>
      {priceText && (
        <Flex justify="end">
          <Text variant="additional-100">{priceText}</Text>
        </Flex>
      )}
    </Grid>
  );
}

export function ServiceBox(props: ServiceBoxProps) {
  const { withIndent = true, ...rest } = props;

  return withIndent ? <ServiceBoxWithIndent {...rest} /> : <ServiceBoxWithoutIndent {...rest} />;
}
