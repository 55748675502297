import { Container, Heading } from "@telia-no-min-side/components";
import { Button, DropDownListWithLabel } from "@telia/styleguide";
import { useEffect, useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { useFetchProfile } from "src/api/fixed";
import { Spinner } from "../../../components/spinner-telia/Spinner";
import REQ from "../../../util/requestStatus";
import { RouterSettingsActions } from "../actions/RouterSettingActions";
import { ChangeAlias } from "../modules/ChangeAlias";
import { WifiAdminTabs } from "../modules/WifiAdminTabs";
import { ErrorPage } from "../pages/ErrorPage";
import WiFiSettings from "../pages/WiFiSettings/WiFiSettings";
import { useRouterSettings } from "../stores/RouterSettingsProvider";
import { routerSettingsAction } from "../stores/reducer";
import { routerAnalytics } from "../util/RouterSettingsAnalytics";
import { wifiAdminTags, wifiAdminUtilities } from "../util/WifiAdminUtilities";

import "./style.less";

const SelectUnitContainer = ({ children }) => <div className="unit-selector-container">{children}</div>;

export const WifiAdministrationWithStore = () => {
  const { routerSettings, dispatch } = useRouterSettings();
  const { devices, activeUnitSerialNumber, updateStatus, fetchStatus, activeUnit } = routerSettings;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { data: profile, isError } = useFetchProfile();
  const [showChangeName, setShowChangeName] = useState(false);

  useEffect(() => {
    if (!activeUnitSerialNumber && devices && fetchStatus === REQ.SUCCESS) {
      dispatch({
        type: routerSettingsAction.type.SET_ACTIVE_UNIT,
        payload: {
          unitSerialNumber: devices.find((unit) => unit.DeviceType === "Main")?.SerialNumber,
          devices: devices,
        },
      });
    }
  }, [activeUnitSerialNumber, fetchStatus, devices]);
  console.log(fetchStatus === REQ.INIT, !devices, profile);
  useEffect(() => {
    if (fetchStatus === REQ.INIT && !devices && profile) {
      RouterSettingsActions.fetchRouterSettings(dispatch);
    }
  }, [devices, fetchStatus, profile]);

  const setNewActiveUnit = (event) => {
    dispatch({
      type: routerSettingsAction.type.SET_ACTIVE_UNIT,
      payload: {
        unitSerialNumber: event.target.value,
      },
    });
  };

  const showChangeAliasPrompt = () => {
    routerAnalytics.trackButtonClick("change-alias");
    setShowChangeName(!showChangeName);
  };

  const closeChangeAliasPromptAfterDelay = () => {
    window.setTimeout(() => {
      showChangeAliasPrompt();
    }, 3000);
  };

  const storeAlias = (alias) => {
    const updatedDevice = wifiAdminUtilities.setAliasForDevice(activeUnit, alias);

    RouterSettingsActions.updateDevice(dispatch, {
      device: updatedDevice,
      tag: wifiAdminTags.changeAlias,
    });
    routerAnalytics.trackStoreClick("alias");
  };

  const isExtenderWithNetworkStatus = () =>
    activeUnit?.DeviceType === "Extender" && activeUnit?.NetworkState === "Available";

  const isMainUnitWithNetworkStatus = () =>
    activeUnit?.DeviceType === "Main" && activeUnit?.NetworkState === "Available";

  const generateSelectUnitValues = () => {
    return devices?.map((device) => {
      if (device.DeviceType === "Main") {
        return { value: device.SerialNumber, name: "Hjemmesentral" };
      }
      if (device.Settings.Alias === "") {
        return { value: device.SerialNumber, name: device.SerialNumber };
      } else {
        return { value: device.SerialNumber, name: device.Settings.Alias };
      }
    });
  };

  if (
    fetchStatus === REQ.ERROR ||
    !devices ||
    !activeUnitSerialNumber ||
    isError ||
    (devices && devices.length === 0)
  ) {
    return <ErrorPage />;
  }
  return (
    <Container maxWidth="md" padding showGoBackButton>
      <Heading tag="h2" variant="title-400">
        {"Nettverks\u00ADinnstillinger"}
      </Heading>

      {!devices || !activeUnit || !activeUnitSerialNumber || !profile ? (
        <Spinner />
      ) : (
        <Container maxWidth="md" padding={"vertical"} data-di-mask>
          {devices.length > 1 ? (
            <SelectUnitContainer>
              <DropDownListWithLabel
                changeSelectedOption={setNewActiveUnit}
                options={generateSelectUnitValues()}
                selectedOption={activeUnitSerialNumber}
                label={"Velg enhet"}
                visibleLabel
              />
            </SelectUnitContainer>
          ) : (
            <Heading level={2} text={"Hjemmesentral"} />
          )}
          {isExtenderWithNetworkStatus() && (
            <>
              <div className={"wifi-admin__change-name"}>
                <Button text={"Endre navn på enheten"} onClick={showChangeAliasPrompt} />
                <ChangeAlias
                  onStore={storeAlias}
                  show={showChangeName}
                  onCancel={showChangeAliasPrompt}
                  onSuccessfulStore={closeChangeAliasPromptAfterDelay}
                />
              </div>
              <WiFiSettings />
            </>
          )}
          {isMainUnitWithNetworkStatus() && (
            <WifiAdminTabs setActiveTabIndex={setActiveTabIndex} activeTabIndex={activeTabIndex} />
          )}
          {updateStatus.updating && (
            <Beforeunload onBeforeunload={() => "En oppdatering skjer, sikker på at du vil laste den inn på nytt?"} />
          )}
        </Container>
      )}
    </Container>
  );
};
