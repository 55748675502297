import { useState } from "react";
import { Button, Container, Paragraph, TextInput, Alert } from "@telia-no-min-side/components";
import { useSendPin } from "../hooks/useSendPin";
import { useSetNewPassword } from "../hooks/useSetNewPassword";

type Props = {
  phoneNumber: string;
  loadInfoPage: () => void;
};

export function ChangePassword({ loadInfoPage, phoneNumber }: Props) {
  const [serviceLoading, setServiceLoading] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageOtp, setErrorMessageOtp] = useState("");

  const { sendPin, loading: sendPinIsLoading, data: sendPinResponse, error: sendPinError } = useSendPin({});

  const {
    setNewPassword,
    loading: setNewPasswordIsLoading,
    data: setNewPasswordResponse,
  } = useSetNewPassword({
    onCompleted() {
      setErrorMessage("");
    },
    onError: setErrorMessage,
  });

  if (setNewPasswordResponse?.clearTrustSetPassword?.ctsession) {
    return (
      <Container maxWidth="xs" padding="bottom">
        <Container padding="bottom">
          <Alert
            kind="positive"
            isOpen
            title="Passordet er endret!"
            text="Nytt passord er lagret, og klart til bruk."
          />
        </Container>
        <Button variant="secondary" onClick={loadInfoPage}>
          Tilbake
        </Button>
      </Container>
    );
  }

  return (
    <>
      {sendPinResponse?.sendPin ? (
        <>
          <Container maxWidth="xs" padding="bottom">
            <Container padding="bottom">
              <Paragraph>
                {serviceLoading === "email"
                  ? "Tast inn engangskoden du skal ha fått på e-post. Så lager du nytt passord."
                  : "Tast inn engangskoden du skal ha fått på SMS. Så lager du nytt passord."}
              </Paragraph>
            </Container>
            <TextInput
              required
              errorMessage={errorMessageOtp}
              onBlur={() => {
                if (!otp.length) setErrorMessageOtp("Engangskode er ugyldig.");
              }}
              onChange={(event) => {
                setErrorMessageOtp("");
                setOtp(event.target.value);
              }}
            >
              Engangskode
            </TextInput>
            <Container padding="top">
              <TextInput
                required
                type="password"
                errorMessage={errorMessage}
                onBlur={() => {
                  if (!password.length) setErrorMessage("Passord er påkrevd.");
                }}
                onChange={(event) => {
                  setErrorMessage("");
                  setPassword(event.target.value);
                }}
              >
                Nytt passord
              </TextInput>
            </Container>
          </Container>

          <Container gap>
            <Button
              disabled={setNewPasswordIsLoading || password === "" || otp === ""}
              isLoading={setNewPasswordIsLoading && serviceLoading === "sms"}
              variant="primary"
              loadingText="Behandler"
              onClick={() => {
                setNewPassword({
                  variables: {
                    otp,
                    userId: phoneNumber,
                    password,
                  },
                });
              }}
            >
              Lagre passord
            </Button>
            <Button variant="secondary" onClick={loadInfoPage}>
              Avbryt
            </Button>
          </Container>
        </>
      ) : (
        <>
          {sendPinError && (
            <Container padding="bottom">
              <Alert kind="negative" isOpen title={sendPinError} />
            </Container>
          )}
          <Paragraph>Du vil motta en bekreftelseskode på din valgte mobiltelefon eller e-postadresse.</Paragraph>
          <Container padding="vertical">
            <Container gap>
              <Button
                disabled={sendPinIsLoading}
                isLoading={sendPinIsLoading && serviceLoading === "sms"}
                variant="primary"
                loadingText="Behandler"
                onClick={() => {
                  setServiceLoading("sms");
                  sendPin({
                    variables: {
                      sendOtpByEmail: false,
                      userId: phoneNumber,
                    },
                  });
                }}
              >
                SMS med engangskode
              </Button>
              <Button
                disabled={sendPinIsLoading}
                isLoading={sendPinIsLoading && serviceLoading === "email"}
                variant="primary"
                loadingText="Behandler"
                onClick={() => {
                  setServiceLoading("email");
                  sendPin({
                    variables: {
                      sendOtpByEmail: true,
                      userId: phoneNumber,
                    },
                  });
                }}
              >
                E-post med engangskode
              </Button>
            </Container>
          </Container>
          <Button variant="secondary" onClick={loadInfoPage}>
            Avbryt
          </Button>
        </>
      )}
    </>
  );
}
