import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { formatDataAmount } from "@telia-no-min-side/utils";
import { Card, Button, Flex, Heading, Icon, Text, color, Skeleton } from "@telia/teddy";
import { UserRole } from "gql/graphql";
import { ErrorBox } from "components/ErrorBox";
import { Breadcrumb } from "components/Breadcrumb";
import { getAverageDataUsage, HUNDRED_MB } from "./utils";
import { useMobileAccount } from "hooks/useMobileAccount";
import { HistoryChart, OfferingCards } from "./components";
import { GET_DATA_USAGE_HISTORY } from "./graphql/getDataUsageHistory";

export function MobileSubscriptionDataUsageHistory() {
  const { phoneNumber } = useMobileAccount();

  const { loading, data, error, refetch } = useQuery(GET_DATA_USAGE_HISTORY, {
    variables: { phoneNumber },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const subscription = data?.subscription;

  const baseData = subscription?.usage?.filter((item) => item?.category === "DATA" && item?.type === "BASE");
  const totalBaseData = baseData?.reduce((total, item) => total + (item?.limit?.amount || 0), 0) || 0;
  const isDataBoostSessionActive = !!subscription?.usage?.find((item) => item?.activeDataBoostSession);

  const averageDataUsage = getAverageDataUsage(subscription);
  // If dataBoost is enabled, the data balance is unlimited during boost time. So all other data package types including "BASE" won't be part of subscription usage and it will only have type "DATABOOST".
  // Once the dataBoost is expired, usage will again start to show all available data package types including "BASE", "ROLLOVER" etc,
  // Hence offerUpgrade depends on isDataBoostSessionActive and offerUpgrade will be false during dataBoost, not to show wrong info to user.
  const offerUpgrade =
    subscription?.roles.includes(UserRole.LegalOwner) && averageDataUsage > totalBaseData && !isDataBoostSessionActive;
  const hasAcceptableUsage = averageDataUsage > HUNDRED_MB;

  return (
    <Flex direction="column" gap="50px">
      <Flex direction="column" width="100%" gap="gutter-xl">
        <Breadcrumb />

        <Heading as="h2" variant="title-300">
          Tidligere dataforbruk
        </Heading>
      </Flex>

      {loading && (
        <Flex width="100%" gap="gutter-xl" wrap="wrap">
          <Skeleton height="380px" width="100%" maxWidth="470px" />
          <Skeleton height="175px" width="100%" maxWidth="470px" />
        </Flex>
      )}

      {error && (
        <ErrorBox
          title="Vi beklager"
          description="Dessverre oppstod det en feil under innlastningen av denne siden. Vennligst prøv igjen litt senere."
          actionContent={<Icon name="sync">Last side på nytt</Icon>}
          onClick={() => refetch()}
          maxWidth="470px"
        />
      )}

      {!loading && !error && (
        <Flex gap="gutter-xl" align="start" wrap="wrap">
          <Card variant="purple-dark" width="100%" maxWidth="470px">
            <Text variant="preamble-100" color={color.teddyColorBrandWhite}>
              Databruk de siste 6 månedene
            </Text>
            <Text color={color.teddyColorBrandWhite}>Målt i GB</Text>
            <HistoryChart subscription={subscription} averageDataUsage={averageDataUsage} />
            {hasAcceptableUsage && (
              <Text color={color.teddyColorBrandWhite} style={{ textAlign: "center" }}>
                Trykk på grafen for å se detaljer
              </Text>
            )}
            <Flex direction="row" justify="between" mt="300">
              <Text>Gjennomsnitt per måned</Text>
              <Text variant="paragraph-100-bold">{formatDataAmount(averageDataUsage)}</Text>
            </Flex>
          </Card>

          <OfferingCards
            offerUpgrade={offerUpgrade}
            totalBaseData={totalBaseData}
            averageDataUsage={averageDataUsage}
          />
        </Flex>
      )}

      <Flex justify="start">
        <Button variant="primary" asChild>
          <Link to="..">
            <Icon name="arrow-left" />
            Tilbake
          </Link>
        </Button>
      </Flex>
    </Flex>
  );
}
