import { SyntheticEvent, useMemo, useState } from "react";
import { Accordion, useFormHandling } from "@telia-no-min-side/components";
import { RadioButtonList } from "@telia/styleguide";
import { Field, Fields, getInitialState } from "../utils/getInitialState";
import { AccordionButtons } from "./AccordionButtons";
import { HeaderInfo } from "./InvoiceCardHeader";
import { PaperInvoiceAccordionBody } from "./styles/PaperInvoice.styled";
import { PaperTextInput, StyledAccordionItem, StyledForm } from "./styles/common-styles";
import { AccountDetailsQuery, DistributionCode, InvoiceDistribution, InvoiceType } from "src/gql/graphql";
import { DELIVERY_OPTION, DELIVERY_TYPE } from "util/constants/address";

type PaperInvoiceProps = {
  invoiceType?: InvoiceType;
  account: AccountDetailsQuery["account"];
  onSubmit: (
    formData: Fields,
    emailAddress: string,
    distributionType: DELIVERY_TYPE,
    invoiceType: DistributionCode
  ) => void;
};

const listOptions = [
  {
    label: "Gateadresse",
    value: DELIVERY_OPTION.URBAN_ADDRESS,
  },
  {
    label: "Postboks",
    value: DELIVERY_OPTION.PO_BOX_ADDRESS,
  },
];

export const PaperInvoice = ({ invoiceType, account, onSubmit }: PaperInvoiceProps) => {
  const user = account.invoiceReceiver!.individual!;
  const { address, emailAddress } = user;

  const selected = account.selectedInvoiceDistributionType === InvoiceDistribution.Mail;

  const hasPoBoxAdress = !!address?.poboxAddress;

  const [distributionType, setDistributionType] = useState<DELIVERY_TYPE>(
    hasPoBoxAdress ? DELIVERY_OPTION.PO_BOX_ADDRESS : DELIVERY_OPTION.URBAN_ADDRESS
  );

  const urbanAddressPreamble = [
    [
      address?.urbanAddress?.streetName?.toLocaleLowerCase(),
      address?.urbanAddress?.streetNr,
      address?.urbanAddress?.streetNrSuffix,
    ].join(" "),
    [address?.urbanAddress?.postcode, address?.urbanAddress?.city?.toLocaleLowerCase()].join(" "),
  ];

  const poboxAddressPreabmle = [
    [address?.poboxAddress?.boxName?.toLocaleLowerCase(), address?.poboxAddress?.boxNr].join(" "),
    [address?.poboxAddress?.postcode, address?.poboxAddress?.city?.toLocaleLowerCase()].join(" "),
  ];

  const initialState = useMemo(() => getInitialState(hasPoBoxAdress, user), [hasPoBoxAdress, account]);

  const { state, dispatch, Actions } = useFormHandling(initialState);
  const { form } = state;

  const fieldsByDistributionType = Object.values(form).filter(({ fieldType }) => fieldType.includes(distributionType));

  const handleOnSubmit = (formData: Fields, email: string | null | undefined) => {
    onSubmit(formData, email || "", distributionType, DistributionCode.Rg);
  };

  const handleFieldChange = (field: Field, value: string) => {
    dispatch({
      type: Actions.SET_FORM,
      payload: {
        [field.name]: {
          ...field,
          value,
          isDirty: true,
          isValid: !!value && !!field.required,
        },
      },
    });
  };

  const notAllMandatoryFieldsFilled = () => {
    return (
      Object.values(form).filter(
        (field) => field.required && field.fieldType?.includes(distributionType) && field.value === ""
      ).length > 0
    );
  };

  const disabled = notAllMandatoryFieldsFilled();

  return (
    <Accordion>
      <StyledAccordionItem selected={selected}>
        <Accordion.Trigger>
          <HeaderInfo
            selected={selected}
            invoiceType={invoiceType}
            hasPoBoxAdress={hasPoBoxAdress}
            poboxAddressPreabmle={poboxAddressPreabmle}
            urbanAddressPreamble={urbanAddressPreamble}
          />
        </Accordion.Trigger>
        <PaperInvoiceAccordionBody>
          <div>
            <RadioButtonList
              list={listOptions}
              type="horizontal"
              onChange={(e) => setDistributionType(e.target.value)}
              selectedIndex={listOptions.findIndex((option) => option.value === distributionType)}
            />
            <StyledForm
              onSubmit={(e) => {
                e.preventDefault();
                handleOnSubmit(form, emailAddress);
              }}
            >
              {fieldsByDistributionType.map((field) => (
                <PaperTextInput
                  required={field.required}
                  type={field.type}
                  key={field.name}
                  disabled={field.disabled}
                  value={form[field.name].value.toLocaleLowerCase()}
                  errorMessage={
                    (!form[field.name].isValid && form[field.name].isDirty && field.errorMessage) || undefined
                  }
                  onChange={(e: SyntheticEvent<Element, Event>) => {
                    handleFieldChange(field, (e.target as HTMLInputElement).value);
                  }}
                  data-di-mask
                >
                  {field.labelText}
                </PaperTextInput>
              ))}
            </StyledForm>
            <AccordionButtons
              disabled={disabled}
              form={form}
              emailAddress={emailAddress}
              handleOnSubmit={handleOnSubmit}
              handleOnCancel={() => dispatch({ type: Actions.RESET })}
            />
          </div>
        </PaperInvoiceAccordionBody>
      </StyledAccordionItem>
    </Accordion>
  );
};
