import { Link } from "react-router-dom";
import { Notabene, Text, Icon } from "@telia/teddy";
import {
  INVOICE_OVERDUE_WARNING_HEADING,
  INVOICE_OVERDUE_WARNING_MESSAGE,
  INVOICE_OVERDUE_WARNING_MESSAGE_TV_BOX,
  INOICE_GOTO_TEXT,
} from "util/constants/invoices";
import { useFixedInvoice } from "src/hooks/useFixedInvoice";

type Props = {
  variant?: string;
};

export function OverdueInvoiceWarning({ variant }: Props) {
  const { isInvoiceOverdueOrInCollection } = useFixedInvoice();
  if (!isInvoiceOverdueOrInCollection) {
    return null;
  }
  return (
    <Notabene>
      <Notabene.Icon name="invoice" />
      <Notabene.Content>
        <Notabene.Heading>{INVOICE_OVERDUE_WARNING_HEADING}</Notabene.Heading>
        <Text mb="200">
          {variant === "TV_BOX" ? INVOICE_OVERDUE_WARNING_MESSAGE_TV_BOX : INVOICE_OVERDUE_WARNING_MESSAGE}
        </Text>
        <Link to="/minside/faktura">
          {INOICE_GOTO_TEXT}
          <Icon name="arrow-right" />
        </Link>
      </Notabene.Content>
    </Notabene>
  );
}
