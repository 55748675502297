import { graphql } from "src/gql";

export const GET_PREPAID_ORDER_HISTORY = graphql(`
  query getPrepaidOrderHistory($phoneNumber: String!, $nextIndex: Int!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      orderHistory(options: { paging: { nextIndex: $nextIndex } }) {
        topupOrders {
          orderId
          date
          product {
            name
          }
          payment {
            amount {
              amount
              currency
            }
          }
        }
        options {
          paging {
            nextIndex
          }
        }
      }
    }
  }
`);
