import {
  Alert,
  Button,
  Card,
  Container,
  ExpandableCard,
  Heading,
  Paragraph,
  useToast,
} from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { useFetchUser } from "src/api/fixed";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { ExpandableCardTitle } from "../styles/MoveOutRegistration.styled";
import { MoveOutForm, MoveOutFormData } from "./MoveOutForm";
import { SkeletonForm } from "../../profile/common/SkeletonForm";
import { useEffect, useState } from "react";
import { MoveOutRegistrationSuccess } from "./MoveOutRegistrationSuccess";
import { toMoveOutPayload } from "../utils/toMoveOutPayload";
import type { SalesForceTicket } from "../types";

export function MoveOutRegistration() {
  const { addToast } = useToast();
  const [moveOutTicket, setMoveOutTicket] = useState<SalesForceTicket | null>(null);
  const [moveOutDate, setMoveOutDate] = useState("");

  const { data: userData, isLoading: isUserLoading, isError: fetchUserError } = useFetchUser();
  const selectedUser = userData?.usersWithCustomerId.find((user) => user.selected);

  useEffect(() => {
    setMoveOutTicket(null);
    setMoveOutDate("");
  }, [selectedUser]);

  const registerMoveOut = useAxiosPost<SalesForceTicket>(uri.minSideFixed("/api/user/moveout"), {
    onSuccess: (data) => {
      if (!data || data?.status !== "Registered") {
        showErrorToast();
      } else {
        setMoveOutTicket(data);
      }
    },
    onError: () => showErrorToast(),
  });

  const showErrorToast = () =>
    addToast({
      text: "En feil oppsto. Prøv igjen om noen minutter, eller ta kontakt med kundesenteret.",
      variant: "error",
    });

  const submitMoveOut = (data: MoveOutFormData) => {
    setMoveOutDate(data.moveDate);
    const payload = toMoveOutPayload(data);
    registerMoveOut.post(payload);
  };

  if (!isUserLoading && fetchUserError) {
    return (
      <Alert
        icon="error-filled"
        kind="negative"
        title="En feil oppsto."
        text="Prøv igjen om noen minutter, eller ta kontakt med kundesenteret."
        canClose={false}
        isOpen
      />
    );
  }

  if (!isUserLoading && !userData?.usersWithCustomerId) {
    return (
      <Container>
        <Paragraph>Vi kunne ikke finne noen tv eller internett-abonnement på denne kontoen</Paragraph>
        <Container padding="vertical">
          <Button variant="secondary" tag="a" href={uri.minSideFixed("/hjem")} isInternalNavigation icon="home">
            Tilbake til Hjem
          </Button>
        </Container>
      </Container>
    );
  }

  if (moveOutTicket) return <MoveOutRegistrationSuccess date={moveOutDate} />;

  return (
    <>
      <Container flexDirection="column" gap padding="bottom">
        <Heading tag="h3" variant="title-100">
          Jeg bor i enebolig/villa
        </Heading>
        <Paragraph>Hvis du flytter fra enebolig/villa melder du flyttingen på telia.no</Paragraph>
        <Card removeMinHeight dataTrackingID="report-moving-link" padding="unset">
          <Button
            tag="a"
            href={uri.openPages("/kundeservice/flytting/flytte-enebolig/#skjema")}
            variant="text-purple"
            target="_blank"
            fullWidth
          >
            Gå til flytteskjema for enebolig/villa
          </Button>
        </Card>
      </Container>
      <Container flexDirection="column" gap padding="bottom">
        <Heading tag="h3" variant="title-100">
          Jeg endrer adresse
        </Heading>
        <Paragraph>
          Du er bredbåndskunde hos oss i dag og flytter til en adresse som også har bredbånd fra Telia. Meld flytting og
          ta med deg abonnementet.
        </Paragraph>
        <Card removeMinHeight dataTrackingID="report-moving-link" padding="unset">
          <Button
            tag="a"
            href={uri.openPages("/borettslag/beboer/meld-adresseendring/")}
            variant="text-purple"
            target="_blank"
            fullWidth
          >
            Jeg endrer adresse og vil melde flytting
          </Button>
        </Card>
      </Container>
      <Container flexDirection="column" gap>
        <Heading tag="h3" variant="title-100">
          Jeg vil si opp avtalen
        </Heading>
        <Paragraph>
          Du flytter til en adresse som ikke har bredbånd fra Telia og vil dermed si opp din avtale.{" "}
        </Paragraph>
        <ExpandableCard>
          <ExpandableCard.Trigger>
            <ExpandableCardTitle>Jeg vil si opp avtalen</ExpandableCardTitle>
          </ExpandableCard.Trigger>
          <ExpandableCard.Content>
            <Container>
              <Paragraph variant="additional-50">
                Fyll inn skjemaet under, så kontakter vi deg for informasjon om hva du skal gjøre med utstyret ditt.
              </Paragraph>
              {selectedUser ? (
                <MoveOutForm
                  selectedUser={selectedUser}
                  key={selectedUser.customerId}
                  onFormSubmit={submitMoveOut}
                  isLoading={registerMoveOut.isLoading}
                />
              ) : (
                <SkeletonForm />
              )}
            </Container>
          </ExpandableCard.Content>
        </ExpandableCard>
      </Container>
    </>
  );
}
