import React, { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { enableTabKeyDetection } from "@telia/styleguide";
import { Button } from "../Button";
import { useWindowSize } from "../hooks";
import { MinSideButton } from "./MinSideButton";
import { OpenPagesIconLink } from "./OpenPagesIconLink";
import { SideMenu } from "./SideMenu";
import { Content, MainLayoutContainer, MenuAndContent, SideMenuButton, TopMenu } from "./style";
import { getMenuLinks } from "./utils/links";

export type MinSide = "fixed" | "mobile";

export type Props = {
  /**
   * Is it Min-Side mobile or fixed
   */
  minSide: MinSide;
  /**
   * If the customer is a partner and have an external invoice,
   * you can provide a link here.
   */
  fixedInvoiceLink?: string;
  hideMenu?: boolean;
  isNewInvoice?: boolean;
  children: ReactNode;
  trackingId?: string;
  limitedAccess?: boolean;
  hideMinsideButton?: boolean;
};

export function MainLayout(props: Props) {
  const {
    minSide,
    fixedInvoiceLink,
    hideMenu = false,
    isNewInvoice,
    children,
    trackingId,
    limitedAccess,
    hideMinsideButton = false,
  } = props;
  const [navBarHeight, setNavBarHeight] = useState(0);

  const navBarRef = useRef<HTMLDivElement>(null);
  const [slideInSideMenu, setSlideInSideMenu] = useState<boolean>(false);
  const { isMobile } = useWindowSize();

  const menuLinks = getMenuLinks(minSide, {
    fixedInvoiceLink,
    isNewInvoice,
  });

  enableTabKeyDetection();

  useLayoutEffect(() => {
    const currentNavBarHeight = navBarRef.current?.offsetHeight;

    if (currentNavBarHeight && currentNavBarHeight !== navBarHeight) {
      setNavBarHeight(navBarRef.current.offsetHeight);
    }
  }, [navBarRef, navBarHeight]);

  return (
    <MainLayoutContainer data-id={trackingId}>
      <TopMenu>
        {isMobile && <OpenPagesIconLink />}
        <MinSideButton minSide={minSide} limitedAccess={!!limitedAccess} hideMinsideButton={!!hideMinsideButton} />
      </TopMenu>
      {hideMenu ? (
        <Content hideMinsideButton={!!hideMinsideButton}>{children}</Content>
      ) : (
        <MenuAndContent>
          <SideMenu
            slideInSideMenuButtonClick={slideInSideMenu}
            setSlideInSideMenu={setSlideInSideMenu}
            menuLinks={menuLinks}
            limitedAccess={limitedAccess}
          />
          <Content hideMinsideButton={!!hideMinsideButton}>{children}</Content>
          {isMobile && (
            <SideMenuButton>
              <Button
                id="side-menu-mobile-button"
                variant="tertiary-black"
                size="sm"
                isIconButton
                iconSize="md"
                title={slideInSideMenu ? "Lukk meny" : "Åpne meny"}
                icon={slideInSideMenu ? "close" : "menu"}
                onClick={() => setSlideInSideMenu((prevState) => !prevState)}
              />
            </SideMenuButton>
          )}
        </MenuAndContent>
      )}
    </MainLayoutContainer>
  );
}
