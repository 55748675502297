import { Container } from "@telia-no-min-side/components";
import { GdprRequest } from "src/gql/graphql";
import { SendRequest } from "../components/SendRequest";

export function RightOfPersonalDataPage() {
  return (
    <Container showGoBackButton padding flexDirection="column" gap="lg">
      <SendRequest operation={GdprRequest.Portability} />,
    </Container>
  );
}
