const isLocalhost = window.location.hostname.includes("localhost");

const withFakelogin = (path) => {
  if (isLocalhost) {
    if (path.indexOf("fakeloginas") !== -1) {
      return path;
    }

    const fakeloginasValue = /fakeloginas=([^&]+)/g.exec(
      window.location.search
    );
    const fakeloginasQuerystring = fakeloginasValue
      ? "fakeloginas=" + fakeloginasValue[1]
      : "";

    if (path.indexOf("?") >= 0) {
      const split = path.split("?");

      return split[0] + "?" + fakeloginasQuerystring + split[1];
    } else if (fakeloginasValue) {
      return path + "?" + fakeloginasQuerystring;
    } else {
      return path;
    }
  } else {
    return path;
  }
};

function urlEncoded(data = {}) {
  function toKV(key, value) {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  const keyValues = Object.keys(data).reduce((acc, key) => {
    const value = data[key];

    if (Array.isArray(value)) {
      acc = acc.concat(
        value
          .filter((item) => typeof item !== "undefined")
          .map((item) => toKV(key, item))
      );
    } else if (value) {
      acc.push(toKV(key, value));
    }
    return acc;
  }, []);

  return keyValues.join("&");
}

const UriHelper = {
  urlWithQuery(uri, query = {}) {
    if (Object.keys(query).length > 0) {
      return uri + (uri.includes("?") ? "&" : "?") + urlEncoded(query);
    }
    return uri;
  },

  getQueryParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(window.location.search);

    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  },

  withFakelogin,
};

export default UriHelper;
