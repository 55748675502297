import React from "react";
import MaskedInput from "react-text-mask";
import classnames from "classnames";
import { generalUtilities } from "../../util/GeneralUtilities";

import "./style.less";

export const MaskedInputWithTitle = ({
  mask,
  value,
  onChange,
  disabled,
  width,
  type,
  min,
  max,
  invalid,
  onBlur,
  title,
  autoCapitalize,
}) => (
  <div className="masked-input-outer-container">
    {title && (
      <div
        className={classnames(
          generalUtilities.isMobile() ? "font-size-20" : "font-size-24",
          invalid && "invalid",
          "margin-bottom"
        )}
      >
        {title}
      </div>
    )}
    <div
      className={classnames("input-container", disabled && "disabled-input", invalid && "invalid")}
      style={{ width: width ? width : "100%" }}
    >
      <MaskedInput
        mask={mask}
        disabled={disabled}
        className={generalUtilities.isMobile() ? "font-size-20" : "font-size-30"}
        type={type}
        value={value}
        onChange={(val) => onChange(val)}
        min={min ? min : ""}
        max={max ? max : ""}
        onBlur={onBlur}
        autoCapitalize={autoCapitalize ? "on" : "none"}
      />
    </div>
  </div>
);
