import React from "react";
import { Spinner } from "../../../../components/spinner-telia/Spinner";
import REQ from "../../../../util/requestStatus";
import { RouterSettingsActions } from "../../actions/RouterSettingActions";
import { ErrorPageContainer } from "../../modules/ErrorPageContainer";
import { useRouterSettings } from "../../stores/RouterSettingsProvider";
import { ErrorCodeResolver } from "./ErrorCodeResolver";

export const ErrorStatus = () => {
  const { routerSettings, dispatch } = useRouterSettings();
  const { devices, fetchStatus, activeUnitSerialNumber } = routerSettings;

  if (fetchStatus === REQ.ERROR) {
    return <ErrorCodeResolver code={devices} onRetry={() => RouterSettingsActions.fetchRouterSettings(dispatch)} />;
  }

  if (devices && devices.length === 0) {
    return <ErrorCodeResolver code={404} onRetry={() => RouterSettingsActions.fetchRouterSettings(dispatch)} />;
  }

  if (fetchStatus === REQ.INIT || !devices || !activeUnitSerialNumber) {
    return (
      <ErrorPageContainer>
        <Spinner />
        <div style={{ height: "5rem" }} />
        Vent litt, vi henter innstillingene dine!
      </ErrorPageContainer>
    );
  }
};
