import { Card, FlipCard, Text } from "@telia/teddy";

export function CoverageGuaranteeTerms() {
  return (
    <div>
      <Card.Heading variant="title-400">Vilkår</Card.Heading>
      <Card.Line mt="250" mb="250" />
      <Card.Content mb="200">
        <Text>
          Som ny kunde har du dekningsgaranti opptil 10 GB. Skulle du være så uheldig å ikke ha god nok dekning, kan du
          returnere utstyret i god stand innen 60 dager, forutsatt at du har brukt maks 10 GB. Returner i butikk eller
          kontakt oss for returlapp.
        </Text>
      </Card.Content>
      <FlipCard.Footer>
        <FlipCard.Trigger variant="back">Lukk</FlipCard.Trigger>
      </FlipCard.Footer>
    </div>
  );
}
