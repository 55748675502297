import { Flex } from "@telia/teddy";
import { Container, Icon } from "@telia-no-min-side/components";
import { types } from "@telia-no-min-side/utils";
import { getCardTitle } from "pages/flex/utils/getCardTitle";
import { getCardSubTitle } from "pages/flex/utils/getCardSubTitle";
import { ProductCardSkeleton } from "./ProductCardSkeleton";
import { ProductCardContent } from "./ProductCardContent";

type Props = {
  isLoading: boolean;
  onClick?: () => void;
  tvOption: types.fixed.TvOption | undefined;
  broadbandOption: types.fixed.BroadbandOption | undefined;
  price: number;
  chargePeriod: string;
  showActiveProductBadge?: boolean;
  hideSelectProductButton?: boolean;
};

export function ProductCard({
  isLoading,
  onClick,
  tvOption,
  broadbandOption,
  price,
  chargePeriod,
  showActiveProductBadge = false,
  hideSelectProductButton = false,
}: Props) {
  const useTvOptionData = (tvOption: types.fixed.TvOption | undefined) => ({
    tvLogos: tvOption?.logos || [],
    tvPoints: tvOption?.points || 0,
    tvSalesText: tvOption?.salesText || "",
  });

  const useBroadbandOptionData = (broadbandOption: types.fixed.BroadbandOption | undefined) => ({
    broadbandSpeed: broadbandOption?.downloadSpeed || 0,
    broadbandSalesText: broadbandOption?.salesText || "",
  });

  const { tvLogos, tvPoints, tvSalesText } = useTvOptionData(tvOption);
  const { broadbandSpeed, broadbandSalesText } = useBroadbandOptionData(broadbandOption);
  const cardTitle = getCardTitle(broadbandSpeed, tvPoints);
  const cardSubTitle = getCardSubTitle(broadbandSpeed, tvPoints);

  const renderPeakContent = (isOpen: boolean, broadbandSpeed: number, tvPoints: number) => {
    if (isOpen) return null;

    return (
      <Flex direction="row" gap="100">
        {broadbandSpeed > 0 && <Icon icon="speedometer-dots" title="Bredbånd" />}
        {tvPoints > 0 && <Icon icon="entertainment" title="Tv" />}
      </Flex>
    );
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <ProductCardSkeleton />
      </Container>
    );
  }

  return (
    <ProductCardContent
      showActiveProductBadge={showActiveProductBadge}
      cardTitle={cardTitle}
      cardSubTitle={cardSubTitle}
      price={price}
      chargePeriod={chargePeriod}
      renderPeakContent={renderPeakContent}
      broadbandSpeed={broadbandSpeed}
      tvPoints={tvPoints}
      broadbandSalesText={broadbandSalesText}
      tvSalesText={tvSalesText}
      tvLogos={tvLogos}
      hideSelectProductButton={hideSelectProductButton}
      onClick={onClick}
    />
  );
}
