import { Alert, Button, Container, Heading, Paragraph, Skeleton } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerCareLink } from "src/components/customer-care-link";
import { useFetchCancelAgreement } from "./fetcher";

export function CancelAgreementReceipt() {
  const { agreementDetailId } = useParams();
  const cancelAgreement = useFetchCancelAgreement({ agreementDetailId });
  const navigate = useNavigate();
  const productToCancel = cancelAgreement.data?.productTitle.length ? cancelAgreement.data.productTitle : "tjeneste";
  if (cancelAgreement.isLoading && !cancelAgreement.data) {
    return (
      <Container padding showGoBackButton maxWidth="sm">
        <Skeleton variant="heading-m" width="10ch" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Container>
    );
  }
  if ((cancelAgreement.error && !cancelAgreement.data) || !agreementDetailId) {
    return (
      <Container padding showGoBackButton maxWidth="sm">
        <Heading tag="h2" variant="title-300">
          Avbestilling registrert
        </Heading>
        <Paragraph>Beklager! Vi klarte dessverre ikke hente nødvendig informasjon</Paragraph>
        <Paragraph>Vennligst prøv igjen litt senere. Eller ring kundeservice</Paragraph>
        <CustomerCareLink trackingContext="cancel-agreement" />
        <Container gap padding="vertical" justifyContent="flex-end" flexDirection="row">
          <Button variant="secondary" onClick={() => navigate(-1)}>
            Avbryt
          </Button>
          <Button isLoading={cancelAgreement.isValidating} onClick={() => cancelAgreement.mutate()}>
            Prøv igjen
          </Button>
        </Container>
      </Container>
    );
  }
  const isRecording = cancelAgreement.data?.productTitle === "Opptak";
  return (
    <Container padding showGoBackButton maxWidth="sm">
      <Heading tag="h2" variant="title-300">
        Avbestilling registrert
      </Heading>

      {isRecording && (
        <Container padding="vertical">
          <Alert kind="info" isOpen canClose={false} title="Opptakene dine slettes" icon="info-filled">
            Merk at opptakene dine slettes 3 måneder etter avbestilling, med mindre du bestiller Opptak igjen før det
            har gått 3 måneder.
          </Alert>
        </Container>
      )}
      <Paragraph>
        Din avbestilling av <em>{productToCancel}</em> er registrert og vil bli gjeldende fra{" "}
        {cancelAgreement.data?.cancellationDate}
      </Paragraph>
      <Container padding="vertical" gap justifyContent="flex-end" flexDirection="row">
        <Button tag="a" isInternalNavigation href={uri.minSideFixed("/hjem")} icon="home">
          Tilbake til Hjem
        </Button>
      </Container>
    </Container>
  );
}
