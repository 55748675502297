import styled from "styled-components";
import { colors } from "@telia/styleguide";
import { ActionMenuButtonStyled } from "../ActionMenuButton/style";
import { devices } from "../utils/breakpoints";

export const HorseshoeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;
  margin: auto;

  @media ${devices.sm_down} {
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const HorseshoeContainerGrid = styled.div`
  display: flex;

  @media ${devices.sm_down} {
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
export const HorseshoeButtonContainer = styled.div`
  padding-top: calc(50% - 4rem);

  @media ${devices.sm_down} {
    order: 2;
    padding-top: 0;
    min-width: 20%;
    display: flex;
    justify-content: center;
  }
`;

export const HorseshoeButton = styled(ActionMenuButtonStyled)`
  background-color: inherit;
  border: 3px solid ${colors.corePurple};
  :active,
  :hover {
    background-color: inherit;
    border: 3px solid ${colors.deepPurple};
  }
  :disabled {
    cursor: default;
    border: 3px solid ${colors.grey200};
  }
`;

export const HorseshoeSvgContainer = styled.div`
  width: 320px;
  height: 300px;
  display: flex;
  justify-content: center;

  svg path {
    fill: white;
  }

  @media ${devices.sm_down} {
    height: 260px;
    width: 100%;
    order: 1;
  }
`;

export const HorseshoeTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.grey800};
  height: 300px;

  @media ${devices.sm_down} {
    height: 260px;
  }
`;

export const HorseshoeTitle = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  margin: 0;
`;

export const HorseshoeUnderTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
`;
export const HorseShoePrice = styled.div`
  height: 3rem;
`;

export const HorseshoeText = styled.p`
  max-width: 90%;
  font-size: 1rem;
  text-align: center;
  margin: auto;
  margin-top: 0.6rem;
  font-weight: 400;

  @media ${devices.sm_down} {
    font-size: 0.8rem;
  }
`;

export const StyledHeading = styled.h4`
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
`;
