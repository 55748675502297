import styled from "styled-components";
import { colors } from "@telia/styleguide";
import { Link } from "@telia-no-min-side/components";

export const BlackLink = styled(Link)`
  color: var(--black);
  svg {
    color: var(--black);
  }
`;

export const PhoneLink = styled(BlackLink)`
  background-color: ${colors.grey100};
  border-radius: 8px;
  padding: 0.2rem 0.8rem;
  span {
    text-decoration: none;
  }
`;
