import { Accordion, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";

export function ForgotToPayAccordion(): JSX.Element {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Hva skjer hvis jeg glemmer å betale en faktura?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Har du glemt en faktura får du en påminnelse av oss, men har vi ikke mottatt betalingen innen slutten av en
          måned vil vi overføre det utestående beløpet til neste måneds faktura, slik at denne da gjelder for begge
          månedene.
        </Paragraph>
        <Paragraph>
          Et purregebyr på kr 70,- blir da lagt til. For beløp som forfaller etter 1. oktober endres purregebyret til
          35,-.
        </Paragraph>
        <Paragraph>
          For å unngå sperring av abonnementet ditt må hele dette beløpet betales innen forfallsdatoen. Husk at du
          alltid kan betale fakturaen din direkte på Min Side.
        </Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
