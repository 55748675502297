import { Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { useFixedInvoice } from "src/hooks/useFixedInvoice";
import { OverdueInvoiceWarning } from "src/components/overdue-invoice";
import { PackageSkeleton } from "../components/PackageSkeleton";
import { Package } from "../components/Package";
import { useFlexOrder } from "../../context/FlexOrderProvider";

export function Step2View() {
  const { isLoading, activeFlexProduct, flexProductOffer } = useFlexOrder();
  const { isInvoiceOverdueOrInCollection } = useFixedInvoice();

  const activeDownloadSpeed = activeFlexProduct?.broadbandOption?.downloadSpeed || 0;
  const offerDownloadSpeed = flexProductOffer?.broadbandOption?.downloadSpeed || 0;

  const activeTvPoints = activeFlexProduct?.tvOption?.points || 0;
  const offerTvPoints = flexProductOffer?.tvOption?.points || 0;

  const isCustomersCurrentProduct = activeDownloadSpeed === offerDownloadSpeed && activeTvPoints === offerTvPoints;

  return (
    <>
      <Container flexDirection="column" gap maxWidth="sm">
        <Heading tag="h3" variant="title-200" removeMargin>
          Skreddersy din valgte Flex-pakke
        </Heading>
        <Paragraph>
          Her kan du skreddersy valgt pakke ved å legge til mer internetthastighet eller TV-poeng hvis du ønsker det.
        </Paragraph>
      </Container>

      <Container maxWidth="sm">
        <OverdueInvoiceWarning />
        {!isInvoiceOverdueOrInCollection && (
          <>
            {isLoading && !flexProductOffer ? (
              <PackageSkeleton />
            ) : flexProductOffer ? (
              <Package isCustomersCurrentProduct={isCustomersCurrentProduct} />
            ) : null}
          </>
        )}
      </Container>
    </>
  );
}
