// NOTE: This is a short term solution to fix data-usage detailed usage UI.
// Long term solution is for CBM to return data in the correct format.

import { notEmpty } from "@telia-no-min-side/utils";
import { GetMobileDashboardDataQuery } from "src/gql/graphql";
import { ArrayElement, Quantity } from "./sortDataUsage";

export type Usage = ArrayElement<GetMobileDashboardDataQuery["subscription"]["usage"]>;

export type UsageDataType = {
  type: string;
  limit: Quantity;
  remaining: Quantity;
};

type GroupedDataType = Record<string, UsageDataType>;

export const mergeDataUsageByType = (dataUsage: GetMobileDashboardDataQuery["subscription"]["usage"]) => {
  if (!dataUsage) return [];

  const groupedData: GroupedDataType = {};

  dataUsage.filter(notEmpty).forEach((usage) => {
    if (!usage.type) return;

    if (groupedData[usage.type]) {
      groupedData[usage.type].limit.amount += usage?.limit?.amount || 0;
      groupedData[usage.type].remaining.amount += usage?.remaining?.amount || 0;
    } else {
      groupedData[usage.type] = {
        type: usage?.type,
        limit: {
          amount: usage?.limit?.amount || 0,
          unit: usage?.limit?.unit || "",
        },
        remaining: {
          amount: usage?.remaining?.amount || 0,
          unit: usage?.remaining?.unit || "",
        },
      };
    }
  });

  return Object.values(groupedData);
};
