import cloneDeep from "lodash.clonedeep";

export const ipAddressUtility = {
  updateOctet(address, event, octetNumberToChange) {
    const parsedInt =
      event.target.value.length > 3
        ? parseInt(event.target.value.substring(0, 3), 10)
        : parseInt(event.target.value, 10);

    const value = event.target.value === "" ? "" : parsedInt;
    const updatedAddress = cloneDeep(address);

    switch (octetNumberToChange) {
      case 1:
        updatedAddress.Octet1 = value;
        break;
      case 2:
        updatedAddress.Octet2 = value;
        break;
      case 3:
        updatedAddress.Octet3 = value;
        break;
      case 4:
        updatedAddress.Octet4 = value;
        break;
    }
    return updatedAddress;
  },
};
