import { useQuery } from "@apollo/client";
import {
  Container,
  FullPageLoading,
  Heading,
  HorizontalScroll,
  ToggleButtonGroup,
} from "@telia-no-min-side/components";
import { format, types } from "@telia-no-min-side/utils";
import { useEffect } from "react";
import { graphql } from "src/gql";
import { UserRole } from "src/gql/graphql";
import { useSelectAccount } from "src/hooks/useSelectAccount";
import { useInvoice } from "src/store/hooks/invoice-context";
import { useFetchUser } from "src/api/fixed";
import { ProductUser } from "./ProductUser";
import { InvoiceError } from "./components/InvoiceError";
import { FixedInvoiceComponent } from "./fixed/FixedInvoiceComponent";
import { MobileInvoiceComponent } from "./mobile/MobileInvoiceComponent";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { AUTH } from "util/constants/auth";
import { isInvoicePaymentOverdue } from "./mobile/utils/isInvoicePaymentOverdue";

const invoiceProfileInfoQuery = graphql(`
  query InvoiceProfileInfo {
    accounts {
      id
      subscriptions {
        productUser {
          firstName
        }
        phoneNumber {
          localNumber
        }
        userOffering {
          pricePlan
        }
      }
    }
  }
`);

// This data is used to show a warning icon when invoice is overdue.
// It's loading state and errors can be ignored, because it's not critical data.
// Unlike invoiceProfileInfoQuery it fetches just accounts where you are
// a legal owner or invoice payer.
const invoiceProfileStatusQuery = graphql(`
  query InvoiceProfileStatus($filter: AccountFilter) {
    accounts(filter: $filter) {
      id
      paymentArrangement(status: Open, type: FaxPayment) {
        expectedPaymentDate
      }
      paymentBalance {
        dueDate
        amount
      }
    }
  }
`);

export function Invoice() {
  const { setAccount, selectedAccountType, selectedFixedAccount, selectedMobileAccount, selectedAccountId } =
    useSelectAccount();
  const { setPhoneNumber } = useMobileAccount();
  const user = useFetchUser();
  const { resetPaginationData } = useInvoice();
  const invoiceProfileInfo = useQuery(invoiceProfileInfoQuery);
  const invoiceStatus = useQuery(invoiceProfileStatusQuery, {
    variables: {
      filter: { invoicesVisible: true },
    },
  });

  useEffect(() => {
    if (
      !selectedMobileAccount?.isLoading &&
      !selectedMobileAccount?.allAccounts?.length &&
      selectedFixedAccount?.data?.customerId &&
      selectedAccountType === AUTH.ACCOUNT_TYPE.MOBILE
    ) {
      setAccount({ id: String(selectedFixedAccount.data.customerId), accountType: AUTH.ACCOUNT_TYPE.FIXED });
    }
  }, [selectedFixedAccount, selectedMobileAccount, selectedAccountType]);

  if (
    selectedMobileAccount?.isLoading ||
    invoiceProfileInfo.loading ||
    (user.isLoading && !user.data) ||
    (selectedFixedAccount?.isLoading && !selectedFixedAccount.data)
  ) {
    return (
      <Container padding>
        <FullPageLoading />
      </Container>
    );
  }

  if (selectedMobileAccount?.error && user.error) {
    return (
      <Container padding maxWidth="sm">
        <InvoiceError
          onRefresh={() => {
            selectedMobileAccount.refetch();
            user.mutate();
          }}
        />
      </Container>
    );
  }

  const selectedAccount = selectedMobileAccount?.allAccounts?.find(
    (account) => account.id === selectedMobileAccount.accountId
  );

  const isLegalOwnerOrInvoicePayer = selectedAccount?.roles.includes(UserRole.LegalOwner || UserRole.InvoicePayer);

  return (
    <Container maxWidth="full" padding flexDirection="column">
      <Heading variant="title-300" tag="h2">
        Faktura
      </Heading>
      <HorizontalScroll addContainerScrollMargin>
        {({ scrollBy }) => (
          <Container gap="lg" padding="vertical">
            <ToggleButtonGroup variant="profile-picker" value={selectedAccountId}>
              {invoiceProfileInfo?.data?.accounts?.map((account) => {
                const numberOfSubscriptions = account?.subscriptions?.length || 0;
                const singleSubscription =
                  numberOfSubscriptions === 1
                    ? account?.subscriptions?.find((sub) => sub.phoneNumber.localNumber)
                    : null;

                const accountWithInvoiceStatus = invoiceStatus.data?.accounts?.find((ac) => ac.id === account.id);
                const isPaymentOverdue = isInvoicePaymentOverdue(
                  accountWithInvoiceStatus?.paymentArrangement,
                  accountWithInvoiceStatus?.paymentBalance
                );

                return (
                  <ToggleButtonGroup.Item
                    scrollBy={scrollBy}
                    id={account.id}
                    key={account.id}
                    text={
                      singleSubscription?.productUser
                        ? format.startCase(singleSubscription.productUser.firstName)
                        : "Mobil"
                    }
                    onClick={(e, accountId) => {
                      setAccount({ id: accountId, accountType: AUTH.ACCOUNT_TYPE.MOBILE });
                      if (singleSubscription) {
                        setPhoneNumber(singleSubscription.phoneNumber.localNumber || "");
                      }
                      resetPaginationData();
                    }}
                    value={account.id}
                    secondaryText={
                      singleSubscription
                        ? singleSubscription.phoneNumber.localNumber
                        : numberOfSubscriptions > 1
                        ? `${numberOfSubscriptions} abonnementer`
                        : ""
                    }
                    lozengeRight={isPaymentOverdue ? { status: "warning", icon: "alert" } : undefined}
                  />
                );
              })}
              {user.data?.usersWithCustomerId.map((customer: types.fixed.UserWithCustomerId) => (
                <ToggleButtonGroup.Item
                  scrollBy={scrollBy}
                  id={customer.street}
                  onClick={(e, accountId) => {
                    setAccount({ id: accountId, accountType: AUTH.ACCOUNT_TYPE.FIXED });
                    resetPaginationData();
                  }}
                  key={`${customer.customerId}`}
                  value={customer.customerId}
                  text="TV/internett"
                  secondaryText={format.startCase(customer.street)}
                />
              ))}
            </ToggleButtonGroup>
          </Container>
        )}
      </HorizontalScroll>
      {selectedAccountType === AUTH.ACCOUNT_TYPE.MOBILE ? (
        <>
          {isLegalOwnerOrInvoicePayer ? (
            <MobileInvoiceComponent />
          ) : (
            <ProductUser phoneNumber={selectedAccount?.subscriptions?.[0].phoneNumber.localNumber ?? ""} />
          )}
        </>
      ) : (
        <FixedInvoiceComponent isLoadingNewProfile={selectedFixedAccount ? selectedFixedAccount.isLoading : true} />
      )}
    </Container>
  );
}
