import { useEffect } from "react";
import { Container, HorizontalScroll, ToggleButtonGroup, TopSection } from "@telia-no-min-side/components";
import { useSelectAccount } from "hooks/useSelectAccount";
import { AccountTypePicker } from "./common/AccountTypePicker";
import { MobileProfilePage } from "./mobile";
import { FixedProfilePage } from "./fixed";
import { useAccountType } from "./hooks/useAccountType";
import { SkeletonForm } from "./common/SkeletonForm";
import { AUTH } from "util/constants/auth";

export function Profile() {
  const { selectedAccountType, setAccount } = useSelectAccount();
  const { isConvergentUser, mobileUserSelectedAccountId, fixedUserSelectedAccountId, isFetchAccountsLoading } =
    useAccountType();

  // after load default to selected tab based on the selected existing id for non convergent users
  useEffect(() => {
    if (isFetchAccountsLoading) return;
    if (isConvergentUser) return;
    if (mobileUserSelectedAccountId) {
      setAccount({ id: mobileUserSelectedAccountId, accountType: AUTH.ACCOUNT_TYPE.MOBILE });
    } else if (fixedUserSelectedAccountId) {
      setAccount({ id: fixedUserSelectedAccountId, accountType: AUTH.ACCOUNT_TYPE.FIXED });
    }
  }, [isFetchAccountsLoading]);

  if (isFetchAccountsLoading) {
    return (
      <>
        <Container flexDirection="column" padding>
          <HorizontalScroll addContainerScrollMargin>
            <Container gap="lg" maxWidth="full" data-tracking-id="profile-picker-loading">
              {[...Array(2)].map((_, i) => (
                <ToggleButtonGroup.Item.Skeleton variant="profile-picker" key={`toggle-button-skeleton-${i}`} />
              ))}
            </Container>
          </HorizontalScroll>
        </Container>

        <Container padding flexDirection="row" flexWrap>
          <SkeletonForm />
        </Container>
      </>
    );
  }

  return (
    <TopSection>
      <AccountTypePicker />
      {selectedAccountType === AUTH.ACCOUNT_TYPE.MOBILE && <MobileProfilePage />}
      {selectedAccountType === AUTH.ACCOUNT_TYPE.FIXED && <FixedProfilePage />}
    </TopSection>
  );
}
