import { Button, Card, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getGDPRErrorContent } from "../utils/getGDPRErrorContent";

export enum GDPRErrorType {
  InProgress = "request-already-submitted",
}

export const Error = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const textContent = getGDPRErrorContent(type);

  return (
    <Card>
      <Container padding>
        <Heading tag="h2" variant="title-100">
          {textContent.heading}
        </Heading>
        <Paragraph>{textContent.paragraph}</Paragraph>
        <Container gap padding="top">
          {!type && (
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Prøv igjen
            </Button>
          )}
          <Button
            variant={type ? "primary" : "secondary"}
            tag="a"
            isInternalNavigation
            hideLinkIcon
            href="/minside/personvern"
          >
            Tilbake til personvern
          </Button>
        </Container>
      </Container>
    </Card>
  );
};
