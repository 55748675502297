import { BaseMutationOptions, MutationResult, gql } from "@apollo/client";
import { ReplaceSimMutation, ReplaceSimMutationVariables } from "src/gql/graphql";
import { useBankIdMutation, ExtraBankIdMutationOptions } from "hooks/useBankIdMutation";

export const REPLACE_SIM = gql`
  mutation replaceSim($phoneNumber: String!, $newSimNumber: String!, $oldSimNumber: String) {
    replaceSim(phoneNumber: $phoneNumber, newSimNumber: $newSimNumber, oldSimNumber: $oldSimNumber) {
      orderId
    }
  }
`;

type Params = {
  phoneNumber: string;
  newSimNumber: string;
  oldSimNumber: string;
};

export type ReplaceSimCard = {
  response: MutationResult;
  runReplaceSim: ({ phoneNumber, newSimNumber, oldSimNumber }: Params, forceSkipBankIdCheck?: boolean) => Promise<void>;
};

type ReplaceSimOptions = BaseMutationOptions<ReplaceSimMutation, ReplaceSimMutationVariables> &
  ExtraBankIdMutationOptions;

export function useReplaceSim({ onCompleted, onError, uniqueKey }: ReplaceSimOptions): ReplaceSimCard {
  const [replaceSim, response] = useBankIdMutation(
    REPLACE_SIM,
    {
      onCompleted,
      onError,
    },
    uniqueKey || "replaceSim"
  );

  const runReplaceSim: ReplaceSimCard["runReplaceSim"] = async (
    { phoneNumber, newSimNumber, oldSimNumber },
    forceSkipBankIdCheck
  ) => {
    const replaceSimData = {
      variables: {
        phoneNumber,
        newSimNumber,
        oldSimNumber,
      },
    };

    try {
      await replaceSim(replaceSimData, forceSkipBankIdCheck);
    } catch (error) {
      console.error(error);
    }
  };

  return { response: response, runReplaceSim };
}
