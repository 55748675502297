import { Accordion, Link, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { StyledHeading } from "../style";

export function InvoiceFeeMobileAccordion(): JSX.Element {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Hvorfor får jeg fakturagebyr og hvordan kan jeg slippe det?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Du får fakturagebyr dersom du har valgt papirfaktura som fakturametode. Du kan slippe å betale gebyr for
          papirfaktura etter første faktura ved å opprette en AvtaleGiro med elektronisk varsling eller eFaktura-avtale
          i nettbanken din.
        </Paragraph>
        <Paragraph>
          Opprett avtalegiro <Link href={uri.minSideFixed("/faktura/innstillinger")}>her.</Link>
        </Paragraph>
        <Paragraph>
          Gebyrer tilkommer ellers når du mottar papirfaktura, fakturapåminnelse per post, eller ved purringer.
        </Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
