import cloneDeep from "lodash.clonedeep";

const macAddressRegex =
  /^[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}$/;

const nameRegex = /^[ 0-9a-zA-Z-_:;,.!]{0,30}$/;

export const ipv4Equals = (first, second) => {
  return (
    first.Octet1 === second.Octet1 &&
    first.Octet2 === second.Octet2 &&
    first.Octet3 === second.Octet3 &&
    first.Octet4 === second.Octet4
  );
};

export const ipv4Compare = (mask, first, second) => {
  const maskValues = Object.values(mask);

  for (let i = 0; i < maskValues.length; i++) {
    switch (i) {
      case 0:
        if (maskValues[i] !== 255) {
          if (first.Octet1 !== second.Octet1) {
            return first.Octet1 - second.Octet1;
          }
        }
        break;
      case 1:
        if (maskValues[i] !== 255) {
          if (first.Octet2 !== second.Octet2) {
            return first.Octet2 - second.Octet2;
          }
        }
        break;
      case 2:
        if (maskValues[i] !== 255) {
          if (first.Octet3 !== second.Octet3) {
            return first.Octet3 - second.Octet3;
          }
        }
        break;
      case 3:
        if (maskValues[i] !== 255) {
          return first.Octet4 - second.Octet4;
        }
        break;
    }
  }
};

const isValidMacAddress = (macAddress) => {
  return macAddressRegex.test(macAddress);
};

const isValidAlias = (staticIpEntry) => {
  const { Alias } = staticIpEntry;

  return Alias === "" || Alias === null || nameRegex.test(Alias);
};

const validateStaticInternalIPEntries = (homeCentral) => {
  const errorMessages = [];

  homeCentral.Settings.StaticInternalIP.StaticInternalIPEntries.forEach(
    (entry, index) => {
      const tag = `static-internal-ip-${index}`;

      if (!isValidMacAddress(entry.MacAddress)) {
        errorMessages.push({
          tag: tag + " mac-address",
          message:
            "må være på formatet: 'XX:XX:XX:XX:XX:XX hvor X må være mellom 0 og F.'",
        });
      }
      if (!isValidAlias(entry)) {
        errorMessages.push({
          tag: tag + " alias",
          message:
            "maksimalt 30 karakterer langt, kan kun inneholde bokstaver(a-z), tall samt -_:;,.!.",
        });
      }
      if (ipv4Equals(homeCentral.Settings.Lan.InternalGWIP, entry.IPAddress)) {
        errorMessages.push({
          tag: tag + " ip-address",
          message: "kan ikke være like Intern IP (gateway).",
        });
      }
      if (entry.IPAddress.Octet4 === 0) {
        errorMessages.push({
          tag: tag + " ip-address",
          message: "kan ikke slutte på null",
        });
      }
      if (Object.values(entry.IPAddress).some((val) => val === "")) {
        errorMessages.push({
          tag: tag + " ip-address",
          message: "alle deler av adressen må ha en verdi.",
        });
      }
    }
  );
  return errorMessages;
};

export const validateUncommitedStaticInternalIPEntry = (entry, homeCentral) => {
  const errorMessages = [];
  const tag = `static-internal-ip-x`;

  if (!isValidMacAddress(entry.MacAddress)) {
    errorMessages.push({
      tag: tag + " mac-address",
      message:
        "må være på formatet: 'XX:XX:XX:XX:XX:XX hvor X må være mellom 0 og F.'",
    });
  }
  if (!isValidAlias(entry)) {
    errorMessages.push({
      tag: tag + " alias",
      message:
        "maksimalt 30 karakterer langt, kan kun inneholde bokstaver, tall samt -_:;,.!.",
    });
  }
  if (ipv4Equals(homeCentral.Settings.Lan.InternalGWIP, entry.IPAddress)) {
    errorMessages.push({
      tag: tag + " ip-address",
      message: "kan ikke være like Intern IP (gateway).",
    });
  }
  if (entry.IPAddress.Octet4 === 0) {
    errorMessages.push({
      tag: tag + " ip-address",
      message: "kan ikke slutte på null",
    });
  }
  if (Object.values(entry.IPAddress).some((val) => val === "")) {
    errorMessages.push({
      tag: tag + " ip-address",
      message: "alle deler av adressen må ha en verdi.",
    });
  }

  return errorMessages;
};

export const ipUtilities = {
  validateHomeCentral: function (homeCentral) {
    const messages = [];
    const DHCPFrom = homeCentral.Settings.Dhcp.FromIP;
    const DHCPTo = homeCentral.Settings.Dhcp.ToIP;
    const mask = homeCentral.Settings.Lan.NetworkMask;
    const gateway = homeCentral.Settings.Lan.InternalGWIP;

    // DHCP
    if (homeCentral.SupportedSettings.Dhcp.Supported) {
      if (ipv4Compare(mask, DHCPFrom, DHCPTo) > 0) {
        messages.push({
          valid: false,
          message: '"Fra" må være et lavere tall enn "til"',
          tag: "dhcp-from",
        });
      }
      if (DHCPFrom.Octet4 === 0) {
        messages.push({
          valid: false,
          message: "Kan ikke slutte på 0",
          tag: "dhcp-from",
        });
      }
      if (DHCPTo.Octet4 === 0) {
        messages.push({
          valid: false,
          message: "Kan ikke slutte på 0",
          tag: "dhcp-to",
        });
      }
      if (ipv4Equals(DHCPFrom, gateway)) {
        messages.push({
          valid: false,
          message: "Kan ikke være lik intern IP (gateway)",
          tag: "dhcp-from",
        });
      }
      if (ipv4Equals(DHCPTo, gateway)) {
        messages.push({
          valid: false,
          message: "Kan ikke være lik intern IP (gateway)",
          tag: "dhcp-to",
        });
      }
    }
    // DMZ
    if (
      homeCentral.Settings.Dmz.Enabled &&
      homeCentral.SupportedSettings.Dmz.Supported
    ) {
      const Dmz = homeCentral.Settings.Dmz.IP;

      if (
        !(
          ipv4Compare(mask, Dmz, DHCPFrom) >= 0 &&
          ipv4Compare(mask, Dmz, DHCPTo) <= 0
        )
      ) {
        messages.push({
          valid: false,
          message: "Velg et tall innenfor DHCP fra-til",
          tag: "dmz",
        });
      }
      if (Dmz.Octet4 === 0) {
        messages.push({
          valid: false,
          message: "Kan ikke slutte på 0",
          tag: "dmz",
        });
      }
      if (ipv4Equals(Dmz, gateway)) {
        messages.push({
          valid: false,
          message: "Kan ikke være lik intern IP (gateway)",
          tag: "dmz",
        });
      }
    }
    // Gateway
    if (
      homeCentral.SupportedSettings.Dhcp.Supported &&
      ipv4Compare(mask, gateway, DHCPFrom) >= 0 &&
      ipv4Compare(mask, gateway, DHCPTo) <= 0
    ) {
      messages.push({
        valid: false,
        message: "Velg et tall utenfor DHCP fra-til",
        tag: "gateway",
      });
    }
    if (gateway.Octet4 === 0) {
      messages.push({
        valid: false,
        message: "Kan ikke slutte på 0",
        tag: "gateway",
      });
    }

    return homeCentral.SupportedSettings.StaticInternalIP.Supported
      ? messages.concat(validateStaticInternalIPEntries(homeCentral))
      : messages;
  },
  updateOctetAccordingToContract: function (
    gatewayValue,
    address,
    contract,
    iteratorCount
  ) {
    if (contract) {
      switch (iteratorCount) {
        case 0:
          if (contract.Octet1.CanChange) {
            address.Octet1 = gatewayValue;
          }
          break;
        case 1:
          if (contract.Octet2.CanChange) {
            address.Octet2 = gatewayValue;
          }
          break;
        case 2:
          if (contract.Octet3.CanChange) {
            address.Octet3 = gatewayValue;
          }
          break;
        case 3:
          if (contract.Octet4.CanChange) {
            address.Octet4 = gatewayValue;
          }
          break;
      }
    } else {
      switch (iteratorCount) {
        case 0:
          address.Octet1 = gatewayValue;
          break;
        case 1:
          address.Octet2 = gatewayValue;
          break;
        case 2:
          address.Octet3 = gatewayValue;
          break;
        case 3:
          address.Octet4 = gatewayValue;
          break;
      }
    }
    return address;
  },
  trickleDownGateWayChanges: function (homeCentral) {
    const maskValues = Object.values(homeCentral.Settings.Lan.NetworkMask);
    const gateWayValues = Object.values(homeCentral.Settings.Lan.InternalGWIP);

    let newPortForwardingRules;

    let newStaticInternalIpEntries;

    // Loop over the network mask values to determine which octets need to be updated on all
    // the other IP addresses on the router.
    for (
      let indexInMaskValuesList = 0;
      indexInMaskValuesList < maskValues.length;
      indexInMaskValuesList++
    ) {
      if (maskValues[indexInMaskValuesList] === 255) {
        const currentGatewayOctetValue = gateWayValues[indexInMaskValuesList];

        // Update the DHCP from IP
        homeCentral.Settings.Dhcp.FromIP = this.updateOctetAccordingToContract(
          currentGatewayOctetValue,
          homeCentral.Settings.Dhcp.FromIP,
          homeCentral.SupportedSettings.Dhcp.FromIP,
          indexInMaskValuesList
        );
        // Update the DHCP to IP
        homeCentral.Settings.Dhcp.ToIP = this.updateOctetAccordingToContract(
          currentGatewayOctetValue,
          homeCentral.Settings.Dhcp.ToIP,
          homeCentral.SupportedSettings.Dhcp.ToIP,
          indexInMaskValuesList
        );
        // Update the DMZ IP
        homeCentral.Settings.Dmz.IP = this.updateOctetAccordingToContract(
          currentGatewayOctetValue,
          homeCentral.Settings.Dmz.IP,
          homeCentral.SupportedSettings.Dmz.IP,
          indexInMaskValuesList
        );
        // Create a new list of portforwarding rules that have their IP addresses updated according to the new
        // gateway values.
        newPortForwardingRules =
          homeCentral.Settings.PortForwarding.PortForwardingRules.map(
            (rule) => {
              const newRule = JSON.parse(JSON.stringify(rule));

              newRule.InternalIP = this.updateOctetAccordingToContract(
                currentGatewayOctetValue,
                rule.InternalIP,
                null,
                indexInMaskValuesList
              );
              return newRule;
            }
          );
        homeCentral.Settings.PortForwarding.PortForwardingRules =
          newPortForwardingRules;
        // Create new list of static internal IP entries so that their IP addresses are updated according to the new
        // gateway values.
        if (homeCentral.SupportedSettings.StaticInternalIP.Supported) {
          newStaticInternalIpEntries =
            homeCentral.Settings.StaticInternalIP.StaticInternalIPEntries.map(
              (entry) => {
                const newEntry = cloneDeep(entry);

                newEntry.IPAddress = this.updateOctetAccordingToContract(
                  currentGatewayOctetValue,
                  entry.IPAddress,
                  null,
                  indexInMaskValuesList
                );
                return newEntry;
              }
            );
          homeCentral.Settings.StaticInternalIP.StaticInternalIPEntries =
            newStaticInternalIpEntries;
        }
      }
    }
    return homeCentral;
  },
  validateAdditionalWifiSettings(settings, supportedSettings) {
    const errorMessages = [];

    if (
      !supportedSettings.SecurityProtocols.includes(settings.SecurityProtocol)
    ) {
      errorMessages.push({
        message:
          "Den trådløse frekvensen støtter ikke denne sikkerhetsprotokollen.",
        tag: "security-protocol",
      });
    }

    const bandwidth = supportedSettings.BandwidthToChannelMaps.find(
      (bandwidth) => bandwidth.Bandwidth === settings.Bandwidth
    );

    if (!bandwidth) {
      errorMessages.push({
        message: "Den trådløse frekvensen støtter ikke denne båndbredden",
        tag: "bandwidth",
      });
    }
    if (!bandwidth.Channels.includes(settings.Channel)) {
      errorMessages.push({
        message: "Den trådløse frekvensen støtter ikke denne kanalen.",
        tag: "channel",
      });
    }
    return errorMessages;
  },
  extractEditableAndUneditableParts(address, mask, contract, typeOfAddress) {
    const maskKeys = Object.keys(mask);
    const addressKeys = Object.keys(address);
    const editablePart = [];
    const uneditablePart = [];
    const contractValues = [];

    Object.keys(contract)
      .map((key) => Object.values(contract[key]))
      .forEach((val) => {
        if (val.length > 0) {
          contractValues.push(val[0]);
        } else {
          contractValues.push(false);
        }
      });

    if (typeOfAddress === "mask" || typeOfAddress === "gateway") {
      for (let i = 0; i < contractValues.length; i++) {
        if (!contractValues[i]) {
          uneditablePart.push({ value: address[addressKeys[i]], octet: i + 1 });
        } else {
          editablePart.push({
            value: address[addressKeys[i]],
            octet: i + 1,
            valid: true,
          });
        }
      }
    } else {
      for (let i = 0; i < maskKeys.length; i++) {
        if (mask[maskKeys[i]] === 255) {
          uneditablePart.push({ value: address[addressKeys[i]], octet: i + 1 });
        } else {
          editablePart.push({
            value: address[addressKeys[i]],
            octet: i + 1,
            valid: true,
          });
        }
      }
    }

    return { uneditable: uneditablePart, editable: editablePart };
  },
  validateOctet(value) {
    if (value === "") {
      return { valid: false, message: "Skriv et tall mellom 1 og 255" };
    }
    if (parseInt(value, 10) > 255) {
      return { valid: false, message: "Skriv et tall mellom 1 og 255" };
    }
    if (parseInt(value, 10) < 0) {
      return { valid: false, message: "Skriv et tall mellom 1 og 255" };
    }
    return { valid: true, message: "" };
  },
  validateSSIDAndPasswordSettings(ssid2GHz, ssid5GHz) {
    if (ssid2GHz === ssid5GHz) {
      return [
        {
          tag: "ssid",
          message:
            "Vi anbefaler at 2.4 GHz og 5 GHz nettverkene ikke har samme navn.",
        },
      ];
    } else {
      return [];
    }
  },
};
