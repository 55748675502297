import React, { ComponentPropsWithoutRef, ReactNode, forwardRef } from "react";
import { v4 as uuid } from "uuid";
import { IconDefinition } from "@telia/styleguide";
import { Icon } from "../Icon";
import {
  TextInputWrapper,
  Label,
  Required,
  Input,
  Additional,
  Error,
  InputIconWrapper,
  IconWrapper,
  IconWrapperRight,
  ClearButton,
} from "./style";

type ClearButtonProps =
  | {
      showClearButton?: never;
      onClearClick?: never;
    }
  | {
      showClearButton: boolean;
      onClearClick: () => void;
    };

export type TextInputProps = ClearButtonProps & {
  children: ReactNode;
  additional?: string;
  disabled?: boolean;
  errorMessage?: string;
  required?: boolean;
  icon?: IconDefinition;
  showEditIcon?: boolean;
  inputMode?: "text" | "email" | "decimal" | "numeric" | "tel" | "search" | "url";
} & Omit<
    ComponentPropsWithoutRef<"input">,
    "id" | "disabled" | "aria-disabled" | "aria-labelledby" | "aria-describedby" | "inputMode"
  >;

/**
 * @deprecated Use Teddy components instead
 */
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function (
  {
    children,
    additional,
    disabled,
    errorMessage,
    required,
    icon,
    showClearButton,
    showEditIcon,
    onClearClick,
    ...inputAttributes
  },
  ref
) {
  const id = uuid();

  function getAriaDescribeBy() {
    if (!additional && !errorMessage) return "";
    if (!additional && errorMessage) return `error-${id}`;
    if (additional && !errorMessage) return `additional-${id}`;
    return `additional-${id} error-${id}`;
  }
  return (
    <TextInputWrapper disabled={disabled}>
      <Label id={`label-${id}`} htmlFor={`input-${id}`}>
        {required && <Required>* </Required>}
        {children}
      </Label>
      <InputIconWrapper>
        {icon && (
          <IconWrapper>
            <Icon icon={icon} />
          </IconWrapper>
        )}
        <Input
          {...inputAttributes}
          ref={ref}
          aria-invalid={!!errorMessage}
          aria-required={required}
          disabled={disabled}
          onKeyDown={(e) => (disabled === true ? false : e)}
          id={`input-${id}`}
          aria-labelledby={`label-${id}`}
          aria-describedby={getAriaDescribeBy()}
          iconAfter={showClearButton || showEditIcon}
          iconBefore={!!icon}
          data-di-mask
        />
        {(showClearButton || showEditIcon) && (
          <IconWrapperRight>
            {showClearButton && (
              <ClearButton aria-label="Tøm" onClick={() => onClearClick && onClearClick()}>
                <Icon icon="close" />
              </ClearButton>
            )}
            {!showClearButton && showEditIcon && <Icon icon="edit" />}
          </IconWrapperRight>
        )}
      </InputIconWrapper>
      {additional && <Additional id={`additional-${id}`}>{additional}</Additional>}
      {errorMessage && (
        <Error id={`error-${id}`}>
          <Icon icon="alert" size="sm" />
          {errorMessage}
        </Error>
      )}
    </TextInputWrapper>
  );
});

TextInput.displayName = "TextInput";
