import { SetStateAction, useEffect } from "react";
import { useFetchProductGroup } from "src/api/fixed";
import { NoBox, TeliaBox, TeliaPlayBox } from "src/components/product-shopping-cards";
import { productId } from "src/util/productIds";

type SelectTvBoxListProps = {
  setSelectedBoxAxiomId: (value: SetStateAction<number | null>) => void;
  selectedBoxAxiomId: number | null;
  shouldDisableSelect?: boolean;
};

export function SelectTvBoxList({
  selectedBoxAxiomId,
  setSelectedBoxAxiomId,
  shouldDisableSelect,
}: SelectTvBoxListProps): JSX.Element | null {
  const newTvBoxesOptions = useFetchProductGroup("grp_new_tv_box");

  const hasMultipleTvBoxOptions = newTvBoxesOptions.data ? newTvBoxesOptions.data?.length > 1 : false;

  useEffect(() => {
    if (!hasMultipleTvBoxOptions) {
      const axiomId = newTvBoxesOptions.data?.find((box) => box.axiomId)?.axiomId;
      setSelectedBoxAxiomId(axiomId || null);
    }
  }, [hasMultipleTvBoxOptions, newTvBoxesOptions]);

  return (
    <>
      {newTvBoxesOptions.data?.map((tvBox) => {
        const isSelected = tvBox.axiomId === selectedBoxAxiomId;

        if (productId.teliaPlayBox == tvBox.productId) {
          return (
            <TeliaPlayBox
              shouldDisableSelect={shouldDisableSelect}
              key={tvBox.axiomId}
              product={tvBox}
              isSelected={isSelected}
              onSelectClick={hasMultipleTvBoxOptions ? () => setSelectedBoxAxiomId(tvBox.axiomId) : undefined}
            />
          );
        }

        if (productId.teliaBox.includes(tvBox.productId)) {
          return (
            <TeliaBox
              shouldDisableSelect={shouldDisableSelect}
              key={tvBox.axiomId}
              product={tvBox}
              isSelected={isSelected}
              onSelectClick={hasMultipleTvBoxOptions ? () => setSelectedBoxAxiomId(tvBox.axiomId) : undefined}
            />
          );
        }

        if (productId.noBox == tvBox.productId) {
          return (
            <NoBox
              shouldDisableSelect={shouldDisableSelect}
              key={tvBox.axiomId}
              product={tvBox}
              isSelected={isSelected}
              onSelectClick={hasMultipleTvBoxOptions ? () => setSelectedBoxAxiomId(tvBox.axiomId) : undefined}
            />
          );
        }
      })}
    </>
  );
}
