import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Heading, Box, Icon } from "@telia/teddy";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { Breadcrumb } from "components/Breadcrumb";
import { ErrorBox } from "components/ErrorBox";
import { GET_PREPAID_ORDER_HISTORY } from "../graphql/getPrepaidOrderHistory";
import { OrderList, Props as OrderListProps } from "./OrderList";
import { NoOrders } from "./NoOrders";
import { Pagination } from "./Pagination";

export function PrepaidOrderHistory() {
  const [index, setIndex] = useState(0);
  const { phoneNumber } = useMobileAccount();
  const { loading, data, refetch, error } = useQuery(GET_PREPAID_ORDER_HISTORY, {
    variables: { phoneNumber, nextIndex: index },
  });
  const topupOrders = (data?.subscription?.orderHistory?.topupOrders || []) as OrderListProps["orders"];
  const nextIndex = data?.subscription?.orderHistory?.options?.paging?.nextIndex;

  return (
    <Box mt="200" width={{ sm: "100%", lg: "55%" }}>
      <Breadcrumb />
      <Heading as="h2" variant="title-100" mt="200">
        Kjøpshistorikk
      </Heading>
      <Box mt="200">
        {error ? (
          <ErrorBox
            title="Vi beklager"
            description="Dessverre oppstod det en feil under innlastningen av denne siden. Vennligst prøv igjen litt senere."
            actionContent={<Icon name="sync">Last side på nytt</Icon>}
            onClick={() => refetch()}
          />
        ) : loading ? (
          <OrderList.Loader />
        ) : topupOrders.length === 0 ? (
          <NoOrders />
        ) : (
          <>
            <OrderList orders={topupOrders} />
            <Pagination index={index} setIndex={setIndex} nextIndex={nextIndex} />
          </>
        )}
      </Box>
    </Box>
  );
}
