import { GDPRForm } from "./GDPRForm";
import { UserInfo } from "./components/UserInfo";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { breakpoint, Button, Flex, Heading, Link, Text, TextSpacing } from "@telia/teddy";
import { Link as ReactRouterLink } from "react-router-dom";
import { CookieSettingsTrigger } from "./components/CookieSettingsTrigger";
import { useQuery } from "@apollo/client";
import { GDPR_REPORTS_MOBILE } from "./pages/RightOfAccessStepOne";

export function PrivacySettingsPage() {
  const insightStatusMobile = useQuery(GDPR_REPORTS_MOBILE);
  return (
    <Flex
      p="400"
      pb="800"
      direction="column"
      maxWidth={breakpoint.teddyBreakpointMd}
      gapY="400"
      data-tracking-id="privacy-settings-page"
    >
      <Flex direction="column" gapY="200">
        <TextSpacing>
          <Heading as="h2" variant="title-400">
            Innstillinger for personvern
          </Heading>
          <Text>
            Du kan be om å reservere deg mot at vi bruker personopplysninger for formål som ikke er nødvendig for å
            oppfylle lovpålagte plikter eller for å levere produkter og tjenester til deg. Du finner mer informasjon om
            bruk av personopplysninger til slike formål i vår &nbsp;
            <Link variant="text" href={EXTERNAL_LINK.PRIVACY} target="_blank">
              personvernerklæring.
            </Link>
          </Text>
        </TextSpacing>
        <UserInfo />
      </Flex>

      <GDPRForm />

      <TextSpacing>
        <Heading as="h2" variant="subsection-100">
          Kvalitet og opplæring på kundesenteret
        </Heading>
        <Text>
          Du kan reservere deg mot lagring av kundedialog for bruk til kvalitets- og opplæringsformål gjennom kanalen
          (telefon, chat osv) du bruker for å kontakte kundesenteret.
        </Text>
      </TextSpacing>

      <Flex direction="column" gapY="200" align="start">
        <TextSpacing>
          <Heading as="h2" variant="subsection-100">
            Innsyn
          </Heading>
          <Text>
            Du har rett til innsyn i egne personopplysninger. Dette betyr at du skal få vite om vi behandler
            opplysninger om deg, og til å få utlevert en kopi av opplysningene vi sitter på om deg.
          </Text>
        </TextSpacing>

        <Flex direction="column" align="start" gap="200">
          <Button size="sm" variant="secondary" asChild>
            <ReactRouterLink to="/minside/personvern/innsyn-tv-internett">
              Bestill innsynsrapport for TV/Internett
            </ReactRouterLink>
          </Button>
          <Button size="sm" variant="secondary" asChild>
            <ReactRouterLink to="/minside/personvern/innsyn-mobil">
              {insightStatusMobile.data?.gdprStatus?.downloadableReports?.length
                ? "Se innsynsrapport for mobil"
                : "Bestill innsynsrapport for mobil"}
            </ReactRouterLink>
          </Button>
        </Flex>
      </Flex>

      <CookieSettingsTrigger />

      <Flex direction="column" gapY="200" align="start">
        <TextSpacing>
          <Heading as="h2" variant="subsection-100">
            Sletting
          </Heading>
          <Text>
            Du kan i visse sammenhenger be om å få slettet dine personopplysninger. Du har rett til å be om sletting av
            opplysninger hvor ikke videre lagring er strengt nødvendig eller lovpålagt.
          </Text>
        </TextSpacing>

        <Button size="sm" variant="secondary" asChild>
          <ReactRouterLink to="/minside/personvern/sletting">
            Be om sletting av personopplysninger om deg
          </ReactRouterLink>
        </Button>
      </Flex>

      <Flex direction="column" gapY="200" align="start">
        <TextSpacing>
          <Heading as="h2" variant="subsection-100">
            Dataportabilitet
          </Heading>
          <Text>
            Du har rett til å kreve at vi utleverer dine personopplysninger til en annen virksomhet i et format som er
            forståelig for mottaker av opplysningene.
          </Text>
        </TextSpacing>

        <Button size="sm" variant="secondary" asChild>
          <ReactRouterLink to="/minside/personvern/dataportabilitet">
            Be om uttrekk av personopplysninger
          </ReactRouterLink>
        </Button>
      </Flex>
    </Flex>
  );
}
