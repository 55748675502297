import { ApolloError, useMutation } from "@apollo/client";
import { ChangeSubscriptionStatusMutation, MutationChangeSubscriptionStatusArgs } from "src/gql/graphql";
import { graphql } from "src/gql";

const CHANGE_SUBSCRIPTION_STATUS = graphql(`
  mutation changeSubscriptionStatus($phoneNumber: String, $status: SubscriptionStatusChange) {
    changeSubscriptionStatus(phoneNumber: $phoneNumber, status: $status) {
      newSubscriptionStatus
    }
  }
`);

type Props = {
  onError: (error: ApolloError) => void;
  onCompleted?: (data: ChangeSubscriptionStatusMutation) => void;
};

export const useChangeSubscriptionStatus = ({ onCompleted, onError }: Props) => {
  const [changeSubscriptionStatus, { loading, data }] = useMutation<
    ChangeSubscriptionStatusMutation,
    MutationChangeSubscriptionStatusArgs
  >(CHANGE_SUBSCRIPTION_STATUS, {
    onError,
    onCompleted,
  });

  return {
    loading,
    data,
    changeSubscriptionStatus,
  };
};
