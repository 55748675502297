import { Alert, Container, Skeleton } from "@telia-no-min-side/components";
import { LeaseAgreements } from "../lease/LeaseAgreements";
import { SwitchAgreements } from "../switch/SwitchAgreements";
import { useAccountAgreements } from "pages/mobile/mobile-subscription-agreements/context/AccountAgreementsContext";
import { useMobileAccount } from "hooks/useMobileAccount";
import { getFragmentData } from "gql";
import { LEASE_AGREEMENT_FRAGMENT } from "../../graphql/leaseAgreementFragment";
import { InsuranceAgreements } from "../insurance/InsuranceAgreements";
import { INSURANCE_AGREEMENT_FRAGMENT } from "../../graphql/insuranceAgreementFragment";
import { AgreementError } from "../AgreementError";

type Props = {
  showBanAgreementWithoutSub: boolean;
};

export function SubscriptionAgreements({ showBanAgreementWithoutSub }: Props) {
  const { isLoading: accountLoading, phoneNumber } = useMobileAccount();

  const {
    loading: agreementsLoading,
    data,
    error,
    refetch,
    isRefetching,
    hasSwitchAgreements,
    switchAgreements,
    hasLeaseAgreements,
    leaseAgreements,
    hasInsuranceAgreements,
    insuranceAgreements,
  } = useAccountAgreements();

  const isLoading = accountLoading || agreementsLoading;

  // SWITCH
  const filteredSwitchAgreements = showBanAgreementWithoutSub
    ? switchAgreements?.filter((agreement) => agreement.isBanLevelOnly)
    : switchAgreements.filter(
        (agreement) => !agreement.isBanLevelOnly && agreement?.phoneNumber?.localNumber === phoneNumber
      );

  // LEASE
  const filteredLeaseAgreements = showBanAgreementWithoutSub
    ? []
    : leaseAgreements.filter((leaseAgreement) => {
        const agreement = getFragmentData(LEASE_AGREEMENT_FRAGMENT, leaseAgreement);
        return agreement?.phoneNumber.localNumber === phoneNumber;
      });

  // INSURANCE
  const filteredInsurances = insuranceAgreements?.filter((insuranceAgreement) => {
    const agreement = getFragmentData(INSURANCE_AGREEMENT_FRAGMENT, insuranceAgreement);
    return showBanAgreementWithoutSub && agreement.productLevel === "ACCOUNT";
  });

  const hasNoAgreements = filteredSwitchAgreements.length === 0 && filteredLeaseAgreements.length === 0;

  if (isLoading || isRefetching) {
    return (
      <Container flexDirection="column" gap="lg">
        <Skeleton variant="rectangular" height="11rem" width="37.5rem" />
      </Container>
    );
  }

  if (!isLoading && hasNoAgreements && !showBanAgreementWithoutSub) {
    return (
      <Container maxWidth="sm">
        <Alert
          kind="info"
          isOpen
          canClose={false}
          icon="info-filled"
          title="Ingen avtaler funnet"
          text="Dette abonnementet har ingen tilknyttede avtaler."
          dataTrackingId="alert-user-without-agreements"
        />
      </Container>
    );
  }

  if (error && !data) {
    return <AgreementError refetch={refetch} />;
  }

  return (
    <Container flexDirection="column" gap="lg">
      {(hasSwitchAgreements || showBanAgreementWithoutSub) && (
        <SwitchAgreements switchAgreements={filteredSwitchAgreements} />
      )}
      {(hasLeaseAgreements || showBanAgreementWithoutSub) && (
        <LeaseAgreements leaseAgreements={filteredLeaseAgreements} />
      )}
      {(hasInsuranceAgreements || showBanAgreementWithoutSub) && (
        <InsuranceAgreements insuranceAgreements={filteredInsurances} />
      )}
    </Container>
  );
}
