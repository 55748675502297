import type { Dispatch, SetStateAction } from "react";
import { Button, Modal, ModalHeading, ModalBody, ModalFooter } from "@telia-no-min-side/components";
import { FetchResult } from "@apollo/client";
import { CancelSwitchInsuranceMutation, CancelSwitchInsuranceMutationVariables, OrderOp } from "src/gql/graphql";
import { ModalBodyParagraph } from "../styles/SwitchInsurance.styled";
import { SkeletonButton, SkeletonHeadingM, SkeletonText } from "@telia-no-min-side/components/src/Skeleton/style";
import { DUMMY_SCREEN_INSURANCE_PRODUCT } from "util/constants/agreements";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export type Props = {
  loading: boolean;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  accountId?: string;
  phoneNumber?: string;
  serviceAgreementId?: number | null;
  cancelSwitchInsurance: (options?: {
    variables: CancelSwitchInsuranceMutationVariables;
  }) => Promise<FetchResult<CancelSwitchInsuranceMutation>>;
  endDate: string;
};

export const ToggleSwitchScreenOffModal = ({
  loading,
  isModalOpen,
  setIsModalOpen,
  accountId,
  phoneNumber,
  serviceAgreementId,
  cancelSwitchInsurance,
  endDate,
}: Props) => {
  async function runCancelInsurance(phoneNumber?: string) {
    const variables: CancelSwitchInsuranceMutationVariables = {
      phoneNumber,
      input: [
        {
          operation: OrderOp.RemoveAdditionalProduct,
          productName: DUMMY_SCREEN_INSURANCE_PRODUCT,
          accountId,
          serviceAgreementId,
        },
      ],
    };

    try {
      await cancelSwitchInsurance({ variables });
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Modal setIsOpen={setIsModalOpen} isOpen={isModalOpen}>
      {loading && (
        <ModalBody>
          <SkeletonHeadingM />
          <SkeletonText />
          <SkeletonText />
          <SkeletonText />
          <SkeletonButton />
        </ModalBody>
      )}
      {!loading && (
        <>
          <ModalHeading>Kanseller Skjermforsikring etter Svitsj-avtalen utløper</ModalHeading>
          <ModalBody>
            <ModalBodyParagraph>
              Er du sikker på at du ikke vil fortsette å ha Skjermforsikring til 69,- /md. etter din Svitsj-avtale
              utløper {endDate}? Dette kan ikke velges tilbake hvis du ombestemmer deg.
            </ModalBodyParagraph>
          </ModalBody>
          <ModalFooter flex>
            <Button onClick={() => setIsModalOpen(false)} variant="tertiary-black">
              Avbryt
            </Button>
            <Button
              trackEvent={{
                ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
                ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.CANCEL_SCREEN_INSURANCE,
                ui_item_text: "Kanseller forsikring",
                ui_item_type: TRACK_EVENT.ITEM_TYPE.SUBMIT,
                ui_item_url: "",
              }}
              onClick={() => {
                runCancelInsurance(phoneNumber);
              }}
            >
              Kanseller forsikring
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
