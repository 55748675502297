import { ReactNode, useEffect } from "react";
import { TopMenu } from "./TopMenu";
import { format, types } from "@telia-no-min-side/utils";
import { PickerButton } from "./PickerButton";
import { Icon } from "@telia/teddy";
import { TopMenuSkeleton } from "./TopMenuSkeleton";
import { datadogRum } from "@datadog/browser-rum";
import { useFixedAccount } from "src/hooks/useFixedAccount";
import { useProfilePicker } from "components/profile-pickers/hooks/useProfilePicker";
import { PickerOption } from "./Picker";

/**
 * FixedProfilePicker is used for switching between fixed.
 * customer profiles. It displays all existing fixed customers
 * (even when they don't have broadband subscription).
 */

function getSelectedProfile(
  profiles: PickerOption<types.fixed.UserWithCustomerId>[]
): PickerOption<types.fixed.UserWithCustomerId> | undefined {
  const selectedProfile = (profiles || []).find((profile) => profile.value.selected);
  return selectedProfile;
}

type Props = {
  dataTrackingID?: string;
  children?: ReactNode;
};

export function TvTopMenu({ dataTrackingID, children }: Props) {
  const { newFixed: fixed } = useProfilePicker({ filter: "fixed" });

  const { changeFixedAccount } = useFixedAccount();
  const currentSelectedProfile = getSelectedProfile(fixed.profiles);

  useEffect(() => {
    if (!currentSelectedProfile && fixed.profiles.length > 0) {
      onProfileChange(fixed.profiles[0].value);
    }
  }, [currentSelectedProfile, fixed.profiles]);

  const showRetry = !fixed.loading && fixed.hasError;

  const onRetry = () => {
    if (fixed.hasError) {
      fixed.refetch();
    }
  };

  const onProfileChange = async (profile: types.fixed.UserWithCustomerId) => {
    try {
      // SWR will change the selected profile immediately
      // in cache. There is no need to store selected profile locally
      // during the request duration
      await changeFixedAccount(profile.userKey);
    } catch (error) {
      datadogRum.addError(error, { feature: "FixedProfilePicker" });
    }
  };

  const info = currentSelectedProfile
    ? {
        title: format.startCase(`${currentSelectedProfile?.value.street || ""}`),
        subtitle: `Kundenummer: ${currentSelectedProfile?.value.customerId}`,
      }
    : undefined;

  const showLoading = fixed.loading && !fixed.profiles.length;

  return showLoading ? (
    <TopMenuSkeleton dataTrackingID={dataTrackingID} />
  ) : (
    <TopMenu
      info={info}
      selectedOption={currentSelectedProfile}
      options={fixed.profiles}
      handleOptionSelect={onProfileChange}
      dataTrackingID={dataTrackingID}
    >
      {children}
      {showRetry && (
        <PickerButton style={{ textWrap: "nowrap" }} subtext="Kan ikke laste" handleClick={onRetry}>
          <Icon size="sm" name="sync" />
        </PickerButton>
      )}
    </TopMenu>
  );
}
