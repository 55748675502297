import { Container, Skeleton } from "@telia-no-min-side/components";

export const AdditionalSimCardsSkeleton = () => {
  return (
    <Container data-tracking-id="additional-sim-card-loading">
      <Container maxWidth="sm" flexDirection="column" gap="sm" padding="bottom" paddingSize="lg">
        <Skeleton variant="heading-m" width="15ch" />
        <Skeleton variant="text" />
        <Skeleton variant="text" width="22ch" />
        <Skeleton variant="text" width="24ch" />
        <Skeleton variant="text" width="18ch" />
        <Container floatContent="right">
          <Skeleton variant="button" />
        </Container>
      </Container>
      <Container maxWidth="sm" flexDirection="column" gap="sm" padding="bottom" paddingSize="lg">
        <Skeleton variant="heading-m" width="15ch" />
        <Skeleton variant="text" />
        <Skeleton variant="text" width="22ch" />
        <Skeleton variant="text" width="24ch" />
        <Skeleton variant="text" width="18ch" />
        <Container floatContent="right">
          <Skeleton variant="button" />
        </Container>
      </Container>
    </Container>
  );
};
