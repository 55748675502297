import { useEffect, useRef, useState, ComponentProps } from "react";
import { useLazyQuery } from "@apollo/client";
import { Button, Card, Container, Icon, Paragraph } from "@telia-no-min-side/components";
import { format, uri } from "@telia-no-min-side/utils";
import { FragmentType, getFragmentData } from "src/gql";
import { BillingType } from "src/gql/graphql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { getConvergentBenefitAmount } from "src/util/format-subscription/getConvergentBenefitAmount";
import { getName } from "src/util/format-subscription/getName";
import { isCommitted } from "src/util/format-subscription/isCommitted";
import { LINE_DISCOUNT_GROUP } from "src/util/format-subscription/variables";
import { FamilyDiscountLine } from "./styles";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";
import { isUngSubscription } from "util/format-subscription/isUngSubscription";
import { config } from "util/config";
import { SUBSCRIPTION_CHANGE_QUERY } from "pages/mobile/mobile-subscription-change/graphql/getChangeSubscription";
import { SUBSCRIPTION_FRAGMENT } from "../graphql/subscriptionFragment";

type Props = {
  lastFamilySubscription: boolean;
  subscription: FragmentType<typeof SUBSCRIPTION_FRAGMENT>;
  index: number;
  isLines: boolean;
  accountId: string;
  hasMultipleSubs: boolean;
};

export function SubscriptionCardWithDetails({
  subscription,
  accountId,
  lastFamilySubscription,
  isLines,
  index,
  hasMultipleSubs,
}: Props) {
  const {
    userOffering,
    billingType,
    subscriptionRank,
    productUser,
    commitmentEndDate,
    phoneNumber,
    additionalProducts,
  } = getFragmentData(SUBSCRIPTION_FRAGMENT, subscription);
  const convergentBenefit = additionalProducts && getConvergentBenefitAmount(additionalProducts);
  const hasOnGoingOrder = useOrderSubscription().orders.some((order) => order.phoneNumber === phoneNumber.localNumber);
  const hasJuniorSummerCampaign = isJuniorSummerCampaign(userOffering?.pricePlan || "");
  const isUngSub = isUngSubscription(userOffering?.additionalProperties);

  const [prefetchNextPage, prefetchedSubscriptionChange] = useLazyQuery(SUBSCRIPTION_CHANGE_QUERY, {
    variables: { accountId, phoneNumber: phoneNumber.localNumber },
    fetchPolicy: "cache-first",
  });

  const [cardHeight, setCardHeight] = useState(0);
  const cardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (cardRef.current) {
      setCardHeight(cardRef.current.getBoundingClientRect().height);
    }
  }, []);

  if (!subscription) return null;

  const getLozenge = (): ComponentProps<typeof Card>["lozengeRight"] => {
    if (hasJuniorSummerCampaign) {
      return !config.disableSummerCampaignDoubleData
        ? { label: "Sommerkampanje", status: "warning", icon: "weather" }
        : undefined;
    }

    if (isLines && hasMultipleSubs && index > 0 && !isUngSub) {
      return {
        label: "Familierabatt",
        status: "communication",
      };
    }

    return undefined;
  };

  const subscriptionName = getName(
    userOffering?.offeringPrices?.find((offeringPrice) => offeringPrice?.offeringPrices)?.offeringPrices,
    userOffering?.shortName,
    !!convergentBenefit?.hasDoubleData
  );

  const subRank = subscriptionRank ?? 0;
  const firstName = productUser?.firstName ?? "";
  const isCommittedSubscription = isCommitted(commitmentEndDate);
  const price = userOffering?.monthlyCost?.amount ?? 0;
  const originalPrice = userOffering?.originalPrice?.amount ?? 0;
  const discountEligible = userOffering?.additionalProperties?.find(
    (additionalProperty) => additionalProperty?.value === LINE_DISCOUNT_GROUP
  );

  const showOriginalPrice = discountEligible && originalPrice > price;
  const isPrepaid = billingType === BillingType.Prepaid;
  return (
    <>
      {subRank > 0 && !lastFamilySubscription && <FamilyDiscountLine height={cardHeight} />}
      <Card
        maxWidth="xl"
        ref={cardRef}
        removeMinHeight
        style={{
          marginLeft: isLines && index > 0 ? "2rem" : "",
        }}
        onMouseOver={() => {
          if (!prefetchedSubscriptionChange.data && !prefetchedSubscriptionChange.loading) {
            prefetchNextPage();
          }
        }}
        lozengeRight={hasOnGoingOrder ? { icon: "sync", label: "Oppdateres", status: "info" } : undefined}
        lozengeLeft={getLozenge()}
        data-tracking-id={`mobile-subscription-card-with-details-${(firstName || "").replaceAll(" ", "_")}`}
      >
        <Container alignItems="center" justifyContent="space-between" flexDirection="row" gap="lg">
          <Container flexDirection="column" gap>
            <Paragraph removeMargin isFaded>
              <i data-di-mask>{format.startCase(firstName)}</i>
            </Paragraph>
            <Container flexDirection="row" gap="lg" alignItems="center" flexWrap width="unset">
              <Container
                gap
                width="unset"
                data-tracking-id={`mobile-subscription-${(subscriptionName || "").replaceAll(" ", "_")}`}
              >
                {subscriptionName && (
                  <Paragraph variant="preamble-100" removeMargin>
                    <em>{subscriptionName}</em>
                  </Paragraph>
                )}

                {isCommittedSubscription && (
                  <Container justifyContent="center" alignItems="center">
                    {<Icon size="sm" icon="calendar" />}
                  </Container>
                )}
              </Container>
              <Container gap width="unset">
                {showOriginalPrice && (
                  <Paragraph
                    removeMargin
                    textDecorationLine="line-through"
                    data-tracking-id="mobile-subscription-original-price"
                  >
                    {format.simplePriceText(originalPrice, "md.")}
                  </Paragraph>
                )}
                <Paragraph removeMargin data-tracking-id="mobile-subscription-price">
                  {format.simplePriceText(price, "md.")}
                </Paragraph>
              </Container>
            </Container>
          </Container>
          <Container flexDirection="column" width="unset">
            <Button
              icon="edit"
              isIconButton
              tag="a"
              isInternalNavigation
              href={uri.minSideFixed(
                isPrepaid
                  ? `${location.pathname}/legg-til/${accountId}`
                  : `${location.pathname}/endre/${accountId}/${phoneNumber.localNumber}`
              )}
              title="Endre"
              variant="secondary"
            />
            Endre
          </Container>
        </Container>
      </Card>
    </>
  );
}
