import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { remCalc } from "../utils/fonts";

export const RadioFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  legend {
    padding: 0;
  }
`;
export const RadioLegend = styled.legend<{ isVisuallyHidden: boolean | undefined }>`
  font-weight: 500;
  ${({ isVisuallyHidden }) =>
    isVisuallyHidden &&
    css`
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    `}
`;
export const RadioLabel = styled.label<{ isBold?: boolean }>`
  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 500;
    `}
`;
export const RadioInput = styled.input`
  all: unset;
  position: absolute;
  inset: 0;
  cursor: pointer;
`;
export const RadioIndicator = styled.span<{ isSelected: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 999px;
  box-shadow: rgba(0, 0, 0, 0.33) 0px 0px 0px 1px inset;
  position: relative;
  flex-shrink: 0;

  ::after {
    content: "";
    position: absolute;
    inset: 4px;
    border-radius: 999px;
    outline: ${colors.corePurple600} 1px solid;
    outline-offset: 3px;
    background-color: ${colors.corePurple700};
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
    transition: ease-out 0.15s opacity;
  }
  ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 999px;
    box-shadow: ${colors.corePurple700} 0px 0px 0px 2px inset;
    opacity: 0;
    transition: ease-out 0.15s opacity;
  }
`;

export const RadioItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:hover ${RadioIndicator} {
    ::before {
      opacity: ${({ isSelected }) => (isSelected ? 0 : 1)};
    }
  }
  ::before {
    content: "";
    position: absolute;
    inset: -4px;
    border-radius: ${remCalc(11)};
    outline: 2px solid ${colors.corePurple500};
    opacity: 0;
    transition: ease-out 0.15s opacity;
  }
  &:has(${RadioInput}:focus-visible) {
    ::before {
      opacity: 1;
    }
  }
`;
