import { Flex, Skeleton } from "@telia/teddy";

export function RoamingLoading() {
  return (
    <Flex direction="column" gap="gutter-sm">
      <Skeleton height="70px" width="100%" />
      <Skeleton height="1px" width="100%" />
      <Skeleton height="72px" width="100%" />

      <Flex direction="column" gap="100">
        <Skeleton height="20px" width="80px" />
        <Skeleton height="46px" width="100%" />
      </Flex>
    </Flex>
  );
}
