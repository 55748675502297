import { Container, TrackClickEvent } from "@telia-no-min-side/components";
import { SelectAccount } from "./SelectAccount";
import { useB2BFamilyDiscountFlow } from "./useB2BFamilyDiscount";
import { Navigate, Link } from "react-router-dom";
import { Button, Heading, Text, Icon, Flex, Spinner } from "@telia/teddy";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { Error } from "./components/Error";
import { Success } from "./components/Success";

export function B2BFamilyDiscount() {
  const {
    b2cAccountsWithSubscription,
    b2cAccountsWithoutSubscription,
    needToSelectSubscription,
    b2bPhoneNumber,
    b2cAccountId,
    orderFamilyDiscount,
    cachedPricePlan,
    orderB2cAccount,
    isLoading,
  } = useB2BFamilyDiscountFlow();

  if (isLoading) {
    return (
      <Container goBackTo="/minside/hjem" padding maxWidth="lg" flexDirection="column" gap="lg">
        <Flex gap="300" align="center">
          <Spinner size="lg" variant="primary" />
          <Heading as="h1" variant="title-300">
            Laster familierabatt...
          </Heading>
        </Flex>
      </Container>
    );
  }

  if (!b2bPhoneNumber) {
    return (
      <Container goBackTo="/minside/hjem" padding maxWidth="lg" flexDirection="column" gap="lg">
        <Heading as="h1" variant="title-300">
          Du kan ikke bestille familierabatt!
        </Heading>
        <Text>
          Vi finner ingen bedriftsabonnementer som kvalifiserer for familierabatt. Hvis du mener dette er en feil,
          vennligst kontakt kundeservice.
        </Text>

        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK_BACK_TO_HOME,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.B2B2C_FAMILY_DISCOUNT,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: window.location.pathname,
            ui_item_text: "Tilbake til hjem (Du kan ikke bestille familierabatt!)",
          }}
        >
          <Button asChild mr="auto">
            <Link to={"/minside/hjem"}>
              <Icon name="home" />
              Tilbake til hjem
            </Link>
          </Button>
        </TrackClickEvent>
      </Container>
    );
  }

  // ------------ The user has completed the flow ------------
  if (!orderFamilyDiscount.needToOrder && !orderB2cAccount.needToOrder && b2cAccountsWithSubscription.length) {
    return <Success reEnteringPage />;
  }

  // ------------ Order in progress ------------
  // if we have a cachedAccountId it means the user has ordered a discount
  if (
    b2cAccountsWithSubscription.length &&
    orderFamilyDiscount.isOrderInProgress &&
    orderFamilyDiscount.cachedAccountId
  ) {
    return <Navigate replace to={`${b2bPhoneNumber}/${orderFamilyDiscount.cachedAccountId}/oversikt`} />;
  }

  // if we have a cachedPricePlan it means the user dosent have a been on the select sub page
  if (orderB2cAccount.isOrderInProgress && cachedPricePlan) {
    return <Navigate replace to={`${b2bPhoneNumber}/${cachedPricePlan}/bestill`} />;
  }

  if (orderFamilyDiscount.isOrderInProgress && orderFamilyDiscount.cachedAccountId) {
    return <Navigate replace to={`${b2bPhoneNumber}/${cachedPricePlan}/bestill`} />;
  }

  // ------------ view overview ------------
  // if the user has only one b2c account with subscription send to overview page
  if (b2cAccountsWithSubscription.length === 1 && b2cAccountId) {
    return <Navigate replace to={`${b2bPhoneNumber}/${b2cAccountId}/oversikt`} />;
  }

  // ------------ select account ------------
  // customer has multiple b2c accounts with subscriptions
  if (b2cAccountsWithSubscription.length > 1 && !b2cAccountId) {
    return (
      <SelectAccount accounts={b2cAccountsWithSubscription}>
        {({ selectedAccountId }) => <Link to={`${b2bPhoneNumber}/${selectedAccountId}/oversikt`}>Velg</Link>}
      </SelectAccount>
    );
  }
  // customer has multiple b2c accounts without subscription and needs to select which account to order sub and discount on
  if (b2cAccountsWithoutSubscription.length > 1 && !b2cAccountId) {
    return (
      <SelectAccount accounts={b2cAccountsWithoutSubscription}>
        {({ selectedAccountId }) => (
          <Link to={`${b2bPhoneNumber}/velg-abonnement?b2cAccountId=${selectedAccountId}`}>Velg</Link>
        )}
      </SelectAccount>
    );
  }

  // ------------ select sub ------------
  // user has already a b2c account and needs to select a subscription
  if (b2cAccountId && needToSelectSubscription) {
    return <Navigate replace to={`${b2bPhoneNumber}/velg-abonnement?b2cAccountId=${b2cAccountId}`} />;
  }
  // user has no b2c account and needs to select a subscription before creating a b2c account
  if (needToSelectSubscription) {
    return <Navigate replace to={`${b2bPhoneNumber}/velg-abonnement`} />;
  }

  // ------------ Send user to webshop ------------
  // if the user has ordered a discount, and not completed the order at webshop
  if (
    !orderFamilyDiscount.needToOrder &&
    !orderB2cAccount.needToOrder &&
    !b2cAccountsWithSubscription.length &&
    cachedPricePlan
  ) {
    return <Navigate replace to={`${b2bPhoneNumber}/${cachedPricePlan}/bestill`} />;
  }

  return <Error />;
}
