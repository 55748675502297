import { v4 as uuid } from "uuid";
import { Accordion, Container, Heading, SwitchProgressBar } from "@telia-no-min-side/components";
import { PhoneInfo } from "./components/PhoneInfo";
import { format, track } from "@telia-no-min-side/utils";
import { AgreementInfo } from "./components/AgreementInfo";
import { Terms } from "../Terms";
import { SwitchButton } from "./components/SwitchButton";
import { SwitchInsuranceAgreement } from "./components/SwitchInsuranceAgreement";
import { useMobileAccount } from "hooks/useMobileAccount";
import { SwitchAlerts } from "./components/SwitchAlerts";
import { SwitchAgreement } from "../../utils/getAllUniqueSwitchAgreements";
import { canSwitchButWithPayment } from "../../utils/canSwitchButWithPayment";
import { URL_SWITCH_AGREEMENT_TERMS } from "util/constants/agreements";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = {
  switchAgreements: SwitchAgreement[] | undefined;
};

export function SwitchAgreements({ switchAgreements }: Props) {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { accountId } = useMobileAccount();

  return switchAgreements?.map((agreement) => {
    const canSwitchForFree = agreement.canPerformSwitch && agreement.remainingMandatoryInstallments <= 0;

    const canSwitchWithPayment = canSwitchButWithPayment(
      agreement.remainingMandatoryInstallments,
      agreement.canPerformSwitch
    );

    return (
      <Accordion key={agreement.id || uuid()} data-tracking-id="agreement-switch-card">
        <Accordion.Item
          backgroundOnHover="none"
          lozengeRight={
            canSwitchForFree
              ? {
                  label: "Du kan Svitsje!",
                  status: "positive",
                }
              : undefined
          }
        >
          <Container gap="lg" data-tracking-id="agreement-switch-card-heading">
            <Accordion.Trigger>
              <Heading tag="h2" variant="title-100" data-di-mask>
                Svitsj-avtale
              </Heading>

              <PhoneInfo
                product={agreement.product}
                phoneNumber={agreement.phoneNumber}
                imei={agreement.imei}
                insuranceType={agreement.insurance?.type}
              />
            </Accordion.Trigger>
          </Container>
          <Accordion.Content>
            <Container gap="lg" flexDirection="column">
              <Container gap="lg" flexDirection="column" data-tracking-id="agreement-switch-card-progress-bar">
                <SwitchProgressBar
                  canSwitch={agreement.canPerformSwitch}
                  effectiveDate={agreement.effectiveDate}
                  expirationDate={agreement.expirationDate}
                  costToReplace={format.formatPrice(agreement.costToReplace.value)}
                  tackStepClick={() => {
                    return (step: number, initialStep: number, heading: string) => {
                      const trackEvent = {
                        ui_item_action: TRACK_EVENT.ITEM_ACTION.SHOW,
                        ui_item_context: `${TRACK_EVENT.ITEM_CONTEXT_SWTICH.PROGRESS_BAR}-${step}-initial-${initialStep}`,
                        ui_item_text: heading,
                        ui_item_type: TRACK_EVENT.ITEM_TYPE.INFO_MSG,
                        ui_item_url: "",
                      };
                      pushGenericTrackingEvent(trackEvent);
                    };
                  }}
                />
              </Container>
              <AgreementInfo
                pricePerInstallment={agreement.pricePerInstallment}
                remainingCost={agreement.remainingCost}
                totalCost={agreement.totalCost}
                effectiveDate={agreement.effectiveDate}
                expirationDate={agreement.expirationDate}
              />
              <Container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                data-tracking-id="agreement-switch-card-terms-pdf"
              >
                <Terms
                  termsInsuranceProofUrl=""
                  ui_item_context={TRACK_EVENT.ITEM_CONTEXT_SWTICH.FILE_INSURANCE_CLAIM}
                  termsInsuranceUrl={URL_SWITCH_AGREEMENT_TERMS}
                  dataTrackingId="switch-agreements"
                />
                {agreement.canPerformSwitch && agreement.isLegalOwner && (
                  <SwitchButton
                    agreement={agreement}
                    accountId={accountId}
                    canSwitchWithPayment={canSwitchWithPayment}
                  />
                )}
              </Container>
              {!canSwitchForFree && (
                <Container data-tracking-id="agreement-switch-card-alert">
                  <SwitchAlerts
                    isLegalOwner={agreement.isLegalOwner}
                    canPerformSwitch={agreement.canPerformSwitch}
                    switchRejectionReason={agreement.switchRejectionReason}
                  />
                </Container>
              )}

              <SwitchInsuranceAgreement
                // if account id is present in payload, backend will cancel on ban-level
                accountId={agreement.isBanLevelOnly ? accountId : undefined}
                isLegalOwner={agreement.isLegalOwner}
                switchAgreement={agreement}
                wantToContinueScreenInsurance={agreement.wantToContinueScreenInsurance}
              />
            </Container>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    );
  });
}
