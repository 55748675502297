import React from "react";
import { SideMenuItem } from "@telia/styleguide";
import { useMainLayoutNavigation } from "../../hooks/useMainLayoutNavigation";
import { MenuLink, MenuLinkId } from "../utils/links";
import { Options } from "./SideMenuItems";
import { useSideMenu } from ".";

type Props = {
  menuLink: MenuLink;
  onMenuItemClick: (activeId: MenuLinkId, options: Options) => void;
  numberOfNotifications?: number;
  dataTrackingId?: string;
  disabled?: boolean;
};
export function SideMenuLink(props: Props) {
  const { onMenuItemClick, menuLink, numberOfNotifications, dataTrackingId } = props;
  const { isCollapsed, limitedAccess } = useSideMenu();
  const { activeMenuId } = useMainLayoutNavigation();
  const { href, id, isInternalLink, limitedAccessView, ...rest } = menuLink;

  return (
    <SideMenuItem
      {...rest}
      href={href}
      onClick={() => {
        onMenuItemClick(id, {
          navigateTo: href,
          isInternalLink: isInternalLink,
        });
      }}
      active={activeMenuId === id}
      collapse={isCollapsed}
      numberOfNotifications={numberOfNotifications}
      dataTrackingId={dataTrackingId}
      disabled={limitedAccess && limitedAccessView}
    />
  );
}
