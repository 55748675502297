import { Flex, Card, Button, Icon } from "@telia/teddy";
import { TopupCategory } from "gql/graphql";
import { ActionsSection, Badge, LimitedDataProgressSection } from "..";
import { formatBytesToGB, getDataUsageDetails, getTotalDataUsage, mergeDataUsageByType } from "../../utils";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";

export function LimitedDataUsageCard() {
  const { localDataUsage, timeUntilDataUsagePeriodStarts, openSelectedTopupDrawer } = useMobileDashboardContext();

  const groupedData = mergeDataUsageByType(localDataUsage);
  const totalDataUsage = getTotalDataUsage(groupedData);
  const details = getDataUsageDetails(groupedData, totalDataUsage.limit);

  return (
    <Card bordered shadow layout="default" variant="white" gap="gutter-md">
      <Badge />

      <Card.Heading variant="title-400">{formatBytesToGB(totalDataUsage.remaining)} igjen</Card.Heading>
      <Card.Content>
        <Flex direction="column" gap="gutter-md">
          <LimitedDataProgressSection
            details={details}
            totalData={totalDataUsage}
            timeUntilDataUsagePeriodStarts={timeUntilDataUsagePeriodStarts}
          />
          <ActionsSection />
        </Flex>
      </Card.Content>
      <Card.Footer>
        <Button width="100%" variant="primary" onClick={() => openSelectedTopupDrawer(TopupCategory.Domestic)}>
          <Icon name="plus">Fyll på data</Icon>
        </Button>
      </Card.Footer>
    </Card>
  );
}
