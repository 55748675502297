import { Container } from "@telia-no-min-side/components";
import { Heading, Text } from "@telia/teddy";

type Props = {
  preamble: string;
  heading: string;
  dataTrackingId?: string;
};

export function SwitchHeading({ preamble, heading, dataTrackingId }: Props) {
  return (
    <Container flexDirection="column" data-tracking-id={dataTrackingId}>
      <Text as="p" variant="preamble-100">
        {preamble}
      </Text>
      <Heading as="h4" variant="title-400">
        {heading}
      </Heading>
    </Container>
  );
}
