import { defaultDataIdFromObject, InMemoryCache } from "@apollo/client";
import { format } from "@telia-no-min-side/utils";
import {
  AdditionalProduct,
  MonthlyCost,
  OfferingPrice,
  OfferingPriceDetails,
  SubscriptionData,
  UserOffering,
} from "src/gql/graphql";
import { mergeArraysByKey, mergeOfferingPrices } from "./utils";

// apollo does not warn if you forget the id in a query
// but then the cache becomes quirky
const typesThatRequireIds = ["AccountData", "Topup"];

export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    const { __typename, id } = responseObject;
    const isIdRequired = !!__typename && typesThatRequireIds.includes(__typename);
    if (isIdRequired && !id) {
      console.warn(`Missing 'id' field for type '${__typename}' in Apollo data.`);
    }
    return defaultDataIdFromObject(responseObject);
  },
  typePolicies: {
    SubscriptionData: {
      keyFields: ["phoneNumber", ["localNumber"]],
      fields: {
        productUser: {
          merge: true,
        },
        userOffering: {
          read(existing: SubscriptionData["userOffering"]) {
            if (existing && !existing?.pricePlan) {
              console.warn(`Key "pricePlan" is missing in userOffering query, please add it to the query`);
            }
            return existing;
          },
        },
        legalOwner: {
          merge: true,
        },
        groupedAdditionalProducts: {
          merge: true,
        },
      },
    },
    OfferingPrice: {
      fields: {
        offeringPrices: {
          read(existing: OfferingPrice["offeringPrices"]) {
            if (existing?.some((p) => !p?.code)) {
              console.warn(`Key "code" is missing in offeringPrices query, please add it to the query`);
            }
            return existing;
          },
        },
      },
    },
    OfferingPriceDetails: {
      fields: {
        monetaryUnit: {
          read(existing: OfferingPriceDetails["monetaryUnit"]): OfferingPriceDetails["monetaryUnit"] {
            if (existing === "MBIT" || existing === "Mbit") {
              return "Mbps";
            }
            return existing;
          },
        },
      },
    },
    AdditionalProduct: {
      fields: {
        presentation: {
          merge(existing = {}, incoming) {
            return { ...existing, ...incoming };
          },
        },
        productTerms: {
          merge(existing: AdditionalProduct["productTerms"], incoming: AdditionalProduct["productTerms"]) {
            return mergeArraysByKey(existing, incoming, "code");
          },
        },
      },
    },
    PaymentBalance: {
      merge: true,
    },
    Invoice: {
      merge: true,
      fields: {
        amount: {
          merge: true,
        },
      },
    },
    SwitchInsurance: {
      merge: true,
    },
    MonthlyCost: {
      merge: true,
      fields: {
        amount: {
          read(value: MonthlyCost["amount"]) {
            if (!value) return value;
            return format.roundPrice(value);
          },
        },
        amountWithoutVat: {
          read(value: MonthlyCost["amountWithoutVat"]) {
            if (!value) return value;
            return format.roundPrice(value);
          },
        },
        reasons: {
          merge(existing: MonthlyCost["reasons"], incoming: MonthlyCost["reasons"]) {
            return mergeArraysByKey(existing, incoming, "code");
          },
        },
      },
    },
    UserOffering: {
      merge: true,
      fields: {
        originalPrice: {
          merge: true,
          read(existing: UserOffering["originalPrice"]) {
            if (existing && typeof existing?.amount !== "number") {
              console.warn(`Key "amount" is missing in originalPrice query, please add it to the query`);
            }
            return existing;
          },
        },
        offeringPrices: {
          merge(
            existing: UserOffering["offeringPrices"],
            incoming: UserOffering["offeringPrices"]
          ): UserOffering["offeringPrices"] {
            return mergeOfferingPrices(existing, incoming);
          },
        },
      },
    },
    AccountData: {
      fields: {
        legalOwner: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    BlueConicItem: {
      keyFields: ["tracking", ["variantId"]],
    },
  },
});
