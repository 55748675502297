import { NetworkStatus, useQuery } from "@apollo/client";
import { isOnlyAuthorizationError } from "src/util/apollo/error";
import { useFetchFlexCurrent } from "src/api/fixed";
import { DashboardSection } from "../card/DashboardSection";
import { LoadingSubscriptionCard } from "../card/LoadingSubscriptionCard";
import { ErrorSection } from "../card/ErrorSection";
import { getFixedAgreements } from "./utils/getFixedAgreements";
import { getMobileAccountAgreements } from "./utils/getMobileAccountAgreements";
import { MobileAgreements } from "./MobileAgreements";
import { FixedAgreements } from "./FixedAgreements";
import { useMobileAccount } from "hooks/useMobileAccount";
import { UserRole } from "gql/graphql";
import { accountsAgreementsQuery } from "./graphql/accountsAgreementsQuery";
import { getMobileSubscriptionAgreements } from "./utils/getMobileSubscriptionAgreements";
import { subscriptionAgreementsQuery } from "./graphql/subscriptionAgreementsQuery";

export function Agreements() {
  const { allAccounts, phoneNumber } = useMobileAccount();

  const isLegalOwnerOfAnyAccount = !!allAccounts?.some((account) => account?.roles.includes(UserRole.LegalOwner));

  const accountsAgreements = useQuery(accountsAgreementsQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !isLegalOwnerOfAnyAccount,
  });

  const subscriptionAgreements = useQuery(subscriptionAgreementsQuery, {
    notifyOnNetworkStatusChange: true,
    skip: isLegalOwnerOfAnyAccount || !phoneNumber,
    variables: { phoneNumber },
  });

  const flexCurrentAll = useFetchFlexCurrent({ fetchAllAccounts: true });

  const isMobileAccountsAgreementsLoading = accountsAgreements.loading && !accountsAgreements.data;
  const isMobileSubscriptionAgreementsLoading = subscriptionAgreements.loading && !subscriptionAgreements.data;
  const isFlexAgreementsLoading = flexCurrentAll.isLoading && !flexCurrentAll.data;
  const isAllAgreementsLoading =
    isFlexAgreementsLoading || isMobileSubscriptionAgreementsLoading || isMobileAccountsAgreementsLoading;

  if (isAllAgreementsLoading) {
    return (
      <DashboardSection title="Dine avtaler" isLoading>
        <LoadingSubscriptionCard />
      </DashboardSection>
    );
  }

  const isAccountAgreementsError =
    !isOnlyAuthorizationError(accountsAgreements.error) && !accountsAgreements.data && flexCurrentAll.isError;

  if (isAccountAgreementsError) {
    return (
      <ErrorSection
        onRefreshClick={() => {
          if (!isOnlyAuthorizationError(accountsAgreements.error) && !accountsAgreements.data) {
            accountsAgreements.refetch();
          }
          if (!flexCurrentAll.data && flexCurrentAll.error) {
            flexCurrentAll.mutate();
          }
        }}
        isLoading={accountsAgreements.networkStatus === NetworkStatus.refetch || flexCurrentAll.isValidating}
        title="Dine avtaler"
        sectionText="dine avtaler"
      />
    );
  }

  if (subscriptionAgreements.error) {
    return (
      <ErrorSection
        onRefreshClick={() => {
          if (!isOnlyAuthorizationError(subscriptionAgreements.error) && !subscriptionAgreements.data) {
            subscriptionAgreements.refetch();
          }
        }}
        isLoading={subscriptionAgreements.networkStatus === NetworkStatus.refetch}
        title="Dine avtaler"
        sectionText="dine avtaler"
      />
    );
  }

  const { flexCustomerIds } = getFixedAgreements(flexCurrentAll.data);
  const mobileAccountAgreements = getMobileAccountAgreements(accountsAgreements.data?.accounts);
  const mobileSubscriptionAgreements = getMobileSubscriptionAgreements(subscriptionAgreements.data?.subscription);
  const mobileAgreements = isLegalOwnerOfAnyAccount ? mobileAccountAgreements : mobileSubscriptionAgreements;

  const hasAgreements = mobileAgreements.length > 0 || flexCustomerIds.length > 0;
  if (!hasAgreements) return null;

  return (
    <DashboardSection title="Dine avtaler">
      <MobileAgreements agreements={mobileAgreements} />
      <FixedAgreements flexCustomerIds={flexCustomerIds} />
    </DashboardSection>
  );
}
