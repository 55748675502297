export const INVOICE_POSTPONE_DAYS = 30;
export const INVOICE_POSTPONE_LOADING_MESSAGE = "Vennligst vent mens vi behandler forespørselen din.";
export const INVOICE_POSTPONE_SUCCESS_MESSAGE = `Betalingen din er nå utsatt i ${INVOICE_POSTPONE_DAYS} dager.`;
export const INVOICE_POSTPONE_GENERIC_ERROR_MESSAGE = `En feil oppsto. Prøv igjen om noen minutter, 
            eller ta kontakt med kundesenteret.`;
export const INVOICE_POSTPONE_NOT_ELIGIBLE_ERROR_MESSAGE = "Du har dessverre ikke mulighet til å utsette faktura.";
export const INVOICE_OVERDUE_WARNING_HEADING = "Utestående faktura";
export const INVOICE_OVERDUE_WARNING_MESSAGE =
  "For å kunne bestille utstyr må den siste fakturaen betales. Så fort betalingen er registrert kan du bestille ønsket utstyr.";
export const INVOICE_OVERDUE_WARNING_MESSAGE_TV_BOX =
  "For å kunne bestille Telia Play box må den siste fakturaen betales. Så fort betalingen er registrert kan du bestille Telia Play box.";
export const INOICE_GOTO_TEXT = "Gå til faktura";
