import { useLayoutEffect, useState, RefObject } from "react";
import { useWindowSize } from ".";

export function useHorizontalScroll(elementRef: RefObject<HTMLDivElement>) {
  const [isScrollable, setIsScrollable] = useState(false);
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);
  const [isStartOfScroll, setIsStartOfScroll] = useState(false);
  const { width } = useWindowSize();

  function onScroll(maxLeftPosition: number, leftPosition?: number) {
    const threshold = 10;

    if (leftPosition === undefined) return;

    setIsStartOfScroll(leftPosition <= threshold);
    setIsEndOfScroll(leftPosition >= maxLeftPosition - threshold);
  }

  function initialPlaceInView(scrollViewElement: HTMLDivElement) {
    const childElementToPlaceInView = document.getElementById("scrollElementIntoView");
    const offsetWidth = scrollViewElement.offsetWidth;
    const centerPositionChild = (childElementToPlaceInView?.offsetLeft ?? 0) - offsetWidth / 2;
    const scrollTo = childElementToPlaceInView ? centerPositionChild : 0;

    scrollViewElement.scrollTo({
      left: scrollTo,
    });
  }

  useLayoutEffect(() => {
    if (!elementRef.current) return;
    const scrollWidth = elementRef.current.scrollWidth;
    const elementWidth = elementRef.current.offsetWidth;

    const maxLeftScroll = scrollWidth - elementWidth;
    const isElementScrollable = scrollWidth > elementWidth;
    setIsScrollable(isElementScrollable);
    if (!isElementScrollable || scrollWidth === 0 || isElementScrollable === isScrollable) return;

    initialPlaceInView(elementRef.current);
    onScroll(maxLeftScroll, elementRef.current.scrollLeft);
    elementRef.current.onscroll = () =>
      onScroll((scrollWidth || 0) - (elementWidth || 0), elementRef.current?.scrollLeft);
  }, [elementRef.current?.scrollWidth, elementRef.current?.offsetWidth, isScrollable, width]);

  return {
    isScrollable,
    isEndOfScroll,
    isStartOfScroll,
  };
}
