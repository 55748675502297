import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { remCalc } from "../utils/fonts";
import { ParagraphProps } from ".";

type StyledParagraphProps = Pick<
  ParagraphProps,
  | "variant"
  | "removeMargin"
  | "textAlign"
  | "textDecorationLine"
  | "ellipsisOverflow"
  | "verticalAlign"
  | "isFaded"
  | "whiteSpace"
>;

export const StyledParagraph = styled.p<StyledParagraphProps>`
  text-align: ${({ textAlign }) => textAlign};
  white-space: ${({ whiteSpace }) => whiteSpace};
  text-decoration-line: ${({ textDecorationLine }) => textDecorationLine};
  font-weight: normal;
  white-space: ${({ whiteSpace }) => whiteSpace};

  cite {
    font-style: normal;
  }

  cite::before {
    content: "“";
  }

  cite::after {
    content: "”";
  }

  em,
  strong,
  time {
    font-style: normal;
    font-weight: 500;
  }

  strong,
  b {
    font-weight: bold;
  }

  code {
    background-color: ${colors.blue100};
    padding: 0.24rem 0.5rem;
  }

  ${({ ellipsisOverflow }) => {
    if (ellipsisOverflow) {
      return css`
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }
  }}
  ${({ isFaded }) => {
    if (isFaded) {
      return css`
        opacity: 0.6;
      `;
    }
  }}

  ${({ verticalAlign }) => {
    if (verticalAlign) {
      return css`
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
      `;
    }
  }}

  ${({ variant }) => {
    if (variant === "paragraph-100") {
      return css`
        line-height: 1.5;
        font-size: 1rem;
      `;
    }
    if (variant === "paragraph-200") {
      return css`
        line-height: 1.75;
        font-size: 1rem;
      `;
    }
    if (variant === "additional-50") {
      return css`
        line-height: 1.5;
        font-size: ${remCalc(12)};
      `;
    }
    if (variant === "additional-100") {
      return css`
        line-height: 1.5;
        font-size: ${remCalc(14)};
      `;
    }
    if (variant === "preamble-200") {
      return css`
        line-height: 1.5;
        font-size: ${remCalc(22)};
      `;
    }
    if (variant === "preamble-100") {
      return css`
        line-height: 1.5;
        font-size: ${remCalc(20)};
      `;
    }
    if (variant === "overline-200") {
      return css`
        line-height: 1;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 1rem;
      `;
    }
    if (variant === "overline-100") {
      return css`
        line-height: 1;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: ${remCalc(14)};
      `;
    }
  }}
  margin: 0;

  ${({ variant, removeMargin }) => {
    if (removeMargin) return;
    if (variant === "additional-100" || variant === "overline-100") {
      return css`
        margin-bottom: ${remCalc(8)};
      `;
    }
    if (variant === "overline-200") {
      return css`
        margin-bottom: ${remCalc(12)};
      `;
    }

    if (variant === "preamble-100") {
      return css`
        margin-bottom: ${remCalc(32)};
      `;
    }
    if (variant === "preamble-200") {
      return css`
        margin-bottom: ${remCalc(48)};
      `;
    }
    return css`
       {
        margin-bottom: ${remCalc(16)};
      }
    `;
  }}

  :last-child {
    margin-bottom: 0;
  }
`;
