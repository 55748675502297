import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { OfferingPriceCodes, OfferingPriceDetails, SubscriptionUsageFragment } from "../../gql/graphql";
import { quantityToBytes } from "../../util/data-usage";
import { getIncludedData } from "../../pages/home/card/util";

dayjs.extend(duration);
dayjs.extend(relativeTime);

type OfferingPriceDetailsSubset = Pick<OfferingPriceDetails, "code" | "amount" | "monetaryUnit">;

function getOfferingByCode(
  code: OfferingPriceCodes,
  offeringPrices: (OfferingPriceDetailsSubset | null | undefined)[]
) {
  return offeringPrices.find((offeringPrice) => offeringPrice?.code === code);
}

export function getCurrentDownloadSpeed(
  usage: NonNullable<SubscriptionUsageFragment["usage"]>,
  userOffering: NonNullable<SubscriptionUsageFragment["userOffering"]>
) {
  const flattenedOfferingPrices = userOffering.offeringPrices?.flatMap((offering) => offering?.offeringPrices);
  const teliaxUsage = usage.filter((item) => item?.category === "DATA" && item.type === "TELIAX");

  if (!flattenedOfferingPrices) return null;

  const totalDataUsage = teliaxUsage.reduce(
    (totalUsage, item) => (item?.usage ? (totalUsage += item.usage) : totalUsage),
    0
  );

  const dataLimitSpeedReduced = getOfferingByCode(OfferingPriceCodes.DataLimitSpeedReduced, flattenedOfferingPrices);
  const speedDownloadReduced = getOfferingByCode(OfferingPriceCodes.SpeedDownloadReduced, flattenedOfferingPrices);

  const speedDownload = getOfferingByCode(OfferingPriceCodes.SpeedDownload, flattenedOfferingPrices);

  const dataLimitSpeedReducedAsByte = quantityToBytes({
    amount: dataLimitSpeedReduced?.amount,
    unit: dataLimitSpeedReduced?.monetaryUnit,
  });

  if (dataLimitSpeedReducedAsByte && totalDataUsage > dataLimitSpeedReducedAsByte)
    return { speedDownload: speedDownloadReduced, isReduced: true };

  return { speedDownload, isReduced: false };
}

export function getDataBoostRemainingTime(usage: NonNullable<SubscriptionUsageFragment["usage"]>) {
  const dataBoost = usage.find((item) => item?.activeDataBoostSession);

  if (!dataBoost) return null;

  const now = dayjs();
  const date = dayjs(dataBoost.expireDate);
  const duration = dayjs.duration(date.diff(now));
  const hours = duration.hours();
  const minutes = duration.minutes();
  const timeRemaining = `${hours > 0 ? `${hours}t ` : ""}${minutes}m`;

  return timeRemaining;
}

function isSubscriptionWithUsage(usage: NonNullable<SubscriptionUsageFragment["usage"]>) {
  return usage.some((item) => item?.type === "BASE" || item?.type === "ROLLOVER");
}

export function getSumDataUsage(
  usage: NonNullable<SubscriptionUsageFragment["usage"]>,
  userOffering: SubscriptionUsageFragment["userOffering"],
  summerCampaignDataAmount?: number,
  summerCampaignDataAmountUnit?: string
) {
  const includedData = getIncludedData(userOffering);
  const includedDataAmount = quantityToBytes({
    amount: includedData?.amount,
    unit: includedData?.monetaryUnit,
  });

  const summerCampaignData =
    summerCampaignDataAmount && summerCampaignDataAmountUnit
      ? quantityToBytes({
          amount: summerCampaignDataAmount,
          unit: summerCampaignDataAmountUnit,
        })
      : undefined;

  const totalData = (includedDataAmount || 0) + (summerCampaignData || 0);

  if (includedDataAmount && !isSubscriptionWithUsage(usage)) {
    return {
      base: totalData,
      rollover: 0,
      totalDataUsed: 0,
      totalDataToUse: totalData,
      totalDataRemaining: totalData,
    };
  }

  if (!isSubscriptionWithUsage(usage)) return null;
  const { base, rollover, topups } = usage
    .filter((item) => item?.category === "DATA")
    .reduce(
      (acc, item) => {
        if (item?.type === "BASE" && item.used) {
          acc.base.used += item.used.amount;
        }
        if (item?.type === "BASE" && item.remaining) {
          acc.base.remaining += item.remaining.amount;
        }
        if (item?.type === "ROLLOVER" && item.used) {
          acc.rollover.used += item.used.amount;
        }
        if (item?.type === "ROLLOVER" && item.remaining) {
          acc.rollover.remaining += item.remaining.amount;
        }
        if (item?.type === "TOPUPS" && item.used) {
          acc.rollover.used += item.used.amount;
        }
        if (item?.type === "TOPUPS" && item.remaining) {
          acc.rollover.remaining += item.remaining.amount;
        }
        return acc;
      },
      { base: { used: 0, remaining: 0 }, rollover: { used: 0, remaining: 0 }, topups: { used: 0, remaining: 0 } }
    );

  const totalDataUsed = base.used + rollover.used + topups.used;
  const totalDataRemaining = base.remaining + rollover.remaining + topups.remaining;
  const totalDataToUse = totalDataUsed + totalDataRemaining;

  return { base, rollover, topups, totalDataUsed, totalDataToUse, totalDataRemaining };
}

export type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export function isValidSpeedDownload(
  offeringPriceDetails: OfferingPriceDetailsSubset | undefined | null
): offeringPriceDetails is NonNullableFields<OfferingPriceDetailsSubset> {
  return (
    typeof offeringPriceDetails?.amount === "number" &&
    typeof offeringPriceDetails?.monetaryUnit === "string" &&
    typeof offeringPriceDetails?.code === "string"
  );
}
