import { types } from "@telia-no-min-side/utils";
import { ShoppingSummary } from "components/shopping-summary";
import { getSimplePriceText } from "util/priceFormatter";
import { getChargePeriodText } from "util/prices";

type Props = {
  newTvBox?: types.fixed.ProductGroupSingleProductWithShipping;
  recording?: {
    addon?: types.fixed.ProductGroupSingleProductAddon;
    price?: types.fixed.ProductGroupPriceExtended;
  };
  modem?: types.fixed.ProductGroupSingleProductWithShipping;
};

export function MonthList(props: Props): JSX.Element {
  const { newTvBox, recording, modem } = props;
  const chargePeriod = newTvBox ? newTvBox.price.currentPrice.chargePeriod : "md.";

  const recordingPrice = recording?.price ? recording?.price?.currentPrice.recurring : 0;
  const boxPrice = newTvBox ? newTvBox.price.currentPrice.recurring : 0;
  const modemPrice = modem ? modem.price.currentPrice.recurring : 0;

  function totalPrice() {
    const price = recordingPrice + boxPrice + modemPrice;
    return getSimplePriceText(price, chargePeriod);
  }

  const chargePeriodTextLowercase = getChargePeriodText(chargePeriod, false);
  const chargePeriodTextCapitalized = getChargePeriodText(chargePeriod, true);

  return (
    <ShoppingSummary.List title={chargePeriodTextCapitalized + "spris"}>
      {newTvBox && (
        <ShoppingSummary.Item
          text={newTvBox.name ? newTvBox.name : "-"}
          price={getSimplePriceText(boxPrice, chargePeriod)}
        />
      )}
      {modem?.price && (
        <ShoppingSummary.Item
          text={modem.name ? modem.name : "-"}
          price={getSimplePriceText(modemPrice, chargePeriod)}
        />
      )}
      {recording?.price && (
        <ShoppingSummary.Item text={"Opptak"} price={getSimplePriceText(recordingPrice, chargePeriod)} />
      )}

      <ShoppingSummary.Item
        text={"Totalt per " + chargePeriodTextLowercase}
        price={totalPrice()}
        highlight
        secondaryText="Belastes på din neste faktura"
      />
    </ShoppingSummary.List>
  );
}
