import { Flex } from "@telia/teddy";
import { Skeleton } from "@telia-no-min-side/components";
import { LineSeparator } from "pages/flex/styles/style";

export function ProductCardSkeleton() {
  return (
    <Flex direction="column" p={"24px"} style={{ border: "1px solid rgb(242, 242, 242)", borderRadius: "0.5rem" }}>
      <Skeleton width="20ch" />
      <Flex direction="row" align="center" justify="between">
        <Skeleton width="20ch" />
        <Skeleton height="3ch" width="6ch" />
      </Flex>
      <LineSeparator />
      <Skeleton variant="circular" height="3ch" width="3ch" />
    </Flex>
  );
}
