import { PaymentArrangement, Maybe, PaymentBalance } from "gql/graphql";
import dayjs from "dayjs";

export function isInvoicePaymentOverdue(
  paymentArrangement: Maybe<Partial<PaymentArrangement>[]> | undefined,
  paymentBalance: Maybe<PaymentBalance> | undefined
) {
  // Users can postpone their invoice payment date.
  // The postoponed date is stored in the paymentArrangement object.
  // Original payment date is stored in the paymentBalance object.
  const delayedPaymentArrangementDate = paymentArrangement?.[0]?.expectedPaymentDate || "";
  const paymentDate = delayedPaymentArrangementDate || paymentBalance?.dueDate;
  const paymentOverdue = (paymentBalance?.amount || 0) > 0 && dayjs().isAfter(paymentDate, "day");

  return paymentOverdue;
}
