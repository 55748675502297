import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { remCalc } from "../utils/fonts";
import { TextInputProps } from "./TextInput";

type IconPlacement = {
  iconBefore?: boolean;
  iconAfter?: boolean;
};

const iconWidth = "25px";

export const Input = styled.input<IconPlacement>`
  border: 1px solid ${colors.grey400};
  border-radius: 2px;
  background-color: #ffffff;
  color: ${colors.black};
  font-family: inherit;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding-block: ${remCalc(10)};

  padding-right: calc(${(props) => (props.iconAfter ? `${iconWidth} + ${remCalc(12)}` : remCalc(12))});
  padding-left: calc(${(props) => (props.iconBefore ? `${iconWidth} + ${remCalc(12)}` : remCalc(12))});
  width: 100%;
  height: ${remCalc(48)};

  &:hover:not(:disabled) {
    outline: 2px solid ${colors.black};
    outline-offset: -2px;
  }

  &:focus {
    outline: ${remCalc(2)} solid ${colors.blue500};
    outline-offset: ${remCalc(2)};
  }

  &[aria-invalid="true"]:not(:focus):not(:disabled) {
    border: 1px solid ${colors.red600};
    &:hover {
      outline-color: ${colors.red600};
    }
  }

  &:disabled {
    background-color: ${colors.grey200};
    color: ${colors.grey600};
    cursor: not-allowed;
    -webkit-text-fill-color: ${colors.grey600};
  }
`;

export const Label = styled.label`
  font-size: 1rem;
  color: ${colors.black};
  font-weight: 500;
  line-height: 1.25;
`;

export const Additional = styled.span`
  color: ${colors.grey700};
`;

export const Error = styled.span`
  font-size: 1rem;
  line-height: 1.5;
  color: ${colors.red600};
  display: flex;
  flex-direction: row;
  gap: ${remCalc(4)};
  align-items: center;
`;

export const Required = styled.span`
  line-height: ${remCalc(20)};
`;

export const InputIconWrapper = styled.div`
  position: relative;
`;

const absoluteIcon = css`
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  display: flex;
`;

export const IconWrapper = styled.div`
  ${absoluteIcon}
  left: 5px;
`;

export const IconWrapperRight = styled.div`
  ${absoluteIcon}
  right: 5px;
`;

export const ClearButton = styled.button`
  background: none;
  border: none;
  outline: none;
  display: grid;
  color: black;
  :focus {
    outline: 3px solid ${colors.blue500};
  }
  :hover {
    cursor: pointer;
  }
`;

type TextInputWrapperProps = Pick<TextInputProps, "disabled">;
export const TextInputWrapper = styled.div<TextInputWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: ${remCalc(4)};

  ${({ disabled }) => {
    if (disabled) {
      return css`
        ${Label} {
          color: ${colors.grey600};
          cursor: not-allowed;
        }
        ${Additional} {
          color: ${colors.grey600};
          cursor: not-allowed;
        }
      `;
    }
  }}
`;
