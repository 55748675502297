import { ComponentProps } from "react";
import { useProfilePicker } from "./hooks/useProfilePicker";
import { types } from "@telia-no-min-side/utils";
import { ProfilePickerTypes, ProfilePicker } from "@telia-no-min-side/components";
import { useFixedAccount } from "src/hooks/useFixedAccount";
import { datadogRum } from "@datadog/browser-rum";

/**
 * FixedProfilePicker is used for switching between fixed.
 * customer profiles. It displays all existing fixed customers
 * (even when they don't have broadband subscription).
 */

function getSelectedProfile(
  profiles: ProfilePickerTypes.Profile<types.fixed.UserWithCustomerId>[]
): types.fixed.UserWithCustomerId | undefined {
  const selectedProfile = (profiles || []).find((profile) => profile.value.selected);
  return selectedProfile?.value;
}

type FixedProfilePickerProps = {
  dataTrackingID?: string;
  pickerVariant?: ComponentProps<typeof ProfilePicker>["variant"];
};

export function FixedProfilePicker({ dataTrackingID, pickerVariant }: FixedProfilePickerProps) {
  const { fixed } = useProfilePicker({ filter: "fixed" });
  const { changeFixedAccount } = useFixedAccount();
  const currentSelectedProfile = getSelectedProfile(fixed.profiles);

  const showRetry = !fixed.loading && fixed.hasError;

  const onRetry = () => {
    if (fixed.hasError) {
      fixed.refetch();
    }
  };

  const onProfileChange = async (profile: types.fixed.UserWithCustomerId) => {
    try {
      // SWR will change the selected profile immediately
      // in cache. There is no need to store selected profile locally
      // during the request duration
      await changeFixedAccount(profile.userKey);
    } catch (error) {
      datadogRum.addError(error, { feature: "FixedProfilePicker" });
    }
  };

  const showLoading = !fixed.profiles?.length && fixed.loading;

  return (
    <ProfilePicker
      profiles={fixed.profiles}
      loading={showLoading}
      selectedProfile={currentSelectedProfile}
      onChange={onProfileChange}
      showRetry={showRetry}
      onRetry={onRetry}
      dataTrackingId={dataTrackingID}
      variant={pickerVariant}
      containerProps={{ gap: true }}
    />
  );
}
