import React, { ReactNode, SyntheticEvent } from "react";
import { Button, ButtonVariant, CardBaseProps, Container } from "@telia-no-min-side/components";
import { track } from "@telia-no-min-side/utils";
import { ActionContainer, CardLink, InnerCardGrid, InteractiveCard, LinkCardImage, ScreenReaderOnly } from "./style";
import { IconDefinition } from "@telia/styleguide";

export type LinkCardProps = {
  children?: ReactNode;
  isGridLayout?: boolean;
  floatButtonRight?: boolean;
  onLinkNavigation?: (e: SyntheticEvent) => void;
  routerState?: Record<string, unknown>;
  buttonVariant?: ButtonVariant;
  hideLinkIcon?: boolean;
  newTabLink?: boolean;
  lozenge?: CardBaseProps["lozengeRight"];
  footer?: ReactNode;
  dataTrackingID?: string;
  uiItemContext?: string;
  buttonIcon?: IconDefinition;
  img?: {
    src: string;
    alt: string;
  };
} & (
  | {
      href: string;
      isInternalNavigation: boolean;
      linkText: string;
      cardHref?: string;
      cardLinkText?: string;
      isCardLinkInternalNavigation?: boolean;
    }
  | {
      href?: string;
      isInternalNavigation?: boolean;
      linkText?: string;
      cardHref: string;
      cardLinkText: string;
      isCardLinkInternalNavigation: boolean;
    }
) &
  Pick<CardBaseProps, "backgroundColor" | "lozengeLeft" | "removeMinHeight">;

export function LinkCard({
  children,
  isInternalNavigation,
  onLinkNavigation,
  linkText,
  href,
  cardHref,
  isGridLayout,
  buttonVariant = "text-purple",
  floatButtonRight,
  cardLinkText,
  newTabLink = false,
  removeMinHeight,
  hideLinkIcon = true,
  img,
  isCardLinkInternalNavigation,
  backgroundColor,
  routerState,
  lozenge,
  lozengeLeft,
  uiItemContext = "Hjem",
  footer,
  dataTrackingID,
  buttonIcon,
}: LinkCardProps): JSX.Element {
  const { pushGenericTrackingEvent } = track.useEventTracking();

  function onLinkClick(e: SyntheticEvent) {
    e.stopPropagation();
    if (onLinkNavigation) {
      onLinkNavigation(e);
    }
  }

  const hasCardLink = typeof cardHref === "string";

  return (
    <InteractiveCard
      removeMinHeight={removeMinHeight}
      lozengeRight={lozenge}
      lozengeLeft={lozengeLeft}
      maxWidth="xxs"
      backgroundColor={backgroundColor}
      showHoverShadow
      dataTrackingID={dataTrackingID}
    >
      {img && <LinkCardImage {...img} />}
      <Container flexDirection="column">
        {isGridLayout && <InnerCardGrid>{children}</InnerCardGrid>}
        {!isGridLayout && children}
        <ActionContainer flexDirection="column">
          <CardLink
            aria-hidden={!hasCardLink}
            tabIndex={hasCardLink ? 0 : -1}
            onClick={(e) => {
              pushGenericTrackingEvent({
                ui_item_action: "link_click",
                ui_item_context: uiItemContext,
                ui_item_type: "card",
                ui_item_url: cardHref || href || "no_url",
                ui_item_text: cardLinkText || linkText || "no_text",
              });
              onLinkClick(e);
            }}
            href={cardHref || href || ""}
            reactRouterState={routerState}
            isInternalNavigation={isCardLinkInternalNavigation || isInternalNavigation}
          >
            <ScreenReaderOnly>{cardLinkText || linkText}</ScreenReaderOnly>
          </CardLink>
          {linkText && href && (
            <Container
              floatContent={floatButtonRight ? "right" : undefined}
              flexDirection={footer ? "row" : undefined}
              justifyContent={footer ? "space-between" : undefined}
            >
              <Button
                tag="a"
                variant={buttonVariant}
                hideLinkIcon={hideLinkIcon}
                size="sm"
                reactRouterState={routerState}
                href={href}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                  pushGenericTrackingEvent({
                    ui_item_action: "link_click",
                    ui_item_context: uiItemContext,
                    ui_item_type: "link",
                    ui_item_url: href,
                    ui_item_text: linkText,
                  });
                  onLinkClick(e);
                }}
                isInternalNavigation={isInternalNavigation}
                icon={buttonIcon}
                {...(newTabLink ? { target: "_blank", rel: "noreferrer" } : {})}
              >
                {linkText}
              </Button>
              {footer}
            </Container>
          )}
        </ActionContainer>
      </Container>
    </InteractiveCard>
  );
}
