import { format } from "@telia-no-min-side/utils";
import { AdditionalProduct } from "gql/graphql";
import { BlankSimCardType } from "pages/mobile/mobile-subscription-order-blank-sim-card/components/OrderBlankSimCardSummary";
import { formatDatalayerTransactionData } from "src/services/googleAnalytics/eventDataHelpers";
import { getTax } from "util/tracking";

export function getTransactionDataSIM(product: AdditionalProduct, orderId?: string | null) {
  const isActiveProduct = !!product.presentation?.active;
  const isReplacementCard = Object.values(BlankSimCardType).includes(product.code as BlankSimCardType);

  return formatDatalayerTransactionData({
    actionId: orderId || "",
    revenue: isActiveProduct ? 0 : product.price?.price || product.price?.priceWithoutDiscount || 0,
    shipping: 0,
    name: isReplacementCard ? "Replacement_sim" : format.startCase(product.productGroup || ""),
    id: product?.code,
    price: isActiveProduct ? 0 : product.price?.price || product.price?.priceWithoutDiscount || 0,
    tax: isActiveProduct ? 0 : getTax(product.price?.price || product.price?.priceWithoutDiscount || 0),
    category: isReplacementCard ? "SERVICE/SIM-CARD/Replacement" : "SERVICE/SIM-CARD",
    isActiveProduct,
  });
}
