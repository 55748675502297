import { Maybe, PoboxAddress, UrbanAddress, Address } from "gql/graphql";
import { format } from "@telia-no-min-side/utils";

export const formatPoboxAddress = (poboxAddress: Maybe<PoboxAddress> | undefined) => {
  if (!poboxAddress) return "";
  return [poboxAddress?.boxName, poboxAddress?.boxNr].filter((a) => !!a).join(" ");
};

export const formatUrbanAddress = (urbanAddress: Maybe<UrbanAddress> | undefined) => {
  if (!urbanAddress) return "";
  return [urbanAddress?.streetName, urbanAddress?.streetNr, urbanAddress?.streetNrSuffix].filter((a) => !!a).join(" ");
};

export const formatAddress = (address: Address | undefined | null) => {
  if (!address) return "";
  const displayLocation = address?.poboxAddress || address?.urbanAddress;
  const displayAddress = formatPoboxAddress(address.poboxAddress) || formatUrbanAddress(address?.urbanAddress);
  return format.startCase(`${displayAddress}, ${displayLocation?.postcode} 
                ${displayLocation?.city}`);
};

/**
 * This function checks whether any of the postbox-address (PO) object properties have values.
 * If the customer has a post box address related to it,the function returns true; and if not, false is returned.
 */

export const hasPoboxAddress = (address: Address) =>
  address?.poboxAddress && Object.entries(address.poboxAddress).some(([key, value]) => key !== "__typename" && !!value);
