import { devices, remCalc } from "@telia-no-min-side/components";
import styled from "styled-components";

export const FeatureList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: ${remCalc(20)};

  @media ${devices.md_up} {
    display: grid;
    grid-template-columns: minmax(auto, ${remCalc(300)}) minmax(auto, ${remCalc(300)});
  }
`;

export const Feature = styled.li`
  display: flex;
  gap: 0.5rem;
`;
