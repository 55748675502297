import { isPricePlanMobileBroadband } from "src/util/format-subscription/isPricePlanMobileBroadband";
import { Profiles, useProfilePicker } from "./useProfilePicker";

export function useBroadbandProfiles() {
  const { mobile, fixed } = useProfilePicker();

  const loading = mobile.loading || (fixed.loading && !fixed.profiles);

  const mobileBroadbandProfiles = mobile.profiles.filter((profile) =>
    isPricePlanMobileBroadband(profile.value?.userOffering?.pricePlan)
  );

  const profiles = [...fixed.profiles, ...mobileBroadbandProfiles] as Profiles;

  return { profiles, loading };
}
