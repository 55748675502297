import { UserOverviewDataQuery, UserRole } from "gql/graphql";
import { useMobileAccount } from "hooks/useMobileAccount";

export type SubscriptionType = "MOBILE" | "MBB";

export function isSubscriptionOfType(
  subscription: NonNullable<NonNullable<UserOverviewDataQuery["accounts"]>[0]["subscriptions"]>[0],
  subscriptionType: SubscriptionType
) {
  return (subscription?.userOffering?.additionalProperties || []).some(
    (property) => property?.name === "RESOURCE_TYPE" && property?.value === subscriptionType
  );
}

export function getSubscriptionsByResourceType(
  accounts: UserOverviewDataQuery["accounts"],
  subscriptionType: SubscriptionType
) {
  const allSubscriptions = (accounts || []).flatMap((account) => {
    if (!account?.subscriptions?.length) return [];
    const { subscriptions, ...accountData } = account;
    return subscriptions.map((subscription) => ({ ...subscription, account: accountData }));
  });

  return allSubscriptions.filter((subscription) => isSubscriptionOfType(subscription, subscriptionType));
}

export const useSubscriptionOverview = () => {
  const { allAccounts, isLoading, error, refetch, isRefetching } = useMobileAccount();

  const mobileSubscriptionsOverview = getSubscriptionsByResourceType(allAccounts, "MOBILE");
  const mbbSubscriptionsOverview = getSubscriptionsByResourceType(allAccounts, "MBB");
  const hasAnyAccounts = (allAccounts || []).length > 0;
  const hasAnySubscriptions = mobileSubscriptionsOverview.length > 0 || mbbSubscriptionsOverview.length > 0;

  const legalOwnerAccounts = (allAccounts || []).filter(
    (account) => account.id && account.roles.includes(UserRole.LegalOwner)
  );
  const hasLegalOwnerAccounts = legalOwnerAccounts.length > 0;

  return {
    refetch,
    isRefetching,
    loading: isLoading,
    error,
    mobileSubscriptionsOverview,
    mbbSubscriptionsOverview,
    hasAnySubscriptions,
    hasAnyAccounts,
    legalOwnerAccounts,
    hasLegalOwnerAccounts,
  };
};
