import { graphql } from "gql";

export const ACCOUNTS_QUERY = graphql(`
  query AccountsQuery {
    accounts {
      id
      subscriptions {
        subscriptionRank
        phoneNumber {
          localNumber
        }
        additionalProducts {
          id
          code
          productGroup
          productCategory
          name
          productTerms {
            code
            amount
          }
        }
        userOffering {
          pricePlan
          monthlyCost {
            amount
          }
          additionalProperties {
            name
            value
          }
          originalPrice {
            amount
          }
          discountedPrice {
            amount
            monetaryUnit
            amountWithoutVat
          }
          offeringPrices {
            offeringPrices {
              code
              amount
              monetaryUnit
            }
          }
        }
        ...SubscriptionCardWithDetailsFragment
      }
    }
  }
`);
