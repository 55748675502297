import styled, { css, keyframes } from "styled-components";
import { zIndex } from "../utils/variables";
import { BackdropProps } from ".";

type StyledBackdropProps = Pick<BackdropProps, "color" | "isVisible" | "zIndex" | "fadeIn">;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledBackdrop = styled.div<StyledBackdropProps>`
  position: fixed;
  inset: 0px;
  z-index: ${(props) => props.zIndex ?? zIndex.backDrop};
  display: ${(props) => (props.isVisible ? "block" : "none")};
  ${(props) => {
    if (props.color === "black") {
      return css`
        background-color: rgba(0, 0, 0, 0.2);
      `;
    }
  }}
  ${({ fadeIn }) => {
    if (fadeIn) {
      return css`
        animation: ${fadeInAnimation} 0.3s ease-in-out;
      `;
    }
  }}
`;
