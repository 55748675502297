import { Container, TrackClickEvent, useAuth } from "@telia-no-min-side/components";
import { SwitchHeading } from "../Common/SwitchHeading";
import { Button, Heading, Icon, Text } from "@telia/teddy";
import { Link, useOutletContext } from "react-router-dom";
import { useSafeWebshopUrl, webshopSwitchPath, initialDataToEncrypt } from "../../utils/createWebshopUrl";
import { L } from "util/links";
import { SwitchOutletContext } from "../../types";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { toLocalPhoneNumber } from "@telia-no-min-side/utils/src/format";

export function NoDamage() {
  const { switchAgreement, accountId } = useOutletContext<SwitchOutletContext>();
  const { userInfo } = useAuth();
  const userPhoneNumber = toLocalPhoneNumber(userInfo?.phone_number);

  const { data: safeWebshopUrlData, loading } = useSafeWebshopUrl({
    host: L.TeliaNoUrl.href,
    path: webshopSwitchPath,
    dataToEncrypt: {
      ...initialDataToEncrypt,
      imei: switchAgreement?.imei || "",
      msisdn: switchAgreement?.phoneNumber?.localNumber || "",
      orderer_msisdn: switchAgreement?.phoneNumber?.localNumber || userPhoneNumber,
      ban: accountId,
    },
  });
  const selectNewPhoneButtonText = switchAgreement?.costToReplace?.value
    ? `Velg ny telefon for ${switchAgreement.costToReplace.value} kr`
    : "Velg ny telefon";

  return (
    <Container flexDirection="column" alignItems="start" gap="lg">
      <SwitchHeading
        preamble="Velg telefonens tilstand"
        heading="Ingen skader"
        dataTrackingId="switch-no-damage-heading"
      />
      <Container
        flexDirection="column"
        alignItems="start"
        gap="lg"
        data-tracking-id="switch-no-damage-details-container"
      >
        <Heading as="h4" variant="title-100">
          Så bra!
        </Heading>
        <Text data-tracking-id="switch-no-damage-return-phone-text">
          Du får en retureske til den gamle telefonen i posten og instruksjoner om retur når du har mottatt den nye. Nå
          kan du velge deg en ny telefon i nettbutikken.
        </Text>
        <Text data-tracking-id="switch-no-damage-insurance-offer">
          PS: Hvis du vil ha Plussforsikring på din nye telefon, må du velge det også (den følger ikke med automatisk).
        </Text>
        <Container flexDirection="column" alignItems="start" gap>
          <TrackClickEvent
            asChild
            event={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_no-damage",
              ui_item_text: selectNewPhoneButtonText,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
              ui_item_url: safeWebshopUrlData?.safeWebshopUrl || "",
            }}
          >
            <Button asChild variant="primary" disabled={loading}>
              <a
                href={safeWebshopUrlData?.safeWebshopUrl || ""}
                data-tracking-id="switch-no-damage-select-phone-button"
              >
                <Icon name="trade-phone" />
                {selectNewPhoneButtonText}
              </a>
            </Button>
          </TrackClickEvent>
          <TrackClickEvent
            asChild
            event={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_CANCEL,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_no-damage",
              ui_item_text: "Tilbake til forrige steg",
              ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
              ui_item_url: "..",
            }}
          >
            <Button asChild variant="text">
              <Link to=".." data-tracking-id="switch-no-damage-back-button">
                <Icon name="arrow-left" />
                Tilbake til forrige steg
              </Link>
            </Button>
          </TrackClickEvent>
        </Container>

        <Text variant="additional-100" data-tracking-id="switch-no-damage-additional-text">
          Hvis det er en skade på telefonen vil vi ta kontakt med deg. Det kan påbeløpe kostnader for reparasjon.
        </Text>
      </Container>
    </Container>
  );
}
