import Analytics from "../../../util/Analytics";

const routerCategory = "router-settings";

export const routerAnalytics = {
  trackNavigation(fromPage, toPage) {
    Analytics.trackEvent(
      routerCategory,
      "navigation",
      `${fromPage} - ${toPage}`
    );
  },
  trackButtonClick(buttonName) {
    Analytics.trackEvent(routerCategory, "click", buttonName);
  },
  trackStoreClick(typeOfSettingsStored) {
    Analytics.trackEvent(routerCategory, "store", typeOfSettingsStored);
  },
  trackStoreResponse(typeOfSettings, success) {
    const statusMessage = success ? "suksess" : "feil";

    Analytics.trackEvent(
      routerCategory,
      "store-response",
      `${typeOfSettings} - ${statusMessage}`
    );
  },
  trackFetchSettingsResponse(success) {
    const statusMessage = success ? "suksess" : "feil";

    Analytics.trackEvent(routerCategory, "fetch-response", statusMessage);
  },
};
