import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { IconDefinition } from "@telia/styleguide";
import { remCalc } from "../utils/fonts";
import { Icon, IconProps } from "../Icon";

type ListProps = {
  spacing?: "small" | "regular";
};

const Li = styled.li<{ center: boolean; iconSize?: IconProps["size"] }>`
  font-size: 0;
  padding: 0;
  display: flex;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  :not(:first-of-type) {
    margin: ${remCalc(15)} 0 0;
  }
  svg {
    margin: 0 0.5rem 0.75rem 0;
    overflow: visible;
    ${(props) => {
      if (!props.iconSize) {
        return css`
          height: ${remCalc(20)};
          width: ${remCalc(20)};
        `;
      }
    }}
  }
  em {
    font-weight: 500;
    font-style: normal;
  }
  ${(props) => {
    if (props.center) {
      return css`
        align-items: center;
        svg {
          margin: 0 0.5rem 0 0;
        }
      `;
    }
  }}
`;

export const List = styled.ul<ListProps>`
  list-style: none;
  margin: 0.5rem 0 0;
  padding: 0;
  ${(props) => {
    if (props.spacing === "small") {
      return css`
        ${Li} {
          &:not(:first-of-type) {
            margin: ${remCalc(5)} 0 0;
          }
        }
      `;
    }
  }}
`;

type Props = {
  icon?: IconDefinition;
  iconColor?: string;
  iconSize?: IconProps["size"];
  center?: boolean;
  className?: string;
  children: ReactNode;
};

/**
 * @deprecated Use Teddy components instead
 */
export function ListItem({
  icon,
  center = false,
  iconColor = "currentColor",
  className,
  iconSize,
  children,
  ...rest
}: Props) {
  return (
    <Li className={className} iconSize={iconSize} center={center} {...rest}>
      {icon && <Icon style={{ fill: iconColor }} icon={icon} size={iconSize} />} <div>{children}</div>
    </Li>
  );
}
