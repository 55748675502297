import cloneDeep from "lodash.clonedeep";

export const wifiAdminUtilities = {
  setAliasForDevice(originalDevice, alias) {
    const device = cloneDeep(originalDevice);

    device.Settings.Alias = alias;
    return device;
  },
  isExtenderSettingsEqualToHomeCentralSettings(
    homeCentralSettings,
    activeUnitObject
  ) {
    const activeUnitSettings = activeUnitObject.Settings;

    if (
      activeUnitObject.SupportedSettings.Wifi2GHz.Supported &&
      activeUnitObject.SupportedSettings.Wifi5GHz.Supported
    ) {
      const wifi2ghzEquality =
        activeUnitSettings.Wifi2GHz.Ssid ===
          homeCentralSettings.Wifi2GHz.Ssid &&
        activeUnitSettings.Wifi2GHz.Channel ===
          homeCentralSettings.Wifi2GHz.Channel &&
        activeUnitSettings.Wifi2GHz.SecurityProtocol ===
          homeCentralSettings.Wifi2GHz.SecurityProtocol &&
        activeUnitSettings.Wifi2GHz.Password ===
          homeCentralSettings.Wifi2GHz.Password;

      const wifi5ghzEquality =
        activeUnitSettings.Wifi5GHz.Ssid ===
          homeCentralSettings.Wifi5GHz.Ssid &&
        activeUnitSettings.Wifi5GHz.Channel ===
          homeCentralSettings.Wifi5GHz.Channel &&
        activeUnitSettings.Wifi5GHz.SecurityProtocol ===
          homeCentralSettings.Wifi5GHz.SecurityProtocol &&
        activeUnitSettings.Wifi5GHz.Password ===
          homeCentralSettings.Wifi5GHz.Password;

      return wifi2ghzEquality && wifi5ghzEquality;
    } else if (
      !activeUnitObject.SupportedSettings.Wifi2GHz.Supported &&
      activeUnitObject.SupportedSettings.Wifi5GHz.Supported
    ) {
      return (
        activeUnitSettings.Wifi5GHz.Ssid ===
          homeCentralSettings.Wifi5GHz.Ssid &&
        activeUnitSettings.Wifi5GHz.Channel ===
          homeCentralSettings.Wifi5GHz.Channel &&
        activeUnitSettings.Wifi5GHz.SecurityProtocol ===
          homeCentralSettings.Wifi5GHz.SecurityProtocol &&
        activeUnitSettings.Wifi5GHz.Password ===
          homeCentralSettings.Wifi5GHz.Password
      );
    } else {
      return (
        activeUnitSettings.Wifi2GHz.Ssid ===
          homeCentralSettings.Wifi2GHz.Ssid &&
        activeUnitSettings.Wifi2GHz.Channel ===
          homeCentralSettings.Wifi2GHz.Channel &&
        activeUnitSettings.Wifi2GHz.SecurityProtocol ===
          homeCentralSettings.Wifi2GHz.SecurityProtocol &&
        activeUnitSettings.Wifi2GHz.Password ===
          homeCentralSettings.Wifi2GHz.Password
      );
    }
  },
};

export const wifiAdminTags = {
  changeAlias: "change-alias",
};
