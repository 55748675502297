import { Text, color, Flex } from "@telia/teddy";

type Props = {
  originalPrice?: string;
  currentPrice?: string;
  secondaryText?: string;
};

export function FixedDevicePrice({ originalPrice, currentPrice, secondaryText }: Props) {
  return (
    <Flex direction="column">
      {originalPrice ? (
        <Text variant="additional-100" faded>
          Original pris: {originalPrice}
        </Text>
      ) : null}
      <Text variant="paragraph-100-bold" color={originalPrice ? color.teddyColorTextStatusDiscount : undefined}>
        {currentPrice}
      </Text>
      <Text>{secondaryText}</Text>
    </Flex>
  );
}
