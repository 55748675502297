import { useNavigate, useLocation } from "react-router-dom";
import { getHash, getNewLocationState, getPathnameFromRouterUrl, getSearchParams } from "../Link/utils/urlHelper";

export type LocationState<CustomState = object> = CustomState &
  (
    | {
        returnUrl: string;
      }
    | {
        returnUrl?: never;
      }
  );
export type Options<CustomState> = LocationState<CustomState> & object;

export function useReactRouterHistory<CustomState = object>() {
  const location = useLocation();
  const navigate = useNavigate();

  function getHistoryArgs(href: string) {
    const pathname = getPathnameFromRouterUrl(href);
    const search = getSearchParams(href);
    const hash = getHash(href);
    return { pathname, search, hash };
  }

  function getState(options?: Options<CustomState>) {
    const { state: prevState } = location;
    const state = getNewLocationState<CustomState>(prevState, options);
    return { state };
  }

  function push(href: string, options?: Options<CustomState>) {
    navigate(getHistoryArgs(href), getState(options));
  }
  function replace(href: string, options?: Options<CustomState>) {
    navigate(getHistoryArgs(href), { replace: true, ...getState(options) });
  }
  return {
    push,
    location,
    replace,
    getHistoryArgs,
    goBack: () => navigate(-1),
  };
}
