import { Card, Container, Paragraph } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import { B2BFamilyDiscountFlowQuery } from "gql/graphql";
import { CSSProperties } from "react";

type Props = {
  index: number;
  subscription: NonNullable<NonNullable<B2BFamilyDiscountFlowQuery["accounts"]>[number]["subscriptions"]>[number];
  style?: CSSProperties;
};

export function FamilySubscriptionCard({ subscription, style, index }: Props) {
  const { userOffering, productUser } = subscription;

  const fullPrice = userOffering?.originalPrice?.amount;
  const discountedPrice = userOffering?.offeringPrices?.[0]?.offeringPrices?.find(
    (price) => price?.code === "MONTHLY_CHARGE_LINE"
  )?.amount;

  return (
    <Card
      maxWidth="sm"
      removeMinHeight
      style={style}
      lozengeRight={
        discountedPrice !== undefined
          ? {
              label: `Familierabatt${index === 0 ? " legges til!" : ""}`,
              status: "communication",
            }
          : {}
      }
    >
      <Container alignItems="center" justifyContent="space-between" flexDirection="row" gap="lg">
        <Container flexDirection="column" gap>
          <Paragraph removeMargin isFaded>
            <i>{format.startCase(productUser?.firstName || "")}</i>
          </Paragraph>
          <Container flexDirection="row" gap="lg" alignItems="center" flexWrap width="unset">
            <Container gap width="unset">
              <Paragraph variant="preamble-100" removeMargin>
                <em>{userOffering?.shortName}</em>
              </Paragraph>
            </Container>
            <Container gap width="unset">
              {discountedPrice !== undefined && fullPrice !== discountedPrice && (
                <Paragraph
                  removeMargin
                  textDecorationLine="line-through"
                  data-tracking-id="mobile-subscription-original-price"
                >
                  {format.simplePriceText(fullPrice, "md.")}
                </Paragraph>
              )}
              <Paragraph removeMargin data-tracking-id="mobile-subscription-price">
                {format.simplePriceText(discountedPrice !== undefined ? discountedPrice : fullPrice, "md.")}
              </Paragraph>
            </Container>
          </Container>
        </Container>
      </Container>
    </Card>
  );
}
