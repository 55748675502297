import { Container } from "@telia-no-min-side/components";
import { setSearchParams, uri } from "@telia-no-min-side/utils";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Invoice } from "src/gql/graphql";
import { DateLink } from "../../styles";
import { Flex, ScrollArea } from "@telia/teddy";

type InvoiceSelectorMobileProps = {
  invoices?: Invoice[] | null;
  isSubLevel?: boolean;
};

export function InvoiceSelectorMobile({ invoices, isSubLevel }: InvoiceSelectorMobileProps): JSX.Element {
  const queryParams = {
    isMobile: true,
    isSubLevel,
  };

  const uriParams = setSearchParams(queryParams);
  const params = useParams();
  const currentInvoiceId = params.id;

  return (
    <Container maxWidth="sm">
      {invoices && invoices.length > 0 && (
        <ScrollArea dir="rtl">
          <Flex gap="gutter-sm">
            {invoices.map((invoice) => {
              const isCurrentDate = currentInvoiceId === `${invoice.invoiceNumber}`;
              return (
                <ScrollArea.Item isSelected={isCurrentDate} asChild key={`scroller-${invoice.invoiceNumber}`}>
                  <DateLink
                    to={uri.minSideFixed(`/faktura/${invoice.invoiceNumber}${uriParams}`)}
                    id={isCurrentDate ? "scrollElementIntoView" : undefined}
                    $isCurrentDate={isCurrentDate}
                  >
                    {dayjs(invoice.billingDate).format("MMM YY")}
                  </DateLink>
                </ScrollArea.Item>
              );
            })}
            <ScrollArea.Button direction="right" variant="primary-negative" size="sm" />
            <ScrollArea.Button direction="left" variant="primary-negative" size="sm" />
          </Flex>
        </ScrollArea>
      )}
    </Container>
  );
}
