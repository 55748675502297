import { Modal, Text, Link, Icon, Button } from "@telia/teddy";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function ReceiptErrorContent() {
  return (
    <Modal.Content>
      <Modal.Group variant="title">
        <Modal.Title as="h3">Vi kan dessverre ikke fullføre din ordre</Modal.Title>
      </Modal.Group>

      <Modal.Description>
        <Text variant="paragraph-100">
          Vennligst ta kontakt med kundeservice så snart som mulig.
          <Link target="_blank" variant="standalone" href={EXTERNAL_LINK.CUSTOMER_SERVICE_CONTACT_US}>
            <Icon name="arrow-right" />
            Kontakt kundeservice
          </Link>
        </Text>
      </Modal.Description>
      <Modal.Group variant="action">
        <Modal.Close slot="content">
          <Button variant="primary" size="sm">
            Lukk
          </Button>
        </Modal.Close>
      </Modal.Group>
    </Modal.Content>
  );
}
