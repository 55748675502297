import { graphql } from "src/gql";

export const accountsAgreementsQuery = graphql(`
  query AccountsAgreements {
    accounts {
      id
      switchAgreements {
        id
        ...SwitchAgreement
      }
      leaseAgreements {
        leases {
          id
          ...LeaseAgreement
        }
      }
      insuranceAgreements {
        serviceAgreementId
        ...InsuranceAgreement
      }
    }
  }
`);
