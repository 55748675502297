import { useEffect } from "react";
import { Heading, Text } from "@telia/teddy";
import { uri } from "@telia-no-min-side/utils";
import { LinkCard } from "../../card/LinkCard";
import { BlueConicItem, useBlueConic } from "hooks/useBlueConic";

const isFixedUrl = (url: string) =>
  !!url.includes("minside") && !url.includes(uri.channelpickerBackend("")) && !url.includes(uri.purchase(""));

export function BlueConicActionCard({ message }: { message: BlueConicItem | undefined }) {
  const { trackBlueConicClick, trackBlueConicView } = useBlueConic();

  useEffect(() => {
    if (message?.variantId) {
      trackBlueConicView(message?.variantId);
    }
  }, [message?.variantId]);

  if (!message) {
    return null;
  }

  return (
    <LinkCard
      key={message.variantId}
      dataTrackingID={message.data.bc_promo_id || "bc-personal-nba"}
      lozenge={{
        status: "communication",
        label: message?.data.label || "",
      }}
      onLinkNavigation={() => trackBlueConicClick(message.variantId)}
      href={message?.data.ui_item_url || ""}
      linkText={message?.data.ui_item_action_text || ""}
      isInternalNavigation={isFixedUrl(message?.data.ui_item_url)}
      uiItemContext="Hjem-BC"
      floatButtonRight
      hideLinkIcon={isFixedUrl(message?.data.ui_item_url)}
      img={
        message?.data.image_url
          ? {
              src: message?.data.image_url || "",
              alt: message?.data.ui_item_description || "",
            }
          : undefined
      }
    >
      <Heading as="h3" variant="title-100">
        {message?.data.ui_item_headline}
      </Heading>
      <Text variant="additional-100">{message?.data.ui_item_description}</Text>
    </LinkCard>
  );
}
