import { Accordion, Link, Paragraph } from "@telia-no-min-side/components";
import { track } from "@telia-no-min-side/utils";
import { StyledHeading } from "../style";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
const trackStrexEvent = {
  event: "ms_faq_strex_minside",
  affiliation: "Telia Min Side",
  ui_item_text: "Hva er Strex og hvorfor ser jeg Strex på min faktura?",
  ui_item_action: "view",
  ui_item_context: "Invoice FAQ",
  ui_item_type: "accordion item,",
  ui_item_url: "https://www.telia.no/minside/faktura",
};
const trackContactSupport = {
  event: "ms_faq_strex_contact_support",
  affiliation: "Telia Min Side",
  ui_item_text: "Hva er Strex og hvorfor ser jeg Strex på min faktura?",
  ui_item_action: "view",
  ui_item_context: "Invoice FAQ",
  ui_item_type: "accordion item,",
  ui_item_url: "https://www.telia.no/minside/faktura",
};

export function Strex() {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Hva er Strex og hvorfor ser jeg Strex på min faktura?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Strex betaling gjelder innholdstjenester eller varer som du bestiller via SMS og betaler for via fakturaen fra
          oss. Hvis du har blitt fakturert for varer og tjenester (Strex betaling), og lurer på hva det gjelder, så kan
          du logge deg inn på strex.no og sjekke alle dine transaksjoner.
        </Paragraph>
        <Paragraph removeMargin>
          <Link
            onClick={() => pushGenericTrackingEvent(trackStrexEvent)}
            href={EXTERNAL_LINK.MINSIDE_STREX}
            target="_blank"
            variant="standalone"
          >
            Logg inn på strex.no
          </Link>
        </Paragraph>
        <Link
          onClick={() => pushGenericTrackingEvent(trackContactSupport)}
          href={EXTERNAL_LINK.CUSTOMER_SERVICE_MOBILE_SERVICES}
          target="_blank"
          variant="standalone"
        >
          Les mer om Strex
        </Link>
      </Accordion.Content>
    </Accordion.Item>
  );
}
