import { format, getSimplePriceText, track, types, uri } from "@telia-no-min-side/utils";
import { useFetchProductGroup } from "src/api/fixed";
import { CustomerCareLink } from "src/components/customer-care-link";
import { Recording } from "src/components/product-shopping-cards/Recording";
import { productId } from "src/util/productIds";
import { ReceiptError } from "./ReceiptError";
import { ReceiptSuccess } from "./ReceiptSuccess";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { getChargePeriodText } from "util/prices";
import { PdfHeader } from "components/pdf-order-summary/PdfHeader";
import { openPdf } from "util/pdf-order-summary/openPdf";
import { DownloadButton } from "components/pdf-order-summary/DownloadButton";
import { PdfFooter } from "components/pdf-order-summary/PdfFooter";
import { breakpoint, Flex, Heading, Text, Button, Icon, Box } from "@telia/teddy";
import { ShoppingCard } from "components/shopping-card";
import { Link } from "react-router-dom";
import { ShoppingSummary } from "components/shopping-summary";

export function BuyRecording() {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const recording = useFetchProductGroup("grp_opptak");
  const submitOrder = useAxiosPost<types.fixed.ResponseSubmitMultipleProducts, types.fixed.SubmitMultipleProducts>(
    uri.purchase(ICX_REQUEST.URL_MULTIPLE_PRODUCTS_SUBMIT),
    {
      customSuccessValidation(response) {
        return response.status === "vellykket";
      },
    }
  );
  const recordingProduct = recording.data?.find((option) => option.productId === productId.recording);
  console.log(recordingProduct);

  function placeOrder() {
    if (recordingProduct?.axiomId) {
      pushGenericTrackingEvent({
        ui_item_text: `Bekreft kjøp (${recordingProduct.name} ${recordingProduct.price.currentPrice.recurring})`,
        ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
        ui_item_context: TRACK_EVENT.ITEM_CONTEXT.ORDER_RECORDING,
        ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
        ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
      });

      submitOrder.post({ axiomIds: [{ axiomId: recordingProduct.axiomId, quantity: 1, addonIds: [] }] });
    }
  }

  if (submitOrder.isSuccess) {
    return <ReceiptSuccess />;
  }

  if (submitOrder.isError) {
    return <ReceiptError retry={placeOrder} isRetrying={submitOrder.isLoading} />;
  }

  if (recording.isLoading && !recording.data?.length) {
    return (
      <Flex maxWidth={breakpoint.teddyBreakpointMd} id="buy-recording-skeleton">
        <ShoppingCard.Skeleton hasTitle />
      </Flex>
    );
  }

  if ((recording.isError && !recordingProduct) || !recordingProduct) {
    return (
      <Flex maxWidth={breakpoint.teddyBreakpointMd} direction="column" gap="200">
        <Heading as="h2" variant="title-200">
          Noe gikk galt!
        </Heading>
        {recording.isError ? (
          <Text>Vennligst prøv igjen, eller kontakt vår kundeservice:</Text>
        ) : (
          <Text>
            Det ser ut som du ikke kan kjøpe opptak. Mener du at dette er feil, vennligst kontakt vår kundeservice:
          </Text>
        )}

        <CustomerCareLink trackingContext="buy-tv-subscription-fwa" />
        <Flex pt="gutter-sm" pb="gutter-sm" gap="200">
          {recording.isError ? (
            <Button variant="secondary" onClick={() => recording.mutate()} loading={recording.isValidating}>
              Prøv igjen
            </Button>
          ) : null}
          <Button asChild>
            <Link to={uri.minSideFixed("/hjem")}>
              <Icon name="home" />
              Gå til Hjem
            </Link>
          </Button>
        </Flex>
      </Flex>
    );
  }

  const { currentPrice, postCampaignPrice } = recordingProduct.price;

  const isDiscountRecurringPrice = currentPrice.recurring < postCampaignPrice.recurring;

  const chargePeriodTextCapitalized = getChargePeriodText(currentPrice.chargePeriod, true);

  return (
    <Flex gap="gutter-md" maxWidth={breakpoint.teddyBreakpointLg} wrap="wrap">
      <Box flexGrow="2" flexShrink="1" maxWidth="600px">
        <Recording product={recordingProduct} isSelected />
      </Box>
      <Box flexGrow="1" flexShrink="1" id="recording-order-summary">
        <PdfHeader />
        <ShoppingSummary>
          <ShoppingSummary.List title={chargePeriodTextCapitalized + "spris"}>
            <ShoppingSummary.Item
              price={getSimplePriceText(currentPrice.recurring, currentPrice.chargePeriod)}
              additionalText={
                isDiscountRecurringPrice
                  ? `${getSimplePriceText(postCampaignPrice.recurring, postCampaignPrice.chargePeriod)} etter kampanje`
                  : undefined
              }
              text={format.startCase(recordingProduct.name || "")}
            />
          </ShoppingSummary.List>
          <ShoppingSummary.Line />
          <DownloadButton onClick={async () => await openPdf("#recording-order-summary")} />
          <Flex data-html2canvas-ignore justify="end" gap="400">
            <Button
              onClick={() =>
                pushGenericTrackingEvent({
                  ui_item_text: TRACK_EVENT.ITEM_TEXT.CANCEL,
                  ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                  ui_item_context: TRACK_EVENT.ITEM_CONTEXT.ORDER_RECORDING,
                  ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
                  ui_item_url: uri.minSideFixed("/tv"),
                })
              }
              variant="text"
              asChild
            >
              <Link to={uri.minSideFixed("/tv")}>Avbryt</Link>
            </Button>
            <Button variant="primary" loading={submitOrder.isLoading} onClick={placeOrder}>
              Bekreft kjøp
            </Button>
          </Flex>
        </ShoppingSummary>
        <PdfFooter />
      </Box>
    </Flex>
  );
}
