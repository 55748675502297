import { graphql } from "src/gql";

export const GET_UPGRADE_DOWNGRADE_SUMMARY = graphql(`
  query GetUpgradeDowngradeSummary(
    $phoneNumber: String!
    $accountId: String!
    $offering: String!
    $withProductChanges: Boolean!
  ) {
    account(accountId: $accountId) {
      id
      subscriptions {
        phoneNumber {
          localNumber
        }
        userOffering {
          pricePlan
          additionalProperties {
            name
            value
          }
        }
      }
      subscription(phoneNumber: $phoneNumber) {
        phoneNumber {
          localNumber
        }
        additionalProducts {
          id
          code
          name
          productTerms {
            code
            amount
          }
        }
        userOffering {
          pricePlan
          shortName
          longDescription
          additionalProperties {
            name
            value
          }
          monthlyCost {
            code
            amount
            amountWithoutVat
            description
            monetaryUnit
          }
          offeringPrices {
            offeringPrices {
              code
              amount
              monetaryUnit
            }
          }
        }
        newOfferingDetails {
          offerings {
            shortName
            longDescription
            pricePlan
            monthlyCost {
              amount
              code
              amountWithoutVat
              description
              monetaryUnit
            }
            price {
              offeringPrices {
                code
                amount
                monetaryUnit
              }
            }
            additionalProperties {
              name
              value
            }
          }
        }
        productChanges: newOfferingDetails(offering: $offering) @include(if: $withProductChanges) {
          lostProducts {
            name
            shortName
            code
            longDescription
            price {
              price
            }
          }
          addedProducts {
            name
            shortName
            code
            longDescription
            price {
              price
            }
          }
          changedProducts {
            oldProduct {
              name
              shortName
              code
              longDescription
              price {
                price
              }
              additionalProperties {
                name
                value
              }
            }
            newProduct {
              name
              shortName
              code
              longDescription
              price {
                price
              }
              additionalProperties {
                name
                value
              }
            }
          }
        }
      }
    }
  }
`);
