import { StyledBox, UserInfoRow } from "../style";
import { Container, Skeleton } from "@telia-no-min-side/components";

export function SuspendBoxLoader() {
  return (
    <StyledBox data-tracking-id="container--suspend-subscription">
      <Container flexDirection="column" gap>
        <Skeleton variant="heading-m" width="70%" />

        <UserInfoRow>
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="20%" />
        </UserInfoRow>
        <UserInfoRow>
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="20%" />
        </UserInfoRow>
        <UserInfoRow>
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="20%" />
        </UserInfoRow>

        <Container flexDirection="column" gap="lg">
          <Skeleton variant="rectangular" height="50px" width="100%" />
          <Skeleton variant="rectangular" height="50px" width="100%" />
        </Container>

        <Skeleton variant="button" />
      </Container>
    </StyledBox>
  );
}
