import type { Dispatch, SetStateAction } from "react";
import { Button, Modal } from "@telia/teddy";
import { useToast } from "@telia-no-min-side/components";
import { AdditionalSimCard } from "gql/graphql";
import { useChangeSimCardNickname } from "../../hooks/useChangeSimCardNickname";
import { ModalType } from "../AdditionalSimCard";

type Props = {
  phoneNumber: string;
  simCard: AdditionalSimCard;
  setModalType: Dispatch<SetStateAction<ModalType | undefined>>;
  simCardNickname: string | undefined;
};

export function DeleteSimCardNicknameModal({ phoneNumber, simCard, setModalType, simCardNickname }: Props) {
  const { addToast } = useToast();

  const { response, runChangeSimCardNickname } = useChangeSimCardNickname({
    onCompleted() {
      addToast({
        text: "Egendefinert kallenavn er registrert. Det vil ta et par minutter før endringen blir registrert.",
        variant: "success",
      });
      setModalType(undefined);
    },
    onError() {
      addToast({
        text: "Beklager, noe gikk galt. Prøv igjen senere",
        variant: "error",
      });
      setModalType(undefined);
    },
  });

  const handleClose = () => setModalType(undefined);

  const handleClick = () =>
    runChangeSimCardNickname({
      phoneNumber,
      additionalSimCard: simCard,
      alias: "",
    });

  return (
    <Modal open>
      <Modal.Overlay>
        <Modal.Content>
          <Modal.Group variant="title">
            <Modal.Title as="h3">Slett egendefinert kallenavn</Modal.Title>
          </Modal.Group>
          <Modal.Description
            data-di-mask
          >{`Er du sikker på at du vil slette kallenavnet ${simCardNickname}?`}</Modal.Description>
          <Modal.Group variant="action">
            <Modal.Close slot="content">
              <Button variant="text" onClick={handleClose}>
                Avbryt
              </Button>
            </Modal.Close>
            <Button loading={response.loading} onClick={handleClick}>
              Slett kallenavn
            </Button>
          </Modal.Group>
        </Modal.Content>
      </Modal.Overlay>
    </Modal>
  );
}
