import { Button, Container, Heading, Link, Paragraph, ScrollToTop } from "@telia-no-min-side/components";
import { types, uri } from "@telia-no-min-side/utils";
import { phoneNumbers } from "src/util/phoneNumbers";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

type Props = {
  receipt: types.fixed.FlexSubmitResponse["receipt"] | undefined;
};

export function Receipt({ receipt }: Props) {
  if (!receipt) {
    return (
      <Container maxWidth="md">
        <ScrollToTop />
        <Heading tag="h2" variant="title-400">
          Noe gikk galt!
        </Heading>
        <Paragraph>
          Vi kunne dessverre ikke behandle ordren din og fikk heller ikke opprettet en manuell sak for oppfølging. Vi
          beklager at dette skjedde og ønsker gjerne at du tar kontakt med oss på telefon.
        </Paragraph>
        <Paragraph>
          <Link icon="phone" variant="standalone" href={`tel:${phoneNumbers.customerServiceNorway}`}>
            {phoneNumbers.customerServiceNorway}
          </Link>
        </Paragraph>
      </Container>
    );
  }
  return (
    <Container maxWidth="md">
      <ScrollToTop />
      <Heading tag="h2" variant="title-400">
        {receipt.title}
      </Heading>
      {receipt.subtitle && (
        <Paragraph>
          <em>{receipt.subtitle}</em>
        </Paragraph>
      )}
      {receipt.texts.map((text) => (
        <Paragraph key={text}>{text}</Paragraph>
      ))}
      {receipt.showForhandler && (
        <Paragraph>
          <Link target="_blank" variant="text" href={EXTERNAL_LINK.CUSTOMER_SERVICE_STORES_AND_DEALERS}>
            Finn din nærmeste forhandler her!
          </Link>{" "}
        </Paragraph>
      )}
      {receipt.showCustomerService && (
        <>
          <Paragraph>
            Vi kunne dessverre ikke behandle ordren din og fikk heller ikke opprettet en manuell sak for oppfølging. Vi
            beklager at dette skjedde og ønsker gjerne at du tar kontakt med oss på telefon.
          </Paragraph>
          <Paragraph>
            <Link icon="phone" variant="standalone" href={`tel:${phoneNumbers.customerServiceNorway}`}>
              {phoneNumbers.customerServiceNorway}
            </Link>
          </Paragraph>
        </>
      )}
      {receipt.showAngrerett && (
        <Paragraph>
          Angreretten forutsetter at du senest innen 14 dager etter at du mottar leveransen gir oss melding om dette
          (angrefrist). Skal du benytte deg av angreretten og sende inn en klage må du fylle ut{" "}
          <Link target="_blank" variant="text" href={EXTERNAL_LINK.CUSTOMER_SERVICE_TERMS_AND_CONDITIONS}>
            angrerettskjema TV og internett
          </Link>{" "}
          og sende det til oss.
        </Paragraph>
      )}
      <Container gap>
        {receipt.showCustomerService}
        <Button
          tag="a"
          variant={receipt.showCustomerService ? "secondary" : "primary"}
          href={uri.minSideFixed("/hjem")}
          isInternalNavigation
          icon="home"
        >
          Hjem
        </Button>
      </Container>
    </Container>
  );
}
