import type { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Flex, Icon, Text, toast } from "@telia/teddy";
import { format, track } from "@telia-no-min-side/utils";
import { AdditionalProduct, OrderOp } from "gql/graphql";
import { INSURANCE_POST_DUMMY_EXISTING, INSURANCE_TYPE } from "util/constants/agreements";
import { useOrderAdditionalProducts } from "hooks/useAdditionalProducts";
import { getDeviceInfo } from "pages/value-added-services/components/utils/product";
import { getTransactionData } from "../utils/getTransactionData";
import { useOrderSubscription } from "src/store/OrderToastContext";

type Props = {
  phoneNumber: string;
  selectedInsurance: AdditionalProduct | null;
  setSelectedInsurance: Dispatch<SetStateAction<AdditionalProduct | null>>;
  postPurchaseDummyProduct: AdditionalProduct;
};

export function CheckoutCard({
  phoneNumber,
  selectedInsurance,
  setSelectedInsurance,
  postPurchaseDummyProduct,
}: Props) {
  const navigate = useNavigate();
  const { subscribeToOrder } = useOrderSubscription();
  const { orderResponse, runOrderAdditionalProducts } = useOrderAdditionalProducts();

  const { pushTrackingEvent } = track.useEventTracking();

  const { shortName, price } = selectedInsurance || {};
  const { price: priceValue, priceWithoutDiscount } = price || {};
  const { deviceModel, deviceImei, featureCode } = getDeviceInfo(postPurchaseDummyProduct);

  const isScreenInsurance = shortName?.toLowerCase().includes(INSURANCE_TYPE.SCREEN);
  const insuranceName = isScreenInsurance
    ? format.startCase(INSURANCE_TYPE.SCREEN)
    : format.startCase(INSURANCE_TYPE.PLUS);

  const orderInput = [
    {
      productName: selectedInsurance?.code,
      operation: OrderOp.AddAdditionalProduct,
      productCharacteristics: {
        featureCode,
        config: deviceModel && deviceImei ? [deviceModel, deviceImei] : [],
      },
    },
  ];

  if (!selectedInsurance || !postPurchaseDummyProduct) return null;

  const handleOrder = () => {
    runOrderAdditionalProducts(
      { phoneNumber, input: orderInput },
      {
        onCompleted(data) {
          const transactionData = getTransactionData(selectedInsurance, insuranceName, data?.order.orderId);
          pushTrackingEvent(transactionData);

          data?.order.orderId &&
            subscribeToOrder({
              id: data?.order.orderId,
              phoneNumber,
              toastText: "Forsikring er aktivert!",
              dataTrackingId: INSURANCE_POST_DUMMY_EXISTING,
            });

          navigate("/minside/hjem", { replace: true });
          toast.success("Forsikring er aktivert!", {
            description: "Din forsikring vil dukke opp under “Dine avtaler” i løpet av kort tid.",
            icon: <Icon name="check-circle-filled" size="sm" />,
          });
        },
        onError() {
          navigate("/minside/hjem", { replace: true });
          toast.error("Vi klarte ikke å fullføre bestillingen", {
            icon: <Icon name="error-filled" size="sm" />,
            description: "Vennligst prøv igjen, eller ta kontakt med kundeservice.",
          });
        },
      }
    );
  };

  return (
    <Card layout="default" variant="white" bordered style={{ width: "330px" }}>
      <Flex justify="center">
        <Card.Heading as="h3" style={{ fontSize: "18px" }}>
          Din bestilling
        </Card.Heading>
      </Flex>

      <Card.Content>
        <Flex direction="column" gap="150">
          <Flex direction="column" gap="150">
            <Text variant="paragraph-100-medium">Månedspris</Text>

            <Flex direction="column" gap="150">
              <Flex direction="row" justify="between">
                <Text>{insuranceName}</Text>
                <Flex direction="column" align="end">
                  <Text>{priceValue},-/md.</Text>
                  <Text faded variant="additional-100">
                    Deretter {priceWithoutDiscount},- md.
                  </Text>
                </Flex>
              </Flex>

              <Flex direction="row" justify="between">
                <Flex direction="column">
                  <Text variant="paragraph-100-medium">Totalt per måned</Text>
                  <Text faded variant="additional-100">
                    Belastes på din faktura
                  </Text>
                </Flex>
                <Text variant="paragraph-100-medium" style={{ justifySelf: "flex-end" }}>
                  {priceWithoutDiscount},- /md.
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Card.Line />

          <Flex direction="column" gap="100">
            <Text variant="paragraph-100-medium">Forsikringen gjelder:</Text>
            <Text variant="additional-100">{deviceModel?.value}</Text>
            <Text variant="additional-100" data-di-mask>
              {format.formatPhoneNumber(phoneNumber)}
            </Text>
            <Text variant="additional-100" data-di-mask>
              IMEI {deviceImei?.value}
            </Text>
          </Flex>

          <Card.Line />

          <Flex direction="row" gap="100" justify="end">
            <Button variant="secondary" onClick={() => setSelectedInsurance(null)}>
              Avbryt
            </Button>
            <Button variant="primary" onClick={handleOrder} loading={orderResponse.loading}>
              Bestill
            </Button>
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  );
}
