import { useNavigate } from "react-router-dom";
import { Drawer, Card, Button, Heading, List, Icon, Flex } from "@telia/teddy";
import { useMobileAccount } from "hooks/useMobileAccount";
import { IncludedProperty } from "pages/internet/mobile-broadband/types";
import { SLEEP_MODE_OFFERING_CODE } from "util/format-subscription/variables";
import { useMobileBroadbandContext } from "pages/internet/mobile-broadband/context/MobileBroadbandContext";

export function HvilemodusDrawerContent() {
  const { isSleepMode } = useMobileBroadbandContext();
  const { accountId, phoneNumber } = useMobileAccount();
  const navigate = useNavigate();

  const hvilemodusProperties: IncludedProperty[] = [
    {
      icon: "savings",
      code: "fakeHvilemodusSavingsCode",
      text: (
        <>
          Hvilemodus koster kun <strong>99,-/md.</strong> og inkluderer <strong>2 GB</strong> data hver måned.
        </>
      ),
    },
    {
      icon: "speedometer",
      code: "fakeHvilemodusSpeedCode",
      text: (
        <>
          Etter 2 GB reduseres hastigheten til <strong>64 Kbit/s.</strong> Det påløper ingen ekstra kostnader, men du
          kan kjøpe ekstra datapakker med full hastighet. Abonnementet har en maks hastighet for nedlastning på 100
          Mbit/s og for opplasting 35 Mbit/s.
        </>
      ),
    },
  ];

  const handleClick = () => {
    isSleepMode
      ? navigate(`/minside/mobilt-bredband/endre/${accountId}/${phoneNumber}`)
      : navigate(
          `/minside/mobilt-bredband/endre/${accountId}/${phoneNumber}/bekreft?pricePlan=${SLEEP_MODE_OFFERING_CODE}`
        );
  };

  return (
    <>
      <Drawer.Title>{isSleepMode ? "Deaktiver" : "Aktiver"} hvilemodus</Drawer.Title>
      <Card.Line />
      <Drawer.Description>Hvilemodus reduserer abonnementet i perioder du bruker lite data.</Drawer.Description>
      <Heading as="h4" variant="title-200">
        Info
      </Heading>
      <List isSubcomponent layout="vertical">
        {hvilemodusProperties.map(({ icon, code, text }) => (
          <List.Item key={`subscription-card-expanded-content-property-${code}`}>
            {icon && (
              <List.Illustration>
                <Icon name={icon} size="md" />
              </List.Illustration>
            )}
            <List.Content>{text}</List.Content>
          </List.Item>
        ))}
      </List>
      <Flex gap="200">
        <Drawer.Close slot="content">
          <Button variant="secondary" fullWidth>
            Avbryt
          </Button>
        </Drawer.Close>
        <Button onClick={handleClick} fullWidth>
          {isSleepMode ? "Deaktiver" : "Aktiver"} hvilemodus
        </Button>
      </Flex>
    </>
  );
}
