import { Container, Heading, Link, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";

export function NotFound(): JSX.Element {
  return (
    <Container maxWidth="sm" padding>
      <Heading tag="h2" variant="title-500">
        Vi beklager
      </Heading>
      <Paragraph variant="paragraph-200">Vi fant ikke siden du leter etter!</Paragraph>
      <Paragraph>
        Siden du leter etter er enten flyttet, eller så eksistererer den ikke lenger. Hvis vi har lenket feil så setter
        vi veldig pris på en tilbakemelding til
      </Paragraph>

      <Link href={uri.minSideFixed("/tv")} isInternalNavigation>
        Gå til forside
      </Link>
    </Container>
  );
}
