import { Operation } from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";
import { print } from "graphql";

export const getNetworkErrorMessage = (error: NetworkError, operation: Operation) => {
  if (!error) return "";

  const errorStatusCode = "statusCode" in error ? error?.statusCode : undefined;

  return [operation.operationName, errorStatusCode, error.message].filter(Boolean).join(" | ");
};

export const getNetworkErrorContext = (error: NetworkError, operation: Operation) => {
  if (!error) return;

  const requestTraceId = "response" in error ? error.response?.headers?.get("x-amzn-requestid") : undefined;

  return {
    originalErrorName: error.name,
    errorGroup: "NetworkError",
    errorCause: error?.cause,
    errorStatusCode: "statusCode" in error ? error?.statusCode : undefined,
    errorBodyText: "bodyText" in error ? error?.bodyText : undefined,
    errorResult: "result" in error ? error?.result : undefined,
    operationName: operation.operationName,
    query: print(operation.query),
    "x-amzn-requestid": requestTraceId || undefined,
  };
};
