import { Flex, Text, Heading, Button, Icon } from "@telia/teddy";
import { uri } from "@telia-no-min-side/utils";
import { Link as RuterLink } from "react-router-dom";

export function CompensationReceipt() {
  return (
    <>
      <Flex mb="400" direction="column" gap="300">
        <Heading as="h1" variant="title-300">
          Ditt valg er registrert!
        </Heading>
        <Text as="p">Du får nå et avslag på 109,-/md. som trekkes fra og med din neste faktura for TV/internett.</Text>
      </Flex>
      <Button asChild mt="200">
        <RuterLink to={uri.minSideFixed("/hjem")}>
          <Icon name="home">Tilbake til hjem </Icon>
        </RuterLink>
      </Button>
    </>
  );
}
