import { Badge as TeddyBadge, ExpandableCard, Icon } from "@telia/teddy";

type Props = {
  hasOnGoingOrder: boolean;
  hasFamilyDiscount: boolean;
};

export function Badge({ hasOnGoingOrder, hasFamilyDiscount }: Props) {
  if (hasOnGoingOrder) {
    return (
      <ExpandableCard.Slot align="top-right">
        <TeddyBadge variant="information" hideIcon>
          <Icon name="sync" />
          Oppdateres
        </TeddyBadge>
      </ExpandableCard.Slot>
    );
  }

  if (hasFamilyDiscount) {
    return (
      <ExpandableCard.Slot align="top-right">
        <TeddyBadge variant="special" hideIcon>
          Familierabatt
        </TeddyBadge>
      </ExpandableCard.Slot>
    );
  }

  return null;
}
