import { Flex, Heading } from "@telia/teddy";
import { types } from "@telia-no-min-side/utils";
import { Outages } from "./Outages";
import dayjs from "dayjs";

type Props = {
  name: string;
  phoneNumber: string;
  isLegalOwner: boolean;
  usersWithFixedProducts: types.fixed.UsersWithCustomerId;
};

const christmasIcons = ["🎄", "❤️", "🎁", "❄️", "⛄"];
const newYearIcons = ["🎉", "🎊", "🌟", "💫"];
const random = Math.random();
export function getRandomIcon(icons: string[]) {
  return icons[Math.floor(random * icons.length)];
}

export function getHolidayGreeting(date: dayjs.Dayjs = dayjs()) {
  const month = date.month(); // 0-based index (0 = January, 11 = December)
  const day = date.date();

  if (month === 11 && day >= 19 && day <= 24) {
    return `${getRandomIcon(christmasIcons)} God jul`;
  }
  if (month === 11 && day >= 25 && day <= 31) {
    return `${getRandomIcon(christmasIcons)} Gledelig jul`;
  }

  if (month === 0 && day <= 6) {
    return `${getRandomIcon(newYearIcons)} Godt nytt år`;
  }

  return null;
}
export function Header({ name, phoneNumber, isLegalOwner, usersWithFixedProducts }: Props) {
  const hasPostCode = usersWithFixedProducts.some((product) => product.postalCode);
  const welcomeMessage = getHolidayGreeting() ?? "Hei";
  return (
    <Flex maxWidth="full" direction="row" justify="between" pb="300" data-tracking-id="homepage-profile-heading">
      <Flex maxWidth="md">
        <Heading as="h2" variant="title-400" data-di-mask>
          {welcomeMessage}, {name}!
        </Heading>
      </Flex>

      {import.meta.env.VITE_SHOW_OUTAGES === "true" && hasPostCode && (
        <Outages
          phoneNumber={phoneNumber}
          isLegalOwner={isLegalOwner}
          usersWithFixedProducts={usersWithFixedProducts}
        />
      )}
    </Flex>
  );
}
