import { format, types } from "@telia-no-min-side/utils";
import { colors } from "@telia/styleguide";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";
import { AccountInvoiceDetailsQuery } from "src/gql/graphql";
import {
  DetailsAccordionItem,
  Dot,
  InvoiceProduct,
  InvoiceProductList,
  PriceCell,
  StyledAccordionBody,
  StyledAccordionHeader,
  SumCell,
  SumTextCell,
  TitleCell,
  TotalSumGrid,
} from "src/pages/invoice/components/details-element/style";
import { getPriceAmountText } from "src/util/priceFormatter";

export type Props = {
  invoiceGroup: NonNullable<
    NonNullable<NonNullable<AccountInvoiceDetailsQuery["account"]["invoice"]>["invoiceGroup"]>[number]
  >;
  isOpen: boolean | undefined;
  toggleIsOpen: (forceValue?: boolean) => void;
};

export function getProductTypeColor(amount: number): string {
  if (amount < 0) {
    return colors.green200;
  }

  return colors.corePurple300;
}

export function getDatePeriod(product: types.fixed.InvoiceDetail): string {
  const { periodFrom, periodTo } = product;
  const dateFormatter = (date: number) => dayjs.unix(date).format(format.DATE_DISPLAY_FORMAT_WITH_SHORT_YEAR);
  const datePeriod = (from: number, to: number) => `${dateFormatter(from)}-${dateFormatter(to)}`;

  if (periodFrom <= 0 && periodTo <= 0) return "";
  if (periodFrom <= 0 && periodTo > 0) {
    return datePeriod(periodTo, periodTo);
  }
  if (periodFrom > 0 && periodTo <= 0) {
    return datePeriod(periodFrom, periodFrom);
  }
  return datePeriod(periodFrom, periodTo);
}

export function DetailsElementAccountLevel(props: Props) {
  const { invoiceGroup, isOpen, toggleIsOpen } = props;

  if (!invoiceGroup.invoiceRow?.length) {
    return null;
  }
  const totalAmount = invoiceGroup.invoiceRow.reduce((acc, row) => acc + (row?.totalAmount?.value ?? 0), 0);
  return (
    <DetailsAccordionItem hideBorder={true} showShadow={false} isOpen={isOpen} onClick={() => toggleIsOpen()}>
      <StyledAccordionHeader>
        <TitleCell as="h3" data-di-mask>
          {invoiceGroup.name}
        </TitleCell>
        {!isOpen && totalAmount && <PriceCell data-di-mask>{getPriceAmountText(totalAmount, "kr")}</PriceCell>}
      </StyledAccordionHeader>
      <StyledAccordionBody>
        <InvoiceProductList>
          {invoiceGroup.invoiceRow.map((detail) => {
            return (
              <InvoiceProduct key={uuid()}>
                <TitleCell>
                  <Dot color={getProductTypeColor(detail?.totalAmount?.value ?? 0)} />

                  {detail?.name}
                </TitleCell>
                <PriceCell>{getPriceAmountText(detail?.totalAmount?.value, "kr")}</PriceCell>
              </InvoiceProduct>
            );
          })}
          {totalAmount && (
            <TotalSumGrid>
              <SumTextCell>Sum</SumTextCell>
              <SumCell data-di-mask>{getPriceAmountText(totalAmount, "kr")}</SumCell>
            </TotalSumGrid>
          )}
        </InvoiceProductList>
      </StyledAccordionBody>
    </DetailsAccordionItem>
  );
}
