import type { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { Flex, Button, Text, Icon } from "@telia/teddy";
import { TopupCategory } from "gql/graphql";
import { formatTextWithLineBreaks } from "../utils";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";

type Props = {
  hasExceededDataLimit?: boolean;
  setIsTermsVisible?: Dispatch<SetStateAction<boolean>>;
};

export function ActionsSection({ hasExceededDataLimit = false, setIsTermsVisible }: Props) {
  const { activeDataBoostProduct, isTeliaXSubscription, showTopup, openSelectedTopupDrawer } =
    useMobileDashboardContext();

  const HistoricalDataUsageButton = () => (
    <Flex direction="column" gap="100" align="center">
      <Button variant="secondary" aria-label="bar-chart" iconOnly asChild>
        <Link to={INTERNAL_LINK.MOBILE_NEW.DATA_USAGE_HISTORY_PAGE}>
          <Icon name="bar-chart" />
        </Link>
      </Button>
      <Text style={{ textAlign: "center" }}>{formatTextWithLineBreaks("Tidligere dataforbruk")}</Text>
    </Flex>
  );

  const TermsButton = ({ setIsTermsVisible }: { setIsTermsVisible: Dispatch<SetStateAction<boolean>> }) => (
    <Flex direction="column" gap="100" align="center">
      <Button aria-label="info" iconOnly variant="secondary" onClick={() => setIsTermsVisible(true)}>
        <Icon name="info" />
      </Button>
      <Text style={{ textAlign: "center" }}>Vilkår</Text>
    </Flex>
  );

  const DataBoostButton = () => (
    <Flex direction="column" gap="100" align="center">
      <Button
        aria-label="getting-started"
        iconOnly
        variant="secondary"
        onClick={() => openSelectedTopupDrawer(TopupCategory.DataBoost)}
      >
        <Icon name="getting-started" />
      </Button>
      <Text style={{ textAlign: "center" }}>{formatTextWithLineBreaks("Data Boost")}</Text>
    </Flex>
  );

  const RoamingButton = () => (
    <Flex direction="column" gap="100" align="center">
      <Button
        aria-label="world"
        iconOnly
        variant="secondary"
        onClick={() => openSelectedTopupDrawer(TopupCategory.Roaming)}
      >
        <Icon name="world" />
      </Button>
      <Text style={{ textAlign: "center" }}>{formatTextWithLineBreaks("Datapakker utland")}</Text>
    </Flex>
  );

  if (activeDataBoostProduct) {
    return (
      <Flex gap="300">
        <HistoricalDataUsageButton />
        {showTopup && <RoamingButton />}
      </Flex>
    );
  }

  if (isTeliaXSubscription) {
    return (
      <Flex justify="between">
        {hasExceededDataLimit && <HistoricalDataUsageButton />}
        {!hasExceededDataLimit && showTopup && <DataBoostButton />}
        {showTopup && <RoamingButton />}
        {setIsTermsVisible && <TermsButton setIsTermsVisible={setIsTermsVisible} />}
      </Flex>
    );
  }

  return (
    <Flex justify="between">
      <HistoricalDataUsageButton />
      {showTopup && <DataBoostButton />}
      {showTopup && <RoamingButton />}
    </Flex>
  );
}
