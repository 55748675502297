import { Container } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useInvoice } from "src/store/hooks/invoice-context";
import { DateLink } from "../../styles";
import { InvoiceDetailsParams } from "./InvoiceDetailsFixed";
import { Flex, ScrollArea } from "@telia/teddy";

export const InvoiceSelectorFixed = (): JSX.Element => {
  const { invoiceHistoryOverview } = useInvoice();
  const params = useParams<InvoiceDetailsParams>();
  const currentInvoiceId = params.id;

  return (
    <Container maxWidth="sm">
      {!!invoiceHistoryOverview?.invoices?.length && (
        <ScrollArea dir="rtl">
          <Flex gap="gutter-sm">
            {invoiceHistoryOverview.invoices.map((invoice) => {
              const isCurrentDate = currentInvoiceId === `${invoice.invoiceId}`;
              return (
                <ScrollArea.Item isSelected={isCurrentDate} asChild key={`scroller-${invoice.invoiceId}`}>
                  <DateLink
                    to={uri.minSideFixed(`/faktura/${invoice.invoiceId}`)}
                    id={isCurrentDate ? "scrollElementIntoView" : undefined}
                    $isCurrentDate={isCurrentDate}
                  >
                    {dayjs.unix(invoice.invoiceDate).format("MMM YY")}
                  </DateLink>
                </ScrollArea.Item>
              );
            })}
            <ScrollArea.Button direction="right" variant="primary-negative" size="sm" />
            <ScrollArea.Button direction="left" variant="primary-negative" size="sm" />
          </Flex>
        </ScrollArea>
      )}
    </Container>
  );
};
