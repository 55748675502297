import { Container } from "@telia-no-min-side/components";
import { InvoiceCardSkeleton } from "../invoice-card/Skeleton";

export function SkeltonInvoiceList(): JSX.Element {
  return (
    <Container padding="top" flexDirection="column" gap>
      <InvoiceCardSkeleton />
      <InvoiceCardSkeleton />
      <InvoiceCardSkeleton />
      <InvoiceCardSkeleton />
    </Container>
  );
}
