import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

type Args = {
  financialAccountId?: number;
};

export function useFetchInvoiceHistoryOverview({ financialAccountId }: Args) {
  const { data, error, mutate } = useAxiosSWR<types.fixed.InvoiceHistoryOverview>(
    financialAccountId ? `${ICX_REQUEST.URL_INVOICES_HISTORY_OVERVIEW}/${financialAccountId}` : null
  );

  return {
    invoiceHistoryOverview: data,
    invoiceHistoryOverviewIsLoading: !error && !data,
    invoiceHistoryOverviewIsError: !!error,
    invoiceHistoryOverviewError: error,
    invoiceHistoryOverviewMutate: mutate,
  };
}
