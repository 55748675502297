import { ReactNode, createContext, useContext, useState } from "react";
import { useFetchSelectedAccount } from "src/api/fixed";
import { useSearchParams } from "react-router-dom";
import { useMobileAccount } from "./useMobileAccount";
import { useFixedAccount } from "./useFixedAccount";
import { AUTH } from "util/constants/auth";

export type AccountType = "FIXED" | "MOBILE";

type SetAccountArgs =
  | { id: string | undefined; phoneNumber?: never; accountType: "FIXED" }
  | { id: string | undefined; phoneNumber?: string | undefined; accountType: "MOBILE" };

type SelectAccount = {
  setAccount: (args: SetAccountArgs) => void;
  selectedAccountType: AccountType;
  selectedMobileAccount: ReturnType<typeof useMobileAccount> | undefined;
  selectedFixedAccount: ReturnType<typeof useFetchSelectedAccount> | undefined;
  selectedAccountId: string | undefined | null;
};

const SelectAccountContext = createContext<SelectAccount>({
  selectedAccountId: undefined,
  selectedAccountType: AUTH.ACCOUNT_TYPE.MOBILE,
  selectedFixedAccount: undefined,
  selectedMobileAccount: undefined,
  setAccount: () => undefined,
});

export const ACCOUNT_TYPE_KEY_PARAM = "accT";

export function SelectAccountProvider({ children }: { children: ReactNode }) {
  const [search] = useSearchParams();
  const initialAccountType = search.get(ACCOUNT_TYPE_KEY_PARAM);
  const selectedMobileAccount = useMobileAccount();
  const selectedFixedAccount = useFetchSelectedAccount();
  const [selectedAccountType, setSelectedAccountType] = useState<AccountType>(
    initialAccountType === AUTH.ACCOUNT_TYPE.FIXED ? AUTH.ACCOUNT_TYPE.FIXED : AUTH.ACCOUNT_TYPE.MOBILE
  );
  const { changeFixedAccount } = useFixedAccount();
  function setAccount({ id, accountType, ...rest }: SetAccountArgs) {
    if (!id) return;
    if (accountType === AUTH.ACCOUNT_TYPE.FIXED) {
      changeFixedAccount(id);
    }
    if (accountType === AUTH.ACCOUNT_TYPE.MOBILE) {
      selectedMobileAccount.setAccountId(id);
      rest.phoneNumber && selectedMobileAccount.setPhoneNumber(rest.phoneNumber);
    }
    setSelectedAccountType(accountType);
  }

  const availableAccountTypes =
    selectedFixedAccount.isLoading || selectedMobileAccount.isLoading
      ? AUTH.ACCOUNT_TYPE.NONE
      : selectedFixedAccount.data?.customerId && selectedMobileAccount.accountId
      ? AUTH.ACCOUNT_TYPE.BOTH
      : selectedFixedAccount.data?.customerId
      ? AUTH.ACCOUNT_TYPE.FIXED
      : selectedMobileAccount.accountId
      ? AUTH.ACCOUNT_TYPE.MOBILE
      : AUTH.ACCOUNT_TYPE.NONE;

  const selectedAccountId =
    availableAccountTypes === AUTH.ACCOUNT_TYPE.NONE
      ? null
      : (availableAccountTypes === AUTH.ACCOUNT_TYPE.BOTH && selectedAccountType === AUTH.ACCOUNT_TYPE.FIXED) ||
        availableAccountTypes === AUTH.ACCOUNT_TYPE.FIXED
      ? `${selectedFixedAccount.data?.customerId}`
      : selectedMobileAccount.accountId;

  return (
    <SelectAccountContext.Provider
      value={{
        setAccount,
        selectedAccountType,
        selectedMobileAccount,
        selectedFixedAccount,
        selectedAccountId,
      }}
    >
      {children}
    </SelectAccountContext.Provider>
  );
}

export function useSelectAccount(): SelectAccount {
  const context = useContext<SelectAccount>(SelectAccountContext);

  if (!context) {
    throw Error(
      "No SelectAccountContext found! This usually happens when you try to access a context outside of a provider"
    );
  }
  return context;
}
