import { getSimplePriceText } from "@telia-no-min-side/utils";
import { Maybe, Offering, UserOffering } from "gql/graphql";
import { Container, Paragraph } from "@telia-no-min-side/components";

type Props = {
  subToBeRemoved: Maybe<UserOffering> | undefined;
  subToBeAdded: Maybe<Offering> | undefined;
};

export function PriceChangeSummary({ subToBeRemoved, subToBeAdded }: Props) {
  const isAddingCommitment = !subToBeAdded?.pricePlan?.endsWith("REGULAR");
  return (
    <Container padding="bottom" data-tracking-id="mobile-subscription-confirm-subscription-to-be-removed">
      {subToBeRemoved?.shortName &&
      typeof subToBeRemoved?.monthlyCost?.amount === "number" &&
      typeof subToBeAdded?.monthlyCost?.amount === "number" &&
      subToBeAdded?.shortName ? (
        <Paragraph>
          Du er i ferd med å bytte{" "}
          <em>
            {subToBeRemoved.shortName} ({getSimplePriceText(subToBeRemoved.monthlyCost.amount, "md.")})
          </em>{" "}
          til{" "}
          {isAddingCommitment ? (
            <>
              <em>{subToBeAdded.shortName} </em>
              med 10% rabatt på månedsavgiften og 12 md. avtaletid til
              <em> ({getSimplePriceText(subToBeAdded?.monthlyCost?.amount, "md.")})</em>
            </>
          ) : (
            <em>
              {subToBeAdded.shortName} ({getSimplePriceText(subToBeAdded?.monthlyCost?.amount, "md.")})
            </em>
          )}
        </Paragraph>
      ) : null}
    </Container>
  );
}
