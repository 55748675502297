import styled, { css } from "styled-components";
import { InternalVariant } from ".";
import { Box } from "@telia/teddy";

type WrapperProps = {
  variant: InternalVariant;
};

export const variantStyles = css<Pick<WrapperProps, "variant">>`
  ${(props) => {
    switch (props.variant) {
      case "help":
        return css`
          background-color: var(--teddy-color-purple-50);
          border-color: var(--teddy-color-purple-50);
        `;
      case "information":
        return css`
          background-color: var(--teddy-color-blue-50);
          border-color: var(--teddy-color-blue-50);
        `;
      default:
        return css`
          background-color: var(--teddy-color-functional-white);
          border-color: var(--teddy-color-gray-150);
        `;
    }
  }}
`;

export const Wrapper = styled(Box)<WrapperProps>`
  border-width: var(--teddy-border-width-xs);
  border-style: solid;
  ${variantStyles};
`;

export const BadgeSlot = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: var(--teddy-spacing-0);
  right: 2.5rem;
`;
