import { Container } from "@telia-no-min-side/components";
import { Card, Text, Button, Badge, Icon, Flex } from "@telia/teddy";
import { Link } from "react-router-dom";
import { phoneNumbers } from "util/phoneNumbers";

export function TempAccessError() {
  return (
    <Container maxWidth="sm" padding>
      <Card shadow gap="200">
        <Card.Heading>Beklager</Card.Heading>
        <Card.Content>
          <Flex direction="column" gap="400">
            <Text>Det har dessverre oppstått en feil. Vennligst kontakt kundeservice.</Text>
            <Flex direction="column" align="start" gap="300">
              <Badge variant="neutral">
                <Icon name="phone" />
                {phoneNumbers.customerServiceNorway}
              </Badge>
              <Button variant="primary" asChild>
                <Link to="/minside/hjem">
                  <Icon name="home" />
                  Tilbake til Hjem
                </Link>
              </Button>
            </Flex>
          </Flex>
        </Card.Content>
      </Card>
    </Container>
  );
}
