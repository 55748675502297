import { Link } from "@telia-no-min-side/components";
import { getSimplePriceText } from "@telia-no-min-side/utils";
import { isB2BCompanyPaidTeliaTryggProduct } from "./utils/isB2BCompanyPaidTeliaTryggProduct";
import { config } from "src/util/config";
import { VasAdditionalProduct } from "../types";
import { Box, Card, Flex } from "@telia/teddy";
import { ProductBadge } from "../ProductBadge";
import { TryggProductFeatures } from "./TryggProductFeatures";

type Props = {
  product: VasAdditionalProduct;
};

export const TryggProduct = ({ product }: Props) => {
  const isB2BTeliaTrygg = isB2BCompanyPaidTeliaTryggProduct(product);

  const getPriceText = (): { price: string; pricePrefix?: string } | null => {
    const price = getSimplePriceText(product.price?.priceWithoutDiscount ?? 0, "md.");

    if (isB2BTeliaTrygg) {
      return {
        pricePrefix: "Ordinær pris",
        price,
      };
    }

    return { price };
  };

  const priceText = getPriceText();

  return (
    <Flex width="100%" maxWidth="400px" direction="column" gap="gutter-lg">
      <Card
        variant="white"
        justify="stretch"
        shadow
        layout="product"
        bordered
        data-tracking-id="additional-services-trygg-product-section-info"
      >
        <Card.Slot>
          <ProductBadge product={product} showFullText />
        </Card.Slot>
        <Card.Heading as="h2">{product.shortName}</Card.Heading>
        <Card.Content>
          <TryggProductFeatures shortDescription={false} />
          <Box mt="200">
            <Link
              href={product?.descriptionLink?.length ? product?.descriptionLink : `${config.teliaNoRootUrl}/trygg/`}
              variant="standalone"
              target="_blank"
            >
              Les mer om Telia Trygg
            </Link>
          </Box>
        </Card.Content>
        {priceText ? (
          <Card.Price priceText={priceText.price} pricePrefix={priceText.pricePrefix} variant="purple" />
        ) : null}
      </Card>
    </Flex>
  );
};
