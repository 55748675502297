import { types } from "@telia-no-min-side/utils";
import { SelectedRequirementProductIds } from "../context/FlexOrderProvider";

export function getSelectedRequirementProductIds(
  { productId, requirementIndex }: Pick<SelectedRequirementProductIds[number], "productId" | "requirementIndex">,
  pageRequirement: types.fixed.FlexProductRequirement | undefined,
  prevState: SelectedRequirementProductIds | undefined
) {
  const productToSet = pageRequirement?.options.find((requirement) => requirement.productId === productId);

  if (!prevState?.length) {
    return [
      {
        productId,
        requirementIndex,
        flexRequirementId: productToSet?.flexRequirementId,
      },
    ];
  }
  const shouldRemove = prevState.some(
    (requirement) => requirement.productId === productId && requirement.requirementIndex === requirementIndex
  );
  if (shouldRemove) {
    return prevState.filter((requirement) => requirement.productId !== productId);
  }

  if (pageRequirement?.askAboutKeepingDecoder) {
    return [
      ...prevState.filter((p) => p.requirementIndex !== requirementIndex),
      {
        productId,
        requirementIndex,
        flexRequirementId: productId,
      },
    ];
  }

  const selectedProductsForCurrentIndex = prevState.filter((p) => p.requirementIndex === requirementIndex);
  const isNoEquipmentProduct = productId === -1;
  const productToReplace = selectedProductsForCurrentIndex.find(
    (p) =>
      p.flexRequirementId === productToSet?.flexRequirementId ||
      (isNoEquipmentProduct && p.flexRequirementId) ||
      (p.productId === -1 && productToSet?.flexRequirementId)
  );

  if (productToSet && productToReplace) {
    return prevState.map((p) =>
      p.productId === productToReplace.productId && p.requirementIndex === requirementIndex
        ? {
            ...p,
            productId: productToSet.productId,
            flexRequirementId: productToSet.flexRequirementId,
          }
        : p
    );
  }

  if (isNoEquipmentProduct && productToReplace) {
    return prevState.map((p) =>
      p.productId === productToReplace.productId
        ? {
            ...p,
            productId,
            flexRequirementId: null,
          }
        : p
    );
  }
  if (isNoEquipmentProduct) {
    return [
      ...prevState,
      {
        productId,
        requirementIndex,
        flexRequirementId: null,
      },
    ];
  }
  if (productToSet) {
    return [
      ...prevState,
      {
        productId: productToSet.productId,
        requirementIndex,
        flexRequirementId: productToSet.flexRequirementId,
      },
    ];
  }

  return prevState;
}
