import { Card, Container, Heading, Paragraph, Icon } from "@telia-no-min-side/components";

type Props = {
  hasBestSubscription: boolean;
  commitmentEndDate: string;
};

export function BindingTimeWarning({ hasBestSubscription, commitmentEndDate }: Props) {
  return (
    <Card style={{ padding: 0, marginBottom: "1rem" }} data-tracking-id="mobile-subscription-change-commitment-info">
      <Container flexDirection="column" gap padding>
        <Heading tag="h3" variant="title-150" removeMargin>
          Bindingstid
        </Heading>
        <Paragraph>
          {hasBestSubscription
            ? `Siden du har bindingstid så kan du oppgradere ditt abonnement, men ikke nedgradere. Siden du allerede
        har det beste abonnementet, har du ikke mulighet til å endre ditt abonnement.`
            : `Siden du har bindingstid så kan du oppgradere ditt abonnement, men ikke nedgradere. `}
        </Paragraph>
        <Card backgroundColor="blue" showShadow={false} style={{ minHeight: "auto" }}>
          <Container flexDirection="row" gap>
            <Icon icon="calendar" />
            <Paragraph>
              Din bindingstid utgår <b>{commitmentEndDate}.</b> Da kan du endre ditt abonnement helt fritt!
            </Paragraph>
          </Container>
        </Card>
      </Container>
    </Card>
  );
}
