import { TextField } from "@telia/styleguide";
import { Card, Container, Heading, MoreInfoIcon, Paragraph, useWindowSize } from "@telia-no-min-side/components";
import { useSimCheckout } from "pages/mobile/mobile-subscription-sim-pin-puk/hooks/useSimCheckout";

export function SimDescriptionField() {
  const { isBigDesktop } = useWindowSize();
  const { simDescription, setSimDescription } = useSimCheckout();

  return (
    <Card data-tracking-id="sim-card-description-field">
      <Container flexDirection="row" justifyContent="space-between">
        <Heading tag="h3" variant="title-100">
          Valgfritt
        </Heading>

        <MoreInfoIcon
          label="Beskrivelse av SIM"
          description="Du kan sette en egendefinert beskrivelse av din nye SIM for å enklere skille mellom SIM-kortene dine."
          position={isBigDesktop ? "right" : "left"}
        />
      </Container>

      <Paragraph removeMargin>
        <em>Gi en beskrivelse av ditt nye SIM-kort:</em>
      </Paragraph>

      <TextField
        maxlength={12}
        helpText="Maks lengde er 12 karakterer"
        value={simDescription}
        onChange={(e) => setSimDescription(e.target.value)}
        type={"text"}
        placeholder={"F.eks. Silje sin smartklokke"}
      />
    </Card>
  );
}
