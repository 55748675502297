import { PaymentMethodName, InvoiceDistribution } from "gql/graphql";

// Check if user can proceed to create direct debit (Opprett AvtaleGiro) section
export const canCreateDirectDebit = (
  paymentMethodName: PaymentMethodName | null | undefined,
  invoiceDistributionType: InvoiceDistribution | undefined,
  invoicePaid: boolean | null | undefined
) => {
  // Direct Debit (AvtaleGiro) can be shown to users who have below mentioned payment method and current InvoiceDistribution type not as EFakture
  return (
    paymentMethodName === PaymentMethodName.Manual &&
    invoiceDistributionType !== InvoiceDistribution.EFaktura &&
    !invoicePaid
  );
};
