import { notEmpty } from "@telia-no-min-side/utils";
import { getFragmentData } from "gql";
import { AccountsAgreementsQuery, SwitchStatus } from "src/gql/graphql";
import { insuranceAgreementFragment } from "../graphql/insuranceAgreementFragment";
import { switchAgreementFragment } from "../graphql/switchAgreementFragment";

type AgreementAccount = NonNullable<AccountsAgreementsQuery["accounts"]>[0];

export type LeaseAgreement = NonNullable<AgreementAccount["leaseAgreements"]>["leases"][0];
export type SwitchAgreement = NonNullable<AgreementAccount["switchAgreements"]>[0];
export type InsuranceAgreement = NonNullable<AgreementAccount["insuranceAgreements"]>[0];

export type MobileAgreement =
  | { type: "LeaseAgreement"; accountId: string; data: NonNullable<LeaseAgreement> }
  | { type: "SwitchAgreement"; accountId: string; data: NonNullable<SwitchAgreement> }
  | { type: "InsuranceAgreement"; accountId: string; data: NonNullable<InsuranceAgreement> };

const switchAgreementOrder = {
  [SwitchStatus.Active]: 1,
  [SwitchStatus.Pending]: 2,
  [SwitchStatus.Hold]: 3,
  [SwitchStatus.Unknown]: 4,
};

function sortSwitchAgreementsByStatus(agreements: SwitchAgreement[]) {
  return agreements.sort((a, b) => {
    const { status: statusA } = getFragmentData(switchAgreementFragment, a);
    const { status: statusB } = getFragmentData(switchAgreementFragment, b);
    return switchAgreementOrder[statusA] - switchAgreementOrder[statusB];
  });
}

export function getMobileAccountAgreements(
  accountsWithMobileAgreements?: AccountsAgreementsQuery["accounts"]
): MobileAgreement[] {
  const agreementsData = accountsWithMobileAgreements?.filter(notEmpty).flatMap((account) => {
    const leaseAgreements: MobileAgreement[] = (account.leaseAgreements?.leases || [])
      .filter(notEmpty)
      .map((agreement) => ({
        accountId: account.id,
        data: agreement,
        type: "LeaseAgreement",
      }));

    const switchAgreements: MobileAgreement[] = sortSwitchAgreementsByStatus(
      (account.switchAgreements || []).filter(notEmpty)
    ).map((agreement) => ({
      accountId: account.id,
      data: agreement,
      type: "SwitchAgreement",
    }));

    const insuranceAgreements: MobileAgreement[] = (account.insuranceAgreements || [])
      .filter((insurance) => {
        const { product } = getFragmentData(insuranceAgreementFragment, insurance);
        return !product?.toLocaleLowerCase().includes("switch");
      })
      .map((agreement) => ({
        accountId: account.id,
        data: agreement,
        type: "InsuranceAgreement",
      }));

    return [...leaseAgreements, ...switchAgreements, ...insuranceAgreements];
  });

  return agreementsData || [];
}
