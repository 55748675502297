import { useState } from "react";
import { format } from "@telia-no-min-side/utils";
import { Card, Flex, Badge, Icon, Text } from "@telia/teddy";
import { MoreInfoIcon, useWindowSize } from "@telia-no-min-side/components";
import { Maybe } from "gql/graphql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { ActionItem, ActionMenu } from "components/action-menu";

type Props = {
  productName: string;
  isEsim: boolean;
  cardNickname?: string;
  price: Maybe<number> | undefined;
  simCardNumber: string;
  showActionButton?: boolean;
  actionList?: ActionItem[];
  lastUsedDevice?: Maybe<string>;
  imei?: Maybe<string>;
  initialPinCode: string;
  pukCode: string;
};

export function SimCard({
  productName,
  isEsim,
  cardNickname,
  price,
  simCardNumber,
  showActionButton,
  actionList,
  lastUsedDevice,
  imei,
  initialPinCode,
  pukCode,
}: Props) {
  const { isMobile } = useWindowSize();
  const [showActions, setShowActions] = useState(false);
  const { orders } = useOrderSubscription();
  const isBeingDeactivated = orders.some((order) => order.simCardNumber === simCardNumber);

  return (
    <Card shadow bordered layout="default" width="100%" maxWidth="600px">
      <Card.Slot align="top-left">
        <Badge variant="information">{productName}</Badge>
      </Card.Slot>

      {isBeingDeactivated && (
        <Card.Slot align="top-right">
          <Badge variant="neutral">
            <Icon name="sync">Deaktiveres</Icon>
          </Badge>
        </Card.Slot>
      )}

      <Card.Content pt="200">
        <Flex direction="column" gap="gutter-sm">
          <Flex gap={isMobile ? "200" : "600"} align="start">
            <Flex gap="300" flexGrow="1" flexShrink="1">
              <Icon size="md" name={isEsim ? "esim" : "simcard"} style={{ alignSelf: "start" }} />
              <Flex direction="column" gap="50" width="100%">
                <Flex justify="between">
                  <Flex direction={isMobile ? "column" : "row"} gap="100">
                    <Text>{isEsim ? "eSim" : "Fysisk SIM"}</Text>
                    {cardNickname && <Text data-di-mask>{cardNickname}</Text>}
                  </Flex>

                  <Text>{format.simplePriceText(price, "md.")}</Text>
                </Flex>

                <Flex gapX="400" wrap="wrap">
                  <Text>SIM-nummer:</Text>
                  <Text faded data-di-mask>
                    #{simCardNumber}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            {showActionButton && !isBeingDeactivated && (
              <ActionMenu
                showActions={showActions}
                actionList={actionList}
                toggleShowActions={() => setShowActions((prev) => !prev)}
              />
            )}
          </Flex>

          {(lastUsedDevice || imei) && (
            <Flex>
              <Flex gap="300">
                <Icon size="md" name="devices" style={{ alignSelf: "start" }} />
                <Flex direction="column" gap="50">
                  {lastUsedDevice && <Text data-di-mask>Brukes av: {lastUsedDevice}</Text>}
                  {imei && (
                    <Flex gapX="400" wrap="wrap">
                      <Text>IMEI-nummer:</Text>
                      <Text faded data-di-mask>
                        {imei}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}

          <Card variant="gray" layout="default" width="65%" ml="600">
            <Flex justify="between" wrap="wrap" align="center">
              <Flex gap="50" align="center">
                <Text>Orginal PIN:</Text>
                <Text variant="paragraph-100-bold" data-di-mask>
                  {initialPinCode}
                </Text>

                <MoreInfoIcon
                  label="Original PIN-kode"
                  description="Vi kan bare se PIN-koden som gjaldt for SIM-kortet da du bestilte det. Har du byttet PIN-koden selv i etterkant, vil ikke den vises her. Har du glemt koden, kan du taste feil tre ganger og bruke PUK-koden til høyre for å lage en ny."
                  position={isMobile ? "bottom" : "right"}
                />
              </Flex>

              <Flex align="center" gap="50">
                <Text>PUK:</Text>
                <Text variant="paragraph-100-bold" data-di-mask>
                  {pukCode}
                </Text>
              </Flex>
            </Flex>
          </Card>
        </Flex>
      </Card.Content>
    </Card>
  );
}
