export const getErrorMessage = (errorMsg: string) => {
  if (
    errorMsg.includes("TELEPHONE_NUMBER_IN_USE_ON_ORDER_IN_PROGRESS") ||
    errorMsg.includes("ERROR_MAIN_SIM_PHONE_IN_PROGRESS")
  ) {
    return "Dette telefonnummeret er allerede i en bestillingsprosess. Dersom du mener dette er feil, kontakt kundeservice.";
  } else if (errorMsg.includes("NOT_AUTHORIZED_TO_ACCESS_PHONE_NUMBER")) {
    return " Du har ikke tilgang til dette telefonnummeret.";
  } else if (errorMsg.includes("CUSTOM_DELIVERY_ADDRESS_NOT_ALLOWED")) {
    return "En egendefinert leveringsadresse er ikke tillatt. Dersom du mener leveringsadressen er feil, kontakt kundeservice.";
  } else if (errorMsg.includes("IMSI_NOT_AVAILABLE")) {
    return "SIM-kortet er enten allerede aktivert eller utilgjengelig. Dersom du mener dette er feil, kontakt kundeservice.";
  }
  return "Noe gikk galt. Vennligst kontakt kundeservice.";
};
