import { SyntheticEvent, useEffect, useState } from "react";
import { useFormHandling, useLocalStorage } from "@telia-no-min-side/components";
import { getSimplePriceText } from "@telia-no-min-side/utils";
import { useSearchParams } from "react-router-dom";
import { AdditionalProduct, OrderAdditionalProductsMutation, OrderOp } from "src/gql/graphql";
import { Option } from "../../types";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { ExpandableCard, Flex, Heading, Badge, Notification, Button } from "@telia/teddy";
import { ServiceBox } from "components/service-box";
import { useOrderAdditionalProducts } from "hooks/useAdditionalProducts";
import { PromptModal } from "components/prompt-modal/PromptModal";

type Props = {
  products: AdditionalProduct[];
  title: string;
  pageName: string;
  onCompletedHandler?: (data: OrderAdditionalProductsMutation) => void;
};

type InitialState = Record<string, Option>;

const getInitialState = (products: AdditionalProduct[]): InitialState => {
  const validProducts = products.filter((p) => !!p.code);
  return Object.fromEntries(
    validProducts.map((product) => [
      product.code,
      {
        isModifiable: product.presentation?.modifiable,
        isActive: product.presentation?.active,
        title: <strong>{product.name}</strong>,
        content: product.shortDescription,
        priceText: getSimplePriceText(product.price?.price || 0),
        id: product.code,
        excludedAdditionalProducts: product.excludedAdditionalProducts,
        originalState: product.presentation?.active,
      },
    ])
  );
};

export function OrderServiceForm({ products, title, pageName, onCompletedHandler }: Props) {
  const [searchParams] = useSearchParams();
  const setting = searchParams.get("setting");

  if (setting) {
    const element = document.getElementById(setting);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  const { phoneNumber } = useMobileAccount();
  const initialState = getInitialState(products);
  const [isModalShown, setIsModalShown] = useState(false);
  const { state, dispatch, Actions } = useFormHandling(initialState);
  const { form, previousForm, showSuccess, showError } = state;

  const { orderResponse, runOrderAdditionalProducts } = useOrderAdditionalProducts({
    onCompleted(data) {
      if (onCompletedHandler) onCompletedHandler(data);
    },
  });
  const { data, error, loading } = orderResponse;

  const totalProductsCount = products.length;
  const totalActiveProductsCount = Object.values(previousForm).filter((product) => product.isActive).length;

  const isFormModified = !Object.values(form).every(
    (product) => product.isActive === previousForm[product.id].isActive
  );

  const { setValue: setOrderId } = useLocalStorage<string>(`telia-no-minside-order-id__${pageName}`);

  useEffect(() => {
    dispatch({ type: Actions.SET_SHOW_ERROR, payload: { showError: false } });
    dispatch({
      type: Actions.SET_SHOW_SUCCESS,
      payload: { showSuccess: false },
    });

    if (data?.order?.orderId && !loading) {
      dispatch({ type: Actions.ON_SUCCESS });
      setOrderId(data.order.orderId);
    }

    if (error?.message && !loading) {
      dispatch({ type: Actions.ON_ERROR });
    }
  }, [dispatch, data, error, loading, Actions]);

  function order(form: Record<string, Option>, phoneNumber: string) {
    const orderInput = Object.values(form)
      // only add changed values
      .filter((option) => option.isActive !== previousForm[option.id].isActive)
      .map((option) => ({
        productName: option.id,
        operation: option.isActive ? OrderOp.AddAdditionalProduct : OrderOp.RemoveAdditionalProduct,
        email: "",
      }));

    runOrderAdditionalProducts({
      phoneNumber,
      input: orderInput,
    });
  }

  function handleToggle(key: string) {
    const toggled = !form[key].isActive;
    const option = {
      ...form[key],
      isActive: toggled,
    };

    // Disable excluded products when trying to enable a specific product
    if (toggled) {
      const excludedProducts = form[key].excludedAdditionalProducts;

      excludedProducts?.forEach((excludedProduct) => {
        if (form[excludedProduct]) {
          dispatch({
            type: Actions.SET_FORM,
            payload: {
              [excludedProduct]: { ...form[excludedProduct], isActive: false },
            },
          });
        }
      });
    }

    dispatch({ type: Actions.SET_SHOW_ERROR, payload: { showError: false } });
    dispatch({
      type: Actions.SET_SHOW_SUCCESS,
      payload: { showSuccess: false },
    });
    dispatch({
      type: Actions.SET_FORM,
      payload: { [key]: option },
    });
  }

  return (
    <>
      <ExpandableCard
        type="multiple"
        defaultValue={!!setting && setting === title.toLocaleLowerCase() ? ["content1"] : undefined}
      >
        <ExpandableCard.Item value="content1">
          <ExpandableCard.Trigger
            onClick={() => {
              !loading && dispatch({ type: Actions.RESET });
            }}
          >
            <ExpandableCard.Header>
              <ExpandableCard.Description>
                <Flex direction="column" gap="100">
                  <Heading as="h5" variant="title-100">
                    {title}
                  </Heading>
                  <div>
                    <Badge variant={totalActiveProductsCount > 0 ? "success" : "neutral"} hideIcon>
                      {`Aktivert ${totalActiveProductsCount} av ${totalProductsCount}`}
                    </Badge>
                  </div>
                </Flex>
              </ExpandableCard.Description>
              <ExpandableCard.Indicator />
            </ExpandableCard.Header>
          </ExpandableCard.Trigger>
          <ExpandableCard.Content>
            <Notification variant="success" open={showSuccess}>
              <Notification.Dismiss />
              <Notification.Heading as="h3">
                <Notification.Icon>Dine endringer er lagret.</Notification.Icon>
              </Notification.Heading>
              <Notification.Text>Det kan ta opp til 5 minutter før du ser endringen her.</Notification.Text>
            </Notification>
            <Notification variant="error" open={showError}>
              <Notification.Dismiss />
              <Notification.Heading as="h3">
                <Notification.Icon>Endringene dine ble ikke lagret. Vennligst prøv på nytt.</Notification.Icon>
              </Notification.Heading>
            </Notification>
            {Object.values(form).map((product: Option) => (
              <ServiceBox
                {...product}
                key={product.id}
                disabled={!product.isModifiable || loading}
                onChange={() => {
                  if (product.id === "STOP_VOICEMAIL" && product.isActive === false) {
                    setIsModalShown(true);
                  } else {
                    handleToggle(product.id);
                  }
                }}
              />
            ))}
            {isFormModified && (
              <Flex gap="200">
                <Button
                  onClick={() => dispatch({ type: Actions.RESET })}
                  type="button"
                  variant="tertiary-purple"
                  disabled={loading}
                >
                  Avbryt
                </Button>

                <Button
                  onClick={(event: SyntheticEvent) => {
                    event.preventDefault();
                    order(form, phoneNumber);
                  }}
                  loading={loading}
                  disabled={loading}
                  type="button"
                  data-tracking-id="service-save-button"
                >
                  {loading ? "Sender bestilling" : "Lagre"}
                </Button>
              </Flex>
            )}
          </ExpandableCard.Content>
        </ExpandableCard.Item>
      </ExpandableCard>

      <PromptModal
        open={isModalShown}
        onClose={() => setIsModalShown(false)}
        onConfirm={() => {
          setIsModalShown(false);
          handleToggle("STOP_VOICEMAIL");
        }}
        heading={<p>OBS!</p>}
        body={
          <p>Fjerning av personsvar vil også deaktivere alle tvilling SIM-kort som er knyttet til dette nummeret.</p>
        }
      />
    </>
  );
}
