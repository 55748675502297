import { Container, TrackClickEvent } from "@telia-no-min-side/components";
import { Heading, Text, Button, Icon } from "@telia/teddy";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function Success({ reEnteringPage, children }: { reEnteringPage?: boolean; children?: ReactNode }) {
  return (
    <Container goBackTo="/minside/hjem" padding maxWidth="lg" flexDirection="column" gap="lg">
      <Heading as="h1" variant="title-300">
        Din familierabatt er aktivert!
      </Heading>
      {children}
      {reEnteringPage ? (
        <Text as="p">Du har allerede en aktiv familierabatt.</Text>
      ) : (
        <Text as="p">I løpet av kort tid vil du se endringene her på Min Side. </Text>
      )}
      <TrackClickEvent
        asChild
        event={{
          ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK_BACK_TO_HOME,
          ui_item_context: TRACK_EVENT.ITEM_CONTEXT.B2B2C_FAMILY_DISCOUNT,
          ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
          ui_item_url: window.location.pathname,
          ui_item_text: "Tilbake til hjem (Din familierabatt er aktivert!)",
        }}
      >
        <Button asChild mr="auto">
          <Link to="/minside/hjem">
            <Icon name="home">Tilbake til hjem</Icon>
          </Link>
        </Button>
      </TrackClickEvent>
    </Container>
  );
}
