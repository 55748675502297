import { Button, Heading, Paragraph } from "@telia-no-min-side/components";
import { track } from "@telia-no-min-side/utils";
import { CustomerCareLink } from "src/components/customer-care-link";
import { SerialNumberBoxes } from "./info-modal/SerialNumberContent";
import { useSelfRecoveryState } from ".";

export function AccountNotFound(): JSX.Element {
  const { setRecoveryPage } = useSelfRecoveryState();
  const { pushGenericTrackingEvent } = track.useEventTracking();

  return (
    <div>
      <Paragraph>Vi fant dessverre ikke informasjon som samsvarte med dine opplysninger.</Paragraph>
      <Heading tag="h2" variant="subsection-100">
        Brukte du kundenummer eller fakturanummer?
      </Heading>
      <Paragraph>Gjerne dobbeltsjekk at du brukte korrekt kundenummer eller fakturanummer for TV/internett. </Paragraph>
      <Heading tag="h2" variant="subsection-100">
        Brukte du serienummer?
      </Heading>
      <Paragraph>Vær oppmerksom på at kun ett av nummerene på klistremerket er korrekt. </Paragraph>
      <SerialNumberBoxes />
      <Button
        variant="primary"
        onClick={() => {
          pushGenericTrackingEvent({
            ui_item_action: "button_click",
            ui_item_context: "self-recovery",
            ui_item_text: "Prøv igjen",
            ui_item_type: "button",
            ui_item_url: "no_url",
          });
          setRecoveryPage("chooseRecoveryMethod");
        }}
      >
        Prøv igjen
      </Button>
      <Paragraph>Klarer du fortsatt ikke løse dette problemt. Kontakt kundeservice.</Paragraph>
      <CustomerCareLink trackingContext="self-recovery" />
    </div>
  );
}
