import React, { ReactNode } from "react";
import { Header } from "./style";

type Props = {
  children: ReactNode;
};

/**
 * @deprecated Use Teddy components instead
 */
export function ModalHeader(props: Props): JSX.Element {
  const { children } = props;

  return (
    <Header justifyContent="center" alignItems="center">
      {children}
    </Header>
  );
}
