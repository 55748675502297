import styled from "styled-components";
import { breakpoints, Container, devices, Heading, Paragraph } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";

export const StyledContainer = styled(Container)`
  padding: 4rem 1rem;
  max-width: ${breakpoints.md}px;
`;

export const StyledBox = styled.div`
  border: solid 0.5rem ${colors.corePurple700};
  background-color: ${colors.white};
  max-width: 840px;
  padding: 50px;
  display: flex;
  flex-direction: column;
`;

export const UserInfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${devices.sm_down} {
    flex-direction: column;
  }
`;

export const UserInfoKey = styled(Paragraph)`
  width: 150px;
`;

export const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: 1rem;
`;
