import { AgencyAssignMessage } from "./components/AgencyAssignMessage";
import { CustomerRequestMessage } from "./components/CustomerRequestMessage";
import { LostMessage } from "./components/LostMessage";
import { NotPayingMessage } from "./components/NotPaying";
import { StolenMessage } from "./components/StolenMessage";
import { OtherMessage } from "./components/OtherMessage";
import { OverCreditLimitMessage } from "./components/OverCreditLimit";
import { FraudMessage } from "./components/FraudMessage";
import { UnderAgeMessage } from "./components/UnderAgeMessage";
import { StatusReasonCode } from "src/gql/graphql";
import { UseMobileAccountSubscriptionData } from "src/hooks/useMobileAccount";
import { SoftCollectionMessage } from "./components/SoftCollectionMessage";

type Props = {
  subscription: UseMobileAccountSubscriptionData;
};

// TODO: Where to place this component
// TODO: Check links L, url directs to mobile-side
export function MobileSubscriptionSuspended({ subscription }: Props) {
  const userOffering = subscription?.userOffering;
  const subscriptionStatusDate = subscription?.subscriptionStatusDate;
  const productUser = subscription?.productUser;

  switch (subscription?.statusReasonCode) {
    case StatusReasonCode.Lost:
      return (
        <LostMessage
          userOffering={userOffering}
          subscriptionStatusDate={subscriptionStatusDate}
          productUser={productUser}
          phoneNumber={subscription?.phoneNumber}
        />
      );

    case StatusReasonCode.SoftCollection:
      return <SoftCollectionMessage userOffering={userOffering} subscriptionStatusDate={subscriptionStatusDate} />;

    case StatusReasonCode.NotPaying:
      return (
        <NotPayingMessage
          userOffering={userOffering}
          subscriptionStatusDate={subscriptionStatusDate}
          productUser={productUser}
          phoneNumber={subscription?.phoneNumber}
        />
      );

    case StatusReasonCode.Stolen:
      return <StolenMessage userOffering={userOffering} subscriptionStatusDate={subscriptionStatusDate} />;

    case StatusReasonCode.OverCreditLimit:
      return <OverCreditLimitMessage userOffering={userOffering} subscriptionStatusDate={subscriptionStatusDate} />;
    case StatusReasonCode.Fraud:
      return <FraudMessage userOffering={userOffering} subscriptionStatusDate={subscriptionStatusDate} />;

    case StatusReasonCode.AgencyAssing:
      return <AgencyAssignMessage userOffering={userOffering} />;

    case StatusReasonCode.UnderMaturityAge:
      return <UnderAgeMessage userOffering={userOffering} />;

    case StatusReasonCode.CustomerRequest:
      return (
        <CustomerRequestMessage
          userOffering={userOffering}
          subscriptionStatusDate={subscriptionStatusDate}
          productUser={productUser}
          phoneNumber={subscription?.phoneNumber}
        />
      );

    default:
      return <OtherMessage userOffering={userOffering} subscriptionStatusDate={subscriptionStatusDate} />;
  }
}
