import { Flex, Card, Badge, Icon, Text } from "@telia/teddy";
import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { useToast } from "@telia-no-min-side/components";
import { format, track } from "@telia-no-min-side/utils";
import { graphql } from "src/gql";
import { OfferingPriceCodes, OrderOp } from "src/gql/graphql";
import { ErrorCard } from "../../card/ErrorCard";
import { LoadingSubscriptionCard } from "../../card/LoadingSubscriptionCard";
import { getProductNameFromSubscription } from "../../card/util";
import { SUMMER_CAMPAIGN_PRODUCT_CODES } from "util/format-subscription/variables";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

const SUMMER_CAMPAIGN_QUERY = graphql(`
  query summerCampaignQuery($phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      account {
        id
      }
      productUser {
        firstName
        surname
      }
      phoneNumber {
        localNumber
      }
      additionalProducts {
        id
        code
        shortName
        presentation {
          active
        }
        productTerms {
          code
          amount
          monetaryUnit
        }
      }
      userOffering {
        pricePlan
        shortName
        offeringPrices {
          offeringPrices {
            amount
            code
            description
            monetaryUnit
          }
        }
      }

      ...SubscriptionUsage
    }
  }
`);

const ORDER_ADDITIONAL_PRODUCT = graphql(`
  mutation OrderAdditionalProduct($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);

type Props = {
  phoneNumber: string;
};

export function SummerCampaignCard({ phoneNumber }: Props) {
  const detailedSubscription = useQuery(SUMMER_CAMPAIGN_QUERY, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
  });

  const { addToast } = useToast();

  const [orderAdditionalProduct, orderAdditionalProductResponse] = useMutation(ORDER_ADDITIONAL_PRODUCT);
  const { subscribeToOrder, orders } = useOrderSubscription();
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const hasOnGoingOrder = orders.some((order) => order.phoneNumber === phoneNumber);

  if (detailedSubscription.error && !detailedSubscription.data) {
    return (
      <ErrorCard
        sectionText={
          <>
            abonnement til <em data-di-mask>{phoneNumber}</em>
          </>
        }
        onRefreshClick={() => detailedSubscription.refetch({ phoneNumber })}
        isLoading={detailedSubscription.networkStatus === NetworkStatus.refetch}
      />
    );
  }

  if (detailedSubscription.loading && !detailedSubscription.data) {
    return <LoadingSubscriptionCard />;
  }

  if (!detailedSubscription.data?.subscription) return null;
  const { subscription } = detailedSubscription.data;
  const firstName = subscription.productUser?.firstName || "";

  const summerCampaignProduct = subscription.additionalProducts?.find(
    (additionalProduct) =>
      additionalProduct.code && SUMMER_CAMPAIGN_PRODUCT_CODES.includes(additionalProduct.code.split("_")[0])
  );

  const productCode = summerCampaignProduct?.code || "";

  const originalData = subscription.userOffering?.offeringPrices
    ?.find(
      (offerings) => offerings?.offeringPrices?.find((offering) => offering?.code === OfferingPriceCodes.DataIncluded)
    )
    ?.offeringPrices?.find((offering) => offering?.code === OfferingPriceCodes.DataIncluded);

  const campaignData = summerCampaignProduct?.productTerms?.find(
    (term) => term?.code === OfferingPriceCodes.DataIncluded
  );

  const totalData = format.getTotalData(
    {
      amount: originalData?.amount || 0,
      unit: originalData?.monetaryUnit || "GB",
    },
    {
      amount: campaignData?.amount || 0,
      unit: campaignData?.monetaryUnit || "GB",
    }
  );

  function activateDoubleData() {
    orderAdditionalProduct({
      variables: {
        phoneNumber,
        input: {
          productName: productCode,
          operation: OrderOp.AddAdditionalProduct,
        },
      },
      onCompleted: (response) => {
        const orderId = response.order.orderId;
        if (orderId) {
          subscribeToOrder({
            id: orderId,
            toastText: `Dobbel data er aktivert for ${format.startCase(firstName)}`,
            phoneNumber: phoneNumber,
          });
        } else {
          addToast({
            text: `Vi kunne dessverre ikke aktivere din sommerkampanje. Vennligst prøv igjen. Hvis feilen gjentar seg, kontakt kundeservice.`,
            variant: "error",
          });
        }
      },
      onError: () =>
        addToast({
          text: `Vi kunne dessverre ikke aktivere din sommerkampanje. Vennligst prøv igjen. Hvis feilen gjentar seg, kontakt kundeservice.`,
          variant: "error",
        }),
    });
  }

  const handleClick = () => {
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.DASHBOARD_SUMMER_CAMPAIGN,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.ACTIVATE_OFFER,
    });
    activateDoubleData();
  };

  return (
    <Card shadow bordered variant="white" layout="default" width="100%" maxWidth="400px">
      <Card.Slot align="top-right">
        <Badge variant="warning" hideIcon>
          <Icon name="weather" />
          Sommerkampanje
        </Badge>
      </Card.Slot>

      <Card.Heading as="h3" variant="title-100" data-di-mask>
        {format.startCase(firstName)}
      </Card.Heading>

      <Card.Content>
        <Flex direction="row" justify="between">
          <Flex direction="column">
            <Text variant="additional-100" style={{ maxWidth: "170px" }}>
              Du kan få <strong>dobbel data</strong> på ditt abonnement:
            </Text>
            <Text variant="additional-100" faded>
              Gjelder til 20.09.24
            </Text>
          </Flex>

          <Flex direction="column">
            <Text data-di-mask>{format.formatPhoneNumber(phoneNumber)}</Text>
            <Flex direction="row" justify="end">
              <Text faded>
                <s>{getProductNameFromSubscription(subscription)}</s>
              </Text>
              <Text>
                <strong>{totalData}</strong>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Card.Content>

      <Card.Footer height="100%" align="end">
        <Card.Button
          variant="text"
          loading={orderAdditionalProductResponse.loading || hasOnGoingOrder}
          onClick={handleClick}
        >
          Aktiver tilbud
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
