import { AccountData } from "src/gql/graphql";

type NameFormat = "firstName" | "fullName";

export const getLegalOwnerName = (legalOwner: AccountData["legalOwner"], format: NameFormat = "fullName") => {
  if (!legalOwner) return "";

  if (legalOwner?.individual) {
    const firstName = legalOwner.individual?.firstName || "";
    const lastName = legalOwner.individual?.surname || "";
    return format === "fullName" ? `${firstName} ${lastName}` : firstName;
  }

  if (legalOwner?.organization) {
    return legalOwner?.organization?.name || "";
  }

  return "";
};
