import { ReactNode } from "react";
import { Heading, Box, Flex } from "@telia/teddy";

type ListProps = {
  title?: string;
  children: ReactNode;
};

export function List(props: ListProps) {
  const { title, children } = props;
  return (
    <Box>
      <Heading variant="title-100" as="h3" mb="100" pl="100" pr="100">
        {title}
      </Heading>
      <Flex direction="column" gap="100" mb="100">
        {children}
      </Flex>
    </Box>
  );
}
