import { MainSimCard as MainSimCardType } from "gql/graphql";
import { SimCard } from ".";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { ActionItem } from "components/action-menu";
import { useLocation } from "react-router-dom";

type Props = {
  simCard: MainSimCardType;
  isOver18: boolean;
};

export function MainSimCard({ simCard, isOver18 }: Props) {
  const location = useLocation();
  const isMBB = location.pathname?.includes("mobilt-bredband");

  const canOrderReplacementSim = isOver18;

  const actionListItems: ActionItem[] = [
    {
      kind: "link",
      icon: "switch-arrows",
      text: "Bestill erstatning",
      isInternalNavigation: true,
      href: INTERNAL_LINK[isMBB ? "FIXED" : "MOBILE"].ORDER_ADDITIONAL_SIM_CARD_PAGE,
    },
  ];

  return (
    <SimCard
      productName="HovedSIM"
      isEsim={!!simCard.isEsim}
      price={0}
      simCardNumber={simCard.simCardNumber || ""}
      showActionButton={canOrderReplacementSim}
      actionList={actionListItems}
      lastUsedDevice={simCard.lastUsedDevice}
      imei={simCard.imei}
      initialPinCode={simCard.initialPin || ""}
      pukCode={simCard.pukCode || ""}
    />
  );
}
