import { Heading, Button, Text, TextSpacing, Flex } from "@telia/teddy";

export function CookieSettingsTrigger() {
  const oneTrust = window as Window & { OneTrust?: { ToggleInfoDisplay?: () => void } };

  if (!oneTrust?.OneTrust) return null;

  return (
    <Flex direction="column" gapY="200" align="start">
      <TextSpacing>
        <Heading as="h2" variant="subsection-100">
          Informasjonskapsler/cookies
        </Heading>
        <Text>
          Informasjonskapsler på telia.no brukes for visse funksjoner som forbedrer nettsiden, eller gir oss statistikk
          om bruken. Les mer om Vår bruk av informasjonskapsler.
        </Text>
      </TextSpacing>

      <Button onClick={() => oneTrust.OneTrust?.ToggleInfoDisplay?.()} variant="secondary">
        Se eller endre cookie-innstillinger
      </Button>
    </Flex>
  );
}
