import { useEffect, useState, BaseSyntheticEvent } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { colors, TextBoxWithLabel } from "@telia/styleguide";
import { Button, Paragraph, Container, Heading, useToast } from "@telia-no-min-side/components";
import { DropdownList, InformationSection } from "./style";
import { useReplaceSim } from "./hooks/useReplaceSim";
import { getErrorMessage } from "../mobile-subscription-sim-pin-puk/utils";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { GET_ACTIVATION_SIM_DATA } from "./graphql/getActivationSimData";
import { isSimCardOption } from "./utils/isSimCardOption";
import { BankIdRequiredModal } from "components/bank-id-required-modal";
import { config } from "util/config";

export function MobileSubscriptionActivateSimCard() {
  const [searchParams] = useSearchParams();
  const { phoneNumber } = useMobileAccount();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const [bankIdOpen, setBankIdOpen] = useState(false);
  const forceSkipBankIdCheck = !config.requireBankId;

  const { response: replaceSimResponse, runReplaceSim } = useReplaceSim({
    uniqueKey: "changeVoiceSettings",
    onCompleted() {
      setBankIdOpen(false);
      addToast({
        text: "SIM-kort er aktivert.",
        variant: "success",
        dataTrackingId: "activate-sim-success",
      });
      navigate("..");
    },
    onError(error) {
      const errorText = getErrorMessage(error.message);
      setBankIdOpen(false);
      addToast({
        text: `Bestilling feilet. ${errorText}`,
        variant: "error",
        dataTrackingId: "activate-sim-error",
      });
    },
  });

  const { data, error } = useQuery(GET_ACTIVATION_SIM_DATA, {
    variables: { phoneNumber },
  });

  const simNr = searchParams.get("simNr");
  const mainSimcard = data?.subscription?.mainSimcard;
  const additionalSimcards = data?.subscription?.additionalSimcards;
  const [newSimCardNumber, setNewSimCardNumber] = useState(
    !Array.isArray(simNr) && simNr !== null && simNr !== undefined ? simNr : ""
  );
  const [simCardOptions, setSimCardOptions] = useState<
    {
      name: string;
      value: string | null | undefined;
    }[]
  >([]);
  const [selectedSimCard, setSelectedSimCard] = useState("");

  useEffect(() => {
    if (mainSimcard) {
      const main = {
        name: `Hoved-SIM (${mainSimcard.simCardNumber})`,
        value: mainSimcard.simCardNumber,
      };
      const additional = additionalSimcards?.map((additionalSimcard) => {
        return {
          name: additionalSimcard?.product?.shortName
            ? `${additionalSimcard?.product?.shortName} (${additionalSimcard?.simCardNumber})`
            : "",
          value: additionalSimcard?.simCardNumber,
        };
      });
      const allSimCards = [main, ...(additional || [])];
      if (allSimCards) {
        setSimCardOptions(allSimCards);
        if (allSimCards[0].value) {
          setSelectedSimCard(allSimCards[0].value);
        }
      }
    }
  }, [mainSimcard, additionalSimcards, phoneNumber]);

  const extractNumbers = (input: BaseSyntheticEvent) => {
    return input.target.value.replace(/[^0-9.]/g, "");
  };
  const handleInput = (input: BaseSyntheticEvent) => {
    const inputString = extractNumbers(input);
    setNewSimCardNumber(inputString);
  };

  const validSimCardOption = simCardOptions.filter(isSimCardOption);
  const isSimCardNumberValid = newSimCardNumber.length >= 12 && newSimCardNumber.length <= 20;

  return (
    <>
      {!error && (
        <Container maxWidth="sm" flexDirection="column" gap>
          <Link to="..">
            <Button variant="text-black" icon="arrow-left" iconPosition="before">
              Tilbake
            </Button>
          </Link>

          <Container>
            <Heading tag="h2" variant="title-200">
              Aktiver nytt SIM
            </Heading>
            <Paragraph>
              SIM-nummeret (ICC) finner du på baksiden av SIM-kortet ditt. Angi de siste 12 sifrene (eller alle 20
              sifre) på kortet for å aktivere det. For eSIM, se under strekkoden øverst i brevet.
            </Paragraph>
            <DropdownList
              label="Velg SIM som blir erstattet:"
              visibleLabel={true}
              options={validSimCardOption}
              changeSelectedOption={(e) => setSelectedSimCard(e.target.value)}
              selectedOption={selectedSimCard}
            />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setBankIdOpen(true);
              }}
            >
              <TextBoxWithLabel
                labelText="Nytt SIM-nummer (12 eller 20 siffer)"
                type="text"
                placeholder="SIM-nummer..."
                value={newSimCardNumber}
                onChange={handleInput}
                errorMessage={newSimCardNumber.length > 20 ? "Antall siffer skal ikke være over 20" : undefined}
              />
            </form>
            <InformationSection color={colors.black}>
              Merk at gammelt SIM blir deaktivert samtidig som det nye aktiveres. Slå av og på enheten din etter
              aktiveringen er vellykket
            </InformationSection>
            <Container flexDirection="row" justifyContent="end" gap="lg" padding="top">
              <Button variant="tertiary-black" onClick={() => navigate("..")}>
                Avbryt
              </Button>
              {forceSkipBankIdCheck ? (
                <Button
                  isLoading={replaceSimResponse.loading}
                  loadingText="Aktiverer"
                  disabled={!isSimCardNumberValid}
                  onClick={() =>
                    runReplaceSim(
                      {
                        oldSimNumber: selectedSimCard,
                        newSimNumber: newSimCardNumber,
                        phoneNumber: phoneNumber,
                      },
                      forceSkipBankIdCheck
                    )
                  }
                >
                  Aktiver
                </Button>
              ) : (
                <BankIdRequiredModal
                  open={bankIdOpen}
                  onOpenChange={setBankIdOpen}
                  triggerComp={
                    <Button
                      isLoading={replaceSimResponse.loading}
                      loadingText="Aktiverer"
                      disabled={!isSimCardNumberValid}
                    >
                      Aktiver
                    </Button>
                  }
                  mutateComp={
                    <Button
                      isLoading={replaceSimResponse.loading}
                      onClick={() =>
                        runReplaceSim({
                          oldSimNumber: selectedSimCard,
                          newSimNumber: newSimCardNumber,
                          phoneNumber: phoneNumber,
                        })
                      }
                      data-tracking-id="bankid-modal-bankid-button"
                      disabled={!isSimCardNumberValid}
                    >
                      Til BankID
                    </Button>
                  }
                />
              )}
            </Container>
          </Container>
        </Container>
      )}
    </>
  );
}
