import Keycloak, { KeycloakLoginOptions } from "keycloak-js";
import { isStageDomain } from "../utils/locationHelper";
import type { AuthenticationAdapter, AuthenticationAdapterOptions, UserInfo } from "./auth";

const BANKID_ACR_VALUE = 3;

function isUserInfo(t: unknown): t is UserInfo {
  return typeof t === "object" && t !== null && "name" in t;
}

export function createKeycloakAdapter(adapterOptions: AuthenticationAdapterOptions): AuthenticationAdapter {
  const { customRedirectUri, forceProd } = adapterOptions;
  const isStage = isStageDomain(forceProd);
  const keycloak = new Keycloak({
    url: `https://${isStage ? "stage." : ""}identity.telia.no/`,
    clientId: "telia.no.min-side",
    realm: "telia",
  });

  function requestSignIn() {
    if (keycloak) {
      keycloak.login({
        redirectUri: customRedirectUri || window.location.href.split("#")[0],
      });
    }
  }

  function requestBankId(params?: string, redirectUri?: string) {
    if (keycloak) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const currentCreateLoginUrl = keycloak.createLoginUrl;
      keycloak.createLoginUrl = (options: KeycloakLoginOptions) =>
        `${currentCreateLoginUrl(options)}&acr_values=${BANKID_ACR_VALUE}`;
      keycloak.login({
        redirectUri: redirectUri || `${window.location.href.split("#")[0]}${params ? `?${params}` : ""}`,
      });
    }
  }

  function requestBankIdWithMaxAge(maxAge: number, options?: { params?: string; redirectUri?: string }) {
    const { params, redirectUri } = options || {};
    if (keycloak) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const currentCreateLoginUrl = keycloak.createLoginUrl;
      keycloak.createLoginUrl = (options: KeycloakLoginOptions) =>
        `${currentCreateLoginUrl(options)}&acr_values=${BANKID_ACR_VALUE}&max_age=${maxAge}`;

      keycloak.login({
        redirectUri: redirectUri || `${window.location.href.split("#")[0]}${params ? `?${params}` : ""}`,
      });
    }
  }

  function requestSignOut() {
    if (keycloak) {
      keycloak.logout({
        redirectUri: `https://${isStage ? "stage." : "www."}telia.no`,
      });
    }
  }

  function onTokenExpired() {
    if (keycloak) {
      keycloak.updateToken(60);
    }
  }

  async function setUserInfo() {
    const userInfo = await keycloak?.loadUserInfo();
    if (isUserInfo(userInfo)) {
      adapterOptions.setUserInfo(userInfo);
    }
  }

  const onAuthSuccess = () => {
    if (keycloak) {
      adapterOptions.onTokenChange(keycloak.token, keycloak.tokenParsed);
      setUserInfo();
    }
  };

  const onAuthRefreshSuccess = () => {
    if (keycloak) {
      adapterOptions.onTokenChange(keycloak.token, keycloak.tokenParsed);
    }
  };

  keycloak.authServerUrl = window.location.href;
  keycloak.onAuthSuccess = onAuthSuccess;
  keycloak.onAuthRefreshSuccess = onAuthRefreshSuccess;
  keycloak.onTokenExpired = onTokenExpired;

  if (customRedirectUri) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const currentCreateLoginUrl = keycloak.createLoginUrl;
    keycloak.createLoginUrl = (opts: KeycloakLoginOptions) => {
      const url = currentCreateLoginUrl({
        ...opts,
        redirectUri: customRedirectUri,
      });

      return `${url}`;
    };
  }
  keycloak
    .init({
      flow: "standard",
      onLoad: "login-required",
      checkLoginIframe: false,
      enableLogging: true,
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (!authenticated) requestSignIn();
    });

  return {
    requestSignIn,
    requestSignOut,
    requestBankId,
    requestBankIdWithMaxAge,
  };
}
