import { TrackClickEvent } from "@telia-no-min-side/components";
import { Component, TermContainer } from "../styles/Terms.styled";
import { Link, Icon } from "@telia/teddy";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = {
  termsInsuranceUrl: string;
  termsInsuranceProofUrl: string;
  ui_item_context?: string;
  dataTrackingId?: string;
};

export function Terms(props: Props) {
  const { termsInsuranceUrl, termsInsuranceProofUrl, ui_item_context, dataTrackingId } = props;
  const haveContractId = !!termsInsuranceProofUrl;

  return (
    <Component>
      <TermContainer>
        <TrackClickEvent
          event={
            ui_item_context
              ? {
                  ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                  ui_item_context: ui_item_context,
                  ui_item_text: "Se vilkår",
                  ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
                  ui_item_url: termsInsuranceUrl,
                }
              : undefined
          }
          asChild
        >
          <Link
            variant="navigation"
            href={termsInsuranceUrl}
            target="_blank"
            disableVisited
            data-tracking-id={`${dataTrackingId}-see-insurance-terms-link`}
          >
            <Icon name="external" />
            Se vilkår
          </Link>
        </TrackClickEvent>
      </TermContainer>

      {haveContractId && (
        <TermContainer>
          <TrackClickEvent
            asChild
            event={
              ui_item_context
                ? {
                    ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                    ui_item_context: ui_item_context,
                    ui_item_text: "Forsikringsbevis",
                    ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
                    ui_item_url: termsInsuranceProofUrl,
                  }
                : undefined
            }
          >
            <Link variant="navigation" href={termsInsuranceProofUrl} target="_blank" disableVisited>
              <Icon name="document-pdf" />
              Forsikringsbevis
            </Link>
          </TrackClickEvent>
        </TermContainer>
      )}
    </Component>
  );
}
