import React from "react";
import { DropDownListWithLabel, Heading } from "@telia/styleguide";
import { IpAddressInput } from "../../../modules/IPAddressInput";
import { ContentWrapper } from "../../../modules/LayoutComponents/ContentWrapper";

export const Dhcp = ({
  errorMessages,
  fromIp,
  toIp,
  mask,
  fromIpContract,
  toIpContract,
  onChange,
  canChangeLeaseTime,
  leaseOptions,
  leaseValue,
  handleLeaseSelect,
  onBlur,
}) => (
  <ContentWrapper>
    <ContentWrapper>
      <Heading level={3} text={"DHCP"} />
      <IpAddressInput
        address={fromIp}
        typeOfAddress={"dhcp-from"}
        onChange={onChange}
        title={"Fra"}
        mask={mask}
        addressContract={fromIpContract}
        onBlur={onBlur}
        errorMessage={errorMessages
          .filter((msg) => msg.tag === "dhcp-from")
          .map((msg) => msg.message)
          .join(" og ")}
      />
      <IpAddressInput
        address={toIp}
        typeOfAddress={"dhcp-to"}
        onChange={onChange}
        title={"Til"}
        mask={mask}
        addressContract={toIpContract}
        onBlur={onBlur}
        errorMessage={errorMessages
          .filter((msg) => msg.tag === "dhcp-to")
          .map((msg) => msg.message)
          .join(" og ")}
      />
    </ContentWrapper>
    {canChangeLeaseTime && (
      <DropDownListWithLabel
        label={"Lease varighet"}
        selectedOption={leaseValue}
        options={leaseOptions}
        changeSelectedOption={handleLeaseSelect}
        visibleLabel
      />
    )}
  </ContentWrapper>
);
