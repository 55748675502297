import React from "react";
import { Container, Heading } from "@telia-no-min-side/components";
import { Tabs } from "@telia/styleguide";
import { Beforeunload } from "react-beforeunload";
import { useFetchProfile } from "src/api/fixed";
import REQ from "../../../../util/requestStatus";
import { useRouterSettings } from "../../stores/RouterSettingsProvider";
import { ErrorStatus } from "./ErrorStatus";

export const ErrorPage = () => {
  const { routerSettings } = useRouterSettings();
  const { isLoading } = useFetchProfile();

  return (
    <div>
      <Container maxWidth="md" padding showGoBackButton>
        <Heading tag="h2" variant="title-400">
          {"Nettverks\u00ADinnstillinger"}
        </Heading>
        <Container maxWidth="md" padding={"vertical"}>
          <Tabs>
            <Tabs.TabPanel heading="Trådløse nett" />
            <Tabs.TabPanel heading="Andre innstillinger" />
            <Tabs.TabPanel heading="Porter" />
          </Tabs>
          <ErrorStatus />
          {(isLoading || routerSettings.fetchStatus === REQ.PENDING) && (
            <Beforeunload onBeforeunload={() => "Siden laster, sikker på at du vil laste den inn på nytt?"} />
          )}
        </Container>
      </Container>
    </div>
  );
};
