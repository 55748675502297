import { breakpoints, Container, devices } from "@telia-no-min-side/components";
import styled from "styled-components";

export const MainContainer = styled.div`
  --minside-switch-self-assessment-backlink-height: 30px;
  height: calc(100vh - var(--minside-content-top-margin));
  max-height: calc(100vh - var(--minside-content-top-margin));
  display: flex;
  flex-direction: column;

  @media (min-height: 600px) {
    overflow-y: hidden;
  }

  @media ${devices.md_up} {
    flex-direction: row;
  }
`;

export const BackLink = styled(Container)`
  min-height: var(--minside-switch-self-assessment-backlink-height);
`;

export const Column = styled(Container)`
  @media ${devices.md_up} {
    flex-basis: ${breakpoints.xs}px;
  }
`;

export const ScrollableColumn = styled(Column)`
  margin-top: var(--minside-switch-self-assessment-backlink-height);
  width: 100%;

  @media (min-height: 600px) {
    overflow-y: auto;
  }
`;

export const InsuranceWrapper = styled.div`
  margin-bottom: 3rem;
`;
