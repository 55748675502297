import { TopupCategory } from "src/gql/graphql";
import { isFreeTopup } from "./isFreeTopup";
import { TopupLocalType } from "pages/mobile/mobile-subscription-dashboard/types";

export const getTopupVouchers = (topup: TopupLocalType) => {
  const isFreeTopupType = topup.category === TopupCategory.DataFree || topup.category === TopupCategory.DataBoostFree;

  if (isFreeTopup(topup) && isFreeTopupType) {
    return {
      numberOfVouchers: topup.numberOfVouchers,
      lastReceivedDate: topup.latestReceivedDate,
      nextExpirationDateNullable: topup.nextExpirationDateNullable,
    };
  }
  return {};
};
