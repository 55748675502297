import { Flex } from "@telia/teddy";
import { Breadcrumb } from "components/Breadcrumb";
import { Navigation } from "pages/internet/components/Navigation";
import { Drawer } from "../Drawer/Drawer";
import { TopupModal } from "../TopupModal/TopupModal";
import { OrderSection } from "../Sections/OrderSection";
import { SubscriptionCard } from "../SubscriptionCard/SubscriptionCard";
import { UsageAndAdminSection } from "../Sections/UsageAndAdminSection";
import { MobileBroadbandProvider } from "../../context/MobileBroadbandContext";

export function MobileBroadbandDashboard() {
  return (
    <MobileBroadbandProvider>
      <Drawer />
      <TopupModal />

      <Flex direction="column" gap="50px" maxWidth="720px" pb="300">
        <Flex direction="column" gap="gutter-xl">
          <Breadcrumb />
          <SubscriptionCard />
          <Navigation />
        </Flex>

        <UsageAndAdminSection />

        <OrderSection />
      </Flex>
    </MobileBroadbandProvider>
  );
}
