import { useQuery } from "@apollo/client";
import { format } from "@telia-no-min-side/utils";
import { breakpoint, Flex, Notification, Skeleton } from "@telia/teddy";
import { UserRole } from "gql/graphql";
import { formatDateForSuspendedMessage } from "../utils";
import { useWindowSize } from "@telia-no-min-side/components";
import { GET_ADDITIONAL_PRODUCTS } from "pages/home/header/Outages";
import { getLegalOwnerName } from "util/format-subscription/getLegalOwnerName";
import { ProductFilters } from "pages/mobile/mobile-subscription-settings/types";
import { Hint } from "pages/mobile/mobile-subscription-settings/components/Forms/HintForm";
import { useMobileAccount, UseMobileAccountSubscriptionData } from "src/hooks/useMobileAccount";

type Props = Pick<UseMobileAccountSubscriptionData, "subscriptionStatusDate" | "userOffering">;

export function OverCreditLimitMessage({ userOffering, subscriptionStatusDate }: Props) {
  const userOfferingShortName = userOffering?.shortName || "";
  const { phoneNumber } = useMobileAccount();
  const { isMobile } = useWindowSize();
  const { data, loading } = useQuery(GET_ADDITIONAL_PRODUCTS, {
    variables: { phoneNumber },
    fetchPolicy: "network-only",
  });

  const isLegalOwner = data?.subscription?.roles?.includes(UserRole.LegalOwner);
  const hintProduct = data?.subscription.additionalProducts?.find((product) => product.code === ProductFilters.Hint);

  return (
    <Flex maxWidth={breakpoint.teddyBreakpointMd} p={isMobile ? "200" : "400"} gap="300" direction="column">
      <Skeleton loading={loading}>
        <Notification variant="information" data-tracking-id="alert--suspended-subscription_reason-over-credit-limit">
          <Notification.Heading as="h3">
            <Notification.Icon>Ditt abonnement {userOfferingShortName} er sperret</Notification.Icon>
          </Notification.Heading>
          {isLegalOwner ? (
            <Notification.Text>
              Abonnementet {userOfferingShortName} ble sperret{" "}
              {formatDateForSuspendedMessage(subscriptionStatusDate || "")} på grunn av at din beløpsgrense er nådd. Du
              kan selv gjenåpne abonnementet ved å endre/slå av forbrukssperren under.
            </Notification.Text>
          ) : (
            <Notification.Text>
              Abonnementet {userOfferingShortName} ble sperret{" "}
              {formatDateForSuspendedMessage(subscriptionStatusDate || "")} på grunn av at din forbrukssperre er nådd.
              For å gjenåpne dette abonnementet må juridisk eier{" "}
              {format.startCase(getLegalOwnerName(data?.subscription?.legalOwner))} logge inn på sin bruker og endre/slå
              av forbrukssperren på dette abonnementet.
            </Notification.Text>
          )}
        </Notification>

        {isLegalOwner && hintProduct && <Hint isLegalOwner={isLegalOwner} product={hintProduct} />}
      </Skeleton>
    </Flex>
  );
}
