import { breakpoint, Flex, Skeleton, ExpandableCard, Heading, Badge, Box } from "@telia/teddy";

function SkeletonElement() {
  return (
    <ExpandableCard type="multiple" value={["content2"]}>
      <ExpandableCard.Item value="content1">
        <ExpandableCard.Trigger>
          <ExpandableCard.Header>
            <ExpandableCard.Description>
              <Flex direction="column" gap="100">
                <Skeleton loading>
                  <Heading as="h4" variant="title-200">
                    Settings
                  </Heading>
                </Skeleton>
                <Box flexGrow="0">
                  <Badge variant="neutral" hideIcon>
                    <Skeleton loading>Off</Skeleton>
                  </Badge>
                </Box>
              </Flex>
            </ExpandableCard.Description>
          </ExpandableCard.Header>
        </ExpandableCard.Trigger>
      </ExpandableCard.Item>
    </ExpandableCard>
  );
}

export function SkeletonForm() {
  return (
    <Flex maxWidth={breakpoint.teddyBreakpointMd} direction="column" gap="200">
      <SkeletonElement />
      <SkeletonElement />
      <SkeletonElement />
      <SkeletonElement />
    </Flex>
  );
}
