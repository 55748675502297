import { notEmpty } from "@telia-no-min-side/utils";
import { getFragmentData } from "gql";
import { SubscriptionAgreementsQuery, SwitchStatus } from "src/gql/graphql";
import { switchAgreementFragment } from "../graphql/switchAgreementFragment";
import { insuranceAgreementFragment } from "../graphql/insuranceAgreementFragment";

type AgreementAccount = NonNullable<SubscriptionAgreementsQuery["subscription"]>;

export type LeaseAgreement = NonNullable<AgreementAccount["leaseAgreements"]>["leases"][0];
export type SwitchAgreement = NonNullable<AgreementAccount["switchAgreements"]>[0];
export type InsuranceAgreement = NonNullable<AgreementAccount["insuranceAgreements"]>[0];

export type MobileAgreement =
  | { type: "LeaseAgreement"; accountId: string; data: NonNullable<LeaseAgreement> }
  | { type: "SwitchAgreement"; accountId: string; data: NonNullable<SwitchAgreement> }
  | { type: "InsuranceAgreement"; accountId: string; data: NonNullable<InsuranceAgreement> };

const switchAgreementOrder = {
  [SwitchStatus.Active]: 1,
  [SwitchStatus.Pending]: 2,
  [SwitchStatus.Hold]: 3,
  [SwitchStatus.Unknown]: 4,
};

function sortSwitchAgreementsByStatus(agreements: SwitchAgreement[]) {
  return agreements.sort((a, b) => {
    const { status: statusA } = getFragmentData(switchAgreementFragment, a);
    const { status: statusB } = getFragmentData(switchAgreementFragment, b);
    return switchAgreementOrder[statusA] - switchAgreementOrder[statusB];
  });
}

export function getMobileSubscriptionAgreements(
  subscription: SubscriptionAgreementsQuery["subscription"] | undefined
): MobileAgreement[] {
  const switchAgreements: MobileAgreement[] = sortSwitchAgreementsByStatus(
    (subscription?.switchAgreements || []).filter(notEmpty)
  ).map((agreement) => ({
    accountId: "",
    data: agreement,
    type: "SwitchAgreement",
  }));

  const leaseAgreements: MobileAgreement[] = (subscription?.leaseAgreements?.leases || [])
    .filter(notEmpty)
    .map((agreement) => ({
      accountId: "",
      data: agreement,
      type: "LeaseAgreement",
    }));

  const insuranceAgreements: MobileAgreement[] = (subscription?.insuranceAgreements || [])
    .filter((insurance) => {
      const { product } = getFragmentData(insuranceAgreementFragment, insurance);
      return !product?.toLocaleLowerCase().includes("switch");
    })
    .map((agreement) => ({
      accountId: "",
      data: agreement,
      type: "InsuranceAgreement",
    }));

  return [...switchAgreements, ...leaseAgreements, ...insuranceAgreements];
}
