import { useFetchUser } from "src/api/fixed";
import { useMobileAccount } from "hooks/useMobileAccount";

export function useAccountType() {
  const mobileAccounts = useMobileAccount();
  const fixedAccounts = useFetchUser();
  const fixedUsers = fixedAccounts.data?.usersWithCustomerId || [];
  const mobileUsers = mobileAccounts.allAccounts || [];

  const isConvergentUser = fixedUsers.length > 0 && mobileUsers.length > 0;

  const mobileUserSelectedAccountId = mobileAccounts.accountId;
  const fixedUserSelectedAccountId = (fixedAccounts.data?.usersWithCustomerId || []).find(
    (fixedUser) => fixedUser.selected
  )?.customerId;

  const isFetchAccountsLoading = mobileAccounts.isLoading || fixedAccounts.isLoading;

  return {
    isConvergentUser,
    mobileUserSelectedAccountId,
    fixedUserSelectedAccountId,
    isFetchAccountsLoading,
  };
}
