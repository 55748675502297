import { types } from "@telia-no-min-side/utils";
import { ShoppingSummary } from "components/shopping-summary";

type Props = {
  newTvBox?: types.fixed.ProductGroupSingleProductWithShipping;
  selectedShipping?: types.fixed.ProductGroupShipping;
  modem?: types.fixed.ProductGroupSingleProductWithShipping;
};

export function Shipping(props: Props): JSX.Element {
  const { newTvBox, selectedShipping, modem } = props;
  const tvBoxpriceEstablishment = newTvBox?.price.currentPrice.onceOff ? newTvBox.price.currentPrice.onceOff : 0;
  const shippingPrice = selectedShipping?.onceOffPrice ? selectedShipping.onceOffPrice : 0;

  const modemPrice = modem ? modem.price.currentPrice.onceOff : 0;

  function totalPrice() {
    return tvBoxpriceEstablishment + shippingPrice + modemPrice;
  }

  function establishmentPriceExist() {
    return !(newTvBox && tvBoxpriceEstablishment === 0 && modem && modemPrice === 0);
  }

  return (
    <>
      <ShoppingSummary.Line />
      <ShoppingSummary.List title="Engangspris">
        {establishmentPriceExist() ? (
          <>
            {newTvBox && (
              <ShoppingSummary.Item text={"Etablering " + newTvBox.name} price={`${tvBoxpriceEstablishment},-`} />
            )}
            {modem && <ShoppingSummary.Item text={"Etablering " + modem.name} price={`${modemPrice},-`} />}
          </>
        ) : (
          <ShoppingSummary.Item text="Etablering" price={`0,-`} />
        )}
        {selectedShipping && (
          <ShoppingSummary.Item
            text="Frakt"
            price={`${shippingPrice},-`}
            secondaryText={selectedShipping?.description}
          />
        )}
        <ShoppingSummary.Item
          text="Total engangspris"
          price={`${totalPrice()},-`}
          highlight
          secondaryText="Belastes på din neste faktura"
        />
      </ShoppingSummary.List>
    </>
  );
}
