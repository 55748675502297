import { useQuery } from "@apollo/client";
import { Flex } from "@telia/teddy";
import { Button, Container, Paragraph } from "@telia-no-min-side/components";
import { format, uri } from "@telia-no-min-side/utils";
import { useLocation } from "react-router-dom";
import { usePrefetchLazyQuery } from "src/hooks/usePrefetchQuery";
import { isPricePlanMobileBroadband } from "src/util/format-subscription/isPricePlanMobileBroadband";
import { LINE_DISCOUNT_GROUP } from "src/util/format-subscription/variables";
import { ErrorMessage } from "./components/ErrorMessage";
import { NewLinesCard } from "./components/LinesInfoCard";
import { SkeletonPage } from "./components/Skeleton";
import { SubscriptionCardContainer } from "./components/styled";
import { SubscriptionCardWithDetails } from "./subscription-card-with-details";
import { getViaplayOfferingMonthlyCharge, isViaplayTotalPriceplan } from "util/format-subscription/isViaplay";
import { AccountsQueryQuery } from "gql/graphql";
import { useMobileAccount } from "hooks/useMobileAccount";
import { Breadcrumb } from "components/Breadcrumb";
import { ACCOUNTS_QUERY } from "./graphql/accountsQuery";
import { SUBSCRIPTION_ADD_QUERY } from "../mobile-subscription-add";
import { PageContainer } from "components/PageContainer";

type Accounts = NonNullable<AccountsQueryQuery["accounts"]>[0];
type SubscriptionData = NonNullable<Accounts["subscriptions"]>[0];

export function MobileSubscriptionAccountOverview() {
  const accounts = useQuery(ACCOUNTS_QUERY);
  const { accountId: selectedAccountId, isLoading: accountSelectorIsLoading } = useMobileAccount();
  const location = useLocation();
  const [prefetchAddSub, prefetchedAddSub] = usePrefetchLazyQuery(SUBSCRIPTION_ADD_QUERY, {
    variables: { accountId: selectedAccountId || "" },
  });

  if (accounts.error) {
    return (
      <ErrorMessage
        isLoading={accounts.loading}
        refetch={() => accounts.refetch()}
        dataTrackingId="mobile-dashboard-error-container"
      />
    );
  }
  if ((accounts.loading && !accounts.data) || accountSelectorIsLoading) {
    return <SkeletonPage />;
  }

  const account = accounts.data?.accounts?.find((account) => account.id == selectedAccountId);
  const subscriptions = account?.subscriptions;
  const mobileSubscriptions = subscriptions?.filter((sub) => !isPricePlanMobileBroadband(sub.userOffering?.pricePlan));

  if (selectedAccountId === undefined && !accountSelectorIsLoading) {
    return <ErrorMessage />;
  }
  if (!mobileSubscriptions?.length) {
    return (
      <Container flexDirection="column" maxWidth="sm" padding>
        <Paragraph>Du har ingen mobilabonnement på denne kontoen.</Paragraph>
        <div>
          <Button
            tag="a"
            isInternalNavigation
            onMouseEnter={() => {
              if (selectedAccountId && !prefetchedAddSub.data && !prefetchedAddSub.loading) {
                prefetchAddSub();
              }
            }}
            href={uri.minSideFixed(`${location.pathname}/legg-til/${selectedAccountId}`)}
            icon="plus"
          >
            Kjøp abonnement
          </Button>
        </div>
      </Container>
    );
  }

  const getPrice = (subscription: SubscriptionData) => {
    const originalPrice = subscription.userOffering?.originalPrice?.amount || 0;
    if (subscription.userOffering && isViaplayTotalPriceplan(subscription.userOffering?.pricePlan)) {
      const viaplayTotalMonthlyCharge = getViaplayOfferingMonthlyCharge(subscription.additionalProducts)?.amount || 0;
      return originalPrice - viaplayTotalMonthlyCharge;
    }
    return originalPrice;
  };

  const linesSubscriptions = mobileSubscriptions
    ?.filter(
      (subscription) =>
        subscription.userOffering?.additionalProperties?.some(
          (additionalProperty) => additionalProperty?.value === LINE_DISCOUNT_GROUP
        )
    )
    .sort((a, b) => {
      const rankDiff = (a.subscriptionRank || 0) - (b.subscriptionRank || 0);
      if (rankDiff === 0) {
        // if ranks are the same, sort by price
        const aPrice = getPrice(a);
        const bPrice = getPrice(b);

        return bPrice - aPrice;
      }
      return rankDiff;
    });

  const hasMultipleSubs = mobileSubscriptions.length > 1;
  const nonLinesSubscriptions = mobileSubscriptions?.filter(
    (subscription) =>
      !linesSubscriptions?.some((lineSub) => lineSub.phoneNumber.localNumber === subscription.phoneNumber.localNumber)
  );

  const totalOriginalPrice = mobileSubscriptions?.reduce((total, subscription) => {
    const amount = subscription.userOffering?.originalPrice?.amount || 0;
    return total + amount;
  }, 0);

  const totalPriceWithDiscount = mobileSubscriptions?.reduce((totalDiscount, subscription) => {
    const amount = subscription.userOffering?.monthlyCost?.amount || 0;
    return totalDiscount + amount;
  }, 0);
  const hasDiscount = Boolean(
    totalOriginalPrice && totalPriceWithDiscount && totalOriginalPrice > totalPriceWithDiscount
  );
  const discountAmount = hasDiscount ? totalOriginalPrice - totalPriceWithDiscount : 0;
  const hasLineDiscount = linesSubscriptions.length > 1;

  return (
    <PageContainer>
      <Flex direction="column" width="100%" maxWidth="600px" gap="20px">
        <Breadcrumb />

        {linesSubscriptions &&
          selectedAccountId &&
          linesSubscriptions.map((subscription, index) => (
            <SubscriptionCardContainer
              flexDirection="row"
              padding={"bottom"}
              key={`${subscription.phoneNumber.localNumber}-sub-card-container`}
            >
              <SubscriptionCardWithDetails
                hasMultipleSubs={hasMultipleSubs}
                isLines={true}
                index={index}
                key={`${subscription.phoneNumber.localNumber}-sub-card-details`}
                subscription={subscription}
                lastFamilySubscription={linesSubscriptions.length === index + 1}
                accountId={selectedAccountId}
              />
            </SubscriptionCardContainer>
          ))}

        {nonLinesSubscriptions &&
          selectedAccountId &&
          nonLinesSubscriptions.map((subscription, index) => (
            <SubscriptionCardContainer
              flexDirection="row"
              padding="bottom"
              key={`${subscription.phoneNumber.localNumber}-sub-card-container`}
            >
              <SubscriptionCardWithDetails
                hasMultipleSubs={hasMultipleSubs}
                isLines={false}
                index={index}
                key={`${subscription.phoneNumber.localNumber}-sub-card-details`}
                subscription={subscription}
                lastFamilySubscription={nonLinesSubscriptions.length === index + 1}
                accountId={selectedAccountId}
              />
            </SubscriptionCardContainer>
          ))}

        <SubscriptionCardContainer flexDirection="row" padding="bottom">
          <Container flexDirection="row" maxWidth="md">
            <Container data-tracking-id="mobile-subscription-overview-total-price">
              <Paragraph whiteSpace="nowrap">
                <em>Totalpris</em>
              </Paragraph>
              {hasDiscount && (
                <Paragraph whiteSpace="nowrap">
                  <em>Din rabatt</em>
                </Paragraph>
              )}
            </Container>
            <Container alignItems="end" justifyContent="end">
              <Paragraph isFaded>
                {hasDiscount && (
                  <span
                    data-tracking-id="mobile-subscription-overview-total-price-original"
                    style={{ textDecoration: "line-through", marginRight: ".5rem" }}
                  >
                    {format.simplePriceText(totalOriginalPrice, "md.")}
                  </span>
                )}
                <span data-tracking-id="mobile-subscription-overview-total-price-discounted">
                  {format.simplePriceText(totalPriceWithDiscount, "md.")}
                </span>
              </Paragraph>

              {hasDiscount && <Paragraph isFaded>{format.simplePriceText(discountAmount, "md.")}</Paragraph>}
            </Container>
          </Container>
        </SubscriptionCardContainer>

        <SubscriptionCardContainer flexDirection="row" padding="bottom">
          <Container justifyContent="end" alignItems="end">
            <Button
              tag="a"
              isInternalNavigation
              onMouseEnter={() => {
                if (selectedAccountId && !prefetchedAddSub.data && !prefetchedAddSub.loading) {
                  prefetchAddSub();
                }
              }}
              href={uri.minSideFixed(`${location.pathname}/legg-til/${selectedAccountId}`)}
              icon="plus"
              data-tracking-id="mobile-subscription-overview-add-more"
            >
              Legg til flere
            </Button>
          </Container>
        </SubscriptionCardContainer>
        <Container gap="lg" flexDirection="column">
          {hasMultipleSubs && hasLineDiscount && <NewLinesCard />}
        </Container>
      </Flex>
    </PageContainer>
  );
}
