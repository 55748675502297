import { Button, Container, Heading } from "@telia-no-min-side/components";
import { track } from "@telia-no-min-side/utils";
import { useFlexOrder } from "pages/flex/context/FlexOrderProvider";
import { SelectCard } from "pages/flex/styles/style";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = {
  requirementSelectHandler: ({ productId }: { productId: number }) => void;
};

export function ShowDontNeedNewEquipment({ requirementSelectHandler }: Props) {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { pageSelectedRequirements, setQuantityExtender } = useFlexOrder();

  return (
    <SelectCard isSelected={pageSelectedRequirements?.some((requirement) => requirement.productId === -1)}>
      <Container gap flexDirection="row" floatContent="center" justifyContent="space-between">
        <Heading tag="h2" variant="title-100" removeMargin>
          Jeg trenger ikke nytt utstyr
        </Heading>
        <Button
          size="sm"
          variant={
            pageSelectedRequirements?.some((requirement) => requirement.productId === -1) ? "primary" : "secondary"
          }
          onClick={() => {
            pushGenericTrackingEvent({
              ui_item_action: TRACK_EVENT.ITEM_ACTION.SELECT_ITEM,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FLEX,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.DONT_NEED_NEW_EQUIPMENT,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.SELECT,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
            });
            setQuantityExtender(0);
            requirementSelectHandler({ productId: -1 });
          }}
        >
          {pageSelectedRequirements?.some((requirement) => requirement.productId === -1) ? "Valgt" : "Velg"}
        </Button>
      </Container>
    </SelectCard>
  );
}
