import { ApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import { SubscriptionData } from "gql/graphql";

type SubscriptionStatus = Pick<SubscriptionData, "subscriptionStatus" | "subscriptionStatusDate" | "statusReasonCode">;

export function updateSubscriptionStatus(
  client: ApolloClient<object>,
  phoneNumber: string,
  newStatus: SubscriptionStatus
) {
  client.cache.modify({
    id: client.cache.identify({ __typename: "SubscriptionData", phoneNumber: { localNumber: phoneNumber } }),
    fields: {
      subscriptionStatus() {
        return newStatus.subscriptionStatus;
      },
      subscriptionStatusDate() {
        return dayjs().format("YYYY-MM-DD");
      },
      statusReasonCode() {
        return newStatus.statusReasonCode;
      },
    },
  });
}
