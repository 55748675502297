import { emailRegex } from "../validEmail";

export const validationPatterns = {
  customerId: {
    value: /^\d{8,9}$/,
    message: "Kundenummer må være 8 eller 9 siffer",
  },
  email: {
    value: emailRegex,
    message: "Ugyldig e-postadresse",
  },
  phoneNumber: {
    value: /^(0047[2-9])?([0-9]{7,8})$/,
    message:
      "Telefonnummer må inneholde 8 eller 12 siffer og kan ikke ha mellomrom. Eksempel 8 siffer: 99999999 / Eksempel 12 siffer: 004799887766",
  },
  postalCode: {
    value: /^\d{4}$/,
    message: "Postnummer må være 4 siffer. Eksempel: 0167",
  },
  numbersOnly: {
    value: /^\d+$/,
    message: "Verdien må kun være sifre",
  },
};
