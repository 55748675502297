import { types } from "@telia-no-min-side/utils";
import { Location } from "react-router-dom";
import { productId } from "util/productIds";

export function getRemoteControlPurchaseOption(
  options?: types.fixed.ProductGroupWithShipping
): types.fixed.ProductGroupSingleProductWithShipping | undefined {
  if (!options) {
    return;
  }

  return options.find((option) => option.productId === productId.remoteControl);
}

const BoxNames = {
  teliaBox: "Telia Box",
  mikro: "TV box Mikro",
};

const HashElements = {
  teliaBox: "teliabox",
  mikro: "mikro",
};

export function getBoxNameFromRoute(location: Location): string | undefined {
  if (location.hash.includes(HashElements.teliaBox)) return BoxNames.teliaBox;
  if (location.hash.includes(HashElements.mikro)) return BoxNames.mikro;

  return undefined;
}
