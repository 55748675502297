import React, { ReactNode } from "react";
import { StyledBackdrop } from "./style";

type Color = "transparent" | "black";

export type BackdropProps = {
  onClick: () => void;
  isVisible: boolean;
  children?: ReactNode;
  fadeIn?: boolean;
  color?: Color;
  zIndex?: number;
};

/**
 * @deprecated Use Teddy components instead
 */
export function Backdrop(props: BackdropProps) {
  const { onClick, color = "black", isVisible, children, zIndex, fadeIn } = props;
  return (
    <StyledBackdrop isVisible={isVisible} color={color} onClick={onClick} fadeIn={fadeIn} zIndex={zIndex}>
      <div onClick={(e) => e.stopPropagation()} tabIndex={-1}>
        {children}
      </div>
    </StyledBackdrop>
  );
}
