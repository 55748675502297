import { Container, Heading } from "@telia-no-min-side/components";
import { types } from "@telia-no-min-side/utils/";
import dayjs from "dayjs";
import { PreviousInvoiceCard } from "../components/PreviousInvoiceCard";
import { InvoiceCardSkeleton } from "../invoice-card/Skeleton";

type PreviousInvoiceListProps = {
  invoices?: types.fixed.InvoiceHistoryItemExtended[];
  isLoading: boolean;
};

function getPaidText(invoice: types.fixed.InvoiceHistoryItemExtended, currentDate: dayjs.Dayjs) {
  if (invoice.paid && invoice.paidDate > 0) {
    return `Betalt ${dayjs.unix(invoice.paidDate).format("D. MMMM")}`;
  }
  if (invoice.paid) {
    return "Betalt";
  }
  if (dayjs.unix(invoice.dueDate).add(1, "day") > currentDate) {
    return `Forfall ${dayjs.unix(invoice.dueDate).format("D. MMMM")}`;
  }
  return `Forfalt ${dayjs.unix(invoice.dueDate).format("D. MMMM")}`;
}

export function PreviousInvoiceListFixed({ invoices, isLoading }: PreviousInvoiceListProps) {
  const currentTime = dayjs();
  return (
    <Container padding="top" flexDirection="column" gap>
      <Heading tag="h2" variant="title-100">
        Din fakturaoversikt
      </Heading>
      {isLoading ? (
        <>
          <InvoiceCardSkeleton />
          <InvoiceCardSkeleton />
          <InvoiceCardSkeleton />
          <InvoiceCardSkeleton />
        </>
      ) : (
        invoices?.map((item, index) => (
          <PreviousInvoiceCard
            invoiceId={item.invoiceId.toString()}
            invoiceDate={dayjs.unix(item.invoiceDate).format("MMMM YY")}
            paidText={index === 0 ? getPaidText(item, currentTime) : undefined}
            amount={item.amount}
            paid={index === 0 ? item.paid : true}
            key={item.invoiceId}
            lozenge={index === 0 && !item.paid ? { label: "Gjeldende faktura", status: "communication" } : undefined}
          />
        ))
      )}
    </Container>
  );
}
