import { Card, Paragraph } from "@telia-no-min-side/components";
import { AvtalegiroText } from "./styles/AvtalegiroCard.styled";

export const WithAvtalegiroCard = () => {
  return (
    <Card maxWidth="xs" backgroundColor="light-purple">
      <Paragraph>Du har AvtaleGiro</Paragraph>
      <AvtalegiroText>
        Du har AvtaleGiro som automatisk betaler fakturaen. Det vil si at banken sørger for at regningene betales
        direkte fra din konto på forfallsdato.
      </AvtalegiroText>
    </Card>
  );
};
