import { Container } from "@telia-no-min-side/components";

const viaplayImages = [
  { url: "https://www.telia.no/globalassets/system/logo-kanaler/viaplay-fixed-on-light.svg", height: "30" },
  { url: "https://www.telia.no/globalassets/tv/viaplay/premier-league-logo.svg", height: "30" },
  { url: "https://www.telia.no/globalassets/tv/viaplay/formel-1-logo.png", height: "20" },
];

type Props = {
  subscriptionName: string;
};

export function ViaplayTotalImages({ subscriptionName }: Props) {
  return (
    <Container gap flexDirection="column" alignItems="flex-start">
      <Container gap="lg" flexDirection="row" alignItems="center">
        {viaplayImages.map((image) => (
          <img key={`${image.url}-peak-content-${subscriptionName}`} src={image.url} alt="logo" height={image.height} />
        ))}
      </Container>
    </Container>
  );
}
