import { AdditionalProduct, OfferingPriceCodes, OfferingPrice, Maybe } from "../../gql/graphql";

export function getDataAmount(
  additionalProducts: AdditionalProduct[],
  offeringPrices: Maybe<Maybe<OfferingPrice>[]> | undefined
) {
  // Base data
  const currentDataAmount = (offeringPrices || [])
    .find(
      (offerings) => offerings?.offeringPrices?.find((offering) => offering?.code === OfferingPriceCodes.DataIncluded)
    )
    ?.offeringPrices?.find((offering) => offering?.code === OfferingPriceCodes.DataIncluded)?.amount;

  // Additional data from summer campaign

  const doubleDataProduct = additionalProducts?.find(
    (additionalProduct) => additionalProduct.shortName?.toLowerCase().includes("dobbel data")
  );

  const isSummerCampaignActive = doubleDataProduct?.presentation?.active;

  const summerCampaignDataAmount = doubleDataProduct?.productTerms?.find(
    (term) => term?.code === OfferingPriceCodes.DataIncluded
  )?.amount;
  const summerCampaignDataAmountUnit = doubleDataProduct?.productTerms?.find(
    (term) => term?.code === OfferingPriceCodes.DataIncluded
  )?.monetaryUnit;

  // Additional data from active data product

  const activeDataIncludedProduct = additionalProducts?.find(
    (additionalProduct) =>
      additionalProduct.presentation?.active &&
      additionalProduct.productTerms?.some((productTerm) => productTerm?.code === OfferingPriceCodes.DataIncluded)
  );

  const activeDataIncludedProductAmount = activeDataIncludedProduct?.productTerms?.find(
    (term) => term?.code === OfferingPriceCodes.DataIncluded
  )?.amount;
  const activeDataIncludedProductUnit = activeDataIncludedProduct?.productTerms?.find(
    (term) => term?.code === OfferingPriceCodes.DataIncluded
  )?.monetaryUnit;

  // Total
  const dataAmountTotal =
    Number(currentDataAmount) + Number(summerCampaignDataAmount || activeDataIncludedProductAmount);
  const formattedDataAmountTotal = `${dataAmountTotal} ${
    summerCampaignDataAmountUnit || activeDataIncludedProductUnit
  }`;

  return {
    currentDataAmount,
    isSummerCampaignActive,
    summerCampaignDataAmount,
    summerCampaignDataAmountUnit,
    activeDataIncludedProductAmount,
    activeDataIncludedProductUnit,
    dataAmountTotal,
    formattedDataAmountTotal,
  };
}
