import { useSWRConfig } from "swr";
import { axiosPost } from "src/services/axios/axios";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useTpnPost() {
  const { mutate } = useSWRConfig();
  async function postTpn(url: string, requestData?: Record<string, unknown>) {
    const response = await axiosPost<unknown, Record<string, unknown>>(url, requestData);
    await mutate(ICX_REQUEST.URL_SAFE_ON_NET);

    return response;
  }

  return {
    postTpn,
  };
}
