import { Alert } from "@telia-no-min-side/components";
import { Subscription } from "../types";
import { hasRole } from "util/format-subscription/hasRole";
import { isOlderThan } from "@telia-no-min-side/utils";
import { getLegalOwnerName } from "util/format-subscription/getLegalOwnerName";
import { UserRole } from "gql/graphql";

type Props = {
  subscription: Subscription;
};

export function NotLegalOwnerWarning({ subscription }: Props) {
  const isLegalOwner = hasRole(UserRole.LegalOwner, subscription.roles);

  if (isLegalOwner) {
    return null;
  }

  // Should be shown for only users over 18, users under 18 handled in InsufficientAgeWarning component
  const isUnder18 = !isOlderThan(18, subscription.productUser?.birthDate || "");

  if (isUnder18) {
    return null;
  }

  const legalOwnerName = getLegalOwnerName(subscription.legalOwner, "firstName");

  return (
    <Alert
      kind="info"
      icon="info-filled"
      isOpen
      canClose={false}
      title="Du kan ikke endre abonnementet"
      text={`Du kan ikke endre abonnementet selv, men eier av abonnementet ${legalOwnerName} kan endre det ved å logge inn med sin egen bruker på Min Side. `}
    />
  );
}
