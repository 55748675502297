import { ReactElement } from "react";
import { Card, Container } from "@telia-no-min-side/components";
import { Box, Text, Link, List, Icon, IconName } from "@telia/teddy";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

type Props = {
  hideInfoBox?: boolean;
  shortDescription?: boolean;
};

const features: { icon: IconName; shortDescription: string; longDescription: ReactElement }[] = [
  {
    icon: "infinite",
    shortDescription: "Ubegrenset lagring",
    longDescription: (
      <>
        <strong>Ubegrenset lagring</strong> - Lagre så mye du vil, helt kostnadsfritt.
      </>
    ),
  },
  {
    icon: "conversation",
    shortDescription: "Alltid tilgjengelig",
    longDescription: (
      <>
        <strong>Alltid tilgjengelig</strong> - Tilgang fra flere enheter, automatisk backup, enkel deling.
      </>
    ),
  },
  {
    icon: "secured-1",
    shortDescription: "Trygg lagring",
    longDescription: (
      <>
        <strong>Trygg lagring</strong> - Alle data lagres på norske servere i henhold til norsk personvernlov.
      </>
    ),
  },
  {
    icon: "save",
    shortDescription: "Lagre alt på ett sted",
    longDescription: (
      <>
        <strong>Lagre alt på ett sted</strong> - Støtter både bilder, videoer, viktige dokumenter og annet.
      </>
    ),
  },
];

export function SkyProductDescription({ hideInfoBox = false, shortDescription = true }: Props) {
  return (
    <Box mt="200">
      <List gapSize="small" mb="gutter-md" pt="gutter-xs">
        {features.map((feature) => (
          <List.Item key={feature.icon}>
            <List.Illustration>
              <Icon name={feature.icon} />
            </List.Illustration>
            <List.Content>{shortDescription ? feature.shortDescription : feature.longDescription}</List.Content>
          </List.Item>
        ))}
      </List>
      {!hideInfoBox && (
        <Container padding="top">
          <Card backgroundColor="blue" showShadow={false} hideBorder removeMinHeight>
            <Text variant="additional-100">
              Last ned i{" "}
              <Link variant="text" href={EXTERNAL_LINK.APP_STORE_TELIA_SKY} target="_blank">
                App Store
              </Link>{" "}
              og{" "}
              <Link variant="text" href={EXTERNAL_LINK.GOOGLE_PLAY_TELIA_SKY} target="_blank">
                Google Play
              </Link>
              , eller bruk Telia Sky på web. Logg inn med det samme brukernavnet og passordet du bruker på Min Side for
              å komme i gang.
            </Text>
          </Card>
        </Container>
      )}
    </Box>
  );
}
