import { AgreementType, GetSubscriptionDataQuery } from "gql/graphql";
import { Order } from "src/store/OrderToastContext";
import { getJuniorSummerCampaignDataForCurrentSubscription } from "pages/mobile/mobile-subscription-change/change-mobile/utils/getJuniorSummerCampaignData";
import { getConvergentBenefitAmount } from "util/format-subscription/getConvergentBenefitAmount";
import { getDownloadSpeed } from "util/format-subscription/getDownloadSpeed";
import { getIncludedPropertiesNEW } from "util/format-subscription/getIncludedProperties";
import { getName } from "util/format-subscription/getName";
import { getOfferingPrices } from "util/format-subscription/getOfferingPrices";
import { hasUnlimitedData } from "util/format-subscription/hasUnlimitedData";
import { isViaplay } from "util/format-subscription/isViaplay";
import { BUCKET_SUBCRIPTIONS, MAX_SPEED_SUBSCRIPTIONS } from "util/format-subscription/variables";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";

export const useSubscriptionData = (
  subscription: GetSubscriptionDataQuery["subscription"] | undefined,
  orders: Order[]
) => {
  if (!subscription) return { hasSubscription: false };

  const isB2B = subscription?.agreementType === AgreementType.Business;
  const isUnlimitedDataSubscription = hasUnlimitedData(subscription?.userOffering?.additionalProperties);
  const convergentBenefit = getConvergentBenefitAmount(subscription?.additionalProducts);
  const includedProperties = getIncludedPropertiesNEW(subscription?.userOffering?.additionalProperties);

  const hasJuniorSummerCampaign = isJuniorSummerCampaign(subscription?.userOffering?.pricePlan || "");
  const offeringPrices = getOfferingPrices(subscription?.userOffering?.offeringPrices);
  const downloadSpeed = getDownloadSpeed(offeringPrices);
  const displayedDownloadSpeed = convergentBenefit?.hasDoubleSpeed ? convergentBenefit.amount : downloadSpeed;

  const subscriptionName =
    getName(offeringPrices, subscription?.userOffering?.shortName, convergentBenefit?.hasDoubleData) || "";

  const isViaplaySubscription = !!isViaplay(subscriptionName);
  const isBucketSubscription = BUCKET_SUBCRIPTIONS.includes(subscriptionName);

  const canGetDoubleSpeed = !isBucketSubscription && !MAX_SPEED_SUBSCRIPTIONS.includes(subscriptionName);
  const hasDoubleSpeedOrDataBenefit = !!convergentBenefit?.hasDoubleSpeed || !!convergentBenefit?.hasDoubleData;
  const showDoubleDataOrSpeedInfo = (canGetDoubleSpeed || isBucketSubscription) && hasDoubleSpeedOrDataBenefit;

  const hasOnGoingOrder = orders.some((order) => order.phoneNumber === subscription.phoneNumber.localNumber);
  const { formattedBaseDataAmount, formattedTotalDataAmount } = getJuniorSummerCampaignDataForCurrentSubscription(
    offeringPrices,
    subscription.additionalProducts
  );

  const getSubHeading = () => {
    if (!!downloadSpeed && isUnlimitedDataSubscription) return `${displayedDownloadSpeed} Mbps - Ubegrenset data`;
    if (isUnlimitedDataSubscription) return "Ubegrenset data";
    if (downloadSpeed) return `${displayedDownloadSpeed} Mbps`;
    return null;
  };
  const subHeading = getSubHeading();

  return {
    accountId: subscription.account.id,
    phoneNumber: subscription.phoneNumber.localNumber,
    hasSubscription: true,
    hasFamilyDiscount: (subscription?.subscriptionRank ?? 0) > 1,
    monthlyPrice: subscription?.userOffering?.monthlyCost?.amount,
    originalMonthlyPrice: subscription?.userOffering?.originalPrice?.amount,
    hasDoubleSpeed: convergentBenefit.hasDoubleData,
    convergentBenefit,
    includedProperties,
    hasJuniorSummerCampaign,
    subscriptionName,
    isViaplaySubscription,
    canGetDoubleSpeed,
    showDoubleDataOrSpeedInfo,
    hasOnGoingOrder,
    formattedBaseDataAmount,
    formattedTotalDataAmount,
    subHeading,
    isB2B,
  };
};
