import { graphql } from "gql";

export const B2B_FAMILY_DISCOUNT = graphql(`
  query b2BFamilyDiscountFlow {
    accounts {
      id
      agreementType
      subscriptions {
        userOffering {
          shortName
          pricePlan
          originalPrice {
            amount
          }
          offeringPrices {
            offeringPrices {
              code
              amount
            }
          }
          monthlyCost {
            amount
            reasons {
              code
              amount
            }
          }
        }
        subscriptionRank
        productUser {
          firstName
        }
        phoneNumber {
          localNumber
        }
        additionalProducts {
          code
          presentation {
            active
          }
          characteristic {
            config {
              name
              value
            }
          }
        }
      }
    }
  }
`);
