import React from "react";
import { Skeleton } from "../Skeleton";
import { StyledToggleButtonSkeleton } from "./style";
import { ToggleButtonProps } from ".";

export type ToggleButtonOnChange<T> = (event: React.BaseSyntheticEvent, newValue?: T) => void;

export type ToggleButtonSkeletonProps<T> = {
  /**
   * Remove left  margin
   */
  removeLeftMargin?: boolean;
  /**
   * Remove right margin
   */
  removeRightMargin?: boolean;
} & Pick<ToggleButtonProps<T>, "variant">;

export function ToggleButtonSkeleton<T>(props: ToggleButtonSkeletonProps<T>) {
  const { removeLeftMargin, removeRightMargin } = props;

  return (
    <StyledToggleButtonSkeleton
      removeMinHeight
      variant={props.variant}
      removeLeftMargin={removeLeftMargin}
      removeRightMargin={removeRightMargin}
      tag={"button"}
      showSelectedRing={false}
    >
      <Skeleton variant="text" color="dark" />
    </StyledToggleButtonSkeleton>
  );
}
