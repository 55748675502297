import { graphql } from "src/gql";

export const switchAgreementFragment = graphql(`
  fragment SwitchAgreement on SwitchAgreement {
    id
    status
    phoneNumber {
      localNumber
    }
    canPerformSwitch
    remainingMandatoryInstallments
    insurance {
      name
      type
    }
    product {
      id
      name
      productNumber
      imageUrl
    }
    costToReplace {
      value
    }
  }
`);
