import { ComponentProps } from "react";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { useInvoiceUrl } from "src/store/hooks/useInvoiceUrl";
import { useFetchProductsAdditionalInfo } from "src/api/fixed";
import { NavigationButtons } from "components/NavigationButtons";

export function QuickLinks() {
  const invoice = useInvoiceUrl();
  const productsAdditionalInfo = useFetchProductsAdditionalInfo();

  const fixedLinks: ComponentProps<typeof NavigationButtons>["buttonsData"] = [
    {
      URL: invoice.url || "#",
      text: "Faktura",
      icon: "invoice",
      isLoading: invoice.loading,
      hide: !invoice?.url,
    },
    {
      URL: EXTERNAL_LINK.TELIA_PLAY,
      text: "Telia Play",
      icon: "play",
    },
  ];

  if (productsAdditionalInfo.data?.hasAlaCarte) {
    fixedLinks.push({
      URL: EXTERNAL_LINK.TELIA_PLAY_CHANNELPICKER,
      text: "Valgmenyen",
      icon: "switch-arrows",
    });
  }

  return <NavigationButtons buttonsData={fixedLinks} />;
}
