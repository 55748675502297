import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchToBeReturned() {
  const { error, ...rest } = useAxiosSWR<types.fixed.ToBeReturned>(ICX_REQUEST.URL_PRODUCTS_TO_BE_RETURNED);

  return {
    isError: !!error,
    error,
    ...rest,
  };
}
