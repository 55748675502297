import PropTypes from "prop-types";

const { shape, number, bool, string, oneOfType, arrayOf, object } = PropTypes;

export const ipv4 = shape({
  Octet1: number,
  Octet2: number,
  Octet3: number,
  Octet4: number,
});

export const ipv4Supported = shape({
  Octet1: shape({
    CanChange: bool,
  }),
  Octet2: shape({
    CanChange: bool,
  }),
  Octet3: shape({
    CanChange: bool,
  }),
  Octet4: shape({
    CanChange: bool,
  }),
});

export const ipv4StringableOctets = shape({
  Octet1: oneOfType([number, string]),
  Octet2: oneOfType([number, string]),
  Octet3: oneOfType([number, string]),
  Octet4: oneOfType([number, string]),
});

export const updateStatus = shape({
  updating: bool,
  tag: string,
});

export const updateResponse = shape({
  response: oneOfType([string, object, number]),
  tag: string,
  error: bool,
  shown: bool,
});

export const staticInternalIpEntry = shape({
  Alias: string,
  MacAddress: string,
  IPAddress: ipv4StringableOctets,
});

export const device = shape({
  ModelDescription: string,
  DeviceType: string,
  IPAddress: string,
  NetworkState: string,
  CustomerReference: number,
  ID: number,
  SerialNumber: string,
  ParentSerialNumber: string,
  MacAddress1: string,
  MacAddress2: string,
  Status: string,
  LastModifiedDateTime: string,
  ModelID: number,
  Settings: shape({
    Alias: string,
    BridgeMode: shape({
      Enabled: bool,
    }),
    Upnp: shape({
      Enabled: bool,
    }),
    Wifi2GHz: shape({
      Enabled: bool,
      Ssid: string,
      Password: string,
      SecurityProtocol: string,
      Mode: string,
      Bandwidth: string,
      Channel: string,
      SignalStrength: string,
    }),
    Wifi5GHz: shape({
      Enabled: bool,
      Ssid: string,
      Password: string,
      SecurityProtocol: string,
      Mode: string,
      Bandwidth: string,
      Channel: string,
      SignalStrength: string,
    }),
    BackhaulWifi: shape({
      ParametersSet: bool,
      Ssid: string,
      Password: string,
    }),
    PortForwarding: shape({
      PortForwardingRules: arrayOf(
        shape({
          Name: string,
          Protocol: string,
          Enabled: bool,
          ExternalFromPort: number,
          ExternalToPort: number,
          InternalFromPort: number,
          InternalToPort: number,
          InternalIP: ipv4,
        })
      ),
    }),
    Lan: shape({
      InternalGWIP: ipv4,
      NetworkMask: ipv4,
    }),
    Dhcp: shape({
      FromIP: ipv4,
      ToIP: ipv4,
      LeaseTime: number,
    }),
    Dmz: shape({
      Enabled: bool,
      IP: ipv4,
    }),
    StaticInternalIP: shape({
      StaticInternalIPEntries: arrayOf(staticInternalIpEntry),
    }),
    Alg: shape({
      SipAlgEnabled: bool,
      PptpAlgEnabled: bool,
      IpsecAlgEnabled: bool,
    }),
  }),
  SupportedSettings: shape({
    BridgeMode: shape({
      Supported: bool,
    }),
    Upnp: shape({
      Supported: bool,
    }),
    Lan: shape({
      Supported: bool,
      InternalGWIP: ipv4Supported,
      NetworkMask: ipv4Supported,
    }),
    Dhcp: shape({
      Supported: bool,
      MinLeaseTime: number,
      MaxLeaseTime: number,
      CanChangeLeaseTime: bool,
      FromIP: ipv4Supported,
      ToIP: ipv4Supported,
    }),
    Dmz: shape({
      Supported: bool,
      IP: ipv4Supported,
    }),
    Wifi5GHz: shape({
      Supported: bool,
      SsidMinLength: number,
      SsidMaxLength: number,
      PasswordMinLength: number,
      PasswordMaxLength: number,
      SecurityProtocols: arrayOf(string),
      SignalStrengths: arrayOf(string),
      BandwidthToChannelMaps: arrayOf(
        shape({
          Bandwidth: string,
          Channels: arrayOf(string),
        })
      ),
    }),
    Wifi2GHz: shape({
      Supported: bool,
      SsidMinLength: number,
      SsidMaxLength: number,
      PasswordMinLength: number,
      PasswordMaxLength: number,
      SecurityProtocols: arrayOf(string),
      SignalStrengths: arrayOf(string),
      BandwidthToChannelMaps: arrayOf(
        shape({
          Bandwidth: string,
          Channels: arrayOf(string),
        })
      ),
    }),
    BackhaulWifi: shape({
      Supported: bool,
    }),
    PortForwarding: shape({
      Supported: bool,
      PortRangeSupported: bool,
      MaxNumOfRules: number,
      BlacklistedPorts: arrayOf(number),
      Protocols: arrayOf(string),
    }),
    StaticInternalIP: shape({
      Supported: bool,
    }),
    Alg: shape({
      Supported: bool,
    }),
  }),
});
