import { useExpandableCard } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import {
  AdditionalInfoContainer,
  AddressHeadline,
  AddressText,
  AddressWrapper,
  BLOCKSPACE,
  CardHeader,
  CardHeaderButton,
  CardHeadlineWrapper,
  SelectedInvoiceLozenge,
} from "./styles/InvoiceCardHeader.styled";
import { ButtonContainer } from "./styles/common-styles";
import { DistributionCode, InvoiceType } from "src/gql/graphql";

type HeaderInfoProps = {
  selected: boolean;
  invoiceType?: InvoiceType;
  hasPoBoxAdress?: boolean;
  urbanAddressPreamble?: string[];
  poboxAddressPreabmle?: string[];
  emailAddress?: string | null;
  hasAvtalegiro?: boolean;
};

export const HeaderInfo = ({
  urbanAddressPreamble,
  selected,
  invoiceType,
  emailAddress,
  hasPoBoxAdress,
  poboxAddressPreabmle,
  hasAvtalegiro,
}: HeaderInfoProps) => {
  const { isOpen } = useExpandableCard();

  const monthlyPrice =
    invoiceType?.code === DistributionCode.Rg
      ? format.formatPrice(invoiceType.monthlyPrice) + "/md."
      : format.simplePriceText(invoiceType?.monthlyPrice || 0);

  if (selected) {
    return (
      <>
        <SelectedInvoiceLozenge label="Du har denne fakturatypen" status="communication" type="square" />
        <CardHeadlineWrapper>
          <CardHeader data-di-mask>{invoiceType?.name}</CardHeader>
          <CardHeader data-di-mask>{monthlyPrice}</CardHeader>
        </CardHeadlineWrapper>
        {invoiceType?.code === DistributionCode.Rg && !isOpen && (
          <>
            <AddressHeadline>Din papirfaktura blir sendt til denne adressen:</AddressHeadline>
            <AdditionalInfoContainer>
              <AddressWrapper data-di-mask>
                {hasPoBoxAdress
                  ? poboxAddressPreabmle?.map((text, index) => <AddressText key={index}>{text}</AddressText>)
                  : urbanAddressPreamble?.map((text, index) => <AddressText key={index}>{text}</AddressText>)}
              </AddressWrapper>
              <CardHeaderButton selected>Endre adresse</CardHeaderButton>
            </AdditionalInfoContainer>
          </>
        )}
        {invoiceType?.code === DistributionCode.Ee && !isOpen && (
          <>
            <AddressHeadline data-di-mask>
              {hasAvtalegiro
                ? "med AvtaleGiro"
                : `Din e-postfaktura blir sendt til denne e-postadressen: ${emailAddress}`}
            </AddressHeadline>
            <ButtonContainer>
              <CardHeaderButton>Endre e-post</CardHeaderButton>
            </ButtonContainer>
          </>
        )}
        {invoiceType?.code === DistributionCode.Ef && !isOpen && (
          <>{hasAvtalegiro ? <AddressHeadline data-di-mask>med AvtaleGiro</AddressHeadline> : <BLOCKSPACE />}</>
        )}
      </>
    );
  }
  return (
    <CardHeadlineWrapper>
      <CardHeader isOpen={isOpen} data-di-mask>
        {invoiceType?.name}
      </CardHeader>
      <CardHeader isOpen={isOpen} data-di-mask>
        {monthlyPrice}
      </CardHeader>
      {!isOpen && <CardHeaderButton>Opprett</CardHeaderButton>}
    </CardHeadlineWrapper>
  );
};
