import { Accordion, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";

export function SubBlockedAccordion(): JSX.Element {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Abonnementet mitt har blitt sperret - hva gjør jeg nå?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Har abonnementet ditt blitt sperret anbefaler vi at du betaler fakturaen direkte på Min Side - da vil det
          normalt bli åpnet igjen umiddelbart. Velger du å betale via vanlig nettbank tar det vanligvis 1-3 virkedager
          før innbetalingen registreres hos oss og abonnementet gjenåpnes. Behandlingstiden kan variere noe fra bank til
          bank. Husk at dersom abonnementet ditt har blitt stengt vil du også bli fakturert for et stengingsgebyr på
          490,-.
        </Paragraph>
        <Paragraph>
          For å få abonnementet ditt midlertidig gjenåpnet kan du også kontakte kundeservice. Hvis ikke det utestående
          beløpet blir betalt innen 4 virkedager vil tjenestene dine stenges igjen.
        </Paragraph>
        <Paragraph>
          Har kravet blitt sendt til inkasso må videre kommunikasjon tas med inkassoselskapet. Dersom ditt abonnement
          frakobles grunnet inkasso, vil du ved en eventuell retilkobling måtte betale etableringskostnader etter
          gjeldende priser.
        </Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
