import { Modal, Text, Button, Link, Icon, Notification } from "@telia/teddy";
import { types } from "@telia-no-min-side/utils";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

type Props = {
  status: types.fixed.ResponseStatusSubmitMultipleProducts;
  isOpen: boolean;
  setIsOpen: (show: boolean) => void;
  isBuyingRouter: boolean;
};

export function Receipt(props: Props) {
  const { status, isOpen, setIsOpen, isBuyingRouter } = props;

  function onCloseClick() {
    setIsOpen(false);
  }

  return (
    <Modal open={isOpen}>
      <Modal.Content>
        {status === "vellykket" ? (
          <>
            <Modal.Title>Takk for bestillingen!</Modal.Title>
            <Modal.Description>
              {isBuyingRouter && (
                <Notification variant="information">
                  <Notification.Heading as="h3">
                    <Notification.Icon>
                      Internett slutter å fungere på gammel router når du henter den nye.
                    </Notification.Icon>
                  </Notification.Heading>
                  <Notification.Text>
                    Internett vil automatisk flyttes til ny router når denne hentes hos Postnord. Den gamle routeren vil
                    på dette tidspunktet slutte å fungere.
                  </Notification.Text>
                </Notification>
              )}
              <Text mt="200">
                Vi har nå mottatt din bestilling. Utstyret sendes til deg per post og du mottar pakken i løpet av 3-7
                arbeidsdager.
              </Text>
              <Text mt="200">
                Angreretten forutsetter at du senest innen 14 dager etter at du mottar leveransen gir oss melding om
                dette (angrefrist). Skal du benytte deg av angreretten og sende inn en klage må du fylle ut{" "}
                <Link target="_blank" variant="text" href={EXTERNAL_LINK.CUSTOMER_SERVICE_TERMS_AND_CONDITIONS}>
                  angrerettskjema TV og internett
                </Link>{" "}
                og sende det til oss.
              </Text>
            </Modal.Description>
          </>
        ) : (
          <>
            <Modal.Title>Vi kan dessverre ikke fullføre din ordre</Modal.Title>
            <Modal.Description>
              <Text>
                Vennligst ta kontakt med kundeservice så snart som mulig.
                <Link target="_blank" variant="standalone" href={EXTERNAL_LINK.CUSTOMER_SERVICE_CONTACT_US}>
                  Kontakt kundeservice
                  <Icon name="arrow-right" />
                </Link>
              </Text>
            </Modal.Description>
          </>
        )}
        <Modal.Group variant="action">
          <Button variant="primary" size="sm" onClick={onCloseClick}>
            Lukk
          </Button>
        </Modal.Group>
      </Modal.Content>
    </Modal>
  );
}
