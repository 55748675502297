import { Link } from "@telia-no-min-side/components";
import { setSearchParams, uri } from "@telia-no-min-side/utils";
import { InvoiceGroup, InvoiceRow } from "src/gql/graphql";
import {
  DetailsAccordionItem,
  Dot,
  InvoiceProduct,
  InvoiceProductList,
  PriceCell,
  StyledAccordionBody,
  StyledAccordionHeader,
  SumCell,
  SumTextCell,
  TitleCell,
  TotalSumGrid,
} from "src/pages/invoice/components/details-element/style";
import { getPriceAmountText } from "src/util/priceFormatter";
import { getProductTypeColor } from "./DetailsElementMobile";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export type Props = {
  invoiceGroupItem: InvoiceGroup | null;
  isOpen: boolean | undefined;
  toggleIsOpen: () => void;
  phoneNumber?: string;
  invoiceId?: string | null;
  isProductUser: boolean;
};

const shouldSeeCallSpecificationForRow = (row: InvoiceRow | null) => {
  const isDataWithBlankQuantity = row?.usageType?.toLowerCase() === "data" && !row.quantity?.unit;

  if (!row?.service || row?.chargeType?.toLowerCase() !== "u" || isDataWithBlankQuantity) {
    return false;
  }

  return true;
};

export function SubDetailsElementMobile(props: Props) {
  const { invoiceGroupItem, isOpen, toggleIsOpen, phoneNumber, invoiceId, isProductUser } = props;

  if (!invoiceGroupItem) {
    return null;
  }

  const { name, totalAmount, invoiceRow } = invoiceGroupItem;

  return (
    <DetailsAccordionItem hideBorder={true} showShadow={false} onClick={toggleIsOpen} isOpen={isOpen}>
      <StyledAccordionHeader>
        <TitleCell as="h3" data-di-mask>
          {name}
        </TitleCell>
        {!isOpen && <PriceCell data-di-mask>{getPriceAmountText(totalAmount?.value, "kr")}</PriceCell>}
      </StyledAccordionHeader>
      <StyledAccordionBody>
        <InvoiceProductList>
          {invoiceRow
            ?.filter((item: InvoiceRow | null) => item?.displayOnInvoice)
            .map((detail: InvoiceRow | null, index) => {
              const quantityUnit = detail?.quantity?.unit || "stk.";
              const quantityText = detail?.quantity?.quantity ? `(${detail.quantity.quantity} ${quantityUnit})` : "";
              const queryParams = {
                phoneNumber,
                invoiceId,
              };

              return (
                <InvoiceProduct key={`${detail?.name}-${index}`}>
                  <TitleCell>
                    <Dot color={getProductTypeColor(detail?.totalAmount?.value ?? 0)} data-di-mask />
                    {shouldSeeCallSpecificationForRow(detail) && isProductUser ? (
                      <>
                        <Link
                          href={uri.minSideFixed(
                            `/faktura/detaljert-bruk${setSearchParams(queryParams)}&serviceCode=${detail?.service?.join(
                              ","
                            )}`
                          )}
                          isInternalNavigation
                        >
                          {detail?.name} {quantityText}
                        </Link>
                        {detail?.name && isStrex(detail.name) && (
                          <Link href={EXTERNAL_LINK.STREX} target="_blank">
                            Logg inn på strex.no for å se detaljer
                          </Link>
                        )}
                      </>
                    ) : (
                      <>
                        {detail?.name} {quantityText}
                      </>
                    )}
                  </TitleCell>
                  <PriceCell data-di-mask>{getPriceAmountText(detail?.totalAmount?.value, "kr")}</PriceCell>
                </InvoiceProduct>
              );
            })}
          <TotalSumGrid>
            <SumTextCell>Delsum</SumTextCell>
            <SumCell data-di-mask>{getPriceAmountText(totalAmount?.value, "kr")}</SumCell>
          </TotalSumGrid>
        </InvoiceProductList>
      </StyledAccordionBody>
    </DetailsAccordionItem>
  );
}

function isStrex(name: string) {
  return name.toLowerCase().includes("**strex");
}
