import { breakpoint } from "@telia/teddy";
import { ErrorBox } from "components/ErrorBox";
import { useMobileAccount } from "hooks/useMobileAccount";
import { INTERNAL_LINK } from "util/constants/internalLinks";

export function HasNoSubscriptionError() {
  const { accountId } = useMobileAccount();

  return (
    <ErrorBox
      title="Du har ikke abonnement hos oss"
      description="Finn det abonnementet som passer best for deg og dine."
      actionContent="Sjekk ut våre abonnement"
      href={`${INTERNAL_LINK.MOBILE.ADD_SUBSCRIPTION_PAGE}/${accountId}`}
      maxWidth={breakpoint.teddyBreakpointMd}
      openInSameTab
    />
  );
}
