import { Box } from "@telia/teddy";
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Accordion, Container, Heading } from "@telia-no-min-side/components";
import { graphql } from "src/gql";
import { OfferingAccordionItem } from "./OfferingAccordionItem";
import { Error } from "../../error";
import { SkeletonPage } from "./Skeleton";
import { getMobileOffersForSale } from "../mobile-subscription-change/change-mobile/utils/getMobileOffersForSale";
import { groupMobileOffersForSaleByCategory } from "../mobile-subscription-change/change-mobile/utils/groupMobileOffersForSaleByCategory";
import { getLineSubscriptions } from "../mobile-subscription-change/change-mobile/utils/getLineSubscriptions";
import { OfferGroup } from "../mobile-subscription-change/types";
import { OfferGroupPicker } from "../mobile-subscription-change/components/OfferGroupPicker";
import { isOfTypeOfferGroup } from "../mobile-subscription-change/change-mobile/utils/isOfTypeOfferGroup";
import { NewLinesCard } from "pages/mobile/mobile-subscription-account-overview/components/LinesInfoCard";

export const SUBSCRIPTION_ADD_QUERY = graphql(`
  query SubscriptionAdd($accountId: String!) {
    account(accountId: $accountId) {
      subscriptions {
        phoneNumber {
          localNumber
        }
        userOffering {
          pricePlan
          additionalProperties {
            name
            value
          }
          originalPrice {
            amount
          }
        }
      }
      id
      offerings {
        ...SubscriptionUserOffering
        pricePlan
        shortName
        weight
        commitmentTimeInMonths
        originalPrice {
          amount
        }
        additionalProperties {
          name
          value
        }
      }
    }
  }
`);

export function MobileSubscriptionAdd() {
  const { accountId, selectedOfferType } = useParams<{ accountId?: string; selectedOfferType?: OfferGroup }>();
  const [offerType, setOfferType] = useState<OfferGroup>("unlimitedData");
  const addSubscription = useQuery(SUBSCRIPTION_ADD_QUERY, {
    variables: { accountId: accountId || "" },
    notifyOnNetworkStatusChange: true,
    skip: !accountId,
  });

  useEffect(() => {
    // Select Offertab if selectedOfferType exist in query param
    if (isOfTypeOfferGroup(selectedOfferType)) {
      setOfferType(selectedOfferType);
    }
  }, [selectedOfferType]);

  if (addSubscription.loading && !addSubscription.data?.account) {
    return <SkeletonPage />;
  }
  if (!addSubscription.data?.account) {
    return <Error />;
  }

  const offersForSale = getMobileOffersForSale(addSubscription.data.account.offerings);
  const {
    juniorOffers,
    bucketOffers,
    unlimitedDataOffers,
    hasJuniorOffers,
    hasBucketOffers,
    hasUnlimitedDataOffers,
    isJuniorSummerCampaignActive,
  } = groupMobileOffersForSaleByCategory(offersForSale);

  const linesSubscriptions = getLineSubscriptions(addSubscription.data.account?.subscriptions);
  const hasLineDiscount = linesSubscriptions.length > 1;

  const offerGroups: { text: string; value: OfferGroup; available: boolean }[] = [
    {
      text: "Ubegrenset data",
      value: "unlimitedData",
      available: hasUnlimitedDataOffers,
    },
    {
      text: "Fast data",
      value: "bucket",
      available: hasBucketOffers,
    },
    {
      text: "Barn",
      value: "junior",
      available: hasJuniorOffers,
    },
  ];
  const availableOfferGroups = offerGroups.filter((group) => group.available);

  return (
    <Container padding showGoBackButton flexDirection="column">
      <Box pb={{ sm: "200", md: "400" }}>
        <Heading tag="h2" variant="title-200-light">
          Kjøp nytt abonnement
        </Heading>
      </Box>
      <OfferGroupPicker value={offerType} onChange={setOfferType} options={availableOfferGroups} />
      <Container flexDirection="column" gap="lg">
        {offerType === "unlimitedData" && (
          <Accordion gap="lg">
            {unlimitedDataOffers?.map((offering) => (
              <OfferingAccordionItem
                userOfferingFragment={offering}
                key={`${offering.shortName}-unlimitedData-new-${offering.weight}`}
                hasOneOrMoreSubs={hasLineDiscount}
              />
            ))}
          </Accordion>
        )}
        {offerType === "bucket" && (
          <Accordion gap="lg">
            {bucketOffers?.map((offering) => (
              <OfferingAccordionItem
                key={`${offering.shortName}-bucket-new-${offering.weight}`}
                userOfferingFragment={offering}
                hasOneOrMoreSubs={hasLineDiscount}
              />
            ))}
          </Accordion>
        )}
        {offerType === "junior" && (
          <Accordion gap="lg">
            {juniorOffers?.map((offering) => (
              <OfferingAccordionItem
                key={`${offering.shortName}-junior-new-${offering.weight}`}
                userOfferingFragment={offering}
                hasOneOrMoreSubs={hasLineDiscount}
                additionalText="For deg under 16 år"
              />
            ))}
          </Accordion>
        )}
        {hasLineDiscount && !(offerType === "junior" && isJuniorSummerCampaignActive) && <NewLinesCard />}
      </Container>
    </Container>
  );
}
