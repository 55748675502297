import React, { ReactNode, useRef, useState } from "react";
import { track } from "@telia-no-min-side/utils";
import { IconDefinition } from "@telia/styleguide";
import { v4 as uuid } from "uuid";
import { Card, CardBaseProps } from "../Card";
import { Content } from "./Content";
import { ExpandableCardContext } from "./Context";
import { Trigger } from "./Trigger";
ExpandableCard.Trigger = Trigger;
ExpandableCard.Content = Content;
export { useExpandableCard } from "./Context";
export type ExpandableCardProps = {
  /**
   * The id you want to associate with the AccordionItem.
   * By default, the component will generate a uniq id on its own.
   * This id is used by Accordion to handle which AccordionItem to expand.
   */
  id?: string;
  /**
   * Force open or closed state for the component.
   * If not present, the component will control this state on its own.
   */
  isOpen?: boolean;

  /**
   * SetInitial open state
   */
  initialIsOpen?: boolean;

  compact?: boolean;

  /**
   * Callback function fired when accordion is clicked.
   *
   * @param {React.BaseSyntheticEvent}
   */
  onClick?: (e: React.BaseSyntheticEvent, openIde: string) => void;

  tracking?: {
    open: track.GenericEvent;
    close: track.GenericEvent;
  };
  icon?: IconDefinition;

  /**
   * In order for the component to work correctly,
   * you need to only use AccordionHeader and AccordionBody as child components
   */
  children?: ReactNode;

  className?: string;
} & Omit<CardBaseProps, "removeMinHeight">;

export function ExpandableCard(props: ExpandableCardProps): JSX.Element {
  const {
    children,
    tracking,
    isOpen: propsIsOpen,
    className,
    id: propsId,
    initialIsOpen,
    onClick,
    compact = false,
    icon = "chevron-down",
    ...cardProps
  } = props;
  const [isOpenInternal, setIsOpenInternal] = useState(initialIsOpen || false);
  const generatedIdRef = useRef(uuid());
  const id = propsId ?? generatedIdRef.current;
  const isOpen = typeof propsIsOpen === "boolean" ? propsIsOpen : isOpenInternal;

  function onItemClick(e: React.BaseSyntheticEvent) {
    if (onClick) {
      onClick(e, id);
      if (e.defaultPrevented) return;
    }
    setIsOpenInternal((prev) => !prev);
  }

  return (
    <ExpandableCardContext.Provider
      value={{
        id,
        isOpen,
        onClick: onItemClick,
        tracking,
        icon,
        compact,
      }}
    >
      <Card padding="unset" showHoverShadow {...cardProps} removeMinHeight className={className}>
        {children}
      </Card>
    </ExpandableCardContext.Provider>
  );
}
