import { Container } from "@telia-no-min-side/components";
import { Button, Card, Text, TextField, TextSpacing } from "@telia/teddy";

type Props = {
  phoneNumber: string;
  error: string[];
  isTeliaNumber: boolean;
  isLoading?: boolean;
  onNumberChange: (e: string) => void;
  onSubmit: () => void;
};

export function PhoneCheck({ phoneNumber, error, isTeliaNumber, isLoading, onNumberChange, onSubmit }: Props) {
  return (
    <Card shadow>
      <TextSpacing>
        <Card.Heading as="h2">Steg 1</Card.Heading>
        <Card.Content>
          <Container flexDirection="column" gap alignItems="start">
            <Text>
              For å benytte deg av dette må du ha et nummer som er tilknyttet et Telia-abonnement. Vi sender deg en
              engangskode til dette nummeret.
            </Text>
            <div style={{ width: "70%" }}>
              <TextField errors={error || []} isValid={isTeliaNumber}>
                <TextField.Label>Telefonnummer til ditt abonnement</TextField.Label>
                <TextField.Input
                  maxLength={8}
                  max={8}
                  pattern="\d*"
                  value={phoneNumber}
                  onValueChange={onNumberChange}
                />
                <TextField.ErrorList />
              </TextField>
            </div>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={!!error?.length || phoneNumber.length < 8}
              loading={isLoading}
            >
              Fortsett
            </Button>
          </Container>
        </Card.Content>
      </TextSpacing>
    </Card>
  );
}
