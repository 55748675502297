import { Link } from "react-router-dom";
import { track } from "@telia-no-min-side/utils";
import { Card, Flex, Text, Button, Icon, Badge } from "@telia/teddy";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { ComponentProps } from "react";

type Props = {
  href: string;
  isLinkExternal?: boolean;
  linkText: string;
  badge?: {
    variant: ComponentProps<typeof Badge>["variant"];
    text: string;
  };
  tagPrefix?: string;
};

export function TeliaPlayCard({ href, isLinkExternal, linkText, badge, tagPrefix }: Props) {
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const trackClick = () => {
    const trackEvent = {
      event: TRACK_EVENT.EVENT.CUSTOMER_NO_PROD,
      affiliation: TRACK_EVENT.AFFILIATION.TELIA_MIN_SIDE,
      ui_item_text: linkText,
      ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
      ui_item_context: `no-tv-products${tagPrefix ? `-${tagPrefix}` : ""}`,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
      ui_item_url: href,
    };
    pushGenericTrackingEvent(trackEvent);
  };

  return (
    <Card shadow bordered width="100%" maxWidth="400px" layout="default" variant="white">
      <Card.Slot align="top-right">{badge ? <Badge variant={badge.variant}>{badge.text}</Badge> : null}</Card.Slot>

      <Card.Heading as="h3" variant="title-100">
        Bestill Telia Play
      </Card.Heading>

      <Card.Content>
        <Flex direction="row" gap="150" height="100%" width="100%">
          <img
            src="https://www.telia.no/globalassets/produkter/tv/eget-utstyr-v-2.png"
            alt="Telia play"
            width="100px"
          />
          <Text variant="additional-100">Strømming og TV på ett sted!</Text>
        </Flex>
      </Card.Content>
      <Card.Footer height="100%" align="end">
        <Button variant="text" asChild onClick={trackClick}>
          {isLinkExternal ? (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {linkText}
              <Icon name="arrow-right" />
            </a>
          ) : (
            <Link to={href}>
              {linkText}
              <Icon name="arrow-right" />
            </Link>
          )}
        </Button>
      </Card.Footer>
    </Card>
  );
}
