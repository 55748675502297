import { useFetchInvoiceHistoryOverview, useFetchProfile } from "src/api/fixed";
import React, { ReactNode, useEffect, useState } from "react";
import { isPartnerWithoutInvoice } from "../../../util/PartnerHacks";
import { Invoice, InvoiceContext } from "./context";
import { useFetchInvoiceHistoryWithPrefetch } from "./useFetchInvoiceHistoryWithPrefetch";
import { useSelectedFinancialAccountId } from "./useSelectedFinancalAccountId";

type Props = {
  children: ReactNode;
};

const CURSOR_STEP = 4;

export function InvoiceProvider(props: Props): JSX.Element {
  const { children } = props;
  const profile = useFetchProfile();
  const { selectedFinancialAccountId, financial, financialIsLoading, setSelectedFinancialAccountId } =
    useSelectedFinancialAccountId();

  const { invoiceHistoryOverview } = useFetchInvoiceHistoryOverview({
    financialAccountId: selectedFinancialAccountId,
  });

  const initInvoicePageCursorState = {
    start: 0,
    end: CURSOR_STEP,
    max: invoiceHistoryOverview?.invoices.length ?? 0,
  };
  const [invoicePageCursor, setInvoicePageCursor] =
    useState<InvoiceContext["invoicePageCursor"]>(initInvoicePageCursorState);

  const prefetchFetchFromDate = invoiceHistoryOverview?.invoices[invoicePageCursor.end - 1]?.invoiceDate;

  const {
    invoiceHistory,
    invoiceHistoryError,
    invoiceHistoryIsLoading,
    setDateToFetchFrom,
    lastInvoiceHistory,
    dateAndHourNow,
    lastInvoiceHistoryIsLoading,
    mutateInvoiceHistory,
  } = useFetchInvoiceHistoryWithPrefetch({
    numberInvoicesToFetch: CURSOR_STEP,
    prefetchFetchFrom: prefetchFetchFromDate,
    financialAccountId: selectedFinancialAccountId,
  });

  const lastInvoice = lastInvoiceHistory?.invoiceHistories[0]
    ? {
        invoice: lastInvoiceHistory.invoiceHistories[0],
        financialAccountId: lastInvoiceHistory.financialAccountId,
      }
    : undefined;

  const length = invoiceHistoryOverview?.invoices.length;

  useEffect(() => {
    length && setInvoicePageCursor((prevState) => ({ ...prevState, max: length }));
  }, [length, selectedFinancialAccountId]);

  function fetchInvoiceFromPageCursorPosition(cursorsPosition: number) {
    const nextDateToFetchFrom =
      cursorsPosition <= 0 ? dateAndHourNow : invoiceHistoryOverview?.invoices[cursorsPosition].invoiceDate;

    nextDateToFetchFrom && setDateToFetchFrom(nextDateToFetchFrom);
  }

  function goToNextPage() {
    setInvoicePageCursor((prevState) => {
      const start = prevState.end;
      const end = prevState.end + CURSOR_STEP;

      fetchInvoiceFromPageCursorPosition(prevState.end - 1);
      return { ...prevState, start, end };
    });
  }
  function goToPreviousPage() {
    setInvoicePageCursor((prevState) => {
      const start = prevState.start - CURSOR_STEP;
      const end = prevState.start;

      fetchInvoiceFromPageCursorPosition(start - 1);
      return { ...prevState, start, end };
    });
  }

  function onPageChange(forward: boolean) {
    if (forward) {
      goToNextPage();
    } else {
      goToPreviousPage();
    }
  }

  const canSeeInvoice = profile.isLoading || !isPartnerWithoutInvoice(profile.data?.businessUnitId);

  function resetPaginationData() {
    setDateToFetchFrom(dateAndHourNow);
    setInvoicePageCursor(initInvoicePageCursorState);
  }
  function onFinancialAccountsChange(_: React.BaseSyntheticEvent, financialAccountId?: number) {
    if (financialAccountId === selectedFinancialAccountId) return;
    setSelectedFinancialAccountId(financialAccountId);
    resetPaginationData();
  }

  function mutateInvoices() {
    setInvoicePageCursor((prevState) => ({
      ...initInvoicePageCursorState,
      max: prevState.max,
    }));
    mutateInvoiceHistory();
  }

  return (
    <Invoice.Provider
      value={{
        financial,
        financialIsLoading,
        canSeeInvoice,
        onFinancialAccountsChange,
        resetPaginationData,
        selectedFinancialAccountId,
        invoiceHistoryError,
        invoicePageCursor,
        invoiceHistoryIsLoading,
        invoiceHistory,
        CURSOR_STEP,
        onPageChange,
        invoiceHistoryOverview,
        lastInvoice,
        mutateInvoices,
        lastInvoiceIsLoading: lastInvoiceHistoryIsLoading,
        isProfileLoading: profile.isLoading && !profile.data,
      }}
    >
      {children}
    </Invoice.Provider>
  );
}
