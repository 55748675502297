import { useState } from "react";
import { v4 as uuid } from "uuid";
import { getFragmentData, graphql } from "src/gql";
import { useMutation } from "@apollo/client";
import { Accordion, Button, Container, Heading, useToast } from "@telia-no-min-side/components";
import { Terms } from "../Terms";
import { useMobileAccount } from "hooks/useMobileAccount";
import { CardHeader } from "./components/CardHeader";
import { AgreementInfo } from "./components/AgreementInfo";
import { getProductTerm } from "../../utils/getProductTerm";
import { getInsuranceProofUrl } from "../../utils/getInsuranceProofUrl";
import { CancelInsuranceModal } from "../switch/components/CancelInsuranceModal";
import { INSURANCE_AGREEMENT_FRAGMENT } from "../../graphql/insuranceAgreementFragment";
import { GetAccountAgreementsQuery } from "gql/graphql";
import {
  DUMMY_SCREEN_INSURANCE_PRODUCT,
  INSURANCE_TYPE,
  URL_INSURANCE_AGREEMENT_TERMS,
  URL_INSURANCE_CLAIM_DAMAGE,
  CANCEL_INSURANCE_TEXT,
} from "util/constants/agreements";
import { capitalizeFirstLetter } from "util/capitalizeFirstLetter";
import { L } from "util/links";

export const CANCEL_INSURANCE = graphql(`
  mutation cancelInsurance($phoneNumber: String, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);

type Props = {
  insuranceAgreements: GetAccountAgreementsQuery["account"]["insuranceAgreements"];
};

export function InsuranceAgreements({ insuranceAgreements }: Props) {
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const { addToast } = useToast();
  const { accountId, phoneNumber } = useMobileAccount();

  const [cancelInsurance, { loading }] = useMutation(CANCEL_INSURANCE, {
    onCompleted() {
      setOpenCancelModal(false);
      setOpenCancelModal(false);
      addToast({ text: CANCEL_INSURANCE_TEXT.SUCCESS, variant: "success" });
    },

    onError() {
      setOpenCancelModal(false);
      setOpenCancelModal(false);
      addToast({ text: CANCEL_INSURANCE_TEXT.ERROR, variant: "error" });
    },
  });

  return insuranceAgreements?.map((insuranceAgreement) => {
    const agreement = getFragmentData(INSURANCE_AGREEMENT_FRAGMENT, insuranceAgreement);
    const { shortName, productTerms, product, terms, serviceAgreementId, presentation, productLevel } = agreement;
    const imei = getProductTerm("IMEI", productTerms);
    const phoneModel = getProductTerm("MODEL", productTerms);
    const isScreenInsurance = shortName?.toLowerCase()?.includes(INSURANCE_TYPE.SCREEN) || false;
    const insuranceName = isScreenInsurance ? INSURANCE_TYPE.SCREEN : INSURANCE_TYPE.PLUS;
    const startDate = agreement.effectiveDate;
    const monthlyPrice = agreement.price?.value;
    const isInsuranceCancelable = presentation?.modifiable;
    const isAccountLevelInsurance = productLevel === "ACCOUNT";

    // If the insurance agreement is part of switch agreement, we don't display it
    if (
      agreement.product?.toLocaleLowerCase().includes("switch") ||
      agreement.product?.includes(DUMMY_SCREEN_INSURANCE_PRODUCT)
    ) {
      return null;
    }

    return (
      <Container key={agreement.serviceAgreementId || uuid()} maxWidth="sm">
        <Accordion data-tracking-id="agreement-insurance-card">
          <Accordion.Item>
            <Container gap="lg">
              <Accordion.Trigger>
                <Heading tag="h2" variant="title-100" data-di-mask>
                  {capitalizeFirstLetter(insuranceName)}
                </Heading>

                <CardHeader phoneImage="" phoneModel={phoneModel} phoneNumber="" imei={imei} />
              </Accordion.Trigger>
            </Container>

            <Accordion.Content>
              <Container flexDirection="column" gap="lg">
                <AgreementInfo
                  monthlyPrice={monthlyPrice}
                  startDate={startDate}
                  remainingPrice={undefined}
                  isScreenInsurance={isScreenInsurance}
                />

                <Terms
                  termsInsuranceUrl={terms || URL_INSURANCE_AGREEMENT_TERMS}
                  termsInsuranceProofUrl={getInsuranceProofUrl(productTerms)}
                  dataTrackingId="insurance-agreements"
                />

                <Container
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={isInsuranceCancelable ? "space-between" : "flex-end"}
                  gap
                >
                  {isInsuranceCancelable && (
                    <Button onClick={() => setOpenCancelModal(true)} variant="text-purple">
                      Kanseller forsikring
                    </Button>
                  )}
                  <Button
                    href={
                      isScreenInsurance
                        ? `${L.TeliaNoUrl.href}${URL_INSURANCE_CLAIM_DAMAGE}`
                        : URL_INSURANCE_AGREEMENT_TERMS
                    }
                    tag="a"
                    variant="secondary"
                  >
                    Meld forsikringssak
                  </Button>
                </Container>
              </Container>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>

        <CancelInsuranceModal
          loading={loading}
          isOpen={openCancelModal}
          setIsOpen={setOpenCancelModal}
          // send accountId only when insurance is on account level.
          accountId={isAccountLevelInsurance ? accountId : undefined}
          phoneNumber={phoneNumber}
          title={shortName || "Forsikring"}
          product={product}
          cancelSwitchInsurance={cancelInsurance}
          serviceAgreementId={serviceAgreementId}
        />
      </Container>
    );
  });
}
