import styled, { css } from "styled-components";
import { Card } from "../Card";
import { Heading } from "../Heading";

export const BaseCard = styled(Card)<{ dashboardCard?: boolean }>`
  ${({ dashboardCard }) => {
    if (dashboardCard) {
      return css`
        flex: 1 1 320px;
        max-width: 400px;
      `;
    }
  }};
`;

export const CardMainText = styled(Heading)`
  font-weight: 500;
`;
