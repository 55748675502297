import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { datadogRum } from "@datadog/browser-rum";
import { uri, types, track, format } from "@telia-no-min-side/utils";
import { useToast } from "@telia-no-min-side/components";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import {
  INVOICE_POSTPONE_DAYS,
  INVOICE_POSTPONE_SUCCESS_MESSAGE,
  INVOICE_POSTPONE_GENERIC_ERROR_MESSAGE,
  INVOICE_POSTPONE_NOT_ELIGIBLE_ERROR_MESSAGE,
  INVOICE_POSTPONE_LOADING_MESSAGE,
} from "util/constants/invoices";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { ConfirmPostponeInvoiceModal } from "../components/ConfirmPostponeInvoiceModal";

type Props = {
  modalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  dueDate: number;
};

export const PostponeInvoice = ({ modalOpen, setIsModalOpen, dueDate }: Props) => {
  const [loadingToastId, setLoadingToastId] = useState<string | null>(null);
  const { addToast, removeToast } = useToast();
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const formattedPostponedDueDate = dayjs
    .unix(dueDate)
    .add(INVOICE_POSTPONE_DAYS, "days")
    .format(format.DATE_DISPLAY_FORMAT_FULL);

  const submitPostponeFixedInvoice = useAxiosPost<types.fixed.PostponeInvoiceResponse>(
    uri.minSideFixed(ICX_REQUEST.URL_INVOICE_POSTPONE),
    {
      onSuccess(response) {
        if (response?.newInvoiceDueDate) {
          addToast({
            text: INVOICE_POSTPONE_SUCCESS_MESSAGE,
            variant: "success",
            dataTrackingId: "postpone-fixed-invoice-success",
          });
          return;
        }
        if (response && response.mustHandleManually === true) {
          addToast({
            text: INVOICE_POSTPONE_NOT_ELIGIBLE_ERROR_MESSAGE,
            variant: "error",
            dataTrackingId: "postpone-fixed-invoice-error-not-eligible",
            eventErrorType: TRACK_EVENT.ITEM_TYPE.FIXED_INVOICE_POSTPONE_ERROR,
          });
          datadogRum.addError(JSON.stringify(response), { feature: "postponeFixedInvoice" });
          return;
        }
        addToast({
          text: INVOICE_POSTPONE_GENERIC_ERROR_MESSAGE,
          variant: "error",
          dataTrackingId: "postpone-fixed-invoice-error-generic",
          eventErrorType: TRACK_EVENT.ITEM_TYPE.FIXED_INVOICE_POSTPONE_ERROR,
        });
        datadogRum.addError(JSON.stringify(response), { feature: "postponeFixedInvoice" });
      },
      onError() {
        addToast({
          text: INVOICE_POSTPONE_GENERIC_ERROR_MESSAGE,
          variant: "error",
          dataTrackingId: "postpone-fixed-invoice-error-generic",
          eventErrorType: TRACK_EVENT.ITEM_TYPE.FIXED_INVOICE_POSTPONE_ERROR,
        });
      },
      onFinally() {
        setIsModalOpen(false);
        if (loadingToastId) {
          removeToast(loadingToastId);
        }
      },
    }
  );

  const handlePostponeInvoice = () => {
    datadogRum.addAction("postpone_fixed_invoice", { feature: "postponeFixedInvoice" });
    pushGenericTrackingEvent({
      ui_item_text: TRACK_EVENT.ITEM_TEXT.POSTPONE_INVOICE,
      ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.POSTPONE_INVOICE_MODAL,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
    });
    submitPostponeFixedInvoice.post({});
  };

  useEffect(() => {
    if (loadingToastId) {
      removeToast(loadingToastId);
    }
    if (submitPostponeFixedInvoice.isLoading) {
      setIsModalOpen(false);
      setLoadingToastId(
        addToast({
          text: INVOICE_POSTPONE_LOADING_MESSAGE,
          variant: "loading",
          dataTrackingId: "postpone-fixed-invoice-loading",
        }).id
      );
    }
  }, [submitPostponeFixedInvoice.isLoading]);

  return (
    <ConfirmPostponeInvoiceModal
      modalOpen={modalOpen}
      setIsModalOpen={setIsModalOpen}
      dueDate={formattedPostponedDueDate}
      handlePostponeInvoice={handlePostponeInvoice}
      isLoading={submitPostponeFixedInvoice.isLoading}
    />
  );
};
