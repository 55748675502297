import { Dispatch, SetStateAction } from "react";
import { Flex, Icon, Button } from "@telia/teddy";

export type Props = {
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
  nextIndex?: number;
};

export function Pagination({ index, nextIndex, setIndex }: Props) {
  const handlePagination = (direction: "previous" | "next") => {
    const recordsPerPage = 10; // This number is harcoded in backend
    if (direction === "previous" && index >= recordsPerPage) {
      setIndex((index) => index - recordsPerPage);
    } else if (direction === "next" && nextIndex) {
      setIndex(nextIndex);
    }
  };

  return (
    <Flex mt="200" justify="end">
      <Button
        aria-label="previous"
        iconOnly
        variant="list-item"
        disabled={index === 0}
        onClick={() => handlePagination("previous")}
      >
        <Icon size="sm" name="arrow-left" />
      </Button>
      <Button
        aria-label="next"
        iconOnly
        variant="list-item"
        disabled={!nextIndex}
        onClick={() => handlePagination("next")}
      >
        <Icon size="sm" name="arrow-right" />
      </Button>
    </Flex>
  );
}
