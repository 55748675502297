import styled from "styled-components";

export const DetailedUsage = styled.div`
  @media only screen and (max-width: 860px) {
    .detailed-usage-table {
      display: block;

      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      .full-width-table__header .full-width-table__row,
      .full-width-table__footer .full-width-table__row {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      .full-width-table__body {
        td.full-width-table__cell {
          position: relative;
          padding-left: 50%;
          text-align: left;
          font-family: "TeliaSans-Medium", Helvetica, Arial, sans-serif;
        }

        .full-width-table__cell::before {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 45%;
          padding-right: 10px;
          padding-top: 0.5em;
          white-space: nowrap;
          text-align: right;
          content: attr(data-title);
        }
      }
    }
  }
`;
