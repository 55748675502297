import { Subscription, OfferingForSale } from "../../types";
import { notEmpty } from "@telia-no-min-side/utils";
import { isMbbBucketOffer } from "src/util/format-subscription/isMbbBucketOffer";
import { isMbbUnlimitedDataOffer } from "src/util/format-subscription/isMbbUnlimitedDataOffer";
import { isMbbSleepModeOffer } from "util/format-subscription/isMbbSleepModeOffer";

export function getMbbOffersForSale(subscription: Subscription) {
  return (subscription?.newOfferingDetails?.offerings || []).filter(notEmpty).filter((offering) => {
    return (
      !offering?.commitmentTimeInMonths &&
      !!offering?.additionalProperties?.find(
        (additionalProperty) => additionalProperty?.name === "RESOURCE_TYPE" && additionalProperty?.value === "MBB"
      )
    );
  });
}

export function groupMbbOffersForSaleByCategory(offersForSale: OfferingForSale[] | undefined) {
  const sortedOffers = (offersForSale || []).sort((a, b) => {
    if (!a?.weight || !b?.weight) return 0;
    return a.weight - b.weight;
  });

  const [sleepModeOffers, otherOffers] = sortedOffers.filter(notEmpty).reduce<NonNullable<OfferingForSale>[][]>(
    (groupedOffers, offer) => {
      isMbbSleepModeOffer(offer.shortName) ? groupedOffers[0].push(offer) : groupedOffers[1].push(offer);
      return groupedOffers;
    },
    [[], []]
  );

  // From a backend perspective all MBB offers are unlimited speed / limited data
  // But from marketing perspective offers with acceptable reduced download speed when you run out of
  // data are unlimited and the rest are bucket. So mobile and mbb UI is the same, but business logic
  // is totally different.
  const bucketOffers = otherOffers.filter((offering) =>
    isMbbBucketOffer(offering?.price?.offeringPrices, offering.shortName)
  );
  const unlimitedDataOffers = otherOffers.filter((offering) =>
    isMbbUnlimitedDataOffer(offering?.price?.offeringPrices, offering.shortName)
  );

  const hasBucketOffers = !!bucketOffers?.length;
  const hasUnlimitedDataOffers = !!unlimitedDataOffers?.length;
  const hasSleepModeOffers = !!sleepModeOffers?.length;
  const hasAnyOffers = hasBucketOffers || hasUnlimitedDataOffers || sleepModeOffers;
  const hasMultipleCategories =
    [hasBucketOffers, hasUnlimitedDataOffers, sleepModeOffers].filter((subscriptionCategory) => !!subscriptionCategory)
      .length > 1;

  return {
    sleepModeOffers,
    bucketOffers,
    unlimitedDataOffers,
    hasBucketOffers,
    hasUnlimitedDataOffers,
    hasSleepModeOffers,
    hasMultipleCategories,
    hasAnyOffers,
  };
}
