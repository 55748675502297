import styled from "styled-components";
import { CloseIcon, colors } from "@telia/styleguide";
import { remCalc } from "../utils/fonts";
import { Icon } from "../Icon";
import { zIndex } from "../utils/variables";
import { InfoTextContainerProps } from ".";

export const Container = styled.div`
  position: relative;
  display: flex;
`;

export const IconWrapper = styled.div`
  height: ${remCalc(20)};
  width: ${remCalc(20)};
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  height: 100%;
  width: 100%;
`;

export const InfoTextContainer = styled.div<InfoTextContainerProps>`
  position: absolute;
  top: ${(props) => props.positionEl?.top};
  left: ${(props) => props.positionEl?.left};
  transform: translateY(-50%);
  height: auto;
  width: ${remCalc(250)};
  font-size: ${remCalc(14)};
  background-color: ${colors.grey100};
  z-index: ${zIndex.sideMenu};
`;

export const LabelContainer = styled.div`
  margin: ${remCalc(8)} ${remCalc(16)};
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: ${remCalc(36)};
  width: ${remCalc(36)};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  height: 100%;
  width: 100%;
  padding: ${remCalc(8)};
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 50%;
  &:hover {
    background-color: ${colors.grey200};
  }
`;

export const LineSeparator = styled.div`
  height: ${remCalc(1)};
  width: 100%;
  background-color: ${colors.grey200};
`;

export const DescriptionText = styled.p`
  font-weight: 500;
  margin: ${remCalc(8)} ${remCalc(16)};
`;
