import { ProductBoxListProps } from "@telia-no-min-side/components";
import { types } from "@telia-no-min-side/utils";
import { IconDefinition } from "@telia/styleguide";
import { getListPriceText, getPriceText } from "../priceFormatter";
import { getActionList, getDeviceActionList } from "./getActionList";
import { getProductBadge, getDeviceBadge } from "./getBadge";

type Options = {
  overrideImage?: {
    src?: string;
    alt?: string;
    icon?: IconDefinition;
    iconColor?: string;
  };
  removeActionList?: boolean;
};

function getSecondaryText(product: types.fixed.Product): string {
  if (product.schedule?.displayName) {
    return product.schedule.displayName;
  }

  if (product.price?.offerText) {
    return product.price.offerText;
  }

  if (product.serialNumber) {
    return product.serialNumber;
  }

  return "";
}

export function getProductBoxProduct(
  product?: types.fixed.ProductCategory,
  options?: Options
): ProductBoxListProps["products"] {
  if (!product?.products) return;
  return product.products.map((product) => {
    const src = options?.overrideImage?.src ?? product.src;
    const icon = options?.overrideImage?.icon;
    const alt = options?.overrideImage?.alt ?? product.displayName;
    const secondaryText = getSecondaryText(product);
    return {
      text: product.displayName,
      secondaryText,
      price: getPriceText(product?.price),
      listPrice: getListPriceText(product.price),
      isLoading: false,
      src: src,
      alt: alt,
      icon: icon,
      tvBoxIsEvo: product.tvBoxIsEvo,
      iconColor: options?.overrideImage?.iconColor,
      badge: getProductBadge(product),
      status: product.status,
      actionList: options?.removeActionList ? undefined : getActionList(product.actions),
    };
  });
}

export function getDeviceProducts(products?: types.fixed.Product[]) {
  if (!products?.length) return;

  return products.map((product) => ({
    id: product.id,
    text: product.displayName,
    secondaryText: getSecondaryText(product),
    price: getPriceText(product?.price),
    listPrice: getListPriceText(product.price),
    isLoading: false,
    src: product.src,
    alt: product.displayName,
    badge: getDeviceBadge(product),
    actionList: getDeviceActionList(product.actions),
  }));
}

export const PurchaseTypes = {
  buyRemoteControl: "kjoep-fjernkontroll",
};
