import { types, uri } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export const urlFinance = uri.minSideFixed(ICX_REQUEST.URL_FINANCE);

export function useFetchFinanceInvoices({ getLast }: { getLast?: boolean } = {}) {
  const { error, ...rest } = useAxiosSWR<types.fixed.FinanceInvoices[]>(
    `${urlFinance}/invoices/all${getLast ? "/last" : ""}`
  );

  return {
    isError: !!error,
    error: error,
    ...rest,
  };
}
export function useFetchFinanceAccounts() {
  const { error, ...rest } = useAxiosSWR<types.fixed.FinanceAccounts[]>(`${urlFinance}/accounts/all`);

  return {
    isError: !!error,
    error: error,
    ...rest,
  };
}
