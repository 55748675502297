import type { ReactNode, Dispatch, SetStateAction } from "react";
import { useState, useEffect, createContext, useContext, useCallback } from "react";
import { isOlderThan, track } from "@telia-no-min-side/utils";
import { datadogRum } from "@datadog/browser-rum";
import { useSearchParams } from "react-router-dom";
import { ApolloError, useQuery } from "@apollo/client";
import { useDataUsage } from "../hooks/useDataUsage";
import { useMobileAccount } from "hooks/useMobileAccount";
import { useConfirmPayment } from "../hooks/useConfirmPayment";
import { GET_MOBILE_DASHBOARD_DATA } from "pages/mobile/mobile-subscription-dashboard/graphql/getMobileDashboardData";
import {
  OrderOp,
  UserRole,
  AgreementType,
  TopupCategory,
  PaymentProvider,
  ConfirmPaymentStatus,
  GetMobileDashboardDataQuery,
  AdditionalProductPaymentType,
} from "gql/graphql";
import { useAdditionalProducts } from "hooks/useAdditionalProducts";
import { isPrepaidAccount } from "util/mobile/prepaid";
import { NordicBalticMinutesType, TopupLocalType } from "../types";
import { formatTimeUntilDate, getSubscriptionBenefits, isChildSubscription } from "../modules/DataUsageCard/utils";
import { useMutationResponse } from "hooks/useMutationResponse";
import { getTopupTransactionData, isPaidTopupHandler, isTeliaXSubscriptionHandler } from "../modules/TopupDrawer/utils";
import { ArrayElement } from "../modules/DataUsageCard/utils/sortDataUsage";

type MobileDashboardContextType = {
  isLoading: boolean;
  isError: ApolloError | undefined;
  refetch: () => void;
  subscription: GetMobileDashboardDataQuery["subscription"] | undefined;
  paymentStatus: ConfirmPaymentStatus | undefined;
  purchaseError: string;
  subscriptionBenefits: ReturnType<typeof getSubscriptionBenefits>;
  showTopup: boolean;
  isTeliaXSubscription: boolean;
  timeUntilDataUsagePeriodStarts: string | null;
  isB2B: boolean;
  isLegalOwner: boolean;
  isPrepaid: boolean;
  isOver18: boolean;
  selectedTopup: TopupLocalType | undefined;
  setSelectedTopup: Dispatch<SetStateAction<TopupLocalType | undefined>>;
  orderTopup: (topup?: TopupLocalType) => void;
  handleTopupSelection: (topup?: TopupLocalType) => void;
  isDrawerOpen: boolean;
  selectedTopupCategory: TopupCategory | NordicBalticMinutesType | undefined;
  closeDrawerResetTopup: () => void;
  openSelectedTopupDrawer: (category: TopupCategory | NordicBalticMinutesType) => void;
  activeDataBoostProduct: ArrayElement<GetMobileDashboardDataQuery["subscription"]["usage"]> | null;
  localDataUsage: GetMobileDashboardDataQuery["subscription"]["usage"];
  foreignDataUsage: GetMobileDashboardDataQuery["subscription"]["usage"];
};

const MobileDashboardContext = createContext<MobileDashboardContextType | undefined>(undefined);

export function MobileDashboardProvider({ children }: { children: ReactNode }) {
  const [purchaseId, setPurchaseId] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTopup, setSelectedTopup] = useState<TopupLocalType>();
  const [selectedTopupCategory, setSelectedTopupCategory] = useState<TopupCategory | NordicBalticMinutesType>();

  const { phoneNumber } = useMobileAccount();
  const { pushTrackingEvent } = track.useEventTracking();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setMutationResponseMessage } = useMutationResponse();
  const { runOrderAdditionalProducts } = useAdditionalProducts();

  // Try confirming purchase if purchaseId was sent as an url parameter
  const { response: confirmPaymentResponse, runConfirmPayment } = useConfirmPayment();
  const paymentStatus = confirmPaymentResponse.data?.confirmPayment?.result;

  const dataUsageQuery = useQuery(GET_MOBILE_DASHBOARD_DATA, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
    skip: !phoneNumber,
  });

  const { isLoading, subscription, purchaseError, setPurchaseError } = useDataUsage(dataUsageQuery);

  const isTeliaXSubscription = isTeliaXSubscriptionHandler(subscription);

  const isB2B = subscription?.agreementType === AgreementType.Business;
  const isLegalOwner = !!subscription?.roles.includes(UserRole.LegalOwner);
  const isOver18 = isOlderThan(18, subscription?.productUser?.birthDate);
  const subscriptionBenefits = getSubscriptionBenefits(subscription?.additionalProducts);
  const showTopup = isLegalOwner || !isChildSubscription(subscription?.userOffering?.shortName);

  const allDataUsage = subscription?.usage?.filter((quota) => quota?.category?.startsWith("DATA")) || [];
  const isForeignUsage = (service: string) => service.includes("SONE") || service.includes("USA");

  const localDataUsage = allDataUsage?.filter((quota) => !isForeignUsage(quota?.service || ""));
  const foreignDataUsage = allDataUsage?.filter((quota) => isForeignUsage(quota?.service || "")) || [];

  const period = localDataUsage?.find((usage) => usage?.type === "BASE")?.period;
  const timeUntilDataUsagePeriodStarts = formatTimeUntilDate(period?.endDate);
  const activeDataBoostProduct = localDataUsage?.find((quota) => quota?.activeDataBoostSession === true);

  const closeDrawerResetTopup = () => {
    setIsDrawerOpen(false);
    setSelectedTopup(undefined);
    setSelectedTopupCategory(undefined);
  };

  useEffect(() => {
    // When user is being redirected back to /minside/mobil/forbruk?purchaseId=${purchaseId}&product=${productId}
    // data is being extracted for order confirmation and url parameters should be removed
    const product = searchParams.get("product");
    const purchaseId = searchParams.get("purchaseId") || "";
    if (purchaseId || product) {
      setPurchaseId(purchaseId);
      searchParams.delete("product");
      searchParams.delete("purchaseId");
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (!purchaseId) return;
    runConfirmPayment({ purchaseId, provider: PaymentProvider.Vipps });
  }, [purchaseId]);

  const handleTopupSelection = useCallback(
    (topup?: TopupLocalType) => {
      if (topup) datadogRum.addAction("select_topup", { ...topup });
      setSelectedTopup(topup);
      setMutationResponseMessage((prev) => ({ ...prev, isSuccessfulMutation: false }));
      setPurchaseError("");
    },
    [setSelectedTopup, setPurchaseError]
  );

  const composeReturnUrl = (location: Location, selectedTopup?: TopupLocalType) =>
    location.search
      ? `${location.href}&purchaseId={purchase_id}&product=${selectedTopup?.id}`
      : `${location.href}?purchaseId={purchase_id}&product=${selectedTopup?.id}`;

  const orderTopup = (topup?: TopupLocalType) => {
    if (!topup) return;
    const returnUrl = composeReturnUrl(window.location, topup);
    const isPaidTopup = isPaidTopupHandler(topup);

    const orderInput = {
      operation: OrderOp.AddAdditionalProduct,
      productName: topup.id,
      topupType: topup.type,
      paymentInfo: {
        paymentType: isPaidTopup && topup.paymentType ? topup.paymentType : AdditionalProductPaymentType.Invoice,
        returnUrl,
      },
    };
    datadogRum.addAction("buy_topup", { ...orderInput });

    if (runOrderAdditionalProducts) {
      runOrderAdditionalProducts(
        {
          phoneNumber,
          input: [orderInput],
        },
        {
          onCompleted(data) {
            closeDrawerResetTopup();
            const orderId = data?.order?.orderId || "";
            const transactionData = getTopupTransactionData(topup, orderId);
            return pushTrackingEvent(transactionData);
          },
          onError() {
            closeDrawerResetTopup();
          },
        }
      );
    }
  };

  const openSelectedTopupDrawer = (category: TopupCategory | NordicBalticMinutesType) => {
    setIsDrawerOpen(true);
    setSelectedTopupCategory(category);
  };

  return (
    <MobileDashboardContext.Provider
      value={{
        isLoading,
        isError: dataUsageQuery.error,
        refetch: dataUsageQuery.refetch,
        subscription,
        paymentStatus,
        purchaseError,
        subscriptionBenefits,
        showTopup,
        isTeliaXSubscription,
        timeUntilDataUsagePeriodStarts,
        isB2B,
        isPrepaid: isPrepaidAccount(subscription?.billingType),
        isLegalOwner,
        isOver18,
        selectedTopup,
        setSelectedTopup,
        orderTopup,
        handleTopupSelection,
        isDrawerOpen,
        selectedTopupCategory,
        closeDrawerResetTopup,
        openSelectedTopupDrawer,
        activeDataBoostProduct: activeDataBoostProduct || null,
        localDataUsage,
        foreignDataUsage,
      }}
    >
      {children}
    </MobileDashboardContext.Provider>
  );
}

export function useMobileDashboardContext() {
  const context = useContext(MobileDashboardContext);
  if (!context) {
    throw new Error("useMobileDashboardContext must be used within a MobileDashboardProvider");
  }
  return context;
}
