import { Flex, Heading, Icon } from "@telia/teddy";
import { ErrorBox } from "components/ErrorBox";

type Props = {
  title: string;
  refetch: () => void;
};

export function Error({ title, refetch }: Props) {
  return (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100">
        {title}
      </Heading>

      <ErrorBox
        title="Vi beklager"
        description="Dessverre oppstod det en feil under innlastningen av denne siden. Vennligst prøv igjen litt senere."
        actionContent={<Icon name="sync">Last side på nytt</Icon>}
        onClick={() => refetch()}
      />
    </Flex>
  );
}
