import { Card, Text, Heading, Icon, Button, Flex, Box, Skeleton } from "@telia/teddy";
import { GetPrepaidUsagesQuery } from "gql/graphql";
import { getPriceAmountText } from "util/priceFormatter";
import {
  MainCardContainer,
  HeaderFlexContainer,
  CardAdditionalInfo,
  DetailedInfoFlexContainer,
  DetailedInfoItem,
} from "./style";
import { getAllUsages, hasUnlimitedNonDataServices } from "../utils/usage";

type Props = {
  loading: boolean;
  balance: number;
  usageData: GetPrepaidUsagesQuery["subscription"]["usage"];
  usageTopupData: GetPrepaidUsagesQuery["subscription"]["usageTopup"];
  topupLink: string;
  isMBB?: boolean;
};

export function PrepaidBalance({ loading, balance, usageData, usageTopupData, topupLink, isMBB }: Props) {
  const { voice, sms, mms } = getAllUsages(usageData);
  const showUnlimitedInfoBox = hasUnlimitedNonDataServices(usageTopupData?.unlimitedQuotas);
  return (
    <MainCardContainer variant="white" shadow bordered>
      <HeaderFlexContainer direction="row" width="100%" justify="between" align="end">
        <div>
          <Text>Saldo</Text>
          <Skeleton loading={loading}>
            <Heading as="h1" variant="title-400">
              {getPriceAmountText(balance, "kr")}
            </Heading>
          </Skeleton>
        </div>
      </HeaderFlexContainer>
      <Card.Line />
      <Card.Content>
        {!isMBB && (
          <>
            <Box mt="200" mb="200">
              {/* The usage data from backend will always be for last 31 days */}
              <Skeleton loading={loading}>
                <Text variant="paragraph-100">Ditt bruk de siste 31 dagene:</Text>
              </Skeleton>
            </Box>
            <DetailedInfoFlexContainer align="start" wrap="wrap" direction="row" width="100%">
              <DetailedInfoItem>
                <Text>Ringing</Text>
                <Skeleton loading={loading}>
                  <Text variant="preamble-100">{voice}</Text>
                </Skeleton>
              </DetailedInfoItem>
              <DetailedInfoItem>
                <Text>SMS</Text>
                <Skeleton loading={loading}>
                  <Text variant="preamble-100">{sms}</Text>
                </Skeleton>
              </DetailedInfoItem>
              <DetailedInfoItem>
                <Text>MMS</Text>
                <Skeleton loading={loading}>
                  <Text variant="preamble-100">{mms}</Text>
                </Skeleton>
              </DetailedInfoItem>
            </DetailedInfoFlexContainer>
            <Skeleton loading={loading}>
              {showUnlimitedInfoBox && (
                <CardAdditionalInfo>
                  <Flex gap="100">
                    <Icon size="lg" name="check-circle" style={{ alignSelf: "start" }} />
                    <div>Ubegrenset ringing, SMS og MMS er inkludert i din månedspakke</div>
                  </Flex>
                </CardAdditionalInfo>
              )}
            </Skeleton>
          </>
        )}
        <Flex justify="end">
          <Box mt="300">
            <Skeleton loading={loading}>
              <Button variant="primary" asChild>
                <a href={topupLink} target="_blank" rel="noreferrer">
                  Fyll på <Icon name="arrow-right" />
                </a>
              </Button>
            </Skeleton>
          </Box>
        </Flex>
      </Card.Content>
    </MainCardContainer>
  );
}
