import { graphql } from "src/gql";

export const GET_EXPLORE_SECTION_DATA = graphql(`
  query getExploreSectionData($phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      account {
        id
      }
      phoneNumber {
        localNumber
      }
      dataSharingQualification {
        eligibleForDataSharing
        nonEligibilityReasons {
          code
        }
      }
      usage {
        category
        type
        remaining {
          amount
        }
      }
      roles
      agreementType
      commitmentEndDate
      userOffering {
        shortName
      }
      newOfferingDetails {
        offerings {
          shortName
          shortDescription
          price {
            offeringPrices {
              code
              amount
            }
          }
        }
      }
    }
  }
`);
