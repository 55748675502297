import { Container, Heading } from "@telia-no-min-side/components";
import { FAQAccordionMobile } from "./FAQAccordionMobile";

export function FaqComponentMobile(): JSX.Element {
  return (
    <Container padding="bottom" flexDirection="column" gap>
      <Heading tag="h4" variant="title-100">
        Ofte stilte spørsmål
      </Heading>
      <FAQAccordionMobile />
    </Container>
  );
}
