import { Container } from "@telia-no-min-side/components";
import { StreamingImage } from "./style";

export function StreamingImages() {
  const path = "https://www.telia.no/globalassets/tv-channel-logos/";

  return (
    <Container gap flexDirection="row" flexWrap padding="top">
      <StreamingImage theme="light">
        <img alt="Netflix" src={path + "netflix.svg"} />
      </StreamingImage>
      <StreamingImage theme="light">
        <img alt="Viaplay" src={path + "viaplay.svg"} />
      </StreamingImage>
      <StreamingImage theme="light">
        <img alt="SkyShowtime" src={path + "skyshowtime.svg"} />
      </StreamingImage>
      <StreamingImage theme="light">
        <img alt="Max" src={path + "max.svg"} />
      </StreamingImage>
      <StreamingImage theme="light">
        <img alt="Prime" src={path + "prime.svg"} />
      </StreamingImage>
    </Container>
  );
}
