import { types } from "@telia-no-min-side/utils";
import { LoadingSubscriptionCard } from "src/pages/home/card/LoadingSubscriptionCard";
import { UpSellSmartWifiRouterCard } from "./UpSellSmartWifiRouterCard";
import { BroadbandSubscriptionCard } from "./BroadbandSubscriptionCard";
import { hasAnyFixedBroadbandProducts } from "../utils/hasAnyFixedBroadbandProducts";
import { ErrorCard } from "pages/home/card/ErrorCard";

type Props = {
  user?: types.fixed.User;
  products?: types.fixed.ProductCategoryAll;
  isLoading: boolean;
  showError: boolean;
  isRefetching: boolean;
  onRefetch: () => void;
};

export function BroadbandSubscriptions({ user, products, isLoading, showError, isRefetching, onRefetch }: Props) {
  if (isLoading) {
    return <LoadingSubscriptionCard />;
  }

  if (showError) {
    return <ErrorCard onRefreshClick={onRefetch} isLoading={isRefetching} sectionText="dine internett-produkter" />;
  }

  const productsEntries = Object.entries(products || {});
  const hasBroadband = hasAnyFixedBroadbandProducts(products);

  if (!hasBroadband) {
    return null;
  }

  return (
    <>
      {productsEntries?.map(([customerId, { products }]) => {
        const customer = user?.usersWithCustomerId.find(
          (userWithCustomerId) => userWithCustomerId.customerId === customerId
        );
        if (!customer) return null;
        return products
          .filter((product) => product.status?.shouldDisplay)
          .map((product) => <BroadbandSubscriptionCard key={product.id} product={product} customer={customer} />);
      })}
      <UpSellSmartWifiRouterCard />
    </>
  );
}
