import React, { ReactNode, useEffect, useState } from "react";
import { FlattenSimpleInterpolation } from "styled-components";
import { ContainerBaseProps } from "../Container";
import { ExpandableCard } from "../ExpandableCard";
import { ExpandableCardState } from "../ExpandableCard/Context";
import { AccordionContext, AccordionState } from "./Context";
import { AccordionItem } from "./Item";
import { AccordionTheme } from "./styles";
Accordion.Item = AccordionItem;
Accordion.Trigger = ExpandableCard.Trigger;
Accordion.Content = ExpandableCard.Content;

export type AccordionProps = {
  /**
   * To display multiple accordion bodies at the same time.
   * @default false
   */
  enableMultipleOpenItems?: boolean;

  /**
   * Callback function fired when accordion state is changed.
   *
   * @param {string} openIds
   */
  onChange?: (openIds: AccordionState["openIds"]) => void;

  children?: ReactNode;

  containerStyles?: FlattenSimpleInterpolation;
  variant?: "primary" | "info";

  dataTrackingID?: string;
} & Omit<ContainerBaseProps, "onChange">;
function removeItemFromArray<T>(arrayToModify: T[], index: number) {
  return [...arrayToModify.slice(0, index), ...arrayToModify.slice(index + 1)];
}

/**
 * @deprecated Use Teddy components instead
 */
export function Accordion(props: AccordionProps) {
  const {
    enableMultipleOpenItems = false,
    variant = "primary",
    onChange,
    children,
    dataTrackingID,
    ...containerProps
  } = props;
  const [openIds, setOpenIds] = useState<AccordionState["openIds"]>(undefined);

  useEffect(() => {
    if (onChange) onChange(openIds);
  }, [openIds]);

  function onAccordionItemClick(id: ExpandableCardState["id"]) {
    if (!openIds || openIds.length === 0) {
      setOpenIds([id]);
      return;
    }

    const indexOfIdInOpenIds = openIds.indexOf(id);

    if (indexOfIdInOpenIds !== -1) {
      const newOpenIds = removeItemFromArray<string>(openIds, indexOfIdInOpenIds);
      setOpenIds(newOpenIds);
      return;
    }
    if (enableMultipleOpenItems) {
      setOpenIds([...openIds, id]);
      return;
    }
    setOpenIds([id]);
  }

  return (
    <AccordionContext.Provider value={{ openIds, setAccordionItemOpen: onAccordionItemClick, variant }}>
      <AccordionTheme
        gap={variant !== "info"}
        flexDirection="column"
        variant={variant}
        data-tracking-id={dataTrackingID}
        {...containerProps}
      >
        {children}
      </AccordionTheme>
    </AccordionContext.Provider>
  );
}
