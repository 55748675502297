import { colors } from "@telia/styleguide";
import styled, { css, keyframes } from "styled-components";
import { Card } from "../Card";
import { ToggleButtonProps } from ".";

const activeAndSelectedStyle = css`
  background-color: ${colors.corePurple900};
  color: white;
  :hover {
    background-color: ${colors.corePurple700};
  }
`;

const skeletonLoading = keyframes`
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 0% 20%;
  }
`;

const fadeInFromSkeleton = keyframes`
  0% {
    background-color: ${colors.grey100}; 
    max-width: 200px;
    line-height: 0;
    color: transparent;
  }
  50% {
    background-color: ${colors.grey100}; 
    line-height: 0;
    color: transparent;
  }
  51% {
    line-height: normal;
  }
  100% {
    max-width: 1000px;
  }
`;

const disappear = keyframes`
  0% {
    visibility: visible;
    opacity: 1;
    height: 1rem
  }
  49% {
    height: 1rem
  }
  50% {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
`;

const ProfilePickerStyle = css<ToggleButtonSkeleton>`
  font-weight: 500;
  font-size: 1.125rem;
  min-width: 150px;
  min-height: 73px;
  line-height: normal;
  width: unset;
`;

type ToggleButtonSkeleton = {
  removeLeftMargin?: boolean;
  removeRightMargin?: boolean;
  removeBlockMargin?: boolean;
} & Pick<ToggleButtonProps<unknown>, "variant">;

export const StyledToggleButtonSkeleton = styled(Card)<ToggleButtonSkeleton>`
  padding: 0.5rem 1rem;
  ${(props) => props.variant === "profile-picker" && ProfilePickerStyle};
  width: 200px;
  background-color: ${colors.grey100};
`;

export const SkeletonLine = styled.div<{ fadeOut?: boolean }>`
  background-repeat: no-repeat;
  background: linear-gradient(-45deg, ${colors.grey100}, ${colors.grey300});
  background-size: auto;
  background-size: 300% 300%;
  border-radius: 4rem;
  width: 100%;
  height: 1rem;
  visibility: visible;

  ${(props) => {
    if (props.fadeOut) {
      return css`
        animation: ${disappear} 1.5s ease;
        opacity: 0;
        height: 0;
        visibility: hidden;
      `;
    } else {
      return css`
        animation: ${skeletonLoading} 2s ease infinite;
      `;
    }
  }}
`;

type ToggleButton = {
  isSelected: boolean;
  removeLeftMargin?: boolean;
  removeRightMargin?: boolean;
  fadeFromSkeleton?: boolean;
} & Pick<ToggleButtonProps<unknown>, "variant">;

export const StyledToggleButton = styled(Card)<ToggleButton>`
  padding: 0.5rem 1rem;
  ${(props) => props.variant === "profile-picker" && ProfilePickerStyle};
  cursor: pointer;
  max-width: max-content;
  white-space: nowrap;
  transition:
    background-color 200ms ease,
    color 200ms ease;

  ${(props) => {
    if (props.isSelected)
      return css`
        border-color: transparent;
        ${activeAndSelectedStyle}
      `;
  }}
  ${(props) => {
    if (props.fadeFromSkeleton) {
      return css`
        animation: ${fadeInFromSkeleton} 1.5s ease;
      `;
    }
  }}
`;

export const SecondaryText = styled.div`
  font-weight: normal;
  font-size: 1rem;
`;
