import React, { InputHTMLAttributes, useContext, useState } from "react";
import { Container } from "../Container";
import { RadioFieldset, RadioIndicator, RadioInput, RadioItem, RadioLabel, RadioLegend } from "./style";

export type RadioGroupProps = {
  /**
   * The legend for the radio group
   */
  legend: string;
  visualHideLegend?: boolean;
  onValueChange?: (value: string) => void;
  /**
   * A descriptive name for the radio group
   */
  name: string;
  defaultValue?: string;
  dataTrackingID?: string;
  itemAlignment?: "row" | "column";
} & Omit<InputHTMLAttributes<HTMLFieldSetElement>, "defaultValue">;

type Context = {
  value: string | undefined;
  name: string;
  onChange: (value: string) => void;
};

export const RadioGroupContext = React.createContext<Context>({
  value: undefined,
  name: "fallbackName",
  onChange: () => {},
});

/**
 * @deprecated Use Teddy components instead
 */
function RadioGroup({
  children,
  defaultValue,
  visualHideLegend,
  name,
  legend,
  dataTrackingID,
  onValueChange,
  itemAlignment = "column",
  ...props
}: RadioGroupProps) {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(defaultValue || String(props.value));
  const onChange = (value: string) => {
    setSelectedValue(value);
    if (onValueChange) onValueChange(value);
  };

  return (
    <RadioGroupContext.Provider value={{ value: selectedValue, name, onChange }}>
      <RadioFieldset {...props} data-tracking-id={dataTrackingID}>
        <RadioLegend isVisuallyHidden={visualHideLegend}>{legend}</RadioLegend>
        <Container alignItems="flex-start" padding="top" flexDirection={itemAlignment} gap>
          {children}
        </Container>
      </RadioFieldset>
    </RadioGroupContext.Provider>
  );
}

type RadioGroupItemProps = {
  value: string;
  dataTrackingID?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "name" | "value" | "id" | "checked">;

function Item({ children, dataTrackingID, ...props }: RadioGroupItemProps) {
  const { value, onChange, name } = useContext(RadioGroupContext);
  const id = value;
  const isChecked = value === props.value;
  return (
    <RadioItem
      onClick={() => onChange(props.value)}
      isSelected={isChecked}
      data-tracking-id={dataTrackingID || `${name}-${props.value}`}
    >
      <RadioInput
        type="radio"
        id={id}
        name={name}
        {...props}
        onChange={(e) => {
          props.onChange && props.onChange(e);
          onChange(props.value);
        }}
        checked={isChecked}
      />
      <RadioIndicator isSelected={isChecked} />
      <RadioLabel htmlFor={id}>
        <span>{children}</span>
      </RadioLabel>
    </RadioItem>
  );
}

RadioGroup.Item = Item;
export { RadioGroup };
