import { types } from "@telia-no-min-side/utils";
import { productId } from "src/util/productIds";

export type AvailableSmartWifiProducts = {
  teliaWifiRouter?: types.fixed.ProductGroupSingleProductWithShipping;
  smartWifiRouter?: types.fixed.ProductGroupSingleProductWithShipping;
  smartWifiExtender?: types.fixed.ProductGroupSingleProductWithShipping;
};

export function getAvailableSmartWifiProducts(
  wifi: types.fixed.ProductGroupWithShipping | undefined
): AvailableSmartWifiProducts {
  const teliaWifiRouter = wifi?.find((product) => product.productId === productId.teliaWifiRouter);
  const smartWifiRouter = wifi?.find((product) => productId.smartWifiRouters.includes(product.productId));
  const smartWifiExtender = wifi?.find((product) => product.productId === productId.smartWifiExtender);
  return { teliaWifiRouter, smartWifiRouter, smartWifiExtender };
}
