export function getAddress(street: string) {
  const regex = /^(.*?)(?:\s(\d+)(?:\s*([A-Za-zÆØÅæøå]+)\b)?)?$/;
  const streetComponents = street.match(regex);

  const streetName = streetComponents?.[1] ? streetComponents[1] : "";
  const streetNumber = streetComponents?.[2] ? streetComponents[2] : "";
  const streetLetter = streetComponents?.[3] ? streetComponents[3] : "";

  const streetNumberAndLetter = `${streetNumber} ${streetLetter}`.trim();

  return { streetName, streetNumberAndLetter, streetNumber, streetLetter };
}
