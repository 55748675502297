import { Flex } from "@telia/teddy";
import { Accordion, Button, Container, Heading, Icon, Paragraph } from "@telia-no-min-side/components";
import { StreamingContainer, StreamingImage } from "../../styles/style";
import { types } from "@telia-no-min-side/utils";

type Props = {
  showActiveProductBadge: boolean;
  cardTitle: "Strømming og TV" | "Internett" | "Internett + Strømming og TV";
  cardSubTitle: string;
  price: number;
  chargePeriod: string;
  renderPeakContent: (isOpen: boolean, broadbandSpeed: number, tvPoints: number) => JSX.Element | null;
  broadbandSpeed: number;
  tvPoints: number;
  broadbandSalesText: string;
  tvSalesText: string;
  tvLogos: types.fixed.Logo[];
  hideSelectProductButton: boolean;
  onClick?: () => void;
};

export function ProductCardContent({
  showActiveProductBadge,
  cardTitle,
  cardSubTitle,
  price,
  chargePeriod,
  renderPeakContent,
  broadbandSpeed,
  tvPoints,
  broadbandSalesText,
  tvSalesText,
  tvLogos,
  hideSelectProductButton,
  onClick,
}: Props) {
  return (
    <Accordion>
      <Accordion.Item
        lozengeRight={showActiveProductBadge ? { label: "Du har denne pakken", status: "info" } : undefined}
      >
        <Accordion.Trigger>
          <>
            <Paragraph removeMargin>{cardTitle}</Paragraph>
            <Flex direction="row" justify="between" pt="50">
              <Heading tag="h3" variant="title-150">
                {cardSubTitle}
              </Heading>
              <Paragraph removeMargin>
                <em>{price},-</em>/{chargePeriod}
              </Paragraph>
            </Flex>
          </>
        </Accordion.Trigger>
        <Accordion.Content peakContent={({ isOpen }) => renderPeakContent(isOpen, broadbandSpeed, tvPoints)}>
          <div style={{ marginTop: "-16px" }}>
            <Flex direction="column" gap="150">
              <Paragraph removeMargin>
                <em>Inkludert</em>
              </Paragraph>
              {broadbandSpeed > 0 && (
                <Container flexDirection="row" gap>
                  <Icon icon="speedometer-dots" title="Bredbånd" />
                  <Paragraph>{broadbandSalesText}</Paragraph>
                </Container>
              )}
              {tvPoints > 0 && (
                <Flex direction="row" gap="100">
                  <Icon icon="entertainment" title="Tv" />
                  <Container flexDirection="column" gap>
                    <Paragraph removeMargin>{tvSalesText}</Paragraph>
                    <Flex direction="row" gap="100">
                      {tvLogos.length > 0 && (
                        <StreamingContainer>
                          {tvLogos.map((logo: types.fixed.Logo) => (
                            <StreamingImage key={logo.name} transparentBG>
                              <img alt={logo.name} src={logo.src} />
                            </StreamingImage>
                          ))}
                        </StreamingContainer>
                      )}
                    </Flex>
                  </Container>
                </Flex>
              )}

              {hideSelectProductButton ? null : (
                <Container flexDirection="row" justifyContent="flex-end" padding="vertical">
                  <Button variant="primary" onClick={onClick}>
                    Skreddersy pakke
                  </Button>
                </Container>
              )}
            </Flex>
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  );
}
