import { Box, Card, Flex, Icon, Text } from "@telia/teddy";
import { VasAdditionalProduct } from "./types";
import { Link } from "react-router-dom";
import { useSelectAccount } from "hooks/useSelectAccount";
import { isFreeOrIncluded } from "./utils/product";
import { ProductBadge } from "./ProductBadge";
import { ServiceImage } from "./ActiveVas";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { isTeliaSkyIncluded } from "./services-telia-sky/utils/isTeliaSkyIncluded";

type Props = {
  products: VasAdditionalProduct[];
};

export function BuyVas(props: Props) {
  const { selectedMobileAccount } = useSelectAccount();
  const { orders } = useOrderSubscription();

  return (
    <>
      {props.products?.map((product) => {
        const dataTrackingId = `vas-${product.productGroup?.toLowerCase()}-inactive`;
        const isTeliaSkyIncludedForFree = isTeliaSkyIncluded(product);
        const inOrder = orders.some(
          (order) => order.phoneNumber === selectedMobileAccount?.phoneNumber && order.vasProduct === product?.code
        );
        return (
          <Card
            layout="navigation-vertical"
            key={`${product.id}-${product.code}`}
            variant="white"
            bordered
            shadow
            data-tracking-id={dataTrackingId}
          >
            <Card.Slot align="top-right">
              <ProductBadge product={product} />
            </Card.Slot>
            <Card.Illustration>
              <ServiceImage type={product.productGroup} />
            </Card.Illustration>
            <Card.Heading as="h3" variant="title-100">
              {product.shortName}
            </Card.Heading>
            <Card.Content>
              <Text data-tracking-id={`additional-services-description-${product.productGroup?.toLowerCase()}`}>
                {product.shortDescription}
              </Text>
              {isTeliaSkyIncludedForFree && (
                <Text>
                  <em>Tjenesten må aktiveres før bruk.</em>
                </Text>
              )}
              {product.productGroup ? <Box asChild mr="auto" mt="auto"></Box> : null}
            </Card.Content>
            <Flex justify="end" width="100%">
              <Card.Button
                aria-label={inOrder ? "Se mer" : isFreeOrIncluded(product) ? "Aktiver" : "Bestill"}
                variant="secondary"
                iconOnly
                asChild
              >
                <Link to={`${product.productGroup}/${selectedMobileAccount?.phoneNumber}`}>
                  <Icon name="arrow-right" />
                </Link>
              </Card.Button>
            </Flex>
          </Card>
        );
      })}
    </>
  );
}
