import { TopupLocalType } from "pages/mobile/mobile-subscription-dashboard/types";
import { RoamingTopup, SpeedBoostTopup, DataBoostTopup, DomesticTopup, TopupCategory } from "src/gql/graphql";

export const isPaidTopupHandler = (
  topup: TopupLocalType
): topup is RoamingTopup | SpeedBoostTopup | DataBoostTopup | DomesticTopup =>
  topup.category === TopupCategory.Roaming ||
  topup.category === TopupCategory.SpeedBoost ||
  topup.category === TopupCategory.DataBoost ||
  topup.category === TopupCategory.Domestic;
