import { SwitchContainer } from "./components/SwitchSelfAssessment";
import { useSwitchAgreement } from "./hooks/useSwitchAgreements";
import { Container, Skeleton, Link } from "@telia-no-min-side/components";
import { SwitchLoading } from "./components/Common/SwitchLoading";
import { BackLink, Column, MainContainer, ScrollableColumn } from "./styles/commonStyles";

export function SwitchSelfAssessment() {
  const { loading } = useSwitchAgreement();

  if (loading) {
    return (
      <MainContainer>
        <Column gap flexDirection="column" width="unset" horizontalPosition="unset">
          <BackLink padding="horizontal">
            <Link href="/minside/mobil/avtaler" icon="arrow-left" iconPosition="before" isInternalNavigation>
              Tilbake
            </Link>
          </BackLink>
          <Container flexDirection="column" gap="lg" maxWidth="xs" padding="horizontal">
            <Skeleton height="200px" width="100%" variant="rectangular" />
          </Container>
        </Column>
        <ScrollableColumn>
          <Container flexDirection="column" gap maxWidth="xs" padding="horizontal">
            <SwitchLoading />
            <Skeleton height="100px" width="100%" variant="rectangular" />
            <Skeleton height="100px" width="100%" variant="rectangular" />
            <Skeleton height="100px" width="100%" variant="rectangular" />
            <Skeleton height="100px" width="100%" variant="rectangular" />
          </Container>
        </ScrollableColumn>
      </MainContainer>
    );
  }

  return <SwitchContainer />;
}
