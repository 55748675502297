import { Icon } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { remCalc } from "../utils/fonts";
import { IconProps } from ".";

export const StyledIcon = styled(Icon)<IconProps>`
  color: ${({ color }) => color};
  margin-top: ${({ alignTextTop }) => (alignTextTop ? "0.33rem" : "0")};
  ${({ size }) => {
    if (size === "sm") {
      return css`
        height: ${remCalc(20)};
        width: ${remCalc(20)};
      `;
    }
    if (size === "lg") {
      return css`
        height: ${remCalc(32)};
        width: ${remCalc(32)};
      `;
    }
    if (size === "md") {
      return css`
        height: ${remCalc(26)};
        width: ${remCalc(26)};
      `;
    }
  }}
`;
