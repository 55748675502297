import { useState } from "react";
import { Card, Container, Paragraph } from "@telia-no-min-side/components";
import { TablePagination } from "@telia/styleguide";
import dayjs from "dayjs";
import { useFetchInvoiceDetails, useFetchInvoicesSettings } from "src/api/fixed";
import { useSelectAccount } from "src/hooks/useSelectAccount";
import { useInvoice } from "src/store/hooks/invoice-context";
import { InvoiceCardPaid } from "../InvoiceCardPaid";
import { CurrentInvoiceCard } from "../components/CurrentInvoiceCard";
import { InvoiceLoader } from "../components/InvoiceLoader";
import { NotPayingOwnInvoiceCard } from "../components/NotPayingOwnInvoiceCard";
import { PayByCardButton } from "../components/PayByCardButton";
import { FaqComponentFixed } from "./FaqComponentFixed";
import { PayByCardFixed } from "./PayByCardFixed";
import { PreviousInvoiceListFixed } from "./PreviousInvoiceListFixed";
import { PostponeInvoice } from "./PostponeInvoice";
import { shouldAllowPostponing } from "util/fixed/invoice";

export function InvoiceCardOption() {
  const [isPostoneInvoiceModalOpen, setIsPostoneInvoiceModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const { selectedFixedAccount } = useSelectAccount();
  const { lastInvoice, canSeeInvoice, financialIsLoading, isProfileLoading, lastInvoiceIsLoading, financial } =
    useInvoice();
  const invoiceId = lastInvoice?.invoice.invoiceId.toString() ?? "";
  const invoiceDetails = useFetchInvoiceDetails(invoiceId);

  const invoiceSettings = useFetchInvoicesSettings(lastInvoice?.financialAccountId);

  const payByCard = () => {
    setIsPaymentModalOpen(true);
  };
  const { invoicePageCursor, CURSOR_STEP, onPageChange, invoiceHistoryIsLoading, invoiceHistory } = useInvoice();

  if (
    isProfileLoading ||
    financialIsLoading ||
    (lastInvoiceIsLoading && !lastInvoice) ||
    (invoiceDetails.isLoading && !invoiceDetails.data) ||
    (invoiceSettings.isLoading && !invoiceSettings.data)
  ) {
    return (
      <Container maxWidth="sm">
        <InvoiceLoader />
      </Container>
    );
  }

  if (!canSeeInvoice) {
    return (
      <Container flexDirection="column" gap maxWidth="sm">
        <Card padding="lg">
          <Paragraph>Du får faktura for våre tjenester fra din lokale partner.</Paragraph>
        </Card>
      </Container>
    );
  }

  if (!invoiceDetails.data && !invoiceHistoryIsLoading && !invoiceHistory?.invoiceHistories.length) {
    return <NotPayingOwnInvoiceCard />;
  }

  if (!invoiceDetails.data) return null;

  const canBePaidOnline = invoiceDetails.data?.canBePaidOnline;
  const paymentOverdue =
    !invoiceDetails.data?.paid &&
    !!invoiceDetails.data?.dueDate &&
    dayjs().isAfter(dayjs.unix(invoiceDetails.data?.dueDate), "day");

  return (
    <Container flexDirection="column" gap maxWidth="sm">
      {invoiceDetails.data.paid ? (
        <InvoiceCardPaid
          accountId={`${selectedFixedAccount?.data?.customerId ?? ""}`}
          distribution={invoiceSettings.data?.name ?? ""}
        />
      ) : (
        <CurrentInvoiceCard
          accountId={`${selectedFixedAccount?.data?.customerId ?? ""}`}
          kidNumber={invoiceDetails.data?.kidNumber ?? ""}
          currentInvoiceAmount={invoiceDetails.data?.sumOfDetails ?? 0}
          distribution={invoiceSettings.data?.name}
          bankAccountNumber={invoiceDetails.data?.bankAccount ?? ""}
          paymentDate={dayjs.unix(invoiceDetails.data?.dueDate || 0).format("D. MMMM")}
          invoiceId={invoiceId}
          paymentOverdue={paymentOverdue}
          setModalOpen={setIsPostoneInvoiceModalOpen}
          isPostponingDisabled={!shouldAllowPostponing(lastInvoice, invoiceDetails.data, financial)}
        >
          {canBePaidOnline && !isPaymentDone && <PayByCardButton onClick={payByCard} />}
        </CurrentInvoiceCard>
      )}
      <PreviousInvoiceListFixed invoices={invoiceHistory?.invoiceHistories} isLoading={invoiceHistoryIsLoading} />
      <Container>
        <TablePagination
          dataLength={invoicePageCursor.max}
          from={invoicePageCursor.start}
          onPageChange={onPageChange}
          perPage={CURSOR_STEP}
          to={invoicePageCursor.end > invoicePageCursor.max ? invoicePageCursor.max : invoicePageCursor.end}
        />
      </Container>
      {!invoiceDetails.data.paid && (
        <PayByCardFixed
          isPaymentModalOpen={isPaymentModalOpen}
          setIsPaymentModalOpen={setIsPaymentModalOpen}
          setIsPaymentDone={setIsPaymentDone}
        />
      )}
      <FaqComponentFixed />
      <PostponeInvoice
        setIsModalOpen={setIsPostoneInvoiceModalOpen}
        modalOpen={isPostoneInvoiceModalOpen}
        dueDate={invoiceDetails.data?.dueDate}
      />
    </Container>
  );
}
