import { AdditionalProduct } from "src/gql/graphql";

export const getProductCharacteristics = (product: AdditionalProduct, value: string) => {
  const characteristics = product.characteristic?.find((item) => item?.featureCode === value);

  if (characteristics) {
    return characteristics.config?.map((characteristic) => ({
      characteristicType: characteristic?.characteristicType,
      name: characteristic?.name,
      value: characteristic?.value,
    }));
  }
  return [];
};
