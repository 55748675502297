import { UserOverviewDataQuery } from "src/gql/graphql";

type MobileAccounts = UserOverviewDataQuery["accounts"];

export const getMobileSubscriptionsByAccountId = (accounts: MobileAccounts, accountId: string) => {
  const account = accounts?.find((account) => account.id === accountId);
  if (!account) return [];

  return (account.subscriptions || []).filter((subscription) => subscription?.phoneNumber.localNumber.length === 8);
};
