import { Flex, Grid, Heading, Box } from "@telia/teddy";
import { config } from "util/config";
import { AdminSection } from "./AdminSection";
import { PrepaidBalance } from "pages/prepaid/balance";
import { PrepaidUsageInfo } from "pages/prepaid/usage-info";
import { DataUsageCard } from "../DataUsageCard/DataUsageCard";
import { useMobileBroadbandContext } from "../../context/MobileBroadbandContext";

export function UsageAndAdminSection() {
  const { isPrepaid } = useMobileBroadbandContext();

  return isPrepaid ? (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100">
        Forbruk
      </Heading>
      {config.showNewPrepaidPage ? <PrepaidUsageInfo isMBB /> : <PrepaidBalance />}
      <Box mt="200">
        <AdminSection itemColumns={{ xs: "1", md: "2" }} />
      </Box>
    </Flex>
  ) : (
    <Grid columns={{ xs: "1", md: "2" }} gap="gutter-xl" maxWidth="720px">
      <Flex direction="column" gap="gutter-sm">
        <Heading as="h3" variant="title-100">
          Forbruk
        </Heading>
        <DataUsageCard />
      </Flex>
      <AdminSection />
    </Grid>
  );
}
