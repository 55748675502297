export function truncateText(text: string, charLimit: number): string {
  if (!text || charLimit <= 0) return "";
  if (text.length <= charLimit) return text;

  const truncated = text.slice(0, charLimit);
  const lastSpaceIndex = truncated.lastIndexOf(" "); // Find the last space for clean word cutoff

  return lastSpaceIndex !== -1
    ? `${truncated.slice(0, lastSpaceIndex)}...` // Truncate at the last space
    : `${truncated}...`; // Fallback if no spaces found
}
