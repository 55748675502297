import { uri } from "@telia-no-min-side/utils";
import { Navigate, Outlet } from "react-router-dom";
import "./App.less";
import { MobileSubscriptionAdd } from "./pages/mobile/mobile-subscription-add";
import { MobileSubscriptionAddCommitment } from "./pages/mobile/mobile-subscription-add-commitment";
import { MobileSubscriptionAddRedir } from "./pages/mobile/mobile-subscription-add-redir";
import { MobileSubscriptionChange } from "./pages/mobile/mobile-subscription-change";
import { MobileSubscriptionChangeRedir } from "./pages/mobile/mobile-subscription-change-redir";
import { MobileSubscriptionConfirm } from "./pages/mobile/mobile-subscription-confirm";
import { MobileSubscriptionAgreements } from "pages/mobile/mobile-subscription-agreements";
import { SwitchSelfAssessment } from "./pages/mobile/mobile-switch-selfassessment";
import { MobileSubscriptionDashboardLayout } from "./pages/mobile/mobile-subscription-dashboard-layout";
import { MobileSubscriptionInfo } from "./pages/mobile/mobile-subscription-info";
import { MutationResponseProvider } from "./hooks/useMutationResponse";
import { MobileSubscriptionActivateSimCard } from "./pages/mobile/mobile-subscription-activate-sim-card";
import { MobileSubscriptionOrderAdditionalSimCard } from "./pages/mobile/mobile-subscription-order-additional-sim-card";
import { MobileSubscriptionOrderBlankSimCard } from "./pages/mobile/mobile-subscription-order-blank-sim-card";
import { SuspendSubscriptionPage } from "./pages/mobile/mobile-subscription-suspend/SuspendSubscriptionPage";
import { UnsuspendSubscriptionPage } from "./pages/mobile/mobile-subscription-unsuspend/UnsuspendSubscriptionPage";
import { SwitchOptions } from "pages/mobile/mobile-switch-selfassessment/components/Common/SwitchOptions";
import { StolenPhone } from "pages/mobile/mobile-switch-selfassessment/components/StolenPhone";
import { ScreenInsurance } from "pages/mobile/mobile-switch-selfassessment/components/ScreenDamage";
import { NoDamage } from "pages/mobile/mobile-switch-selfassessment/components/NoDamage/NoDamage";
import { OtherDamage } from "pages/mobile/mobile-switch-selfassessment/components/OtherDamage";
import { InsuranceProcessed } from "pages/mobile/mobile-switch-selfassessment/components/InsuranceClaim/InsuranceProcessed";
import { SimCardOrderStatusMemoized } from "pages/mobile/mobile-subscription-order-additional-sim-card/components/SimCardOrderStatus";
import { InsuranceProofDocumentPage } from "pages/mobile/mobile-subscription-agreements/components/insurance/InsuranceProofDocumentPage";
import { Overview } from "pages/b2b-family-discount/Overview";
import { OrderSubscription } from "pages/b2b-family-discount/OrderSubscription";
import { B2BFamilyDiscount } from "pages/b2b-family-discount";
import { MobileSubscriptionAvailable } from "pages/b2b-family-discount/mobile-subscription-available";
import { Error } from "pages/b2b-family-discount/components/Error";
import { AdditionalProductsProvider } from "hooks/useAdditionalProducts";
import { MobileSubscriptionProfile } from "pages/mobile/mobile-subscription-profile";
import { MobileSubscriptionSettings } from "pages/mobile/mobile-subscription-settings";
import { MobileSubscriptionDashboard } from "pages/mobile/mobile-subscription-dashboard";
import { MobileSubscriptionSimPinPuk } from "pages/mobile/mobile-subscription-sim-pin-puk";
import { MobileSubscriptionAccountOverview } from "./pages/mobile/mobile-subscription-account-overview";
import { MobileSubscriptionDataUsageHistory } from "pages/mobile/mobile-subscription-data-usage-history";
import { PrepaidOrderHistory } from "pages/prepaid/order-history";
import { PrepaidBalance } from "pages/prepaid/balance";

export const mobileRoutes = [
  {
    path: "mobil",
    handle: {
      crumb: "Mobil",
    },
    children: [
      {
        path: "bedrift-familierabatt",
        children: [
          { index: true, element: <B2BFamilyDiscount /> },
          {
            path: ":b2bPhoneNumber",
            children: [
              { path: ":b2cAccountId/oversikt", element: <Overview /> },
              { path: "velg-abonnement", element: <MobileSubscriptionAvailable /> },
              { path: ":pricePlan/bestill", element: <OrderSubscription /> },
            ],
          },
          { path: "feil", element: <Error /> },
        ],
      },
      {
        path: "avtaler",
        element: <MobileSubscriptionAgreements />,
        handle: {
          crumb: "Avtaler",
        },
      },
      {
        path: "avtaler/svitsj/evaluering/:accountId/:svitsjId",
        element: <SwitchSelfAssessment />,
        handle: {
          crumb: "Svitsj evaluering",
        },
        children: [
          { index: true, element: <SwitchOptions /> },
          { path: "ingen-skader", element: <NoDamage /> },
          { path: "skader-på-skjerm", element: <ScreenInsurance /> },
          { path: "skader-på-telefonen", element: <OtherDamage /> },
          { path: "stjålet-eller-mistet", element: <StolenPhone /> },
          { path: "forsikringssak-er-ferdigbehandlet", element: <InsuranceProcessed /> },
        ],
      },
      {
        element: <MobileSubscriptionDashboardLayout />,
        children: [
          {
            index: true,
            element: (
              <AdditionalProductsProvider>
                <MutationResponseProvider>
                  <MobileSubscriptionDashboard />
                </MutationResponseProvider>
              </AdditionalProductsProvider>
            ),
          },
          {
            path: "tidligere-dataforbruk",
            element: <MobileSubscriptionDataUsageHistory />,
            handle: {
              crumb: "Tidligere dataforbruk",
            },
          },
          {
            // index: true, // Redirect when accessing /mobil
            path: "forbruk",
            element: <Navigate to={uri.minSideFixed("/mobil")} replace />,
          },
          {
            path: "info",
            element: <MobileSubscriptionInfo />,
            handle: {
              crumb: "Info",
            },
          },
          {
            path: "saldo",
            element: <PrepaidBalance />,
            handle: {
              crumb: "Saldo",
            },
          },
          {
            path: "sim-pin-puk",
            element: (
              <MutationResponseProvider>
                <Outlet />
              </MutationResponseProvider>
            ),
            handle: {
              crumb: "SIM, PIN & PUK",
            },
            children: [
              { index: true, element: <MobileSubscriptionSimPinPuk /> },
              {
                path: "bestill-erstatning",
                element: <MobileSubscriptionOrderBlankSimCard />,
                handle: {
                  crumb: "Bestill erstatning",
                },
              },
              {
                path: "aktivere-sim",
                element: <MobileSubscriptionActivateSimCard />,
                handle: {
                  crumb: "Aktivere SIM",
                },
              },
              {
                path: "bestill-sim",
                element: <MobileSubscriptionOrderAdditionalSimCard />,
                handle: {
                  crumb: "Bestill SIM",
                },
              },
              {
                path: "ordre-status/:phoneNumber/:orderId",
                element: <SimCardOrderStatusMemoized />,
                handle: {
                  crumb: "Ordre status",
                },
              },
            ],
          },
          {
            path: "innstillinger",
            element: <MobileSubscriptionSettings />,
            handle: {
              crumb: "Innstillinger",
            },
          },
          {
            path: "kjopshistorikk",
            element: <PrepaidOrderHistory />,
            handle: {
              crumb: "Kjøpshistorikk",
            },
          },
          {
            path: "profil",
            element: <MobileSubscriptionProfile />,
            handle: {
              crumb: "Kontaktinformasjon",
            },
          },
        ],
      },
      {
        path: "bestill-arsrabatt/:phoneNumber",
        element: <MobileSubscriptionAddCommitment />,
        handle: {
          crumb: "Bestill årsrabatt",
        },
      },
      { path: "legg-til", element: <MobileSubscriptionAddRedir /> },
      {
        path: "legg-til/:accountId",
        element: <MobileSubscriptionAdd />,
        handle: {
          crumb: "Legg till",
        },
      },
      {
        path: "legg-til/:accountId/:selectedOfferType",
        element: <MobileSubscriptionAdd />,
        handle: {
          crumb: "Legg till",
        },
      },
      {
        path: "endre",
        element: <MobileSubscriptionChangeRedir />,
      },
      {
        path: "endre/:accountId",
        handle: {
          crumb: "Endre",
        },
        children: [
          {
            path: ":phoneNumber",
            handle: {
              crumb: "Endre",
            },
            children: [
              { index: true, element: <MobileSubscriptionChange /> },
              {
                path: "bekreft",
                element: <MobileSubscriptionConfirm />,
                handle: {
                  crumb: "Bekreft",
                },
              },
            ],
          },
          {
            path: ":phoneNumber/:selectedOfferType",
            handle: {
              crumb: "Endre",
            },
            children: [
              { index: true, element: <MobileSubscriptionChange /> },
              {
                path: "bekreft",
                element: <MobileSubscriptionConfirm />,
                handle: {
                  crumb: "Bekreft",
                },
              },
            ],
          },
        ],
      },
      {
        path: "mine-abonnement",
        handle: {
          crumb: "Mine abonnement",
        },
        children: [
          { index: true, element: <MobileSubscriptionAccountOverview /> },
          {
            path: "legg-til/:accountId",
            element: <MobileSubscriptionAdd />,
            handle: {
              crumb: "Legg til",
            },
          },
          {
            path: "endre/:accountId",
            handle: {
              crumb: "Endre",
            },
            children: [
              { index: true, element: <MobileSubscriptionAdd /> },
              {
                path: ":phoneNumber",
                children: [
                  { index: true, element: <MobileSubscriptionChange /> },
                  {
                    path: "bekreft",
                    element: <MobileSubscriptionConfirm />,
                    handle: {
                      crumb: "Bekreft",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "sperr-abonnement",
        element: <SuspendSubscriptionPage />,
        handle: {
          crumb: "Sperr abonnement",
        },
      },
      {
        path: "gjenaapne-abonnement",
        element: <UnsuspendSubscriptionPage />,
        handle: {
          crumb: "Gjenaapne abonnement",
        },
      },
      {
        path: "forsikringsbevis/:contractId",
        element: <InsuranceProofDocumentPage />,
        handle: {
          crumb: "Forsikringsbevis",
        },
      },
    ],
  },
];
