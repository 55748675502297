import { Box, BoxProps, breakpoint } from "@telia/teddy";

export function PageContainer({ children, ...rest }: BoxProps) {
  return (
    <Box
      pl={{ sm: "page-padding-sm", md: "page-padding-md", lg: "page-padding-lg", xl: "page-padding-xl" }}
      pr={{ sm: "page-padding-sm", md: "page-padding-md", lg: "page-padding-lg", xl: "page-padding-xl" }}
      pb={{ sm: "300", lg: "600" }}
      maxWidth={breakpoint.teddyBreakpointLg}
      data-tracking-id="page-container"
      {...rest}
    >
      {children}
    </Box>
  );
}
