import React, { HTMLAttributes, ReactNode } from "react";
import { StyledHeading } from "./style";

export type HeadingProps = {
  tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children: ReactNode;
  removeMargin?: boolean;
  textAlign?: AlignSetting;
  ellipsisOverflow?: boolean;
  color?: string;
  lineThrough?: boolean;
  variant:
    | "display-100"
    | "display-200"
    | "subsection-100"
    | "title-75"
    | "title-100"
    | "title-150"
    | "title-200"
    | "title-200-light"
    | "title-300"
    | "title-400"
    | "title-500"
    | "title-600"
    | "title-700";
} & HTMLAttributes<HTMLHeadingElement>;

/**
 * @deprecated Use Teddy components instead
 */
export function Heading({
  children,
  className,
  tag,
  removeMargin = false,
  variant,
  ...headingArgs
}: HeadingProps): JSX.Element {
  return (
    <StyledHeading as={tag} variant={variant} className={className} removeMargin={removeMargin} {...headingArgs}>
      {children}
    </StyledHeading>
  );
}
