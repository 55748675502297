import { Maybe, AdditionalProperty } from "gql/graphql";
import { IncludedPropertiesCode, IncludedPropertiesCodeNEW } from "./variables";

export function getIncludedProperties(additionalProperties: Maybe<Maybe<AdditionalProperty>[]> | undefined) {
  return IncludedPropertiesCode.filter(
    ({ code }) =>
      additionalProperties?.some(
        (additionalProperty) => additionalProperty?.name === code && additionalProperty?.value === "true"
      )
  );
}

export function getIncludedPropertiesNEW(additionalProperties: Maybe<Maybe<AdditionalProperty>[]> | undefined) {
  return IncludedPropertiesCodeNEW.filter(
    ({ code }) =>
      additionalProperties?.some(
        (additionalProperty) => additionalProperty?.name === code && additionalProperty?.value === "true"
      )
  );
}
