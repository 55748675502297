import { Accordion, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";

export function Efaktura(): JSX.Element {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Kom i gang med eFaktura</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          For å få eFaktura må du gi en generell aksept for dette i nettbanken eller betalingsappen din. Dette blir kalt
          «Ja takk til alle», «Automatisk eFaktura», «Alltid eFaktura» eller «eFaktura fra alle», avhengig av hvilken
          bank eller betalingsapp du bruker. Når dette er aktivert, vil du automatisk få eFaktura på fremtidige faktura
          fra alle som tilbyr dette - du trenger med andre ord kun bestille eFaktura én gang!
        </Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
