import { graphql } from "gql";

export const CREATE_B2C_ACCOUNT = graphql(`
  mutation createB2CAccount($phoneNumber: String!) {
    accountCreate(phoneNumber: $phoneNumber) {
      orderId
    }
  }
`);

export const ORDER_FAMILY_DISCOUNT = graphql(`
  mutation orderFamilyDiscountB2cAccount($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);
