import { useMutation } from "@apollo/client";
import { graphql } from "src/gql";

const SEND_GDPR_REQUEST = graphql(`
  mutation sendGDPRRequest($input: GDPRInput!) {
    gdpr(input: $input) {
      id
    }
  }
`);

export const useObjection = () => {
  const [sendGDPRRequest, { loading, error }] = useMutation(SEND_GDPR_REQUEST);
  return { sendGDPRRequest, loading, error };
};
