import { Dispatch, SetStateAction, useState, useRef, useEffect } from "react";

export function useCountUp({
  initialValue = 0,
  intervalMs = 1000, // default interval of 1 second
  max = Infinity,
  step = 1,
} = {}): [number, Dispatch<SetStateAction<number>>] {
  const [count, setCount] = useState<number>(initialValue);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    // Only schedule the next update if count is below max.
    if (count < max) {
      timeoutRef.current = setTimeout(() => {
        setCount((prevCount) => {
          const newCount = prevCount + step;
          // Clamp the new count at max.
          return newCount >= max ? max : newCount;
        });
      }, intervalMs);
    }

    // Cleanup the timeout if the effect is re-run or the component unmounts.
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [count, intervalMs, max, step]);

  return [count, setCount];
}
