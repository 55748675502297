import { graphql } from "src/gql";

export const GET_SIM_DATA = graphql(`
  query getSimData($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      productUser {
        birthDate
      }
      roles
      mainSimcard {
        simCardNumber
        initialPin
        pukCode
        imsi
        imei
        isEsim
        lastUsedDevice
      }
      additionalSimcards {
        lastUsedDevice
        simCardNumber
        initialPin
        pukCode
        imsi
        imei
        isEsim
        product {
          code
          name
          shortName
          characteristic {
            config {
              name
              value
            }
          }
          price {
            price
            priceAggregated
            priceWithoutDiscount
            discountDuration
          }
        }
      }
      additionalProducts {
        id
        code
        productCategory
        presentation {
          modifiable
          active
        }
      }
    }
  }
`);
