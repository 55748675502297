import { Flex, Heading } from "@telia/teddy";
import { DataUsageCard } from "../DataUsageCard/DataUsageCard";
import { useMobileDashboardContext } from "../../context/MobileDashboardContext";
import { config } from "util/config";
import { PrepaidUsageInfo } from "pages/prepaid/usage-info";
import { PrepaidBalance } from "pages/prepaid/balance";

export function UsageSection() {
  const { isPrepaid } = useMobileDashboardContext();
  return (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100" style={{ fontWeight: "500" }}>
        Forbruk
      </Heading>
      {isPrepaid ? config.showNewPrepaidPage ? <PrepaidUsageInfo /> : <PrepaidBalance /> : <DataUsageCard />}
    </Flex>
  );
}
