import { devices } from "@telia-no-min-side/components";
import { colors, Lozenge, Paragraph } from "@telia/styleguide";
import styled from "styled-components";
import { InputHeadline } from "./common-styles";

export const AddressHeadline = styled(InputHeadline)`
  font-size: 0.75rem;
  margin-top: 1rem;
`;

export const AddressText = styled(InputHeadline)`
  text-transform: capitalize;
`;

export const SelectedInvoiceLozenge = styled(Lozenge)`
  transform: translateY(-200%);
  margin-left: 12rem;
`;

export const CardHeadlineWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 1fr;

  @media ${devices.xxs_down} {
    grid-template-columns: 9rem 3.5rem 5.5rem;
  }
`;

export const CardHeader = styled.p<{ isOpen?: boolean }>`
  font-size: 1.125rem;
  margin-top: ${(props) => (props.isOpen ? "0.9rem" : "0")};
  margin-bottom: 0;

  font-weight: 500;
  align-self: center;
`;

export const CardHeaderButton = styled(Paragraph)<{
  selected?: boolean;
}>`
  font-weight: 500;
  justify-content: end;
  color: ${colors.corePurple700};
  margin-top: ${(props) => (props.selected ? "1.5rem" : "1rem")};
  display: flex;
`;

export const AdditionalInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 11rem 11rem;

  @media ${devices.xxs_down} {
    grid-template-columns: 9rem 9rem;
  }
`;

export const AddressWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin-top: 1rem;
`;

export const BLOCKSPACE = styled.div`
  height: 1rem;
`;
