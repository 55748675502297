import { Button, Container, Modal, ModalBody, ModalFooter, RadioCardGroup } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import { Dispatch, useState } from "react";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
type Props = {
  accountBalance: number;
  oldestUnpaidInvoice: number;
  onConfirm: (amountToPay: number) => void;
  isPaymentModalOpen: boolean;
  setIsPaymentModalOpen: Dispatch<boolean>;
};

export function PaymentAmountModal({
  accountBalance,
  isPaymentModalOpen,
  setIsPaymentModalOpen,
  oldestUnpaidInvoice,
  onConfirm,
}: Props) {
  const [selectedAmountToPay, setSelectedAmountToPay] = useState<number>(accountBalance);
  return (
    <Modal isOpen={isPaymentModalOpen} setIsOpen={setIsPaymentModalOpen} dataTrackingID="payment-amount-modal">
      <ModalBody>
        <Container padding="vertical">
          <RadioCardGroup
            value={String(selectedAmountToPay)}
            onValueChange={(v) => {
              const amount = Number(v);
              if (amount >= 0) {
                setSelectedAmountToPay(amount);
              }
            }}
            name="select-amount-to-pay"
            legend="Velg beløp du vil betale"
          >
            <RadioCardGroup.Item value={String(accountBalance)} title="Samlet utestående beløp">
              {format.formatPrice(accountBalance)}
            </RadioCardGroup.Item>
            <RadioCardGroup.Item value={String(oldestUnpaidInvoice)} title="Kun den eldste fakturaen">
              {format.formatPrice(oldestUnpaidInvoice)}
            </RadioCardGroup.Item>
          </RadioCardGroup>
        </Container>

        <ModalFooter flex>
          <Button variant="tertiary-purple" onClick={() => setIsPaymentModalOpen(false)}>
            Avbryt
          </Button>
          <Button
            trackEvent={{
              ui_item_action: `Pay ${
                selectedAmountToPay === oldestUnpaidInvoice ? "Oldest Invoice" : "Outstanding Balance"
              }`,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.PAY_INVOICE,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.PAY_NOW,
            }}
            onClick={() => {
              onConfirm(selectedAmountToPay);
            }}
          >
            Betal nå
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}
