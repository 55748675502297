import { graphql } from "src/gql";

export const GET_ACCOUNT_AGREEMENTS = graphql(`
  query getAccountAgreements($accountId: String!) {
    account(accountId: $accountId) {
      id
      roles
      legalOwner {
        individual {
          firstName
        }
      }
      switchAgreements(loadInsuranceInfo: true) {
        id
        ...SwitchAgreementFields
      }
      insuranceAgreements {
        serviceAgreementId
        ...InsuranceAgreementFields
      }
      leaseAgreements {
        leases {
          id
          ...LeaseAgreementFields
        }
      }

      subscriptions {
        phoneNumber {
          localNumber
        }
        productUser {
          firstName
        }
        roles
        switchAgreements(loadInsuranceInfo: true) {
          id
          ...SwitchAgreementFields
        }
        insuranceAgreements {
          serviceAgreementId
          ...InsuranceAgreementFields
        }
        leaseAgreements {
          leases {
            id
            ...LeaseAgreementFields
          }
        }
      }
    }
  }
`);
