import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";

export const SuccessContent = () => {
  return (
    <Container flexDirection="column" floatContent="left" gap padding="bottom" maxWidth="sm">
      <Heading tag="h1" variant="title-300">
        Takk for din bestilling!
      </Heading>

      <Paragraph>Din årsrabatt er lagt til.</Paragraph>

      <Paragraph>Du vil se endringer på Min Side etter kort tid.</Paragraph>

      <Button tag="a" makeReturnUrl isInternalNavigation href="/minside" icon="arrow-left">
        Tilbake til oversikten
      </Button>
    </Container>
  );
};
