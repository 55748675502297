import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { breakpoints } from "../utils/breakpoints";
import { zIndex } from "../utils/variables";
import { ToastElement } from "./Toast";
import { Toasts } from ".";

type ToastContainerProps = {
  toasts: Toasts;
};

export function ToastPortal({ toasts }: ToastContainerProps) {
  return createPortal(
    <div role="alert">
      {toasts.length > 0 && (
        <Wrapper>
          {toasts.map((item, i) => (
            <ToastElement key={item.id} index={i} {...item} />
          ))}
        </Wrapper>
      )}
    </div>,
    document.body
  );
}

const Wrapper = styled.div`
  position: fixed;
  inset: 0 0 auto 0;
  transition: height 0.5s ease;
  overflow-x: visible;
  padding: 1rem 1rem 2rem;
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(auto, ${breakpoints.xxs}px);
  z-index: ${zIndex.modal};
  pointer-events: none;
`;
