import { useFetchUser } from "src/api/fixed";
import { types, uri } from "@telia-no-min-side/utils";
import { DashboardSection } from "../card/DashboardSection";
import { useSubscriptionOverview } from "../hooks/useSubscriptionOverview";
import { useAxiosSWR } from "@telia-no-min-side/components";
import { BroadbandSubscriptions } from "./components-fixed/BroadbandSubscriptions";
import { MobileBroadbandSubscriptions } from "./components-mobile/MobileBroadbandSubscriptions";
import { hasAnyFixedBroadbandProducts } from "./utils/hasAnyFixedBroadbandProducts";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { BlueConicActionCard } from "../highlights/personal/BlueConicActionCard";
import { useBlueConic } from "hooks/useBlueConic";
import { BlueConicPromoList } from "../highlights/personal/BlueConicPromoList";
import { config } from "util/config";

export function AllBroadbandSubscriptions() {
  // fixed broadband
  const products = useAxiosSWR<types.fixed.ProductCategoryAll>(
    uri.minSideFixed(ICX_REQUEST.URL_ALL_BROADBAND_SUBSCRIPTION)
  );
  const user = useFetchUser();
  const { bcBroadband } = useBlueConic();
  // mobile broadband
  const subscriptionOverview = useSubscriptionOverview();

  const isMobileBroadbandLoading = subscriptionOverview.loading && !subscriptionOverview.hasAnyAccounts;
  const isFixedBroadbandLoading = products.isLoading && !products.data;
  const isAllBroadbandLoading = isMobileBroadbandLoading && isFixedBroadbandLoading;

  const hasFixedBroadband = hasAnyFixedBroadbandProducts(products.data);
  const hasMobileBroadband = subscriptionOverview.mbbSubscriptionsOverview.length > 0;

  if (!(isMobileBroadbandLoading || isFixedBroadbandLoading) && !hasFixedBroadband && !hasMobileBroadband) {
    return null;
  }

  return (
    <DashboardSection title="Internett" isLoading={isAllBroadbandLoading}>
      <BroadbandSubscriptions
        user={user.data}
        products={products.data}
        isLoading={products.isLoading && !products.data}
        showError={products.error && !products.data}
        isRefetching={products.isValidating}
        onRefetch={products.mutate}
      />
      <MobileBroadbandSubscriptions
        subscriptions={subscriptionOverview.mbbSubscriptionsOverview}
        isLoading={subscriptionOverview.loading && !subscriptionOverview.hasAnyAccounts}
        showError={!!subscriptionOverview.error && !subscriptionOverview.hasAnyAccounts}
        isRefetching={subscriptionOverview.isRefetching}
        onRefetch={subscriptionOverview.refetch}
      />
      {config.isNewBlueconicEnabled ? (
        <BlueConicPromoList type="broadband" />
      ) : (
        <BlueConicActionCard message={bcBroadband} />
      )}
    </DashboardSection>
  );
}
