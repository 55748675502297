import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { SideMenuTop } from "@telia/styleguide";
import { MenuLinks } from "../utils/links";
import { OpenPagesIconLink } from "../OpenPagesIconLink";
import { useWindowSize } from "../../hooks";
import { SideMenuItems } from "./SideMenuItems";
import { BackDrop, LinkImageItem, PositionSideMenu, SideMenuContainer, StyledSideMenu } from "./style";

type Props = {
  menuLinks: MenuLinks;
  slideInSideMenuButtonClick: boolean;
  setSlideInSideMenu: Dispatch<SetStateAction<boolean>>;
  limitedAccess?: boolean;
};

type SideMenuContextType = {
  menuLinks: MenuLinks;
  isCollapsed?: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean | undefined>>;
  openMenuGroup: string;
  setOpenMenuGroup: Dispatch<SetStateAction<string>>;
  isDeviceWithCollapseSupport: boolean;
  isDeviceWithSlideInSupport: boolean;
  setSlideInSideMenu: Dispatch<SetStateAction<boolean>>;
  limitedAccess: boolean;
};

const SideMenuContext = createContext<SideMenuContextType | undefined>(undefined);

export function useSideMenu() {
  const context = useContext(SideMenuContext);
  if (context === undefined) {
    throw new Error("useSideMenu must be used within a SideMenuProvider");
  }
  return context;
}

export const SideMenu: React.FC<Props> = (props) => {
  const { menuLinks, setSlideInSideMenu, slideInSideMenuButtonClick, limitedAccess } = props;

  const [isHover, setIsHover] = useState<boolean | undefined>(undefined);
  const [openMenuGroup, setOpenMenuGroup] = useState("");
  const { isMobile, isTablet } = useWindowSize();
  const isDeviceWithSlideInSupport = isMobile;
  const isDeviceWithCollapseSupport = isTablet && !isDeviceWithSlideInSupport;
  const isDeviceWithHoverSupport = window.matchMedia("(hover: hover) and (pointer: fine)").matches;
  const [isCollapsed, setIsCollapsed] = useState<boolean | undefined>(isDeviceWithCollapseSupport);

  const sideMenuContextValue: SideMenuContextType = {
    menuLinks,
    setIsCollapsed,
    isCollapsed,
    openMenuGroup,
    setOpenMenuGroup,
    setSlideInSideMenu,
    isDeviceWithSlideInSupport,
    isDeviceWithCollapseSupport,
    limitedAccess: !!limitedAccess,
  };

  function backDropClick() {
    if (isDeviceWithCollapseSupport) {
      setIsCollapsed(true);
    }
    if (isDeviceWithSlideInSupport) {
      setSlideInSideMenu(false);
    }
  }

  useEffect(() => {
    setIsCollapsed(isDeviceWithCollapseSupport);
  }, [isDeviceWithCollapseSupport]);

  useEffect(() => {
    if (isDeviceWithCollapseSupport && isCollapsed) {
      setOpenMenuGroup("");
    }
  }, [isDeviceWithCollapseSupport, isCollapsed]);

  useEffect(() => {
    if (isDeviceWithCollapseSupport && isDeviceWithHoverSupport) {
      setIsCollapsed(!isHover);
    }
  }, [isDeviceWithCollapseSupport, isDeviceWithHoverSupport, isHover]);

  const showBackdrop =
    (isDeviceWithCollapseSupport && !isDeviceWithHoverSupport && isCollapsed === false) ||
    (isDeviceWithSlideInSupport && slideInSideMenuButtonClick);

  return (
    <SideMenuContext.Provider value={sideMenuContextValue}>
      {showBackdrop && <BackDrop onClick={backDropClick} />}
      <SideMenuContainer onPointerEnter={() => setIsHover(true)} onPointerLeave={() => setIsHover(false)}>
        <PositionSideMenu fadeInFromRight={slideInSideMenuButtonClick} id="side-menu">
          <StyledSideMenu aria-labelledby="main_menu" collapse={isCollapsed}>
            <SideMenuTop>
              {!isMobile && (
                <LinkImageItem>
                  <OpenPagesIconLink isCollapsed={isCollapsed} />
                </LinkImageItem>
              )}
              <SideMenuItems />
            </SideMenuTop>
          </StyledSideMenu>
        </PositionSideMenu>
      </SideMenuContainer>
    </SideMenuContext.Provider>
  );
};
