import { Card, Container, Skeleton } from "@telia-no-min-side/components";

export function ShoppingCartSkeleton() {
  return (
    <Card maxWidth="sm">
      <Container flexDirection="column">
        <Container floatContent="center">
          <Skeleton variant="heading-s" width="10ch" />
        </Container>
        <Skeleton width="15ch" />
        <Skeleton width="25ch" />
        <Skeleton width="25ch" />
        <Skeleton width="20ch" />
        <Skeleton variant="button" float="right" width="10ch" />
      </Container>
    </Card>
  );
}
