import { useState } from "react";
import { useToast } from "@telia-no-min-side/components";
import { getSimplePriceText, uri } from "@telia-no-min-side/utils";
import { useMutation } from "@apollo/client";
import { Badge, Card, Icon, Text, Button, Flex, Box, Link, Heading } from "@telia/teddy";
import { useNavigate } from "react-router-dom";
import { PhoneLink } from "./styles/TryggProductPurchased.styled";
import { TryggCancelServiceModal } from "./TryggCancelServiceModal";
import { isB2BCompanyPaidTeliaTryggProduct } from "./utils/isB2BCompanyPaidTeliaTryggProduct";
import { config } from "src/util/config";
import { getTryggOrderInput } from "./utils/getTryggOrderInput";
import { AdditionalProduct } from "src/gql/graphql";
import { graphql } from "gql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { VAS_MESSAGE } from "util/constants/valueAddedServices";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { TryggProductFeatures } from "./TryggProductFeatures";

type ActiveTryggProps = {
  product: AdditionalProduct;
  phoneNumber: string;
};

const CancelTeliaTryggMutation = graphql(`
  mutation cancelTeliaTrygg($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);

export const TryggProductPurchased = ({ product, phoneNumber }: ActiveTryggProps) => {
  const isB2BTrygg = isB2BCompanyPaidTeliaTryggProduct(product);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { subscribeToOrder, orders } = useOrderSubscription();

  const [cancelTeliaTrygg, { loading }] = useMutation(CancelTeliaTryggMutation, {
    onCompleted(data) {
      setShowCancelModal(false);
      addToast({
        text: VAS_MESSAGE.CONFIRM_CANCELLATION_BY_EMAIL,
        variant: "success",
      });
      product.code &&
        data?.order.orderId &&
        subscribeToOrder({
          id: data?.order.orderId,
          phoneNumber: phoneNumber,
          vasProduct: product.code,
          toastText: VAS_MESSAGE.SERVICE_CANCELLED,
        });

      navigate(uri.minSideFixed("/tjenester"));
    },

    onError() {
      setShowCancelModal(false);
      addToast({
        text: VAS_MESSAGE.ERROR_OCCURED,
        variant: "error",
      });
    },
  });
  const cancelTrygg = () => {
    const orderInput = getTryggOrderInput(product, "");
    cancelTeliaTrygg({ variables: { input: orderInput, phoneNumber: phoneNumber } });
  };

  const getPriceText = (): { price: string; pricePrefix?: string } | null => {
    const price = getSimplePriceText(product.price?.priceWithoutDiscount ?? 0, "md.");

    if (isB2BTrygg) {
      return {
        pricePrefix: "Ordinær pris",
        price,
      };
    }

    return { price };
  };

  const priceText = getPriceText();

  const inOrder = orders.some((order) => order.vasProduct === product.code && order.phoneNumber === phoneNumber);

  return (
    <Flex
      width="100%"
      maxWidth="400px"
      direction="column"
      gap="gutter-lg"
      data-tracking-id="additional-services-trygg-product-purchased"
    >
      <Card variant="white" shadow pb="100" layout="product" bordered>
        <Card.Slot>
          <Badge variant="success" hideIcon>
            Aktiv
          </Badge>
        </Card.Slot>
        <Card.Heading as="h2">{product?.shortName}</Card.Heading>

        <Card.Content>
          <TryggProductFeatures />

          <Flex direction="column" gap="gutter-lg" mb="gutter-lg">
            <Card variant="gray">
              <Heading as="h3" variant="subsection-100">
                ID-forsikring og E-handelsforsikring levert av Tryg
              </Heading>

              <Text variant="paragraph-100">
                For å melde inn forsikringssak som gjelder ID-forsikring og E-handelforskring kontakt Tryg på tlf.
              </Text>

              <PhoneLink variant="standalone" href={`tel:${config.teliaTryggPhoneNumber}`} icon="phone">
                {config.teliaTryggPhoneNumber}
              </PhoneLink>
            </Card>

            <Card variant="gray">
              <Heading as="h3" variant="subsection-100">
                Trygghetsportal levert av F-Secure
              </Heading>

              <Text variant="paragraph-100">
                I Trygghetsportalen har du tilgang til ID-overvåkning og passordbehandling.
              </Text>

              <div>
                <Button variant="text" asChild>
                  <a href={EXTERNAL_LINK.TELIA_TRYGG_PORTAL} rel="noreferrer">
                    <Icon name="arrow-right" />
                    Gå til Trygghetsportalen
                  </a>
                </Button>
              </div>
            </Card>

            <Box pt="gutter-sm">
              <Link
                variant="text"
                href={product?.descriptionLink?.length ? product?.descriptionLink : `${config.teliaNoRootUrl}/trygg/`}
                target="_blank"
              >
                Les mer om Telia Trygg
              </Link>
            </Box>
          </Flex>
        </Card.Content>
        {priceText ? (
          <Card.Price
            priceText={priceText.price}
            pricePrefix={priceText.pricePrefix}
            variant="purple"
            textBelowPrice={isB2BTrygg ? "Betales av arbeidsgiver" : undefined}
          />
        ) : null}
      </Card>
      {!inOrder && (
        <div>
          <Button
            variant="secondary"
            onClick={() => {
              setShowCancelModal(true);
            }}
            disabled={loading}
            data-tracking-id="additional-services-telia-trygg-cancel-button"
          >
            Kanseller
          </Button>
        </div>
      )}
      <TryggCancelServiceModal
        loading={loading}
        isOpen={showCancelModal}
        setIsModalOpen={setShowCancelModal}
        onCancelProduct={cancelTrygg}
      />
    </Flex>
  );
};
