import { InsuranceAgreement } from "src/gql/graphql";
import { getProductTerm } from "./getProductTerm";
import { URL_INSURANCE_PROOF } from "util/constants/agreements";

export function getInsuranceProofUrl(productTerms: InsuranceAgreement["productTerms"]): string {
  const getContractId = getProductTerm("CONTRACT_ID", productTerms);

  if (!getContractId) return "";
  return `${URL_INSURANCE_PROOF}/${getContractId}`;
}
