import { Text } from "@telia/teddy";
import { format, types, uri } from "@telia-no-min-side/utils";
import { useSelectAccount } from "src/hooks/useSelectAccount";
import { config } from "src/util/config";
import { useFetchUser } from "src/api/fixed";
import { useFixedAccount } from "src/hooks/useFixedAccount";
import { FinanceCard } from "../card/FinanceCard";
import { AUTH } from "util/constants/auth";

type FinanceFixedProps = {
  userWithCustomerId: {
    financialAccounts: {
      lastInvoice: types.fixed.FinanceInvoice | null;
      financialAccountId: string;
      account: types.fixed.FinanceAccount;
    }[];
    customerId: string;
  };
};

export function FinanceFixed({ userWithCustomerId }: FinanceFixedProps) {
  const user = useFetchUser();
  const { setAccount } = useSelectAccount();
  const currentUser = user.data?.usersWithCustomerId.find(
    (customer: { customerId: string }) => customer.customerId === userWithCustomerId.customerId
  );
  const profile = useFixedAccount();
  const hasMultipleFinancialAccounts = userWithCustomerId.financialAccounts.length > 1;

  return (
    <>
      {userWithCustomerId.financialAccounts
        .sort(
          (financialAccountA, financialAccountB) =>
            Number(financialAccountA.financialAccountId) - Number(financialAccountB.financialAccountId)
        )
        .map((financialAccount) => {
          if (!financialAccount.lastInvoice) return null;
          return (
            <FinanceCard
              key={financialAccount.financialAccountId}
              isLastInvoicePaid={financialAccount.lastInvoice.balance <= 0}
              lastInvoiceAmount={financialAccount.lastInvoice.totalAmount}
              balance={financialAccount.account.balance}
              onLinkNavigation={() => {
                config.isNewInvoice
                  ? setAccount({ id: `${userWithCustomerId.customerId}`, accountType: AUTH.ACCOUNT_TYPE.FIXED })
                  : profile.changeFixedAccount(String(userWithCustomerId.customerId));
              }}
              buttonHref={uri.minSideFixed(`/faktura`)}
              cardHeading={
                hasMultipleFinancialAccounts
                  ? `TV/Internett (Konto ${financialAccount.financialAccountId})`
                  : "TV/Internett"
              }
              cardContent={
                <Text variant="additional-100" data-di-mask>
                  {format.startCase(currentUser?.street || "")}
                </Text>
              }
            />
          );
        })}
    </>
  );
}
