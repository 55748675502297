import { useQuery } from "@apollo/client";
import { uri } from "@telia-no-min-side/utils";
import { Navigate, useParams } from "react-router-dom";
import { graphql } from "src/gql";
import { Container, Skeleton } from "@telia-no-min-side/components";

import { Heading, Box, Card, breakpoint, Text } from "@telia/teddy";

import { MOBILE_VAS } from "util/constants/valueAddedServices";

export const ADD_VAS_REDIR_QUERY = graphql(`
  query AddVASRedir {
    subscriptions {
      phoneNumber {
        localNumber
      }
    }
  }
`);

export function VasDetailsRedir() {
  const addVas = useQuery(ADD_VAS_REDIR_QUERY);
  const params = useParams<{ productGroup: string }>();
  const productGroup = params.productGroup;

  const isValidVAS = Object.values(MOBILE_VAS).includes(productGroup as MOBILE_VAS);
  const hasOnlyOneSub = addVas.data?.subscriptions?.length === 1;

  if (!isValidVAS || (addVas.data && !hasOnlyOneSub)) {
    return <Navigate replace to={uri.minSideFixed("/tjenester/")} />;
  }
  if (addVas.loading && !addVas.data) {
    return <SkeletonPage />;
  }
  if (isValidVAS && hasOnlyOneSub && addVas.data?.subscriptions?.[0]?.phoneNumber) {
    const phoneNumber = addVas.data.subscriptions[0].phoneNumber.localNumber;
    return <Navigate replace to={uri.minSideFixed(`/tjenester/${productGroup}/${phoneNumber}`)} />;
  }
  return <Navigate replace to={uri.minSideFixed("/tjenester/")} />;
}

function SkeletonPage() {
  return (
    <Container padding showGoBackButton gap flexDirection="column">
      <Heading as="h1" variant="title-300" pb={"100"}>
        <Box maxWidth="10ch">
          <Skeleton variant="heading-m" />
        </Box>
      </Heading>
      <Text>
        <Box maxWidth="10ch" as="span">
          <Skeleton variant="text" />
        </Box>
      </Text>
      <Box pt="200">
        <Card variant="white" shadow maxWidth={breakpoint.teddyBreakpointMd} justify="stretch" bordered>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Card>
      </Box>
    </Container>
  );
}
