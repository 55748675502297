import React from "react";
import { Item, ShoppingSummaryItemProps } from "./Item";
import { List } from "./List";
import { ActionWrapper, LineSeparator, Section } from "./style";
import { Wrapper } from "./Wrapper";
export type { ShoppingSummaryItemProps };

export const ShoppingSummary = {
  Wrapper,
  List,
  Item,
  ActionWrapper,
  Section,
  LineSeparator: () => <LineSeparator />,
};
