import React from "react";
import { BottomSection } from "./style";
import { Button } from "../../Button";
import { MinSide } from "..";
import { getCustomerLinks } from "../utils/links";

type Props = {
  minSide: MinSide;
  onSelect: () => void;
  limitedAccess: boolean;
};

export const CustomerLinks = ({ minSide, onSelect, limitedAccess }: Props) => {
  const links = getCustomerLinks(minSide);

  return (
    <BottomSection data-tracking-id="minside-button-links-layout">
      {links.map((link) => (
        <Button
          onClick={onSelect}
          key={link.label}
          tag="a"
          href={link.href}
          variant="text-black"
          icon={link.icon}
          iconSize="sm"
          alignSelf="start"
          isInternalNavigation={link.isInternalLink}
          disabled={limitedAccess && link.limitedAccessView}
        >
          {link.label}
        </Button>
      ))}
    </BottomSection>
  );
};
