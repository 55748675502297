import { getTax } from "util/tracking";
import { AdditionalProduct } from "gql/graphql";
import { formatDatalayerTransactionData } from "src/services/googleAnalytics/eventDataHelpers";

export const getTransactionData = (nordicBalticsMinutes: AdditionalProduct, orderId: string) => {
  const isActiveProduct = nordicBalticsMinutes?.presentation?.active || false;
  const price = nordicBalticsMinutes?.price?.price;

  return formatDatalayerTransactionData({
    actionId: orderId,
    revenue: isActiveProduct ? 0 : price || 0,
    shipping: 0,
    name: nordicBalticsMinutes?.name,
    id: nordicBalticsMinutes?.code,
    price: isActiveProduct ? 0 : price || 0,
    tax: isActiveProduct ? 0 : getTax(price || 0),
    category: "Service/Call/Nordic_Baltic_Minutes",
    variant: nordicBalticsMinutes.shortDescription || "",
    isActiveProduct,
  });
};
