import React, { ReactNode, useRef } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { useWindowSize } from "../hooks";
import { useHorizontalScroll } from "../hooks/useHorizontalScroll";
import { Children, Container, LeftNavigation, RightNavigation, ScrollView, Shadow } from "./style";

smoothscroll.polyfill();

export type HorizontalScrollScrollBy = ({ scrollDistance }: { scrollDistance: number }) => void;

export type HorizontalScrollProps = {
  /**
   * InitialMargin can be used to align the first element in the horizontal scroll.
   */
  initialMargin?: string;
  /**
   * ScrollMargin can be used to allow the scrollable element to be visible further left.
   * To achieve this you need to provide a negative value.
   */
  scrollMargin?: string;
  hideBackdrop?: boolean;
  reversScrollOrder?: boolean;
  children?: (({ scrollBy }: { scrollBy: HorizontalScrollScrollBy }) => ReactNode) | ReactNode;
  addContainerScrollMargin?: boolean;
};

const BUTTON_SIZE = 30;

export const HorizontalScroll = (props: HorizontalScrollProps) => {
  const { isMobile } = useWindowSize();
  const defaultScrollMargin = props.addContainerScrollMargin ? (isMobile ? "1rem" : "2rem") : "0px";
  const {
    initialMargin = defaultScrollMargin,
    scrollMargin = `-${defaultScrollMargin}`,
    hideBackdrop = false,
    reversScrollOrder = false,
  } = props;
  const scrollableRef = useRef<HTMLDivElement>(null);
  const { isScrollable, isEndOfScroll, isStartOfScroll } = useHorizontalScroll(scrollableRef);
  const showLeftScrollIndicator = isScrollable && (!isStartOfScroll || isEndOfScroll);
  const showRightScrollIndicator = isScrollable && (!isEndOfScroll || isStartOfScroll);

  function scrollBy({ scrollDistance }: { scrollDistance: number }) {
    if (!scrollableRef.current) return;
    scrollableRef.current.scrollBy({
      left: scrollDistance,
      behavior: "smooth",
    });
  }
  function onScrollClick(scrollDirection: "left" | "right") {
    if (!scrollableRef.current) return;
    const scrollDistance =
      scrollDirection === "left"
        ? -scrollableRef.current.offsetWidth + (showLeftScrollIndicator ? BUTTON_SIZE : 0)
        : scrollableRef.current.offsetWidth - (showRightScrollIndicator ? BUTTON_SIZE : 0);

    scrollBy({ scrollDistance });
  }

  return (
    <Container margin={scrollMargin}>
      <LeftNavigation
        title="Scroll til venstre"
        isIconButton
        icon="arrow-left"
        size="sm"
        variant="tertiary-purple"
        hide={!showLeftScrollIndicator}
        aria-hidden={!showLeftScrollIndicator}
        onClick={() => onScrollClick("left")}
        tabIndex={showLeftScrollIndicator ? 0 : -1}
      />
      <Shadow
        hideBackdrop={hideBackdrop}
        showLeftShadow={showLeftScrollIndicator}
        showRightShadow={showRightScrollIndicator}
      >
        <ScrollView ref={scrollableRef}>
          <Children reversScrollOrder={reversScrollOrder} initialMargin={initialMargin}>
            {typeof props.children === "function" ? props.children({ scrollBy }) : props.children}
          </Children>
        </ScrollView>
      </Shadow>
      <RightNavigation
        title="Scroll til høyre"
        isIconButton
        icon="arrow-right"
        size="sm"
        variant="tertiary-purple"
        hide={!showRightScrollIndicator}
        aria-hidden={!showRightScrollIndicator}
        onClick={() => onScrollClick("right")}
        tabIndex={showRightScrollIndicator ? 0 : -1}
      />
    </Container>
  );
};
