import { Maybe, ProductService } from "gql/graphql";
import { START_DISCOUNT_CODE } from "./variables";

export function hasStartDiscountOnAddSubscription(pricePlan: string | null | undefined): boolean {
  return !!pricePlan?.toLowerCase().includes("start");
}

export function hasStartDiscountOnChangeSubscription(
  includedProducts: Maybe<Maybe<ProductService>[]> | undefined
): boolean {
  return !!includedProducts?.some((product) => product?.code === START_DISCOUNT_CODE);
}
