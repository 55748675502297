import { BaseSyntheticEvent } from "react";
import { useExpandableCard } from "@telia-no-min-side/components";
import { Fields } from "../utils/getInitialState";
import { ButtonContainer, FormButton } from "./styles/common-styles";

type AccordionButtonsProps = {
  disabled: boolean;
  form: Fields;
  emailAddress?: string | null;
  handleOnSubmit: (form: Fields, emailAddress: string | null | undefined) => void;
  handleOnCancel: (e: BaseSyntheticEvent) => void;
};

export const AccordionButtons = ({
  disabled,
  form,
  emailAddress,
  handleOnSubmit,
  handleOnCancel,
}: AccordionButtonsProps) => {
  const { onClick } = useExpandableCard();

  const closeAccordionOnClick = (e: BaseSyntheticEvent) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <ButtonContainer>
      <FormButton
        variant="tertiary-purple"
        onClick={(e: BaseSyntheticEvent) => {
          closeAccordionOnClick(e);
          handleOnCancel(e);
        }}
      >
        Avbryt
      </FormButton>
      <FormButton disabled={disabled} type="submit" onClick={() => handleOnSubmit(form, emailAddress)}>
        Lagre
      </FormButton>
    </ButtonContainer>
  );
};
