import { format } from "@telia-no-min-side/utils";
import { DashboardSection } from "../card/DashboardSection";
import { ErrorSection } from "../card/ErrorSection";
import { LoadingSubscriptionCard } from "../card/LoadingSubscriptionCard";
import { SubscriptionCard } from "../card/SubscriptionCard/SubscriptionCard";
import { isB2BAccount } from "../utils";
import { MobileSubAdminCard } from "./MobileSubAdminCard";
import { UpSellMobileSub } from "./UpSellMobileSub";
import { useSubscriptionOverview } from "../hooks/useSubscriptionOverview";
import { useBlueConic } from "hooks/useBlueConic";
import { BlueConicActionCard } from "../highlights/personal/BlueConicActionCard";
import { ACCESS_LEVEL, useAuth } from "@telia-no-min-side/components";
import { AddSubscriptionCard } from "../card/AddSubscriptionCard";
import { config } from "util/config";
import { BlueConicPromoList } from "../highlights/personal/BlueConicPromoList";

export function SubscriptionsMobile() {
  const subscriptionOverview = useSubscriptionOverview();
  const { bcMobile } = useBlueConic();
  const { accessLevel } = useAuth();

  if (subscriptionOverview.loading && !subscriptionOverview.hasAnyAccounts) {
    return (
      <DashboardSection isLoading>
        <LoadingSubscriptionCard isGaugeMeter />
      </DashboardSection>
    );
  }

  if (subscriptionOverview.error && !subscriptionOverview.hasAnyAccounts) {
    return (
      <ErrorSection
        title="Mobil"
        sectionText="dine mobil produkter"
        onRefreshClick={subscriptionOverview.refetch}
        isLoading={subscriptionOverview.isRefetching}
      />
    );
  }

  if (!subscriptionOverview.hasAnyAccounts) return null;

  return (
    <DashboardSection title="Mobil">
      {subscriptionOverview.hasAnySubscriptions && (
        <>
          {subscriptionOverview.mobileSubscriptionsOverview.map((subscription) => {
            const isB2B = isB2BAccount(subscription.account);
            return (
              <SubscriptionCard
                accountId={subscription.account.id}
                key={subscription.phoneNumber.localNumber}
                phoneNumber={subscription.phoneNumber.localNumber}
                isB2B={isB2B}
                dataTrackingID={`subscription-card-id-${format.toKebabCase(
                  format.getFullName(subscription.productUser)
                )}`}
              />
            );
          })}
          {accessLevel === ACCESS_LEVEL.BRONZE && <AddSubscriptionCard />}

          <MobileSubAdminCard />
        </>
      )}
      <UpSellMobileSub />
      {config.isNewBlueconicEnabled ? <BlueConicPromoList type="mobile" /> : <BlueConicActionCard message={bcMobile} />}
    </DashboardSection>
  );
}
