import styled from "styled-components";
import { Flex, Text, color } from "@telia/teddy";
import { RoamingCountry } from "gql/graphql";

type Props = {
  filteredCountries: RoamingCountry[];
  handleCountrySelection: (selectedCountry: RoamingCountry) => void;
};

export function CountryDropdown({ filteredCountries, handleCountrySelection }: Props) {
  return (
    <DropdownContainer direction="column" gap="50">
      <DropdownList>
        {filteredCountries.map((country) => (
          <DropdownItem
            key={`${country.countryCode}-${country.prefix}`}
            style={{ cursor: "pointer" }}
            onClick={() => handleCountrySelection(country)}
          >
            {country.name}
          </DropdownItem>
        ))}
      </DropdownList>
    </DropdownContainer>
  );
}

const DropdownContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;

  border: 1px solid ${color.teddyColorGray100};
  border-radius: 6px;
  background: white;
  overflow: hidden;
`;

const DropdownList = styled.div`
  max-height: 230px;
  overflow-y: auto;
  padding: 5px;
`;

const DropdownItem = styled(Text)`
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: ${color.teddyColorGray100};
  }
`;
