import { Maybe, OfferingPriceDetails } from "../../gql/graphql";
import { findAmount } from "./findAmount";
import { findSpeedDownload } from "./findSpeedDownload";

export function getAmount(offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined): number {
  const dataIncluded = offeringPrices?.find(findAmount);
  const speedDownload = offeringPrices?.find(findSpeedDownload);
  const subscription = dataIncluded || speedDownload;

  if (!subscription) return 0;

  return subscription.amount || 0;
}
