import { Dispatch, SetStateAction } from "react";
import { track } from "@telia-no-min-side/utils";
import { Card, Flex, Text, Button } from "@telia/teddy";
import { useToast } from "@telia-no-min-side/components";
import { OrderOp } from "src/gql/graphql";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { ROAMING_RESTRICTION_CODE } from "util/constants/topups";
import { useOrderAdditionalProducts } from "hooks/useAdditionalProducts";

type Props = {
  canRemoveRoamingRestriction: boolean;
  setIsRoamingBlocked: Dispatch<SetStateAction<boolean>>;
};

export function RoamingRestriction({ canRemoveRoamingRestriction, setIsRoamingBlocked }: Props) {
  const { addToast } = useToast();
  const { phoneNumber } = useMobileAccount();
  const { pushTrackingEvent } = track.useEventTracking();

  const { orderResponse, runOrderAdditionalProducts } = useOrderAdditionalProducts({
    onCompleted: (response) => {
      const orderId = response?.order?.orderId;
      if (orderId) {
        addToast({
          text: "Sperring av datapakker i utland er opphevet!",
          variant: "success",
        });
        setIsRoamingBlocked(false);
      }
    },
    onError: () => {
      addToast({
        text: "En feil oppsto. Prøv igjen om noen minutter, eller ta kontakt med kundesenteret.",
        variant: "error",
      });
    },
  });

  function enableRoaming() {
    runOrderAdditionalProducts({
      phoneNumber,
      input: {
        productName: ROAMING_RESTRICTION_CODE,
        operation: OrderOp.RemoveAdditionalProduct,
        email: "",
      },
    });

    const trackEvent = {
      ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.UNBLOCK_ROAMING_TOPUPS,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.UNBLOCK,
    };
    pushTrackingEvent(trackEvent);
  }

  return (
    <Card shadow variant="beige">
      <Flex direction="column" gap="gutter-sm">
        <Text variant="paragraph-100-bold">Sperret datapakker i utland</Text>
        {canRemoveRoamingRestriction ? (
          <>
            <Text>
              Du har satt på sperre for kjøp av datapakker i utland i dine innstillinger. Klikk på knappen under for å
              oppheve denne sperringen.
            </Text>
            <Button size="sm" loading={orderResponse.loading} onClick={enableRoaming}>
              Opphev sperring
            </Button>
          </>
        ) : (
          <Text>
            Du er sperret for kjøp av datapakker i utland i dine innstillinger. Denne sperren kan oppheves av juridisk
            eier for dette abonnementet.
          </Text>
        )}
      </Flex>
    </Card>
  );
}
