import { datadogRum } from "@datadog/browser-rum";
import { AuthProvider, MainLayout, ScrollToTop, ToastProvider, useAuth } from "@telia-no-min-side/components";
import { track, uri } from "@telia-no-min-side/utils";
import AllIcons from "@telia/styleguide/assets/allicons/AllIcons";
import sha256 from "crypto-js/sha256";
import React, { useEffect, useMemo } from "react";
import { Navigate, Outlet, createBrowserRouter, matchPath, useLocation, useNavigate } from "react-router-dom";
import "./App.less";
import { MobileAccountProvider } from "./hooks/useMobileAccount";
import { SelectAccountProvider } from "./hooks/useSelectAccount";
import { BuyTVSubscription } from "./pages/buy-tv-subscription";
import { CancelAgreement } from "./pages/cancel-agreement";
import { CancelAgreementReceipt } from "./pages/cancel-agreement/Recipt";
import { FwaCommitmentPage } from "./pages/commitment-fwa/FwaCommitmentPage";
import { Home } from "./pages/home";
import { NotFound } from "./pages/error/NotFound";
import { FlexPage } from "./pages/flex";
import { Invoice } from "./pages/invoice";
import { InvoiceDetailsPage } from "./pages/invoice/InvoiceDetailsPage";
import { InvoiceSettingsPage } from "./pages/invoice/InvoiceSettingsPage";
import { AvtalegiroPage } from "./pages/invoice/AvtalegiroPage";
import { InvoiceAbroadPage } from "./pages/invoice/mobile/InvoiceAbroadPage";
import { InvoiceConfirmPaymentPage } from "./pages/invoice/mobile/InvoiceConfirmPaymentPage";
import { InvoicePreview } from "./pages/invoice/mobile/details/InvoicePreview";
import { LogOut } from "./pages/log-out";
import { MbbSubscriptionChange } from "./pages/mobile/mobile-subscription-change";
import { MbbSubscriptionConfirm } from "./pages/mobile/mobile-subscription-confirm";
import { MoveOutPage } from "./pages/move-out-registration/MoveOutPage";
import { PrivacySettingsPage } from "./pages/privacy/PrivacySettingsPage";
import { RightOfAccessPage } from "./pages/privacy/RightOfAccessPage";
import { Profile } from "./pages/profile";
import { ChangeProfileInfo } from "./pages/profile/fixed/ChangeProfileInfo";
import { ReceiptError } from "./pages/receipt/ReceiptError";
import { ReceiptSuccessNoBox } from "./pages/receipt/ReceiptSuccessNoBox";
import { ReceiptSuccessWithPostal } from "./pages/receipt/ReceiptSuccessWithPostal";
import { SelfRecovery } from "./pages/self-recovery";
import { OrderSubscriptionProvider } from "./store/OrderToastContext";
import { InvoiceProvider } from "./store/hooks/invoice-context";
import { useInvoiceUrl } from "./store/hooks/useInvoiceUrl";
import { WifiRouterAdmin } from "./templates/wifi-router-admin";
import { ResultMessageProvider } from "./util/ResultMessageContext";
import { config } from "./util/config";
import { isApp } from "./util/isApp";
import { InvoiceChangeDueDatePage } from "./pages/invoice/InvoiceChangeDueDatePage";
import { RightOfAccessStepOne } from "./pages/privacy/pages/RightOfAccessStepOne";
import { RightOfAccessStepTwo } from "./pages/privacy/pages/RightOfAccessStepTwo";
import { RightToBeForgottenPage } from "./pages/privacy/pages/RightToBeForgottenPage";
import { RightOfPersonalDataPage } from "./pages/privacy/pages/RightOfPersonalDataPage";
import { GDPRSuccessPage } from "pages/privacy/pages/SuccessPage";
import { GDPRErrorPage } from "pages/privacy/pages/ErrorPage";
import { FakeLoginAs } from "./components/fake-login-as";
import { InvoiceDetailedUsagePage } from "pages/invoice/invoice-detailed-usage/InvoiceDetailedUsagePage";
import { SelfVerifyProductUser } from "pages/self-recovery/self-verify-product-user/SelfVerifyProductUser";
import { HelpPage } from "pages/help";
import { ValueAddedServicesPage } from "./pages/value-added-services/ValueAddedServicesPage";
import { VasDetails } from "pages/value-added-services/VasDetails";
import { TempAccessPage } from "pages/temp-access";
import { TempAccessVerification } from "pages/temp-access/access-steps";
import { TempAccessSuccess } from "pages/temp-access/response-states/TempAccessSuccess";
import { TempAccessError } from "pages/temp-access/response-states/TempAccessError";
import { TempAccessNoMatch } from "pages/temp-access/response-states/TempAccessNoMatch";
import { VasDetailsRedir } from "pages/value-added-services/VasDetailsRedir";
import { useAnalyticsData } from "hooks/useAnalyticsData";
import { BlueConicProvider, useBlueConic } from "hooks/useBlueConic";
import { AUTH } from "util/constants/auth";
import { accessibleViews } from "util/links";
import { Toast } from "@telia/teddy";
import { PostPurchaseInsurancesPage } from "pages/home/home-post-purchase-insurances";

// NEW PAGES ROUTES (TEMPORARY):
import { CreateBan } from "pages/dev/order-ban";
import { mobileRoutes } from "./mobile-routes";
import { tvRoutes } from "./tv-routes";
import { internetRoutes } from "./internet-routes";

(function removeWeirdSessionStuffInUrl() {
  const indexOfSemiColon = window.location.href.indexOf(";");
  //TODO: remove
  if (indexOfSemiColon > -1) {
    window.location.replace(window.location.href.slice(0, indexOfSemiColon));
  }
})();

type EventTrackingWrapperProps = {
  children: React.ReactNode;
};

export function EventTrackingWrapper({ children }: EventTrackingWrapperProps) {
  const { EventTrackingProvider } = track;
  const { commonEventValues, isLoading } = useAnalyticsData();
  return <EventTrackingProvider commonItems={!isLoading ? commonEventValues : {}}>{children}</EventTrackingProvider>;
}

function ForceCommonLogin() {
  const { setAuthAdapter } = useAuth();
  useEffect(() => {
    setAuthAdapter(AUTH.PROVIDER.CIAM);
  }, []);
  return <Navigate to={uri.minSideFixed("/")} />;
}

function MenuLayout() {
  const invoice = useInvoiceUrl();
  const { userInfo, hasLimitedAccess, selectedAuthProvider } = useAuth();
  const { setBlueConicProfile } = useBlueConic();
  const partyId = userInfo?.["urn:teliacompany:dis:ciam:1.0:party_id"];
  const trackingId = useMemo(() => partyId && sha256(partyId).toString(), [partyId]);

  if (trackingId) {
    setBlueConicProfile(trackingId);
  }

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const topMenuSupportedRoutes = [
    "/minside/mobil",
    "/minside/mobil/forbruk",
    "/minside/mobil/sim-pin-puk",
    "/minside/mobil/sim-pin-puk/bestill-sim",
    "/minside/mobil/innstillinger",
    "/minside/mobil/kjopshistorikk",
    "/minside/mobil/mine-abonnement",
    "/minside/flex",
    "/minside/tv/tv-innhold",
    "/minside/tv/tv-bokser",
    "/minside/tv/tv-bokser/kjop-fjernkontroll",
    "/minside/tv/tv-bokser/kjop-ny-tv-boks",
    "/minside/tv/tilleggstjenester",
    "/minside/tv/tilleggstjenester/kjop-opptak",
    "/minside/flytting",
    "/minside/tv",
    "/minside/internett/*",
  ];

  datadogRum.setUser({
    id: partyId,
    authProvider: selectedAuthProvider,
  });

  // If user doesn't have temp-access or BankId, they are only allowed to view certain pages.
  useEffect(() => {
    if (hasLimitedAccess) {
      const isAccessablePage = !!accessibleViews.find((view) => pathname.includes(view));
      if (!isAccessablePage) {
        navigate(uri.minSideFixed("/hjem"));
      }
    }
  }, []);

  return (
    <MainLayout
      minSide="fixed"
      fixedInvoiceLink={invoice.isInternalNavigation || !invoice.url ? undefined : invoice.url}
      trackingId={trackingId}
      isNewInvoice={config.isNewInvoice}
      limitedAccess={hasLimitedAccess}
      hideMinsideButton={topMenuSupportedRoutes.some(
        (route) => matchPath({ path: route, caseSensitive: false }, pathname) !== null
      )}
    >
      <Outlet />
    </MainLayout>
  );
}

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <AuthProvider
          minsideApp={window.location.pathname.includes("minside/mobil") ? "mobile" : "fixed"}
          isTempAccessEnabled={config.isTempAccessEnabled}
          customRedirectUri={
            window.location.search.includes("webshopRedirectUrl")
              ? new URLSearchParams(window.location.search).get("webshopRedirectUrl") ?? undefined
              : undefined
          }
        >
          <FakeLoginAs />
          <ScrollToTop />
          <AllIcons />
          <Toast />
          <Outlet />
        </AuthProvider>
      ),
      children: [
        { path: "minside/logg-ut", element: <LogOut /> },
        {
          element: (
            <ToastProvider>
              <OrderSubscriptionProvider>
                <MobileAccountProvider>
                  <SelectAccountProvider>
                    <EventTrackingWrapper>
                      <ToastProvider>
                        <OrderSubscriptionProvider>
                          <BlueConicProvider>
                            <Outlet />
                          </BlueConicProvider>
                        </OrderSubscriptionProvider>
                      </ToastProvider>
                    </EventTrackingWrapper>
                  </SelectAccountProvider>
                </MobileAccountProvider>
              </OrderSubscriptionProvider>
            </ToastProvider>
          ),
          children: [
            {
              path: "minside",
              element: isApp ? <Outlet /> : <MenuLayout />,
              handle: {
                crumb: "Hjem",
              },
              children: [
                { path: "ciam", element: <ForceCommonLogin /> },
                {
                  path: "midlertidig-tilgang",
                  children: [
                    { index: true, element: <TempAccessPage /> },
                    { path: "bekrefte", element: <TempAccessVerification /> },
                    { path: "suksess", element: <TempAccessSuccess /> },
                    { path: "igen-treff", element: <TempAccessNoMatch /> },
                    { path: "feil", element: <TempAccessError /> },
                  ],
                },
                {
                  path: "hjem",
                  handle: {
                    crumb: "Hjem",
                  },
                  children: [
                    { index: true, element: <Home /> },
                    {
                      path: "bestill-forsikring",
                      element: <PostPurchaseInsurancesPage />,
                      handle: {
                        crumb: "Bestill forsikring",
                      },
                    },
                  ],
                },

                ...mobileRoutes,
                ...tvRoutes,
                ...internetRoutes,

                {
                  path: "avbestill",
                  handle: {
                    crumb: "Avbestill",
                  },
                  children: [
                    { path: "kvittering/:agreementDetailId", element: <CancelAgreementReceipt /> },
                    { path: ":agreementDetailId", element: <CancelAgreement /> },
                  ],
                },
                {
                  path: "kontogjenoppretting",
                  element: <SelfRecovery />,
                  handle: {
                    crumb: "Kontogjenoppretting",
                  },
                },
                {
                  path: "flex",
                  element: <FlexPage />,
                  handle: {
                    crumb: "Flex",
                  },
                },
                {
                  path: "bestill/tv",
                  element: <BuyTVSubscription />,
                  handle: {
                    crumb: "Bestill TV",
                  },
                },
                {
                  path: "binding-fwa",
                  element: <FwaCommitmentPage />,
                  handle: {
                    crumb: "Binding FWA",
                  },
                },
                {
                  path: "profil",
                  handle: {
                    crumb: "Profil",
                  },
                  children: [
                    { path: "*", index: true, element: <Profile /> },
                    {
                      path: "endre",
                      element: <ChangeProfileInfo />,
                      handle: {
                        crumb: "Endre",
                      },
                    },
                  ],
                },
                {
                  path: "tjenester",
                  element: <ValueAddedServicesPage />,
                  handle: {
                    crumb: "Tjenester",
                  },
                },
                {
                  path: "tjenester/:productGroup",
                  element: <VasDetailsRedir />,
                  handle: {
                    crumb: "Tjenester",
                  },
                },
                {
                  path: "tjenester/:productGroup/:selectedUserId",
                  element: <VasDetails />,
                  handle: {
                    crumb: "Tjenester",
                  },
                },
                {
                  path: "personvern",
                  handle: {
                    crumb: "Personvern",
                  },
                  children: [
                    { index: true, element: <PrivacySettingsPage /> },
                    { path: "innsyn-tv-internett", element: <RightOfAccessPage /> },
                    {
                      path: "innsyn-mobil",
                      children: [
                        { index: true, element: <RightOfAccessStepOne /> },
                        { path: "send-foresporsel", element: <RightOfAccessStepTwo /> },
                      ],
                    },
                    { path: "sletting", element: <RightToBeForgottenPage /> },
                    { path: "dataportabilitet", element: <RightOfPersonalDataPage /> },
                    { path: "error", element: <GDPRErrorPage /> },
                    { path: "suksess", element: <GDPRSuccessPage /> },
                  ],
                },
                {
                  path: "faktura",
                  handle: {
                    crumb: "Faktura",
                  },
                  element: (
                    <InvoiceProvider>
                      <ResultMessageProvider>
                        <Outlet />
                      </ResultMessageProvider>
                    </InvoiceProvider>
                  ),
                  children: [
                    { index: true, element: <Invoice /> },
                    { path: "pdf/:accountId/:invoiceId", element: <InvoicePreview /> },
                    { path: "felles/:id", element: <InvoiceDetailsPage /> },
                    { path: "verifiser-betaling", element: <InvoiceConfirmPaymentPage /> },
                    { path: "utland", element: <InvoiceAbroadPage /> },
                    { path: ":id", element: <InvoiceDetailsPage /> },
                    { path: "innstillinger", element: <InvoiceSettingsPage /> },
                    { path: "avtalegiro", element: <AvtalegiroPage /> },
                    { path: "forfallsdato", element: <InvoiceChangeDueDatePage /> },
                    { path: "detaljert-bruk", element: <InvoiceDetailedUsagePage /> },
                  ],
                },
                {
                  path: "flytting",
                  element: <MoveOutPage />,
                  handle: {
                    crumb: "Flytting",
                  },
                },
                { path: "wifi-nettverk", lazy: () => import("./templates/wifi-status") },
                { path: "router-innstillinger", element: <WifiRouterAdmin /> },
                { path: "kvittering/post/vellykket", element: <ReceiptSuccessWithPostal /> },
                { path: "kvittering/vellykket-uten-boks", element: <ReceiptSuccessNoBox /> },
                { path: "kvittering/feil", element: <ReceiptError /> },
                {
                  path: "mobilt-bredband/endre/:accountId",
                  children: [
                    {
                      path: ":phoneNumber",
                      handle: {
                        crumb: "Mobilt bredbånd",
                      },
                      children: [
                        { index: true, element: <MbbSubscriptionChange /> },
                        {
                          path: "bekreft",
                          element: <MbbSubscriptionConfirm />,
                          handle: {
                            crumb: "Bekreft",
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "hjelp",
                  handle: {
                    crumb: "Hjelp",
                  },
                  children: [
                    { index: true, element: <HelpPage /> },
                    {
                      path: "mobil-kontogjenoppretting",
                      element: <SelfVerifyProductUser />,
                      handle: {
                        crumb: "Mobil Kontogjenoppretting",
                      },
                    },
                  ],
                },
                { path: "404", element: <NotFound /> },

                {
                  path: "dev",
                  element: <>{config.teliaEnv === "at" ? <Outlet /> : <Navigate replace to="/minside/hjem" />}</>,
                  children: [{ path: "create-ban", element: <CreateBan /> }],
                },
                {
                  path: "*",
                  index: true,
                  handle: {
                    crumb: "Hjem",
                  },
                  element: <Navigate replace to="/minside/hjem" />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
    },
  }
);
