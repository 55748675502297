import { UserOverviewDataQuery } from "src/gql/graphql";
import { isPricePlanMobileBroadband } from "src/util/format-subscription/isPricePlanMobileBroadband";

export const getMobileSubscriptions = (accounts: NonNullable<UserOverviewDataQuery["accounts"]>) => {
  return accounts
    .flatMap((account) => account.subscriptions)
    .filter(
      (subscription) =>
        subscription?.phoneNumber.localNumber.length === 8 &&
        !isPricePlanMobileBroadband(subscription.userOffering?.pricePlan)
    );
};
