import React from "react";
import {
  SkeletonButton,
  SkeletonCircular,
  SkeletonHeadingL,
  SkeletonHeadingM,
  SkeletonHeadingMega,
  SkeletonHeadingS,
  SkeletonHeadingXL,
  SkeletonHeadingXS,
  SkeletonHeadingXXL,
  SkeletonRectangular,
  SkeletonText,
  SkeletonHorseshoe,
} from "./style";

type VariantWithHeight = "circular" | "rectangular";

type Variant =
  | "text"
  | "button"
  | "button-small"
  | "heading-xs"
  | "heading-s"
  | "heading-m"
  | "heading-l"
  | "heading-xl"
  | "heading-xxl"
  | "heading-mega"
  | "horseshoe";

export type Props = {
  width?: string;
  float?: "right" | "left";
  /**
   * @default 'light'
   */
  color?: "light" | "dark";
} & (
  | {
      variant?: VariantWithHeight;
      height?: string;
    }
  | {
      variant?: Variant;
      height?: never;
    }
);

/**
 * @deprecated Use Teddy components instead
 */
export function Skeleton(props: Props): JSX.Element {
  const { variant = "text", height } = props;
  if (variant === "circular") return <SkeletonCircular height={height} {...props} />;
  if (variant === "button") return <SkeletonButton height={"3rem"} {...props} />;
  if (variant === "button-small") return <SkeletonButton height={"2rem"} {...props} />;
  if (variant === "rectangular") return <SkeletonRectangular {...props} />;
  if (variant === "heading-xs") return <SkeletonHeadingXS {...props} />;
  if (variant === "heading-s") return <SkeletonHeadingS {...props} />;
  if (variant === "heading-m") return <SkeletonHeadingM {...props} />;
  if (variant === "heading-l") return <SkeletonHeadingL {...props} />;
  if (variant === "heading-xl") return <SkeletonHeadingXL {...props} />;
  if (variant === "heading-xxl") return <SkeletonHeadingXXL {...props} />;
  if (variant === "heading-mega") return <SkeletonHeadingMega {...props} />;
  if (variant === "horseshoe") return <SkeletonHorseshoe {...props} />;
  if (variant === "text") return <SkeletonText {...props} />;
  return <SkeletonText {...props} />;
}
