import { Card, Container, Paragraph } from "@telia-no-min-side/components";

export function NotPayingOwnInvoiceCard() {
  return (
    <Card>
      <Paragraph removeMargin variant="preamble-100">
        Du har ingen fakturaer
      </Paragraph>
      <Container padding="vertical">
        <Paragraph variant="additional-100" isFaded>
          Dette kan være fordi du ikke betaler din egen faktura.
        </Paragraph>
      </Container>
    </Card>
  );
}
