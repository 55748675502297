import { useMutation, gql, MutationResult, BaseMutationOptions } from "@apollo/client";
import { OrderOp } from "src/gql/graphql";

export const TERMINATE_DATA_SIM = gql`
  mutation terminateDataSIM($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`;

type Params = {
  phoneNumber: string;
  productCode?: string;
};

export type TerminateSimCard = {
  response: MutationResult;
  runTerminateSimCard: ({ phoneNumber, productCode }: Params) => Promise<void>;
};

export function useTerminateSimCard({ onCompleted, onError }: BaseMutationOptions): TerminateSimCard {
  const [terminateDataSIM, response] = useMutation(TERMINATE_DATA_SIM, {
    onCompleted,
    onError,
  });

  const runTerminateSimCard = async ({ phoneNumber, productCode }: Params) => {
    if (!productCode) return;
    const terminateSimCardData = {
      variables: {
        phoneNumber,
        input: [
          {
            operation: OrderOp.RemoveAdditionalProduct,
            productName: productCode,
          },
        ],
      },
    };

    try {
      await terminateDataSIM(terminateSimCardData);
    } catch (error) {
      console.error(error);
    }
  };

  return { response, runTerminateSimCard };
}
