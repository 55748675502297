import styled from "styled-components";
import { devices, Skeleton } from "@telia-no-min-side/components";

export function SkeletonForm() {
  return (
    <div>
      <div>
        <TopSkeletonSpacingTopRight variant="text" width="10ch" />
        <TopSkeletonSpacingTop variant="text" width="25ch" />
      </div>
      <div>
        <SkeletonSpacingTopRight variant="text" width="10ch" />
        <SkeletonSpacingTop variant="text" width="25ch" />
      </div>
      <div>
        <SkeletonSpacingTopRight variant="text" width="10ch" />
        <SkeletonSpacingTop variant="text" width="25ch" />
      </div>
      <div>
        <SkeletonSpacingTopRight variant="text" width="10ch" />
        <SkeletonSpacingTop variant="text" width="25ch" />
      </div>
      <div>
        <SkeletonSpacingTopRight variant="text" width="10ch" />
        <SkeletonSpacingTop variant="text" width="25ch" />
      </div>
      <SkeletonSpacingTop variant="button" width="30ch" />
    </div>
  );
}

const SkeletonSpacingTop = styled(Skeleton)`
  margin-top: 1rem;
  @media ${devices.sm_down} {
    :not(:first-of-type) {
      margin-top: 0.5rem;
    }
  }
`;

const SkeletonSpacingTopRight = styled(SkeletonSpacingTop)`
  margin-right: 1rem;
`;

const TopSkeletonSpacingTop = styled(Skeleton)`
  margin-top: 3rem;
  @media ${devices.sm_down} {
    :not(:first-of-type) {
      margin-top: 0.5rem;
    }
  }
`;
const TopSkeletonSpacingTopRight = styled(TopSkeletonSpacingTop)`
  margin-right: 1rem;
`;
