import { useState } from "react";
import { AdditionalSimCard as AdditionalSimCardType } from "src/gql/graphql";
import { useMobileAccount } from "hooks/useMobileAccount";
import {
  getSimCardProductName,
  getSimCardNickname,
  isSimCardNicknameExistHandler,
  getActionListForAdditionalSim,
} from "../utils";
import { SimCard, TerminateSimCardModal, ChangeSimCardNicknameModal, DeleteSimCardNicknameModal } from ".";

export type ModalType = "TERMINATE_SIM" | "RENAME_NICKNAME" | "DELETE_NICKNAME";

type Props = {
  simCard: AdditionalSimCardType | null;
  isOver18: boolean;
  isLegalOwner: boolean;
};

export function AdditionalSimCard({ simCard, isOver18, isLegalOwner }: Props) {
  const [modalType, setModalType] = useState<ModalType | undefined>();

  const { phoneNumber } = useMobileAccount();

  if (!simCard) return null;
  const simCardProductName = getSimCardProductName(simCard.product?.name);
  const simCardNickname = getSimCardNickname(simCard);
  const isSimCardNicknameExist = isSimCardNicknameExistHandler(simCardNickname);

  const isAbleTo = {
    orderReplacementSIM: isLegalOwner || isOver18,
    editNickname: isSimCardNicknameExist,
    addNickname: !isSimCardNicknameExist,
    deleteNickname: isSimCardNicknameExist,
    terminateSIM: isLegalOwner || isOver18,
  };

  const actionListItems = getActionListForAdditionalSim(isAbleTo, setModalType);

  return phoneNumber ? (
    <>
      <SimCard
        productName={simCardProductName}
        isEsim={!!simCard.isEsim}
        cardNickname={simCardNickname}
        price={simCard?.product?.price?.price}
        simCardNumber={simCard.simCardNumber || ""}
        showActionButton={!!actionListItems.length}
        actionList={actionListItems}
        lastUsedDevice={simCard.lastUsedDevice}
        imei={simCard.imei}
        initialPinCode={simCard.initialPin || ""}
        pukCode={simCard.pukCode || ""}
      />

      {modalType == "TERMINATE_SIM" && (
        <TerminateSimCardModal
          phoneNumber={phoneNumber}
          simCard={simCard}
          simCardProductName={simCardProductName}
          setModalType={setModalType}
        />
      )}

      {modalType == "RENAME_NICKNAME" && (
        <ChangeSimCardNicknameModal
          phoneNumber={phoneNumber}
          simCard={simCard}
          setModalType={setModalType}
          simCardNickname={simCardNickname}
          isSimCardNicknameExist={isSimCardNicknameExist}
        />
      )}

      {modalType == "DELETE_NICKNAME" && (
        <DeleteSimCardNicknameModal
          phoneNumber={phoneNumber}
          simCard={simCard}
          setModalType={setModalType}
          simCardNickname={simCardNickname}
        />
      )}
    </>
  ) : null;
}
