import { Flex, Text, Ribbon, Icon, ProgressBar } from "@telia/teddy";

type Props = {
  hasExceededDataLimit: boolean;
  formattedLimits: { dataLimitRLH: string; dataLimitWhenSpeedReduced: string; reducedSpeed: string };
  timeUntilDataUsagePeriodStarts: string | null;
};

export function UnlimitedDataProgressSection({
  hasExceededDataLimit,
  formattedLimits,
  timeUntilDataUsagePeriodStarts,
}: Props) {
  return (
    <Flex direction="column" gap="100">
      <Flex justify="between">
        <Text variant="paragraph-100-medium">Ubegrenset</Text>
        <Icon name="infinite" size="md" />
      </Flex>
      <ProgressBar progress={100} />

      {timeUntilDataUsagePeriodStarts && (
        <Text variant="additional-100">Ny periode starter om {timeUntilDataUsagePeriodStarts}</Text>
      )}

      {hasExceededDataLimit && (
        <Ribbon rounded variant="orange-100">
          <Icon name="slow-wifi" size="md" />
          <Text>
            Siden du har brukt over {formattedLimits?.dataLimitWhenSpeedReduced}, er hastigheten redusert til{" "}
            {formattedLimits?.reducedSpeed}.
          </Text>
        </Ribbon>
      )}
    </Flex>
  );
}
