import { TextBoxWithLabel } from "@telia/styleguide";
import { Button } from "@telia/styleguide";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useRouterSettings } from "../../stores/RouterSettingsProvider";
import { wifiAdminTags } from "../../util/WifiAdminUtilities";
import { FadeInContent } from "../Animations/FadeIn/FadeInContent";
import { RowFlexAlignEndJustifyStart } from "../LayoutComponents/RowFlexAlignEndJustifyStart";
import { ResponseMessage } from "../ResponseMessage";

import "./style.less";

const transitionTime = 500;

const { func, bool } = PropTypes;

export const ChangeAlias = (props) => {
  const { routerSettings } = useRouterSettings();
  const { updateResponse, updateStatus, activeUnit } = routerSettings;
  const { onStore, show, onCancel, onSuccessfulStore } = props;
  const currentAlias = activeUnit?.Settings.Alias;
  const [alias, setAlias] = useState(currentAlias);

  useEffect(() => {
    if (updateResponse.tag === wifiAdminTags.changeAlias && !updateResponse.error) {
      onSuccessfulStore();
    }
    if (currentAlias !== alias) {
      setAlias(currentAlias);
    }
  }, [currentAlias]);

  const handleInput = (input) => {
    setAlias(input.target.value);
  };

  return (
    <CSSTransition in={show} classNames="change-alias" timeout={transitionTime}>
      {(state) => (
        <div>
          <FadeInContent show={state === "entered"}>
            <div>
              <TextBoxWithLabel
                onChange={handleInput}
                type={"text"}
                value={alias}
                labelText={"Endre navn på enheten"}
              />
              <div className="change-alias-button-container">
                <Button
                  onClick={() => onStore(alias)}
                  isProcessing={updateStatus.tag === wifiAdminTags.changeAlias && updateStatus.updating}
                  processingText={"Lagre"}
                  text={"Lagre"}
                />
                <Button onClick={onCancel} kind={"cancel"} text={"Avbryt"} />
              </div>
              <RowFlexAlignEndJustifyStart>
                <ResponseMessage updateResponse={updateResponse} tag={wifiAdminTags.changeAlias} />
              </RowFlexAlignEndJustifyStart>
            </div>
          </FadeInContent>
        </div>
      )}
    </CSSTransition>
  );
};

ChangeAlias.propTypes = {
  onStore: func,
  show: bool,
  onCancel: func,
  onSuccessfulStore: func,
};
