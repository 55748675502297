import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMobileAccount } from "./useMobileAccount";
import { useSelectAccount } from "./useSelectAccount";
import { useFetchUser } from "src/api/fixed";
import { AgreementType } from "gql/graphql";
import { getCookieValue, setCookie } from "@telia-no-min-side/utils";
import isEqual from "lodash.isequal";
import { COOKIE } from "util/constants/cookies";
import { AUTH } from "util/constants/auth";

export function useAnalyticsData() {
  const [userType, setUserType] = useState(AUTH.ACCOUNT_TYPE.NONE);
  const location = useLocation();
  const mobile = useMobileAccount();
  const fixed = useFetchUser();
  const { selectedMobileAccount } = useSelectAccount();

  const mobileUsers =
    mobile?.allAccounts?.map(({ agreementType }) => {
      return {
        accountType: "mobile",
        customerType:
          agreementType === AgreementType.Private
            ? "B2C"
            : agreementType === AgreementType.Business
            ? "B2B"
            : undefined,
      };
    }) || [];

  const fixedUsers =
    fixed.data?.usersWithCustomerId?.map(() => {
      return {
        accountType: "fixed",
        customerType: "B2C",
      };
    }) || [];

  const isLoading = mobile.isLoading || mobile.isRefetching || fixed.isLoading || fixed.isValidating;

  useEffect(() => {
    if (!isLoading) {
      // Set user type based on available accounts
      let userAcccountType = AUTH.ACCOUNT_TYPE.NONE;
      const hasMobileAccounts = mobileUsers.length > 0;
      const hasFixedAccounts = fixedUsers.length > 0;
      if (hasMobileAccounts && hasFixedAccounts) {
        userAcccountType = AUTH.ACCOUNT_TYPE.BOTH;
      } else if (hasMobileAccounts) {
        userAcccountType = AUTH.ACCOUNT_TYPE.MOBILE;
      } else if (hasFixedAccounts) {
        userAcccountType = AUTH.ACCOUNT_TYPE.FIXED;
      }
      setUserType(userAcccountType);
      // Add user data to GA cookie if cookie value does not exist or user data has changed
      const userData = {
        userType: userAcccountType,
        userAccounts: [...mobileUsers, ...fixedUsers],
      };
      if (!isEqual(JSON.parse(getCookieValue(COOKIE.USER_DATA_GA) || "{}"), userData)) {
        setCookie(COOKIE.USER_DATA_GA, JSON.stringify(userData));
      }
    }
  }, [location, isLoading]);

  return {
    commonEventValues: {
      user_type: userType,
      // For mobile user check if the user is B2B or B2C based on Agreementtype
      // For fixed users hardcode to B2C, as there are no B2B fixed customers
      // Customer can have multiple accounts with B2C and B2B, customer_type is assumed based on selected account in the application
      customer_type: selectedMobileAccount?.accountType || "B2C",
    },
    isLoading,
  };
}
