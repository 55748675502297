const validCharacters = /^[ a-zA-Z0-9_-]*$/;
const norwegianLetters = /^[^æøåÆØÅ]*$/;

export const ssidPwValidation = {
  validateSsid(ssid, maxLength, minLength) {
    if (ssid.length < minLength) {
      return `må være minst ${minLength} tegn`;
    } else if (ssid.length > maxLength) {
      return `maks ${maxLength} tegn`;
    } else if (!norwegianLetters.test(ssid)) {
      return "kan ikke inneholde æ, ø, å";
    } else if (!validCharacters.test(ssid)) {
      return "kun bokstaver (a-z), tall, mellomrom, understrek og bindestrek";
    } else {
      return "";
    }
  },
  validatePw(pw, maxLength, minLength) {
    if (pw.length < minLength) {
      return `må være minst ${minLength} tegn`;
    } else if (minLength.length > maxLength) {
      return `maks ${maxLength} tegn`;
    } else if (!norwegianLetters.test(pw)) {
      return "kan ikke inneholde æ, ø, å";
    } else if (!validCharacters.test(pw)) {
      return "kun bokstaver (a-z), tall, mellomrom, understrek og bindestrek";
    } else {
      return "";
    }
  },
};
