import { BaseMutationOptions, MutationResult, useMutation } from "@apollo/client";
import { GenerateAvtalegiroLinkMutation, MutationAvtalegiroArgs } from "src/gql/graphql";
import { GET_AVTALEGIRO } from "../../mutations";

export type GetAvtalegiroLink = [
  MutationResult<GenerateAvtalegiroLinkMutation>,
  (accountId: string, returnUrl: string) => Promise<void>,
];

export function useAvtalegiro({
  onCompleted,
  onError,
}: BaseMutationOptions<GenerateAvtalegiroLinkMutation>): GetAvtalegiroLink {
  const [orderAvtalegiro, response] = useMutation<GenerateAvtalegiroLinkMutation, MutationAvtalegiroArgs>(
    GET_AVTALEGIRO,
    {
      onCompleted,
      onError,
    }
  );

  const runAvtalegiro = async (accountId: string, returnUrl: string) => {
    const avtalegiroInput = {
      variables: {
        accountId,
        input: {
          returnUrl,
        },
      },
    };
    try {
      await orderAvtalegiro(avtalegiroInput);
    } catch (error) {
      console.log(error, { tags: { hook: "getAvtalegiroLink" } });
    }
  };

  return [response, runAvtalegiro];
}
