import React from "react";
import { LoadingPebble } from "@telia/styleguide";
import styled from "styled-components";
import { devices } from "../utils/breakpoints";

export function FullPageLoading() {
  return (
    <Centering>
      <LoadingPebble />
    </Centering>
  );
}

const Centering = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  height: 20vh;

  @media ${devices.md_up} {
    height: 50vh;
  }
`;
