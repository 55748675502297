import { Flex, Text } from "@telia/teddy";
import { Card, Container, DynamicGaugeMeter, Heading, Skeleton } from "@telia-no-min-side/components";
import { DynamicGaugeMeterWrapper } from "pages/flex/styles/style";

export function PackageSkeleton() {
  return (
    <Card maxWidth="xl" style={{ padding: "0" }}>
      <Flex direction="column" gap="250" p={"300"}>
        <Heading variant="title-100" tag="h3" style={{ textAlign: "center" }}>
          Vil du gjøre noen oppgraderinger på valgt pakke?
        </Heading>

        <DynamicGaugeMeterWrapper>
          <Container>
            <Heading variant="title-100" tag="h3">
              Internett
            </Heading>
            <DynamicGaugeMeter percentage={0} strokeWidth={8} isLoading>
              <Skeleton width="4ch" />
              <Text variant="additional-100">Mbps</Text>
            </DynamicGaugeMeter>
          </Container>
          <Container>
            <Heading variant="title-100" tag="h3">
              Strømming & TV
            </Heading>
            <DynamicGaugeMeter percentage={0} strokeWidth={8} isLoading>
              <Skeleton width="4ch" />
              <Text variant="additional-100">poeng</Text>
            </DynamicGaugeMeter>
          </Container>
        </DynamicGaugeMeterWrapper>

        <Flex direction="column" gap="200">
          <Flex direction="row" gap="250">
            <Skeleton width="4ch" />
            <Skeleton width="4ch" />
          </Flex>

          <Flex direction="row" gap="250">
            <Skeleton height="4ch" width="4ch" />
            <Skeleton width="20ch" />
          </Flex>

          <Flex direction="row" gap="250">
            <Skeleton height="4ch" width="4ch" />
            <Skeleton width="20ch" />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
