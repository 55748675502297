import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "@telia-no-min-side/components";
import { useFetchUser } from "src/api/fixed";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchEvoBoxes() {
  const tvBoxes = useAxiosSWR<types.fixed.AllUsersRequest<types.fixed.ProductCategory>>(ICX_REQUEST.URL_ALL_TV_BOXES);
  const evoBoxUsersThatChanSwitch = Object.entries(tvBoxes.data || {})
    .filter(([, tvBox]) => tvBox.products.some((product) => product.tvBoxIsEvo && product.status?.active))
    .map(([customerId, tvBox]) => ({
      customerId,
      products: tvBox.products.filter((product) => product.tvBoxIsEvo && product.status?.active),
    }))
    .filter(({ products }) => products.length > 0);
  const user = useFetchUser();
  const usersIdsWithEvo = evoBoxUsersThatChanSwitch.map(({ customerId }) => customerId);
  const usersWithEVo = user.data?.usersWithCustomerId.filter((user) => usersIdsWithEvo.includes(user.customerId));
  const hasMultipleAccounts = (user.data?.usersWithCustomerId.length || 0) > 1;
  const hasOneOrMoreEvoBox = evoBoxUsersThatChanSwitch.length > 0;

  return {
    tvBoxes,
    user,
    evoBoxUsersThatChanSwitch,
    usersWithEVo,
    usersIdsWithEvo,
    hasMultipleAccounts,
    hasOneOrMoreEvoBox,
  };
}
