import { Container, devices, Heading, remCalc, Icon, Card } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";
import styled from "styled-components";

export const CurrentProductHeading = styled(Heading)`
  p + && {
    margin-top: 0.5rem;
  }
`;

export const CardWrapper = styled(Container)`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const DynamicGaugeMeterWrapper = styled(Container)`
  display: grid;
  gap: 1rem;
  text-align: center;
  grid-template-columns: repeat(2, minmax(min(100%, ${remCalc(150)}), 1fr));

  @media ${devices.xs_down} {
    grid-template-columns: ${remCalc(200)};
    justify-content: center;
  }
`;

export const FlexList = styled.ul`
  margin: 0.5rem 0;
  padding: 0;
  list-style: none;
  & > li {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(2rem, auto) 1fr;
    gap: 1rem;
    margin: 0.5rem 0;
  }
`;

export const CrossIcon = styled(Icon)`
  position: absolute;
  inset: 0;
  color: ${colors.red600};
  transform: scale(1.2);
`;

export const FlexIconWrapper = styled.span`
  position: relative;
  display: grid;
  align-self: start;
`;

export const StreamingImage = styled.div<{ transparentBG?: boolean }>`
  background-color: ${({ transparentBG }) => (transparentBG ? "transparent" : "black")};
  border-radius: 1rem;
  width: 4.5rem;
  display: flex;
  justify-content: center;
  height: 3rem;
  overflow: hidden;

  img {
    width: 100%;
    max-width: 3.5rem;
  }
`;

export const StreamingContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  @media ${devices.lg_down} {
    display: none;
  }
`;

export const FlexWidth = styled.div`
  flex: 1;
  min-width: 300px;
`;

export const StaticContainer = styled(Container)`
  margin-right: 0;
`;

export const SelectCard = styled(Card)`
  min-height: unset;
`;

export const LineSeparator = styled.div`
  border: 0.5px solid rgba(0, 0, 0, 0.16);
  margin: 16px 0;
`;
