import { Notification } from "@telia/teddy";

export function EmptyRoamingTopupsNotification() {
  return (
    <Notification variant="information">
      <Notification.Heading as="h3">
        <Notification.Icon>Det er dessverre ingen tilgjengelige datapakker i dette landet.</Notification.Icon>
      </Notification.Heading>
    </Notification>
  );
}
