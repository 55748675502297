import { Box, List, Icon, IconName, Text } from "@telia/teddy";

type Props = {
  hideInfoBox?: boolean;
  shortDescription?: boolean;
};

const trygg_v2_features: { icon: IconName; shortDescription: string; longDescription: string }[] = [
  {
    icon: "wireless",
    shortDescription: "Trygg wifi",
    longDescription: "Trygger deg mot usikre wifi-nettverk og farlige nettsider, inkludert VPN for trygg surfing.",
  },
  {
    icon: "vcard",
    shortDescription: "Trygg ID",
    longDescription: "Overvåking av dine personopplysninger, lagring av passord og forsikring ved ID-tyveri.",
  },
  {
    icon: "cart",
    shortDescription: "Trygg netthandel",
    longDescription: "Sjekker at nettbutikker og nettbanker er trygge. Forsikring hvis du blir svindlet.",
  },
  {
    icon: "secure-device",
    shortDescription: "Trygg enhet",
    longDescription: "Sikrer Android-mobil/PC mot virus og farlige programmer. Full kontroll på alle apptillatelser.",
  },
];

export function TryggProductFeatures({ shortDescription = true }: Props) {
  return (
    <Box mt="200">
      <List gapSize="small" mb="gutter-md" pt="gutter-xs">
        {trygg_v2_features.map((feature) => (
          <List.Item key={feature.icon}>
            <List.Illustration>
              <Icon name={feature.icon} />
            </List.Illustration>
            <List.Content>
              {shortDescription ? (
                feature.shortDescription
              ) : (
                <Text variant="paragraph-100-medium">{feature.shortDescription}</Text>
              )}
              {shortDescription ? null : <Text variant="paragraph-100">{feature.longDescription}</Text>}
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Box>
  );
}
