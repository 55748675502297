import { types, uri } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

const urlAntichurn = uri.purchase(ICX_REQUEST.URL_FWA_ANTICHURN);

export function useFetchAntichurn() {
  const { error, ...rest } = useAxiosSWR<types.fixed.AllUsersRequest<types.fixed.fwaAntichurn>>(`${urlAntichurn}`);

  return {
    isError: !!error,
    error: error,
    ...rest,
  };
}
