import { useReactRouterHistory } from "@telia-no-min-side/components";
import { types } from "@telia-no-min-side/utils";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFetchFinancial } from "src/api/fixed";

type Return = {
  selectedFinancialAccountId?: number;
  financialIsLoading: boolean;
  setSelectedFinancialAccountId: Dispatch<SetStateAction<number | undefined>>;
  financial?: types.fixed.Financial;
};

export function useSelectedFinancialAccountId(): Return {
  const [selectedFinancialAccountId, setSelectedFinancialAccountId] = useState<number | undefined>();
  const history = useReactRouterHistory<{ financialAccountId?: number | null }>();
  const financial = useFetchFinancial();
  const financialAccounts = financial.data?.financialAccounts;

  useEffect(() => {
    if (
      typeof history.location.state === "object" &&
      history.location.state &&
      "financialAccountId" in history.location.state &&
      history.location.state.financialAccountId
    ) {
      setSelectedFinancialAccountId(Number(history.location.state.financialAccountId));

      return history.replace(location.pathname, {
        financialAccountId: null,
      });
    }

    if (
      financialAccounts?.length &&
      !financialAccounts.some((d) => d.id.financialAccountId === selectedFinancialAccountId)
    ) {
      setSelectedFinancialAccountId(financialAccounts[0].id.financialAccountId);
    }
  }, [financialAccounts]);

  return {
    selectedFinancialAccountId,
    financialIsLoading: financial.isLoading && !financial.data,
    setSelectedFinancialAccountId,
    financial: financial.data,
  };
}
