import { FormEvent, ReactNode, useState } from "react";
import { Button, TextInput, TextInputProps, axiosPost } from "@telia-no-min-side/components";
import { track, uri } from "@telia-no-min-side/utils";
import { Form } from "./style";
import { RecoveryPageState, useSelfRecoveryState } from ".";

type SelfRecoveryRequest = {
  invoicenr?: string;
  customerid?: string;
  serialnr?: string;
};

type SelfRecoveryResponse =
  | {
      redirectUrl: string;
      accountFound: true;
    }
  | {
      accountFound: false;
    };

type FieldInputRecoveryPageState = Extract<RecoveryPageState, "invoiceNumber" | "customerNumber" | "serialNumber">;

type Props = {
  label: ReactNode;
  methodId: FieldInputRecoveryPageState;
} & Omit<TextInputProps, "children" | "showClearButton" | "onClearClick">;

const recoveryRequest: Record<FieldInputRecoveryPageState, keyof SelfRecoveryRequest> = {
  invoiceNumber: "invoicenr",
  customerNumber: "customerid",
  serialNumber: "serialnr",
};

export function SelfRecoveryInputForm({ label, methodId, ...inputProps }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const { setRecoveryPage } = useSelfRecoveryState();
  async function postSelfRecovery(requestData: SelfRecoveryRequest) {
    setIsLoading(true);
    try {
      const response = await axiosPost<SelfRecoveryResponse, SelfRecoveryRequest>(
        uri.minSideFixed("/api/selfrecovery/start"),
        requestData
      );

      if (response.accountFound && response.redirectUrl) {
        window.location.href = response.redirectUrl;
        return;
      }
      if (!response.accountFound) {
        setRecoveryPage("accountNotFound");
      }
    } catch (error) {
      setRecoveryPage("error");
    }
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (inputValue.length === 0) {
      return setErrorMessage("Fyll ut før du går videre");
    }
    pushGenericTrackingEvent({
      ui_item_action: "next_click",
      ui_item_context: "self-recovery",
      ui_item_text: "Neste",
      ui_item_type: "button",
      ui_item_url: "no_url",
    });
    postSelfRecovery({ [recoveryRequest[methodId]]: inputValue });
  }

  return (
    <Form onSubmit={onSubmit} name="selfRecoveryForm">
      <TextInput
        {...inputProps}
        name={methodId}
        autoFocus
        errorMessage={errorMessage}
        onChange={(e) => {
          if (errorMessage) setErrorMessage("");
          setInputValue(e.currentTarget.value);
        }}
      >
        {label}
      </TextInput>
      <Button variant="primary" disabled={isLoading} isLoading={isLoading} loadingText="Behandler data" type="submit">
        Neste
      </Button>
    </Form>
  );
}
