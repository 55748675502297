import type { Dispatch, SetStateAction } from "react";
import { Button, Modal, ModalHeading, ModalBody, ModalFooter } from "@telia-no-min-side/components";
import { FetchResult } from "@apollo/client";
import { CancelSwitchInsuranceMutation, CancelSwitchInsuranceMutationVariables, OrderOp } from "src/gql/graphql";
import { ModalBodyContainer } from "../styles/SwitchInsurance.styled";
import { SkeletonButton, SkeletonHeadingM, SkeletonText } from "@telia-no-min-side/components/src/Skeleton/style";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export type Props = {
  loading: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  accountId?: string;
  phoneNumber?: string;
  title: string;
  product?: string | null;
  serviceAgreementId?: number | null;
  cancelSwitchInsurance: (options?: {
    variables: CancelSwitchInsuranceMutationVariables;
  }) => Promise<FetchResult<CancelSwitchInsuranceMutation>>;
};

export function CancelInsuranceModal({
  loading,
  isOpen,
  setIsOpen,
  accountId,
  phoneNumber,
  title,
  product,
  serviceAgreementId,
  cancelSwitchInsurance,
}: Props) {
  async function runCancelInsurance(product: string, phoneNumber?: string) {
    const variables: CancelSwitchInsuranceMutationVariables = {
      phoneNumber,
      input: [
        {
          operation: OrderOp.RemoveAdditionalProduct,
          productName: product,
          accountId,
          serviceAgreementId,
        },
      ],
    };
    try {
      await cancelSwitchInsurance({ variables });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      {loading && (
        <ModalBody>
          <SkeletonHeadingM />
          <SkeletonText />
          <SkeletonText />
          <SkeletonText />
          <SkeletonButton />
        </ModalBody>
      )}
      {!loading && (
        <>
          <ModalHeading>Kanseller forsikring</ModalHeading>
          <ModalBody>
            <ModalBodyContainer>
              <p>Er du sikker på at du vil kansellere {title}?</p>
              <p>
                Det vil ikke være mulig å bestille forsikringen på nytt til denne enheten etter at du har avbestilt.
              </p>
            </ModalBodyContainer>
          </ModalBody>
          <ModalFooter flex>
            <Button onClick={() => setIsOpen(false)} variant="tertiary-black">
              Lukk
            </Button>
            {product && (
              <Button
                trackEvent={{
                  ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
                  ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.CANCEL_PLUS_INSURANCE,
                  ui_item_text: "Kanseller forsikring",
                  ui_item_type: TRACK_EVENT.ITEM_TYPE.SUBMIT,
                  ui_item_url: "",
                }}
                onClick={() => runCancelInsurance(product, phoneNumber)}
              >
                Kanseller forsikring
              </Button>
            )}
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}
