import { Outlet } from "react-router-dom";
import { Card, Container, Heading, Link } from "@telia-no-min-side/components";
import { SwitchAlert } from "./Common/SwitchAlert";
import { PhoneInfo } from "pages/mobile/mobile-subscription-agreements/components/switch/components/PhoneInfo";
import { ScrollableColumn, MainContainer, BackLink, Column } from "../styles/commonStyles";
import { useSwitchAgreement } from "../hooks/useSwitchAgreements";
import { GET_ACCOUNT_AGREEMENTS } from "pages/mobile/mobile-subscription-agreements/graphql/getAccountAgreements";
import { useQuery } from "@apollo/client";

export function SwitchContainer() {
  const { error, switchAgreement, accountId } = useSwitchAgreement();

  const { loading, data } = useQuery(GET_ACCOUNT_AGREEMENTS, {
    skip: !accountId,
    variables: { accountId: accountId || "" },
    errorPolicy: "all",
  });

  const switchAgreementsLength = data?.account.switchAgreements?.length;

  if (error || !switchAgreement) {
    return (
      <Container>
        <SwitchAlert errorMsg="Vi kunne ikke finne noen SVITSJ avtaler for denne brukeren" />
      </Container>
    );
  }

  return (
    <MainContainer>
      <Column gap flexDirection="column" width="unset" horizontalPosition="unset">
        <BackLink padding="horizontal">
          <Link
            href="/minside/mobil/avtaler"
            icon="arrow-left"
            iconPosition="before"
            isInternalNavigation
            dataTrackingId="go-back-button"
          >
            Tilbake
          </Link>
        </BackLink>
        <Container padding="horizontal" flexDirection="column" gap="lg" maxWidth="xs" className="sticky-header">
          <Card dataTrackingID="switch-phone-card-container">
            <Container flexDirection="column" gap>
              <Heading tag="h4" variant="title-100">
                Du svitsjer denne telefonen:
              </Heading>
              <PhoneInfo
                product={switchAgreement.product}
                phoneNumber={switchAgreement.phoneNumber}
                insuranceType={switchAgreement.insurance?.type}
              />
            </Container>
          </Card>
          {!loading && switchAgreementsLength && switchAgreementsLength > 1 && (
            <Link variant="text" href="/minside/mobil/avtaler" icon="replace">
              Svitsj en annen telefon
            </Link>
          )}
        </Container>
      </Column>
      <ScrollableColumn padding width="unset" horizontalPosition="unset">
        <Outlet context={{ switchAgreement, accountId }} />
      </ScrollableColumn>
    </MainContainer>
  );
}
