import { DropDownListWithLabel, Heading } from "@telia/styleguide";
import { Button } from "@telia/styleguide";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import PropTypes from "prop-types";
import React from "react";
import { CSSTransition } from "react-transition-group";
import { FadeInContent } from "../../modules/Animations/FadeIn/FadeInContent";
import { RowFlexAlignEndJustifyStart } from "../../modules/LayoutComponents/RowFlexAlignEndJustifyStart";
import { ResponseMessage } from "../../modules/ResponseMessage";
import { updateResponse, updateStatus } from "../../util/PropTypes";
import { ipUtilities } from "../../util/validation";
import { wifiSettingsTags } from "../../util/WiFiSettingsUtilities";

import "./AdditionalSettingsExtender.less";

const Container = ({ children }) => <div className="additional-settings-container">{children}</div>;

const RowSpaceBetween = ({ children }) => <div className={"row-space-between font-size-24"}>{children}</div>;

const ButtonContainer = ({ children }) => <div className="button-container">{children}</div>;

const SelectContainer = ({ children }) => <div className="additional-settings-select-container">{children}</div>;

const { shape, string, func, arrayOf, bool, number } = PropTypes;

class AdditionalSettingsExtender extends React.Component {
  static propTypes = {
    wifi: shape({
      Enabled: bool,
      Channel: string,
      Bandwidth: string,
      Mode: string,
      Password: string,
      SecurityProtocol: string,
      Ssid: string,
      SignalStrength: string,
    }).isRequired,
    supportedSettings: shape({
      Supported: bool,
      SsidMinLength: number,
      SsidMaxLength: number,
      PasswordMinLength: number,
      PasswordMaxLength: number,
      SecurityProtocols: arrayOf(string),
      SignalStrengths: arrayOf(string),
      BandwidthToChannelMaps: arrayOf(
        shape({
          Bandwidth: string,
          Channels: arrayOf(string),
        })
      ),
    }).isRequired,
    frequency: string.isRequired,
    Model: string.isRequired,
    onStoreClick: func.isRequired,
    onCancelClick: func.isRequired,
    show: bool.isRequired,
    updateStatus: updateStatus.isRequired,
    updateResponse: updateResponse.isRequired,
    callBackOnExit: func,
  };

  constructor(props) {
    super(props);
    this.state = {
      wifi: cloneDeep(this.props.wifi),
      errorMessages: [],
      dataTouched: false,
    };
    this.tag = props.frequency === "2.4" ? wifiSettingsTags.Wifi2GhzSettings : wifiSettingsTags.Wifi5GhzSettings;
  }

  updateWiFiSettings = () => {
    const { onStoreClick, frequency } = this.props;

    this.setState({ dataTouched: false });
    onStoreClick(frequency, this.state.wifi);
  };

  componentWillUpdate(nextProps) {
    if (!isEqual(nextProps.wifi, this.state.wifi) && !this.state.dataTouched) {
      this.setState({ wifi: cloneDeep(nextProps.wifi) });
    }
  }

  updateValueForCorrespondingSetting = (type, value) => {
    const newState = cloneDeep(this.state.wifi);
    const { supportedSettings } = this.props;

    switch (type) {
      case "bandwidth":
        newState.Bandwidth = value;
        const newBandwidth = supportedSettings.BandwidthToChannelMaps.find(
          (bandwidth) => bandwidth.Bandwidth === value
        );

        if (!newBandwidth.Channels.includes(newState.Channel)) {
          newState.Channel = newBandwidth.Channels[0];
        }
        break;
      case "securityProtocol":
        newState.SecurityProtocol = value;
        break;
      case "channel":
        newState.Channel = value;
        break;
      case "strength":
        newState.SignalStrength = value;
        break;
    }
    const errorMessages = ipUtilities.validateAdditionalWifiSettings(newState, this.props.supportedSettings);

    if (!this.state.dataTouched) {
      this.setState({ dataTouched: false });
    }
    this.setState({ errorMessages: errorMessages, wifi: newState });
  };

  render() {
    const { onCancelClick, frequency, supportedSettings, Model, show, updateResponse, updateStatus } = this.props;
    const { wifi } = this.state;

    return (
      <CSSTransition
        in={show}
        classNames={"wifi-settings-collapsible-extender"}
        timeout={500}
        unmountOnExit
        onExited={this.props.callBackOnExit}
      >
        {(state) => (
          <div>
            <FadeInContent show={state === "entered"}>
              <Container>
                <Heading tag="h3" size="xs" text={`Innstillinger ${frequency} GHz`} style={{ marginTop: 0 }} />
                <RowSpaceBetween>
                  <div className="font-size-24-bold">Modell:</div>
                  <div className="font-size-24">{Model}</div>
                </RowSpaceBetween>
                <SelectContainer>
                  <DropDownListWithLabel
                    label={"Sikkerhetsprotokoll"}
                    visibleLabel
                    selectedOption={wifi.SecurityProtocol}
                    options={supportedSettings.SecurityProtocols.map((prot) => {
                      return { value: prot, name: prot };
                    })}
                    changeSelectedOption={(event) =>
                      this.updateValueForCorrespondingSetting("securityProtocol", event.target.value)
                    }
                  />
                  <DropDownListWithLabel
                    label={"Kanal"}
                    visibleLabel
                    selectedOption={wifi.Channel}
                    options={supportedSettings.BandwidthToChannelMaps.find(
                      (bandwidth) => bandwidth.Bandwidth === wifi.Bandwidth
                    ).Channels.map((channel) => {
                      return { value: channel, name: `${channel}` };
                    })}
                    changeSelectedOption={(event) =>
                      this.updateValueForCorrespondingSetting("channel", event.target.value)
                    }
                  />
                </SelectContainer>
                <ButtonContainer>
                  <Button
                    text={"Lagre"}
                    processingText={"Lagre"}
                    onClick={this.updateWiFiSettings}
                    isProcessing={updateStatus.tag === this.tag && updateStatus.updating}
                    kind={"primary"}
                  />
                  <Button text={"Avbryt"} onClick={onCancelClick} kind={"cancel"} />
                </ButtonContainer>
                <div style={{ margin: ".5rem 0" }} />
                <RowFlexAlignEndJustifyStart>
                  <ResponseMessage updateResponse={updateResponse} tag={this.tag} />
                </RowFlexAlignEndJustifyStart>
              </Container>
            </FadeInContent>
          </div>
        )}
      </CSSTransition>
    );
  }
}

export default AdditionalSettingsExtender;
