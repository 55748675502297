import { colors } from "@telia/styleguide";
import styled, { css, keyframes } from "styled-components";
import { Props } from ".";

const skeletonLoading = keyframes`{
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  `;

const disappear = keyframes`{
    0% {
      visibility: visible;
      opacity: 1;
      height: 1rem
    }
    49% {
      height: 1rem
    }
    50% {
      opacity: 0;
      visibility: hidden;
      height: 0;
    }
  }`;

const disappearStyling = css`
  animation: ${disappear} 1.5s ease;
  opacity: 0;
  height: 0;
  visibility: hidden;
`;

const skeletonLoadingStyle = css`
  animation: ${skeletonLoading} 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
`;

type SkeletonLineProps = Pick<Props, "color"> & {
  fadeOut?: boolean;
};

export const skeletonLine = css<SkeletonLineProps>`
  display: inline-block;
  background-color: ${({ color }) => (color === "dark" ? colors.grey300 : colors.grey200)};
  border-radius: 4rem;
  visibility: visible;
  height: auto;
  content: "";

  ${(props) => {
    if (props.fadeOut) {
      return disappearStyling;
    } else {
      return skeletonLoadingStyle;
    }
  }}
`;

export const float = css<{ float?: Props["float"] }>`
  margin-left: ${(props) => (props.float === "right" ? "auto" : 0)};
  margin-right: ${(props) => (props.float === "left" ? "auto" : 0)};
`;

type ShapeProps = Pick<Props, "height" | "width" | "color" | "float">;

export const SkeletonHorseshoe = styled.span<ShapeProps>`
  ${skeletonLine}

  position: relative;
  background: transparent;
  height: ${(props) => props.height || "210px"};
  width: ${(props) => props.width || "210px"};
  border: 22px solid ${({ color }) => (color === "dark" ? colors.grey300 : colors.grey200)};
  border-bottom: 22px solid transparent;
  border-radius: 50%;
  ${float}

  &::before {
    content: "";
    bottom: 0px;
    width: 35px;
    height: 22px;
    border-radius: 20px;
    background: ${({ color }) => (color === "dark" ? colors.grey300 : colors.grey200)};
    position: absolute;
    left: 5px;
    transform: rotate(42deg);
  }
  &::after {
    content: "";
    bottom: 0px;
    width: 35px;
    height: 22px;
    border-radius: 20px;
    background: ${({ color }) => (color === "dark" ? colors.grey300 : colors.grey200)};
    position: absolute;
    right: 5px;
    transform: rotate(-42deg);
  }
`;

export const SkeletonCircular = styled.span<ShapeProps>`
  ${skeletonLine}
  height: ${(props) => props.height || "30px"};
  width: ${(props) => props.width || "30px"};
  border-radius: 100%;
  ${float}
`;

export const SkeletonRectangular = styled.span<ShapeProps>`
  ${skeletonLine}
  height: ${(props) => props.height || "300px"};
  width: ${(props) => props.width || "300px"};
  border-radius: 2px;
  ${float}
`;

export const SkeletonButton = styled.span<ShapeProps>`
  ${skeletonLine}
  height: ${(props) => props.height || "1rem"};
  width: ${(props) => props.width || "15ch"};
  border-radius: 2rem;
  margin: 0.1875rem 0;
  ${float}
`;

type TextProp = Pick<Props, "color" | "width" | "float">;

export const SkeletonText = styled.span<TextProp>`
  ${skeletonLine}
  height: 1rem;
  margin: 0.25rem 0;
  width: ${(props) => props.width || "100%"};
  ${float}
`;

export const SkeletonHeadingXS = styled.span<TextProp>`
  ${skeletonLine}
  height: 1.125rem;
  margin: 0.1875rem 0;
  width: ${(props) => props.width || "100%"};
  ${float}
`;

export const SkeletonHeadingS = styled.span<TextProp>`
  ${skeletonLine}
  height: 1.375rem;
  margin: 0.1875rem 0;
  width: ${(props) => props.width || "100%"};
  ${float}
`;

export const SkeletonHeadingM = styled.span<TextProp>`
  ${skeletonLine}
  height: 2rem;
  margin: 0.1875rem 0;
  width: ${(props) => props.width || "100%"};
  ${float}
`;
export const SkeletonHeadingL = styled.span<TextProp>`
  ${skeletonLine}
  height: 2.25rem;
  margin: 0.1875rem 0;
  width: ${(props) => props.width || "100%"};
  ${float}
`;
export const SkeletonHeadingXL = styled.span<TextProp>`
  ${skeletonLine}
  height: 3rem;
  margin: 0.25rem 0;
  width: ${(props) => props.width || "100%"};
  ${float}
`;
export const SkeletonHeadingXXL = styled.span<TextProp>`
  ${skeletonLine}
  height: 3.75rem;
  margin: 0.1875rem 0;
  width: ${(props) => props.width || "100%"};
  ${float}
`;
export const SkeletonHeadingMega = styled.span<TextProp>`
  ${skeletonLine}
  height: 5rem;
  margin: 0.5rem 0;
  width: ${(props) => props.width || "100%"};
  ${float}
`;
