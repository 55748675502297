import { useEffect, useRef } from "react";
import { Flex, Spinner, Heading, Text, ProgressBar, breakpoint, Grid, Button, Icon, TextSpacing } from "@telia/teddy";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { graphql } from "gql";
import { useLazyQuery } from "@apollo/client";
import { uri } from "@telia-no-min-side/utils";
import { Container } from "@telia-no-min-side/components";
import { useB2BFamilyDiscountFlow } from "./useB2BFamilyDiscount";
import { data } from "./mobile-subscription-available/data";
import { OfferingAccordionItem } from "./mobile-subscription-available/OfferingAccordionItem";
import { useCountUp } from "hooks/useCountUp";
import { Success } from "./components/Success";

const SAFE_WEBSHOP_URL = graphql(`
  query safeWebshopUrlFamilyDiscount($input: SafeWebshopUrlInput) {
    safeWebshopUrl(input: $input)
  }
`);

export function OrderSubscription() {
  const { b2bPhoneNumber, pricePlan } = useParams<{ b2bPhoneNumber: string; pricePlan: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    orderB2cAccount,
    orderFamilyDiscount,
    b2cAccountId,
    needToSelectB2CAccountWithoutSubscrioton,
    needToSelectB2cAccountWithSubscription,
  } = useB2BFamilyDiscountFlow({
    selectedB2cAccountId: searchParams.get("b2cAccountId") || undefined,
  });
  const [fetchWebshopUrl, webshopUrl] = useLazyQuery(SAFE_WEBSHOP_URL, {
    onCompleted(data) {
      if (data.safeWebshopUrl === null) {
        navigate("/minside/mobil/bedrift-familierabatt/feil", {
          state: { errorMsg: "Klarte ikke å sende deg til nettbutikken." },
        });
      }
    },
  });
  const calledActionRef = useRef<null | "orderB2cAccount" | "orderDiscount" | "webshopUrl">(null);
  const [progress, setProgress] = useCountUp({
    max: 100,
    intervalMs: 5000,
    step: 1,
  });
  useEffect(() => {
    if (orderB2cAccount.isOrderInProgress) {
      setProgress((prev) => (prev < 20 ? 20 : prev));
    }
    if (
      b2bPhoneNumber &&
      pricePlan &&
      !b2cAccountId &&
      orderB2cAccount.needToOrder &&
      !orderB2cAccount.isOrderInProgress &&
      calledActionRef.current !== "orderB2cAccount"
    ) {
      setProgress((prev) => (prev < 10 ? 10 : prev));
      orderB2cAccount.order({ b2bPhoneNumber, pricePlan });
      calledActionRef.current = "orderB2cAccount";
    }
    if (orderFamilyDiscount.isOrderInProgress) {
      setProgress((prev) => (prev < 65 ? 65 : prev));
    }
    if (
      b2bPhoneNumber &&
      b2cAccountId &&
      orderFamilyDiscount.needToOrder &&
      !orderFamilyDiscount.isOrderInProgress &&
      calledActionRef.current !== "orderDiscount"
    ) {
      setProgress((prev) => (prev < 60 ? 60 : prev));
      orderFamilyDiscount.order({ b2bPhoneNumber, b2cAccountId });
    }

    if (
      b2bPhoneNumber &&
      b2cAccountId &&
      !webshopUrl.called &&
      !orderFamilyDiscount.needToOrder &&
      !orderB2cAccount.needToOrder &&
      calledActionRef.current !== "webshopUrl"
    ) {
      fetchWebshopUrl({
        variables: {
          input: {
            host: uri.openPages(),
            path: "/shop/api/external/subscriptions?d=",
            dataToEncrypt: {
              msisdn: "",
              orderer_msisdn: "",
              ts: Date.now(),
              missing: false,
              initiatedBy: "minside",
              quantity: 1,
              ban: b2cAccountId,
              offeringCode: pricePlan,
              b2bMsisdn: b2bPhoneNumber,
            },
          },
        },
      });
      calledActionRef.current = "webshopUrl";
    }
    if (webshopUrl.data?.safeWebshopUrl) {
      setProgress(100);
      window.open(webshopUrl.data.safeWebshopUrl, "_self");
    }
  }, [
    b2bPhoneNumber,
    b2cAccountId,
    orderFamilyDiscount.needToOrder,
    orderB2cAccount.needToOrder,
    orderFamilyDiscount.isOrderInProgress,
    orderB2cAccount.isOrderInProgress,
    webshopUrl.data,
  ]);

  if (progress === 100 && !orderFamilyDiscount.needToOrder && !orderB2cAccount.needToOrder) {
    return (
      <Success>
        {webshopUrl.data?.safeWebshopUrl ? (
          <TextSpacing>
            <Text variant="paragraph-100" as="p">
              Du vil bli automatisk sendt til nettbutikken for å fullføre bestillingen. Hvis du ikke blir sendt videre,
              kan du trykke på knappen under.
            </Text>
            <Button variant="secondary" asChild>
              <a href={webshopUrl.data?.safeWebshopUrl}>
                Gå til nettbutikk
                <Icon name="arrow-right" />
              </a>
            </Button>
          </TextSpacing>
        ) : null}
      </Success>
    );
  }

  if (needToSelectB2CAccountWithoutSubscrioton || needToSelectB2cAccountWithSubscription) {
    return <Navigate to="/minside/mobil/bedrift-familierabatt" />;
  }

  const userOffering = data.account.offerings.find((offering) => offering.pricePlan === pricePlan);
  return (
    <Container flexDirection="column" floatContent="left" gap="lg" padding maxWidth="md">
      <Flex gap="300">
        <Spinner size="lg" variant="primary" />
        <Flex direction="column" gap="200">
          <Heading as="h1" variant="title-300">
            Vennligst vent...{" "}
          </Heading>
          <Text as="p">
            Vi jobber med å hente frem din familierabatt. Dette kan ta opptil 5 minutter. Vennligst ikke lukk siden.
          </Text>
          {progress === 100 ? (
            <Text as="p">
              Det ser ut til at det tar litt lengre tid enn forventet. Vi jobber fortsatt med å opprette din
              familierabatt.
            </Text>
          ) : null}
          <Grid gap="25" width="100%" mt="300" mb="300" justify="stretch" maxWidth={breakpoint.teddyBreakpointMd}>
            <Flex gap="200" justify={"between"}>
              <Text variant="paragraph-100-medium">Oppretter din familierabatt...</Text>
              <Text variant="paragraph-100-medium">{progress}%</Text>
            </Flex>
            <ProgressBar progress={progress} />
          </Grid>
          {userOffering ? (
            <div>
              <Text as="p" mb="200">
                Valgt abonnement:
              </Text>
              <OfferingAccordionItem hideAction userOffering={userOffering} hasOneOrMoreSubs={false} />
            </div>
          ) : null}
        </Flex>
      </Flex>
    </Container>
  );
}
