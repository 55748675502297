import styled from "styled-components";
import { Card } from "@telia-no-min-side/components";

export const ListItem = styled.div`
  display: flex;
  .Icon {
    width: 1.1rem;
    height: 1.1rem;
    margin: 1.25rem 0.8rem 0 0;
  }
`;

export const UserInfoCard = styled(Card)`
  width: 150px;
  display: flex;
  align-items: center;
  padding: 5px;
  .Icon {
    margin-right: 10px;
  }
`;
