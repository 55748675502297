import { Container, List, ListItem, Paragraph } from "@telia-no-min-side/components";

export function TeliaPlayInfo() {
  return (
    <Container>
      <Paragraph>Telia Play Box med opptak er inkludert.</Paragraph>
      <List>
        <ListItem icon="tv-simple" iconSize="md">
          <Container gap>
            <Paragraph>
              Tilgang til over <em>100 strømmetjenester og kanaler</em>
            </Paragraph>
          </Container>
        </ListItem>
        <ListItem icon="money-krone" iconSize="md">
          <Container gap>
            <Paragraph>Spar 150,-/md. de første 12 md.</Paragraph>
          </Container>
        </ListItem>
        <ListItem icon="film" iconSize="md">
          <Container gap>
            <Paragraph>
              <em>Max Basis</em> uten kostnad
            </Paragraph>
          </Container>
        </ListItem>
        <ListItem icon="reverse" iconSize="md">
          <Container gap>
            <Paragraph>
              Bytt <em>strømmetjenester og kanaler</em> så ofte du vil rett fra fjernkontrollen.
            </Paragraph>
          </Container>
        </ListItem>
        <ListItem icon="lock-locked" iconSize="md">
          <Container gap>
            <Paragraph>Bindingstid i 12 måneder</Paragraph>
          </Container>
        </ListItem>
      </List>
    </Container>
  );
}
