import { Button, Container, Heading } from "@telia-no-min-side/components";
import { Link } from "react-router-dom";
import { GetReplacementSimDataQuery } from "gql/graphql";
import { BlankSimCardType } from "./OrderBlankSimCardSummary";
import { OrderBlankSimCardSummary } from "./OrderBlankSimCardSummary";
import { SimCheckoutView, useSimCheckout } from "pages/mobile/mobile-subscription-sim-pin-puk/hooks/useSimCheckout";
import { SimCardTypeOption } from "./SimCardTypeOption";
import { ChangeAddressForm } from "./ChangeAdressForm";

type Props = {
  subscription: GetReplacementSimDataQuery["subscription"] | undefined;
  simCardType: BlankSimCardType;
  setSimCardType(simType: BlankSimCardType): void;
};

export function OrderAdditionalSimCard({ subscription, simCardType, setSimCardType }: Props) {
  const { checkoutView } = useSimCheckout();

  // If no SIM replacement price received from backend, defaults to 99 NOK.
  const availableOfferingPrices = subscription?.userOffering?.offeringPrices?.find((price) => !!price?.offeringPrices);

  const simReplacementPrice =
    (availableOfferingPrices?.offeringPrices || []).find((offering) => offering?.code === "SIM_REPL_ORDER_FEE")
      ?.amount ?? 99;

  if (checkoutView === SimCheckoutView.ChangeAddress) {
    return <ChangeAddressForm />;
  }

  return (
    <Container maxWidth="lg" flexDirection="column" gap>
      <Link to="..">
        <Button variant="text-black" icon="arrow-left" iconPosition="before">
          Tilbake
        </Button>
      </Link>
      <Heading tag="h2" variant="title-100">
        Velg hvilken type SIM-kort du vil ha
      </Heading>

      <Container gap="lg" flexWrap flexDirection="row" alignItems="flex-start">
        <Container maxWidth="xs" gap="lg" flexDirection="column" horizontalPosition="unset">
          <SimCardTypeOption
            title="Fysisk SIM-kort"
            buttonText="fysisk SIM-kort"
            icon="sim-card"
            paragraphs={["Dette er standard, fysisk SIM-kort, som passer i de fleste enheter."]}
            isSelected={simCardType === BlankSimCardType.Hw}
            handleSelect={() => {
              setSimCardType(BlankSimCardType.Hw);
            }}
          />

          <SimCardTypeOption
            title="eSIM"
            icon="esim"
            paragraphs={[
              "eSIM er et digitalt SIM-kort. Dermed kan det aktiveres umiddelbart etter bestilling, for utstyr som støtter eSIM.",
              "Pass på at din enhet støtter eSIM før du velger dette.",
            ]}
            isSelected={simCardType === BlankSimCardType.Esim}
            handleSelect={() => setSimCardType(BlankSimCardType.Esim)}
          />
        </Container>

        <OrderBlankSimCardSummary simCardType={simCardType} simReplacementPrice={simReplacementPrice} />
      </Container>
    </Container>
  );
}
