import { Button, Card, Grid, Text, breakpoint } from "@telia/teddy";
import { Link, useLocation } from "react-router-dom";

export const Success = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");
  const type = params.get("type");

  return (
    <Card bordered variant="white" layout="default" maxWidth={breakpoint.teddyBreakpointMd}>
      <Card.Heading variant="title-100">Forespørselen din er sendt!</Card.Heading>
      {type !== "reservasjon" && (
        <Card.Description asChild>
          <Grid gap="100">
            <Text>
              Vi har sendt deg en bekreftelse på mailadressen <b>{email}</b>
            </Text>
            <Text>Dersom du ikke mottar en bekreftelse anbefaler vi deg å sjekke spam-filtere i mailboksen din.</Text>
          </Grid>
        </Card.Description>
      )}
      <Card.Footer justify="start">
        <Button variant="primary" asChild>
          <Link to="/minside/personvern">Tilbake til personvern</Link>
        </Button>
      </Card.Footer>
    </Card>
  );
};
