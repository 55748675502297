import { graphql } from "src/gql";

export const PAY_INVOICE_BALANCE = graphql(`
  mutation payInvoiceBalance($input: PayInvoiceBalanceInput!) {
    payInvoiceBalance(input: $input) {
      redirectUrl
    }
  }
`);

export const POSTPONE_INVOICE_PAYMENT = graphql(`
  mutation deferInvoice($accountId: String!) {
    deferInvoice(accountId: $accountId) {
      success
      reason
      nextPaymentDate
      originalPaymentDate
    }
  }
`);

export const CONFIRM_INVOICE_PAYMENT = graphql(`
  mutation confirmInvoicePayment($purchaseId: String!) {
    confirmPayment(purchaseId: $purchaseId, provider: NETS) {
      result
    }
  }
`);

export const GET_AVTALEGIRO = graphql(`
  mutation generateAvtalegiroLink($accountId: String, $input: AvtalegiroInput) {
    avtalegiro(accountId: $accountId, input: $input) {
      expirationDate
      shortUrl
    }
  }
`);

export const UPDATE_INVOICE_SETTINGS = graphql(`
  mutation accountUpdate($input: AccountUpdateInput!) {
    accountUpdate(input: $input) {
      orderId
    }
  }
`);

export const CHANGE_BILL_CYCLE = graphql(`
  mutation changeBillCycle($accountId: String!, $billCycleCode: Int!) {
    changeBillingCycle(accountId: $accountId, billCycle: $billCycleCode) {
      message
    }
  }
`);
