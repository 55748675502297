import type { BaseSyntheticEvent } from "react";
import { createContext } from "react";
import { types } from "@telia-no-min-side/utils";

type InvoicePageCursor = {
  start: number;
  end: number;
  max: number;
};

export type InvoiceContext = {
  financial: types.fixed.Financial | undefined;
  financialIsLoading: boolean;
  canSeeInvoice: boolean;
  onFinancialAccountsChange: (event: BaseSyntheticEvent, arg?: number) => void;
  selectedFinancialAccountId: number | undefined;
  CURSOR_STEP: number;
  invoicePageCursor: InvoicePageCursor;
  invoiceHistory: types.fixed.InvoiceHistory | undefined;
  invoiceHistoryIsLoading: boolean;
  onPageChange: (arg: boolean) => void;
  resetPaginationData: () => void;
  invoiceHistoryError?: Error;
  invoiceHistoryOverview: types.fixed.InvoiceHistoryOverview | undefined;
  lastInvoice: types.fixed.LastInvoice | undefined;
  mutateInvoices: () => void;
  isProfileLoading: boolean;
  lastInvoiceIsLoading: boolean;
};

export const Invoice = createContext<InvoiceContext | undefined>(undefined);
