import { useToast } from "@telia-no-min-side/components";
import { AdditionalProduct } from "src/gql/graphql";
import { getSimplePriceText, uri } from "@telia-no-min-side/utils";
import { isTeliaSkyIncluded } from "./utils/isTeliaSkyIncluded";
import { getTeliaSkyOrderInput } from "./utils/getTeliaSkyOrderInput";
import { SkyCancelServiceModal } from "./SkyCancelServiceModal";
import { SkyProductDescription } from "./SkyProductDescription";
import { isB2BCompanyPaidTeliaSkyProduct } from "./utils/isB2BCompanyPaidTeliaSkyProduct";
import { config } from "src/util/config";
import { useMutation } from "@apollo/client";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { useState } from "react";
import { graphql } from "gql";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Card, Button, Icon, Flex, Box } from "@telia/teddy";
import { VAS_MESSAGE } from "util/constants/valueAddedServices";

type Props = {
  product: AdditionalProduct;
};

const cancelTeliaSkyMutation = graphql(`
  mutation CancelTeliaSky($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);

export const SkyProductPurchased = ({ product }: Props) => {
  const isIncludedForFree = isTeliaSkyIncluded(product);
  const params = useParams<{ productGroup: string; selectedUserId: string }>();
  const phoneNumber = params.selectedUserId;
  const [showCancelModal, setShowCancelModal] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { subscribeToOrder, orders } = useOrderSubscription();

  const [cancelTeliaSky, { loading }] = useMutation(cancelTeliaSkyMutation, {
    onCompleted(data) {
      setShowCancelModal(false);
      addToast({
        text: VAS_MESSAGE.TERMINATE_SERVICE,
        variant: "success",
      });
      product.code &&
        data?.order.orderId &&
        subscribeToOrder({
          id: data?.order.orderId,
          phoneNumber: phoneNumber || "",
          vasProduct: product.code,
          toastText: VAS_MESSAGE.SERVICE_CANCELLED,
        });

      navigate(uri.minSideFixed("/tjenester"));
    },

    onError() {
      setShowCancelModal(false);
      addToast({
        text: VAS_MESSAGE.ERROR_OCCURED,
        variant: "error",
      });
    },
  });
  const isB2BTeliaSky = isB2BCompanyPaidTeliaSkyProduct(product);

  const cancelSky = () => {
    const orderInput = getTeliaSkyOrderInput(product, "");
    phoneNumber && cancelTeliaSky({ variables: { input: orderInput, phoneNumber: phoneNumber } });
  };

  const getPriceText = (): { price: string; pricePrefix?: string } | null => {
    if (isIncludedForFree) return null;

    const price = getSimplePriceText(product.price?.priceWithoutDiscount ?? 0, "md.");

    if (isB2BTeliaSky) {
      return {
        pricePrefix: "Ordinær pris",
        price,
      };
    }

    return { price };
  };

  const priceText = getPriceText();

  const inOrder = orders.some((order) => order.vasProduct === product.code && order.phoneNumber === phoneNumber);

  return (
    <Flex
      width="100%"
      maxWidth="400px"
      direction="column"
      gap="gutter-lg"
      data-tracking-id="additional-services-telia-sky-product-card-purchased"
    >
      <Card variant="white" shadow pb="100" layout="product" bordered>
        <Card.Slot>
          <Badge variant="success" hideIcon>
            Aktiv
          </Badge>
        </Card.Slot>
        <Card.Heading as="h2">{product.shortName}</Card.Heading>
        <Card.Content>
          <SkyProductDescription />
          <Box pt="gutter-lg" pb="gutter-xs">
            <Button variant="text" size="sm" asChild>
              <a href={config.teliaSkyLoginUrl}>
                <Icon name="arrow-right">Gå til Telia Sky</Icon>
              </a>
            </Button>
          </Box>
        </Card.Content>
        {priceText ? (
          <Card.Price
            priceText={priceText.price}
            pricePrefix={priceText.pricePrefix}
            variant="purple"
            textBelowPrice={isB2BTeliaSky ? "Betales av arbeidsgiver" : undefined}
          />
        ) : null}
      </Card>
      {!inOrder && (
        <div>
          <Button
            variant="secondary"
            onClick={() => {
              setShowCancelModal(true);
            }}
            disabled={loading}
            data-tracking-id="additional-services-telia-sky-cancel-button"
          >
            Kanseller
          </Button>
        </div>
      )}
      <SkyCancelServiceModal
        loading={loading}
        isOpen={showCancelModal}
        setIsModalOpen={setShowCancelModal}
        onCancelProduct={cancelSky}
      />
    </Flex>
  );
};
