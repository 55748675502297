import { Transaction } from "./types";

const dimension = {
  financing: "dimension1",
  acquisitionRetention: "dimension3",
  newPortinUpsell: "dimension73",
  combosaleProductname: "dimension74",
  delivery: "dimension75",
  klikkOgHentButikk: "dimension76",
  subscriptionType: "dimension78",
  webdeal: "dimension82",
  xlines: "dimension86",
  xlinesQuantity: "dimension87",
  konvergensYayOrNay: "dimension90",
  ringtimeType: "dimension96",
  userIsAsSameOwner: "dimension103",
  fixedOfferCode: "dimension104",
  previousSubId: "dimension105",
  previousSubName: "dimension106",
  previousSubPrice: "dimension107",
} as const;

export function createTransactionEvent({ products, revenue, shippingPrice, transactionId }: Transaction) {
  const calculatedTax = revenue - revenue / 1.25;
  const tax = Math.round(calculatedTax * 100) / 100; // max two decimals
  return {
    event: "transaction",
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId,
          affiliation: "Telia Min Side",
          revenue,
          tax,
          shipping: shippingPrice,
          action: "purchase",
        },
        products: products.map(
          ({
            financing,
            acquisitionRetention,
            newPortinUpsell,
            klikkOgHentButikk,
            subscriptionType,
            webdeal,
            xlines,
            xlinesQuantity,
            konvergensYayOrNay,
            ringtimeType,
            delivery,
            userIsAsSameOwner,
            fixedOfferCode,
            previousSubId,
            previousSubName,
            previousSubPrice,
            ...rest
          }) => {
            const categoryDimension = rest.category.split("|");
            const vas =
              categoryDimension.length && categoryDimension[0] === "VAS"
                ? categoryDimension[categoryDimension.length - 1]
                : undefined;
            return {
              ...rest,
              vas,
              [dimension.financing]: financing,
              [dimension.acquisitionRetention]: acquisitionRetention,
              [dimension.newPortinUpsell]: newPortinUpsell,
              [dimension.klikkOgHentButikk]: klikkOgHentButikk,
              [dimension.subscriptionType]: subscriptionType,
              [dimension.webdeal]: webdeal,
              [dimension.xlines]: xlines,
              [dimension.xlinesQuantity]: xlinesQuantity,
              [dimension.konvergensYayOrNay]: konvergensYayOrNay,
              [dimension.ringtimeType]: ringtimeType,
              [dimension.delivery]: delivery,
              [dimension.userIsAsSameOwner]: userIsAsSameOwner,
              [dimension.fixedOfferCode]: fixedOfferCode,
              [dimension.previousSubId]: previousSubId,
              [dimension.previousSubName]: previousSubName,
              [dimension.previousSubPrice]: previousSubPrice,
              dimension2: products
                .map((product) => product.category)
                .filter(Boolean)
                .join("|"),
            };
          }
        ),
      },
    },
  };
}
