import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Alert, Container, SkeletonList } from "@telia-no-min-side/components";
import { Header, HeaderText } from "./invoice-settings/styles/InvoiceSettingsPage.styled";
import { AvtalegiroAlert } from "./invoice-settings/components/Avtalegiro/AvtalegiroAlert";
import { InvoiceTypeList } from "./invoice-settings/InvoiceTypeList";
import { DistributionCode, PaymentMethodName } from "src/gql/graphql";
import { useEffect, useState } from "react";
import { Fields } from "./invoice-settings/utils/getInitialState";
import { WithAvtalegiroCard } from "./invoice-settings/components/Avtalegiro/WithAvtalegiroCard";
import { NoAvtalegiroCard } from "./invoice-settings/components/Avtalegiro/NoAvtalegiroCard";
import { GET_INVOICE_SETTINGS } from "./queries";
import { useUpdateInvoiceSettings } from "./invoice-settings/hooks/useUpdateInvoiceSettings";
import { useSelectAccount } from "src/hooks/useSelectAccount";
import { DELIVERY_OPTION, DELIVERY_TYPE } from "util/constants/address";

export enum AvtalegiroStatus {
  SUCCESS = "ok",
  CANCEL = "cancel",
  ERROR = "error",
}

export const InvoiceSettingsPage = () => {
  const [invoiceUpdateResult, setInvoiceUpdateResult] = useState<string>();
  const [invoiceUpdateErrorTwo, setInvoiceUpdateError] = useState<string>();

  const { selectedAccountId } = useSelectAccount();
  const navigate = useNavigate();
  const url = window.location.href;
  const query = new URL(url);
  const avtalegiroStatus = query.searchParams.get("status");

  const [fetchInvoiceSettings, { loading, error, data }] = useLazyQuery(GET_INVOICE_SETTINGS, {
    variables: { accountId: selectedAccountId || "" },
    fetchPolicy: "network-only",
  });
  const profileEmail = data?.account.invoiceReceiver?.individual?.emailAddress;

  useEffect(() => {
    fetchInvoiceSettings();
  }, [invoiceUpdateResult, fetchInvoiceSettings]);

  const [runUpdateInvoiceSettings, { loading: loadingUpdate }] = useUpdateInvoiceSettings({
    onCompleted(data) {
      setInvoiceUpdateResult(data);
    },
    onError(error) {
      setInvoiceUpdateError(error.message);
    },
  });

  if (loading || loadingUpdate || !data) {
    return (
      <Container alignItems="center" justifyContent="center">
        <SkeletonList count={4} />
      </Container>
    );
  }

  if (error || !data?.account.invoiceReceiver?.individual) {
    return (
      <Container showGoBackButton maxWidth="md">
        <Alert isOpen kind="negative">
          En uventet feil oppsto
        </Alert>
      </Container>
    );
  }

  const handleOnSubmit = (
    formData: Fields,
    emailAddress: string,
    distributionType: DELIVERY_TYPE,
    invoiceType: DistributionCode,
    eInvoiceId?: string
  ) => {
    setInvoiceUpdateError(undefined);
    setInvoiceUpdateResult(undefined);
    navigate("/minside/faktura/innstillinger", { replace: true });
    const receiver = {
      name: {
        firstName: formData.firstName.value,
        lastName: formData.surname.value,
      },
      address:
        distributionType === DELIVERY_OPTION.PO_BOX_ADDRESS
          ? {
              postBoxNumber: formData.boxNr.value,
              postBoxName: formData.boxName.value,
              zipCode: formData.postcode.value,
              city: formData.city.value,
            }
          : {
              streetName: formData.streetName.value,
              streetNumber: formData.streetNr.value,
              streetNumberSuffix: formData.streetNrSuffix.value,
              floorNumber: formData.floorNumber.value,
              doorNumber: formData.doorNumber.value,
              zipCode: formData.postcode.value,
              city: formData.city.value,
              coName: formData.careOfName.value,
            },
      emailAddress: emailAddress ?? profileEmail,
    };

    runUpdateInvoiceSettings({
      accountId: data.account.id,
      distributionCode: invoiceType,
      invoiceReceiver: receiver,
      eInvoiceId,
    });
  };

  const { paymentMethod } = data.account;

  return (
    <Container maxWidth="sm" padding showGoBackButton>
      <Header>Bytt fakturatype</Header>
      <Container>
        <AvtalegiroAlert avtalegiroStatus={avtalegiroStatus} />
        <Alert
          title="Din fakturatype er nå byttet"
          text="Din neste faktura vil være på den valgte fakturametoden du har
                byttet til."
          isOpen={!!invoiceUpdateResult}
          kind="positive"
        />
        <Alert
          title="En feil har oppstått"
          text="Vi klarte dessverre ikke å utføre fakturabytte. Vennligst prøv igjen eller kontakt kundeservice."
          isOpen={!!invoiceUpdateErrorTwo}
          kind="negative"
        />
      </Container>
      <HeaderText>Hvordan ønsker du å motta fakturaen din?</HeaderText>

      <Container flexDirection="column" gap="lg" padding="bottom">
        <InvoiceTypeList account={data.account} onSubmit={handleOnSubmit} />
        {paymentMethod && paymentMethod.name === PaymentMethodName.Manual && <NoAvtalegiroCard />}
        {paymentMethod && paymentMethod.name === PaymentMethodName.Avtalegiro && <WithAvtalegiroCard />}
      </Container>
    </Container>
  );
};
