import styled, { css } from "styled-components";
import { colors, SideMenu } from "@telia/styleguide";
import { zIndex } from "../../utils/variables";
import { devices } from "../../utils/breakpoints";
import { fadeIn } from "../../styles/keyframes";

export const SideMenuContainer = styled.div`
  position: relative;
  background-color: ${colors.grey100};
  display: flex;
`;

type PositionSideMenuArgs = {
  fadeInFromRight?: boolean;
};

export const PositionSideMenu = styled.div<PositionSideMenuArgs>`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.sideMenu};
  @media ${devices.sm_down} {
    z-index: ${zIndex.sideMenuMobile};
    left: 100vw;
    transition: transform 300ms ease-in-out;
    ${(props) => {
      if (props.fadeInFromRight) {
        return css`
          transform: translateX(-240px);
        `;
      }
    }}
  }
`;

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${zIndex.sideMenuBackDrop};
  animation: ${fadeIn} 0.2s ease-in-out;
`;

export const StyledSideMenu = styled(SideMenu)`
  padding-top: 1rem;
  @media ${devices.sm_down} {
    padding-top: 3rem;
  }
`;
export const LinkImageItem = styled.li`
  padding: 0 0 10px 20px;
  overflow-x: hidden;
`;
