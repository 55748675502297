import { graphql } from "src/gql";

export const GET_TOPUPS = graphql(`
  query getTopupsNEW($phoneNumber: String!, $filter: TopupFilter) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      userOffering {
        shortName
      }
      topups(filter: $filter) {
        id
        name
        hiddenInGeneralTopupList
        shortDescription
        weight
        type
        category
        quota {
          type
          unit
          value
        }
        duration {
          durationHours
          durationCalendrical {
            days
            type
          }
          durationBillcycle {
            untilDateInclusive
            type
          }
          type
        }
        ... on DomesticTopup {
          price {
            valueWithoutVat
            valueWithVat
            currency
          }
          paymentType
        }
        ... on DataBoostTopup {
          price {
            valueWithoutVat
            valueWithVat
            currency
          }
          paymentType
        }
        ... on SpeedBoostTopup {
          price {
            valueWithoutVat
            valueWithVat
            currency
          }
          paymentType
        }
        ... on RoamingTopup {
          price {
            valueWithoutVat
            valueWithVat
            currency
          }
          paymentType
        }
        ... on FreeDataBoostTopup {
          numberOfVouchers
          latestReceivedDate
          nextExpirationDateNullable
        }
        ... on FreeDataTopup {
          numberOfVouchers
          latestReceivedDate
          nextExpirationDateNullable
        }
      }
    }
  }
`);
