import styled, { css } from "styled-components";
import { colors } from "@telia/styleguide";

const SharedToggleButtonStyle = css`
  font-weight: 500;
  font-size: 1.125rem;
  text-align: left;
  text-decoration: none;
  background-color: white;
  filter: drop-shadow(0 2px 6px ${colors.lightGrey});
  border: none;
  overflow: visible;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  border: solid 2px transparent;
`;

type ToggleButton = {
  isSelected?: boolean;
  isLastChild?: boolean;
};

const activeAndSelectedStyle = css`
  border: solid 2px ${colors.corePurple};
  box-shadow: none;
`;

export const StyledButton = styled.button<ToggleButton>`
  ${SharedToggleButtonStyle};
  margin: 8px ${(props) => (props.isLastChild ? 0 : "16px")} 8px 0;
  cursor: pointer;
  max-width: max-content;
  white-space: nowrap;
  transition:
    background-color 200ms ease,
    color 200ms ease;
  min-height: 73px;

  /* If desktop  */
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      box-shadow: 0px 3px 8px ${colors.grey300};
    }
  }
  ${(props) => {
    if (props.isSelected)
      return css`
        ${activeAndSelectedStyle}
      `;
  }}
`;
