import React, { useEffect } from "react";
import { Backdrop } from "../Backdrop";
import { useWindowSize } from "../hooks";
import { useAria } from "../hooks/context/aria";
import { useEscapeListener } from "../hooks/useEscapeListener";
import { useFocusTrap } from "../hooks/useFocusTrap";
import { ModalWrapper, ScrollElement, StyledIconButton } from "./style";
import { ModalProps } from ".";

/**
 * @deprecated Use Teddy components instead
 */
export function ModalElement(props: ModalProps): JSX.Element {
  const {
    isOpen,
    setIsOpen,
    size,
    removePadding = false,
    children,
    className,
    dataTrackingID,
    showCloseButton,
    backDropColor,
    onClose,
    ...wraperProps
  } = props;
  const { isMobile } = useWindowSize();

  function closeModal() {
    setIsOpen(false);
    onClose && onClose();
  }

  const { container } = useFocusTrap();
  useEscapeListener({ onEscape: closeModal });
  const { labelId } = useAria();

  useEffect(() => {
    let returnFocusTo: Element | null;
    if (isOpen) {
      returnFocusTo = document.activeElement;
      container.current?.focus();
      document.querySelector("html")?.classList.add("scroll-lock");
    } else {
      document.querySelector("html")?.classList.remove("scroll-lock");
    }
    return () => {
      (returnFocusTo as HTMLElement)?.focus();
      document.querySelector("html")?.classList.remove("scroll-lock");
    };
  }, [isOpen]);

  return (
    <>
      <Backdrop color={backDropColor} fadeIn={isMobile} isVisible={isOpen} onClick={closeModal} />
      <ModalWrapper
        {...wraperProps}
        className={className}
        ref={container}
        tabIndex={isOpen ? 0 : -1}
        role="dialog"
        aria-labelledby={labelId}
        aria-modal="true"
        aria-hidden={!isOpen}
        isOpen={isOpen}
        size={size}
        removePadding
        data-tracking-id={dataTrackingID}
      >
        <ScrollElement removePadding={removePadding}>{children}</ScrollElement>
        {showCloseButton && (
          <StyledIconButton onClick={closeModal} isIconButton title="Lukk" variant="tertiary-black" icon="close" />
        )}
      </ModalWrapper>
    </>
  );
}
