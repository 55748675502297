import { Container, devices, images, remCalc } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";
import styled from "styled-components";

export const Hero = styled.div`
  font-size: ${remCalc(18)};
  color: ${colors.white};
  font-weight: normal;
  padding: 0;
  margin: 10px 0;
  position: relative;
  background-color: ${colors.corePurple850};
  background-image: url(${images.Telia5thElementPurplePng});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 100%;
  background-clip: padding-box;
  @media ${devices.sm_down} {
    background-position: 50vw;
  }
`;

export const HeroHeading = styled.h2`
  font-size: min(max(${remCalc(24)}, 3vw), ${remCalc(40)});
  grid-template-columns: 1fr 1fr;
  display: grid;
  grid-column: 1 / 3;
  align-items: end;
  margin: 0 0 0.5rem;
  gap: 0.25rem 0.5rem;
`;
export const SubHeading = styled.span`
  font-size: ${remCalc(16)};
  text-transform: capitalize;
`;
export const InfoGrid = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.25rem 0.5rem;
`;
export const GridItem = styled.div`
  font-size: ${remCalc(14)};
`;
