import { BaseSyntheticEvent } from "react";
import { Container, ToggleButtonGroup } from "@telia-no-min-side/components";
import { useSelectAccount, AccountType } from "hooks/useSelectAccount";
import { useAccountType } from "../hooks/useAccountType";
import { AUTH } from "util/constants/auth";

export function AccountTypePicker() {
  const { isConvergentUser, mobileUserSelectedAccountId, fixedUserSelectedAccountId } = useAccountType();
  const { selectedAccountType, setAccount } = useSelectAccount();

  const accountTypes = [
    { label: "Mobil", value: AUTH.ACCOUNT_TYPE.MOBILE },
    { label: "TV/internett", value: AUTH.ACCOUNT_TYPE.FIXED },
  ];

  const updateAccountType = (_: BaseSyntheticEvent, newSelectedAccountType?: AccountType) => {
    if (!newSelectedAccountType) return;
    if (newSelectedAccountType === AUTH.ACCOUNT_TYPE.MOBILE) {
      setAccount({ id: mobileUserSelectedAccountId, accountType: newSelectedAccountType });
    }
    if (newSelectedAccountType === AUTH.ACCOUNT_TYPE.FIXED) {
      setAccount({ id: fixedUserSelectedAccountId, accountType: newSelectedAccountType });
    }
  };

  if (!isConvergentUser) {
    return null;
  }

  return (
    <Container maxWidth="full" gap="lg">
      <ToggleButtonGroup variant="profile-picker" value={selectedAccountType} onChange={updateAccountType}>
        {accountTypes.map(({ value, label }) => (
          <ToggleButtonGroup.Item key={`service-${value}`} value={value} text={label} />
        ))}
      </ToggleButtonGroup>
    </Container>
  );
}
