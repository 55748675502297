import { useQuery } from "@apollo/client";
import { graphql } from "../../../gql";
import { DashboardSection } from "../card/DashboardSection";
import { LoadingSubscriptionCard } from "../card/LoadingSubscriptionCard";
import { getB2BAdditionalServices } from "./utils";
import { AdditionalServiceCard } from "./components/AdditionalServiceCard";
import { uri } from "@telia-no-min-side/utils";
import { MOBILE_VAS, B2B_VAS_PRODUCT_CODES } from "util/constants/valueAddedServices";

const getAdditionalServicesSummaryQuery = graphql(`
  query GetAdditionalServicesSummary($manualProducts: [String!]) {
    accounts {
      id
      agreementType
      subscriptions {
        phoneNumber {
          localNumber
        }
        productUser {
          emailAddress
          firstName
        }
        additionalProducts(manualProducts: $manualProducts) {
          id
          productGroup
          code
          shortName
          presentation {
            active
          }
        }
      }
    }
  }
`);

export function AdditionalServices() {
  const additionalServicesSummary = useQuery(getAdditionalServicesSummaryQuery, {
    variables: { manualProducts: B2B_VAS_PRODUCT_CODES },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const isLoading = additionalServicesSummary.loading && !additionalServicesSummary.data;

  if (isLoading) {
    return (
      <DashboardSection isLoading>
        <LoadingSubscriptionCard />
      </DashboardSection>
    );
  }

  if (additionalServicesSummary.error) {
    return null;
  }

  const additionalServicesList = getB2BAdditionalServices(additionalServicesSummary.data?.accounts);

  if (!additionalServicesList.length) {
    return null;
  }

  return (
    <DashboardSection title="Tjenester">
      {additionalServicesList.map((service) => {
        switch (service.productGroup as MOBILE_VAS) {
          case MOBILE_VAS.SKY:
            return (
              <AdditionalServiceCard
                service={service}
                checkoutUrl={uri.minSideFixed("/tjenester")}
                description="Lagre alt du trenger, helt trygt."
                icon="cloud"
                key={`sky-${service.phoneNumber}`}
              />
            );
          case MOBILE_VAS.TRYGG:
            return (
              <AdditionalServiceCard
                service={service}
                checkoutUrl={uri.minSideFixed("/tjenester")}
                description="Bestill Telia Trygg og invester i sikkerheten din på nett i dag."
                icon="secured-1"
                key={`trygg-${service.phoneNumber}`}
              />
            );
          default:
            return null;
        }
      })}
    </DashboardSection>
  );
}
