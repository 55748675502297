import { BillingType, UserTopupQuotas } from "gql/graphql";
import dayjs from "dayjs";

export const isPrepaidAccount = (billingType?: BillingType) => billingType === BillingType.Prepaid;

export const canOrderNordicOrBalticMinutesPrepaidPlan = (topupQuotas?: UserTopupQuotas[]) => {
  const nordicBalticsMinutes = topupQuotas?.find((quota) => quota.product?.isNordicAndBalticMinutes);
  return (
    !nordicBalticsMinutes ||
    nordicBalticsMinutes.usage.remaining.amount <= 1 ||
    dayjs(nordicBalticsMinutes.expireTime).isBefore()
  );
};
