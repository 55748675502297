import { useFetchSelectedAccount, useFetchTvOverFwa as useFetchTvOverFwaOriginal } from "src/api/fixed";

export function useFetchTvOverFwa() {
  const tvOverFwa = useFetchTvOverFwaOriginal({ fetchAllAccounts: true });
  const selectedAccount = useFetchSelectedAccount();
  const fwaOverFwaSelectedUser = tvOverFwa.data?.[selectedAccount.data?.customerId || ""];

  const flattenTvOverFwa =
    tvOverFwa.data && Object.values(tvOverFwa.data).length
      ? Object.values(tvOverFwa.data).reduce((acc, tvOverFwaPrCustomer) => [...acc, ...tvOverFwaPrCustomer])
      : null;

  const productWithMostDiscountAllAccounts = flattenTvOverFwa?.length
    ? flattenTvOverFwa.reduce((accProduct, currentProduct) => {
        const currentProductPrice = currentProduct.prices;
        const currentProductDiscount = currentProductPrice.postCampaignAmount - currentProductPrice.postCampaignAmount;
        const accProductPrice = accProduct.prices;
        const accProductDiscount = accProductPrice.postCampaignAmount - accProductPrice.postCampaignAmount;
        return currentProductDiscount > accProductDiscount ? currentProduct : accProduct;
      })
    : null;
  const mostDiscountPriceAllAccounts = productWithMostDiscountAllAccounts
    ? productWithMostDiscountAllAccounts.prices.postCampaignAmount -
      productWithMostDiscountAllAccounts.prices.currentAmount
    : 0;
  const isCampaignAllAccounts = productWithMostDiscountAllAccounts
    ? productWithMostDiscountAllAccounts.prices.postCampaignAmount >
      productWithMostDiscountAllAccounts.prices.currentAmount
    : 0;

  const customerIdsWithAvailableTvOverFwa = tvOverFwa.data
    ? Object.keys(tvOverFwa.data).filter((customerId) => {
        return tvOverFwa.data?.[customerId]?.some((product) => product.productId);
      })
    : null;

  const productWithMostDiscountSelectedAccount = fwaOverFwaSelectedUser?.length
    ? fwaOverFwaSelectedUser.reduce((accProduct, currentProduct) => {
        const currentProductPrice = currentProduct.prices;
        const currentProductDiscount = currentProductPrice.postCampaignAmount - currentProductPrice.currentAmount;
        const accProductPrice = accProduct.prices;
        const accProductDiscount = accProductPrice.postCampaignAmount - accProductPrice.currentAmount;
        return currentProductDiscount > accProductDiscount ? currentProduct : accProduct;
      })
    : null;
  const mostDiscountPriceSelectedAccount =
    (productWithMostDiscountSelectedAccount?.prices.postCampaignAmount || 0) -
    (productWithMostDiscountSelectedAccount?.prices.currentAmount || 0);

  const { data, isLoading, error, ...rest } = tvOverFwa;

  return {
    ...rest,
    isLoading: isLoading || selectedAccount.isLoading,
    error: error || selectedAccount.error,
    isError: error || selectedAccount.isError,
    customerIdsWithAvailableTvOverFwa,
    allAccounts: {
      data,
      hasAvailableTvOverFwaOffer: (customerIdsWithAvailableTvOverFwa?.length || 0) > 0,
      productWithMostDiscount: productWithMostDiscountAllAccounts,
      mostDiscountPrice: mostDiscountPriceAllAccounts,
      isCampaign: isCampaignAllAccounts,
      hasMultipleFwaProducts: (flattenTvOverFwa?.length || 0) > 1,
    },
    selectedAccount: {
      data: fwaOverFwaSelectedUser,
      mostDiscountPrice: mostDiscountPriceSelectedAccount,
      productWithMostDiscount: productWithMostDiscountSelectedAccount,
      isCampaign: mostDiscountPriceSelectedAccount > 0,
      hasMultipleFwaProducts: (fwaOverFwaSelectedUser?.length || 0) > 1,
    },
  };
}
