import { Paragraph } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import { OrderAddressType } from "gql/graphql";
import { useSimCheckout } from "pages/mobile/mobile-subscription-sim-pin-puk/hooks/useSimCheckout";

export const PostAddress = () => {
  const { userFullName, deliveryAddress } = useSimCheckout();

  return (
    <>
      {userFullName ? (
        <Paragraph variant="additional-100" data-di-mask>
          {userFullName}
        </Paragraph>
      ) : null}
      {deliveryAddress?.addressType === OrderAddressType.Urban ? (
        <>
          <Paragraph variant="additional-100" data-di-mask>
            {format.firstLetterUpperCase(deliveryAddress.streetName || "")} {deliveryAddress.streetNr}
            {deliveryAddress.streetNrSuffix}
          </Paragraph>

          <Paragraph variant="additional-100" data-di-mask>
            {deliveryAddress.postcode} {format.firstLetterUpperCase(deliveryAddress.city || "")}
          </Paragraph>
        </>
      ) : null}
      {deliveryAddress?.addressType === OrderAddressType.Pobox ? (
        <>
          <Paragraph variant="additional-100" data-di-mask>
            {format.firstLetterUpperCase(deliveryAddress.boxName || "")} {deliveryAddress.boxNr}
          </Paragraph>

          <Paragraph variant="additional-100" data-di-mask>
            {deliveryAddress.postcode} {format.firstLetterUpperCase(deliveryAddress.city)}
          </Paragraph>
        </>
      ) : null}
      <Paragraph variant="additional-100" isFaded>
        Vi sender ditt SIM-kort til denne adressen
      </Paragraph>
    </>
  );
};
