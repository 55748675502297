import { Button, Container, Paragraph } from "@telia-no-min-side/components";
import { track } from "@telia-no-min-side/utils";
import { CustomerCareLink } from "src/components/customer-care-link";
import { useSelfRecoveryState } from ".";

export function ErrorPage(): JSX.Element {
  const { setRecoveryPage } = useSelfRecoveryState();
  const { pushGenericTrackingEvent } = track.useEventTracking();
  return (
    <div>
      <Paragraph>Beklager! En uventet feil skjedde.</Paragraph>
      <Paragraph>Vennligst prøv igjen litt senere.</Paragraph>
      <Paragraph>
        Har du ikke mulighet til å bruke BankID, er du velkommen til å kontakte kundeservice for å løse denne
        situasjonen.
      </Paragraph>
      <Container flexDirection="column" gap floatContent="left" padding="top">
        <Button
          variant="primary"
          onClick={() => {
            pushGenericTrackingEvent({
              ui_item_action: "button_click",
              ui_item_context: "self-recovery",
              ui_item_text: "Prøv igjen",
              ui_item_type: "button",
              ui_item_url: "no_url",
            });
            setRecoveryPage("chooseRecoveryMethod");
          }}
        >
          Prøv igjen
        </Button>
        <CustomerCareLink trackingContext="self-recovery" />
      </Container>
    </div>
  );
}
