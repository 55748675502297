import { Alert, Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { Icon } from "@telia/styleguide";
import { descriptionItemList } from "./descriptionItemList";
import { ListItem, UserInfoCard } from "./styles/AvtalegiroDescription.styled";
import { AvtalegiroData } from "src/gql/graphql";

type AvtalegiroDescriptionComponentProps = {
  accountId: string;
  data?: AvtalegiroData;
  error?: string;
};

export const AvtalegiroDescription = ({ accountId, data, error }: AvtalegiroDescriptionComponentProps) => {
  const avtalegiroLink = data?.shortUrl;

  return (
    <Container showGoBackButton padding="left" maxWidth="md">
      <Alert
        withSpacing
        icon="alert"
        kind="negative"
        isOpen={!!error}
        title="Vi klarte ikke å laste inn siden"
        text="Vennligst prøv igjen ved å laste inn siden på nytt."
      />
      <Container padding="bottom" paddingSize="lg">
        <Heading variant="title-200" tag="h2">
          Opprett AvtaleGiro
        </Heading>
        <Paragraph>Du er i ferd med å opprette AvtaleGiro for mobilfakura med kundenummer:</Paragraph>
        {accountId && (
          <UserInfoCard padding="unset" removeMinHeight backgroundColor="grey">
            <Icon icon="user" />
            <span>{accountId}</span>
          </UserInfoCard>
        )}
      </Container>
      <Heading variant="title-100" tag="h2">
        Verdt å vite om AvtaleGiro
      </Heading>
      {descriptionItemList.map((item, index) => (
        <ListItem key={index}>
          <Icon icon={item.icon} />
          <p>{item.text}</p>
        </ListItem>
      ))}
      <Container padding="bottom" flexDirection="rowReverse">
        <Button
          variant="primary"
          tag="a"
          href={avtalegiroLink ?? ""}
          disabled={!avtalegiroLink}
          icon="arrow-large-right"
          iconPosition="after"
          size="sm"
        >
          Opprett AvtaleGiro
        </Button>
      </Container>
    </Container>
  );
};
