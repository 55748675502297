import { graphql } from "src/gql";

export const GET_ORDER_STATE = graphql(`
  query getOrderState($phoneNumber: String!, $orderId: Int!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      order(orderId: $orderId) {
        orderState
      }
    }
  }
`);
