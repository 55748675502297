import { graphql } from "src/gql";

export const GET_NORDIC_BALTIC_MINUTES = graphql(`
  query getNordicBalticMinutes($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }

      additionalProducts(productCategory: SERVICE) {
        id
        code
        presentation {
          active
          modifiable
        }
        price {
          price
        }
        name
        shortDescription
      }
    }
  }
`);
