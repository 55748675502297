import { Flex, Heading, Text, TextSpacing, Button, breakpoint, Icon } from "@telia/teddy";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "@telia-no-min-side/components";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { graphql } from "src/gql";
import { useApolloClient, useQuery } from "@apollo/client";
import { memo } from "react";
import { OrderState } from "gql/graphql";
import { uri } from "@telia-no-min-side/utils";

const GET_ORDER_GUID = graphql(`
  query getOrderGuid($ID: Int!, $phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        countryCode
        localNumber
      }
      order(orderId: $ID) {
        id
        orderState
        orderGuid
      }
    }
  }
`);

export function SimCardOrderStatus() {
  const { addToast } = useToast();
  const { orderId, phoneNumber } = useParams<{ orderId?: string; phoneNumber?: string }>();
  const navigate = useNavigate();
  const { subscribeToOrder, unsubscribeFromOrder } = useOrderSubscription();
  const client = useApolloClient();

  const { data, error } = useQuery(GET_ORDER_GUID, {
    skip: !orderId || !phoneNumber,
    variables: {
      ID: Number(orderId),
      phoneNumber: phoneNumber || "",
    },
    onCompleted: (data) => {
      if (!orderId || !data || !phoneNumber) return;

      const simOrder = data.subscription.order;
      const isOrderComplete = simOrder?.orderState === OrderState.Completed;

      if (isOrderComplete) {
        return;
      }

      subscribeToOrder({
        id: orderId,
        toastText: "Installeringssiden er klar!",
        dataTrackingId: "esim-card-order-status-success",
        phoneNumber,
        onOrderCompleted: () => {
          // invalidate selected subscription in cache and refetch from the network
          // in order to get fresh mainSimcard, additionalSimcards, additionalProducts data
          client.refetchQueries({
            updateCache(cache) {
              const args = { phoneNumber };
              cache.evict({ fieldName: "subscription", args });
            },
          });
        },
      });
    },
    onError: () => {
      addToast({
        text: "Klarte ikke å laste inn siden. Vennligst bruk linken vi har sendt til deg på mail. ",
        variant: "error",
        dataTrackingId: "esim-card-order-status-error",
      });
    },
  });

  const goBack = () => {
    if (orderId) {
      unsubscribeFromOrder(orderId);
    }
    navigate("/minside/mobil/sim-pin-puk");
  };

  const simOrder = data?.subscription.order;
  const orderGuid = simOrder?.orderGuid;
  const isOrderCompleted = simOrder?.orderState === OrderState.Completed;

  return (
    <Flex direction="column" gap="600" maxWidth={breakpoint.teddyBreakpointMd}>
      <TextSpacing data-tracking-id="sim-card-order-status-content">
        <Heading as="h2" variant="title-100" data-tracking-id="ordered-sim-page-heading">
          Du har nå bestilt eSIM
        </Heading>
        <Text data-tracking-id="ordered-sim-page-description-1">
          Før du kan ta i bruk ditt nye eSIM må det installeres.
        </Text>
        <Text data-tracking-id="ordered-sim-page-description-2">
          Ikke lukk denne siden hvis du vil installere eSIM nå. Det kan ta noen minutter å laste inn
          innstalleringssiden.
        </Text>
        <Text data-tracking-id="ordered-sim-page-description-3">
          Hvis du vil installere eSIM ved en senere anledning, blir linken til installeringssiden også sendt til deg på
          mail.
        </Text>
      </TextSpacing>
      <Flex gap="200" justify="flex-end" data-tracking-id="sim-card-order-status-buttons">
        <Button variant="secondary" onClick={goBack} data-tracking-id="ordered-sim-page-install-later-button">
          Jeg installerer senere
        </Button>
        <Button asChild loading={!isOrderCompleted} disabled={!orderGuid || !!error}>
          {isOrderCompleted ? (
            <a href={uri.openPages(`/esim/installering/${orderGuid}`)} data-tracking-id="install-esim-button">
              Installer eSIM nå
              <Icon name="arrow-right" />
            </a>
          ) : (
            <a data-tracking-id="install-esim-button-loading">Installeringssiden lastes</a>
          )}
        </Button>
      </Flex>
    </Flex>
  );
}

export const SimCardOrderStatusMemoized = memo(SimCardOrderStatus);
