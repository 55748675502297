import { useState } from "react";
import { useQuery } from "@apollo/client";
import { isOlderThan } from "@telia-no-min-side/utils";
import { Flex, Heading, Icon } from "@telia/teddy";
import { ACCESS_LEVEL, useAuth } from "@telia-no-min-side/components";
import { UserRole } from "gql/graphql";
import { ErrorBox } from "components/ErrorBox";
import { isBuyable, isMultiSIM } from "./utils";
import { Breadcrumb } from "components/Breadcrumb";
import { GET_SIM_DATA } from "./graphql/getSimData";
import { useMobileAccount } from "hooks/useMobileAccount";
import { SimPinPukLoading, MainSimCard, AdditionalSimCard, ActionButtons } from "./components";

export function MobileSubscriptionSimPinPuk() {
  const [isOver18, setIsOver18] = useState(false);
  const [isLegalOwner, setIsLegalOwner] = useState(false);

  const { accessLevel } = useAuth();
  const { phoneNumber } = useMobileAccount();

  const { loading, data, error, refetch } = useQuery(GET_SIM_DATA, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const subscription = data.subscription;
      const birthDate = subscription?.productUser?.birthDate || "";
      const isLegalOwner = !!subscription?.roles?.some((role) => role === UserRole.LegalOwner);

      setIsOver18(isOlderThan(18, birthDate));
      setIsLegalOwner(isLegalOwner);
    },
  });

  const subscription = data?.subscription;
  const mainSimCard = subscription?.mainSimcard;
  const additionalProducts = subscription?.additionalProducts;

  const availableSimProducts = additionalProducts?.filter((product) => isMultiSIM(product) && isBuyable(product));
  const areSimProductsAvailable = !!availableSimProducts && availableSimProducts.length > 0;
  const canOrderSim = areSimProductsAvailable && (isOver18 || isLegalOwner) && accessLevel === ACCESS_LEVEL.SILVER;

  // We get subscription data with a list of SIM cards from CDS, but we get additional products from PM
  // (every SIM card has an additional product which stores SIM card price).
  // It seems when sim cards are ordered/deleted and the order is in state "COMPLETE" PM is in sync with changes
  // but CDS is not - it still returns the old sim data. So filtering out SIM cards that exist in CDS but
  // does not have an additional product in PM.
  const additionalSimCards = subscription?.additionalSimcards?.filter((card) => !!card?.product);

  return (
    <Flex direction="column" width="100%" gap="50px">
      <Flex direction="column" width="100%" gap="gutter-xl">
        <Breadcrumb />

        <Heading as="h2" variant="title-300">
          SIM, PIN & PUK
        </Heading>
      </Flex>

      {loading && <SimPinPukLoading />}
      {error && (
        <ErrorBox
          title="Vi beklager"
          description="Dessverre oppstod det en feil under innlastningen av denne siden. Vennligst prøv igjen litt senere."
          actionContent={<Icon name="sync">Last side på nytt</Icon>}
          onClick={() => refetch()}
          maxWidth="600px"
        />
      )}

      {!loading && !error && (
        <>
          {mainSimCard && <MainSimCard simCard={mainSimCard} isOver18={isOver18} />}

          {!!additionalSimCards?.[0] &&
            additionalSimCards.map((additionalSimCard) => (
              <AdditionalSimCard
                key={additionalSimCard?.simCardNumber}
                simCard={additionalSimCard}
                isOver18={isOver18}
                isLegalOwner={isLegalOwner}
              />
            ))}

          <ActionButtons canOrderSim={canOrderSim} />
        </>
      )}
    </Flex>
  );
}
