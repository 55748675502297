import { types, uri } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { getCustomerSpecificUrl } from "./utils";
import { useFetchProfile } from "./useFetchProfile";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

type FetchFlexRequirements = {
  productIdTv: string | undefined | null;
  productIdBroadband: string | undefined | null;
};

function getFlexRequirementsUrl(
  customerId: string | undefined,
  { productIdTv, productIdBroadband }: FetchFlexRequirements
) {
  if (!productIdTv && !productIdBroadband) return null;
  const baseUrl = getCustomerSpecificUrl(ICX_REQUEST.URL_FLEX_REQUIREMENTS, {
    customerId,
    requireCustomerId: true,
  });
  if (!productIdTv && productIdBroadband) {
    return uri.purchase(`${baseUrl}?bbProduct=${productIdBroadband}`);
  }
  if (productIdTv && !productIdBroadband) {
    return uri.purchase(`${baseUrl}?tvProduct=${productIdTv}`);
  }
  return uri.purchase(`${baseUrl}?tvProduct=${productIdTv}&bbProduct=${productIdBroadband}`);
}

export function useFetchFlexRequirements({ productIdTv, productIdBroadband }: FetchFlexRequirements) {
  const customerId = useFetchProfile().data?.customerId.toString();
  const url = getFlexRequirementsUrl(customerId, {
    productIdTv,
    productIdBroadband,
  });
  const { error, ...rest } = useAxiosSWR<types.fixed.FlexProductRequirements>(!url ? null : url);

  return {
    isError: !!error,
    error,
    ...rest,
  };
}
