import { ComponentProps, useEffect } from "react";
import { useProfilePicker } from "./hooks/useProfilePicker";
import { types } from "@telia-no-min-side/utils";
import { ProfilePickerTypes, ProfilePicker } from "@telia-no-min-side/components";
import { useFixedAccount } from "src/hooks/useFixedAccount";
import { datadogRum } from "@datadog/browser-rum";
import { useFetchProfiles } from "src/api/fixed/useFetchProfiles";

/**
 * FixedProfilePicker is used for switching between fixed.
 * customer profiles. It displays all existing fixed customers
 * (even when they don't have broadband subscription).
 */

function getSelectedProfile(
  profiles: ProfilePickerTypes.Profile<types.fixed.UserWithCustomerId>[]
): types.fixed.UserWithCustomerId | undefined {
  const selectedProfile = (profiles || []).find((profile) => profile.value.selected);
  return selectedProfile?.value;
}

type FixedProfilePickerProps = {
  dataTrackingID?: string;
  fwaCustomerIds: string[];
  pickerVariant?: ComponentProps<typeof ProfilePicker>["variant"];
};

export function FwaProfilePicker({ dataTrackingID, fwaCustomerIds, pickerVariant }: FixedProfilePickerProps) {
  const profilesWithPlatforms = useFetchProfiles();

  const { fixed } = useProfilePicker({ filter: "fixed" });
  const { changeFixedAccount } = useFixedAccount();

  const fwaProfiles = fixed.profiles?.filter((profile) => fwaCustomerIds.includes(profile.value.customerId));

  const currentSelectedProfile = getSelectedProfile(fwaProfiles);

  useEffect(() => {
    if (!currentSelectedProfile && fwaProfiles.length > 0) {
      onProfileChange(fwaProfiles[0].value);
    }
  }, [fwaProfiles, currentSelectedProfile]);

  const showRetry = !fixed.loading && fixed.hasError;

  const onRetry = () => {
    if (fixed.hasError) {
      fixed.refetch();
    }
  };

  const onProfileChange = async (profile: types.fixed.UserWithCustomerId) => {
    try {
      // SWR will change the selected profile immediately
      // in cache. There is no need to store selected profile locally
      // during the request duration
      await changeFixedAccount(profile.userKey);
    } catch (error) {
      datadogRum.addError(error, { feature: "FixedProfilePicker" });
    }
  };

  const showLoading = !fixed.profiles?.length && fixed.loading && profilesWithPlatforms.isLoading;

  return (
    <ProfilePicker
      profiles={fwaProfiles}
      loading={showLoading}
      selectedProfile={currentSelectedProfile}
      onChange={onProfileChange}
      showRetry={showRetry}
      onRetry={onRetry}
      dataTrackingId={dataTrackingID}
      variant={pickerVariant}
      containerProps={{ gap: true }}
    />
  );
}
