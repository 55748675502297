import type { Dispatch, SetStateAction } from "react";
import { Card, Text, Button } from "@telia/teddy";

type Props = {
  title: string;
  countries: string[];
  setIsTermsVisible: Dispatch<SetStateAction<boolean>>;
};

export const Terms = ({ title, countries, setIsTermsVisible }: Props) => {
  return (
    <>
      <Card.Heading variant="title-400">{title}</Card.Heading>
      <Card.Line />
      <Card.Content>
        <Text variant="paragraph-100-medium">Din datapakke kan brukes i disse landene:</Text>
        <Text style={{ lineHeight: "1.8" }}>{countries.join(", ")}</Text>
      </Card.Content>
      <Button width="100%" variant="text" onClick={() => setIsTermsVisible(false)}>
        Lukk
      </Button>
    </>
  );
};
