import { useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Flex, Label, InputGroup } from "@telia/teddy";
import { Topup } from "../../../components/Topup";
import { GET_TOPUPS } from "../../../graphql";
import { getTopupPeriod } from "../../../utils";
import { useMobileAccount } from "hooks/useMobileAccount";
import { getTopupDisplayInfo } from "../../../../TopupModal/utils";
import { AdditionalProductPaymentType, RoamingCountry, TopupCategory, TopupFilterRoamingZone } from "gql/graphql";
import { CountrySearchLoading, Error, EmptyRoamingTopupsNotification, RoamingLikeHomeBadge, CountryDropdown } from ".";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";
import { getTopupDuration } from "pages/mobile/mobile-subscription-dashboard/modules/TopupModal/utils/getTopupDuration";

type Props = {
  roamingCountries: RoamingCountry[];
};

export function CountrySearch({ roamingCountries }: Props) {
  const [countryFilter, setCountryFilter] = useState("");
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [isSubscriptionTypeUnsupported, setIsSubscriptionTypeUnsupported] = useState(false);

  const { phoneNumber } = useMobileAccount();
  const { isB2B, handleTopupSelection } = useMobileDashboardContext();

  const [fetchCountrySpecificTopups, { loading, data, error, refetch }] = useLazyQuery(GET_TOPUPS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const countrySpecificTopups = data?.subscription?.topups || [];
  const hasDomesticalTopups = countrySpecificTopups.some((topup) => topup.category !== TopupCategory.Roaming);

  const roamingTopups = countrySpecificTopups.filter((topup) => {
    if (topup.category !== TopupCategory.Roaming || topup.hiddenInGeneralTopupList) return false;

    return isB2B ? "paymentType" in topup && topup.paymentType === AdditionalProductPaymentType.Vipps : true;
  });

  const isRoamingLikeHome = hasDomesticalTopups && roamingTopups.length === 0;

  const handleCountrySelection = (selectedCountry: RoamingCountry) => {
    fetchCountrySpecificTopups({
      variables: {
        phoneNumber,
        filter: {
          countryCode: selectedCountry.countryCode,
          zone: TopupFilterRoamingZone.FromCountryCode,
        },
      },
      onError(error) {
        setIsSubscriptionTypeUnsupported(error.message.includes("SUBSCRIPTION_TYPE_UNSUPPORTED"));
      },
    });

    setIsCountrySelected(true);
    setCountryFilter(selectedCountry.name || "");
  };

  const filteredCountries = useMemo(() => {
    if (!countryFilter) return roamingCountries;
    return roamingCountries
      .filter((c) => c.name?.toLowerCase().includes(countryFilter.toLowerCase()))
      .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));
  }, [countryFilter, roamingCountries]);

  const handleInputChange = (e: string) => {
    setIsCountrySelected(false);
    setIsSubscriptionTypeUnsupported(false);
    setCountryFilter(e);
  };

  return (
    <Flex direction="column" gap="gutter-xl">
      <Flex direction="column" gap="100">
        <Label>Velg land</Label>
        <InputGroup.Input placeholder="Velg et land" value={countryFilter} onValueChange={handleInputChange} />
      </Flex>

      {!!countryFilter && !isCountrySelected && (
        <CountryDropdown filteredCountries={filteredCountries} handleCountrySelection={handleCountrySelection} />
      )}

      {loading && <CountrySearchLoading />}
      {error && !isSubscriptionTypeUnsupported && isCountrySelected && <Error refetch={refetch} />}
      {isRoamingLikeHome && isCountrySelected && <RoamingLikeHomeBadge />}

      {((isCountrySelected && countrySpecificTopups.length === 0) || isSubscriptionTypeUnsupported) && !loading && (
        <EmptyRoamingTopupsNotification />
      )}

      <Flex direction="column" gap="gutter-sm">
        {!loading &&
          !error &&
          !isRoamingLikeHome &&
          isCountrySelected &&
          countrySpecificTopups.map((topup) => {
            const value = topup.quota.value.toString();
            const valueUnit = topup.quota.unit;
            const period = getTopupPeriod(topup.name);
            const topupInfo = getTopupDisplayInfo(topup);
            const hasVouchers = topupInfo.numberOfVouchers && topupInfo.numberOfVouchers > 0;
            const vouchersNumber = hasVouchers ? topupInfo.numberOfVouchers : 0;
            const duration = getTopupDuration(topup.duration);
            const price = "price" in topup ? topup.price?.valueWithVat : undefined;
            const isVippsPayment =
              "paymentType" in topup ? topup.paymentType === AdditionalProductPaymentType.Vipps : false;

            return (
              <Topup
                key={topup.id + topup.name}
                duration={duration}
                value={value}
                valueUnit={valueUnit}
                period={period}
                price={price}
                isVippsPayment={isVippsPayment}
                buttonText={hasVouchers ? "Aktiver" : "Kjøp"}
                onClick={() => handleTopupSelection(topup)}
                vouchersNumber={vouchersNumber}
              />
            );
          })}
      </Flex>
    </Flex>
  );
}
