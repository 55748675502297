export const productId = {
  smartWifiRouters: [1973, 1974],
  smartWifiExtender: 1975,
  teliaWifiRouter: 2050,
  movieRental: 205,
  teliaBox: [1710, 1711],
  teliaPlayBox: 2077,
  noBox: 1676,
  tvBoxDelayedReturn: 2060,
  recording: 1605,
  remoteControl: 1828,
  tvPoints: {
    p20: 1684,
    p10: 1685,
    p0: 873,
  },
};
