import cloneDeep from "lodash.clonedeep";
import REQ from "../../../util/requestStatus";
import { generalUtilities } from "../util/GeneralUtilities";

// Actions:
export const routerSettingsAction = {
  type: {
    FETCH_ROUTER_SETTINGS_REQUEST: Symbol("FETCH_ROUTER_SETTINGS_REQUEST"),
    FETCH_ROUTER_SETTINGS_ERROR: Symbol("FETCH_ROUTER_SETTINGS_ERROR"),
    FETCH_ROUTER_SETTINGS_SUCCESS: Symbol("FETCH_ROUTER_SETTINGS_SUCCESS"),
    UPDATE_ACTIVE_UNIT_REQUEST: Symbol("UPDATE_ACTIVE_UNIT_REQUEST"),
    UPDATE_ACTIVE_UNIT_SUCCESS: Symbol("UPDATE_ACTIVE_UNIT_SUCCESS"),
    UPDATE_ACTIVE_UNIT_ERROR: Symbol("UPDATE_ACTIVE_UNIT_ERROR"),
    UPDATE_ACTIVE_UNIT_SETTINGS_IN_STORE: Symbol(
      "UPDATE_ACTIVE_UNIT_SETTINGS_IN_STORE"
    ),
    SET_ACTIVE_UNIT: Symbol("SET_ACTIVE_UNIT"),
    FLIP_OTHER_SETTINGS_CHANGED: Symbol("FLIP_OTHER_SETTINGS_CHANGED"),
  },
};

export const RouterSettingsReducer = (state, action) => {
  const newState = cloneDeep(state);

  switch (action.type) {
    case routerSettingsAction.type.FETCH_ROUTER_SETTINGS_REQUEST: {
      newState.devices = null;
      newState.fetchStatus = REQ.PENDING;
      break;
    }
    case routerSettingsAction.type.FETCH_ROUTER_SETTINGS_ERROR: {
      newState.devices = action.payload.error;
      newState.fetchStatus = REQ.ERROR;
      break;
    }
    case routerSettingsAction.type.FETCH_ROUTER_SETTINGS_SUCCESS: {
      newState.devices = action.payload.devices;
      newState.fetchStatus = REQ.SUCCESS;
      break;
    }
    case routerSettingsAction.type.UPDATE_ACTIVE_UNIT_REQUEST: {
      newState.updateStatus = { updating: true, tag: action.payload.tag };
      newState.updateResponse = {
        response: "",
        tag: "",
        error: false,
        shown: false,
      };
      break;
    }
    case routerSettingsAction.type.UPDATE_ACTIVE_UNIT_SUCCESS: {
      newState.updateStatus = { updating: false, tag: "" };
      newState.updateResponse = {
        response: action.payload.response,
        tag: action.payload.tag,
        error: false,
        shown: false,
      };
      break;
    }
    case routerSettingsAction.type.UPDATE_ACTIVE_UNIT_ERROR: {
      newState.updateStatus = { updating: false, tag: "" };
      newState.updateResponse = {
        response: action.payload.error,
        tag: action.payload.tag,
        error: true,
        shown: false,
      };
      break;
    }
    case routerSettingsAction.type.SET_ACTIVE_UNIT: {
      newState.activeUnitSerialNumber = action.payload.unitSerialNumber;
      newState.activeUnit = generalUtilities.getActiveUnit(
        action.payload.devices ?? state.devices,
        action.payload.unitSerialNumber
      );
      break;
    }
    case routerSettingsAction.type.UPDATE_ACTIVE_UNIT_SETTINGS_IN_STORE: {
      const activeUnitObject = state.devices.find(
        (unit) => unit.SerialNumber === state.activeUnitSerialNumber
      );
      const indexOfActiveUnit = state.devices.indexOf(activeUnitObject);

      newState.devices[indexOfActiveUnit] = action.payload.device;
      newState.activeUnit = generalUtilities.getActiveUnit(
        newState.devices,
        state.activeUnitSerialNumber
      );
      break;
    }
    case routerSettingsAction.type.FLIP_OTHER_SETTINGS_CHANGED: {
      newState.otherSettingsChanged = !state.otherSettingsChanged;
      break;
    }
  }
  return newState;
};
