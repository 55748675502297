import {
  Button,
  Card,
  Container,
  Heading,
  Paragraph,
  ShoppingCard,
  Skeleton,
  breakpoints,
  useScrollToTop,
} from "@telia-no-min-side/components";
import { format, types, uri } from "@telia-no-min-side/utils";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchTvOverFwa } from "src/store/hooks/useFetchTvOverFwa";
import { getSimplePriceText } from "src/util/priceFormatter";
import { productId } from "src/util/productIds";
import { useFetchBroadbandSubscription, useFetchProfile, useFetchTvSubscription } from "src/api/fixed";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { useFixedInvoice } from "hooks/useFixedInvoice";
import { PageLoadError } from "../error/ErrorContent";
import { ReceiptError } from "./ReceiptError";
import { ShoppingCart } from "./ShoppingCart";
import { StreamingImages } from "./StreamingImages";
import { TeliaPlayInfo } from "./TeliaPlayInfo";
import { TeliaPlayModal } from "./TeliaPlayModal";
import { getTransactionTrackingData } from "./shoppingSummaryUtils";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { getProductShipping } from "pages/tv/utils/shipping";

export function BuyTVSubscription() {
  useScrollToTop();
  const tvOverFwa = useFetchTvOverFwa();
  const profile = useFetchProfile();
  const broadbandSubscription = useFetchBroadbandSubscription();
  const shoppingCartRef = useRef<HTMLDivElement>(null);
  const tvSubscription = useFetchTvSubscription();
  const hasAlreadyTvSubscription = tvSubscription.data?.products && tvSubscription.data.products.length > 0;
  const currentBroadbandSubscription = broadbandSubscription.data?.products.find((product) => product.id);
  const [selectedProductId, setSelectedProductId] = useState<number | undefined>();
  const navigate = useNavigate();
  const [isPostResponseError, setIsPostResponseError] = useState(false);
  const submitOrder = useAxiosPost<types.fixed.TvOverFwaSubmitResponse, types.fixed.TvOverFwaSubmitArgs>(
    uri.purchase("/api/tv-over-fwa/submit"),
    {
      customSuccessValidation(responseData) {
        return responseData.status === "vellykket";
      },
      onSuccess(response) {
        setIsPostResponseError(false);
        tvSubscription.mutate();
        const receiptUri = response.box ? "/kvittering/post/vellykket" : "/kvittering/vellykket-uten-boks";
        return navigate({ pathname: uri.minSideFixed(receiptUri) }, { replace: true });
      },
      onError() {
        setIsPostResponseError(true);
      },
    }
  );
  const { isInvoiceOverdueOrInCollection } = useFixedInvoice();

  useEffect(() => {
    if (selectedProductId) return;
    if (!tvOverFwa.selectedAccount.data || !currentBroadbandSubscription) return;

    const currentBroadbandSpeed = currentBroadbandSubscription?.speed?.down;
    if (tvOverFwa.selectedAccount.hasMultipleFwaProducts && currentBroadbandSpeed) {
      const closestProduct = tvOverFwa.selectedAccount.data.reduce<types.fixed.TvOverFwaPrice | null>(
        (closest, product) => {
          if (!product.name) return closest;

          const broadbandSpeed = extractBroadbandSpeed(product.name);
          if (broadbandSpeed === null) return closest;

          const speedDifference = Math.abs(broadbandSpeed - currentBroadbandSpeed);
          const closestSpeed = closest?.name ? extractBroadbandSpeed(closest.name) : null;
          const closestSpeedDifference = closestSpeed ? Math.abs(closestSpeed - currentBroadbandSpeed) : Infinity;
          return speedDifference < closestSpeedDifference ? product : closest;
        },
        null
      );

      if (closestProduct) {
        setSelectedProductId(closestProduct.productId);
        return;
      }
    }
    setSelectedProductId(tvOverFwa.selectedAccount.productWithMostDiscount?.productId);
  }, [currentBroadbandSubscription, tvOverFwa.allAccounts.data, selectedProductId]);

  if (tvOverFwa.isLoading || broadbandSubscription.isLoading || profile.isLoading)
    return (
      <Container showGoBackButton padding>
        <Heading tag="h2" variant="title-400">
          Bestill Telia Play
        </Heading>
        <Container maxWidth="md" padding="vertical">
          <Skeleton />
          <Skeleton />
        </Container>
        <Card>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Card>
      </Container>
    );

  if (isInvoiceOverdueOrInCollection) {
    return (
      <Container showGoBackButton padding>
        <Heading tag="h2" variant="title-400">
          Bestill Telia Play
        </Heading>
        <Paragraph>
          Kjøp av Telia Play er dessverre ikke tilgjengelig da du har en eller flere utestående fakturaer.
        </Paragraph>
        <Container gap padding="top">
          <Button
            trackEvent={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.ORDER_TV,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: uri.minSideFixed("/hjem"),
              ui_item_text: TRACK_EVENT.ITEM_TEXT.PAY_INVOICE,
            }}
            tag="a"
            hideLinkIcon
            iconPosition="after"
            variant="secondary"
            isInternalNavigation
            href={uri.minSideFixed("/faktura")}
          >
            Betal faktura
          </Button>
          <Button
            trackEvent={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.ORDER_TV,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: uri.minSideFixed("/hjem"),
              ui_item_text: TRACK_EVENT.ITEM_TEXT.GO_TO_HOME_INVOICE,
            }}
            tag="a"
            icon="home"
            iconPosition="after"
            isInternalNavigation
            href={uri.minSideFixed("/hjem")}
          >
            Gå til Hjem
          </Button>
        </Container>
      </Container>
    );
  }
  if (tvOverFwa.isError)
    return (
      <Container showGoBackButton padding>
        <Heading tag="h2" variant="title-400">
          Bestill Telia Play
        </Heading>
        <PageLoadError />
        <Container gap padding="top">
          <Button
            trackEvent={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.ORDER_TV,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.TRY_AGAIN_ERROR,
            }}
            isLoading={tvOverFwa.isValidating}
            onClick={() => tvOverFwa.mutate()}
          >
            Prøv igjen
          </Button>
          <Button
            trackEvent={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.ORDER_TV,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: uri.minSideFixed("/hjem"),
              ui_item_text: TRACK_EVENT.ITEM_TEXT.GO_TO_HOME_ERROR,
            }}
            tag="a"
            icon="home"
            iconPosition="after"
            variant="secondary"
            isInternalNavigation
            href={uri.minSideFixed("/hjem")}
          >
            Gå til Hjem
          </Button>
        </Container>
      </Container>
    );

  const hasToSelectProduct = tvOverFwa.selectedAccount.hasMultipleFwaProducts;
  const selectedProduct = tvOverFwa.selectedAccount.data?.find((product) => product.productId === selectedProductId);

  if (!hasToSelectProduct && !selectedProduct)
    return (
      <Container showGoBackButton padding>
        <Heading tag="h2" variant="title-400">
          Bestill Telia Play
        </Heading>
        <Paragraph>
          {hasAlreadyTvSubscription
            ? "Du har allerede Telia Play. "
            : "Det ser dessverre ikke ut som du har mulighet til å bestille Telia Play."}
        </Paragraph>
        <Button
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.ORDER_TV,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
            ui_item_url: uri.minSideFixed("/hjem"),
            ui_item_text: `Gå til Hjem ${
              hasAlreadyTvSubscription
                ? "(Du har allerede Telia Play.)"
                : "(Det ser dessverre ikke ut som du har mulighet til å bestille Telia Play.)"
            }}`,
          }}
          tag="a"
          icon="home"
          iconPosition="after"
          isInternalNavigation
          href={uri.minSideFixed("/hjem")}
        >
          Gå til Hjem
        </Button>
      </Container>
    );

  const shipping = selectedProduct && getProductShipping([selectedProduct.shippingOptionDTO]);
  const orderToPlace: types.fixed.TvOverFwaSubmitArgs | null = selectedProduct
    ? { fwaProduct: selectedProduct.productId }
    : null;

  const broadbandProducts = tvOverFwa.selectedAccount.data
    ?.map((product) => {
      if (!product.name) return null;
      const broadbandSpeed = extractBroadbandSpeed(product.name);
      if (broadbandSpeed === null) return null;
      return { ...product, broadbandSpeed };
    })
    .filter((product): product is NonNullable<typeof product> => product !== null)
    .sort((a, b) => {
      if (a.broadbandSpeed < b.broadbandSpeed) return -1;
      if (a.broadbandSpeed > b.broadbandSpeed) return 1;
      return 0;
    });

  if (isPostResponseError && selectedProduct) {
    return (
      <ReceiptError
        onRetryClick={() => {
          if (orderToPlace) {
            submitOrder.post(orderToPlace);
          }
        }}
        isLoading={submitOrder.isLoading}
      />
    );
  }
  const isOnlyTv = selectedProduct?.productId === productId.tvPoints.p0;

  return (
    <Container showGoBackButton padding>
      <Heading tag="h2" variant="title-400">
        Bestill Telia Play
      </Heading>
      {!isOnlyTv &&
        ((!broadbandSubscription.isLoading && currentBroadbandSubscription) || broadbandSubscription.isLoading) && (
          <Container padding="bottom" floatContent="left">
            <Card width="unset" removeMinHeight backgroundColor="grey">
              <Paragraph>
                I dag betaler du{" "}
                {broadbandSubscription.isLoading ? (
                  <Skeleton variant="text" width="5ch" />
                ) : (
                  <em>
                    {getSimplePriceText(
                      currentBroadbandSubscription?.price?.amount,
                      currentBroadbandSubscription?.price?.chargePeriod
                    )}
                  </em>
                )}{" "}
                for{" "}
                {broadbandSubscription.isLoading ? (
                  <Skeleton variant="text" width="10ch" />
                ) : (
                  currentBroadbandSubscription?.title2
                )}
              </Paragraph>
            </Card>
          </Container>
        )}
      <Container flexWrap gap="lg" flexDirection="row" horizontalPosition="left" padding="top">
        <div>
          <Container gap="lg" flexDirection="column">
            {broadbandProducts && broadbandProducts.length > 1 && (
              <div>
                <Paragraph>Velg hvilken fart du vil ha på ditt trådløse bredbånd:</Paragraph>
                <Container gap="lg" flexWrap flexDirection="row">
                  {broadbandProducts?.map((product) => {
                    return (
                      <Container
                        flexGrow="1"
                        flexShrink="1"
                        flexBasis="0"
                        key={`${product.productId}-select-tv-product`}
                      >
                        <Card
                          onClick={() => {
                            setSelectedProductId(product.productId);
                          }}
                          removeMinHeight
                          maxWidth="xs"
                          tag="button"
                          isSelected={selectedProductId === product.productId}
                        >
                          <Paragraph textAlign="center">
                            <em>{product.broadbandSpeed} Mbps</em>
                          </Paragraph>
                        </Card>
                      </Container>
                    );
                  })}
                </Container>
              </div>
            )}
            <Container horizontalPosition="unset" maxWidth="xs">
              <ShoppingCard
                title={
                  selectedProduct?.name?.length
                    ? `${format.startCase(selectedProduct.name)} med Telia Play`
                    : "Telia Play"
                }
                titleVariant="title-200"
                price={getSimplePriceText(selectedProduct?.prices.currentAmount, selectedProduct?.prices.chargePeriod)}
                postCampaignPrice={
                  selectedProduct?.prices.currentAmount !== selectedProduct?.prices.postCampaignAmount
                    ? getSimplePriceText(
                        selectedProduct?.prices.postCampaignAmount,
                        selectedProduct?.prices.chargePeriod
                      )
                    : undefined
                }
                showSelectedRing={hasToSelectProduct}
                isSelected={!hasToSelectProduct}
              >
                <Container flexDirection="column" padding="vertical">
                  <TeliaPlayInfo />
                  <StreamingImages />
                  <Container padding="top">
                    <TeliaPlayModal />
                  </Container>
                </Container>
              </ShoppingCard>
            </Container>
          </Container>
        </div>
        <Container flexBasis={breakpoints.xs} width="unset" flexGrow={1}>
          <div ref={shoppingCartRef}>
            <ShoppingCart
              submitOrder={() => {
                if (orderToPlace && selectedProduct) {
                  const trackingData = getTransactionTrackingData(
                    currentBroadbandSubscription,
                    selectedProduct,
                    shipping
                  );
                  submitOrder.post(orderToPlace, trackingData);
                }
              }}
              selectedProductId={selectedProduct?.productId}
              isSubmitting={submitOrder.isLoading}
              shipping={shipping}
            />
          </div>
        </Container>
      </Container>
    </Container>
  );
}

function extractBroadbandSpeed(productName: string) {
  const lastWholeNumberRegex = /.*\b(\d+)\b/;
  const match = productName.match(lastWholeNumberRegex);

  if (!match || match.length <= 1) return null;
  const speed = match[1];

  return Number(speed);
}
