import { format } from "@telia-no-min-side/utils";
import { formatDatalayerTransactionData } from "src/services/googleAnalytics/eventDataHelpers";
import { VasAdditionalProduct } from "../components/types";
import { getTax } from "util/tracking";
import { MOBILE_VAS } from "util/constants/valueAddedServices";

export function getTransactionDataVAS(product: VasAdditionalProduct, orderId?: string | null) {
  const isActiveProduct = !!product.presentation?.active;

  const category = product.productGroup?.includes(MOBILE_VAS.SKY)
    ? "VAS/Security/Sky"
    : product.productGroup?.includes(MOBILE_VAS.TRYGG)
    ? "VAS/Security/Trygg"
    : "VAS/Entertainment/Streaming";

  return formatDatalayerTransactionData({
    actionId: orderId || "",
    revenue: isActiveProduct ? 0 : product.price?.price || product.price?.priceWithoutDiscount || 0,
    shipping: 0,
    name: format.startCase(product.productGroup || ""),
    id: product?.code,
    price: isActiveProduct ? 0 : product.price?.price || product.price?.priceWithoutDiscount || 0,
    tax: isActiveProduct ? 0 : getTax(product.price?.price || product.price?.priceWithoutDiscount || 0),
    category: category,
    isActiveProduct,
  });
}
