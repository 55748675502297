import { Lozenge, colors } from "@telia/styleguide";
import styled, { css } from "styled-components";
import { CardBaseProps } from ".";
import { remCalc } from "../utils/fonts";
import { zIndex } from "../utils/variables";
import { breakpoints, devices } from "./../utils/breakpoints";

type CardWrapperArgs = Omit<CardBaseProps, "lozengeLeft" | "lozengeRight"> & {
  isClickable?: boolean;
};
export const CardWrapper = styled.div<CardWrapperArgs>`
  position: relative;
  min-height: ${(props) => (props.removeMinHeight ? "0" : "95px")};
  max-width: ${(props) => (props.maxWidth ? breakpoints[props.maxWidth] : breakpoints.sm)}px;
  overflow: visible;
  border-radius: ${remCalc(8)};
  border: none;
  text-align: left;
  text-decoration: none;
  color: black;
  background-color: white;
  display: block;
  font-size: 1rem; //override default font size for button

  ${({ width }) => {
    if (width === "unset") return;
    return css`
      width: 100%;
    `;
  }}

  ${({ padding }) => {
    if (padding === "unset") {
      return css`
        padding: 0;
      `;
    }
    if (padding === "lg") {
      return css`
        padding: 2rem 1.5rem;
        @media ${devices.sm_down} {
          padding: 1rem;
        }
      `;
    }
    if (padding === "sm") {
      return css`
        padding: 0.5rem;
      `;
    }
    if (padding === "lg-vertical") {
      return css`
        padding: 2rem 1rem;
        @media ${devices.sm_down} {
          padding: 1rem;
        }
      `;
    }
    if (padding === "top") {
      return css`
        padding: 1.25rem 1rem 1rem;
        @media ${devices.sm_down} {
          padding: 1rem;
        }
      `;
    }
    return css`
      padding: 1rem;
    `;
  }}

  ${({ hideBorder, backgroundColor }) => {
    if (backgroundColor === "warning") {
      return css`
        background-color: ${colors.orange100};
      `;
    }
    if (backgroundColor === "beige") {
      return css`
        background-color: #ecded4;
      `;
    }
    if (backgroundColor === "purple") {
      return css`
        background-color: ${colors.corePurple900};
        color: white;
      `;
    }
    if (backgroundColor === "light-purple") {
      return css`
        background-color: ${colors.corePurple100};
        border: ${hideBorder ? "none" : `1px solid ${colors.corePurple200}`};
      `;
    }
    if (backgroundColor === "grey") {
      return css`
        background-color: ${colors.grey50};
      `;
    }
    if (backgroundColor === "blue") {
      return css`
        background-color: ${colors.blue100};
      `;
    }
    if (backgroundColor === "green") {
      return css`
        background-color: ${colors.green100};
      `;
    }

    if (hideBorder) {
      return css`
        background-color: "white";
      `;
    }
    return css`
      background-color: "white";
      border: 1px solid ${colors.grey100};
    `;
  }}


${({ borderColor }) => {
    if (borderColor === "purple") {
      return css`
        border-color: ${colors.corePurple900};
      `;
    }
  }}

  ${(props) => {
    if (props.isClickable) {
      return css`
        cursor: pointer;
      `;
    }
  }}


  -webkit-tap-highlight-color: transparent;
  outline: 0;

  /* shadow has to be applied with pseudo element, telia styleguide is removing box-shadow with important on focus*/
  ::before {
    position: absolute;
    inset: 0;
    content: "";
    border-radius: inherit;
    transition: box-shadow ease-in-out 200ms;
    box-shadow: 0px ${remCalc(1)} ${remCalc(4)} ${remCalc(0)} rgba(0, 0, 0, 0.1);
    opacity: ${({ showShadow }) => (showShadow === false ? 0 : 1)};
    pointer-events: none;
  }

  ${(props) => {
    if (props.showHoverShadow) {
      return css`
        :hover::before {
          box-shadow: 0px ${remCalc(1)} ${remCalc(5)} ${remCalc(0)} rgba(0, 0, 0, 0.4);
        }
      `;
    }
  }}

  ::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 ${remCalc(2)};
    opacity: 0;
    color: ${colors.corePurple900};
    pointer-events: none;
    transition: opacity 150ms ease-in-out;
  }

  :focus-visible::after {
    inset: -${remCalc(2)};
    opacity: 1;
    color: ${colors.blue500};
  }

  ${(props) => {
    if (props.isSelected && !props.hideIsSelectedRing) {
      return css`
        ::after {
          opacity: 1;
        }
      `;
    }
    return css`
      :focus:not(:focus-visible)::after {
        opacity: 0;
      }
    `;
  }}
`;

type StyledLozengeArgs = Pick<CardBaseProps, "padding">;

export const StyledLozengeLeft = styled(Lozenge)<StyledLozengeArgs>`
  position: absolute;
  transform: translateY(-50%);
  top: 0;
  z-index: ${zIndex.toolTip};
  left: ${({ padding }) => (padding === "lg" ? "1.5rem" : "1rem")};
  @media ${devices.sm_down} {
    left: "1rem";
  }
`;

export const StyledLozengeRight = styled(Lozenge)<StyledLozengeArgs>`
  position: absolute;
  transform: translateY(-50%);
  top: 0;
  z-index: ${zIndex.toolTip};
  right: ${({ padding }) => (padding === "lg" ? "1.5rem" : "1rem")};
  @media ${devices.sm_down} {
    right: "1rem";
  }
`;
