import dayjs from "dayjs";
import { setCookie } from "./set";

type Options = {
  path?: string;
  domain?: string;
};

export function deleteCookie(name: string, options?: Options) {
  setCookie(name, "", {
    expires: dayjs(),
    path: options?.path || "/",
    domain: options?.domain,
  });
}
