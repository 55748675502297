import { useQuery } from "@apollo/client";
import { Container, Heading } from "@telia-no-min-side/components";
import { TablePagination } from "@telia/styleguide";
import { InvoiceLoader } from "./components/InvoiceLoader";
import { NotPayingOwnInvoiceCard } from "./components/NotPayingOwnInvoiceCard";
import { FaqComponentMobile } from "./mobile/FaqComponentMobile";
import { INVOICE_PAGE_SIZE } from "./mobile/MobileInvoiceComponent";
import { PreviousInvoiceList } from "./mobile/PreviousInvoiceList";
import usePagination from "./mobile/usePagination";
import { SUBSCRIPTION_INVOICE_QUERY } from "./queries";

type ProductUserProps = {
  phoneNumber: string;
};

export const ProductUser = ({ phoneNumber }: ProductUserProps) => {
  const subscriptionInvoices = useQuery(SUBSCRIPTION_INVOICE_QUERY, {
    variables: { phoneNumber, pageSize: INVOICE_PAGE_SIZE },
    notifyOnNetworkStatusChange: true,
  });
  const { startIndex, onPageChange, itemsPerPage, endIndex, getCurrentPageData } = usePagination();

  if (subscriptionInvoices.loading) {
    return (
      <Container maxWidth="sm">
        <InvoiceLoader />
      </Container>
    );
  }

  const invoices = subscriptionInvoices.data?.subscription.invoices;

  return (
    <Container padding="vertical" flexDirection="column" gap maxWidth="sm">
      {invoices && invoices.length > 0 ? (
        <Container gap flexDirection="column" padding="top">
          <Heading tag="h2" variant="title-100">
            Din fakturaoversikt
          </Heading>
          <PreviousInvoiceList invoices={getCurrentPageData(invoices)} isSubLevel />
          <Container>
            <TablePagination
              dataLength={invoices.length}
              from={startIndex}
              onPageChange={onPageChange}
              perPage={itemsPerPage}
              to={endIndex > invoices.length ? invoices.length : endIndex}
            />
          </Container>
        </Container>
      ) : (
        <NotPayingOwnInvoiceCard />
      )}
      <Container padding="top" flexDirection="column" gap maxWidth="sm">
        <FaqComponentMobile />
      </Container>
    </Container>
  );
};
