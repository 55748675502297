import { colors } from "@telia/styleguide";
import { config } from "util/config";

type Props = {
  baseDataAmount: string;
  totalDataAmount: string;
};

export function JuniorSummerCampaignTitle({ baseDataAmount, totalDataAmount }: Props) {
  if (config.disableSummerCampaignDoubleData) {
    return (
      <span>
        <b>Telia Junior {baseDataAmount}</b>
      </span>
    );
  }

  return (
    <span>
      <b>Telia Junior</b>{" "}
      <span style={{ color: colors.grey600, textDecoration: "line-through" }}>{baseDataAmount}</span>{" "}
      <b>{totalDataAmount}</b>
    </span>
  );
}
