import { RefObject } from "react";
import { useLazyQuery } from "@apollo/client";
import { Button, Paragraph } from "@telia-no-min-side/components";
import { useParams, useSearchParams } from "react-router-dom";
import { MobileSubscriptionAccordionItem } from "src/components/mobile-subscription-accordion-item";
import { FragmentType, getFragmentData } from "src/gql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { recommendedKeySearchParam } from "../../mobile-subscription-change-redir";
import { getDownloadSpeedMbb } from "util/format-subscription/getDownloadSpeedMbb";
import { OFFER_FOR_SALE_FRAGMENT } from "../graphql/offerForSaleFragment";
import { getIncludedPropertiesMbb } from "../../../../util/format-subscription/getIncludedPropertiesMbb";
import { getMbbName } from "../../../../util/format-subscription/getMbbName";
import { GET_UPGRADE_DOWNGRADE_SUMMARY } from "pages/mobile/mobile-subscription-confirm/graphql/getUpgradeDowngradeSummary";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function OfferForSale({
  offeringFragment,
  additionalText,
  canNotChangeAlertRef,
}: {
  offeringFragment: FragmentType<typeof OFFER_FOR_SALE_FRAGMENT>;
  additionalText?: string;
  canNotChangeAlertRef: RefObject<HTMLDivElement>;
}) {
  const params = useParams<{ phoneNumber?: string; accountId?: string }>();
  const [search] = useSearchParams();
  const recommendedPricePlan = search.get(recommendedKeySearchParam);

  const offering = getFragmentData(OFFER_FOR_SALE_FRAGMENT, offeringFragment);
  const [prefetchNextPage, prefetchedSummary] = useLazyQuery(GET_UPGRADE_DOWNGRADE_SUMMARY, {
    variables: {
      phoneNumber: params.phoneNumber || "",
      offering: offering.pricePlan || "",
      accountId: params.accountId || "",
      withProductChanges: true,
    },
    nextFetchPolicy: "standby",
    fetchPolicy: "cache-first",
  });
  const { orders } = useOrderSubscription();
  const hasOnGoingOrder = orders.some((order) => order.phoneNumber === params.phoneNumber);

  if (!offering.pricePlan) return null;

  const downloadSpeed = getDownloadSpeedMbb(offering.price?.offeringPrices, offering.longDescription);
  const name = getMbbName(offering.price?.offeringPrices, offering.shortName);
  const priceMonthly = offering.monthlyCost?.amount;
  const includedProperties = getIncludedPropertiesMbb(
    offering.shortName,
    downloadSpeed,
    offering.additionalProperties,
    offering.disclaimers,
    offering.price?.offeringPrices
  );

  const tracking = {
    new_subscription: offering.shortName || "",
    new_subscription_price: priceMonthly || "",
  };

  const scrollToCannotChangeAlert = () => {
    if (canNotChangeAlertRef.current) {
      canNotChangeAlertRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      canNotChangeAlertRef.current.animate(
        [{ transform: "scale(1)" }, { transform: "scale(1.025)" }, { transform: "scale(1)" }],
        {
          delay: 1000,
          duration: 1000,
        }
      );
    }
  };

  const overLine = downloadSpeed ? <Paragraph removeMargin={true}>{downloadSpeed} Mbps</Paragraph> : undefined;

  return (
    <MobileSubscriptionAccordionItem
      hasDoubleData={false}
      hasDoubleSpeed={false}
      overLine={overLine}
      isRecommended={recommendedPricePlan === offering.pricePlan}
      additionalText={additionalText?.length ? additionalText : undefined}
      includedProperties={[...includedProperties]}
      priceMonthly={priceMonthly}
      originalPriceMonthly={offering.originalPrice?.amount}
      subscriptionName={name?.length ? name : "Klarte ikke hente navn"}
      triggerContainerProps={{
        onMouseOver: () => {
          if (params.phoneNumber && offering.pricePlan && !prefetchedSummary.data && !prefetchedSummary.loading) {
            prefetchNextPage();
          }
        },
      }}
      dataTrackingID={`change-subscription-offering-item-${(name || "").replaceAll(" ", "_")}`}
    >
      <div>
        {hasOnGoingOrder ? (
          <Button
            floatRight
            disabled
            onDisableClick={scrollToCannotChangeAlert}
            trackEvent={{
              ui_item_action: TRACK_EVENT.ITEM_ACTION.UPGRADE_DOWNGRADE_SUMMARY_DISABLED,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.CHANGE_MBB,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
              ui_item_text: TRACK_EVENT.ITEM_TEXT.SELECT_DISABLED,
            }}
          >
            Velg
          </Button>
        ) : (
          <Button
            floatRight
            tag="a"
            hideLinkIcon
            isInternalNavigation
            href={`${location.pathname}/bekreft?pricePlan=${encodeURIComponent(offering.pricePlan)}`}
            trackEvent={{
              ui_item_action: `upgrade-downgrade-summary page ${JSON.stringify(tracking)}`,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.CHANGE_MBB,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
              ui_item_url: "/mobilt-bredband/endre",
              ui_item_text: TRACK_EVENT.ITEM_TEXT.SELECT,
            }}
          >
            Velg
          </Button>
        )}
      </div>
    </MobileSubscriptionAccordionItem>
  );
}
