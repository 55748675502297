import { Icon, Badge } from "@telia/teddy";
import { isSummerCampaign, getOfferSummary } from "./utils/campaign";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { useSelectAccount } from "hooks/useSelectAccount";
import { isDiscountAvailable } from "./utils/product";
import { AdditionalProduct } from "gql/graphql";
import { isB2BCompanyPaidTeliaTryggProduct } from "./services-telia-trygg/utils/isB2BCompanyPaidTeliaTryggProduct";
import { isTeliaSkyIncluded } from "./services-telia-sky/utils/isTeliaSkyIncluded";
import { isB2BCompanyPaidTeliaSkyProduct } from "./services-telia-sky/utils/isB2BCompanyPaidTeliaSkyProduct";

type Props = {
  hasFreeTeliaSkyOnFixedSide?: boolean;
  product: AdditionalProduct;
  showFullText?: boolean;
};

export function ProductBadge({ product, showFullText, hasFreeTeliaSkyOnFixedSide }: Props) {
  const { orders } = useOrderSubscription();
  const { selectedMobileAccount } = useSelectAccount();
  const isB2BTeliaTrygg = isB2BCompanyPaidTeliaTryggProduct(product);
  const isIncludedForFree = isTeliaSkyIncluded(product);
  const isB2BTeliaSky = isB2BCompanyPaidTeliaSkyProduct(product);
  const showOrderStatus = orders.find(
    (order) => order.phoneNumber === selectedMobileAccount?.phoneNumber && order.vasProduct === product.code
  );

  if (!product) return null;
  if (showOrderStatus) {
    return (
      <Badge variant="information" hideIcon>
        <Icon name="sync">Oppdateres</Icon>
      </Badge>
    );
  }
  if (product?.presentation?.active === true) {
    return (
      <Badge variant="success" hideIcon>
        Aktiv
      </Badge>
    );
  }
  if (product?.presentation?.active === false && !!product?.presentation?.included) {
    return (
      <Badge variant="information" hideIcon>
        Ikke aktivert
      </Badge>
    );
  }
  if (isB2BTeliaSky) {
    return (
      <Badge variant="special">
        <Icon name="suitcase">Betales av arbeidsgiver</Icon>
      </Badge>
    );
  }

  if (hasFreeTeliaSkyOnFixedSide) {
    return (
      <Badge variant="information">
        <Icon name="offering">Telia Samlet-fordel</Icon>
      </Badge>
    );
  }

  if (isIncludedForFree) {
    return <Badge variant="information">Inkludert</Badge>;
  }
  if (isB2BTeliaTrygg) {
    return (
      <Badge variant="information" hideIcon>
        <Icon name="suitcase">Betales av arbeidsgiver</Icon>
      </Badge>
    );
  }

  if (isSummerCampaign(product.code)) {
    return (
      <Badge variant="warning" hideIcon>
        <Icon name="weather">{showFullText ? getOfferSummary(product) : "Sommerkampanje"}</Icon>
      </Badge>
    );
  }
  if (isDiscountAvailable(product)) {
    return (
      <Badge variant="special">
        <>{showFullText ? getOfferSummary(product) : "Kampanje"}</>
      </Badge>
    );
  }
  return null;
}
