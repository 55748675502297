import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getSwitchAgreements } from "../graphql/GetSwitchAgreements";

export const useSwitchAgreement = () => {
  const { svitsjId, accountId } = useParams();

  const { loading, error, data } = useQuery(getSwitchAgreements, {
    variables: {
      accountId: accountId || "",
    },
    errorPolicy: "all",
  });

  const switchAgreement = data?.account.switchAgreements.find((agreement) => agreement.id === svitsjId);

  return { loading, error, switchAgreement, accountId };
};
