import styled from "styled-components";
import { Button } from "@telia/teddy";
import { remCalc } from "../utils/fonts";

type AnimatedHeight = {
  animateHeight?: number;
};

export const CollapseWrapper = styled.div<AnimatedHeight>`
  height: ${(props) => props.animateHeight}px;
  visibility: ${(props) => (props.animateHeight && props.animateHeight > 0 ? "visible" : "hidden")};
  overflow: ${(props) => (props.animateHeight && props.animateHeight > 0 ? "visible" : "hidden")};
  opacity: ${(props) => (props.animateHeight && props.animateHeight > 0 ? 1 : 0)};
  transition:
    background-color 200ms ease,
    max-height 200ms ease,
    opacity 200ms ease;

  .collapse {
    margin-top: ${remCalc(30)};
  }
`;

export const StyledButton = styled(Button)<{ $isOpen?: boolean }>`
  background-color: transparent;
  svg {
    transform: rotateZ(${(props) => (props.$isOpen ? "180deg" : "0deg")});
    transition: transform 0.2s ease-in-out;
  }
`;
