import { useAuth } from "@telia-no-min-side/components";
import { breakpoint, Flex, Heading } from "@telia/teddy";
import { GetAccessOptions } from "./GetAccessOptions";

export function NoAccess() {
  const auth = useAuth();

  return (
    <Flex gap="150" direction="column" maxWidth={breakpoint.teddyBreakpointMd}>
      <Heading as="h2" variant="title-400" data-di-mask data-tracking-id="homepage-profile-heading">
        Hei, {auth.userInfo?.given_name}
      </Heading>
      <GetAccessOptions />
    </Flex>
  );
}
