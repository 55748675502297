import { Button, Container, Link, devices, remCalc, Card } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";
import styled from "styled-components";

export const InnerCardGrid = styled.div`
  display: grid;
  grid-template-columns: ${remCalc(75)} auto minmax(8ch, auto);
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  flex: 1 1 auto;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  @media ${devices.xxs_down} {
    grid-template-columns: ${remCalc(73)} auto minmax(8ch, auto);
  }
`;

export const TopSection = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const SpanThreeRows = styled.div`
  grid-row: span 2;
  padding-right: 0.5rem;
  display: grid;
  align-items: center;
  white-space: normal;
  text-overflow: initial;
  align-self: stretch;

  @media ${devices.xxs_down} {
    margin-right: 0;
  }
`;

export const BaseCard = styled(Card)`
  flex: 1 1 320px;
  max-width: 400px;
`;

export const ButtonContainer = styled(Container)`
  margin-top: auto;
`;
export const InteractiveCard = styled(BaseCard)`
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  outline: none;
  display: flex;
  gap: 1rem;
`;

export const LinkCardImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 100px;
  max-height: 130px;
  object-fit: contain;
  @media ${devices.xxs_down} {
    max-width: 100px;
  }
`;
export const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 70px;
  object-fit: contain;
`;

export const ActionContainer = styled(Container)`
  margin-top: auto;
`;

export const CardLink = styled(Link)`
  display: block;
  -webkit-tap-highlight-color: transparent;

  ::after {
    position: absolute;
    inset: 0;
    content: "";
  }

  :focus-visible {
    outline: none;
    background: none;
    ::after {
      border-radius: 5px;
      outline: ${remCalc(2)} solid ${colors.blue500};
      outline-offset: ${remCalc(2)};
    }
  }
`;

export const Spacer = styled.div`
  height: 0.5rem;
`;
export const ScreenReaderOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: none;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;
