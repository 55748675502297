import { Card, Flex, Icon, Text, ProgressBar } from "@telia/teddy";
import { ActionsSection } from "../ActionsSection";
import { getRemainingTime } from "pages/mobile/mobile-subscription-dashboard/modules/DataUsageCard/utils";
import { useMobileBroadbandContext } from "pages/internet/mobile-broadband/context/MobileBroadbandContext";

export function ActiveDataBoost() {
  const { activeDataBoost } = useMobileBroadbandContext();

  return (
    <Card bordered shadow layout="default" variant="white" gap="gutter-md">
      <Card.Heading variant="title-400">Data Boost</Card.Heading>
      <Card.Line />
      <Card.Content>
        <Flex direction="column" gap="100">
          <Flex justify="between">
            <Text variant="paragraph-100-medium">Ubegrenset data</Text>
            <Icon name="infinite" size="md" />
          </Flex>
          <ProgressBar progress={100} />

          <Text variant="additional-100">{getRemainingTime(activeDataBoost?.expireDate)}</Text>
        </Flex>
      </Card.Content>

      <Card.Footer justify="start">
        <ActionsSection />
      </Card.Footer>
    </Card>
  );
}
