import { Container, Skeleton } from "@telia-no-min-side/components";

export function SkeletonPage() {
  return (
    <Container maxWidth="md" padding alignItems="flex-start" flexDirection="column" gap>
      <Skeleton variant="heading-m" width="30ch" />
      <Skeleton width="80%" />
      <Skeleton variant="rectangular" width="15ch" height="8ch" />
      <Skeleton width="90%" />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton float="right" variant="button" width="15ch" />
    </Container>
  );
}
