import { format } from "@telia-no-min-side/utils";
import { LoadingSubscriptionCard } from "src/pages/home/card/LoadingSubscriptionCard";
import { SubscriptionCard } from "pages/home/card/SubscriptionCard/SubscriptionCard";
import { useSubscriptionOverview } from "src/pages/home/hooks/useSubscriptionOverview";
import { ErrorCard } from "pages/home/card/ErrorCard";

type Props = {
  subscriptions: ReturnType<typeof useSubscriptionOverview>["mbbSubscriptionsOverview"];
  isLoading: boolean;
  showError: boolean;
  isRefetching: boolean;
  onRefetch: () => void;
};

export function MobileBroadbandSubscriptions({ subscriptions, isLoading, showError, isRefetching, onRefetch }: Props) {
  if (isLoading) {
    return <LoadingSubscriptionCard isGaugeMeter />;
  }

  if (showError) {
    return (
      <ErrorCard sectionText="dine mobilt bredbånd produkter" onRefreshClick={onRefetch} isLoading={isRefetching} />
    );
  }

  if (!subscriptions.length) return null;

  return (
    <>
      {subscriptions.map((subscription) => (
        <SubscriptionCard
          key={subscription.phoneNumber.localNumber}
          accountId={subscription.account.id}
          phoneNumber={subscription.phoneNumber.localNumber}
          dataTrackingID={`subscription-card-id-${format.toKebabCase(format.getFullName(subscription.productUser))}`}
        />
      ))}
    </>
  );
}
