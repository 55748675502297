import { Card, Container, Skeleton } from "@telia-no-min-side/components";
import { SkeltonInvoiceList } from "./SkeltonInvoiceList";

export const InvoiceLoader = () => {
  return (
    <Container padding="top" flexDirection="column" gap maxWidth="sm">
      <Card padding="lg">
        <Container flexDirection="column">
          <Skeleton width="10ch" />
          <Skeleton width="80%" />
          <Skeleton width="80%" />
        </Container>
        <Container flexDirection="row" justifyContent="space-between">
          <Skeleton width="10ch" />
          <Skeleton width="10ch" />
        </Container>
        <Container flexDirection="row" justifyContent="space-between">
          <Skeleton width="10ch" />
          <Skeleton width="10ch" />
        </Container>
      </Card>
      <SkeltonInvoiceList />
    </Container>
  );
};
