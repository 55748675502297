import { Flex, Box, Text } from "@telia/teddy";

export type ShoppingSummaryItemProps = {
  text: string;
  highlight?: boolean;
  price: string;
  secondaryText?: string;
  additionalText?: string;
};
export function Item(props: ShoppingSummaryItemProps): JSX.Element {
  const { text, secondaryText, price, highlight, additionalText } = props;
  const justifyContentCondition = additionalText && !secondaryText ? "end" : "space-between";

  return (
    <Box pl="100" pr="100">
      {highlight ? (
        <Flex justify="space-between" width="100%">
          <Text variant="paragraph-100-bold">{text} </Text>
          <Text variant="paragraph-100-bold">
            <span>{price}</span>
          </Text>
        </Flex>
      ) : (
        <Flex justify="space-between" width="100%">
          {text}
          <span>{price}</span>
        </Flex>
      )}
      <Flex direction="row" justify={justifyContentCondition} width="100%">
        {secondaryText && (
          <Text variant="additional-100" faded style={{ lineHeight: 2 }}>
            {secondaryText}
          </Text>
        )}
        {additionalText && (
          <Text variant="additional-100" faded style={{ lineHeight: 2 }}>
            {additionalText}
          </Text>
        )}
      </Flex>
    </Box>
  );
}
