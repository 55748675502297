import { Container, Paragraph } from "@telia-no-min-side/components";

export function PageLoadError(): JSX.Element {
  return (
    <Container maxWidth="sm">
      <Paragraph>Dessverre oppstod det en feil under innlasting av denne siden.</Paragraph>
      <Paragraph>Vennligst prøv igjen litt senere.</Paragraph>
    </Container>
  );
}
