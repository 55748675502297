import React, { CSSProperties, ReactNode } from "react";
import { Card, Badge, Flex, Text, Box } from "@telia/teddy";
import { CardBaseProps } from "../Card";
import { Heading, HeadingProps } from "../Heading";
import { Skeleton } from "../Skeleton";
import { AdditionalPriceText, PostCampaignPrice, ProductImage } from "./style";

export type ShoppingCardProps = {
  title: string;
  titleVariant?: HeadingProps["variant"];
  additionalTagText?: ReactNode | string;
  price?: string;
  postCampaignPrice?: string;
  additionalPriceText?: string;
  image?: string;
  campaignText?: string | undefined;
  children?: ReactNode;
  padding?: CardBaseProps["padding"];
  showSelectedRing?: boolean;
} & (
  | {
      isSelected?: never;
      selectedBadgeText?: never;
    }
  | {
      isSelected: boolean;
      selectedBadgeText?: string;
    }
);

function Root(props: ShoppingCardProps) {
  const {
    title,
    additionalTagText,
    price,
    image,
    isSelected,
    children,
    additionalPriceText,
    selectedBadgeText,
    postCampaignPrice,
    titleVariant = "title-150",
    campaignText,
  } = props;

  const title500: CSSProperties = {
    fontWeight: 500,
  };

  return (
    <Card variant="white" shadow justify="stretch" bordered>
      {isSelected && (
        <Card.Slot align="top-right">
          <Badge variant="warning" hideIcon>
            {selectedBadgeText || "Valgt"}
          </Badge>
        </Card.Slot>
      )}
      {campaignText && (
        <Card.Slot align="top-left">
          <Badge variant="special" hideIcon>
            {campaignText}
          </Badge>
        </Card.Slot>
      )}
      <Flex direction="row" width="100%">
        {image && (
          <Box mr="200">
            <ProductImage alt={title} src={image} />
          </Box>
        )}
        <Box p="10">
          <Heading tag="h2" variant={titleVariant} removeMargin style={title500}>
            {title}
          </Heading>
          {additionalTagText && <Text>{additionalTagText}</Text>}
        </Box>
        <Box p="100" ml="auto">
          <Flex direction="column" align="end">
            <Box right="0">
              {postCampaignPrice && <PostCampaignPrice>{postCampaignPrice}</PostCampaignPrice>}
              <b>{price}</b>
            </Box>
            {additionalPriceText && <AdditionalPriceText>{additionalPriceText}</AdditionalPriceText>}
          </Flex>
        </Box>
      </Flex>
      {children}
    </Card>
  );
}

function Body({ children }: { children: ReactNode }) {
  return children;
}

function Footer({ children }: { children: ReactNode }) {
  return (
    <Box mt="100">
      <Card.Line />
      <Card.Footer>{children}</Card.Footer>
    </Box>
  );
}

function ShoppingCardSkeleton({
  hasImage,
  hasTitle,
  hasBody,
  hasFooter,
}: {
  hasImage?: boolean;
  hasBody?: boolean;
  hasTitle?: boolean;
  hasFooter?: boolean;
}) {
  return (
    <Card variant="white" shadow bordered>
      <Flex direction="row" width="100%" wrap="wrap">
        {hasImage && (
          <Box mr="200">
            <Skeleton width="6rem" height="6rem" variant="rectangular" />
          </Box>
        )}
        {hasTitle && (
          <Box>
            <Skeleton variant="heading-s" width="45ch" />
          </Box>
        )}
      </Flex>
      {hasBody && (
        <Box>
          <Skeleton variant="text" width="55ch" />
          <Skeleton variant="text" width="55ch" />
        </Box>
      )}
      {hasFooter && (
        <>
          <Card.Line />
          <Box mt="100" ml="auto">
            <Skeleton variant="button" width="12ch" />
          </Box>
        </>
      )}
    </Card>
  );
}

export const ShoppingCard = Object.assign(Root, {
  Body,
  Footer,
  Skeleton: ShoppingCardSkeleton,
});
