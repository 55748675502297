import { InsuranceAgreementCard } from "./components/InsuranceAgreementCard";
import { LeaseAgreementCard } from "./components/LeaseAgreementCard";
import { SwitchAgreementCard } from "./components/SwitchAgreementCard";
import { MobileAgreement } from "./utils/getMobileAccountAgreements";

type Props = {
  agreements: MobileAgreement[];
};

export function MobileAgreements({ agreements }: Props) {
  return agreements.map((agreement) => {
    switch (agreement.type) {
      case "LeaseAgreement":
        return (
          <LeaseAgreementCard accountId={agreement.accountId} leaseAgreement={agreement.data} key={agreement.data.id} />
        );
      case "SwitchAgreement":
        return (
          <SwitchAgreementCard
            accountId={agreement.accountId}
            switchAgreement={agreement.data}
            key={agreement.data.id}
          />
        );
      case "InsuranceAgreement":
        return (
          <InsuranceAgreementCard
            accountId={agreement.accountId}
            insuranceAgreement={agreement.data}
            key={agreement.data.serviceAgreementId}
          />
        );
      default:
        return null;
    }
  });
}
