import { Container, Paragraph, ShoppingSummary, TextInput, useToast } from "@telia-no-min-side/components";
import { Text, Button, Icon } from "@telia/teddy";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "pages/mobile/mobile-subscription-sim-pin-puk/utils";
import { isValidEmailAddress, track, uri } from "@telia-no-min-side/utils";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import {
  isValidDeliveryAddress,
  SimCheckoutView,
  useSimCheckout,
} from "pages/mobile/mobile-subscription-sim-pin-puk/hooks/useSimCheckout";
import { config } from "util/config";
import { useBankIdMutation } from "hooks/useBankIdMutation";
import { OrderOp } from "gql/graphql";
import { graphql } from "gql";
import { PostAddress } from "./PostAddress";
import { BankIdRequiredModal } from "components/bank-id-required-modal";
import { getTransactionDataSIM } from "pages/mobile/mobile-subscription-order-additional-sim-card/utils/getTransactionDataSIM";

const { Item, LineSeparator, List, ActionWrapper, Wrapper } = ShoppingSummary;

export enum BlankSimCardType {
  Esim = "ESIM-QR",
  Hw = "COMBI",
  Nano = "NANO",
  Micro = "MICRO",
  EsimPush = "ESIM-PUSH",
  Normal = "NORMAL",
}

export const ORDER_BLANK_SIM = graphql(`
  mutation orderBlankSIM($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
    }
  }
`);

type Props = {
  simCardType: BlankSimCardType;
  simReplacementPrice: number;
};

export function OrderBlankSimCardSummary({ simCardType, simReplacementPrice }: Props) {
  const navigate = useNavigate();
  const { phoneNumber } = useMobileAccount();
  const { pushTrackingEvent } = track.useEventTracking();
  const { setCheckoutView: setView, deliveryAddress, userEmail, setUserEmail, addressLoading } = useSimCheckout();
  const { addToast } = useToast();
  const isEsimSim = simCardType !== BlankSimCardType.Hw;
  const forceSkipBankIdCheck = !config.requireBankId;

  const productForGA = {
    code: simCardType,
    price: { price: simReplacementPrice },
  };
  const [mutate, response] = useBankIdMutation(
    ORDER_BLANK_SIM,
    {
      onCompleted(data) {
        const orderId = data.order?.orderId;
        const transactionData = getTransactionDataSIM(productForGA, data?.order.orderId);
        pushTrackingEvent(transactionData);
        if (isEsimSim && config.enableEsimInstallationRedirect) {
          navigate(uri.minSideFixed(`/mobil/sim-pin-puk/ordre-status/${phoneNumber}/${orderId}`));
          return;
        }

        addToast({
          text: "SIM er bestilt. Det vil ta et par minutter før endringen blir registrert.",
          variant: "success",
          dataTrackingId: "order-blank-physical-sim-success",
        });
        navigate("..");
      },
      onError(error) {
        addToast({
          text: `Beklager, bestillingen feilet. ${getErrorMessage(error.message)}`,
          variant: "error",
          dataTrackingId: "order-blank-physical-sim-error",
        });
        navigate("..");
      },
    },
    "orderBlankSimCard"
  );

  const onSubmitOrder = () => {
    mutate(
      {
        variables: {
          phoneNumber,
          input: [
            {
              operation: OrderOp.OrderBlankSimCard,
              blankSimCardType: simCardType,
              email: userEmail,
              deliverToLegalOwner: false, // is this correct?
              deliveryAddress: simCardType === BlankSimCardType.Hw ? deliveryAddress : undefined,
            },
          ],
        },
      },
      forceSkipBankIdCheck
    );
  };

  const isValidAddress = simCardType === BlankSimCardType.Hw ? isValidDeliveryAddress(deliveryAddress) : true;
  const isValidOrder = phoneNumber && isValidEmailAddress(userEmail) && isValidAddress;

  return (
    <Wrapper>
      <List title="Engangspris">
        <Item
          text={"Erstatt SIM"}
          price={`${simReplacementPrice},-`}
          secondaryText={simCardType === BlankSimCardType.Esim ? "eSIM" : "Vanlig SIM"}
        />
        <Item
          text="Totalt"
          price={`${simReplacementPrice},-`}
          highlight
          secondaryText={"Belastes på din neste faktura"}
        />
      </List>
      <LineSeparator />
      {simCardType === BlankSimCardType.Hw ? (
        <>
          <List>
            <Paragraph>
              <strong>* Din adresse</strong>
            </Paragraph>

            <PostAddress />

            <Button variant="text" onClick={() => setView(SimCheckoutView.ChangeAddress)}>
              <Icon name="edit">Endre</Icon>
            </Button>
          </List>
          {!isValidAddress && (
            <Text color="red">
              <Container as="span" flexDirection="row" alignItems="center" gap="sm" padding="bottom">
                <Icon name="alert" size="sm" />
                Ufullstendig adresse
              </Container>
            </Text>
          )}
          <LineSeparator />
        </>
      ) : null}
      <Container gap flexDirection="column">
        <TextInput
          value={userEmail}
          required
          onChange={(e) => setUserEmail(e.target.value)}
          inputMode="email"
          showEditIcon
          style={{ fontWeight: "500" }}
          placeholder="Fyll inn din e-post"
          disabled={addressLoading}
          errorMessage={!addressLoading && !isValidEmailAddress(userEmail) ? "E-postadressen er ugyldig" : ""}
        >
          Din e-post
        </TextInput>
        <Paragraph variant="additional-100" isFaded>
          {simCardType === BlankSimCardType.Hw
            ? "Vi sender ordrebekreftelse til denne e-posten"
            : "Vi sender eSIM til denne e-postadressen"}
        </Paragraph>
      </Container>
      <LineSeparator />
      <ActionWrapper>
        <Button variant="tertiary-purple" onClick={() => navigate("..")}>
          Avbryt
        </Button>
        {forceSkipBankIdCheck ? (
          <Button
            loading={response.loading}
            variant="primary"
            disabled={!isValidOrder}
            data-tracking-id="order-sim-button"
            onClick={onSubmitOrder}
          >
            Bestill erstatning
          </Button>
        ) : (
          <BankIdRequiredModal
            triggerComp={
              <Button
                loading={response.loading}
                variant="primary"
                disabled={!isValidOrder}
                data-tracking-id="order-sim-button"
              >
                Bestill erstatning
              </Button>
            }
            mutateComp={
              <Button
                loading={response.loading}
                onClick={onSubmitOrder}
                data-tracking-id="bankid-modal-bankid-button"
                disabled={!isValidOrder}
              >
                Til BankID
              </Button>
            }
          />
        )}
      </ActionWrapper>
    </Wrapper>
  );
}
