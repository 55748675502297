import { Link } from "@telia-no-min-side/components";
import { format, setSearchParams, types, uri } from "@telia-no-min-side/utils";
import { colors } from "@telia/styleguide";
import dayjs from "dayjs";
import { AccountInvoiceDetailsQuery } from "src/gql/graphql";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import {
  DetailsAccordionItem,
  Dot,
  InvoiceDetailsHeading,
  InvoiceProduct,
  InvoiceProductList,
  PriceCell,
  StyledAccordionBody,
  StyledAccordionHeader,
  SumCell,
  SumTextCell,
  TitleCell,
  TotalSumGrid,
} from "src/pages/invoice/components/details-element/style";
import { getPriceAmountText } from "src/util/priceFormatter";

export type Props = {
  subscriptions: AccountInvoiceDetailsQuery["account"]["subscriptions"];
  accountLevelInvoiceGroups: NonNullable<NonNullable<AccountInvoiceDetailsQuery["account"]["invoice"]>["invoiceGroup"]>;
  amount?: number;
  category: string;
  isOpen: boolean | undefined;
  toggleIsOpen: (forceValue?: boolean) => void;
};

export function getProductTypeColor(amount: number): string {
  if (amount < 0) {
    return colors.green200;
  }

  return colors.corePurple300;
}

export function getDatePeriod(product: types.fixed.InvoiceDetail): string {
  const { periodFrom, periodTo } = product;
  const dateFormatter = (date: number) => dayjs.unix(date).format(format.DATE_DISPLAY_FORMAT_WITH_SHORT_YEAR);
  const datePeriod = (from: number, to: number) => `${dateFormatter(from)}-${dateFormatter(to)}`;

  if (periodFrom <= 0 && periodTo <= 0) return "";
  if (periodFrom <= 0 && periodTo > 0) {
    return datePeriod(periodTo, periodTo);
  }
  if (periodFrom > 0 && periodTo <= 0) {
    return datePeriod(periodFrom, periodFrom);
  }
  return datePeriod(periodFrom, periodTo);
}

export function DetailsElementMobile(props: Props): JSX.Element | null {
  const { accountLevelInvoiceGroups, subscriptions, amount, category, isOpen, toggleIsOpen } = props;
  const { setPhoneNumber } = useMobileAccount();

  if (!subscriptions?.length) {
    return null;
  }

  const totalAmountAccountLevelInvoiceGroup = accountLevelInvoiceGroups?.reduce(
    (acc, invoiceGroup) =>
      acc + (invoiceGroup?.invoiceRow?.reduce((acc, row) => acc + (row?.totalAmount?.value ?? 0), 0) || 0),
    0
  );
  const totalAmount = (amount || 0) - totalAmountAccountLevelInvoiceGroup;

  return (
    <DetailsAccordionItem hideBorder={true} showShadow={false} onClick={() => toggleIsOpen()} isOpen={isOpen}>
      <StyledAccordionHeader>
        <InvoiceDetailsHeading data-di-mask>{category}</InvoiceDetailsHeading>
        {!isOpen && totalAmount && <PriceCell data-di-mask>{getPriceAmountText(totalAmount, "kr")}</PriceCell>}
      </StyledAccordionHeader>
      <StyledAccordionBody>
        <InvoiceProductList>
          {subscriptions.map((detail) => {
            const queryParams = {
              isSubLevel: true,
            };

            const uriParams = setSearchParams(queryParams);
            return (
              <InvoiceProduct
                key={`${detail?.invoice?.invoiceNumber}-${detail?.phoneNumber?.localNumber}-detail-accordion-item`}
              >
                <TitleCell>
                  <Dot color={getProductTypeColor(detail?.invoice?.amount?.value ?? 0)} />
                  <Link
                    isInternalNavigation
                    href={uri.minSideFixed(`/faktura/${detail?.invoice?.invoiceNumber}${uriParams}`)}
                    onClick={() => setPhoneNumber(detail?.phoneNumber.localNumber ?? "")}
                    data-di-mask
                  >
                    {format.startCase(`${detail?.productUser?.firstName} ${detail?.productUser?.surname}`)}
                  </Link>
                </TitleCell>
                <PriceCell data-di-mask>{getPriceAmountText(detail?.invoice?.amount?.value, "kr")}</PriceCell>
              </InvoiceProduct>
            );
          })}
          {totalAmount && (
            <TotalSumGrid>
              <SumTextCell>Sum</SumTextCell>
              <SumCell data-di-mask>{getPriceAmountText(totalAmount, "kr")}</SumCell>
            </TotalSumGrid>
          )}
        </InvoiceProductList>
      </StyledAccordionBody>
    </DetailsAccordionItem>
  );
}
