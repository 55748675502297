import { colors } from "@telia/styleguide";
import styled from "styled-components";
import { remCalc } from "../utils/fonts";

export const SwitchButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  text-align: inherit;
  :disabled {
    cursor: not-allowed;
  }
`;
export const KnobBox = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  border: 1px solid ${colors.corePurple900};
  border-radius: 50rem;
  box-sizing: content-box;
  height: 1.25rem;
  width: 2.625rem;
  position: relative;
  margin: 0.5rem 0;
  right: 0px;
  transition: transform 200ms ease 0s;
  ${SwitchButton}:focus-visible & {
    outline: ${remCalc(2)} solid ${colors.blue500};
    outline-offset: ${remCalc(2)};
  }
  ${SwitchButton}:disabled & {
    border-color: ${colors.grey200};
  }
`;
export const Knob = styled.div`
  background-clip: padding-box;
  box-sizing: border-box;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 99rem;
  padding: 0.75rem;
  transform: translateX(-0.2rem);
  transition: transform 200ms ease 0s;
  display: grid;
  place-items: center;
  position: relative;
  ${SwitchButton}[aria-checked="true"] & {
    transform: translateX(1.325rem);
  }
`;
export const KnobDot = styled.span`
  height: 100%;
  width: 100%;
  background-color: ${colors.corePurple900};
  border-radius: inherit;
  position: absolute;
  inset: 0;
  transition:
    transform 200ms ease 0s,
    background-color 200ms ease 0s;
  &::before {
    position: absolute;
    inset: 0 50% 0 50%;
    margin: 0.4rem 0;
    border-radius: 99rem;
    width: 2px;
    content: "";
    background-color: white;
    transform: scaleY(1) translateX(-50%);
    transition: inherit;
    ${SwitchButton}[aria-checked="false"] & {
      transform: scaleY(0) translateX(-50%);
    }
  }
  ${SwitchButton}[aria-checked="false"] & {
    background-color: ${colors.grey500};
  }
  ${SwitchButton}:disabled & {
    background-color: ${colors.grey200};
  }
`;
export const KnobShadow = styled.span`
  position: absolute;
  inset: 0;
  background-color: ${colors.corePurple300};
  opacity: 0.5;
  border-radius: inherit;
  transition: inherit;
  ${KnobBox}:hover & {
    transform: scale(1.75);
  ${SwitchButton}:disabled & {
    display: none;
  }
`;
