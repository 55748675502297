import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

type Args = {
  financialAccountId: number | undefined;
  offset: number;
  dateFrom: number;
};

export function useFetchInvoiceHistory({ financialAccountId, dateFrom, offset }: Args) {
  const { data, error, mutate, isValidating } = useAxiosSWR<types.fixed.InvoiceHistory, Error | undefined>(
    financialAccountId ? `${ICX_REQUEST.URL_INVOICE_HISTORY}/${financialAccountId}` : null,
    { axiosConfig: { params: { offset, dateFrom } } }
  );

  return {
    invoiceHistory: data,
    invoiceHistoryIsLoading: !error && !data,
    invoiceHistoryIsError: !!error,
    invoiceHistoryError: error,
    invoiceHistoryMutate: mutate,
    isValidating,
  };
}
