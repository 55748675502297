import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { useFetchAndLocalStore } from "./useFetchAndLocalStore";

type Options = {
  enableSkeletonNumberStore?: boolean;
};

export function useFetchBroadbandProducts(options?: Options) {
  const enableSkeletonNumberStore = options?.enableSkeletonNumberStore ?? false;
  const { data, mutate, ...rest } = useFetchAndLocalStore(ICX_REQUEST.URL_BROADBAND_PRODUCT, {
    storeKey: "number-of-broadband-products",
    enableSkeletonNumberStore,
  });

  return {
    broadbandProducts: data,
    mutateBroadbandProducts: mutate,
    ...rest,
  };
}
