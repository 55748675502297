import { graphql } from "src/gql";

export const getSwitchAgreements = graphql(`
  query getSwitchAgreements($accountId: String!) {
    account(accountId: $accountId) {
      id
      switchAgreements(loadInsuranceInfo: true) {
        id
        phoneNumber {
          localNumber
        }
        remainingCost {
          value
        }
        totalMandatoryInstallments
        costToReplace {
          value
        }
        imei
        phoneNumber {
          localNumber
        }
        insurance {
          type
          code
          insuranceCase {
            caseNumber
            caseStatus
          }
        }
        product {
          id
          brand
          name
          imageUrl
        }
      }
    }
  }
`);
