import { Container, TrackClickEvent } from "@telia-no-min-side/components";
import { graphql } from "gql";
import { Heading, TextSpacing, Text, Flex, Button, RadioCardGroup } from "@telia/teddy";
import { ReactNode, useState } from "react";
import { format } from "@telia-no-min-side/utils";
import { B2BFamilyDiscountFlowQuery } from "gql/graphql";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export const CREATE_B2C_ACCOUNT = graphql(`
  mutation createB2CAccount($phoneNumber: String!) {
    accountCreate(phoneNumber: $phoneNumber) {
      orderId
    }
  }
`);

// Steps to get b2b family discount:
// 1. Check if b2b sub has b2b_family_offer as additional product
// 2. User has to select a b2c subscription if they don't have one. (select sub page)
// 3. Create b2c account
// 4. Add SOC to b2c account
// 5. Redir to webshop to complete order

export function SelectAccount({
  accounts,
  children,
}: {
  accounts: NonNullable<B2BFamilyDiscountFlowQuery["accounts"]>;
  children: ({ selectedAccountId }: { selectedAccountId: string | undefined }) => ReactNode;
}) {
  const firstB2CAccount = accounts.find((account) => account.id);
  const [selectedAccountId, setSelectedAccountId] = useState(firstB2CAccount?.id);
  return (
    <Container goBackTo="/minside/hjem" padding maxWidth="lg" flexDirection="column" gap="lg">
      <TextSpacing>
        <Heading variant="title-300" as="h1">
          Velg konto
        </Heading>
        <Text>Familierabatt kan kun legges til én av dine kontoer. Velg hvilken konto du vil bruke rabatten på:</Text>
      </TextSpacing>
      <Flex direction="column" gap="200" maxWidth="400px">
        <RadioCardGroup
          defaultValue={firstB2CAccount?.id}
          value={selectedAccountId}
          onValueChange={(accountId) => {
            setSelectedAccountId(accountId);
          }}
        >
          {accounts?.map((account) => (
            <RadioCardGroup.Item key={account.id} value={account.id}>
              <RadioCardGroup.Title>Konto {account.id}</RadioCardGroup.Title>
              <RadioCardGroup.Body as="div">
                {account.subscriptions?.length
                  ? account.subscriptions?.map((sub) => (
                      <Text key={sub.phoneNumber.localNumber}>
                        {format.startCase(sub.productUser?.firstName || "")} (
                        {format.formatPhoneNumber(sub.phoneNumber.localNumber)})
                      </Text>
                    ))
                  : "Uten abonnement"}
              </RadioCardGroup.Body>
            </RadioCardGroup.Item>
          ))}
        </RadioCardGroup>

        <TrackClickEvent
          asChild
          event={{
            ui_item_action: `select_account`,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.B2B2C_FAMILY_DISCOUNT,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: window.location.pathname,
            ui_item_text: "Velg",
          }}
        >
          <Button ml="auto" asChild disabled={!selectedAccountId}>
            {children({ selectedAccountId })}
          </Button>
        </TrackClickEvent>
      </Flex>
    </Container>
  );
}
