import { Card, Badge, Text } from "@telia/teddy";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function BroadbandCabin() {
  return (
    <Card variant="white" shadow layout="default" bordered width="100%" maxWidth="400px">
      <Card.Slot align="top-right">
        <Badge variant="information">Dekningsgaranti</Badge>
      </Card.Slot>

      <Card.Heading as="h3" variant="title-100">
        Ta med deg internett overalt!
      </Card.Heading>
      <Card.Content>
        <Text variant="additional-100">Med mobilt bredbånd får du raskt internett der du er, fra kun 249,-</Text>
      </Card.Content>
      <Card.Footer height="100%" align="end">
        <Card.Button asChild variant="secondary">
          <a href={EXTERNAL_LINK.INTERNET_MOBILE_BROADBAND}>Se tilbud</a>
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
