import { ReactNode, SyntheticEvent } from "react";
import { ShoppingSummary } from "@telia-no-min-side/components";
import { getSimplePriceText } from "@telia-no-min-side/utils";
import { isDiscountAvailable } from "./utils/product";
import { Button, Notification } from "@telia/teddy";
import { VasAdditionalProduct } from "./types";

type CartSummaryProps = {
  loading: boolean;
  children?: ReactNode;
  onOrder: () => void;
  onCancel: () => void;
  selectedProduct: VasAdditionalProduct;
};

export function CartSummary({ selectedProduct, loading, onCancel, onOrder, children }: CartSummaryProps) {
  const hasDiscount = selectedProduct && isDiscountAvailable(selectedProduct);
  const order = (e: SyntheticEvent) => {
    e.preventDefault();
    onOrder();
  };

  return (
    <ShoppingSummary.Wrapper maxWidth="md">
      <ShoppingSummary.List title="Månedspris">
        <ShoppingSummary.Item
          text={`${selectedProduct?.shortName} ${
            hasDiscount ? `${selectedProduct?.price?.discountDuration} md. rabatt ` : ""
          }
          `}
          price={getSimplePriceText(
            hasDiscount ? selectedProduct?.price?.price ?? 0 : selectedProduct?.price?.priceWithoutDiscount ?? 0,
            "md."
          )}
          secondaryText={
            hasDiscount
              ? `Deretter ${getSimplePriceText(selectedProduct?.price?.priceWithoutDiscount ?? 0, "md.")}`
              : ""
          }
        />

        <ShoppingSummary.Item
          text="Totalt per måned"
          price={getSimplePriceText(selectedProduct?.price?.priceWithoutDiscount ?? 0, "md.")}
          highlight
          secondaryText={
            hasDiscount && selectedProduct.price?.priceWithoutDiscount
              ? "Belastes på din faktura etter kampanjeperioden"
              : "Belastes på din neste faktura"
          }
        />
      </ShoppingSummary.List>

      {hasDiscount ? (
        <Notification mb="200" variant="information" type="tile">
          <Notification.Icon name="info" />
          <Notification.Text>
            Siden vi fakturerer forskuddsvis, får du en faktura i løpet av prøveperioden. Hvis du sier opp får du
            pengene tilbake.
          </Notification.Text>
        </Notification>
      ) : null}

      <ShoppingSummary.LineSeparator />

      {children}

      <ShoppingSummary.ActionWrapper>
        <Button
          variant="tertiary-purple"
          onClick={onCancel}
          disabled={loading}
          data-tracking-id="additional-services-cart-cancel-button"
        >
          Avbryt
        </Button>

        <Button
          variant="primary"
          onClick={order}
          loading={loading}
          data-tracking-id="additional-services-cart-order-button"
        >
          {selectedProduct?.presentation?.included ? "Aktiver" : "Bestill"}
        </Button>
      </ShoppingSummary.ActionWrapper>
    </ShoppingSummary.Wrapper>
  );
}
