import { types } from "@telia-no-min-side/utils";
import { formatDatalayerTransactionData } from "src/services/googleAnalytics/eventDataHelpers";
import { getTax } from "util/tracking";

export function createFlexChangeEvent(
  removedProduct: types.fixed.TvPointsAndBroadbandPrice | undefined,
  addedProduct: types.fixed.TvPointsAndBroadbandPrice | undefined
) {
  return formatDatalayerTransactionData({
    actionId: "",
    id: `BB-${addedProduct?.broadbandOption?.price.commercialProductId}/TV-${addedProduct?.tvOption?.price.commercialProductId}`,
    name: `BB-${addedProduct?.broadbandOption?.downloadSpeed}/TV-${addedProduct?.tvOption?.points}`,
    revenue: addedProduct?.sum.currentAmount || 0,
    price: addedProduct?.sum.currentAmount || 0,
    tax: getTax(addedProduct?.sum.currentAmount || 0),
    category: "Flex/Subscription",
    dimensions: {
      dimension105: `BB-${removedProduct?.broadbandOption?.price.commercialProductId}/TV-${removedProduct?.tvOption?.price.commercialProductId}`,
      dimension106: `BB-${removedProduct?.broadbandOption?.downloadSpeed}/TV-${removedProduct?.tvOption?.points}`,
      dimension107: `${removedProduct?.sum.currentAmount}`,
    },
  });
}
