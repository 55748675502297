import cloneDeep from "lodash.clonedeep";
import { ipUtilities } from "./validation";

export const staticInternalIPParams = {
  Alias: Symbol("Alias"),
  IPAddress: Symbol("IPAddress"),
  MacAddress: Symbol("MacAddress"),
};

export const ALGParams = {
  PPTP: Symbol("PPTP"),
  IPSEC: Symbol("IPSEC"),
  SIP: Symbol("SIP"),
};

const isWifi2GHzSupported = (homeCentral) => {
  return homeCentral.SupportedSettings.Wifi2GHz.Supported;
};

const isWifi5GHzSupported = (homeCentral) => {
  return homeCentral.SupportedSettings.Wifi5GHz.Supported;
};

const turnOffWifi2GHzIfOn = (homeCentral) => {
  const updatedHomeCentral = cloneDeep(homeCentral);

  if (updatedHomeCentral.Settings.Wifi2GHz.Enabled) {
    updatedHomeCentral.Settings.Wifi2GHz.Enabled = false;
  }
  return updatedHomeCentral;
};

const turnOffWifi5GHzIfOn = (homeCentral) => {
  const updatedHomeCentral = cloneDeep(homeCentral);

  if (updatedHomeCentral.Settings.Wifi5GHz.Enabled) {
    updatedHomeCentral.Settings.Wifi5GHz.Enabled = false;
  }
  return updatedHomeCentral;
};

const turnOnWifi2GHzIfOff = (homeCentral) => {
  const updatedHomeCentral = cloneDeep(homeCentral);

  if (!updatedHomeCentral.Settings.Wifi2GHz.Enabled) {
    updatedHomeCentral.Settings.Wifi2GHz.Enabled = true;
  }
  return updatedHomeCentral;
};

const turnOnWifi5GHzIfOff = (homeCentral) => {
  const updatedHomeCentral = cloneDeep(homeCentral);

  if (!updatedHomeCentral.Settings.Wifi5GHz.Enabled) {
    updatedHomeCentral.Settings.Wifi5GHz.Enabled = true;
  }
  return updatedHomeCentral;
};

const extractCorrectOctetsFromGateway = (gateway, mask) => {
  const gatewayValues = Object.values(gateway);
  const maskValues = Object.values(mask);
  const internalIpForRule = {
    Octet1: "",
    Octet2: "",
    Octet3: "",
    Octet4: "",
  };

  for (let i = 0; i < maskValues.length; i++) {
    if (maskValues[i] === 255) {
      switch (i) {
        case 0:
          internalIpForRule.Octet1 = gatewayValues[i];
          break;
        case 1:
          internalIpForRule.Octet2 = gatewayValues[i];
          break;
        case 2:
          internalIpForRule.Octet3 = gatewayValues[i];
          break;
        case 3:
          internalIpForRule.Octet4 = gatewayValues[i];
          break;
      }
    }
  }
  return internalIpForRule;
};

export const homeCentralUtilities = {
  setUpnpFlag(originalHomeCentral) {
    const homeCentral = cloneDeep(originalHomeCentral);

    homeCentral.Settings.Upnp.Enabled = !homeCentral.Settings.Upnp.Enabled;
    return homeCentral;
  },
  setBridgeModeFlag(originalHomeCentral) {
    let homeCentral = cloneDeep(originalHomeCentral);

    if (!homeCentral.Settings.BridgeMode.Enabled) {
      if (isWifi2GHzSupported(homeCentral)) {
        homeCentral = turnOffWifi2GHzIfOn(homeCentral);
      }
      if (isWifi5GHzSupported(homeCentral)) {
        homeCentral = turnOffWifi5GHzIfOn(homeCentral);
      }
    } else {
      if (isWifi2GHzSupported(homeCentral)) {
        homeCentral = turnOnWifi2GHzIfOff(homeCentral);
      }
      if (isWifi5GHzSupported(homeCentral)) {
        homeCentral = turnOnWifi5GHzIfOff(homeCentral);
      }
    }

    homeCentral.Settings.BridgeMode.Enabled = !homeCentral.Settings.BridgeMode
      .Enabled;
    return homeCentral;
  },
  updateIPAddresses(homeCentral, newValue, parameterToChange) {
    const updatedHomeCentral = cloneDeep(homeCentral);

    switch (parameterToChange) {
      case "gateway":
        updatedHomeCentral.Settings.Lan.InternalGWIP = newValue;
        ipUtilities.trickleDownGateWayChanges(updatedHomeCentral);
        break;
      case "dhcp-from":
        updatedHomeCentral.Settings.Dhcp.FromIP = newValue;
        break;
      case "dhcp-to":
        updatedHomeCentral.Settings.Dhcp.ToIP = newValue;
        break;
      case "dmz":
        updatedHomeCentral.Settings.Dmz.IP = newValue;
        break;
    }
    return updatedHomeCentral;
  },
  setLease(homeCentral, leaseValue) {
    const updatedHomeCentral = cloneDeep(homeCentral);

    updatedHomeCentral.Settings.Dhcp.LeaseTime = leaseValue;
    return updatedHomeCentral;
  },
  setDmzFlag(homeCentral) {
    const updatedHomeCentral = cloneDeep(homeCentral);

    updatedHomeCentral.Settings.Dmz.Enabled = !updatedHomeCentral.Settings.Dmz
      .Enabled;
    return updatedHomeCentral;
  },
  generateLeaseValues(homeCentral) {
    const leaseOptions = [{ value: "1", name: "1 time" }];

    for (
      let i = homeCentral.SupportedSettings.Dhcp.MinLeaseTime;
      i <= homeCentral.SupportedSettings.Dhcp.MaxLeaseTime;
      i++
    ) {
      if (i % 12 === 0) {
        leaseOptions.push({ value: "" + i, name: `${i} timer` });
      }
    }
    return leaseOptions;
  },
  setSipAlgFlag(homeCentral) {
    const updatedHomeCentral = cloneDeep(homeCentral);

    updatedHomeCentral.Settings.Alg.SipAlgEnabled = !updatedHomeCentral.Settings
      .Alg.SipAlgEnabled;
    return updatedHomeCentral;
  },
  setPptpAlgFlag(homeCentral) {
    const updatedHomeCentral = cloneDeep(homeCentral);

    updatedHomeCentral.Settings.Alg.PptpAlgEnabled = !updatedHomeCentral
      .Settings.Alg.PptpAlgEnabled;
    return updatedHomeCentral;
  },
  setIpsecAlgFlag(homeCentral) {
    const updatedHomeCentral = cloneDeep(homeCentral);

    updatedHomeCentral.Settings.Alg.IpsecAlgEnabled = !updatedHomeCentral
      .Settings.Alg.IpsecAlgEnabled;
    return updatedHomeCentral;
  },
  createEmtyStaticInteralIpAndAddToHomeCentral(homeCentral) {
    const { InternalGWIP, NetworkMask } = homeCentral.Settings.Lan;
    const updatedHomeCentral = cloneDeep(homeCentral);
    const newEntry = {
      Alias: "",
      MacAddress: "",
      IPAddress: extractCorrectOctetsFromGateway(InternalGWIP, NetworkMask),
    };
    const currentEntryList =
      updatedHomeCentral.Settings.StaticInternalIP.StaticInternalIPEntries;

    updatedHomeCentral.Settings.StaticInternalIP.StaticInternalIPEntries = currentEntryList.concat(
      [newEntry]
    );

    return updatedHomeCentral;
  },
  createEmtpyStaticInternalEntry(homeCentral) {
    const { InternalGWIP, NetworkMask } = homeCentral.Settings.Lan;

    return {
      Alias: "",
      MacAddress: "",
      IPAddress: extractCorrectOctetsFromGateway(InternalGWIP, NetworkMask),
    };
  },
  addNewStaticInternalIPEntryToHomeCentral(homeCentral, newEntry) {
    const updatedHomeCentral = cloneDeep(homeCentral);
    const currentListOfEntries = cloneDeep(
      updatedHomeCentral.Settings.StaticInternalIP.StaticInternalIPEntries
    );

    updatedHomeCentral.Settings.StaticInternalIP.StaticInternalIPEntries = currentListOfEntries.concat(
      [newEntry]
    );

    return updatedHomeCentral;
  },
  deleteStaticInternalIP(homeCentral, indexOfIP) {
    const updatedHomeCentral = cloneDeep(homeCentral);

    updatedHomeCentral.Settings.StaticInternalIP.StaticInternalIPEntries = updatedHomeCentral.Settings.StaticInternalIP.StaticInternalIPEntries.filter(
      (ip, index) => index !== indexOfIP
    );
    return updatedHomeCentral;
  },
  updateStaticInternalIPEntry(homeCentral, updatedRule, indexOfRule) {
    const updatedHomeCentral = cloneDeep(homeCentral);

    updatedHomeCentral.Settings.StaticInternalIP.StaticInternalIPEntries[
      indexOfRule
    ] = updatedRule;
    return updatedHomeCentral;
  },
  updateParamInStaticInternalIPEntry(entry, newValue, param) {
    const { Alias, IPAddress, MacAddress } = staticInternalIPParams;
    const updatedEntry = cloneDeep(entry);

    switch (param) {
      case Alias:
        updatedEntry.Alias = newValue;
        break;
      case IPAddress:
        updatedEntry.IPAddress = newValue;
        break;
      case MacAddress:
        updatedEntry.MacAddress = newValue;
        break;
      default:
        return updatedEntry;
    }
    return updatedEntry;
  },
};
