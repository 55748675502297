import React, { createContext, ReactNode, useContext, useMemo } from "react";
import { v4 as uuid } from "uuid";

type AriaContext = {
  labelId: string;
};

const initialAriaValue: AriaContext = {
  labelId: uuid(),
};

const Aria = createContext<AriaContext>(initialAriaValue);

export function useAria(): AriaContext {
  const context = useContext<AriaContext>(Aria);

  if (!context) {
    throw Error("No aria context found! This usually happens when you try to access a context outside of a provider");
  }
  return context;
}

type Props = {
  children: ReactNode;
};

export function AriaProvider(props: Props) {
  const { children } = props;

  const memoValue = useMemo<AriaContext>(() => ({ ...initialAriaValue }), [initialAriaValue]);

  return <Aria.Provider value={memoValue}>{children}</Aria.Provider>;
}
