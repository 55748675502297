import dayjs from "dayjs";
import { Container, Paragraph } from "@telia-no-min-side/components";

type Props = {
  monthlyPrice: number;
  remainingPrice: number;
  effectiveDate: string;
  expirationDate: string;
};

export function AgreementInfo({ monthlyPrice, remainingPrice, effectiveDate, expirationDate }: Props) {
  return (
    <Container flexDirection="row" gap>
      <Container flexDirection="column" gap="lg">
        <Container>
          <Paragraph isFaded removeMargin>
            Månedspris
          </Paragraph>
          <Paragraph data-di-mask removeMargin>
            {monthlyPrice},-
          </Paragraph>
        </Container>
        {remainingPrice && (
          <Container>
            <Paragraph isFaded removeMargin>
              Gjenstående å betale
            </Paragraph>
            <Paragraph data-di-mask removeMargin>
              {remainingPrice},-
            </Paragraph>
          </Container>
        )}
      </Container>
      <Container flexDirection="column" gap="lg">
        <Container>
          <Paragraph isFaded removeMargin>
            Avtale startet
          </Paragraph>
          <Paragraph data-di-mask removeMargin>
            {dayjs(effectiveDate).format("DD.MM.YYYY")}
          </Paragraph>
        </Container>
        <Container>
          <Paragraph isFaded removeMargin>
            Telefonen er ferdig nedbetalt
          </Paragraph>
          <Paragraph data-di-mask removeMargin>
            {dayjs(expirationDate).format("DD.MM.YYYY")}
          </Paragraph>
        </Container>
      </Container>
    </Container>
  );
}
