import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function ReceiptSuccess() {
  return (
    <Container maxWidth="md" padding alignItems="flex-start" flexDirection="column" gap>
      <Heading tag="h2" variant="title-300">
        Takk for din bestilling!
      </Heading>

      <Paragraph>
        <strong>Din årsrabatt er lagt til.</strong>
      </Paragraph>
      <Paragraph>Du vil se endringer på Min Side etter kort tid.</Paragraph>
      <Button
        tag="a"
        isInternalNavigation
        href={uri.minSideFixed("/hjem")}
        icon="arrow-left"
        iconPosition="before"
        trackEvent={{
          ui_item_text: TRACK_EVENT.ITEM_TEXT.BACK_TO_OVERVIEW,
          ui_item_url: uri.minSideFixed("/hjem"),
          ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FWA_COMMITMENT,
          ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_SUCCESS,
          ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
        }}
      >
        Tilbake til hjem
      </Button>
    </Container>
  );
}
