import { ComponentProps } from "react";
import { Accordion } from "@telia-no-min-side/components";
import { Product } from "@telia-no-min-side/utils/src/types/fixed";

export const getBadgeInfo = (product: Product): ComponentProps<typeof Accordion.Item>["lozengeRight"] => {
  if (!product.status?.title) return undefined;

  return {
    label: product.status?.title,
    status: product.status?.active ? "positive" : "neutral",
  };
};
