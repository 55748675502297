import React from "react";
import { Container } from "../Container";
import { Li, SpaceBetween, SecondaryText } from "./style";

export type ShoppingSummaryItemProps = {
  text: string;
  highlight?: boolean;
  price: string;
  secondaryText?: string;
  additionalText?: string;
};
export function Item(props: ShoppingSummaryItemProps): JSX.Element {
  const { text, secondaryText, price, highlight, additionalText } = props;
  const justifyContentCondition = additionalText && !secondaryText ? "end" : "space-between";

  return (
    <Li>
      {highlight ? (
        <strong>
          <SpaceBetween>
            {text}
            <b>{price}</b>
          </SpaceBetween>
        </strong>
      ) : (
        <SpaceBetween>
          {text}
          <b>{price}</b>
        </SpaceBetween>
      )}
      <Container flexDirection="row" justifyContent={justifyContentCondition}>
        {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
        {additionalText && <SecondaryText>{additionalText}</SecondaryText>}
      </Container>
    </Li>
  );
}
