import dayjs from "dayjs";

export const formatTimeUntilDate = (endDate: string) => {
  if (!endDate) return null;

  const duration = dayjs.duration(dayjs(endDate).diff(dayjs()));
  const daysLeft = Math.trunc(duration.asDays());
  const hoursLeft = Math.trunc(duration.asHours());

  if (hoursLeft < 24) return hoursLeft <= 1 ? `${hoursLeft} time` : `${hoursLeft} timer`;
  return daysLeft === 1 ? `${daysLeft} dag` : `${daysLeft} dager`;
};
