import { useEffect } from "react";
import { Icon } from "@telia/teddy";
import { ErrorBox } from "components/ErrorBox";
import { uri, track } from "@telia-no-min-side/utils";
import { fetchPartnerInformation, isTelia } from "util/PartnerHacks";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { useFixedInvoice } from "hooks/useFixedInvoice";
import { useFetchFlexCurrent, useFetchProfile, useFetchTvSubscription } from "src/api/fixed";

function getOrderBroadbandUrl(hasFlex?: boolean, hasTv?: boolean): string {
  if (hasFlex) return INTERNAL_LINK.FLEX_PAGE;
  if (hasTv) return uri.purchase("/bredband/nytt");
  return EXTERNAL_LINK.INTERNET_FIXED_BRODBAND;
}

export function NoBroadband() {
  const profile = useFetchProfile();
  const partnerInfo = fetchPartnerInformation(profile.data?.businessUnitId);
  const tvSubscription = useFetchTvSubscription();
  const hasTv = tvSubscription.data?.products && tvSubscription.data.products.length > 0;
  const flexCurrent = useFetchFlexCurrent();
  const hasFlex = !!flexCurrent.data?.currentTvAndBroadband;
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { isInvoiceOverdueOrInCollection } = useFixedInvoice();
  const actionContent = (actionText: string) => (
    <>
      {actionText} <Icon name="arrow-right" />
    </>
  );
  const errorBoxWidth = { md: "100%", lg: "40%" };

  useEffect(() => {
    const trackEvent = {
      event: TRACK_EVENT.EVENT.CUSTOMER_NO_PROD,
      affiliation: TRACK_EVENT.AFFILIATION.TELIA_MIN_SIDE,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.NOT_INERNET_CUSTOMER,
      ui_item_action: TRACK_EVENT.ITEM_ACTION.VIEW,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.NO_PRODUCT_ACTIVE,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.INFO_MSG,
      ui_item_url: EXTERNAL_LINK.INTERNET_EQUIPMENT,
    };
    pushGenericTrackingEvent(trackEvent);
  }, []);
  // Logged in without fixed customerid, gives 204 on /api/profile/get

  if (!profile) {
    return (
      <ErrorBox
        title="Ikke Internett-kunde ennå?"
        href={EXTERNAL_LINK.INTERNET_FIXED_BRODBAND}
        actionContent={actionContent("Sjekk om du kan få internett fra Telia")}
        description="Ved å samle både TV, internett og mobil får du mange fordeler med Telia!"
        width={errorBoxWidth}
      />
    );
  }

  // Only Telia customers can order broadband on MinSide
  if (!isTelia(profile.data?.businessUnitId)) {
    if (partnerInfo) {
      return (
        <ErrorBox
          title="Ikke Internett-kunde ennå?"
          href={partnerInfo.homepage}
          actionContent={actionContent(`Gå til ${partnerInfo.name}`)}
          description={`Ta kontakt med ${partnerInfo.name} på tlf ${partnerInfo.phone} eller gå til hjemmesiden deres om du ønsker Internett.`}
          width={errorBoxWidth}
        />
      );
    }
    return (
      <ErrorBox
        title="Ikke Internett-kunde ennå?"
        href={EXTERNAL_LINK.INTERNET_HOME}
        actionContent={actionContent("Sjekk hva vi kan levere hos deg")}
        description="Vi får deg på nett uansett - med fast, trådløst eller mobilt bredbånd."
        width={errorBoxWidth}
      />
    );
  }

  // Customer cannot buy if invoice is overdue or in collection
  if (isInvoiceOverdueOrInCollection) {
    const overdueText =
      "Vi kan dessverre ikke selge deg Internett grunnet ubetalt faktura. " +
      "Vennligst betal utestående beløp og prøv igjen.";

    const collectionText =
      "Vi kan dessverre ikke selge deg Internett grunnet ubetalt faktura. " +
      "Etter du har betalt det du skylder, kan du kontakte Telia sin " +
      "kundeservice for å få tilgang på en ny konto.";

    return (
      <ErrorBox
        href={INTERNAL_LINK.INVOICE_PAGE}
        actionContent={actionContent("Gå til faktura")}
        title="Ikke Internett-kunde ennå?4"
        description={profile.data?.defaultFinancialAccountOverdue ? overdueText : collectionText}
        openInSameTab={true}
        width={errorBoxWidth}
      />
    );
  }

  // Customers can buy Internet on MinSide if they already have TV from Telia.
  // If not they have to use the schema on open pages.
  return (
    <ErrorBox
      title="Ikke Internett-kunde ennå?"
      href={getOrderBroadbandUrl(hasFlex, hasTv)}
      description="Ved å samle både TV, internett og mobil får du mange fordeler med Telia!"
      actionContent={actionContent(hasTv ? "Bestill internett fra Telia" : "Sjekk om du kan få internett fra Telia")}
      openInSameTab={!!hasTv}
      width={errorBoxWidth}
    />
  );
}
