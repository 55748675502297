import { Paragraph } from "@telia-no-min-side/components";
import { MobileSubscriptionAccordionItem } from "src/components/mobile-subscription-accordion-item";
import { FragmentType, getFragmentData } from "src/gql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { ComponentProps } from "react";
import { getDownloadSpeedMbb } from "util/format-subscription/getDownloadSpeedMbb";
import { getOfferingPrices } from "util/format-subscription/getOfferingPrices";
import { CURRENT_SUBSCRIPTION_FRAGMENT } from "../graphql/currentSubscriptionFragment";
import { getIncludedPropertiesMbb } from "../../../../util/format-subscription/getIncludedPropertiesMbb";
import { getMbbName } from "../../../../util/format-subscription/getMbbName";

export function CurrentSubscriptionAccordionItem({
  offeringFragment,
}: {
  offeringFragment: FragmentType<typeof CURRENT_SUBSCRIPTION_FRAGMENT>;
}) {
  const subscription = getFragmentData(CURRENT_SUBSCRIPTION_FRAGMENT, offeringFragment);
  const { orders } = useOrderSubscription();
  const hasOnGoingOrder = orders.some((order) => order.phoneNumber === subscription.phoneNumber.localNumber);

  if (!subscription.userOffering) return <Paragraph removeMargin>Klarte ikke hente data</Paragraph>;

  const offeringPrices = getOfferingPrices(subscription.userOffering.offeringPrices);
  const subscriptionName = getMbbName(offeringPrices, subscription.userOffering.shortName);
  const downloadSpeed = getDownloadSpeedMbb(offeringPrices, subscription.userOffering.longDescription);
  const includedProperties = getIncludedPropertiesMbb(
    subscription.userOffering.shortName,
    downloadSpeed,
    subscription.userOffering.additionalProperties,
    subscription.userOffering.disclaimers,
    offeringPrices
  );

  const getLozenge = (): ComponentProps<typeof MobileSubscriptionAccordionItem>["lozengeRight"] => {
    if (hasOnGoingOrder) {
      return { icon: "sync", label: "Oppdateres", status: "info" };
    }

    return undefined;
  };

  const overLine = downloadSpeed ? <Paragraph removeMargin={true}>{downloadSpeed} Mbps</Paragraph> : undefined;

  return (
    <MobileSubscriptionAccordionItem
      overLine={overLine}
      hasDoubleData={false}
      hasDoubleSpeed={false}
      includedProperties={includedProperties}
      priceMonthly={subscription.userOffering.monthlyCost?.amount}
      subscriptionName={subscriptionName?.length ? subscriptionName : "Klarte ikke hente navn"}
      originalPriceMonthly={subscription.userOffering.originalPrice?.amount}
      lozengeRight={getLozenge()}
      dataTrackingID={`change-subscription-current-item-${(subscriptionName || "").replaceAll(" ", "_")}`}
    />
  );
}
