import {
  DetailedSubscriptionQuery,
  OfferingPriceCodes,
  OfferingPriceDetails,
  SubscriptionUsageFragment,
} from "../../../gql/graphql";
import { isViaplay } from "util/format-subscription/isViaplay";

export function getIncludedData(userOffering: SubscriptionUsageFragment["userOffering"]) {
  const offeringPrices = userOffering?.offeringPrices?.flatMap((offeringPrice) => offeringPrice?.offeringPrices);
  const dataIncluded = offeringPrices?.find((offeringPrice) => offeringPrice?.code === OfferingPriceCodes.DataIncluded);
  return dataIncluded;
}

export function isDataIncludedValid(
  offeringPriceDetails: ReturnType<typeof getIncludedData>
): offeringPriceDetails is Omit<OfferingPriceDetails, "amount" | "monetaryUnit"> &
  Required<Pick<OfferingPriceDetails, "amount" | "monetaryUnit">> {
  return typeof offeringPriceDetails?.amount === "number" && typeof offeringPriceDetails?.monetaryUnit === "string";
}

export function getProductNameFromSubscription(subscription: DetailedSubscriptionQuery["subscription"]) {
  const dataIncluded = getIncludedData(subscription.userOffering);

  const shouldDisplayDataIncluded = isDataIncludedValid(dataIncluded);

  if (shouldDisplayDataIncluded) {
    return `${dataIncluded?.amount} ${dataIncluded?.monetaryUnit}`;
  }

  const productName = subscription.userOffering?.shortName;

  if (productName?.startsWith("Telia X")) {
    const productNames = productName.split(" ");
    if (productNames.length >= 3) {
      const isViaplayTotal = isViaplay(productName);
      const isPlusSbu = productNames.length > 3 && productNames[3].toLocaleLowerCase().includes("pluss");
      const teliaXVariant = isPlusSbu || isViaplayTotal ? `${productNames[2]} ${productNames[3]}` : productNames[2];
      return `X ${teliaXVariant}`;
    }
    return productName.replace("Telia ", "");
  }
  if (productName?.toLowerCase().includes("kontant")) {
    return productName.replace("Telia ", "");
  }
  if (productName?.includes("Ansatt")) {
    return "Ansatt";
  }
  if (productName?.includes("Telia")) {
    return productName.replace("Telia ", "");
  }
  return productName;
}
