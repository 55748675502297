import { notEmpty } from "@telia-no-min-side/utils";
import { ProductChangesMap, ProductChangesType } from "../type";

const toChangedProducts = (products: NonNullable<ProductChangesType>["changedProducts"]) => {
  return (products || [])
    .filter(notEmpty)
    .filter((p) => !!p.newProduct.name)
    .map((product) => ({
      name: product.newProduct.name || "",
      shortName: product.newProduct.shortName || "",
      longDescription: product.newProduct.longDescription || "",
      code: product.newProduct.code || "",
      priceComparison: {
        newPrice: product.newProduct.price?.price || 0,
        oldPrice: product.oldProduct.price?.price || 0,
      },
    }));
};

const toAddedOrLostProducts = (
  products: NonNullable<ProductChangesType>["lostProducts"] | NonNullable<ProductChangesType>["addedProducts"],
  isLostProducts = false
) => {
  return (products || [])
    .filter(notEmpty)
    .filter((p) => !!p.name)
    .map((product) => ({
      name: product.name || "",
      shortName: product.shortName || "",
      longDescription: product.longDescription || "",
      code: product.code || "",
      price: product.price?.price || 0,
      hidePrice: isLostProducts && product.name?.localeCompare("Viaplay Total") === 0,
    }));
};

function isSimProduct(product: NonNullable<NonNullable<ProductChangesType>["changedProducts"]>[0]) {
  return product?.oldProduct?.additionalProperties?.some(
    (prop) => prop?.name === "PRODUCT_GROUP" && prop.value === "EXTRA_SIM"
  );
}

export function toProductChangesMap(productChanges?: ProductChangesType): ProductChangesMap | null {
  if (!productChanges) {
    return null;
  }

  const changedProducts: NonNullable<ProductChangesType>["changedProducts"] = [];
  const simProducts: NonNullable<ProductChangesType>["changedProducts"] = [];

  // sort changedProducts into two groups - sim card products and everything else
  (productChanges.changedProducts || []).forEach((product) =>
    isSimProduct(product) ? simProducts.push(product) : changedProducts.push(product)
  );

  // all sim cards have the same price. We want to show just the
  // price change, not a list of all cards
  const simProduct = simProducts[0] ? simProducts[0] : undefined;

  const lostProductsWithChangedDescription = changeViaplayProductDescriptionWhenDowngrading(
    productChanges.lostProducts
  );

  return {
    simProducts: simProduct
      ? {
          newPrice: simProduct.newProduct.price?.price || 0,
          oldPrice: simProduct.oldProduct.price?.price || 0,
        }
      : undefined,
    changedProducts: toChangedProducts(changedProducts),
    lostProducts: toAddedOrLostProducts(lostProductsWithChangedDescription, true),
    addedProducts: toAddedOrLostProducts(productChanges.addedProducts),
  };
}

const VIAPLAY_DOWNGRADE_TEXT =
  "Du vil bli fakturert månedspris for Telia X Viaplay Total ut inneværende fakturaperiode. Du beholder tilgang til Viaplay Total ut den samme fakturaperioden.";

// Please, forgive me for this
export function changeViaplayProductDescriptionWhenDowngrading(
  products: NonNullable<ProductChangesType>["lostProducts"]
) {
  return products?.map((product) =>
    product?.name?.localeCompare("Viaplay Total") === 0
      ? { ...product, longDescription: VIAPLAY_DOWNGRADE_TEXT }
      : product
  );
}
