import React from "react";
import { CSSTransition } from "react-transition-group";
import { FadeInContent } from "../../../../modules/Animations/FadeIn/FadeInContent";

import "./ExpandableStaticInternalIPEntry.less";

export const ExpandableStaticInternalIPEntry = ({ show, children }) => (
  <CSSTransition timeout={600} in={show} unmountOnExit classNames="expandable-static-internal-ip-entry">
    {(state) => (
      <div>
        <FadeInContent show={state === "entered"}>{children}</FadeInContent>
      </div>
    )}
  </CSSTransition>
);
