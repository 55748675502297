import { Button, ShoppingSummary, Container } from "@telia-no-min-side/components";
import { format, getSimplePriceText, types, uri } from "@telia-no-min-side/utils";
import { useFetchTvOverFwa } from "src/store/hooks/useFetchTvOverFwa";
import { productId } from "src/util/productIds";
import { getIncludedProducts } from "./shoppingSummaryUtils";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { openPdf } from "util/pdf-order-summary/openPdf";
import { DownloadButton } from "components/pdf-order-summary/DownloadButton";
import { PdfHeader } from "components/pdf-order-summary/PdfHeader";
import { PdfFooter } from "components/pdf-order-summary/PdfFooter";

type ShoppingCartProps = {
  selectedProductId: number | undefined;
  submitOrder: () => void;
  isSubmitting: boolean;
  shipping?: types.fixed.ProductGroupShipping | undefined;
};

export function ShoppingCart({ selectedProductId, submitOrder, isSubmitting, shipping }: ShoppingCartProps) {
  const tvOverFwa = useFetchTvOverFwa();
  const selectedProduct = tvOverFwa.selectedAccount.data?.find((product) => product.productId === selectedProductId);

  if (!selectedProduct) {
    return null;
  }

  const { currentAmount, postCampaignAmount, chargePeriod } = selectedProduct.prices;
  const isDiscountRecurringPrice = currentAmount < postCampaignAmount;
  const { hasFreeRecording, hasFreeTeliaBox, hasTvAsInclusions } = getIncludedProducts(selectedProduct);

  return (
    <Container maxWidth="sm" id="tv-subscription-order-summary" flexDirection="column" gap="lg">
      <PdfHeader />
      <ShoppingSummary.Wrapper maxWidth="xs">
        <>
          <ShoppingSummary.List title="Månedspris">
            {(selectedProduct.productId === productId.tvPoints.p0 || hasTvAsInclusions) && (
              <ShoppingSummary.Item
                price={getSimplePriceText(currentAmount, chargePeriod)}
                additionalText={
                  isDiscountRecurringPrice ? `${getSimplePriceText(postCampaignAmount)} etter 12 måneder` : undefined
                }
                text={
                  selectedProduct.name?.length
                    ? `${format.startCase(selectedProduct.name)} med Telia Play`
                    : "Telia Play"
                }
              />
            )}
            {hasFreeTeliaBox && <ShoppingSummary.Item price={getSimplePriceText(0, "md.")} text="Telia Box" />}
            {hasFreeRecording && <ShoppingSummary.Item price={getSimplePriceText(0, "md.")} text="Opptak" />}
            <ShoppingSummary.Item
              price={getSimplePriceText(currentAmount, chargePeriod)}
              highlight
              text="Totalt per måned"
              secondaryText="Belastes på din neste faktura"
            />
          </ShoppingSummary.List>
          <ShoppingSummary.LineSeparator />
          <ShoppingSummary.List title="Engangspris">
            {shipping && (
              <ShoppingSummary.Item
                text="Frakt"
                secondaryText={shipping.description}
                price={getSimplePriceText(shipping.onceOffPrice)}
              />
            )}
            <ShoppingSummary.Item
              highlight
              text="Totalt engangsbeløp"
              secondaryText="Belastes på din neste faktura"
              price={getSimplePriceText(shipping?.onceOffPrice || 0)}
            />
          </ShoppingSummary.List>
          <ShoppingSummary.LineSeparator />
          <DownloadButton mr="auto" onClick={async () => openPdf("#tv-subscription-order-summary")} />
          <ShoppingSummary.ActionWrapper data-html2canvas-ignore>
            <Button
              trackEvent={{
                ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                ui_item_context: TRACK_EVENT.ITEM_CONTEXT.ORDER_TV,
                ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
                ui_item_url: uri.minSideFixed("/hjem"),
                ui_item_text: TRACK_EVENT.ITEM_TEXT.CANCEL,
              }}
              variant="tertiary-black"
              tag="a"
              href={uri.minSideFixed("/hjem")}
              isInternalNavigation
              hideLinkIcon
            >
              Avbryt
            </Button>
            <Button
              variant="purchase"
              trackEvent={{
                ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                ui_item_context: TRACK_EVENT.ITEM_CONTEXT.ORDER_TV,
                ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
                ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
                ui_item_text: `Fullfør bestilling (${selectedProduct.name} ${selectedProduct.prices.currentAmount})`,
              }}
              isLoading={isSubmitting}
              onClick={submitOrder}
            >
              Fullfør bestilling
            </Button>
          </ShoppingSummary.ActionWrapper>
        </>
      </ShoppingSummary.Wrapper>
      <PdfFooter />
    </Container>
  );
}
