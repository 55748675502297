import React from "react";
import { RouterSettingsProvider } from "./stores/RouterSettingsProvider";
import { WifiAdministrationWithStore } from "./WifiAdministrationWithStore";

export const WifiRouterAdmin = () => {
  return (
    <RouterSettingsProvider>
      <WifiAdministrationWithStore />
    </RouterSettingsProvider>
  );
};
