import { Button, ButtonTagProps } from "@telia-no-min-side/components";

type PayByCardButtonProps = {
  isLoading?: boolean;
} & Pick<ButtonTagProps, "onClick">;

export const PayByCardButton = ({ onClick, isLoading }: PayByCardButtonProps) => {
  return (
    <Button
      fullWidthSmallScreen
      isLoading={isLoading}
      loadingText="Behandler"
      icon="credit-card"
      onClick={onClick}
      fullWidth
    >
      Betal nå
    </Button>
  );
};
