import { GdprBusinessObject, GdprInput, GdprRequest } from "src/gql/graphql";

type GdprInputProps = {
  operation: GdprRequest;
  name: string;
  email: string;
  phoneNumber: string;
  pid?: string;
  businessObject?: GdprBusinessObject;
};

export function getGdprInput({ name, email, phoneNumber, pid, operation, businessObject }: GdprInputProps): GdprInput {
  const base = {
    name,
    email,
    phoneNumber,
    pid,
    operation,
  };

  switch (operation) {
    case GdprRequest.Portability:
      return {
        ...base,
        operationPayload: {
          portabilityRequested: [
            {
              businessObject: GdprBusinessObject.All,
              detailLevel: 1,
            },
          ],
        },
      };
    case GdprRequest.Access: {
      return {
        ...base,
        operationPayload: {
          accessRequested: [
            {
              businessObject,
              detailLevel: 1,
            },
          ],
        },
      };
    }
    case GdprRequest.Erasure:
      return {
        ...base,
        operationPayload: {
          erasureRequested: { reason: "" },
        },
      };
    default:
      return { ...base };
  }
}
