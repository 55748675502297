import { useQuery } from "@apollo/client";
import { Accordion, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { format, uri } from "@telia-no-min-side/utils";
import { useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { Error } from "../../../error";
import { recommendedKeySearchParam } from "../../mobile-subscription-change-redir";
import { OfferForSale } from "./OfferForSale";
import { CurrentSubscriptionAccordionItem } from "./CurrentSubscription";
import { SkeletonPage } from "../components/Skeleton";
import { NotLegalOwnerWarning } from "../components/NotLegalOwnerWarning";
import { SUBSCRIPTION_CHANGE_QUERY } from "../graphql/getChangeSubscription";
import { InsufficientAgeWarning } from "../components/InsufficientAgeWarning";
import { OngoingOrderWarning } from "../components/OngoingOrderWarning";
import { OfferGroup } from "../types";
import { OfferGroupPicker } from "../components/OfferGroupPicker";
import { getMbbOffersForSale, groupMbbOffersForSaleByCategory } from "./utils/offersForSale";
import { committedEndDate } from "util/format-subscription/isCommitted";
import { BindingTimeWarning } from "../components/BindingTimeWarning";

export function MbbSubscriptionChange() {
  const params = useParams<{ accountId?: string; phoneNumber?: string }>();
  const [offerType, setOfferType] = useState<OfferGroup>("unlimitedData");
  const [search] = useSearchParams();
  const preSelectPricePlan = search.get(recommendedKeySearchParam);
  const changeSubscription = useQuery(SUBSCRIPTION_CHANGE_QUERY, {
    variables: { accountId: params.accountId || "", phoneNumber: params.phoneNumber || "" },
    notifyOnNetworkStatusChange: true,
    skip: !params.accountId || !params.phoneNumber,
  });
  const canNotChangeAlertRef = useRef<HTMLDivElement>(null);
  const hasOnGoingOrder = useOrderSubscription().orders.some((order) => order.phoneNumber === params.phoneNumber);

  if (!params.accountId || !params.phoneNumber) {
    return <Error />;
  }

  if (changeSubscription.loading && !changeSubscription.data?.account.subscription) {
    return <SkeletonPage />;
  }

  if (!changeSubscription.data?.account.subscription) {
    return <Error />;
  }

  const { subscription: subscriptionToChange } = changeSubscription.data.account;
  const commitmentEndDate = committedEndDate(subscriptionToChange.commitmentEndDate);
  const hasBestSubscription = subscriptionToChange.newOfferingDetails?.offerings?.length === 0;

  const offersForSale = getMbbOffersForSale(subscriptionToChange);
  const {
    sleepModeOffers,
    bucketOffers,
    unlimitedDataOffers,
    hasBucketOffers,
    hasUnlimitedDataOffers,
    hasSleepModeOffers,
    hasAnyOffers,
    hasMultipleCategories,
  } = groupMbbOffersForSaleByCategory(offersForSale);

  const offerGroups: { text: string; value: OfferGroup; available: boolean }[] = [
    {
      text: "Ubegrenset data",
      value: "unlimitedData",
      available: hasUnlimitedDataOffers,
    },
    {
      text: "Fast data",
      value: "bucket",
      available: hasBucketOffers,
    },
    {
      text: "Hvilemodus",
      value: "sleepMode",
      available: hasSleepModeOffers,
    },
  ];
  const availableOfferGroups = offerGroups.filter((group) => group.available);

  return (
    <Container
      padding
      showGoBackButton
      goBackTo={preSelectPricePlan ? uri.minSideFixed("/hjem") : undefined}
      gap="lg"
      maxWidth="sm"
      flexDirection="column"
    >
      <NotLegalOwnerWarning subscription={subscriptionToChange} />
      {subscriptionToChange.productUser?.firstName?.length ? (
        <Heading tag="h2" variant="title-200-light" data-di-mask data-tracking-id="mobile-subscription-change-heading">
          Velg nytt abonnement for {format.startCase(subscriptionToChange.productUser.firstName)}
        </Heading>
      ) : null}
      {hasOnGoingOrder && (
        <div ref={canNotChangeAlertRef}>
          <OngoingOrderWarning />
        </div>
      )}

      {!hasAnyOffers && <InsufficientAgeWarning subscription={subscriptionToChange} />}

      <Container gap flexDirection="column" data-tracking-id="mobile-subscription-change-current-subscription">
        <Paragraph removeMargin>Endres fra</Paragraph>
        <Accordion>
          <CurrentSubscriptionAccordionItem offeringFragment={subscriptionToChange} />
        </Accordion>
      </Container>
      <Container gap flexDirection="column" data-tracking-id="mobile-subscription-change-pick-new-subscription">
        {hasAnyOffers && <Paragraph removeMargin>Velg nytt abonnement</Paragraph>}
        {hasMultipleCategories && (
          <OfferGroupPicker value={offerType} onChange={setOfferType} options={availableOfferGroups} />
        )}

        {!!commitmentEndDate?.length && (
          <BindingTimeWarning hasBestSubscription={hasBestSubscription} commitmentEndDate={commitmentEndDate} />
        )}

        {offerType === "unlimitedData" && (
          <Accordion gap="lg" data-tracking-id="mobile-subscription-change-offer-unlimitedData">
            {unlimitedDataOffers.map((offering) => (
              <OfferForSale
                key={`${offering.shortName}-change-unlimitedData-${offering.weight}`}
                offeringFragment={offering}
                canNotChangeAlertRef={canNotChangeAlertRef}
              />
            ))}
          </Accordion>
        )}
        {offerType === "bucket" && (
          <Accordion gap="lg" data-tracking-id="mobile-subscription-change-offer-bucket">
            {bucketOffers.map((offering) => (
              <OfferForSale
                key={`${offering.shortName}-change-bucket-${offering.weight}`}
                offeringFragment={offering}
                canNotChangeAlertRef={canNotChangeAlertRef}
              />
            ))}
          </Accordion>
        )}
        {offerType === "sleepMode" && (
          <Accordion gap="lg" data-tracking-id="mobile-subscription-change-offer-bucket">
            {sleepModeOffers.map((offering) => (
              <OfferForSale
                key={`${offering.shortName}-change-bucket-${offering.weight}`}
                offeringFragment={offering}
                canNotChangeAlertRef={canNotChangeAlertRef}
              />
            ))}
          </Accordion>
        )}
      </Container>
    </Container>
  );
}
