import { useState, useEffect } from "react";
import { AvtalegiroData, GenerateAvtalegiroLinkMutation } from "gql/graphql";
import { AvtalegiroDescription } from "./invoice-settings/components/Avtalegiro/AvtalegiroDescription";
import { useAvtalegiro } from "./invoice-settings/hooks/useAvtalegiro";
import { useSelectAccount } from "hooks/useSelectAccount";
import { SkeletonForm } from "pages/profile/common/SkeletonForm";
import { Container } from "@telia/teddy";

export const AvtalegiroPage = () => {
  const [avtalegiroLink, setAvtalegiroLink] = useState<AvtalegiroData>();
  const [avtalegiroError, setAvtalegiroError] = useState<string>();
  const { selectedAccountId } = useSelectAccount();
  const [, runAvtalegiro] = useAvtalegiro({
    onCompleted(data: GenerateAvtalegiroLinkMutation) {
      if (data?.avtalegiro) {
        setAvtalegiroLink(data.avtalegiro);
      }
    },
    onError(error) {
      setAvtalegiroError(error.message);
    },
  });

  const url = window.location.href;
  const query = new URL(url);
  const returnUrl = `${query.origin}/minside/faktura/innstillinger`;

  useEffect(() => {
    runAvtalegiro(selectedAccountId || "", returnUrl);
  }, []);

  return (
    <>
      {avtalegiroLink || avtalegiroError ? (
        <AvtalegiroDescription accountId={selectedAccountId || ""} data={avtalegiroLink} error={avtalegiroError} />
      ) : (
        <Container>
          <SkeletonForm />
        </Container>
      )}
    </>
  );
};
