import { useQuery } from "@apollo/client";
import { MasonaryContainer, MasonaryItem } from "components/masonary";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { PrepaidBalance } from "./PrepaidBalance";
import { DataUsage } from "./DataUsage";
import { Prices } from "./Prices";
import { CallBalance } from "./CallBalance";
import { GET_PREPAID_USAGES } from "../graphql/getPrepaidUsages";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

type Props = {
  isMBB?: boolean;
};

export function PrepaidUsageInfo({ isMBB = false }: Props) {
  const { phoneNumber } = useMobileAccount();
  const { loading, data } = useQuery(GET_PREPAID_USAGES, {
    variables: { phoneNumber },
  });
  const paymentBalanceAmount = data?.subscription.paymentBalance?.amount || 0;
  const topupLink = EXTERNAL_LINK.PREPAID_ORDER_TOPUPS;
  const prepaidCards = [
    {
      condition: true,
      component: (
        <PrepaidBalance
          loading={loading}
          balance={paymentBalanceAmount}
          usageData={data?.subscription.usage}
          usageTopupData={data?.subscription.usageTopup}
          topupLink={topupLink}
          isMBB={isMBB}
        />
      ),
    },
    {
      condition: true,
      component: <DataUsage loading={loading} usageTopupData={data?.subscription.usageTopup} topupLink={topupLink} />,
    },
    {
      condition: !isMBB,
      component: <CallBalance loading={loading} usageTopup={data?.subscription.usageTopup} />,
    },
    {
      condition: !isMBB,
      component: <Prices />,
    },
  ];

  return (
    <MasonaryContainer cols={2} gap={30}>
      {prepaidCards.map(
        ({ condition, component }) => condition && <MasonaryItem key={component.toString()}>{component}</MasonaryItem>
      )}
    </MasonaryContainer>
  );
}
