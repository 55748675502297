import { useEffect, useState } from "react";
import { Container, Heading } from "@telia-no-min-side/components";
import { useMobileAccount } from "hooks/useMobileAccount";
import { AgreementsProfilePicker } from "./components/AgreementsProfilePicker";
import { SubscriptionAgreements } from "./components/account/SubscriptionAgreements";
import { FaqAgreements } from "./components/faq/FaqAgreements";
import { AgreementType } from "gql/graphql";
import { SeeAllAgreements } from "./components/account/SeeAllAgreements";
import { AccountAgreementsProvider } from "./context/AccountAgreementsContext";
import { SeeAllForAccountAgreements } from "./components/account/SeeAllForAccountAgreements";

export const SEE_ALL = "Se alle";
export const AGREEMENT_WITHOUT_SUBSCRIPTION = "uten abonnement";

export function MobileSubscriptionAgreements() {
  const [seeAll, setSeeAll] = useState(false);
  const [seeAllForAccount, setSeeAllForAccount] = useState(false);
  const [showBanAgreementWithoutSub, setShowBanAgreementWithoutSub] = useState(false);

  const { allAccounts } = useMobileAccount();

  // No agreements for B2B customers
  const nonB2BAccounts = allAccounts?.filter((account) => account.agreementType === AgreementType.Private);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AccountAgreementsProvider shouldSkipQuery={seeAll}>
      <Container flexDirection="column" gap="lg" padding>
        <Heading tag="h2" variant="title-300">
          Dine avtaler
        </Heading>

        <AgreementsProfilePicker
          nonB2BAccounts={nonB2BAccounts}
          seeAll={seeAll}
          setSeeAll={setSeeAll}
          seeAllForAccount={seeAllForAccount}
          setSeeAllForAccount={setSeeAllForAccount}
          showBanAgreementWithoutSub={showBanAgreementWithoutSub}
          setShowBanAgreementWithoutSub={setShowBanAgreementWithoutSub}
        />

        {seeAll ? (
          <SeeAllAgreements seeAll={seeAll} />
        ) : seeAllForAccount ? (
          <SeeAllForAccountAgreements />
        ) : (
          <SubscriptionAgreements showBanAgreementWithoutSub={showBanAgreementWithoutSub} />
        )}

        <FaqAgreements />
      </Container>
    </AccountAgreementsProvider>
  );
}
