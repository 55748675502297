import {
  Button,
  Card,
  Container,
  Heading,
  Icon,
  LineSeparator,
  Paragraph,
  useToast,
} from "@telia-no-min-side/components";
import { Lozenge } from "@telia/styleguide";
import { ReactNode, ComponentProps } from "react";
import { InvoicePaymentStatus, BillingCycles, PaymentMethodName, InvoiceDistribution } from "gql/graphql";
import { getPriceAmountText } from "src/util/priceFormatter";
import { ActionMenu } from "../ActionMenu";
import { InvoiceActions } from "./InvoiceActions";
import { AdditionalInfoContainer } from "../styles";
import { config } from "util/config";

type Props = {
  accountId: string;
  kidNumber: string;
  currentInvoiceAmount: number;
  distribution?: string;
  isMobileAccount?: boolean;
  bankAccountNumber: string;
  paymentDate: string;
  nextInvoiceDate?: string;
  invoiceId: string;
  paymentOverdue: boolean;
  setModalOpen: (value: boolean) => void;
  children?: ReactNode;
  invoiceCardPaymentStatus?: InvoicePaymentStatus;
  billingCyclesExist?: boolean;
  orderedBillingCycle?: Partial<BillingCycles["orderedBillCycle"]>;
  canChangeBillingCycle?: boolean;
  hasChangedBillingCycle?: boolean;
  currentBillingCycleDueDay?: number | null;
  paymentMethodName?: PaymentMethodName | null;
  invoiceDistributionType?: InvoiceDistribution;
  isPostponingDisabled?: boolean;
};

export function CurrentInvoiceCard(props: Props) {
  const {
    accountId,
    kidNumber,
    currentInvoiceAmount,
    distribution,
    isMobileAccount,
    bankAccountNumber,
    paymentDate,
    nextInvoiceDate,
    invoiceId,
    paymentOverdue,
    setModalOpen,
    children,
    invoiceCardPaymentStatus,
    billingCyclesExist,
    orderedBillingCycle,
    canChangeBillingCycle,
    hasChangedBillingCycle,
    currentBillingCycleDueDay,
    paymentMethodName,
    invoiceDistributionType,
    isPostponingDisabled,
  } = props;

  const { addToast } = useToast();
  const dueDay = hasChangedBillingCycle ? orderedBillingCycle?.dueDay : currentBillingCycleDueDay;
  const getLozengeByInvoiceStatus = (): ComponentProps<typeof Lozenge> => {
    if (
      invoiceCardPaymentStatus === InvoicePaymentStatus.Delivered ||
      invoiceCardPaymentStatus === InvoicePaymentStatus.Reserved
    ) {
      return { label: "Betaling registrert", status: "info", icon: "time-waiting" };
    }
    if (paymentOverdue) {
      return { label: "Forfalt", status: "warning" };
    }
    if (paymentMethodName === PaymentMethodName.Avtalegiro) {
      return { label: "Betales med AvtaleGiro", status: "info" };
    }
    if (invoiceDistributionType === InvoiceDistribution.EFaktura) {
      return { label: "Betales med eFaktura", status: "info" };
    }
    return { label: "Ubetalt", status: "info" };
  };

  const handleCopyClick = (copyInput: string, tostText: string) => {
    navigator.clipboard
      .writeText(copyInput)
      .then(() => {
        addToast({ text: `${tostText} kopiert til utklippstavlen`, variant: "success" });
      })
      .catch(() => {
        addToast({ text: `Kunne ikke kopiere til utklippstavlen`, variant: "error" });
      });
  };

  return (
    <Card padding="lg" lozengeRight={getLozengeByInvoiceStatus()}>
      <Container flexDirection="column" gap>
        <Container flexDirection="row" justifyContent="space-between">
          <Heading variant="title-200-light" tag="h3">
            {isMobileAccount ? "Faktura for mobil" : "Faktura for TV/internett"}
          </Heading>
          {isMobileAccount && <ActionMenu canChangeBillingCycle={canChangeBillingCycle} />}
        </Container>
        <Container flexDirection="row" justifyContent="space-between">
          <Paragraph removeMargin variant="preamble-100">
            Utestående beløp:
          </Paragraph>
          <Paragraph variant="preamble-100">{getPriceAmountText(currentInvoiceAmount, "kr")}</Paragraph>
        </Container>
        {billingCyclesExist && (
          <>
            <LineSeparator />
            <Container flexDirection="row" justifyContent="space-between" flexWrap gap="sm">
              <Container flexDirection="column" width="unset">
                <Paragraph variant="additional-100">Til konto:</Paragraph>
                <Container flexDirection="row" alignItems="center">
                  <Paragraph variant="additional-100" isFaded removeMargin>
                    {bankAccountNumber}
                  </Paragraph>
                  <Button
                    title="Kopier Kontonummer"
                    size="sm"
                    isIconButton
                    iconSize="sm"
                    variant="tertiary-black"
                    icon="copy"
                    onClick={() => handleCopyClick(bankAccountNumber.replace(/\s+/g, ""), "Kontonummer")}
                  />
                </Container>
              </Container>

              <Container flexDirection="column" width="unset">
                <Paragraph variant="additional-100">Ditt KID-nummer: </Paragraph>
                <Container flexDirection="row" alignItems="center">
                  <Paragraph variant="additional-100" isFaded removeMargin>
                    {kidNumber}
                  </Paragraph>
                  <Button
                    title="Kopier KID"
                    size="sm"
                    isIconButton
                    iconSize="sm"
                    variant="tertiary-black"
                    icon="copy"
                    onClick={() => handleCopyClick(kidNumber, "KID-nummer")}
                  />
                </Container>
              </Container>

              <Container flexDirection="column" width="unset">
                <Paragraph variant="additional-100">Forfallsdato:</Paragraph>
                <Container flexDirection="row" alignItems="center" flexGrow={1}>
                  <Paragraph variant="additional-100" isFaded removeMargin>
                    {paymentDate}
                  </Paragraph>
                </Container>
              </Container>
            </Container>
          </>
        )}
        <LineSeparator />

        <AdditionalInfoContainer floatContent="center" flexDirection="row" gap flexGrow={1}>
          <Container flexDirection="row" alignItems="center" gap flexGrow={1}>
            <Icon size="sm" icon="user" />
            <Paragraph removeMargin isFaded>
              Kundenummer: <em>{accountId}</em>
            </Paragraph>
          </Container>
        </AdditionalInfoContainer>

        <AdditionalInfoContainer floatContent="center" flexDirection="row" gap flexGrow={1}>
          <Container flexDirection="row" alignItems="center" gap flexGrow={1}>
            <Icon size="sm" icon="invoice" />
            <Paragraph removeMargin isFaded>
              Fakturatype: <em>{distribution}</em>
            </Paragraph>
          </Container>
          {isMobileAccount && (
            <Button hideLinkIcon variant="text-purple" size="sm" tag="a" href="/minside/faktura/innstillinger">
              Endre
            </Button>
          )}
        </AdditionalInfoContainer>

        {nextInvoiceDate && (
          <AdditionalInfoContainer floatContent="center" flexDirection="row" flexGrow={1} gap>
            <Icon size="sm" icon="calendar" />
            <Paragraph removeMargin isFaded>
              Neste faktura kommer
            </Paragraph>
            <Paragraph isFaded>
              <em>{nextInvoiceDate}</em>
            </Paragraph>
          </AdditionalInfoContainer>
        )}

        {isMobileAccount && !config.disableBillingCycleChange && (
          <AdditionalInfoContainer floatContent="center" flexDirection="row" gap flexGrow={1}>
            <Container flexDirection="row" alignItems="center" gap flexGrow={1}>
              <Icon size="sm" icon="money-back" />
              <Paragraph removeMargin isFaded>
                Forfallsdato: <em>{dueDay}. hver måned</em>
              </Paragraph>
            </Container>
            {canChangeBillingCycle && (
              <Button
                hideLinkIcon
                variant="text-purple"
                size="sm"
                tag="a"
                isInternalNavigation
                href={"/minside/faktura/forfallsdato"}
              >
                Endre
              </Button>
            )}
          </AdditionalInfoContainer>
        )}
        <InvoiceActions
          invoiceId={invoiceId}
          isMobileAccount={!!isMobileAccount}
          isPostponingDisabled={isPostponingDisabled}
          setPostponeInvoiceModalOpen={setModalOpen}
        >
          {children}
        </InvoiceActions>
      </Container>
    </Card>
  );
}
