import { useQuery } from "@apollo/client";
import { Container, Skeleton } from "@telia-no-min-side/components";
import { graphql } from "src/gql";
import { useParams } from "react-router-dom";

import { AddCommitment } from "./components/AddCommitment";
export const SUBSCRIPTION_COMMITMENT_QUERY = graphql(`
  query getSubscriptionCommitmentOfferings($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      productUser {
        firstName
        surname
      }
      account {
        id
      }
      phoneNumber {
        localNumber
      }
      userOffering {
        commitmentTimeInMonths
        name
        shortName
        pricePlan
        originalPrice {
          code
          description
          amount
          monetaryUnit
          amountWithoutVat
        }
      }
      newOfferingDetails {
        offerings {
          name
          shortName
          pricePlan
          commitmentTimeInMonths
          price {
            offeringPrices {
              code
              description
              amount
              monetaryUnit
              amountWithoutVat
            }
          }
          originalPrice {
            code
            description
            amount
            monetaryUnit
            amountWithoutVat
          }
        }
      }
    }
  }
`);

export function MobileSubscriptionAddCommitment() {
  const params = useParams<{ accountId?: string; phoneNumber?: string }>();

  const { loading: offeringsLoading, data: offeringsData } = useQuery(SUBSCRIPTION_COMMITMENT_QUERY, {
    variables: {
      phoneNumber: params.phoneNumber || "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <Container flexDirection="column" padding="horizontal" gap="lg" maxWidth="full">
      {offeringsLoading && !offeringsData && (
        <Container padding>
          <br />
          <br />
          <Container flexDirection="column" gap padding="top" maxWidth="sm">
            <Skeleton variant="heading-m" width="40em" />
            <Skeleton variant="text" width="25em" />
            <Skeleton variant="rectangular" width="20em" height="8em" />
          </Container>
        </Container>
      )}

      {offeringsData && params.phoneNumber && (
        <AddCommitment
          phoneNumber={params.phoneNumber}
          subscription={offeringsData.subscription}
          accountId={offeringsData.subscription.account.id}
        />
      )}
    </Container>
  );
}
