import { Alert, Button, Container, Heading, Paragraph, Skeleton } from "@telia-no-min-side/components";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerCareLink } from "src/components/customer-care-link";
import { CanNotCancelReason } from "./CanNotCancelReason";
import { useFetchCancelAgreement, usePostCancelAgreement } from "./fetcher";

export function CancelAgreement(): JSX.Element {
  const { agreementDetailId } = useParams();
  const cancelAgreement = useFetchCancelAgreement({ agreementDetailId });
  const cancelAgreementPost = usePostCancelAgreement({ agreementDetailId });
  const navigate = useNavigate();
  const productToCancel = cancelAgreement.data?.productTitle.length ? cancelAgreement.data.productTitle : "tjeneste";

  if (cancelAgreementPost.isError) {
    return (
      <Container padding showGoBackButton maxWidth="sm">
        <Heading tag="h2" variant="title-300">
          Avbestilling av {productToCancel}
        </Heading>
        <Paragraph>Beklager! Vi klarte dessverre ikke gjennomføre avbestilling</Paragraph>
        <Paragraph>Vennligst prøv igjen litt senere. Eller ring kundeservice</Paragraph>
        <CustomerCareLink trackingContext="cancel-agreement" />
        <Container gap padding="vertical" justifyContent="flex-end" flexDirection="row">
          <Button variant="secondary" onClick={() => navigate(-1)}>
            Avbryt
          </Button>
          <Button isLoading={cancelAgreementPost.isLoading} onClick={() => cancelAgreementPost.post()}>
            Prøv igjen
          </Button>
        </Container>
      </Container>
    );
  }

  if (cancelAgreement.isLoading && !cancelAgreement.data) {
    return (
      <Container padding showGoBackButton maxWidth="sm">
        <Skeleton variant="heading-m" width="10ch" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Container>
    );
  }
  if ((cancelAgreement.error && !cancelAgreement.data) || !agreementDetailId) {
    return (
      <Container padding showGoBackButton maxWidth="sm">
        <Heading tag="h2" variant="title-300">
          Avbestilling av {productToCancel}
        </Heading>
        <Paragraph>Beklager! Vi klarte dessverre ikke hente nødvendig informasjon</Paragraph>
        <Paragraph>Vennligst prøv igjen litt senere. Eller ring kundeservice</Paragraph>
        <CustomerCareLink trackingContext="cancel-agreement" />
        <Container gap padding="vertical" justifyContent="flex-end" flexDirection="row">
          <Button variant="secondary" onClick={() => navigate(-1)}>
            Avbryt
          </Button>
          <Button isLoading={cancelAgreement.isValidating} onClick={() => cancelAgreement.mutate()}>
            Prøv igjen
          </Button>
        </Container>
      </Container>
    );
  }

  const isRecording = cancelAgreement.data?.productTitle === "Opptak";
  return (
    <Container padding showGoBackButton maxWidth="sm">
      <Heading tag="h2" variant="title-300">
        Avbestilling av {productToCancel}
      </Heading>
      {cancelAgreement.data?.canCancel ? (
        <>
          {isRecording && (
            <Container padding="vertical">
              <Alert kind="info" isOpen canClose={false} title="Opptakene dine slettes" icon="info-filled">
                Merk at opptakene dine slettes 3 måneder etter avbestilling, med mindre du bestiller Opptak igjen før
                det har gått 3 måneder.
              </Alert>
            </Container>
          )}
          <Paragraph>
            Avbestillingen vil tre i kraft etter neste måned ({cancelAgreement.data.cancellationDate})
          </Paragraph>
          <Container padding="vertical" gap justifyContent="flex-end" flexDirection="row">
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Avbryt
            </Button>
            <Button isLoading={cancelAgreementPost.isLoading} onClick={() => cancelAgreementPost.post()}>
              Bekreft avbestilling
            </Button>
          </Container>
        </>
      ) : (
        <>
          <CanNotCancelReason agreementDetailId={agreementDetailId} />
          <Container padding="vertical" justifyContent="flex-end" flexDirection="row">
            <Button onClick={() => navigate(-1)}>Jeg skjønner</Button>
          </Container>
        </>
      )}
    </Container>
  );
}
