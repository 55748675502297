export enum EXTERNAL_LINK {
  HELP = "https://help.no/",
  STREX = "https://strex.no/",
  TELIA_SKY = "https://sky.telia.no/",
  TELIA_PLAY = "https://teliaplay.no/",
  TELIA_PLAY_MAX = "https://www.telia.no/tv/max/?tab=4",
  TELIA_PLAY_BOX_CONNECTION = "https://www.telia.no/kundeservice/tv/oppkobling-tv/oppkobling-telia-play-box/",
  TELIA_PLAY_CHANNELPICKER = "https://account.teliaplay.no/subscription/selection-menu",
  MINSIDE_CHANNELPICKER = "https://www.telia.no/minside/valgmenyen",
  TV_HELP_PAGES = "https://www.telia.no/tv/hjelp/",
  BUSINESS_NETWORK = "https://sb.telia.no/",
  MINSIDE_STREX = "https://minside.strex.no/",
  PRIVACY = "https://www.telia.no/personvern/",
  ROAMING_ZONES = "https://www.telia.no/globalassets/pdf/priser/nye-soner-utenfor-eu.pdf",
  TELIA_TRYGG_PORTAL = "https://safeavenue.f-secure.com/iframe/-sso/telianorway",
  ADDITIONAL_SERVICES_STORYTEL = "https://www.telia.no/mobilabonnement/tilleggstjenester/storytel/",
  FILM_RENTALS_AND_RECORDINGS = "https://www.telia.no/kundeservice/tv/innhold-tv/opptak/tv-boks/",
  DEBT_COLLECTION_AGENCY = "https://minsak.sergel.no",
  BEFORE_SWITCH_PHONE_TERMS = "https://www.telia.no/mobiltelefoner/svitsj/bytte/",
  VIPS_PAYMENT_TERMS = "https://www.nets.eu/no-nb/losninger/efaktura/vipps-efaktura/Pages/default.aspx",
  APP_STORE_TELIA_SKY = "https://itunes.apple.com/app/apple-store/id478820780?mt=8",
  APP_STORE_TOPUPS = "https://apps.apple.com/no/app/telia-norway/id382824774?amp%3Bmt=8&l=nb",
  GOOGLE_PLAY_TELIA_SKY = "https://play.google.com/store/apps/details?id=no.get.safe.jottacloud.android.client",
  GOOGLE_PLAY_TOPUPS = "https://play.google.com/store/apps/details?id=mobi.mobiletech.apps.android.netcom&pli=1",
  SAFE_AVENUE_SSO = "https://safeavenue.f-secure.com/iframe/-sso/get",
  AVTALEGIRO_PRIVATE = "https://www.avtalegiro.no/Privat",
  E_FAKTURA_PRIVATE = "https://www.efaktura.no/privat",
  CUSTOMER_SERVICE_TV = "https://www.telia.no/kundeservice/tv/",
  CUSTOMER_SERVICE_MOBILE = "https://www.telia.no/kundeservice/mobil/",
  CUSTOMER_SERVICE_INTERNET = "https://www.telia.no/kundeservice/internett/",
  CUSTOMER_SERVICE_CONTACT_US = "https://www.telia.no/kundeservice/kontakt-oss",
  CUSTOMER_SERVICE_MOBILE_SERVICES = "https://www.telia.no/kundeservice/mobil/innholdstjenester/",
  CUSTOMER_SERVICE_TERMS_AND_CONDITIONS = "https://www.telia.no/kundeservice/vilkar-og-angrerett",
  CUSTOMER_SERVICE_STORES_AND_DEALERS = "https://www.telia.no/kundeservice/butikker-og-forhandlere/?filter=offersPickUpAndReturnHelp",
  CUSTOMER_SERVICE_RETURN_EQUIPMENT = "https://www.telia.no/kundeservice/retur-av-utstyr/",
  CUSTOMER_SERVICE_NOT_RETURNABLE_EQUIPMENT = "https://www.telia.no/kundeservice/flytting/flytte-enebolig/utstyr-som-ikke-skal-returneres/",
  INTERNET_HOME = "https://www.telia.no/internett/#sok",
  INTERNET_WIFI = "https://www.telia.no/internett/wifix/",
  INTERNET_SAFE_ON_NET = "https://www.telia.no/internett/trygg-pa-nett/",
  INTERNET_EQUIPMENT = "https://www.telia.no/minside/internett/utstyr",
  INTERNET_HELP = "https://www.telia.no/internett/hjelp/",
  INTERNET_MOBILE_BROADBAND = "https://www.telia.no/internett/mobilt-bredband/",
  INTERNET_FIXED_BRODBAND = "https://www.telia.no/internett/bredband/#sok",
  MOBILE_HELP = "https://www.telia.no/mobilabonnement/hjelp/",
  PREPAID_ORDER_TOPUPS = "https://www.telia.no/mobilabonnement/kontantkort/lade/",
}
