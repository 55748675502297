import { useNavigate } from "react-router-dom";
import { uri } from "@telia-no-min-side/utils";
import { Flex, Card, Text, Icon, Badge, color } from "@telia/teddy";
import { FragmentType, getFragmentData } from "../../../../gql";
import { AccountData, SwitchInsuranceType, SwitchStatus } from "../../../../gql/graphql";
import { switchAgreementFragment } from "../graphql/switchAgreementFragment";
import { INSURANCE_TYPE } from "util/constants/agreements";
import { useMobileAccount } from "hooks/useMobileAccount";
import { truncateText } from "../utils/truncateText";
import { capitalizeFirstLetter } from "util/capitalizeFirstLetter";

type Props = {
  switchAgreement: FragmentType<typeof switchAgreementFragment>;
  accountId: AccountData["id"];
};

export function SwitchAgreementCard({ switchAgreement, accountId }: Props) {
  const navigate = useNavigate();
  const { setPhoneNumber, setAccountId } = useMobileAccount();

  const {
    product,
    phoneNumber,
    canPerformSwitch,
    insurance,
    remainingMandatoryInstallments,
    id: agreementId,
    status,
  } = getFragmentData(switchAgreementFragment, switchAgreement);

  const phoneModel = product?.name || "";
  const truncatedPhoneModel = truncateText(phoneModel, 35);
  const isInsuranceExist = !!insurance?.type;
  const isScreenInsurance = insurance?.type === SwitchInsuranceType.Screen;
  const isImageAvailable = !!product?.imageUrl;
  const canSwitchInstantly = canPerformSwitch && remainingMandatoryInstallments <= 0;

  const handleClick = () => {
    if (phoneNumber?.localNumber) {
      setPhoneNumber(phoneNumber?.localNumber);
    }
    setAccountId(accountId);
    navigate(
      uri.minSideMobile(canSwitchInstantly ? `/avtaler/svitsj/evaluering/${accountId}/${agreementId}` : "/avtaler")
    );
  };

  return (
    <Card
      shadow
      bordered
      width="100%"
      maxWidth="400px"
      layout="default"
      variant="white"
      data-tracking-id={`switch-agreement-${agreementId}`}
    >
      {(status === SwitchStatus.Pending || status === SwitchStatus.Hold) && (
        <Card.Slot align="top-left">
          <Badge variant="information" hideIcon>
            <Icon name="delivery" />
            Svitsjing pågår!
          </Badge>
        </Card.Slot>
      )}

      {canSwitchInstantly && (
        <Card.Slot align="top-right">
          <Badge variant="special" hideIcon>
            Du kan svitsje kostnadsfritt!
          </Badge>
        </Card.Slot>
      )}

      <Card.Heading as="h3" variant="title-100">
        <Flex direction="row" justify="between" align="center">
          Svitsj-avtale
          {phoneNumber?.localNumber && (
            <Text variant="paragraph-100-bold" data-di-mask>
              {phoneNumber?.localNumber}
            </Text>
          )}
        </Flex>
      </Card.Heading>

      <Card.Content>
        <Flex direction={isImageAvailable ? "row" : "column"} gap="150" width="100%">
          {isImageAvailable && <img src={`https:${product?.imageUrl}`} alt="product-image" width="70px" />}
          <Flex direction="column" gap="100" width="100%">
            <Text>{isImageAvailable ? truncatedPhoneModel : phoneModel}</Text>
            {isInsuranceExist && (
              <Flex>
                <Icon name="checkmark" size="sm" color={color.teddyColorPurple600}>
                  {capitalizeFirstLetter(isScreenInsurance ? INSURANCE_TYPE.SCREEN : INSURANCE_TYPE.PLUS)}
                </Icon>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Card.Content>

      <Card.Footer height="100%" align="end">
        <Card.Button variant="text" onClick={handleClick}>
          {canSwitchInstantly ? "Svitsj nå" : "Se avtale"}
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
