import { useState } from "react";
import { ActionMenuButton, ActionPoint } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";

type ActionMenuProps = {
  canChangeBillingCycle?: boolean;
};

export const ActionMenu = ({ canChangeBillingCycle }: ActionMenuProps) => {
  const [isActionVisible, setIsActionVisible] = useState(false);

  const actionList = [
    {
      href: uri.minSideFixed("/faktura/utland"),
      icon: "credit-card",
      kind: "link",
      text: "Betal med utenlandsk konto",
      isInternalNavigation: true,
    },
    ...(canChangeBillingCycle
      ? [
          {
            href: uri.minSideFixed("/faktura/forfallsdato"),
            icon: "money-back",
            kind: "link",
            text: "Bytt forfallsdato",
            isInternalNavigation: true,
          } as const,
        ]
      : []),
  ] satisfies ActionPoint[];

  return (
    <ActionMenuButton
      showActions={isActionVisible}
      actionList={actionList}
      toggleShowActions={() => setIsActionVisible(!isActionVisible)}
    />
  );
};
