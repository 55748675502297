import { useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import { Button, Modal } from "@telia/teddy";
import { useApolloClient } from "@apollo/client";
import { useToast } from "@telia-no-min-side/components";
import { AdditionalSimCard } from "gql/graphql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { getErrorMessage, getSimCardProductName } from "../../utils";
import { useTerminateSimCard } from "../../hooks/useTerminateSimCard";
import { ModalType } from "../AdditionalSimCard";

type Props = {
  phoneNumber: string;
  simCard: AdditionalSimCard;
  simCardProductName: string;
  setModalType: Dispatch<SetStateAction<ModalType | undefined>>;
};

export function TerminateSimCardModal({ phoneNumber, simCard, simCardProductName, setModalType }: Props) {
  const [terminatedSimCard, setTerminatedSimCard] = useState("");

  const client = useApolloClient();
  const { addToast } = useToast();
  const { subscribeToOrder } = useOrderSubscription();

  const { response: terminateSimResponse, runTerminateSimCard } = useTerminateSimCard({
    onCompleted(data) {
      const orderId = data.order.orderId;

      setModalType(undefined);
      addToast({
        text: "Det vil ta et par minutter før endringen blir registrert.",
        variant: "success",
      });

      subscribeToOrder({
        id: orderId,
        toastText: `${getSimCardProductName(terminatedSimCard)} er avbestilt og deaktivert`,
        phoneNumber,
        simCardNumber: simCard.simCardNumber ?? "",
        onOrderCompleted: async () => {
          // invalidate selected subscription in cache and refetch from the network
          // in order to get fresh mainSimcard, additionalSimcards, additionalProducts data
          await client.refetchQueries({
            updateCache(cache) {
              const args = { phoneNumber };
              cache.evict({ fieldName: "subscription", args });
            },
          });
        },
      });
    },
    onError(error) {
      addToast({
        text: `Beklager, avbestillingen feilet. ${getErrorMessage(error.message)}`,
        variant: "error",
      });
      setModalType(undefined);
    },
  });

  const handleClose = () => setModalType(undefined);

  const handleClick = () => {
    if (simCard?.product?.code && simCard?.product?.name) {
      runTerminateSimCard({
        phoneNumber: phoneNumber,
        productCode: simCard?.product?.code,
      });
      setTerminatedSimCard(simCard?.product?.name);
    }
  };

  return (
    <Modal open>
      <Modal.Overlay>
        <Modal.Content>
          <Modal.Group variant="title">
            <Modal.Title as="h3">Avbestille SIM</Modal.Title>
          </Modal.Group>
          <Modal.Description data-di-mask>
            {`Er du sikker på at du vil avbestille ${simCardProductName}?`}
            <br />
            SIM-kortet vil umiddelbart bli deaktivert.
          </Modal.Description>
          <Modal.Group variant="action">
            <Modal.Close slot="content">
              <Button variant="text" onClick={handleClose}>
                Avbryt
              </Button>
            </Modal.Close>
            <Button loading={terminateSimResponse.loading} onClick={handleClick}>
              Ja, avbestill
            </Button>
          </Modal.Group>
        </Modal.Content>
      </Modal.Overlay>
    </Modal>
  );
}
