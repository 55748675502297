import { Container, Skeleton } from "@telia-no-min-side/components";

export function SwitchLoading() {
  return (
    <Container maxWidth="sm">
      <Skeleton variant="heading-m" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </Container>
  );
}
