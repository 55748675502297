import { useState, useEffect } from "react";
import { Icon, toast } from "@telia/teddy";
import { getTopupDisplayInfo } from "./utils";
import { ConfirmPaymentStatus } from "gql/graphql";
import { TopupModalView } from "./components/TopupModalView";
import { useAdditionalProducts } from "hooks/useAdditionalProducts";
import { useMutationResponse } from "hooks/useMutationResponse";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";

export function TopupModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { orderResponse } = useAdditionalProducts();
  const { selectedTopup, orderTopup, paymentStatus, purchaseError, closeDrawerResetTopup } =
    useMobileDashboardContext();

  const {
    mutationResponseMessage: { isSuccessfulMutation, text, title },
    setMutationResponseMessage,
  } = useMutationResponse();

  useEffect(() => {
    setIsModalOpen(!!selectedTopup || !!isSuccessfulMutation || !!purchaseError || !!paymentStatus);
  }, [selectedTopup, isSuccessfulMutation, purchaseError, paymentStatus]);

  // Edge case: Show modal after returning from a 3rd party payment page (e.g., VIPPS),
  useEffect(() => {
    if (purchaseError || paymentStatus === ConfirmPaymentStatus.Failed) {
      toast.error("Her gikk noe galt!", {
        icon: <Icon name="error-filled" size="sm" />,
        description:
          "Vi kunne dessverre ikke behandle forespørselen din og fikk heller ikke opprettet en manuell sak for oppfølging. Vennligst prøv igjen litt senere.",
      });
      setIsModalOpen(false);
      closeDrawerResetTopup();
    }
  }, [purchaseError, paymentStatus]);

  useEffect(() => {
    if (isSuccessfulMutation || paymentStatus === ConfirmPaymentStatus.Ok) {
      title === "NORDIC_BALTIC_MINUTES"
        ? toast.success(text)
        : toast.success("Bestilling utført!", {
            icon: <Icon name="checkmark" size="sm" />,
            description: "Din tjeneste er aktivert.",
          });

      setMutationResponseMessage({ isSuccessfulMutation: false, title: "", text: "" });
      setIsModalOpen(false);
    }
  }, [isSuccessfulMutation, paymentStatus]);

  const closeModalResetState = () => {
    setIsModalOpen(false);
    closeDrawerResetTopup();
  };

  if (selectedTopup) {
    const topupDisplayInfo = getTopupDisplayInfo(selectedTopup);
    const topupInfo = `${topupDisplayInfo.size} ${topupDisplayInfo.sizeUnit}`;

    return (
      <TopupModalView
        isOpen={isModalOpen}
        closeModal={closeModalResetState}
        heading={`Du har valgt ${topupInfo} for ${topupDisplayInfo.price},-`}
        description="Denne tjenesten vil aktiveres umiddelbart."
        leftButtonText="Avbryt"
        leftButtonClick={closeModalResetState}
        rightButtonText={topupDisplayInfo.numberOfVouchers ? "Aktiver" : "Kjøp"}
        rightButtonClick={() => orderTopup(selectedTopup)}
        isLoading={orderResponse?.loading}
      />
    );
  }

  return null;
}
