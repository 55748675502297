import { Card, Container, Paragraph } from "@telia-no-min-side/components";
import { CSSProperties } from "react";

export function TopCard({ style }: { style?: CSSProperties }) {
  return (
    <Card maxWidth="sm" removeMinHeight style={style}>
      <Container alignItems="center" justifyContent="space-between" flexDirection="row" gap="lg">
        <Container flexDirection="column" gap>
          <Paragraph removeMargin isFaded>
            <i>Dekkes av arbeidsgiver</i>
          </Paragraph>
          <Container flexDirection="row" gap="lg" alignItems="center" flexWrap width="unset">
            <Container gap width="unset">
              <Paragraph variant="preamble-100" removeMargin>
                <em>Bedriftsabonnement</em>
              </Paragraph>
            </Container>
          </Container>
        </Container>
      </Container>
    </Card>
  );
}
