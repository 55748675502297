import { useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { Card, Drawer } from "@telia/teddy";
import { notEmpty } from "@telia-no-min-side/utils";
import { useMobileAccount } from "hooks/useMobileAccount";
import { ROAMING_RESTRICTION_CODE } from "util/constants/topups";
import { GET_COUNTRIES, GET_ROAMING_RESTRICTION } from "../../graphql";
import { RoamingLoading, Error, RoamingRestriction, CountrySearch } from "./components";
import { useMobileDashboardContext } from "pages/mobile/mobile-subscription-dashboard/context/MobileDashboardContext";

export function Roaming() {
  const { phoneNumber } = useMobileAccount();
  const [isRoamingBlocked, setIsRoamingBlocked] = useState(false);
  const { isB2B, isLegalOwner } = useMobileDashboardContext();

  const getCountriesQuery = useQuery(GET_COUNTRIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const getRoamingRestrictionQuery = useQuery(GET_ROAMING_RESTRICTION, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const error = getCountriesQuery.error || getRoamingRestrictionQuery.error;
  const loading = getCountriesQuery.loading || getRoamingRestrictionQuery.loading;

  const refetchAll = useCallback(() => {
    getCountriesQuery.refetch();
    getRoamingRestrictionQuery.refetch();
  }, [getCountriesQuery.refetch, getRoamingRestrictionQuery.refetch]);

  const subscription = getRoamingRestrictionQuery.data?.subscription;
  const additionalProducts = subscription?.additionalProducts;

  const canRemoveRoamingRestriction = isLegalOwner || isB2B;
  const roamingCountries = getCountriesQuery.data?.countries?.filter(notEmpty) || [];

  useEffect(() => {
    const roamingProduct = additionalProducts?.find((group) => group?.code === ROAMING_RESTRICTION_CODE);
    setIsRoamingBlocked(!!roamingProduct?.presentation?.active);
  }, [additionalProducts]);

  if (loading) return <RoamingLoading />;
  if (error) return <Error refetch={refetchAll} />;

  return (
    <>
      <Drawer.Close aria-label="Close" slot="floating" />
      <Drawer.Title variant="title-200">Datapakker utenfor EU, EØS, Sveits, Storbritannia</Drawer.Title>
      <Card.Line />
      <Drawer.Description>
        Datapakker varer fra kjøp og frem dataen er brukt opp, eller til pakken utløper. Velg den varigheten på pakken
        som passer best for deg.
      </Drawer.Description>

      {isRoamingBlocked && (
        <RoamingRestriction
          setIsRoamingBlocked={setIsRoamingBlocked}
          canRemoveRoamingRestriction={canRemoveRoamingRestriction}
        />
      )}

      {!isRoamingBlocked && <CountrySearch roamingCountries={roamingCountries} />}
    </>
  );
}
