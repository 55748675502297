import { graphql } from "gql";

export const SUBSCRIPTION_FRAGMENT = graphql(`
  fragment SubscriptionCardWithDetailsFragment on SubscriptionData {
    phoneNumber {
      localNumber
    }
    billingType
    additionalProducts {
      id
      code
      productGroup
      productCategory
      name
      productTerms {
        code
        amount
      }
    }
    userOffering {
      pricePlan
      additionalProperties {
        name
        value
      }
      originalPrice {
        amount
      }
      monthlyCost {
        amount
      }
      offeringPrices {
        offeringPrices {
          code
          amount
          monetaryUnit
        }
      }
      name
      shortName
    }
    subscriptionRank
    productUser {
      firstName
    }
    commitmentEndDate
  }
`);
