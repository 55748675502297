import { useQuery } from "@apollo/client";
import { isOlderThan } from "@telia-no-min-side/utils";
import { graphql } from "src/gql";
import { AgreementType, SubscriptionStatus, UserRole } from "src/gql/graphql";
import { DashboardSection } from "../card/DashboardSection";
import { LoadingSubscriptionCard } from "../card/LoadingSubscriptionCard";
import { AnnualMobileDiscount } from "./annual-mobil-discount";
import { FwaAntichurnCard, useFwaAntichurn } from "./fwa-antichurn/FwaAntichurnCard";
import { BlueConicActionCard } from "./personal/BlueConicActionCard";
import { SummerCampaignCard } from "./summer-campaign/SummerCampaignCard";
import { ChangeEvoBox } from "./change-evo-box/EvoBox";
import { useFetchEvoBoxes } from "./hooks/useFetchEvoBoxes";
import { useSubscriptionOverview } from "../hooks/useSubscriptionOverview";
import { isSummerCampaignDoubleDataSubscription } from "util/format-subscription/isSummerCampaignDoubleDataSubscription";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";
import { isSummerCampaignDoubleDataActivated } from "util/format-subscription/isSummerCampaignDoubleDataActivated";
import { isGetUserDoubleDataActivated } from "util/format-subscription/isGetUserDoubleDataActivated";
import { ToBeReturned } from "./to-be-returned/ToBeReturned";
import { useFetchToBeReturned } from "src/api/fixed/useFetchToBeReturned";
import { useBlueConic } from "hooks/useBlueConic";
import { getUserOfferingWithAnnualDiscount } from "util/mobile/offer";
import { InsightCard } from "./InsightCard";
import { PostPurchaseOfferCard } from "../post-purchase-offer-card";
import { INSURANCE_POST_DUMMY_EXISTING } from "util/constants/agreements";
import { BlueConicPromoList } from "./personal/BlueConicPromoList";
import { config } from "util/config";
import { useBlueConicPromos } from "hooks/useBlueConicPromos";
import { GDPR_REPORTS_MOBILE } from "pages/privacy/pages/RightOfAccessStepOne";
import { useCompensation } from "pages/tv/compensation/useCompensation";
import { TvCompensationCards } from "pages/tv/compensation/TvCompensationCards";

const CAMPAIGN_SUBSCRIPTIONS = graphql(`
  query CampaignSubscriptions {
    subscriptions {
      account {
        id
      }
      phoneNumber {
        localNumber
      }
      subscriptionStatus
      userOffering {
        pricePlan
        shortName
        additionalProperties {
          value
          name
        }
      }
      productUser {
        firstName
        surname
        birthDate
      }
      newOfferingDetails {
        offerings {
          shortName
          shortDescription
          price {
            offeringPrices {
              code
              amount
            }
          }
        }
      }
      roles
      agreementType
      commitmentEndDate
      additionalProducts {
        id
        code
        shortName
        presentation {
          active
        }
        productCategory
        productTerms {
          code
          amount
        }
      }
    }
  }
`);

export function Highlights() {
  const campaignOverview = useQuery(CAMPAIGN_SUBSCRIPTIONS);
  const fwaAntichurn = useFwaAntichurn();
  const toBeReturned = useFetchToBeReturned();
  const tvCompensation = useCompensation();
  const { tvBoxes, user, hasOneOrMoreEvoBox } = useFetchEvoBoxes();
  const subscriptionOverview = useSubscriptionOverview();
  const { bcHighlights } = useBlueConic();
  const { promos, promosLoading } = useBlueConicPromos();
  const insightMobileStatus = useQuery(GDPR_REPORTS_MOBILE);

  if (
    (subscriptionOverview.loading && !subscriptionOverview.hasAnySubscriptions) ||
    fwaAntichurn.isLoading ||
    tvBoxes.isLoading ||
    insightMobileStatus.loading ||
    user.isLoading ||
    toBeReturned.isLoading ||
    campaignOverview.loading ||
    promosLoading ||
    tvCompensation.isLoading
  ) {
    return (
      <DashboardSection isLoading>
        <LoadingSubscriptionCard />
      </DashboardSection>
    );
  }

  const doubleDataSubscriptions = campaignOverview.data?.subscriptions?.filter(
    (subscription) =>
      isSummerCampaignDoubleDataSubscription(subscription.additionalProducts) &&
      !isSummerCampaignDoubleDataActivated(subscription.additionalProducts) &&
      !isGetUserDoubleDataActivated(subscription.additionalProducts) &&
      subscription?.subscriptionStatus !== SubscriptionStatus.Suspended &&
      !isJuniorSummerCampaign(subscription?.userOffering?.pricePlan || "")
  );

  // const oldBarnSubscriptions = (campaignOverview.data?.subscriptions || []).filter(
  //   (subscription) =>
  //     isJuniorSubscription(subscription.userOffering?.additionalProperties) &&
  //     /BARN/.test(subscription.userOffering?.pricePlan?.toUpperCase() || "")
  // );

  // const defaultAccountForJuniorSubscriptions = (subscriptionOverview.mobileSubscriptionsOverview || []).find(
  //   (subscription) =>
  //     subscription.account.agreementType === AgreementType.Private &&
  //     subscription.account.roles.includes(UserRole.LegalOwner)
  // );

  const mobileSubscriptions =
    campaignOverview.data?.subscriptions?.filter((subscription) => subscription.phoneNumber.localNumber.length === 8) ||
    [];

  const postPurchaseDummyProductExist = mobileSubscriptions.some(
    (subscription) =>
      subscription.additionalProducts?.find(
        (p) => p.code === INSURANCE_POST_DUMMY_EXISTING && isOlderThan(18, subscription.productUser?.birthDate)
      )
  );
  const showAnnualDiscount = config.showAnnualDiscount;
  const annualDiscountSubscriptions =
    showAnnualDiscount &&
    campaignOverview.data?.subscriptions?.filter(
      (subscription) =>
        !!subscription &&
        !subscription.commitmentEndDate &&
        !!getUserOfferingWithAnnualDiscount(
          subscription.userOffering?.shortName || "",
          subscription.newOfferingDetails?.offerings
        ) &&
        subscription.agreementType === AgreementType.Private &&
        subscription.roles.includes(UserRole.LegalOwner)
    );

  if (
    !doubleDataSubscriptions?.length &&
    !(annualDiscountSubscriptions && annualDiscountSubscriptions.length) &&
    !insightMobileStatus.data?.gdprStatus?.downloadableReports?.length &&
    !bcHighlights &&
    !fwaAntichurn.showFwaAntichurn &&
    !hasOneOrMoreEvoBox &&
    !toBeReturned.data?.length &&
    !postPurchaseDummyProductExist &&
    !promos?.blueconic?.highlights?.length &&
    !tvCompensation.eligibleCustomerIds?.length
  )
    return null;

  return (
    <DashboardSection title="Aktuelt">
      {config.isNewBlueconicEnabled ? (
        <BlueConicPromoList type="highlights" />
      ) : (
        <BlueConicActionCard message={bcHighlights} />
      )}
      {doubleDataSubscriptions?.map((subscription) => (
        <SummerCampaignCard
          key={subscription.phoneNumber.localNumber}
          phoneNumber={subscription.phoneNumber.localNumber}
        />
      ))}

      {mobileSubscriptions.map((subscription) => (
        <PostPurchaseOfferCard
          key={subscription.phoneNumber.localNumber}
          phoneNumber={subscription.phoneNumber.localNumber}
        />
      ))}

      {(tvCompensation.eligibleCustomerIds?.length || 0) > 0 && <TvCompensationCards />}

      {/* Blueconic should show the banner instead, but keeping just in case. Can be deleted after June 2024 */}
      {/* {defaultAccountForJuniorSubscriptions?.account?.id ? (
          <JuniorSummerCampaignCard accountId={defaultAccountForJuniorSubscriptions.account.id} />
        ) : null} */}

      {/* {oldBarnSubscriptions?.map((subscription) => (
          <BarnSummerCampaignCard
            key={subscription.phoneNumber.localNumber}
            phoneNumber={subscription.phoneNumber.localNumber}
            accountId={subscription.account.id}
            name={subscription.productUser?.firstName || ""}
          />
        ))} */}
      {!!insightMobileStatus.data?.gdprStatus?.downloadableReports?.length && <InsightCard />}
      {showAnnualDiscount && !!annualDiscountSubscriptions && (
        <AnnualMobileDiscount subscriptions={annualDiscountSubscriptions} />
      )}
      {fwaAntichurn.showFwaAntichurn && <FwaAntichurnCard />}
      {hasOneOrMoreEvoBox && <ChangeEvoBox />}
      <ToBeReturned />
    </DashboardSection>
  );
}
