import { types } from "@telia-no-min-side/utils";
import { useFetchProductRestrictions } from "src/api/fixed";
import { productId } from "src/util/productIds";

type ReturnVal = {
  extenderRestrictions?: types.fixed.ProductRestriction;
  hasMaxNumberOfExtenderRestriction: boolean;
  maxAllowedExtendersToOrder?: number;
  hasAvailableExtenders?: boolean;
  isLoading: boolean;
};

export function useSmartWifiExtenderRestrictions(): ReturnVal {
  const { productRestrictions, isLoading } = useFetchProductRestrictions();
  const extenderRestrictions = productRestrictions?.productRestrictions.find(
    (restriction) => restriction.commercialProductId === productId.smartWifiExtender
  );

  const hasMaxNumberOfExtenderRestriction = extenderRestrictions ? extenderRestrictions.max > 0 : false;

  const remainingAvailableExtenders = extenderRestrictions
    ? extenderRestrictions.max - extenderRestrictions.count
    : undefined;

  const maxAllowedExtendersToOrder = hasMaxNumberOfExtenderRestriction ? remainingAvailableExtenders : 100;

  const hasAvailableExtenders =
    !hasMaxNumberOfExtenderRestriction || remainingAvailableExtenders === undefined || remainingAvailableExtenders > 0;

  return {
    extenderRestrictions,
    hasMaxNumberOfExtenderRestriction,
    maxAllowedExtendersToOrder,
    hasAvailableExtenders,
    isLoading,
  };
}
