import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { useFwaCommitment } from "src/store/hooks/useFwaCommitment";
import { CustomerCareLink } from "src/components/customer-care-link";
import { useAxiosPost } from "src/services/axios/useAxiosPost";
import { useFixedAccount } from "src/hooks/useFixedAccount";
import { Error } from "./components/Error";
import { ReceiptSuccess } from "./components/ReceiptSuccess";
import { ReceiptError } from "./components/ReceiptError";
import { SkeletonPage } from "./components/SkeletonPage";
import { NewOffer } from "./components/NewOffer";
import { ExtensionOffer } from "./components/ExtensionOffer";

export function FwaCommitment() {
  const { isChangingProfile } = useFixedAccount();
  const fwa = useFwaCommitment();

  const offersAdd = useAxiosPost(uri.minSideFixed("/api/offers/fwa/add/fwa_recommitment"));

  function submitOffer() {
    offersAdd.post({}).then(() => fwa.refetch());
  }

  if (offersAdd.isSuccess) {
    return <ReceiptSuccess />;
  }

  if (offersAdd.isError) {
    return <ReceiptError onRetryClick={submitOffer} isLoading={offersAdd.isLoading} />;
  }

  if (fwa.isLoading || isChangingProfile) {
    return <SkeletonPage />;
  }

  if (fwa.isError) {
    return (
      <Error>
        <Button isLoading={fwa.isValidating} onClick={fwa.refetch} loadingText="Laster inn">
          Prøv på nytt
        </Button>
      </Error>
    );
  }

  if (fwa.extensionFwaOffer) {
    return (
      <ExtensionOffer
        extensionFwaOffer={fwa.extensionFwaOffer}
        isLoading={offersAdd.isLoading}
        onSubmit={submitOffer}
      />
    );
  }

  if (fwa.newFwaOffer) {
    return <NewOffer availableFwaOffer={fwa.newFwaOffer} isLoading={offersAdd.isLoading} onSubmit={submitOffer} />;
  }

  return (
    <Container maxWidth="md" padding alignItems="flex-start" flexDirection="column" gap>
      <Heading tag="h2" variant="title-300">
        Vi klarte ikke å finne din årsrabatt
      </Heading>
      <Paragraph>
        Er du trådløs bredbåndskunde og mener dette er feil, vennligst kontakt vår kundeservice:
        <br />
        <CustomerCareLink trackingContext="fwa-discount" />
      </Paragraph>
    </Container>
  );
}
