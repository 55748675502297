import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { useNavigate } from "react-router-dom";
type Props = {
  title: string;
  paragraphs: string[];
  isError?: boolean;
  reset?: () => void;
};

export function DueDateResult({ title, paragraphs, isError, reset }: Props) {
  const navigate = useNavigate();
  return (
    <Container maxWidth="sm" padding alignItems="flex-start" flexDirection="column" gap="lg">
      <Heading tag="h2" variant="title-300">
        {title}
      </Heading>

      <Container>
        {paragraphs.map((p, i) => (
          <Paragraph key={i}>{p}</Paragraph>
        ))}
      </Container>

      <Container flexDirection="row" gap>
        {isError && reset && (
          <Button icon="sync" iconPosition="before" onClick={() => reset()}>
            Prøv igjen
          </Button>
        )}

        <Button
          variant={isError ? "secondary" : "primary"}
          icon="arrow-left"
          iconPosition="before"
          onClick={() => navigate("/minside/faktura")}
        >
          Tilbake til fakturasiden
        </Button>
      </Container>
    </Container>
  );
}
