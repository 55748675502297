import { Link } from "react-router-dom";
import { Flex, Card, Badge, Icon, Button } from "@telia/teddy";
import { format, track, types, uri } from "@telia-no-min-side/utils";
import { getPriceText } from "util/priceFormatter";
import { getBadgeVariant } from "util/productHelper/getBadge";
import { useSelectAccount } from "hooks/useSelectAccount";
import { AUTH } from "util/constants/auth";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { useRef } from "react";

type Props = {
  customer: types.fixed.UserWithCustomerId;
  product: types.fixed.Product;
};

export function TvSubscriptionCard({ customer, product }: Props) {
  const { setAccount } = useSelectAccount();
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const additinalLinkRef = useRef<HTMLAnchorElement>(null);

  const badgeVariant = product.status ? getBadgeVariant(product.status) : "neutral";

  const handleCardClick = () => {
    setAccount({ accountType: AUTH.ACCOUNT_TYPE.FIXED, id: customer.customerId });
    pushGenericTrackingEvent({
      ui_item_action: "link_click",
      ui_item_context: "Hjem",
      ui_item_type: "card",
      ui_item_url: uri.minSideFixed(ICX_REQUEST.URL_INTERNET_EQUIPMENT),
      ui_item_text: "Ditt TV-innhold",
    });
  };

  const handleButtonClick = () => {
    pushGenericTrackingEvent({
      ui_item_action: "link_click",
      ui_item_context: "Hjem",
      ui_item_type: "card",
      ui_item_url: uri.channelpicker(),
      ui_item_text: "Til Valgmenyen",
    });
  };

  return (
    <Card
      shadow
      bordered
      width="100%"
      maxWidth="400px"
      layout="default"
      variant="white"
      onPointerUp={(e) => {
        if (additinalLinkRef.current === e.target) {
          e.preventDefault();
        }
      }}
      style={{ cursor: "pointer" }}
    >
      {product.status && badgeVariant !== "success" ? (
        <Card.Slot align="top-right">
          <Badge variant={badgeVariant} hideIcon>
            {product.status.localizedtitle}
          </Badge>
        </Card.Slot>
      ) : product?.schedule?.displayName ? (
        <Card.Slot align="top-right">
          <Badge variant="error" hideIcon>
            {product.schedule.displayName}
          </Badge>
        </Card.Slot>
      ) : null}

      <Card.Heading as="h3" variant="title-100" data-di-mask>
        {format.startCase(`${customer.street}`)}
      </Card.Heading>

      <Card.Content>
        <Flex direction="row" justify="between">
          <Icon name="entertainment" size="md">
            {product.title2}
          </Icon>
          {getPriceText(product.price)}
        </Flex>
      </Card.Content>

      <Card.Footer height="100%" align="end">
        <Flex direction="row" justify={product.points?.initial ? "between" : "end"} width="100%">
          <Card.Button variant="text" asChild>
            <Link onClick={handleCardClick} to={uri.minSideFixed("/tv/tv-innhold")}>
              Ditt TV-innhold
            </Link>
          </Card.Button>
          {!!product.points?.initial && (
            <Button variant="text" asChild onClick={handleButtonClick}>
              <a ref={additinalLinkRef} href={uri.channelpicker()}>
                Til Valgmenyen <Icon name="arrow-right" />
              </a>
            </Button>
          )}
        </Flex>
      </Card.Footer>
    </Card>
  );
}
