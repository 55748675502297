import { ProfilePickerTypes } from "@telia-no-min-side/components";
import { format, types } from "@telia-no-min-side/utils";
import type { UserOverviewDataQuery, UserRole } from "src/gql/graphql";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { useFetchUser } from "src/api/fixed";
import { PickerOption } from "../../top-menu/Picker";
import { getFirstName } from "../utils/getFirstName";

export type Account = NonNullable<NonNullable<UserOverviewDataQuery>["accounts"]>[0];
export type BaseSubscription = NonNullable<Account["subscriptions"]>[0];
export type Subscription = BaseSubscription & { account: { id: string; roles: UserRole[] } };
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export type ProfileValue = types.fixed.UserWithCustomerId | Subscription | string;
export type Profiles = ProfilePickerTypes.Profile<ProfileValue>[];
export type NewProfiles = PickerOption<ProfileValue>[];

/**
 * Used to identify if a selected profile is
 * fixed or mobile
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isMobileCustomer(item: any): item is Subscription {
  return !!item?.subscriptionType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isFixedCustomer(item: any): item is types.fixed.UserWithCustomerId {
  return !!item?.userKey;
}

/**
 * Maps different profile types (fixed / mobile) to
 * a single format that one can pass to a ProfilePicker
 */

function mapFixedUserToProfileOld(
  customer: types.fixed.UserWithCustomerId,
  formatTitle?: (customer: types.fixed.UserWithCustomerId) => string
): ProfilePickerTypes.Profile<types.fixed.UserWithCustomerId> {
  return {
    id: customer.customerId,
    key: customer.userKey,
    title: formatTitle ? formatTitle(customer) : format.startCase(`${customer.street}`),
    value: customer,
  };
}

function mapFixedUserToProfile(
  customer: types.fixed.UserWithCustomerId,
  formatTitle?: (customer: types.fixed.UserWithCustomerId) => string
): PickerOption<types.fixed.UserWithCustomerId> {
  const buttonSubtext = formatTitle ? formatTitle(customer) : format.startCase(`${customer?.street || ""}`);
  return {
    id: customer.customerId,
    buttonText: buttonSubtext.charAt(0).toUpperCase(),
    buttonSubtext,
    value: customer,
  };
}

/**
 @deprecated
 */

// TODO: Remove after new picker is in use
function mapMobileUserToProfileOld(
  subscription: BaseSubscription,
  account: Account,
  formatTitle?: (subscription: BaseSubscription) => string,
  formatSubtitle?: (subscription: BaseSubscription) => string
): ProfilePickerTypes.Profile<Subscription> {
  return {
    id: subscription.phoneNumber.localNumber,
    key: subscription.phoneNumber.localNumber,
    title: formatTitle ? formatTitle(subscription) : format.startCase(subscription?.productUser?.firstName || ""),
    subtitle: formatSubtitle
      ? formatSubtitle(subscription)
      : format.formatPhoneNumber(`${subscription?.phoneNumber?.localNumber}`),
    value: { ...subscription, account: { id: account.id, roles: account.roles } },
  };
}

function mapMobileUserToProfile(
  subscription: BaseSubscription,
  account: Account,
  formatTitle?: (subscription: BaseSubscription) => string,
  formatSubtitle?: (subscription: BaseSubscription) => string
): PickerOption<Subscription> {
  return {
    id: subscription.phoneNumber.localNumber,
    buttonText: formatTitle
      ? formatTitle(subscription)
      : `${subscription.productUser?.firstName.charAt(0) || ""}${
          subscription.productUser?.surname.charAt(0) || ""
        }`.toUpperCase(),
    buttonSubtext: formatSubtitle
      ? formatSubtitle(subscription)
      : format.startCase(`${getFirstName(subscription.productUser?.firstName)}`),
    value: { ...subscription, account: { id: account.id, roles: account.roles } },
  };
}

/**
 * Fetches both mobile and fixed profiles.
 * You can use filter param to fetch only the
 * mobile or only the fixed profiles.
 */

type ProfileFilter = "all" | "fixed" | "mobile";

type ProfilePickerArgs = {
  filter?: ProfileFilter;
  mobileTitleFormatter?: (subscription: BaseSubscription) => string;
  mobileSubtitleFormatter?: (subscription: BaseSubscription) => string;
  fixedTitleFormatter?: (customer: types.fixed.UserWithCustomerId) => string;
};

export function useProfilePicker(args?: ProfilePickerArgs) {
  const { filter, mobileTitleFormatter, mobileSubtitleFormatter, fixedTitleFormatter } = args || {};

  const skipFixedData = filter === "mobile";

  const mobile = useMobileAccount();
  const fixed = useFetchUser(undefined, skipFixedData);

  const mobileUsers = mobile.allAccounts || [];
  // TODO: Remove after new picker is in use
  const oldMobileProfiles = mobileUsers.flatMap((account) =>
    account.subscriptions
      ? account.subscriptions.map((subscription) =>
          mapMobileUserToProfileOld(subscription, account, mobileTitleFormatter, mobileSubtitleFormatter)
        )
      : []
  );

  const mobileProfiles = mobileUsers.flatMap((account) =>
    account.subscriptions
      ? account.subscriptions.map((subscription) =>
          mapMobileUserToProfile(subscription, account, mobileTitleFormatter, mobileSubtitleFormatter)
        )
      : []
  );

  const fixedUsers = fixed.data?.usersWithCustomerId || [];
  // TODO: Remove after new picker is in use
  const oldFixedProfiles = fixedUsers.map((user) => mapFixedUserToProfileOld(user, fixedTitleFormatter));
  const fixedProfiles = fixedUsers.map((user) => mapFixedUserToProfile(user, fixedTitleFormatter));

  return {
    mobile: {
      profiles: oldMobileProfiles,
      loading: mobile.isLoading || mobile.isRefetching,
      hasError: !!mobile.error,
      refetch: mobile.refetch,
    },
    fixed: {
      profiles: oldFixedProfiles,
      loading: fixed.isLoading || fixed.isValidating,
      hasError: !!fixed.error,
      refetch: fixed.mutate,
    },
    newFixed: {
      profiles: fixedProfiles,
      loading: fixed.isLoading || fixed.isValidating,
      hasError: !!fixed.error,
      refetch: fixed.mutate,
    },
    newMobile: {
      profiles: mobileProfiles,
      loading: mobile.isLoading || mobile.isRefetching,
      hasError: !!mobile.error,
      refetch: mobile.refetch,
    },
  };
}
