export const INTERNAL_LINK = {
  HOME_PAGE: "/minside/hjem",
  INVOICE_PAGE: "/minside/faktura",
  INVOICE_SETTINGS_PAGE: "/minside/faktura/innstillinger",
  FLEX_PAGE: "/minside/flex",
  PROFILE_PAGE: "/minside/profil",
  VALUE_ADDED_SERVICES_PAGE: "/minside/tjenester",
  POST_PURCHASE_INSURANCES_PAGE: "/minside/hjem/bestill-forsikring",
  MOBILE: {
    AGREEMENTS_PAGE: "/minside/mobil/avtaler",
    ADD_SUBSCRIPTION_PAGE: "/minside/mobil/legg-til",
    CHANGE_SUBSCRIPTION_PAGE: "/minside/mobil/endre",
    SUSPEND_SUBSCRIPTION_PAGE: "/minside/mobil/sperr-abonnement",
    DATA_USAGE_HISTORY_PAGE: "/minside/mobil/tidligere-dataforbruk",
    ORDER_SIM_PAGE: "/minside/mobil/sim-pin-puk/bestill-sim",
    ACTIVATE_SIM_PAGE: "/minside/mobil/sim-pin-puk/aktivere-sim",
    ORDER_ADDITIONAL_SIM_CARD_PAGE: "/minside/mobil/sim-pin-puk/bestill-erstatning",
    ANNUAL_DISCOUNT_PAGE: "/minside/mobil/bestill-arsrabatt",
  },
  MOBILE_NEW: {
    PROFILE_PAGE: "/minside/mobil/profil",
    SETTINGS_PAGE: "/minside/mobil/innstillinger",
    SIM_PIN_PUK_PAGE: "/minside/mobil/sim-pin-puk",
    ACCOUNT_OVERVIEW_PAGE: "/minside/mobil/mine-abonnement",
    DATA_USAGE_HISTORY_PAGE: "/minside/mobil/tidligere-dataforbruk",
    PREPAID_ORDER_HISTORY: "/minside/mobil/kjopshistorikk",
  },
  FIXED: {
    WIFI_NETWORK_SETTINGS_PAGE: "/minside/internett/wifi-nettverk",
    BROADBAND_HOME: "/minside/internett",
    BROADBAND_SERVICES_PAGE: "/minside/internett/tilleggstjenester",
    BROADBAND_BUY_HARDWARE_PAGE: "/minside/internett/utstyr/kjop-smart-wifi",
    MOBILE_BROADBAND: "/minside/internett/mobilt-bredband",
    MOBILE_BROADBAND_SETTINGS_PAGE: "/minside/internett/mobilt-bredband/innstillinger",
    MOBILE_BROADBAND_PREPAID_ORDER_HISTORY: "/minside/internett/mobilt-bredband/kjopshistorikk",

    // MBB SIM PIN PUK
    MOBILE_BROADBAND_SIM_CARD_PAGE: "/minside/internett/mobilt-bredband/sim-pin-puk",
    ACTIVATE_SIM_PAGE: "/minside/internett/mobilt-bredband/sim-pin-puk/aktivere-sim",
    ORDER_ADDITIONAL_SIM_CARD_PAGE: "/minside/internett/mobilt-bredband/sim-pin-puk/bestill-erstatning",
    ORDER_SIM_PAGE: "/minside/internett/mobilt-bredband/sim-pin-puk/bestill-sim",

    TV_BUY_NEW_BOX: "/minside/tv/tv-bokser/kjop-ny-tv-boks",
    TV_ORDER_RECORDING: "/minside/tv/tilleggstjenester/kjop-opptak",
  },
};
