export const data = {
  account: {
    offerings: [
      {
        pricePlan: "TELIA_X_START_2025.COM12PURP10",
        name: "Telia X Start med 10% rabatt på månedsavgiften og 12 md avtaletid",
        shortName: "Telia X Start",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 381,
          reasons: [
            {
              code: "MONTHLY_CHARGE",
            },
            {
              code: "MONTHLY_DISCOUNT",
            },
          ],
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "HAS_DISCOUNT",
            value: "true",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 479,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 10,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 379,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_IN_PERCENTS",
                amount: 10,
                monetaryUnit: "%",
              },
              {
                code: "MONTHLY_DISCOUNT_PERC",
                amount: 10,
                monetaryUnit: "PERCENT",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_DISCOUNT",
                amount: 48,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 479,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "TOTAL_COST",
                amount: 5172,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_DURATION",
                amount: 12,
                monetaryUnit: "MONTH",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 152,
        commitmentTimeInMonths: 12,
      },
      {
        pricePlan: "TELIA_X_BASIC_2025.COM12PURP10",
        name: "Telia X Basis med 10% rabatt på månedsavgiften og 12 md avtaletid",
        shortName: "Telia X Basis",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 449,
          reasons: [
            {
              code: "MONTHLY_DISCOUNT",
            },
          ],
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "HAS_DISCOUNT",
            value: "true",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 499,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 399,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_IN_PERCENTS",
                amount: 10,
                monetaryUnit: "%",
              },
              {
                code: "MONTHLY_DISCOUNT_PERC",
                amount: 10,
                monetaryUnit: "PERCENT",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_DISCOUNT",
                amount: 50,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 499,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "TOTAL_COST",
                amount: 5388,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_DURATION",
                amount: 12,
                monetaryUnit: "MONTH",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 147,
        commitmentTimeInMonths: 12,
      },
      {
        pricePlan: "TELIA_JUNIOR_03GB.REGULAR",
        name: "Telia Junior 5 GB",
        shortName: "Telia Junior 5 GB",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 179,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_MAX_AGE",
            value: "15",
          },
          {
            name: "COST_CONTROL",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_ROLLOVER",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "PRICEPLAN_GROUP",
            value: "JUNIOR",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
        ],
        originalPrice: {
          amount: 179,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_INCLUDED",
                amount: 5,
                monetaryUnit: "GB",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "MBIT",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 149,
                monetaryUnit: "NOK",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 64,
                monetaryUnit: "KBIT",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 179,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 5,
                monetaryUnit: "GB",
              },
            ],
          },
        ],

        weight: 350,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "ANSATT_FAMILY_X.REGULAR",
        name: "ANSATT Familie X",
        shortName: "ANSATT Familie X",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 250,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "SPEED_UNLIMITED",
            value: "true",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 249.5,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "Mbit",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 30,
                monetaryUnit: "GB",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "MBIT",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 250,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 10000,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "TELIA_MOBIL_10GB_2025.REGULAR",
        name: "Telia Mobil 10 GB",
        shortName: "Telia Mobil 10 GB",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 379,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 379,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_INCLUDED",
                amount: 10,
                monetaryUnit: "GB",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "MBIT",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 349,
                monetaryUnit: "NOK",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 64,
                monetaryUnit: "KBIT",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 379,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 10,
                monetaryUnit: "GB",
              },
            ],
          },
        ],

        weight: 250,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "ANSATT_FAMILY_01GB.REGULAR",
        name: "ANSATT Familie 1 GB",
        shortName: "ANSATT Familie 1 GB",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 75,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE",
            value: "true",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "true",
          },
          {
            name: "SPEED_UNLIMITED",
            value: "true",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 74.5,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_INCLUDED",
                amount: 1,
                monetaryUnit: "GB",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "MBIT",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 64,
                monetaryUnit: "KBIT",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 75,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 1,
                monetaryUnit: "GB",
              },
            ],
          },
        ],

        weight: 9850,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "TELIA_MOBIL_05GB_2025.REGULAR",
        name: "Telia Mobil 5 GB",
        shortName: "Telia Mobil 5 GB",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 329,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 329,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_INCLUDED",
                amount: 5,
                monetaryUnit: "GB",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "MBIT",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 299,
                monetaryUnit: "NOK",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 64,
                monetaryUnit: "KBIT",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 329,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 5,
                monetaryUnit: "GB",
              },
            ],
          },
        ],

        weight: 325,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "ANSATT_FAMILY_08GB.REGULAR",
        name: "ANSATT Familie 10 GB",
        shortName: "ANSATT Familie 10 GB",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 125,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE",
            value: "true",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "true",
          },
          {
            name: "SPEED_UNLIMITED",
            value: "true",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 124.5,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_INCLUDED",
                amount: 10,
                monetaryUnit: "GB",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "MBIT",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 64,
                monetaryUnit: "KBIT",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 125,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 10,
                monetaryUnit: "GB",
              },
            ],
          },
        ],

        weight: 9900,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "TELIA_MOBIL_X_RASK_VT.COM12KR3",
        name: "Telia X + Viaplay Total med 100kr rabatt og 12 mnd avtaletid",
        shortName: "Telia X + Viaplay Total",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 999,
          reasons: [
            {
              code: "MONTHLY_DISCOUNT",
            },
          ],
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "HAS_DISCOUNT",
            value: "true",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 1099,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 1000,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 999,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_IN_PERCENTS",
                amount: 9,
                monetaryUnit: "%",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_DISCOUNT",
                amount: 100,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 1099,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "TOTAL_COST",
                amount: 11988,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_DURATION",
                amount: 12,
                monetaryUnit: "MONTH",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 50,
                monetaryUnit: "GB",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 96,
        commitmentTimeInMonths: 12,
      },
      {
        pricePlan: "TELIA_MOBIL_05GB_2025.COM12PURP10",
        name: "Telia Mobil 5 GB med 10% rabatt på månedsavgiften og 12 mnd avtaletid",
        shortName: "Telia Mobil 5 GB",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 296,
          reasons: [
            {
              code: "MONTHLY_DISCOUNT",
            },
          ],
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "HAS_DISCOUNT",
            value: "true",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 329,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "MBIT",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 299,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_IN_PERCENTS",
                amount: 10,
                monetaryUnit: "%",
              },
              {
                code: "MONTHLY_DISCOUNT_PERC",
                amount: 10,
                monetaryUnit: "PERCENT",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 64,
                monetaryUnit: "KBIT",
              },
              {
                code: "MONTHLY_DISCOUNT",
                amount: 33,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 329,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 5,
                monetaryUnit: "GB",
              },
              {
                code: "TOTAL_COST",
                amount: 3552,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_INCLUDED",
                amount: 5,
                monetaryUnit: "GB",
              },
              {
                code: "MONTHLY_DISCOUNT_DURATION",
                amount: 12,
                monetaryUnit: "MONTH",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 352,
        commitmentTimeInMonths: 12,
      },
      {
        pricePlan: "TELIA_X_MAX_PLUS_2025.REGULAR",
        name: "Telia X Max Pluss",
        shortName: "Telia X Max Pluss",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 699,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "SCREEN_PROTECTOR",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "SPEED_UNLIMITED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "EXTRA_SIM",
            value: "true",
          },
          {
            name: "TELIA_TRYGG",
            value: "true",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
        ],
        originalPrice: {
          amount: 699,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 1000,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 599,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "MBIT",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 699,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 70,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "TELIA_X_MAX_2025.REGULAR",
        name: "Telia X Max",
        shortName: "Telia X Max",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 599,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "SCREEN_PROTECTOR",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "SPEED_UNLIMITED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
        ],
        originalPrice: {
          amount: 599,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 1000,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 499,
                monetaryUnit: "NOK",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "MBIT",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 599,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 75,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "TELIA_JUNIOR_01GB.REGULAR",
        name: "Telia Junior 1 GB",
        shortName: "Telia Junior 1 GB",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 129,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_MAX_AGE",
            value: "15",
          },
          {
            name: "COST_CONTROL",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_ROLLOVER",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "PRICEPLAN_GROUP",
            value: "JUNIOR",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
        ],
        originalPrice: {
          amount: 129,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_INCLUDED",
                amount: 1,
                monetaryUnit: "GB",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "MBIT",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 64,
                monetaryUnit: "KBIT",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 129,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 1,
                monetaryUnit: "GB",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 375,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "TELIA_X_MAX_2025.COM12PURP10",
        name: "Telia X Max med 10% rabatt på månedsavgiften og 12 md avtaletid",
        shortName: "Telia X Max",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 539,
          reasons: [
            {
              code: "MONTHLY_DISCOUNT",
            },
          ],
        },
        additionalProperties: [
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "SCREEN_PROTECTOR",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "SPEED_UNLIMITED",
            value: "true",
          },
          {
            name: "HAS_DISCOUNT",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
        ],
        originalPrice: {
          amount: 599,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 1000,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 499,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_IN_PERCENTS",
                amount: 10,
                monetaryUnit: "%",
              },
              {
                code: "MONTHLY_DISCOUNT_PERC",
                amount: 10,
                monetaryUnit: "PERCENT",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "MBIT",
              },
              {
                code: "MONTHLY_DISCOUNT",
                amount: 60,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 599,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "TOTAL_COST",
                amount: 6468,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_DURATION",
                amount: 12,
                monetaryUnit: "MONTH",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 102,
        commitmentTimeInMonths: 12,
      },
      {
        pricePlan: "TELIA_MOBIL_X_RASK_VT.REGULAR",
        name: "Telia X + Viaplay Total",
        shortName: "Telia X + Viaplay Total",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 1099,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 1099,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 1000,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 999,
                monetaryUnit: "NOK",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 1099,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 50,
                monetaryUnit: "GB",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 50,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "TELIA_X_START_2025.REGULAR",
        name: "Telia X Start",
        shortName: "Telia X Start",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 429,
          reasons: [
            {
              code: "MONTHLY_CHARGE",
            },
          ],
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 479,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 10,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 379,
                monetaryUnit: "NOK",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 479,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 125,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "TELIA_X_BASIC_2025.REGULAR",
        name: "Telia X Basis",
        shortName: "Telia X Basis",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 499,
          reasons: null,
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 499,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 399,
                monetaryUnit: "NOK",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 499,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 120,
        commitmentTimeInMonths: 0,
      },
      {
        pricePlan: "TELIA_JUNIOR_01GB.COM12PURP10",
        name: "Telia Junior 1 GB med 10% rabatt på månedsavgiften og 12 md avtaletid",
        shortName: "Telia Junior 1 GB",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 116,
          reasons: [
            {
              code: "MONTHLY_DISCOUNT",
            },
          ],
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_MAX_AGE",
            value: "15",
          },
          {
            name: "COST_CONTROL",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_ROLLOVER",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "HAS_DISCOUNT",
            value: "true",
          },
          {
            name: "PRICEPLAN_GROUP",
            value: "JUNIOR",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
        ],
        originalPrice: {
          amount: 129,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "MBIT",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_IN_PERCENTS",
                amount: 10,
                monetaryUnit: "%",
              },
              {
                code: "MONTHLY_DISCOUNT_PERC",
                amount: 10,
                monetaryUnit: "PERCENT",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 64,
                monetaryUnit: "KBIT",
              },
              {
                code: "MONTHLY_DISCOUNT",
                amount: 13,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 129,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 1,
                monetaryUnit: "GB",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "TOTAL_COST",
                amount: 1393,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_INCLUDED",
                amount: 1,
                monetaryUnit: "GB",
              },
              {
                code: "MONTHLY_DISCOUNT_DURATION",
                amount: 12,
                monetaryUnit: "MONTH",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 402,
        commitmentTimeInMonths: 12,
      },

      {
        pricePlan: "TELIA_JUNIOR_03GB.COM12PURP10",
        name: "Telia Junior 5 GB med 10% rabatt på månedsavgiften og 12 md avtaletid",
        shortName: "Telia Junior 5 GB",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 161,
          reasons: [
            {
              code: "MONTHLY_DISCOUNT",
            },
          ],
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_MAX_AGE",
            value: "15",
          },
          {
            name: "COST_CONTROL",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_ROLLOVER",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "HAS_DISCOUNT",
            value: "true",
          },
          {
            name: "PRICEPLAN_GROUP",
            value: "JUNIOR",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
        ],
        originalPrice: {
          amount: 179,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "MBIT",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 149,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_IN_PERCENTS",
                amount: 10,
                monetaryUnit: "%",
              },
              {
                code: "MONTHLY_DISCOUNT_PERC",
                amount: 10,
                monetaryUnit: "PERCENT",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 64,
                monetaryUnit: "KBIT",
              },
              {
                code: "MONTHLY_DISCOUNT",
                amount: 18,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 179,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 5,
                monetaryUnit: "GB",
              },
              {
                code: "TOTAL_COST",
                amount: 1933,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_INCLUDED",
                amount: 5,
                monetaryUnit: "GB",
              },
              {
                code: "MONTHLY_DISCOUNT_DURATION",
                amount: 12,
                monetaryUnit: "MONTH",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 377,
        commitmentTimeInMonths: 12,
      },
      {
        pricePlan: "TELIA_X_MAX_PLUS_2025.COM12PURP10",
        name: "Telia X Max Pluss med 10% rabatt på månedsavgiften og 12 md avtaletid",
        shortName: "Telia X Max Pluss",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 629,
          reasons: [
            {
              code: "MONTHLY_DISCOUNT",
            },
          ],
        },
        additionalProperties: [
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "TELIA_SKY",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "DATA_UNLIMITED",
            value: "true",
          },
          {
            name: "DATA_ROLLOVER",
            value: "false",
          },
          {
            name: "SCREEN_PROTECTOR",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "SPEED_UNLIMITED",
            value: "true",
          },
          {
            name: "HAS_DISCOUNT",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "EXTRA_SIM",
            value: "true",
          },
          {
            name: "TELIA_TRYGG",
            value: "true",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
        ],
        originalPrice: {
          amount: 699,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 1000,
                monetaryUnit: "Mbit",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 599,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_IN_PERCENTS",
                amount: 10,
                monetaryUnit: "%",
              },
              {
                code: "MONTHLY_DISCOUNT_PERC",
                amount: 10,
                monetaryUnit: "PERCENT",
              },
              {
                code: "MINUTES_INCLUDED_NORDIC",
                amount: 1000,
                monetaryUnit: "MIN",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 3,
                monetaryUnit: "MBIT",
              },
              {
                code: "MONTHLY_DISCOUNT",
                amount: 70,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 699,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "TOTAL_COST",
                amount: 7548,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_DURATION",
                amount: 12,
                monetaryUnit: "MONTH",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_RLH",
                amount: 100,
                monetaryUnit: "GB",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 97,
        commitmentTimeInMonths: 12,
      },
      {
        pricePlan: "TELIA_MOBIL_10GB_2025.COM12PURP10",
        name: "Telia Mobil 10 GB med 10% rabatt på månedsavgiften og 12 mnd avtaletid",
        shortName: "Telia Mobil 10 GB",
        monthlyCost: {
          code: "MONTHLY_CHARGE",
          amount: 341,
          reasons: [
            {
              code: "MONTHLY_DISCOUNT",
            },
          ],
        },
        additionalProperties: [
          {
            name: "PRODUCT_USER_VALIDATION",
            value: "true",
          },
          {
            name: "COST_CONTROL",
            value: "false",
          },
          {
            name: "ELIGIBLE_TO_RECEIVE_GIFT_DATA_BALANCE",
            value: "true",
          },
          {
            name: "DATA_CONTROL",
            value: "true",
          },
          {
            name: "ENTITLEMENT",
            value: "MULTISIM",
          },
          {
            name: "ENTITLEMENT",
            value: "ESIM",
          },
          {
            name: "ENTITLEMENT",
            value: "IPAD_SIGNUP",
          },
          {
            name: "CATEGORY",
            value: "VOICE",
          },
          {
            name: "DATA_ROLLOVER",
            value: "true",
          },
          {
            name: "PRICEPLAN_DISCOUNT_GROUP",
            value: "MHS_B2C",
          },
          {
            name: "HAS_DISCOUNT",
            value: "true",
          },
          {
            name: "SWITCH_ALLOWED",
            value: "true",
          },
          {
            name: "RESOURCE_TYPE",
            value: "MOBILE",
          },
        ],
        originalPrice: {
          amount: 379,
        },
        offeringPrices: [
          {
            offeringPrices: [
              {
                code: "PER_UNIT_MMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "NEW_MSISDN_FEE",
                amount: 199,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD",
                amount: 250,
                monetaryUnit: "MBIT",
              },
              {
                code: "MONTHLY_CHARGE_LINE",
                amount: 349,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_DISCOUNT_IN_PERCENTS",
                amount: 10,
                monetaryUnit: "%",
              },
              {
                code: "MONTHLY_DISCOUNT_PERC",
                amount: 10,
                monetaryUnit: "PERCENT",
              },
              {
                code: "VOICE_PER_MIN",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "SPEED_DOWNLOAD_REDUCED",
                amount: 64,
                monetaryUnit: "KBIT",
              },
              {
                code: "MONTHLY_DISCOUNT",
                amount: 38,
                monetaryUnit: "NOK",
              },
              {
                code: "MONTHLY_CHARGE",
                amount: 379,
                monetaryUnit: "NOK",
              },
              {
                code: "SIM_REPL_ORDER_FEE",
                amount: 99,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_LIMIT_SPEED_REDUCED",
                amount: 10,
                monetaryUnit: "GB",
              },
              {
                code: "TOTAL_COST",
                amount: 4092,
                monetaryUnit: "NOK",
              },
              {
                code: "DATA_INCLUDED",
                amount: 10,
                monetaryUnit: "GB",
              },
              {
                code: "MONTHLY_DISCOUNT_DURATION",
                amount: 12,
                monetaryUnit: "MONTH",
              },
              {
                code: "SIGN_UP_FEE",
                amount: 0,
                monetaryUnit: "NOK",
              },
              {
                code: "PER_UNIT_SMS",
                amount: 0,
                monetaryUnit: "NOK",
              },
            ],
          },
        ],

        weight: 277,
        commitmentTimeInMonths: 12,
      },
    ],
  },
};
