import { getSimplePriceText, types } from "@telia-no-min-side/utils";
import { ShoppingSummary } from "components/shopping-summary";
import { GetPrice } from "pages/tv/utils/price";

type Props = {
  remoteControlProduct?: types.fixed.ProductGroupSingleProductWithShipping;
  remoteControlPrice?: GetPrice;
  shipping?: types.fixed.ProductGroupShipping;
};

export function OneTimeCosts(props: Props): JSX.Element {
  const { remoteControlProduct, remoteControlPrice, shipping } = props;

  const remotePrice = remoteControlPrice?.totalOnceOffPrice ?? 0;
  const shippingPrice = shipping?.onceOffPrice ?? 0;

  const remoteDisplayPrice = getSimplePriceText(remotePrice);

  const total = getSimplePriceText(remotePrice + shippingPrice);

  return (
    <ShoppingSummary.List title="Engangspris">
      {remoteControlProduct && remoteControlPrice && (
        <ShoppingSummary.Item text="1x Fjernkontroll" price={remoteDisplayPrice} />
      )}
      {shipping && (
        <ShoppingSummary.Item text="Frakt" price={`${shipping?.onceOffPrice},-`} secondaryText={shipping.description} />
      )}
      <ShoppingSummary.Line />
      <ShoppingSummary.Item text="Sum" price={total} />
    </ShoppingSummary.List>
  );
}
