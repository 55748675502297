import { color } from "@telia/teddy";

export function getColorByType(quotaType: string) {
  switch (quotaType) {
    case "BASE":
      return color.teddyColorPurple800;
    case "ROLLOVER":
      return color.teddyColorPurple300;
    case "TOPUPS":
      return color.teddyColorTeal300;
    default:
      return "";
  }
}
