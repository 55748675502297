import { Flex, Notabene, Text, Heading, AlertBadge } from "@telia/teddy";
import { NetworkStatistics } from "src/api/fixed";

type Props = {
  networkStatistics: NetworkStatistics;
};

export function Overview({ networkStatistics }: Props) {
  return (
    <>
      <Flex mt="200" align="baseline">
        <Heading as="h2" variant="title-300" mr="200">
          Innstillinger
        </Heading>
        <Text mr="100">{networkStatistics?.success ? "På" : "Av"}</Text>
        <AlertBadge variant={networkStatistics?.success ? "success" : "error"} />
      </Flex>
      <Notabene width={{ md: "100%", lg: "40%" }} p="250" mt="200">
        <Notabene.Content>
          Da 5 GHz-nettet gir høyere hastighet og færre forstyrrelser, anbefaler vi deg å koble utstyret ditt til dette
          nettet. Se under hva 5 GHz-nettet heter, og hvilket nett utstyret er koblet til.
        </Notabene.Content>
      </Notabene>
    </>
  );
}
