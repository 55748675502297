import { Button, Container, Heading, Icon, Paragraph, Skeleton } from "@telia-no-min-side/components";
import { format, track, types, uri } from "@telia-no-min-side/utils";
import { IconDefinition } from "@telia/styleguide";
import { useFetchAntichurn, useFetchUser } from "src/api/fixed";
import { useFixedAccount } from "src/hooks/useFixedAccount";
import { useEffect, useState } from "react";
import { config } from "src/util/config";
import { LinkCard } from "../../card/LinkCard";
import { BaseCard, InteractiveCard } from "../../card/style";
import { CloseButton } from "../../subscriptions-mobile/style";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

const changeBroadbandUrl = uri.purchase(ICX_REQUEST.URL_CHANGE_BROADBAND);
const buttonText = "Se priser";

export function FwaAntichurnCard() {
  const fwaAntichurn = useFwaAntichurn();
  const { changeFixedAccount } = useFixedAccount();
  const [showSelectAccount, setShowSelectAccount] = useState(false);
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const isLoading = fwaAntichurn.isLoading;

  if (isLoading) {
    return (
      <BaseCard>
        <Skeleton variant="heading-s" width="20ch" />
        <Skeleton width="90%" />
        <Skeleton variant="button-small" float="right" width="12ch" />
      </BaseCard>
    );
  }

  if (!fwaAntichurn.data || fwaAntichurn.isError) return null;

  const content = fwaAntichurn.shouldSeeRecommendNew
    ? ({
        title: "Bytte fart på bredbåndet ditt?",
        text: "Se våre nye bredbåndstilbud. Det kan være penger å spare!",
        lozenge: "Tips!",
        icon: "bulb" satisfies IconDefinition,
      } as const)
    : fwaAntichurn.shouldSeeCanKeepSpeed
    ? ({
        title: "Spar penger på bredbåndet ditt!",
        text: "Samme hastighet til lavere pris.",
        lozenge: "Tilbud til deg!",
        icon: "savings" satisfies IconDefinition,
      } as const)
    : null;

  if (!content) return null;

  const lozenge = { icon: content.icon, label: content.lozenge, status: "communication" } as const;
  if (showSelectAccount && fwaAntichurn.usersWithAntichurn?.length) {
    return (
      <BaseCard lozengeRight={lozenge}>
        <CloseButton
          isIconButton
          icon="close"
          onClick={() => setShowSelectAccount(false)}
          title="Lukk"
          size="sm"
          variant="text-black"
        />
        <Paragraph>Velg hvilken adresse det gjelder:</Paragraph>
        <Container flexDirection="column" gap>
          {fwaAntichurn.usersWithAntichurn?.map((customer) => (
            <div key={`${customer.customerId}-select-wifi-address`}>
              <LinkCard
                removeMinHeight
                data-di-mask
                backgroundColor="light-purple"
                cardHref={changeBroadbandUrl}
                isCardLinkInternalNavigation={false}
                onLinkNavigation={() => {
                  pushGenericTrackingEvent({
                    ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                    ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
                    ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_FWA_ANTICHURN,
                    ui_item_url: changeBroadbandUrl,
                    ui_item_text: format.startCase(`${customer.street}`),
                  });
                  changeFixedAccount(customer.customerId);
                }}
                cardLinkText={format.startCase(`${customer.street}`)}
              >
                <Container flexDirection="row" gap floatContent="center">
                  <Icon size="sm" icon="home" />
                  <Paragraph removeMargin>{format.startCase(customer.street)}</Paragraph>
                </Container>
              </LinkCard>
            </div>
          ))}
        </Container>
      </BaseCard>
    );
  }
  const hasToSelectAccount = (fwaAntichurn.usersWithAntichurn?.length || 0) > 1;
  if (hasToSelectAccount) {
    return (
      <InteractiveCard
        onClick={() => {
          setShowSelectAccount(true);
        }}
        lozengeRight={lozenge}
      >
        <Container flexDirection="column">
          <Content heading={content.title} text={content.text} />
          <Container floatContent="right">
            <Button
              variant="text-purple"
              size="sm"
              trackEvent={{
                ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
                ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
                ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_FWA_ANTICHURN,
                ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
                ui_item_text: buttonText,
              }}
              onClick={() => {
                setShowSelectAccount(true);
              }}
            >
              {buttonText}
            </Button>
          </Container>
        </Container>
      </InteractiveCard>
    );
  }

  const userToSelect = fwaAntichurn.usersWithAntichurn?.find((customerId) => customerId);
  return (
    <LinkCard
      href={changeBroadbandUrl}
      isInternalNavigation={false}
      floatButtonRight
      linkText={buttonText}
      hideLinkIcon={false}
      onLinkNavigation={() => {
        pushGenericTrackingEvent({
          ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
          ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
          ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_FWA_ANTICHURN,
          ui_item_url: changeBroadbandUrl,
          ui_item_text: buttonText,
        });
        userToSelect && changeFixedAccount(userToSelect.customerId);
      }}
      lozenge={lozenge}
    >
      <Content heading={content.title} text={content.text} />
    </LinkCard>
  );
}
type ContentProps = {
  text: string;
  heading: string;
};

function Content({ heading, text }: ContentProps) {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  useEffect(() => {
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.VIEW,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_FWA_ANTICHURN,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.ORDER_TELIA_PLAY,
    });
  }, []);

  return (
    <div>
      <Heading tag="h3" variant="title-75" ellipsisOverflow>
        {heading}
      </Heading>
      <Paragraph>{text}</Paragraph>
    </div>
  );
}

export function useFwaAntichurn() {
  const fwaAntichurn = useFetchAntichurn();
  const user = useFetchUser();

  if (fwaAntichurn.isLoading || user.isLoading) {
    return { ...fwaAntichurn, isLoading: true, showFwaAntichurn: false } as const;
  }
  if (fwaAntichurn.isError || user.isError) {
    return {
      ...fwaAntichurn,
      isError: true,
      error: fwaAntichurn.error || user.error,
      showFwaAntichurn: false,
    } as const;
  }

  const customerIdsThatCanGetNewFwa = fwaAntichurn.data
    ? Object.keys(fwaAntichurn.data).filter((customerId) => {
        const { recommendNewFwa } = fwaAntichurn.data?.[customerId] || {};
        return recommendNewFwa;
      })
    : null;

  const usersThatCanGetNewFwa = customerIdsThatCanGetNewFwa
    ?.map((customerId) => {
      return user.data?.usersWithCustomerId.find((user) => user.customerId === customerId);
    })
    .filter((user): user is types.fixed.UserWithCustomerId => user !== undefined);

  const atLeastOneUserCanGetNewFwa = (usersThatCanGetNewFwa?.length || 0) > 0;
  const canKeepSpeed = fwaAntichurn.data && Object.values(fwaAntichurn.data).filter((fwa) => fwa.canKeepSpeed).length;
  const allAccountsThatCanGetNewFwaCanAlsoKeepTheirOldSpeed = customerIdsThatCanGetNewFwa?.length === canKeepSpeed;

  return {
    shouldSeeRecommendNew: atLeastOneUserCanGetNewFwa && !allAccountsThatCanGetNewFwaCanAlsoKeepTheirOldSpeed,
    shouldSeeCanKeepSpeed: atLeastOneUserCanGetNewFwa && allAccountsThatCanGetNewFwaCanAlsoKeepTheirOldSpeed,
    usersWithAntichurn: usersThatCanGetNewFwa,
    showFwaAntichurn: config.showFwaAntichurn && atLeastOneUserCanGetNewFwa,
    ...fwaAntichurn,
  } as const;
}
