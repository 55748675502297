import { useRef } from "react";
import { Link } from "react-router-dom";
import { NetworkStatus, useQuery } from "@apollo/client";
import { format, track, uri } from "@telia-no-min-side/utils";
import { Card, Flex, Text, Box, Badge, BadgeProps, Icon, IconName, Button } from "@telia/teddy";
import { getDataBoostRemainingTime } from "src/components/data-usage-gauge-meter/utils";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { getConvergentBenefitAmount } from "src/util/format-subscription/getConvergentBenefitAmount";
import { DataUsageGaugeMeter, subscriptionUsageFragment } from "../../../../components/data-usage-gauge-meter";
import { getFragmentData } from "gql";
import { OfferingPriceCodes, SubscriptionStatus } from "gql/graphql";
import { getSimplePriceText } from "../../../../util/priceFormatter";
import { ErrorCard } from "../ErrorCard";
import { LoadingSubscriptionCard } from "../LoadingSubscriptionCard";
import { getIncludedData, getProductNameFromSubscription, isDataIncludedValid } from "../util";
import { useMobileAccount } from "hooks/useMobileAccount";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";
import { getDataAmount } from "util/format-subscription/getDataAmount";
import { isSummerCampaignDoubleDataActivated } from "util/format-subscription/isSummerCampaignDoubleDataActivated";
import { config } from "util/config";
import { GET_DETAILED_SUBSCRIPTION_QUERY } from "./graphql/getDetailedSubscriptionQuery";

type Props = {
  phoneNumber: string;
  isB2B?: boolean;
  dataTrackingID?: string;
  accountId: string;
};

export function SubscriptionCard({ phoneNumber, accountId, isB2B, dataTrackingID }: Props) {
  const { orders } = useOrderSubscription();
  const { setPhoneNumber, setAccountId } = useMobileAccount();
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const isMobileNumber = format.isMobilePhoneNumber(phoneNumber);

  const additionalLinkRef = useRef<HTMLAnchorElement>(null);

  function handlePreNavigationClick() {
    setAccountId(accountId);
    setPhoneNumber(phoneNumber);
  }
  const detailedSubscription = useQuery(GET_DETAILED_SUBSCRIPTION_QUERY, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
  });

  if (detailedSubscription.error && !detailedSubscription.data) {
    return (
      <ErrorCard
        sectionText={
          <>
            abonnement til {isMobileNumber ? "telefonnummer" : "bredbåndsnummer"} <em data-di-mask>{phoneNumber}</em>
          </>
        }
        onRefreshClick={() => detailedSubscription.refetch({ phoneNumber })}
        isLoading={detailedSubscription.networkStatus === NetworkStatus.refetch}
      />
    );
  }

  if (detailedSubscription.loading && !detailedSubscription.data) {
    return <LoadingSubscriptionCard isGaugeMeter />;
  }

  if (!detailedSubscription.data?.subscription) return null;
  const { subscription } = detailedSubscription.data;
  const { productUser, additionalProducts, userOffering } = subscription;
  const { usage } = getFragmentData(subscriptionUsageFragment, subscription);

  const isSubscriptionSuspended =
    subscription?.subscriptionStatus === SubscriptionStatus.Suspended && !!subscription.productUser;

  const activeDataIncludedProduct = additionalProducts?.find(
    (additionalProduct) =>
      additionalProduct.presentation?.active &&
      additionalProduct.productTerms?.some((productTerm) => productTerm?.code === OfferingPriceCodes.DataIncluded)
  );

  const dataIncluded = getIncludedData(subscription.userOffering);
  const isDataSpecificSubscription = isDataIncludedValid(dataIncluded);
  const hasDataBoost = Boolean(usage && getDataBoostRemainingTime(usage)?.length);
  const monthlyCost =
    typeof subscription.userOffering?.monthlyCost?.amount === "number"
      ? getSimplePriceText(subscription.userOffering.monthlyCost.amount, "md.")
      : null;

  const showSummerCampaignBadge =
    (!config.disableSummerCampaignDoubleData && isJuniorSummerCampaign(userOffering?.pricePlan || "")) ||
    isSummerCampaignDoubleDataActivated(subscription.additionalProducts || []);

  const { isSummerCampaignActive, formattedDataAmountTotal, summerCampaignDataAmount, summerCampaignDataAmountUnit } =
    getDataAmount(additionalProducts || [], userOffering?.offeringPrices);

  const convergentBenefit = getConvergentBenefitAmount(additionalProducts || []);

  const showBenefit = convergentBenefit.hasDoubleData || convergentBenefit.hasDoubleSpeed;
  const isSleepModeMbbSubscription = detailedSubscription.data?.subscription?.userOffering?.name === "Hvilemodus";

  const getBadgeDetails = (): { icon?: IconName; label: string; variant: BadgeProps["variant"] } | undefined => {
    const hasOrder = orders.find((order) => order.phoneNumber === phoneNumber);
    if (hasOrder) {
      return { icon: "sync", variant: "information", label: "Oppdateres" };
    }
    if (isSleepModeMbbSubscription) {
      return { icon: "coffee", variant: "information", label: "Hvilemodus aktivert" };
    }
    if (showSummerCampaignBadge) {
      return { icon: "weather", variant: "warning", label: "Dobbel data" };
    }
    if (activeDataIncludedProduct || showBenefit) {
      const label = isSummerCampaignActive || convergentBenefit.hasDoubleData ? "Dobbel data" : "Dobbel hastighet";
      return { variant: "information", label };
    }
    return undefined;
  };

  const badgeDetails = getBadgeDetails();
  const hideSimPinPukButton = isSubscriptionSuspended || isSleepModeMbbSubscription;
  const subscriptionRequiresTopup = isDataSpecificSubscription && !hasDataBoost;

  const simPinPukButtonHref = isMobileNumber
    ? uri.minSideMobile("/sim-pin-puk")
    : uri.minSideFixed("/internett/mobilt-bredband/sim-pin-puk");

  const handleSimPinPukClick = () => {
    handlePreNavigationClick();

    pushGenericTrackingEvent({
      ui_item_action: "link_click",
      ui_item_context: "Hjem",
      ui_item_type: "link",
      ui_item_url: simPinPukButtonHref,
      ui_item_text: "SIM/PIN/PUK",
    });
  };

  const getLeftButtonProps = () => {
    if (isSubscriptionSuspended) {
      return {
        label: "Se mer",
        href: uri.minSideFixed(isMobileNumber ? "/mobil" : "/internett/mobilt-bredband"),
      };
    }
    if (isSleepModeMbbSubscription) {
      return {
        label: "Deaktiver hvilemodus",
        href: uri.minSideFixed(
          `/mobilt-bredband/endre/${detailedSubscription.data?.subscription?.account?.id}/${phoneNumber}`
        ),
      };
    }
    if (subscriptionRequiresTopup) {
      return {
        label: "Fyll på data",
        href: uri.minSideFixed(isMobileNumber ? "/mobil#data-pakker" : "/internett/mobilt-bredband"),
      };
    }

    return {
      label: "Se dataforbruk",
      href: uri.minSideFixed(isMobileNumber ? "/mobil" : "/internett/mobilt-bredband"),
    };
  };

  const leftButtonProps = getLeftButtonProps();

  return (
    <Card
      shadow
      bordered
      variant="white"
      layout="default"
      width="100%"
      maxWidth="400px"
      data-tracking-id={dataTrackingID}
      onPointerUp={(e) => {
        if (additionalLinkRef.current === e.target) {
          e.preventDefault();
        }
      }}
    >
      {isB2B && (
        <Card.Slot align="top-left">
          <Badge variant="information">
            <Icon name="b2b-customer" />
            Bedriftskunde
          </Badge>
        </Card.Slot>
      )}

      {badgeDetails && (
        <Card.Slot align="top-right">
          <Badge variant={badgeDetails?.variant} hideIcon>
            {badgeDetails.icon && <Icon name={badgeDetails.icon} />}
            {badgeDetails.label}
          </Badge>
        </Card.Slot>
      )}

      <Flex direction="column" height="100%" gap="100">
        <Flex direction="row" gap="200">
          <Box width="90px">
            <DataUsageGaugeMeter
              isTeliaX={subscription.userOffering?.name?.toLocaleLowerCase().includes("telia x") || false}
              isError={!!detailedSubscription.error?.graphQLErrors.length}
              subscriptionUsage={subscription}
              unlimitedSpeedAmount={convergentBenefit.amount}
              disabled={isSubscriptionSuspended}
              summerCampaignDataAmount={isSummerCampaignActive ? summerCampaignDataAmount || undefined : undefined}
              summerCampaignDataAmountUnit={
                isSummerCampaignActive ? summerCampaignDataAmountUnit || undefined : undefined
              }
            />
          </Box>

          <Flex direction="column" width="100%" gap="100">
            <Flex direction="row" justify="between" height="100%" width="100%">
              <Card.Heading as="h3" variant="title-100" data-di-mask>
                {!isMobileNumber
                  ? "Mobilt bredbånd"
                  : format.startCase(
                      isB2B ? `${productUser?.firstName} ${productUser?.surname}` : `${productUser?.firstName}`
                    )}
              </Card.Heading>
              {!isB2B && <Text>{monthlyCost}</Text>}
            </Flex>

            <Flex direction="row" justify="between" height="100%" width="100%">
              <Text data-di-mask>
                <i>{format.formatPhoneNumber(phoneNumber)}</i>
              </Text>

              {activeDataIncludedProduct ? (
                <Flex direction="row" gap="100">
                  <Text faded>
                    <s>{getProductNameFromSubscription(subscription)}</s>
                  </Text>
                  <Text faded={isSubscriptionSuspended}>{formattedDataAmountTotal}</Text>
                </Flex>
              ) : (
                <Text faded={isSubscriptionSuspended}>{getProductNameFromSubscription(subscription)}</Text>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="row" justify="between" height="100%" width="100%" align="end">
          {!hideSimPinPukButton && (
            <Button variant="text" asChild data-tracking-id="homepage-sim-pin-puk">
              <Link
                ref={additionalLinkRef}
                to={
                  isMobileNumber
                    ? uri.minSideMobile("/sim-pin-puk")
                    : uri.minSideFixed("/internett/mobilt-bredband/sim-pin-puk")
                }
                onClick={handleSimPinPukClick}
              >
                SIM/PIN/PUK
              </Link>
            </Button>
          )}
          <Card.Button variant="text" asChild>
            <Link onClick={handlePreNavigationClick} to={leftButtonProps.href}>
              {leftButtonProps?.label}
            </Link>
          </Card.Button>
        </Flex>
      </Flex>
    </Card>
  );
}
