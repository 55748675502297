/* eslint-disable */
(function (i, s, o, g, r, a, m) {
  i["GoogleAnalyticsObject"] = r;
  (i[r] =
    i[r] ||
    function () {
      (i[r].q = i[r].q || []).push(arguments);
    }),
    (i[r].l = 1 * new Date());
  (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m);
})(window, document, "script", "//www.google-analytics.com/analytics.js", "ga");
/* eslint-enable */

window.ga("create", "UA-17826252-8", "auto");

const Analytics = {
  trackPageView(pathName) {
    window.ga("set", "page", pathName);
    window.ga("send", "pageview");
  },

  trackTabBar(text) {
    window.ga("send", "event", "Tab Bar", "Open", text);
  },

  trackCustomerID(customerId) {
    window.ga("set", "userId", customerId);
    window.ga("set", "dimension1", customerId);
    window.ga("send", "dimension1");
  },

  trackEvent(category, action, label) {
    window.ga("send", "event", category, `${action}`, label);
  },
};

export default Analytics;
