import { Maybe, OfferingPriceDetails, OfferingPriceCodes } from "gql/graphql";
import { isMbbSleepModeOffer } from "./isMbbSleepModeOffer";

export function isMbbUnlimitedDataOffer(
  offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined,
  shortName?: Maybe<string>
) {
  if (isMbbSleepModeOffer(shortName) || !offeringPrices) {
    return false;
  }

  return (
    offeringPrices.filter(
      (prop) => prop?.code === OfferingPriceCodes.SpeedDownloadReduced && prop?.monetaryUnit !== "KBIT"
    ).length > 0
  );
}
