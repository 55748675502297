import { useState } from "react";
import { track } from "@telia-no-min-side/utils";

export type Options = {
  /**
   * Tracking when toggle is opened
   */
  onOpenTracking?: track.GenericEvent;
  /**
   * Tracking when toggle is closed
   */
  onCloseTracking?: track.GenericEvent;
};

export function useToggleWithGaTracking(initialState: boolean, options: Options) {
  const [isOpen, setIsOpen] = useState(initialState);
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { onOpenTracking, onCloseTracking } = options;

  function toggleIsOpen() {
    setIsOpen((prevState) => {
      if (prevState && onCloseTracking) {
        pushGenericTrackingEvent({ ...onCloseTracking });
      }
      if (!prevState && onOpenTracking) {
        pushGenericTrackingEvent({ ...onOpenTracking });
      }
      return !prevState;
    });
  }

  return { isOpen, toggleIsOpen, setIsOpen };
}
