import { ApolloError, MutationResult, useMutation } from "@apollo/client";
import { graphql } from "src/gql";
import { UpdateDirectoryListingMutation, UpdateDirectoryListingMutationVariables } from "src/gql/graphql";

export const UPDATE_DIRECTORY_LISTING = graphql(`
  mutation updateDirectoryListing($phoneNumber: String!, $directoryListingLevel: String!) {
    updateDirectoryListing(phoneNumber: $phoneNumber, directoryListingLevel: $directoryListingLevel) {
      orderId
    }
  }
`);

export type PublishLevelCode = "Z" | "H" | "X" | "W";

const getError = (error: ApolloError | undefined) => {
  if (error) {
    switch (error.message) {
      case "TELEPHONE_NUMBER_IN_USE_ON_ORDER_IN_PROGRESS":
        return "En bestilling er allerede under behandling, vennligst prøv igjen senere.";
      case "UNKNOWN_ERROR":
        return "En ukjent feil oppstod. Vennligst prøv igjen.";
      default:
        return "En feil oppstod. Vennligst prøv igjen.";
    }
  }
};

export type IuseUpdateDirectoryListing = [
  MutationResult<UpdateDirectoryListingMutation>,
  ({ phoneNumber, directoryListingLevel }: UpdateDirectoryListingMutationVariables) => Promise<void>,
];

export function useUpdateDirectoryListing(): IuseUpdateDirectoryListing {
  const [directoryListingUpdate, response] = useMutation<UpdateDirectoryListingMutation>(UPDATE_DIRECTORY_LISTING);

  const runUpdate = async ({ phoneNumber, directoryListingLevel }: UpdateDirectoryListingMutationVariables) => {
    const updateData: UpdateDirectoryListingMutationVariables = {
      phoneNumber,
      directoryListingLevel,
    };

    try {
      await directoryListingUpdate({ variables: updateData });
    } catch (error) {
      console.error(error);
    }
  };

  const updateResponse = {
    ...response,
    formattedError: getError(response?.error),
  };

  return [updateResponse, runUpdate];
}
