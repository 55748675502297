import React, { BaseSyntheticEvent, ReactNode } from "react";
import { track } from "@telia-no-min-side/utils";
import { Icon } from "../Icon";
import { useExpandableCard } from "./Context";
import { Arrow, ExpandableCardTrigger, MainTriggerContent } from "./style";

export type TriggerProps = {
  children?: (({ isOpen }: { isOpen: boolean }) => ReactNode) | ReactNode;
  overLine?: (({ isOpen }: { isOpen: boolean }) => ReactNode) | ReactNode;
  className?: string;
};

/**
 * @deprecated Use Teddy components instead
 */
export function Trigger(props: TriggerProps): JSX.Element {
  const { id, isOpen, onClick, tracking, icon, compact } = useExpandableCard();
  const { pushGenericTrackingEvent } = track.useEventTracking();

  function expandableOnClick(e: BaseSyntheticEvent) {
    if (onClick) {
      onClick(e);
    }
    if (tracking) {
      pushGenericTrackingEvent(isOpen ? tracking.close : tracking.open);
    }
  }

  return (
    <ExpandableCardTrigger
      aria-controls={`expandable-card-body-${id}`}
      aria-expanded={isOpen}
      onClick={expandableOnClick}
      id={`expandable-card-header-${id}`}
      compact={compact}
    >
      {typeof props.overLine === "function" ? props.overLine({ isOpen }) : props.overLine}
      <MainTriggerContent>
        <div className={props.className}>
          {typeof props.children === "function" ? props.children({ isOpen }) : props.children}
        </div>
        <Arrow isOpen={isOpen && icon === "chevron-down"}>
          <Icon icon={icon} color="black" size="sm" />
        </Arrow>
      </MainTriggerContent>
    </ExpandableCardTrigger>
  );
}
