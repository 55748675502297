import { Heading } from "@telia/styleguide";
import { Button } from "@telia/styleguide";
import { Spinner } from "../../../../components/spinner-telia/Spinner";
import { RouterSettingsActions } from "../../actions/RouterSettingActions";
import { RowVertical } from "../../modules/LayoutComponents/RowVertical";
import { ResponseMessage } from "../../modules/ResponseMessage";
import { useRouterSettings } from "../../stores/RouterSettingsProvider";
import { routerAnalytics } from "../../util/RouterSettingsAnalytics";
import { wifiSettingsTags, wiFiSettingsUtilities } from "../../util/WiFiSettingsUtilities";

import "./WiFiSettings.less";
import { WifiSettingsCard } from "./WifiSettingsCard";

const Container = ({ children }) => <div className="wifi-settings-container">{children}</div>;

const WiFiSettings = () => {
  const { routerSettings, dispatch } = useRouterSettings();
  const { updateStatus, updateResponse, activeUnit, devices } = routerSettings;
  const homeCentral = devices?.find((device) => "Main" === device.DeviceType);

  const updateActiveStatusForWiFi = (frequency) => {
    const updatedDevice = wiFiSettingsUtilities.setActiveStatusForWiFi(activeUnit, frequency);
    const tag = frequency === "2.4" ? wifiSettingsTags.Wifi2GhzActiveStatus : wifiSettingsTags.Wifi5GhzActiveStatus;

    if (!updateStatus.updating) {
      RouterSettingsActions.updateDevice(dispatch, {
        device: updatedDevice,
        tag: tag,
      });
      routerAnalytics.trackStoreClick(tag);
    }
  };

  const updateWiFiSettings = (frequency, updatedWifiObject) => {
    const updatedDevice = wiFiSettingsUtilities.setAdditionalSettingsForWiFi(activeUnit, frequency, updatedWifiObject);
    const tag = frequency === "2.4" ? wifiSettingsTags.Wifi2GhzSettings : wifiSettingsTags.Wifi5GhzSettings;

    RouterSettingsActions.updateDevice(dispatch, {
      device: updatedDevice,
      tag: tag,
    });
    routerAnalytics.trackStoreClick(tag);
  };

  const setSSIDAndPassword = (wifiFrequency, SSID, Password) => {
    const updatedDevice = wiFiSettingsUtilities.setSSIDAndPasswordForWifi(activeUnit, wifiFrequency, SSID, Password);
    const tag =
      wifiFrequency === "2.4" ? wifiSettingsTags.SetSsidAndPassword2Ghz : wifiSettingsTags.SetSsidAndPassword5Ghz;

    if (!updateStatus.updating) {
      RouterSettingsActions.updateDevice(dispatch, {
        device: updatedDevice,
        tag: tag,
      });
      routerAnalytics.trackStoreClick(tag);
    }
  };
  // Only used for extenders.
  const copySettingsFromHomeCentral = () => {
    const updatedExtender = wiFiSettingsUtilities.copySettingsFromHomeCentral(homeCentral, activeUnit);

    if (!updateStatus.updating) {
      RouterSettingsActions.updateDevice(dispatch, {
        device: updatedExtender,
        tag: wifiSettingsTags.CopySettingsFromHomeCentral,
      });
      routerAnalytics.trackStoreClick(wifiSettingsTags.CopySettingsFromHomeCentral);
    }
  };
  // Set same password for both networks
  const setSamePasswordForBothNetworks = (frequency) => {
    const updatedDevice = wiFiSettingsUtilities.setSamePasswordForWifiNetworks(activeUnit, frequency);

    if (!updateStatus.updating) {
      if (frequency === "2.4") {
        RouterSettingsActions.updateDevice(dispatch, {
          device: updatedDevice,
          tag: wifiSettingsTags.SetSameSSIDAndPassword2GHz,
        });
        routerAnalytics.trackStoreClick(wifiSettingsTags.SetSameSSIDAndPassword2GHz);
      } else {
        RouterSettingsActions.updateDevice(dispatch, {
          device: updatedDevice,
          tag: wifiSettingsTags.SetSsidAndPassword5Ghz,
        });
        routerAnalytics.trackStoreClick(wifiSettingsTags.SetSameSSIDANdPassword5GHz);
      }
    }
  };

  if (!activeUnit) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
        }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <Container>
      {activeUnit.DeviceType === "Extender" && <Heading tag="h2" size="s" text="Trådløse nett" />}
      {activeUnit.SupportedSettings.Wifi5GHz.Supported && (
        <WifiSettingsCard
          key={1}
          frequency={"5"}
          wifi={activeUnit.Settings.Wifi5GHz}
          supportedSettings={activeUnit.SupportedSettings.Wifi5GHz}
          callBackForSettingsClick={updateWiFiSettings}
          callBackForSSIDPWClick={setSSIDAndPassword}
          callBackForWifiToggle={updateActiveStatusForWiFi}
          setSamePasswordForNetworks={setSamePasswordForBothNetworks}
          ssidForOtherFrequency={activeUnit.SupportedSettings.Wifi2GHz.Supported && activeUnit.Settings.Wifi2GHz.Ssid}
        />
      )}
      {activeUnit.SupportedSettings.Wifi2GHz.Supported && (
        <WifiSettingsCard
          key={0}
          frequency={"2.4"}
          wifi={activeUnit.Settings.Wifi2GHz}
          supportedSettings={activeUnit.SupportedSettings.Wifi2GHz}
          callBackForSettingsClick={updateWiFiSettings}
          callBackForSSIDPWClick={setSSIDAndPassword}
          callBackForWifiToggle={updateActiveStatusForWiFi}
          setSamePasswordForNetworks={setSamePasswordForBothNetworks}
          ssidForOtherFrequency={activeUnit.SupportedSettings.Wifi5GHz.Supported && activeUnit.Settings.Wifi5GHz.Ssid}
        />
      )}
      {activeUnit.DeviceType === "Extender" && (
        <div style={{ marginBottom: "4rem" }}>
          <RowVertical>
            Vil du at denne enheten skal bruke samme nettverksnavn, passord og innstillinger som hjemmesentralen din?
            <div style={{ margin: "1rem 0" }} />
            <Button
              processingText={"Kopierer fra hjemmesentral"}
              isProcessing={updateStatus.tag === wifiSettingsTags.CopySettingsFromHomeCentral && updateStatus.updating}
              text={"Kopier fra hjemmesentral"}
              onClick={copySettingsFromHomeCentral}
            />
          </RowVertical>
        </div>
      )}
      <RowVertical>
        <ResponseMessage updateResponse={updateResponse} tag={wifiSettingsTags.CopySettingsFromHomeCentral} />
      </RowVertical>
    </Container>
  );
};

export default WiFiSettings;
