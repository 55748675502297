import { Text, Flex } from "@telia/teddy";
import { MainCardContainer, PriceCardContainer } from "./style";

export function Prices() {
  const priceList = [
    {
      title: "Oppstart per samtale",
      price: "0,99 kr per stk.",
    },
    {
      title: "Minuttpris",
      price: "0,89 kr per min.",
    },
    {
      title: "Sende SMS",
      price: "0,69 kr per stk.",
    },
    {
      title: "Sende MMS",
      price: "1,99 kr per stk.",
    },
  ];

  return (
    <MainCardContainer variant="white" shadow bordered>
      <Text variant="preamble-100">Telefonipriser i Norge</Text>
      <PriceCardContainer variant="white" shadow bordered>
        {priceList.map((price) => (
          <Flex key={price.title} p="100" width="100%" justify="between">
            <Text>{price.title}</Text>
            <Text>{price.price}</Text>
          </Flex>
        ))}
      </PriceCardContainer>
    </MainCardContainer>
  );
}
