import { Box, Icon, IconName, Link, List } from "@telia/teddy";
import { MOBILE_VAS } from "util/constants/valueAddedServices";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

type Props = {
  productType: MOBILE_VAS;
  isActive?: boolean;
};

const hbo_features: { icon: IconName; shortDescription: string }[] = [
  { icon: "entertainment", shortDescription: "Tilgang til populære filmer, serier og eksklusivt originalinnhold." },
  {
    icon: "star",
    shortDescription:
      "Strøm ikoniske serier, storfilmer, nye norske serier, direktesendt sport, Harry Potter, HBO og Discovery, samt populære TV-kanaler som TVNorge, FEM og REX.",
  },
  { icon: "stop", shortDescription: "Ingen bindingstid" },
];

const storytel_features: { icon: IconName; shortDescription: string }[] = [
  { icon: "education", shortDescription: "Velg blant 120 000 lydbøker og e-bøker" },
  { icon: "switch-arrows", shortDescription: "Bytt enkelt mellom lesing og lytting" },
  { icon: "wireless-off", shortDescription: "Strøm eller last ned offline" },
  { icon: "stop", shortDescription: "Ingen bindingstid" },
];

export const ProductCardInfo = ({ productType, isActive }: Props) => {
  if (productType === MOBILE_VAS.HBO) {
    return (
      <>
        <List gapSize="small" mb="gutter-md" pt="gutter-xs">
          {hbo_features.map((feature) => (
            <List.Item key={feature.icon}>
              <List.Illustration>
                <Icon name={feature.icon} />
              </List.Illustration>
              <List.Content>{feature.shortDescription}</List.Content>
            </List.Item>
          ))}
        </List>
        {!isActive && (
          <Box mt="200">
            <Link variant="standalone" href={EXTERNAL_LINK.TELIA_PLAY_MAX} target="_blank">
              <Icon size="sm" name="arrow-right" />
              Les mer om Max
            </Link>
          </Box>
        )}
      </>
    );
  }
  if (productType === MOBILE_VAS.STORYTEL) {
    return (
      <>
        <List gapSize="small" mb="gutter-md" pt="gutter-xs">
          {storytel_features.map((feature) => (
            <List.Item key={feature.icon}>
              <List.Illustration>
                <Icon name={feature.icon} />
              </List.Illustration>
              <List.Content>{feature.shortDescription}</List.Content>
            </List.Item>
          ))}
        </List>

        {!isActive && (
          <Box mt="200">
            <Link variant="standalone" href={EXTERNAL_LINK.ADDITIONAL_SERVICES_STORYTEL} target="_blank">
              <Icon size="sm" name="arrow-right" />
              Les mer om Storytel
            </Link>
          </Box>
        )}
      </>
    );
  }
  return null;
};
