import { useQuery } from "@apollo/client";
import { Alert, Container, Skeleton } from "@telia-no-min-side/components";
import { GET_ALL_ACCOUNTS_AGREEMENTS } from "pages/mobile/mobile-subscription-agreements/graphql/getAllAccountsAgreements";
import { LeaseAgreements } from "../lease/LeaseAgreements";
import { InsuranceAgreements } from "../insurance/InsuranceAgreements";
import { getAllUniqueSwitchAgreements } from "pages/mobile/mobile-subscription-agreements/utils/getAllUniqueSwitchAgreements";
import { notEmpty } from "@telia-no-min-side/utils";
import { SwitchAgreements } from "../switch/SwitchAgreements";
import { AgreementError } from "../AgreementError";

type Props = {
  seeAll: boolean;
};

export function SeeAllAgreements({ seeAll }: Props) {
  const { loading, data, error, refetch } = useQuery(GET_ALL_ACCOUNTS_AGREEMENTS, {
    skip: !seeAll,
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });

  const hasSwitchAgreements = !!data?.accounts?.flatMap((account) => account.switchAgreements).filter(notEmpty).length;
  const hasLeaseAgreements = !!data?.accounts?.flatMap((account) => account.leaseAgreements?.leases).filter(notEmpty)
    .length;
  const hasInsuranceAgreements = !!data?.accounts?.flatMap((account) => account.insuranceAgreements).filter(notEmpty)
    .length;

  const hasNoAgreements = !hasSwitchAgreements && !hasLeaseAgreements && !hasInsuranceAgreements;

  const uniqueSwitchAgreements = data?.accounts?.map((account) => getAllUniqueSwitchAgreements(account));
  const switchAgreementsMap = new Map();

  uniqueSwitchAgreements?.forEach((agreement) => {
    agreement.forEach((value, key) => switchAgreementsMap.set(key, value));
  });

  const switchAgreements =
    switchAgreementsMap && switchAgreementsMap?.size > 0 ? [...switchAgreementsMap.values()] : [];

  const leaseAgreements = data?.accounts?.flatMap((account) => account.leaseAgreements?.leases).filter(notEmpty);
  const insuranceAgreements = data?.accounts?.flatMap((account) => account.insuranceAgreements).filter(notEmpty);

  if (loading) {
    return (
      <Container flexDirection="column" gap="lg">
        <Skeleton variant="rectangular" height="11rem" width="37.5rem" />
        <Skeleton variant="rectangular" height="11rem" width="37.5rem" />
      </Container>
    );
  }

  if (error && !data) {
    return <AgreementError refetch={refetch} />;
  }

  if (hasNoAgreements && !loading) {
    return (
      <Container maxWidth="sm">
        <Alert
          kind="info"
          isOpen
          icon="info-filled"
          title="Ingen avtaler funnet"
          text="Dette abonnementet har ingen tilknyttede avtaler."
          dataTrackingId="alert-user-without-agreements"
        />
      </Container>
    );
  }

  return (
    <Container flexDirection="column" gap="lg">
      {hasSwitchAgreements && <SwitchAgreements switchAgreements={switchAgreements} />}
      {hasLeaseAgreements && <LeaseAgreements leaseAgreements={leaseAgreements} />}
      {hasInsuranceAgreements && <InsuranceAgreements insuranceAgreements={insuranceAgreements} />}
    </Container>
  );
}
