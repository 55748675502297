import { AdditionalProduct, OrderOp } from "src/gql/graphql";
import { isPurchased } from "../../utils/product";

export const getTeliaSkyOrderInput = (selectedProduct: AdditionalProduct, email: string) => {
  return {
    productName: selectedProduct.code,
    operation: isPurchased(selectedProduct) ? OrderOp.RemoveAdditionalProduct : OrderOp.AddAdditionalProduct,
    productCharacteristics: {
      config: [
        {
          name: "EMAIL_ADDRESS",
          value: email || "",
        },
      ],
      featureCode: "F-SKY",
    },
  };
};
