import { graphql } from "src/gql";

export const GET_PREPAID_USAGES = graphql(`
  query getPrepaidUsages($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      paymentBalance {
        amount
      }
      usage {
        unit
        type
        usage
        usedDurationNormalised {
          hours
          minutes
          seconds
        }
      }
      usageTopup {
        topupQuotas {
          unit
          type
          expireTime
          category
          service
          allowanceType
          usage {
            total {
              amount
              unit
              normalised {
                amount
                unit
              }
            }
            remaining {
              amount
              unit
              normalised {
                amount
                unit
              }
            }
          }
          product {
            isNordicAndBalticMinutes
            topupPackageType
            size {
              unit
              amount
            }
          }
        }
        totalQuotas {
          sms {
            total {
              amount
              unit
            }
            remaining {
              amount
              unit
            }
          }
          mms {
            total {
              amount
              unit
            }
            remaining {
              amount
              unit
            }
          }
          data {
            total {
              amount
              unit
            }
            remaining {
              amount
              unit
            }
          }
          domesticVoice {
            total {
              amount
              unit
            }
            remaining {
              amount
              unit
            }
          }
        }
        unlimitedQuotas {
          sms
          mms
          data
          voice
        }
      }
    }
  }
`);
