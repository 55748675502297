import styled from "styled-components";
import { Button as TeliaButton } from "../Button";
import { Card } from "../Card";
import { devices } from "../utils/breakpoints";
import { remCalc } from "../utils/fonts";

export const Component = styled(Card)`
  position: relative;
  padding: ${remCalc(12)} ${remCalc(16)};

  display: flex;
  flex-direction: column;
  gap: ${remCalc(12)};

  @media ${devices.md_up} {
    flex-direction: row;
    justify-content: space-between;
    padding: ${remCalc(24)} ${remCalc(32)};
  }
`;

export const Description = styled.div`
  margin: 0;
  font-size: ${remCalc(14)};
`;

export const Footer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media ${devices.md_up} {
    min-width: ${remCalc(190)};
  }
`;

export const PriceContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${devices.md_up} {
    row-gap: ${remCalc(5)};
  }
`;

export const Price = styled.h3`
  margin: 0;
  font-size: ${remCalc(18)};
  font-weight: 500;
`;

export const Button = styled(TeliaButton)`
  align-self: flex-end;
`;
