import { uri } from "@telia-no-min-side/utils";
import { Flex, Heading, Button, Text, Icon } from "@telia/teddy";
import { Link } from "react-router-dom";
import { CustomerCareLink } from "src/components/customer-care-link";

export function ReceiptError({ retry, isRetrying }: { retry?: () => void; isRetrying?: boolean }) {
  return (
    <Flex direction="column" gap="200">
      <Heading as="h2" variant="title-200">
        Noe gikk galt!
      </Heading>
      <Text>
        Vi kunne dessverre ikke behandle forespørselen din og fikk heller ikke opprettet en manuell sak for oppfølging.
        Vennligst prøv igjen, eller kontakt vår kundeservice:
      </Text>
      <CustomerCareLink trackingContext="buy-tv-subscription-fwa" />
      <Flex pt="gutter-sm" pb="gutter-sm" gap="200">
        <Button variant="secondary" onClick={retry} loading={isRetrying}>
          Prøv igjen
        </Button>
        <Button asChild>
          <Link to={uri.minSideFixed("/hjem")}>
            <Icon name="home" />
            Tilbake til Hjem
          </Link>
        </Button>
      </Flex>
    </Flex>
  );
}
