import { colors } from "@telia/styleguide";
import { ProductChangesType } from "../type";
import { toProductChangesMap } from "../utils/toProductChangesMap";
import { ProductChangesList } from "./ProductChangesList";
import { SimChanges } from "./SimChanges";

type Props = {
  productChanges: ProductChangesType;
};

export function ProductChangesSection({ productChanges }: Props) {
  if (!productChanges) return null;

  const productChangesMap = toProductChangesMap(productChanges);

  return (
    <>
      {productChangesMap?.simProducts ? (
        <SimChanges
          newPrice={productChangesMap.simProducts.newPrice}
          oldPrice={productChangesMap.simProducts.oldPrice}
        />
      ) : null}
      <ProductChangesList
        icon="add"
        iconColor={colors.green}
        title="Produkter og tjenester som er inkludert"
        subTitle="Dette er tjenestene du får når du endrer til ditt nye abonnement."
        products={productChangesMap?.addedProducts}
      />
      <ProductChangesList
        icon="question"
        iconColor={colors.orange}
        title="Produkter og tjenester som endres"
        subTitle="Disse tjenester blir overført til ditt nye abonnement med endringer."
        products={productChangesMap?.changedProducts}
      />
      <ProductChangesList
        icon="stop"
        iconColor={colors.red}
        title="Produkter og tjenester som blir borte"
        subTitle="Disse tjenestene er ikke inkludert i ditt nye abonnement, men kan bestilles i etterkant."
        products={productChangesMap?.lostProducts}
      />
    </>
  );
}
