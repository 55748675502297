import { Accordion, breakpoints, Container, devices, Heading, Paragraph, remCalc } from "@telia-no-min-side/components";
import { colors } from "@telia/styleguide";
import styled, { css } from "styled-components";

export const DetailsAccordionItem = styled(Accordion.Item)`
  box-shadow: none;
  margin: 0;
  border-bottom: 1px solid ${colors.grey100};
  border-radius: 0;
  @media ${devices.lg_up} {
    max-width: ${breakpoints.sm}px;
  }
`;

const accordionRow = css`
  display: grid;
  grid-template-columns: 1fr 1fr 100px;
  align-items: center;
  gap: 0.5rem 1rem;
  width: 100%;
  @media ${devices.sm_down} {
    grid-template-columns: 1fr 100px;
    grid-template-rows: auto auto;
  }
`;

export const StyledAccordionHeader = styled(Accordion.Trigger)`
  ${accordionRow}
  margin-right: 1rem;
  margin-block: 5px;
  h3 {
    margin: 0;
  }
  font-size: 16px;
`;
export const StyledAccordionBody = styled(Accordion.Content)`
  padding: 0;
  font-size: 16px;
`;

export const InvoiceProductList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Dot = styled.div<{ color: string }>`
  position: absolute;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  width: 7px;
  height: 7px;
  left: -16px;
  top: 50%;
  transform: translateY(-50%);
`;

export const InvoiceProduct = styled.li`
  ${accordionRow}
  position: relative;
  padding: 14px 14px 14px 50px;
  &:first-of-type {
    border-top: 1px solid ${colors.grey100};
  }
  &:not(&:last-of-type) {
    border-bottom: 1px solid ${colors.grey100};
  }
`;

export const TitleCell = styled.div`
  position: relative;
  @media ${devices.sm_down} {
    grid-area: 2 / 1;
  }
`;

export const DateCell = styled.div`
  @media ${devices.sm_down} {
    grid-area: 1 / 1;
    font-size: 12px;
  }
`;
export const PriceCell = styled.div`
  text-align: end;
  grid-area: 1 / 3;
  @media ${devices.sm_down} {
    grid-area: 1 / 2 / 3 / 2;
  }
`;

export const SumTextCell = styled.div`
  font-weight: bold;
  grid-area: 1 / 1 / 2 / 2;
`;

export const SumCell = styled.div`
  font-weight: bold;
  text-align: end;
  grid-area: 1 / 3 / 3 / 3;
  @media ${devices.sm_down} {
    grid-area: 1 / 2 / 3 / 2;
  }
`;

export const TotalSumGrid = styled(Container)`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 14px 80px 14px 30px;
  font-size: ${remCalc(20)};
  @media ${devices.sm_down} {
    padding: 14px 30px;
  }
`;

const InvoiceDetailsStyled = styled.div<{ customColor?: string }>`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ customColor }) => (customColor ? customColor : colors.black)};

  @media ${devices.xxs_up} {
    font-size: 1.125rem;
  }
  @media ${devices.md_up} {
    font-size: 1.25rem;
  }
`;

export const InvoiceDetailsHeading = styled(InvoiceDetailsStyled).attrs({
  as: Heading,
  tag: "h3",
  variant: "title-150",
})``;
export const InvoiceDetailsParagraph = styled(InvoiceDetailsStyled).attrs({ as: Paragraph })``;

export const Line = styled.div<{ dashed?: boolean }>`
  width: 100%;
  border-top: ${(props) => (props.dashed ? `1px dashed ${colors.grey200}` : `2px solid ${colors.grey200}`)};
`;
