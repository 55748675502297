import { AdditionalProduct } from "gql/graphql";
import { TryggProductPurchased } from "./TryggProductPurchased";
import { TeliaTryggCheckout } from "./TeliaTryggCheckout";

type Props = {
  product: AdditionalProduct;
  phoneNumber: string;
};

export const TeliaTrygg = ({ product, phoneNumber }: Props) => {
  return product?.presentation?.active ? (
    <TryggProductPurchased product={product} phoneNumber={phoneNumber} />
  ) : (
    <TeliaTryggCheckout product={product} phoneNumber={phoneNumber} />
  );
};
