import { Box, breakpoint } from "@telia/teddy";
import { useFetchFlexCurrent, useFetchTvSubscription } from "src/api/fixed";
import { TvInfoCard } from "./TvInfoCard";
import { FlexInfoCard } from "pages/internet/broadband/modules/broadband-dashboard/components";

export function SubscriptionInfo() {
  const tvSubscription = useFetchTvSubscription();
  const tvSubscriptionProduct = tvSubscription.data?.products?.[0];

  const flexCurrent = useFetchFlexCurrent();
  const showFlexCardOnlyIfCustomerHasBothTVandBB =
    flexCurrent.data?.currentTvAndBroadband?.broadbandOption && flexCurrent.data?.currentTvAndBroadband?.tvOption;

  if (!tvSubscriptionProduct && tvSubscription.isError) return null;

  return (
    <Box maxWidth={breakpoint.teddyBreakpointMd}>
      {showFlexCardOnlyIfCustomerHasBothTVandBB ? (
        <FlexInfoCard
          flexCombo={flexCurrent.data}
          isLoading={flexCurrent.isValidating}
          actions={tvSubscriptionProduct?.actions}
        />
      ) : (
        <TvInfoCard subscriptionProduct={tvSubscriptionProduct} isLoading={tvSubscription.isLoading} />
      )}
    </Box>
  );
}
