import type { Dispatch, SetStateAction } from "react";
import { ModalType } from "../components/AdditionalSimCard";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { ActionItem } from "components/action-menu";

export function getActionListForAdditionalSim(
  isAbleTo: Record<string, boolean>,
  setModalType: Dispatch<SetStateAction<ModalType | undefined>>
): ActionItem[] {
  const actionPoints = [
    isAbleTo.orderReplacementSIM && {
      icon: "switch-arrows",
      kind: "link",
      text: "Bestill erstatning",
      isInternalNavigation: true,
      href: INTERNAL_LINK.MOBILE.ORDER_ADDITIONAL_SIM_CARD_PAGE,
    },
    isAbleTo.editNickname && {
      icon: "edit",
      kind: "button",
      text: "Rediger kallenavn",
      onClick: () => setModalType("RENAME_NICKNAME"),
    },
    isAbleTo.addNickname && {
      icon: "edit",
      kind: "button",
      text: "Gi kallenavn",
      onClick: () => setModalType("RENAME_NICKNAME"),
    },
    isAbleTo.deleteNickname && {
      icon: "close-circle",
      kind: "button",
      text: "Slett kallenavn",
      onClick: () => setModalType("DELETE_NICKNAME"),
    },
    isAbleTo.terminateSIM && {
      icon: "remove",
      kind: "button",
      text: "Avbestill og deaktiver",
      onClick: () => setModalType("TERMINATE_SIM"),
      styling: "negative",
    },
  ];

  return actionPoints.filter(Boolean) as ActionItem[];
}
