import { useEffect } from "react";
import { track } from "@telia-no-min-side/utils";
import { GetAccessFixedCard } from "src/components/get-access-fixed-card";
import { Flex, Heading, Text } from "@telia/teddy";

export function GetAccessDashboard() {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  useEffect(() => {
    pushGenericTrackingEvent({
      ui_item_action: "view",
      ui_item_context: "Hjem",
      ui_item_text: "Få tilgang til den nye Min Side",
      ui_item_type: "infobox",
      ui_item_url: "no_url",
    });
  }, []);

  return (
    <Flex direction="column" gap="300">
      <Flex direction="column">
        <Heading as="h2" variant="title-400">
          Hei
        </Heading>
        <Text>
          Velkommen til en ny Min Side. Vi oppdaterer og forbedrer Min Side støtt og stadig for å forbedre din
          brukeropplevelse. Her ser du en oversikt over dine produkter og nyttige snarveier.
        </Text>
      </Flex>
      <GetAccessFixedCard />
    </Flex>
  );
}
