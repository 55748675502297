import { Button, Heading, ToggleButton } from "@telia/styleguide";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { FadeInContent } from "../../modules/Animations/FadeIn/FadeInContent";
import { RowFlexAlignEndJustifyStart } from "../../modules/LayoutComponents/RowFlexAlignEndJustifyStart";
import { ResponseMessage } from "../../modules/ResponseMessage";
import { useRouterSettings } from "../../stores/RouterSettingsProvider";
import { generalUtilities } from "../../util/GeneralUtilities";
import { ipUtilities } from "../../util/validation";
import { wifiSettingsTags } from "../../util/WiFiSettingsUtilities";
import AdditionalSettings from "../AdditionalWifiSettings";
import AdditionalSettingsExtender from "../AdditionalWifiSettings/AdditionalSettingsExtender";
import SSIDPWDialog from "../SSIDPWDialog/SSIDPWDialog";

import "./WifiSettingsCard.less";

const Container = ({ children }) => <div className="card-container">{children}</div>;

const SSIDContainer = ({ children }) => <div className="ssid-container">{children}</div>;

const ButtonContainer = ({ children }) => <div className="button-container">{children}</div>;

const SSIDLabel = ({ children }) => <div className={"ssid-label"}>{children}</div>;

const { shape, bool, string, arrayOf, func, number } = PropTypes;

export const WifiSettingsCard = (props) => {
  const { routerSettings } = useRouterSettings();
  const { updateStatus, updateResponse, activeUnit } = routerSettings;
  const [showSSIDDialogue, setShowSSIDDialogue] = useState(false);
  const [showSettingsDialogue, setShowSettingsDialogue] = useState(false);
  const [shouldShowSSIDDialogue, setShouldShowSSIDDialogue] = useState(false);
  const [shouldShowSettingsDialogue, setShouldShowSettingsDialogue] = useState(false);

  const {
    wifi,
    frequency,
    supportedSettings,
    callBackForSettingsClick,
    callBackForWifiToggle,
    callBackForSSIDPWClick,
    setSamePasswordForNetworks,
    ssidForOtherFrequency,
  } = props;

  const tag = frequency === "2.4" ? wifiSettingsTags.Wifi2GhzActiveStatus : wifiSettingsTags.Wifi5GhzActiveStatus;

  const toggleWifiActiveStatus = () => {
    callBackForWifiToggle(frequency);
  };

  const toggleSSIDAndPasswordPrompt = () => {
    if (showSettingsDialogue && !showSSIDDialogue) {
      setShouldShowSSIDDialogue(true);
      setShowSettingsDialogue(false);
    } else {
      setShowSSIDDialogue(!showSSIDDialogue);
    }
  };

  const toggleAdditionalSettingsPrompt = () => {
    if (showSSIDDialogue && !showSettingsDialogue) {
      setShouldShowSettingsDialogue(true);
      setShowSSIDDialogue(false);
    } else {
      setShowSettingsDialogue(!showSettingsDialogue);
    }
  };

  const updatePasswordAndSSIDForWifi = (ssid, pw) => {
    callBackForSSIDPWClick(frequency, ssid, pw);
  };

  const showSSIDOrAdditionalSettingsIfApplicable = () => {
    if (shouldShowSettingsDialogue) {
      setShowSettingsDialogue(true);
      setShouldShowSettingsDialogue(false);
    }
    if (shouldShowSSIDDialogue) {
      setShowSSIDDialogue(true);
      setShouldShowSettingsDialogue(false);
    }
  };

  const sameSsidWarning = ipUtilities.validateSSIDAndPasswordSettings(wifi.Ssid, ssidForOtherFrequency);

  return (
    <div className="settings-card-outer-container">
      <Container>
        <Heading tag="h2" size="s" text={`${frequency} GHz`} />
        {sameSsidWarning.length > 0 && (
          <label className="warning-message">{sameSsidWarning.find((msg) => msg.tag === "ssid").message}</label>
        )}
        <SSIDContainer>
          <SSIDLabel>{wifi.Ssid}</SSIDLabel>
          <div className={"settings-card-toggle-wrapper"}>
            <ToggleButton
              id={"wifi-toggler-" + frequency}
              labelText={!generalUtilities.isMobile() && "Skru denne trådløse frekvensen av eller på."}
              onChange={toggleWifiActiveStatus}
              checked={wifi.Enabled}
            />
          </div>
        </SSIDContainer>
        <ButtonContainer>
          <Button onClick={toggleSSIDAndPasswordPrompt} text={generalUtilities.isMobile() ? "SSID/Passord" : "Endre"} />
          <Button onClick={toggleAdditionalSettingsPrompt} text={"Innstillinger"} />
        </ButtonContainer>
      </Container>
      {
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CSSTransition
            in={showSSIDDialogue}
            timeout={500}
            classNames={"ssid-dialogue-collabsible"}
            unmountOnExit
            onExited={() => showSSIDOrAdditionalSettingsIfApplicable()}
          >
            {(state) => (
              <div>
                <FadeInContent show={state === "entered"}>
                  <SSIDPWDialog
                    SSID={wifi.Ssid}
                    pw={wifi.Password}
                    frequency={frequency}
                    PasswordMaxLength={supportedSettings.PasswordMaxLength}
                    PasswordMinLength={supportedSettings.PasswordMinLength}
                    SsidMaxLength={supportedSettings.SsidMaxLength}
                    SsidMinLength={supportedSettings.SsidMinLength}
                    onCancelClick={toggleSSIDAndPasswordPrompt}
                    onSaveClick={updatePasswordAndSSIDForWifi}
                    updateResponse={updateResponse}
                    updateStatus={updateStatus}
                    twoAndFiveGhzSupported={
                      activeUnit.SupportedSettings.Wifi2GHz.Supported && activeUnit.SupportedSettings.Wifi5GHz.Supported
                    }
                    setSamePasswordForBothNetworks={setSamePasswordForNetworks}
                    SSIDForOtherFrequency={ssidForOtherFrequency}
                  />
                </FadeInContent>
              </div>
            )}
          </CSSTransition>
        </div>
      }
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {activeUnit.DeviceType === "Main" ? (
          <AdditionalSettings
            wifi={wifi}
            frequency={frequency}
            supportedSettings={supportedSettings}
            onStoreClick={callBackForSettingsClick}
            onCancelClick={() => setShowSettingsDialogue(false)}
            show={showSettingsDialogue}
            updateResponse={updateResponse}
            updateStatus={updateStatus}
            callBackOnExit={showSSIDOrAdditionalSettingsIfApplicable}
          />
        ) : (
          <AdditionalSettingsExtender
            wifi={wifi}
            frequency={frequency}
            supportedSettings={supportedSettings}
            onStoreClick={callBackForSettingsClick}
            onCancelClick={() => setShowSettingsDialogue(false)}
            Model={activeUnit.ModelDescription}
            show={showSettingsDialogue}
            updateStatus={updateStatus}
            updateResponse={updateResponse}
            callBackOnExit={showSSIDOrAdditionalSettingsIfApplicable}
          />
        )}
      </div>
      <RowFlexAlignEndJustifyStart>
        <ResponseMessage updateResponse={updateResponse} tag={tag} />
      </RowFlexAlignEndJustifyStart>
    </div>
  );
};

WifiSettingsCard.propType = {
  wifi: shape({
    Enabled: bool,
    Channel: string,
    Bandwidth: string,
    Mode: string,
    Password: string,
    SecurityProtocol: string,
    Ssid: string,
    SignalStrength: string,
  }),
  supportedSettings: shape({
    Supported: bool,
    SsidMinLength: number,
    SsidMaxLength: number,
    PasswordMinLength: number,
    PasswordMaxLength: number,
    SecurityProtocols: arrayOf(string),
    SignalStrengths: arrayOf(string),
    Modes: arrayOf(
      shape({
        BandwidthToChannelMaps: arrayOf(
          shape({
            Bandwidth: string,
            Channels: arrayOf(string),
          })
        ),
      })
    ),
  }),
  frequency: string,
  callBackForSettingsClick: func,
  callBackForSSIDPWClick: func,
  callBackForWifiToggle: func,
  setSamePasswordForNetworks: func,
  ssidForOtherFrequency: string.isRequired,
};
