import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Container, Heading, HorizontalScroll, Paragraph, ToggleButtonGroup } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import { TablePagination, colors } from "@telia/styleguide";
import dayjs from "dayjs";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { InvoicePaymentStatus } from "src/gql/graphql";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { PrefetchQuery } from "src/hooks/usePrefetchQuery";
import { canCreateDirectDebit } from "util/mobile/invoice";
import { InvoiceCardPaid } from "../InvoiceCardPaid";
import { CurrentInvoiceCard } from "../components/CurrentInvoiceCard";
import { InvoiceError } from "../components/InvoiceError";
import { InvoiceLoader } from "../components/InvoiceLoader";
import { NotPayingOwnInvoiceCard } from "../components/NotPayingOwnInvoiceCard";
import { PaymentResultMessage } from "../components/PaymentResultMessage";
import { SkeltonInvoiceList } from "../components/SkeltonInvoiceList";
import { ACCOUNT_OWNER_INVOICE_QUERY, GET_INVOICE_BY_ACCOUNT_QUERY, SUBSCRIPTION_INVOICE_QUERY } from "../queries";
import { FaqComponentMobile } from "./FaqComponentMobile";
import { PayBalance } from "./PayBalance";
import { PostponeInvoice } from "./PostponeInvoice";
import { PreviousInvoiceList } from "./PreviousInvoiceList";
import usePagination from "./usePagination";
import { useTabData } from "./useTabDataHook";
import { Button, Icon, Notabene, Text } from "@telia/teddy";
import { config } from "util/config";

export const INVOICE_PAGE_SIZE = 24;

const invoiceTypeMapper: Record<string, string> = {
  Mail: "Papirfaktura",
  Email: "E-postfaktura",
  EFaktura: "eFaktura",
  MailWithBankTransfer: "Papirfaktura med giro",
};

export function MobileInvoiceComponent() {
  const [modalOpen, setModalOpen] = useState(false);
  const [nextPaymentDate, setNextPaymentDate] = useState("");
  const { setPhoneNumber, accountId } = useMobileAccount();
  const subscriptionOverview = useQuery(ACCOUNT_OWNER_INVOICE_QUERY, {
    variables: { accountId, pageSize: INVOICE_PAGE_SIZE },
    notifyOnNetworkStatusChange: true,
  });
  const { getCurrentPageData, startIndex, endIndex, onPageChange, itemsPerPage, setCurrentPage } = usePagination();
  const { selectedTab, setSelectedTab, subscriptionInvoices, loadingSubInvoices } = useTabData({
    accountId,
  });

  useEffect(() => {
    setSelectedTab({ accountId, phoneNumber: "" });
  }, [accountId]);

  const onUserTabChange = (_: BaseSyntheticEvent, userSubscription?: { accountId: string; phoneNumber: string }) => {
    if (userSubscription === selectedTab) return;
    setSelectedTab(userSubscription ?? { accountId: "", phoneNumber: "" });
    setPhoneNumber(userSubscription?.phoneNumber ?? "");
    setCurrentPage(1);
  };

  if (subscriptionOverview.loading && !subscriptionOverview.data) {
    return (
      <Container maxWidth="sm">
        <InvoiceLoader />
      </Container>
    );
  }

  if (subscriptionOverview.error) {
    return (
      <Container maxWidth="sm">
        <InvoiceError onRefresh={() => subscriptionOverview.refetch()} />
      </Container>
    );
  }

  const accountInvoice = subscriptionOverview.data?.account;
  const invoiceCardPaymentStatus = subscriptionOverview.data?.account.invoiceCardPaymentStatus;

  const billingCycles = accountInvoice?.billingCycles;
  const orderedBillingCycle = billingCycles?.orderedBillCycle;
  const hasChangedBillingCycle = !!billingCycles?.orderedBillCycle;

  const canChangeBillingCycle = config.disableBillingCycleChange ? false : !!billingCycles?.possibleBillCycles?.length;

  const currentBillingCycleDueDay = accountInvoice?.billingCycles?.currentBillCycle?.dueDay;

  const delayedPaymentArrangementDate = accountInvoice?.paymentArrangement?.[0]?.expectedPaymentDate || "";

  const paymentDate = nextPaymentDate || delayedPaymentArrangementDate || accountInvoice?.paymentBalance?.dueDate;

  const nextInvoiceDate = format.formatDate(
    accountInvoice?.currentInvoice?.billingDate,
    format.DATE_DISPLAY_FORMAT_WITH_SHORT_YEAR
  );

  const distribution = accountInvoice?.invoiceDistribution ?? "";
  const lastInvoice = accountInvoice?.invoices?.[0];
  const paid = !!lastInvoice?.paid;

  // We only show sub picker tabs when there is more than one subscription. Otherwise just account level invoice is shown.
  const moreThanOneSubOnAccount = accountInvoice?.subscriptions && accountInvoice?.subscriptions?.length > 1;

  const nameOfSelected = subscriptionOverview.data?.account.subscriptions?.find(
    (sub) => sub.phoneNumber.localNumber === selectedTab.phoneNumber
  )?.productUser?.firstName;

  const paymentMethodName = accountInvoice?.paymentMethod?.name;
  const selectedInvoiceDistributionType = accountInvoice?.selectedInvoiceDistributionType;

  const isInvoiceNotReady = lastInvoice?.dueDate !== accountInvoice?.paymentBalance?.dueDate;

  return (
    <Container maxWidth="full">
      {moreThanOneSubOnAccount && (
        <>
          <HorizontalScroll addContainerScrollMargin>
            {({ scrollBy }) => (
              <Container gap padding="vertical">
                <ToggleButtonGroup onChange={onUserTabChange} value={selectedTab}>
                  <ToggleButtonGroup.Item
                    scrollBy={scrollBy}
                    isSelected={selectedTab.accountId === accountId}
                    text={"Hele fakturaen"}
                    value={{ accountId, phoneNumber: "" }}
                  />
                  {subscriptionOverview.data?.account.subscriptions?.map((subscription) => (
                    <ToggleButtonGroup.Item
                      scrollBy={scrollBy}
                      key={subscription.phoneNumber.localNumber}
                      text={format.startCase(subscription.productUser?.firstName?.toLowerCase() ?? "")}
                      value={{
                        accountId: "",
                        phoneNumber: subscription.phoneNumber.localNumber,
                      }}
                      isSelected={selectedTab.phoneNumber === subscription.phoneNumber.localNumber}
                    />
                  ))}
                </ToggleButtonGroup>
              </Container>
            )}
          </HorizontalScroll>
          {subscriptionOverview.data?.account.subscriptions?.map(
            (subscription) =>
              subscription.phoneNumber.localNumber && (
                <PrefetchQuery
                  key={`prefetcher-phone-sub-inv-${subscription.phoneNumber.localNumber}`}
                  query={SUBSCRIPTION_INVOICE_QUERY}
                  options={{ variables: { phoneNumber: subscription.phoneNumber.localNumber } }}
                />
              )
          )}

          {!selectedTab.accountId && (
            <Container padding="vertical" flexDirection="column" gap maxWidth="sm">
              <Container backgroundColor={colors.grey100} padding>
                <Paragraph variant="additional-100" data-di-mask>
                  Her ser du {nameOfSelected ? format.startCase(nameOfSelected) : "mangler navn"} sin del av den felles
                  fakturaen. Per dags dato kan man kun se personens andel. Ved betaling av faktura betales hele
                  fakturaen.
                </Paragraph>
              </Container>
              {subscriptionInvoices?.subscription.invoices?.length ? (
                <>
                  <Heading tag="h2" variant="title-100" data-di-mask>
                    Fakturaoversikt for {format.startCase(nameOfSelected ?? "")}
                  </Heading>
                  <PreviousInvoiceList
                    invoices={getCurrentPageData(subscriptionInvoices.subscription.invoices)}
                    isSubLevel
                  />
                  <Container>
                    <TablePagination
                      dataLength={subscriptionInvoices.subscription.invoices.length}
                      from={startIndex}
                      onPageChange={onPageChange}
                      perPage={itemsPerPage}
                      to={
                        endIndex > subscriptionInvoices.subscription.invoices.length
                          ? subscriptionInvoices.subscription.invoices.length
                          : endIndex
                      }
                    />
                  </Container>
                </>
              ) : loadingSubInvoices ? (
                <SkeltonInvoiceList />
              ) : (
                <NotPayingOwnInvoiceCard />
              )}
            </Container>
          )}
        </>
      )}
      {selectedTab.accountId && (
        <Container padding="vertical" flexDirection="column" gap maxWidth="sm">
          <Container gap="lg" flexDirection="column">
            <PaymentResultMessage invoiceCardPaymentStatus={invoiceCardPaymentStatus} />
            {!accountInvoice || !lastInvoice?.invoiceNumber ? (
              <NotPayingOwnInvoiceCard />
            ) : isInvoiceNotReady ? (
              <Notabene>
                <Notabene.Icon name="bulb" />
                <Notabene.Content>
                  <Notabene.Heading as="h3">Ny faktura på vei</Notabene.Heading>
                  <Text mb="50">
                    Vi jobber med å ferdigstille din faktura. Den vil være tilgjengelig i løpet av 24 timer.
                  </Text>
                </Notabene.Content>
              </Notabene>
            ) : paid ? (
              <InvoiceCardPaid
                amount={accountInvoice?.paymentBalance?.amount ?? 0}
                accountId={accountId}
                isMobileAccount
                nextInvoiceDate={nextInvoiceDate}
                distribution={invoiceTypeMapper[distribution]}
                partialPayment={(accountInvoice?.paymentBalance?.amount ?? 0) > 0}
                orderedBillingCycle={orderedBillingCycle}
                canChangeBillingCycle={canChangeBillingCycle}
                hasChangedBillingCycle={hasChangedBillingCycle}
                currentBillingCycleDueDay={currentBillingCycleDueDay}
              />
            ) : (
              <CurrentInvoiceCard
                accountId={accountId}
                kidNumber={accountId}
                currentInvoiceAmount={accountInvoice?.paymentBalance?.amount ?? 0}
                distribution={invoiceTypeMapper[distribution]}
                isMobileAccount
                bankAccountNumber={accountInvoice?.currentInvoice?.billingInfo?.bankAccount ?? ""}
                paymentDate={dayjs(paymentDate).format("DD. MMMM")}
                nextInvoiceDate={nextInvoiceDate}
                invoiceId={lastInvoice?.invoiceNumber ?? ""}
                paymentOverdue={dayjs().isAfter(paymentDate, "day")}
                setModalOpen={setModalOpen}
                invoiceCardPaymentStatus={invoiceCardPaymentStatus}
                billingCyclesExist={!!billingCycles}
                orderedBillingCycle={orderedBillingCycle}
                canChangeBillingCycle={canChangeBillingCycle}
                hasChangedBillingCycle={hasChangedBillingCycle}
                currentBillingCycleDueDay={currentBillingCycleDueDay}
                paymentMethodName={paymentMethodName}
                invoiceDistributionType={selectedInvoiceDistributionType}
                isPostponingDisabled={!accountInvoice?.canDeferInvoice}
              >
                {invoiceCardPaymentStatus === InvoicePaymentStatus.None && (
                  <>
                    <PayBalance accountId={accountId} />
                    {lastInvoice?.invoiceNumber && (
                      <PrefetchQuery
                        query={GET_INVOICE_BY_ACCOUNT_QUERY}
                        options={{
                          variables: { accountId, invoiceId: lastInvoice?.invoiceNumber, pageSize: INVOICE_PAGE_SIZE },
                        }}
                      />
                    )}
                  </>
                )}
              </CurrentInvoiceCard>
            )}
          </Container>
          {accountInvoice?.invoices && accountInvoice.invoices.length > 0 && (
            <Container gap flexDirection="column" padding="vertical" maxWidth="sm">
              <Heading tag="h2" variant="title-100">
                Din fakturaoversikt
              </Heading>
              <PreviousInvoiceList invoices={getCurrentPageData(accountInvoice.invoices)} />
              <Container flexBasis={"auto"}>
                <TablePagination
                  dataLength={accountInvoice?.invoices?.length}
                  from={startIndex}
                  onPageChange={onPageChange}
                  perPage={itemsPerPage}
                  to={endIndex > accountInvoice.invoices.length ? accountInvoice.invoices.length : endIndex}
                />
              </Container>
            </Container>
          )}
          {canCreateDirectDebit(paymentMethodName, selectedInvoiceDistributionType, paid) && (
            <Notabene>
              <Notabene.Icon name="bulb" />
              <Notabene.Content>
                <Notabene.Heading as="h3">Ønsker du AvtaleGiro?</Notabene.Heading>
                <Text mb="50">
                  Med AvtaleGiro blir fakturaen automatisk betalt. Det vil si at banken sørger for at regningene betales
                  direkte fra din konto på forfallsdato.
                </Text>
                <Button variant="text" asChild>
                  <Link to="avtalegiro">
                    Opprett AvtaleGiro <Icon name="arrow-right" />
                  </Link>
                </Button>
              </Notabene.Content>
            </Notabene>
          )}
        </Container>
      )}
      <Container flexDirection="column" gap maxWidth="sm" padding="vertical">
        <FaqComponentMobile />
        <PostponeInvoice
          dueDate={paymentDate}
          modalOpen={modalOpen}
          setIsModalOpen={setModalOpen}
          accountId={accountId}
          setNextPaymentDate={setNextPaymentDate}
        />
      </Container>
    </Container>
  );
}
