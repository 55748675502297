import { axiosPost } from "src/services/axios/axios";

const urlLogoutAll = "/minside/api/getidentity/logout-all";

type Response = {
  success?: boolean;
  logoutUrl?: string;
};

export function useLogoutAllDevices() {
  async function logoutAllDevices() {
    return await axiosPost<Response>(urlLogoutAll);
  }

  return {
    logoutAllDevices,
  };
}
