import { Button, Container, Heading, Paragraph, useScrollToTop } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { CustomerCareLink } from "src/components/customer-care-link";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = {
  onRetryClick(): void;
  isLoading?: boolean;
};

export function ReceiptError({ isLoading, onRetryClick }: Props): JSX.Element {
  useScrollToTop();
  return (
    <Container maxWidth="md" padding alignItems="flex-start" flexDirection="column" gap>
      <Heading tag="h2" variant="title-300">
        Noe gikk galt!
      </Heading>
      <Paragraph>
        Vi kunne dessverre ikke behandle forespørselen din og fikk heller ikke opprettet en manuell sak for oppfølging.
        Vennligst prøv igjen, eller kontakt vår kundeservice:
      </Paragraph>
      <CustomerCareLink trackingContext="buy-tv-subscription-fwa" />
      <Container gap flexDirection="row">
        {onRetryClick && (
          <Button
            onClick={onRetryClick}
            isLoading={isLoading}
            trackEvent={{
              ui_item_text: TRACK_EVENT.ITEM_TEXT.TRY_AGAIN,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.BUY_FWA_SUB,
              ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_ERROR,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
            }}
          >
            Prøv igjen
          </Button>
        )}
        <Button
          tag="a"
          isInternalNavigation
          href={uri.minSideFixed("/hjem")}
          variant="secondary"
          icon="home"
          iconPosition="after"
          trackEvent={{
            ui_item_text: TRACK_EVENT.ITEM_TEXT.BACK_TO_OVERVIEW,
            ui_item_url: uri.minSideFixed("/hjem"),
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.BUY_FWA_SUB,
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_ERROR,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
          }}
        >
          Gå til Hjem
        </Button>
      </Container>
    </Container>
  );
}
