import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchFwaPortfolio() {
  const { data, error, ...rest } = useAxiosSWR<string>(ICX_REQUEST.URL_FWA_OFFERS_PORTFOLIO);

  return {
    data,
    isError: !!error,
    ...rest,
  };
}
