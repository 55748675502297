import { Button, Flex } from "@telia/teddy";
import { CSSProperties, ComponentProps, ReactNode, useEffect, useRef } from "react";
import { trunc } from "./utils";

type Props = {
  id?: string;
  variant?: ComponentProps<typeof Button>["variant"];
  selected?: boolean;
  handleClick: () => void;
  children: ReactNode;
  subtext?: string;
  style?: CSSProperties;
};

export function PickerButton({
  selected,
  handleClick,
  children,
  subtext,
  id,
  style = {},
  variant = "secondary",
}: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (selected && buttonRef.current) {
      // on some pages scroll into view will shift the layout left and leave an empty space
      // on the right with other than current scrollIntoView options
      // https://stackoverflow.com/questions/11039885/scrollintoview-causing-the-whole-page-to-move
      buttonRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [buttonRef.current]);

  return (
    <Flex direction="column" align="center" style={{ scrollSnapAlign: "start", ...style }} title={subtext}>
      <Button
        style={{ width: "50px", height: "50px", position: "relative" }}
        size="lg"
        variant={selected ? "primary" : variant}
        iconOnly={true}
        id={id ? `tab-${id}` : undefined}
        type="button"
        role="tab"
        aria-selected={!!selected}
        aria-label={subtext || "profile-option"}
        aria-controls="tabpanel-for-picker"
        ref={buttonRef}
        onClick={handleClick}
      >
        <span style={{ position: "absolute", top: "50%", left: "50%", translate: "-50% -50%" }}>{children}</span>
      </Button>
      {subtext && <span data-di-mask>{trunc(subtext)}</span>}
    </Flex>
  );
}
