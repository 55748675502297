import { useState } from "react";
import { useQuery } from "@apollo/client";
import { graphql } from "src/gql";
import { types } from "@telia-no-min-side/utils";
import { getConvergentBenefitAmount } from "src/util/format-subscription/getConvergentBenefitAmount";
import { OutageFetcher } from "./components/Outage/OutageFetcher";
import { Outage } from "./components/Outage/Outage";

export const GET_ADDITIONAL_PRODUCTS = graphql(`
  query GetAdditionalProducts($phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      roles
      legalOwner {
        individual {
          firstName
          surname
        }
      }
      additionalProducts {
        id
        code
        shortName
        productCategory
        price {
          price
        }
        presentation {
          active
          modifiable
        }
        productTerms {
          code
          amount
          monetaryUnit
        }
        characteristic {
          featureCode
          config {
            name
            value
            characteristicType
          }
        }
      }
    }
  }
`);

type Props = {
  phoneNumber: string;
  isLegalOwner: boolean;
  usersWithFixedProducts: types.fixed.UsersWithCustomerId;
};

export type ExtendedFixedProductType = (types.fixed.UserWithCustomerId & {
  hasOutage?: boolean;
})[];

export function Outages({ phoneNumber, isLegalOwner, usersWithFixedProducts }: Props) {
  const [outageList, setOutagesList] = useState<ExtendedFixedProductType>(usersWithFixedProducts);

  const additionalProductsQuery = useQuery(GET_ADDITIONAL_PRODUCTS, {
    variables: { phoneNumber },
    skip: !phoneNumber || !isLegalOwner,
    notifyOnNetworkStatusChange: true,
  });

  const allOutagesFetched = outageList.every((outage) => outage.hasOutage !== undefined);

  const additionalProducts = additionalProductsQuery.data?.subscription.additionalProducts;
  const convergentBenefit = getConvergentBenefitAmount(additionalProducts || []);
  const isConvergentUser = convergentBenefit && (convergentBenefit.hasDoubleData || convergentBenefit.hasDoubleSpeed);

  return (
    <>
      {usersWithFixedProducts.map((product) => (
        <OutageFetcher
          key={product.customerId}
          postCode={product.postalCode}
          street={product.street}
          setOutagesList={setOutagesList}
        />
      ))}

      {allOutagesFetched && (
        <Outage phoneNumber={phoneNumber} isConvergentUser={isConvergentUser} outageList={outageList} />
      )}
    </>
  );
}
