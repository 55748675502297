import { useRef } from "react";
import { useQuery } from "@apollo/client";
import { uri } from "@telia-no-min-side/utils";
import { graphql } from "src/gql";
import { QuerySafeWebshopUrlArgs } from "src/gql/graphql";

const SAFE_WEBSHOP_URL = graphql(`
  query safeWebshopUrl($input: SafeWebshopUrlInput) {
    safeWebshopUrl(input: $input)
  }
`);

type UseSafeWebshopUrlArgs = Pick<
  NonNullable<NonNullable<QuerySafeWebshopUrlArgs["input"]>["dataToEncrypt"]>,
  "ban" | "offeringCode"
>;

export const useSafeWebshopUrl = ({ ban, offeringCode }: UseSafeWebshopUrlArgs) => {
  const date = useRef(Date.now());
  return useQuery(SAFE_WEBSHOP_URL, {
    variables: {
      input: {
        host: uri.openPages(),
        path: "/shop/api/external/subscriptions?d=",
        dataToEncrypt: {
          msisdn: "",
          orderer_msisdn: "",
          ts: date.current,
          missing: false,
          initiatedBy: "minside",
          quantity: 1,
          ban,
          offeringCode, // pricePlan
        },
      },
    },
  });
};
