import { useMobileBroadbandContext } from "pages/internet/mobile-broadband/context/MobileBroadbandContext";
import { useNavigate } from "react-router-dom";
import { Flex, Card, Button, Icon } from "@telia/teddy";
import { formatDataAmount } from "@telia-no-min-side/utils";
import { ActionsSection, LimitedDataProgressSection } from "..";
import { getDataUsageDetails, getTotalDataUsage, mergeDataUsageByType } from "../../utils";
import { useMobileAccount } from "hooks/useMobileAccount";

export function LimitedDataUsageCard() {
  const { phoneNumber, accountId } = useMobileAccount();
  const { domesticalUsage, timeUntilDataUsagePeriodStarts, isSleepMode } = useMobileBroadbandContext();
  const navigate = useNavigate();

  const groupedData = mergeDataUsageByType(domesticalUsage);
  const totalDataUsage = getTotalDataUsage(groupedData);
  const details = getDataUsageDetails(groupedData, totalDataUsage.limit);

  return (
    <Card bordered shadow layout="default" variant="white" gap="gutter-md">
      <Card.Heading variant="title-400">{formatDataAmount(totalDataUsage.remaining)} igjen</Card.Heading>
      <Card.Content>
        <Flex direction="column" gap="gutter-md">
          <LimitedDataProgressSection
            details={details}
            totalData={totalDataUsage}
            timeUntilDataUsagePeriodStarts={timeUntilDataUsagePeriodStarts}
          />
          {!isSleepMode && <ActionsSection />}
        </Flex>
      </Card.Content>

      {isSleepMode && (
        <Card.Footer>
          <Button
            width="100%"
            variant="primary"
            onClick={() => {
              navigate(`/minside/mobilt-bredband/endre/${accountId}/${phoneNumber}`);
            }}
          >
            <Icon name="coffee">Deaktiver Hvilemodus</Icon>
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
}
