import { colors } from "@telia/styleguide";
import styled from "styled-components";
import { remCalc } from "../utils/fonts";

export const Wrapper = styled.div`
  .telia-date-picker {
    .telia-date-picker__input {
      --text-input-default-border-color: ${colors.grey400};
      --text-input-default-padding: ${remCalc(24)} ${remCalc(12)};
      --text-input-default-text-color: ${colors.black};
      --text-input-default-text-size: 16px;
      --text-input-default-label-color: ${colors.black};
      --text-input-default-label-font-size: 1rem;
      --text-input-default-label-font-weight: 500;
      --text-input-default-label-margin-bottom: 0.25rem;
      --text-input-default-help-text-font-size: 1rem;
      --text-input-default-help-text-margin-top: 0.25rem;
      --text-input-empty-border-color: ${colors.grey400};
      --text-input-empty-text-color: ${colors.black};
      --text-input-hover-border-color: ${colors.grey400};
      --text-input-focus-border-color: ${colors.grey400};
      --text-input-border-radius: 2px;
    }

    .telia-textfield__content:hover {
      outline: 2px solid ${colors.black};
      outline-offset: -2px;
    }

    .telia-textfield--error {
      .telia-textfield__content:hover {
        outline-color: ${colors.red600};
      }
    }

    .telia-textfield--disabled {
      .telia-textfield__content:hover {
        outline: none;
      }
    }

    .telia-textfield--focus {
      .telia-textfield__content {
        outline: ${remCalc(2)} solid ${colors.blue500};
        outline-offset: ${remCalc(2)};
      }
    }
  }
`;
