export function getNameAndValueFromCookie(cookie: string | undefined) {
  if (!cookie) return;
  const [cookieName, cookieValue] = cookie.split("=");
  return { cookieName, cookieValue };
}

export function getCookieValue(name: string) {
  const filteredCookies = document.cookie.split("; ").filter((cookie) => {
    return getNameAndValueFromCookie(cookie)?.cookieName === name;
  });

  return getNameAndValueFromCookie(filteredCookies[0])?.cookieValue;
}
