import { getAddress } from "@telia-no-min-side/utils";
import { MoveOutFormData } from "../components/MoveOutForm";

export function toMoveOutPayload(data: MoveOutFormData) {
  const { streetName, streetNumber, streetLetter } = getAddress(data.to.streetAddress);

  const request = {
    email: data.email,
    to: {
      postalCode: data.to.postalCode,
      city: data.to.city,
      streetName,
      streetNumber: streetNumber,
      streetAlphanumeric: streetLetter,
    },
    moveDate: data.moveDate,
    contactNewResident: data.contactNewResident,
    newResident: {
      name: data.newResident?.name || "",
      phoneNumber: data.newResident?.phoneNumber || "",
    },
  };

  return request;
}
