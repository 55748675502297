import React from "react";
import { Heading } from "@telia/styleguide";
import { IpAddressInput } from "../../../modules/IPAddressInput";
import { ContentWrapper } from "../../../modules/LayoutComponents/ContentWrapper";

const getErrorMessage = (errorMessages) => {
  if (errorMessages.some((error) => error.message !== "")) {
    return errorMessages.find((error) => error.message !== "").message;
  }
};

export const Lan = ({ errorMessages, gateway, mask, gatewayContract, maskContract, onChange, onBlur }) => (
  <ContentWrapper>
    <Heading level={3} text={"LAN"} />
    <IpAddressInput
      address={gateway}
      title={"Lokal IP (Gateway)"}
      onChange={onChange}
      typeOfAddress={"gateway"}
      mask={mask}
      addressContract={gatewayContract}
      onBlur={onBlur}
      errorMessage={getErrorMessage(errorMessages)}
    />
    <IpAddressInput
      title={"Intern nettverksmaske"}
      address={mask}
      mask={mask}
      typeOfAddress={"mask"}
      addressContract={maskContract}
      errorMessage={""}
    />
  </ContentWrapper>
);
