import { useContext } from "react";
import { Invoice, InvoiceContext } from "./context";

export const useInvoice = (): InvoiceContext => {
  const context = useContext<InvoiceContext | undefined>(Invoice);

  if (!context) {
    throw Error(
      "No invoice context found! This usually happens when you try to access a context outside of a provider"
    );
  }
  return context;
};
