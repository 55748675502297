import { types } from "@telia-no-min-side/utils";

export const shouldAllowPostponing = (
  lastInvoice?: types.fixed.LastInvoice,
  selectedInvoice?: types.fixed.InvoiceDetails,
  financial?: types.fixed.Financial
) => {
  if (!lastInvoice || !selectedInvoice || !financial) {
    return false;
  }
  const financialStatus = financial?.financialAccounts.find(
    (financialAccount) => financialAccount.id.financialAccountId === lastInvoice?.financialAccountId
  )?.status;
  const isLastInvoiceSelectedAndUnPaid =
    lastInvoice?.invoice.invoiceId === selectedInvoice?.invoiceId ? !selectedInvoice?.paid : false;
  // Allow postponing only for latest/last unpaid invoice, with statuses NORMAL/OVERDUE
  return (financialStatus === "NORMAL" || financialStatus === "OVERDUE") && isLastInvoiceSelectedAndUnPaid;
};
