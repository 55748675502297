import React from "react";
import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";

export function Success(): JSX.Element {
  return (
    <>
      <Heading tag="h3" variant="title-100">
        Suksess!
      </Heading>
      <Paragraph>Produktene dine vil snart bli synlig i appen og på Min Side. Det vil ta ca. 5 minutter.</Paragraph>
      <Paragraph>For at vi skal kunne vise din nye data må du laste inn siden på nytt.</Paragraph>
      <Container padding="top">
        <Button tag="a" isInternalNavigation iconPosition="before" icon="arrow-left" href={uri.minSideFixed("/tv")}>
          Tilbake til Min Side
        </Button>
      </Container>
    </>
  );
}
