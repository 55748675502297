import { uri } from "@telia-no-min-side/utils";
import { config } from "./config";

export type LinkProps = {
  text: string;
  href: string;
  internalLink?: boolean;
};

// NOTE: no-access users can only see the following pages:
export const accessibleViews = ["hjem", "midlertidig-tilgang", "personvern", "hjelp"];

export const L = {
  TeliaNoUrl: {
    href: config.teliaNoRootUrl,
  },
  ChangeLegalOwner: {
    text: "Bytte eier",
    href: `${config.teliaNoRootUrl}/mitt-abonnement/bytte-eier/`,
    internalLink: false,
  },
  CancelSubscription: {
    text: "Avslutte abonnement",
    href: `${config.teliaNoRootUrl}/mitt-abonnement/oppsigelse/`,
    internalLink: false,
  },
  Invoice: (text = "Faktura") => ({
    text,
    href: uri.minSideFixed("/faktura"),
    internalLink: true,
  }),
  AccountLevelAgreements: {
    text: "Dine avtaler",
    href: uri.minSideMobile("/avtaler"),
    internalLink: true,
  },
  BlockSubscription: {
    text: "Sperr mine SIM",
    href: uri.minSideMobile("/sperr-abonnement"),
    internalLink: true,
  },
  Tjenester: {
    text: "Tjenester",
    href: `${config.teliaNoRootUrl}/tjenester/`,
  },
};
