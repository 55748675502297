import { ErrorLink } from "@apollo/client/link/error";
import { enhanceErrorData } from "./datadog";
import { getNetworkErrorContext, getNetworkErrorMessage } from "./networkErrors";
import {
  getGraphQLErrorContext,
  getGraphQLErrorGroup,
  getGraphQLErrorMessage,
  hasAuthenticationError,
  isErrorInIgnoreList,
} from "./graphQLErrors";

type DatadogRum = { addError: (error: unknown, context?: object | undefined) => void };

export const createApolloErrorHandler =
  (datadogRum: DatadogRum, onAuthenticationError?: () => void): ErrorLink.ErrorHandler =>
  ({ graphQLErrors, networkError, operation }) => {
    if (networkError) {
      // Datadog mostly consumes error.message, error.name and error.stack props.
      // Usually the network error message is "Load failed", "Failed to fetch" or
      // "NetworkError when attempting to fetch resource".
      // Once we have some historic data from error context, the message could
      // be improved with the context data.
      const context = getNetworkErrorContext(networkError, operation);

      const channelAPINetworkError = enhanceErrorData(networkError, {
        customErrorName: "ChannelAPINetworkError",
        customMessage: getNetworkErrorMessage(networkError, operation),
      });

      datadogRum.addError(channelAPINetworkError, context);
    }

    if (graphQLErrors) {
      if (hasAuthenticationError(graphQLErrors)) {
        onAuthenticationError && onAuthenticationError();
        return;
      }

      graphQLErrors.forEach((graphQLError) => {
        if (isErrorInIgnoreList(graphQLError, operation)) return;

        const context = getGraphQLErrorContext(graphQLError, operation);

        // Although error is typed as unknown in
        // datadogRum.addError(error: unknown, context?: object | undefined)
        // in order to show the data correctly in Datadog dashboards
        // it must be an instance of Error
        const channelAPIError = enhanceErrorData(new Error(graphQLError.message), {
          customMessage: getGraphQLErrorMessage(graphQLError, operation),
          customErrorName: "ChannelAPIError",
          errorGroup: getGraphQLErrorGroup(graphQLError, operation.operationName),
        });

        datadogRum.addError(channelAPIError, context);
      });
    }
  };
