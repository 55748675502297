import { DashboardLayout } from "pages/tv/common/DashboardLayout";
import { BuyRecording } from "./BuyRecording";

export function BuyRecordingPage() {
  return (
    <DashboardLayout>
      <BuyRecording />
    </DashboardLayout>
  );
}
