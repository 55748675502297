import { Button, CheckBoxWithLabel, Heading } from "@telia/styleguide";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import React, { useEffect, useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { Spinner } from "../../../../components/spinner-telia/Spinner";
import { RouterSettingsActions } from "../../actions/RouterSettingActions";
import { ContentWrapper } from "../../modules/LayoutComponents/ContentWrapper";
import { RowFlexAlignEndJustifyStart } from "../../modules/LayoutComponents/RowFlexAlignEndJustifyStart";
import { ResponseMessage } from "../../modules/ResponseMessage";
import { useRouterSettings } from "../../stores/RouterSettingsProvider";
import { ALGParams, homeCentralUtilities } from "../../util/HomeCentralUtilities";
import { device } from "../../util/PropTypes";
import { routerAnalytics } from "../../util/RouterSettingsAnalytics";
import { ipUtilities } from "../../util/validation";

import { ALG } from "./PageModules/ALG";
import { Dhcp } from "./PageModules/Dhcp";
import { Dmz } from "./PageModules/Dmz";
import { GeneralInfo } from "./PageModules/GeneralInfo";
import { Lan } from "./PageModules/Lan";
import { StaticInternalIp } from "./PageModules/StaticInternalIP";

import "./style.less";

const Container = ({ children }) => <div className="home-central-container">{children}</div>;

const RowFlexStart = ({ children, additionalClassName }) => (
  <div className={"home-central-row-flex-start " + additionalClassName}>{children}</div>
);

export const HomeCentral = (props) => {
  const { routerSettings, dispatch } = useRouterSettings();
  const { updateStatus, updateResponse, devices } = routerSettings;
  const [homeCentral, setHomeCentral] = useState(cloneDeep(props.homeCentral));
  const initialState = cloneDeep(props.homeCentral);
  const [dataTouched, setDataTouched] = useState(false);
  const [validState, setValidState] = useState(true);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    RouterSettingsActions.setActiveUnitSerialNumber(dispatch, homeCentral.SerialNumber, devices);
  }, []);

  const setNewStates = (newState) => {
    const newErrorMessages = ipUtilities.validateHomeCentral(newState);

    setHomeCentral(newState);
    setErrorMessages(newErrorMessages);
  };

  const checkDifferenceToOriginal = (newState) => {
    return isEqual(newState, initialState);
  };

  const checkStaticInternalIpEqualityToOriginal = (newState) => {
    return isEqual(
      newState.Settings.StaticInternalIP.StaticInternalIPEntries,
      initialState.Settings.StaticInternalIP.StaticInternalIPEntries
    );
  };

  const checkALGDifferenceToOriginal = (newState) => {
    const { Alg } = initialState.Settings;

    return isEqual(Alg, newState.Settings.Alg);
  };

  const toggleBridgeMode = () => {
    const newState = homeCentralUtilities.setBridgeModeFlag(homeCentral);

    setNewStates(newState);
    setDataTouched(!checkDifferenceToOriginal(newState));
  };

  const toggleUpnp = () => {
    const newState = homeCentralUtilities.setUpnpFlag(homeCentral);

    setNewStates(newState);
    setDataTouched(!checkDifferenceToOriginal(newState));
  };

  const updateHomeCentralSettings = () => {
    const newErrorMessages = ipUtilities.validateHomeCentral(homeCentral);

    setErrorMessages(newErrorMessages);
    if (newErrorMessages.length === 0 && validState && !updateStatus.updating) {
      RouterSettingsActions.updateDevice(dispatch, {
        device: homeCentral,
        tag: "home-central",
      });
      routerAnalytics.trackStoreClick("home-central");
      if (dataTouched) {
        setDataTouched(false);
      }
    }
  };

  const updateIpAddress = (value, param, validState) => {
    if (validState !== validState) {
      setValidState(validState);
    }
    const newState = homeCentralUtilities.updateIPAddresses(homeCentral, value, param);

    setNewStates(newState);
    setDataTouched(!checkDifferenceToOriginal(newState));
  };

  const setLease = (event) => {
    const newState = homeCentralUtilities.setLease(homeCentral, parseInt(event.target.value));

    setNewStates(newState);
    setDataTouched(!checkDifferenceToOriginal(newState));
  };

  const toggleDmz = () => {
    const newState = homeCentralUtilities.setDmzFlag(homeCentral);

    setNewStates(newState);
    setDataTouched(!checkDifferenceToOriginal(newState));
  };

  const updateStaticInternalIPEntry = (entry, index) => {
    const newState = homeCentralUtilities.updateStaticInternalIPEntry(homeCentral, entry, index);

    setNewStates(newState);
    setDataTouched(!checkStaticInternalIpEqualityToOriginal(newState));
  };

  const deleteStaticInternalIPEntry = (index) => {
    const newState = homeCentralUtilities.deleteStaticInternalIP(homeCentral, index);

    setNewStates(newState);
    setDataTouched(!checkStaticInternalIpEqualityToOriginal(newState));
  };

  const saveNewStaticInternalIPEntryToHomeCentral = (newEntry) => {
    const newState = homeCentralUtilities.addNewStaticInternalIPEntryToHomeCentral(homeCentral, newEntry);

    setNewStates(newState);
    setDataTouched(!checkStaticInternalIpEqualityToOriginal(newState));
  };

  const flipAlgFlag = (typeOfFlag) => {
    let newState = {};

    switch (typeOfFlag) {
      case ALGParams.SIP:
        newState = homeCentralUtilities.setSipAlgFlag(homeCentral);
        break;
      case ALGParams.PPTP:
        newState = homeCentralUtilities.setPptpAlgFlag(homeCentral);
        break;
      case ALGParams.IPSEC:
        newState = homeCentralUtilities.setIpsecAlgFlag(homeCentral);
        break;
      default:
        newState = homeCentral;
    }

    setHomeCentral(newState);
    setErrorMessages(ipUtilities.validateHomeCentral(newState));
    setDataTouched(!checkALGDifferenceToOriginal(newState));
  };

  const validateHomeCentralSettings = () => {
    const validation = ipUtilities.validateHomeCentral(homeCentral);

    setErrorMessages(validation);
    setValidState(validation.length === 0);
  };

  if (!homeCentral) {
    return <Spinner />;
  }
  return (
    <Container>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {updateStatus.tag === "home-central" && <Spinner type={"sm"} />}
      </div>
      <GeneralInfo onBridgeModeChange={toggleBridgeMode} homeCentral={homeCentral} />
      {homeCentral.SupportedSettings.Lan.Supported && (
        <Lan
          mask={homeCentral.Settings.Lan.NetworkMask}
          onChange={updateIpAddress}
          gateway={homeCentral.Settings.Lan.InternalGWIP}
          errorMessages={errorMessages.filter((msg) => msg.tag === "gateway")}
          gatewayContract={homeCentral.SupportedSettings.Lan.InternalGWIP}
          maskContract={homeCentral.SupportedSettings.Lan.NetworkMask}
          onBlur={validateHomeCentralSettings}
        />
      )}
      {homeCentral.SupportedSettings.StaticInternalIP.Supported && (
        <StaticInternalIp
          mask={homeCentral.Settings.Lan.NetworkMask}
          deleteRule={deleteStaticInternalIPEntry}
          entries={homeCentral.Settings.StaticInternalIP.StaticInternalIPEntries}
          updateRule={updateStaticInternalIPEntry}
          errorMessages={errorMessages.filter((msg) => msg.tag.includes("static-internal-ip"))}
          validate={validateHomeCentralSettings}
          homeCentral={homeCentral}
          saveNewRuleToHomeCentral={saveNewStaticInternalIPEntryToHomeCentral}
        />
      )}
      {homeCentral.SupportedSettings.Dhcp.Supported && (
        <Dhcp
          mask={homeCentral.Settings.Lan.NetworkMask}
          errorMessages={errorMessages.filter((msg) => msg.tag === "dhcp-from" || msg.tag === "dhcp-to")}
          onChange={updateIpAddress}
          fromIp={homeCentral.Settings.Dhcp.FromIP}
          fromIpContract={homeCentral.SupportedSettings.Dhcp.FromIP}
          toIp={homeCentral.Settings.Dhcp.ToIP}
          toIpContract={homeCentral.SupportedSettings.Dhcp.ToIP}
          canChangeLeaseTime={homeCentral.SupportedSettings.Dhcp.CanChangeLeaseTime}
          handleLeaseSelect={setLease}
          leaseOptions={homeCentralUtilities.generateLeaseValues(props.homeCentral)}
          leaseValue={`${homeCentral.Settings.Dhcp.LeaseTime}`}
          onBlur={validateHomeCentralSettings}
        />
      )}
      {homeCentral.SupportedSettings.Dmz.Supported && (
        <Dmz
          onChange={updateIpAddress}
          errorMessages={errorMessages.filter((msg) => msg.tag === "dmz")}
          mask={homeCentral.Settings.Lan.NetworkMask}
          dmzContract={homeCentral.SupportedSettings.Dmz.IP}
          dmzEnabled={homeCentral.Settings.Dmz.Enabled}
          dmzIp={homeCentral.Settings.Dmz.IP}
          handleDmzCheck={toggleDmz}
          onBlur={validateHomeCentralSettings}
        />
      )}
      <ContentWrapper>
        <Heading level={3} text={"Universal Plug and Play"} />
        {homeCentral.SupportedSettings.Upnp.Supported && (
          <CheckBoxWithLabel label={"Aktiver Upnp"} checked={homeCentral.Settings.Upnp.Enabled} onChange={toggleUpnp} />
        )}
      </ContentWrapper>
      {homeCentral.SupportedSettings.Alg.Supported && <ALG alg={homeCentral.Settings.Alg} flipFlag={flipAlgFlag} />}
      <RowFlexAlignEndJustifyStart>
        <Button
          onClick={updateHomeCentralSettings}
          text={"Lagre"}
          isProcessing={updateStatus.tag === "home-central" && updateStatus.updating}
          processingText={"Lagre"}
          isDisabled={!dataTouched}
          kind={"primary"}
        />
      </RowFlexAlignEndJustifyStart>
      <RowFlexStart>
        <ResponseMessage updateResponse={updateResponse} tag={"home-central"} />
      </RowFlexStart>
      {dataTouched && <Beforeunload onBeforeunload={() => "Du har ulagrede endringer, vil du forlate vinduet?"} />}
    </Container>
  );
};

HomeCentral.propType = {
  homeCentral: device.isRequired,
};
