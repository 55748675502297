import React, { CSSProperties } from "react";
import { IconDefinition } from "@telia/styleguide";
import { StyledIcon } from "./style";

export type IconProps = {
  icon: IconDefinition;
  /**
   *  Size of the icon
   * @default md
   */
  size?: "sm" | "md" | "lg";
  className?: string;
  /**
   * Add a slow spin to the icon (e.g. loading indicators)
   * @default false
   */
  spin?: boolean;
  /**
   * An optional title for accessibility.
   */
  title?: string;
  color?: string;
  style?: CSSProperties;
  alignTextTop?: boolean;
};

/**
 * @deprecated Use Teddy components instead
 */
export function Icon({ size = "md", spin = false, color, ...rest }: IconProps): JSX.Element {
  return <StyledIcon color={color} size={size} spin={spin} {...rest} />;
}
