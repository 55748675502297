import { graphql } from "src/gql";

export const GET_MOBILE_BROADBAND_SUBSCRIPTION_DATA = graphql(`
  query getMobileBroadbandSubscriptionData($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      roles
      agreementType
      billingType
      userOffering {
        longDescription
        disclaimers {
          code
          name
          text
        }

        pricePlan
        shortName
        monthlyCost {
          amount
        }
        originalPrice {
          amount
        }

        additionalProperties {
          name
          value
        }
        offeringPrices {
          offeringPrices {
            code
            amount
            monetaryUnit
          }
        }
      }
      additionalProducts {
        id
        code
        name
        productCategory
        presentation {
          active
          modifiable
        }
        productTerms {
          code
          amount
          monetaryUnit
        }
      }
      usage {
        expireDate
        type
        usage
        service
        category
        activeDataBoostSession
        remaining {
          amount
          unit
        }
        limit {
          amount
          unit
        }
        period {
          endDate
        }
      }
    }
  }
`);
