import React, { ReactNode } from "react";
import { BodyWrapper } from "./style";

type Props = {
  children: ReactNode;
  className?: string;
};

/**
 * @deprecated Use Teddy components instead
 */
export function ModalBody(props: Props): JSX.Element {
  const { className, children } = props;
  return <BodyWrapper className={className}>{children}</BodyWrapper>;
}
