import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchTvRecord() {
  const { data, mutate, error, ...rest } = useAxiosSWR<types.fixed.ProductCategory>(ICX_REQUEST.URL_TV_RECORD);

  return {
    record: data,
    isError: !!error,
    error: error,
    mutateRecord: mutate,
    ...rest,
  };
}
