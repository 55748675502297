import { ReactNode, createContext, useContext, useState, useMemo } from "react";
import { useQuery, useSubscription } from "@apollo/client";
import { useLocalStorage, useToast } from "@telia-no-min-side/components";
import dayjs, { Dayjs } from "dayjs";
import { graphql } from "src/gql";
import { OrderState } from "gql/graphql";

export type Order = {
  id: string;
  toastText: string;
  createdAt: Dayjs;
  phoneNumber: string;
  vasProduct?: string;
  simCardNumber?: string;
  dataTrackingId?: string;
  /**
   * You should use this callback if you want to control what gets updated
   * after the order has been completed. Here are your options:
   *
   * 1. Refetches every query in the cache (default).
   *
   * client.refetchQueries({ include: "all" })
   *
   * 2. Refetches only the queries that are currently being used by some component.
   *
   * client.refetchQueries({ include: "active" })
   *
   * 3. Refetches only the queries that contain subcription with the given args.
   *
   * client.refetchQueries({
   *   updateCache(cache) {
   *     const args = { phoneNumber };
   *     cache.evict({ fieldName: "subscription", args });
   *   },
   * });
   *
   *
   * Read more: https://www.apollographql.com/docs/react/data/refetching/
   *
   */
  onOrderCompleted?: () => Promise<void> | void;
};

type OrderSubscriptionContext = {
  subscribeToOrder: (order: Omit<Order, "createdAt">) => void;
  unsubscribeFromOrder: (orderId: string) => void;
  orders: Order[];
};

const initialOrderSubscriptionValue: OrderSubscriptionContext = {
  subscribeToOrder: () => {},
  unsubscribeFromOrder: () => {},
  orders: [],
};

const OrderSubscription = createContext<OrderSubscriptionContext>(initialOrderSubscriptionValue);

export function useOrderSubscription(): OrderSubscriptionContext {
  const context = useContext<OrderSubscriptionContext>(OrderSubscription);

  if (!context) {
    throw Error(
      "No OrderSubscriptionContext found! This usually happens when you try to access a context outside of a provider"
    );
  }
  return context;
}

type Props = {
  children: ReactNode;
};

export function OrderSubscriptionProvider(props: Props) {
  const localStorage = useLocalStorage<Order[]>("orders");
  const [orders, setOrders] = useState<Order[]>(localStorage.value || []);

  function addOrder(order: Omit<Order, "createdAt">) {
    setOrders((orders) => {
      const orderExists = orders.some((o) => o.id === order.id);
      if (orderExists) return orders;

      const newOrder = { ...order, createdAt: dayjs() };
      const newState = [...orders, newOrder];
      localStorage.setValue(newState);

      return newState;
    });
  }

  function removeOrder(orderId: Order["id"]) {
    setOrders((orders) => {
      const newState = orders.filter((o) => o.id !== orderId);
      localStorage.setValue(newState);
      return newState;
    });
  }

  const providerValue = useMemo(
    () => ({
      subscribeToOrder: addOrder,
      unsubscribeFromOrder: removeOrder,
      orders,
    }),
    [orders]
  );

  return (
    <OrderSubscription.Provider value={providerValue}>
      {orders.map((order) => (
        <SubscribeWithToast removeOrder={() => removeOrder(order.id)} key={`order-toast-${order.id}`} order={order} />
      ))}
      {props.children}
    </OrderSubscription.Provider>
  );
}

const ORDER_SUBSCRIPTION = graphql(`
  subscription OrderSubscription($ID: ID!) {
    onOrderStatusUpdate(id: $ID) {
      id
      lastModified
      state
    }
  }
`);

const ORDER = graphql(`
  query OrderToaster($ID: Int!, $phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        countryCode
        localNumber
      }
      order(orderId: $ID) {
        id
        orderState
      }
    }
  }
`);

const THRESHOLD_ALERT_CHANGE = 15;

function SubscribeWithToast({ order, removeOrder }: { order: Order; removeOrder: () => void }) {
  const { addToast } = useToast();

  useQuery(ORDER, {
    variables: {
      ID: Number(order.id),
      phoneNumber: order.phoneNumber,
    },
    onCompleted(data) {
      if (!data) return;

      const subscriptionOrder = data.subscription?.order;
      const isOrderComplete = subscriptionOrder?.orderState === OrderState.Completed;

      if (isOrderComplete) {
        return;
      }

      const minutesSinceCreated = dayjs().diff(order.createdAt, "m");
      if (minutesSinceCreated > THRESHOLD_ALERT_CHANGE) {
        removeOrder();
      }
    },
    onError(error) {
      const isInvalidOrderID = error.message?.includes("INVALID_ORDER_ID");

      if (isInvalidOrderID) {
        removeOrder();
      }
    },
  });

  useSubscription(ORDER_SUBSCRIPTION, {
    variables: {
      ID: order.id,
      phoneNumber: order.phoneNumber,
    },
    async onData({ data: { data }, client }) {
      if (data?.onOrderStatusUpdate?.state !== OrderState.Completed) return;

      // type OrderDetails and type OrderStatus unfortunately are different types in graphql but same data in BE (Order).
      // So apollo cache thinks they have noting in common and does not update OrderDetails when OrderStatus
      // is updated via subscription (they get different ids in cache, OrderDetails:123 and OrderStatus:123).
      // So we have to manually update OrderDetails in cache if we want to get the new order state somewhere else in the app.
      const { id: orderId, state: orderState, lastModified } = data.onOrderStatusUpdate;

      if (orderId) {
        client.cache.modify({
          id: client.cache.identify({ __typename: "OrderDetail", id: orderId }),
          fields: {
            orderState(previousOrderState) {
              return orderState ? orderState : previousOrderState;
            },
            lastModifiedDate(previousLastModifiedDate) {
              return lastModified ? lastModified : previousLastModifiedDate;
            },
          },
        });
      }

      if (order.onOrderCompleted) {
        order.onOrderCompleted instanceof Promise ? await order.onOrderCompleted() : order.onOrderCompleted();
      } else {
        await client.refetchQueries({ include: "all" });
      }

      const minutesSinceCreated = dayjs().diff(order.createdAt, "m");
      if (minutesSinceCreated < THRESHOLD_ALERT_CHANGE) {
        await addToast({ text: order.toastText, variant: "success", dataTrackingId: order.dataTrackingId }).onRemove;
      }
      removeOrder();
    },
  });

  return null;
}
