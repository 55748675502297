import { ChevronDownIcon, colors, Heading, Paragraph } from "@telia/styleguide";
import styled, { css, keyframes, FlattenSimpleInterpolation } from "styled-components";
import { remCalc } from "../utils/fonts";

const boostChartAnimation = keyframes`
  0% {
    background: ${colors.corePurple};
  }
  20% {
    background: ${colors.blue400};
  }
  40% {
    background: ${colors.red400};
  }
  60% {
    background: ${colors.orange400};
  }
  80% {
    background: ${colors.green400};
  }
  100% {
    background: ${colors.corePurple};
  }
`;

const boostChartAnimationScale = keyframes`
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
  `;

export const HorseshoeBoostSliceHeading = styled(Heading)`
  margin: 0 0 ${remCalc(4)};
  font-weight: bold;
`;

export const HorseshoeBoostSliceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

export const HorseshoeBoostSlice = styled.div`
  position: relative;
  width: 210px;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    animation: ${boostChartAnimationScale} 1.5s linear infinite;
    border-radius: 50%;
    width: 80%;
    height: 80%;
    margin: 10%;
    background: #fff;
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  &::before {
    border-radius: 50%;
    animation:
      ${boostChartAnimation} 5s linear infinite,
      ${boostChartAnimationScale} 1.5s linear infinite;
    background: #000;
    width: 100%;
    height: 100%;
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const HorseshoeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const HorseshoeContainer = styled.div`
  position: relative;
  margin: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HorseshoeTextWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HorseshoeSizeTextWrapper = styled(HorseshoeTextWrapper)`
  top: 50px;
`;

export const StyledSizeToggleButton = styled.button`
  border-style: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 190px;
`;

export const StyledSpeedToggleButton = styled.button`
  border-style: none;
  background-color: transparent;
  cursor: pointer;
`;

export const StyledButtonText = styled(Paragraph)`
  display: flex;
  margin: 0;
  font-size: 0.875rem;
`;

const animateArrowOpen = keyframes`
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
  }
`;

const animateArrowClose = keyframes`
  0% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(0deg);
  }
`;

export const StyledArrow = styled(ChevronDownIcon)<{ isOpen: boolean }>`
  height: 20px;
  width: auto;
  color: ${colors.corePurple};
  animation: 0.2s ${({ isOpen }) => (isOpen ? animateArrowOpen : animateArrowClose)} forwards;
`;

const animateCircle = keyframes`
  0% {
    stroke-dasharray: 0 567;
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
`;

const animateSpeedometer = keyframes`
  from {
    transform: translateX(2%) translateY(2%) rotate(40deg);
  }
  to {
    transform: translateX(2%) translateY(2%) rotate(320deg);
  }
`;

const animateSpeedometerReversed = keyframes`
  from {
    transform: translateX(2%) translateY(2%) rotate(320deg);
  }
  to {
    transform: translateX(2%) translateY(2%) rotate(40deg);
  }
`;

export const StyledSpeedometer = styled.svg<{
  reversed?: boolean;
}>`
  animation: ${(props) => css`
    ${props.reversed ? animateSpeedometerReversed : animateSpeedometer} 1s ease-in-out forwards
  `};
  transform-origin: top;
  top: 105px;
  position: absolute;
`;

export const StyledCircle = styled.circle<{
  animate?: boolean;
  rotate: number;
  animationDelay?: number;
  animationDuration?: number;
}>`
  display: inline-block;
  transform: ${(props) => (props.rotate ? css`rotate(${props.rotate + 126}deg)` : css`rotate(126deg)`)};
  transform-origin: 50% 50%;
  visibility: hidden;
  transition: 0.3s;

  ${(props) => {
    const delay = props.animationDelay || 0;
    const duration = props.animationDuration || 1;
    if (props.animate) {
      return css`
        animation: ${animateCircle} ${duration}s ease-in ${delay}s;
        animation-fill-mode: forwards;
      `;
    }
    return css`
      visibility: visible;
    `;
  }};
`;

export const HorseshoeSizeTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${colors.grey800};
`;

export const HorseshoeSpeedTitle = styled(HorseshoeSizeTitle)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const HorseshoeText = styled.div`
  color: ${colors.grey800};
  margin-top: -10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  text-align: center;
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  min-width: 300px;
  justify-content: center;
`;

export const SpeedDetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  position: relative;
  transition: 0.2s all ease-in-out;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
`;

export const DetailsTitle = styled.p<{ styles?: FlattenSimpleInterpolation }>`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  margin-top: 9px;
  margin-bottom: 6px;
  text-align: center;
  color: ${colors.grey800};
  ${(props) => props.styles};
`;

export const DetailsUsedText = styled.p`
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  font-weight: bold;
  color: ${colors.grey800};
`;

export const DetailsTotalText = styled.p`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  margin-top: 2px;
  text-align: center;
  color: ${colors.grey800};
`;

export const DetailsVerticalDivider = styled.div`
  display: flex;
  width: 1px;
  background-color: ${colors.grey300};
  margin: auto 20px;
  justify-content: center;
  height: 66px;
`;

export const SpeedDetailsPlaceholder = styled.div<{
  isOpen: boolean;
  detailsLength: number;
}>`
  height: ${({ isOpen, detailsLength }) =>
    isOpen ? remCalc(250 + detailsLength * 50) : remCalc(75 + detailsLength * 50)};
`;

export const SpeedDetailsParagraph = styled.div`
  margin-bottom: 5px;
`;

export const HorseshoeSizeSubtitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  color: ${colors.grey800};
`;

export const HorseshoeSpeedSubtitle = styled(HorseshoeSizeSubtitle)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const SpeedDetailsRowAbsolute = styled(SpeedDetailsRow)`
  position: absolute;
  top: 87%;
`;
