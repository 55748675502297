import { Skeleton } from "@telia-no-min-side/components";
import {
  DetailsAccordionItem,
  InvoiceProduct,
  InvoiceProductList,
  StyledAccordionBody,
  StyledAccordionHeader,
} from "../styles";

export function DetailsElementSkeleton(): JSX.Element | null {
  return (
    <DetailsAccordionItem hideBorder={true} showShadow={false}>
      <StyledAccordionHeader>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </StyledAccordionHeader>
      <StyledAccordionBody>
        <InvoiceProductList>
          <InvoiceProduct>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </InvoiceProduct>
        </InvoiceProductList>
      </StyledAccordionBody>
    </DetailsAccordionItem>
  );
}
