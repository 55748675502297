import { Flex, Skeleton } from "@telia/teddy";

export function Loading() {
  return (
    <Flex direction="column" gap="gutter-sm">
      <Skeleton height="34px" width="100%" />
      <Skeleton height="1px" width="100%" />
      <Skeleton height="48px" width="100%" />
      <Skeleton height="44px" width="100%" />
    </Flex>
  );
}
