import { useQuery } from "@apollo/client";
import { Accordion, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { format, uri } from "@telia-no-min-side/utils";
import { useRef, useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { Error } from "../../../error";
import { recommendedKeySearchParam } from "../../mobile-subscription-change-redir";
import { OfferForSale } from "./OfferForSale";
import { CurrentSubscriptionAccordionItem } from "./CurrentSubscription";
import { SkeletonPage } from "../components/Skeleton";
import { NotLegalOwnerWarning } from "../components/NotLegalOwnerWarning";
import { SUBSCRIPTION_CHANGE_QUERY } from "../graphql/getChangeSubscription";
import { InsufficientAgeWarning } from "../components/InsufficientAgeWarning";
import { OngoingOrderWarning } from "../components/OngoingOrderWarning";
import { OfferGroup } from "../types";
import { OfferGroupPicker } from "../components/OfferGroupPicker";
import { groupMobileOffersForSaleByCategory } from "./utils/groupMobileOffersForSaleByCategory";
import { getMobileOffersForSale } from "./utils/getMobileOffersForSale";
import { getLineSubscriptions } from "./utils/getLineSubscriptions";
import { isOfTypeOfferGroup } from "./utils/isOfTypeOfferGroup";
import { BLOCKED_CHANGES_BY_VIAPLAY_TOTAL } from "util/format-subscription/variables";
import { isViaplay } from "util/format-subscription/isViaplay";
import { getMobileOffersForSaleWithCommitment } from "./utils/getMobileOffersForSaleWithCommitment";
import { committedEndDate } from "util/format-subscription/isCommitted";
import { BindingTimeWarning } from "../components/BindingTimeWarning";
import { NewLinesCard } from "pages/mobile/mobile-subscription-account-overview/components/LinesInfoCard";

export function MobileSubscriptionChange() {
  const params = useParams<{ accountId?: string; phoneNumber?: string; selectedOfferType?: OfferGroup }>();
  const [offerType, setOfferType] = useState<OfferGroup>("unlimitedData");
  const [search] = useSearchParams();
  const preSelectPricePlan = search.get(recommendedKeySearchParam);
  const changeSubscription = useQuery(SUBSCRIPTION_CHANGE_QUERY, {
    variables: { accountId: params.accountId || "", phoneNumber: params.phoneNumber || "" },
    notifyOnNetworkStatusChange: true,
    skip: !params.accountId || !params.phoneNumber,
  });
  const canNotChangeAlertRef = useRef<HTMLDivElement>(null);
  const hasOnGoingOrder = useOrderSubscription().orders.some((order) => order.phoneNumber === params.phoneNumber);
  const { selectedOfferType } = params;

  useEffect(() => {
    if (isOfTypeOfferGroup(selectedOfferType)) {
      setOfferType(selectedOfferType);
    }
  }, [selectedOfferType]);

  if (!params.accountId || !params.phoneNumber) {
    return <Error />;
  }

  if (changeSubscription.loading && !changeSubscription.data?.account.subscription) {
    return <SkeletonPage />;
  }

  if (!changeSubscription.data?.account.subscription) {
    return <Error />;
  }

  const { subscription: subscriptionToChange } = changeSubscription.data.account;

  const commitmentEndDate = committedEndDate(subscriptionToChange.commitmentEndDate);
  const hasBestSubscription = subscriptionToChange.newOfferingDetails?.offerings?.length === 0;

  const offersForSale = getMobileOffersForSale(subscriptionToChange.newOfferingDetails?.offerings);
  const offersForSaleWithCommitment = getMobileOffersForSaleWithCommitment(
    subscriptionToChange.newOfferingDetails?.offerings
  );

  const {
    juniorOffers,
    bucketOffers,
    unlimitedDataOffers,
    hasBucketOffers,
    hasUnlimitedDataOffers,
    hasJuniorOffers,
    hasAnyOffers,
    hasMultipleCategories,
    isJuniorSummerCampaignActive,
  } = groupMobileOffersForSaleByCategory(offersForSale);

  const linesSubscriptions = getLineSubscriptions(changeSubscription.data.account?.subscriptions);
  const hasLineDiscount = linesSubscriptions.length > 1;

  const offerGroups: { text: string; value: OfferGroup; available: boolean }[] = [
    {
      text: "Ubegrenset data",
      value: "unlimitedData",
      available: hasUnlimitedDataOffers,
    },
    {
      text: "Fast data",
      value: "bucket",
      available: hasBucketOffers,
    },
    {
      text: "Barn",
      value: "junior",
      available: hasJuniorOffers,
    },
  ];
  const availableOfferGroups = offerGroups.filter((group) => group.available);

  const isChangeBlockedByViaplayTotal = subscriptionToChange.additionalProducts?.some(
    (product) => product.code === BLOCKED_CHANGES_BY_VIAPLAY_TOTAL
  );

  const filteredUnlimitedDataOffers = unlimitedDataOffers.filter((offering) =>
    isChangeBlockedByViaplayTotal ? !isViaplay(offering.shortName) : offering
  );

  return (
    <Container
      padding
      showGoBackButton
      goBackTo={preSelectPricePlan ? uri.minSideFixed("/hjem") : undefined}
      gap="lg"
      maxWidth="sm"
      flexDirection="column"
    >
      <NotLegalOwnerWarning subscription={subscriptionToChange} />
      {subscriptionToChange.productUser?.firstName?.length ? (
        <Heading tag="h2" variant="title-200-light" data-di-mask data-tracking-id="mobile-subscription-change-heading">
          Velg nytt abonnement for {format.startCase(subscriptionToChange.productUser.firstName)}
        </Heading>
      ) : null}
      {hasOnGoingOrder && (
        <div ref={canNotChangeAlertRef}>
          <OngoingOrderWarning />
        </div>
      )}

      {!hasAnyOffers && <InsufficientAgeWarning subscription={subscriptionToChange} />}

      <Container gap flexDirection="column" data-tracking-id="mobile-subscription-change-current-subscription">
        <Paragraph removeMargin>Endres fra</Paragraph>
        <Accordion>
          <CurrentSubscriptionAccordionItem hasLineDiscount={hasLineDiscount} offeringFragment={subscriptionToChange} />
        </Accordion>
      </Container>
      <Container gap flexDirection="column" data-tracking-id="mobile-subscription-change-pick-new-subscription">
        {hasAnyOffers && <Paragraph removeMargin>Velg nytt abonnement</Paragraph>}
        {hasMultipleCategories && (
          <OfferGroupPicker value={offerType} onChange={setOfferType} options={availableOfferGroups} />
        )}
        {!!commitmentEndDate?.length && (
          <BindingTimeWarning hasBestSubscription={hasBestSubscription} commitmentEndDate={commitmentEndDate} />
        )}

        {offerType === "unlimitedData" && (
          <Accordion gap="lg" data-tracking-id="mobile-subscription-change-offer-unlimitedData">
            {filteredUnlimitedDataOffers.map((offering) => (
              <OfferForSale
                additionalProducts={subscriptionToChange?.additionalProducts}
                key={`${offering.shortName}-change-unlimitedData-${offering.weight}`}
                offeringFragment={offering}
                isLinesApplicable={hasLineDiscount}
                canNotChangeAlertRef={canNotChangeAlertRef}
                offeringWithCommitmentFragment={offersForSaleWithCommitment?.find(
                  (offeringWithcommitment) => offeringWithcommitment?.shortName === offering.shortName
                )}
              />
            ))}
          </Accordion>
        )}
        {offerType === "bucket" && (
          <Accordion gap="lg" data-tracking-id="mobile-subscription-change-offer-bucket">
            {bucketOffers.map((offering) => (
              <OfferForSale
                additionalProducts={subscriptionToChange?.additionalProducts}
                key={`${offering.shortName}-change-bucket-${offering.weight}`}
                offeringFragment={offering}
                isLinesApplicable={hasLineDiscount}
                canNotChangeAlertRef={canNotChangeAlertRef}
                offeringWithCommitmentFragment={offersForSaleWithCommitment?.find(
                  (offeringWithcommitment) => offeringWithcommitment?.shortName === offering.shortName
                )}
              />
            ))}
          </Accordion>
        )}
        {offerType === "junior" && (
          <Accordion gap="lg" data-tracking-id="mobile-subscription-change-offer-junior">
            {juniorOffers.map((offering) => (
              <OfferForSale
                additionalProducts={subscriptionToChange?.additionalProducts}
                key={`${offering.shortName}-change-junior-${offering.weight}`}
                offeringFragment={offering}
                isLinesApplicable={hasLineDiscount}
                additionalText="For deg under 16 år"
                canNotChangeAlertRef={canNotChangeAlertRef}
                offeringWithCommitmentFragment={offersForSaleWithCommitment?.find(
                  (offeringWithcommitment) => offeringWithcommitment?.shortName === offering.shortName
                )}
              />
            ))}
          </Accordion>
        )}
      </Container>

      {hasLineDiscount && !(offerType === "junior" && isJuniorSummerCampaignActive) && <NewLinesCard />}
    </Container>
  );
}
