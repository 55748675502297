export function formatPrice(price: number | null) {
  return price ? parseFloat((Math.round(price * 10000) / 10000).toFixed(4)) : null;
}

export function getRandomArbitrary(min: number, max: number): number {
  const minCeil: number = Math.ceil(min);
  const maxFloor: number = Math.floor(max);

  return Math.floor(Math.random() * (maxFloor - minCeil + 1)) + minCeil;
}

export function generateId(): string {
  return `MS${getRandomArbitrary(1000000, 9999999)}`;
}

export function getCodeWithIcim(code: string, icim = ""): string {
  return `${code}${icim?.substring(icim?.indexOf(".")) || ""}`;
}

export function getSellData(priceOld: number, priceNew: number, dataOld: number, dataNew: number): string {
  const UPSELL = "Upsell";
  const DOWNSELL = "Downsell";

  if (priceOld < priceNew) {
    return UPSELL;
  } else if (priceOld > priceNew) {
    return DOWNSELL;
  } else return dataOld < dataNew ? UPSELL : DOWNSELL;
}

export function getTax(price: number | null) {
  return price ? formatPrice(price - price / 1.25) : null;
}
