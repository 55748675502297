import { Maybe, OfferingPriceCodes, OfferingPriceDetails } from "gql/graphql";

export function getDownloadSpeed(offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined): number | undefined {
  const downloadSpeed = offeringPrices?.find((price) => price?.code === OfferingPriceCodes.SpeedDownload);

  if (typeof downloadSpeed?.amount === "number" && !!downloadSpeed.monetaryUnit) {
    return downloadSpeed.amount;
  }

  return undefined;
}
