import { colors } from "@telia/styleguide";
import styled from "styled-components";

export const Line = styled.div<{ height?: number | undefined }>`
  width: 50px;
  height: 150%;
  border-left: 2px solid ${colors.corePurple700};
  border-bottom: 2px solid ${colors.corePurple700};
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 10px;
  bottom: 50%;
  margin-top: 3px;
  z-index: -1;
`;
