import dayjs from "dayjs";
import { DetailedUsage } from "./styles/DetailedUsageTable.styled";
import { InvoicedServiceQuery, UnbilledServiceQuery } from "gql/graphql";

const shouldShowDuration = (rows: ServiceDataItems) => rows.filter((r) => !!r.duration).length > 0;

const shouldShowQuantity = (rows: ServiceDataItems) => rows.filter((r) => !!r.quantity).length > 0;

const shouldShowTargetPhoneNumber = (rows: ServiceDataItems) =>
  rows.filter((r) => !!r.destination?.phoneNumber).length > 0;

const shouldShowNetwork = (rows: ServiceDataItems) => rows.filter((r) => !!r.roamingOperator).length > 0;

type ServiceData =
  | UnbilledServiceQuery["subscription"]["unbilledService"]
  | InvoicedServiceQuery["subscription"]["invoicedService"];
type ServiceDataItems = NonNullable<NonNullable<ServiceData>["items"]>;
type IUsageDetailedTableProps = {
  serviceData: ServiceData;
};

export default function DetailedUsageTable({ serviceData }: IUsageDetailedTableProps) {
  if (!serviceData?.items) return null;

  return (
    <DetailedUsage>
      <table className="full-width-table detailed-usage-table">
        <thead className="full-width-table__header">
          <tr className="full-width-table__row">
            <th className="full-width-table__cell">Dato</th>
            <th className="full-width-table__cell">Start tid</th>

            {shouldShowTargetPhoneNumber(serviceData.items) && <th className="full-width-table__cell">Valgt nummer</th>}
            {shouldShowNetwork(serviceData.items) && <th className="full-width-table__cell">Nettverk</th>}
            <th className="full-width-table__cell">Land</th>

            {shouldShowDuration(serviceData.items) && <th className="full-width-table__cell">Varighet</th>}
            {shouldShowQuantity(serviceData.items) && <th className="full-width-table__cell">Antall</th>}
            <th className="full-width-table__cell">Priser</th>
          </tr>
        </thead>
        <tbody className="full-width-table__body">
          {serviceData.items.map((row, i) => {
            if (serviceData.items) {
              return (
                <tr key={i} className="full-width-table__row">
                  <td data-title="Dato" className="full-width-table__cell">
                    {row.date && dayjs(row.date).format("DD.MM.YYYY")}
                  </td>
                  <td data-title="Start tid" className="full-width-table__cell">
                    {row.date && dayjs(row.date).format("HH:mm:ss")}
                  </td>
                  {shouldShowTargetPhoneNumber(serviceData.items) && (
                    <td data-title="Valgt nummer" className="full-width-table__cell">
                      {row.destination?.phoneNumber
                        ? `+${row.destination.phoneNumber.countryCode} ${row.destination.phoneNumber.localNumber}`
                        : ""}
                    </td>
                  )}
                  {shouldShowNetwork(serviceData.items) && (
                    <td data-title="Nettverk" className="full-width-table__cell">
                      {row.roamingOperator}
                    </td>
                  )}
                  <td data-title="Land" className="full-width-table__cell">
                    {row.country?.name}
                  </td>
                  {shouldShowDuration(serviceData.items) && (
                    <td data-title="Varighet" className="full-width-table__cell">
                      {row.duration?.formatted}
                    </td>
                  )}
                  {shouldShowQuantity(serviceData.items) && (
                    <td data-title="Antall" className="full-width-table__cell">
                      {row.quantity?.amount}
                    </td>
                  )}
                  <td data-title="Priser" className="full-width-table__cell">
                    {row.price?.value},-
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
        <tfoot className="full-width-table__footer">
          <tr className="full-width-table__row">
            <td className="full-width-table__cell">Total</td>
            <td className="full-width-table__cell" />
            {shouldShowTargetPhoneNumber(serviceData.items) && <td className="full-width-table__cell" />}
            {shouldShowNetwork(serviceData.items) && <td className="full-width-table__cell" />}
            <td className="full-width-table__cell" />
            {shouldShowDuration(serviceData.items) && (
              <th className="full-width-table__cell">{serviceData.summary.duration?.formatted}</th>
            )}
            {shouldShowQuantity(serviceData.items) && (
              <td className="full-width-table__cell">{serviceData.summary.quantity?.formatted}</td>
            )}
            <td className="full-width-table__cell">{serviceData.summary?.price?.value},-</td>
          </tr>
        </tfoot>
      </table>
    </DetailedUsage>
  );
}
