import { Container, Heading, Paragraph, ScrollToTop } from "@telia-no-min-side/components";
import { ShoppingCart } from "../components/ShoppingCart/ShoppingCart";
import { StaticContainer } from "../styles/style";
import { useFlexOrder } from "../context/FlexOrderProvider";
import { getSelectedRequirementProductIds } from "../utils/getSelectedRequirementProductIds";
import { AskAboutKeepingDecoder } from "./components/AskAboutKeepingDecoder";
import { ShowLinkToMinsideTv } from "./components/ShowLinkToMinsideTv";
import { ShowCustomerService } from "./components/ShowCustomerService";
import { ShowDontNeedNewEquipment } from "./components/ShowDontNeedNewEquipment";
import { RequirementProducts } from "./components/RequirementProducts";

export function RequirementsSelector() {
  const { requirementIndex, pageRequirement, setSelectedRequirementProductIds, hasSelectedRequirementsForCurrentPage } =
    useFlexOrder();

  const requirementSelectHandler = ({ productId }: { productId: number }) =>
    setSelectedRequirementProductIds((prevState) =>
      getSelectedRequirementProductIds({ productId, requirementIndex }, pageRequirement, prevState)
    );

  return (
    <Container gap flexDirection="column">
      <ScrollToTop />
      <Container maxWidth="xs" padding="bottom">
        <Heading tag="h2" variant="title-200">
          {pageRequirement?.title}
        </Heading>
        {!pageRequirement?.askAboutKeepingDecoder && <Paragraph>{pageRequirement?.description}</Paragraph>}
      </Container>
      <Container flexWrap gap="lg" flexDirection="row" horizontalPosition="left">
        <StaticContainer maxWidth="xs" gap="lg" flexDirection="column">
          {pageRequirement?.askAboutKeepingDecoder && <AskAboutKeepingDecoder />}
          {pageRequirement?.showLinkToMinsideTv && <ShowLinkToMinsideTv />}
          {pageRequirement?.showCustomerService && <ShowCustomerService />}
          <RequirementProducts requirementSelectHandler={requirementSelectHandler} />
          {pageRequirement?.showDontNeedNewEquipment && (
            <ShowDontNeedNewEquipment requirementSelectHandler={requirementSelectHandler} />
          )}
        </StaticContainer>
        {hasSelectedRequirementsForCurrentPage && (
          <Container maxWidth="xs">
            <ShoppingCart />
          </Container>
        )}
      </Container>
    </Container>
  );
}
