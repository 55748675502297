import { useNavigate } from "react-router-dom";
import { Skeleton, Flex, Card, Text, Badge } from "@telia/teddy";
import { format, uri } from "@telia-no-min-side/utils";
import { useFetchFlexCurrent, useFetchFlexPrices, useFetchUser } from "src/api/fixed";
import { FlexIcon } from "pages/flex/components/FlexIcon";
import { ErrorCard } from "../../card/ErrorCard";
import { useFixedAccount } from "src/hooks/useFixedAccount";

type Props = {
  customerId: string;
};

export function FlexAgreementCard({ customerId }: Props) {
  const user = useFetchUser();
  const navigate = useNavigate();
  const { changeFixedAccount } = useFixedAccount();
  const flexCurrent = useFetchFlexCurrent({
    customerId,
    requireCustomerId: true,
  });
  useFetchFlexPrices({
    config: {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    },
    customerId,
  });

  const customer = user.data?.usersWithCustomerId.find(
    (userWithCustomerId) => userWithCustomerId.customerId === customerId
  );
  const street = customer ? format.startCase(`${customer.street}`) : null;
  const broadbandSpeed = flexCurrent.data?.currentTvAndBroadband?.broadbandOption?.downloadSpeed || 0;
  const tvPoints = flexCurrent.data?.currentTvAndBroadband?.tvOption?.points || 0;

  const isError = (user.isError && !user.data) || (flexCurrent.isError && !flexCurrent.data);

  if (isError) {
    return (
      <ErrorCard
        onRefreshClick={() => {
          if (user.isError) {
            user.mutate();
          }
          if (flexCurrent.isError) {
            flexCurrent.mutate();
          }
        }}
        isLoading={flexCurrent.isValidating || user.isValidating}
        sectionText="din Flex-avtale"
      />
    );
  }

  const handleClick = () => {
    changeFixedAccount(customerId);
    navigate(uri.minSideFixed("/flex"));
  };

  return (
    <Skeleton loading={user.isLoading || flexCurrent.isLoading}>
      <Card
        shadow
        bordered
        width="100%"
        maxWidth="400px"
        layout="default"
        variant="white"
        data-tracking-id={`flex-agreement-${customerId}`}
      >
        <Card.Slot align="top-right">
          <Badge variant="information" hideIcon>
            Flex-avtale
          </Badge>
        </Card.Slot>

        <Card.Heading as="h3" variant="title-100" data-di-mask>
          {street}
        </Card.Heading>

        <Card.Content>
          <Flex direction="column" gap="150">
            <Flex direction="row" gap="150" style={{ opacity: broadbandSpeed === 0 ? 0.5 : 1 }}>
              <FlexIcon icon="speedometer-dots" title="Bredbånd" />
              <Text variant="paragraph-100">Internett: {broadbandSpeed || 0} Mbps</Text>
            </Flex>

            <Flex direction="row" gap="150" style={{ opacity: tvPoints === 0 ? 0.5 : 1 }}>
              <FlexIcon icon="entertainment" title="Tv" />
              <Text variant="paragraph-100">Strømming & TV: {tvPoints} poeng</Text>
            </Flex>
          </Flex>
        </Card.Content>

        <Card.Footer height="100%" align="end" justify="start">
          <Card.Button variant="text" onClick={handleClick}>
            Administrer
          </Card.Button>
        </Card.Footer>
      </Card>
    </Skeleton>
  );
}
