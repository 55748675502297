type DownloadBlob = {
  blob: Blob;
  fileName?: string;
  openInNewTab?: boolean;
  openInSameWindow?: boolean;
};

export const downloadBlob = ({ blob, fileName, openInNewTab, openInSameWindow }: DownloadBlob) => {
  if (openInNewTab) {
    window.open(URL.createObjectURL(blob));
  } else {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    if (openInSameWindow) {
      link.setAttribute("target", "_self");
    } else {
      link.setAttribute("download", fileName || "");
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
