import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { config } from "util/config";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchFwaPricingInfo() {
  const { data, error, ...rest } = useAxiosSWR<types.fixed.OffersPricingInfo>(
    config.showFwaDiscount ? ICX_REQUEST.URL_FWA_OFFERS_PRICE_INFO : null
  );

  return {
    data,
    isError: !!error,
    ...rest,
  };
}
