import { useEffect, useState } from "react";
import { useFetchProductGroup } from "src/api/fixed";
import { useFixedInvoice } from "src/hooks/useFixedInvoice";
import { OverdueInvoiceWarning } from "components/overdue-invoice";
import { OrderSummary } from "./order-summary";
import { SelectTvBoxList } from "./SelectTvBoxList";
import { ShoppingCard } from "components/shopping-card";
import { Box, breakpoint, Text, Flex } from "@telia/teddy";

export function BuyNewTvBox(): JSX.Element | null {
  const newTvBoxesOptions = useFetchProductGroup("grp_new_tv_box");
  const [selectedBoxAxiomId, setSelectedBoxAxiomId] = useState<number | null>(null);
  const [selectedBoxOptionId, setSelectedBoxOptionId] = useState<number | null>(null);
  const { isInvoiceOverdueOrInCollection } = useFixedInvoice();

  useEffect(() => {
    setSelectedBoxOptionId(null); // reset selected box option when selected box is changed
  }, [selectedBoxAxiomId]);

  if (newTvBoxesOptions.isLoading) {
    return (
      <Flex maxWidth={breakpoint.teddyBreakpointMd} id="buy-new-tv-box-skeleton">
        <ShoppingCard.Skeleton hasTitle />
      </Flex>
    );
  }

  if (newTvBoxesOptions.isError || !newTvBoxesOptions.data) {
    return (
      <Box pb="gutter-sm" data-test-id="buy-new-tv-box-product-card-error">
        <Text>En feil har oppstått ved lasting av innholdet! Vennligst last inn siden på nytt og prøv igjen.</Text>
      </Box>
    );
  }

  return (
    <Flex gap="gutter-md" maxWidth={breakpoint.teddyBreakpointLg} wrap="wrap">
      <Box flexGrow="2" flexShrink="1" maxWidth="600px">
        <SelectTvBoxList
          shouldDisableSelect={isInvoiceOverdueOrInCollection}
          selectedBoxAxiomId={selectedBoxAxiomId}
          setSelectedBoxAxiomId={setSelectedBoxAxiomId}
        />
        <OverdueInvoiceWarning variant="TV_BOX" />
      </Box>

      <Box flexGrow="1" flexShrink="1">
        {selectedBoxAxiomId && !isInvoiceOverdueOrInCollection && (
          <OrderSummary selectedBoxAxiomId={selectedBoxAxiomId} selectRecordingId={selectedBoxOptionId} />
        )}
      </Box>
    </Flex>
  );
}
