import { Accordion, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";

export function ShippingCostAccordion(): JSX.Element {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Når kommer fraktkostnad i tillegg?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Når du bestiller utstyr fra oss for første gang, eller du velger å oppgradere det utstyret du har uten at det
          er noe galt med det du erstatter, vil det tilkomme en kostnad for frakten.
        </Paragraph>
        <Paragraph>
          Sendes utstyret med servicepakke til postkontor/post i butikk, vil kostnaden vises som Frakt (99,-) på
          fakturaen.
        </Paragraph>
        <Paragraph>Sendes utstyret hjem til deg med hjemlevering vil det stå Hjemlevering (129,-).</Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
