import { useState, useEffect } from "react";
import { Icon, toast } from "@telia/teddy";
import { ConfirmPaymentStatus } from "gql/graphql";
import { useAdditionalProducts } from "hooks/useAdditionalProducts";
import { useMutationResponse } from "hooks/useMutationResponse";
import { useMobileBroadbandContext } from "pages/internet/mobile-broadband/context/MobileBroadbandContext";
import { getTopupDisplayInfo } from "pages/mobile/mobile-subscription-dashboard/modules/TopupModal/utils";
import { TopupModalView } from "pages/mobile/mobile-subscription-dashboard/modules/TopupModal/components/TopupModalView";

export function TopupModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { orderResponse } = useAdditionalProducts();
  const {
    selectedTopup,
    setSelectedTopup,
    orderTopup,
    paymentStatus,
    purchaseError,
    topupCategory,
    setTopupCategory,
    setDrawerContent,
    closeDrawer,
  } = useMobileBroadbandContext();

  const {
    mutationResponseMessage: { isSuccessfulMutation },
    setMutationResponseMessage,
  } = useMutationResponse();

  useEffect(() => {
    setIsModalOpen(!!selectedTopup || !!isSuccessfulMutation || !!purchaseError || !!paymentStatus);
  }, [selectedTopup, isSuccessfulMutation, purchaseError, paymentStatus]);

  // Edge case: Show modal after returning from a 3rd party payment page (e.g., VIPPS),
  useEffect(() => {
    if (purchaseError || paymentStatus === ConfirmPaymentStatus.Failed) {
      toast.error("Her gikk noe galt!", {
        icon: <Icon name="error-filled" size="sm" />,
        description:
          "Vi kunne dessverre ikke behandle forespørselen din og fikk heller ikke opprettet en manuell sak for oppfølging. Vennligst prøv igjen litt senere.",
      });
      setIsModalOpen(false);
      setSelectedTopup(undefined);
    }
  }, [purchaseError, paymentStatus]);

  useEffect(() => {
    if (isSuccessfulMutation || paymentStatus === ConfirmPaymentStatus.Ok) {
      const title = topupCategory === "Boost" ? "Din Data Boost nå aktiv" : "Din datapakke er nå aktiv";
      toast.success(title, {
        description: "Oversikt over bruk finner du lenger ned på siden.",
      });

      setIsModalOpen(false);
      setDrawerContent(undefined);
      setTopupCategory(undefined);
      setMutationResponseMessage({ isSuccessfulMutation: false, title: "", text: "" });
      closeDrawer();
    }
  }, [isSuccessfulMutation, paymentStatus]);

  const closeModal = () => setIsModalOpen(false);
  const closeModalResetState = () => {
    closeModal();
    setSelectedTopup(undefined);
  };

  if (selectedTopup) {
    const topupDisplayInfo = getTopupDisplayInfo(selectedTopup);
    const topupInfo = `${topupDisplayInfo.size} ${topupDisplayInfo.sizeUnit}`;

    return (
      <TopupModalView
        isOpen={isModalOpen}
        closeModal={closeModalResetState}
        heading={`Du har valgt ${topupInfo} for ${topupDisplayInfo.price},-`}
        description="Denne tjenesten vil aktiveres umiddelbart."
        leftButtonText="Avbryt"
        leftButtonClick={closeModalResetState}
        rightButtonText={topupDisplayInfo.numberOfVouchers ? "Aktiver" : "Kjøp"}
        rightButtonClick={() => orderTopup(selectedTopup)}
        isLoading={orderResponse?.loading}
      />
    );
  }

  return null;
}
