import { Skeleton, useAuth, useAxiosSWR } from "@telia-no-min-side/components";
import { isPurchased } from "../utils/product";
import { SkyProductPurchased } from "./SkyProductPurchased";
import { VasAdditionalProduct } from "../types";
import { TeliaSkyCheckout } from "./TeliaSkyCheckout";
import { isTeliaSkyIncluded } from "./utils/isTeliaSkyIncluded";
import { isB2BCompanyPaidTeliaSkyProduct } from "./utils/isB2BCompanyPaidTeliaSkyProduct";
import { AUTH } from "util/constants/auth";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

type Props = {
  product?: VasAdditionalProduct;
  isLegalOwner: boolean;
};

export const TeliaSky = ({ product, isLegalOwner }: Props) => {
  const { selectedAuthProvider } = useAuth();

  const isCTUser = selectedAuthProvider === AUTH.PROVIDER.CT;
  const isB2BTeliaSky = product ? isB2BCompanyPaidTeliaSkyProduct(product) : false;

  const shouldCheckForTeliaSkyOnFixedSide =
    !isCTUser && product && !isPurchased(product) && !isTeliaSkyIncluded(product) && isLegalOwner && !isB2BTeliaSky;

  const { isLoading, data } = useAxiosSWR(shouldCheckForTeliaSkyOnFixedSide ? ICX_REQUEST.URL_TELIA_SKY_STATUS : null);

  const hasFreeTeliaSkyOnFixedSide = !!data?.hasTeliaSky;

  // Telia Sky should be hidden for users on old login, because
  // Telia Sky requires the new login to be able to log in to the Telia Sky service itself.
  // Also it will not be possible to order the product if you dont have a personal ID.
  if (isCTUser) return null;

  if (!product) return null;
  if (isLoading)
    return (
      <>
        <Skeleton variant="rectangular" height="10rem" width="auto" />
        <Skeleton variant="rectangular" height="10rem" width="auto" />
        <Skeleton variant="rectangular" height="10rem" width="auto" />
      </>
    );
  return (
    <div data-tracking-id="additional-services-telia-sky">
      {isPurchased(product) ? (
        <SkyProductPurchased product={product} />
      ) : (
        <TeliaSkyCheckout product={product} hasFreeTeliaSkyOnFixedSide={hasFreeTeliaSkyOnFixedSide} />
      )}
    </div>
  );
};
