import React from "react";
import { Slot } from "@radix-ui/react-slot";
import { composeEventHandlers } from "./util";
import { GenericEvent, track } from "@telia-no-min-side/utils";
import styled from "styled-components";

type TrackClickEventProps = Omit<React.ComponentPropsWithoutRef<"button">, "asChild"> & {
  // When asChild is set to true, Radix will not render a default DOM element, instead cloning the part's child and passing it the props and behavior required to make it functional.
  asChild?: boolean;
  event: GenericEvent | undefined;
};

export function TrackClickEvent({ asChild = false, event, ...props }: TrackClickEventProps) {
  const Comp = asChild ? Slot : Button;
  const { pushGenericTrackingEvent } = track.useEventTracking();

  return (
    <Comp
      {...props}
      onClick={composeEventHandlers(props.onClick, () => {
        event && pushGenericTrackingEvent(event);
      })}
    />
  );
}

const Button = styled.button`
  all: unset;
`;
