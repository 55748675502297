import { Accordion, TextSpacing, Text, Link, Icon } from "@telia/teddy";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function FaqAgreements() {
  return (
    <Accordion type="multiple" style={{ marginTop: "70px" }}>
      <Accordion.Title as="h3" variant="title-100">
        Ofte stilte spørsmål
      </Accordion.Title>
      <Accordion.Item value="Hva skjer hvis jeg skifter abonnement mens jeg har SVITSJ?">
        <Accordion.Header>
          <Accordion.Trigger>Hva skjer hvis jeg skifter abonnement mens jeg har SVITSJ?</Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <TextSpacing>
            <Text as="p">
              Du kan når som helst bytte abonnement når du har en SVITSJ avtale. Det påvirker ikke SVITSJ nedbetalingen
              din, men totalbeløpet på fakturaen vil øke eller synke basert på om du går opp eller ned i abonnement.
            </Text>
          </TextSpacing>
        </Accordion.Content>
      </Accordion.Item>
      <Accordion.Item value="Hva skjer hvis jeg sier opp Telia men har SVITSJ?">
        <Accordion.Header>
          <Accordion.Trigger>Hva skjer hvis jeg sier opp Telia, men har SVITSJ?</Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <Text as="p">
            SVITSJ-avtalen din beholder du selv om du sier opp ditt kundeforhold hos Telia. Du vil faktureres månedlig
            så lenge du har et nummer, uavhengig av om du har nummeret ditt i Telia videre.
          </Text>
        </Accordion.Content>
      </Accordion.Item>
      <Accordion.Item value="Hvordan returnerer jeg min gamle SVITSJ telefon?">
        <Accordion.Header>
          <Accordion.Trigger>Hvordan returnerer jeg min gamle SVITSJ telefon?</Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <TextSpacing>
            <Text as="p">Når du har valgt en ny telefon vil du motta en retureske.</Text>
            <Text as="p">Følg denne sjekklisten før du returnerer din SVITSJ telefon:</Text>

            <Link variant="text" href={EXTERNAL_LINK.BEFORE_SWITCH_PHONE_TERMS}>
              Før du svitsjer
              <Icon name="arrow-right" />
            </Link>
          </TextSpacing>
        </Accordion.Content>
      </Accordion.Item>

      <Accordion.Item value="Kan jeg avslutte SVITSJ avtalen min?">
        <Accordion.Header>
          <Accordion.Trigger>Kan jeg avslutte SVITSJ avtalen min?</Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <TextSpacing>
            <Text as="p">
              Du kan ikke selv avslutte SVITSJ avtalen din. Ønsker du å avslutte SVITSJ avtalen din kan du kontakte
              kundesenteret vårt på 924 05 050.
            </Text>
            <Text as="p">
              Dette er fordi vi vil sørge for at alt går riktig for seg. Når du sier opp SVITSJ avtalen din vil du være
              nødt til å betale ut resterende beløp for telefonen din, dette havner på fakturaen din og kan føre til at
              abonnementet ditt blir sperret hvis ikke kredittgrensen er høy nok.
            </Text>
          </TextSpacing>
        </Accordion.Content>
      </Accordion.Item>

      <Accordion.Item value="Hva skjer med delbetalingsavtalen min hvis jeg sier opp mobilabonnementet mitt med Telia?">
        <Accordion.Header>
          <Accordion.Trigger>
            Hva skjer med delbetalingsavtalen min hvis jeg sier opp mobilabonnementet mitt med Telia?
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <TextSpacing>
            <Text as="p">
              Hvis du sier opp ditt kundeforhold/abonnement hos Telia mens du har en pågående delbetaling/rabattavtale
              vil du bli bedt om å betale ut resterende beløp for telefonen din. Dette vil komme på din neste faktura
            </Text>
          </TextSpacing>
        </Accordion.Content>
      </Accordion.Item>
      <Accordion.Item value="Kan jeg avslutte delbetalingsavtalen min?">
        <Accordion.Header>
          <Accordion.Trigger>Kan jeg avslutte delbetalingsavtalen min?</Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <TextSpacing>
            <Text as="p">
              Ja, med bruddgebyr. Bruddgebyret er lik totalrabatten kunden får i kampanjen. Bruddgebyret reduseres
              månedlig ut avtaletiden regnet fra avtaleinngåelsen, men minimumsbeløpet er likevel ikke mindre enn kr
              399.
            </Text>
          </TextSpacing>
        </Accordion.Content>
      </Accordion.Item>
      <Accordion.Item value="Kan jeg endre forsikringene mine?">
        <Accordion.Header>
          <Accordion.Trigger>Kan jeg endre forsikringene mine?</Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <TextSpacing>
            <Text as="p">Forsikringer kan kanselleres når som helst.</Text>
            <Text as="p">
              Dersom du ønsker å oppgradere eller kjøpe en ny forsikring er dette kun mulig ved kjøp av ny telefon og
              innen 30 dager etter kjøp.
            </Text>
          </TextSpacing>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  );
}
