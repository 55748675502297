import { useState } from "react";
import { Container, Heading } from "@telia-no-min-side/components";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { MobileSubscriptionPicker } from "src/components/profile-pickers/MobileSubscriptionPicker";
import { Subscription } from "src/components/profile-pickers/hooks/useProfilePicker";
import { Suspend } from "./components/Suspend";

export function SuspendSubscriptionPage() {
  const [showProfilePicker, setShowProfilePicker] = useState(true);
  const { phoneNumber, setPhoneNumber, setAccountId } = useMobileAccount();

  const handleProfileChange = (phoneNumber: string, subscription: Subscription) => {
    setPhoneNumber(phoneNumber);
    setAccountId(subscription.account.id);
  };

  return (
    <Container data-tracking-id="header--suspend-subscription" showGoBackButton padding gap="lg" flexDirection="column">
      <Heading tag="h1" variant="title-300">
        Sperr SIM og abonnement
      </Heading>
      {showProfilePicker && (
        <>
          <Heading tag="h3" variant="title-100">
            Velg abonnement:
          </Heading>
          <MobileSubscriptionPicker
            variant="primary"
            onProfileSelect={handleProfileChange}
            selectedProfileId={phoneNumber}
            subcriptionType="all"
            hideFamilyTab
          />
        </>
      )}
      <Suspend onSuspended={() => setShowProfilePicker(false)} />
    </Container>
  );
}
