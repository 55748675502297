import { Notification } from "@telia/teddy";

export function EmptyTopupNotification() {
  return (
    <Notification variant="information">
      <Notification.Heading as="h3">
        <Notification.Icon>Denne tjenesten er ikke tilgjengelig</Notification.Icon>
      </Notification.Heading>
      <Notification.Text>Dette kan være fordi tjenesten ikke inngår i ditt abonnement.</Notification.Text>
    </Notification>
  );
}
