import { Link } from "react-router-dom";
import { Flex, Heading, Card, Grid, IconName, Icon, Text } from "@telia/teddy";
import { INTERNAL_LINK } from "util/constants/internalLinks";

type DataType = { link: string; icon: IconName; title: string; description: string };

export function AdminSection() {
  const ADMIN_SECTION_DATA: DataType[] = [
    {
      link: INTERNAL_LINK.MOBILE_NEW.SETTINGS_PAGE,
      icon: "settings",
      title: "Innstillinger",
      description: "Administrer dine innstillinger",
    },
  ];

  return (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100">
        Administrer
      </Heading>

      <Grid columns={{ md: "1", lg: "2" }} justify="stretch" gap="gutter-xl">
        {ADMIN_SECTION_DATA.map((item) => (
          <Card key={item.title} layout="navigation-horizontal-small" variant="gray" width="100%">
            <Card.Illustration>
              <Icon name={item.icon} size="lg" />
            </Card.Illustration>
            <Card.Heading variant="title-100">{item.title}</Card.Heading>
            <Card.Content>
              <Text>{item.description}</Text>
            </Card.Content>
            <Card.Button aria-label="Proceed to page" asChild iconOnly>
              <Link to={item.link}>
                <Icon name="arrow-right" />
              </Link>
            </Card.Button>
          </Card>
        ))}
      </Grid>
    </Flex>
  );
}
