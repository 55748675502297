import { Heading, Text, TextSpacing } from "@telia/teddy";

type Props = {
  isCommitment?: boolean;
};

export function PdfFooter({ isCommitment }: Props) {
  return isCommitment ? (
    <TextSpacing className="show-on-print">
      <Heading as="h3" variant="subsection-100">
        Våre standardvilkår
      </Heading>
      <Text>
        Avtalen forutsetter en bindingstid på 12 måneder, og du har 14 dagers angrerett etter akseptert tilbud i henhold
        til angrerettloven. Les alle våre standardvilkår [https://www.telia.no/hjelp/vilkar-og-angrerett/]. Her finner
        du også angrerettskjema.
      </Text>
      <Text>
        Bruddgebyret er lik totalrabatten du får i kampanjen. Gebyret reduseres med en tolvtedel hver måned, ned til
        minimum 399 kr.
      </Text>
      <Text>
        Telia tar forbehold om at det i tilbudet for øvrig ikke er økonomiske eller tekniske hindringer for leveranse av
        tjenesten(e).
      </Text>
    </TextSpacing>
  ) : (
    <TextSpacing className="show-on-print">
      <Heading as="h3" variant="subsection-100">
        Våre standardvilkår
      </Heading>
      <Text>
        Du har 14 dagers angrerett etter akseptert tilbud i henhold til angrerettloven. Les alle våre standardvilkår
        [https://www.telia.no/hjelp/vilkar-og-angrerett/]. Her finner du også angrerettskjema.
      </Text>
      <Text>
        Telia tar forbehold om at det i tilbudet for øvrig ikke er økonomiske eller tekniske hindringer for leveranse av
        tjenesten(e).
      </Text>
    </TextSpacing>
  );
}
