import { Skeleton } from "@telia/teddy";
import { ActiveDataBoost } from "./components/ActiveDataBoost/ActivedataBoost";
import { CoverageGuaranteeCard } from "./components/CoverageGuarantee/CoverageGuaranteeCard";
import { DataUsageCardError, LimitedDataUsageCard, UnlimitedDataUsageCard } from "./components";
import { useMobileBroadbandContext } from "pages/internet/mobile-broadband/context/MobileBroadbandContext";

export function DataUsageCard() {
  const { loading, error, refetch, isUnlimitedDataSubscription, hasCoverageGuarantee, activeDataBoost } =
    useMobileBroadbandContext();

  if (loading) return <Skeleton height="440px" width="100%" maxWidth="350px" />;
  if (error) return <DataUsageCardError refetch={refetch} />;

  if (hasCoverageGuarantee) {
    return <CoverageGuaranteeCard />;
  }

  if (activeDataBoost) {
    return <ActiveDataBoost />;
  }
  if (isUnlimitedDataSubscription) {
    return <UnlimitedDataUsageCard />;
  }

  return <LimitedDataUsageCard />;
}
