import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Badge, Card, Text } from "@telia/teddy";
import { Link, Paragraph, Skeleton } from "@telia-no-min-side/components";
import { format, track, uri } from "@telia-no-min-side/utils";
import { useFetchProductGroup, useFetchProductsAdditionalInfo, useFetchUser } from "src/api/fixed";
import { BaseCard } from "src/pages/home/card/style";
import { NameList } from "src/pages/home/style";
import { useSelectAccount } from "hooks/useSelectAccount";
import { AUTH } from "util/constants/auth";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";
import { getAvailableSmartWifiProducts } from "pages/internet/broadband/utils";

export function UpSellSmartWifiRouterCard() {
  const smartWifi = useFetchProductGroup("grp_smart_wifi", {
    fetchAllAccounts: true,
  });
  const navigate = useNavigate();
  const { setAccount } = useSelectAccount();
  const [showSelectAccount, setShowSelectAccount] = useState(false);
  const productsAdditionalInfo = useFetchProductsAdditionalInfo({
    fetchAllAccounts: true,
  });
  const user = useFetchUser();
  const { pushGenericTrackingEvent } = track.useEventTracking();

  const isLoading = smartWifi.isLoading || productsAdditionalInfo.isLoading || user.isLoading;

  if (isLoading) {
    return (
      <BaseCard>
        <Skeleton variant="heading-s" width="20ch" />
        <Skeleton width="90%" />
        <Skeleton variant="button-small" float="right" width="12ch" />
      </BaseCard>
    );
  }

  if (!smartWifi.data || !productsAdditionalInfo.data) return null;

  const isCampaign = Object.keys(smartWifi.data).every((customerId) => {
    if (!smartWifi.data?.[customerId]) return false;
    const { smartWifiRouter } = getAvailableSmartWifiProducts(smartWifi.data[customerId]);
    if (!smartWifiRouter?.price) return false;
    return smartWifiRouter.price.postCampaignPrice.recurring > smartWifiRouter.price.currentPrice.recurring;
  });

  const customerIdsThatCanOrderInternetEquipment =
    productsAdditionalInfo.data &&
    Object.keys(productsAdditionalInfo.data).filter(
      (customerId) => productsAdditionalInfo.data?.[customerId].canOrderInternetEquipment
    );

  const customerIdsWithAvailableSmartWifi =
    smartWifi.data &&
    Object.keys(smartWifi.data).filter(
      (customerId) =>
        smartWifi.data?.[customerId] && !!getAvailableSmartWifiProducts(smartWifi.data[customerId]).smartWifiRouter
    );

  if (!customerIdsWithAvailableSmartWifi.length || !customerIdsThatCanOrderInternetEquipment.length) return null;

  const customerIdsApplicableForSmartWifi = customerIdsThatCanOrderInternetEquipment.filter((customerId) =>
    customerIdsWithAvailableSmartWifi.includes(customerId)
  );

  if (showSelectAccount && user.data) {
    const userWithSmartWifi = customerIdsApplicableForSmartWifi.map(
      (customerId) =>
        user.data?.usersWithCustomerId.find((userWithCustomerId) => userWithCustomerId.customerId === customerId)
    );
    return (
      <Card shadow bordered height="100%" width="100%" maxWidth="400px" layout="default" variant="white">
        {isCampaign && (
          <Card.Slot align="top-right">
            <Badge variant="special" hideIcon>
              Kostnadsfritt første 3 md.
            </Badge>
          </Card.Slot>
        )}

        <Card.Content>
          <Paragraph>Velg hvilken adresse det gjelder:</Paragraph>
          <Flex direction="column" gap="150">
            <NameList>
              {userWithSmartWifi?.map(
                (customer) =>
                  customer && (
                    <li key={`${customer.customerId}-select-wifi-address`} data-di-mask>
                      <Link
                        variant="standalone"
                        href={uri.minSideFixed(ICX_REQUEST.URL_BUY_SMART_WIFI)}
                        isInternalNavigation
                        onClick={() => {
                          pushGenericTrackingEvent({
                            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
                            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
                            ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_SMART_WIFI,
                            ui_item_url: uri.minSideFixed(ICX_REQUEST.URL_BUY_SMART_WIFI),
                            ui_item_text: format.startCase(`${customer.street}`),
                          });
                          setAccount({ accountType: AUTH.ACCOUNT_TYPE.FIXED, id: customer.customerId });
                        }}
                      >
                        {format.startCase(`${customer.street}`)}
                      </Link>
                    </li>
                  )
              )}
            </NameList>
          </Flex>
        </Card.Content>
      </Card>
    );
  }

  const hasMultipleAvailableSmartWifiProducts = customerIdsApplicableForSmartWifi.length > 1;
  const customerIdToSelect = customerIdsApplicableForSmartWifi.find((customerId) => customerId);

  const handleMultipleSmartWifi = () => {
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_SMART_WIFI,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.ORDER,
    });
    setShowSelectAccount(true);
  };

  const handleUpgradeYouRouter = () => {
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.HOME,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BANNER_SMART_WIFI,
      ui_item_url: uri.minSideFixed(ICX_REQUEST.URL_BUY_SMART_WIFI),
      ui_item_text: TRACK_EVENT.ITEM_TEXT.ORDER,
    });
    customerIdToSelect && setAccount({ accountType: AUTH.ACCOUNT_TYPE.FIXED, id: customerIdToSelect });
    navigate(uri.minSideFixed(ICX_REQUEST.URL_BUY_SMART_WIFI));
  };

  const handleClick = () => {
    return hasMultipleAvailableSmartWifiProducts ? handleMultipleSmartWifi() : handleUpgradeYouRouter();
  };

  return (
    <Card shadow bordered width="100%" maxWidth="400px" layout="default" variant="white">
      {isCampaign && (
        <Card.Slot align="top-right">
          <Badge variant="special" hideIcon>
            Kostnadsfritt første 3 md.
          </Badge>
        </Card.Slot>
      )}

      <Card.Heading as="h3" variant="title-100">
        Oppgrader din router
      </Card.Heading>

      <Card.Content>
        <Text variant="additional-100">
          Vår kraftigste router <strong>Smart WiFi</strong> sørger for et superraskt og stabilt nett hjemme, som sømløst
          dekker hele huset.
        </Text>
      </Card.Content>

      <Card.Footer height="100%" align="end">
        <Card.Button variant="text" onClick={handleClick}>
          Bestill
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
