import { ReactNode } from "react";
import { ApolloError } from "@apollo/client";
import { Link } from "@telia-no-min-side/components";
import { config } from "src/util/config";
import { VAS_ERROR_CODE, VAS_MESSAGE } from "util/constants/valueAddedServices";

type DisplayError = {
  title: string;
  text: ReactNode;
};

type ErrorContext = {
  isCancelServiceError?: boolean;
};

export const getErrorMessage = (error: ApolloError | undefined, context?: ErrorContext): DisplayError | undefined => {
  if (!error) return undefined;

  if (error.message.includes(VAS_ERROR_CODE.USED_BY_SIS)) {
    return {
      title: VAS_MESSAGE.CANNOT_PROCCESS_ORDER,
      text: VAS_MESSAGE.EMAIL_ALREADY_IN_USE,
    };
  }

  if (error.message.includes(VAS_ERROR_CODE.TELIA_SKY_ALREADY_ACTIVE)) {
    return {
      title: VAS_MESSAGE.TELIA_SKY_ALREADY_ACTIVE,
      text: (
        <>
          Du har allerede aktivert Telia Sky og kan derfor ikke bestille dette på nytt. Klikk{" "}
          <Link href={config.teliaSkyLoginUrl} target="_blank">
            her
          </Link>{" "}
          for å logge inn på din Telia Sky-konto
        </>
      ),
    };
  }

  if (context?.isCancelServiceError) {
    return {
      title: VAS_MESSAGE.SOMETHING_WENT_WRONG,
      text: VAS_MESSAGE.REQUEST_PROCESSING_FAILURE_MESSAGE,
    };
  }

  return {
    title: VAS_MESSAGE.CANNOT_COMPLETE_ORDER,
    text: VAS_MESSAGE.UNKNOWN_ERROR,
  };
};
