import { Heading, Flex, Box } from "@telia/teddy";
import { useFetchBroadbandProducts } from "src/api/fixed";
import { getDeviceProducts } from "util/productHelper";
import { FixedDeviceCard } from "components/fixed-device-card/FixedDeviceCard";

export function Hardware() {
  const { broadbandProducts, isError, isLoading, numberOfSkeleton } = useFetchBroadbandProducts({
    enableSkeletonNumberStore: true,
  });
  const broadbandProductDevices = getDeviceProducts(broadbandProducts?.products) ?? [];
  const boxWidths = { sm: "100%", md: "45%", lg: "33.33%" };

  if ((broadbandProductDevices.length === 0 || isError) && !isLoading) return null;

  return (
    <Box mt="600" mb="200">
      <Heading as="h2" variant="title-100">
        Ditt bredbåndutstyr
      </Heading>
      <Flex mt="200" gap="400" wrap="wrap">
        {isLoading &&
          [...Array(numberOfSkeleton)].map((v, i) => (
            <Box key={`loading-box-${i}`} width={boxWidths}>
              <FixedDeviceCard isLoading />
            </Box>
          ))}
        {broadbandProductDevices.map((product) => (
          <Box key={`product-box-${product.id}`} width={boxWidths}>
            <FixedDeviceCard isLoading={false} product={product} />
          </Box>
        ))}
      </Flex>
    </Box>
  );
}
