import { types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { getCustomerSpecificUrl, GetCustomerSpecificUrlArgs } from "./utils";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function useFetchFinancial(args?: GetCustomerSpecificUrlArgs) {
  const { error, ...rest } = useAxiosSWR<types.fixed.Financial>(
    getCustomerSpecificUrl(ICX_REQUEST.URL_FINANCIAL, args)
  );

  return {
    isError: !!error,
    error: error,
    ...rest,
  };
}
