import type { AuthenticationAdapter } from "./auth";

export function createAppSsoAdapter(): AuthenticationAdapter {
  function requestSignOut() {
    window.location.replace(
      `/minside/logoutcontroller/?loginState=login&service=https%3A%2F%2F${window.location.host}`
    );
  }

  return {
    requestSignOut,
  };
}
