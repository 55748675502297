import { graphql } from "gql";

export const GET_PRODUCT_USER = graphql(`
  query getProductUser($phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      productUser {
        firstName
        surname
        emailAddress
        birthDate
        telephoneNumber {
          localNumber
          countryCode
        }
        address {
          urbanAddress {
            careOfName
            streetName
            streetNr
            streetNrSuffix
            city
            postcode
            floorNumber
            doorNumber
            locality
          }
          poboxAddress {
            boxNr
            boxName
            city
            postcode
          }
        }
      }
      subscriptionStatus
      phoneNumber {
        localNumber
      }
    }
  }
`);
