import { Grid, Flex, Heading, Drawer, List, Icon, Card } from "@telia/teddy";
import { Loading, Error } from "..";
import { useExploreSection } from "../../hooks";
import { useMobileAccount } from "hooks/useMobileAccount";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { NavigationCard, Props as NavigationCardProps } from "components/NavigationCard";
import { MOBILE_VAS } from "util/constants/valueAddedServices";
import { useMobileDashboardContext } from "../../../../context/MobileDashboardContext";

type ExploreSectionCard = NavigationCardProps & { condition: boolean };

export function ExploreSection() {
  const { isPrepaid, isLegalOwner, isOver18 } = useMobileDashboardContext();
  const { accountId, phoneNumber } = useMobileAccount();
  const { loading, error, refetch, showAnnualDiscountCard, showAccountOverviewCard, dataShareText } =
    useExploreSection();

  const cardData = [
    {
      condition: showAnnualDiscountCard && !isPrepaid,
      link: `${INTERNAL_LINK.MOBILE.ANNUAL_DISCOUNT_PAGE}/${phoneNumber}`,
      icon: "savings",
      title: "Rabatt til deg",
      description: "Legg til avtaletid, og få rabatt på 100,- i måneden.",
    },
    {
      condition: !!dataShareText && !isPrepaid,
      icon: "send",
      title: "Data Sharing",
      description: "Del ubrukt Rollover-data med andre som har Telia-abonnement.",
      cardVariant: "gray",
      badge: {
        variant: "special",
        icon: "present",
        text: dataShareText || "", // is handled by condition above
      },
      children: (
        <Drawer>
          <Drawer.Trigger>
            <Card.Button iconOnly aria-label="Slik fungerer Data Sharing">
              <Icon name="arrow-right" />
            </Card.Button>
          </Drawer.Trigger>

          <Drawer.Content>
            <Drawer.Close slot="floating" aria-label="Lukk" />
            <Drawer.Title>Data Sharing</Drawer.Title>
            <Drawer.Description>
              Bruker du ikke opp all dataen din, og kjenner noen som har behov for litt ekstra? Med tjenesten Data
              Sharing kan du i appen dele data du har oppspart fra forrige måned; altså ubrukt Rollover-data.
            </Drawer.Description>
            <Heading as="h4" variant="title-200" mt="gutter-lg">
              Slik fungerer det:
            </Heading>
            <List asChild variant="icon-white">
              <ol>
                <List.Item>
                  <Icon name="smartphone" size="md" />
                  <List.Content>Last ned eller åpne Telia-appen.</List.Content>
                </List.Item>
                <List.Item>
                  <Icon name="present" size="md" />
                  <List.Content>Velg hvor mye data du vil sende. Du kan sende opptil 10 GB i måneden.</List.Content>
                </List.Item>
                <List.Item>
                  <Icon name="send" size="md" />
                  <List.Content>Del med en venn som har Telia Mobil-, Barn- eller Junior-abonnement.</List.Content>
                </List.Item>
              </ol>
            </List>
          </Drawer.Content>
        </Drawer>
      ),
    },
    {
      condition: !isPrepaid && isOver18,
      link: `${INTERNAL_LINK.VALUE_ADDED_SERVICES_PAGE}/${MOBILE_VAS.TRYGG}/${phoneNumber}`,
      icon: "secured-1",
      title: "Telia Trygg",
      description: "Vår beste trygghetspakke noensinne.",
    },
    {
      condition: !isPrepaid && isOver18,
      link: INTERNAL_LINK.VALUE_ADDED_SERVICES_PAGE,
      icon: "services",
      title: "Dine tjenester",
      description: "Se, bestill og administrer dine tjenester",
    },
    {
      condition: showAccountOverviewCard && !isPrepaid,
      link: INTERNAL_LINK.MOBILE_NEW.ACCOUNT_OVERVIEW_PAGE,
      icon: "care",
      title: "Din familierabatt",
      description: "Se full oversikt over dine abonnement og familerabatter",
    },
    {
      condition: isLegalOwner,
      link: `${INTERNAL_LINK.MOBILE.ADD_SUBSCRIPTION_PAGE}/${accountId}`,
      icon: "cart",
      title: "Kjøp nytt abonnement",
      description: "Kjøp nytt abonnement fra kun 99,-",
    },
    {
      condition: true,
      link: EXTERNAL_LINK.MOBILE_HELP,
      icon: "question",
      title: "Hjelpesider mobil",
      description: "Sjekk ut våre guider hvis du har problemer med internett",
      cardVariant: "purple-light",
    },
  ] satisfies ExploreSectionCard[];

  if (loading) return <Loading />;
  if (error) return <Error title="Utforsk" refetch={refetch} />;

  return (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100">
        Utforsk
      </Heading>

      <Grid columns={{ sm: "1", md: "2" }} gap="gutter-xl">
        {cardData.map(
          (card) =>
            card.condition && (
              <NavigationCard
                key={card.title}
                link={card.link}
                icon={card.icon}
                title={card.title}
                description={card.description}
                cardVariant={card.cardVariant}
                badge={card.badge}
              >
                {card.children}
              </NavigationCard>
            )
        )}
      </Grid>
    </Flex>
  );
}
