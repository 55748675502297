import { Alert } from "@telia-no-min-side/components";

export function OngoingOrderWarning() {
  return (
    <Alert isOpen kind="warning" icon="time-waiting" title="Kan ikke endre abonnement nå">
      Siden du har en pågående bestilling, kan du ikke endre abonnementet akkurat nå. Dette kan ta opptil 15 minutter,
      og du kan endre abonnementet igjen etter dette.
    </Alert>
  );
}
