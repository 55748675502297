import { Heading, Notabene, Notification, Link, breakpoint, Flex } from "@telia/teddy";
import { GetSubscriptionSettingsQuery, BillingType, UserRole } from "src/gql/graphql";
import { CallSpecificationPrivate } from "./Forms/CallSpecificationPrivateForm";
import { Hint } from "./Forms/HintForm";
import { VoiceMail } from "./Forms/VoiceMailForm";
import { CallForwardingForm } from "./Forms/CallForwardingForm";
import { InformationServicesForm } from "./Forms/InformationServicesForm";
import { availableProductsList } from "../utils/availableProductsList";
import { SubscriptionControls } from "./SubscriptionControls";
import { ProductFilters } from "../types";
import { OrderServiceForm } from "./Forms/OrderServiceForm";
import { CallForwarding } from "../hooks/useCallForwarding";
import { isOlderThan } from "@telia-no-min-side/utils";
import { BLOCKED_CHANGES_BY_VIAPLAY_TOTAL } from "util/format-subscription/variables";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { GroupedAdditionalProductsFragment } from "../query";
import { getFragmentData } from "gql";
import { SecurityDNS } from "./Forms/SecurityDNS";
import { Breadcrumb } from "components/Breadcrumb";
import { config } from "util/config";
import { isChildSubscription } from "pages/mobile/mobile-subscription-dashboard/modules/DataUsageCard/utils";

const telemarketServiceCodes = ["STOP_TELEMARKET", "STOP_5-DIGIT", "STOP_TELETORG", "STOP_18XX"];

type Props = {
  settings: GetSubscriptionSettingsQuery;
  callForwardingData: CallForwarding | undefined;
  orderStatus?: string;
  orderId?: string | null;
};

export function SubscriptionSettings({ settings, callForwardingData, orderStatus, orderId }: Props) {
  const subscription = settings?.subscription;
  const directoryListings = subscription?.directoryListings;

  const additionalProducts = getFragmentData(
    GroupedAdditionalProductsFragment,
    subscription?.groupedAdditionalProducts
  );

  const isPostpaid = subscription?.billingType === BillingType.Postpaid;

  const isOver18 = isOlderThan(18, subscription.productUser?.birthDate);
  const isLegalOwner = subscription.roles.includes(UserRole.LegalOwner);

  const hideHint = isChildSubscription(subscription?.userOffering?.shortName || "") && !isOver18;

  const availableProducts = availableProductsList(isLegalOwner, isOver18, additionalProducts);
  const flatAdditionalProducts = Object.values(availableProducts).flat();

  const voicemailServices = availableProducts[ProductFilters.Voicemail];
  const canUseVoicemail = isLegalOwner || isOver18;
  const barringGprsServices = availableProducts[ProductFilters.BarringGprs];
  const barringCpaServices = availableProducts[ProductFilters.BarringCpa];
  const filterByProductCode = (code: string) => flatAdditionalProducts.filter((product) => product.code === code);

  const hintService = filterByProductCode(ProductFilters.Hint);
  const callSpecificationPrivateServices = filterByProductCode(ProductFilters.CallSpecificationFree);
  const securityDNSService = filterByProductCode(ProductFilters.SecurityDNS);
  const cancelSecurityDNSService = filterByProductCode(ProductFilters.CancelSecurityDNS);

  const barringCallsServices = availableProducts[ProductFilters.BarringCalls];
  const isMbnUser = filterByProductCode(ProductFilters.MbnUser).length > 0;

  const telemarketServices = flatAdditionalProducts.filter((product) =>
    telemarketServiceCodes.includes(product?.code || "")
  );

  const otherSettings = flatAdditionalProducts
    .filter(
      (product) =>
        !product.productGroup &&
        product?.price?.price === 0 &&
        product.code !== ProductFilters.GiftDataBalance &&
        product.code !== BLOCKED_CHANGES_BY_VIAPLAY_TOTAL
    )
    .filter(
      (product) =>
        ![
          ...telemarketServices,
          ...hintService,
          ...callSpecificationPrivateServices,
          ...securityDNSService,
          ...cancelSecurityDNSService,
        ].includes(product)
    );

  return (
    <Flex maxWidth={breakpoint.teddyBreakpointMd} direction="column" gap="200">
      <Breadcrumb />
      <Heading as="h2" variant="title-300" mb="200">
        Innstillinger
      </Heading>

      {isMbnUser && (
        <Notabene>
          <Notabene.Icon name="alert" />
          <Notabene.Content>
            <Notabene.Heading>Viderekobling må utføres via Telias Bedriftsnettsider</Notabene.Heading>
            <Link href={EXTERNAL_LINK.BUSINESS_NETWORK} target="_blank" variant="standalone">
              Logg inn her
            </Link>
          </Notabene.Content>
        </Notabene>
      )}

      {orderId && orderStatus && (
        <Notification open={orderStatus !== "COMPLETED"} variant="information">
          <Notification.Heading as="h3">
            <Notification.Icon>Det kan ta opp til 5 minutter før du ser endringen her.</Notification.Icon>
          </Notification.Heading>
        </Notification>
      )}

      <SubscriptionControls isLegalOwner={isLegalOwner} />

      {barringCallsServices?.length > 0 && (
        <OrderServiceForm title="Sperre for anrop og meldinger" products={barringCallsServices} pageName="settings" />
      )}

      {securityDNSService?.length > 0 && (
        <SecurityDNS securityDNSService={securityDNSService} cancelSecurityDNSService={cancelSecurityDNSService} />
      )}

      {barringGprsServices?.length > 0 && (
        <OrderServiceForm title="Datatrafikk" products={barringGprsServices} pageName="settings" />
      )}

      {config.isHint2Enabled && hintService?.length > 0 && !hideHint && (
        <Hint product={hintService[0]} isLegalOwner={isLegalOwner} />
      )}

      {voicemailServices?.length > 0 && canUseVoicemail && <VoiceMail options={voicemailServices} />}

      {callSpecificationPrivateServices?.length > 0 && (
        <CallSpecificationPrivate options={callSpecificationPrivateServices} />
      )}

      {barringCpaServices?.length > 0 && (
        <OrderServiceForm title="Strex betaling" products={barringCpaServices} pageName="settings" />
      )}

      {telemarketServices?.length > 0 && (
        <OrderServiceForm title="Teletorg" products={telemarketServices} pageName="settings" />
      )}

      {isPostpaid && !isMbnUser && <CallForwardingForm callForwarding={callForwardingData} />}

      <InformationServicesForm subscription={subscription} directoryListings={directoryListings} />

      {otherSettings.length > 0 && <OrderServiceForm title="Andre" products={otherSettings} pageName="settings" />}
    </Flex>
  );
}
