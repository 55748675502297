import styled from "styled-components";
import { devices, remCalc } from "@telia-no-min-side/components";

export const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${remCalc(32)};
  margin-top: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;

  /* @media ${devices.lg_up} {
    flex-direction: row;
    align-items: flex-start;
  } */
`;
