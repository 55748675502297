import { types } from "@telia-no-min-side/utils";

export function getOrderToPlace(
  product: types.fixed.ProductGroupSingleProductWithShipping,
  shippingMethod?: number
): types.fixed.SubmitMultipleProducts {
  return {
    axiomIds: [
      {
        axiomId: product.axiomId,
        quantity: 1,
        addonIds: [],
      },
    ],
    shippingMethodId: shippingMethod,
  };
}
