import { Button, Paragraph } from "@telia-no-min-side/components";
import { useParams } from "react-router-dom";
import { MobileSubscriptionAccordionItem } from "src/components/mobile-subscription-accordion-item";
import { FragmentType, getFragmentData, graphql } from "src/gql";
import { useSafeWebshopUrl } from "src/hooks/useSafeWebshopUrl";
import { get5GSpeedText } from "src/util/format-subscription/get5GSpeedText";
import { getName } from "src/util/format-subscription/getName";
import { hasUnlimitedData } from "src/util/format-subscription/hasUnlimitedData";
import { getDownloadSpeed } from "util/format-subscription/getDownloadSpeed";
import { getIncludedProperties } from "util/format-subscription/getIncludedProperties";
import { getOfferingPrices } from "util/format-subscription/getOfferingPrices";
import { hasLinesPrice } from "util/format-subscription/hasLinesPrice";
import { hasStartDiscountOnAddSubscription } from "util/format-subscription/hasStartDiscount";
import { isUngSubscription } from "util/format-subscription/isUngSubscription";
import { SpeedOrDataBenefit } from "../mobile-subscription-change/components/SpeedOrDataBenefit";
import { ComponentProps } from "react";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";
import { getJuniorSummerCampaignDataForOffer } from "../mobile-subscription-change/change-mobile/utils/getJuniorSummerCampaignData";
import { JuniorSummerCampaignTitle } from "../mobile-subscription-change/components/JuniorSummerCampaignTitle";
import { JuniorSummerCampaignBanner } from "../mobile-subscription-change/components/JuniorSummerCampaignBanner";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

const SUBSCRIPTION_USER_OFFERING_FRAGMENT = graphql(`
  fragment SubscriptionUserOffering on UserOffering {
    pricePlan
    name
    shortName
    monthlyCost {
      code
      amount
      reasons {
        code
      }
    }
    additionalProperties {
      name
      value
    }
    originalPrice {
      amount
    }
    offeringPrices {
      offeringPrices {
        code
        amount
        monetaryUnit
      }
    }
  }
`);

export function OfferingAccordionItem({
  userOfferingFragment,
  hasOneOrMoreSubs,
  additionalText,
}: {
  userOfferingFragment: FragmentType<typeof SUBSCRIPTION_USER_OFFERING_FRAGMENT>;
  hasOneOrMoreSubs: boolean;
  additionalText?: string;
}) {
  const userOffering = getFragmentData(SUBSCRIPTION_USER_OFFERING_FRAGMENT, userOfferingFragment);
  const params = useParams<{ accountId?: string }>();
  const ban = params.accountId;
  const offeringCode = userOffering.pricePlan;
  const safeWebshopUrl = useSafeWebshopUrl({ ban, offeringCode });

  if (!userOffering) return <Paragraph removeMargin>Klarte ikke hente data</Paragraph>;

  const offeringPrices = getOfferingPrices(userOffering.offeringPrices);
  const name = getName(offeringPrices, userOffering.shortName);
  const downloadSpeed = getDownloadSpeed(offeringPrices);
  const hasUnlimitedDataBenefit = hasUnlimitedData(userOffering.additionalProperties);
  const priceMonthly = userOffering.monthlyCost?.amount;

  const hasStartDiscount = hasStartDiscountOnAddSubscription(userOffering.pricePlan);
  const includedProperties = getIncludedProperties(userOffering?.additionalProperties);
  const linesPriceEnabled = hasLinesPrice(userOffering.monthlyCost);
  const isUngSub = isUngSubscription(userOffering?.additionalProperties);
  const hasJuniorSummerCampaign = isJuniorSummerCampaign(userOffering?.pricePlan || "");
  const { formattedBaseDataAmount, formattedTotalDataAmount } = getJuniorSummerCampaignDataForOffer(
    userOffering?.pricePlan || "",
    offeringPrices,
    includedProperties
  );

  const tracking = {
    new_subscription: userOffering.shortName || "",
    new_subscription_price: userOffering.originalPrice?.amount || "",
  };

  const getLozenge = (): ComponentProps<typeof MobileSubscriptionAccordionItem>["lozengeRight"] => {
    if (hasJuniorSummerCampaign) {
      // Family benefit is not applicable for junior summer campaign
      return undefined;
    }

    if (!isUngSub && linesPriceEnabled) {
      return { label: "Familierabatt", status: "communication" };
    }

    if (hasOneOrMoreSubs && !linesPriceEnabled) {
      return { label: "Gir familierabatt", status: "communication" };
    }

    return undefined;
  };

  const overLine = (
    <SpeedOrDataBenefit
      downloadSpeed={downloadSpeed}
      hasUnlimitedData={hasUnlimitedDataBenefit}
      showDownloadSpeed={!!downloadSpeed}
      removeMargin={true}
    />
  );

  const juniorSummerCampaignTitle = hasJuniorSummerCampaign ? (
    <JuniorSummerCampaignTitle baseDataAmount={formattedBaseDataAmount} totalDataAmount={formattedTotalDataAmount} />
  ) : undefined;

  return (
    <MobileSubscriptionAccordionItem
      subscriptionName={name?.length ? name : "Klarte ikke hente navn"}
      customSubscriptionName={juniorSummerCampaignTitle}
      hasDoubleData={false}
      hasDoubleSpeed={false}
      overLine={overLine}
      customPeakContent={hasJuniorSummerCampaign ? <JuniorSummerCampaignBanner /> : undefined}
      additionalText={
        additionalText?.length
          ? additionalText
          : isUngSub
          ? "For deg under 30 år"
          : hasStartDiscount
          ? "50,- i rabatt de første 12 md. uten binding"
          : undefined
      }
      includedProperties={[
        ...includedProperties,
        ...(hasUnlimitedDataBenefit && downloadSpeed
          ? [{ code: "5g", text: get5GSpeedText(downloadSpeed), icon: "five-g", showInPeak: false } as const]
          : []),
      ]}
      priceMonthly={priceMonthly}
      originalPriceMonthly={userOffering.originalPrice?.amount}
      lozengeRight={getLozenge()}
      dataTrackingID={`change-subscription-offering-${(name || "").replaceAll(" ", "_")}`}
    >
      <div>
        <Button
          floatRight
          tag="a"
          href={safeWebshopUrl.data?.safeWebshopUrl || ""}
          isLoading={safeWebshopUrl.loading || !!safeWebshopUrl.error}
          loadingText="Henter link"
          trackEvent={{
            ui_item_action: `link_click ${JSON.stringify(tracking)}`,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.BUY_NEW_SUBSCRIPTION,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: "/minside/mobil/legg-til",
            ui_item_text: TRACK_EVENT.ITEM_TEXT.BUY_NEW_SUBSCRIPTION,
          }}
        >
          Velg
        </Button>
      </div>
    </MobileSubscriptionAccordionItem>
  );
}
