import { useFlexOrder } from "../context/FlexOrderProvider";
import { ProductSelector } from "../product-selector/ProductSelector";
import { RequirementsSelector } from "../requirement-selector/RequirementsSelector";
import { Receipt } from "./Receipt";
import { PageContainer } from "components/PageContainer";
import { useProfilePicker } from "components/profile-pickers/hooks/useProfilePicker";
import { format } from "@telia-no-min-side/utils";
import { TopMenu } from "components/top-menu/TopMenu";
import { TopMenuSkeleton } from "components/top-menu/TopMenuSkeleton";

export function PageHandler() {
  const { receiptRef, pageView, pageRequirement, submitFlexData } = useFlexOrder();

  const { newFixed: fixed } = useProfilePicker({ filter: "fixed" });
  const currentSelectedProfile = fixed.profiles.find((profile) => profile.value.selected);
  const info = currentSelectedProfile
    ? {
        title: format.startCase(`${currentSelectedProfile?.value.street || ""}`),
        subtitle: `Kundenummer: ${currentSelectedProfile?.value.customerId}`,
      }
    : undefined;

  return (
    <>
      {fixed.loading ? (
        <TopMenuSkeleton />
      ) : currentSelectedProfile ? (
        <TopMenu
          info={info}
          selectedOption={currentSelectedProfile}
          options={[currentSelectedProfile]}
          handleOptionSelect={() => {}}
        />
      ) : null}

      <PageContainer>
        {pageView === "PRODUCT_SELECT" && <ProductSelector />}
        {pageView === "REQUIREMENT_SELECT" && pageRequirement && <RequirementsSelector />}
        <div ref={receiptRef}>{pageView === "RECEIPT" && <Receipt receipt={submitFlexData.receipt} />}</div>
      </PageContainer>
    </>
  );
}
