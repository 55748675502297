import { uri } from "@telia-no-min-side/utils";
import { Text, Flex, Heading, Link, Icon, Box, Grid, Button, Container } from "@telia/teddy";
import { Navigate, Link as RuterLink, useParams } from "react-router-dom";
import { PointsCompensationCard } from "./PointsCompensationCard";
import { CacheCompensationCard } from "./CacheCompensationCard";
import { useCompensation } from "./useCompensation";
import { useFetchProfiles } from "src/api/fixed/useFetchProfiles";
import { PageLoadError } from "pages/error/ErrorContent";
export function TvPointCompensation() {
  const profile = useFetchProfiles();
  const { currentUserCompensation, isLoading, error, mutate, isValidating } = useCompensation();
  const { customerId } = useParams();
  const currentProfile = profile.data?.find((p) => p.customerId.toString() === customerId?.toString());

  if (!isLoading && !profile.isLoading && profile.data && !currentProfile) {
    return <Navigate to={uri.minSideFixed("/tv/kompensasjon")} replace />;
  }

  if (!isLoading && !currentUserCompensation?.canSwitchCompensation && !currentUserCompensation?.hasExtraPoints) {
    return <Navigate to={uri.minSideFixed("/hjem")} replace />;
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Heading as="h1" variant="title-300" mb="300">
          Vi beklager!
        </Heading>
        <PageLoadError />
        <Flex gap="100" mt="200">
          <Button variant="secondary" onClick={() => mutate()} loading={isValidating}>
            Prøve igjen
          </Button>
          <Button asChild>
            <RuterLink to={uri.minSideFixed("/hjem")}>
              <Icon name="home">Tilbake til hjem</Icon>
            </RuterLink>
          </Button>
        </Flex>
      </Container>
    );
  }

  return (
    <>
      <Flex mb="400" direction="column" gap="300">
        <Heading as="h1" variant="title-300">
          Du har fått 50 TV-poeng ekstra
        </Heading>
        <Text as="p">
          Du har fått 50 ekstra TV-poeng i Telia Play som kompensasjon for at TV 2 Play midlertidig er utilgjengelig.
          Hvis du har brukt poeng på TV 2 Play, er de nå frigjort til andre strømmetjenester og kanaler. Dette tilbudet
          gjelder frem til TV 2 Play er tilgjengelig igjen.
        </Text>
        <Box>
          <Link variant="standalone" href={uri.openPages("/tv/tv-2-info/")}>
            <Icon name="arrow-right">Les mer om forhandlingene her</Icon>
          </Link>
        </Box>
      </Flex>
      <Grid gap="300">
        <PointsCompensationCard />
        {currentUserCompensation?.canSwitchCompensation ? <CacheCompensationCard /> : null}
        <div>
          <Button asChild mt="200">
            <RuterLink to={uri.minSideFixed("/hjem")}>
              <Icon name="home">Tilbake til hjem </Icon>
            </RuterLink>
          </Button>
        </div>
      </Grid>
    </>
  );
}
