import { Flex, breakpoint } from "@telia/teddy";
import { MoveOutRegistration } from "./components/MoveOutRegistration";
import { TvTopMenu } from "components/top-menu/TvTopMenu";
import { PageContainer } from "components/PageContainer";

export function MoveOutPage() {
  return (
    <Flex direction="column" gap="100">
      <TvTopMenu dataTrackingID="moveout-fixed-profile-picker" />

      <PageContainer maxWidth={breakpoint.teddyBreakpointMd} pt="200">
        <MoveOutRegistration />
      </PageContainer>
    </Flex>
  );
}
