import { Button, TextBoxWithLabel } from "@telia/styleguide";
import React from "react";
import { IpAddressInput } from "../../../../modules/IPAddressInput";
import { MaskedInputWithTitle } from "../../../../modules/MaskedInputWithTitle";
import { staticInternalIPParams } from "../../../../util/HomeCentralUtilities";

import "./StaticInternalIPEntryEditable.less";

const findFirstErrorMessage = (messages, tag) => {
  return messages.filter((msg) => msg.tag.includes(tag)).find((msg) => msg.message !== "");
};

const Container = ({ children }) => <div className={"static-internal-entry-container"}>{children}</div>;

const RowAlignCenter = ({ children }) => <div className="static-internal-ip-row-align-center">{children}</div>;

export const StaticInternalIPEntryEditable = ({
  internalIpEntry,
  mask,
  errorMessages,
  onChange,
  validate,
  onSave,
  onClose,
}) => (
  <Container>
    <TextBoxWithLabel
      onChange={(event) => onChange(event.target.value, staticInternalIPParams.Alias)}
      value={internalIpEntry.Alias}
      onBlur={validate}
      labelText={"Navn"}
      errorMessage={findFirstErrorMessage(errorMessages, "alias")}
    />
    <IpAddressInput
      onChange={(newIp) => onChange(newIp, staticInternalIPParams.IPAddress)}
      title={"IP-adresse"}
      address={internalIpEntry.IPAddress}
      addressContract={{ noContract: true }}
      mask={mask}
      typeOfAddress={"static-internal-ip"}
      onBlur={validate}
      errorMessage={findFirstErrorMessage(errorMessages, "ip-address")}
    />
    <MaskedInputWithTitle
      title={
        findFirstErrorMessage(errorMessages, "mac-address")
          ? "Mac-adresse" + " - " + findFirstErrorMessage(errorMessages, "mac-address")
          : "Mac-adresse"
      }
      onChange={(event) => onChange(event.target.value, staticInternalIPParams.MacAddress)}
      value={internalIpEntry.MacAddress}
      type={"text"}
      onBlur={validate}
      invalid={errorMessages.some((msg) => msg.tag.includes("mac-address"))}
      mask={[
        /[0-9a-fA-F]/,
        /[0-9a-fA-F]/,
        ":",
        /[0-9a-fA-F]/,
        /[0-9a-fA-F]/,
        ":",
        /[0-9a-fA-F]/,
        /[0-9a-fA-F]/,
        ":",
        /[0-9a-fA-F]/,
        /[0-9a-fA-F]/,
        ":",
        /[0-9a-fA-F]/,
        /[0-9a-fA-F]/,
        ":",
        /[0-9a-fA-F]/,
        /[0-9a-fA-F]/,
      ]}
    />
    <RowAlignCenter>
      {onClose && <Button text={"Avbryt"} onClick={onClose} kind={"cancel"} />}
      {onSave && <Button text={"Lagre"} onClick={onSave} />}
    </RowAlignCenter>
  </Container>
);
