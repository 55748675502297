import { SwitchRejectionReason } from "gql/graphql";

type AlertDetailsType = {
  title: string;
  text: string;
  type: "warning" | "info";
};

export function rejectionAlertDetails(isLegalOwner: boolean, rejectionReason?: string | null): AlertDetailsType {
  const defaultAlert: AlertDetailsType = {
    title: "Når kan jeg Svitsje?",
    text: "Ukjent årsak, ta kontakt med kundeservice for mer info.",
    type: "info",
  };

  if (!isLegalOwner) {
    return {
      ...defaultAlert,
      text: "Du kan dessverre ikke svitsje fordi du er ikke er eier av avtalen.",
    };
  }

  switch (rejectionReason) {
    case SwitchRejectionReason.LessThanThreeInstallmentsPaid:
      return {
        ...defaultAlert,
        text: "Du kan Svitsje etter at 3 fakturaer er betalt.",
      };
    case SwitchRejectionReason.LessThanFourInstallmentsPaid:
      return {
        ...defaultAlert,
        text: "Du kan Svitsje etter at 4 fakturaer er betalt.",
      };
    case SwitchRejectionReason.SwitchOnHold:
      return {
        ...defaultAlert,
        text: "Vi venter på at den gamle telefonen din skal komme i retur for vurdering. Derfor har SVITSJ-avtalen din midlertidig blitt satt på vent sånn at du ikke blir fakturert i dette tidsrommet.",
      };
    case SwitchRejectionReason.SwitchPending:
      return {
        ...defaultAlert,
        text: "SVITSJ-avtalen din er for øyeblikket i behandling, derfor vil det ikke være mulig å svitsje akkurat nå.",
      };
    case SwitchRejectionReason.HasSwitchBar:
      return {
        ...defaultAlert,
        text: "Du kan Svitsje etter at 3 påfølgende fakturaer er betalt.",
      };
    case SwitchRejectionReason.MoreThanOneSwitch:
      return {
        ...defaultAlert,
        text: "Vi har registert mer enn én SVITSJ-avtale på ditt abonnement. Derfor vil det ikke være mulig å svistje for øyeblikket.",
      };
    case SwitchRejectionReason.NoRightsToDoSwitch:
      return {
        ...defaultAlert,
        text: "I følge våre systemer har du ikke tilgang til å kunne svitsje på dette abonnementet. Ta kontakt med kundeservice dersom dette ikke stemmer.",
      };
    case SwitchRejectionReason.InsuranceClaimStatusAwaiting:
      return {
        ...defaultAlert,
        text: "Status for forsikringskrav er under behandling, og derfor kan du ikke svitsje nå.",
      };
    default:
      return {
        title: "Du kan ikke Svitsje nå",
        text: "Ukjent årsak, ta kontakt med kundeservice for mer info.",
        type: "warning",
      };
  }
}
