import { ReactNode } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Button, Icon, Flex, Box } from "@telia/teddy";
import { setSearchParams, uri } from "@telia-no-min-side/utils";

type Props = {
  invoiceId?: string;
  isMobileAccount?: boolean;
  isPostponingDisabled?: boolean;
  setPostponeInvoiceModalOpen: (value: boolean) => void;
  children?: ReactNode;
  isDetailPage?: boolean;
};

export function InvoiceActions({
  invoiceId,
  isMobileAccount,
  isPostponingDisabled,
  setPostponeInvoiceModalOpen,
  isDetailPage,
  children,
}: Props) {
  return (
    <Flex direction="row" wrap="wrap" justify="space-between" gapY="gutter-sm" gapX="gutter-sm" pt="gutter-sm">
      {/* Hide Se fakturadetaljer button on detail page */}
      {!isDetailPage && invoiceId && (
        <Box
          flexGrow="1"
          flexShrink="1"
          flexBasis={{
            sm: "100%",
            md: "calc(50% - var(--teddy-cg))",
          }}
        >
          <Button size="md" variant="secondary" asChild fullWidth>
            <ReactRouterLink
              to={uri.minSideFixed(`/faktura/${invoiceId}${setSearchParams({ isMobile: isMobileAccount })}`)}
            >
              <Icon name="zoom" />
              Se fakturadetaljer
            </ReactRouterLink>
          </Button>
        </Box>
      )}
      {!isPostponingDisabled && (
        <Box
          flexGrow="1"
          flexShrink="1"
          flexBasis={{
            sm: "100%",
            md: "calc(50% - var(--teddy-cg))",
          }}
        >
          <Button size="md" variant="secondary" onClick={() => setPostponeInvoiceModalOpen(true)} fullWidth>
            <Icon name="late-payment" />
            Utsett betalingen
          </Button>
        </Box>
      )}
      {/* Minor style changes are made fro detail page using isDetailPage prop */}
      <Box
        flexGrow={isDetailPage ? "0" : "1"}
        flexShrink="1"
        flexBasis={{
          sm: "100%",
          md: isDetailPage ? "50%" : "calc(50% - var(--teddy-cg))",
        }}
      >
        <div>{children}</div>
      </Box>
    </Flex>
  );
}
