import {
  Button,
  Card,
  Container,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Paragraph,
} from "@telia-no-min-side/components";
import { useState } from "react";
import { StreamingImage } from "./style";
import { config } from "util/config";

export function TeliaPlayModal() {
  const [isOpen, setIsOpen] = useState(false);
  const path = "https://www.telia.no/globalassets/tv-channel-logos/";

  return (
    <>
      <Button onClick={() => setIsOpen(true)} variant="text-purple">
        Les mer om Telia Play
      </Button>
      <Modal size="md" isOpen={isOpen} setIsOpen={setIsOpen}>
        <ModalHeader>
          <ModalHeading>Har du Telia Play, har du alt!</ModalHeading>
        </ModalHeader>
        <ModalBody>
          <Container padding="vertical" gap="lg" flexWrap>
            <Container flexBasis="50%" flexShrink={1} flexGrow={1}>
              <Container gap padding="vertical">
                <div>
                  <Icon icon="film" />
                </div>
                <Paragraph>De største strømmetjenestene samlet ett sted</Paragraph>
              </Container>
              <Container gap padding="vertical">
                <div>
                  <Icon icon="tv-simple" />
                </div>
                <Paragraph>Alle de kjente norske og utenlandske kanalene</Paragraph>
              </Container>
              <Container gap padding="vertical">
                <div>
                  <Icon icon="reverse" />
                </div>
                <Paragraph>
                  Valgfrihet – med et tastetrykk bytter du fra Netflix til Max til TV 2 Play. Se hva du vil, når du vil!
                </Paragraph>
              </Container>
            </Container>
            <Container flexBasis="200px" flexGrow={1} flexShrink={1} gap="lg" flexDirection="column">
              <Card>
                <Paragraph>Bytt så ofte du vil mellom (blant annet):</Paragraph>
                <Container gap flexDirection="row" flexWrap justifyContent="center">
                  <StreamingImage theme="light">
                    <img alt="Netflix" src={path + "netflix.svg"} />
                  </StreamingImage>
                  <StreamingImage theme="light">
                    <img alt="Viaplay" src={path + "viaplay.svg"} />
                  </StreamingImage>
                  <StreamingImage theme="light">
                    {config.showTvCompensation ? (
                      <img alt="V Sport" src={path + "v-sport.svg"} />
                    ) : (
                      <img alt="TV 2 Play" src={path + "tv-2-play.svg"} />
                    )}
                  </StreamingImage>
                  <StreamingImage theme="light">
                    <img alt="SkyShowtime" src={path + "skyshowtime.svg"} />
                  </StreamingImage>
                  <StreamingImage theme="light">
                    <img alt="Max" src={path + "max.svg"} />
                  </StreamingImage>
                  <StreamingImage theme="light">
                    <img alt="Prime" src={path + "prime.svg"} />
                  </StreamingImage>
                </Container>
              </Card>
              <Card>
                <Paragraph>Inkludert (blant annet): </Paragraph>
                <Container gap flexDirection="row" flexWrap justifyContent="center">
                  <StreamingImage theme="light">
                    <img alt="NRK 1" src={path + "nrk-1.svg"} />
                  </StreamingImage>
                  <StreamingImage theme="light">
                    <img alt="NRK 2" src={path + "nrk-2.svg"} />
                  </StreamingImage>
                  <StreamingImage theme="light">
                    {config.showTvCompensation ? (
                      <img alt="Rex" src={path + "rex.svg"} />
                    ) : (
                      <img alt="TV 2 Direkte" src={path + "tv-2-direkte.svg"} />
                    )}
                  </StreamingImage>
                  <StreamingImage theme="light">
                    {config.showTvCompensation ? (
                      <img alt="Fem" src={path + "fem.svg"} />
                    ) : (
                      <img alt="TV 2 Nyheter" src={path + "tv-2-nyheter.svg"} />
                    )}
                  </StreamingImage>
                  <StreamingImage theme="light">
                    <img alt="TV Norge" src={path + "tv-norge.svg"} />
                  </StreamingImage>
                  <StreamingImage theme="light">
                    <img alt="TV 3" src={path + "tv3.svg"} />
                  </StreamingImage>
                </Container>
              </Card>
            </Container>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Container flexDirection="column" alignItems="center">
            <Button onClick={() => setIsOpen(false)}>Lukk</Button>
          </Container>
        </ModalFooter>
      </Modal>
    </>
  );
}
