import { Alert } from "@telia-no-min-side/components";
import { SwitchAgreementFieldsFragment } from "gql/graphql";
import { rejectionAlertDetails } from "pages/mobile/mobile-subscription-agreements/utils/rejectionAlertDetails";

export type Props = Pick<SwitchAgreementFieldsFragment, "switchRejectionReason"> & {
  canPerformSwitch: boolean;
  isLegalOwner: boolean;
};

export function SwitchAlerts({ canPerformSwitch, switchRejectionReason, isLegalOwner }: Props) {
  const { title, type, text } = rejectionAlertDetails(isLegalOwner, switchRejectionReason);

  return (
    <>
      {!canPerformSwitch && (
        <Alert
          isOpen
          canClose={false}
          icon={type === "info" ? "info" : "warning"}
          title={title}
          kind={type}
          text={text}
        />
      )}
    </>
  );
}
