import { HintProgress } from "./HintProgress";
import { UseFormRegister } from "react-hook-form";
import { FieldErrorText, Flex, Input, Label } from "@telia/teddy";
import { HintFormValues } from "../Forms/HintForm";

type Props = {
  register: UseFormRegister<HintFormValues>;
  value: string;
  defaultValues: HintFormValues;
  loading: boolean;
  errorMessage?: string;
  isEditing: boolean;
  isLegalOwner: boolean;
  onEditing: () => void;
  onDeactivate: () => void;
};

export function HintControls({
  register,
  value,
  loading,
  defaultValues,
  errorMessage,
  isEditing,
  isLegalOwner,
  onEditing,
  onDeactivate,
}: Props) {
  const limit = Number(value);

  if (!defaultValues.active || isEditing || limit <= 0) {
    return (
      <Flex direction="column" gap="200">
        <div key={"hint-amount"}>
          <Label> Du mottar varsling ved forbruk over:</Label>
          <Input
            {...register("value", {
              pattern: {
                value: /^[0-9]+$/,
                message: "Dette feltet kan kun inneholde tall",
              },
              min: {
                value: 1,
                message: "Verdien må være større enn 0",
              },
            })}
            disabled={loading || !isLegalOwner}
            placeholder="Skriv inn beløp"
            type="number"
            min={1}
          />
          {errorMessage ? <FieldErrorText>{errorMessage}</FieldErrorText> : null}
        </div>
      </Flex>
    );
  }

  return <HintProgress onEditInput={onEditing} onDeactivate={onDeactivate} limit={limit} isLegalOwner={isLegalOwner} />;
}
