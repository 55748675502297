import { uri } from "@telia-no-min-side/utils";
import { Flex, Heading, Text, Icon, Button, breakpoint } from "@telia/teddy";
import { Link } from "react-router-dom";

export function ReceiptSuccess() {
  return (
    <Flex direction="column" gap="400" maxWidth={breakpoint.teddyBreakpointMd}>
      <Heading as="h2" variant="title-200">
        Takk for din bestilling!
      </Heading>
      <Text>Innen kort tid vil Opptak være tilgjengelig.</Text>
      <div>
        <Button asChild>
          <Link to={uri.minSideFixed("/hjem")}>
            <Icon name="home" />
            Tilbake til Hjem
          </Link>
        </Button>
      </div>
    </Flex>
  );
}
