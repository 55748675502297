import { colors, Lozenge } from "@telia/styleguide";
import styled, { keyframes, css } from "styled-components";
import { devices } from "../utils/breakpoints";
import { Button } from "../Button";

export const Wrapper = styled.div`
  position: relative;
`;

export const DynamicGaugeMeterWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 0.5rem;
`;

type TextProps = {
  strokeWidth: number;
  cutoffPercentage: number;
  radius: number;
};

export const ChildWrapper = styled.div<TextProps>`
  position: absolute;
  top: calc(50% + ${(props) => (props.cutoffPercentage > 0.25 ? props.strokeWidth / 2 : 0)}px);
  left: 50%;
  translate: -50% -50%;
  text-align: center;
  line-height: 1.15;
  margin: 0;
  width: calc(${(props) => props.radius * 2}px - ${(props) => props.strokeWidth}px);
`;

type LozengeProps = {
  strokeWidth: number;
  cutoffPercentage: number;
};

export const LozengeWrapper = styled.div<LozengeProps>`
  position: absolute;
  top: calc(50% + ${(props) => (props.cutoffPercentage > 0.25 ? props.strokeWidth / 2 : 0)}px);
  left: 50%;
  translate: -50% -50%;
  margin: 0;
  min-width: 100%;
`;

export const StyledLozenge = styled(Lozenge)`
  min-width: 100%;
  text-align: center;
  display: inline-flex;
  justify-content: center;
`;

export const Clip = styled.div<{ clipHight: number }>`
  overflow: hidden;
  width: 100%;
  height: ${(props) => props.clipHight}px;
`;

export const SVGWrapper = styled.div`
  display: grid;
  place-items: center;
  overflow: hidden;
`;

export const SVG = styled.svg`
  rotate: ${(props) => props.rotate};
  width: auto;
  height: auto;
`;

export const FadeOut = styled.div<{ fadeHideButton?: boolean }>`
  transition: opacity 500ms ease;

  ${(props) =>
    props.fadeHideButton &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;
export const DynamicIconButton = styled(Button)`
  height: 2.5rem;
  width: 2.5rem;
  flex-shrink: 0;

  @media ${devices.sm_down} {
    height: 2rem;
    width: 2rem;
  }
`;

type CircleProps = {
  color: string;
  animate?: boolean;
  circumference: number;
  coloredArea: number;
  prevColoredArea: number;
  strokeWidth: number;
  isLoading?: boolean;
  isPulsing?: boolean;
};

type Progress = Pick<CircleProps, "circumference" | "prevColoredArea" | "coloredArea">;

const progress = ({ circumference, prevColoredArea, coloredArea }: Progress) => keyframes`
    0% {
      stroke-dasharray: ${prevColoredArea} ${circumference};
    }
    100% {
      stroke-dasharray: ${coloredArea} ${circumference};
    }
  `;

const puls = keyframes`
    0% {
      stroke: ${colors.corePurple800};
      transform: scale(1);
    }
    20% {
      stroke: ${colors.blue400};
    }
    25% {
      transform: scale(.95);
    }
    40% {
      stroke: ${colors.red400};
    }
    50% {
      transform: scale(1);
    }
    60% {
      stroke: ${colors.orange400};
    }
    75% {
      transform: scale(.95);
    }
    80% {
      stroke: ${colors.green400};
    }
    100% {
      stroke: ${colors.corePurple800};
      transform: scale(1);
    }
`;

const isLoading = keyframes`
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
`;

export const Circle = styled.circle<CircleProps>`
  transform-origin: center;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: ${({ strokeWidth }) => strokeWidth + 0};
  stroke: ${(props) => props.color};
  stroke-dasharray: ${({ coloredArea, circumference }) => `${coloredArea} ${circumference}`};

  ${(props) =>
    props.animate &&
    css`
      animation: ${progress(props)} 0.5s ease;
    `}
  ${(props) =>
    props.isLoading &&
    css`
      animation: ${isLoading} 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    `}
    ${(props) =>
    props.isPulsing &&
    css`
      animation: ${puls} 6s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    `};
`;
