import type { SyntheticEvent } from "react";
import { useEffect, useMemo } from "react";
import { useFormHandling, useLocalStorage, useToast } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import { useUpdateCallForwarding } from "../../hooks/useUpdateCallForwarding";
import { CallForwarding } from "../../hooks/useCallForwarding";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { BankIdRequiredModal } from "components/bank-id-required-modal";
import { config } from "util/config";
import { ExpandableCard, Heading, Flex, Badge, Button, Toggle, Label, Input, FieldErrorText, Box } from "@telia/teddy";

type Props = {
  callForwarding: CallForwarding | undefined;
};

type Option =
  | "callForwardUnconditional"
  | "callForwardWhenBusy"
  | "callForwardWhenNoReply"
  | "callForwardWhenNotReachable";

export function CallForwardingForm({ callForwarding }: Props) {
  const { phoneNumber } = useMobileAccount();
  const { addToast } = useToast();
  const forceSkipBankIdCheck = !config.requireBankId;

  const initialState = useMemo(() => getCallForwardingInitialState(callForwarding), [callForwarding]);

  const { state, dispatch, Actions } = useFormHandling(initialState);
  const { form, previousForm } = state;

  const isFormModified = !Object.values(form).every(
    (product) =>
      product.active === previousForm[product.id as Option].active &&
      product.number === previousForm[product.id as Option].number
  );
  const isCallForwardingActive = Object.values(previousForm).some((product) => product.active);

  const {
    callForwardingResponse: { data, loading, error },
    runUpdateCallForwarding,
  } = useUpdateCallForwarding({
    uniqueKey: "useUpdateCallForwarding",
    onCompleted() {
      addToast({
        text: "Dine endringer er lagret. Det kan ta opp til 5 minutter før du ser endringen her.",
        variant: "success",
        dataTrackingId: "order-call-forwarding-success",
      });
    },
    onError() {
      addToast({
        text: "Endringene dine ble ikke lagret. Vennligst prøv på nytt.",
        variant: "error",
        dataTrackingId: "order-call-forwarding-error",
      });
    },
  });

  const { setValue: setOrderId } = useLocalStorage<string>("telia-no-minside-order-id__settings");

  useEffect(() => {
    dispatch({ type: Actions.SET_SHOW_ERROR, payload: { showError: false } });
    dispatch({
      type: Actions.SET_SHOW_SUCCESS,
      payload: { showSuccess: false },
    });

    if (data?.updateCallForwarding?.orderId && !loading) {
      dispatch({ type: Actions.ON_SUCCESS });
      setOrderId(data.updateCallForwarding.orderId);
    }

    if (error?.message && !loading) {
      dispatch({ type: Actions.ON_ERROR });
    }
  }, [dispatch, data, error, loading, Actions]);

  // if initially callForwarding is undefined and then changes to defined, form state changes accordingly
  useEffect(() => {
    dispatch({
      type: Actions.SET_STATE,
      payload: { form: initialState, previousForm: initialState },
    });
  }, [dispatch, initialState, Actions]);

  function update() {
    const settingsInput = {
      callForwardUnconditional: {
        active: form.callForwardUnconditional.active,
        number: form.callForwardUnconditional.number,
      },
      callForwardWhenBusy: {
        active: form.callForwardWhenBusy.active,
        number: form.callForwardWhenBusy.number,
      },
      callForwardWhenNoReply: {
        active: form.callForwardWhenNoReply.active,
        number: form.callForwardWhenNoReply.number,
      },
      callForwardWhenNotReachable: {
        active: form.callForwardWhenNotReachable.active,
        number: form.callForwardWhenNotReachable.number,
      },
    };
    runUpdateCallForwarding(phoneNumber, settingsInput, forceSkipBankIdCheck);
  }

  function handleToggle(key: Option) {
    const toggled = !form[key].active;
    const option = {
      ...form[key],
      number: "",
      active: toggled,
      validationError: "",
    };

    dispatch({ type: Actions.SET_FORM, payload: { [key]: option } });
  }

  function handleNumberChange(newNumber: string, key: Option) {
    let validationError = "";
    if (!newNumber) {
      validationError = "Nummeret må fylles ut";
    } else if (!format.validatePhoneNumber(newNumber)) {
      validationError = "Nummeret er ikke gyldig";
    }

    const option = {
      ...form[key],
      number: format.formatFullPhoneNumber(newNumber),
      validationError,
    };

    dispatch({ type: Actions.SET_FORM, payload: { [key]: option } });
  }

  function isFormValid() {
    return !Object.keys(form).find((key) => {
      return (form[key as Option].active && form[key as Option].number === "") || form[key as Option].validationError;
    });
  }

  return (
    <ExpandableCard type="multiple">
      <ExpandableCard.Item value="content1">
        <ExpandableCard.Trigger
          onClick={() => {
            !loading && dispatch({ type: Actions.RESET });
          }}
        >
          <ExpandableCard.Header>
            <ExpandableCard.Description>
              <Flex direction="column" gap="100">
                <Heading as="h5" variant="title-100">
                  Viderekoblinger
                </Heading>
                <div>
                  {isCallForwardingActive ? (
                    <Badge variant="success" hideIcon>
                      På
                    </Badge>
                  ) : (
                    <Badge variant="neutral">Av</Badge>
                  )}
                </div>
              </Flex>
            </ExpandableCard.Description>
            <ExpandableCard.Indicator />
          </ExpandableCard.Header>
        </ExpandableCard.Trigger>
        <ExpandableCard.Content>
          {Object.values(form).map((item) => (
            <Flex key={item.id} gap="100" direction="column">
              <Label>{item.title}</Label>

              <Toggle>
                <Toggle.Input
                  checked={form[item.id as Option].active}
                  onCheckedChange={() => handleToggle(item.id as Option)}
                  disabled={loading}
                >
                  <Toggle.Indicator />
                  <Toggle.Thumb />
                </Toggle.Input>
              </Toggle>

              {form[item.id as Option].active && (
                <Box mb="200">
                  <Label>Telefonnummer</Label>
                  <Input
                    onValueChange={(newNumber) => handleNumberChange(newNumber, item.id as Option)}
                    value={form[item.id as Option].number}
                    placeholder="Telefonnummer"
                    type="text"
                    disabled={loading}
                  />
                  {form[item.id as Option].validationError ? (
                    <FieldErrorText>{form[item.id as Option].validationError}</FieldErrorText>
                  ) : null}
                </Box>
              )}
            </Flex>
          ))}

          {isFormModified && (
            <Flex gap="200">
              <Button
                onClick={() => dispatch({ type: Actions.RESET })}
                type="button"
                variant="tertiary-purple"
                disabled={loading}
              >
                Avbryt
              </Button>
              {forceSkipBankIdCheck ? (
                <Button
                  loading={loading}
                  data-tracking-id="service-save-button"
                  onClick={(e: SyntheticEvent) => {
                    e.preventDefault();
                    update();
                  }}
                >
                  {loading ? "Lagrer" : "Lagre"}
                </Button>
              ) : (
                <BankIdRequiredModal
                  triggerComp={
                    isFormValid() ? (
                      <Button loading={loading} data-tracking-id="service-save-button">
                        {loading ? "Lagrer" : "Lagre"}
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                  mutateComp={
                    <Button
                      onClick={(e: SyntheticEvent) => {
                        e.preventDefault();
                        update();
                      }}
                      loading={loading}
                      data-tracking-id="bankid-modal-bankid-button"
                    >
                      {loading ? "Lagrer" : "Til BankID"}
                    </Button>
                  }
                />
              )}
            </Flex>
          )}
        </ExpandableCard.Content>
      </ExpandableCard.Item>
    </ExpandableCard>
  );
}

function getCallForwardingInitialState(callForwarding: CallForwarding | undefined) {
  return {
    callForwardUnconditional: {
      id: "callForwardUnconditional",
      active: !!callForwarding?.callForwardUnconditional.active,
      number: callForwarding?.callForwardUnconditional.number || "",
      title: "Fast viderekobling",
      validationError: "",
    },
    callForwardWhenBusy: {
      id: "callForwardWhenBusy",
      active: !!callForwarding?.callForwardWhenBusy.active,
      number: callForwarding?.callForwardWhenBusy.number || "",
      title: "Viderekobling når opptatt",
      validationError: "",
    },
    callForwardWhenNoReply: {
      id: "callForwardWhenNoReply",
      active: !!callForwarding?.callForwardWhenNoReply.active,
      number: callForwarding?.callForwardWhenNoReply.number || "",
      title: "Viderekobling når ikke svar",
      validationError: "",
    },
    callForwardWhenNotReachable: {
      id: "callForwardWhenNotReachable",
      active: !!callForwarding?.callForwardWhenNotReachable.active,
      number: callForwarding?.callForwardWhenNotReachable.number || "",
      title: "Viderekobling utenfor dekning",
      validationError: "",
    },
  };
}
