import { ComponentProps } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Paragraph } from "@telia-no-min-side/components";
import { Lozenge } from "@telia/styleguide";
import { AvtalegiroText } from "./styles/AvtalegiroCard.styled";

type AvtalegiroCardProps = {
  lozenge?: Omit<ComponentProps<typeof Lozenge>, "type" | "className">;
};

export const NoAvtalegiroCard = ({ lozenge }: AvtalegiroCardProps) => {
  const navigate = useNavigate();
  return (
    <Card backgroundColor="light-purple" maxWidth="xs" lozengeLeft={lozenge}>
      <Paragraph>Ønsker du AvtaleGiro?</Paragraph>
      <AvtalegiroText>
        Med AvtaleGiro blir fakturaen automatisk betalt. Det vil si at banken sørger for at regningene betales direkte
        fra din konto på forfallsdato.
      </AvtalegiroText>
      <Container flexDirection="rowReverse">
        <Button onClick={() => navigate("/minside/faktura/avtalegiro")} variant="tertiary-purple">
          Opprett AvtaleGiro
        </Button>
      </Container>
    </Card>
  );
};
