import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

function DefaultMessage() {
  return (
    <>
      <Heading tag="h2" variant="title-200" data-tracking-id="mobile-subscription-confirm-header">
        Takk for din bestilling!
      </Heading>
      <Paragraph>Det kan ta opptil 15 minutter før du ser endringen her på Min Side.</Paragraph>
    </>
  );
}

function SleeModeMessage() {
  return (
    <>
      <Heading tag="h2" variant="title-200" data-tracking-id="mobile-subscription-confirm-header">
        Takk for din bestilling!
      </Heading>
      <Paragraph>Det kan ta opptil 15 minutter før du ser endringen her på Min Side.</Paragraph>
    </>
  );
}

export function ReceiptSuccess({ isSleepMode }: { isSleepMode: boolean }): JSX.Element {
  const url = uri.minSideFixed("/internett");
  return (
    <Container
      maxWidth="md"
      flexDirection="column"
      padding
      gap="lg"
      data-tracking-id="mobile-subscription-confirm-container"
    >
      <Container flexDirection="column">{isSleepMode ? <SleeModeMessage /> : <DefaultMessage />}</Container>
      <Container padding="top">
        <Button
          tag="a"
          isInternalNavigation
          href={url}
          hideLinkIcon
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_SUCCESS,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.UPGRADE_DOWNGRADE_SUMMARY,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
            ui_item_url: url,
            ui_item_text: TRACK_EVENT.ITEM_TEXT.BACK_TO_OVERVIEW,
          }}
          data-tracking-id="mobile-subscription-confirm-button"
        >
          Tilbake til oversikten
        </Button>
      </Container>
    </Container>
  );
}
