import { Container, useAuth } from "@telia-no-min-side/components";
import { SwitchHeading } from "../Common/SwitchHeading";
import { SwitchInsuranceType } from "gql/graphql";
import { OtherDamageBase } from "./OtherDamageBase";
import { OtherDamagePlus } from "./OtherDamagePlus";
import { L } from "util/links";
import { useSafeWebshopUrl, initialDataToEncrypt, webshopSwitchPath } from "../../utils/createWebshopUrl";
import { useOutletContext } from "react-router-dom";
import { SwitchOutletContext } from "../../types";
import { toLocalPhoneNumber } from "@telia-no-min-side/utils/src/format";

export function OtherDamage() {
  const { switchAgreement, accountId } = useOutletContext<SwitchOutletContext>();
  const { userInfo } = useAuth();
  const userPhoneNumber = toLocalPhoneNumber(userInfo?.phone_number);

  const { data: safeWebshopUrlData, loading } = useSafeWebshopUrl({
    host: L.TeliaNoUrl.href,
    path: webshopSwitchPath,
    dataToEncrypt: {
      ...initialDataToEncrypt,
      imei: switchAgreement?.imei || "",
      msisdn: switchAgreement?.phoneNumber?.localNumber || "",
      orderer_msisdn: switchAgreement?.phoneNumber?.localNumber || userPhoneNumber,
      ban: accountId,
      case_number: switchAgreement.insurance?.insuranceCase?.caseNumber || null,
    },
  });

  return (
    <Container flexDirection="column" alignItems="start" gap="lg">
      <SwitchHeading preamble="Velg telefonens tilstand" heading="Skader på telefonen" />
      {switchAgreement?.insurance?.type === SwitchInsuranceType.Plus ? (
        <OtherDamagePlus />
      ) : (
        <OtherDamageBase safeWebshopUrl={safeWebshopUrlData?.safeWebshopUrl} loading={loading} />
      )}
    </Container>
  );
}
