import { Tabs, Alert } from "@telia/styleguide";
import PropTypes from "prop-types";
import React from "react";
import REQ from "../../../../util/requestStatus";
import { HomeCentral } from "../../pages/HomeCentral";
import { PortForwarding } from "../../pages/PortForwarding";
import WiFiSettings from "../../pages/WiFiSettings/WiFiSettings";
import { useRouterSettings } from "../../stores/RouterSettingsProvider";
import { routerAnalytics } from "../../util/RouterSettingsAnalytics";

export const WifiAdminTabs = (props) => {
  const { activeTabIndex, setActiveTabIndex } = props;
  const { routerSettings } = useRouterSettings();

  const { devices, fetchStatus, activeUnit } = routerSettings;

  const generateNavigationTabs = () => {
    if (fetchStatus === REQ.SUCCESS) {
      return [
        {
          name: "Trådløse nett",
          index: 0,
        },
        {
          name: "Andre innstillinger",
          index: 1,
        },
        {
          name: "Porter",
          index: 2,
        },
      ];
    }
  };

  const setActiveTab = (index) => {
    const tabs = generateNavigationTabs();
    const currentTabName = tabs.find((tab) => tab.index === activeTabIndex).name;
    const tabToBeOpened = tabs.find((tab) => tab.index === index).name;

    routerAnalytics.trackNavigation(currentTabName, tabToBeOpened);
    setActiveTabIndex(index);
  };

  if (activeUnit.NetworkState === "Unavailable") {
    return (
      <Alert kind="warning">
        <p>Knute på tråden! Det ser ikke ut til at vi får kontakt med denne enheten!</p>
      </Alert>
    );
  }

  return (
    <Tabs uniqueId={"wifi-admin-tabs"} selectedIndex={activeTabIndex} onSelect={setActiveTab}>
      <Tabs.TabPanel heading="Trådløse nett">
        <div className={"tab-content-container"}>
          <WiFiSettings />
        </div>
      </Tabs.TabPanel>
      <Tabs.TabPanel heading="Andre innstillinger">
        <div className={"tab-content-container"}>
          <HomeCentral homeCentral={devices.find((unit) => "Main" === unit.DeviceType)} />
        </div>
      </Tabs.TabPanel>
      <Tabs.TabPanel heading="Porter">
        <div className={"tab-content-container"}>
          <PortForwarding />
        </div>
      </Tabs.TabPanel>
    </Tabs>
  );
};

WifiAdminTabs.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  setActiveTabIndex: PropTypes.func.isRequired,
};
