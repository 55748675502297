import { useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { FullPageLoading } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { Navigate, useSearchParams } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { ResultMessageContext } from "src/util/ResultMessageContext";
import { CONFIRM_INVOICE_PAYMENT } from "../mutations";
import { ConfirmPaymentStatus } from "gql/graphql";

export const InvoiceConfirmPaymentPage = () => {
  const context = useContext(ResultMessageContext);
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("transactionId") || "";

  const [confirmInvoicePaid, { loading }] = useMutation(CONFIRM_INVOICE_PAYMENT, {
    onCompleted: (data) => {
      const paymentResult = data?.confirmPayment?.result === ConfirmPaymentStatus.Ok ? "OK" : "FAILED";

      datadogRum.addAction(
        `Confirmed invoice payment with NETS. Result ${data?.confirmPayment?.result}, internal status ${paymentResult}`,
        {
          feature: "verifiser-betaling",
        }
      );

      context?.setInvoicePaymentResult(paymentResult);
    },
    onError: (error) => {
      const paymentResult =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error as any)?.graphQLErrors?.[0]?.message === "ANOTHER_PAYMENT_IN_PROGRESS"
          ? "ANOTHER_PAYMENT_IN_PROGRESS"
          : "FAILED";

      datadogRum.addAction(`Failed to confirm invoice payment with NETS. Internal status ${paymentResult}`, {
        feature: "verifiser-betaling",
      });

      context?.setInvoicePaymentResult(paymentResult);
    },
  });

  useEffect(() => {
    if (transactionId) {
      datadogRum.addAction(`Started confirming invoice payment with NETS provider. transactionId ${transactionId}`, {
        feature: "verifiser-betaling",
      });

      confirmInvoicePaid({
        variables: { purchaseId: transactionId },
      });
    }
  }, [confirmInvoicePaid, transactionId]);

  if (loading) {
    return <FullPageLoading />;
  }

  return <Navigate to={uri.minSideFixed("/faktura")} />;
};
