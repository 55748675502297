import { format, isOlderThan } from "@telia-no-min-side/utils";
import { useAuth } from "@telia-no-min-side/components";
import { GetProductUserQuery } from "gql/graphql";
import { FieldBlock } from "../style";
import { formatAddress } from "util/mobile/address";
import { SkeletonInfo } from "../common/SkeletonInfo";
import { ProfileView } from "../types";
import { Notification, Icon, Button, Flex, Text } from "@telia/teddy";
import { PageContainer } from "components/PageContainer";

type Props = {
  profileData?: GetProductUserQuery["subscription"];
  isLoading: boolean;
  onUpdateProfileClick: (profileView: ProfileView) => void;
};

export function ProfileInfo({ profileData, isLoading, onUpdateProfileClick }: Props) {
  const { userInfo } = useAuth();
  const { productUser } = profileData || {};
  const { address } = productUser || {};
  const isOver18 = userInfo?.birthdate ? isOlderThan(18, userInfo.birthdate) : true;

  if (isLoading) {
    return <SkeletonInfo />;
  }

  return (
    <PageContainer data-tracking-id="profile-page-user-info-minside" pl="0" pr="0">
      <FieldBlock>
        <div>Navn</div>
        <div data-di-mask>{format.getFullName(productUser)}</div>
      </FieldBlock>
      <FieldBlock>
        <div>Adresse</div>
        <div data-di-mask>
          <p>{formatAddress(address)}</p>
        </div>
      </FieldBlock>
      <FieldBlock>
        <div>E-post</div>
        <div data-di-mask>{productUser?.emailAddress}</div>
      </FieldBlock>

      <Flex direction="column" align="start" gap="200" data-tracking-id="profile-page-action-buttons-minside">
        {isOver18 ? (
          <Button variant="secondary" size="sm" onClick={() => onUpdateProfileClick("changeInfo")}>
            <Icon name="edit" />
            <Text>Endre e-post</Text>
          </Button>
        ) : null}

        {
          /* Show Change Password only for non Identity User (CT user/ Legacy mobile user) 
            For identity users Change Password is not handled from minside , it's done from identity.telia.no */
          !userInfo && (
            <Button variant="secondary" onClick={() => onUpdateProfileClick("changePassword")}>
              Endre passord
            </Button>
          )
        }
      </Flex>

      <Notification variant="information" mt="200" maxWidth="400px">
        <Notification.Heading as="h3">
          <Notification.Icon name="question">Er adressen din feil?</Notification.Icon>
        </Notification.Heading>
        <Notification.Text>
          Din adresse er hentet fra Folkeregisteret. Hvis du oppdager at adressen ikke stemmer, må du oppdatere
          informasjonen direkte i Folkeregisteret. Endringen vil automatisk oppdateres hos oss etter at den er
          behandlet.
        </Notification.Text>
        <Notification.Footer>
          <Button asChild variant="text">
            <a href="https://www.skatteetaten.no/person/folkeregister/flytte">
              Link til folkeregister
              <Icon name="arrow-right" />
            </a>
          </Button>
        </Notification.Footer>
      </Notification>
    </PageContainer>
  );
}
