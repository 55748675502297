import { Text } from "@telia/teddy";
import { Button, Container, ShoppingCard } from "@telia-no-min-side/components";
import { TabContent } from "../../styles/page-styles";
import { getSimplePriceText } from "util/priceFormatter";
import { Grid } from "./style";
import { BoxProps } from ".";

export function NoBox(props: BoxProps): JSX.Element {
  const { product, isSelected, onSelectClick, shouldDisableSelect } = props;
  const { currentPrice } = product.price;

  const onceOffText = currentPrice.onceOff ? `Etableringspris: ${currentPrice.onceOff},-` : "";

  return (
    <TabContent maxWidth="sm">
      <ShoppingCard
        title={product.name || "Eget utstyr"}
        additionalTagText="Jeg har allerede Apple TV, eller ser Telia Play på mobil, nettbrett eller nett-TV."
        price={getSimplePriceText(product.price.currentPrice.recurring, product.price.currentPrice.chargePeriod)}
        image={product.image}
        isSelected={isSelected}
      >
        <ShoppingCard.Body>
          <Text mt="100" mb="100" variant="additional-100">
            {onceOffText}
          </Text>
        </ShoppingCard.Body>
        {onSelectClick && (
          <ShoppingCard.Footer>
            <Grid>
              <Container floatContent="right" padding="top">
                <Button
                  disabled={shouldDisableSelect}
                  variant={isSelected ? "primary" : "secondary"}
                  size="sm"
                  onClick={onSelectClick}
                >
                  {isSelected ? "Valgt" : "Velg"}
                </Button>
              </Container>
            </Grid>
          </ShoppingCard.Footer>
        )}
      </ShoppingCard>
    </TabContent>
  );
}
