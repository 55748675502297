import { GetMobileDashboardDataQuery } from "gql/graphql";

export function isTeliaXSubscriptionHandler(
  subscription: GetMobileDashboardDataQuery["subscription"] | undefined
): boolean {
  if (!subscription) return false;

  const pricePlan = subscription.userOffering?.pricePlan;

  return (
    pricePlan?.startsWith("TELIA_MOBIL_X") ||
    pricePlan?.startsWith("TELIA_X") ||
    pricePlan?.startsWith("SMART_X") ||
    false // Fallback to ensure it always returns a boolean
  );
}
