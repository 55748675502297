import styled from "styled-components";
import { Card } from "../Card";
import { colors } from "@telia/styleguide";
import { remCalc } from "../utils/fonts";

export const ActionCard = styled(Card)`
  color: ${(props) => props.color || colors.corePurple700};
  align-self: center;
  min-height: inherit;
  height: ${remCalc(73)};
  width: ${remCalc(150)};
  margin-right: 1rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  column-gap: ${remCalc(10)};
  min-height: inherit;
`;
