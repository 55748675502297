import { Dayjs } from "dayjs";

type Options = {
  path?: string;
  domain?: string;
  expires?: Dayjs;
};

export function setCookie(name: string, value: string, options?: Options) {
  const path = options?.path || "/";
  const pathCookieString = path !== "" ? `;path=${path}` : "";

  if (!options) {
    return (document.cookie = `${name}=${value}${pathCookieString}`);
  }

  const { expires, domain } = options;
  const expiresCookieString = expires
    ? `;expires=${expires.isUTC() ? expires.toString() : expires.utc().toString()}`
    : "";

  const domainCookieString = domain ? `;domain=${domain}` : "";
  const cookie = `${name}=${value}${expiresCookieString}${domainCookieString}${pathCookieString}`;
  document.cookie = cookie;
}
