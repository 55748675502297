import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Card, Container, Heading, Icon, Paragraph } from "@telia-no-min-side/components";
import { uri, track } from "@telia-no-min-side/utils";
import { useParams, useSearchParams } from "react-router-dom";
import { Offering, UserOffering } from "src/gql/graphql";
import { useOrderSubscription } from "src/store/OrderToastContext";
import { ErrorMessage } from "../components/MbbError";
import { SkeletonPage } from "../components/Skeleton";
import { ReceiptSuccess } from "../components/MbbSuccess";
import { Grid } from "../components/styles/style";
import { ProductChangesSection } from "../components/ProductChangesSection";
import { createConfirmChangeSubscriptionEvent } from "../utils/createConfirmChangeSubscriptionEvent";
import { GET_UPGRADE_DOWNGRADE_SUMMARY } from "../graphql/getUpgradeDowngradeSummary";
import { CHANGE_OFFERING_MUTATION } from "../graphql/changeOfferingMutation";
import { findAmount } from "util/format-subscription/findAmount";
import { isMbbUnlimitedDataOffer } from "util/format-subscription/isMbbUnlimitedDataOffer";
import { getDownloadSpeedMbb } from "util/format-subscription/getDownloadSpeedMbb";
import { getOfferingPrices } from "util/format-subscription/getOfferingPrices";
import { isMbbSleepModeOffer } from "util/format-subscription/isMbbSleepModeOffer";
import { PriceChangeSummary } from "../components/PriceChangeSummary";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { PdfHeader } from "components/pdf-order-summary/PdfHeader";
import { DownloadButton } from "components/pdf-order-summary/DownloadButton";
import { openPdf } from "util/pdf-order-summary/openPdf";
import { PdfFooter } from "components/pdf-order-summary/PdfFooter";

export function MbbSubscriptionConfirm() {
  const params = useParams<{ accountId?: string; phoneNumber?: string }>();
  const { subscribeToOrder } = useOrderSubscription();
  const { pushTrackingEvent } = track.useEventTracking();
  const [search] = useSearchParams();
  const pricePlan = search.get("pricePlan");
  const [pageView, setPageView] = useState<"summary" | "receipt-error" | "receipt-success">("summary");

  const upgradeDowngradeSummary = useQuery(GET_UPGRADE_DOWNGRADE_SUMMARY, {
    variables: {
      phoneNumber: params.phoneNumber || "",
      offering: pricePlan || "",
      accountId: params.accountId || "",
      withProductChanges: true,
    },
    skip: !params.phoneNumber || !pricePlan || !params.accountId,
  });

  const [mutateSubscription, mutateSubscriptionResult] = useMutation(CHANGE_OFFERING_MUTATION);

  const handleChangeSubscription = (
    subToBeRemoved: UserOffering | undefined | null,
    subToBeAdded: Offering | undefined | null
  ) => {
    const phoneNumber = params.phoneNumber || "";
    const offering = pricePlan;
    mutateSubscription({
      variables: { phoneNumber, offering },
      onCompleted(data) {
        const orderId = data.changeOffering?.orderId;
        if (orderId) {
          subscribeToOrder({
            id: orderId,
            toastText: "Nytt abonnement er aktivert",
            phoneNumber: params.phoneNumber || "",
          });
          pushTrackingEvent(createConfirmChangeSubscriptionEvent(subToBeRemoved, subToBeAdded, orderId));
          return setPageView("receipt-success");
        }
        setPageView("receipt-error");
      },
      onError: () => {
        setPageView("receipt-error");
      },
    });
  };

  const subToBeAdded = upgradeDowngradeSummary.data?.account.subscription.newOfferingDetails?.offerings?.find(
    (offer) => offer?.pricePlan === pricePlan
  );

  if (upgradeDowngradeSummary.loading && !upgradeDowngradeSummary.data) {
    return <SkeletonPage />;
  }

  if (
    (!upgradeDowngradeSummary.data && upgradeDowngradeSummary.error) ||
    !upgradeDowngradeSummary.data ||
    !params.accountId ||
    !params.phoneNumber ||
    (!subToBeAdded && pageView === "summary") ||
    !pricePlan
  ) {
    return (
      <ErrorMessage
        isLoading={upgradeDowngradeSummary.loading}
        refetch={
          pricePlan && params.phoneNumber
            ? () => {
                upgradeDowngradeSummary.refetch({ offering: pricePlan, phoneNumber: params.phoneNumber });
              }
            : undefined
        }
      />
    );
  }

  const { userOffering: subToBeRemoved } = upgradeDowngradeSummary.data.account.subscription;

  const subToBeRemovedOfferingPrices = getOfferingPrices(subToBeRemoved?.offeringPrices);

  const subToBeRemovedDownloadSpeed = getDownloadSpeedMbb(
    subToBeRemovedOfferingPrices,
    subToBeRemoved?.longDescription
  );
  const subToBeAddedDownloadSpeed = getDownloadSpeedMbb(
    subToBeAdded?.price?.offeringPrices,
    subToBeAdded?.longDescription
  );

  const subToBeRemovedDataIncluded = subToBeRemovedOfferingPrices?.find(findAmount);
  const subToBeAddedDataIncluded = subToBeAdded?.price?.offeringPrices?.find(findAmount);

  const subToBeRemovedHasUnlimitedData = isMbbUnlimitedDataOffer(
    subToBeRemovedOfferingPrices,
    subToBeRemoved?.shortName
  );
  const subToBeAddedHasUnlimitedData = isMbbUnlimitedDataOffer(
    subToBeAdded?.price?.offeringPrices,
    subToBeAdded?.shortName
  );

  if (pageView === "receipt-success") {
    return <ReceiptSuccess isSleepMode={isMbbSleepModeOffer(subToBeAdded?.shortName)} />;
  }
  if (pageView === "receipt-error") {
    return (
      <ErrorMessage
        isLoading={mutateSubscriptionResult.loading}
        refetch={() => handleChangeSubscription(subToBeRemoved, subToBeAdded)}
      />
    );
  }

  return (
    <Container
      id="mbb-subscription-change-order-summary"
      padding
      showGoBackButton
      gap
      flexDirection="column"
      maxWidth="sm"
      data-tracking-id="mobile-subscription-confirm-container"
    >
      <PdfHeader />
      <Heading tag="h2" variant="title-300" data-tracking-id="mobile-subscription-confirm-heading">
        Ordresammendrag
      </Heading>

      <PriceChangeSummary subToBeRemoved={subToBeRemoved} subToBeAdded={subToBeAdded} />

      <Container flexDirection="column" gap="lg" padding="bottom" data-tracking-id="mobile-subscription-confirm-info">
        <Container gap="sm" flexDirection="column" data-tracking-id="mobile-subscription-confirm-speed">
          <Paragraph removeMargin>
            <em>Hastighet</em>
          </Paragraph>
          <Card backgroundColor="grey" removeMinHeight showShadow={false} padding="md" maxWidth="lg">
            <Grid gap>
              <Paragraph removeMargin>{subToBeRemovedDownloadSpeed} Mbps</Paragraph>
              <Icon icon="arrow-right" />
              <Paragraph removeMargin>{subToBeAddedDownloadSpeed} Mbps</Paragraph>
            </Grid>
          </Card>
        </Container>
        <Container gap="sm" flexDirection="column" data-tracking-id="mobile-subscription-confirm-data">
          <Paragraph removeMargin>
            <em>Datamengde</em>
          </Paragraph>
          <Card backgroundColor="grey" removeMinHeight showShadow={false} padding="md" maxWidth="lg">
            <Grid gap>
              <Paragraph removeMargin>
                {subToBeRemovedHasUnlimitedData
                  ? "Ubegrenset data"
                  : typeof subToBeRemovedDataIncluded?.amount === "number"
                  ? `${subToBeRemovedDataIncluded.amount} GB`
                  : "Klarte ikke hente"}
              </Paragraph>
              <Icon icon="arrow-right" />
              <Paragraph>
                {" "}
                {subToBeAddedHasUnlimitedData
                  ? "Ubegrenset data"
                  : typeof subToBeAddedDataIncluded?.amount === "number"
                  ? `${subToBeAddedDataIncluded.amount} GB`
                  : "Klarte ikke hente"}
              </Paragraph>
            </Grid>
          </Card>
        </Container>
        <ProductChangesSection productChanges={upgradeDowngradeSummary.data.account.subscription.productChanges} />
      </Container>
      <DownloadButton mr="auto" onClick={async () => openPdf("#mbb-subscription-change-order-summary")} />
      <Container
        flexDirection="row"
        gap="lg"
        justifyContent="flex-end"
        padding="vertical"
        data-tracking-id="mobile-subscription-confirm-action-buttons"
        data-html2canvas-ignore
      >
        <Button
          hideLinkIcon
          variant="secondary"
          tag="a"
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_CANCEL,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.UPGRADE_DOWNGRADE_SUMMARY,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
            ui_item_url: uri.minSideFixed("/hjem"),
            ui_item_text: TRACK_EVENT.ITEM_TEXT.CANCEL,
          }}
          href={uri.minSideFixed("/hjem")}
          isInternalNavigation
          data-tracking-id="mobile-subscription-confirm-action-buttons-cancel"
        >
          Avbryt
        </Button>
        <Button
          type="button"
          variant="primary"
          hideLinkIcon
          isLoading={mutateSubscriptionResult.loading}
          loadingText="Sender bestilling"
          onClick={() => handleChangeSubscription(subToBeRemoved, subToBeAdded)}
          data-tracking-id="mobile-subscription-confirm-action-buttons-order"
        >
          Fullfør bestilling
        </Button>
      </Container>
      <PdfFooter />
    </Container>
  );
}
