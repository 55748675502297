import { Container, Heading } from "@telia-no-min-side/components";
import { FAQAccordion } from "../components/help-accordion-list/FAQAccordion";

export function FaqComponentFixed(): JSX.Element {
  return (
    <Container padding="bottom" flexDirection="column" gap>
      <Heading tag="h4" variant="title-100">
        Ofte stilte spørsmål
      </Heading>
      <FAQAccordion />
    </Container>
  );
}
