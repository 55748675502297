import React from "react";
import { LoadingDot, LoadingDotsWrapper } from "./style";

/**
 * @deprecated Use Teddy components instead
 */
export function LoadingDots(): JSX.Element {
  return (
    <LoadingDotsWrapper>
      <LoadingDot>.</LoadingDot>
      <LoadingDot>.</LoadingDot>
      <LoadingDot>.</LoadingDot>
    </LoadingDotsWrapper>
  );
}
