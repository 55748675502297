import { Drawer as TeddyDrawer } from "@telia/teddy";
import { RLHDrawerContent, TopupDrawerContent, HvilemodusDrawerContent } from "./components";
import { useMobileBroadbandContext } from "pages/internet/mobile-broadband/context/MobileBroadbandContext";

export function Drawer() {
  const { isDrawerOpen, closeDrawer, drawerContent, setDrawerContent, setTopupCategory } = useMobileBroadbandContext();
  return (
    <TeddyDrawer
      open={isDrawerOpen}
      onOpenChange={(open) => {
        !open && closeDrawer();
        setDrawerContent(undefined);
        setTopupCategory(undefined);
      }}
    >
      <TeddyDrawer.Content width="100%" maxWidth="500px">
        <TeddyDrawer.Close aria-label="Close" slot="floating" onClick={closeDrawer} />
        {drawerContent === "Hvilemodus" && <HvilemodusDrawerContent />}
        {drawerContent === "RLH" && <RLHDrawerContent />}
        {drawerContent === "Topup" && <TopupDrawerContent />}
      </TeddyDrawer.Content>
    </TeddyDrawer>
  );
}
