import { useQuery } from "@apollo/client";
import { Alert } from "@telia-no-min-side/components";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { BlankSimCardType } from "./components/OrderBlankSimCardSummary";
import { AdditionalSimCardsSkeleton } from "../mobile-subscription-order-additional-sim-card/components/AdditionalSimCardsSkeleton";
import { OrderAdditionalSimCard } from "./components/OderAdditionalSimCard";
import { SimCheckoutContextProvider, useSimCheckout } from "../mobile-subscription-sim-pin-puk/hooks/useSimCheckout";
import { graphql } from "gql";
import { useSearchParams } from "react-router-dom";

export const GET_REPLACEMENT_SIM_DATA = graphql(`
  query getReplacementSimData($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      productUser {
        emailAddress
      }
      userOffering {
        pricePlan
        offeringPrices {
          offeringPrices {
            code
            amount
          }
        }
      }
    }
  }
`);

function getSimCardType(simCardType: string | null) {
  switch (simCardType) {
    case BlankSimCardType.Hw:
      return BlankSimCardType.Hw;
    case BlankSimCardType.Esim:
      return BlankSimCardType.Esim;
    case BlankSimCardType.EsimPush:
      return BlankSimCardType.EsimPush;
    case BlankSimCardType.Micro:
      return BlankSimCardType.Micro;
    case BlankSimCardType.Nano:
      return BlankSimCardType.Nano;
    case BlankSimCardType.Normal:
      return BlankSimCardType.Normal;
    default:
      return BlankSimCardType.Esim;
  }
}

export function MobileSubscriptionOrderBlankSimCard() {
  const [searchParams, setSearchParams] = useSearchParams();

  const simCardType = getSimCardType(searchParams.get("simCardType"));

  const { phoneNumber } = useMobileAccount();
  const { data, loading, error } = useQuery(GET_REPLACEMENT_SIM_DATA, { variables: { phoneNumber } });
  const { addressLoading } = useSimCheckout();

  const subscription = data?.subscription;
  function setSimCardType(simCardType: BlankSimCardType) {
    setSearchParams({ simCardType });
  }

  if (error) {
    return (
      <Alert isOpen={true} kind="negative" icon="alert" title="Beklager, noe gikk galt" text="Prøv igjen senere" />
    );
  }

  if (loading || addressLoading) {
    return <AdditionalSimCardsSkeleton />;
  }

  return (
    <SimCheckoutContextProvider>
      <OrderAdditionalSimCard subscription={subscription} simCardType={simCardType} setSimCardType={setSimCardType} />
    </SimCheckoutContextProvider>
  );
}
