import { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { format } from "@telia-no-min-side/utils";
import { Flex, Badge, Card, Text, Button, Heading, Icon, Link as TeddyLink } from "@telia/teddy";
import { AdditionalProduct } from "gql/graphql";
import { INSURANCE_TYPE, URL_INSURANCE_AGREEMENT_TERMS, URL_INSURANCE_POLICY_IPID } from "util/constants/agreements";
import { shouldDisplayOldPrice } from "./utils/shouldDisplayOldPrice";
import { CheckoutCard } from "./components/CheckoutCard";

type PostPurchaseInsurancesType = {
  firstName: string;
  phoneNumber: string;
  postPurchaseDummyProduct: AdditionalProduct;
  postPurchaseInsurances: AdditionalProduct[];
};

export function PostPurchaseInsurancesPage() {
  const navigate = useNavigate();
  const { state } = useLocation() as { state: PostPurchaseInsurancesType };
  const { firstName = "", phoneNumber = "", postPurchaseDummyProduct = {}, postPurchaseInsurances = [] } = state || {};

  const [selectedInsurance, setSelectedInsurance] = useState<AdditionalProduct | null>(null);

  if (!phoneNumber || !postPurchaseDummyProduct || !postPurchaseInsurances.length) {
    navigate("/minside/hjem", { replace: true });
    return null;
  }

  return (
    <Flex direction="column" p="400" gap="400">
      <Flex justify="start">
        <Button variant="text" asChild>
          <Link to="..">
            <Icon name="arrow-left" />
            Tilbake
          </Link>
        </Button>
      </Flex>

      <Flex direction="column" gap="300">
        <Heading as="h1" style={{ fontSize: "28px" }}>
          Velg hvilken forsikring du vil ha
        </Heading>

        {!!firstName && (
          <Text data-di-mask>
            Forsikringen gjelder for {format.startCase(firstName)} med nummer {phoneNumber}.
          </Text>
        )}

        <Flex direction="row" align="start" gap="400" wrap="wrap">
          <Flex direction="column-reverse" gap="300" style={{ maxWidth: "660px" }}>
            {postPurchaseInsurances.map((insurance: AdditionalProduct) => {
              const { shortName, price } = insurance;
              const { price: priceValue, priceWithoutDiscount, discountDuration } = price || {};

              const isScreenInsurance = shortName?.toLowerCase().includes(INSURANCE_TYPE.SCREEN);
              const insuranceName = isScreenInsurance
                ? format.startCase(INSURANCE_TYPE.SCREEN)
                : format.startCase(INSURANCE_TYPE.PLUS);

              const description = isScreenInsurance
                ? "Skjermforsikring dekker knust skjerm på mobilen. Ingen bindingstid."
                : "Plussforsikring gir deg full dekning ved skade, tap, tyveri og vannskade. Ingen bindingstid.";

              const displayOldPrice = shouldDisplayOldPrice(insurance);

              return (
                <Card
                  layout="default"
                  variant="white"
                  bordered
                  key={shortName}
                  data-tracking-id={`post-purchase-insurance-card-${isScreenInsurance ? "screen" : "plus"}`}
                >
                  <Card.Slot align="top-right">
                    <Flex gap="150">
                      {discountDuration && <Badge variant="special">Første {discountDuration} md. kostnadsfritt</Badge>}
                      {selectedInsurance === insurance && (
                        <Badge variant="warning" hideIcon>
                          Valgt
                        </Badge>
                      )}
                    </Flex>
                  </Card.Slot>

                  <Flex direction="row" justify="between">
                    <Card.Heading>{insuranceName}</Card.Heading>

                    <Flex direction="row" align="center" gap="100">
                      {displayOldPrice && (
                        <Text variant="paragraph-100-medium">
                          <s>{priceWithoutDiscount},-</s>
                        </Text>
                      )}

                      <Flex direction="row" align="center">
                        <Card.Heading>{priceValue},-</Card.Heading>
                        <Text>/md.</Text>
                      </Flex>
                    </Flex>
                  </Flex>

                  <Card.Content>
                    <Text>{description}</Text>
                  </Card.Content>

                  <Card.Line />

                  <Card.Footer>
                    <Button
                      variant={selectedInsurance === insurance ? "primary" : "secondary"}
                      onClick={() => setSelectedInsurance(insurance)}
                    >
                      {selectedInsurance === insurance ? "Valgt" : "Velg"}
                    </Button>
                  </Card.Footer>
                </Card>
              );
            })}
          </Flex>
          <CheckoutCard
            phoneNumber={phoneNumber}
            selectedInsurance={selectedInsurance}
            setSelectedInsurance={setSelectedInsurance}
            postPurchaseDummyProduct={postPurchaseDummyProduct}
          />
        </Flex>
      </Flex>

      <Text variant="additional-100">
        Egenandel vil påløpe ved bruk av forsikring.{" "}
        <TeddyLink variant="text" href={URL_INSURANCE_POLICY_IPID} target="_blank">
          Les produktfaktablad IPID (PDF)
        </TeddyLink>{" "}
        og{" "}
        <TeddyLink variant="text" href={URL_INSURANCE_AGREEMENT_TERMS} target="_blank">
          mer om forsikring.
        </TeddyLink>
      </Text>
    </Flex>
  );
}
