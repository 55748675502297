import { Flex, Skeleton } from "@telia/teddy";

export function CountrySearchLoading() {
  return (
    <Flex direction="column" gap="gutter-sm">
      <Skeleton height="90px" width="100%" />
      <Skeleton height="90px" width="100%" />
      <Skeleton height="90px" width="100%" />
    </Flex>
  );
}
