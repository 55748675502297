import { Button, ShoppingSummary } from "@telia-no-min-side/components";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { useFlexOrder } from "../../context/FlexOrderProvider";

type Props = {
  hideShoppingCartHandler?: () => void;
};

export function ShoppingCartActions({ hideShoppingCartHandler }: Props) {
  const {
    submit,
    pageView,
    setPageView,
    setViewMode,
    flexProductOffer,
    setRequirementIndex,
    flexRequirementsData,
    flexRequirementsLoading,
    hasNextRequirementsPage,
  } = useFlexOrder();

  const getTrackEvent = (action: string, text: string) => ({
    ui_item_action: action,
    ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FLEX,
    ui_item_text: text,
    ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
    ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
  });

  const isLoading = flexRequirementsLoading || submit.isSubmitting;
  const noRequirementsNeeded = flexRequirementsData?.length === 0;
  const requirementsNeeded = !!flexRequirementsData && flexRequirementsData?.length > 0;

  const BackButton = () => (
    <Button
      variant="tertiary-black"
      onClick={() => {
        setViewMode("STEP1");
        setPageView("PRODUCT_SELECT");
        hideShoppingCartHandler && hideShoppingCartHandler();
      }}
      trackEvent={getTrackEvent(TRACK_EVENT.ITEM_ACTION.CANCEL, TRACK_EVENT.ITEM_TEXT.CANCEL)}
    >
      Avbryt
    </Button>
  );

  if (noRequirementsNeeded) {
    return (
      <ShoppingSummary.ActionWrapper>
        <BackButton />
        <Button
          isLoading={isLoading}
          onClick={submit.placeOrder}
          trackEvent={getTrackEvent(TRACK_EVENT.ITEM_ACTION.ORDER, TRACK_EVENT.ITEM_TEXT.ORDER)}
          variant="purchase"
        >
          Bestill
        </Button>
      </ShoppingSummary.ActionWrapper>
    );
  }

  if (pageView === "PRODUCT_SELECT" && requirementsNeeded) {
    return (
      <ShoppingSummary.ActionWrapper>
        <BackButton />
        <Button
          isLoading={flexRequirementsLoading}
          icon="arrow-right"
          iconPosition="after"
          trackEvent={getTrackEvent(
            `go to requirementSelect bb:${flexProductOffer?.broadbandOption?.downloadSpeed}, tv:${flexProductOffer?.tvOption?.points}`,
            TRACK_EVENT.ITEM_TEXT.FURTHER
          )}
          onClick={() => setPageView("REQUIREMENT_SELECT")}
        >
          Videre
        </Button>
      </ShoppingSummary.ActionWrapper>
    );
  }

  if (pageView === "REQUIREMENT_SELECT") {
    return (
      <ShoppingSummary.ActionWrapper>
        <BackButton />
        {hasNextRequirementsPage ? (
          <Button
            isLoading={flexRequirementsLoading}
            icon="arrow-right"
            iconPosition="after"
            trackEvent={getTrackEvent(TRACK_EVENT.ITEM_ACTION.GO_TO_NEXT_REQUIREMENT, TRACK_EVENT.ITEM_TEXT.FURTHER)}
            onClick={() => setRequirementIndex((prev) => prev + 1)}
          >
            Videre
          </Button>
        ) : (
          <Button
            isLoading={isLoading}
            onClick={submit.placeOrder}
            trackEvent={getTrackEvent(TRACK_EVENT.ITEM_ACTION.ORDER_WITH_REQUIREMENTS, TRACK_EVENT.ITEM_TEXT.ORDER)}
            variant="purchase"
          >
            Bestill
          </Button>
        )}
      </ShoppingSummary.ActionWrapper>
    );
  }

  return null;
}
