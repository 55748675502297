import { Accordion, Container } from "@telia-no-min-side/components";
import { AvtalegiroMobileAccordion } from "src/pages/invoice/components/help-accordion-list/accordion/AvtalegiroMobile";
import { Efaktura } from "src/pages/invoice/components/help-accordion-list/accordion/Efaktura";
import { ForgotToPayAccordion } from "src/pages/invoice/components/help-accordion-list/accordion/ForgotToPay";
import { InvoiceCopyMobileAccordion } from "src/pages/invoice/components/help-accordion-list/accordion/InvoiceCopyMobile";
import { InvoiceFeeMobileAccordion } from "src/pages/invoice/components/help-accordion-list/accordion/InvoiceFeeMobile";
import { PaidEmployerAccordion } from "src/pages/invoice/components/help-accordion-list/accordion/PaidEmployer";
import { PapirfakturaMobile } from "src/pages/invoice/components/help-accordion-list/accordion/PapirfakturaMobile";
import { PaymentDeferralMobileAccordion } from "src/pages/invoice/components/help-accordion-list/accordion/PaymentDeferralMobile";
import { PostSmsAccordion } from "src/pages/invoice/components/help-accordion-list/accordion/PostSms";
import { Strex } from "src/pages/invoice/components/help-accordion-list/accordion/Strex";
import { SubBlockedAccordion } from "src/pages/invoice/components/help-accordion-list/accordion/SubBlocked";
import { HighPriceAccordion } from "../components/help-accordion-list/accordion/HighPrice";

export const FAQAccordionMobile = () => {
  return (
    <Accordion>
      <Container flexDirection="column" gap>
        <HighPriceAccordion />
        <Efaktura />
        <Strex />
        <PapirfakturaMobile />
        <InvoiceFeeMobileAccordion />
        <AvtalegiroMobileAccordion />
        <InvoiceCopyMobileAccordion />
        <ForgotToPayAccordion />
        <PaymentDeferralMobileAccordion />
        <SubBlockedAccordion />
        <PaidEmployerAccordion />
        <PostSmsAccordion />
      </Container>
    </Accordion>
  );
};
