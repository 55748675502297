import { Link } from "@telia-no-min-side/components";
import { track } from "@telia-no-min-side/utils";

type Props = {
  trackingContext: string;
};

export function CustomerCareLink({ trackingContext, ...rest }: Props) {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  return (
    <Link
      onClick={() => {
        pushGenericTrackingEvent({
          ui_item_action: "link_click",
          ui_item_context: trackingContext,
          ui_item_text: "Ring +47 924 05 050",
          ui_item_type: "phoneNumber-link",
          ui_item_url: "no_url",
        });
      }}
      variant="standalone"
      href="tel:+4792405050"
      icon="support"
      {...rest}
    >
      +47 924 05 050
    </Link>
  );
}
