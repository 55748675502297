import { useContext, createContext, useMemo, ReactNode } from "react";
import { MutationResult, BaseMutationOptions } from "@apollo/client";
import { OrderAdditionalProductsMutation, OrderAdditionalProductsMutationVariables } from "src/gql/graphql";
import { graphql } from "src/gql";
import { useBankIdMutation } from "./useBankIdMutation";

export const ORDER_ADDITIONAL_PRODUCTS = graphql(`
  mutation orderAdditionalProducts($phoneNumber: String!, $input: [OrderInput!]!) {
    order(phoneNumber: $phoneNumber, input: $input) {
      orderId
      redirectUrl
    }
  }
`);

type OrderAdditionalProductsOptions = BaseMutationOptions<
  OrderAdditionalProductsMutation,
  OrderAdditionalProductsMutationVariables
> & {
  uniqueKey?: string;
};

export function useOrderAdditionalProducts(options?: OrderAdditionalProductsOptions) {
  const [orderAdditionalProducts, response] = useBankIdMutation<
    OrderAdditionalProductsMutation,
    OrderAdditionalProductsMutationVariables
  >(
    ORDER_ADDITIONAL_PRODUCTS,
    {
      onCompleted: options?.onCompleted,
      onError: options?.onError,
    },
    options?.uniqueKey || "orderAdditionalProducts"
  );

  async function runOrderAdditionalProducts(
    { phoneNumber, input }: OrderAdditionalProductsMutationVariables,
    mutationOptions?: OrderAdditionalProductsOptions,
    requireBankId?: boolean
  ) {
    const forceSkipBankIdCheck = !requireBankId;

    if (!forceSkipBankIdCheck && !options?.uniqueKey) {
      console.warn("Unique key is required when bank id check is not skipped");
    }

    try {
      const finalOrderInput = Array.isArray(input)
        ? input.map((order) => {
            if (order.productCharacteristics) {
              return {
                ...order,
                productCharacteristics: {
                  ...order.productCharacteristics,
                  config: order.productCharacteristics.config?.map((characteristic) => ({
                    name: characteristic?.name,
                    value: characteristic?.value,
                  })),
                },
              };
            }

            return order;
          })
        : input;

      await orderAdditionalProducts(
        {
          variables: {
            phoneNumber,
            input: finalOrderInput,
          },
          ...options,
          ...mutationOptions,
        },
        forceSkipBankIdCheck
      );
    } catch (error) {
      console.error(error);
    }
  }

  return {
    orderResponse: response,
    runOrderAdditionalProducts,
  };
}

type OrderAdditionalProductsContext = {
  orderResponse: MutationResult<OrderAdditionalProductsMutation> | undefined;
  runOrderAdditionalProducts: RunOrderAdditionalProducts | undefined;
};

export type RunOrderAdditionalProducts = (
  { phoneNumber, input }: OrderAdditionalProductsMutationVariables,
  options?: OrderAdditionalProductsOptions,
  requireBankId?: boolean
) => Promise<void>;

export const AdditionalProductsContext = createContext<OrderAdditionalProductsContext>({
  orderResponse: undefined,
  runOrderAdditionalProducts: undefined,
});

export function useAdditionalProducts() {
  const context = useContext(AdditionalProductsContext);
  if (!context.runOrderAdditionalProducts || !context.orderResponse) {
    console.log("You are using AdditionalProductsContext without provider");
  }

  return context;
}

export function AdditionalProductsProvider({ children }: { children: ReactNode }) {
  const { orderResponse, runOrderAdditionalProducts } = useOrderAdditionalProducts();

  const memoizedValue = useMemo(() => {
    return {
      orderResponse,
      runOrderAdditionalProducts,
    };
  }, [orderResponse.loading]);

  return <AdditionalProductsContext.Provider value={memoizedValue}>{children}</AdditionalProductsContext.Provider>;
}
