import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { useFetchAndLocalStore } from "./useFetchAndLocalStore";
import { types } from "@telia-no-min-side/utils";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

type Options = {
  enableSkeletonNumberStore?: boolean;
};

export function useFetchTvBoxes(options?: Options) {
  const enableSkeletonNumberStore = options?.enableSkeletonNumberStore ?? false;
  const { data, mutate, ...rest } = useFetchAndLocalStore(ICX_REQUEST.URL_TV_BOXES, {
    storeKey: "number-of-tv-boxes",
    enableSkeletonNumberStore,
  });

  return {
    tvBoxes: data,
    mutateTvBoxes: mutate,
    ...rest,
  };
}
export function useFetchAllTvBoxes() {
  return useAxiosSWR<types.fixed.ProductCategoryAll>(ICX_REQUEST.URL_ALL_TV_BOXES);
}
