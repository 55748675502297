import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = {
  onRetryClick?(): void;
  isLoading?: boolean;
};

export function ReceiptError({ isLoading, onRetryClick }: Props) {
  return (
    <Container maxWidth="md" padding alignItems="flex-start" flexDirection="column" gap>
      <Heading tag="h2" variant="title-300">
        Noe gikk galt!
      </Heading>

      <Paragraph>
        Vi kunne dessverre ikke fullføre din bestilling.{" "}
        {onRetryClick ? "Vennligst prøv igjen. Hvis feilen gjentar seg, kontakt kundeservice." : "Kontakt kundeservice"}
      </Paragraph>
      <Container gap flexDirection="row">
        {onRetryClick && (
          <Button
            onClick={onRetryClick}
            isLoading={isLoading}
            trackEvent={{
              ui_item_text: TRACK_EVENT.ITEM_TEXT.TRY_AGAIN,
              ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
              ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FWA_COMMITMENT,
              ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_ERROR,
              ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
            }}
          >
            Prøv på nytt
          </Button>
        )}
        <Button
          tag="a"
          isInternalNavigation
          href={uri.minSideFixed("/hjem")}
          variant="secondary"
          icon="arrow-left"
          iconPosition="before"
          trackEvent={{
            ui_item_text: TRACK_EVENT.ITEM_TEXT.BACK_TO_OVERVIEW,
            ui_item_url: uri.minSideFixed("/hjem"),
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FWA_COMMITMENT,
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_ERROR,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
          }}
        >
          Tilbake til oversikten
        </Button>
      </Container>
    </Container>
  );
}
