import { useState } from "react";
import { Modal, Button, Text, Flex } from "@telia/teddy";
import { NavigationCard } from "components/NavigationCard";
import { useLogoutAllDevices } from "src/hooks/useLogoutAllDevices";
import { tvCardWidth } from "../utils/constants";

type LogoutStatus = "success" | "error" | "processing";

export function LogoutOfAllDevices(): JSX.Element {
  const { logoutAllDevices } = useLogoutAllDevices();
  const [logoutStatus, setLogoutStatus] = useState<LogoutStatus | undefined>(undefined);

  async function onLogoutClick() {
    setLogoutStatus("processing");
    try {
      const response = await logoutAllDevices();

      setLogoutStatus(response.success ? "success" : "error");
      if (response.logoutUrl) {
        window.location.replace(response.logoutUrl);
      }
    } catch {
      setLogoutStatus("error");
    }
  }

  return (
    <>
      <Modal onOpenChange={() => setLogoutStatus(undefined)}>
        <Modal.Trigger>
          <NavigationCard
            title="Logg ut overalt"
            description="Logg ut av alle bokser og enheter"
            icon="logout"
            width={tvCardWidth}
            layout="navigation-horizontal-small"
          />
        </Modal.Trigger>
        <Modal.Overlay>
          <Modal.Content>
            <Modal.Group variant="title">
              <Modal.Title as="h3">Logge av alle enheter</Modal.Title>
            </Modal.Group>
            <Modal.Close slot="floating" aria-label="lukk" />
            <Modal.Description>
              {(!logoutStatus || logoutStatus === "processing") && (
                <Flex direction="column" gap="200">
                  <Text variant="paragraph-100">
                    Logg av Telia Play på alle enheter som er innlogget med ditt brukernavn og passord. Det vil si alle
                    TV-bokser og nettlesere hvor du er logget inn i Telia Play.
                  </Text>
                  <Text variant="paragraph-100">Det kan ta opptil en time før alle enhetene er logget av.</Text>
                  <Text variant="paragraph-100">
                    Andre som er logget inn med ditt brukernavn og passord blir også logget ut.
                  </Text>
                </Flex>
              )}
              {logoutStatus === "success" && <Text>Du vil nå bli logget ut av alle enheter.</Text>}
              {logoutStatus === "error" && (
                <Text>En feil oppsto ved utlogging av alle tjenester. Prøv igjen senere.</Text>
              )}
            </Modal.Description>
            <Modal.Group variant="action">
              <Modal.Close slot="content">
                <Button variant="secondary">Avbryt</Button>
              </Modal.Close>
              {!logoutStatus || logoutStatus === "processing" ? (
                <Button onClick={onLogoutClick} loading={logoutStatus === "processing"}>
                  Logg av enheter
                </Button>
              ) : null}
            </Modal.Group>
          </Modal.Content>
        </Modal.Overlay>
      </Modal>
    </>
  );
}
