import { Paragraph } from "@telia-no-min-side/components";

type Props = {
  downloadSpeed?: number;
  showDownloadSpeed: boolean;
  hasUnlimitedData: boolean;
  removeMargin?: boolean;
};

export function SpeedOrDataBenefit({ downloadSpeed, showDownloadSpeed, hasUnlimitedData, removeMargin }: Props) {
  if (showDownloadSpeed && hasUnlimitedData) {
    return <Paragraph removeMargin={removeMargin}>{downloadSpeed} Mbps - Ubegrenset data</Paragraph>;
  }

  if (hasUnlimitedData) {
    return <Paragraph removeMargin={removeMargin}>Ubegrenset data</Paragraph>;
  }

  if (showDownloadSpeed) {
    return <Paragraph removeMargin={removeMargin}>{downloadSpeed} Mbps</Paragraph>;
  }

  return undefined;
}
