import { Card, Icon, Text, IconProps, CardProps, Skeleton, BadgeProps, Badge, Flex } from "@telia/teddy";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

export type Props = {
  title?: string;
  description?: string | ReactElement;
  icon?: IconProps["name"];
  badge?: { variant: BadgeProps["variant"]; icon: IconProps["name"]; text: string };
  link?: string;
  onClick?: () => void;
  cardVariant?: CardProps["Root"]["variant"];
  isLoading?: boolean;
  width?: CardProps["Root"]["width"];
  children?: ReactElement;
  isExternalLink?: boolean;
  layout?: CardProps["Root"]["layout"];
};

export function NavigationCard({
  cardVariant = "gray",
  title,
  description,
  icon,
  badge,
  link,
  onClick,
  isLoading = false,
  width,
  children,
  isExternalLink,
  layout = "navigation-horizontal-large",
}: Props) {
  const isLinkExternal = isExternalLink !== undefined ? isExternalLink : link?.startsWith("https");

  return (
    <Card
      layout={layout}
      variant={cardVariant}
      width={width}
      onPointerUp={(event) => {
        const isKeyDownInside = event?.currentTarget?.contains(event?.target as HTMLElement);
        if (!isKeyDownInside) {
          event?.preventDefault();
        }
      }}
    >
      <Skeleton loading={isLoading} width="30px" height="30px" radius="xs">
        {icon && (
          <Card.Illustration>
            <Icon name={icon} size="lg" />
          </Card.Illustration>
        )}
      </Skeleton>
      <Skeleton loading={isLoading} width="150px" height="30px" radius="lg" mt="300">
        {title && <Card.Heading>{title}</Card.Heading>}
      </Skeleton>
      <Skeleton loading={isLoading} radius="lg" width="100%" mt="200">
        {description && (
          <Card.Content>
            <Flex direction="column" gap="100">
              <Text>{description}</Text>
              {badge && (
                <Badge variant={badge.variant} style={{ marginRight: "auto" }} hideIcon>
                  <Icon name={badge.icon}>{badge.text}</Icon>
                </Badge>
              )}
            </Flex>
          </Card.Content>
        )}
      </Skeleton>
      <Skeleton loading={isLoading} radius="full" mt="200" ml="auto">
        {link && (
          <Card.Button aria-label="Proceed to page" asChild iconOnly>
            {isLinkExternal ? (
              <a href={link} target="_blank" rel="noopener noreferrer">
                <Icon name="arrow-right" />
              </a>
            ) : (
              <Link to={link}>
                <Icon name="arrow-right" />
              </Link>
            )}
          </Card.Button>
        )}
        {onClick && (
          <Card.Button aria-label="Handle click" iconOnly onClick={onClick}>
            <Icon name="arrow-right" />
          </Card.Button>
        )}
        {children}
      </Skeleton>
    </Card>
  );
}
