import styled from "styled-components";
import { colors } from "@telia/styleguide";

export const ExpandableCardTitle = styled.span`
  display: block;
  text-align: center;
  color: ${colors.corePurple700};
  font-size: 1rem;
  font-weight: 500;
`;
