import { colors } from "@telia/styleguide";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;
export const Label = styled.label`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
`;
export const Input = styled.input`
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  font-family: inherit;
  text-align: center;
  -moz-appearance: textfield;
  border: none;
  border-radius: 5px;
  line-height: 2rem;
  margin-inline: 0.5rem;
  min-width: 4ch;

  &::selection {
    background-color: ${colors.corePurple};
    color: white;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;
