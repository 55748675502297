import { useEffect } from "react";
import { Container, Heading, NoProducts, UpsellMobileCard, ContainerBaseProps } from "@telia-no-min-side/components";
import { uri, track } from "@telia-no-min-side/utils";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { FirstTimeCard } from "pages/home/useful-shortcuts/FirstTimeCard";

type Props = {
  isMobile: boolean;
  padding?: ContainerBaseProps["padding"];
  hideHeading?: boolean;
};

export function NoSubscriptionsFound({ isMobile, hideHeading, padding = true }: Props) {
  const { pushGenericTrackingEvent } = track.useEventTracking();

  useEffect(() => {
    if (!isMobile) {
      const trackEvent = {
        event: TRACK_EVENT.EVENT.CUSTOMER_NO_PROD,
        affiliation: TRACK_EVENT.AFFILIATION.TELIA_MIN_SIDE,
        ui_item_text: TRACK_EVENT.ITEM_TEXT.NOT_MBB_CUSTOMER,
        ui_item_action: TRACK_EVENT.ITEM_ACTION.VIEW,
        ui_item_context: TRACK_EVENT.ITEM_CONTEXT.NO_PRODUCT_ACTIVE,
        ui_item_type: TRACK_EVENT.ITEM_TYPE.INFO_MSG,
        ui_item_url: TRACK_EVENT.ITEM_URL.MBB,
      };
      return pushGenericTrackingEvent(trackEvent);
    }
    const trackEvent = {
      event: TRACK_EVENT.EVENT.CUSTOMER_NO_PROD,
      affiliation: TRACK_EVENT.AFFILIATION.TELIA_MIN_SIDE,
      ui_item_text: TRACK_EVENT.ITEM_TEXT.NOT_MOBILE_CUSTOMER,
      ui_item_action: TRACK_EVENT.ITEM_ACTION.VIEW,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.NO_PRODUCT_ACTIVE,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.INFO_MSG,
      ui_item_url: TRACK_EVENT.ITEM_URL.FORBRUK,
    };
    pushGenericTrackingEvent(trackEvent);
  }, [isMobile]);

  return isMobile ? (
    <Container flexDirection="column" padding={padding} gap="lg">
      {!hideHeading && (
        <Heading tag="h1" variant="title-200">
          Her kunne vi dessverre ikke finne noen produkter...
        </Heading>
      )}

      <Container flexDirection="column" gap="lg" maxWidth="xxs" padding="bottom">
        <FirstTimeCard />

        <UpsellMobileCard
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.NO_MOBILE_SUB,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.TELIA_SAMLET,
            ui_item_url: uri.openPages("/mobilabonnement/ubegrenset-data/?ts=true"),
            ui_item_text: TRACK_EVENT.ITEM_TEXT.BECOME_MOBILE_CUSTOMER,
          }}
        />
      </Container>
    </Container>
  ) : (
    <Container flexDirection="column" padding gap="lg">
      <Container flexDirection="column" gap="lg" maxWidth="xxs" padding="bottom">
        <NoProducts
          title="Ikke mobilt bredbånd-kunde ennå?"
          href={uri.openPages("/internett/mobilt-bredband/")}
          linkText={"Sjekk ut mobilt bredbånd"}
          description={
            "Ta med deg internett overalt! Med mobilt bredbånd får du raskt internett, der du er. Nå også med 5G!"
          }
        />
      </Container>
    </Container>
  );
}
