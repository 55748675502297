import { Accordion, Button, Container } from "@telia-no-min-side/components";
import { Alert } from "@telia/styleguide";
import { Product } from "@telia-no-min-side/utils/src/types/fixed";
import { HeaderWrapper, Heading } from "pages/internet/broadband/styles/style";
import { TeliaSkyDescription } from "./TeliaSkyDescription";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { getAccordionTrackingEvent, getBadgeInfo } from "pages/internet/broadband/utils";
import { useAdditionalServices } from "pages/internet/broadband/hooks/useAdditionalServices";

type Props = {
  product: Product;
};

export function TeliaSky({ product }: Props) {
  const { activationStatus, activateService } = useAdditionalServices(product);

  return (
    <Accordion.Item
      id={"TPN-Telia-Sky"}
      tracking={getAccordionTrackingEvent("Telia Sky")}
      lozengeRight={getBadgeInfo(product)}
      initialIsOpen={!product.status?.active}
      dataTrackingID="additional-services-telia-sky"
    >
      <Accordion.Trigger>
        <HeaderWrapper>
          <Heading>Telia Sky</Heading>
        </HeaderWrapper>
      </Accordion.Trigger>
      <Accordion.Content>
        <TeliaSkyDescription />

        {product.status?.active ? (
          <Container padding="top" floatContent="right">
            <Button
              variant="text-purple"
              size="sm"
              icon="arrow-right"
              iconPosition="after"
              onClick={(e) => {
                e.preventDefault();
                window.open(EXTERNAL_LINK.TELIA_SKY, "_blank");
              }}
            >
              Gå til Telia Sky
            </Button>
          </Container>
        ) : (
          <Container padding="top" floatContent="right">
            <Button variant="primary" size="sm" isLoading={activationStatus === "activating"} onClick={activateService}>
              Aktiver
            </Button>
          </Container>
        )}
        {activationStatus === "error" && (
          <Container padding="top">
            <Alert kind="negative">
              <p>En feil oppstod under aktivering, vennligst prøv igjen eller ta kontakt med kundeservice.</p>
            </Alert>
          </Container>
        )}
      </Accordion.Content>
    </Accordion.Item>
  );
}
