import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { POSTPONE_INVOICE_PAYMENT } from "../mutations";
import { ConfirmPostponeInvoiceModal } from "../components/ConfirmPostponeInvoiceModal";
import { track, format } from "@telia-no-min-side/utils";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { useToast } from "@telia-no-min-side/components";
import {
  INVOICE_POSTPONE_DAYS,
  INVOICE_POSTPONE_SUCCESS_MESSAGE,
  INVOICE_POSTPONE_GENERIC_ERROR_MESSAGE,
} from "util/constants/invoices";

type PostponeInvoiceProps = {
  modalOpen: boolean;
  dueDate: string;
  setIsModalOpen: (isOpen: boolean) => void;
  accountId: string;
  setNextPaymentDate: (newDate: string) => void;
};

export const PostponeInvoice = ({
  modalOpen,
  dueDate,
  setIsModalOpen,
  accountId,
  setNextPaymentDate,
}: PostponeInvoiceProps) => {
  const [postponeInvoicePayment, postponeInvoicePaymentResponse] = useMutation(POSTPONE_INVOICE_PAYMENT);
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { addToast } = useToast();
  const getNextDueDate = (currentDueDate: string): string =>
    dayjs(currentDueDate).add(INVOICE_POSTPONE_DAYS, "days").format(format.DATE_DISPLAY_FORMAT_FULL);

  const handlePostponeInvoice = () => {
    pushGenericTrackingEvent({
      ui_item_text: TRACK_EVENT.ITEM_TEXT.POSTPONE_INVOICE,
      ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.POSTPONE_INVOICE_MODAL,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.BUTTON,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
    });
    postponeInvoicePayment({
      variables: {
        accountId,
      },
      onCompleted: (response) => {
        if (response.deferInvoice?.success) {
          setNextPaymentDate(response?.deferInvoice?.nextPaymentDate);
          addToast({
            text: INVOICE_POSTPONE_SUCCESS_MESSAGE,
            variant: "success",
            dataTrackingId: "postpone-mobile-invoice-success",
          });
        } else {
          addToast({
            text: INVOICE_POSTPONE_GENERIC_ERROR_MESSAGE,
            variant: "error",
            dataTrackingId: "postpone-mobile-invoice-error-generic",
            eventErrorType: TRACK_EVENT.ITEM_TYPE.MOBILE_INVOICE_POSTPONE_ERROR,
          });
        }
        setIsModalOpen(false);
      },
      onError: () => {
        addToast({
          text: INVOICE_POSTPONE_GENERIC_ERROR_MESSAGE,
          variant: "error",
          dataTrackingId: "postpone-mobile-invoice-error-generic",
          eventErrorType: TRACK_EVENT.ITEM_TYPE.MOBILE_INVOICE_POSTPONE_ERROR,
        });
        setIsModalOpen(false);
      },
    });
  };

  return (
    <ConfirmPostponeInvoiceModal
      modalOpen={modalOpen}
      setIsModalOpen={setIsModalOpen}
      dueDate={getNextDueDate(dueDate)}
      handlePostponeInvoice={handlePostponeInvoice}
      isLoading={postponeInvoicePaymentResponse.loading}
    />
  );
};
