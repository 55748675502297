import { Button, Paragraph } from "@telia-no-min-side/components";
import { CustomerCareLink } from "src/components/customer-care-link";
import { uri } from "@telia-no-min-side/utils";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function ShowCustomerService() {
  return (
    <div>
      <Paragraph>Vennligst ta kontakt med vår kundeservice for å få hjelp.</Paragraph>
      <Paragraph>
        <CustomerCareLink trackingContext="flex" />
      </Paragraph>
      <Paragraph>
        <Button
          tag="a"
          href={uri.minSideFixed("/hjem")}
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FLEX,
            ui_item_text: TRACK_EVENT.ITEM_TEXT.HOME,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: uri.minSideFixed("/hjem"),
          }}
          isInternalNavigation
          icon="home"
        >
          Hjem
        </Button>
      </Paragraph>
    </div>
  );
}
