import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

type Device = {
  Device_Id: string;
  Serial: string;
  Device_Type: string;
  Ssid_24: string;
  Ssid_5: string;
};

type Client = {
  Connected_5_Minutes: number;
  Connected_24_Minutes: number;
  Mac: string;
  Name: string;
  IsConnected: boolean;
  Last_Connected_Interface: string;
  LanPort: string;
};

type EthernetPort = {
  Clients: [
    {
      Name: string;
      IsConnected: boolean;
      Connected_Minutes: number;
    },
  ];
  Port_Id: number;
  LanPort: string;
  Type: number;
};

export type NetworkStatistics = {
  status: string;
} & (
  | {
      success: true;
      result:
        | {
            LifeMoteApiData: {
              DurationMs: number;
              ApiResultText: string;
              Success: boolean;
              ExceptionMessage: string;
              LastSeenMax: number;
              Devices: Device[];
              Clients: Client[];
              Ethernet_Ports: EthernetPort[];
            };
          }
        | string;
    }
  | {
      success: false;
      result: string;
    }
);

export function useFetchNetworkStatistics() {
  const { data, mutate, error, isValidating } = useAxiosSWR<NetworkStatistics>(ICX_REQUEST.URL_NETWORK_STATISTICS);

  return {
    networkStatistics: data,
    isLoading: !error && !data,
    isError: !!error,
    isValidating: isValidating,
    error: error,
    mutateNetworkStatistics: mutate,
  };
}
