import { track, types } from "@telia-no-min-side/utils";
import { getProductShipping } from "pages/tv/utils/shipping";
import { v4 } from "uuid";

export function getSingleProductTracking(
  remoteControlProduct: types.fixed.ProductGroupSingleProductWithShipping,
  category: track.Category
): track.Transaction {
  const productShipping = remoteControlProduct?.shippingOptionDTOS;
  const shipping = getProductShipping(productShipping);
  const shippingPrice = shipping?.onceOffPrice ?? 0;
  const remoteControlProductPrice = remoteControlProduct.price.currentPrice.onceOff;
  const revenue = remoteControlProductPrice + shippingPrice;

  return {
    shippingPrice,
    transactionId: v4(),
    revenue: revenue,
    products: [
      ...(remoteControlProduct
        ? [
            {
              brand: "Telia",
              category,
              id: `${remoteControlProduct.productId}`,
              name: remoteControlProduct.name || "Unknown",
              price: remoteControlProductPrice,
              quantity: 1,
              financing: "Billing",
              acquisitionRetention: "Acquisition",
              newPortinUpsell: "New",
              delivery: "Levert til postkontoret",
            } satisfies track.TransactionProduct,
          ]
        : []),

      ...(shipping
        ? [
            {
              brand: "Telia",
              category: "Service/Delivery",
              id: "DELIVERY",
              name: shipping.description || "Frakt",
              price: shippingPrice,
              quantity: 1,
              financing: "Billing",
              delivery: "Posten",
            } as const,
          ]
        : []),
    ],
  };
}
