import { Container, Skeleton } from "@telia-no-min-side/components";
import { useQuery } from "@apollo/client";
import { ActiveVas } from "./components/ActiveVas";
import { BuyVas } from "./components/BuyVas";
import { Grid, Heading, TextSpacing } from "@telia/teddy";
import { ProfilePicker } from "./ProfilePicker";
import { useSelectAccount } from "hooks/useSelectAccount";
import { graphql } from "gql";
import { AdditionalProduct, GetVasProductsQueryQuery } from "gql/graphql";
import { Text } from "@telia/teddy";
import { config } from "util/config";
import { MOBILE_VAS, TELIA_SKY_PRODUCT_TYPE } from "util/constants/valueAddedServices";
import { ServiceNotification } from "components/alerts/ServiceNotification";

export type EditMode = false | "contactInformation" | "password";

export const MOBILE_VAS_PRODUCT_GROUPS = Object.values(MOBILE_VAS);

type VAS_PRODUCTS = (typeof MOBILE_VAS_PRODUCT_GROUPS)[number];
type GroupedProducts = Record<VAS_PRODUCTS, AdditionalProduct[]>;

function getProductsByGroup(data: GetVasProductsQueryQuery) {
  const initialGroupProducts: GroupedProducts = MOBILE_VAS_PRODUCT_GROUPS.reduce(
    (acc, group) => {
      acc[group] = [];
      return acc;
    },
    {} as Record<VAS_PRODUCTS, AdditionalProduct[]>
  );
  const groupProducts = initialGroupProducts;
  data.subscription?.additionalProducts?.forEach((product) => {
    if (!product.productGroup) return;
    const group = product.productGroup as VAS_PRODUCTS;
    groupProducts[group].push(product);
  });
  return groupProducts;
}

function findActiveProduct(products: AdditionalProduct[]) {
  return products.find((product) => product.presentation?.active);
}

const isIncludedProduct = (product: AdditionalProduct) =>
  product.presentation?.included || product.id === TELIA_SKY_PRODUCT_TYPE.FREE;
function findIncludedProduct(products: AdditionalProduct[], excludeId: Set<string>) {
  return products.find((product) => product.id && isIncludedProduct(product) && !excludeId.has(product.id));
}

function findOtherProduct(products: AdditionalProduct[], excludeId: Set<string>): AdditionalProduct {
  return (
    products.find(
      (product) =>
        !product.presentation?.active && !product.presentation?.included && product?.id && !excludeId.has(product?.id)
    ) || products[0]
  );
}

function getCategorizedProducts(groupedProducts: GroupedProducts | undefined) {
  const activeProducts: AdditionalProduct[] = [];
  const includedProducts: AdditionalProduct[] = [];
  const otherProducts: AdditionalProduct[] = [];
  const excludedIds = new Set<string>();
  if (!groupedProducts) {
    return { activeProducts: [], includedProducts: [], otherProducts: [] };
  }
  Object.values(groupedProducts).forEach((products) => {
    const activeProduct = findActiveProduct(products);
    if (activeProduct) {
      activeProducts.push(activeProduct);
      activeProduct.id && excludedIds.add(activeProduct.id);
    }

    const includedProduct = findIncludedProduct(products, excludedIds);
    if (includedProduct) {
      includedProducts.push(includedProduct);
      includedProduct.id && excludedIds.add(includedProduct.id);
    }

    if (!activeProduct && !includedProduct) {
      const otherProduct = findOtherProduct(products, excludedIds);
      if (otherProduct) {
        otherProducts.push(otherProduct);
        otherProduct.id && excludedIds.add(otherProduct.id);
      }
    }
  });

  return { activeProducts, includedProducts, otherProducts };
}

export const GET_VAS = graphql(`
  query getVasProductsQuery($productGroups: [String!], $phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      productUser {
        emailAddress
        firstName
      }
      additionalProducts(productGroups: $productGroups) {
        id
        productGroup
        code
        shortName
        shortDescription
        weight
        presentation {
          active
          included
        }
        price {
          discountDuration
          price
          priceWithoutDiscount
        }
      }
    }
  }
`);

export function ValueAddedServicesPage() {
  const { selectedMobileAccount } = useSelectAccount();

  const additionalServices = useQuery(GET_VAS, {
    variables: {
      productGroups: [...MOBILE_VAS_PRODUCT_GROUPS],
      phoneNumber: selectedMobileAccount?.phoneNumber || "",
    },
    skip: !selectedMobileAccount?.phoneNumber,
  });
  const productGroups = additionalServices.data && getProductsByGroup(additionalServices.data);
  const productCategories = getCategorizedProducts(productGroups);
  const activeProducts = [...productCategories.activeProducts, ...productCategories.includedProducts];
  const buyableProducts = [...productCategories.otherProducts];

  return (
    <Container showGoBackButton padding>
      <TextSpacing>
        <Heading as="h1" variant="title-300" pb="200" data-tracking-id="vas-heading">
          Dine tjenester
        </Heading>
        {config.showInternalServiceWarning_VAS && (
          <ServiceNotification
            type="SERVICE_WARNING"
            title="Tekniske problemer"
            message="Grunnet tekniske problemer er aktivering og deaktivering av Max/Netflix/Prime ikke mulig i en periode. Beklager ulempene dette medfører."
          />
        )}
        <ProfilePicker />
        <Grid gap="300" columns={`repeat(auto-fill, minmax(220px, 1fr))`} pb="300" pt="400">
          {(additionalServices.loading && !additionalServices.data) ||
          (selectedMobileAccount?.isLoading && !selectedMobileAccount.allAccounts) ? (
            <>
              <Skeleton variant="rectangular" height="10rem" width="auto" />
              <Skeleton variant="rectangular" height="10rem" width="auto" />
              <Skeleton variant="rectangular" height="10rem" width="auto" />
            </>
          ) : (
            <>
              {activeProducts.length ? <ActiveVas activeProducts={activeProducts} /> : null}
              {buyableProducts.length ? <BuyVas products={buyableProducts} /> : null}
              {!activeProducts.length && !buyableProducts.length ? (
                <Text data-tracking-id="additional-services-empty-list">
                  Du har ingen tjenester tilgjengelig på denne kontoen
                </Text>
              ) : null}
            </>
          )}
        </Grid>
      </TextSpacing>
    </Container>
  );
}
