import { Skeleton } from "@telia-no-min-side/components";
import { StyledCard } from "./style";

export function InvoiceCardSkeleton() {
  return (
    <StyledCard isPaid>
      <Skeleton variant="circular" width="2ch" height="2ch" />
      <Skeleton width="50%" />
      <Skeleton width="5ch" />
    </StyledCard>
  );
}
