import { Maybe, OfferingPriceDetails } from "../../gql/graphql";
import { findAmount } from "./findAmount";
import { findSpeedDownload } from "./findSpeedDownload";

export function getUnit(offeringPrices: Maybe<Maybe<OfferingPriceDetails>[]> | undefined): string {
  const dataIncluded = offeringPrices?.find(findAmount);
  const speedDownload = offeringPrices?.find(findSpeedDownload);
  const subscription = dataIncluded || speedDownload;
  if (!subscription) return "";

  return subscription.monetaryUnit || "";
}
