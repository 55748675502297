import { FlexAgreementCard } from "./components/FlexAgreementCard";

type Props = {
  flexCustomerIds: string[];
};

export function FixedAgreements({ flexCustomerIds }: Props) {
  return flexCustomerIds.map((customerId) => (
    <FlexAgreementCard key={`flex-current-product-${customerId}`} customerId={customerId} />
  ));
}
