import { useEffect, useState } from "react";
import { getSubscriptionByAccountId } from "util/mobile/accounts";
import { useMobileAccount } from "hooks/useMobileAccount";
import { Notification, Box, Button, Icon, Heading, breakpoint } from "@telia/teddy";
import { useProductUser } from "../hooks/useProductUser";
import { MobileProfilePicker } from "components/profile-pickers/MobileProfilePicker";
import { ProfileInfo } from "./ProfileInfo";
import { ProfileForm, FormSubmitStatus } from "./ProfileForm";
import { ChangePassword } from "./ChangePassword";
import { MobileSubscriptionSuspended } from "pages/mobile/mobile-subscription-suspended";
import { MobileSubscriptionReserved } from "pages/mobile/mobile-subscription-reserved";
import { SubscriptionStatus } from "gql/graphql";
import { ProfileView } from "../types";
import { SkeletonForm } from "../common/SkeletonForm";

export function MobileProfilePage() {
  const [formSubmitStatus, setFormSubmitStatus] = useState<FormSubmitStatus | undefined>();
  const [profileView, setProfileView] = useState<ProfileView>("viewInfo");
  const { allAccounts, accountId, phoneNumber, isLoading, error, refetch, isRefetching } = useMobileAccount();
  const { getProductUser, productUserResponse } = useProductUser();

  // Subcription status related logic
  const selectedAccountSubscriptions = getSubscriptionByAccountId(allAccounts, accountId);
  const selectedSubscription = selectedAccountSubscriptions.find(
    (sub) => sub?.phoneNumber?.localNumber === phoneNumber
  );
  const subscriptionStatus = selectedSubscription?.subscriptionStatus;
  const isSubscriptionReserved = subscriptionStatus === SubscriptionStatus.Reserved;
  const isSubscriptionSuspended =
    subscriptionStatus === SubscriptionStatus.Suspended && !!selectedSubscription?.productUser;

  const handleProfileViewChange = (profileView?: ProfileView) => {
    setFormSubmitStatus(undefined);
    setProfileView(profileView || "viewInfo");
  };

  // On phoneNumber or accountId change get selected product user and hide existing alerts from previous from submissions
  useEffect(() => {
    if (phoneNumber) {
      getProductUser(phoneNumber);
    }
    handleProfileViewChange();
  }, [phoneNumber, accountId]);

  // Reset to view after form submit
  useEffect(() => {
    if (formSubmitStatus) {
      setProfileView("viewInfo");
    }
  }, [formSubmitStatus]);

  const SectionTitle = ({ title }: { title: string }) => (
    <Box maxWidth="sm" pt="200">
      <Heading as="h2" variant="title-200">
        {title}
      </Heading>
    </Box>
  );

  if (isLoading || isRefetching) return <SkeletonForm />;

  if (error || !allAccounts)
    return (
      <Box maxWidth={breakpoint.teddyBreakpointMd} p="page-padding-sm">
        <Notification variant="error" mb="gutter-lg">
          <Notification.Heading as="h3">
            <Notification.Icon>Noe gikk galt!</Notification.Icon>
          </Notification.Heading>
          <Notification.Text>En feil oppstod. Vennligst prøv igjen.</Notification.Text>
        </Notification>
        <Button onClick={() => refetch()}>
          <Icon name="sync" />
          Last inn siden på nytt
        </Button>
      </Box>
    );

  return (
    <>
      <MobileProfilePicker />
      {selectedSubscription && (
        <>
          {isSubscriptionSuspended && <MobileSubscriptionSuspended subscription={selectedSubscription} />}
          {isSubscriptionReserved && <MobileSubscriptionReserved />}
          {!isSubscriptionSuspended && !isSubscriptionReserved && (
            <div data-tracking-id="profile-page-overview-minside">
              {profileView === "viewInfo" && (
                <>
                  <SectionTitle title="Din kontaktinformasjon" />
                  <ProfileInfo
                    profileData={productUserResponse.data}
                    isLoading={productUserResponse.loading}
                    onUpdateProfileClick={handleProfileViewChange}
                  />
                </>
              )}
              {profileView === "changeInfo" && (
                <>
                  <SectionTitle title="Endre kontaktinformasjon" />
                  <ProfileForm
                    profileData={productUserResponse.data}
                    onCancelUpdateClick={setProfileView}
                    onFormSumbitComplete={setFormSubmitStatus}
                  />
                </>
              )}
              {profileView === "changePassword" && (
                <>
                  <SectionTitle title="Endre passord" />
                  <ChangePassword phoneNumber={phoneNumber} loadInfoPage={() => setProfileView("viewInfo")} />
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
