import { Alert } from "@telia-no-min-side/components";
import { Subscription } from "../types";
import { isOlderThan } from "@telia-no-min-side/utils";
import { UserRole } from "gql/graphql";

type Props = {
  subscription: Subscription;
};

export function InsufficientAgeWarning({ subscription }: Props) {
  const isLegalOwner = subscription?.roles.includes(UserRole.LegalOwner);

  if (isLegalOwner) {
    return null;
  }
  const birthDate = subscription.productUser?.birthDate || "";
  const isOver18 = isOlderThan(18, birthDate);

  if (isOver18) {
    return null;
  }

  return (
    <Alert
      isOpen
      kind="info"
      icon="info-filled"
      title="Du kan ikke endre abonnementet"
      canClose={false}
      dataTrackingId="change-subscription-alert-under-18"
    >
      Du må være minst 18 år for å endre abonnementet selv, men eier av abonnementet kan endre det ved å logge inn med
      sin egen bruker på Min Side.
    </Alert>
  );
}
