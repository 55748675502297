import { colors } from "@telia/styleguide";
import styled from "styled-components";
import { skeletonLine } from "../Skeleton/style";

export const InformationSectionContainer = styled.div`
  display: flex;
  margin: 0 -1rem;
  width: 100%;
  flex-wrap: wrap;
`;
export const InformationItemContainer = styled.div<{ $fitContent?: boolean }>`
  margin: 0.5rem 1rem;
  width: ${(props) => (props.$fitContent ? "fit-content" : "normal")};
`;

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.grey600};
  margin: 0;
`;

export const Text = styled.p`
  font-size: 1.125rem;
  margin: 0;
`;

export const ActonLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > * {
    margin: 5px 0;
  }
`;

export const SkeletonInformationItemContainer = styled(InformationItemContainer)`
  background-color: ${colors.grey100};
`;

export const SkeletonTitle = styled(Title)`
  ${skeletonLine}
  width: 100px;
`;

export const SkeletonText = styled(Text)`
  ${skeletonLine}
`;
