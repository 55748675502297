import { useMutation, useQuery } from "@apollo/client";
import { graphql } from "gql";
import { useCallback } from "react";

const QUERY_SERVER_CACHE = graphql(`
  query cacheValue($cacheKey: String!) {
    serverCache(input: { cacheKey: $cacheKey }) {
      cacheKey
      value
    }
  }
`);

const MUTATION_SERVER_CACHE = graphql(`
  mutation mutateCacheValue($cacheKey: String!, $value: String!, $lifetimeInSeconds: Int!) {
    serverCache(input: { cacheKey: $cacheKey, value: $value, lifetimeInSeconds: $lifetimeInSeconds }) {
      cacheKey
      value
    }
  }
`);
type UseServerCacheOptions = {
  lifetimeInSeconds: number;
  defaultValue?: string;
};

export function useServerCache(
  cacheKey: string,
  { lifetimeInSeconds: defaultLifetimeInSeconds, defaultValue }: UseServerCacheOptions
) {
  const { data } = useQuery(QUERY_SERVER_CACHE, {
    variables: { cacheKey },
  });

  const [mutateCache] = useMutation(MUTATION_SERVER_CACHE);

  const value = data?.serverCache?.value ?? defaultValue;

  const setValue = useCallback(
    (newValue: string, lifetimeInSeconds?: number) => {
      mutateCache({
        variables: {
          cacheKey,
          value: newValue,
          lifetimeInSeconds: lifetimeInSeconds || defaultLifetimeInSeconds,
        },
        update: (cache) => {
          cache.writeQuery({
            query: QUERY_SERVER_CACHE,
            variables: { cacheKey },
            data: {
              serverCache: {
                cacheKey,
                value: newValue,
              },
            },
          });
        },
      });
    },
    [cacheKey, defaultLifetimeInSeconds, mutateCache]
  );

  return { value, setValue };
}
