import { Link, Paragraph } from "@telia-no-min-side/components";
import { FragmentType, getFragmentData, graphql } from "gql";
import { MobileFinanceCard } from "./MobileFinanceCard";

const accountFinanceOverviewFragment = graphql(`
  fragment AccountFinanceOverview on AccountData {
    collectionAgency {
      caseNumber
    }
    id
    selectedInvoiceDistributionType: invoiceDistribution
    paymentBalance {
      ...PaymentBalance
    }
    paymentMethod {
      name
    }
    invoiceCardPaymentStatus
    invoices(take: 1) {
      ...Invoice
    }
  }
`);

export type FinanceMobileProps = {
  hasMultipleAccounts: boolean;
  accountFinanceOverview: FragmentType<typeof accountFinanceOverviewFragment>;
};

export function FinanceMobile({ accountFinanceOverview, hasMultipleAccounts }: FinanceMobileProps) {
  const { paymentBalance, collectionAgency, id, invoices, paymentMethod, selectedInvoiceDistributionType } =
    getFragmentData(accountFinanceOverviewFragment, accountFinanceOverview);

  const lastInvoice = invoices?.length ? invoices[0] : null;

  if (!paymentBalance) return null;

  if (collectionAgency?.caseNumber?.length) {
    return (
      <MobileFinanceCard
        hasMultipleAccounts={hasMultipleAccounts}
        accountId={id}
        paymentBalance={paymentBalance}
        lastInvoice={lastInvoice}
        paymentMethodName={paymentMethod?.name}
        invoiceDistributionType={selectedInvoiceDistributionType}
      >
        <Paragraph>
          Dette beløpet, eller deler av det, er flyttet over til vårt inkassobyrå Sergel, og kan kun betales hos dem.
          Telefonnummer: <Link href="tel:+4733456031">+47 33 45 60 31</Link>
        </Paragraph>
        <Paragraph>
          Ditt saksnummer er: <em>{collectionAgency?.caseNumber}</em>
        </Paragraph>
      </MobileFinanceCard>
    );
  }
  return (
    <MobileFinanceCard
      accountId={id}
      paymentBalance={paymentBalance}
      hasMultipleAccounts={hasMultipleAccounts}
      lastInvoice={lastInvoice}
      paymentMethodName={paymentMethod?.name}
      invoiceDistributionType={selectedInvoiceDistributionType}
    />
  );
}
