import { Container, TrackClickEvent } from "@telia-no-min-side/components";
import { Button, Heading, Icon, Text } from "@telia/teddy";
import { Link } from "react-router-dom";
import { L } from "util/links";
import { URL_INSURANCE_CLAIM_DAMAGE } from "util/constants/agreements";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

export function OtherDamagePlus() {
  return (
    <>
      <Container flexDirection="column" alignItems="start" gap="lg">
        <Heading as="h4" variant="title-100">
          Forsikringen din dekker skader utenom skjermen.
        </Heading>
        <Text>
          Meld en forsikringssak. Når den er godkjent kan du bestille ny telefon. Når den har blitt godkjent (tar ca 10
          minutter) kan du bestille en ny telefon.
        </Text>
        <Text>Du trenger ikke gjøre noe med telefonen du har før du får en retureske fra oss.</Text>
      </Container>
      <Container flexDirection="column" alignItems="start" gap>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_other-damage-plus",
            ui_item_text: "Meld forsikringssak",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: L.TeliaNoUrl.href + URL_INSURANCE_CLAIM_DAMAGE,
          }}
        >
          <Button asChild variant="primary">
            <a href={L.TeliaNoUrl.href + URL_INSURANCE_CLAIM_DAMAGE}>
              Meld forsikringssak
              <Icon name="arrow-right" />
            </a>
          </Button>
        </TrackClickEvent>
        <TrackClickEvent
          asChild
          event={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.BUTTON_CLICK_ON_CANCEL,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT_SWTICH.SELF_ASSESSMENT + "_other-damage-plus",
            ui_item_text: "Tilbake til forrige steg",
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: "..",
          }}
        >
          <Button asChild variant="text">
            <Link to="..">
              <Icon name="arrow-left" />
              Tilbake til forrige steg
            </Link>
          </Button>
        </TrackClickEvent>
      </Container>
    </>
  );
}
