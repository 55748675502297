import { Flex } from "@telia/teddy";
import { Breadcrumb } from "components/Breadcrumb";
import { SubscriptionCard, Notification, AdminSection, ExploreSection } from "./components";

type Props = {
  name: string | null | undefined;
  price: number | undefined;
};

export function NotSupportedSubscription({ name, price }: Props) {
  return (
    <Flex direction="column" width="100%" maxWidth="720px">
      <Flex direction="column" gap="gutter-xl">
        <Breadcrumb />

        <SubscriptionCard name={name} price={price} />
        <Notification />

        <AdminSection />
        <ExploreSection />
      </Flex>
    </Flex>
  );
}
