import { uri } from "@telia-no-min-side/utils";
import { Card, Text, Icon } from "@telia/teddy";
import { config } from "src/util/config";
import { Link } from "react-router-dom";

export function MoveCard() {
  const { showMduForm } = config;

  return (
    <Card variant="white" shadow layout="default" bordered width="100%" maxWidth="400px">
      <Card.Heading as="h3" variant="title-100">
        Skal du flytte?
      </Card.Heading>

      <Card.Content>
        <Text variant="additional-100">
          Vi hjelper deg med flytting av TV- og bredbåndsabonnementet, slik at du kommer raskt på nett i ny bolig.
        </Text>
      </Card.Content>

      <Card.Footer height="100%" align="end">
        <Card.Button asChild variant="secondary">
          <Link to={showMduForm ? "/minside/flytting" : uri.openPages("/kundeservice/flytting")}>
            Meld flytting
            <Icon name="arrow-right" size="sm" />
          </Link>
        </Card.Button>
      </Card.Footer>
    </Card>
  );
}
