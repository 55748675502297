import { graphql } from "src/gql";

export const GET_SUBSCRIPTION_SETTINGS = graphql(`
  query getSubscriptionSettings(
    $phoneNumber: String!
    $mainProductGroup: AdditionalProductMainGroup
    $manualProducts: [String!]
  ) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      agreementType
      billingType
      roles
      groupedAdditionalProducts(mainProductGroup: $mainProductGroup, manualProducts: $manualProducts) {
        ...GroupedAdditionalProducts
      }
      publishLevelCode
      agreementType
      legalOwner {
        individual {
          firstName
          surname
          birthDate
          emailAddress
          telephoneNumber {
            localNumber
          }
          address {
            urbanAddress {
              streetName
              streetNr
              streetNrSuffix
              city
              postcode
              careOfName
              floorNumber
              doorNumber
              locality
            }
            poboxAddress {
              city
              postcode
              boxName
              boxNr
            }
          }
        }
      }
      productUser {
        firstName
        surname
        birthDate
        emailAddress
        telephoneNumber {
          localNumber
        }
        address {
          urbanAddress {
            streetName
            streetNr
            streetNrSuffix
            city
            postcode
            careOfName
            floorNumber
            doorNumber
            locality
          }
          poboxAddress {
            city
            postcode
            boxName
            boxNr
          }
        }
      }
      userOffering {
        pricePlan
        shortName
      }
      directoryListings {
        key
        longDescription
        shortDescription
      }
    }
  }
`);
