import { GaugeMeter, Skeleton } from "@telia-no-min-side/components";
import { BaseCard, InnerCardGrid, SpanThreeRows } from "./style";

type Props = {
  isGaugeMeter?: boolean;
  dataTrackingID?: string;
};

export function LoadingSubscriptionCard({ isGaugeMeter = false, dataTrackingID }: Props) {
  return (
    <BaseCard dataTrackingID={dataTrackingID}>
      <InnerCardGrid>
        <SpanThreeRows>
          {isGaugeMeter ? (
            <GaugeMeter isLoading strokeWidth={5} />
          ) : (
            <Skeleton variant="rectangular" height="100%" width="100%" />
          )}
        </SpanThreeRows>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </InnerCardGrid>
      <Skeleton />
    </BaseCard>
  );
}
