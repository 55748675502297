import { Container, Heading, Link, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { useFetchBroadbandSubscription, useFetchTvSubscription } from "src/api/fixed";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function FlexError() {
  const tvSubscription = useFetchTvSubscription();
  const hasTvSubscription = tvSubscription.data?.products && tvSubscription.data.products.length > 0;
  const bbSubscription = useFetchBroadbandSubscription();
  const hasBbSubscription = bbSubscription.data?.products && bbSubscription.data.products.length > 0;

  return (
    <Container padding showGoBackButton>
      <Heading tag="h2" variant="title-300">
        Din Flex-avtale
      </Heading>
      <Paragraph>Dessverre oppstod det en feil under innlasting av Flex.</Paragraph>
      {hasBbSubscription && (
        <Paragraph>
          <Link href={uri.purchase(ICX_REQUEST.URL_CHANGE_BROADBAND)}>Endre bredbåndshastighet</Link>
        </Paragraph>
      )}
      {hasTvSubscription && (
        <Paragraph>
          <Link href={uri.purchase(ICX_REQUEST.URL_CHANNEL_POINTS_CHANGE)}>Endre TV-poeng</Link>
        </Paragraph>
      )}
    </Container>
  );
}
