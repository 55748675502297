import React from "react";
import { IconDefinition, MoreIcon } from "@telia/styleguide";
import { LinkProps } from "../Link";
import { Backdrop } from "../Backdrop";
import { ActionMenuPoint } from "./ActionMenuPoint";
import { ActionMenuButtonStyled, ActionMenuContainer, ActionPoints } from "./style";
import { types } from "@telia-no-min-side/utils";

export type ActionPoint = {
  icon: IconDefinition;
  styling?: "negative";
  borderTop?: boolean;
  text: string;
  type?: types.fixed.Action["type"];
} & (
  | ({
      kind: "link";
    } & Pick<LinkProps, "isInternalNavigation" | "href" | "returnUrl">)
  | {
      kind: "button";
      onClick: () => void;
    }
);

export type ActionMenuButtonProps = {
  actionList?: ActionPoint[];
  showActions: boolean;
  className?: string;
  dataTrackingID?: string;
  toggleShowActions: () => void;
};

/**
 * @deprecated Use Teddy components instead
 */
export function ActionMenuButton(props: ActionMenuButtonProps) {
  const { actionList, showActions, toggleShowActions, className, dataTrackingID } = props;
  return (
    <ActionMenuContainer className={className} data-tracking-id={dataTrackingID}>
      {actionList && (
        <>
          <ActionMenuButtonStyled active={showActions} onClick={toggleShowActions} title="Valg" aria-label="Valg">
            <MoreIcon />
          </ActionMenuButtonStyled>
          {showActions && (
            <>
              <ActionPoints>
                {actionList.map((actionPoint, index) => (
                  <ActionMenuPoint
                    key={`action-point-${actionPoint.text}-${index}`}
                    toggleShowActions={toggleShowActions}
                    {...actionPoint}
                  />
                ))}
              </ActionPoints>
              <Backdrop isVisible color="transparent" onClick={toggleShowActions} />
            </>
          )}
        </>
      )}
    </ActionMenuContainer>
  );
}
