import { Button, Card, Container, Heading, Icon, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import dayjs from "dayjs";

type Props = {
  date?: string;
};

export function MoveOutRegistrationSuccess({ date }: Props) {
  const displayDate = date ? dayjs(date).format("DD. MMMM YYYY") : null;

  return (
    <Card padding="lg">
      <Container flexDirection="column" gap>
        <Container flexDirection="row" gap alignItems="center" padding="bottom">
          <Icon icon="key" />
          <Heading tag="h3" variant="title-75">
            <span>{`Du har meldt flytting ${displayDate}`}</span>
          </Heading>
        </Container>

        <Paragraph variant="paragraph-100">
          Gratulerer med ny bolig! Vi håper Telia får være med på flyttelasset.
        </Paragraph>
        <Paragraph variant="paragraph-100">Sjekk hvilke Telia-produkter du kan få på din nye adresse:</Paragraph>

        <Container flexDirection="column" gap="lg">
          <Button
            variant="primary"
            icon="arrow-right"
            iconPosition="after"
            tag="a"
            target="_blank"
            href={uri.openPages("/internett/adressesok")}
          >
            Gå til Adressesøk
          </Button>
          <Button variant="secondary" tag="a" href={uri.minSideFixed("/hjem")} isInternalNavigation icon="home">
            Tilbake til Hjem
          </Button>
        </Container>
      </Container>
    </Card>
  );
}
