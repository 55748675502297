import React from "react";
import { Link } from "@telia/styleguide";
import { LinkText, StyledLink } from "./style";

export function BusinessLinks() {
  return (
    <>
      <Link type="bubble-link" href="https://minbedrift.telia.no/" icon="mobile" inverted>
        <LinkText>Min Bedrift</LinkText>
      </Link>
      <Link type="bubble-link" href="https://minportal.telia.no/" icon="wireless" inverted>
        <LinkText>Min Portal</LinkText>
      </Link>
      <Link type="bubble-link" href="https://sb.telia.no/login" icon="product-bedriftsnett" inverted>
        <LinkText>Bedriftsnett</LinkText>
      </Link>

      <StyledLink href="https://www.telia.no/bedrift/info/" icon="list-view" iconPosition="before">
        <LinkText>Andre portaler</LinkText>
      </StyledLink>
    </>
  );
}
