import React, { createContext, useContext } from "react";
import { GenericEvent } from "./types";

type Props = {
  children: React.ReactNode;
  commonItems:
    | {
        customer_type?: string;
        user_type?: string;
      }
    | undefined;
};

type PromoEvent = {
  id: string;
  name: string;
  creative: string;
  position: string;
  destinationUrl?: string;
};
type PushEvent = <T extends object>(event: T | undefined) => void;

type PushEvents = <T extends object>(events: T[]) => void;

type PushGenericEvent = (event: GenericEvent) => void;

type pushEcommercePromoEvent = (promotion: PromoEvent, eventType: "promoView" | "promoClick") => void;

type EventTrackingContextType = {
  pushTrackingEvent: PushEvent;
  pushTrackingEvents: PushEvents;
  pushGenericTrackingEvent: PushGenericEvent;
  pushEcommercePromoTrackingEvent: pushEcommercePromoEvent;
};

const pushEventsToDataLayer: PushEvent = (event) => {
  if (!window.dataLayer) return;
  window.dataLayer.push(event);
};

export const EventTrackingContext = createContext<EventTrackingContextType | undefined>(undefined);

export function EventTrackingProvider({ children, commonItems }: Props) {
  // Push single event
  const pushTrackingEvent: PushEvent = (event) => {
    // Check if event object exist
    if (!event) return;
    // push to dataLayer
    pushEventsToDataLayer({
      ...commonItems,
      ...event,
    });
  };

  // Push multiple events
  const pushTrackingEvents: PushEvents = (events) => {
    // Check if events array exist and is not empty
    if (!events) return;
    events.forEach((e) =>
      // push to dataLayer
      pushEventsToDataLayer({
        ...commonItems,
        ...e,
      })
    );
  };

  // Push Generic UI events (event: "min_side_ui_event")
  const pushGenericTrackingEvent: PushGenericEvent = (event) => {
    // Check if event object exist
    if (!event) return;
    // Set Generic values
    const genericValues = {
      event: "min_side_ui_event",
      affiliation: "Telia Min Side",
    };
    // push to dataLayer
    pushEventsToDataLayer({
      ...commonItems,
      ...genericValues,
      ...event,
    });
  };

  const pushEcommercePromoTrackingEvent: pushEcommercePromoEvent = (promotion, eventType) => {
    pushEventsToDataLayer({ ecommerce: null }); // Clear the previous ecommerce object (https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item-gtm)
    pushEventsToDataLayer({
      ...commonItems,
      event: eventType,
      ecommerce: {
        [eventType]: {
          promotions: [promotion],
        },
      },
    });
  };

  return (
    <EventTrackingContext.Provider
      value={{ pushTrackingEvent, pushTrackingEvents, pushGenericTrackingEvent, pushEcommercePromoTrackingEvent }}
    >
      {children}
    </EventTrackingContext.Provider>
  );
}

export function useEventTracking(): EventTrackingContextType {
  const context = useContext(EventTrackingContext);
  if (!context) {
    // This should not throw error as it's just tracking event, not a core functionality
    // Will just log the error for debugging
    return {
      pushTrackingEvent: () => {
        console.log("pushTrackingEvent Error: useEventTracking can be used only in EventTrackingContext");
      },
      pushTrackingEvents: () => {
        console.log("pushTrackingEvents Error: useEventTracking can be used only in EventTrackingContext");
      },
      pushGenericTrackingEvent: () => {
        console.log("pushGenericTrackingEvent Error: useEventTracking can be used only in EventTrackingContext");
      },
      pushEcommercePromoTrackingEvent: () => {
        console.log("pushEcommercePromoEvent Error: useEventTracking can be used only in EventTrackingContext");
      },
    };
  }
  return context;
}
