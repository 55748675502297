import { Accordion, Link, Paragraph } from "@telia-no-min-side/components";
import { StyledHeading } from "../style";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function PaidEmployerAccordion() {
  return (
    <Accordion.Item>
      <Accordion.Trigger>
        <StyledHeading>Hva må jeg gjøre hvis fakturaen skal betales av arbeidsgiver?</StyledHeading>
      </Accordion.Trigger>
      <Accordion.Content>
        <Paragraph>
          Dersom arbeidsgiver skal betale hele eller deler av fakturaen din, trenger vi følgende informasjon tilsendt
          skriftlig:
        </Paragraph>
        <ul>
          <li>Ditt navn og kundenummer</li>
          <li>Bedriftens navn og organisasjonsnummer</li>
          <li>Din leders navn og tittel, samt dokumentasjon på din stilling i firmaet</li>
          <li>Signert aksept fra arbeidsgiver med informasjon om hvilke produkter de skal faktureres for</li>
          <li>
            Adressen fakturaen skal sendes til, samt ønsket syklus for faktureringen (månedlig, kvartalsvis eller årlig)
          </li>
        </ul>
        <Paragraph>
          <Link href={EXTERNAL_LINK.CUSTOMER_SERVICE_CONTACT_US} target="_blank" variant="standalone">
            Ta kontakt med kundesenteret vårt så hjelper vi deg med dette så raskt som mulig.
          </Link>
        </Paragraph>
      </Accordion.Content>
    </Accordion.Item>
  );
}
