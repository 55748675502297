import { Heading } from "@telia-no-min-side/components";
import styled from "styled-components";

export const StyledHeading = styled(Heading)`
  margin: 0 0 5px;
`;

export const StyledRadioButtonListWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledAccordionButtons = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  gap: 0.5rem;
`;
