import { useQuery } from "@apollo/client";
import { Button, Container, Skeleton, useToast } from "@telia-no-min-side/components";
import { format, getCookieValue, uri } from "@telia-no-min-side/utils";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { InvoicePaymentStatus } from "src/gql/graphql";
import { config } from "src/util/config";
import { downloadFile } from "src/util/downloadfile";
import { DetailsInfoHero } from "../../payment-info/DetailsInfoHero";
import { GET_INVOICE_BY_ACCOUNT_QUERY } from "../../queries";
import { ButtonWrapper, DetailsWrapper, ReceiptPaper } from "../../styles";
import { INVOICE_PAGE_SIZE } from "../MobileInvoiceComponent";
import { PostponeInvoice } from "../PostponeInvoice";
import { DetailsElementListMobile } from "./DetailsElementListMobile";
import { InvoiceSelectorMobile } from "./InvoiceSelectorMobile";
import { COOKIE } from "util/constants/cookies";
import { graphql } from "gql";
import { Flex } from "@telia/teddy";

export type InvoiceDetailsParams = {
  id?: string;
};

type InvoiceDetailsMobileProps = {
  accountId: string;
};

const ALL_ACCOUNT_INVOICES = graphql(`
  query AllAccountInvoices($accountId: String!, $pageSize: Int) {
    account(accountId: $accountId) {
      invoices(take: $pageSize) {
        billingDate
        invoiceNumber
        paid
        billingDate
        amount {
          value
          formatted
          valueWithVat
          valueWithoutVat
        }
        period {
          startDate
          endDate
        }
        pdfUrl
      }
    }
  }
`);

export const InvoiceDetailsMobile = ({ accountId }: InvoiceDetailsMobileProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [nextPaymentDate, setNextPaymentDate] = useState<string>("");
  const [openIds, setOpenIds] = useState<string[]>([]);
  const [isAllOpen, setIsAllOpen] = useState(false);
  const [isDownloadingInvoice, setIsDownloadingInvoice] = useState(false);

  const params = useParams<InvoiceDetailsParams>();
  const invoiceId = params.id;
  const { addToast } = useToast();

  const accountInvoiceQuery = useQuery(GET_INVOICE_BY_ACCOUNT_QUERY, {
    variables: {
      accountId,
      invoiceId: invoiceId || "",
    },
  });
  const allInvoices = useQuery(ALL_ACCOUNT_INVOICES, {
    variables: {
      accountId,
      pageSize: INVOICE_PAGE_SIZE,
    },
  });

  const accountInvoice = accountInvoiceQuery.data?.account;
  const accountInvoiceDetails = accountInvoice?.invoice;
  const delayedPaymentArrangementDate = accountInvoice?.paymentArrangement?.[0]?.expectedPaymentDate;
  const paymentDate = nextPaymentDate || delayedPaymentArrangementDate || accountInvoice?.paymentBalance?.dueDate;
  const isPaymentInProgress =
    accountInvoice?.invoiceCardPaymentStatus && accountInvoice.invoiceCardPaymentStatus !== InvoicePaymentStatus.None;

  async function downloadInvoice() {
    setIsDownloadingInvoice(true);
    const ctsession = getCookieValue(COOKIE.CT_SESSION);
    const url = `${config.channelApiRestUrl}/documents?type=INVOICE&accountId=${accountId}&invoiceId=${invoiceId}`;
    try {
      await downloadFile({
        url,
        filename: `faktura-${invoiceId}.pdf`,
        config: {
          headers: {
            ctsession: ctsession || "",
          },
        },
      });
    } catch {
      addToast({
        text: "Kunne ikke laste ned faktura",
        variant: "error",
      });
    } finally {
      setIsDownloadingInvoice(false);
    }
  }

  return (
    <Container padding="bottom" goBackTo={uri.minSideFixed("/faktura")}>
      <DetailsInfoHero
        invoiceDate={dayjs(accountInvoiceDetails?.billingDate).format("MMMM YYYY")}
        dueDate={dayjs(accountInvoiceDetails?.dueDate).format("DD. MMMM YYYY")}
        kidNumber={accountId}
        bankAccount={accountInvoiceDetails?.billingInfo?.bankAccount ?? ""}
        paid={!!accountInvoiceDetails?.paid}
        invoicePeriod={
          accountInvoiceDetails?.period
            ? `${format.formatDate(accountInvoiceDetails.period.startDate, "DD.MMM")}-${format.formatDate(
                accountInvoiceDetails.period.endDate,
                "DD.MMM"
              )}`
            : undefined
        }
        isLegalOwner
        isLoading={accountInvoiceQuery.loading || !accountId}
      >
        {allInvoices.loading ? (
          <Container maxWidth="sm">
            <Flex gap={"100"}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Flex>
          </Container>
        ) : (
          <InvoiceSelectorMobile invoices={allInvoices.data?.account.invoices} />
        )}
      </DetailsInfoHero>
      <DetailsWrapper>
        <DetailsElementListMobile
          isAllOpen={isAllOpen}
          setOpenIds={setOpenIds}
          openIds={openIds}
          setIsAllOpen={setIsAllOpen}
          invoiceCardPaymentStatus={accountInvoice?.invoiceCardPaymentStatus}
          invoice={accountInvoice?.invoice}
          subscriptions={accountInvoice?.subscriptions}
          setModalOpen={setModalOpen}
          invoiceDetailsIsLoading={accountInvoiceQuery.loading || !accountId}
          accountId={accountId}
          showPaidAmount={accountInvoiceDetails?.showPaidAmount || false}
          paidAmount={accountInvoiceDetails?.paidAmount || 0}
          showOverpaidAmount={accountInvoiceDetails?.showOverpaidAmount || false}
          restAmount={accountInvoiceDetails?.restAmount || 0}
          isPostponingDisabled={!accountInvoice?.canDeferInvoice}
        />
        <ReceiptPaper />
        <ButtonWrapper>
          {accountInvoiceQuery.loading ? (
            <>
              <Skeleton variant="button" />
              <Skeleton variant="button" />
            </>
          ) : (
            <>
              <Button
                fullWidthSmallScreen
                icon="zoom"
                variant="secondary"
                onClick={() =>
                  setIsAllOpen((p) => {
                    if (p) {
                      setOpenIds([]);
                    }
                    return !p;
                  })
                }
              >
                {isAllOpen ? "Lukk detaljert faktura" : "Se fakturadetaljer"}
              </Button>
              <Button
                fullWidthSmallScreen
                icon="download"
                onClick={downloadInvoice}
                variant="secondary"
                isLoading={isDownloadingInvoice}
              >
                Last ned pdf
              </Button>
              {!accountInvoiceDetails?.paid && !isPaymentInProgress && (
                <Button
                  fullWidthSmallScreen
                  icon="pay-once"
                  variant="secondary"
                  tag="a"
                  href={uri.minSideFixed("/faktura/utland")}
                  isInternalNavigation
                >
                  Betal fra utenlandsk konto
                </Button>
              )}
            </>
          )}
        </ButtonWrapper>
      </DetailsWrapper>
      <PostponeInvoice
        modalOpen={modalOpen}
        dueDate={paymentDate}
        setIsModalOpen={setModalOpen}
        accountId={accountId}
        setNextPaymentDate={setNextPaymentDate}
      />
    </Container>
  );
};
