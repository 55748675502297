import { Heading, Flex, Box } from "@telia/teddy";
import { NavigationCard } from "components/NavigationCard";
import { tvCardWidth } from "../utils/constants";
import { LogoutOfAllDevices } from "./LogoutOfAllDevices";
import { config } from "util/config";
import { uri } from "@telia-no-min-side/utils";

export function ManageLinks() {
  const { showMduForm } = config;

  return (
    <Box>
      <Heading as="h2" variant="title-100">
        Administrer
      </Heading>
      <Flex mt="200" gap="400" wrap="wrap">
        <LogoutOfAllDevices />
        <NavigationCard
          title="Skal du flytte?"
          description="Vi hjelper deg med flytting av TV- og bredbånd-abonnement."
          icon="address-book"
          link={showMduForm ? "/minside/flytting" : uri.openPages("/kundeservice/flytting")}
          width={tvCardWidth}
          layout="navigation-horizontal-small"
        />
      </Flex>
    </Box>
  );
}
