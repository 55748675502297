// Only Telia can buy TV or Internet
// Can change TV equipment: Telia, Rissa, Tafjord, Varanger
// Can change BB equipment: Telia, Rissa

const TELIA = 1;
const SOGNENETT = 7;
const NOKAB = 23;
const OKAPI = 24;
const KINSARVIK = 25;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RISSA = 26;
const TAFJORD = 27;
const VITNETT = 29;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VARANGER = 30;
const SVORKA = 33;
const SODVIN = 53;
const RENROROS = 54;
const SUCOM = 55;
const SVISS = [VITNETT, SVORKA, SODVIN, RENROROS, SUCOM];

export const isTelia = (businessUnitId: number | undefined) => !!businessUnitId && businessUnitId === TELIA;

export const isSVISS = (businessUnitId: number | undefined) => !!businessUnitId && SVISS.includes(businessUnitId);

// Tafjord and SVISS have their own invoicing
export const isPartnerWithoutInvoice = (businessUnitId: number | undefined) =>
  !!businessUnitId && (businessUnitId === TAFJORD || isSVISS(businessUnitId));

// NB: Only add urls for partners who have their own digital invoice!
export const getPartnerInvoiceUrl = (businessUnitId: number | undefined): string | null => {
  if (businessUnitId === TAFJORD) {
    return "https://kunde.tafjord.no/Touch";
  }
  return null;
};

export type PartnerInfo = {
  partnerInfo: {
    name: string;
    phone: string;
    homepage: string;
  };
};

export function fetchPartnerInformation(businessUnitId: number | undefined) {
  switch (businessUnitId) {
    case SOGNENETT:
      return {
        name: "Sognenett",
        phone: "9519 2222",
        homepage: "https://www.sognenett.no",
      };
    case NOKAB:
      return {
        name: "NOKAB",
        phone: "67 06 15 19",
        homepage: "https://www.nokab.no",
      };
    case OKAPI:
      return {
        name: "Okapi",
        phone: "53 66 53 00",
        homepage: "https://www.okapi.no/",
      };
    case KINSARVIK:
      return {
        name: "Kinsarvik",
        phone: "411 461 78",
        homepage: "https://www.kinsarvik-breiband.net/",
      };
    case VITNETT:
      return {
        name: "Vitnett",
        phone: "72 42 44 44",
        homepage: "https://vitnett.no/",
      };
    case SVORKA:
      return {
        name: "SVORKA",
        phone: "97 91 22 22",
        homepage: "https://svorka.no/bredbandtv/produkter",
      };
    case SODVIN:
      return {
        name: "SODVIN",
        phone: "72 45 01 00",
        homepage: "https://www.sodvin.no/",
      };
    case RENROROS:
      return {
        name: "Ren Røros",
        phone: "72 41 48 60",
        homepage: "https://www.renroros.no/",
      };
    case SUCOM:
      return {
        name: "SuCom",
        phone: "71 40 55 00",
        homepage: "https://www.sucom.no/",
      };
    default:
      return null;
  }
}
