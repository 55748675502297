import styled from "styled-components";
import { colors } from "@telia/styleguide";
import { devices } from "../utils/breakpoints";

type Props = {
  gridLayoutForMbb: boolean;
};

export const AccordionContainer = styled.section<Props>`
  // Accordion related
  .accordion {
    position: relative;
    width: 100%;
    max-width: 40rem;
    box-sizing: border-box;
    border: none;
    border-radius: 3px;
    background-color: ${colors.white};
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
    margin: 1rem auto;
    transition: all 0.4s;

    &--inverted {
      .accordion__header {
        background-color: ${colors.black};
        color: ${colors.white};
        position: relative;
        padding: 1rem 0.75rem 0.75rem;

        &:hover,
        &:focus {
          outline: none;
          cursor: pointer;
        }
      }

      .accordion__arrow-icon {
        color: ${colors.white};
      }
    }
  }

  .accordion__header {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem 0.75rem 0.75rem;
    color: ${colors.black};
    background-color: ${colors.white};
    border: 3px solid transparent;
    transition: border 150ms ease-out;

    &:hover,
    &:focus {
      outline: none;
      cursor: pointer;
      border: 3px solid ${colors.corePurple};

      ~ .accordion__expanded-section {
        border-left: 3px solid ${colors.corePurple};
        border-right: 3px solid ${colors.corePurple};
      }

      ~ .footer--expanded {
        border: 3px solid ${colors.corePurple};
        border-top: 0;
      }
    }

    &--expanded {
      border-bottom: 0;
      &:hover,
      &:focus {
        border-bottom: 0;
      }
    }
  }

  .accordion__header-content {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.75rem;

    @media ${devices.sm_up} {
      display: grid;
      grid-template-columns: ${(props) => (props.gridLayoutForMbb ? "10% 45% 5% 35% 5%" : "10% 40% 10% 30% 10%")};
    }
  }

  .accordion__radio-button-container {
    width: 35px;

    .radiobutton__svg-container {
      background-color: ${colors.white};
    }
  }

  .accordion__heading-name {
    text-transform: none;
    text-align: left;
  }

  .accordion__aside {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;

    @media ${devices.sm_down} {
      width: auto;
    }
  }

  .accordion__description-container {
    font-size: 1rem;
    color: ${colors.grey500};

    &--inverted {
      color: ${colors.grey200};
    }
  }

  .accordion__arrow-icon {
    width: 1rem !important;
    height: 1rem !important;
    margin: 0;
    transition: transform 0.4s;

    &--expanded {
      transform: rotate(180deg);
    }
  }

  .accordion__content-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .accordion__pdf-document {
    text-align: left;
  }

  .accordion__expanded-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    transition: border 150ms ease-out;
    padding: 0 2rem 1rem;

    &--inverted {
      padding: 0rem 0.5rem 0.5rem 0.5rem;
      border-left: 3px solid ${colors.black};
      border-right: 3px solid ${colors.black};
      background-image: linear-gradient(to bottom, ${colors.white}, ${colors.white} 100%),
        linear-gradient(to bottom, ${colors.black}, ${colors.black} 100%);
      background-clip: content-box, padding-box;
    }
  }

  // Reusable
  .price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .price {
    font-weight: bold;
    margin-left: 1rem;

    @media ${devices.sm_up} {
      font-size: 1.5rem;
      margin-left: 0rem;
    }

    &-discount {
      font-weight: bold;
      color: ${colors.grey500};
      text-decoration: line-through;
      margin-left: 1rem;

      @media ${devices.sm_up} {
        font-size: 1.5rem;
        margin-left: 0;
        margin-right: 1rem;
      }
    }
  }

  .price-text {
    font-size: 1rem;
    color: ${colors.grey500};

    &--inverted {
      color: ${colors.grey500};
    }
  }

  .price-month {
    font-size: 0.75rem;
    font-weight: lighter;
    margin-right: 1rem;
  }

  .subscriptions-count {
    font-size: 1rem;
    color: ${colors.grey500};
    font-weight: bold;
  }

  .desktop-only {
    display: none;
    @media ${devices.sm_up} {
      display: block;
    }
  }

  .mobile-only {
    display: block;
    @media ${devices.sm_up} {
      display: none;
    }
  }

  // Footer related
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: border 150ms ease-out;

    @media ${devices.sm_up} {
      height: 0;
    }

    &--inverted {
      * {
        color: ${colors.grey200};
      }

      .price {
        color: ${colors.white};
      }
    }

    &--expanded {
      border: 3px solid transparent;
      border-top: 0;
    }

    &--inverted-expanded {
      background-color: ${colors.black};
    }

    .price {
      color: ${colors.black};
    }

    .footer__price-container {
      align-self: flex-start;
      padding: 0 2rem 1rem;
    }
  }
`;

export const LineSeparator = styled.div`
  border-left: 1px solid ${colors.grey300};
  height: 65px;
  display: none;

  @media ${devices.sm_down} {
    width: auto;
  }

  @media ${devices.sm_up} {
    display: block;
  }
`;

export const ArrowDownButtonContainer = styled.div`
  flex: 1;
  text-align: end;

  @media ${devices.sm_up} {
    flex: 0;
  }
`;

export const ArrowDownButton = styled.button`
  background: none;
  border: none;

  :hover {
    cursor: pointer;
  }
  :hover,
  :active,
  :focus {
    outline: none;
    svg {
      fill: ${colors.corePurple400};
    }
  }
`;
