import styled from "styled-components";
import { Badge } from "@telia/teddy";

export const BadgeCenter = styled(Badge)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PackageContentGrid = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 20px;
  justify-items: start;
  grid-auto-rows: auto;
`;
