import { graphql } from "src/gql";

export const GET_POST_PURCHASE = graphql(`
  query GetPostPurchase($phoneNumber: String) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }

      productUser {
        firstName
        birthDate
      }

      roles
      additionalProducts {
        id
        code
        shortName
        expirationDate
        characteristic {
          featureCode
          config {
            name
            value
          }
        }
        price {
          price
          priceWithoutDiscount
          discountDuration
        }
        additionalProperties {
          name
          value
        }
      }
    }
  }
`);
