import { types } from "@telia-no-min-side/utils";
import { getSimplePriceText } from "util/priceFormatter";

export type GetPrice = {
  displayPriceRecurring: string;
  totalRecurringPrice: number;
  displayOnceOffPrice: string;
  totalOnceOffPrice: number;
  chargePeriod: string;
};

export function getPrice(product?: types.fixed.ProductGroupSingleProductWithShipping): GetPrice | undefined {
  if (!product) return;
  const {
    price: { currentPrice },
  } = product;
  const { chargePeriod, onceOff, recurring } = currentPrice;
  const totalRecurringPrice = recurring;
  return {
    displayPriceRecurring: getSimplePriceText(totalRecurringPrice, chargePeriod),
    totalRecurringPrice: totalRecurringPrice,
    displayOnceOffPrice: getSimplePriceText(onceOff),
    totalOnceOffPrice: onceOff,
    chargePeriod,
  };
}

export function getSumOfProductAndShipping(
  product?: types.fixed.ProductGroupSingleProductWithShipping,
  shipping?: types.fixed.ProductGroupShipping
): string | undefined {
  if (!product || !shipping) return;

  return `${product.price.currentPrice.onceOff + shipping.onceOffPrice},-`;
}
