import { Link } from "react-router-dom";
import { Flex, Icon, ExpandableCard, Heading, List, Button } from "@telia/teddy";
import { IncludedProperty } from "./CardContent";
import { VIAPLAY_INCLUDED_PRODUCTS } from "util/format-subscription/variables";

type Props = {
  isViaplaySubscription: boolean;
  includedProperties: IncludedProperty[];
  changeSubscriptionLink: string;
};

const ViaplayExpandedContent = ({
  includedProperties,
  changeSubscriptionLink,
}: Pick<Props, "includedProperties" | "changeSubscriptionLink">) => {
  return (
    <ExpandableCard.Content>
      <Flex direction="column" gap="gutter-lg">
        <Flex direction="column" gap="gutter-sm">
          <Heading as="h5" variant="title-100">
            Inkludert i Viaplay Total
          </Heading>

          <List isSubcomponent layout="vertical">
            {VIAPLAY_INCLUDED_PRODUCTS.map(({ icon, text }) => (
              <List.Item key={`expanded-content-included-product-${icon}`}>
                {icon && (
                  <List.Illustration>
                    <Icon name={icon} size="md" />
                  </List.Illustration>
                )}
                <List.Content>{text}</List.Content>
              </List.Item>
            ))}
          </List>
        </Flex>

        <Flex direction="column">
          <Flex direction="column" gap="gutter-sm">
            <Heading as="h5" variant="title-100">
              Inkludert i abonnement
            </Heading>

            <List isSubcomponent layout="vertical">
              {includedProperties.map(({ icon, text }) => (
                <List.Item key={`expanded-content-included-property-${icon}`}>
                  {icon && (
                    <List.Illustration>
                      <Icon name={icon} size="md" />
                    </List.Illustration>
                  )}
                  <List.Content>{text}</List.Content>
                </List.Item>
              ))}
            </List>
          </Flex>
        </Flex>
        <Flex width="100%" justify="end">
          <Button variant="text" asChild>
            <Link to={changeSubscriptionLink}>Endre</Link>
          </Button>
        </Flex>
      </Flex>
    </ExpandableCard.Content>
  );
};

const ExpandedContent = ({
  includedProperties,
  changeSubscriptionLink,
}: Pick<Props, "includedProperties" | "changeSubscriptionLink">) => {
  return (
    <ExpandableCard.Content>
      <Heading as="h4" variant="title-100">
        Inkludert
      </Heading>
      <List isSubcomponent layout="vertical">
        {includedProperties.map(({ icon, code, text }) => (
          <List.Item key={`subscription-card-expanded-content-property-${code}`}>
            {icon && (
              <List.Illustration>
                <Icon name={icon} size="md" />
              </List.Illustration>
            )}
            <List.Content>{text}</List.Content>
          </List.Item>
        ))}
      </List>

      <Flex width="100%" justify="end">
        <Button variant="text" asChild>
          <Link to={changeSubscriptionLink}>Endre</Link>
        </Button>
      </Flex>
    </ExpandableCard.Content>
  );
};

export function CardExpandedContent({ isViaplaySubscription, includedProperties, changeSubscriptionLink }: Props) {
  if (isViaplaySubscription) {
    return (
      <ViaplayExpandedContent includedProperties={includedProperties} changeSubscriptionLink={changeSubscriptionLink} />
    );
  }

  return <ExpandedContent includedProperties={includedProperties} changeSubscriptionLink={changeSubscriptionLink} />;
}
