import { Container, Link, useAuth } from "@telia-no-min-side/components";
import { Link as ReactRouterLink } from "react-router-dom";
import { track, uri } from "@telia-no-min-side/utils";
import { Accordion, TextSpacing, Text, Button, Icon, Heading } from "@telia/teddy";
import { phoneNumbers } from "util/phoneNumbers";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

export function HelpPage() {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { tokenParsed } = useAuth();
  const hasTempAccess = tokenParsed
    ? tokenParsed["urn:teliacompany:dis:ciam:mobile:no:1.0:temp_access"]?.length > 0
    : undefined;
  if (hasTempAccess) {
    return (
      <Container maxWidth="md" padding gap flexDirection="column">
        <Heading as="h3" variant="title-200" data-di-mask>
          Trenger du hjelp?
        </Heading>
        <Container padding="vertical">
          <Button asChild variant="primary">
            <a
              href={uri.openPages("/kundeservice")}
              onClick={() => {
                pushGenericTrackingEvent({
                  ui_item_action: "call kundeservice",
                  ui_item_context: "help",
                  ui_item_text: "help",
                  ui_item_type: "message",
                  ui_item_url: "no_url",
                });
              }}
            >
              Gå til kundeservice
              <Icon name="arrow-right" />
            </a>
          </Button>
        </Container>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" padding gap flexDirection="column">
      <Accordion type="multiple">
        <Accordion.Title as="h3" variant="title-100">
          Trenger du hjelp?
        </Accordion.Title>
        <Accordion.Item value="Jeg finner ikke mine mobil-produkter">
          <Accordion.Header>
            <Accordion.Trigger>Jeg finner ikke mine mobil-produkter</Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <TextSpacing>
              <Text as="p">
                Hvis du har eksisterende mobil-abonnement skulle det dukket opp{" "}
                <Link href={uri.minSideMobile("")}> her </Link>. Hvis dine mobil-produkter ikke dukker opp, må du utføre
                en verifisering via knappen under.
              </Text>
              <Button asChild variant="primary">
                <ReactRouterLink
                  to={uri.minSideFixed("/hjelp/mobil-kontogjenoppretting")}
                  onClick={() => {
                    pushGenericTrackingEvent({
                      ui_item_action: "start-self-recovery-mobile",
                      ui_item_context: "self-recovery",
                      ui_item_text: "help",
                      ui_item_type: "message",
                      ui_item_url: "no_url",
                    });
                  }}
                >
                  Verifiser deg
                </ReactRouterLink>
              </Button>
            </TextSpacing>
          </Accordion.Content>
        </Accordion.Item>
        <Accordion.Item value="Jeg finner ikke mine TV-produkter">
          <Accordion.Header>
            <Accordion.Trigger>Jeg finner ikke mine TV-produkter</Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <TextSpacing>
              <Text as="p">
                Hvis du har eksisterende TV-abonnement skulle det dukket opp{" "}
                <Link href={uri.minSideFixed("/tv")} isInternalNavigation>
                  {" "}
                  her{" "}
                </Link>
                . Hvis dine TV-produkter ikke dukker opp, må du utføre en verifisering via knappen under.
              </Text>
              <Button asChild variant="primary">
                <ReactRouterLink
                  to={uri.minSideFixed("/kontogjenoppretting")}
                  onClick={() => {
                    pushGenericTrackingEvent({
                      ui_item_action: "start-self-recovery-tv",
                      ui_item_context: "self-recovery",
                      ui_item_text: "help",
                      ui_item_type: "message",
                      ui_item_url: "no_url",
                    });
                  }}
                >
                  Verifiser deg
                </ReactRouterLink>
              </Button>
            </TextSpacing>
          </Accordion.Content>
        </Accordion.Item>
        <Accordion.Item value="Jeg finner ikke mine Internett-produkter">
          <Accordion.Header>
            <Accordion.Trigger>Jeg finner ikke mine Internett-produkter</Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <TextSpacing>
              <Text as="p">
                Hvis du har eksisterende internett-abonnement skulle det dukket opp{" "}
                <Link href={uri.minSideFixed(ICX_REQUEST.URL_INTERNET_EQUIPMENT)} isInternalNavigation>
                  {" "}
                  her{" "}
                </Link>
                . Hvis dine internett-produkter ikke dukker opp, må du utføre en verifisering via knappen under.
              </Text>
              <Button asChild variant="primary">
                <ReactRouterLink
                  to={uri.minSideFixed("/kontogjenoppretting")}
                  onClick={() => {
                    pushGenericTrackingEvent({
                      ui_item_action: "start-self-recovery-internet",
                      ui_item_context: "self-recovery",
                      ui_item_text: "help",
                      ui_item_type: "message",
                      ui_item_url: "no_url",
                    });
                  }}
                >
                  Verifiser deg
                </ReactRouterLink>
              </Button>
            </TextSpacing>
          </Accordion.Content>
        </Accordion.Item>
        <Accordion.Item value="Jeg finner ikke mine Mobilt bredbånd-produkter">
          <Accordion.Header>
            <Accordion.Trigger>Jeg finner ikke mine Mobilt bredbånd-produkter</Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <Text as="p">
              Hvis du har eksisterende Mobilt bredbånd-abonnement skulle det dukket opp{" "}
              <Link href={uri.minSideFixed(ICX_REQUEST.URL_INTERNET_EQUIPMENT)} isInternalNavigation>
                {" "}
                her{" "}
              </Link>
              . Hvis dine Mobilt bredbånd-produkter ikke dukker opp, må du ringe kundeservice på nummer{" "}
              <Link variant="standalone" href={`tel:${phoneNumbers.customerServiceNorway}`}>
                {phoneNumbers.customerServiceNorway}
              </Link>
            </Text>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
      <Container padding="vertical">
        <Button asChild variant="primary">
          <a
            href={uri.openPages("/kundeservice")}
            onClick={() => {
              pushGenericTrackingEvent({
                ui_item_action: "call kundeservice",
                ui_item_context: "help",
                ui_item_text: "help",
                ui_item_type: "message",
                ui_item_url: "no_url",
              });
            }}
          >
            Gå til kundeservice
            <Icon name="arrow-right" />
          </a>
        </Button>
      </Container>
    </Container>
  );
}
