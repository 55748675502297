import type { ReactNode } from "react";
import { useEffect, useLayoutEffect, useRef, useState, Children, cloneElement, isValidElement } from "react";
import { Container, Item } from "./styles";
import { useWindowDimensions } from "./useWindowDimension";

type Props = {
  children: ReactNode;
  cols?: number;
  gap?: number;
};

export function MasonaryItem({ children }: { children: ReactNode }) {
  return children;
}

export function MasonaryContainer({ children, cols = 2, gap = 20 }: Props) {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [childElemHeight, setChildElemHeight] = useState<number[]>([]);
  const [childElemRowPosition, setChildElemRowPositon] = useState<string[]>([]);
  const [childElemColumnPosition, setChildElemColumnPosition] = useState<string[]>([]);
  const [updateChildElemWidth, setUpdateChildElemWidth] = useState(false);
  const { width } = useWindowDimensions();
  const mobileBreakpoint = 600;

  const isMobile = width && width <= mobileBreakpoint;
  // For mobile, it will always be 1 column layout
  const columns = isMobile ? 1 : cols;

  const calculateElementWidth = () => {
    if (!parentRef.current) {
      return 0;
    }
    return parentRef.current?.clientWidth / columns;
  };

  const getElementWidth = () => {
    // Handle responsive design for mobile
    if (isMobile) {
      return `100%`;
    }
    return `${calculateElementWidth()}px`;
  };

  // Set the flag to set width of each child element to calculate the height of each block before initial render or when any child is updated or screen width is changed
  useLayoutEffect(() => {
    setUpdateChildElemWidth(true);
  }, [children, width]);

  // Calculate the row and column position for each child eleemnt based on the height of each block
  useEffect(() => {
    if (updateChildElemWidth) {
      const itemHeights: number[] = [];
      const itemRowPostions: number[] = [];
      const itemColumnPosition: number[] = [];
      parentRef.current?.childNodes.forEach((node) => {
        if (node instanceof HTMLElement) {
          // This is check for typescript
          itemHeights.push(node?.clientHeight);
        }
      });
      let columnNumber = 0;
      let maxRowHeight = 0;
      for (let i = 0; i < itemHeights.length; i++) {
        // Calculate the row for each block
        let newIndex = i;
        let itemRowPostion = 0;
        while (newIndex >= 0) {
          newIndex = newIndex - columns;
          itemRowPostion += itemHeights[newIndex] ? itemHeights[newIndex] + gap : 0;
        }
        // Calculate the height of the container
        maxRowHeight = maxRowHeight < itemRowPostion + itemHeights[i] ? itemRowPostion + itemHeights[i] : maxRowHeight;
        itemRowPostions.push(itemRowPostion);
        // Calculate the column for each block
        if (i % columns === 0 || i === 0) {
          columnNumber = 0;
        } else {
          columnNumber = columnNumber + 1;
        }
        itemColumnPosition.push((calculateElementWidth() + gap) * columnNumber);
      }
      setContainerHeight(maxRowHeight);
      setChildElemHeight(itemHeights);
      setChildElemRowPositon(itemRowPostions.map((s) => `${s}px`));
      setChildElemColumnPosition(itemColumnPosition.map((c) => `${c}px`));
    }
  }, [updateChildElemWidth]);

  // unset the flag after calculated row and column position are applied
  useEffect(() => {
    if (childElemHeight.length > 0) {
      setUpdateChildElemWidth(false);
    }
  }, [childElemHeight, childElemRowPosition, childElemColumnPosition]);

  return (
    <Container ref={parentRef} height={`${containerHeight}px`}>
      {Children.map(children, (child, id) => {
        // Check if the child is a valid element and also if it is a MasonaryItem
        if (isValidElement(child) && child.type === MasonaryItem) {
          return (
            <Item
              width={getElementWidth()}
              rowPostion={childElemRowPosition[id]}
              columnPosition={childElemColumnPosition[id]}
            >
              {cloneElement(child)}
            </Item>
          );
        }
      })}
    </Container>
  );
}
