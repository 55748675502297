import { useLazyQuery, useMutation } from "@apollo/client";
import { graphql } from "gql";
import { ICustomer, ProductUserUpdateMutation, ProductUserUpdateMutationVariables } from "gql/graphql";
import { GET_PRODUCT_USER } from "../graphql/getProductUser";
import { useBankIdMutation } from "hooks/useBankIdMutation";
import { useToast } from "@telia-no-min-side/components";
import { config } from "util/config";

export const UPDATE_PRODUCT_USER = graphql(`
  mutation ProductUserUpdate($phoneNumber: String, $productUser: ICustomer!) {
    productUserUpdate(phoneNumber: $phoneNumber, productUser: $productUser) {
      orderId
    }
  }
`);

export function useProductUser() {
  const { addToast } = useToast();
  const mutationOptions = {
    onCompleted() {
      addToast({
        text: "Dine endringer er lagret. Det kan ta opp til 5 minutter før du ser endringen her.",
        variant: "success",
        dataTrackingId: "update-product-user-data-success",
      });
    },
    onError() {
      addToast({
        text: "Endringene dine ble ikke lagret. Vennligst prøv på nytt.",
        variant: "error",
        dataTrackingId: "update-product-user-data-error",
      });
    },
  };
  const [updateProductUser, updateProductUserResponse] = useMutation<
    ProductUserUpdateMutation,
    ProductUserUpdateMutationVariables
  >(UPDATE_PRODUCT_USER, mutationOptions);

  const [updateProductUserWithBankId, updateProductUserWithBankIdResponse] = useBankIdMutation(
    UPDATE_PRODUCT_USER,
    mutationOptions,
    "updateProductUserData"
  );

  const [runGetProductUserQuery, { loading: productUserLoading, error: productUserError, data: productUserData }] =
    useLazyQuery(GET_PRODUCT_USER, {
      fetchPolicy: "no-cache",
    });

  const getProductUser = (phoneNumber: string) => {
    runGetProductUserQuery({ variables: { phoneNumber } });
  };

  const runUpdateProductUser = async (phoneNumber: string, productUser: ICustomer) => {
    if (config.requireBankId) {
      await updateProductUserWithBankId({ variables: { phoneNumber, productUser } });
    } else {
      await updateProductUser({
        variables: {
          phoneNumber,
          productUser,
        },
      });
    }
  };

  return {
    getProductUser,
    productUserResponse: {
      loading: productUserLoading,
      error: productUserError,
      data: productUserData?.subscription,
    },
    runUpdateProductUser,
    runUpdateProductUserResponse: config.requireBankId
      ? updateProductUserWithBankIdResponse
      : updateProductUserResponse,
  };
}
