import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  FetchResult,
  MutationFunction,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";
import { useAuth } from "@telia-no-min-side/components";
import { KeycloakTokenParsed } from "keycloak-js";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

// Unique should be provided to the useBankIdMutation hook to allow for multiple mutations on the same page
// Else the pending mutation will be executed on the first mutation that returns from BankID
export type ExtraBankIdMutationOptions = {
  uniqueKey: string;
};

const PENDING_MUTATION_KEY = "pendingMutationVariables";
const PENDING_AUTH_TIME = "pendingAuthTime";
const BANK_ID_MAX_AGE = 60 * 2; // 2 minute
const AUTH_EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
const BANK_ID_QUERY_PARAM = { key: "redir", value: "bankid" };

type BankIdMutationFunctionOptions<TData, TVariables, TContext, TCache extends ApolloCache<unknown>> = Pick<
  MutationFunctionOptions<TData, TVariables, TContext, TCache>,
  "variables"
>;

// The mutate callback we return should not allow passing onCompleted or onError callbacks
// Because they cannot be serialized and persisted in sessionStorage.
// Instead hook level callbacks should be "restored" and called when a component in question in mounted.
type BankIdMutationFunction<TData, TVariables, TContext, TCache extends ApolloCache<unknown>> = (
  options?: BankIdMutationFunctionOptions<TData, TVariables, TContext, TCache>,
  forceSkipBankIdCheck?: boolean
) => Promise<void | FetchResult<TData>>;

export function useBankIdMutation<
  TData = unknown,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<unknown> = ApolloCache<unknown>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: MutationHookOptions<TData, TVariables, TContext, TCache>,
  uniqueKey: string
): [BankIdMutationFunction<TData, TVariables, TContext, TCache>, MutationResult<TData>] {
  const { setAuthAdapter, requestBankIdWithMaxAge, tokenParsed } = useAuth();
  const [internalMutate, response] = useMutation(mutation, options);
  const [searchParams, setSearchParams] = useSearchParams();
  const isSessionValid = (token: KeycloakTokenParsed | undefined): boolean =>
    !!(token?.acr === "3" && token?.amr?.includes("bankid")) &&
    Boolean(token?.auth_time) &&
    Math.floor(Date.now() / 1000) - (token?.auth_time || 0) < BANK_ID_MAX_AGE;

  const isReturningFromBankId = (): boolean => {
    const pendingAuthTime = sessionStorage.getItem(PENDING_AUTH_TIME);
    const hasReturnParam = searchParams.get(BANK_ID_QUERY_PARAM.key) === BANK_ID_QUERY_PARAM.value;
    const authTime = pendingAuthTime ? parseInt(pendingAuthTime, 10) : NaN;
    return hasReturnParam && !isNaN(authTime) && Date.now() - authTime < AUTH_EXPIRATION_TIME;
  };

  const mutate: MutationFunction<TData, TVariables, TContext, TCache> = async (
    // It does not make sense to allow anything than variables here, because only variables are persisted
    // callbacks like onCompleted or onError will not be persisted and should be passed at hook useBankIdMutation level
    mutateOptions?: Pick<MutationFunctionOptions<TData, TVariables, TContext, TCache>, "variables">,
    // Avoid using it, it's mostly for feature flags or edge cases
    forceSkipBankIdCheck?: boolean
  ) => {
    if (forceSkipBankIdCheck) {
      return internalMutate(mutateOptions);
    }

    // 1. Handle legacy authentication providers
    if (!requestBankIdWithMaxAge) {
      setAuthAdapter("CIAM");
      return Promise.resolve({});
    }

    // 2. If the session is not valid, store the mutation variables to allow for auto mutate on return from BankID
    if (!isSessionValid(tokenParsed)) {
      const variables = mutateOptions?.variables;

      if (variables) {
        sessionStorage.setItem(PENDING_MUTATION_KEY, JSON.stringify({ variables, uniqueMutationKey: uniqueKey }));
        sessionStorage.setItem(PENDING_AUTH_TIME, Date.now().toString());
      }
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        params.set(BANK_ID_QUERY_PARAM.key, BANK_ID_QUERY_PARAM.value);
        return params;
      });

      requestBankIdWithMaxAge(BANK_ID_MAX_AGE);

      // Return a to make typescript happy, requestBankIdWithMaxAge will make a redirect to bankid, so this code will never be reached
      return Promise.resolve({});
    }

    return internalMutate(mutateOptions);
  };

  useEffect(() => {
    const handlePendingMutation = async () => {
      const pendingVariables = sessionStorage.getItem(PENDING_MUTATION_KEY);

      if (!pendingVariables || !isReturningFromBankId()) {
        sessionStorage.removeItem(PENDING_MUTATION_KEY);
        sessionStorage.removeItem(PENDING_AUTH_TIME);
        return;
      }

      const { variables, uniqueMutationKey } = JSON.parse(pendingVariables);

      if (uniqueMutationKey !== uniqueKey) {
        return;
      }

      sessionStorage.removeItem(PENDING_AUTH_TIME);

      if (!isSessionValid(tokenParsed)) {
        console.error("Session is invalid after authentication.");
        sessionStorage.removeItem(PENDING_MUTATION_KEY);
        return;
      }

      try {
        await internalMutate({
          ...options,
          variables,
        });
      } catch (error) {
        console.error("Failed to perform pending mutation:", error);
      }

      sessionStorage.removeItem(PENDING_MUTATION_KEY);
    };

    handlePendingMutation();
  }, [internalMutate, tokenParsed]);

  return [mutate, response];
}
